import { useEffect } from 'react'

/**
 * A hook that blocks the "CTRL" or "COMMAND" (for Mac) key in combination with the use of the "+" and "-" keys. It also blocks the use of mouse wheel to zoom the page.
 *
 * @returns {void}
 */
const useZoomBlocker = (): void => {
  const handleKeyDown = (event: KeyboardEvent): void => {
    if ((event.ctrlKey || event.metaKey) && (event.key === '+' || event.key === '-')) {
      event.preventDefault()
    }
  }

  const handleMouseWheel = (event: WheelEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.deltaY !== 0) {
      event.preventDefault()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('wheel', handleMouseWheel, { passive: false }) //passive: false is necessary to allow the event.preventDefault() function https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#:~:text=to%20false.-,passive,-Optional
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('wheel', handleMouseWheel)
    }
  }, [])
}

export default useZoomBlocker
