import React from 'react'
import styled, { css } from 'styled-components'
import { breakpoints, palette, pxToRem, getFluidSizeWithFullFormula as gF } from '../../style/theme'
import RCIcon from '../../components/UI/RCIcon'
import { scrollbarCss } from '../../components/CommonComponents'
import { AfaCartProduct } from '../../model/afa'
import { useSelector } from 'react-redux'
import { afaCartDataSelector } from '../../store/afaCart/selectors'
import { useTranslation } from 'react-i18next'

const sizeGridRows = css`
  display: grid;
  grid-template-columns: 5fr 1.5fr 1.5fr 1.5fr;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'GilmerRegular', sans-serif;
  color: ${palette.tangaroa};
  align-items: center;
  overflow: hidden;
`

const Title = styled.div`
  font-size: ${gF('px', 26, 48, 1366, 3840)};
  margin-bottom: ${gF('px', 32, 40, 1366, 3840)};
  font-family: GilmerMedium, sans-serif;
  @media (max-width: ${breakpoints.L}) {
    font-family: GilmerBold, sans-serif;
  }
`

const Subtitle = styled.div`
  font-size: ${gF('px', 20, 40, 1366, 3840)};
  margin-bottom: ${gF('px', 72, 87, 1366, 3840)};
`

const MainWrapper = styled.div`
  width: ${gF('px', 690, 1370, 1366, 3840)};
  margin-bottom: ${gF('px', 72, 165, 1366, 3840)};
`

const HeaderWrapper = styled.div`
  ${sizeGridRows}
`

const GridHeader = styled.div<{ centered?: boolean }>`
  border-bottom: solid ${gF('px', 1, 4, 1366, 3840)} ${palette.mischka};
  color: ${palette.manatee};
  padding-bottom: ${gF('px', 16, 32, 1366, 3840)};
  margin-bottom: ${gF('px', 24, 64, 1366, 3840)};
  position: sticky;
  top: 0;
  background-color: ${palette.white};
  text-align: ${props => (props.centered ? 'center' : 'left')};
  font-family: GilmerBold, sans-serif;
  font-size: ${gF('px', 16, 32, 1366, 3840)};
`

const ProductsWrapper = styled.div`
  ${scrollbarCss};
  display: flex;
  flex-direction: column;
  height: ${gF('px', 500, 900, 1366, 3840)};
  overflow-y: auto;
`

const ProductRow = styled.div`
  ${sizeGridRows}
  & > .arrow {
    font-size: ${gF('px', 32, 57, 1366, 3840)};
  }
`

const ProductCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${gF('px', 2, 22, 1366, 3840)};
  align-self: center;
  margin-bottom: ${gF('px', 29, 40, 1366, 3840)};
  color: ${palette.tangaroa};
  @media (max-width: ${breakpoints.L}) {
    color: ${palette.cloudBurst};
  }
`

const ProductName = styled.span`
  font-family: GilmerMedium, sans-serif;
  font-size: ${gF('px', 16, 28, 1366, 3840)};
`

const ProductColor = styled.span`
  font-size: ${gF('px', 14, 28, 1366, 3840)};
`

const QuantityCell = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  font-size: ${gF('px', 16, 28, 1366, 3840)};
  align-self: center;
  margin-bottom: ${gF('px', 29, 40, 1366, 3840)};

  &.prev-quantity {
    color: ${palette.shadowBlue};
    font-family: GilmerMedium, sans-serif;
  }

  &.new-quantity {
    color: ${palette.darkGreen};
    font-family: GilmerBold, sans-serif;
  }
`

const DoneButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: ${pxToRem(4)}rem;
  font-size: ${gF('px', 13, 24, 1366, 3840)};
  padding: ${gF('px', 20, 27, 1366, 3840)} ${gF('px', 83, 182, 1366, 3840)};
  height: ${gF('px', 56, 72, 1366, 3840)};
  min-width: ${gF('px', 120, 150, 1366, 3840)};
  font-family: GilmerBold, sans-serif;
  color: ${palette.white};
  background-color: ${palette.tangaroa};
  text-transform: uppercase;
`

const groupQuantitiesByMoco = (cartProducts: AfaCartProduct[]) => {
  const quantitiesByMoco = cartProducts.reduce((result, cartProduct) => {
    const key = `${cartProduct.modelCode}____${cartProduct.colorCode}`
    result[key] = (result[key] || 0) + cartProduct.unconfirmedQuantity
    return result
  }, {} as Record<string, number>)
  return quantitiesByMoco
}

type Props = {
  expiredProducts: AfaCartProduct[]
  cartProducts: AfaCartProduct[]
  onConfirm: () => void
}

const AfaCartAdjustExpiredRecap: React.FC<Props> = ({
  expiredProducts,
  cartProducts,
  onConfirm,
}) => {
  const { t } = useTranslation()
  const cartData = useSelector(afaCartDataSelector)

  const expiredKeys = expiredProducts.map(({ key }) => key)
  const cartProductsNotExpired = cartProducts.filter(({ key }) => !expiredKeys.includes(key))
  const expiredQuantitiesByMoco = groupQuantitiesByMoco(expiredProducts)
  const cartQuantitiesByMoco = groupQuantitiesByMoco(cartProductsNotExpired)

  return (
    <Wrapper>
      <Title>{t('Afa.Cart.AdjustAll.Title')}</Title>
      <Subtitle>{t('Afa.Cart.AdjustAll.Subtitle')}</Subtitle>
      <MainWrapper>
        <HeaderWrapper>
          <GridHeader>{t('Afa.Cart.AdjustAll.Product')}</GridHeader>
          <GridHeader centered>{t('Afa.Cart.AdjustAll.PrevQty')}</GridHeader>
          <GridHeader />
          <GridHeader centered>{t('Afa.Cart.AdjustAll.NewQty')}</GridHeader>
        </HeaderWrapper>
        <ProductsWrapper>
          {Object.entries(expiredQuantitiesByMoco).map(([mocoCode, quantity], index) => {
            const [modelCode, colorCode] = mocoCode.split('____')
            const productDetails = cartData && cartData[modelCode]
            const moco =
              productDetails &&
              Object.values(productDetails.mocos).find(moco => colorCode === moco.colorCode)

            return (
              <ProductRow key={`product-${index}-row-${mocoCode}`}>
                <ProductCell>
                  <ProductName>{productDetails?.name || modelCode}</ProductName>
                  <ProductColor>{moco?.colorDescription || colorCode}</ProductColor>
                </ProductCell>
                <QuantityCell className="prev-quantity">{quantity}</QuantityCell>
                <QuantityCell className="arrow">
                  <RCIcon type="checkout-import-export-comparison-arrow" />
                </QuantityCell>
                <QuantityCell className="new-quantity">
                  {cartQuantitiesByMoco[mocoCode] || 0}
                </QuantityCell>
              </ProductRow>
            )
          })}
        </ProductsWrapper>
      </MainWrapper>
      <DoneButton onClick={() => onConfirm()}>{t('Afa.Cart.AdjustAll.Done')}</DoneButton>
    </Wrapper>
  )
}

export default AfaCartAdjustExpiredRecap
