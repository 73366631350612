import { merge } from 'lodash'

import { getCouvettesByModelCodes } from '../api/restApi'
import { ApiData } from '../model/api'
import { AfaCartProduct } from '../model/afa'
import { CartProduct, MocoInCart } from '../model/cart'
import { CustomerType } from '../model/customer'
import { Moco, Product } from '../model/product'
import { convertDdMmYyyyToDate } from './time'
import { isNotUndefined } from './utils'

export function getMocoQuantity(mocoSizes: CartProduct[]) {
  return Object.values(mocoSizes).reduce((total, size) => total + size.quantity, 0)
}

export const extractModelCodeFromMocoCode = (mocoCode: string) => mocoCode.split('__')[0]
export const extractColorCodeFromMocoCode = (mocoCode: string | null): string => {
  return mocoCode && mocoCode.split('__')[1] ? mocoCode.split('__')[1] : ''
}
export const splitMocoCode = (mocoCode: string) => mocoCode.split('__')

export const buildMocoCode = ({ modelCode, colorCode }: { modelCode: string; colorCode: string }) =>
  `${modelCode}__${colorCode}`

export const getProductSizesFromMoco = <
  T extends { mocoCode: string; size: string; modelCode: string }
>(
  mocos: { sizes: Record<string, T>; enabledDoors: string[] }[],
  dislike: string[] = [],
) =>
  mocos
    .flatMap(({ sizes, enabledDoors }) =>
      Object.values(sizes).map(size => ({ ...size, enabledDoors })),
    )
    .filter(size => !dislike.includes(size.mocoCode))
    .sort((a, b) => (Number(a.size) < Number(b.size) ? -1 : 1))

export const loadMissingProductDetails = (
  missingModelCodes: string[],
  apiData: ApiData,
  withContent = false,
  requestType: string,
  customerType: CustomerType,
) => {
  const { token, lang, customerId, eventId } = apiData
  if (!missingModelCodes || !customerId || !eventId) {
    return Promise.resolve()
  }

  const missingModelCodesNoDuplicates = [...new Set(missingModelCodes)]

  if (!token) {
    return Promise.reject('missing token')
  }

  const chunkSize = 150
  const missingModelCodesChunks = []
  while (missingModelCodesNoDuplicates.length) {
    const chunk = missingModelCodesNoDuplicates.splice(0, chunkSize)
    missingModelCodesChunks.push(chunk)
  }

  return Promise.all(
    missingModelCodesChunks.map(missingModelCodesChunk =>
      getCouvettesByModelCodes({
        authToken: token,
        lang,
        customerId,
        eventId,
        modelCodes: missingModelCodesChunk,
        withContent,
        requestType,
        customerType,
      }),
    ),
  )
}

export const mergeNewProductDetailsInState = (
  detailsInState: Record<string, Product>,
  newDetailsArray: Product[],
  otherProperties = {},
) => {
  const newDetailsObj = newDetailsArray.reduce((result, product) => {
    result[product.modelCode] = product
    return result
  }, {} as Record<string, Product>)

  return merge(newDetailsObj, detailsInState, otherProperties)
}

export const isMocoDiscontinued = (moco: Moco | MocoInCart) =>
  moco.tags && moco.tags.includes('discontinued')

export const isNewOrColorRefresh = (moco?: Moco) =>
  (moco?.isNew || moco?.isDigitalPreview) && ['A', 'N'].includes(moco?.newOld?.label || '')

/**
 * This function get the list of cLens_mocoCode to pass to rc-api to get contents by cLens and then
 * assign them to the right moco
 * @param mocos
 * @returns an array of string in the format cLenCode|mocoCode
 */
export const getCLensToMocoCode = (mocos: Moco[]) =>
  mocos
    .flatMap(moco => Object.values(moco.sizes)?.map(size => `${size.cLens?.id}|${size.mocoCode}`))
    .filter(isNotUndefined)

export const isUpcCode = (str: string) => str.match(/^[0-9]+$/) !== null && str.length > 8

export const sortProductsByDelivery = (product1: AfaCartProduct, product2: AfaCartProduct) => {
  const firstDate =
    (product1.deliveryDate && convertDdMmYyyyToDate(product1.deliveryDate).getTime()) || 0
  const secondDate =
    (product2.deliveryDate && convertDdMmYyyyToDate(product2.deliveryDate).getTime()) || 0
  if (firstDate > secondDate) {
    return 1
  } else if (firstDate < secondDate) {
    return -1
  } else {
    return 0
  }
}
