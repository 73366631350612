import React from 'react'

import KeyboardInput from '../KeyboardInput'

type Props = {
  disabled?: boolean
  name?: string
  onChange: (value: string) => void
  onHideKeyboard: () => void
  value: number | string // It can be an empty string
  onFocus: () => void
  onBlur: () => void
}

const NumberKeyboardInput: React.FC<Props> = ({
  disabled,
  onChange,
  value,
  name,
  onHideKeyboard,
  onFocus,
  onBlur,
}) => (
  <KeyboardInput
    enabled={!disabled}
    type="text"
    onChange={onChange}
    value={value}
    name={name}
    onHideKeyboard={onHideKeyboard}
    onFocus={onFocus}
    onBlur={onBlur}
  />
)

export default NumberKeyboardInput
