// import './index.sass'
import './i18n'
import 'current-device'

import { createBrowserHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
// Redux Store
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'

import App from './App'
import socketManager from './libs/socketManager'
import { getDeviceFromUrl } from './libs/url'
import { TDispatch } from './model/model'
import { store } from './store'
import {
  setCartViewType as setCartViewTypeAction,
  subscribeToAppMessagesAction,
  subscribeToCartMessagesAction,
} from './store/actions'
import appActions from './store/app/actions'
import authActions from './store/auth/actions'
import { subscribeToWishlistMessagesAction } from './store/wishlist/substriptions'
import GlobalStyle from './style/GlobalStyle'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

const customHistory = createBrowserHistory()

const setCartViewType = (dispatch: any, history: any) => {
  const dispatchSetCartViewType = (device: string) =>
    dispatch(setCartViewTypeAction(device === 'wall' ? 'wallView' : 'tableView'))
  const device = getDeviceFromUrl()
  if (device) {
    dispatchSetCartViewType(device)
  } else {
    const historyListener = () => {
      const device = getDeviceFromUrl()
      if (device) {
        dispatchSetCartViewType(device)
        //eslint-disable-next-line
        stopHistoryListener()
      }
    }
    const stopHistoryListener = history.listen(historyListener)
  }
}

const dispatch: TDispatch = store.dispatch

// intersession info (eventId, customerId, token) cannot be inserted into the default state
// or they will persist event when the DESTROY_SESSION message is dispatched
// and the state is reverted to the initial one
// so we need to keep the initial state clean and then update these info as soon as possible
dispatch(appActions.initSession())
dispatch(authActions.initSession())

socketManager.initSocket(store.dispatch, store.getState)

setCartViewType(store.dispatch, customHistory)

dispatch(subscribeToCartMessagesAction())
dispatch(subscribeToWishlistMessagesAction())
dispatch(subscribeToAppMessagesAction(customHistory))

ReactDOM.render(
  <Provider store={store}>
    <Router history={customHistory}>
      <GlobalStyle />
      <App />
      <ToastContainer />
    </Router>
  </Provider>,
  document.getElementById('root'),
)
