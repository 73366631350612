import React, { SVGProps } from 'react'

const DashboardIcon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-3 -3 24.000001 24.000001"
    width="19.200001"
    height="19.200001"
    fill="currentColor"
    {...props}
  >
    <path d="M 7.2,-3 H -2.4 A 0.6,0.6 0 0 0 -3,-2.4 v 7.2 a 0.6,0.6 0 0 0 0.6,0.6 H 7.2 A 0.6,0.6 0 0 0 7.8,4.8 V -2.4 A 0.6,0.6 0 0 0 7.2,-3 Z M 6.6,4.2 h -8.4 v -6 H 6.6 Z M 7.2,7.8 H -2.4 A 0.6,0.6 0 0 0 -3,8.4 v 12 A 0.6,0.6 0 0 0 -2.4,21 H 7.2 A 0.6,0.6 0 0 0 7.8,20.4 V 8.4 A 0.6,0.6 0 0 0 7.2,7.8 Z m -0.6,12 H -1.8 V 9 H 6.6 Z M 20.4,-3 h -9.6 a 0.6,0.6 0 0 0 -0.6,0.6 v 12 a 0.6,0.6 0 0 0 0.6,0.6 h 9.6 A 0.6,0.6 0 0 0 21,9.6 v -12 A 0.6,0.6 0 0 0 20.4,-3 Z M 19.8,9 H 11.4 V -1.8 h 8.4 z m 0.6,3.6 h -9.6 a 0.6,0.6 0 0 0 -0.6,0.6 v 7.2 a 0.6,0.6 0 0 0 0.6,0.6 h 9.6 A 0.6,0.6 0 0 0 21,20.4 v -7.2 a 0.6,0.6 0 0 0 -0.6,-0.6 z m -0.6,7.2 h -8.4 v -6 h 8.4 z" />
  </svg>
)

export default DashboardIcon
