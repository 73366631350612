import { useLocation } from 'react-router-dom'

export const useLocationState = () => {
  const location = useLocation()

  return location && location.state && typeof location.state === 'object'
    ? (location.state as Record<string, any>)
    : ({} as Record<string, any>)
}

export const useLocationStateValue = (key: string) => {
  const locationState = useLocationState()

  return locationState ? locationState[key] : undefined
}
