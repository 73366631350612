import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { lastAddedProductRecapSelector } from '../../../../store/cart/selectors'
import {
  breakpointsCross,
  breakpointsNoUnit,
  getFluidSizeWithFullFormula,
  minSupportedWidth,
  palette,
} from '../../../../style/theme'
import ThumbItem from '../../../Couvettes/Couvette/TableThumbsNavigation/ThumbItem'
import { getProductImageUrl } from '../../../../libs/productImages'

export const LastProduct = styled.div`
  position: absolute;
  right: 2.1vw;
  bottom: 4.63vh;
  z-index: 100;
  background-color: ${palette.white};
  font-size: 0.75rem;
  border-radius: 0.35vw;
  box-shadow: 0 2px 24px 8px rgba(0, 0, 0, 0.04);
  padding-bottom: 0.8vh;
  width: 17.6vw;
  font-size: 1.5rem;

  @media screen and (max-width: ${breakpointsCross.L.min}) {
    font-size: 0.8rem;
  }

  @media screen and (max-width: ${breakpointsCross.M.min}) {
    max-width: 40vw;
    width: 20rem;
    right: 1.76vw;
    bottom: 1.76vw;
  }

  h2 {
    padding: 0.8vmin;
    text-align: center;
    background-image: linear-gradient(to bottom, ${palette.tropicalBlue}, ${palette.selago});
    font-family: 'Avenir-Roman', 'Avenir-Roman-Custom', sans-serif;
    font-size: ${getFluidSizeWithFullFormula('px', 6, 35, minSupportedWidth, breakpointsNoUnit.XL)};
    font-weight: 900;
    color: ${palette.biscay};
    text-transform: uppercase;
    border-top-left-radius: 0.35vw;
    border-top-right-radius: 0.35vw;
  }

  .info {
    display: flex;
    justify-content: flex-end;
    padding: 0 0.5rem;
    align-items: stretch;
  }

  .size {
    display: flex;
    align-items: center;
    padding: 0.3rem;
    border: 1px solid ${palette.gallery};
    border-radius: 1rem;
    background-color: ${palette.white};
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    color: ${palette.kobi};
    padding: 0 1rem;
    font-size: ${getFluidSizeWithFullFormula('px', 6, 50, minSupportedWidth, breakpointsNoUnit.XL)};
    margin-right: 0.3rem;
  }

  .count {
    font-size: ${getFluidSizeWithFullFormula('px', 6, 50, minSupportedWidth, breakpointsNoUnit.XL)};
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${getFluidSizeWithFullFormula('px', 8, 100, minSupportedWidth, breakpointsNoUnit.XL)};
    width: ${getFluidSizeWithFullFormula('px', 8, 100, minSupportedWidth, breakpointsNoUnit.XL)};
    border-radius: 50%;
    background-color: ${palette.hibiscus};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    color: ${palette.white};
  }

  .product-thumb {
    > span {
      font-size: ${getFluidSizeWithFullFormula(
        'px',
        6,
        30,
        minSupportedWidth,
        breakpointsNoUnit.XL,
      )};
      color: ${palette.curiousBlue};
      display: flex;
      justify-content: space-between;
      padding: 0 1vw;
    }
  }

  img {
    max-height: 20vmin;
    max-width: 100%;
    display: block;
    width: auto;
    margin: auto;
  }
`

type Props = {
  className?: string
}

export const LastCartProduct: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation()
  const product = useSelector(lastAddedProductRecapSelector)

  if (!product || !Object.keys(product).length) {
    return null
  }

  const productThumbnail = product && getProductImageUrl({ path: product.catalogImages.base })
  return (
    <LastProduct className={className} data-testid="lastProduct">
      <h2>{t('ProductPage.lastAddedToCart')}</h2>
      <div className="info">
        <div className="size">
          {product.sizeLabel} {product.size}
        </div>
        <div className="count">{product.quantity}</div>
      </div>

      <ThumbItem
        key={product.mocoCode}
        code={product.mocoCode}
        onClick={() => undefined}
        productThumbnail={productThumbnail}
        modelCode={product.modelCode}
        colorCode={product.colorCode}
        frontFamilyColor={product.frontFamilyColor}
      />
    </LastProduct>
  )
}

export default LastCartProduct
