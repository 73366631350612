import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Appointment } from '../../../model/appointment'
import { BoxTitle, GlassBox, scrollbarCssDe } from '../../../style/DigitalEventsCommonComponents'
import { breakpoints, getFluidFontSize, pxToRem, spacing } from '../../../style/theme'

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
`

const Title = styled(BoxTitle)`
  margin-bottom: ${pxToRem(spacing(2))}rem;
`

const Card = styled(GlassBox)`
  flex: 1;
  overflow: hidden;
  padding: ${pxToRem(spacing(6))}rem;
  padding-right: ${pxToRem(spacing(3))}rem;

  @media (max-width: ${breakpoints.M}) {
    padding: ${pxToRem(spacing(4))}rem;
    padding-right: ${pxToRem(spacing(2))}rem;
  }
`
const CardContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: auto;
  ${scrollbarCssDe};
  padding-right: ${pxToRem(spacing(3))}rem;

  @media (max-width: ${breakpoints.M}) {
    padding-right: ${pxToRem(spacing(2))}rem;
  }
`

const CardTitle = styled.div`
  margin-bottom: ${pxToRem(spacing(3))}rem;
  font-size: ${getFluidFontSize('32px')};
  line-height: 1.25;
`

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const CardBody = styled.div`
  flex: 1;
  font-size: 1.2rem;

  & > pre {
    white-space: pre-wrap;
  }

  @media (max-width: ${breakpoints.S}) {
    font-size: 1rem;
  }
`

interface Props {
  appointment?: Appointment
}

const AppointmentDescription: React.FC<Props> = ({ appointment }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <Title>
        {t(appointment?.appointmentType?.rcLabelKey || 'Appointments.multibrandPresentation')}
      </Title>
      <Card>
        <CardContent>
          <CardHeader>
            <CardTitle>{appointment?.subject}</CardTitle>
          </CardHeader>
          <CardBody>
            <pre>{appointment?.description}</pre>
          </CardBody>
        </CardContent>
      </Card>
    </Container>
  )
}

export default AppointmentDescription
