import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import app_config from '../config/app/config'
import { useSelector } from 'react-redux'
import { customerIdSelector, eventIdSelector } from '../store/app/selectors'
import { EvidenceInitiative } from '../store/initiatives/slice'

export const TAG_INITIATIVES = 'frames-initiatives'

export const initiativesApi = createApi({
  reducerPath: 'initiativesApi',
  tagTypes: [TAG_INITIATIVES],
  baseQuery: staggeredBaseQueryWithBailOut(`${app_config.apiUrl}/initiatives`),
  endpoints: builder => ({
    getInitiatives: builder.query<
      { initiatives: EvidenceInitiative[] },
      { eventId: string; customerId: string }
    >({
      query: ({ eventId, customerId }) => ({
        url: `/event/${eventId}/customer/${customerId}`,
      }),
      providesTags: [TAG_INITIATIVES],
    }),
  }),
})

export const useGetInitiativesQuery = () => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  return initiativesApi.useGetInitiativesQuery(
    { eventId, customerId },
    { skip: !eventId || !customerId },
  )
}
