import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  align,
  BoardType,
  distribute,
  ItemType,
  ResultAndWarningsResponse,
  SelectedSwitchType,
  SORTING_CRITERIA,
  WhiteboardItemType,
  WhiteboardLinks,
  WhiteboardProductType,
  WhiteboardResponseType,
  WhiteboardType,
} from '../../model/whiteboard'
import {
  convertPercentageToPx,
  convertPxToPercentage,
  getItemCenter,
  roundNumber,
} from '../../libs/whiteboard'
import { v4 as uuidv4 } from 'uuid'
import { AfaProduct } from '../../model/afa'
import { breakpointsNoUnit } from '../../style/theme'

/**
 * add_text: indica che è stato aggiunto un testo;
 * text_settings: indica che sono state modificate le impostazioni (posizione, dimensione o z-index) di un testo;
 * add_image: indica che è stata aggiunta un'immagine alla board;
 * image_settings: indica che sono state modificate le impostazioni (posizione, dimensione o z-index) di una foto;
 * add_item: indica che è stato aggiunto un prodotto nella board;
 * item_settings: indica che sono state modificate le impostazioni (posizione, dimensione o z-index) di un prodotto;
 */

type WhiteboardState = {
  selectedSwitch: SelectedSwitchType
  whiteboards: WhiteboardResponseType
  templates: WhiteboardResponseType
  selectedCategory?: string
  previousSelectedCategory?: string
  selectedBoardId?: string
  selectedWhiteboard?: WhiteboardType
  itemsToUnpin: string[]
  boardHistory: BoardType[]
  redoActionsBoard: BoardType[]
  selectedBoardMenu?: string
  openCreateNewWhiteboard: boolean
  openWhiteboardSettings: boolean
  openAddItemsInCart: boolean
  isSettingsModalOpen: boolean
  isDeleteBoardModalOpen: boolean
  multiselectEnabled: boolean
  multiselectActionsEnabled: boolean
  whiteboardHistory: WhiteboardType[]
  redoActionsWhiteboard: WhiteboardType[]
  openPinModal: boolean
  itemsPinModal: AfaProduct[]
  lastPinnedWhiteboard: { name: string; id: string; skuItemPinned: string }
  selectedSkuCode: string
  addedTextId: string
  whiteboardSortCriteria: SORTING_CRITERIA
  previewIsUpdating: boolean
  whiteboardSnapshot: string
  filtersOpen: boolean
  whiteboardsGridFilters: { category: string; filters: string[] }[]
  filteredWhiteboardsLength: number
  openTemplateModal: boolean
  whiteboardFromTemplate: string | null
  imagePlaceholder: boolean
  whiteboardFilters: string[]
  latestSaveWhiteboard: ResultAndWarningsResponse | null
  repeatAction: boolean
  whiteboardLinks: WhiteboardLinks
  selectedItems: (WhiteboardProductType | WhiteboardItemType)[]
  sportFiltersAreLoading: boolean
  categoriesSports: { categoryName: string; categorySports: string[] }[] | null
  sportPromisesAreFulfilled: boolean
  showSelectedAction: number | null
  whiteboardToUpdateId: string
  whiteboardToUpdatePreviewProducts: string
  itemIsBeingAddedToBoard: boolean
  initialWhiteboardState: string
  currentWhiteboardState: string
  initialFirstBoardState: string
  currentFirstBoardState: string
  whiteboardIsWaitingForScreenshot: boolean
}

const DEFAULT_STATE: WhiteboardState = {
  selectedSwitch: 'whiteboard',
  whiteboards: {} as WhiteboardResponseType,
  templates: {} as WhiteboardResponseType,
  //TODO: da cancellare
  redoActionsBoard: [],
  //TODO: da cancellare
  boardHistory: [],
  whiteboardHistory: [],
  redoActionsWhiteboard: [],
  itemsToUnpin: [],
  openCreateNewWhiteboard: false,
  openWhiteboardSettings: false,
  openAddItemsInCart: false,
  isSettingsModalOpen: false,
  isDeleteBoardModalOpen: false,
  multiselectEnabled: false,
  multiselectActionsEnabled: false,
  openPinModal: false,
  itemsPinModal: [],
  lastPinnedWhiteboard: { id: '', name: '', skuItemPinned: '' },
  selectedSkuCode: '',
  addedTextId: '',
  whiteboardSortCriteria: 'date',
  previewIsUpdating: false,
  whiteboardSnapshot: '',
  filtersOpen: false,
  whiteboardsGridFilters: [],
  filteredWhiteboardsLength: 0,
  openTemplateModal: false,
  whiteboardFromTemplate: null,
  imagePlaceholder: false,
  whiteboardFilters: [],
  latestSaveWhiteboard: null,
  repeatAction: false,
  whiteboardLinks: {} as WhiteboardLinks,
  selectedItems: [],
  sportFiltersAreLoading: false,
  categoriesSports: null,
  sportPromisesAreFulfilled: false,
  showSelectedAction: null,
  whiteboardToUpdateId: '',
  whiteboardToUpdatePreviewProducts: '',
  itemIsBeingAddedToBoard: false,
  initialWhiteboardState: '',
  currentWhiteboardState: '',
  initialFirstBoardState: '',
  currentFirstBoardState: '',
  whiteboardIsWaitingForScreenshot: false,
}

export const EMPTY_BOARD = {
  items: [],
  images: [],
  texts: [],
  name: '',
  key: '',
  showProductName: true,
  showProductNumber: true,
  showColorName: false,
  showColorCode: false,
  showWholesale: false,
  showSuggested: false,
  labelPosition: 'bottom',
  showItemInCart: true,
}

const deselectItemsInBoard = (whiteboard: WhiteboardType, selectedBoardId: string) => {
  const currentWhiteboard = JSON.parse(JSON.stringify(whiteboard)) as WhiteboardType

  const selectedCurrentBoard = currentWhiteboard.boards.find(
    board => board.key === selectedBoardId,
  ) as BoardType

  selectedCurrentBoard['items'].forEach(item => (item.selected = false))
  selectedCurrentBoard['texts']?.forEach(item => (item.selected = false))
  selectedCurrentBoard['images']?.forEach(item => (item.selected = false))

  return currentWhiteboard
}

export const slice = createSlice({
  name: 'whiteboard',
  initialState: DEFAULT_STATE,
  reducers: {
    emptyPinnedItemsList: state => {
      state.itemsToUnpin = []
    },
    removePinnedItem: (state, { payload }: PayloadAction<string>) => {
      state.itemsToUnpin.push(payload)
    },
    toggleSelectedItem: (
      state,
      {
        payload,
      }: PayloadAction<{ type: ItemType; item: WhiteboardProductType | WhiteboardItemType }>,
    ) => {
      if (!state.selectedBoardId) return

      const {
        item: { id },
        type,
      } = payload

      const selectedBoard = state.selectedWhiteboard?.boards.find(
        board => board.key === state.selectedBoardId,
      ) as BoardType

      const itemIndex = selectedBoard[type].findIndex(item => item.id === id)
      const selectedItem = selectedBoard[type][itemIndex]

      const itemTypes = ['items', 'texts', 'images']
      const filteredItemTypes = ['items', 'texts', 'images'].filter(itemType => itemType !== type)

      //L'utente ha cliccato su done
      if (state.multiselectActionsEnabled) {
        if (!selectedItem.selected) {
          state.multiselectActionsEnabled = false

          itemTypes.forEach(itemType => {
            const list = selectedBoard[itemType as ItemType] as WhiteboardItemType[]
            list.forEach(item => (item.selected = false))
          })

          state.selectedItems = [{ ...selectedItem, type }]
          selectedItem.selected = true
        }
        return
      }

      //Se la toolbar è aperta su multiselect
      if (state.multiselectEnabled) {
        selectedItem.selected = !selectedItem.selected
        const i = state.selectedItems.findIndex(item => item.id === id)
        if (i > -1) {
          state.selectedItems.splice(i, 1)
        } else {
          state.selectedItems.push({ ...selectedItem, type })
        }
      } else {
        if (selectedItem.selected) {
          selectedItem.selected = false
          const i = state.selectedItems.findIndex(item => item.type === type)
          state.selectedItems.splice(i, 1)
          state.showSelectedAction = null
        } else {
          filteredItemTypes.forEach(itemType => {
            const list = selectedBoard[itemType as ItemType] as WhiteboardItemType[]
            list.forEach(item => (item.selected = false))
          })

          state.selectedItems = [{ ...selectedItem, type }]
          state.showSelectedAction = null

          selectedBoard[type].forEach(item => {
            item.selected = item.id === id
          })
        }
      }
    },
    setIsSettingsModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isSettingsModalOpen = payload
    },
    setIsDeleteBoardModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeleteBoardModalOpen = payload
    },
    setSelectedBoardId: (state, { payload }: PayloadAction<string>) => {
      const selectedBoard = state.selectedWhiteboard?.boards.find(
        board => board.key === state.selectedBoardId,
      ) as BoardType
      selectedBoard.items.forEach(item => (item.selected = false))
      selectedBoard.images.forEach(item => (item.selected = false))
      selectedBoard.texts.forEach(item => {
        item.selected = false
        if (item.isNew) {
          delete item.isNew
        }
      })

      state.selectedBoardId = payload
      state.selectedItems = []
    },
    setSize: (
      state,
      {
        payload,
      }: PayloadAction<
        {
          itemId: string
          width: number
          height: number
          x: number
          y: number
          type: ItemType
          fontSize?: number
        }[]
      >,
    ) => {
      if (!state.selectedBoardId || !state.selectedWhiteboard) return

      const currentWhiteboard = deselectItemsInBoard(
        state.selectedWhiteboard,
        state.selectedBoardId,
      )
      state.whiteboardHistory.push(currentWhiteboard)

      const selectedBoard = state.selectedWhiteboard?.boards.find(
        board => board.key === state.selectedBoardId,
      ) as BoardType

      payload.forEach(itemToUpdate => {
        const { width, itemId, height, type, x, y, fontSize } = itemToUpdate

        const index = selectedBoard[type].findIndex(item => item.id === itemId)

        const selectedItem = selectedBoard[type][index]

        selectedItem.settings = {
          ...selectedItem.settings,
          style: {
            ...selectedItem.settings.style,
            width,
            height,
            fontSize,
          },
          position: {
            x,
            y,
          },
        }
      })

      state.redoActionsBoard = []
    },
    setPosition: (
      state,
      { payload }: PayloadAction<{ itemId: string; x: number; y: number; type: ItemType }[]>,
    ) => {
      if (!state.selectedBoardId || !state.selectedWhiteboard) return

      const currentWhiteboard = deselectItemsInBoard(
        state.selectedWhiteboard,
        state.selectedBoardId,
      )
      state.whiteboardHistory.push(currentWhiteboard)

      const selectedBoard = state.selectedWhiteboard?.boards.find(
        board => board.key === state.selectedBoardId,
      ) as BoardType

      payload.forEach(itemToUpdate => {
        const { itemId, x, y, type } = itemToUpdate

        const index = selectedBoard[type].findIndex(item => item.id === itemId)
        const selectedItem = selectedBoard[type][index]

        selectedItem.settings.position = {
          x,
          y,
        }
      })

      state.redoActionsBoard = []
    },
    setSettings: (
      state,
      {
        payload: {
          showProductName,
          showProductNumber,
          showColorName,
          showColorCode,
          showWholesale,
          showSuggested,
          labelPosition,
          showItemInCart,
        },
      }: PayloadAction<{
        showProductName: boolean
        showProductNumber: boolean
        showColorName: boolean
        showColorCode: boolean
        showWholesale: boolean
        showSuggested: boolean
        labelPosition: string
        showItemInCart: boolean
      }>,
    ) => {
      if (!state.selectedBoardId) return

      const selectedBoard = state.selectedWhiteboard?.boards.find(
        board => board.key === state.selectedBoardId,
      ) as BoardType

      selectedBoard.showProductName = showProductName
      selectedBoard.showProductNumber = showProductNumber
      selectedBoard.showColorName = showColorName
      selectedBoard.showColorCode = showColorCode
      selectedBoard.showWholesale = showWholesale
      selectedBoard.showSuggested = showSuggested
      selectedBoard.labelPosition = labelPosition
      selectedBoard.showItemInCart = showItemInCart
    },
    changeSelectedSwitch: (state, { payload }: PayloadAction<SelectedSwitchType>) => {
      state.selectedSwitch = payload
    },
    setSelectedCategory: (
      state,
      { payload: { category, is4K } }: PayloadAction<{ category: string; is4K?: boolean }>,
    ) => {
      state.selectedCategory =
        state.selectedCategory === category ? (is4K ? category : undefined) : category
    },
    setSelectedWhiteboard: (state, { payload }: PayloadAction<WhiteboardType>) => {
      const selectedWhiteboard = { ...payload }

      if (!selectedWhiteboard.boards?.length) {
        const uniqueKey = uuidv4()
        const defaultBoard = [{ ...EMPTY_BOARD, key: uniqueKey, name: 'Board 1' }]
        selectedWhiteboard.boards = [...defaultBoard]
      }

      state.selectedWhiteboard = {
        ...selectedWhiteboard,
        boards: selectedWhiteboard.boards.map(board => ({
          ...board,
          showItemInCart: true,
        })),
      }

      const selectedBoardIndex = state.selectedWhiteboard.boards?.findIndex(
        ({ key }) => key === state.selectedBoardId,
      )
      const boardIndex = selectedBoardIndex > 0 ? selectedBoardIndex : 0
      state.selectedBoardId = selectedWhiteboard.boards[boardIndex].key
      state.boardHistory.push(selectedWhiteboard.boards[boardIndex])
    },
    deselectWhiteboard: state => {
      state.selectedWhiteboard = undefined
      state.selectedBoardId = undefined
    },
    //Imposta lo z-index degli item selezionati, portando in cima o in fondo in base a...
    setLayer: (state, { payload }: PayloadAction<0 | 1>) => {
      if (!state.selectedBoardId || !state.selectedWhiteboard) return

      const currentWhiteboard = deselectItemsInBoard(
        state.selectedWhiteboard,
        state.selectedBoardId,
      )
      state.whiteboardHistory.push(currentWhiteboard)

      const selectedBoard = state.selectedWhiteboard?.boards.find(
        board => board.key === state.selectedBoardId,
      ) as BoardType

      const zIndex = [
        ...Object.entries(selectedBoard.items).map(([, value]) => value),
        ...Object.entries(selectedBoard.texts).map(([, value]) => value),
        ...Object.entries(selectedBoard.images).map(([, value]) => value),
      ].map(item => item.settings.style.zIndex)

      const maxLayer = Math.max(...zIndex)

      selectedBoard.items
        .filter(item => item.selected)
        .forEach(item => (item.settings.style.zIndex = !payload ? payload : maxLayer + 1))

      selectedBoard.texts
        .filter(item => item.selected)
        .forEach(item => (item.settings.style.zIndex = !payload ? payload : maxLayer + 1))

      selectedBoard.images
        .filter(item => item.selected)
        .forEach(item => (item.settings.style.zIndex = !payload ? payload : maxLayer + 1))

      state.redoActionsBoard = []

      state.boardHistory.push(selectedBoard)
    },
    alignItems: (state, { payload }: PayloadAction<align>) => {
      if (!state.selectedBoardId || !state.selectedWhiteboard) return

      const currentWhiteboard = deselectItemsInBoard(
        state.selectedWhiteboard,
        state.selectedBoardId,
      )
      state.whiteboardHistory.push(currentWhiteboard)

      const selectedBoard = state.selectedWhiteboard?.boards.find(
        board => board.key === state.selectedBoardId,
      ) as BoardType

      const selectedItems = [
        ...selectedBoard.items,
        ...selectedBoard.images,
        ...selectedBoard.texts,
      ].filter(item => item.selected)

      if (payload === 'horizontal center' || payload === 'vertical center') {
        const firstItem = selectedItems[0]
        const center = getItemCenter(firstItem, payload)
        const aligned = selectedItems
          .slice(1)
          .every(item => getItemCenter(item, payload).toFixed(2) === center.toFixed(2))

        if (aligned) {
          return state
        }
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const sortedItems = selectedItems.sort((a, b) => {
        if (payload === 'left') {
          if (a.settings.position.x > b.settings.position.x) return 1
          else if (a.settings.position.x < b.settings.position.x) return -1
          else return 0
        }
        if (payload === 'right') {
          if (
            a.settings.position.x + a.settings.style.width <
            b.settings.position.x + b.settings.style.width
          )
            return 1
          else if (
            a.settings.position.x + a.settings.style.width >
            b.settings.position.x + b.settings.style.width
          )
            return -1
          else return 0
        }
        if (payload === 'top') {
          if (a.settings.position.y > b.settings.position.y) return 1
          else if (a.settings.position.y < b.settings.position.y) return -1
          else return 0
        }
        if (payload === 'bottom') {
          if (
            a.settings.position.y + a.settings.style.height <
            b.settings.position.y + b.settings.style.height
          )
            return 1
          else if (
            a.settings.position.y + a.settings.style.height >
            b.settings.position.y + b.settings.style.height
          )
            return -1
          else return 0
        }
        if (payload === 'horizontal center') {
          if (
            a.settings.position.y + a.settings.style.height >
            b.settings.position.y + b.settings.style.height
          )
            return 1
          else if (
            a.settings.position.y + a.settings.style.height <
            b.settings.position.y + b.settings.style.height
          )
            return -1
          else return 0
        }
        if (payload === 'vertical center') {
          if (
            a.settings.position.x + a.settings.style.width >
            b.settings.position.x + b.settings.style.width
          ) {
            return 1
          } else if (
            a.settings.position.x + a.settings.style.width <
            b.settings.position.x + b.settings.style.width
          ) {
            return -1
          } else {
            return 0
          }
        }
      })
      const firstSelectedItem = sortedItems[0]

      const whiteboardArea = document.getElementById('board') as HTMLElement
      const clientWidth = convertPxToPercentage(whiteboardArea.clientWidth)
      const clientHeight = convertPxToPercentage(whiteboardArea.clientHeight)

      const selectedItem = selectedItems.find(
        item => item.id === firstSelectedItem.id,
      ) as WhiteboardProductType

      const {
        id,
        settings: {
          position: { x, y },
          style,
        },
      } = selectedItem
      const width = style.width
      const height = style.height

      switch (payload) {
        case 'left': {
          if (state.selectedItems.length === 1) {
            firstSelectedItem.settings.position.x = 0
            selectedItem.settings.position.x = 0

            const el = document.getElementById(id) as HTMLElement
            el.setAttribute('starting-x', '0')

            const transform = el.style.transform.match(/(-?\d+(?:\.\d+)?)px, (-?\d+(?:\.\d+)?)px/)

            el.style.transform = `translate(0, ${transform ? `${transform[2]}px` : '0px'})`
          } else {
            state.selectedItems.forEach(item => {
              item.settings.position.x = x
              const currentSelectedItem = selectedItems.find(
                selectedItem => selectedItem.id === item.id,
              ) as WhiteboardItemType
              currentSelectedItem.settings.position.x = x

              const el = document.getElementById(item.id) as HTMLElement
              el.setAttribute('starting-x', `${convertPercentageToPx(x)}`)

              const transform = el.style.transform.match(/(-?\d+(?:\.\d+)?)px, (-?\d+(?:\.\d+)?)px/)
              el.style.transform = `translate(${convertPercentageToPx(x)}px, ${
                transform ? `${transform[2]}px` : 0
              })`
            })
          }
          break
        }
        case 'right': {
          if (state.selectedItems.length === 1) {
            const el = document.getElementById(id) as HTMLElement

            firstSelectedItem.settings.position.x = convertPxToPercentage(
              whiteboardArea.clientWidth - el.clientWidth,
            )
            selectedItem.settings.position.x = convertPxToPercentage(
              whiteboardArea.clientWidth - el.clientWidth,
            )

            el.setAttribute('starting-x', `${whiteboardArea.clientWidth - el.clientWidth}`)
            const transform = el.style.transform.match(/(-?\d+(?:\.\d+)?)px, (-?\d+(?:\.\d+)?)px/)
            el.style.transform = `translate(${whiteboardArea.clientWidth - el.clientWidth}px, ${
              transform ? `${transform[2]}px` : 0
            })`
          } else {
            state.selectedItems.forEach(item => {
              item.settings.position.x = x + width - item.settings.style.width
              const currentSelectedItem = selectedItems.find(
                selectedItem => selectedItem.id === item.id,
              ) as WhiteboardItemType
              currentSelectedItem.settings.position.x = x + width - item.settings.style.width

              const el = document.getElementById(item.id) as HTMLElement
              el.setAttribute(
                'starting-x',
                `${convertPercentageToPx(x + width - item.settings.style.width)}`,
              )

              const transform = el.style.transform.match(/(-?\d+(?:\.\d+)?)px, (-?\d+(?:\.\d+)?)px/)
              el.style.transform = `translate(${convertPercentageToPx(
                x + width - item.settings.style.width,
              )}px, ${transform ? `${transform[2]}px` : 0})`
            })
          }
          break
        }
        case 'horizontal center': {
          if (state.selectedItems.length === 1) {
            firstSelectedItem.settings.position.x =
              clientWidth / 2 - selectedItem.settings.style.width / 2
            selectedItem.settings.position.x =
              clientWidth / 2 - selectedItem.settings.style.width / 2

            const el = document.getElementById(id) as HTMLElement
            const startingX = convertPercentageToPx(
              clientWidth / 2 - selectedItem.settings.style.width / 2,
            )
            el.setAttribute('starting-x', `${startingX}`)

            const transform = el.style.transform.match(/(-?\d+(?:\.\d+)?)px, (-?\d+(?:\.\d+)?)px/)
            el.style.transform = `translate(${startingX}px, ${transform ? `${transform[2]}px` : 0})`
          } else {
            const first = sortedItems[0]
            const last = sortedItems[sortedItems.length - 1]
            const verticalCenter = roundNumber(
              first.settings.position.y +
                (last.settings.position.y +
                  last.settings.style.height -
                  first.settings.position.y) /
                  2,
            )

            state.selectedItems.forEach(item => {
              const newY = Number(
                item.settings.position.y +
                  verticalCenter -
                  (item.settings.position.y + item.settings.style.height / 2),
              )

              const currentSelectedItem = selectedItems.find(
                selectedItem => selectedItem.id === item.id,
              ) as WhiteboardItemType
              currentSelectedItem.settings.position.y = newY

              const el = document.getElementById(item.id) as HTMLElement
              el.setAttribute('starting-y', `${convertPercentageToPx(newY)}`)

              const transform = el.style.transform.match(/(-?\d+(?:\.\d+)?)px, (-?\d+(?:\.\d+)?)px/)
              el.style.transform = `translate(${
                transform ? `${transform[1]}px` : 0
              }, ${convertPercentageToPx(newY)})`
            })
          }
          break
        }
        case 'top': {
          if (state.selectedItems.length === 1) {
            selectedItem.settings.position.y = 0
            firstSelectedItem.settings.position.y = 0

            const el = document.getElementById(id) as HTMLElement
            el.setAttribute('starting-y', `${0}`)

            const transform = el.style.transform.match(/(-?\d+(?:\.\d+)?)px, (-?\d+(?:\.\d+)?)px/)
            el.style.transform = `translate(${transform ? `${transform[1]}px` : 0},  0)`
          } else {
            state.selectedItems.forEach(item => {
              item.settings.position.y = y
              const currentSelectedItem = selectedItems.find(
                selectedItem => selectedItem.id === item.id,
              ) as WhiteboardItemType
              currentSelectedItem.settings.position.y = y

              const el = document.getElementById(item.id) as HTMLElement
              el.setAttribute('starting-y', `${convertPercentageToPx(y)}`)

              const transform = el.style.transform.match(/(-?\d+(?:\.\d+)?)px, (-?\d+(?:\.\d+)?)px/)
              el.style.transform = `translate(${
                transform ? `${transform[1]}px` : 0
              }, ${convertPercentageToPx(y)}px)`
            })
          }
          break
        }
        case 'bottom': {
          if (state.selectedItems.length === 1) {
            const el = document.getElementById(id) as HTMLElement

            selectedItem.settings.position.y = convertPxToPercentage(
              whiteboardArea.clientHeight - el.clientHeight,
            )
            firstSelectedItem.settings.position.y = convertPxToPercentage(
              whiteboardArea.clientHeight - el.clientHeight,
            )

            el.setAttribute('starting-y', `${whiteboardArea.clientHeight - el.clientHeight}`)

            const transform = el.style.transform.match(/(-?\d+(?:\.\d+)?)px, (-?\d+(?:\.\d+)?)px/)
            el.style.transform = `translate(${
              transform ? `${transform[1]}px` : 0
            }, ${whiteboardArea.clientHeight - el.clientHeight}px)`
          } else {
            state.selectedItems.forEach(item => {
              item.settings.position.y = y + height - item.settings.style.height
              const currentSelectedItem = selectedItems.find(
                selectedItem => selectedItem.id === item.id,
              ) as WhiteboardItemType
              currentSelectedItem.settings.position.y = y + height - item.settings.style.height

              const el = document.getElementById(item.id) as HTMLElement
              el.setAttribute(
                'starting-y',
                `${convertPercentageToPx(y + height - item.settings.style.height)}`,
              )

              const transform = el.style.transform.match(/(-?\d+(?:\.\d+)?)px, (-?\d+(?:\.\d+)?)px/)
              el.style.transform = `translate(${
                transform ? `${transform[1]}px` : 0
              }, ${convertPercentageToPx(y + height - item.settings.style.height)}px)`
            })
          }
          break
        }
        case 'vertical center': {
          if (state.selectedItems.length === 1) {
            firstSelectedItem.settings.position.y =
              clientHeight / 2 - firstSelectedItem.settings.style.height / 2
            selectedItem.settings.position.y =
              clientHeight / 2 - firstSelectedItem.settings.style.height / 2

            const el = document.getElementById(id) as HTMLElement
            el.setAttribute(
              'starting-y',
              `${convertPercentageToPx(
                clientHeight / 2 - firstSelectedItem.settings.style.height / 2,
              )}`,
            )

            const transform = el.style.transform.match(/(-?\d+(?:\.\d+)?)px, (-?\d+(?:\.\d+)?)px/)
            el.style.transform = `translate(${
              transform ? `${transform[1]}px` : 0
            }, ${convertPercentageToPx(
              clientHeight / 2 - firstSelectedItem.settings.style.height / 2,
            )}px)`
          } else {
            const first = sortedItems[0]
            const last = sortedItems[sortedItems.length - 1]
            const horizontalCenter = roundNumber(
              first.settings.position.x +
                (last.settings.position.x + last.settings.style.width - first.settings.position.x) /
                  2,
            )

            state.selectedItems.forEach(item => {
              const delta = roundNumber(
                horizontalCenter - (2 * item.settings.position.x + item.settings.style.width) / 2,
              )
              const newX = roundNumber(item.settings.position.x + delta)
              item.settings.position.x = newX

              const currentSelectedItem = selectedItems.find(
                selectedItem => selectedItem.id === item.id,
              ) as WhiteboardItemType
              currentSelectedItem.settings.position.x = newX

              const el = document.getElementById(item.id) as HTMLElement
              el.setAttribute('starting-x', `${convertPercentageToPx(newX)}`)

              const transform = el.style.transform.match(/(-?\d+(?:\.\d+)?)px, (-?\d+(?:\.\d+)?)px/)
              el.style.transform = `translate(${convertPercentageToPx(newX)}, ${
                transform ? `${transform[2]}px` : 0
              })`
            })
            /*            state.selectedItems.forEach(item => {
              item.settings.position.y = y + height / 2 - item.settings.style.height / 2
              const currentSelectedItem = selectedItems.find(
                selectedItem => selectedItem.id === item.id,
              ) as WhiteboardItemType
              currentSelectedItem.settings.position.y =
                y + height / 2 - item.settings.style.height / 2

              const el = document.getElementById(item.id) as HTMLElement
              el.setAttribute(
                'starting-y',
                `${convertPercentageToPx(y + height / 2 - item.settings.style.height / 2)}`,
              )

              const transform = el.style.transform.match(/(-?\d+(?:\.\d+)?)px, (-?\d+(?:\.\d+)?)px/)
              el.style.transform = `translate(${
                transform ? `${transform[1]}px` : 0
              }, ${convertPercentageToPx(y + height / 2 - item.settings.style.height / 2)}px)`
            })*/
          }
          break
        }
      }

      state.redoActionsBoard = []

      state.boardHistory.push(selectedBoard)
    },
    //Distribuzione con spaziatura omogenea degli interstizi
    distributeItems: (state, { payload }: PayloadAction<distribute>) => {
      if (!state.selectedBoardId || !state.selectedWhiteboard) return

      const currentWhiteboard = deselectItemsInBoard(
        state.selectedWhiteboard,
        state.selectedBoardId,
      )
      state.whiteboardHistory.push(currentWhiteboard)

      const selectedBoard = state.selectedWhiteboard?.boards.find(
        board => board.key === state.selectedBoardId,
      ) as BoardType

      //const selectedItems = selectedBoard.items.filter(item => item.selected)
      const selectedItems = [
        ...selectedBoard.items,
        ...selectedBoard.images,
        ...selectedBoard.texts,
      ].filter(item => item.selected)

      if (selectedItems.length <= 2) return

      const items = selectedItems.length

      switch (payload) {
        case 'horizontally': {
          const sortedItems = selectedItems.sort(
            (a, b) => a.settings.position.x - b.settings.position.x,
          )
          const rightItem = sortedItems.pop() as WhiteboardProductType
          const leftItem = sortedItems.shift() as WhiteboardProductType
          const x1 = leftItem.settings.position.x + leftItem.settings.style.width / 2
          const x2 = rightItem.settings.position.x + rightItem.settings.style.width / 2
          const delta = (x2 - x1) / (items - 1)

          sortedItems.forEach(
            (item, i) =>
              (item.settings.position.x =
                item.settings.position.x + delta * (i + 1) - item.settings.style.width / 2),
          )

          break
        }
        case 'vertically': {
          const sortedItems = selectedItems.sort(
            (a, b) => a.settings.position.y - b.settings.position.y,
          )
          const bottomItem = sortedItems.pop() as WhiteboardProductType
          const topItem = sortedItems.shift() as WhiteboardProductType
          const y1 = topItem.settings.position.y + topItem.settings.style.height / 2
          const y2 = bottomItem.settings.position.y + bottomItem.settings.style.height / 2
          const delta = (y2 - y1) / (items - 1)

          sortedItems.forEach(
            (item, i) =>
              (item.settings.position.y = y1 + delta * (i + 1) - item.settings.style.height / 2),
          )

          break
        }
      }

      state.redoActionsBoard = []

      state.boardHistory.push(selectedBoard)
    },
    //Distribuzione con spaziatura omogenea dai centri degli item
    distribute: (state, { payload }: PayloadAction<distribute>) => {
      if (!state.selectedBoardId || !state.selectedWhiteboard) return

      const currentWhiteboard = deselectItemsInBoard(
        state.selectedWhiteboard,
        state.selectedBoardId,
      )
      state.whiteboardHistory.push(currentWhiteboard)

      const selectedBoard = state.selectedWhiteboard?.boards.find(
        board => board.key === state.selectedBoardId,
      ) as BoardType

      const selectedItems = [
        ...selectedBoard.items,
        ...selectedBoard.images,
        ...selectedBoard.texts,
      ].filter(item => item.selected)

      if (selectedItems.length <= 2) return

      const items = selectedItems.length
      let previousItemId = -1
      let type: ItemType

      switch (payload) {
        case 'horizontally': {
          const sortedItems = selectedItems.sort(
            (a, b) => a.settings.position.x - b.settings.position.x,
          )
          const itemsToDistribute = [...sortedItems]
          const leftItem = itemsToDistribute.shift() as WhiteboardProductType
          const rightItem = itemsToDistribute.pop() as WhiteboardProductType
          const x1 = leftItem.settings.position.x + leftItem.settings.style.width
          const x2 = rightItem.settings.position.x
          const totalWidth = itemsToDistribute.reduce(
            (sum, item) => sum + item.settings.style.width,
            0,
          )
          const delta = (x2 - x1 - totalWidth) / (items - 1)

          itemsToDistribute.forEach((item, i) => {
            if (selectedBoard.items.findIndex(item => item.id === sortedItems[i].id) > -1) {
              type = 'items'
              previousItemId = selectedBoard.items.findIndex(item => item.id === sortedItems[i].id)
            }
            if (selectedBoard.texts.findIndex(item => item.id === sortedItems[i].id) > -1) {
              type = 'texts'
              previousItemId = selectedBoard.texts.findIndex(item => item.id === sortedItems[i].id)
            }
            if (selectedBoard.images.findIndex(item => item.id === sortedItems[i].id) > -1) {
              type = 'images'
              previousItemId = selectedBoard.images.findIndex(item => item.id === sortedItems[i].id)
            }

            const x =
              selectedBoard[type as ItemType][previousItemId].settings.position.x +
              selectedBoard[type as ItemType][previousItemId].settings.style.width +
              delta

            item.settings.position.x = x

            const el = document.getElementById(item.id) as HTMLElement
            el.setAttribute('starting-x', `${convertPercentageToPx(x)}`)
          })

          break
        }
        case 'vertically': {
          const sortedItems = selectedItems.sort(
            (a, b) => a.settings.position.y - b.settings.position.y,
          )
          const itemsToDistribute = [...sortedItems]
          const topItem = itemsToDistribute.shift() as WhiteboardProductType
          const bottomItem = itemsToDistribute.pop() as WhiteboardProductType
          const y1 = topItem.settings.position.y + topItem.settings.style.height
          const y2 = bottomItem.settings.position.y
          const totalHeight = itemsToDistribute.reduce(
            (sum, item) => sum + item.settings.style.height,
            0,
          )
          const delta = (y2 - y1 - totalHeight) / (items - 1)

          itemsToDistribute.forEach((item, i) => {
            if (
              selectedBoard.items.findIndex(selectedItem => selectedItem.id === sortedItems[i].id) >
              -1
            ) {
              type = 'items'
              previousItemId = sortedItems.findIndex(selectedItem => selectedItem.id === item.id)
            }
            if (
              selectedBoard.texts.findIndex(selectedItem => selectedItem.id === sortedItems[i].id) >
              -1
            ) {
              type = 'texts'
              previousItemId = sortedItems.findIndex(selectedItem => selectedItem.id === item.id)
            }
            if (
              selectedBoard.images.findIndex(
                selectedItem => selectedItem.id === sortedItems[i].id,
              ) > -1
            ) {
              type = 'images'
              previousItemId = sortedItems.findIndex(selectedItem => selectedItem.id === item.id)
            }

            const y =
              sortedItems[previousItemId - 1].settings.position.y +
              sortedItems[previousItemId - 1].settings.style.height +
              delta

            /*            const y =
              selectedBoard[type as ItemType][previousItemId].settings.position.y +
              selectedBoard[type as ItemType][previousItemId].settings.style.height +
              delta*/

            item.settings.position.y = y

            const el = document.getElementById(item.id) as HTMLElement
            el.setAttribute('starting-y', `${convertPercentageToPx(y)}`)
          })

          break
        }
      }

      state.redoActionsBoard = []

      state.boardHistory.push(selectedBoard)
    },
    addItemToBoard: (state, { payload }: PayloadAction<WhiteboardProductType>) => {
      if (!state.selectedBoardId || !state.selectedWhiteboard) return

      const currentWhiteboard = deselectItemsInBoard(
        state.selectedWhiteboard,
        state.selectedBoardId,
      )
      state.whiteboardHistory.push(currentWhiteboard)

      const selectedBoard = state.selectedWhiteboard?.boards.find(
        board => board.key === state.selectedBoardId,
      ) as BoardType

      selectedBoard.items.push(payload)

      state.redoActionsBoard = []

      state.boardHistory.push(selectedBoard)
    },
    removeItemsFromBoard: state => {
      if (!state.selectedBoardId || !state.selectedWhiteboard) return

      const selectedBoardId = state.selectedWhiteboard?.boards.findIndex(
        board => board.key === state.selectedBoardId,
      )

      const selectedBoard = state.selectedWhiteboard.boards[selectedBoardId] as BoardType

      const selectedItems = selectedBoard.items
        .filter(item => item.selected)
        .reduce((res, item) => {
          res[item.id] = true
          return res
        }, {} as Record<string, boolean>)

      selectedBoard.items.forEach(item => (item.selected = false))

      const currentWhiteboard = deselectItemsInBoard(
        state.selectedWhiteboard,
        state.selectedBoardId,
      )
      state.whiteboardHistory.push(currentWhiteboard)

      selectedBoard.items = selectedBoard.items.filter(item => {
        return !selectedItems[item.id]
      })

      if (selectedBoard.texts) {
        const selectedTexts = selectedBoard.texts
          .filter(item => item.selected)
          .reduce((res, item) => {
            res[item.id] = true
            return res
          }, {} as Record<string, boolean>)

        selectedBoard.texts = selectedBoard.texts.filter(item => {
          return !selectedTexts[item.id]
        })
      }

      if (selectedBoard.images) {
        const selectedImages = selectedBoard.images
          .filter(item => item.selected)
          .reduce((res, item) => {
            res[item.id] = true
            return res
          }, {} as Record<string, boolean>)

        selectedBoard.images = selectedBoard.images.filter(item => {
          return !selectedImages[item.id]
        })
      }

      state.showSelectedAction = null
      state.selectedWhiteboard.boards[selectedBoardId] = selectedBoard
      //state.redoActionsWhiteboard = []
    },
    undoAction: state => {
      if (!state.whiteboardHistory.length || !state.selectedWhiteboard) return

      const selectedBoardIndex = state.selectedWhiteboard.boards.findIndex(
        board => board.key === state.selectedBoardId,
      )

      if (selectedBoardIndex === -1) return

      state.redoActionsWhiteboard.push(JSON.parse(JSON.stringify(state.selectedWhiteboard)))
      state.selectedWhiteboard = state.whiteboardHistory.pop() as WhiteboardType
    },
    redoAction: state => {
      if (!state.redoActionsWhiteboard.length || !state.selectedWhiteboard) return

      const selectedBoardIndex = state.selectedWhiteboard.boards.findIndex(
        board => board.key === state.selectedBoardId,
      )

      state.whiteboardHistory.push(JSON.parse(JSON.stringify(state.selectedWhiteboard)))

      const newWhiteboard = state.redoActionsWhiteboard.pop() as WhiteboardType
      newWhiteboard.boards[selectedBoardIndex]?.items.forEach(item => (item.selected = false))

      state.selectedWhiteboard = newWhiteboard
    },
    setOpenCreateNewWhiteboard: (state, { payload }: PayloadAction<boolean>) => {
      state.openCreateNewWhiteboard = payload
    },
    setOpenWhiteboardSettings: (state, { payload }: PayloadAction<boolean>) => {
      state.openWhiteboardSettings = payload
    },
    setOpenPinModal: (state, { payload }: PayloadAction<boolean>) => {
      state.openPinModal = payload
    },
    setSelectedBoardMenu: (state, { payload }: PayloadAction<string>) => {
      state.selectedBoardMenu = state.selectedBoardMenu === payload ? undefined : payload
    },
    setOpenAddItemsInCart: (state, { payload }: PayloadAction<boolean>) => {
      state.openAddItemsInCart = payload
    },
    removeBoard: (state, { payload }: PayloadAction<string>) => {
      if (state.selectedWhiteboard) {
        const selectedBoard = state.selectedWhiteboard.boards.find(
          ({ key }) => key === state.selectedBoardId,
        )
        const boardToDeleteIndex = state.selectedWhiteboard.boards.findIndex(
          board => board.key === payload,
        )
        const boards = state.selectedWhiteboard.boards.filter(({ key }) => key !== payload)
        state.selectedWhiteboard.boards = boards

        if (selectedBoard?.key === payload && boards.length) {
          state.selectedBoardId =
            state.selectedWhiteboard.boards[boardToDeleteIndex - 1]?.key ||
            state.selectedWhiteboard.boards[boardToDeleteIndex].key
        }
      }
    },
    cloneBoard: (state, { payload }: PayloadAction<string>) => {
      if (state.selectedWhiteboard) {
        const boardToClone = state.selectedWhiteboard.boards.find(
          ({ key }) => key === payload,
        ) as BoardType
        const uniqueKey = uuidv4()
          .substring(0, 32)
          .toUpperCase()

        state.selectedWhiteboard.boards.push({
          ...boardToClone,
          key: uniqueKey,
          name: boardToClone.name,
          isNew: true,
        })

        state.selectedBoardId = uniqueKey
      }
    },
    editBoardName: (state, { payload }: PayloadAction<{ boardKey: string; newName: string }>) => {
      if (state.selectedWhiteboard) {
        const board = state.selectedWhiteboard.boards.find(
          ({ key }) => key === payload.boardKey,
        ) as BoardType
        board.name = payload.newName
        delete board.isNew
      }
    },
    createBoard: state => {
      if (state.selectedWhiteboard) {
        const uniqueKey = uuidv4()
          .substring(0, 32)
          .toUpperCase()

        state.selectedWhiteboard.boards.push({
          ...EMPTY_BOARD,
          key: uniqueKey,
          name: null,
          isNew: true,
        })

        state.selectedBoardId = uniqueKey
      }
    },
    addTextToBoard: state => {
      if (!state.selectedBoardId || !state.selectedWhiteboard) return

      const currentWhiteboard = deselectItemsInBoard(
        state.selectedWhiteboard,
        state.selectedBoardId,
      )
      state.whiteboardHistory.push(currentWhiteboard)

      const text: WhiteboardItemType = {
        id: uuidv4(),
        selected: false,
        settings: {
          position: {
            x: (100 - convertPxToPercentage(210)) / 2,
            y: convertPxToPercentage(50),
          },
          style: {
            fontSize: convertPxToPercentage(screen.width >= breakpointsNoUnit.XL ? 32 : 14),
            zIndex: 0,
            width: convertPxToPercentage(screen.width >= breakpointsNoUnit.XL ? 400 : 210),
            height: convertPxToPercentage(screen.width >= breakpointsNoUnit.XL ? 94 : 44),
          },
        },
        value: '',
        type: 'texts',
        isNew: true,
      }

      const selectedBoard = state.selectedWhiteboard?.boards.find(
        board => board.key === state.selectedBoardId,
      ) as BoardType

      if (!selectedBoard.texts) {
        selectedBoard.texts = []
      }

      selectedBoard.texts.push(text)

      state.redoActionsBoard = []

      state.boardHistory.push(selectedBoard)

      state.addedTextId = text.id
    },
    enableMultiselect: state => {
      state.multiselectEnabled = !state.multiselectEnabled
    },
    enableMultiselectActions: state => {
      state.showSelectedAction = null
      state.multiselectActionsEnabled = !state.multiselectActionsEnabled
    },
    deselectAllItems: state => {
      if (!state.selectedBoardId) return

      const selectedBoard = state.selectedWhiteboard?.boards.find(
        board => board.key === state.selectedBoardId,
      ) as BoardType

      selectedBoard['items'].forEach(item => (item.selected = false))
      selectedBoard['texts']?.forEach(item => (item.selected = false))
      selectedBoard['images']?.forEach(item => (item.selected = false))

      state.selectedItems = []

      state.multiselectEnabled = false
      state.multiselectActionsEnabled = false
    },
    setItemsPinModal: (state, { payload }: PayloadAction<AfaProduct[]>) => {
      state.itemsPinModal = payload
    },
    resetItemsPinModal: state => {
      if (!state.openPinModal) {
        state.itemsPinModal = []
      }
    },
    setLastPinnedWhiteboard: (
      state,
      { payload }: PayloadAction<{ id: string; name: string; skuItemPinned: string }>,
    ) => {
      state.lastPinnedWhiteboard = payload
    },
    changeTextItemValue: (
      state,
      { payload: { item } }: PayloadAction<{ item: WhiteboardItemType }>,
    ) => {
      if (!state.selectedBoardId) return

      const selectedBoard = state.selectedWhiteboard?.boards.find(
        board => board.key === state.selectedBoardId,
      ) as BoardType

      const selectedText = selectedBoard.texts.find(
        text => text.id === item.id,
      ) as WhiteboardItemType

      if (selectedText) {
        selectedText.value = item.value
        selectedText.settings.style.height = item.settings.style.height
      }
    },
    setSelectedSkuCode: (state, { payload }: PayloadAction<string>) => {
      state.selectedSkuCode = payload
    },
    setWhiteboardSortingCriteria: (state, { payload }: PayloadAction<SORTING_CRITERIA>) => {
      state.whiteboardSortCriteria = payload
    },
    addImageToBoard: (
      state,
      {
        payload: { URL, width, height },
      }: PayloadAction<{
        URL: string
        width: number
        height: number
      }>,
    ) => {
      if (!state.selectedBoardId || !state.selectedWhiteboard) return

      const ratio = width / height

      const currentWhiteboard = deselectItemsInBoard(
        state.selectedWhiteboard,
        state.selectedBoardId,
      )
      state.whiteboardHistory.push(currentWhiteboard)

      const image: WhiteboardItemType = {
        id: uuidv4(),
        selected: false,
        settings: {
          position: {
            x: (100 - convertPxToPercentage(210)) / 2,
            y: convertPxToPercentage(50),
          },
          style: {
            zIndex: 0,
            width: convertPxToPercentage(210),
            height: convertPxToPercentage(210 / ratio),
          },
        },
        value: URL,
        type: 'images',
      }

      const selectedBoard = state.selectedWhiteboard?.boards.find(
        board => board.key === state.selectedBoardId,
      ) as BoardType

      if (!selectedBoard.images) {
        selectedBoard.images = []
      }

      selectedBoard.images.push(image)

      state.redoActionsBoard = []

      state.boardHistory.push(selectedBoard)

      state.imagePlaceholder = false
    },
    setPreviewIsUpdating: (state, { payload }: PayloadAction<boolean>) => {
      state.previewIsUpdating = payload
    },
    setWhiteboardSnapshot: (state, { payload }: PayloadAction<string>) => {
      state.whiteboardSnapshot = payload
    },
    toggleFiltersOpen: state => {
      state.filtersOpen = !state.filtersOpen
    },
    setWhiteboardsGridFilters: (
      state,
      { payload }: PayloadAction<{ selectedCategory: string; filter: string }>,
    ) => {
      const { selectedCategory, filter } = payload

      const category = state.whiteboardsGridFilters?.find(
        ({ category }) => category === selectedCategory,
      )
      if (category) {
        const filtersSet = new Set(category.filters)
        if (filtersSet.has(filter)) {
          filtersSet.delete(filter)
        } else {
          filtersSet.add(filter)
        }
        category.filters = Array.from(filtersSet)
      } else {
        state.whiteboardsGridFilters.push({ category: selectedCategory, filters: [filter] })
      }
    },
    resetWhiteboardsGridFilters: state => {
      state.whiteboardsGridFilters = []
    },
    setFilteredWhiteboardsLength: (state, { payload }: PayloadAction<number>) => {
      state.filteredWhiteboardsLength = payload
    },
    setTemplates: (state, { payload }: PayloadAction<WhiteboardResponseType>) => {
      state.templates = payload
    },
    setWhiteboards: (state, { payload }: PayloadAction<WhiteboardResponseType>) => {
      state.whiteboards = payload
    },
    setOpenTemplateModal: (state, { payload }: PayloadAction<boolean>) => {
      state.openTemplateModal = payload
    },
    setWhiteboardFromTemplate: (state, { payload }: PayloadAction<string | null>) => {
      state.whiteboardFromTemplate = payload
    },
    showImagePlaceholder: state => {
      state.imagePlaceholder = true
    },
    setWBFilters: (state, { payload }: PayloadAction<string[]>) => {
      state.whiteboardFilters = payload
    },
    resetWBFilters: state => {
      state.whiteboardFilters = []
    },
    setLatestSaveWhiteboard: (
      state,
      { payload }: PayloadAction<ResultAndWarningsResponse | null>,
    ) => {
      state.latestSaveWhiteboard = payload
    },
    setRepeatAction: (state, { payload }: PayloadAction<boolean>) => {
      state.repeatAction = payload
    },
    setWhiteboardLinks: (state, { payload }: PayloadAction<WhiteboardLinks>) => {
      state.whiteboardLinks = payload
    },
    setSportFiltersAreLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.sportFiltersAreLoading = payload
    },
    setCategoriesSports: (
      state,
      { payload }: PayloadAction<{ categoryName: string; categorySports: string[] }[]>,
    ) => {
      state.categoriesSports = payload
    },
    setSportPromisesAreFulfilled: (state, { payload }: PayloadAction<boolean>) => {
      state.sportPromisesAreFulfilled = payload
    },
    setShowSelectedAction: (state, { payload }: PayloadAction<number>) => {
      state.showSelectedAction = payload
    },
    setWhiteboardToUpdateId: (state, { payload }: PayloadAction<string>) => {
      state.whiteboardToUpdateId = payload
    },
    setWhiteboardToUpdatePreviewProducts: (state, { payload }: PayloadAction<string>) => {
      state.whiteboardToUpdatePreviewProducts = payload
    },
    setItemIsBeingAddedToTheBoard: (state, { payload }: PayloadAction<boolean>) => {
      state.itemIsBeingAddedToBoard = payload
    },
    setInitialWhiteboardState: (state, { payload }: PayloadAction<string>) => {
      state.initialWhiteboardState = payload
    },
    setCurrentWhiteboardState: (state, { payload }: PayloadAction<string>) => {
      state.currentWhiteboardState = payload
    },
    setInitialFirstBoardState: (state, { payload }: PayloadAction<string>) => {
      state.initialFirstBoardState = payload
    },
    setCurrentFirstBoardState: (state, { payload }: PayloadAction<string>) => {
      state.currentFirstBoardState = payload
    },
    setWhiteboardIsWaitingForScreenshot: (state, { payload }: PayloadAction<boolean>) => {
      state.whiteboardIsWaitingForScreenshot = payload
    },
  },
})

export default slice.reducer
