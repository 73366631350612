import i18next from 'i18next'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  deleteIntersessionAuthToken,
  getIntersessionAuthToken,
  setIntersessionAuthToken,
} from '../../libs/auth'
import { AuthState } from '../../model/model'
import { resetForNewClient } from '../extraActions'

const DEFAULT_STATE: AuthState = {
  token: '',
  error: null,
}

export const slice = createSlice({
  name: 'auth',
  initialState: DEFAULT_STATE,
  reducers: {
    initSession: state => {
      state.token = getIntersessionAuthToken()
    },
    loginFailed: (state, { payload }: PayloadAction<string>) => {
      deleteIntersessionAuthToken()
      state.token = ''
      state.error = payload
    },
    loggedIn: (state, { payload }: PayloadAction<string>) => {
      i18next.reloadResources() // Reload locales after login
      setIntersessionAuthToken(payload)
      state.token = payload
      state.error = null
    },
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, state => {
      const token = state.token
      return {
        ...DEFAULT_STATE,
        token: token,
      }
    })
  },
})

export default slice.reducer
