import { ContentAPI, getAppointmentsApi, JoinAppointmentApi } from '../../api/restApi'
import { AppointmentApi } from '../../model/appointment'
import { SingleBrandPageContents } from '../../model/content'
import { AppLangCode, AppThunk } from '../../model/model'
import {
  cmsTagSelector,
  customerIdSelector,
  eventIdSelector,
  languageSelector,
  languagesSelector,
} from '../app/selectors'
import { tokenSelector } from '../auth/selectors'
import { getBrands } from '../brands/actions'
import { customerNationalitySelector } from '../customer/selectors'
import { slice } from './slice'
import { handleErrors, logCmsError } from '../../libs/handleError'
import { isObjectEmptyOrWithUndefined } from '../../libs/object'

const formatLanguage = (language: AppLangCode) => (language.includes('en-') ? 'en' : language)

const contentApi = new ContentAPI()

export const getDigitalEventsBackgrounds = ({
  language,
  country,
  cmsTag,
}: {
  language: AppLangCode
  country: string
  cmsTag?: string
}): AppThunk => dispatch => {
  if (cmsTag && country) {
    contentApi
      .getDigitalEventsBackgrounds({ cmsTag, country, language: formatLanguage(language) })
      .then(contents => {
        if (isObjectEmptyOrWithUndefined(contents)) {
          logCmsError({
            message: 'Missing contents',
            operationNamespace: 'Digital Events Backgrounds',
          })
        }
        dispatch(slice.actions.setDigitalEventsBackgrounds(contents))
      })
      .catch(handleErrors)
  }
}

const getDigitalEventsAppointmentTilesBackground = (cmsTag: string, country: string): AppThunk => (
  dispatch,
  getState,
) => {
  const state = getState()
  const language = languageSelector(state)

  if (language && country && cmsTag) {
    contentApi
      .getAppointmentTilesBackground({
        language: formatLanguage(language),
        country,
        cmsTag,
      })
      .then(contents => {
        if (isObjectEmptyOrWithUndefined(contents)) {
          logCmsError({
            message: 'Missing contents',
            operationNamespace: 'Appointment Tiles Backgrounds',
          })
        }
        dispatch(slice.actions.setDigitalEventsAppointmentTileBackground(contents))
      })
      .catch(handleErrors)
  }
}

export const getInitiatives = ({
  language,
  country,
  cmsTag,
}: {
  language: AppLangCode
  country: string
  cmsTag?: string
}): AppThunk => dispatch => {
  if (language && country && cmsTag) {
    contentApi
      .getInitiatives({ cmsTag, language: formatLanguage(language), country })
      .then(initiatives => {
        if (isObjectEmptyOrWithUndefined(initiatives)) {
          logCmsError({
            message: 'Missing contents',
            operationNamespace: 'Initiatives',
          })
        }
        dispatch(slice.actions.setInitiatives(initiatives))
      })
      .catch(handleErrors)
  }
}

export const getSingleBrandPageContents = (brandCode: string): AppThunk => (dispatch, getState) => {
  const state = getState()
  const language = languageSelector(state)
  const cmsTag = cmsTagSelector(state)
  const country = customerNationalitySelector(state)

  if (language && country && cmsTag) {
    contentApi
      .getSingleBrandPageContents({
        brandCode,
        cmsTag,
        country,
        language: formatLanguage(language),
      })
      .then(contents => {
        if (isObjectEmptyOrWithUndefined(contents)) {
          logCmsError({
            message: 'Missing contents',
            operationNamespace: 'Single Brand Page Contents',
          })
        }
        const languages = languagesSelector(state)
        const contentWithSubtitlesDisplayFix = Object.entries(contents).reduce(
          (result, [brand, content]) => {
            result[brand] = {
              ...content,
              videoShoppable: content.videoShoppable.map(videoData => ({
                ...videoData,
                subtitles: videoData.subtitles.map(track => {
                  const langItem = languages.find(language =>
                    track.title.toLocaleLowerCase().endsWith(language.code.toLocaleLowerCase()),
                  )

                  return {
                    ...track,
                    display: langItem ? langItem.name : track.title,
                  }
                }),
              })),
            }
            return result
          },
          {} as SingleBrandPageContents,
        )
        dispatch(slice.actions.setSingleBrandPageContents(contentWithSubtitlesDisplayFix))
      })
      .catch(handleErrors)
  }
}

export const getAppointments = ({
  language,
  cmsTag,
  country,
}: {
  language: AppLangCode
  cmsTag: string
  country: string
}): AppThunk => (dispatch, getState) => {
  const state = getState()
  const authToken = tokenSelector(state) || ''
  const eventId = eventIdSelector(state)
  const customerId = customerIdSelector(state)

  dispatch(getDigitalEventsAppointmentTilesBackground(cmsTag, country))

  getAppointmentsApi({
    authToken,
    language,
    eventId,
    customerId,
  })
    .then((appointments: AppointmentApi[]) => {
      if (isObjectEmptyOrWithUndefined(appointments)) {
        logCmsError({
          message: 'Missing contents',
          operationNamespace: 'Appointment Tiles Backgrounds',
        })
      }
      dispatch(slice.actions.setAppointments(appointments))
    })
    .catch(handleErrors)
}

export const joinAppointment = (appointmentId: number, contactId: string): AppThunk => (
  dispatch,
  getState,
) => {
  const state = getState()
  const authToken = tokenSelector(state) || ''
  const language = languageSelector(state)
  const customerId = customerIdSelector(state)
  const eventId = eventIdSelector(state)

  return JoinAppointmentApi({ authToken, appointmentId, contactId, language }).then(
    ({ result, teamsLink }) => {
      if (result && teamsLink) {
        dispatch(
          slice.actions.setAppointmentLink({ appointmentId: appointmentId, link: teamsLink }),
        )
        dispatch(getBrands(customerId, eventId))
        window.open(teamsLink)
      }
    },
  )
}

export const selectedAppointmentDate = (selectedAppointmentDate: string): AppThunk => dispatch => {
  dispatch(slice.actions.setSelectedAppointmentDate(selectedAppointmentDate))
}

export const setDigitalEventsBackgrounds = slice.actions.setDigitalEventsBackgrounds
export const setDigitalEventsAppointmentTileBackground =
  slice.actions.setDigitalEventsAppointmentTileBackground
export const setInitiatives = slice.actions.setInitiatives
export const setAppointments = slice.actions.setAppointments
export const setAppointmentLink = slice.actions.setAppointmentLink
export const setSelectedAppointmentDate = slice.actions.setSelectedAppointmentDate

export default {
  ...slice.actions,
  getDigitalEventsBackgrounds,
  getInitiatives,
  getSingleBrandPageContents,
  getAppointments,
  joinAppointment,
  selectedAppointmentDate,
}
