import styled, { css } from 'styled-components/macro'
import { productsListWidth } from '../../../../../model/whiteboard'
import {
  breakpointsCross,
  getFluidSizeWithFullFormula as gF,
  palette,
  pxToRem,
} from '../../../../../style/theme'
import { ButtonCancel, ButtonsGroup } from '../../../../../components/AfaCommonComponents'

export const Wrapper = styled.div<{ open?: string }>`
  background-color: white;
  overflow: hidden;
  padding: ${gF('px', 24, 32, 1366, 3840)};
  padding-bottom: ${gF('px', 24, 51, 1366, 3840)};
  padding-top: ${gF('px', 24, 64, 1366, 3840)};
  transition: 0.3s margin-left linear;
  flex-shrink: 0;
  z-index: 10000;
  top: 0;
  box-shadow: 3px 0 6px 0 rgba(3, 20, 52, 0.17);
  display: grid;
  grid-auto-rows: 1fr min-content;
  position: relative;

  @media screen and (max-width: ${breakpointsCross.XL.max}) {
    margin-left: -${productsListWidth}px;

    ${({ open }) =>
      open &&
      css`
        margin-left: 0 !important;
      `}
  }

  @media screen and (min-width: ${breakpointsCross.XL.min}) {
    margin-left: 0;
  }
`

export const CategoryWrapper = styled.div`
  margin-bottom: ${gF('px', 40, 72, 1366, 3840)};
`

export const ViewAll = styled.span`
  font-family: GilmerMedium, sans-serif;
  font-size: ${pxToRem(24)}rem;
  letter-spacing: 0.3px;
  white-space: nowrap;
  color: ${palette.tangaroa};
  align-self: flex-end;
  margin-left: -${pxToRem(40)}rem;
  cursor: pointer;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: ${gF('px', 30, 40, 1366, 3840)};
  row-gap: ${gF('px', 60, 90, 1366, 3840)};

  &.families {
    grid-template-columns: repeat(3, minmax(0, 1fr)) min-content !important;
  }

  .rc-icon {
    width: ${gF('px', 24, 36, 1366, 3840)};
    height: ${gF('px', 24, 36, 1366, 3840)};
    align-self: center;
    justify-self: flex-end;
    cursor: pointer;
  }
`

export const PinnedGrid = styled.div<{ edit: boolean }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${gF('px', 16, 32, 1366, 3840)};
`

export const checkImageValidity = css<{ isImageValid?: boolean }>`
  ${({ isImageValid }) =>
    !isImageValid &&
    css`
      border: ${gF('px', 1, 2, 1366, 3840)} solid rgba(0, 0, 0, 0.1);
    `}
`

export const MobileDraggableImage = styled.img`
  position: absolute;
  z-index: 1;
  ${checkImageValidity}
`

export const NonDraggableImage = styled.img`
  ${checkImageValidity}
`

export const ItemWrapper = styled.div`
  overflow: hidden;
`

export const Item = styled.div`
  position: relative;
  cursor: grab;
  aspect-ratio: 1;

  &.draggable-disabled {
    cursor: not-allowed;

    img {
      filter: grayscale(100%);
      opacity: 0.3;
    }
  }

  .product-name {
    text-align: center;
    font-size: ${gF('px', 10, 20, 1366, 3840)};
    font-weight: 500;
    text-transform: capitalize;
    margin-top: ${pxToRem(4)}rem;

    div {
      text-overflow: ellipsis;
      overflow-x: hidden;
    }
  }

  img {
    object-fit: contain;

    width: 85px;
    height: 85px;

    @media (min-width: 3800px) {
      width: 260px;
      height: 260px;
    }

    @media (min-width: 768px) and (max-width: 3799px) {
      width: 104px;
      height: 104px;
    }

    &.whitFamily {
      @media (max-width: 768px) {
        width: 44px;
        height: 44px;
      }

      @media (min-width: 768px) and (max-width: 3799px) {
        width: 85px;
        height: 85px;
      }

      @media (min-width: 3800px) {
        width: 230px;
        height: 230px;
      }
    }
  }
`

export const ItemName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${gF('px', 12, 24, 1366, 3840)};
  > * {
    font-family: GilmerMedium, sans-serif;
    font-size: ${gF('px', 10, 20, 1366, 3840)};
    line-height: 1.4;
    text-align: center;
    color: ${palette.tangaroa};
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const Header = styled.div`
  margin-bottom: ${pxToRem(16)}rem;

  .counter {
    margin-left: ${pxToRem(6)}rem;
    font-size: ${gF('px', 16, 32, 1366, 3840)};
  }

  .edit {
    font-size: ${gF('px', 12, 24, 1366, 3840)};
    font-weight: 600;
    text-align: right;

    span {
      cursor: pointer;
    }
  }

  h4 {
    font-size: ${gF('px', 16, 32, 1366, 3840)};
    text-transform: capitalize;
    font-family: 'Gilmer', sans-serif;
    font-weight: bold;
  }
`

export const TitleWrapper = styled.div`
  display: grid;
  align-items: center;

  &.pinned {
    grid-template-columns: max-content min-content auto;
  }

  h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-family: GilmerBold, sans-serif;
      font-size: ${gF('px', 16, 32, 1366, 3840)};
    }

    @media (min-width: ${breakpointsCross.XL.max}) {
      span {
        font-size: ${pxToRem(32)}rem;
        color: ${palette.blackPearlWB};
      }
    }

    @media (max-width: ${breakpointsCross.M.over}) {
      span {
        font-size: ${pxToRem(16)}rem;
        color: ${palette.blackPearlWB};
      }
    }

    small {
      display: inline-block;
      font-family: GilmerMedium, sans-serif !important;
      font-size: ${gF('px', 12, 24, 1366, 3840)};
      cursor: pointer;
    }
  }
`

export const InputWrapper = styled.div`
  margin-bottom: 0;
  position: relative;
  font-family: GilmerMedium, sans-serif;
  min-height: ${gF('px', 32, 64, 1366, 3840)};

  @media (max-width: ${breakpointsCross.XL.max}) {
    margin-bottom: ${pxToRem(32)}rem;
  }
`

export const IconSearch = styled.div`
  position: absolute;
  width: ${gF('px', 14, 28, 1366, 3840)};
  height: ${gF('px', 14, 28, 1366, 3840)};
  left: ${gF('px', 13, 26, 1366, 3840)};
  top: 50%;
  transform: translateY(-50%);
  color: ${palette.shadowBlue};
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.493 0a8.502 8.502 0 0 0-2.62 16.583 8.496 8.496 0 0 0 7.619-1.207l4.232 4.233a1.334 1.334 0 1 0 1.885-1.886l-4.231-4.233a8.495 8.495 0 0 0-1.193-11.316A8.488 8.488 0 0 0 8.493 0zm0 14.333A5.83 5.83 0 0 1 2.662 8.5a5.834 5.834 0 0 1 5.831-5.833 5.83 5.83 0 0 1 4.121 9.956 5.836 5.836 0 0 1-4.12 1.71z' fill='%237888A5'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
`

export const Input = styled.input`
  padding: 0 ${gF('px', 36, 72, 1366, 3840)};
  width: 100%;
  height: 100%;
  font-size: ${gF('px', 12, 24, 1366, 3840)};
  border-color: ${palette.shadowBlue};
  outline: none;
`

export const ClearIcon = styled.div`
  position: absolute;
  right: ${gF('px', 13, 26, 1366, 3840)};
  width: ${gF('px', 9, 18, 1366, 3840)};
  height: ${gF('px', 9, 18, 1366, 3840)};
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  height: 100%;
`

export const DeleteIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: ${gF('px', 38, 52, 1366, 3840)};
  height: ${gF('px', 38, 52, 1366, 3840)};
  border-radius: 50%;
  background-color: ${palette.white};
  box-shadow: 0 4px 4px 0 rgba(3, 20, 52, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 999;

  img {
    width: ${gF('px', 20, 34, 1366, 3840)};
    height: ${gF('px', 20, 34, 1366, 3840)};
  }
`

export const BackHeader = styled.div`
  font-family: GilmerMedium, sans-serif;
  font-size: ${gF('px', 12, 24, 1366, 3840)};
  margin-bottom: ${pxToRem(30)}rem;
  display: inline-block;
  cursor: pointer;

  img {
    margin-right: ${pxToRem(6)}rem;
  }
`

export const ScrollWrapper = styled.div<{
  edit: boolean
  family: boolean
  isFilterSelected: boolean
}>`
  overflow-y: auto;
  height: 100%;

  &.isDragging {
    overflow-y: hidden;
  }
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;

  ${({ edit, family, isFilterSelected }) =>
    (edit || family || isFilterSelected) &&
    css`
      flex-direction: column;
    `};

  @media (max-width: ${breakpointsCross.XL.max}) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

export const PinnedItemsWrapper = styled.div<{ edit: boolean }>`
  position: relative;
  height: calc(100% - ${gF('px', 64, 96, 1366, 3840)});
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 3%;
  overflow-y: auto;

  ${({ edit }) =>
    edit &&
    css`
      height: 100%;
    `}
`

export const ItemsWrapper = styled.div`
  &.filter {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
  }
`

export const PinnedItemsTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PinnedItemsTitle = styled.h3`
  font-family: GilmerBold, sans-serif;
  font-size: ${gF('px', 16, 32, 1366, 3840)};
  letter-spacing: 1px;
  color: ${palette.blackPearlWB};
  & span {
    font-family: GilmerMedium, sans-serif;
  }
`

export const PinnedItemsEdit = styled.span`
  cursor: pointer;
  font-family: GilmerMedium, sans-serif;
  font-size: ${gF('px', 12, 24, 1366, 3840)};
  letter-spacing: 0.3px;
  color: ${palette.tangaroa};
`

export const CustomButtonCancel = styled(ButtonCancel)`
  width: unset;
  padding-bottom: ${pxToRem(20)}rem;
`

export const SearchResult = styled.div`
  white-space: nowrap;
  font-size: ${gF('px', 16, 32, 1366, 3840)};
  font-family: GilmerBold, sans-serif;
  color: #071432;
  padding-bottom: ${gF('px', 32, 48, 1366, 3840)};

  &.centered {
    text-align: center;
    font-family: GilmerMedium, sans-serif;
  }
`

export const CustomButtonsGroup = styled(ButtonsGroup)`
  justify-content: center;
  margin-top: auto;
`

export const ImagePlaceholder = styled.div<{ parentWidth: number }>`
  height: ${pxToRem(210)}rem;
  width: ${pxToRem(210)}rem;
  position: absolute;
  left: ${({ parentWidth }) => (parentWidth - 210) / 2}px;
  top: 50px;
  border-radius: ${pxToRem(8)}rem;
  background-color: ${palette.extraLight};
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 33%;
    animation: loading 1s linear infinite;
    @keyframes loading {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`

export const FiltersTitle = styled.div`
  * {
    font-family: 'GilmerBold', sans-serif;
  }

  h3 {
    font-size: ${gF('px', 16, 32, 1366, 3840)};
    letter-spacing: ${gF('px', 1, 2.08, 1366, 3840)};
    color: ${palette.blackPearlWB};
    text-transform: capitalize;
    margin: ${gF('px', 24, 12, 1366, 3840)} 0 ${gF('px', 24, 48, 1366, 3840)};
  }

  h4 {
    font-size: ${gF('px', 14, 24, 1366, 3840)};
    letter-spacing: ${gF('px', 1, 0.72, 1366, 3840)};
    color: ${palette.blackPearlWB};
    text-transform: uppercase;
  }
`

export const FilterCategoriesWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: ${gF('px', 30, 40, 1366, 3840)};
  margin: ${gF('px', 21, 42, 1366, 3840)} 0 ${gF('px', 41, 82, 1366, 3840)};
`

export const FilterCategory = styled.li<{ checked: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  height: ${gF('px', 18, 28, 1366, 3840)};
  flex-shrink: 0;

  div {
    ${({ checked }) =>
      checked
        ? css`
            background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 0a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm13.047 5.653a.455.455 0 0 0 0-.658l-.37-.359a.488.488 0 0 0-.679 0l-6.785 6.578a.244.244 0 0 1-.338 0L4.001 8.427a.49.49 0 0 0-.678 0l-.37.359a.458.458 0 0 0 0 .658l3.568 3.46c.139.135.328.21.524.21a.754.754 0 0 0 .525-.21l7.477-7.251z' fill='%23031434'/%3E%3C/svg%3E%0A");
          `
        : css`
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21 1.5H3A1.5 1.5 0 0 0 1.5 3v18A1.5 1.5 0 0 0 3 22.5h18a1.5 1.5 0 0 0 1.5-1.5V3A1.5 1.5 0 0 0 21 1.5zM3 0a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z' fill='%23DEE0E6'/%3E%3C/svg%3E%0A");

            @media (max-width: ${breakpointsCross.XL.max}) {
              background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.75 1.125H2.25c-.621 0-1.125.504-1.125 1.125v13.5c0 .621.504 1.125 1.125 1.125h13.5c.621 0 1.125-.504 1.125-1.125V2.25c0-.621-.504-1.125-1.125-1.125zM2.25 0A2.25 2.25 0 0 0 0 2.25v13.5A2.25 2.25 0 0 0 2.25 18h13.5A2.25 2.25 0 0 0 18 15.75V2.25A2.25 2.25 0 0 0 15.75 0H2.25z' fill='%23031434'/%3E%3C/svg%3E%0A");
            }
          `}
    width: ${gF('px', 18, 28, 1366, 3840)};
    height: 100%;
    margin-right: ${pxToRem(16)}rem;
    background-repeat: no-repeat;
    background-size: cover;
  }

  span {
    text-transform: capitalize;
    font-family: 'GilmerMedium', sans-serif;
    font-size: ${gF('px', 16, 28, 1366, 3840)};
    letter-spacing: 0.28px;
    color: ${palette.tangaroa};
    user-select: none;
  }
`

export const FiltersApply = styled.div`
  display: flex;
  justify-content: center;
`

export const FiltersApplyButton = styled.div`
  width: ${gF('px', 330, 810, 1366, 3840)};
  height: ${gF('px', 36, 72, 1366, 3840)};
  line-height: ${gF('px', 36, 72, 1366, 3840)};
  font-size: ${gF('px', 13, 24, 1366, 3840)};
  border: ${gF('px', 1, 2, 1366, 3840)} solid ${palette.tangaroa};
  letter-spacing: 0.3px;
  font-family: 'GilmerBold', sans-serif;
  border-radius: 4px;
  background-color: ${palette.tangaroa};
  color: ${palette.white};
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  user-select: none;

  &.disabled {
    pointer-events: none;
    background-color: ${palette.lightGray};
    color: ${palette.santasGray};
    border-color: ${palette.santasGray};
  }
`
