import queryString from 'qs'

import app_config from '../config/app/config'
import { DeviceName, mainRoutes, RoomType } from '../model/model'
import { getPostLoginRedirectUri } from './auth'

const { assetsUrl } = app_config

const devices = ['table', 'wall', 'extra-ipad', 'led-left', 'led-right', 'vto-ipad']

/**
 * It returns the url parts necessaries to redcarpet from URL.
 *
 * roomType
 * roomName
 * device
 * extrapath
 *
 * @param {string} basepath Pathname, `window.location.pathname` by default.
 * @returns {object}
 */
export const getUrlParts = (
  basepath: string = window.location.pathname,
): {
  roomName?: string | undefined
  roomType?: RoomType | undefined
  device?: DeviceName
  extrapath: string
} => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, roomName, part1, part2, ...extra] = basepath
    .split('/')
    .map((str, i) => (i < 4 ? str.toLowerCase() : str)) // eslint-disable-line no-unused-vars
  const parts: {
    roomType: RoomType | undefined
    roomName: string | undefined
    device: DeviceName
    extrapath: string
  } = {
    roomType: undefined,
    roomName: !Object.values(mainRoutes).includes(roomName) ? roomName : undefined,
    // https://abstractsrl.atlassian.net/browse/RED-3853
    // in the corner case where this function is called while in /digital-events route, part1 is undefined
    // since all /digital-events routes are accessed only by table devices we cast device to table
    device: roomName === 'digital-events' ? 'table' : (part1 as DeviceName),
    extrapath: extra.join('/'),
  }
  if (['table', 'led-left', 'led-right', 'wall'].includes(part2)) {
    parts.device = part2 as DeviceName
    parts.roomType = part1 as RoomType
  } else {
    parts.extrapath = [part2, ...extra].join('/')
  }
  return parts
}

/**
 * It returns the current device from URL.
 * @param {string} basepath Pathname, `window.location.pathname` by default.
 * @returns {string} Device type.
 */
export const getDeviceFromUrl = (basepath = window.location.pathname) => {
  const urlDevice = getUrlParts(basepath).device
  const postLoginRedirectUri = getPostLoginRedirectUri()
  const postLoginDevice = postLoginRedirectUri && getUrlParts(postLoginRedirectUri).device
  const device = urlDevice || postLoginDevice
  return devices.find(n => n === device) || undefined
}

export const getPageNameFromUrl = (basepath = window.location.pathname) => {
  const urlParts = getUrlParts(basepath)
  return urlParts.extrapath.split('/')[0]
}

/**
 * Returns true if device is 'table'.
 * @returns {boolean} Whether device is table or not.
 */
export const isTableDevice = () => {
  const deviceFromUrl = getDeviceFromUrl()
  const isTableInUrl = deviceFromUrl === 'table'
  const postLoginRedirectUri = getPostLoginRedirectUri()
  const deviceInPostLoginRedirectUri =
    postLoginRedirectUri && getDeviceFromUrl(postLoginRedirectUri)
  const isTableInPostLoginRedirectUri = deviceInPostLoginRedirectUri === 'table'

  // we moved the select event and select customer page to new url that do not have
  // the device name, so we need to fall back to the redirect uri in these cases
  return isTableInUrl || isTableInPostLoginRedirectUri
}

export const isSlaveDevice = () => {
  return devices.filter(device => device !== 'table').includes(getDeviceFromUrl() || '')
}

export const isLedDevice = () => {
  return getDeviceFromUrl() === 'led-left' || getDeviceFromUrl() === 'led-right'
}

export const isExtraIpadDevice = () => {
  return getDeviceFromUrl() === 'extra-ipad'
}

/**
 * Returns true if device is 'ipad'.
 * @param {boolean} countWidth Defines whether count screens smaller that FullHD as iPad.
 * @returns {boolean} Whether device is iPad or not.
 */
export const isIPadView = () => {
  return window.innerWidth < 1800
}

/**
 * Returns true if screen width equals 1920
 * @returns {boolean} Whether screen with equals 1920 or not.
 */
export const isFullHD = () => window.screen.availWidth === 1920

/**
 * Returns true if device is 'wall'.
 * @returns {boolean} Whether device is wall or not.
 */
export const isWallDevice = () => {
  return getDeviceFromUrl() === 'wall'
}

/**
 * Returns value depening on device type.
 * @param {object} values Object of values to pick from.
 * @param {any} [values.fullhd] – Value for FullHD screen.
 * @param {any} [values.ipad] – Value for iPad.
 * @param {any} [values.extraipad] – Value for Extra iPad.
 * @param {any} [values.wall] – Value for wall.
 * @param {any} values.table – Value for table. Default fallback value.
 * @param {*} fallback Fallback key. 'table' by default.
 * @returns {any} value or fallback
 */
export const deviceSwitch = (values: Record<string, number | undefined>, fallback = 'table') => {
  let value = values[fallback]

  const pick = (val: string) => values[val] || value || 0

  if (isTableDevice()) value = pick('table')
  if (isWallDevice()) value = pick('wall')
  if (isIPadView()) value = pick('ipad')
  if (isExtraIpadDevice()) value = pick('extraipad')
  if (isFullHD()) value = pick('fullhd')

  return value
}

/**
 * It returns the device absolute path.
 * @returns {string} Absolute path for device.
 */
export const getDeviceAbsolutePath = () => {
  if (window.location.pathname.includes('/digital-events')) {
    return '/digital-events'
  } else {
    const [_, roomId, part1, part2] = window.location.pathname.split('/')
    let url = [_, roomId, part1].join('/')
    if (part2 === 'table') {
      url = [_, roomId, part1, part2].join('/')
    }
    return url
  }
}

export const getDeviceBasePath = () => {
  if (window.location.pathname.includes('/digital-events')) {
    return '/digital-events'
  } else {
    const [_, roomId, part1, part2] = window.location.pathname.split('/')
    const url = [_, roomId, part1, part2].join('/')
    return url
  }
}

/**
 * It returns the current room from URL.
 * @returns {string} Number of the room, retrieved from URL.
 */
export const getRoomFromUrl = (location?: string) => {
  const urlRoomName = getUrlParts(location).roomName
  const postLoginRedirectUri = getPostLoginRedirectUri()
  const postLoginRoomName = postLoginRedirectUri && getUrlParts(postLoginRedirectUri).roomName
  return urlRoomName || postLoginRoomName || undefined
}

/**
 * It returns the current room type from URL.
 * @returns {string} Type of the room, retrieved from URL.
 */
export const getRoomTypeFromUrl = (location: string | undefined = undefined) => {
  const urlRoomType = getUrlParts(location).roomType
  const postLoginRedirectUri = getPostLoginRedirectUri()
  const postLoginRoomType = postLoginRedirectUri && getUrlParts(postLoginRedirectUri).roomType
  return urlRoomType || postLoginRoomType || undefined
}

/**
 * Takes in input a string and formats it in order that it can be used into a url.
 * @param {string} el .
 * @returns {string} Generated URL parameter.
 */
export const formatUrlElement = (el: string | undefined) => {
  return el ? el.replace(/\s/g, '_').replace(/\//g, '_') : ''
}

/**
 * Generates Base URL for product image
 * @param {object} moco Model/color details
 * @returns {string} URL to the product image
 */
export const getBaseImageUrlV2 = (moco: {
  modelCode: string
  mocoCode: string
  brandCode: string
}) => {
  const { modelCode, mocoCode, brandCode } = moco
  return `${assetsUrl}/${brandCode}/${formatUrlElement(modelCode)}/${formatUrlElement(mocoCode)}`
}

/**
 * @returns {string} the value to use as imwidth parameter in Akamai Image Manager query string
 */
export const getImwidthParamterValue = (fullscreen = false) =>
  isIPadView() ? (fullscreen ? '1500' : '890') : '1500'

/**
 * Generates URL for product on remote server using assets url from .env.
 * @param {object} moco Model/color details
 * @param {string|null} rotation Rotation angle postfix, 030A by default; if `null`, then returns everything before angle part (for 360 view).
 * @param {string} imgWidth Width of image, could be 1800, 900, 600, 300.
 * @returns {string} URL to the product image
 */
export const getImageUrlV2 = (
  moco: {
    modelCode: string
    mocoCode: string
    brandCode: string
  },
  rotation?: string | null,
  imgWidth = 600,
) => {
  let url = getBaseImageUrlV2(moco)
  if (rotation) {
    url += `_${rotation}.png`
  } else {
    url += '.jpg'
  }
  return `${url}?imwidth=${imgWidth}`
}

/**
 * Checks whether user on PLP page or not.
 * @returns {boolean} Result of checking
 */
export const isPLPPage = () => {
  const urlParts = getUrlParts()

  const [page, , category] = urlParts.extrapath.split('/')
  return (
    page === 'products' ||
    (page === 'single-brand' &&
      ['sun', 'optical', 'electronics-sun', 'electronics-optical'].indexOf(category) !== -1)
  )
}

/**
 * Checks whether user is on targetPage or not.
 * @param {array} pages Pages, where user is expected to be
 * @returns {boolean} Result of checking
 */
export const isPageMatches = (...pages: string[]) => {
  const urlParts = getUrlParts()
  let match = false
  const pageName =
    urlParts.extrapath &&
    (urlParts.extrapath.split('/')[0] === 'afa'
      ? urlParts.extrapath.split('/')[1]
      : urlParts.extrapath.split('/')[0])
  pages.forEach(el => {
    if (el === 'plp' && isPLPPage()) {
      match = true
      return
    }

    if (el === pageName) {
      match = true
      return
    }
  })
  return match
}

export const isElectronicPage = () => {
  const urlParts = getUrlParts()
  const [page, , category] = urlParts.extrapath.split('/')
  return page === 'single-brand' && 'electronic' === category
}

/**
 * Checks whether user on Categories page or not.
 * @returns {boolean} Result of checking
 */
export const isCategoriesPage = () => isPageMatches('single-brand') && !isPLPPage()

/**
 * Creates URL parameter for array.
 * @param {array} arrValues Array of values.
 * @param {string} urlParamName Parameter name to create.
 * @returns {string} Generated URL parameter.
 */
export const arrayToUrlParams = (arrValues: unknown[], urlParamName: string) => {
  let urlParams
  if (arrValues.length) {
    urlParams = arrValues.reduce((accumulator, currentValue, index) => {
      if (index === 0) {
        return `${urlParamName}=${accumulator}${currentValue}`
      }
      return `${accumulator}&${urlParamName}=${currentValue}`
    }, '')
  }
  return urlParams
}

/**
 * Gets current active brand from URL
 * @returns {string} Brand code
 */
export const getActiveBrandFromUrl = () => {
  const parsedSearch = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })
  return parsedSearch['activeBrand']
}

/**
 * Checks if room type in URL matches `expected`
 * @param {*} expected expected room type
 * @returns {boolean} Whether room type matches expected
 */
export const isRoomTypeMatches = (expected: string) => {
  const { roomType } = getUrlParts()

  return typeof roomType === 'string' && roomType.toLowerCase() === expected.toLowerCase()
}

/**
 * Checks if room type is FULL
 */
export const isFullRoom = () => isRoomTypeMatches(app_config.roomType.FULL)

/**
 * Checks if room type is LARGE
 */
export const isLargeRoom = () => isRoomTypeMatches(app_config.roomType.LARGE)

/**
 * Checks if room type is MEDIUM
 */
export const isMediumRoom = () => isRoomTypeMatches(app_config.roomType.MEDIUM)

export const isIpadOnlyRoom = () => isRoomTypeMatches(app_config.roomType.IPAD_ONLY)

/**
 * Checks if page contains searchQuery in URL, and therefore it's search PLP.
 * @returns {boolean} If page is search Plp
 */
export const isSearchPLP = () => location.search.includes('searchQuery')

export const getSelectedBrandFromUrl = () => {
  const match = window.location.search.match(/activeBrand=(\w\w)/)
  return match ? match[1] : ''
}

/**
 * Returns true if device is 'touchScreen'.
 * @returns {boolean} Whether device is touchScreen or not.
 */
export const isTouchDevice = (): boolean => {
  return (
    'ontouchstart' in window ||
    'msmaxtouchpoints' in window.navigator ||
    (!!navigator.maxTouchPoints && navigator.maxTouchPoints > 2)
  ) // some kiosk devices are detected as non touch capable devices
}

export const getBrandSlugFromPlpUrl = () => {
  const { extrapath } = getUrlParts()
  const [pageName, brandSlug] = extrapath.split('/')

  return pageName === 'single-brand' ? brandSlug : undefined
}

export const getBaseUrl = () => {
  return getPostLoginRedirectUri() || '/de/ipad-only/table'
}
