import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { mergeNewProductDetailsInState } from '../../libs/productsV2'
import { Product } from '../../model/product'
import { WishlistProduct, WishlistState } from '../../model/wishlist'
import { resetForNewClient } from '../extraActions'

const DEFAULT_STATE: WishlistState = {
  wishlistData: { id: '', products: [], productDetails: {} },
  loading: false,
  loaded: false,
  currentCategoryId: undefined,
}

export const slice = createSlice({
  name: 'wishlist',
  initialState: DEFAULT_STATE,
  reducers: {
    saveWishlistProductsDetails: (state, { payload }: PayloadAction<Product[]>) => {
      state.wishlistData.productDetails = mergeNewProductDetailsInState(
        state.wishlistData.productDetails,
        payload,
      )
      state.loaded = true
      state.loading = false
    },
    saveWishlistProducts: (
      state,
      { payload }: PayloadAction<{ id: string; products: WishlistProduct[] }>,
    ) => {
      state.wishlistData.id = payload.id
      state.wishlistData.products = payload.products
      state.loaded = true
      state.loading = false
    },
    setWishlistCurrentCategoryId: (state, { payload }: PayloadAction<string | 0 | undefined>) => {
      state.currentCategoryId = payload
    },
    setWishlistLoading: state => {
      state.loading = true
    },
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, () => DEFAULT_STATE)
  },
})

export default slice.reducer
