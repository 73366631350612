import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { cmsTagSelector, languageSelector } from '../store/app/selectors'
import { customerNationalitySelector } from '../store/customer/selectors'
import { getInitiatives } from '../store/digitalEvents/actions'
import { initiativesSelector } from '../store/digitalEvents/selectors'

const useFetchInitiatives = () => {
  const initiatives = useSelector(initiativesSelector)
  const dispatch = useDispatch()
  const language = useSelector(languageSelector)
  const country = useSelector(customerNationalitySelector)
  const cmsTag = useSelector(cmsTagSelector)

  useEffect(() => {
    if (!initiatives) {
      dispatch(getInitiatives({ language, country, cmsTag }))
    }
  }, [initiatives, dispatch, language, country, cmsTag])
}

export default useFetchInitiatives
