import React, { SVGProps } from 'react'

const CloseIcon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    width="38px"
    height="38px"
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    {...props}
  >
    <path d="m 23.0375,19 14.725,14.725 C 37.920833,33.883333 38,34.101042 38,34.378125 c 0,0.277083 -0.07917,0.494792 -0.2375,0.653125 L 35.03125,37.7625 C 34.872917,37.920833 34.655208,38 34.378125,38 34.101042,38 33.883333,37.920833 33.725,37.7625 L 19,23.0375 4.275,37.7625 C 4.1166673,37.920833 3.8989577,38 3.621875,38 3.3447923,38 3.1270827,37.920833 2.96875,37.7625 L 0.2375,35.03125 C 0.07916727,34.872917 0,34.655208 0,34.378125 0,34.101042 0.07916727,33.883333 0.2375,33.725 L 14.9625,19 0.2375,4.275 C 0.07916727,4.1166673 0,3.8989577 0,3.621875 0,3.3447923 0.07916727,3.1270827 0.2375,2.96875 L 2.96875,0.2375 C 3.1270827,0.07916727 3.3447923,0 3.621875,0 3.8989577,0 4.1166673,0.07916727 4.275,0.2375 L 19,14.9625 33.725,0.2375 C 33.883333,0.07916727 34.101042,0 34.378125,0 34.655208,0 34.872917,0.07916727 35.03125,0.2375 L 37.7625,2.96875 C 37.920833,3.1270827 38,3.3447923 38,3.621875 38,3.8989577 37.920833,4.1166673 37.7625,4.275 Z" />
  </svg>
)

export default CloseIcon
