import React from 'react'

import LedView, { VideoNamesMap } from './LedView'

const LedLeftView: React.FC = props => {
  const videoNamesMap: VideoNamesMap = {
    intro: 'intro',
    welcome: 'welcomeLedsx',
    navigation: 'nav_led_sx',
    activation: 'act_led_sx',
    multibrand: 'multibrandLedsx',
    digitalPreview: 'digitalPreviewLedsx',
  }
  return <LedView {...props} category="2" revert={true} videoNamesMap={videoNamesMap} side="left" />
}

export default LedLeftView
