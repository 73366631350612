import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList as List } from 'react-window'
import styled, { css } from 'styled-components/macro'
import { breakpoints, getFluidSizeWithFullFormula as gF } from '../../style/theme'
import { StyledCheckBox } from '../CheckoutPage/components/Common'
import CalendarIconEmpty from '../../components/icons/CalendarIconEmpty'
import { useGetAfaCartProducts } from '../../hooks/useGetAfaCartProducts'
import { useSearchParams } from '../../hooks/useSearchParams'
import { buildMocoCode } from '../../libs/productsV2'
import { palette, pxToRem } from '../../style/theme'
import AfaCartProductCard from './AfaCartProductCard'
import AfaModal from '../../components/AfaModal'
import RemoveSelectedFromCart from '../../components/RemoveSelectedFromCart/RemoveSelectedFromCart'
import {
  useGetCartDeliveryDatesQuery,
  useGetSelectedDelivery,
  useUpdateAfaCartProductsMutation,
} from '../../services/afaCart'
import AfaChangeDateModal from '../../components/AfaChangeDateModal/AfaChangeDateModal'
import PlusIcon from '../../components/icons/PlusIcon'
import TrashIcon from '../../components/icons/TrashIcon'
import AfaCartSplitOrderModal from '../../components/AfaCartSplitOrderModal/AfaCartSplitOrderModal'
import Loading from '../../components/Loading'
import { isNotUndefined } from '../../libs/utils'
import { format } from 'date-fns'
import { convertDdMmYyyyToDate } from '../../libs/time'
import { calculateVirtualRowHeightAfaCart } from '../../helpers/genericHelper'
import { useDispatch, useSelector } from 'react-redux'
import { viewportSelector } from '../../store/viewport/selectors'
import { DdMmYyyyDateString, Upc } from '../../model/model'
import { AfaCartProduct } from '../../model/afa'
import { afaCartAllProductDetailsSelector } from '../../store/afaCart/selectors'
import { getSelectedKeys } from '../../store/afaCart/selectors'
import afaCartActions from '../../store/afaCart/actions'
import { errorNotification, successNotification } from '../../components/Notification/notifications'
import { useGetCartAvailabilityQuery } from '../../services/afaProduct'
import { getNextAvailability } from '../../libs/afa'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${pxToRem(20)}rem ${pxToRem(20)}rem;
  background-color: ${palette.wildSand};
  flex-grow: 1;
  border-top: 1px solid ${palette.silver};

  & > div:last-of-type {
    flex-grow: 1;
  }

  @media (min-width: ${breakpoints.L}) {
    padding: 0 ${pxToRem(32)}rem ${pxToRem(20)}rem;
  }
`

const EmptyCart = styled.div`
  height: 10em;
  color: ${palette.santasGray};
  font-size: ${gF('px', 26, 48, 1366, 3840)};
  text-align: center;
  line-height: 10em;
  background-color: ${palette.white};
  margin-top: ${pxToRem(20)}rem;
  border-radius: 8px;
  font-family: GilmerMedium, sans-serif;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  margin-top: ${gF('px', 32, 50, 1366, 3840)};
  margin-bottom: ${gF('px', 25, 40, 1366, 3840)};
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${gF('px', 8, 32, 1366, 3840)};
`

const DeliveryTitle = styled.span`
  text-transform: uppercase;
  font-size: ${gF('px', 18, 32, 1366, 3840)};
  color: ${palette.tangaroa};
  letter-spacing: ${gF('px', 0.9, 1.6, 1366, 3840)};
  font-family: GilmerBold;
`

const AvailableFromTitle = styled.span`
  font-size: ${gF('px', 14, 24, 1366, 3840)};
  color: ${palette.santasGray};
  letter-spacing: ${gF('px', 0.7, 1.2, 1366, 3840)};
  font-family: GilmerMedium;
`

const Buttons = styled.div`
  margin-left: auto;
  display: flex;
  gap: ${pxToRem(16)}rem;
  height: fit-content;
  text-transform: uppercase;
`

export const Button = styled.div<{ disabled?: boolean }>`
  cursor: pointer;
  letter-spacing: 0.3px;
  font-family: GilmerBold, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${palette.tangaroa};
  font-size: ${gF('px', 13, 24, 1366, 3840)};
  padding: ${gF('px', 9, 20, 1366, 3840)} ${gF('px', 21, 32, 1366, 3840)};
  gap: ${pxToRem(6)}rem;
  background-color: ${palette.white};
  border-radius: 5rem;
  box-shadow: 0 4px 4px 0 rgba(3, 20, 52, 0.17);
  text-transform: uppercase;
  line-height: 1.38;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${palette.santasGray};
      background-color: ${palette.extraLight};
      pointer-events: none;
    `}
  svg {
    height: ${gF('px', 16, 24, 1366, 3840)};
    width: ${gF('px', 16, 24, 1366, 3840)};
  }
`

const CheckboxWrapper = styled.div`
  font-size: ${gF('px', 14, 24, 1366, 3840)};
  margin: ${pxToRem(14)}rem 0;
  display: flex;
  align-items: center;
  gap: ${pxToRem(8)}rem;

  input {
    background-color: ${palette.white};
  }

  span {
    color: ${palette.tangaroa};
    font-family: GilmerMedium, sans-serif;
    margin-left: ${pxToRem(5)}rem;
    text-transform: lowercase;
  }
`

const StyledCheckbox = styled(StyledCheckBox)`
  width: ${gF('px', 18, 30, 1366, 3840)};
  height: ${gF('px', 18, 30, 1366, 3840)};
`

export const groupByMocoCodeAndMinDeliveryDate = (
  cartProducts: AfaCartProduct[],
): AfaCartProduct[][] => {
  return Object.values(
    cartProducts.reduce((result, cartProduct) => {
      const mocoCode = buildMocoCode(cartProduct)
      const key = `${mocoCode}___${cartProduct.minDeliveryDate}`
      result[key] = result[key] ? result[key].concat(cartProduct) : [cartProduct]
      return result
    }, {} as Record<string, AfaCartProduct[]>),
  )
}

type DeliveryDate = string

const buildKeyFromUpcs = (cartProducts: AfaCartProduct[]) =>
  cartProducts
    .map(({ upc, deliveryDate }) => `${upc}/${deliveryDate}`)
    .sort()
    .join('-')

const Row: React.FC<{
  index: number
  style: CSSProperties
  data: {
    mocoRows: AfaCartProduct[][]
    openedProductCards: string[]
    selectedDeliveryDate: DdMmYyyyDateString
    selectedKeys: Record<DeliveryDate, Upc[]>
    setSelectedKeys: (keys: Record<string, string[]>) => void
    setKeysToRemove: (keys: Upc[]) => void
    setOpenedProductCard: (index: number, list: string[]) => void
  }
}> = ({ index, style, data }) => {
  const {
    mocoRows,
    openedProductCards,
    selectedDeliveryDate,
    selectedKeys,
    setSelectedKeys,
    setKeysToRemove,
    setOpenedProductCard,
  } = data
  const key = buildKeyFromUpcs(mocoRows[index])

  const open = openedProductCards.includes(key)

  const mocoKeys = mocoRows[index].map(({ key }) => key)

  return (
    <AfaCartProductCard
      cartProductsByMoco={mocoRows[index]}
      style={style}
      onCheck={() => {
        mocoKeys.some(key => selectedKeys[selectedDeliveryDate]?.includes(key))
          ? setSelectedKeys({
              ...selectedKeys,
              [selectedDeliveryDate]: selectedKeys[selectedDeliveryDate]?.filter(
                key => !mocoKeys.includes(key),
              ),
            })
          : setSelectedKeys({
              ...selectedKeys,
              [selectedDeliveryDate]: [
                ...new Set([...selectedKeys[selectedDeliveryDate], ...mocoKeys]),
              ],
            })
      }}
      checked={selectedKeys[selectedDeliveryDate]?.some(key => mocoKeys.includes(key))}
      open={open}
      removeFromCart={() => {
        setKeysToRemove(mocoKeys)
      }}
      toggleOpen={() =>
        setOpenedProductCard(
          index,
          open ? openedProductCards.filter(k => k !== key) : openedProductCards.concat(key),
        )
      }
    />
  )
}
Row.displayName = 'Row'

const ListContainer = styled.div<{ productListHeight: number }>`
  overflow-y: auto;
  padding-bottom: ${({ productListHeight }) => pxToRem(productListHeight * 0.1)}rem;
`

const AfaCartProducts: React.FC = () => {
  const { t } = useTranslation()

  const { cartProductsByBrandCode, brandsInCart, isLoading } = useGetAfaCartProducts()
  const availabilityQuery = useGetCartAvailabilityQuery()

  const cartDeliveryQuery = useGetCartDeliveryDatesQuery()

  const selectedDelivery = useGetSelectedDelivery()
  const selectedDeliveryDate = selectedDelivery?.deliveryDate
  const selectedDeliveryDateToShow = format(
    convertDdMmYyyyToDate(selectedDelivery?.deliveryDate || '01-01-1970'),
    'dd/MM/uuuu',
  )

  const [wrapperHeight, setWrapperHeight] = useState(0)

  const { height: viewportHeight } = useSelector(viewportSelector)
  const listContainer = document.getElementById('list-container') as HTMLElement
  const listContainerTop = listContainer?.getBoundingClientRect().top
  const footers = [...document.getElementsByTagName('footer')]
  const footer = footers[footers.length - 1]
  const footerHeight = footer?.getBoundingClientRect().height

  useEffect(() => {
    if (listContainerTop && footerHeight) {
      setWrapperHeight(viewportHeight - listContainerTop - footerHeight)
    }
  }, [listContainerTop, viewportHeight, footerHeight, selectedDeliveryDate])

  const [searchParams] = useSearchParams()
  const selectedBrand = searchParams.get('brand') || brandsInCart[0]?.code

  const filteredCartProducts = useMemo(() => {
    return (
      cartProductsByBrandCode[selectedBrand]?.filter(
        ({ deliveryDate }) => deliveryDate === selectedDeliveryDate,
      ) || []
    )
  }, [cartProductsByBrandCode, selectedBrand, selectedDeliveryDate])

  const mocoRows = useMemo(() => {
    return groupByMocoCodeAndMinDeliveryDate(filteredCartProducts)
      .filter(
        arr =>
          arr.reduce((total, cartProduct) => {
            total += cartProduct.unconfirmedQuantity
            return total
          }, 0) > 0,
      )
      .sort((a, b) => {
        const fallback = '9999999'
        const mocoCode1 = `${a[0]?.modelCode || fallback}-${a[0]?.colorCode || fallback}`
        const mocoCode2 = `${b[0]?.modelCode || fallback}-${b[0]?.colorCode || fallback}`
        return mocoCode1.localeCompare(mocoCode2)
      })
  }, [filteredCartProducts])

  const _selectedDeliveryIndex = (cartDeliveryQuery.data || []).findIndex(
    delivery => delivery.deliveryDate === selectedDeliveryDate,
  )
  const selectedDeliveryIndex = _selectedDeliveryIndex > -1 ? _selectedDeliveryIndex : 0

  const selectedKeys = useSelector(getSelectedKeys)
  const dispatch = useDispatch()

  useEffect(() => {
    if (cartDeliveryQuery.data) {
      const selectedUpc = cartDeliveryQuery.data.reduce((result, { deliveryDate }) => {
        result[deliveryDate] = [] as Upc[]
        return result
      }, {} as Record<DeliveryDate, Upc[]>)
      dispatch(afaCartActions.setSelectedKeys(selectedUpc))
    }
  }, [cartDeliveryQuery.data, dispatch])
  const [openedProductCards, _setOpenedProductCards] = useState<string[]>([])
  const [changeDateModalOpen, setChangeDateModalOpen] = useState(false)
  const [splitOrderModalOpen, setSplitOrderModalOpen] = useState(false)
  const [keysToRemove, setKeysToRemove] = useState<Upc[]>([])

  const listRef = useRef<List>(null)

  const setOpenedProductCard = (index: number, list: string[]) => {
    _setOpenedProductCards(list)
    listRef.current?.resetAfterIndex(index)
  }

  const [updateCartProducts, updateAfaCartProductResult] = useUpdateAfaCartProductsMutation(
    'remove-all-items',
  )

  const productCardClosedHeight = calculateVirtualRowHeightAfaCart(viewportHeight).close
  const productCardOpenHeight = calculateVirtualRowHeightAfaCart(viewportHeight).open

  const openedCardsOfDeliveryDate = openedProductCards.filter(card => {
    const cardDate = card.split('/')[1]
    return cardDate === selectedDeliveryDate
  })

  const productListHeight =
    mocoRows.length * productCardClosedHeight +
    openedCardsOfDeliveryDate.length * (productCardOpenHeight - productCardClosedHeight)

  const cartProductDetails = useSelector(afaCartAllProductDetailsSelector)

  useEffect(() => {
    if (updateAfaCartProductResult.status === 'fulfilled') {
      if (updateAfaCartProductResult.isError || updateAfaCartProductResult.error) {
        errorNotification({
          message: t('Afa.errorTryingUpdateCart'),
        })
      }
      if (updateAfaCartProductResult.isSuccess && updateAfaCartProductResult.data?.result) {
        successNotification({
          message: t('Afa.Cart.Adjust.Success'),
        })
      }
      if (updateAfaCartProductResult.data?.warnings.length) {
        updateAfaCartProductResult.data?.warnings.forEach(warning => {
          errorNotification({
            message: warning.faultmessage,
          })
        })
      }
      updateAfaCartProductResult.reset()
    }
  }, [t, updateAfaCartProductResult])

  if (!mocoRows.length) {
    return (
      <Wrapper>
        <EmptyCart>{t('Afa.Cart.EmptyCart')}</EmptyCart>
      </Wrapper>
    )
  }

  if (!selectedDeliveryDate) {
    return null
  }

  const removeAllItems = () => {
    updateCartProducts(
      keysToRemove
        .map(key => filteredCartProducts.find(cartProduct => cartProduct.key === key))
        .filter(isNotUndefined)
        .map(cartProduct => ({ ...cartProduct, quantity: 0 })),
    )
    dispatch(afaCartActions.setSelectedKeys({ ...selectedKeys, [selectedDeliveryDate]: [] }))
    setKeysToRemove([])
  }

  const closeRemoveModal = () => {
    setKeysToRemove([])
  }

  const allKeysInSelectedTab = [...new Set(filteredCartProducts.map(({ key }) => key))]

  const productsForModals = selectedKeys[selectedDeliveryDate]?.length
    ? filteredCartProducts.filter(({ key }) => selectedKeys[selectedDeliveryDate]?.includes(key))
    : filteredCartProducts

  const disableSplitBtn = productsForModals.every(product => {
    return product.unconfirmedQuantity === 1
  })

  const availableFrom = getNextAvailability(mocoRows.flat(), availabilityQuery.data)

  return isLoading ? (
    <Loading isFullPage={false} />
  ) : (
    <Wrapper>
      <Header>
        {selectedDeliveryIndex !== undefined && (
          <Title>
            <DeliveryTitle>
              {t('Afa.Cart.Delivery')} {selectedDeliveryIndex + 1} - {selectedDeliveryDateToShow}
            </DeliveryTitle>
            {availableFrom ? (
              <AvailableFromTitle>
                {t('Afa.Cart.AvailableFromTab')}{' '}
                {format(convertDdMmYyyyToDate(availableFrom), 'dd/MM/uuuu')}
              </AvailableFromTitle>
            ) : (
              <AvailableFromTitle>
                {t('Afa.Cart.AvailableFromTab')} {selectedDeliveryDateToShow}
              </AvailableFromTitle>
            )}
          </Title>
        )}

        <Buttons>
          <Button onClick={() => setChangeDateModalOpen(true)} disabled={!mocoRows.length}>
            <CalendarIconEmpty />
            <span>{t('Afa.Cart.ChangeDate')}</span>
          </Button>
          <Button
            disabled={!mocoRows.length || disableSplitBtn}
            onClick={() => setSplitOrderModalOpen(true)}
          >
            <PlusIcon />
            <span>{t('Afa.Cart.SplitOrder')}</span>
          </Button>
          <Button
            disabled={!selectedKeys[selectedDeliveryDate]?.length}
            onClick={() => {
              setKeysToRemove(selectedKeys[selectedDeliveryDate])
            }}
          >
            <TrashIcon />
            <span>{t('Afa.Cart.RemoveOrder')}</span>
          </Button>
        </Buttons>
      </Header>

      <CheckboxWrapper>
        <StyledCheckbox
          borderColor={palette.tangaroa}
          accentColor={palette.tangaroa}
          checked={allKeysInSelectedTab.every(key =>
            selectedKeys[selectedDeliveryDate]?.includes(key),
          )}
          onChange={() =>
            dispatch(
              afaCartActions.setSelectedKeys({
                ...selectedKeys,
                [selectedDeliveryDate]:
                  selectedKeys[selectedDeliveryDate]?.length === allKeysInSelectedTab.length
                    ? []
                    : allKeysInSelectedTab,
              }),
            )
          }
        />
        <span>
          {mocoRows.length} {t('Afa.Cart.Rows')}
        </span>
      </CheckboxWrapper>

      <ListContainer
        id="list-container"
        style={{ height: wrapperHeight }}
        productListHeight={productListHeight}
      >
        <AutoSizer disableHeight={true} defaultHeight={productListHeight} key={viewportHeight}>
          {({ width }) => {
            return (
              <List
                ref={listRef}
                key={selectedDeliveryDate}
                height={productListHeight}
                itemCount={mocoRows.length}
                itemSize={index => {
                  const cartProducts = mocoRows[index]
                  const key = buildKeyFromUpcs(cartProducts)

                  const productDetails = (cartProductDetails || {})[cartProducts[0]?.modelCode]
                  const sizes = productDetails?.mocos[cartProducts[0]?.colorCode]?.sizes
                  const sizesToRender = sizes
                    ? Object.values(sizes)
                    : mocoRows[index].map(({ upc }) => ({ upc, size: '' }))

                  const rowHeight = calculateVirtualRowHeightAfaCart(
                    viewportHeight,
                    sizesToRender.length,
                  )

                  return openedProductCards.includes(key) ? rowHeight.open : rowHeight.close
                }}
                width={width}
                style={{ overflow: 'visible' }}
                itemData={{
                  mocoRows,
                  openedProductCards,
                  selectedDeliveryDate,
                  selectedKeys,
                  setSelectedKeys: keys => dispatch(afaCartActions.setSelectedKeys(keys)),
                  setKeysToRemove,
                  setOpenedProductCard,
                }}
              >
                {Row}
              </List>
            )
          }}
        </AutoSizer>
      </ListContainer>

      <AfaModal isOpen={changeDateModalOpen} onRequestClose={() => setChangeDateModalOpen(false)}>
        <AfaChangeDateModal
          key={changeDateModalOpen ? '1' : '0'}
          onCancel={() => {
            setChangeDateModalOpen(false)
            dispatch(
              afaCartActions.setSelectedKeys({
                ...selectedKeys,
                [selectedDeliveryDate]: [],
              }),
            )
          }}
          selectedProducts={productsForModals}
        />
      </AfaModal>

      <AfaModal isOpen={splitOrderModalOpen} onRequestClose={() => setSplitOrderModalOpen(false)}>
        {selectedDelivery ? (
          <AfaCartSplitOrderModal
            key={splitOrderModalOpen ? '1' : '0'}
            number={(selectedDeliveryIndex + 1).toString()}
            selectedProducts={productsForModals}
            deliveryDate={selectedDelivery.deliveryDate}
            nextDeliveryDate={
              cartDeliveryQuery.data &&
              cartDeliveryQuery.data[selectedDeliveryIndex + 1]?.deliveryDate
            }
            onCancel={() => setSplitOrderModalOpen(false)}
          />
        ) : (
          <div>ERROR: no selected delivery date</div>
        )}
      </AfaModal>

      <AfaModal isOpen={!!keysToRemove.length} onRequestClose={closeRemoveModal}>
        <RemoveSelectedFromCart
          onCancel={closeRemoveModal}
          onRemoveItems={removeAllItems}
          title={t('Afa.Cart.Remove')}
          confirmText={t('Afa.Cart.AreYouSure')}
          cartProducts={filteredCartProducts.filter(({ key }) => keysToRemove.includes(key))}
        />
      </AfaModal>
    </Wrapper>
  )
}

export default AfaCartProducts
