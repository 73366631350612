import React from 'react'
import { useTranslation } from 'react-i18next'
import { DdMmYyyyDateString } from '../../model/model'

import SizeDate from './SizeDate'
import { SizeDropTitle } from './DropRowsStyles'

type SizeDropDateProps = {
  minDeliveryDate: DdMmYyyyDateString
  dropNumber: string
  type?: string
}

const SizeDropDate: React.FC<SizeDropDateProps> = ({ minDeliveryDate, dropNumber, type }) => {
  const { t } = useTranslation()

  return (
    <SizeDate date={minDeliveryDate} className="dropDate" type={type}>
      <SizeDropTitle>
        {type == 'customDelivery' ? t('Afa.delivery') : `${dropNumber}° ${t('Afa.drop')}`}
      </SizeDropTitle>
    </SizeDate>
  )
}

export default SizeDropDate
