import React from 'react'
import styled from 'styled-components'

import { palette, pxToRem, spacing } from '../../../../style/theme'
import { CheckoutFilterLayerWrapper } from '../../../../components/CommonComponents'
import { Checkbox } from 'antd'

const Wrapper = styled(CheckoutFilterLayerWrapper)`
  padding: 0;
  max-height: ${pxToRem(600)}rem;
  border-radius: 0;
  border: none;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
`

const UlMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
`

const Option = styled(Checkbox)`
  padding: 0.75em 1em;
  font-weight: 400;
  white-space: nowrap;

  .ant-checkbox {
    font-size: inherit;

    .ant-checkbox-inner {
      border: 1px solid ${palette.cornflowerBlue};
      border-radius: 4px;
      background: ${palette.selago};

      &:after {
        border: 1px solid ${palette.congressBlue};
        border-top: 0;
        border-left: 0;
        margin-left: -1px;
        margin-top: -1px;
      }
    }
  }

  display: block;
`
Option.displayName = 'Option'

const LiMenu = styled.li`
  display: flex;
  align-items: center;
  gap: ${pxToRem(spacing(1.25))}rem;
  padding: 0.5em 1em;
  border-bottom: 1px solid ${palette.alto};

  &.selected {
    color: red;
  }
`

type Props = {
  open: boolean
  dates: Record<string, number | undefined>
  selectedDates: string[]
  onChange: (selectedDates: string[]) => void
}

const DateMenu: React.FC<Props> = ({ open, dates, selectedDates, onChange }) => {
  return !open ? null : (
    <Wrapper scrollbarColor={palette.alto}>
      <UlMenu>
        {Object.entries(dates).map(([date, counter]) => (
          <LiMenu
            key={date}
            className={selectedDates.includes(date) ? 'selected' : ''}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              if (counter === 0) {
                return
              }
              const selectedDatesUpdated = selectedDates.includes(date)
                ? selectedDates.filter(selectedDate => selectedDate !== date)
                : selectedDates.concat(date)
              onChange(selectedDatesUpdated)
            }}
          >
            <Option
              checked={selectedDates.includes(date)}
              className="brand-option"
              disabled={counter === 0}
            >
              {date} ({counter || 0})
            </Option>
          </LiMenu>
        ))}
      </UlMenu>
    </Wrapper>
  )
}

export default DateMenu
