import { useDispatch, useSelector } from 'react-redux'
import {
  getSelectedBoardId,
  getSelectedWhiteboard,
  getSetSelectedBoardMenu,
} from '../../../../../store/whiteboard/selectors'
import whiteboardActions from '../../../../../store/whiteboard/actions'
import RCIcon from '../../../../../components/UI/RCIcon'
import React, { useEffect, useRef, useState } from 'react'
import Icon from '../../../../../styleguide/Icon'
import { BoardType } from '../../../../../model/whiteboard'
import classnames from 'classnames'
import {
  BoardButton,
  BoardButtonMenu,
  IconWrapper,
  Toolbar,
  AddBoardButton,
  BoardName,
  StyledIcon,
  Debug,
} from './style'
import { useGet4KDevice } from '../../../../../hooks/useGet4KDevice'
import { DebuggerFeatures } from '../../../../../Debug'
import { appSelectors } from '../../../../../store/app/selectors'

const BoardButtonContainer: React.FC<BoardType & {
  zIndex: number
  disableClone: boolean
  disableDelete: boolean
  variant?: 'compact'
  allBoards: BoardType[]
  isLocked?: boolean
}> = ({
  copyOfKey = '',
  name,
  zIndex,
  disableClone,
  disableDelete,
  variant,
  allBoards,
  isLocked,
  isNew,
}) => {
  const selectedBoardId = useSelector(getSelectedBoardId)
  const setSelectedBoardMenu = useSelector(getSetSelectedBoardMenu)
  const whiteboardDebugger = useSelector(
    appSelectors.isActiveDebugger(DebuggerFeatures.AFA_WHITEBOARD_DEBUGGER),
  )
  const dispatch = useDispatch()
  const compositeBoardMenuId = selectedBoardId + '-' + copyOfKey
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const buttonRef = useRef<HTMLDivElement>(null)
  const boardButtonMenuRef = useRef<HTMLDivElement>(null)
  const boardNameRef = useRef<HTMLDivElement>(null)
  const [boardName, setBoardName] = useState(name || '')
  const is4kDevice = useGet4KDevice()
  const inputRef = useRef<HTMLInputElement>(null)

  const untitledBoards = allBoards
    ? allBoards.filter(board => board.name?.toLowerCase().includes('untitled'))
    : []

  const currentBoardIndex = allBoards.findIndex(({ key }) => key === copyOfKey)
  const nextBoard = allBoards[currentBoardIndex + 1]
  const prevBoard = allBoards[currentBoardIndex - 1]

  useEffect(() => {
    if (setSelectedBoardMenu !== compositeBoardMenuId) {
      setOpen(false)
    }
  }, [setSelectedBoardMenu, compositeBoardMenuId])

  const handleSelectedBoard = () => {
    if (variant === 'compact') return
    dispatch(whiteboardActions.setSelectedBoardId(copyOfKey))
  }

  const handleSelectNextBoard = () => {
    if (nextBoard) dispatch(whiteboardActions.setSelectedBoardId(nextBoard.key))
  }

  const handleSelectPreviousBoard = () => {
    if (prevBoard) dispatch(whiteboardActions.setSelectedBoardId(prevBoard.key))
  }

  const handleSelectMenu = (event: React.MouseEvent) => {
    event.stopPropagation()
    selectedBoardId !== copyOfKey && handleSelectedBoard()
    setTimeout(() => {
      setOpen(open => !open)
    }, 50)
  }

  const cloneBoard = (event: React.MouseEvent) => {
    event.stopPropagation()
    setOpen(false)
    setEdit(false)
    dispatch(whiteboardActions.cloneBoard(copyOfKey))
  }

  const editBoard = (event: React.MouseEvent) => {
    event.stopPropagation()

    if (edit) {
      dispatch(whiteboardActions.editBoardName({ boardKey: copyOfKey, newName: boardName }))
    }

    setEdit(edit => !edit)

    setOpen(false)

    setTimeout(() => {
      inputRef.current?.focus()
    }, 500)
  }

  const handleCloseEditView = (event: Event) => {
    event.stopPropagation()
    const closeEditView =
      boardNameRef.current &&
      boardButtonMenuRef.current &&
      !boardNameRef.current.contains(event.target as Node) &&
      !boardButtonMenuRef.current.contains(event.target as Node)

    if (closeEditView) {
      setOpen(false)
      setEdit(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleCloseEditView)
    return () => {
      document.removeEventListener('click', handleCloseEditView)
    }
  })

  const rect = buttonRef?.current?.getBoundingClientRect()

  useEffect(() => {
    if (name === null && !boardName) {
      setEdit(true)
    }
  }, [name, boardName])

  useEffect(() => {
    if (edit) {
      inputRef?.current?.focus()
    }
  }, [edit, inputRef])

  useEffect(() => {
    if (edit && name === null && !boardName && inputRef) {
      inputRef.current?.focus()
    }
  }, [edit, name, boardName, inputRef])

  const setDefaultName = () => {
    setEdit(false)
    if (!boardName) {
      const newNameIndex = untitledBoards
        .filter(board => board.name?.toLowerCase().match(/^untitled\s/i))
        .map(board => board.name)
        .sort()
        .pop()
        ?.replace(/^untitled\s/i, '')
      const newName = `untitled ${newNameIndex ? Number(newNameIndex) + 1 : '1'}`
      setBoardName(newName)
      dispatch(whiteboardActions.editBoardName({ boardKey: copyOfKey, newName }))
    } else {
      setBoardName(boardName)
      dispatch(whiteboardActions.editBoardName({ boardKey: copyOfKey, newName: boardName }))
    }
  }

  const blurOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputRef.current) {
      event.preventDefault()
      inputRef.current.blur()
      setEdit(false)
      setOpen(false)
    }
  }

  const showBoardButton = variant !== 'compact' || selectedBoardId === copyOfKey

  return (
    <>
      {showBoardButton && (
        <BoardButton
          onClick={handleSelectedBoard}
          key={copyOfKey}
          ref={buttonRef}
          style={{ zIndex }}
          className={classnames({ selected: selectedBoardId === copyOfKey, edit })}
          variant={variant || ''}
        >
          {variant === 'compact' && (
            <div id="prev-board" onClick={handleSelectPreviousBoard}>
              <StyledIcon type="left" className={prevBoard ? '' : 'disabled'} />
            </div>
          )}

          <>
            {!edit && (
              <BoardName ref={boardNameRef} noAction={isLocked}>
                {boardName}
              </BoardName>
            )}
            {(isNew || edit) && (
              <input
                onChange={e => setBoardName(e.target.value)}
                ref={inputRef}
                value={boardName}
                type="text"
                onBlur={setDefaultName}
                onKeyDown={e => blurOnEnter(e)}
                autoFocus
              />
            )}
            {!isLocked && (
              <IconWrapper
                className={classnames({
                  selected: setSelectedBoardMenu === compositeBoardMenuId && open,
                })}
                onClick={handleSelectMenu}
                variant={variant || ''}
              >
                <Icon type="ellipsis" />
              </IconWrapper>
            )}
          </>

          {variant === 'compact' && (
            <div id="next-board" onClick={handleSelectNextBoard}>
              <StyledIcon type="right" className={nextBoard ? '' : 'disabled'} />
            </div>
          )}

          {whiteboardDebugger && (
            <Debug
              onClick={e => {
                e.stopPropagation()
                navigator.clipboard.writeText(copyOfKey)
              }}
            >
              {copyOfKey}
            </Debug>
          )}
        </BoardButton>
      )}
      {open && (
        <BoardButtonMenu
          ref={boardButtonMenuRef}
          style={{
            top: !is4kDevice
              ? variant === 'compact'
                ? (rect?.top || 0) - (rect?.height || 0) - 80
                : (rect?.bottom || 0) + 5
              : 'unset',
            left: is4kDevice ? 48 : rect?.x || 0,
            bottom: is4kDevice ? (rect?.height || 0) + 10 : 'unset',
          }}
        >
          <RCIcon onClick={editBoard} type="icon-pencil" />
          <RCIcon
            iconClass={classnames({ disabled: disableClone })}
            onClick={e => !disableClone && cloneBoard(e)}
            type="icon-duplicate"
          />
          <RCIcon
            iconClass={classnames({ disabled: disableDelete })}
            onClick={() => dispatch(whiteboardActions.setIsDeleteBoardModalOpen(true))}
            type="trash-icon"
          />
        </BoardButtonMenu>
      )}
    </>
  )
}

const BoardsBar = ({ variant, isLocked }: { variant?: 'compact'; isLocked: boolean }) => {
  const whiteboard = useSelector(getSelectedWhiteboard)
  const dispatch = useDispatch()

  const MAX_BOARDS = 6

  const isMaxBoardsReached = whiteboard?.boards && whiteboard.boards.length >= MAX_BOARDS

  return (
    <Toolbar>
      {whiteboard &&
        whiteboard.boards.map((board, index, all) => (
          <BoardButtonContainer
            {...board}
            key={board.key}
            copyOfKey={board.key}
            disableClone={whiteboard?.boards.length === MAX_BOARDS}
            disableDelete={whiteboard?.boards.length === 1}
            zIndex={all.length - index}
            variant={variant}
            allBoards={all}
            isLocked={isLocked}
          />
        ))}
      {!isMaxBoardsReached && !isLocked && (
        <AddBoardButton
          onClick={() => {
            dispatch(whiteboardActions.createBoard())
          }}
        >
          <Icon type="plus" />
        </AddBoardButton>
      )}
    </Toolbar>
  )
}

export default BoardsBar
