import { Table } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CartAnalyticsItem } from '../../../model/cart'
import { breakpoints, getFluidFontSize, palette } from '../../../style/theme'
import { getHeaderColumns } from '../../Graphs/config'

const TableStyled = styled(Table)`
  .ant-table {
    font-size: ${getFluidFontSize('8px', 9)};

    .ant-table-thead {
      th {
        padding: 10px 6px;
        color: ${palette.hibiscus};
        font-weight: 900;
        border-bottom: none;
        border-color: ${palette.hibiscus};
        background-color: transparent;
        > div {
          word-break: normal;
          word-wrap: break-word;
          line-height: 2;
          padding: 0 0.4em;
        }
        &.bordered {
          > div {
            background-color: ${palette.wildSand};
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60px;
            @media screen and (max-width: ${breakpoints.M}) {
              height: 55.33px;
            }
          }
        }
        &.bordered-padded {
          div {
            padding: 0.4em !important;
            @media screen and (max-width: ${breakpoints.L}) {
              padding: 0.4rem 0.15rem !important;
            }
            @media screen and (max-width: ${breakpoints.M}) {
              padding: 0.65rem 0.3rem !important;
            }
          }
          &.ipad-size-fix div {
            @media screen and (max-width: ${breakpoints.M}) {
              padding: 0.8rem 0.3rem 0.85rem !important;
            }
          }
        }
        &.ipad-last-column-fix div {
          @media screen and (max-width: ${breakpoints.L}) {
            padding-left: 0.1rem;
            padding-right: 0.1rem;
          }
        }
        &.bordered_left > div {
          border-left: 1px solid;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          padding-left: 0.5em;
        }
        &.bordered_top > div {
          border-top: 1px solid;
        }
        &.bordered_right > div {
          border-right: 1px solid;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          padding-right: 0.5em;
        }
        &.bordered_bottom > div {
          border-bottom: 1px solid;
        }
        &.coupled_left {
          padding-left: 0;
        }
        &.coupled_right {
          padding-right: 0;
        }
      }
    }

    .ant-table-tbody {
      font-size: 1.3em;
      > tr {
        > td {
          padding-left: 5px;
          padding-right: 5px;
          word-break: normal;
          word-wrap: break-word;
          color: #338acc;
        }
        &:not(:hover) > td.grey_bg {
          background-color: ${palette.wildSand};
        }
        &:first-child > td {
          border: 1px solid ${palette.congressBlue};
          border-left: none;
          border-right: none;
          font-weight: 900;
          background-color: ${palette.selago};
          &:first-child {
            border-left: 1px solid ${palette.congressBlue};
            border-radius: 4px 0 0 4px;
            color: ${palette.hibiscus};
          }
          &:last-child {
            border-right: 1px solid ${palette.congressBlue};
            border-radius: 0 4px 4px 0;
          }
        }
      }
    }

    .ant-table-thead,
    .ant-table-tbody > tr > th,
    td {
      text-align: center;
      background-color: ${palette.white};
    }
  }
`

type Props = {
  dataSource: CartAnalyticsItem[]
  className?: string
}

const TableAnalytics: React.FC<Props> = ({ dataSource, className }) => {
  const { t } = useTranslation()

  const headerColumns = getHeaderColumns(t)

  const columns = Object.entries(headerColumns).map(([headerKey, headerValue]) => {
    return {
      title: headerValue.title.toUpperCase(),
      className: 'classNames' in headerValue ? headerValue.classNames.join(' ') : undefined,
      dataIndex: headerKey,
      key: headerKey,
    }
  })

  return (
    <TableStyled
      className={className}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
    />
  )
}

export default TableAnalytics
