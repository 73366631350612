import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import MyShop from '../../components/Cart/MyShop'
import TableCartContent from '../../components/Cart/TableCartContent'
import app_config from '../../config/app/config'
import devices from '../../config/devices'
import socketManager from '../../libs/socketManager'
import { getSelectedBrandFromUrl, getUrlParts, isLargeRoom } from '../../libs/url'
import { getReverseViewType } from '../../libs/view'
import { BrandCode, CategoryId } from '../../model/model'
import {
  changeAssortmentViewType,
  fullResetFiltersAction,
  resetCartView,
  setActiveBrandAction,
  setFiltersActivePageName,
} from '../../store/actions'
import { assortmentsViewTypeSelector, cartViewTypeSelector } from '../../store/cart/selectors'
import { isPdpVisibleSelector } from '../../store/pdp/selectors'
import FooterNav from '../FooterNav'

export const getDefaultActiveBrand = (
  openedBrandCodes: BrandCode[],
  brandCodesInCart: BrandCode[],
) => {
  // select first brand as default
  const brandFromProps = brandCodesInCart.some(code => openedBrandCodes.includes(code))
    ? openedBrandCodes[0]
    : brandCodesInCart[0]
  return brandFromProps || getSelectedBrandFromUrl()
}

const emitNavigationEvent = ({
  viewType,
  assortmentsViewType,
}: {
  viewType: string
  assortmentsViewType: { type?: string; category: string }
}) => {
  socketManager.emitSignal(
    'open_tableCart_page',
    {
      viewType:
        assortmentsViewType.type === app_config.viewType.leds
          ? assortmentsViewType.category
          : getReverseViewType(viewType),
      roomType: getUrlParts().roomType,
    },
    devices.not([devices.extraipad]),
  )
}

const TableCartContainer: React.FC = () => {
  const viewType = useSelector(cartViewTypeSelector)
  const assortmentsViewType = useSelector(assortmentsViewTypeSelector)
  const isPdpVisible = useSelector(isPdpVisibleSelector)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetCartView())

    const defaultCategory = isLargeRoom() ? (app_config.sunCategory as CategoryId) : null
    dispatch(changeAssortmentViewType(defaultCategory || undefined))
    dispatch(setFiltersActivePageName('cart'))
    dispatch(fullResetFiltersAction())

    return () => {
      dispatch(resetCartView()) // fix: 3081
    }
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(setActiveBrandAction(null))
    }
  }, [dispatch])

  useEffect(() => {
    if (viewType && assortmentsViewType) {
      emitNavigationEvent({ viewType, assortmentsViewType } as {
        viewType: string
        assortmentsViewType: { category: string }
      })
    }
  }, [viewType, assortmentsViewType])

  useEffect(() => {
    // NOTE: when pdp gets closed, table should broadcast again the navigation event
    if (isPdpVisible === false && viewType && assortmentsViewType) {
      emitNavigationEvent({ viewType, assortmentsViewType } as {
        viewType: string
        assortmentsViewType: { category: string }
      })
    }
  }, [isPdpVisible, viewType, assortmentsViewType])

  return (
    <>
      {viewType === app_config.viewType.table ? <TableCartContent /> : <MyShop />}
      <FooterNav />
    </>
  )
}

export default TableCartContainer
