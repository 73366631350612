import qs from 'qs'
import { useSelector } from 'react-redux'

import { createApi } from '@reduxjs/toolkit/query/react'

import app_config from '../config/app/config'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { languageSelector } from '../store/app/selectors'
import { useEffect } from 'react'
import { logCmsError } from '../libs/handleError'

type SportName = string
type ContentData = {
  mimeType: string
  url: string
  alt: string
}
type CategoryName = string

type AssortmentName = string
type Url = string

type Moodboard = {
  title: string
  text: string
  images: Url[]
}
type KeyLookName = string

type IconData = {
  tag: string
  url: string
}

export const afaContentApi = createApi({
  reducerPath: 'afaContentApi',
  baseQuery: staggeredBaseQueryWithBailOut(`${app_config.apiUrl}/content/afa`),
  endpoints: builder => ({
    getCategoriesPageContents: builder.query<
      {
        sports: Record<SportName, ContentData>
        categories: Record<CategoryName, ContentData>
        wallImage?: ContentData
      },
      {
        language: string
        brandCode: string
      }
    >({
      query: params => ({
        url: `/categories-page?${qs.stringify(params)}`,
      }),
    }),

    getSizeGuideHtml: builder.query<
      { url: string },
      {
        language: string
        brandCode: string
        sport: string
      }
    >({
      query: params => ({
        url: `/size-guide?${qs.stringify(params)}`,
      }),
    }),

    getAvdInfoHtml: builder.query<
      { url: string },
      {
        language: string
        brandCode: string
      }
    >({
      query: params => ({
        url: `/avd-info?${qs.stringify(params)}`,
      }),
    }),

    getTechnologyInfoHtml: builder.query<
      { url: string },
      {
        language: string
        brandCode: string
      }
    >({
      query: params => ({
        url: `/technology-info?${qs.stringify(params)}`,
      }),
    }),

    getTechnologyIcons: builder.query<
      {
        tech: IconData[]
        kspTechnology: IconData[]
        kspSustainability: IconData[]
      },
      {
        language: string
        brandCode: string
      }
    >({
      query: params => ({
        url: `/technology-icons?${qs.stringify(params)}`,
      }),
    }),

    getAssortmentContent: builder.query<
      Record<AssortmentName, Url>,
      {
        language: string
      }
    >({
      query: params => ({
        url: `/assortment?${qs.stringify(params)}`,
      }),
    }),

    getBrandContent: builder.query<
      {
        moodboardSport?: Moodboard
        moodboardKeyLooks: Record<KeyLookName, Moodboard>
        keyLooksImages: Record<KeyLookName, Url>
        brandNavigationImage: {
          sx: Url
          dx: Url
        }
      },
      {
        language: string
        sport: string
        brandCode: string
      }
    >({
      query: ({ brandCode, ...params }) => ({
        url: `/brand/${brandCode}?${qs.stringify(params)}`,
      }),
    }),

    getBrandsLogo: builder.query<
      {
        brandCode: string
        logoUrl: string
      }[],
      void
    >({
      query: () => ({
        url: '/brands-logo',
      }),
    }),

    getBrandActivation: builder.query<
      Url[],
      {
        brandCode: string
        language: string
      }
    >({
      query: ({ brandCode, ...params }) => ({
        url: `/brand/${brandCode}/activation?${qs.stringify(params)}`,
      }),
    }),
  }),
})

export const useGetCategoriesPageContentsQuery = (brandCode: string) => {
  const language = useSelector(languageSelector)

  const categoriesPageContentsQuery = afaContentApi.useGetCategoriesPageContentsQuery({
    language,
    brandCode,
  })

  const { isSuccess, data: categoriesPageContents } = categoriesPageContentsQuery

  useEffect(() => {
    if (isSuccess && Object.keys(categoriesPageContents?.categories || {}).length === 0) {
      logCmsError({ message: 'Missing contents', operationNamespace: 'AFA Categories' })
    }
  }, [categoriesPageContents?.categories, isSuccess])

  return categoriesPageContentsQuery
}

export const useGetSizeGuideHtmlQuery = (
  {
    brandCode,
    sport,
  }: {
    brandCode?: string
    sport?: string
  },
  options?: { skip?: boolean },
) => {
  const language = useSelector(languageSelector)

  return afaContentApi.useGetSizeGuideHtmlQuery(
    {
      language,
      brandCode: brandCode || '',
      sport: sport || '',
    },
    { skip: options?.skip || !brandCode || !sport },
  )
}

export const useGetAvdInfoHtmlQuery = (brandCode?: string, options?: { skip?: boolean }) => {
  const language = useSelector(languageSelector)

  return afaContentApi.useGetAvdInfoHtmlQuery(
    {
      language,
      brandCode: brandCode || '',
    },
    { skip: options?.skip || !brandCode },
  )
}

export const useGetTechnologyInfoHtmlQuery = (brandCode = '', options?: { skip?: boolean }) => {
  const language = useSelector(languageSelector)

  return afaContentApi.useGetTechnologyInfoHtmlQuery(
    {
      language,
      brandCode,
    },
    { skip: options?.skip || !brandCode },
  )
}

export const useGetTechnologyIconsQuery = (brandCode = '', options?: { skip?: boolean }) => {
  const language = useSelector(languageSelector)

  return afaContentApi.useGetTechnologyIconsQuery(
    {
      language,
      brandCode,
    },
    { skip: options?.skip || !brandCode },
  )
}

export const useGetAssortmentContentQuery = (options?: { skip?: boolean }) => {
  const language = useSelector(languageSelector)

  return afaContentApi.useGetAssortmentContentQuery(
    {
      language,
    },
    options,
  )
}

export const useGetBrandContentQuery = (
  brandCode = '',
  sport = '',
  options?: { skip?: boolean },
) => {
  const language = useSelector(languageSelector)

  return afaContentApi.useGetBrandContentQuery(
    {
      language,
      sport,
      brandCode,
    },
    { skip: options?.skip || !brandCode },
  )
}

export const useGetBrandActivationQuery = (brandCode = '', options?: { skip?: boolean }) => {
  const language = useSelector(languageSelector)

  return afaContentApi.useGetBrandActivationQuery(
    {
      language,
      brandCode,
    },
    { skip: options?.skip || !brandCode },
  )
}

export const { useGetBrandsLogoQuery } = afaContentApi

export const useGetBrandLogo = (brandCode: string) => {
  const { brandLogo, isSuccess } = afaContentApi.useGetBrandsLogoQuery(undefined, {
    selectFromResult: ({ data, isSuccess }) => ({
      isSuccess: isSuccess,
      brandLogo: data?.find(logoInfo => logoInfo.brandCode === brandCode)?.logoUrl,
    }),
  })

  return { brandLogo, isSuccess }
}
