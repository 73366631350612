import React, { SVGProps } from 'react'
import { palette } from '../../style/theme'

const SortRestingIcon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={21}
    fill="none"
    viewBox="0 0 15 21"
    {...props}
  >
    <path
      d="M7.273 20.5 0 11.41h14.546L7.273 20.5zm0-20L0 9.59h14.546L7.273.5z"
      fill={palette.manatee}
    />
  </svg>
)

export default SortRestingIcon
