import classnames from 'classnames'
import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { handleImageError } from '../../../libs/handleError'
import { Sizes } from '../../../model/product'
import { breakpoints, getFluidFontSize, palette } from '../../../style/theme'
import RCIcon from '../../UI/RCIcon'

const Wrapper = styled.div`
  width: 92%;
  height: 76%;
  position: absolute;
  top: 3vh;
  left: 4%;
  background: white;
  border-radius: 10px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  display: grid;
  padding: 1.5em;
  grid-template-columns: 20% 8% 11% 15% 21% 17% 8%;
  grid-template-rows: 55% 15% 15% 15%;
  grid-template-areas:
    'height image  image  image  image  image  image'
    'empty3 empty1 empty6 bridge empty2 empty4 empty4'
    'empty3 empty1 empty6 empty7 size   empty4 empty4'
    'empty3 empty1 hinge  hinge  hinge  temple empty5';
`
Wrapper.displayName = 'ProductSizes'

const ProductImageWrapper = styled.div`
  grid-area: image;
  position: relative;
`
const ProductImage = styled.img`
  width: 102%;
  position: absolute;
  bottom: -5%;
  left: -2%;
`

ProductImage.displayName = 'ProductImage'

const CloseBtn = styled.button`
  position: absolute;
  right: 1vmin;
  padding: 10px 15px;
  border-radius: 10px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  width: auto;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: ${palette.white};
  font-size: ${getFluidFontSize('14px')};
  &.in-bottom-of-view {
    bottom: 1vmin;
  }
  &.in-top-of-view {
    top: 1vmin;
  }
  .rc-icon {
    margin-right: 0.5rem;
  }
  @media screen and (max-width: ${breakpoints.L}) {
    right: 1vw;
    padding: 0.7vh 0.4vw;
  }
`

const SizePlank = styled.div`
  text-align: center;
  display: inline-block;
  font-size: ${getFluidFontSize('10px')};
  position: absolute;
  transition: opacity 0.3s;
  opacity: 1;

  .text-invisible & {
    opacity: 0;
  }

  span {
    display: inline-block;
    text-transform: lowercase;
    &:first-letter {
      text-transform: uppercase;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  div span:last-child {
    font-weight: bold;
  }

  &.height-diagonal {
    grid-area: height;
    display: flex;
    align-items: center;
    height: 90%;
    margin-top: 10%;
    place-self: center right;

    .label {
      margin-right: 1em;
    }
  }
  &.bridge {
    grid-area: bridge;
    width: 100%;
  }
  &.calibro {
    grid-area: size;
    width: 100%;
  }
  &.hinge-to-hinge {
    grid-area: hinge;
    width: 100%;
  }
  &.arm-length {
    grid-area: temple;
    width: 100%;
  }
`

const arrowCss = css`
  background-color: ${palette.silver};
  position: relative;
  &::before,
  &::after {
    content: '';
    box-sizing: content-box;
    width: 0;
    height: 0;
    border: 4px solid transparent;
    position: absolute;
    top: 0;
  }
  &::before {
    border-top-color: ${palette.silver};
    border-left-color: ${palette.silver};
    transform: rotate(-45deg) translate(50%, 0);
    left: 0;
  }
  &::after {
    border-top-color: ${palette.silver};
    border-right-color: ${palette.silver};
    transform: rotate(45deg) translate(-50%, 0);
    right: 0;
  }
`

const Arrow = styled.div`
  width: 100%;
  height: 2px;
  ${arrowCss}
`

const VerticalArrow = styled.div`
  height: 100%;
  width: 2px;
  ${arrowCss}
  &::before {
    transform: rotate(45deg) translate(-50%, 0);
    top: 0;
  }
  &::after {
    transform: rotate(135deg) translate(-50%, 0);
    top: auto;
    bottom: 0;
  }
`

const addMM = (item: string) => (item ? `${item}mm` : '')

const SizeElement: React.FC<{ sizeText: string; translationKey: string }> = ({
  sizeText,
  translationKey,
}) => {
  const { t } = useTranslation()
  return sizeText.length > 0 ? (
    <div>
      <span>{t(`ProductPage.${translationKey}`)}</span>
      <br />
      <span className={classnames({ 'small-sizes': sizeText.length > 16 })}>{sizeText}</span>
    </div>
  ) : null
}

const mapSizesFor = (sizes: Sizes, property: string) => {
  // TODO: check how many sizes are there and make font smaller if there are a lot of sizes
  const values = [] as string[]
  let sizesString = ''

  Object.values(sizes).forEach(size => {
    const value = ((size as any) as Record<string, { label: string }>)[property]

    if (value && values.indexOf(value.label) === -1 && value.label !== '0' && value.label !== '') {
      values.push(value.label)
    }
  })

  sizesString = sizesString = values.reduce(
    (accumulator, currentValue) => `${accumulator} / ${addMM(currentValue)}`,
    addMM(values.splice(0, 1)[0]),
  )

  return sizesString
}

const SizeItemElement: React.FC<{
  translationKey: string
  sizeKey: string
  className: string
  sizes: Sizes
}> = ({ translationKey, sizeKey, className, sizes }) => {
  const sizeText = mapSizesFor(sizes, sizeKey)

  return sizeText.length > 0 ? (
    <SizePlank
      className={classnames({
        'size-plank': true,
        [className]: true,
      })}
    >
      <div>
        <SizeElement sizeText={sizeText} translationKey={translationKey} />
      </div>
      <Arrow />
    </SizePlank>
  ) : null
}

type Props = {
  image: string
  close: () => void
  sizes: Sizes
  tre60?: ReactElement
}

const ProductSizes: React.FC<Props> = ({ sizes, close, image, tre60 }) => {
  const [cdm, setCdm] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setTimeout(() => {
      setCdm(true)
    }, 100)
  }, [])

  const heightSize = mapSizesFor(sizes, 'lensHeight')
  const lensDiagonalSize = mapSizesFor(sizes, 'lensDiagonal')

  return (
    <Wrapper
      data-testid="product-sizes"
      className={classnames({
        'product-sizes': true,
        'text-invisible': !cdm,
      })}
    >
      {tre60 ? (
        tre60
      ) : (
        <>
          <ProductImageWrapper>
            <ProductImage src={image} alt="product image" onError={handleImageError} />
          </ProductImageWrapper>
          {(heightSize.length > 0 || lensDiagonalSize.length > 0) && (
            <SizePlank className="size-plank height-diagonal">
              <div className="label">
                <SizeElement sizeText={heightSize} translationKey="height" />
                <SizeElement sizeText={lensDiagonalSize} translationKey="lensDiagonal" />
              </div>
              <VerticalArrow />
            </SizePlank>
          )}
          <SizeItemElement
            translationKey="bridge"
            sizeKey="bridgeSize"
            className="bridge"
            sizes={sizes}
          />
          <SizeItemElement
            translationKey="size"
            sizeKey="lensSize"
            className="calibro"
            sizes={sizes}
          />
          <SizeItemElement
            translationKey="item_hingeToHinge"
            sizeKey="hingesDistances"
            className="hinge-to-hinge"
            sizes={sizes}
          />
          <SizeItemElement
            translationKey="item_templelength"
            sizeKey="templeLength"
            className="arm-length"
            sizes={sizes}
          />
        </>
      )}
      <CloseBtn
        onClick={close}
        className={classnames({
          'close-sizes': true,
          'in-bottom-of-view': tre60,
          'in-top-of-view': !tre60,
        })}
      >
        <RCIcon type="close" />
        <span>{t('GenericWords.close')}</span>
      </CloseBtn>
    </Wrapper>
  )
}

export default ProductSizes
