import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import ChevronDownIcon from '../../components/icons/ChevronDownIcon'
import FilterIcon from '../../components/icons/FilterIcon'
import afaActions from '../../store/afa/actions'
import { filtersOpenSelector } from '../../store/afa/selectors'
import { breakpoints, duration, getFluidSizeWithFullFormula, pxToRem } from '../../style/theme'

const Button = styled.div`
  display: flex;
  gap: ${pxToRem(6)}rem;
  align-items: center;
  text-transform: capitalize;
`

const FilterIconStyled = styled(FilterIcon)`
  width: ${getFluidSizeWithFullFormula('px', 16, 24, 1366, 3840)};
  height: ${getFluidSizeWithFullFormula('px', 16, 24, 1366, 3840)};
  display: none;

  @media (max-width: ${breakpoints.M}) {
    display: inline-block;
  }
`

const AnimatedChevronIcon = styled(ChevronDownIcon)`
  width: ${getFluidSizeWithFullFormula('px', 16, 24, 1366, 3840)};
  height: ${getFluidSizeWithFullFormula('px', 16, 24, 1366, 3840)};
  transition: transform ${duration.short}ms;
  transform: rotate(-90deg);

  &.is-open {
    transform: rotate(90deg);
  }

  @media (max-width: ${breakpoints.M}) {
    transform: rotate(180deg);

    &.is-open {
      transform: rotate(0);
    }
  }
`

type Props = {
  className?: string
}

const AfaFilterButton: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation()

  const filtersOpen = useSelector(filtersOpenSelector)

  const dispatch = useDispatch()

  return (
    <Button onClick={() => dispatch(afaActions.toggleFiltersOpen())} className={className}>
      <FilterIconStyled />
      {t('Filters.item_filterby')}
      <AnimatedChevronIcon className={filtersOpen ? 'is-open' : ''} />
    </Button>
  )
}

export default AfaFilterButton
