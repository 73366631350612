import { setActiveBrandAction } from './index'
import socketManager from '../../../libs/socketManager'
import { checkIfCartUpdateIsNeeded } from './updateCart'
import devices, { cartSynchedDevices } from '../../../config/devices'
import { AppThunk } from '../../../model/model'

import { slice } from '../slice'

export const subscribeToCartMessagesAction = (): AppThunk => (dispatch, getState) => {
  socketManager.bindListener('cart_updated', ({ device, lastAddedProductRecap }: any) => {
    if (devices.isCurrentDeviceAuthorized([devices.wall]) && device !== 'extra-ipad') {
      dispatch(slice.actions.setLastAddedProductRecap(lastAddedProductRecap))
    }
    dispatch(checkIfCartUpdateIsNeeded(device))
  })

  socketManager.bindListener('change_active_brand', ({ response, requestData }: any) => {
    if (devices.isCurrentDeviceToSync(cartSynchedDevices, requestData.device)) {
      const { activeBrand } = response
      const state = getState()

      if (activeBrand !== state.cart.activeBrand) {
        dispatch(setActiveBrandAction(activeBrand, false))
      }

      return response
    }
  })
}
