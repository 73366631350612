import '@fortawesome/fontawesome-free/css/all.css'

import { Modal } from 'antd'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'
import styled from 'styled-components'

import { useUpdateCartMetadata } from '../../../../hooks/useUpdateCart'
import { getDeliveryDate } from '../../../../libs/cart'
import { isNotUndefined } from '../../../../libs/utils'
import { CartProduct, CheckoutProduct } from '../../../../model/cart'
import { Door } from '../../../../model/customer'
import {
  cartIsLoadedSelector,
  cartProductsDetailsSelector,
  cartProductsSelector,
} from '../../../../store/cart/selectors'
import {
  selectCheckoutFilters,
  selectCheckoutFiltersToShow,
  selectCheckoutProducts,
  selectFilteredCheckoutProducts,
} from '../../../../store/checkout/selectors'
import { customerDoorsSelector } from '../../../../store/customer/selectors'
import { getFluidFontSize, getFluidSize, palette, pxToRem, spacing } from '../../../../style/theme'
import CalendarIcon from '../../../../components/icons/CalendarIcon'
import Loading from '../../../../components/Loading'
import RCIcon from '../../../../components/UI/RCIcon'
import {
  CartProductsListRow,
  CustomDatePicker,
  CustomInput,
  LabelInput,
  Section,
  WrapperSection,
} from '../Common'
import FilterChip from './FilterChip'
import ProcessTableRow from './ProcessTableRow'
import { ANT_MODAL_Z_INDEX } from '../../../../style/GlobalStyle'

const ProcessWrapper = styled.div`
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
`

const FiltersContainer = styled.div`
  width: 100%;
  border-radius: ${pxToRem(spacing(0.5))}rem;
  background-color: ${palette.white};
  color: ${palette.niceBlue};
`

const StatusContainer = styled.div`
  width: 100%;
  border-radius: ${pxToRem(spacing(0.5))}rem;
  background-color: ${palette.selago};
  border: 1px solid ${palette.cornFlower};
  color: ${palette.blueStars};
  text-transform: uppercase;
`

const CartProductsList = styled.div`
  width: 100%;
  text-align: center;
  flex: 1;
  background-color: ${palette.white};
  display: flex;
  flex-direction: column;
  color: ${palette.biscay};
  font-size: ${getFluidFontSize('12px', 15)};
  font-weight: 500;
`

const ListHead = styled(CartProductsListRow)`
  background: linear-gradient(180deg, ${palette.tropicalBlue} 4%, ${palette.selago});
  font-size: ${getFluidFontSize('12px', 15)};
`

const ListLabels = styled(CartProductsListRow)`
  border-bottom: ${pxToRem(spacing(0.2))}rem solid;
  border-color: ${palette.geyser};
  background: ${palette.white};
  font-size: ${getFluidFontSize('14px', 20)};
`

const ListCell = styled.div``

const ListBody = styled.div`
  flex: 1;
`

const NumberOfRowsCell = styled.div`
  margin: ${pxToRem(spacing(1.5))}rem 0;
  display: flex;
  color: ${palette.biscay};
  padding: ${getFluidSize(`${pxToRem(10)}rem`, 15)} ${getFluidSize(`${pxToRem(10)}rem`, 20)};
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: ${pxToRem(spacing(0.5))}rem;
  font-size: ${getFluidFontSize(`${pxToRem(12.5)}rem`, 15)};
  font-family: 'Avenir-Heavy', sans-serif;

  span:first-of-type {
    text-transform: capitalize;
  }
`

const DateCell = styled(ListCell)`
  grid-column-start: 6;
`

const ListHeaderCell = styled.div`
  display: flex;
  color: ${palette.hibiscus};
  margin: ${getFluidSize(`${pxToRem(15)}rem`, 15)} 0;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  flex-direction: row;
  gap: ${pxToRem(spacing(1))}rem;
  cursor: pointer;
`

const ColumnLabel = styled.span`
  font-size: ${getFluidSize(`${pxToRem(12)}rem`, 15)};
`

const ArrowWrapper = styled.div`
  display: flex;
  color: ${palette.hibiscus};
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  flex-direction: column;

  .anticon {
    height: 1.5em;
    width: 1.5em;
    cursor: pointer;
  }
`

const InputWrapperHeadReference = styled.div`
  border: solid 1px ${palette.bondiBlueDark};
  border-radius: ${pxToRem(spacing(0.5))}rem;
  background: ${palette.white};
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: ${pxToRem(8)}rem ${pxToRem(12)}rem;

  .anticon {
    height: 0.8em;
    width: 0.8em;
    margin-right: ${pxToRem(spacing(1))}rem;
    z-index: 1000;
    cursor: pointer;
  }
`

const ApplyButton = styled.button`
  background: ${palette.white};
  border-radius: ${pxToRem(spacing(3))}rem;
  text-transform: uppercase;
  padding: ${getFluidSize(`${pxToRem(5)}rem`, 15)} ${getFluidSize(`${pxToRem(30)}rem`, 15)};
  color: ${palette.biscay};
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px ${palette.concrete};

  &.disable {
    opacity: 0.5;
    pointer-events: none;
    color: ${palette.alto};
  }
`

const WrapperBodyApply = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${pxToRem(spacing(6))}rem;
`

const BodyApplyText = styled.p`
  text-align: left;
  font-weight: 500;
  font-size: ${pxToRem(spacing(2))}rem;
  color: ${palette.tangaroa};
`

const BodyApplyTextDate = styled.span`
  font-weight: bold;
  font-style: italic;
  font-size: ${pxToRem(spacing(2))}rem;
  color: ${palette.tangaroa};
  margin-left: ${pxToRem(spacing(1))}rem;
  margin-right: ${pxToRem(spacing(1))}rem;
  text-transform: uppercase;
`

const BodyApplyTextReference = BodyApplyTextDate

const WrapperButtonBodyApply = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${pxToRem(spacing(4))}rem;
`

const ButtonBodyApplyCancel = styled.button`
  background: ${palette.white};
  border: 1px solid ${palette.tangaroa};
  color: ${palette.tangaroa};
  padding: ${pxToRem(spacing(1))}rem ${pxToRem(spacing(1.5))}rem;
  font-size: ${pxToRem(spacing(2))}rem;
  text-transform: uppercase;
  border-radius: ${pxToRem(spacing(0.5))}rem;
`

const ButtonBodyApplyConfirm = styled.button`
  background: ${palette.tangaroa};
  color: ${palette.white};
  padding: ${pxToRem(spacing(1))}rem ${pxToRem(spacing(1.5))}rem;
  font-weight: 800;
  font-size: ${pxToRem(spacing(2))}rem;
  text-transform: uppercase;
  border-radius: ${pxToRem(spacing(0.5))}rem;
`

const WrapperNoProducts = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${palette.white};
  font-size: ${getFluidFontSize('14px', 15)};
  text-transform: uppercase;
  color: ${palette.tangaroa};
  height: 100%;
  align-items: center;

  span {
    padding: ${pxToRem(spacing(2))}rem;
    font-weight: bold;
  }
`

const getUpdatedCheckoutProducts = (
  oldProducts: CheckoutProduct[],
  newProducts: CheckoutProduct[],
) => {
  return oldProducts
    .map(product => {
      const newProduct = newProducts.find(
        ({ upc, doorId, status, quantity, confirmedQuantity, deliveryDate, reference }) =>
          upc === product.upc &&
          doorId === product.doorId &&
          status === product.status &&
          quantity === product.quantity &&
          confirmedQuantity === product.confirmedQuantity &&
          (deliveryDate === product.deliveryDate || reference === product.reference),
      )
      return newProduct
    })
    .filter(isNotUndefined)
}

const getDeliveryDateWithFallback = (product: CheckoutProduct) => {
  const date = new Date(getDeliveryDate(product.deliveryDate, product.minDeliveryDate, true))
  return isNaN(date.getTime()) ? new Date(0) : date
}

const isOutOfAssortment = (product: CheckoutProduct) => {
  return product.outOfAssortment && product.status === '1'
}

const sortByOutOfAssortment = (p1: CheckoutProduct, p2: CheckoutProduct) => {
  if (isOutOfAssortment(p1) && !isOutOfAssortment(p2)) {
    return -1
  } else if (isOutOfAssortment(p2) && !isOutOfAssortment(p1)) {
    return 1
  } else {
    return 0
  }
}

const sortProducts = (products: CheckoutProduct[], sortInfo: SortInfo, doors: Door[]) => {
  const order = sortInfo.order === 'asc' ? 1 : -1
  return products.sort((p1, p2) => {
    const sortBySku = p1.skuCode.localeCompare(p2.skuCode)

    const sortByValue = () => {
      if (sortInfo.key === 'deliveryDate') {
        const firstDate = getDeliveryDateWithFallback(p1)
        const secondDate = getDeliveryDateWithFallback(p2)
        if (firstDate > secondDate) {
          return 1
        } else if (firstDate < secondDate) {
          return -1
        } else {
          return 0
        }
      }

      if (sortInfo.key === 'gtm') {
        const firstValue = doors.find(({ id }) => id === p1.doorId)?.gtmRating ?? '9999999999999' // if nullish it goes last
        const secondValue = doors.find(({ id }) => id === p2.doorId)?.gtmRating ?? '9999999999999' // if nullish it goes last
        return firstValue.localeCompare(secondValue)
      }

      if (typeof p1[sortInfo.key] === 'number' || typeof p2[sortInfo.key] === 'number') {
        const firstValue = p1[sortInfo.key] ?? 9999999999999 // if nullish it goes last
        const secondValue = p2[sortInfo.key] ?? 9999999999999 // if nullish it goes last
        if (firstValue > secondValue) {
          return 1
        } else if (firstValue < secondValue) {
          return -1
        } else {
          return 0
        }
      } else {
        const firstValue = p1[sortInfo.key] ?? '9999999999999' // if nullish it goes last
        const secondValue = p2[sortInfo.key] ?? '9999999999999' // if nullish it goes last
        return firstValue.toString().localeCompare(secondValue.toString())
      }
    }

    return (sortByOutOfAssortment(p1, p2) * order || sortByValue() || sortBySku) * order
  })
}

type TableFields = Partial<CartProduct> & { gtm: 'BP' | 'BPF' | 'P' | 'PF' | 'D' }

type SortInfo = {
  key: keyof TableFields
  order: 'asc' | 'disc'
}

const ProcessTable: React.FC = () => {
  const { t } = useTranslation()

  const checkoutProducts = useSelector(selectCheckoutProducts)
  const checkoutProductsFiltered = useSelector(selectFilteredCheckoutProducts)
  const doors = useSelector(customerDoorsSelector)

  const cartProductDetails = useSelector(cartProductsDetailsSelector)
  const cartProducts = useSelector(cartProductsSelector)

  const filters = useSelector(selectCheckoutFilters)
  const filtersToShow = useSelector(selectCheckoutFiltersToShow(true))

  const filtersApplied = Object.values(filters).some(({ active }) => active.length)

  const [date, setDate] = useState<string | undefined>('')
  const [reference, setReference] = useState<string | undefined>('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [sortInfo, setSortInfo] = useState<SortInfo>({ key: 'modelCode', order: 'asc' })
  const [sortedInfo, setSortedInfo] = useState<SortInfo>({ key: 'modelCode', order: 'asc' })

  const cartIsLoaded = useSelector(cartIsLoadedSelector)

  const updateCartMetadata = useUpdateCartMetadata()

  const [sortedProducts, setSortedProducts] = useState<CheckoutProduct[]>(
    sortProducts(checkoutProductsFiltered, sortInfo, doors),
  )

  useEffect(() => {
    if (
      sortedProducts.length !== checkoutProductsFiltered.length ||
      sortedInfo.key !== sortInfo.key ||
      sortedInfo.order !== sortInfo.order
    ) {
      setSortedProducts(sortProducts(checkoutProductsFiltered, sortInfo, doors))
      setSortedInfo(sortInfo)
      return
    }
    const updatedProducts = getUpdatedCheckoutProducts(sortedProducts, checkoutProductsFiltered)
    if (JSON.stringify(updatedProducts) !== JSON.stringify(sortedProducts)) {
      setSortedProducts(updatedProducts)
    }
  }, [
    checkoutProductsFiltered,
    sortedProducts,
    sortInfo.key,
    sortInfo.order,
    sortInfo,
    sortedInfo,
    doors,
  ])

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setDate('')
    setReference('')
    setIsModalVisible(false)
  }

  const formatDateYmD = (d: string) => {
    return d
      .split('-')
      .reverse()
      .join('-')
  }

  const tableHeadArr: [string, keyof TableFields][] = [
    [t('Checkout.Process.Model'), 'modelCode'],
    [t('Checkout.Process.Quantity'), 'quantity'],
    [t('Checkout.Process.Door'), 'doorId'],
    [t('Checkout.Process.GTM'), 'gtm'],
    [t('Checkout.Process.DeliveryDate'), 'deliveryDate'],
    [t('Checkout.Process.Reference'), 'reference'],
  ]

  const productQuantity = checkoutProductsFiltered.reduce((result, product) => {
    return result + product.quantity
  }, 0)

  const productConfirmed = checkoutProductsFiltered.reduce((result, product) => {
    return result + (product.confirmedQuantity || 0)
  }, 0)

  if (productConfirmed > 0) {
    tableHeadArr.splice(1, 0, [t('Checkout.Process.Sent'), 'confirmedQuantity'])
  }

  const disableDates = () => {
    const sortedMinDeliveryDates = cartProducts
      .map(cartProducts => formatDateYmD(cartProducts.minDeliveryDate || ''))
      .map(date => new Date(date))
      .filter(date => date && !isNaN(date.getTime()))
      .sort((a, b) => {
        if (a < b) return -1
        if (a > b) return 1
        return 0
      })
    const lowestDate = sortedMinDeliveryDates[0]
    const today = new Date()
    if (lowestDate && lowestDate > today) {
      const dd = String(lowestDate.getDate()).padStart(2, '0')
      const mm = String(lowestDate.getMonth() + 1).padStart(2, '0')
      const yyyy = lowestDate.getFullYear()
      return yyyy + '-' + mm + '-' + dd
    } else {
      today.setDate(today.getDate() + 1)
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0')
      const yyyy = today.getFullYear()
      return yyyy + '-' + mm + '-' + dd
    }
  }

  const min = disableDates()

  return (
    <ProcessWrapper>
      <div>
        {filtersApplied && (
          <WrapperSection>
            <FiltersContainer>
              <Section>
                <div>
                  <RCIcon type="icons-filters" />
                  <span className="filters-applied">{t('Checkout.Process.Filters.Applied')}</span>
                </div>
                {filtersToShow.map(filterToShow => (
                  <FilterChip
                    key={`${filterToShow.filterKey}-${filterToShow.selectedId}`}
                    filterToShow={filterToShow}
                  />
                ))}
              </Section>
            </FiltersContainer>
          </WrapperSection>
        )}

        {productConfirmed > 0 && (
          <WrapperSection>
            <StatusContainer>
              <Section>
                {`${t('Checkout.Process.StatusStart')} (${productQuantity}) ${t(
                  'Checkout.Process.StatusHalf',
                )} (${productConfirmed}) ${t('Checkout.Process.StatusEnd')}`}
              </Section>
            </StatusContainer>
          </WrapperSection>
        )}
      </div>

      {!cartIsLoaded ? (
        <Loading />
      ) : (
        <CartProductsList>
          <ListHead>
            <NumberOfRowsCell>
              <span>{t('Checkout.Process.Rows')}:</span>
              <span>{checkoutProductsFiltered.length}</span>
              <span>of</span>
              <span>{checkoutProducts.length}</span>
            </NumberOfRowsCell>

            <DateCell>
              <CustomDatePicker
                value={date ? new Date(date) : undefined}
                minDate={min ? new Date(min) : undefined}
                openCalendarOnFocus={true}
                dayPlaceholder="DD"
                monthPlaceholder="MM"
                yearPlaceholder="YYYY"
                calendarIcon={<CalendarIcon />}
                onChange={(date: Date | null) => {
                  setDate(date ? date.toISOString() : '')
                }}
              />
            </DateCell>
            <ListCell>
              <InputWrapperHeadReference>
                <CustomInput
                  type="input"
                  value={reference}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setReference(e.currentTarget.value)
                  }
                  maxLength={15}
                />
                <LabelInput className={reference === '' ? '' : 'none'}>
                  {t('Checkout.Process.ReferencePlaceholder')}
                </LabelInput>
                <RCIcon type="icons-close" onClick={() => setReference('')} />
              </InputWrapperHeadReference>
            </ListCell>
            <ListCell>
              <ApplyButton
                className={date === '' && reference === '' ? 'disable' : ''}
                onClick={showModal}
              >
                {t('Checkout.Process.ApplyDate')}
              </ApplyButton>
            </ListCell>
          </ListHead>
          <ListLabels className={productConfirmed === 0 ? 'notProcessed' : ''}>
            {tableHeadArr.map((column, index) => {
              const key = column[1]
              const columnName: string = column[0].toString()
              return (
                <ListHeaderCell
                  key={index}
                  onClick={() =>
                    setSortInfo({
                      key,
                      order:
                        sortInfo.key === key
                          ? sortInfo.order === 'asc'
                            ? 'disc'
                            : 'asc'
                          : sortInfo.order,
                    })
                  }
                >
                  <ColumnLabel>{columnName}</ColumnLabel>
                  <ArrowWrapper>
                    {sortInfo.key === key && (
                      <RCIcon
                        type={sortInfo.order === 'disc' ? 'icons-sort-down' : 'icons-sort-up'}
                      />
                    )}
                  </ArrowWrapper>
                </ListHeaderCell>
              )
            })}
          </ListLabels>

          <ListBody>
            <AutoSizer>
              {({ height, width }) => (
                <List
                  height={height}
                  itemCount={sortedProducts.length}
                  itemSize={Math.round(
                    height / ((width > height ? 5 : 8.5) - (filtersApplied ? 1 : 0)),
                  )}
                  width={width}
                >
                  {({ index, style }) => {
                    const cartProduct = sortedProducts[index]
                    return (
                      <ProcessTableRow
                        style={style}
                        key={`${cartProduct.upc}-${cartProduct.doorId}-${cartProduct.status}-${cartProduct.quantity}-${cartProduct.confirmedQuantity}-${index}`}
                        checkoutProduct={cartProduct}
                        cartProducts={checkoutProductsFiltered}
                        moco={
                          cartProductDetails[cartProduct.modelCode]?.mocos[cartProduct.colorCode]
                        }
                      />
                    )
                  }}
                </List>
              )}
            </AutoSizer>

            {sortedProducts.length === 0 && cartIsLoaded && (
              <WrapperNoProducts>
                <span>{t('Checkout.Process.NoProducts')}</span>
              </WrapperNoProducts>
            )}
          </ListBody>
        </CartProductsList>
      )}

      <Modal
        className="modal-checkout-apply"
        wrapClassName="checkout-modal-wrapper-apply"
        title={t('Checkout.Process.ApplyDateWarning')}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={null}
        width="70vw"
        zIndex={ANT_MODAL_Z_INDEX + 1}
      >
        <WrapperBodyApply>
          <BodyApplyText>
            {date !== '' ? (
              <>
                {t('Checkout.Process.ApplyDateWarningStart')}
                <BodyApplyTextDate>
                  {format(new Date(date as string), 'dd-MM-yyyy')}
                </BodyApplyTextDate>
                {t('Checkout.Process.ApplyWarningEnd')} {t('Checkout.Process.ApplyDateWarningEnd')}
              </>
            ) : (
              <>
                {t('Checkout.Process.ApplyReferenceWarningStart')}
                <BodyApplyTextReference>{reference}</BodyApplyTextReference>
                {t('Checkout.Process.ApplyWarningEnd')}
              </>
            )}
          </BodyApplyText>
          <WrapperButtonBodyApply>
            <ButtonBodyApplyCancel onClick={handleCancel}>
              {t('Checkout.ImportExport.ModalCancel')}
            </ButtonBodyApplyCancel>
            <ButtonBodyApplyConfirm
              onClick={() => {
                const newDateTimestamp = date ? new Date(date).getTime() : 0

                const products = checkoutProductsFiltered
                  .filter(({ status, outOfAssortment }) => {
                    return status === '1' && !outOfAssortment
                  })
                  .map(
                    ({
                      insertedAt,
                      upc,
                      skuCode,
                      modelCode,
                      colorCode,
                      mocoCode,
                      size,
                      brandCode,
                      doorId,
                      quantity,
                      status,
                      categoryId,
                      confirmedQuantity,
                      minDeliveryDate,
                      deliveryDate,
                      reference: oldReference,
                    }) => {
                      const [day, month, year] = (minDeliveryDate || '')
                        .split('-')
                        .map(str => Number(str))
                      const minDeliveryDateTimestamp =
                        day && month && year ? new Date(year, month - 1, day).getTime() : 0
                      const newDeliveryDate =
                        newDateTimestamp && newDateTimestamp > minDeliveryDateTimestamp
                          ? format(newDateTimestamp, 'dd-MM-yyyy')
                          : deliveryDate

                      return {
                        insertedAt,
                        upc,
                        skuCode,
                        modelCode,
                        colorCode,
                        mocoCode,
                        size,
                        brandCode,
                        doorId,
                        quantity,
                        status,
                        categoryId,
                        confirmedQuantity: confirmedQuantity || 0,
                        requestStatus: 'waiting' as const,
                        oldQnt: quantity,
                        deliveryDate: newDeliveryDate,
                        reference: reference || oldReference || undefined,
                      }
                    },
                  )

                updateCartMetadata(products)

                handleCancel()
              }}
            >
              {t('Checkout.Process.ApplyDateConfirm')}
            </ButtonBodyApplyConfirm>
          </WrapperButtonBodyApply>
        </WrapperBodyApply>
      </Modal>
    </ProcessWrapper>
  )
}

export default ProcessTable
