import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { deleteIntersessionCustomerId } from '../../libs/customer'
import { CustomerState, Door, PricePrefs } from '../../model/customer'
import { resetForNewClient } from '../extraActions'

const getVisitedBrands = (customerData: CustomerState) => {
  return ((customerData && customerData.list_brands) || []).reduce((result, brand) => {
    result[brand] = false
    return result
  }, {} as { [brand: string]: boolean })
}

const DEFAULT_STATE: CustomerState = {
  loaded: false,
  id: '',
  customerId: '',
  name: '',
  nationality: '',
  cart_items: {},
  digitalPreviewVisited: false,
  doors: [],
  list_brands: [],
  showCartTotal: false,
  showSrPrice: false,
  showWhsPrice: false,
  visited: {},
  welcome_visited: false,
  doorsLoadingStatus: 'unsent',
  pricePreferencesLoadingStatus: 'unsent',
  showNotes: false,
  stars: false,
}

export const slice = createSlice({
  name: 'customer',
  initialState: DEFAULT_STATE,
  reducers: {
    loadCustomerAction: (_, { payload }: PayloadAction<CustomerState>) => ({
      ...payload,
      loaded: true,
      visited: getVisitedBrands(payload),
    }),
    loadCustomerFail: () => {
      deleteIntersessionCustomerId()
      return DEFAULT_STATE
    },
    setBrandVisited: (state, { payload }: PayloadAction<string>) => {
      state.visited[payload] = true
    },
    setWelcomeVisited: state => {
      state.welcome_visited = true
    },
    setDigitalPreviewVisited: state => {
      state.digitalPreviewVisited = true
    },
    setCustomerDoors: (state, { payload }: PayloadAction<Door[]>) => {
      state.doors = payload
      state.doorsLoadingStatus = 'ok'
    },
    loadingCustomerDoors: state => {
      state.doorsLoadingStatus = 'pending'
    },
    loadCustomerDoorsError: state => {
      state.doorsLoadingStatus = 'ko'
    },
    loadingCustomerPricePreferences: state => {
      state.pricePreferencesLoadingStatus = 'pending'
    },
    loadedCustomerPricePreferences: state => {
      state.pricePreferencesLoadingStatus = 'ok'
    },
    loadCustomerPricePreferencesError: state => {
      state.pricePreferencesLoadingStatus = 'ko'
    },
    setCustomerPricePreferences: (state, { payload }: PayloadAction<PricePrefs>) => {
      state.showCartTotal = payload.showCartTotal
      state.showSrPrice = payload.showSrPrice
      state.showWhsPrice = payload.showWhsPrice
    },
    setShowNotes: state => {
      state.showNotes = !state.showNotes
    },
    resetCustomerState: () => DEFAULT_STATE,
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, () => DEFAULT_STATE)
  },
})

export default slice.reducer
