import React from 'react'
import { ButtonHeader } from './AfaCartHeader'
import { useGetAfaCartProducts } from '../../hooks/useGetAfaCartProducts'
import { useTranslation } from 'react-i18next'
import { getAfaCartQuantity } from '../../libs/afa'
import { useAfaCheckCartNotifications } from '../../hooks/useAfaCheckCartNotifications'
import { successNotification } from '../../components/Notification/notifications'

interface Props {
  className?: string
}

export const CheckAdjustButton: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation()
  const { cartProducts } = useGetAfaCartProducts()
  const { checkCartNotifications, isChecking } = useAfaCheckCartNotifications()

  const cartQuantity = getAfaCartQuantity(cartProducts)
  const isDisabled = cartQuantity === 0

  const checkCart = async () => {
    const result = await checkCartNotifications(cartProducts)
    if (result) {
      successNotification({
        message: t('Afa.Cart.Adjust.NotNeededText'),
      })
    }
  }

  return (
    <ButtonHeader
      className={`${className ?? ''} ${isChecking ? 'loading' : ''}  ${
        isDisabled ? 'disabled' : ''
      }`}
      onClick={checkCart}
    >
      {t('Afa.checkAdjust')}
    </ButtonHeader>
  )
}
