import { FC } from 'react'

type Props = {
  className?: string
}

const FilterIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      width="36"
      height="36"
      className={className}
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(7 10)" fill="#5093F9" fillRule="evenodd">
        <path
          d="M13.044 12v2H0v-2h13.044zM22 12v2h-4.956v-2H22zM4 3v2H0V3h4zm18 0v2H8V3h14z"
          fillRule="nonzero"
        />
        <rect stroke="#5093F9" x="16.5" y="9.5" width="1" height="7" rx=".5" />
        <rect stroke="#5093F9" x="7.5" y=".5" width="1" height="7" rx=".5" />
      </g>
    </svg>
  )
}

export default FilterIcon
