import styled from 'styled-components/macro'
import {
  breakpointsCross,
  getFluidFontSize,
  getFluidSizeWithFullFormula as gF,
  palette,
  pxToRem,
  spacing,
} from '../../style/theme'

const DOTS_BOTTOM = 2

export const EvidenceCollection = styled.div`
  color: ${palette.tangaroa};
  background-color: ${palette.white};
  display: flex;
  flex-direction: column;
  border-radius: ${spacing(pxToRem(1))}rem;
  padding-top: 2vh;
  overflow: hidden;
  max-width: 100%;
  position: relative;

  @media (max-width: ${breakpointsCross.M.min}) and (orientation: portrait) {
    display: none;
  }
`

export const Dots = styled.div`
  position: absolute;
  bottom: ${DOTS_BOTTOM}vh;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: ${gF('px', 12, 24, 1366, 3840)};
`

export const Dot = styled.div<{ selected: boolean }>`
  width: ${gF('px', 12, 24, 1366, 3840)};
  height: ${gF('px', 12, 24, 1366, 3840)};
  border-radius: 50%;
  background-color: ${({ selected }) => (selected ? palette.white : palette.santasGray)};
  cursor: pointer;
`

export const Wrapper = styled.div`
  height: 100%;
  position: relative;

  .initiative-arrow-button {
    visibility: hidden;
  }

  @media (min-width: ${breakpointsCross.M.over}) and (max-width: ${breakpointsCross.XL.max}) {
    .initiative-arrow-button {
      visibility: visible !important;
    }
  }
`

export const InitiativeContainer = styled.div<{ index?: number; selected?: number }>`
  height: 100%;
  box-sizing: border-box;
`

export const EvidenceTitle = styled.span`
  text-transform: uppercase;
  align-items: center;
  font-size: ${gF('px', 16, 32, 1366, 3840)};
  font-weight: 900;
  margin-bottom: 1vh;
  color: ${palette.congressBlue};
  padding: 0 1vw;
`

export const EvidenceContent = styled.div<{ background: string }>`
  background: linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(255, 255, 255, 0)), url("${({
    background,
  }) => background}");
  background-color: ${palette.mischka};
  padding: 0 1vw;
  height: 100%;
  display: grid;
  grid-auto-rows: 1fr min-content 1fr;
  padding-bottom: ${3 + DOTS_BOTTOM}vh;
  background-position: center;
  background-size: cover;
`

export const InitiativeDescription = styled.div`
  //position: absolute;
    //bottom: ${getFluidFontSize('20px')};
  font-size: ${gF('px', 21, 42, 1366, 3840)};
  line-height: ${gF('px', 26, 52, 1366, 3840)};
  font-weight: 500;
  color: ${palette.white};
  margin-bottom: 2vh;
`

export const Content = styled.div<{ initiatives: boolean }>`
  background-color: #f6f6f6;
  flex: 1;
  display: grid;
  overflow: hidden;
  padding: 2.5rem;
  gap: 1rem;
  grid-template-columns: 1fr ${({ initiatives }) => (initiatives ? '25%' : 'auto')};

  @media (min-width: ${breakpointsCross.M.over}) and (max-width: ${breakpointsCross.XL.max}) {
    grid-template-columns: 1fr ${({ initiatives }) => (initiatives ? '20%' : 'auto')};
  }

  @media (max-width: ${breakpointsCross.M.min}) and (orientation: portrait) {
    grid-template-columns: 1fr;
  }

  @media (min-width: ${breakpointsCross.XL.min}) {
    grid-template-columns: 1fr ${({ initiatives }) => (initiatives ? '22%' : 'auto')};
  }
`

export const InitiativeButton = styled.button`
  background-color: ${palette.tangaroa};
  color: ${palette.white};
  border-radius: ${gF('px', 6, 12, 1366, 3840)};
  padding: ${gF('px', 12, 24, 1366, 3840)} ${gF('px', 20, 40, 1366, 3840)};
  width: fit-content;
  font-size: ${gF('px', 14, 28, 1366, 3840)};
`

export const ChangeInitiative = styled.div`
  width: ${gF('px', 36, 72, 1366, 3840)};
  height: ${gF('px', 36, 72, 1366, 3840)};
  border-radius: ${gF('px', 18, 36, 1366, 3840)};
  background-color: rgb(217 217 217 / 70%);
  color: ${palette.tangaroa};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;

  &.sxarrow {
    transform: rotate(180deg);
    float: left !important;
  }
`
