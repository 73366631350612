import app_config from '../config/app/config'

export const initSmartlook = () => {
  const GDPRConsent = true

  if (['uat', 'prod', 'dev'].includes(app_config.env)) {
    const mapEnvToId = {
      uat: '1fd729914f9254c6aadd585ab6ba7607fd80854b',
      prod: '5839794975a1d241b7458e4674012a0bba5d5fae',
      dev: 'e6c2f45335db76d63f57f8762208878c3b775ebf',
    }

    // eslint-disable-next-line no-console
    console.log(`Initing smartlook on env ${app_config.env}`)

    window.smartlook ||
      (function(d) {
        const o: any = (window.smartlook = function() {
            //eslint-disable-next-line
            o.api.push(arguments)
          }),
          h = d.getElementsByTagName('head')[0]
        const c = d.createElement('script')
        o.api = []
        c.async = true
        c.type = 'text/javascript'
        c.charset = 'utf-8'
        c.src = 'https://rec.smartlook.com/recorder.js'
        h.appendChild(c)
      })(document)
    window.smartlook('init', mapEnvToId[app_config.env as 'uat' | 'prod' | 'dev'])
    window.smartlook('consentForms', GDPRConsent)
    window.smartlook('consentIP', GDPRConsent)
    window.smartlook('consentAPI', GDPRConsent)
  }
}
