import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import { getLangFromUrl } from './libs/lang'

const customLangDetector = {
  name: 'rc-lang-detector',
  lookup() {
    return getLangFromUrl()
  },
}
const lngDetector = new LanguageDetector()
lngDetector.addDetector(customLangDetector)

i18n.use(Backend).use(lngDetector)

// initReactI18next is undefined in tests
if (initReactI18next) {
  i18n.use(initReactI18next)
}

i18n.init({
  load: 'currentOnly',
  fallbackLng: 'en',
  // have a common namespace used around the full app
  ns: ['common'],
  defaultNS: 'common',
  debug: false,
  detection: {
    order: ['rc-lang-detector'],
    caches: [],
  },
  react: {
    wait: true,
    // usePureComponent: true,
    useSuspense: false,
  },
  backend: {
    loadPath: `${process.env.REACT_APP_API_URL}/locales?locale={{lng}}`,
  },
  returnNull: false,
})

export default i18n
