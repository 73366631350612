import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { countItemsInCart } from '../../libs/cart'
import { countItemsInWishlist } from '../../libs/wishlist'
import { cartProductsFilteredByActiveDoorsSelector } from '../../store/cart/selectors/cart'
import { customerSelector } from '../../store/customer/selectors'
import { disableCartButtonSelector } from '../../store/showButtonsSelectors'
import { wishlistProductsSelector } from '../../store/wishlist/selectors'
import { Badge, Spin } from '../../style/DigitalEventsCommonComponents'
import {
  breakpoints,
  duration,
  getFluidFontSize,
  getFluidSize,
  palette,
  pxToRem,
  spacing,
} from '../../style/theme'
import FooterLogin from '../FooterNav/FooterLogin/FooterLogin'
import DoorsList from '../ModalDoorsList/index'
import SubMenu from '../SubMenu/SubMenu'

const Wrapper = styled.footer`
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  gap: ${pxToRem(spacing(4))}rem;
  margin: ${pxToRem(spacing(6))}rem;
  color: ${palette.white};
  position: relative;

  @media (max-width: ${breakpoints.S}) {
    margin: ${pxToRem(spacing(3))}rem;
    gap: ${pxToRem(spacing(3))}rem;
  }
`

const MenuItem = styled.div``

const FooterButton = styled.button`
  text-transform: capitalize;
  color: inherit;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: ${palette.curiousBlue};
  }
`

const FooterLink = styled(Link)`
  color: inherit;
  text-transform: capitalize;
  position: relative;
  transition: color 0.3s;

  &:hover {
    color: ${palette.curiousBlue};
  }
`

const DoorsLayerInner = styled.div`
  height: 40vh;
  width: 850px;
  width: max(850px, 50vw);
`

const LoginMenuModalHeadStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  padding-top: ${getFluidSize('0.8rem')};
`
const ModalTitleStyled = styled.span`
  color: ${palette.biscay};
  font-size: ${getFluidFontSize('1.2rem')};
  font-weight: bold;
  padding-right: ${getFluidSize('1.8rem')};
`

const ModalInfoStyled = styled.div`
  max-width: 15rem;
  max-width: max(20vw, 15rem);
  font-size: ${getFluidFontSize('0.8rem')};
  color: ${palette.biscay};
  font-weight: bold;
  text-align: right;
`

const ModalInfoCustomerNameStyled = styled.div`
  word-wrap: break-word;
`

const FooterLoginStyled = styled(FooterLogin)`
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none;
  }
`

const StyledSubMenu = styled(SubMenu)`
  max-height: 0;
  &.is-open {
    max-height: 100vh;
  }
  transition: transform ${duration.enteringScreen}ms, opacity ${duration.enteringScreen}ms,
    max-height 0 ${duration.enteringScreen}ms;
`

type LayerName = 'none' | 'doors' | 'customer'

const DigitalEventsFooter: React.FC = () => {
  const { t } = useTranslation()
  const customer = useSelector(customerSelector)
  const [openedLayer, setOpenedLayer] = useState('none' as LayerName)
  const cartProductsFilteredByActiveDoors = useSelector(cartProductsFilteredByActiveDoorsSelector)
  const itemsInCart = countItemsInCart(cartProductsFilteredByActiveDoors)
  const wishlistProducts = useSelector(wishlistProductsSelector)
  const itemsInWishlist = countItemsInWishlist(wishlistProducts)
  const disableCartButton = useSelector(disableCartButtonSelector)

  return (
    <Wrapper>
      <MenuItem>
        <FooterButton onClick={() => setOpenedLayer(openedLayer === 'doors' ? 'none' : 'doors')}>
          {t('GenericWords.doors').toLowerCase()}
        </FooterButton>
        <StyledSubMenu open={openedLayer === 'doors'}>
          <DoorsLayerInner>
            <DoorsList />
          </DoorsLayerInner>
        </StyledSubMenu>
      </MenuItem>
      <FooterLink to="/de/ipad-only/table/wishlist">
        {t('FooterNav.item_wishlist').toLowerCase()}
        {itemsInWishlist > 0 && <Badge>{itemsInWishlist}</Badge>}
      </FooterLink>
      <FooterLink to="/de/ipad-only/table/cart">
        {t('FooterNav.item_cart').toLowerCase()}
        {!!(itemsInCart || disableCartButton) && (
          <Badge>{disableCartButton ? <Spin /> : itemsInCart}</Badge>
        )}
      </FooterLink>
      {customer?.name && customer.id && (
        <MenuItem>
          <FooterButton
            onClick={() => setOpenedLayer(openedLayer === 'customer' ? 'none' : 'customer')}
          >
            {customer.name.toLowerCase()}
          </FooterButton>
          <StyledSubMenu open={openedLayer === 'customer'}>
            <LoginMenuModalHeadStyled>
              <ModalTitleStyled>{t('FooterNav.menu_title')}</ModalTitleStyled>
              <ModalInfoStyled>
                <ModalInfoCustomerNameStyled>{customer.name}</ModalInfoCustomerNameStyled>
                <div>ID{customer.id}</div>
              </ModalInfoStyled>
            </LoginMenuModalHeadStyled>
            <FooterLoginStyled />
          </StyledSubMenu>
        </MenuItem>
      )}
    </Wrapper>
  )
}

export default DigitalEventsFooter
