import classNames from 'classnames'
import React from 'react'
import { useGetBrandLogo } from '../../../../services/afaContents'
import { useSelector } from 'react-redux'
import { brandSelectorByCode } from '../../../../store/brands/selectors'

type Props = {
  active?: boolean
  brandCode: string
  onClick?: () => void
  selected?: boolean
  className?: string
}

const BrandLogo: React.FC<Props> = ({ active = true, brandCode, onClick, selected, className }) => {
  const handleClick = () => {
    if (active && onClick) {
      onClick()
    }
  }
  const brand = useSelector(brandSelectorByCode(brandCode))
  const { brandLogo, isSuccess } = useGetBrandLogo(brandCode)

  return (
    <div className={`${className ? className : ''} couvette-brand`}>
      <div
        className={classNames({ 'brand-logo': true, active: selected, 'no-elements': !active })}
        onClick={handleClick}
      >
        {brandLogo || !isSuccess ? (
          <img
            className={classNames({ 'brand-logo-img': true })}
            src={brandLogo}
            alt={brand?.brand || brandCode}
          />
        ) : (
          <span>{brand?.brand || brandCode}</span>
        )}
      </div>
    </div>
  )
}

export default BrandLogo
