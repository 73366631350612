import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getFluidFontSize, palette, pxToRem, spacing } from '../../../../style/theme'
import RCIcon from '../../../../components/UI/RCIcon'

const Wrapper = styled.div`
  background-color: ${palette.white};
  padding-top: ${pxToRem(spacing(8))}rem;
  padding-bottom: ${pxToRem(spacing(6))}rem;
  padding-left: ${pxToRem(spacing(4))}rem;
  padding-right: ${pxToRem(spacing(4))}rem;
  border-radius: ${pxToRem(spacing(1))}rem;
`

const ThankYouWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${pxToRem(spacing(4))}rem;

  .anticon {
    width: 3.2em;
    height: 3.2em;
  }
`

const ThankYouText = styled.p`
  color: ${palette.biscay};
  font-size: ${getFluidFontSize('30px', 15)};
  font-weight: 800;
`

const ThankYou: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <ThankYouWrapper>
        <RCIcon type="thankyou" />
        <ThankYouText>{t('Checkout.Confirm.ThankYou')}</ThankYouText>
      </ThankYouWrapper>
    </Wrapper>
  )
}

export default ThankYou
