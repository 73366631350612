import { useCallback } from 'react'
import app_config from '../config/app/config'
import imageCompression from 'browser-image-compression'
import { convertToBase64 } from '../libs/files'
import { Environments } from '../model/api'

const options = {
  //maxSizeMB: 1,
  maxWidthOrHeight: 300,
  useWebWorker: true,
}

const useProxy: Environments[] = ['lcl', 'dev', 'stage']

const useCheckIfImageNeedsProxy = () => {
  const fetchImage = useCallback(async (path: string, environment: Environments) => {
    let data: Response

    if (useProxy.includes(environment)) {
      data = await fetch(`${app_config.apiUrl}/content/image-proxy?path=${path}`)
    } else {
      data = await fetch(
        `https://redcarpet-im2.luxottica.com/cdn-record-files-pi/${path}?cors=true`,
      )
    }

    const blob = await data.blob()
    const file = new File([blob], 'name.png', { type: 'image/png' })
    const compressedFile = await imageCompression(file, options)
    const base64 = await convertToBase64(compressedFile)

    //const localUrl = window.URL.createObjectURL(compressedFile)
    return base64
  }, [])

  return { fetchImage }
}

export default useCheckIfImageNeedsProxy
