import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useGetAfaCartProducts } from '../../hooks/useGetAfaCartProducts'

import { useSearchParams } from '../../hooks/useSearchParams'
import { breakpoints, palette, pxToRem } from '../../style/theme'
import { useGetBrandLogo } from '../../services/afaContents'
import { BrandLogoImage } from '../../style/CommonComponents'
import { useSelector } from 'react-redux'
import { isSummaryOpenSelector } from '../../store/afaCartSummary/selectors'
import { AfaBrand } from '../../model/afa'

const Wrapper = styled.div`
  width: ${pxToRem(266)}rem;
  background-color: ${palette.extraLight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${pxToRem(16)}rem;
  border: ${palette.silver} 1px solid;
  position: sticky;
  top: 0;
  @media (max-width: ${breakpoints.L}) {
    width: ${pxToRem(126)}rem;
  }
`
const BrandWrapper = styled.div<{
  active?: boolean
}>`
  cursor: pointer;
  position: relative;
  background-color: ${palette.white};
  margin: 0 ${pxToRem(16)}rem;
  width: ${pxToRem(172)}rem;
  height: ${pxToRem(156)}rem;
  border: ${({ active }) => (active ? palette.tangaroa : palette.philippineSilver)} 1px solid;
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(10)}rem;

  justify-content: center;
  align-items: center;
  color: ${palette.doveGray};
  font-size: ${pxToRem(10)}rem;

  &::after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: ${({ active }) => (active ? '5px' : '0')};
    border-style: solid;
    border-color: transparent transparent transparent ${palette.tangaroa};
  }

  img {
    width: 80%;
  }

  @media (max-width: ${breakpoints.L}) {
    width: ${pxToRem(88)}rem;
    height: ${pxToRem(80)}rem;
  }
`

const BrandLogo: React.FC<{
  brand: AfaBrand
  logoVariant?: 'standard' | 'alternative'
}> = ({ brand, logoVariant }) => {
  const { brandLogo, isSuccess } = useGetBrandLogo(brand.code)

  return brandLogo || !isSuccess ? (
    <BrandLogoImage
      className={logoVariant === 'alternative' ? 'alternative' : ''}
      src={brandLogo}
      alt={brand.name || brand.code}
    />
  ) : (
    <span>{brand.name || brand.code}</span>
  )
}

const AfaCartBrands: React.FC = () => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const { brandsInCart, cartItemsBySelectedBrandCodeLength } = useGetAfaCartProducts()

  const setSelectedBrand = useCallback(
    (brand: string) => {
      searchParams.set('brand', brand)
      setSearchParams(searchParams, { replace: true })
    },
    [setSearchParams, searchParams],
  )

  const selectedBrandCode = searchParams.get('brand') || brandsInCart[0]?.code

  const summaryIsOpen = useSelector(isSummaryOpenSelector)

  return (
    <Wrapper className={summaryIsOpen ? 'close' : ''}>
      {brandsInCart.map(brand => {
        return (
          <BrandWrapper
            key={brand.code}
            active={!!selectedBrandCode && brand.code === selectedBrandCode}
            onClick={() => setSelectedBrand(brand.code)}
          >
            <BrandLogo brand={brand} />
            <span>
              {cartItemsBySelectedBrandCodeLength} {t('Afa.Cart.Items')}
            </span>
          </BrandWrapper>
        )
      })}
    </Wrapper>
  )
}

export default AfaCartBrands
