import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  breakpoints,
  breakpointsCross,
  getFluidFontSize,
  getFluidSize,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
  spacing,
} from '../../../style/theme'
import {
  CustomIconAndTextButton,
  CustomInput,
  StyledCheckBox,
} from '../components/AfaCheckoutCommonComponents'
import RCIcon from '../../../components/UI/RCIcon'
import {
  errorNotification,
  successNotification,
} from '../../../components/Notification/notifications'
import { useDownloadAfaCartFileQuery, useSendEmailAfaCartMutation } from '../../../services/afaCart'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: ${getFluidSizeWithFullFormula('px', 48, 100, 1366, 3840)};
  font-family: 'GilmerRegular', sans-serif;
`

const Title = styled.div`
  font-size: ${getFluidSizeWithFullFormula('px', 16, 32, 1366, 3840)};
  text-transform: uppercase;
  line-height: normal;

  @media (max-width: ${breakpointsCross.L.max}) {
    letter-spacing: 0.8px;
    color: ${palette.cloudBurst};
  }

  @media (min-width: ${breakpoints.L}) {
    letter-spacing: 0.28px;
    color: ${palette.tangaroa};
  }
`

const GridTitle = styled(Title)`
  grid-column: 1 / -1;
  margin-bottom: ${getFluidSizeWithFullFormula('px', 32, 56, 1366, 3840)};

  &.not-first {
    margin-top: ${getFluidSizeWithFullFormula('px', 48, 100, 1366, 3840)};
  }

  @media (max-width: ${breakpointsCross.L.max}) {
    font-weight: bold;
  }

  @media (min-width: ${breakpoints.L}) {
    font-weight: bold;
  }
`

const FormatTitle = styled(Title)`
  font-weight: bold;
`

const Subtitle = styled.p`
  padding-top: ${getFluidSizeWithFullFormula('px', 16, 24, 1366, 3840)};
  padding-bottom: ${getFluidSizeWithFullFormula('px', 21, 48, 1366, 3840)};
  font-size: ${getFluidSizeWithFullFormula('px', 12, 24, 1366, 3840)};
  font-weight: bold;
  color: ${palette.tangaroa};

  @media (min-width: ${breakpoints.L}) {
    color: ${palette.santasGray};
  }
`

const Feedback = styled.p`
  padding-top: ${getFluidSizeWithFullFormula('px', 16, 24, 1366, 3840)};
  padding-bottom: ${getFluidSizeWithFullFormula('px', 21, 48, 1366, 3840)};
  font-size: ${getFluidSizeWithFullFormula('px', 10, 20, 1366, 3840)};
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.L}) {
    font-weight: bold;
  }

  img {
    height: ${getFluidSizeWithFullFormula('px', 12, 32, 1366, 3840)};
    width: ${getFluidSizeWithFullFormula('px', 16, 32, 1366, 3840)};
  }
`

const SubSectionForm = styled.div`
  display: flex;
  column-gap: ${pxToRem(spacing(4))}rem;
  row-gap: ${pxToRem(spacing(1))}rem;
  flex-wrap: wrap;
  max-width: ${pxToRem(2000)}rem;
`

const InputAndFeedBack = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const SendForm = styled.form`
  border: 1px solid ${palette.alto};
  border-radius: ${pxToRem(5)}rem;
  justify-content: space-between;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 0.75;

  input {
    outline: none;
    border: solid 1px ${palette.bermudaGray};
    padding-inline: ${getFluidSizeWithFullFormula('px', 16, 24, 1366, 3840)};
    height: ${getFluidSizeWithFullFormula('px', 42, 68, 1366, 3840)};
  }

  .search-icon {
    position: absolute;
    top: ${pxToRem(7)}rem;
    left: ${pxToRem(7)}rem;
  }
`

const CheckboxWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: ${pxToRem(spacing(1.5))}rem;
  font-size: ${getFluidSizeWithFullFormula('px', 14, 24, 1366, 3840)};
  color: ${palette.tangaroa};
  white-space: nowrap;

  @media (min-width: ${breakpoints.L}) {
    letter-spacing: 1.2px;
  }

  p {
    text-transform: capitalize;
  }

  input {
    border: 1px solid ${palette.tangaroa};
  }
`

const FormatButtonWrapper = styled.div`
  display: flex;
  gap: ${pxToRem(spacing(4))}rem;
  height: 3em;
  align-items: center;
  flex-grow: 1;
`

const CheckboxsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, min-content);
  column-gap: clamp(54px, ${getFluidSizeWithFullFormula('px', 70, 54, 1366, 3840)}, 70px);
`

const StyledButton = styled(CustomIconAndTextButton)`
  background-color: ${palette.tangaroa};
  color: ${palette.white};
  white-space: nowrap;
  text-transform: uppercase;
  border-radius: 4px;
  font-size: ${getFluidSizeWithFullFormula('px', 13, 24, 1366, 3840)};
  font-weight: bold;
  letter-spacing: 0.3px;
  height: ${getFluidSizeWithFullFormula('px', 36, 72, 1366, 3840)};

  @media (max-width: ${breakpointsCross.L.max}) {
    line-height: 1.38;
  }

  @media (min-width: ${breakpoints.L}) {
    line-height: 0.75;
  }

  padding: ${getFluidSize(`${pxToRem(15)}rem`, 15)} ${getFluidSize(`${pxToRem(15)}rem`, 15)};
  min-width: max-content;
  width: ${getFluidSizeWithFullFormula('px', 167, 436, 1366, 3840)};
  justify-content: center;

  .rc-icon {
    font-size: ${getFluidFontSize('16px', 15)};
  }
`

type Props = {
  itemsInCart: boolean
  className?: string
}

const SubSectionsContainer = styled.div`
  display: flex;
  column-gap: ${getFluidSizeWithFullFormula('px', 150, 328, 1920, 3840)};
  row-gap: ${getFluidSizeWithFullFormula('px', 80, 179, 1920, 3840)};

  @media (max-width: ${breakpointsCross.L.max}) {
    flex-direction: column;
  }
`

const ExportCartAfa: React.FC<Props> = ({ itemsInCart, className }) => {
  const { t } = useTranslation()

  const [
    sendEmailCart,
    {
      isLoading: sendingEmail,
      isSuccess: emailSuccessfullySended,
      isError: emailNotSuccessfullySended,
    },
  ] = useSendEmailAfaCartMutation()

  const [selectedFileType, setSelectedFileType] = useState<('excel' | 'pdf')[] | undefined>(
    undefined,
  )
  const [showImages, setShowImages] = useState(false)
  const [showPriceWS, setShowPriceWS] = useState(false)
  const [showPriceRT, setShowPriceRT] = useState(false)
  const [startDownload, setStartDownload] = useState(false)
  const [showFeedback, setShowFeedback] = useState(false)
  const [disableButtonDownload, setDisableButtonDownload] = useState(true)

  const handleSubmit = ({
    onError,
    onSuccess,
  }: {
    onError?: () => void
    onSuccess: () => void
  }) => {
    if (!selectedFileType && itemsInCart) {
      onError && onError()
      errorNotification({
        message: t('Checkout.ImportExport.SelectedFileType'),
      })
    } else if (!itemsInCart) {
      onError && onError()
      errorNotification({
        message: t('Checkout.ImportExport.CartIsEmpty'),
      })
    } else {
      onSuccess()
    }
  }

  const handleDownload = () =>
    handleSubmit({
      onError: () => setStartDownload(false),
      onSuccess: () => {
        setStartDownload(true)
        setShowFeedback(true)
      },
    })

  const downloadRes = useDownloadAfaCartFileQuery({
    startDownload,
    showPriceWS,
    showPriceRT,
    showImage: showImages,
    fileType: selectedFileType,
  })

  useEffect(() => {
    if (downloadRes.isFetching) {
      setDisableButtonDownload(true)
    } else if (showImages) {
      setDisableButtonDownload(true)
    } else {
      setDisableButtonDownload(false)
    }
  }, [downloadRes.isFetching, showImages])

  useEffect(() => {
    if (downloadRes.isUninitialized) {
      setSelectedFileType(undefined)
      setShowImages(false)
      setShowPriceWS(false)
      setShowPriceRT(false)
    }
  }, [downloadRes.isUninitialized])

  useEffect(() => {
    if (
      selectedFileType &&
      downloadRes.isSuccess &&
      !downloadRes.isLoading &&
      !downloadRes.isFetching
    ) {
      downloadRes.data.files.forEach(type => {
        const a = document.createElement('a')
        const base64PrefixMap = {
          excel: 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
          pdf: 'data:application/x-pdf;base64,',
        }
        const fileName = type.filename
        const isPdf = !!fileName.includes('pdf')
        const base64Prefix = base64PrefixMap[isPdf ? 'pdf' : 'excel']
        a.href = base64Prefix + type.file
        a.download = fileName
        a.click()
      })

      setStartDownload(false)
    }
  }, [downloadRes, selectedFileType])

  useEffect(() => {
    if (emailSuccessfullySended) {
      successNotification({
        message: t('Checkout.ImportExport.SuccessFeedbackEmail'),
        className: 'afa-checkout-success-email-notification',
      })
    }
    if (emailNotSuccessfullySended) {
      errorNotification({
        message: t('Checkout.ImportExport.ErrorFeedbackEmail'),
        className: 'afa-checkout-error-email-notification',
      })
    }
  }, [t, emailSuccessfullySended, emailNotSuccessfullySended])

  const [email, setEmail] = useState<string>('')

  const addresses = email.split(/, ?/)

  const handleSendEmail = () => {
    if (addresses.length && email) {
      const onSuccess = () => {
        sendEmailCart({
          addresses,
          showPriceWS,
          showPriceRT,
          showImage: showImages,
          fileType: selectedFileType,
        })
        setEmail('')
      }
      handleSubmit({ onSuccess })
    } else {
      errorNotification({
        message: t('Checkout.ImportExport.InsertMail'),
      })
    }
  }

  const handleFileSelection = (fileType: 'pdf' | 'excel') => {
    const updatedFileType = (selectedFileType && [...selectedFileType]) || []
    const fileTypeIndex = updatedFileType.indexOf(fileType)
    if (fileTypeIndex !== -1) {
      updatedFileType.splice(fileTypeIndex, 1)
    } else {
      updatedFileType.push(fileType)
    }
    setSelectedFileType(updatedFileType)
  }

  return (
    <Wrapper className={className}>
      <SubSectionsContainer>
        <CheckboxsContainer>
          <GridTitle>{t('Checkout.ImportExport.ExportAs.Title')}</GridTitle>
          <CheckboxWrapper>
            <StyledCheckBox
              onChange={() => handleFileSelection('excel')}
              checked={!!selectedFileType?.includes('excel')}
              accentColor={palette.tangaroa}
            />
            {t('Checkout.ImportExport.ExportAs.Excel')}
          </CheckboxWrapper>
          <CheckboxWrapper>
            <StyledCheckBox
              onChange={() => handleFileSelection('pdf')}
              checked={!!selectedFileType?.includes('pdf')}
              accentColor={palette.tangaroa}
            />
            {t('Checkout.ImportExport.ExportAs.PDF')}
          </CheckboxWrapper>

          <GridTitle className="not-first">{t('Checkout.ImportExport.AddOptions.Title')}</GridTitle>
          <CheckboxWrapper>
            <StyledCheckBox
              onChange={() => setShowImages(!showImages)}
              checked={showImages}
              accentColor={palette.tangaroa}
            />
            {t('Checkout.ImportExport.AddOptions.ItemImages')}
          </CheckboxWrapper>
          <CheckboxWrapper>
            <StyledCheckBox
              onChange={() => setShowPriceWS(!showPriceWS)}
              checked={showPriceWS}
              accentColor={palette.tangaroa}
            />
            {t('Checkout.ImportExport.AddOptions.WHSPrices')}
          </CheckboxWrapper>
          <CheckboxWrapper>
            <StyledCheckBox
              onChange={() => setShowPriceRT(show => !show)}
              checked={showPriceRT}
              accentColor={palette.tangaroa}
            />
            {t('Checkout.ImportExport.AddOptions.RetailPrices')}
          </CheckboxWrapper>
        </CheckboxsContainer>
      </SubSectionsContainer>

      <div>
        <FormatTitle>{t('Checkout.ImportExport.ExportFormat')}</FormatTitle>
        <Subtitle>{t('Checkout.ImportExport.ExportFormatDescription')}</Subtitle>
        <SubSectionForm>
          <InputAndFeedBack>
            <SendForm>
              <CustomInput
                value={email}
                onChange={e => {
                  setEmail(e.currentTarget.value)
                }}
                pattern="([^@ \t\r\n^,]+@[^@ \t\r\n^,]+\.[^@ \t\r\n^,]+,?\s?)+"
                placeholder={t('Checkout.ImportExport.EmailPlaceholderAfa')}
                required
              />
            </SendForm>
            {showFeedback && !disableButtonDownload && (
              <Feedback>
                <RCIcon key="icon" type="icon_check" />
                {t('Checkout.ImportExport.SuccessFeedback')}
              </Feedback>
            )}
          </InputAndFeedBack>
          <FormatButtonWrapper>
            <StyledButton
              icon="new-send"
              disabled={sendingEmail}
              onClick={handleSendEmail}
              text={t('Checkout.ImportExport.SendEmail')}
              textFirst={false}
            />

            <StyledButton
              icon="new-download"
              text={t('Checkout.ImportExport.Download')}
              textFirst={false}
              disabled={disableButtonDownload}
              onClick={handleDownload}
            />
          </FormatButtonWrapper>
        </SubSectionForm>
      </div>
    </Wrapper>
  )
}

export default ExportCartAfa
