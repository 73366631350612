import { Icon } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled from 'styled-components'

import { useShowMoodboard } from '../../hooks/useHandleOpenPages'
import { getDeviceFromUrl } from '../../libs/url'
import { useGetBrandContentQuery, useGetBrandLogo } from '../../services/afaContents'
import {
  breakpoints,
  getFluidFontSize,
  getFluidSizeWithFullFormula,
  getFluidSizeWithFullFormulaHeight,
  palette,
  pxToRem,
} from '../../style/theme'
import { useGetAfaBrandByCode } from '../../services/afa'

const Wrapper = styled.div`
  flex-grow: 1;
  display: grid;
  grid-auto-rows: 1fr max-content;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${palette.wildSand};
  position: relative;
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;

  @media screen and (max-width: ${breakpoints.M}) {
    justify-content: end;
  }
`

const ProductPageButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1em 2em;
  background-color: ${palette.white};
  border-radius: 10rem;
  line-height: 1;
  color: ${palette.tangaroa};
  left: ${getFluidSizeWithFullFormula('px', 32, 64, 1366, 3840)};
  font-size: ${getFluidFontSize('13px')};
  box-shadow: 0 4px 4px 0 rgba(3, 20, 52, 0.17);
  font-weight: bold;
  gap: ${pxToRem(8)}rem;
  bottom: ${getFluidSizeWithFullFormula('px', 32, 64, 1366, 3840)};
  position: absolute;

  @media screen and (max-width: ${breakpoints.M}) {
    right: ${getFluidSizeWithFullFormula('px', 32, 64, 1366, 3840)};
    left: initial;
  }
`

const Body = styled.div`
  padding-left: ${getFluidSizeWithFullFormula('px', 142, 297, 1366, 3840)};
  padding-right: ${getFluidSizeWithFullFormula('px', 142, 297, 1366, 3840)};
  padding-top: ${getFluidSizeWithFullFormula('px', 88, 116, 1366, 3840)};
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const Title = styled.div`
  font-weight: bold;
  font-size: ${getFluidSizeWithFullFormula('px', 76, 100, 1366, 3840)};
  color: ${palette.tangaroa};

  @media (min-width: ${breakpoints.XL}) {
    min-height: ${pxToRem(100)}rem;
  }
`

const Description = styled.div<{
  minHeight?: boolean
}>`
  color: ${palette.tangaroa};
  font-size: ${getFluidSizeWithFullFormula('px', 16, 36, 1366, 3840)};
  min-height: ${({ minHeight }) =>
    minHeight ? getFluidSizeWithFullFormula('px', 112, 287, 1366, 3840) : 'unset'};
`

const ImageCols = styled.div<{
  cols: number
}>`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(${({ cols }) => cols}, minmax(0, 1fr));
  grid-template-rows: minmax(0, 1fr);
  gap: 1rem;

  ${({ cols }) =>
    cols > 2 &&
    `
    & > div:nth-of-type(2) {
      transform: translateY(calc(${getFluidSizeWithFullFormula('px', 100, 177, 1366, 3840)}));
    }
  `}
`

const ImageCol = styled.div`
  display: flex;
  justify-content: center;
`

const ImageColWithText = styled.div`
  display: grid;
  grid-template-rows: max-content minmax(0, 1fr);
`

const ImageWrapper = styled.div`
  flex-grow: 1;
  max-height: ${pxToRem(564)}rem;

  @media screen and (max-height: 1080px) {
    max-height: ${getFluidSizeWithFullFormulaHeight('px', 400, 709, 768, 1080)};
  }

  @media screen and (min-height: 1080px) {
    max-height: ${getFluidSizeWithFullFormulaHeight('px', 709, 1376, 1080, 2160)};
  }
`

const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: unset;
  max-height: 100%;
`

type Props = {
  sport?: string
  keylook?: string
  brandCode?: string
}

const AfaMoodboard: React.FC<Props> = ({ sport, keylook, brandCode }) => {
  const { t } = useTranslation()
  const params = useParams<{ brandCode: string }>()

  const brandCodeWithFallback = params.brandCode || brandCode || ''
  const brandContent = useGetBrandContentQuery(brandCodeWithFallback, sport)
  const { brandLogo, isSuccess } = useGetBrandLogo(brandCodeWithFallback)
  const { data: brand } = useGetAfaBrandByCode(brandCodeWithFallback)

  const keylookContent = keylook ? brandContent.data?.moodboardKeyLooks?.[keylook] : undefined
  const content = keylookContent || brandContent.data?.moodboardSport
  const images = content?.images || []

  const { switchMoodboard } = useShowMoodboard(params.brandCode, sport, keylook)

  const device = getDeviceFromUrl()

  return (
    <Wrapper>
      <Body>
        {content ? (
          <>
            <Title>{content?.title}</Title>
            {images.length > 2 && <Description>{content?.text}</Description>}
            <ImageCols cols={images.length}>
              {images.map((image, index) => {
                return index === 0 && images.length === 2 ? (
                  <ImageColWithText key={index}>
                    <Description minHeight>{content?.text}</Description>
                    <ImageCol>
                      <ImageWrapper>
                        <Image src={image} />
                      </ImageWrapper>
                    </ImageCol>
                  </ImageColWithText>
                ) : (
                  <ImageCol key={index}>
                    <ImageWrapper>
                      <Image src={image} />
                    </ImageWrapper>
                  </ImageCol>
                )
              })}
            </ImageCols>
          </>
        ) : (
          <ImageCol>
            <ImageWrapper>
              {brandLogo || !isSuccess ? (
                <Image src={brandLogo} alt={brand?.name || brandCodeWithFallback} />
              ) : (
                <span>{brand?.name || brandCodeWithFallback}</span>
              )}
            </ImageWrapper>
          </ImageCol>
        )}
      </Body>

      <Footer>
        {device === 'table' && (
          <ProductPageButton onClick={switchMoodboard}>
            <Icon type="switcher" />
            <span>{t('Afa.MoodboardProductPage')}</span>
          </ProductPageButton>
        )}
      </Footer>
    </Wrapper>
  )
}

export default AfaMoodboard
