import { ContentAPI } from '../../api/restApi'
import i18n from '../../i18n'
import { getCmsTagFromEventList } from '../../libs/event'
import { handleErrors } from '../../libs/handleError'
import log from '../../libs/log'
import { RcEvent } from '../../model/events'
import { AppThunk } from '../../model/model'
import { slice } from './slice'
import { errorNotification } from '../../components/Notification/notifications'

export const getAdvContents = (
  eventId: string,
  eventList: RcEvent[] | null,
  brandCodes: string[],
  append = false,
): AppThunk => {
  return dispatch => {
    if (!brandCodes.length) {
      return
    }
    dispatch(slice.actions.loadingAdv())
    const contentAPI = new ContentAPI()
    const cmsTag = getCmsTagFromEventList(eventId, eventList)

    return contentAPI
      .get({ type: 'adv', eventId, cmsTag, brand: brandCodes?.join(',') })
      .then(handleErrors)
      .then(advData => dispatch(slice.actions.setAdvContent({ contents: advData, append })))
      .catch(err => {
        log.error(err, 'getAdvContents')
        errorNotification({ message: i18n.t('Errors.loadingAdvContent') })
      })
  }
}

export const setAdvContent = slice.actions.setAdvContent
export const loadingAdv = slice.actions.loadingAdv

export default {
  ...slice.actions,
  getAdvContents,
}
