import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { isPageMatches } from '../../libs/url'
import { CartFilters, ToggleFilters } from '../../model/filters'
import { toggleFilters } from '../../store/actions'
import { initFiltersCounters } from '../../store/filters/actions'
import { filterActivePageNameSelector, filtersSelector } from '../../store/filters/selectors'
import { breakpoints, getFluidFontSize, palette, pxToRem, spacing } from '../../style/theme'
import Row from '../../styleguide/Row'
import FiltersBrandBar from '../FiltersBrandBar'
import StarsWholesaleSwitch from '../StarsWholesaleSwitch/StarsWholesaleSwitch'
import FilterCategory from './FilterCategory'
import { FiltersRecapPanelGeneral } from './FiltersRecapPanel/FiltersRecapPanel'

const Wrapper = styled.div`
  font-size: ${getFluidFontSize('10px')};
`

const StarsWholesaleSwitchWithMargin = styled(StarsWholesaleSwitch)`
  margin-bottom: ${pxToRem(spacing(2))}rem;
`

const FilterCategoryItem = styled(FilterCategory)`
  width: ${({ width }: { width: number }) => `${width}vw`};
  margin-bottom: 1.4rem;
  .filter-wrapper-vertical {
    display: flex;
    flex-flow: column wrap;
  }
  .filter-wrapper-vertical-max-height {
    display: flex;
    flex-flow: column wrap;
    max-height: 10em;
  }
  .filter-wrapper-horizontal {
    display: flex;
    flex-wrap: wrap;
  }
  .ant-checkbox-wrapper {
    font-size: inherit;
    margin: 0 0 1em 0;
    @media screen and (max-width: ${breakpoints.S}) {
      margin: 0 0 1.3em 0;
    }
  }

  .ant-radio-wrapper {
    margin: 0 0 1em 0;
    @media screen and (max-width: ${breakpoints.S}) {
      margin: 0 0 1.3em 0;
    }
  }

  .ant-checkbox {
    & + span {
      padding-right: 0;
    }
    .ant-checkbox-inner {
      border: 0.9px solid ${palette.cornflowerBlue};
      border-radius: ${pxToRem(3.6)}rem;
      background-color: ${palette.selago};
      &:after {
        border-color: ${palette.congressBlue};
      }
    }
  }
  .ant-radio {
    .ant-radio-inner {
      background-color: ${palette.selago};
      &:after {
        border-color: ${palette.congressBlue};
      }
    }
  }
  .ant-checkbox-wrapper {
    width: ${({ width }: { width: number }) => {
      switch (width) {
        case 15:
          return '13'
        case 25:
          return '10.5'
        case 45:
          return '12.5'
      }
    }}vw;
  }
`

const FilterCategoryItemColor = styled(FilterCategoryItem)`
  .round-color {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:after {
      content: '';
      display: block;
      width: 1.5vh;
      height: 1.5vh;
      background: #222;
      border-radius: 50%;
      border: 1px solid #ddd;
    }
    &.grey:after {
      background: #999999;
    }
    &.brown:after {
      background: #996633;
    }
    &.green:after {
      background: #339933;
    }
    &.blue:after {
      background: blue;
    }
    &.violet:after {
      background: #663399;
    }
    &.pink:after {
      background: #cc00cc;
    }
    &.red:after {
      background: #f54132;
    }
    &.orange:after {
      background: #ff6600;
    }
    &.yellow:after {
      background: #ffcc33;
    }
    &.white:after {
      background: white;
      border: 1px solid #dadada;
    }

    &.bordeaux:after {
      background: #5f021f;
    }
    &.bronzecopper:after {
      background: #7c600b;
    }
    &.gold:after {
      background: #b8860b;
    }
    &.silver:after {
      background: #c0c0c0;
    }
    &.lightblue:after {
      background: #add8e6;
    }
    &.gunmetal:after {
      background: #2c3539;
    }
    &.honey:after {
      background: #ebaf4c;
    }
    &.havana:after {
      background: #999;
    }
    &.ivory:after {
      background: #fffff0;
      border: 1px solid #dadada;
    }
    &.lightbrown:after {
      background: #b5651d;
    }
    &.purplereddish:after {
      background: #c71585;
    }
    &.clear:after {
      background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(241, 241, 241, 1) 50%,
        rgba(225, 225, 225, 1) 51%,
        rgba(246, 246, 246, 1) 100%
      );
    }
    &.multi:after {
      background: linear-gradient(-45deg, #ff6600, #f54132, #cc00cc, #663399);
    }
    span {
      text-align: center;
    }
  }
  .ant-checkbox-checked + span {
    font-weight: bold;
    color: #222;
  }
  .filter-front-color-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .ant-checkbox {
    display: none;
  }
`

const assortmentIntent = (filters: CartFilters, toggleSelectedFilters: ToggleFilters) => (
  <FilterCategoryItem
    width={15}
    filterName="assortmentIntent"
    filter={filters.assortmentIntent}
    toggleSelectedFilters={toggleSelectedFilters}
  />
)
const lensProperties = (filters: CartFilters, toggleSelectedFilters: ToggleFilters) => (
  <FilterCategoryItem
    width={15}
    filterName="lensProperties"
    filter={filters.lensProperties}
    toggleSelectedFilters={toggleSelectedFilters}
  />
)
const frontShape = (filters: CartFilters, toggleSelectedFilters: ToggleFilters) => (
  <FilterCategoryItem
    width={45}
    filterName="frameShape"
    wrapperClass="filter-wrapper-vertical-max-height"
    filter={filters.frameShape}
    toggleSelectedFilters={toggleSelectedFilters}
  />
)
const frontMaterial = (filters: CartFilters, toggleSelectedFilters: ToggleFilters) => (
  <FilterCategoryItem
    width={25}
    filterName="frontMaterial"
    wrapperClass="filter-wrapper-vertical-max-height"
    filter={filters.frontMaterial}
    toggleSelectedFilters={toggleSelectedFilters}
  />
)
const tagsCategory = (filters: CartFilters, toggleSelectedFilters: ToggleFilters) => (
  <FilterCategoryItem
    width={15}
    filterName="tags"
    filter={filters.tags}
    toggleSelectedFilters={toggleSelectedFilters}
  />
)
const releaseCategory = (filters: CartFilters, toggleSelectedFilters: ToggleFilters) => {
  return (
    filters.release && (
      <FilterCategoryItem
        width={15}
        filterName="release"
        filter={filters.release}
        toggleSelectedFilters={toggleSelectedFilters}
      />
    )
  )
}
const frontColor = (filters: CartFilters, toggleSelectedFilters: ToggleFilters) => (
  <FilterCategoryItemColor
    width={45}
    filterName="frontFamilyColor"
    wrapperClass="filter-front-color-wrapper"
    translationLabel="frontcolor"
    filter={filters.frontFamilyColor}
    colClass="filter-front-color"
    toggleSelectedFilters={toggleSelectedFilters}
    withoutInput={true}
  />
)

const FiltersRow = styled(Row)`
  display: flex;
  flex-direction: row;
  margin: ${spacing(0)};
  padding: ${spacing(0)};
  color: ${palette.biscay};
  font-weight: bold;
  text-transform: uppercase;
  padding-top: ${({ $paddingTop }: { $paddingTop?: number }) =>
    $paddingTop ? $paddingTop + 'vh' : 0};
`

const FiltersRecap = styled.div`
  width: 25vw;
`

export const ModalFilterBy: React.FC = () => {
  const filters = useSelector(filtersSelector)
  const filtersActivePageName = useSelector(filterActivePageNameSelector)

  const brandsVisible = filtersActivePageName !== 'cart'

  const dispatch = useDispatch()

  const toggleSelectedFilters: ToggleFilters = (filterName, optionId, type) =>
    dispatch(toggleFilters(filterName, optionId, type))

  const isChooseBrandPage = isPageMatches('choose-brand')

  return (
    <>
      <Wrapper>
        {isChooseBrandPage && (
          <StarsWholesaleSwitchWithMargin
            onChange={() => {
              dispatch(initFiltersCounters())
            }}
          />
        )}

        <FiltersRow>
          {filters.category && (
            <FilterCategoryItem
              width={10}
              filterName="category"
              translationLabel="category"
              filter={filters.category}
              type="radio"
              toggleSelectedFilters={toggleSelectedFilters}
            />
          )}
          <FilterCategoryItem
            width={15}
            filterName="gender"
            filter={filters.gender}
            toggleSelectedFilters={toggleSelectedFilters}
          />
          <FilterCategoryItem
            width={15}
            filterName="ageGroup"
            filter={filters.ageGroup}
            toggleSelectedFilters={toggleSelectedFilters}
          />
          <FilterCategoryItem
            width={25}
            filterName="size"
            wrapperClass="filter-wrapper-vertical-max-height"
            filter={filters.size}
            toggleSelectedFilters={toggleSelectedFilters}
          />
          <FilterCategoryItem
            width={15}
            filterName="type"
            filter={filters.type}
            toggleSelectedFilters={toggleSelectedFilters}
          />
          <FilterCategoryItem
            width={10}
            filterName="geoFit"
            filter={filters.geoFit}
            toggleSelectedFilters={toggleSelectedFilters}
          />
          <FilterCategoryItem
            width={10}
            filterName="asianFit"
            filter={filters.asianFit}
            toggleSelectedFilters={toggleSelectedFilters}
          />
        </FiltersRow>
        <FiltersRow $paddingTop={1}>
          {assortmentIntent(filters, toggleSelectedFilters)}
          {lensProperties(filters, toggleSelectedFilters)}
          {frontShape(filters, toggleSelectedFilters)}
          {frontMaterial(filters, toggleSelectedFilters)}
        </FiltersRow>
        <FiltersRow $paddingTop={1}>
          {tagsCategory(filters, toggleSelectedFilters)}
          {releaseCategory(filters, toggleSelectedFilters)}
          {frontColor(filters, toggleSelectedFilters)}

          <FiltersRecap>
            <FiltersRecapPanelGeneral />
          </FiltersRecap>
        </FiltersRow>
      </Wrapper>
      {brandsVisible && <FiltersBrandBar />}
    </>
  )
}

export default ModalFilterBy
