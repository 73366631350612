import { Layout } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import classnames from 'classnames'

import { getCLensToMocoCode } from '../../../libs/productsV2'
import { Brand } from '../../../model/brand'
import { PdpContent } from '../../../model/content'
import { loadPdpContent } from '../../../store/actions'
import { pdpMocosSortedSelector, pdpSelector } from '../../../store/pdp/selectors'
import Loading from '../../Loading'
import { Header, HugeTiles, SmallTiles } from '../../Moodboard'
import PageSocketManager, { PageSocketManagerProps } from '../../PageSocketManager'
import LastCartProduct from './LastCartProduct'
import { useGetBrandLogo } from '../../../services/afaContents'

const StyledContent = styled(Layout)`
  display: grid;
  grid-template-rows: auto min-content auto;
`

const StyledLastCartProduct = styled(LastCartProduct)`
  max-width: 40vw;
  min-width: 20vw;
  bottom: unset;
  top: 50%;
  transform: translateY(-50%);
`

type Props = {
  content?: PdpContent
  brand: Brand
}

export const WallPdpContent: React.FC<Props & PageSocketManagerProps> = ({ content, brand }) => {
  const pdp = useSelector(pdpSelector)
  const mocos = useSelector(pdpMocosSortedSelector)
  const dispatch = useDispatch()
  const { brandLogo } = useGetBrandLogo(brand.code)

  if (!content) {
    return <Loading />
  }

  if (mocos) {
    const index = mocos.findIndex(moco => moco.mocoCode === pdp.code)
    if (index !== -1) {
      const mocoCode = mocos[index].mocoCode
      const allMocoCodes = mocos.map(moco => moco.mocoCode)
      const cLensAndMocos = getCLensToMocoCode(mocos)

      const contentPdp = pdp.content
      if (!contentPdp[mocoCode] && pdp.couvette) {
        dispatch(
          loadPdpContent({
            mocos: allMocoCodes,
            cLensAndMocos,
            brand: pdp.couvette.brandCode,
          }),
        )
      }
    }
  }

  return (
    <Layout className="layout">
      <StyledContent
        data-testid="wall-pdp"
        className={classnames('layout', { 'wall-pdp-content': true })}
      >
        <Header
          logo={brandLogo || ''}
          alt={brand.brand || brand.code}
          title={(content && content.titolo && content.titolo.title) || ''}
        />
        <HugeTiles
          image1={(content && content.content1 && content.content1.url) || ''}
          image2={(content && content.content2 && content.content2.url) || ''}
          image3={(content && content.content3 && content.content3.url) || ''}
        />
        <SmallTiles
          tile1={content && content.cu1}
          tile2={content && content.cu2}
          tile3={content && content.cu3}
        >
          <StyledLastCartProduct />
        </SmallTiles>
      </StyledContent>
    </Layout>
  )
}

export default PageSocketManager(WallPdpContent)
