import { Button, Popconfirm } from 'antd'
import classnames from 'classnames'
import { debounce } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import {
  useRevertableUpdateCart,
  useUpdateCart,
  useUpdateCartOnlyIfMainDoorIsEnabled,
} from '../../../../hooks/useUpdateCart'
import { getDefaultDoorId } from '../../../../libs/doors'
import { isNotNull } from '../../../../libs/utils'
import { getIsMassiveOrderEnabled } from '../../../../store/app/selectors'
import { cartEditableSelector, cartProductsSelector } from '../../../../store/cart/selectors'
import { activeDoorsSelector } from '../../../../store/customer/selectors'
import { wishlistSizesSelector } from '../../../../store/wishlist/selectors'
import { breakpointsNoUnit } from '../../../../style/theme'
import { Notification } from '../../../Notification'

const StyledPopconfirm = styled(Popconfirm as any)`
  &.hide-on-ipad {
    @media (max-width: ${breakpointsNoUnit.L - 1}px) {
      visibility: hidden;
    }
  }
  &.hide-on-desktop {
    @media (min-width: ${breakpointsNoUnit.L}px) {
      display: none;
    }
  }
`

const PopupWrapper = styled.div`
  position: fixed;
  bottom: 6vh;
  right: 2vw;
  z-index: 1020;
  max-width: 450px;
`

const AntButton = Button as any

type Props = {
  hideOnIpad: boolean
}

const FooterApplyButton: React.FC<Props> = ({ hideOnIpad }) => {
  const { t } = useTranslation()

  const isMassiveOrderEnabled = useSelector(getIsMassiveOrderEnabled)
  const enabledDoors = useSelector(activeDoorsSelector)
  const cartEditable = useSelector(cartEditableSelector)
  const cartProducts = useSelector(cartProductsSelector)
  const wishlistSizes = useSelector(wishlistSizesSelector)

  const [showPopup, setShowPopup] = useState(false)

  const updateCart = useUpdateCart()
  const revertableUpdateCart = useRevertableUpdateCart()
  const updateCartOnlyIfMainDoorIsEnabled = useUpdateCartOnlyIfMainDoorIsEnabled()

  const noEnabledDoors = !enabledDoors || !enabledDoors.length
  if (noEnabledDoors) return null

  const defaultDoorId = getDefaultDoorId(enabledDoors)

  const onCartClick = () => {
    const doors = isMassiveOrderEnabled ? enabledDoors.map(door => door.id) : [defaultDoorId]
    const updateCartPayload = {
      doors: doors.filter(isNotNull),
      productSizes: wishlistSizes,
      cartProducts,
      qntToAdd: 1,
    }
    const updateCartCallback = isMassiveOrderEnabled
      ? () => revertableUpdateCart(updateCartPayload, ['wishlist'])
      : () => updateCart(updateCartPayload)
    updateCartOnlyIfMainDoorIsEnabled(updateCartCallback)
    if (
      wishlistSizes.filter(size => size.starsDoors?.some((door: string) => doors.includes(door)))
        .length > 0
    ) {
      setShowPopup(true)
    }
  }

  return (
    <StyledPopconfirm
      placement="top"
      title={t('FooterNav.wishlist_addtocart_text')}
      onConfirm={debounce(onCartClick, 500)}
      okText={t('FooterNav.wishlist_addtocart_yes')}
      cancelText={t('FooterNav.wishlist_addtocart_no')}
      icon={''}
      overlayClassName="apply-wishlist-btn"
      className={classnames({
        'hide-on-ipad': hideOnIpad,
        'hide-on-desktop': !hideOnIpad,
      })}
    >
      {wishlistSizes.length > 0 && cartEditable && (
        <AntButton className="apply-button" type="primary">
          {t('FooterNav.wishlist_addtocart')}
        </AntButton>
      )}

      {showPopup && (
        <PopupWrapper>
          <Notification
            text={t('Wishlist.AddWarning')}
            type="notice"
            onClose={() => setShowPopup(false)}
          />
        </PopupWrapper>
      )}
    </StyledPopconfirm>
  )
}

export default FooterApplyButton
