import filter from 'mout/object/filter'

import { createSelector } from '@reduxjs/toolkit'

import { getBrandByBrandCode } from '../../libs/brand'
import { Brand } from '../../model/brand'
import { CartProduct } from '../../model/cart'
import { RootState } from '../../model/model'
import { Moco, Mocos, Product } from '../../model/product'
import { WishlistProduct } from '../../model/wishlist'
import { brandsSelector } from '../brands/selectors'
import { cartProductsFilteredByActiveDoorsSelector } from '../cart/selectors'
import app_config from '../../config/app/config'

export const wishlistSliceSelector = (state: RootState) => state.wishlist

export const wishlistSelector = (state: RootState) => state.wishlist.wishlistData

export const wishlistCurrentCategoryIdSelector = (state: RootState) =>
  state.wishlist.currentCategoryId

export const wishlistProductsRawSelector = (state: RootState) =>
  state.wishlist.wishlistData.products

export const wishlistProductDetailsSelector = (state: RootState) =>
  state.wishlist.wishlistData.productDetails

export const wishlistProductsSelector_cb = (
  wishlistProducts: WishlistProduct[],
  brands: Brand[],
  cartProductsOnActiveDoors: CartProduct[],
) =>
  wishlistProducts.filter(({ brandCode, upc }: { brandCode: string; upc: string }) => {
    const brand = getBrandByBrandCode(brands, brandCode)
    const inCart = cartProductsOnActiveDoors.some(
      (cartProduct: CartProduct) => cartProduct.upc === upc,
    )
    return Boolean(brand) && !inCart
  })
export const wishlistProductsSelector = createSelector(
  wishlistProductsRawSelector,
  brandsSelector,
  cartProductsFilteredByActiveDoorsSelector,
  wishlistProductsSelector_cb,
)

export const wishlistIdSelector = (state: RootState) => state.wishlist.wishlistData.id

export const wishlistDataLoadingSelector = (state: RootState) => ({
  loading: state.wishlist.loading,
  loaded: state.wishlist.loaded,
})

export const isWishlistLoadingSelector = (state: RootState) => state.wishlist.loading

export const wishlistCouvettesSelector = createSelector(
  wishlistProductsSelector,
  wishlistProductDetailsSelector,
  wishlistDataLoadingSelector,
  (wishlistProducts, productDetails, loadingData) => {
    const uniqueModelCodes = [...new Set(wishlistProducts.map(({ modelCode }) => modelCode))]
    const uniqueMocoCodesMap = wishlistProducts.reduce(
      (result: Record<string, any>, { mocoCode }: { mocoCode: string }) => {
        result[mocoCode] = true
        return result
      },
      {},
    )
    const items = uniqueModelCodes
      .map(modelCode => productDetails[modelCode])
      .filter(Boolean)
      .map(product => {
        const mocosInWishlist = filter(
          product.mocos,
          ({ mocoCode }: Moco) => uniqueMocoCodesMap[mocoCode],
        ) as Mocos

        return {
          ...product,
          mocos: mocosInWishlist,
        }
      })

    return {
      items,
      ...loadingData,
    }
  },
)

export const wishlistFilterItemsSelector = createSelector(
  wishlistCouvettesSelector,
  brandsSelector,
  (wishlistCouvettes, brands) => (categoryId: string | 0 | undefined) => {
    const isSunCategory = !!categoryId && app_config.sunCategories.includes(categoryId)
    const isOpticalCategory = !!categoryId && app_config.opticalCategories.includes(categoryId)

    const wishlistFilterItems =
      wishlistCouvettes.items.filter(
        item =>
          !categoryId ||
          (isSunCategory && app_config.sunCategories.includes(item.category?.id || '')) ||
          (isOpticalCategory && app_config.opticalCategories.includes(item.category?.id || '')),
      ) || []

    const sortWishlistCouvettesByBrandSlug = (a: Product, b: Product) => {
      const aBrandSlug = brands.find(brand => brand.code === a.brandCode)?.slug
      const bBrandSlug = brands.find(brand => brand.code === b.brandCode)?.slug
      return aBrandSlug && bBrandSlug && aBrandSlug > bBrandSlug ? 1 : -1
    }

    // sort wishlist items by brands
    wishlistFilterItems.sort((a, b) => sortWishlistCouvettesByBrandSlug(a, b))
    // in every brands sort wishlist items first by category and after by plpOrder
    brands.map(brand => {
      wishlistFilterItems.sort((a, b) => {
        if (a.brandCode === brand.code && b.brandCode === brand.code) {
          return a.category?.id && b.category?.id && a.category?.id > b.category?.id
            ? -1
            : a.category?.id === b.category?.id
            ? a.plpOrder < b.plpOrder
              ? -1
              : 1
            : 1
        } else {
          return 0
        }
      })
    })

    return wishlistFilterItems
  },
)

export const wishlistSizesSelector = createSelector(
  wishlistCouvettesSelector,
  wishlistProductsSelector,
  (wishlistCouvettes, wishlistProducts) => {
    return wishlistCouvettes.items
      .flatMap(couvette =>
        Object.values(couvette.mocos).flatMap((moco: any) =>
          Object.values(moco.sizes).map((size: any) => ({
            ...size,
            enabledDoors: moco.enabledDoors,
            brandCode: moco.brandCode,
          })),
        ),
      )
      .filter(size => wishlistProducts.map(product => product.upc).includes(size.upc))
  },
)

export const brandsSliderListSelector = createSelector(
  wishlistCurrentCategoryIdSelector,
  wishlistFilterItemsSelector,
  (wishlistCurrentCategoryId, wishlistFilterItems) => (brands: Brand[]) => {
    return wishlistCurrentCategoryId
      ? brands.filter(brand => {
          return wishlistFilterItems(wishlistCurrentCategoryId).filter(item => {
            return item.brandCode === brand.code && item.category?.id === wishlistCurrentCategoryId
          }).length
        })
      : brands
  },
)
