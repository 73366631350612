import PropTypes from 'prop-types'
import React from 'react'

type Props = {
  className?: string
}

const SwitcherIcon: React.FC<Props> = ({ className, ...props }) => (
  <svg
    viewBox="64 64 896 896"
    className={['rc-icon', className].join(' ')}
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
    focusable="false"
    {...props}
  >
    <path d="M752 240H144c-17.7 0-32 14.3-32 32v608c0 17.7 14.3 32 32 32h608c17.7 0 32-14.3 32-32V272c0-17.7-14.3-32-32-32zm-40 600H184V312h528v528zm168-728H264c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h576v576c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V144c0-17.7-14.3-32-32-32zM300 550h296v64H300z"></path>
  </svg>
)

SwitcherIcon.propTypes = {
  className: PropTypes.string,
}

export default SwitcherIcon
