import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import whiteboardActions from '../../../../../store/whiteboard/actions'
import {
  ButtonsWrapper,
  CancelButton,
  ConfirmButton,
  SingleTemplate,
  SubTitle,
  TemplateImage,
  TemplateImageWrapper,
  TemplateTitle,
  TemplatesGrid,
  Title,
  Wrapper,
} from './style'
import {
  getSelectedSwitch,
  templatesSelector,
  whiteboardsSelector,
} from '../../../../../store/whiteboard/selectors'
import { useState } from 'react'

const WhiteboardModalTemplate: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { whiteboards: templates } = useSelector(templatesSelector)
  const { whiteboards } = useSelector(whiteboardsSelector)
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null)

  const selectedType = useSelector(getSelectedSwitch)

  const templatesOrWhiteboardsToShow = selectedType === 'whiteboard' ? templates : whiteboards

  const handleClose = () => {
    dispatch(whiteboardActions.setOpenTemplateModal(false))
    setSelectedTemplate(null)
  }

  const handleConfirm = () => {
    if (selectedTemplate) {
      dispatch(whiteboardActions.setWhiteboardFromTemplate(selectedTemplate))
      dispatch(whiteboardActions.deselectWhiteboard())
      dispatch(whiteboardActions.changeSelectedSwitch(selectedType))
      handleClose()
      dispatch(whiteboardActions.setOpenWhiteboardSettings(true))
    }
  }

  return (
    <Wrapper>
      <Title>{t(`Whiteboard.templateModal.title_${selectedType}`)}</Title>
      <SubTitle>{t(`Whiteboard.templateModal.subTitle_${selectedType}`)}</SubTitle>
      <TemplatesGrid>
        {templatesOrWhiteboardsToShow?.map(template => {
          return (
            <SingleTemplate key={template.key}>
              <TemplateImageWrapper
                className={selectedTemplate === template.key ? 'selected' : ''}
                onClick={() => {
                  setSelectedTemplate(template.key)
                }}
              >
                <TemplateImage src={template.previewProducts || ''} />
              </TemplateImageWrapper>
              <TemplateTitle>{template.name}</TemplateTitle>
            </SingleTemplate>
          )
        })}
      </TemplatesGrid>
      <ButtonsWrapper>
        <CancelButton onClick={() => handleClose()}>{t('Whiteboard.cancel')}</CancelButton>
        <ConfirmButton
          onClick={() => handleConfirm()}
          className={!selectedTemplate ? 'disabled' : ''}
        >
          {t(`Whiteboard.newCreation.create_${selectedType}`)}
        </ConfirmButton>
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default WhiteboardModalTemplate
