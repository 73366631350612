import React, { SVGProps } from 'react'

const ResizeIcon: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="26.000999"
      height="20"
      viewBox="0 0 26.000999 20"
      version="1.1"
      id="svg831"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
    >
      <defs id="defs825">
        <path id="3yctnd3m5a" d="M 0,0 H 25 V 20 H 0 Z" />
      </defs>
      <path d="m 22.628,18.494 c 0.589,0 1.044,-0.156 1.364,-0.468 0.32,-0.312 0.48,-0.774 0.48,-1.386 V 3.36 c 0,-0.612 -0.16,-1.074 -0.48,-1.386 C 23.672,1.662 23.217,1.506 22.628,1.506 H 3.372 c -0.6,0 -1.06,0.156 -1.377,0.468 C 1.678,2.286 1.519,2.748 1.519,3.36 v 13.28 c 0,0.612 0.159,1.074 0.476,1.386 0.318,0.312 0.777,0.468 1.377,0.468 z M 0.84,19.18 C 0.28,18.632 0,17.814 0,16.725 V 3.284 C 0,2.194 0.28,1.375 0.84,0.825 1.402,0.275 2.235,0 3.339,0 h 19.316 c 1.11,0 1.944,0.276 2.505,0.83 0.56,0.552 0.841,1.37 0.841,2.454 v 13.44 c 0,1.084 -0.28,1.9 -0.84,2.45 C 24.599,19.724 23.764,20 22.655,20 H 3.338 C 2.234,20 1.401,19.726 0.841,19.18 Z M 17.33,7.746 v -3.79 c 0,-0.725 0.346,-1.088 1.039,-1.088 h 3.81 c 0.693,0 1.04,0.363 1.04,1.089 v 3.79 c 0,0.725 -0.347,1.088 -1.04,1.088 h -3.81 c -0.693,0 -1.04,-0.363 -1.04,-1.089 z" />
    </svg>
  )
}

export default ResizeIcon
