import { useGetCustomerAssortmentQuery } from '../services'
import { useSearchParams } from '../../../hooks/useSearchParams'
import { useMemo } from 'react'
import { AAMoco, Positioning } from '../Model/aaModel'
import { ASSORTMENT_SIZE_DEFAULT, CATEGORIES, POSITIONINGS } from '../consts'
import { isCategoryElectronicsOptical, isCategoryOptical } from '../../../libs/couvettes'

type MocoCategory = {
  novelties: AAMoco[]
  carryover: AAMoco[]
}

type MocosByCategory = {
  sun: MocoCategory
  optical: MocoCategory
}

type MocosByPositioningAndCategory = Record<Positioning, MocosByCategory>

const sortMocoByRank = (moco1: AAMoco, moco2: AAMoco) => {
  return moco1.rank - moco2.rank
}

const getMocosByCategory = (mocos: AAMoco[]) => {
  const mocosByCategory = mocos.reduce(
    (result, moco) => {
      const isOptical =
        isCategoryOptical(moco.category) || isCategoryElectronicsOptical(moco.category)
      const category = isOptical ? 'optical' : 'sun'
      const novelty = moco.isNew ? 'novelties' : 'carryover'
      result[category][novelty].push(moco)
      return result
    },
    {
      sun: {
        novelties: [],
        carryover: [],
      },
      optical: {
        novelties: [],
        carryover: [],
      },
    } as MocosByCategory,
  )

  mocosByCategory.sun.novelties.sort(sortMocoByRank)
  mocosByCategory.sun.carryover.sort(sortMocoByRank)
  mocosByCategory.optical.novelties.sort(sortMocoByRank)
  mocosByCategory.optical.carryover.sort(sortMocoByRank)

  return mocosByCategory
}

const getMocosByPositioningAndCategory = (mocos: AAMoco[]) => {
  const mocosByPositioningAndCategory = mocos.reduce(
    (result, moco) => {
      const positioning = moco.positioning
      const category = moco.category === '2' ? 'sun' : 'optical'
      const noveltyOrCarryover = moco.isNew ? 'novelties' : 'carryover'
      result[positioning][category][noveltyOrCarryover].push(moco)
      return result
    },
    {
      young: {
        sun: {
          novelties: [],
          carryover: [],
        },
        optical: {
          novelties: [],
          carryover: [],
        },
      },
      sophisticated: {
        sun: {
          novelties: [],
          carryover: [],
        },
        optical: {
          novelties: [],
          carryover: [],
        },
      },
      innovative: {
        sun: {
          novelties: [],
          carryover: [],
        },
        optical: {
          novelties: [],
          carryover: [],
        },
      },
      sport: {
        sun: {
          novelties: [],
          carryover: [],
        },
        optical: {
          novelties: [],
          carryover: [],
        },
      },
      everyday: {
        sun: {
          novelties: [],
          carryover: [],
        },
        optical: {
          novelties: [],
          carryover: [],
        },
      },
    } as MocosByPositioningAndCategory,
  )

  const sortMocos = (
    category: keyof MocosByPositioningAndCategory,
    subcategory: keyof MocosByPositioningAndCategory[keyof MocosByPositioningAndCategory],
  ) => {
    mocosByPositioningAndCategory[category][subcategory].novelties.sort(sortMocoByRank)
    mocosByPositioningAndCategory[category][subcategory].carryover.sort(sortMocoByRank)
  }

  Object.entries(mocosByPositioningAndCategory).forEach(([positioning, categories]) => {
    Object.entries(categories).forEach(([cat]) =>
      sortMocos(
        positioning as keyof MocosByPositioningAndCategory,
        cat as keyof MocosByPositioningAndCategory[keyof MocosByPositioningAndCategory],
      ),
    )
  })

  return mocosByPositioningAndCategory
}

type SortType = 'asc' | 'desc'
type Moco = AAMoco & {
  [key: string]: unknown
}
const sortingMocoByFieldAndType = (arr: Moco[], field?: string, type?: SortType) => {
  if (!field || !type) return arr
  return arr.sort((a, b) => {
    if (typeof a[field] === 'string') {
      return type === 'asc' ? a.brand.localeCompare(b.brand) : b.brand.localeCompare(a.brand)
    } else {
      return type === 'asc'
        ? Number(a[field]) - Number(b[field])
        : Number(b[field]) - Number(a[field])
    }
  })
}

const useGetFilteredMocos = (sortCriteria?: string) => {
  const productsQuery = useGetCustomerAssortmentQuery()

  const [searchParams] = useSearchParams()

  const mocos = useMemo(() => {
    return productsQuery.data?.flatMap(product => Object.values(product.mocos || {})) || []
  }, [productsQuery])

  const mocosDefault = mocos.slice(0, ASSORTMENT_SIZE_DEFAULT)

  const getPositioningDefault = (positioning: Positioning) =>
    Math.round(
      (mocosDefault.filter(moco => moco.positioning === positioning).length * 100) /
        mocosDefault.length,
    )
  const opticalDefault = Math.round(
    (mocosDefault.filter(({ category }) => category === '1').length * 100) / mocosDefault.length,
  )
  const carryoverDefault = Math.round(
    (mocosDefault.filter(({ isNew }) => !isNew).length * 100) / mocosDefault.length,
  )

  const mocosByPositioningAndCategory = useMemo(() => {
    const mocosByPositioningAndCategory = getMocosByPositioningAndCategory(mocos)
    return mocosByPositioningAndCategory
  }, [mocos])

  const defaultParams = {
    mix: opticalDefault,
    composition: carryoverDefault,
    young: getPositioningDefault('young'),
    innovative: getPositioningDefault('innovative'),
    sophisticated: getPositioningDefault('sophisticated'),
    sport: getPositioningDefault('sport'),
    everyday: getPositioningDefault('everyday'),
  }

  const assortmentSize = Number(searchParams.get('size')) || ASSORTMENT_SIZE_DEFAULT
  const categoryMix = Number(searchParams.get('mix') || defaultParams.mix)
  const composition = Number(searchParams.get('composition') || defaultParams.composition)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getPositioningValue = (positioning: Positioning) =>
    Number(searchParams.get(positioning) || defaultParams[positioning])

  const quantitiesByPositioningAndCategory = useMemo(() => {
    const young = getPositioningValue('young')
    const innovative = getPositioningValue('innovative')
    const sophisticated = getPositioningValue('sophisticated')
    const sport = getPositioningValue('sport')
    const everyday = getPositioningValue('everyday')

    const percentageOptical = categoryMix
    const percentageSun = 100 - categoryMix

    const percentageCarryover = composition
    const percentageNew = 100 - composition

    const calculateSun = (positioningValue: number) =>
      Math.round((percentageSun * Math.round((positioningValue * assortmentSize) / 100)) / 100)
    const calculateOptical = (positioningValue: number) =>
      Math.round((percentageOptical * Math.round((positioningValue * assortmentSize) / 100)) / 100)

    const calculateNewAndCarryover = (sunValue: number, opticalValue: number) => {
      const newSun = Math.round((percentageNew * sunValue) / 100)
      const carryoverSun = Math.round((percentageCarryover * sunValue) / 100)
      const newOptical = Math.round((percentageNew * opticalValue) / 100)
      const carryoverOptical = Math.round((percentageCarryover * opticalValue) / 100)

      return {
        sun: {
          new: newSun,
          carryover: carryoverSun,
        },
        optical: {
          new: newOptical,
          carryover: carryoverOptical,
        },
      }
    }

    return {
      young: calculateNewAndCarryover(calculateSun(young), calculateOptical(young)),
      innovative: calculateNewAndCarryover(calculateSun(innovative), calculateOptical(innovative)),
      sophisticated: calculateNewAndCarryover(
        calculateSun(sophisticated),
        calculateOptical(sophisticated),
      ),
      sport: calculateNewAndCarryover(calculateSun(sport), calculateOptical(sport)),
      everyday: calculateNewAndCarryover(calculateSun(everyday), calculateOptical(everyday)),
    }
  }, [getPositioningValue, categoryMix, composition, assortmentSize])

  const filteredMocosByPositioning = useMemo(() => {
    const positioningCategories: (keyof MocosByPositioningAndCategory)[] = POSITIONINGS as Positioning[]
    const productCategories: (keyof MocosByPositioningAndCategory[keyof MocosByPositioningAndCategory])[] = CATEGORIES as (keyof MocosByCategory)[]

    const generateFilteredArray = (
      category: keyof MocosByPositioningAndCategory,
      subcategory: keyof MocosByPositioningAndCategory[keyof MocosByPositioningAndCategory],
    ) => {
      const newSlice = mocosByPositioningAndCategory[category][subcategory].novelties.slice(
        0,
        quantitiesByPositioningAndCategory[category][subcategory].new,
      )
      const carryoverSlice = mocosByPositioningAndCategory[category][subcategory].carryover.slice(
        0,
        quantitiesByPositioningAndCategory[category][subcategory].carryover,
      )

      return newSlice.concat(carryoverSlice)
    }

    let filteredArray: AAMoco[] = []
    positioningCategories.forEach(category => {
      productCategories.forEach(subcategory => {
        filteredArray = filteredArray.concat(generateFilteredArray(category, subcategory))
      })
    })

    return filteredArray
  }, [quantitiesByPositioningAndCategory, mocosByPositioningAndCategory])

  const filteredMocos = useMemo(() => {
    const percentageOptical = categoryMix
    const percentageSun = 100 - categoryMix

    const percentageCarryover = composition
    const percentageNew = 100 - composition

    const sizeOptical = Math.round((percentageOptical * assortmentSize) / 100)
    const sizeSun = Math.round((percentageSun * assortmentSize) / 100)

    const sizeOpticalCarryover = Math.round((percentageCarryover * sizeOptical) / 100)
    const sizeOpticalNew = Math.round((percentageNew * sizeOptical) / 100)

    const sizeSunCarryover = Math.round((percentageCarryover * sizeSun) / 100)
    const sizeSunNew = Math.round((percentageNew * sizeSun) / 100)

    const mocosByCategory = getMocosByCategory(filteredMocosByPositioning)

    const sunNew = mocosByCategory.sun.novelties.slice(0, sizeSunNew)
    const sunCarryover = mocosByCategory.sun.carryover.slice(0, sizeSunCarryover)
    const opticalNew = mocosByCategory.optical.novelties.slice(0, sizeOpticalNew)
    const opticalCarryover = mocosByCategory.optical.carryover.slice(0, sizeOpticalCarryover)

    const result = sunNew
      .concat(sunCarryover)
      .concat(opticalNew)
      .concat(opticalCarryover)
      .sort(sortMocoByRank)
      .slice(0, assortmentSize)

    const fieldToSort = sortCriteria?.split('-')[0]
    const type = sortCriteria?.split('-')[1] as SortType

    return sortCriteria ? sortingMocoByFieldAndType(result, fieldToSort, type) : result
  }, [assortmentSize, filteredMocosByPositioning, categoryMix, composition, sortCriteria])

  return {
    mocos: filteredMocos,
    isFetching: productsQuery.isFetching,
    isLoading: productsQuery.isLoading,
    defaultParams,
  }
}

export default useGetFilteredMocos
