import { RcEvent } from '../model/events'

const eventKey = 'rc-intersession-event-id'

export const storeIntersessionEventId = (eventId: string) => {
  if (eventId) {
    localStorage.setItem(eventKey, eventId)
  }
}

export const getIntersessionEventId = () => localStorage.getItem(eventKey)

export const deleteIntersessionEventId = () => {
  localStorage.removeItem(eventKey)
}

export const getCmsTagFromEventList = (eventId: string, eventList: null | RcEvent[]) => {
  const eventObject = eventList?.find(event => event.id === eventId)
  const cmsTag = eventObject && eventObject.cmsTag
  return cmsTag
}
