import {
  FromDoorInfo,
  ListShadow,
  ListWrapper,
  Option,
  TopDiv,
  Wrapper,
  WrapperRow,
} from '../AfaDuplicateCartOnDoorsModal'
import { useTranslation } from 'react-i18next'
import {
  ButtonCancel,
  ButtonSave,
  ButtonsGroup,
  ModalTitle,
} from '../../../../components/AfaCommonComponents'
import styled from 'styled-components'
import { MutationActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate'
import Loading from '../../../../components/Loading'
import { breakpoints, palette } from '../../../../style/theme'
import { getFluidSizeWithFullFormula as gF } from '../../../../style/theme'
import AfaDuplicateCartSearchBar from '../components/AfaDuplicateCartSearchBar'
import React, { useEffect, useState } from 'react'
import { StyledList } from '../AfaDuplicateCartOnDoorsModal'
import filterDoors from '../helpers/filterDoors'
import { useSelector } from 'react-redux'
import { activeDoorsSelector } from '../../../../store/customer/selectors'

const ButtonsGroupMod = styled(ButtonsGroup)`
  margin-top: 2em;
  @media screen and (min-width: ${breakpoints.L}) {
    margin-top: 0;
  }
`
const DuplicateWarning = styled.h3`
  font-size: ${gF('px', 18, 40, 1366, 3840)};
  margin-top: ${gF('px', 32, 40, 1366, 3840)};
  color: ${palette.cloudBurst};
  margin-bottom: ${gF('px', 32, 72, 1366, 3840)};
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: GilmerLight, sans-serif;
  @media (max-width: ${breakpoints.L}) {
    font-family: GilmerRegular, sans-serif;
  }
`
const WarningImg = styled.div`
  height: 1em;
  width: 1em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23F18200'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.6 4.8a.8.8 0 1 0-1.6 0V8a.8.8 0 1 0 1.6 0V4.8zm-.8 5.6a.8.8 0 1 0 0 1.6h.008a.8.8 0 1 0 0-1.6H7.8z' fill='%23fff'/%3E%3C/svg%3E");
`

type Props = {
  sourceDoorId: string
  destinationDoorIds: string[]
  setDestinationDoorIds: React.Dispatch<React.SetStateAction<string[]>>
  duplicateCartOnDoors: (payload: {
    sourceDoorId: string
    destinationDoorIds: string[]
  }) => MutationActionCreatorResult<any>
  setSourceDoorId: React.Dispatch<React.SetStateAction<string>>
  isLoading: boolean
  listCSS: {
    itemSize: number
    listHeight: number
    scrollbarColor: string
    scrollbarBackgroundColor: string
  }
  //setShowRecap: React.Dispatch<React.SetStateAction<boolean>> //FOR TESTING ONLY
}
const SecondPageAfaDuplicate: React.FC<Props> = ({
  sourceDoorId,
  destinationDoorIds,
  setDestinationDoorIds,
  duplicateCartOnDoors,
  setSourceDoorId,
  isLoading,
  listCSS,
  //setShowRecap, //FOR TESTING ONLY
}) => {
  const doors = useSelector(activeDoorsSelector)
  const { t } = useTranslation()
  const fromDoorObj = doors.find(({ id }) => id === sourceDoorId)
  const doorsFiltered = doors.filter(({ id }) => id !== sourceDoorId)

  const [doorSearch, setDoorSearch] = useState('')

  const onlyDoorId = doorsFiltered.length === 1 && doorsFiltered[0]?.id
  useEffect(() => {
    if (onlyDoorId) {
      setDestinationDoorIds([onlyDoorId])
    }
  }, [setDestinationDoorIds, onlyDoorId])

  if (!fromDoorObj) {
    throw new Error('no sourceDoorId')
  }

  const fromDoorName = `${fromDoorObj.id} - ${fromDoorObj.name}`

  return (
    <Wrapper>
      <TopDiv>
        <ModalTitle>{t('Afa.duplicateOrder')}</ModalTitle>
        <FromDoorInfo>
          {t('Afa.Cart.FromDoor')} <span>{fromDoorName}</span>
        </FromDoorInfo>
        <DuplicateWarning>
          <WarningImg />
          <span>{t('Afa.Cart.DuplicateWarning')}</span>
        </DuplicateWarning>
        <AfaDuplicateCartSearchBar doorSearch={doorSearch} setDoorSearch={setDoorSearch} />
        <ListWrapper>
          <StyledList
            height={listCSS.listHeight}
            width={'100%'}
            itemCount={filterDoors(doorsFiltered, doorSearch).length}
            itemSize={listCSS.itemSize}
            scrollbarColor={listCSS.scrollbarColor}
            scrollbarBackgroundColor={listCSS.scrollbarBackgroundColor}
          >
            {({ index, style }) => {
              const door = filterDoors(doorsFiltered, doorSearch)[index]
              return (
                <WrapperRow key={`${door.id}-${index}`} style={style}>
                  <Option>
                    <input
                      type="checkbox"
                      name="destination"
                      id={`destination-${door.id}`}
                      onChange={e => {
                        const checked = e.currentTarget.checked
                        setDestinationDoorIds(
                          checked
                            ? [...destinationDoorIds.concat(door.id)]
                            : destinationDoorIds.filter(id => id !== door.id),
                        )
                      }}
                      disabled={!sourceDoorId}
                      checked={destinationDoorIds.includes(door.id)}
                    />
                    <label htmlFor={`destination-${door.id}`}>
                      {door.id} - {door.name}
                    </label>
                  </Option>
                </WrapperRow>
              )
            }}
          </StyledList>
          <ListShadow />
        </ListWrapper>
      </TopDiv>
      <ButtonsGroupMod>
        <ButtonCancel onClick={() => setSourceDoorId('')}>{t('Afa.goBackBtn')}</ButtonCancel>
        <ButtonSave
          disabled={(doorsFiltered.length !== 1 && !destinationDoorIds.length) || isLoading}
          onClick={() => {
            if (!sourceDoorId) {
              throw new Error('no sourceDoorId')
            }

            duplicateCartOnDoors({
              sourceDoorId,
              destinationDoorIds,
            })
            //setShowRecap(true) //FOR TESTING ONLY (Apply first line comments to duplicateCartOnDoors)
          }}
        >
          {t('Afa.duplicateOrder')}
        </ButtonSave>
      </ButtonsGroupMod>

      {isLoading && <Loading isFullPage={false} />}
    </Wrapper>
  )
}

export default SecondPageAfaDuplicate
