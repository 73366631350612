import classnames from 'classnames'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { firstLetterCapital } from '../../../containers/DigitalEventsDashboard/style'
import NotificationContainer from '../../../containers/NotificationContainer'
import { getDefaultActiveBrand } from '../../../containers/TableCartContainer'
import { getDefaultDoorId } from '../../../libs/doors'
import { getProductSizesFromMoco } from '../../../libs/productsV2'
import { isIpadOnlyRoom, isWallDevice } from '../../../libs/url'
import { isNotUndefined } from '../../../libs/utils'
import { MyShopTab } from '../../../model/cart'
import {
  getCart,
  getCartRecommendedAction,
  setActiveBrandAction,
  setMyShopLastAddedProductUpcs,
  showFooterModal,
  toggleMyShopAssortmentCollapse,
} from '../../../store/actions'
import { activePlpBrandCodesSelector } from '../../../store/brands/selectors'
import {
  activeBrandCodeSelector,
  assortmentsViewTypeSelector,
  filteredCartProductsSelector,
  myShopActiveTabSelector,
  myShopAssortmetsCollapsedSelector,
  myShopBrandsSelector,
  myShopMocoOfActiveTabSelector,
  selectedCartCategorySelector,
} from '../../../store/cart/selectors'
import { brandCodesInCartSelector } from '../../../store/cart/selectors/cartBrand'
import { customerDoorsSelector } from '../../../store/customer/selectors'
import { dislikeSelector } from '../../../store/dislike/selectors'
import { isIPadViewSelector } from '../../../store/showButtonsSelectors'
import { wishlistSelector } from '../../../store/wishlist/selectors'
import { breakpoints, palette, pxToRem, spacing } from '../../../style/theme'
import { CartBrandAndMocos, CartWrapper } from '../../CommonComponents'
import PurchaseControls from '../../Couvettes/Couvette/PurchaseControls/PurchaseControls'
import TableCartBrands from '../../TableCart/TableCartBrands'
import RCIcon from '../../UI/RCIcon'
import CartHeader from '../CartHeader'
import MyShopCategorySwitcher from './MyShopCategorySwitcher'
import MyShopGrid from './MyShopGrid'

const getTitleKey = (activeTab: MyShopTab, assortments = false) => {
  switch (activeTab) {
    case 'futureTrends':
      return assortments ? 'WallCartPage.new_recommended_for_you' : 'WallCartPage.new_in_your_cart'
    case 'bestsellers':
      return assortments
        ? 'WallCartPage.best_seller_recommended_for_you'
        : 'WallCartPage.best_seller_in_your_cart'
    case 'adv':
      return assortments ? 'WallCartPage.recommendedAdv' : 'WallCartPage.advInCart'
  }
}

const AssortmentsCollapsed = styled.div`
  display: none;
  flex-direction: column;
  height: 100%;

  &.show {
    display: flex;
    background-image: linear-gradient(to bottom, ${palette.tropicalBlue}, ${palette.selago});
    border-top-right-radius: ${pxToRem(spacing(2))}rem;
  }
`

AssortmentsCollapsed.displayName = 'AssortmentsCollapsed'

const AssortmentsCollapsedTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;

  span {
    text-transform: uppercase;
    color: #173e5c;
    font-size: 1.25rem;
    font-weight: bold;

    @media screen and (max-width: ${breakpoints.L}) {
      font-size: 1rem;
    }
  }

  .rc-icon {
    margin-top: 10px;
    width: ${pxToRem(spacing(4))}rem;
    height: auto;
    transform: rotate(180deg);
  }
`

const AssortmentsCollapsedIcon = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .rc-icon {
    width: 2vw;
    height: auto;
  }
`

const ContentWrapper = styled.div`
  overflow-y: scroll;
  width: 92.5vw;
  overflow: auto;
  background: ${palette.white};
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${breakpoints.L}) {
    width: 90vw;
    margin: 0 auto;
    overflow: hidden;
  }
`

const MyShopButtons = styled.div`
  background: ${palette.white};
  box-shadow: 0 -1px 0 0 ${palette.alto}, 0 -2px 4px 1px rgba(220, 220, 220, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
`
MyShopButtons.displayName = 'MyShopButtons'

const DoubleArrowBack = styled.div`
  height: 1.3rem;

  img {
    height: 1.3rem;
    width: 2.3rem;
  }
`

const WallContentGridContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 1.5vh 1vw 0 1vw;
`

export const MyShop = () => {
  const { t } = useTranslation()

  const activeBrandCart = useSelector(activeBrandCodeSelector)
  const openedBrandCodes = useSelector(activePlpBrandCodesSelector)
  const brandCodesInCart = useSelector(brandCodesInCartSelector)
  const defaultActiveBrand = getDefaultActiveBrand(
    openedBrandCodes,
    brandCodesInCart.filter(isNotUndefined),
  )
  const activeBrand = activeBrandCart || defaultActiveBrand

  const myShopBrands = useSelector(myShopBrandsSelector)
  const cartProducts = useSelector(filteredCartProductsSelector)
  const assortmentsViewType = useSelector(assortmentsViewTypeSelector)
  const doors = useSelector(customerDoorsSelector)
  const dislike = useSelector(dislikeSelector)
  const wishlist = useSelector(wishlistSelector)
  const { cartMocos, recommendedMocos } = useSelector(myShopMocoOfActiveTabSelector)
  const activeTab = useSelector(myShopActiveTabSelector)
  const assortmetsCollapsed = useSelector(myShopAssortmetsCollapsedSelector)
  const isIpadView = useSelector(isIPadViewSelector)

  const dispatch = useDispatch()

  useEffect(() => {
    if (isWallDevice()) {
      dispatch(getCart())
    }
  }, [dispatch])

  useEffect(() => {
    if (!activeBrandCart) {
      dispatch(setActiveBrandAction(defaultActiveBrand, false))
    }
  }, [dispatch, activeBrandCart, defaultActiveBrand])

  useEffect(() => {
    if (
      assortmentsViewType?.category &&
      isIpadOnlyRoom() &&
      ['1', '2'].includes(assortmentsViewType.category)
    ) {
      dispatch(showFooterModal('planogram'))
    }
  }, [dispatch, assortmentsViewType])

  const category = useSelector(selectedCartCategorySelector)
  useEffect(() => {
    if (category) {
      dispatch(getCartRecommendedAction(activeBrand, category))
    }
  }, [category, activeBrand, dispatch])

  useEffect(() => {
    return () => {
      dispatch(setMyShopLastAddedProductUpcs([]))
    }
  }, [dispatch])

  const defaultDoorId = getDefaultDoorId(doors)

  const cartProductSizes = getProductSizesFromMoco(cartMocos, dislike.items)
  const recommendedProductSizes = getProductSizesFromMoco(recommendedMocos, dislike.items)

  return (
    <CartWrapper>
      <CartHeader title={firstLetterCapital(t('Header.recommended'))} />
      <CartBrandAndMocos>
        <TableCartBrands brands={myShopBrands} activeBrand={activeBrand} />
        <ContentWrapper>
          <WallContentGridContainer>
            <AssortmentsCollapsed
              onClick={() => dispatch(toggleMyShopAssortmentCollapse())}
              className={classnames({
                show: assortmetsCollapsed,
              })}
            >
              <AssortmentsCollapsedTitle className="assortments-collapsed-title">
                <span>{t('WallCartPage.viewCart')}</span>
                <RCIcon type="double-arrow-back" />
              </AssortmentsCollapsedTitle>
              <AssortmentsCollapsedIcon>
                <RCIcon type="basket" />
              </AssortmentsCollapsedIcon>
            </AssortmentsCollapsed>

            <MyShopGrid
              key={`${activeBrand}-${cartMocos.length}`} // force rerendering when changing brand to reset scroll position and when a moco is added to trigger image lazy load
              className={classnames({
                assortments: true,
                hide: assortmetsCollapsed,
              })}
              category={t(getTitleKey(activeTab))}
              mocos={cartMocos}
              cartProducts={cartProducts}
              wishlistItems={wishlist ? wishlist.products : []}
              deleteIcon={true}
              showWishlist={false}
              dislikable={false}
              expanded={false}
              isIpadView={isIpadView}
              headerLeftActions={
                <>
                  <DoubleArrowBack onClick={() => dispatch(toggleMyShopAssortmentCollapse())}>
                    <RCIcon type="double-arrow-back" />
                  </DoubleArrowBack>
                  <PurchaseControls
                    productSizes={cartProductSizes}
                    cartProducts={cartProducts.filter(({ upc }) =>
                      cartMocos.some(moco =>
                        Object.values(moco.sizes).some(size => size?.upc === upc),
                      ),
                    )}
                    cartEditable={true}
                    doors={defaultDoorId ? [defaultDoorId] : []}
                    showWishlist={false}
                    itemsInWishlist={[]}
                    disableWishlist={true}
                    headerControlCode={cartMocos.length ? cartMocos[0].modelCode : ''}
                    myShopHeader
                    brandCode={activeBrand}
                  />
                </>
              }
            />

            <MyShopGrid
              key={recommendedProductSizes[0] ? recommendedProductSizes[0].modelCode : ''} // force rerendering when changing displayed products to reset scroll position
              className="recommendation"
              category={t(getTitleKey(activeTab, true))}
              mocos={recommendedMocos}
              cartProducts={cartProducts}
              wishlistItems={wishlist ? wishlist.products : []}
              deleteIcon={false}
              showWishlist={true}
              expanded={assortmetsCollapsed}
              isIpadView={isIpadView}
              headerLeftActions={
                <PurchaseControls
                  productSizes={recommendedProductSizes}
                  cartProducts={cartProducts.filter(({ upc }) =>
                    recommendedMocos.some(moco =>
                      Object.values(moco.sizes).some(size => size?.upc === upc),
                    ),
                  )}
                  cartEditable={true}
                  doors={defaultDoorId ? [defaultDoorId] : []}
                  showWishlist={false}
                  itemsInWishlist={[]}
                  disableWishlist={true}
                  headerControlCode={recommendedMocos.length ? recommendedMocos[0].modelCode : ''}
                  brandCode={activeBrand}
                  myShopHeader
                />
              }
            />
          </WallContentGridContainer>
          <MyShopButtons>
            <MyShopCategorySwitcher />
          </MyShopButtons>
        </ContentWrapper>
      </CartBrandAndMocos>
      <NotificationContainer />
    </CartWrapper>
  )
}

export default MyShop
