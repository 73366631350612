import jwt from 'jsonwebtoken'

import { createSelector } from '@reduxjs/toolkit'

import { getUserName } from '../../libs/auth'
import { RootState } from '../../model/model'

export const tokenSelector = (state: RootState) => state.auth.token

export const authErrorSelector = (state: RootState) => state.auth.error

export const usernameSelector = createSelector(tokenSelector, token =>
  getUserName(token).replace('&luxottica.com', ''),
)

export const logoutPolicySelector = createSelector(tokenSelector, (token: string | null):
  | string
  | undefined => {
  const decodedToken = token && (jwt.decode(token) as { userData: { wcsToken: string } })
  const wcsToken = decodedToken && decodedToken.userData.wcsToken
  const decodedWcsToken = wcsToken && (jwt.decode(wcsToken) as { acr: string })
  return (decodedWcsToken && decodedWcsToken.acr) || undefined
})
