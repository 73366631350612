import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from '../../../../hooks/useSearchParams'
import Radio from '../../../../styleguide/Radio'
import { palette } from '../../../../style/theme'
import { SortBackdrop, Body, Header, Wrapper } from './style'
import { SORTING_CRITERIA } from '../../../../model/whiteboard'

type Props = {
  sortOpen: boolean
  toggleSortOpen: () => void
  sortingValues: { label: string; value: SORTING_CRITERIA }[]
}

const WhiteboardSort: React.FC<Props> = ({ sortOpen, toggleSortOpen, sortingValues }) => {
  const QS_KEY = 'sort'
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const sortByQuery = searchParams.get(QS_KEY) || 'model'

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    searchParams.set(QS_KEY, e.target.value)
    searchParams.set('start', '0')
    setSearchParams(searchParams, { replace: true })
  }

  return (
    <SortBackdrop onClick={toggleSortOpen} open={sortOpen}>
      <Wrapper onClick={e => e.stopPropagation()}>
        <Header>{t('Afa.sortBy')}</Header>
        <Body>
          {sortingValues.map(({ value, label }) => (
            <Radio
              value={value}
              key={value}
              checked={value === sortByQuery}
              onChange={handleChange}
              backgroundColorChecked={palette.bermudaGray}
              backgroundColorUnchecked={palette.white}
              borderColor={palette.white}
            >
              {t(label)}
            </Radio>
          ))}
        </Body>
      </Wrapper>
    </SortBackdrop>
  )
}

export default WhiteboardSort
