import React from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

import { VideoHighlight } from '../../model/brand'
import { getFluidSize, palette } from '../../style/theme'

const OverlayWrapper = styled.div<{ visible: boolean }>`
  opacity: 0;
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  ${({ visible }) =>
    visible &&
    `
    z-index: 2;
    opacity: 1;
`}
  display: flex;
  justify-items: space-evenly;
  justify-content: space-evenly;
  align-items: center;
  transition: opacity 1s;
`

const ImageWrapper = styled.div`
  position: relative;
  color: ${palette.white};
  img {
    width: 100%;
    height: auto;
  }
  cursor: pointer;
  width: 45%;
  figure {
    position: relative;
  }
  figcaption {
    position: absolute;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.4) 90%);
    width: 100%;
    height: 100%;
    padding: ${getFluidSize('5px')};
    border: 1px solid rgba(255, 255, 255, 0.2);
    &:hover {
      border: 1px solid ${palette.white};
    }
  }
`

const VideoOverlay: React.FC<{
  items?: VideoHighlight[]
  visible: boolean
  className?: string
  onClick: (id: string) => void
}> = ({ items, visible, className, onClick }) => {
  if (items && items.length) {
    return (
      <OverlayWrapper visible={visible} className={className}>
        {items.slice(0, 2).map((video, index) => (
          <ImageWrapper key={index} onClick={() => onClick(video.id)}>
            <figure>
              <figcaption>{video.title}</figcaption>
              <img src={video.posterImage} />
            </figure>
          </ImageWrapper>
        ))}
      </OverlayWrapper>
    )
  } else return null
}

export default withTranslation('common')(VideoOverlay)
