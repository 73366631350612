import React from 'react'
import styled from 'styled-components'

import { handleImageError } from '../../../libs/handleError'
import { zIndex } from '../../../style/theme'
import zoomable from '../../UI/Zoomable'

const Img = styled.img`
  margin: 0 auto;
  display: block;
  touch-action: none;
  height: 100%;
  cursor: move;
  width: 100%;
  object-fit: contain;
  position: absolute;
  z-index: ${zIndex.imagesAboveLoading};
`

type Props = {
  src: string
  zoomStyle?: Record<string, number | string>
  onLoad: () => void
}

const ProductImageFull: React.FC<Props> = ({ src, zoomStyle = {}, onLoad }) => {
  return (
    <Img
      className="full-product-image"
      onError={handleImageError}
      style={zoomStyle}
      src={src}
      alt=""
      loading="lazy"
      onLoad={onLoad}
    />
  )
}

export default zoomable(ProductImageFull)
