import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { Moco } from '../../../../model/product'
import { breakpoints, getFluidFontSize, palette } from '../../../../style/theme'
import { transparentScrollbarCss } from '../../../CommonComponents'
import ThumbItem from './ThumbItem'
import { getProductImageUrl } from '../../../../libs/productImages'

const Wrapper = styled.div`
  ${transparentScrollbarCss}
  width: 16vw;
  max-height: calc(100% - 5vh);
  overflow-y: auto;
  z-index: 10000;
  touch-action: manipulation;
  font-size: ${getFluidFontSize('10px')};
  position: absolute;
  bottom: 5vh;
  left: 4vw;
  overflow-y: overlay;

  @media screen and (max-width: ${breakpoints.L}) {
    left: 1vw;
  }

  &::-webkit-scrollbar {
    width: 0.9vw;
  }
  &::-webkit-scrollbar-track {
    width: 0.9vw;
  }
  &::-webkit-scrollbar-track-piece {
    width: 0.9vw;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 5px 0 0 0;
    border-radius: 0px;
  }
  &:hover {
    color: ${palette.curiousBlue};
  }
`
Wrapper.displayName = 'ThumbsNavigation'

const ThumbsList = styled.div`
  width: 15vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-top: 15vh;
  padding-bottom: 2vh;
`

const scrollThumbsToProduct = (
  activeKey: string,
  containerRef: React.RefObject<HTMLDivElement>,
) => {
  const singleContent = document.getElementById(`${activeKey}-thumb`)

  // scroll only if the user didn't click on the thumb, in that case the thumb
  // already has the "active" class
  if (singleContent && !singleContent.classList.contains('active')) {
    const yPos = singleContent.offsetTop - window.innerHeight * 0.4
    containerRef.current?.scrollTo && containerRef.current?.scrollTo(0, yPos)
  }
}

type Props = {
  activeKey: string
  mocos: Moco[]
  onProductSelected: (mocoCode: string, index: number) => void
}

const ThumbsNavigation: React.FC<Props> = ({ mocos, activeKey, onProductSelected }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [selectedThumb, setSelectedThumb] = useState(activeKey)

  useEffect(() => {
    setSelectedThumb(activeKey)
    scrollThumbsToProduct(activeKey, containerRef)
  }, [activeKey])

  return (
    <Wrapper
      className="thumbs-navigation-wrapper"
      data-testid="thumbs-navigation"
      ref={containerRef}
    >
      <ThumbsList className="thumbs-list">
        {mocos.map((moco, index) => {
          const productThumbnail = getProductImageUrl({ path: moco.catalogImages.base })
          return (
            <ThumbItem
              key={moco.mocoCode}
              activeKey={selectedThumb}
              code={moco.mocoCode}
              onClick={() => {
                setSelectedThumb(moco.mocoCode)
                onProductSelected(moco.mocoCode, index)
              }}
              productThumbnail={productThumbnail}
              modelCode={moco.modelCode}
              colorCode={moco.colorCode}
              frontFamilyColor={moco.frontFamilyColor}
            />
          )
        })}
      </ThumbsList>
    </Wrapper>
  )
}

export default ThumbsNavigation
