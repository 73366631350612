import { groupBy } from 'lodash'
import filter from 'mout/object/filter'

import { createSelector } from '@reduxjs/toolkit'

import { brandExistAndIsAdult } from '../../../libs/brand'
import { keys } from '../../../libs/utils'
import { Brand } from '../../../model/brand'
import { CartProduct, MocoInCart } from '../../../model/cart'
import { Moco } from '../../../model/product'
import { brandsSelector, subbrandToMainBrandMapSelector } from '../../brands/selectors'
import { starsMocosByActiveDoorsAndSelectedMonthSelector } from '../../stars/selectors'
import { mocosInCartFilteredByDoorsSelector } from './cart'
import { isSameCategoryWithElectronics } from '../../../libs/couvettes'

type GetBrandInfoReturn = [{ brand: string; group: string[] }[], Record<string, string>]
const getBrandsInfo = (brands: Brand[]): GetBrandInfoReturn => {
  return brands.reduce(
    (result, brand) => {
      const [groups, slugByCode] = result

      // TODO: what's the point of creating an object where keys and values are equal?
      slugByCode[brand.code] = brand.code

      if (!brand.group.length) {
        return [groups, slugByCode] as GetBrandInfoReturn
      }

      groups.push({
        brand: brand.code,
        group: brand.group,
      })

      return [groups, slugByCode] as GetBrandInfoReturn
    },
    [[], {}] as GetBrandInfoReturn,
  )
}

type CartItemForLed = (Moco | MocoInCart | CartProduct) & {
  isCart: boolean
}

// exported for testing purpose only
export const getMocosForLed = ({
  mocosInCart,
  brands,
  category,
  subbrandsToMainBrandsMap,
}: {
  mocosInCart: (MocoInCart | Moco | CartProduct)[]
  brands: Brand[]
  category: string
  subbrandsToMainBrandsMap: Record<string, string>
}) => {
  const { cartItemsForLed } = mocosInCart.reduce(
    (result, mocoInCart) => {
      const cartItemForLed: CartItemForLed = {
        ...mocoInCart,
        isCart: true,
      }

      return {
        cartItemsForLed: result.cartItemsForLed.concat(cartItemForLed),
      }
    },
    { cartItemsForLed: [] } as {
      cartItemsForLed: CartItemForLed[]
    },
  )

  // Join csa and cart
  const sourceModeMocos = cartItemsForLed.filter(item => {
    // If category provided, then show only this category
    if (!category) {
      return true
    }
    return isSameCategoryWithElectronics(category, item.categoryId)
  })

  if (sourceModeMocos.length === 0) {
    return []
  }

  // Group items by brand code
  const itemsByBrandCode = groupBy(sourceModeMocos, item => item.brandCode)

  // remove junior brands and brands not on customer
  const itemsByBrandCode_filteredBrands = filter(
    itemsByBrandCode,
    (_: unknown, brandCode: string) =>
      brandExistAndIsAdult(brands, brandCode, subbrandsToMainBrandsMap),
  )

  const [groups, slugByCode] = getBrandsInfo(brands)

  // Group subbrands in parent brand
  keys(itemsByBrandCode_filteredBrands).forEach(brandCode => {
    groups.forEach(({ group, brand }: { brand: string; group: string[] }) => {
      if (!group.includes(brandCode) || brand === brandCode) {
        return
      }

      itemsByBrandCode_filteredBrands[brand] = (
        itemsByBrandCode_filteredBrands[brand] || []
      ).concat(itemsByBrandCode_filteredBrands[brandCode])
      delete itemsByBrandCode_filteredBrands[brandCode]
    })
  })

  // Sort brands alphabetically and format response
  const alphabeticallyOrderedBandCodes = keys(itemsByBrandCode_filteredBrands).sort(
    (brandCode1, brandCode2) => {
      const brand1 = brands.find(({ code }) => code === brandCode1)
      const brandName1 = brand1?.brand.toLowerCase()

      const brand2 = brands.find(({ code }) => code === brandCode2)
      const brandName2 = brand2?.brand.toLowerCase()

      return brandName1 && brandName2 && brandName1 > brandName2 ? 1 : -1
    },
  )
  const formattedItems = alphabeticallyOrderedBandCodes.map(code => ({
    code,
    slug: slugByCode[code],
    items: itemsByBrandCode_filteredBrands[code] || [],
  }))

  return formattedItems
}

export const ledMocosByBrandsSelectorFactory = (category: string) => {
  return createSelector(
    mocosInCartFilteredByDoorsSelector,
    brandsSelector,
    subbrandToMainBrandMapSelector,
    (mocosInCart, brands, subbrandsToMainBrandsMap) => {
      const mocosForLed = getMocosForLed({
        mocosInCart,
        brands,
        category,
        subbrandsToMainBrandsMap,
      })
      return mocosForLed
    },
  )
}

export const ledStarsMocosByBrandsAndActiveDoorsSelector = (category: string) =>
  createSelector(
    starsMocosByActiveDoorsAndSelectedMonthSelector(true),
    brandsSelector,
    subbrandToMainBrandMapSelector,
    (starsMocosByActiveDoors, brands, subbrandsToMainBrandsMap) => {
      const mocosForLed = getMocosForLed({
        mocosInCart: starsMocosByActiveDoors,
        brands,
        category,
        subbrandsToMainBrandsMap,
      })
      return mocosForLed
    },
  )
