import { Alert } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import styled from 'styled-components'

import Loading from '../components/Loading'
import { getPostLoginRedirectUri } from '../libs/auth'
import { login, logout } from '../store/auth/actions'
import { authErrorSelector, tokenSelector } from '../store/auth/selectors'
import { breakpoints, palette, pxToRem, spacing } from '../style/theme'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
`

const Header = styled.header`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: ${pxToRem(spacing(6))}rem;
  place-self: start center;

  @media (max-width: ${breakpoints.S}) {
    padding: ${pxToRem(spacing(3))}rem;
  }
`

const Logo = styled.img`
  max-width: 340px;

  @media (max-width: ${breakpoints.S}) {
    max-width: 250px;
  }
`

const Button = styled.button`
  border: solid 2px ${palette.geyser};
  border-radius: ${pxToRem(spacing(4))}rem;
  width: auto;
  padding: ${pxToRem(spacing(1))}rem ${pxToRem(spacing(2))}rem;
  place-self: center;
`

const PostLoginPage: React.FC = () => {
  const storedToken = useSelector(tokenSelector)
  const authError = useSelector(authErrorSelector)
  const dispatch = useDispatch()
  const tokenMatch = window.location.hash.match(/#id_token=(.+)/)
  const token = tokenMatch && tokenMatch[1]
  const { t } = useTranslation()
  const noTokenError = !token ? 'No token provided in the login redirect uri' : ''
  const errorToShow = noTokenError || authError

  const redirectPath = getPostLoginRedirectUri() || '/'

  useEffect(() => {
    if (token) {
      dispatch(login(token))
    }
  }, [token, dispatch])

  return !authError && storedToken ? (
    <Redirect to={redirectPath} />
  ) : (
    <Wrapper>
      <Header>
        <Logo src="/assets/lux-red-carpet-logo-new.svg" alt="luxottica red carpet logo" />
      </Header>
      {errorToShow ? (
        <>
          <Alert message="Error" description={errorToShow} type="error" showIcon />
          <Button
            className="back-login-button"
            onClick={() => {
              dispatch(logout())
            }}
          >
            {t('User.backToLogin')}
          </Button>
        </>
      ) : (
        <Loading />
      )}
    </Wrapper>
  )
}

export default PostLoginPage
