import { useSelector } from 'react-redux'

import { createApi } from '@reduxjs/toolkit/query/react'

import {
  AAProduct,
  MapDemographicsByTypeAndDescription,
  MapData,
  Assortment,
  MapTrends,
} from './Model/aaModel'
import { staggeredBaseQueryWithBailOut } from '../../libs/services'
import { customerIdSelector, eventIdSelector } from '../../store/app/selectors'
import qs from 'qs'
import { useSearchParams } from '../../hooks/useSearchParams'
import { brandsSelector } from '../../store/brands/selectors'
import { useGetSelectedDoorId } from './Hooks/usePreselectDefaultDoor'
import app_config from '../../config/app/config'
import { useCallback } from 'react'

export const aaApi = createApi({
  reducerPath: 'aaApi',
  baseQuery: staggeredBaseQueryWithBailOut(`${app_config.apiUrl}/aa`),
  endpoints: builder => ({
    getCustomerHasAssortment: builder.query<
      boolean,
      {
        customerId: string
        eventId: string
      }
    >({
      query: ({ customerId, eventId }) => ({
        url: `/event/${eventId}/customer/${customerId}/check-assortment`,
      }),
    }),

    getCustomerAssortment: builder.query<
      AAProduct[],
      {
        customerId: string
        eventId: string
        doorId?: string
        brandCodes: string[]
      }
    >({
      query: ({ customerId, eventId, doorId, brandCodes }) => ({
        url: `/event/${eventId}/customer/${customerId}/assortment?${qs.stringify({
          doorId,
          brands: brandCodes.join(','),
        })}`,
      }),
    }),

    getMapData: builder.query<MapData[], { param: string }>({
      query: ({ param }) => ({
        url: `/map/data/${param}`,
      }),
    }),

    getMapDemographics: builder.query<
      MapDemographicsByTypeAndDescription,
      { country: string; zipCode: string }
    >({
      query: ({ country, zipCode }) => ({
        url: `/map/demographics?${qs.stringify({ country, zipCode })}`,
      }),
    }),

    getMapBrandCodes: builder.query<string[], void>({
      query: () => ({
        url: '/map/brands',
      }),
    }),
    getTrendsAssortment: builder.mutation<
      Assortment[],
      {
        products: Record<string, string[]>
      }
    >({
      query: ({ products }) => ({
        url: 'trends-assortment',
        body: {
          products: products,
        },
        method: 'POST',
      }),
    }),
    getMapTrends: builder.query<
      MapTrends,
      {
        country: string
        zipCode: string
      }
    >({
      query: ({ country, zipCode }) => ({
        url: `/map/trends?${qs.stringify({ country, zipCode })}`,
      }),
    }),
  }),
})

export const useGetCustomerHasAssortmentQuery = () => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  return aaApi.useGetCustomerHasAssortmentQuery({
    eventId,
    customerId,
  })
}

export const useGetCustomerAssortmentQuery = () => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)
  const doorId = useGetSelectedDoorId() || customerId || ''

  const [searchParams] = useSearchParams()
  const brandsParam = searchParams.get('brands')?.split(',') || []
  const isJunior = !!searchParams.get('junior')

  const brands = useSelector(brandsSelector)
  const selectedBrands = brands.filter(brand => brandsParam?.includes(brand.code))
  const brandCodes = selectedBrands.flatMap(brand =>
    isJunior ? brand.juniorBrands : brand.group.filter(code => !brand.juniorBrands.includes(code)),
  )

  return aaApi.useGetCustomerAssortmentQuery(
    {
      eventId,
      customerId,
      brandCodes,
      doorId,
    },
    { skip: !doorId || !brandsParam.length || !brands.length || !customerId },
  )
}

export const useGetDemographicsQuery = (country: string, zipCode: string) => {
  return aaApi.useGetMapDemographicsQuery(
    {
      country,
      zipCode,
    },
    { skip: !country || !zipCode },
  )
}

export const useGetTrendsAssortmentMutation = () => {
  const [_getTrendsAssortment, getTrendsAssortmentResult] = aaApi.useGetTrendsAssortmentMutation()

  const getTrendsAssortment = useCallback(
    (products: Record<string, string[]>) =>
      Object.keys(products).length > 0 ? _getTrendsAssortment({ products }) : null,
    [_getTrendsAssortment],
  )

  return [getTrendsAssortment, getTrendsAssortmentResult] as [
    typeof getTrendsAssortment,
    typeof getTrendsAssortmentResult,
  ]
}

export const { useGetMapDataQuery, useGetMapBrandCodesQuery, useGetMapTrendsQuery } = aaApi
