import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { useDownloadCartFileQuery } from '../../../../services/cart'
import {
  breakpoints,
  breakpointsCross,
  getFluidFontSize,
  getFluidSize,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
  spacing,
} from '../../../../style/theme'
import { CustomIconAndTextButton, CustomInput, StyledCheckBox } from '../Common'
import RCIcon from '../../../../components/UI/RCIcon'
import { errorNotification } from '../../../../components/Notification/notifications'

const padding = `${getFluidSize(`${pxToRem(50)}rem`, 15)} ${getFluidSize(
  `${pxToRem(50)}rem`,
  15,
)} ${getFluidSize(`${pxToRem(50)}rem`, 15)}`

const Wrapper = styled.div`
  padding: 0 ${padding};
  padding-bottom: ${getFluidSizeWithFullFormula('px', 40, 40, 1024, 3840)};
  display: flex;
  flex-direction: column;
  row-gap: ${getFluidSizeWithFullFormula('px', 80, 179, 1920, 3840)};
  font-family: 'GilmerRegular', sans-serif;

  @media screen and (min-width: ${breakpoints.XL}) {
    padding-bottom: ${pxToRem(260)}rem;
  }
`

const Title = styled.div`
  font-size: ${getFluidSizeWithFullFormula('px', 16, 32, 1366, 3840)};
  text-transform: uppercase;
  color: ${palette.tangaroa};

  font-weight: bold;

  @media (max-width: ${breakpointsCross.L.max}) {
    letter-spacing: 0.8px;
  }

  @media (min-width: ${breakpoints.L}) {
    letter-spacing: 1.6px;
  }
`

const Subtitle = styled.p`
  padding-top: ${getFluidSizeWithFullFormula('px', 16, 24, 1366, 3840)};
  padding-bottom: ${getFluidSizeWithFullFormula('px', 21, 48, 1366, 3840)};
  font-size: ${getFluidSizeWithFullFormula('px', 12, 24, 1366, 3840)};
  color: ${palette.tangaroa};

  @media (min-width: ${breakpoints.L}) {
    font-weight: bold;
    color: ${palette.santasGray};
  }
`

const Feedback = styled.p`
  padding-top: ${getFluidSizeWithFullFormula('px', 16, 24, 1366, 3840)};
  padding-bottom: ${getFluidSizeWithFullFormula('px', 21, 48, 1366, 3840)};
  font-size: ${getFluidSizeWithFullFormula('px', 10, 20, 1366, 3840)};
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.L}) {
    font-weight: bold;
  }

  img {
    height: ${getFluidSizeWithFullFormula('px', 12, 32, 1366, 3840)};
    width: ${getFluidSizeWithFullFormula('px', 16, 32, 1366, 3840)};
  }
`

const SubSection = styled.div<{
  disableGap?: boolean
}>`
  display: flex;
  position: relative;
  align-items: center;

  ${({ disableGap }) =>
    !disableGap &&
    css`
      @media (min-width: ${breakpoints.L}) {
        gap: ${pxToRem(56)}rem;
      }

      @media (max-width: ${breakpointsCross.L.max}) {
        gap: ${pxToRem(100)}rem;
      }
    `}
  &.format {
    align-items: flex-start;
    gap: ${pxToRem(spacing(4))}rem;
  }
`

const SubSectionForm = styled(SubSection)`
  @media (max-width: ${breakpointsCross.XS.max}) {
    flex-direction: column;
  }
`

const SendForm = styled.form`
  border: 1px solid ${palette.alto};
  border-radius: ${pxToRem(5)}rem;
  justify-content: space-between;
  font-size: ${getFluidSizeWithFullFormula('px', 10, 24, 1366, 3840)};
  width: ${getFluidSizeWithFullFormula('px', 313, 904, 1366, 3840)};
  height: ${getFluidSizeWithFullFormula('px', 42, 68, 1366, 3840)};
  font-weight: 500;
  letter-spacing: 0.3px;

  @media (max-width: ${breakpointsCross.XS.max}) {
    width: 90%;
  }

  @media (min-width: ${breakpoints.L}) {
    line-height: 0.75;
  }

  input {
    outline: none;
    border: solid 1px ${palette.bermudaGray};
    padding-inline: ${getFluidSizeWithFullFormula('px', 16, 24, 1366, 3840)};
    height: ${getFluidSizeWithFullFormula('px', 42, 68, 1366, 3840)};
  }

  .search-icon {
    position: absolute;
    top: ${pxToRem(7)}rem;
    left: ${pxToRem(7)}rem;
  }
`

const CheckboxWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: ${pxToRem(spacing(1.5))}rem;
  font-size: ${getFluidSizeWithFullFormula('px', 14, 24, 1366, 3840)};
  color: ${palette.tangaroa};
  font-weight: 500;

  @media (min-width: ${breakpoints.L}) {
    letter-spacing: 1.2px;
  }

  p {
    text-transform: capitalize;
  }

  input {
    border: 1px solid ${palette.tangaroa};
  }
`

const FormatButtonWrapper = styled.div`
  display: flex;
  gap: ${pxToRem(spacing(4))}rem;
`

const CheckboxsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${getFluidSizeWithFullFormula('px', 24, 32, 1366, 3840)};
`

const StyledButton = styled(CustomIconAndTextButton)`
  background-color: ${palette.tangaroa};
  color: ${palette.white};
  white-space: nowrap;
  text-transform: uppercase;
  border-radius: 4px;
  font-size: ${getFluidSizeWithFullFormula('px', 13, 24, 1366, 3840)};
  font-weight: bold;
  letter-spacing: 0.3px;
  height: ${getFluidSizeWithFullFormula('px', 36, 72, 1366, 3840)};

  @media (max-width: ${breakpointsCross.L.max}) {
    line-height: 1.38;
  }

  @media (min-width: ${breakpoints.L}) {
    line-height: 0.75;
  }

  padding: ${getFluidSize(`${pxToRem(15)}rem`, 15)} ${getFluidSize(`${pxToRem(15)}rem`, 15)};
  min-width: max-content;
  width: ${getFluidSizeWithFullFormula('px', 167, 436, 1366, 3840)};
  justify-content: center;

  .rc-icon {
    font-size: ${getFluidFontSize('16px', 15)};
  }
`

type Props = {
  sendEmailCart: (payload: {
    addresses: string[]
    showPrice: boolean
    showImage: boolean
    fileType: ('excel' | 'pdf')[] | undefined
  }) => void
  itemsInCart: boolean
  className?: string
}

const SubSectionsContainer = styled.div`
  display: flex;
  column-gap: ${getFluidSizeWithFullFormula('px', 150, 328, 1920, 3840)};
  row-gap: ${getFluidSizeWithFullFormula('px', 80, 179, 1920, 3840)};

  @media (max-width: ${breakpointsCross.L.max}) {
    flex-direction: column;
  }
`

const ExportCart: React.FC<Props> = ({ sendEmailCart, itemsInCart, className }) => {
  const { t } = useTranslation()

  const [selectedFileType, setSelectedFileType] = useState<'excel' | 'pdf' | undefined>(undefined)
  const [showImages, setShowImages] = useState(false)
  const [showPrices, setShowPrices] = useState(false)
  const [startDownload, setStartDownload] = useState(false)
  const [showFeedback, setShowFeedback] = useState(false)

  const [disableButtonDownload, setDisableButtonDownload] = useState(true)

  const handleSubmit = ({
    onError,
    onSuccess,
  }: {
    onError?: () => void
    onSuccess: () => void
  }) => {
    if (!selectedFileType && itemsInCart) {
      onError && onError()
      errorNotification({
        message: t('Checkout.ImportExport.SelectedFileType'),
      })
    } else if (!itemsInCart) {
      onError && onError()
      errorNotification({
        message: t('Checkout.ImportExport.CartIsEmpty'),
      })
    } else {
      onSuccess()
      setShowFeedback(true)
    }
  }

  const handleDownload = () =>
    handleSubmit({
      onError: () => setStartDownload(false),
      onSuccess: () => setStartDownload(true),
    })

  const downloadRes = useDownloadCartFileQuery({
    startDownload,
    showPrice: showPrices,
    showImage: showImages,
    fileType: selectedFileType,
  })

  useEffect(() => {
    if (downloadRes.isFetching) {
      setDisableButtonDownload(true)
    } else if (showImages) {
      setDisableButtonDownload(true)
    } else {
      setDisableButtonDownload(false)
    }
  }, [downloadRes.isFetching, showImages])

  useEffect(() => {
    if (downloadRes.isUninitialized) {
      setSelectedFileType(undefined)
      setShowImages(false)
      setShowPrices(false)
    }
  }, [downloadRes.isUninitialized])

  useEffect(() => {
    if (
      selectedFileType &&
      downloadRes.isSuccess &&
      !downloadRes.isLoading &&
      !downloadRes.isFetching
    ) {
      const a = document.createElement('a')
      const base64PrefixMap = {
        excel: 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
        pdf: 'data:application/x-pdf;base64,',
      }
      const fileType = selectedFileType.includes('pdf') ? 'pdf' : 'excel'
      const base64Prefix = base64PrefixMap[fileType]
      a.href = base64Prefix + downloadRes.data.file
      a.download = downloadRes.data.filename
      a.click()
      setStartDownload(false)
    }
  }, [downloadRes, selectedFileType])

  const [email, setEmail] = useState<string>('')

  const addresses = email.split(/, ?/)

  const handleSendEmail = () => {
    if (addresses.length && email) {
      const onSuccess = () => {
        sendEmailCart({
          addresses,
          showPrice: showPrices,
          showImage: showImages,
          fileType: [selectedFileType || 'excel'],
        })
        setEmail('')
      }
      handleSubmit({ onSuccess })
    } else {
      errorNotification({
        message: t('Checkout.ImportExport.InsertMail'),
      })
    }
  }

  return (
    <Wrapper className={className}>
      <SubSectionsContainer>
        <CheckboxsContainer>
          <Title>{t('Checkout.ImportExport.ExportAs.Title')}</Title>
          <SubSection>
            <CheckboxWrapper>
              <StyledCheckBox
                onChange={() => setSelectedFileType('excel')}
                checked={!!selectedFileType?.includes('excel')}
                accentColor={palette.tangaroa}
              />
              {t('Checkout.ImportExport.ExportAs.Excel')}
            </CheckboxWrapper>
            <CheckboxWrapper>
              <StyledCheckBox
                onChange={() => setSelectedFileType('pdf')}
                checked={!!selectedFileType?.includes('pdf')}
                accentColor={palette.tangaroa}
              />
              {t('Checkout.ImportExport.ExportAs.PDF')}
            </CheckboxWrapper>
          </SubSection>
        </CheckboxsContainer>

        <CheckboxsContainer>
          <Title>{t('Checkout.ImportExport.AddOptions.Title')}</Title>
          <SubSection>
            <CheckboxWrapper>
              <StyledCheckBox
                onClick={() => setShowImages(!showImages)}
                checked={showImages}
                accentColor={palette.tangaroa}
              />
              {t('Checkout.ImportExport.AddOptions.ItemImages')}
            </CheckboxWrapper>
            <CheckboxWrapper>
              <StyledCheckBox
                onClick={() => setShowPrices(!showPrices)}
                checked={showPrices}
                accentColor={palette.tangaroa}
              />
              {t('Checkout.ImportExport.AddOptions.WHSPrices')}
            </CheckboxWrapper>
          </SubSection>
        </CheckboxsContainer>
      </SubSectionsContainer>

      <div>
        <Title>{t('Checkout.ImportExport.ExportFormat')}</Title>
        <Subtitle>{t('Checkout.ImportExport.ExportFormatDescription')}</Subtitle>
        <SubSectionForm className="format" disableGap>
          <SendForm>
            <CustomInput
              value={email}
              onChange={e => {
                setEmail(e.currentTarget.value)
              }}
              pattern="([^@ \t\r\n^,]+@[^@ \t\r\n^,]+\.[^@ \t\r\n^,]+,?\s?)+"
              placeholder={t('Checkout.ImportExport.EmailPlaceholder')}
              required
            />
          </SendForm>
          <FormatButtonWrapper>
            <StyledButton
              icon="new-send"
              onClick={handleSendEmail}
              text={t('Checkout.ImportExport.SendEmail')}
              textFirst={false}
            />

            <StyledButton
              icon="new-download"
              text={t('Checkout.ImportExport.Download')}
              textFirst={false}
              disabled={disableButtonDownload}
              onClick={handleDownload}
            />
          </FormatButtonWrapper>
        </SubSectionForm>
        {showFeedback && (
          <Feedback>
            <RCIcon key="icon" type="icon_check" />
            {t('Checkout.ImportExport.SuccessFeedback')}
          </Feedback>
        )}
      </div>
    </Wrapper>
  )
}

export default ExportCart
