import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Product } from '../../model/product'
import { SearchState } from '../../model/search'
import { resetForNewClient } from '../extraActions'

const DEFAULT_STATE: SearchState = {
  isSearchVisible: false,
  modelsFound: null,
}

export const slice = createSlice({
  name: 'search',
  initialState: DEFAULT_STATE,
  reducers: {
    toggleSearch: state => {
      state.isSearchVisible = !state.isSearchVisible
    },
    hideSearch: state => {
      state.isSearchVisible = false
    },
    showSearch: state => {
      state.isSearchVisible = true
    },
    searchModelsSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        couvettes: Product[] | null
        start?: string
      }>,
    ) => {
      const { couvettes, start } = payload

      state.modelsFound = couvettes
      state.start = start
    },
    resetSearchState: () => DEFAULT_STATE,
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, () => DEFAULT_STATE)
  },
})

export default slice.reducer
