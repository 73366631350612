import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import RCIcon from '../../../../components/UI/RCIcon'
import { DisplayCartSummaryAs } from '../../../../model/afa'
import { breakpoints, duration, palette, pxToRem } from '../../../../style/theme'
import { DataCartSummary } from '../AfaCartSummary'
import AfaCartSummaryFilters from './smallComponents/AfaCartSummaryFilters'
import { useDispatch, useSelector } from 'react-redux'
import {
  displayAsLayerOpenSelector,
  displayAsSelector,
  showFiltersSelector,
} from '../../../../store/afaCartSummary/selectors'
import afaCartSummaryActions from '../../../../store/afaCartSummary/actions'
import React from 'react'
import { useGetBrandsQuery } from '../../../../services/afa'
import { showWhsPriceSelector } from '../../../../store/customer/selectors'

const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em 0 0;

  @media (max-width: ${breakpoints.L}) {
    padding: 2em 0;
  }
`

const Title = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  color: ${palette.tangaroa};
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: GilmerMedium;
  letter-spacing: 0.3px;
  font-size: ${pxToRem(24)}rem;

  @media (max-width: ${breakpoints.L}) {
    font-size: ${pxToRem(12)}rem;
  }

  & svg {
    margin-left: 6px;
    width: 0.9em;
    height: 0.9em;
  }

  & span.grey-text {
    color: ${palette.santasGray};
    margin-right: 0.5em;
  }
`

const DisplayAs = styled.ul`
  position: absolute;
  top: 110%;
  right: 0;
  border: 1px solid black;
  background-color: ${palette.white};
  border-radius: 4px;
  opacity: 0;
  pointer-events: none;
  transition: all ${duration.enteringScreen}ms;
  transition: opacity ${duration.enteringScreen}ms, max-height 0 ${duration.enteringScreen}ms;
  max-height: 0;
  z-index: 2;

  & li {
    font-size: 1em;
    min-width: 14em;
    padding: 1em;
    cursor: pointer;
    position: relative;

    &:nth-child(-n + 2)::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      margin-left: 1em;
      width: calc(100% - 2em);
      border-bottom: 1px solid ${palette.mischka};
    }

    &:first-letter {
      text-transform: uppercase;
    }

    &:hover {
      background-color: ${palette.extraLight};
    }
  }
  &.open {
    opacity: 1;
    pointer-events: initial;
    max-height: 100vh;
  }
`
const ToggleFiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.6em 0;
  border: 1px solid transparent;
  &.setPadding {
    border: 1px solid black;
    padding: 0.6em;
    background-color: ${palette.white};
    border-radius: 4px;
  }
`
const FilterApplied = styled.span`
  text-transform: capitalize;
`

type Props = {
  options: DataCartSummary[]
}

const AfaCartSummaryHeaderPages: React.FC<Props> = ({ options }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const showFilters = useSelector(showFiltersSelector)
  const displayAsLayerOpen = useSelector(displayAsLayerOpenSelector)
  const brandsQuery = useGetBrandsQuery()
  const currency = brandsQuery.data?.[0].currency || ''
  const displayAs = useSelector(displayAsSelector)
  const showWhsPrice = useSelector(showWhsPriceSelector)

  const displaysAvailables: { id: DisplayCartSummaryAs; text: string }[] = showWhsPrice
    ? [
        { id: 'quantity', text: `${t('Checkout.Process.Filters.Quantity')}` },
        { id: 'quantity%', text: `${t('Checkout.Process.Filters.Quantity')} ${t('Plp.in')} %` },
        { id: 'value', text: `${t('GenericWords.value')} ${t('Plp.in')} ${currency}` },
        { id: 'value%', text: `${t('GenericWords.value')} ${t('Plp.in')} %` },
      ]
    : [
        { id: 'quantity', text: `${t('Checkout.Process.Filters.Quantity')}` },
        { id: 'quantity%', text: `${t('Checkout.Process.Filters.Quantity')} ${t('Plp.in')} %` },
      ]

  return (
    <Filters>
      <Title onClick={() => dispatch(afaCartSummaryActions.setShowFilters(!showFilters))}>
        {t('Afa.filterStatistics')}
        <RCIcon arrow type={showFilters ? 'up' : 'down'} />
        <AfaCartSummaryFilters options={options} className={showFilters ? 'open' : undefined} />
      </Title>
      <Title
        onClick={() => dispatch(afaCartSummaryActions.setDisplayAsLayerOpen(!displayAsLayerOpen))}
      >
        <span className="grey-text">{t('Afa.displayAs')}</span>

        <ToggleFiltersWrapper className={displayAsLayerOpen ? 'setPadding' : ''}>
          <FilterApplied>
            {displaysAvailables.map(({ id, text }) => id === displayAs && text)}
          </FilterApplied>
          <RCIcon arrow type={displayAsLayerOpen ? 'up' : 'down'} />
        </ToggleFiltersWrapper>

        <DisplayAs className={displayAsLayerOpen ? 'open' : undefined}>
          {displaysAvailables.map(
            ({ id, text }) =>
              displayAs !== id && (
                <li onClick={() => dispatch(afaCartSummaryActions.setDisplayAs(id))} key={id}>
                  {text}
                </li>
              ),
          )}
        </DisplayAs>
      </Title>
    </Filters>
  )
}

export default AfaCartSummaryHeaderPages
