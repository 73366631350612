import { Icon as AntdIcon } from 'antd'
import { ThemeType } from 'antd/lib/icon'
import React from 'react'

const Icon: React.FC<{
  type: string
  className?: string
  onClick?: () => void
  theme?: ThemeType
}> = props => {
  return <AntdIcon {...props} />
}

export default Icon
