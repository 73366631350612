export const convertStringToFloat = (str: string): number => {
  const pointIsSeparator = !isNaN(Number('1.01'))
  const wrongSeparator = pointIsSeparator ? ',' : '.'
  const rightSeparator = wrongSeparator === ',' ? '.' : ','
  return parseFloat(str.replace(wrongSeparator, rightSeparator))
}

export const convertStringToFloatOrNull = (value: string | null | undefined) => {
  if (!value) {
    return null
  }

  const floatValue = convertStringToFloat(value)

  return !isNaN(floatValue) ? floatValue : null
}

export const isNumber = (value: any) => typeof value === 'number' && !isNaN(value)
