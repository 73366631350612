import styled from 'styled-components'
import { getFluidSizeWithFullFormula as gF, palette } from '../../../style/theme'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h1`
  font-family: GilmerBold, sans-serif;
  font-size: ${gF('px', 26, 48, 1366, 3840)};
  color: ${palette.tangaroa};
  margin-top: ${gF('px', 6, 22, 1366, 3840)};
`

export const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: ${gF('px', 16, 34, 1366, 3840)};
  margin-bottom: ${gF('px', 182, 388, 1366, 3840)};
`
