import styled, { css } from 'styled-components/macro'

import {
  analyticsPadding,
  breakpoints,
  breakpointsCross,
  getFluidFontSize,
  palette,
  pxToRem,
  spacing,
  zIndex,
  getFluidSizeWithFullFormula,
} from '../style/theme'

export const IconButton = styled.button`
  background-color: inherit;
  font: inherit;
  appearance: none;
  border: solid 1px ${palette.alto};
  border-radius: 3px;
  padding: ${pxToRem(spacing(1))}rem;
  outline: none;
`

export const scrollbarCss = css<{ scrollbarColor?: string; scrollbarBackgroundColor?: string }>`
  overflow-x: hidden !important;
  color: rgba(0, 0, 0, 0);
  transition: color 0.3s ease;
  &:hover {
    color: ${props =>
      props.scrollbarColor
        ? props.scrollbarColor
        : `var(--scrollbar-thumb-color, ${palette.congressBlue})`};
  }
  &::-webkit-scrollbar {
    display: block !important;
    background-clip: padding-box;
    width: 6px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track {
    background: ${props =>
      props.scrollbarBackgroundColor ?? `var(--scrollbar-track-color, ${palette.wildSand})`};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track-piece {
    background: ${props =>
      props.scrollbarBackgroundColor ?? `var(--scrollbar-track-color, ${palette.wildSand})`};
    border-radius: 4px;
    border: 1px solid
      ${props =>
        props.scrollbarBackgroundColor ?? `var(--scrollbar-track-color, ${palette.wildSand})`};
  }
  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border-radius: 4px;
    box-shadow: inset 0 0 0 10px;
  }
  &::-webkit-scrollbar-corner {
    border-radius: 4px;
  }
  // avoid to show the scrollbar space on mobile devices
  @media (hover: none) and (pointer: coarse) {
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
`

export const afaVerticalScrollbarCss = css<{
  scrollbarColor?: string
  scrollbarBackgroundColor?: string
}>`
  overflow-x: hidden !important;
  & {
    color: ${props =>
      props.scrollbarColor
        ? props.scrollbarColor
        : `var(--scrollbar-thumb-color, ${palette.shadowBlue})`};
  }
  &::-webkit-scrollbar {
    display: block !important;
    background-clip: padding-box;
    width: 6px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track {
    background: ${props =>
      props.scrollbarBackgroundColor ?? `var(--scrollbar-track-color, ${palette.platinum})`};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track-piece {
    background: ${props =>
      props.scrollbarBackgroundColor ?? `var(--scrollbar-track-color, ${palette.platinum})`};
    border-radius: 4px;
    border: 1px solid
      ${props =>
        props.scrollbarBackgroundColor ?? `var(--scrollbar-track-color, ${palette.platinum})`};
  }
  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border-radius: 4px;
    box-shadow: inset 0 0 0 10px;
  }
  &::-webkit-scrollbar-corner {
    border-radius: 4px;
  }
  // avoid to show the scrollbar space on mobile devices
  @media (hover: none) and (pointer: coarse) {
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
`

export const horizontalScrollbarCss = css`
  ${scrollbarCss};
  overflow-x: auto !important;
  overflow-y: hidden !important;
  &::-webkit-scrollbar {
    height: 6px;
  }
`

export const transparentHorizontalScrollbarCss = css`
  ${scrollbarCss};
  overflow-x: overlay !important;
  overflow-y: hidden !important;
  &::-webkit-scrollbar {
    height: 6px;
  }
  &::-webkit-scrollbar {
    background: transparent;
    border: none;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border: none;
  }
  &::-webkit-scrollbar-track-piece {
    background: transparent;
    border: none;
  }
`

export const transparentScrollbarCss = css`
  ${scrollbarCss}
  &::-webkit-scrollbar {
    background: transparent;
    border: none;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border: none;
  }
  &::-webkit-scrollbar-track-piece {
    background: transparent;
    border: none;
  }
`

export const grayScrollbar = css`
  ${scrollbarCss}
  & {
    &:hover {
      color: ${palette.shadowBlue};
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-track-piece {
    border: none;
    background: transparent;
  }

  &:hover {
    ::-webkit-scrollbar-track-piece {
      background: ${palette.extraLight};
    }
  }

  &::-webkit-scrollbar {
    width: 4px;

    @media (min-width: ${breakpoints.L}) {
      width: 8px;
    }
  }
`

export const CartContent = styled.div<{ maxImageHeight?: number }>`
  background: ${palette.white};
  overflow: auto;
  overflow-x: hidden;
  flex: 1;
  width: 100%;
  .ReactVirtualized__Grid,
  .ReactVirtualized__List {
    ${scrollbarCss}
  }
  &.analytics {
    background: #fff;
    padding: ${analyticsPadding};
    overflow-y: hidden;
    .ant-table-body {
      @media screen and (max-width: ${breakpoints.L}) {
        zoom: 0.5;
      }
      @media screen and (max-width: ${breakpoints.M}) {
        zoom: 0.75;
      }
    }
  }
  .purchase-controls .couvette-dislike-btn {
    display: inline-flex;
  }
  .couvette-item__dislike-btn {
    display: none;
  }
  ${props =>
    props.maxImageHeight
      ? `
    .couvette-item__prod-image{
      display: flex;
      align-items: center;
    }
    .product-image {
      width: 100%;
      max-height: ${props.maxImageHeight}px
    }
  `
      : ''}
`
CartContent.displayName = 'CartContent'

export const HeaderTitle = styled.span`
  text-transform: uppercase;
  letter-spacing: 0.012vw;
  grid-column-start: 2;
`
HeaderTitle.displayName = 'HeaderTitle'

export const CartHeader = styled.div`
  padding: 1.2vh;
  background: linear-gradient(180deg, ${palette.tropicalBlue} 0%, ${palette.selago} 100%);
  text-align: center;
  font-weight: bold;
  border-top-left-radius: 0.5vw;
  border-top-right-radius: 0.5vw;
  align-items: center;
  color: ${palette.biscay};
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;

  &.stars {
    background: ${palette.blueStars};
    color: ${palette.white};
  }

  .recommendation & {
    background: linear-gradient(0deg, ${palette.riptide} 0%, ${palette.turquoise} 100%);
  }
`

export const footerLinksAndButtonsStyle = css`
  padding: ${pxToRem(spacing(0.75))}rem ${pxToRem(spacing(1))}rem;
  text-transform: uppercase;
  font-weight: 900;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;

  &.active {
    color: inherit;
    border-color: ${palette.cornflowerBlue};
    background-color: ${palette.selago};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }

  &.disabled {
    background-color: ${palette.wildSand};
  }

  > * {
    margin-right: ${pxToRem(spacing(1))}rem;
  }

  .label {
    padding-top: 0.15em;
    @media (max-width: ${breakpoints.S}) {
      display: none;
    }
  }
`

export const afaFooterLinksAndButtonsStyle = css`
  padding: 0 1rem;
  height: 100%;
  text-transform: capitalize;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${getFluidSizeWithFullFormula('px', 12, 24, 1366, 3840)};
  line-height: 1;
  color: ${palette.tangaroa};

  &.active {
    color: ${palette.white};
    background-color: ${palette.shadowBlue};
  }

  &.disabled {
    background-color: ${palette.wildSand};
  }

  &.doors {
    & span {
      margin-right: ${pxToRem(spacing(1))}rem;
    }
  }
`

export const FooterButton = styled.button`
  ${footerLinksAndButtonsStyle}
`

export const IpadHeaderButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-column-start: 3;
  visibility: hidden;

  .ant-btn {
    font-size: 0.75rem;
    border: none;
    color: ${palette.congressBlue};
    text-transform: uppercase;
    background: transparent;
    box-shadow: none;
    display: flex;
    align-items: center;
    &.back-to-brands {
      color: ${palette.doveGray};
    }
    &.active {
      background-color: ${palette.selago};
      border: 1px solid ${palette.cornflowerBlue};
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }
    &-primary {
      background: ${palette.congressBlue};
      font-weight: bold;
      margin-right: 1vw;
      color: white;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      padding: 10px 20px;
    }
  }

  @media (max-width: ${breakpoints.L}) {
    visibility: visible;
  }
`

export const CartBrandAndMocos = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0.5vw 1.5vw;
  background: ${palette.wildSand};
  flex: 1;
  overflow: hidden;
  .ReactVirtualized__Grid,
  .ReactVirtualized__List {
    ${scrollbarCss}
  }
  &.cart-page.device-table {
    @media (max-width: ${breakpointsCross.XL.max}) {
      padding-right: 0;
    }
  }
`

export const CartWrapper = styled.div`
  overflow: hidden;
  background: ${palette.wildSand};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const getAntBadgeCountCss = (backgroundColor: string) => css`
  .ant-badge-count {
    border: 1px solid ${backgroundColor === palette.white ? palette.wildSand : 'transparent'};
    box-shadow: 0 0 2px 0
      ${backgroundColor === palette.white ? 'rgba(0, 0, 0, 0.3)' : palette.white};
    font-weight: bold;
    color: ${backgroundColor === palette.white ? palette.congressBlue : palette.white};
    background-color: ${backgroundColor};
    top: -10px;
    right: -10px;
    padding: 0 0.5em;

    .ant-scroll-number-only > p.current {
      font-size: ${getFluidFontSize('9px')};
    }
  }
`

export const PlpLayerWrapper = styled.div<{ scrollbarColor?: string }>`
  ${scrollbarCss};
  background: ${palette.white};
  position: absolute;
  z-index: ${zIndex.plpMenus};
  transform: translateX(100%);
  top: 0;
  right: 0;
  padding: 0 ${pxToRem(spacing(2.5))}rem;
  min-width: 12em;
  max-height: ${pxToRem(300)}rem;
  overflow: auto;
  border-radius: 0 5px 5px 0;

  @media (max-width: ${breakpoints.M}) {
    left: 0;
    bottom: 0;
    top: auto;
    transform: translateY(100%);
    border-radius: 0 0 5px 5px;
  }
`

export const PlpLayerWrapperMonth = styled.div`
  background: ${palette.white};
  position: absolute;
  z-index: ${zIndex.plpMenus};
  transform: translateX(100%);
  top: 0;
  right: 0;
  padding: 0 ${pxToRem(spacing(2.5))}rem;
  min-width: 12em;
  border-radius: 0 5px 5px 0;

  @media (max-width: ${breakpoints.M}) {
    left: 0;
    bottom: 0;
    top: auto;
    transform: translateY(100%);
    border-radius: 0 0 5px 5px;
  }
`

export const CheckoutFilterLayerWrapper = styled.div<{ scrollbarColor?: string }>`
  ${scrollbarCss};
  background: ${palette.white};
  border: solid 1px ${palette.alto};
  width: 100%;
  position: absolute;
  z-index: ${zIndex.checkoutMenus};
  left: 0;
  bottom: 0;
  top: auto;
  transform: translateY(100%);
  padding: 0 ${pxToRem(spacing(2.5))}rem;
  min-width: 14em;
  max-height: ${pxToRem(300)}rem;
  overflow: auto;
  border-radius: 6px;
`

export const afaProductCardChipCss = css`
  padding: 0.5em 1em;
  border: 2px solid ${palette.mischka};
  border-radius: 4rem;
  font-weight: 900;
`
