import styled from 'styled-components/macro'
import {
  breakpoints,
  breakpointsCross,
  getFluidFontSize,
  getFluidSize,
  getFluidSizeWithFullFormula as gF,
  palette,
  pxToRem,
} from '../../../../../style/theme'
import { CustomIconAndTextButton } from '../../../../AfaCheckout/components/AfaCheckoutCommonComponents'

export const Wrapper = styled.div`
  grid-area: header;
  display: flex;
  padding: ${gF('px', 15, 48, 1366, 3840)} 0 ${gF('px', 15, 48, 1366, 3840)};
  padding-left: ${pxToRem(32)}rem;
  box-sizing: border-box;
  align-items: center;
  //TODO: remove for the v13.2
  justify-content: space-between;
  width: 100%;

  h1 {
    font-size: 24px;

    b {
      font-family: 'GilmerBold', sans-serif;
    }
  }
`

export const Heading = styled.h1`
  display: flex;
  align-items: center;
  column-gap: 1ch;

  * {
    font-size: ${gF('px', 24, 64, 1366, 3840)};
  }

  span {
    text-transform: capitalize;
  }
`

export const WhiteboardName = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  * {
    font-family: GilmerBold, sans-serif;
    white-space: nowrap;
  }

  input {
    background-color: ${palette.gallery};
    border: none;
    height: 100%;

    &:focus {
      outline-style: solid;
      outline-width: ${gF('px', 2, 4, 1366, 3840)};
    }

    &.empty-name:focus {
      outline-color: ${palette.milanoRed};
    }

    max-width: ${gF('px', 210, 630, 1366, 3840)};
    overflow-x: hidden;
    text-overflow: ellipsis;

    @media (min-width: ${breakpoints.L}) {
      max-width: 100%;
    }
  }
`

export const PencilIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${palette.strangeWhite};
  border: 1px solid ${palette.tangaroa};
  min-width: ${gF('px', 36, 72, 1366, 3840)};
  min-height: ${gF('px', 36, 72, 1366, 3840)};
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  img {
    width: 50%;
    height: 50%;
  }

  &:hover {
    background-color: ${palette.tangaroa};
    border: 1px solid ${palette.strangeWhite};

    img {
      filter: invert(100%) grayscale(100%) brightness(10000%);
    }
  }
`

export const Toolbar = styled.div`
  padding-left: ${pxToRem(48)}rem;
  display: flex;
  align-items: center;
  overflow-x: auto;
`

export const BoardName = styled.div`
  padding: 0 30px 0 10px;
  max-width: 100%;
  font-family: GilmerMedium, sans-serif;
  font-size: ${gF('px', 14, 28, 1366, 3840)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const BoardButton = styled.div`
  height: ${gF('px', 38, 76, 1366, 3840)};
  width: ${gF('px', 125, 250, 1366, 3840)};
  border: solid ${gF('px', 1, 2, 1366, 3840)} ${palette.bermudaGray};
  color: ${palette.tangaroa};
  border-radius: ${pxToRem(4)}rem;
  display: flex;
  align-items: center;
  padding-left: ${gF('px', 12, 24, 1366, 3840)};
  position: relative;
  font-family: GilmerMedium, sans-serif;
  background-color: ${palette.gallery};
  z-index: 0;
  font-size: ${gF('px', 14, 30, 1366, 3840)};

  &:hover {
    cursor: pointer;
  }

  input {
    outline: none;
    color: ${palette.white};
    background: inherit;
    border: none;
    text-align: center;
    padding: 0 ${gF('px', 30, 40, 1366, 3840)} 0 10px;
    max-width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: GilmerMedium, sans-serif;
  }

  &:not(:first-of-type) {
    margin-left: -4px;
  }

  &.selected {
    background-color: ${palette.tangaroa};
    color: ${palette.white};
    border-color: ${palette.tangaroa};

    i {
      color: ${palette.white};
    }
  }

  &.edit {
    background-color: ${palette.shadowBlue};
    color: ${palette.white};

    i {
      color: ${palette.white};
    }
  }
`

export const IconWrapper = styled.div`
  width: ${gF('px', 24, 48, 1366, 3840)};
  height: ${gF('px', 24, 48, 1366, 3840)};
  border-radius: 50%;
  position: absolute;
  right: 4px;
  color: ${palette.tangaroa};
  font-size: ${gF('px', 20, 40, 1366, 3840)};
  transform: rotate(90deg);
  display: flex;
  align-items: center;
  justify-content: center;

  &.selected {
    background-color: ${palette.shadowBlue};
    transform: rotate(0deg);
  }
`

export const BoardButtonMenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
`

export const BoardButtonMenu = styled.div`
  display: flex;
  position: absolute;
  padding: ${pxToRem(20)}rem;
  gap: ${pxToRem(20)}rem;
  background-color: ${palette.white};
  border-radius: ${pxToRem(12)}rem;
  outline: 1px solid ${palette.mischka};
  box-shadow: 0 4px 4px 0 rgba(3, 20, 52, 0.17);
  z-index: 100001;

  img {
    font-size: ${gF('px', 24, 48, 1366, 3840)};
    cursor: pointer;
  }
`

export const AddBoardButton = styled.div`
  height: ${gF('px', 38, 76, 1366, 3840)};
  width: ${gF('px', 38, 76, 1366, 3840)};
  margin-left: ${pxToRem(16)}rem;
  display: inline-flex;
  border-radius: 50%;
  color: ${palette.white};
  background-color: ${palette.tangaroa};
  align-items: center;
  justify-content: center;
  font-size: ${gF('px', 20, 40, 1366, 3840)};
  font-weight: 100;

  &:not(.disabled) {
    cursor: pointer;
  }

  &.disabled {
    pointer-events: none;
    filter: opacity(0.3);
  }
`

export const StyledButton = styled(CustomIconAndTextButton)`
  background-color: ${palette.tangaroa};
  color: ${palette.white};
  white-space: nowrap;
  text-transform: uppercase;
  border-radius: 4px;
  font-size: ${gF('px', 13, 24, 1366, 3840)};
  font-weight: bold;
  letter-spacing: 0.3px;
  height: ${gF('px', 36, 72, 1366, 3840)};

  @media (max-width: ${breakpointsCross.L.max}) {
    line-height: 1.38;
  }

  @media (min-width: ${breakpoints.L}) {
    line-height: 0.75;
  }

  padding: ${getFluidSize(`${pxToRem(15)}rem`, 15)} ${getFluidSize(`${pxToRem(15)}rem`, 15)};
  min-width: max-content;
  width: ${gF('px', 167, 436, 1366, 3840)};
  justify-content: center;

  .rc-icon {
    font-size: ${getFluidFontSize('16px', 15)};
  }
`
