import styled from 'styled-components/macro'
import { breakpointsCross, getFluidSizeWithFullFormula as gF, palette } from '../../../style/theme'
import { ProductsListWidth } from '../../../model/whiteboard'

export const PageWrapper = styled.div`
  height: 100%;
  display: grid;
  font-family: 'GilmerMedium', sans-serif;
  grid-template-rows: min-content auto min-content;
`

export const Wrapper = styled.div`
  background-color: ${palette.gallery};
  padding-right: ${gF('px', 32, 64, 1366, 3840)};
  display: grid;
  grid-template-areas:
    'aside header'
    'aside main';
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr;
  color: ${palette.tangaroa};
  overflow: hidden;

  @media screen and (max-width: ${breakpointsCross.XL.max}) {
    grid-template-areas:
      'header header'
      'aside main';
  }
`

export const Main = styled.main`
  grid-area: main;
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  position: relative;
`

export const BoardContent = styled.div`
  background-color: inherit;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`

export const Board = styled.div<{ height?: number }>`
  background-color: ${palette.white};
  border-radius: 5px;
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  max-height: ${({ height }) => (height ? `${height}px` : '100%')};
  overflow: hidden;
  position: relative;
  aspect-ratio: 1.5;

  @media screen and (max-width: ${breakpointsCross.XL.max}) {
    margin: auto;
  }

  @media screen and (min-width: ${breakpointsCross.XL.min}) {
    margin-left: ${ProductsListWidth['4K']}px;

    &.centered {
      margin: auto;
    }
  }
`

export const BoardWrapper = styled.div`
  position: absolute;
  left: 0;
  margin-left: ${gF('px', 16, 42, 1366, 3840)};
  height: calc(100% - ${gF('px', 32, 70, 1024, 3840)});
  max-height: calc(100% - ${gF('px', 32, 70, 1024, 3840)});
  width: 100%;
`

export const ClickStopper = styled.div`
  cursor: pointer;
  * {
    pointer-events: none;
  }
`
