import { AfaProduct } from '../../model/afa'
import { AppThunk } from '../../model/model'
import { afaProductsSelector } from './selectors'
import { slice } from './slice'

const concatProducts = (queryData?: {
  start: number
  rows: number
  count: number
  products: AfaProduct[]
}): AppThunk => (dispatch, getState) => {
  if (!queryData) {
    return
  }

  if (queryData.start === 0) {
    return dispatch(slice.actions.setProducts(queryData.products))
  }

  const state = getState()
  const products = afaProductsSelector(state)

  const savedModelCodesMap = products.reduce((result, savedProduct) => {
    result[savedProduct.modelCode] = true
    return result
  }, {} as Record<string, true>)
  const productsToAdd = queryData.products.filter(
    newProdutct => !savedModelCodesMap[newProdutct.modelCode],
  )
  if (!productsToAdd.length) {
    return
  }

  return dispatch(slice.actions.setProducts(products.concat(Object.values(productsToAdd))))
}

const afaActions = {
  ...slice.actions,
  concatProducts,
}

export default afaActions
