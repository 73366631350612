import { Popover } from 'antd'
import React, { Component, Fragment } from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import styled from 'styled-components'

import { palette } from '../../style/theme'
import { AlphaNumericKeyboardInput } from '../KeyboardInput'
import RCIcon from '../UI/RCIcon'

const NotePreview = styled.div`
  font-size: 0.5em;
`

const StyledPopover = styled(Popover)`
  transition: none;
  transform: none;
  animation: none;
  animation-duration: 0ms;
`

const Wrapper = styled.div`
  width: 23vw;
  @media screen and (min-width: 1921px) {
    width: 10vw;
  }

  .input {
    width: 100%;
    height: 10vh;
    border: 1px solid #00a3b0;
    box-shadow: inset 0 2px 4px 0 rgba(70, 82, 93, 0.1);
    border-radius: 4px;
    resize: none;
    margin-bottom: 1vh;
    @media screen and (min-width: 1921px) {
      margin-bottom: 0.5vh;
      height: 5vh;
    }
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 18vw;
  margin-bottom: 0.5vh;
  @media screen and (min-width: 1921px) {
    min-width: 8vw;
  }

  h2 {
    margin-bottom: 0;
    flex-grow: 1;
    margin-bottom: 0;
    margin-left: 0.3vw;
    font-size: 1.4rem;
    text-align: center;
    @media screen and (min-width: 1921px) {
      font-size: 2rem;
    }
  }
`

const EditBtn = styled.button`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px ${palette.alto};
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: ${palette.congressBlue};
`

const Controls = styled.div`
  display: flex;
  justify-content: center;
`

const ShowNotesBtn = styled.div`
  position: absolute;
  top: 1em;
  left: -1.5em;
  background: none;
  border: none;
  outline: none;
  padding: 0;

  .rc-icon {
    width: 1.25em;
    height: 1.25em;
  }

  .circle {
    border-radius: 50%;
    border: 1px solid ${palette.congressBlue};
    color: ${palette.congressBlue};
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
  }
`

const NoteBtn = styled.button`
  margin: 0 0.2vw;
  border-radius: 22.5px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px ${palette.congressBlue};
  color: ${palette.congressBlue};
  text-transform: uppercase;
  padding: 0.5em 1em;

  &.save {
    color: ${palette.white};
    background-color: ${palette.congressBlue};
  }

  &:disabled {
    border: solid 1px ${palette.alto};
    color: ${palette.alto};
    background-color: ${palette.white};
  }
`

type Props = {
  showNotes?: boolean
  handleUpdateCart: (
    qntToAdd: number | null,
    finalQuantity?: number | undefined | null,
    notes?: string | undefined,
  ) => void
  notes?: string
}

type State = {
  visibilityNotes: boolean
  textValue?: string
  showEditablePanel: boolean
}

class Note extends Component<Props & { t: TFunction }, State> {
  state = {
    visibilityNotes: false,
    textValue: this.props.notes || '',
    showEditablePanel: false,
  }
  scrollableArea = document.querySelector('.ReactVirtualized__Grid')

  componentDidMount = () => {
    this.scrollableArea && this.scrollableArea.addEventListener('scroll', this.handleScroll, true)
  }

  componentWillUnmount = () => {
    this.scrollableArea && this.scrollableArea.removeEventListener('scroll', this.handleScroll)
  }

  handleChange = (textValue: string) => {
    this.setState({
      textValue,
    })
  }

  handleScroll = () => {
    this.handleVisibleChange()
  }

  handleEdit = () => {
    const { notes } = this.props
    this.setState({
      textValue: notes,
      showEditablePanel: true,
    })
  }

  cancelChanges = () => {
    this.setState({
      textValue: '',
      showEditablePanel: false,
    })
  }

  closePopover = () => {
    this.setState(
      {
        visibilityNotes: false,
      },
      () => {
        this.setState({
          showEditablePanel: false,
        })
      },
    )
  }

  handleClick = () => {
    const { textValue } = this.state
    const { handleUpdateCart, notes } = this.props
    //Prevent request to BE if notes didn't changed
    if (textValue !== notes) {
      handleUpdateCart(null, null, textValue)
      this.setState({
        textValue: '',
        showEditablePanel: false,
      })
    }
  }

  handleVisibleChange = (visible = false) => {
    this.setState({ visibilityNotes: visible })
  }

  renderContent = () => {
    const { t, notes } = this.props
    const { textValue, showEditablePanel, visibilityNotes } = this.state

    return (
      <Wrapper className="note-wrapper">
        <Header className="notes-header">
          {!showEditablePanel && notes && notes.length > 0 && (
            <EditBtn onClick={this.handleEdit} className="note-edit">
              <RCIcon type="show-notes" />
              {t('ProductPage.editNotes')}
            </EditBtn>
          )}
          <h2>{t('ProductPage.notes')}</h2>
          <button onClick={this.closePopover}>
            <RCIcon type="close" />
          </button>
        </Header>
        {!showEditablePanel && <p className="note">{notes}</p>}
        {(showEditablePanel || !notes) && visibilityNotes && (
          <Fragment>
            <AlphaNumericKeyboardInput
              isFirstLetterUppercase={false}
              uppercaseAfterSpace={false}
              inputClassName="ant-input"
              onChange={this.handleChange}
              className="search-customer-field"
              value={textValue}
              placeholder={t('ProductPage.addNotes')}
              showKeyboardOnInit={true}
              onFocus={() => undefined}
              onBlur={() => undefined}
              textarea
            />
            <Controls className="note-control">
              <NoteBtn
                className="btn-note close"
                onClick={this.cancelChanges}
                disabled={textValue.length === 0 && !notes ? true : false}
              >
                {t('GenericWords.cancel')}
              </NoteBtn>
              <NoteBtn
                className="btn-note save"
                onClick={this.handleClick}
                disabled={textValue.length === 0 && !notes ? true : false}
              >
                {t('GenericWords.save')}
              </NoteBtn>
            </Controls>
          </Fragment>
        )}
      </Wrapper>
    )
  }

  render = () => {
    const { showNotes, notes } = this.props
    const { visibilityNotes } = this.state
    const conditionForRender = notes && notes.length > 0
    const icon = conditionForRender ? (
      <div className="rc-icon circle">
        <NotePreview>{notes.length === 1 ? `${notes}` : `${notes[0]}...`}</NotePreview>
      </div>
    ) : (
      <RCIcon type="show-notes" />
    )
    return showNotes ? (
      <StyledPopover
        overlayStyle={{ zIndex: 999999 }}
        placement="left"
        trigger="click"
        overlayClassName="notes-wrapper"
        visible={visibilityNotes}
        content={this.renderContent()}
        onVisibleChange={this.handleVisibleChange}
      >
        <ShowNotesBtn className="show-notes" onClick={() => this.handleVisibleChange(true)}>
          {icon}
        </ShowNotesBtn>
      </StyledPopover>
    ) : null
  }
}

export default withTranslation('common')(Note)
