import { Icon } from 'antd'
import classnames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import app_config from '../../../config/app/config'
import devices from '../../../config/devices'
import { useHandleTableWallPageSwitch } from '../../../hooks/useHandleTableWallPageSwitch'
import socketManager from '../../../libs/socketManager'
import {
  getDeviceAbsolutePath,
  isCategoriesPage,
  isIpadOnlyRoom,
  isLargeRoom,
  isPageMatches,
  isSearchPLP,
} from '../../../libs/url'
import { ModalType } from '../../../model/model'
import { useConfirmCartMutation, useUploadCartMutation } from '../../../services/cart'
import { rebuildFiltersCounters, showFooterModal, toggleSearch } from '../../../store/actions'
import {
  footerModalVisibleSelector,
  isDigitalEventSelector,
  roomTypeSelector,
} from '../../../store/app/selectors'
import {
  brandHasActivationVideoSelector,
  brandHasDiscoveryVideoSelector,
  isBrandActivationPlayingSelector,
  isBrandDiscoveryPlayingSelector,
  openedBrandsSelector,
} from '../../../store/brands/selectors'
import { assortmentsViewTypeSelector } from '../../../store/cart/selectors'
import checkoutActions from '../../../store/checkout/actions'
import {
  checkoutCurrentPageSelector,
  checkoutStepSelector,
  importExportStepSelector,
} from '../../../store/checkout/selectors'
import { switchLabelKeySelector } from '../../../store/content/selectors'
import { showSwitchButtonSelector } from '../../../store/pdp/selectors'
import { isSearchVisibleSelector } from '../../../store/search/selectors'
import {
  isCartPageSelector,
  showFilterButtonSelector,
  showGridButtonSelector,
  showSearchButtonSelector,
  showSortByButtonSelector,
} from '../../../store/showButtonsSelectors'
import { cartModeSelector } from '../../../store/stars/selectors'
import {
  breakpoints,
  breakpointsCross,
  getFluidSize,
  palette,
  pxToRem,
  spacing,
} from '../../../style/theme'
import { FooterButton } from '../../CommonComponents'
import CalendarIcon from '../../icons/CalendarIcon'
import RCIcon from '../../UI/RCIcon'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 6vw 5vw auto auto auto auto;
  place-items: center;
  gap: ${pxToRem(spacing(1))}rem;

  .page-products &,
  .page-single-brand &,
  .page-checkout &,
  .page-choose-brand & {
    .footer-router-buttons {
      visibility: hidden;
      .couvette-brand {
        .brand-logo > img {
          width: 8.5vw;
          max-height: 4vh;
        }
      }
    }
    .cart-label {
      display: none;
    }
    .wishlist-label {
      display: none;
    }
  }

  .page-cart & {
    .default-label {
      display: none;
    }
    .wishlist-label {
      display: none;
    }
  }

  .page-wishlist & {
    .default-label {
      display: none;
    }
    .cart-label {
      display: none;
    }
  }
`

const BackButton = styled(FooterButton)`
  grid-column-start: 1;

  .page-choose-brand & {
    display: none;
  }

  .label {
    text-transform: none;
  }

  @media (max-width: ${breakpointsCross.L.max}) {
    .label {
      display: none;
    }
  }
`

const HomeLink = styled(Link)`
  padding: 0;
  border: 1px solid ${palette.congressBlue};
  border-radius: 100%;
  color: ${palette.doveGray};
  grid-column-start: 2;
  height: 3em;
  width: 3em;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .page-choose-brand & {
    display: none;
  }

  .rc-icon {
    width: 2em;
    height: 2em;
  }
`

const FilterButton = styled(FooterButton)`
  display: none;
  grid-column-start: 3;

  .page-products {
    .products-rcicon {
      display: inline;
    }
    .default-rcicon {
      display: none;
    }
  }
  &,
  .page-choose-brand &,
  .page-single-brand &,
  .page-checkout &,
  .page-cart & {
    display: flex;
  }
  &.filters-active {
    background: ${palette.congressBlue};
    color: ${palette.white};
  }
  .products-rcicon {
    display: none;
  }
`

const MonthButton = styled(FilterButton)`
  grid-column-start: 4;
`

const SortButton = styled(FooterButton)`
  display: none;

  .page-products &,
  .page-single-brand & {
    display: flex;
  }
`

const SwitchButton = styled(FooterButton)`
  @media (max-width: ${breakpoints.M}) {
    display: none;
  }
`

const SearchButton = styled(FooterButton)`
  display: none;

  @media (min-width: ${breakpoints.L}) {
    .page-choose-brand &,
    .page-single-brand &,
    .page-products &,
    .page-cart & {
      display: flex;
    }
  }
`

const ActivationButton = styled(FooterButton)`
  img {
    height: ${getFluidSize('20px')};
    width: ${getFluidSize('20px')};
  }
  grid-column-start: 3;
`

const DiscoveryButton = styled(FooterButton)`
  img {
    height: ${getFluidSize('20px')};
    width: ${getFluidSize('20px')};
  }
  grid-column-start: 4;
`

type Props = {
  backToBrandsEvent: () => void
  goBackEvent: () => void
  toggleModal: (type: ModalType) => void
  thereAreFiltersActive: boolean
}

export const FooterNavLeftColComponent: React.FC<Props> = ({
  backToBrandsEvent,
  goBackEvent,
  toggleModal,
  thereAreFiltersActive,
}) => {
  const { t } = useTranslation()

  const history = useHistory()

  const viewTypeSelected = !!useSelector(assortmentsViewTypeSelector)?.category
  const showGridButton = useSelector(showGridButtonSelector)
  const showSwitchButton = useSelector(showSwitchButtonSelector)
  const showSortByButton = useSelector(showSortByButtonSelector)
  const isStarsCart = useSelector(cartModeSelector) === 'stars'
  const isSearchPage = useSelector(isSearchPLP)
  const isCartPage = useSelector(isCartPageSelector)
  const isCheckoutPage = isPageMatches('checkout')
  const showSearchButton = useSelector(showSearchButtonSelector)
  const showFilterButton = useSelector(showFilterButtonSelector)
  const openedBrands = useSelector(openedBrandsSelector)
  const switchLabelKey = useSelector(switchLabelKeySelector)
  const brandHasActivationVideo = useSelector(brandHasActivationVideoSelector)
  const brandHasDiscoveryVideo = useSelector(brandHasDiscoveryVideoSelector)
  const isDigitalEvent = useSelector(isDigitalEventSelector)
  const isSearchVisible = useSelector(isSearchVisibleSelector)
  const modalVisible = useSelector(footerModalVisibleSelector)
  const isBrandActivationPlaying = useSelector(isBrandActivationPlayingSelector)
  const isBrandDiscoveryPlaying = useSelector(isBrandDiscoveryPlayingSelector)
  const page = useSelector(checkoutCurrentPageSelector)
  const step = useSelector(checkoutStepSelector)
  const importExportStep = useSelector(importExportStepSelector)
  const [, uploadCartResult] = useUploadCartMutation()
  const [, confirmCartResult] = useConfirmCartMutation()
  const isImportExportPage = page === 'import-export'
  const isProcessPage = page === 'process'
  const isThankYouPage = isCheckoutPage && step === 2
  const isAddressPage = isCheckoutPage && step === 1
  const isImportExportFirst =
    isImportExportPage && (importExportStep === 0 || importExportStep === 1)
  const isComparisonPage = isImportExportPage && importExportStep === 2

  const roomType = useSelector(roomTypeSelector)

  const handleSwitch = useHandleTableWallPageSwitch()

  const dispatch = useDispatch()

  const isSwitchDisabled = !isLargeRoom() && viewTypeSelected

  const checkoutGoBack = () => {
    switch (true) {
      case isAddressPage:
        dispatch(checkoutActions.previusStep())
        break
      case isThankYouPage:
        dispatch(checkoutActions.previusStep())
        break
      case isProcessPage:
        history.replace('cart')
        break
    }

    if (isComparisonPage) {
      uploadCartResult.reset()
      dispatch(checkoutActions.setImportExportStep(0))
    } else if (isImportExportFirst) {
      dispatch(checkoutActions.resetCheckout())
      confirmCartResult.reset()
    }
  }

  const changeModal = (type: ModalType) => (e: any) => {
    e.stopPropagation()
    toggleModal(type)
  }

  const handleBrandVideo = (videoType: string) => {
    isIpadOnlyRoom()
      ? dispatch(showFooterModal(`${videoType}Video` as ModalType))
      : socketManager.emitSignal(`play_${videoType}_video`, { brand: openedBrands[0] }, [
          devices.table,
        ])
  }

  const handleBrandActivationVideo = () => handleBrandVideo('activation')

  const handleBrandDiscoveryVideo = () => handleBrandVideo('discovery')

  const isIpadOnly = roomType === 'ipad-only'
  const showPlayActivationButton =
    (!isBrandActivationPlaying || isIpadOnly) && brandHasActivationVideo && isCategoriesPage()
  const showPlayDiscoveryButton =
    (!isBrandDiscoveryPlaying || isIpadOnly) && brandHasDiscoveryVideo && isCategoriesPage()
  const homeLink = isDigitalEvent ? '/digital-events' : `${getDeviceAbsolutePath()}/choose-brand`

  return (
    <Wrapper className="footer-nav-left-col">
      {!isThankYouPage && (
        <BackButton onClick={isCheckoutPage ? checkoutGoBack : goBackEvent} className="btn-back">
          <Icon type="left" />
          <span className="label">{t('CustomersList.back')}</span>
        </BackButton>
      )}
      {homeLink && (
        <HomeLink onClick={backToBrandsEvent} to={homeLink}>
          <RCIcon type="icons-home" />
        </HomeLink>
      )}

      {showFilterButton && !isCheckoutPage && (
        <FilterButton
          onClick={(e: any) => {
            changeModal('filters')(e)

            // rebuild filters counter on opening
            if (!modalVisible) {
              dispatch(rebuildFiltersCounters())
            }
          }}
          className={classnames({
            active: modalVisible === 'filters',
            'filters-active': thereAreFiltersActive && !isSearchPage,
          })}
        >
          <RCIcon iconClass="products-rcicon" type="filters-white" />
          <RCIcon iconClass="default-rcicon" type="filters" />{' '}
          <span className="label cart-label">{t('FooterNav.item_filtercart')}</span>
          <span className="label wishlist-label">{t('FooterNav.item_filterWhishlist')}</span>
          <span className="label default-label">{t('Filters.item_filterby')}</span>
          <Icon type={modalVisible === 'filters' ? 'up' : 'down'} theme="outlined" />
        </FilterButton>
      )}

      {isCheckoutPage && !isImportExportPage && !isAddressPage && !isThankYouPage && (
        <FilterButton
          onClick={(e: any) => {
            changeModal('filtersCheckout')(e)

            // rebuild filters counter on opening
            if (!modalVisible) {
              dispatch(rebuildFiltersCounters())
            }
          }}
        >
          <RCIcon iconClass="products-rcicon" type="filters-white" />
          <RCIcon iconClass="default-rcicon" type="filters" />{' '}
          <span className="label cart-label">{t('FooterNav.item_filtercart')}</span>
          <span className="label wishlist-label">{t('FooterNav.item_filterWhishlist')}</span>
          <span className="label default-label">{t('Filters.item_filterby')}</span>
          <Icon type={modalVisible === 'filtersCheckout' ? 'up' : 'down'} theme="outlined" />
        </FilterButton>
      )}

      {isStarsCart && isCartPage && !isCheckoutPage && (
        <MonthButton
          onClick={e => {
            changeModal('filterByMonth')(e)
          }}
          className={classnames({
            active: modalVisible === 'filterByMonth',
          })}
        >
          <CalendarIcon width="1em" height="1em" />
          <span className="label cart-label">{t('FooterNav.filterByMonth')}</span>
          <Icon type={modalVisible === 'filterByMonth' ? 'up' : 'down'} theme="outlined" />
        </MonthButton>
      )}

      {showSearchButton && (
        <SearchButton
          onClick={() => dispatch(toggleSearch())}
          className={classnames({
            'btn-search': true,
            active: isSearchVisible || isSearchPage,
          })}
        >
          <Icon type="search" /> {t('FooterNav.item_search')}{' '}
        </SearchButton>
      )}

      {showSortByButton && !isStarsCart && (
        <SortButton
          className={classnames({
            active: modalVisible === 'sort',
          })}
          onClick={changeModal('sort')}
        >
          <RCIcon type="order-by" />
          <span className="label cart-label">{t('FooterNav.orderCart_button')}</span>
          <span className="label wishlist-label">{t('FooterNav.orderWishlist_button')}</span>
          <span className="label default-label">{t('FooterNav.orderBy_button')}</span>{' '}
          <Icon type={modalVisible === 'sort' ? 'up' : 'down'} theme="outlined" />
        </SortButton>
      )}

      {showPlayActivationButton && (
        <ActivationButton onClick={handleBrandActivationVideo}>
          <img
            alt="icon"
            className={'anticon rc-icon'}
            src={`${app_config.publicUrl}/assets/images/play.svg`}
          />
          <span className="label">{t('FooterNav.play_intro')}</span>
        </ActivationButton>
      )}

      {showPlayDiscoveryButton && (
        <DiscoveryButton onClick={handleBrandDiscoveryVideo}>
          <img
            alt="icon"
            className={'anticon rc-icon'}
            src={`${app_config.publicUrl}/assets/images/play.svg`}
          />
          <span className="label">{t('FooterNav.play_discovery')}</span>
        </DiscoveryButton>
      )}

      {(showSwitchButton || showGridButton) && (
        <SwitchButton
          disabled={showSwitchButton && isSwitchDisabled}
          className={classnames({
            'switch-link': true,
            disabled: showSwitchButton && isSwitchDisabled,
          })}
          onClick={handleSwitch}
        >
          <Icon type="switcher" />
          <span className="label">{t(switchLabelKey)}</span>
        </SwitchButton>
      )}
    </Wrapper>
  )
}

export default FooterNavLeftColComponent
