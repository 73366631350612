import React from 'react'
import styled from 'styled-components'
import { useChangeAaMapSelectedPositioning } from '../../../../hooks/useHandleOpenPages'
import GlobeIcon from '../../Components/GlobeIcon'
import { Positioning } from '../../Model/aaModel'
import { getFluidSizeWithFullFormula, palette } from '../../../../style/theme'
import { useSearchParams } from '../../../../hooks/useSearchParams'

const Button = styled.button<{ color: string }>`
  width: ${getFluidSizeWithFullFormula('px', 36, 48, 1366, 3840)};
  height: ${getFluidSizeWithFullFormula('px', 36, 48, 1366, 3840)};
  border-radius: ${getFluidSizeWithFullFormula('px', 10, 14, 1366, 3840)};
  background-color: ${palette.biscay};
  display: flex;
  justify-content: center;
  align-items: center;

  &.selected {
    background-color: ${({ color }) => color};
  }
`

const GlobeIconStyled = styled(GlobeIcon)`
  width: ${getFluidSizeWithFullFormula('px', 20, 40, 1366, 3840)};
  height: ${getFluidSizeWithFullFormula('px', 20, 40, 1366, 3840)};
  color: ${palette.white};
`

type Props = {
  positioning: Positioning
}

const WallMapSwitcher: React.FC<Props> = ({ positioning }) => {
  const changeAaMapSelectedPositioning = useChangeAaMapSelectedPositioning(positioning)
  const [searchParams] = useSearchParams()
  const selectedPositioning = searchParams.get('positioning')

  const getBtnColor = () => {
    let color = ''
    switch (positioning) {
      case 'young':
        color = palette.cornflowerBlueAA
        break
      case 'innovative':
        color = palette.barney
        break
      case 'sophisticated':
        color = palette.romanCoffee
        break
      case 'sport':
        color = palette.waterGreen
        break
      case 'everyday':
        color = palette.acidGreen
        break
    }
    return color
  }

  return (
    <Button
      onClick={changeAaMapSelectedPositioning}
      className={selectedPositioning === positioning ? 'selected' : undefined}
      color={getBtnColor()}
    >
      <GlobeIconStyled />
    </Button>
  )
}

export default WallMapSwitcher
