import { createSlice } from '@reduxjs/toolkit'

const DEFAULT_STATE = {
  width: window.innerWidth,
  height: window.innerHeight,
  orientation:
    window.innerWidth >= window.innerHeight
      ? 'landscape'
      : ('portrait' as 'landscape' | 'portrait'),
}

export const viewportSlice = createSlice({
  name: 'viewport',
  initialState: DEFAULT_STATE,
  reducers: {
    updateViewport: state => {
      state.width = window.innerWidth
      state.height = window.innerHeight
      state.orientation = window.innerWidth >= window.innerHeight ? 'landscape' : 'portrait'
    },
  },
})

export default viewportSlice.reducer
