import { createApi } from '@reduxjs/toolkit/query/react'
import {
  DuplicateWhiteboard,
  GetKamsResponse,
  PinnedItemsToWhiteboardResponse,
  PinnedItemsToWhiteboardType,
  ResultAndWarningsResponse,
  SelectedSwitchType,
  ShareTemplate,
  VisibleWhiteboardsParams,
  WhiteboardPinItem,
  WhiteboardResponseType,
  WhiteboardType,
} from '../model/whiteboard'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { v4 as uuidv4 } from 'uuid'
import app_config from '../config/app/config'
import { useDispatch, useSelector } from 'react-redux'
import {
  currentWhiteboardStateSelector,
  getSelectedSkuCode,
  getSelectedSwitch,
  getSelectedWhiteboard,
  initialWhiteboardStateSelector,
} from '../store/whiteboard/selectors'
import { AfaProduct } from '../model/afa'
import { customerIdSelector, eventIdSelector } from '../store/app/selectors'
import { useSearchParams } from '../hooks/useSearchParams'
import { usernameSelector } from '../store/auth/selectors'
import queryString from 'qs'
import whiteboardActions from '../store/whiteboard/actions'
import { errorNotification } from '../components/Notification/notifications'
import { EMPTY_BOARD } from '../store/whiteboard/slice'
import { useTranslation } from 'react-i18next'

export const TAG_WHITEBOARD = 'afa-whiteboard'

export const whiteboardApi = createApi({
  reducerPath: 'whiteboardApi',
  tagTypes: [TAG_WHITEBOARD],
  baseQuery: staggeredBaseQueryWithBailOut(`${app_config.apiUrl}/whiteboard`),
  endpoints: builder => ({
    getVisibleWhiteboards: builder.query<WhiteboardResponseType, VisibleWhiteboardsParams>({
      query: ({
        type,
        eventId,
        customerId,
        start,
        rows,
        sort,
        release,
        showFirstLastPinned,
        skuLastPinned,
        searchText,
      }) => ({
        url: `/event/${eventId}/customer/${customerId}/type/${type ??
          'both'}?${queryString.stringify({
          start,
          rows,
          sort,
          release,
          showFirstLastPinned,
          skuLastPinned,
          searchText,
        })}`,
      }),
      providesTags: [TAG_WHITEBOARD],
      transformResponse: (response: WhiteboardResponseType) => {
        const { whiteboards } = response
        return {
          ...response,
          whiteboards: whiteboards.map(whiteboard => ({
            ...whiteboard,
            id: whiteboard.key,
          })),
        }
      },
    }),

    lockWhiteboard: builder.mutation<
      { result: boolean },
      { lock: boolean; key: string; customerCode: string; eventCode: string }
    >({
      query: ({ lock, key, eventCode, customerCode }) => ({
        url: '/lock',
        method: 'PUT',
        body: {
          lock,
          key,
          eventCode,
          customerCode,
        },
      }),
      invalidatesTags: [TAG_WHITEBOARD],
    }),

    getWhiteboard: builder.query<
      WhiteboardType,
      { type: SelectedSwitchType; key: string; customerCode: string; eventCode: string }
    >({
      query: ({ type, key, eventCode, customerCode }) => ({
        url: `/type/${type}/eventCode/${eventCode}/customerCode/${customerCode}/key/${key}`,
      }),
      providesTags: [TAG_WHITEBOARD],
    }),

    deleteWhiteboard: builder.mutation<
      ResultAndWarningsResponse,
      { whiteboardId: string; eventCode: string; customerCode: string }
    >({
      query: ({ whiteboardId, customerCode, eventCode }) => ({
        url: '/deleteWhiteboard',
        method: 'DELETE',
        body: {
          key: whiteboardId,
          eventCode,
          customerCode,
        },
      }),
      invalidatesTags: [TAG_WHITEBOARD],
    }),

    saveWhiteboard: builder.mutation<
      ResultAndWarningsResponse,
      { whiteboard: WhiteboardType; eventId: string; customerId: string; type: SelectedSwitchType }
    >({
      query: ({ whiteboard, eventId, customerId, type }) => ({
        url: '/saveWhiteboard',
        method: 'POST',
        body: { ...whiteboard, eventCode: eventId, customerCode: customerId, type },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(whiteboardActions.setLatestSaveWhiteboard(data))
        } catch {
          // eslint-disable-next-line no-console
          console.log('error in getting latest saveWhiteboard result')
        }
      },
      invalidatesTags: [TAG_WHITEBOARD],
    }),

    pinItemToWhiteBoard: builder.mutation<
      ResultAndWarningsResponse,
      {
        items: WhiteboardPinItem[]
      }
    >({
      query: itemsToPin => ({
        url: '/PinItemToWhiteBoard',
        method: 'POST',
        body: itemsToPin,
      }),
      invalidatesTags: [TAG_WHITEBOARD],
    }),

    getPinnedItemsForWhiteboard: builder.query<
      PinnedItemsToWhiteboardResponse,
      { key: string; eventCode: string; customerCode: string }
    >({
      query: ({ key, eventCode, customerCode }) => ({
        url: `/eventCode/${eventCode}/customerCode/${customerCode}/key/${key}`,
      }),
      providesTags: [TAG_WHITEBOARD],
    }),

    getKams: builder.query<GetKamsResponse, { searchText: string }>({
      query: ({ searchText }) => ({
        url: `/getKams/searchText/${searchText}`,
      }),
      providesTags: [TAG_WHITEBOARD],
    }),

    duplicateWhiteboard: builder.mutation<ResultAndWarningsResponse, DuplicateWhiteboard>({
      query: duplicateWhiteboard => ({
        url: '/DuplicateWhiteboard',
        method: 'POST',
        body: duplicateWhiteboard,
      }),
      invalidatesTags: [TAG_WHITEBOARD],
    }),

    shareTemplate: builder.mutation<ResultAndWarningsResponse, ShareTemplate>({
      query: shareTemplate => ({
        url: '/shareTemplate',
        method: 'POST',
        body: shareTemplate,
      }),
      invalidatesTags: [TAG_WHITEBOARD],
    }),

    getImageURL: builder.mutation<
      { fileName: string; URL: string },
      { base64: string; filename: string }
    >({
      query: ({ base64, filename }) => ({
        url: '/getImageUrl',
        method: 'POST',
        body: {
          base64,
          filename,
        },
      }),
    }),
  }),
})

export const useGetVisibleWhiteboardsQuery = (searchText?: string, type?: SelectedSwitchType) => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)
  const isWhiteboard = window.location.href.includes('whiteboard')
  const selectedSkuCode = useSelector(getSelectedSkuCode)

  const [searchParams] = useSearchParams()
  const start = Number(searchParams.get('start')) || 1
  const rows = Number(searchParams.get('rows')) || 50
  const showFirstLastPinned = !isWhiteboard
  const sort = searchParams.get('sort') || 'date'

  return whiteboardApi.useGetVisibleWhiteboardsQuery({
    eventId,
    customerId,
    type,
    start: isWhiteboard ? start : 0,
    rows: isWhiteboard ? rows : 0,
    sort,
    release: undefined,
    skuLastPinned: selectedSkuCode,
    showFirstLastPinned,
    searchText,
  })
}

export const useGetSingleWhiteboardQuery = (type: SelectedSwitchType, key: string) => {
  const eventCode = useSelector(eventIdSelector)
  const customerCode = useSelector(customerIdSelector)

  return whiteboardApi.useGetWhiteboardQuery({ type, key, eventCode, customerCode }, { skip: !key })
}

export const useSaveWhiteboardMutation = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)
  const type = useSelector(getSelectedSwitch)
  const owner = useSelector(usernameSelector)
  const initialWhiteboardState = useSelector(initialWhiteboardStateSelector)
  const currentWhiteboardState = useSelector(currentWhiteboardStateSelector)

  const [_saveWhiteboard, saveWhiteboardResult] = whiteboardApi.useSaveWhiteboardMutation()

  const prefix = 'cdn-record-files-pi/'

  const shouldSkipMutation = (whiteboardHasChanged?: boolean, buttonSaveClicked?: boolean) => {
    let mutationShouldBeSkipped = false

    mutationShouldBeSkipped = currentWhiteboardState === initialWhiteboardState

    if (whiteboardHasChanged) {
      mutationShouldBeSkipped = false
    }

    if (mutationShouldBeSkipped) {
      dispatch(whiteboardActions.setPreviewIsUpdating(false))
      if (buttonSaveClicked) {
        errorNotification({ message: t('Whiteboard.noChangesMade') })
      }
    } else {
      dispatch(whiteboardActions.setPreviewIsUpdating(true))
    }

    return mutationShouldBeSkipped
  }

  const saveWhiteboard = async ({
    selectedWhiteboard,
    whiteboardHasChanged,
    buttonSaveClicked,
  }: {
    selectedWhiteboard: WhiteboardType
    whiteboardHasChanged?: boolean
    buttonSaveClicked?: boolean
  }) => {
    if (shouldSkipMutation(whiteboardHasChanged, buttonSaveClicked)) return

    const whiteboard = { ...selectedWhiteboard } as WhiteboardType

    if (whiteboard.boards.length) {
      whiteboard.boards.forEach(board => {
        if (!board.name) board.name = 'untitled'
        board.items.map(item => {
          const img = item?.product?.image || ''
          return img.split(prefix)
        })
        board.texts.map(text => {
          if (text.isNew) {
            return { ...text, isNew: undefined }
          } else {
            return text
          }
        })
      })
    } else {
      whiteboard.boards.push({
        ...EMPTY_BOARD,
        key: uuidv4(),
        name: 'Board 1',
      })
    }

    await _saveWhiteboard({
      whiteboard: { ...whiteboard, owner },
      eventId: eventId,
      customerId: customerId,
      type,
    })
  }

  return [saveWhiteboard, saveWhiteboardResult] as [
    typeof saveWhiteboard,
    typeof saveWhiteboardResult,
  ]
}

export const useDeleteWhiteboardMutation = () => {
  const eventCode = useSelector(eventIdSelector)
  const customerCode = useSelector(customerIdSelector)

  const [_deleteWhiteboard, deleteWhiteboardResult] = whiteboardApi.useDeleteWhiteboardMutation()

  const saveWhiteboard = ({ whiteboardId }: { whiteboardId: string }) => {
    _deleteWhiteboard({
      whiteboardId,
      customerCode,
      eventCode,
    })
  }

  return [saveWhiteboard, deleteWhiteboardResult] as [
    typeof saveWhiteboard,
    typeof deleteWhiteboardResult,
  ]
}

export const usePinItemToWhiteBoardMutation = () => {
  const eventCode = useSelector(eventIdSelector)
  const customerCode = useSelector(customerIdSelector)

  const [
    _pinItemsToWhiteboard,
    pinItemsToWhiteboardResult,
  ] = whiteboardApi.usePinItemToWhiteBoardMutation()

  const pinItemsToWhiteboard = ({
    whiteboardId,
    products,
  }: {
    whiteboardId: string
    products: AfaProduct[] | PinnedItemsToWhiteboardType[]
  }) => {
    const itemsToPinOrUnpin = products.map(product => {
      if ('modelCode' in product) {
        const sizes = Object.values(product?.mocos)?.[0]?.sizes
        return {
          eventCode: eventCode,
          customerCode: customerCode,
          key: whiteboardId,
          productNumber: product.modelCode,
          productName: product.name,
          colorCode: Object.values(product?.mocos)?.[0]?.colorCode,
          colorName: Object.values(product?.mocos)?.[0]?.colorDescription,
          image: Object.values(product?.mocos)?.[0]?.catalogImgPath,
          materialCode: Object.values(sizes)?.[0]?.materialCode,
          pinned: true,
        }
      } else {
        return {
          eventCode: eventCode,
          customerCode: customerCode,
          key: whiteboardId,
          productNumber: product.productNumber,
          productName: product.productName,
          colorCode: product.colorCode,
          colorName: product.colorName,
          image: product.image,
          materialCode: product.materialCode,
          pinned: false,
        }
      }
    })

    _pinItemsToWhiteboard({
      items: itemsToPinOrUnpin,
    })
  }

  return [pinItemsToWhiteboard, pinItemsToWhiteboardResult] as [
    typeof pinItemsToWhiteboard,
    typeof pinItemsToWhiteboardResult,
  ]
}

export const useGetPinnedItemsForWhiteboardMutation = () => {
  const eventCode = useSelector(eventIdSelector)
  const customerCode = useSelector(customerIdSelector)
  const selectedWhiteboard = useSelector(getSelectedWhiteboard)?.key || ''

  return whiteboardApi.useGetPinnedItemsForWhiteboardQuery(
    {
      eventCode,
      customerCode,
      key: selectedWhiteboard,
    },
    { skip: !selectedWhiteboard },
  )
}

export const useDuplicateWhiteboardMutation = () => {
  const eventCode = useSelector(eventIdSelector)
  const customerCode = useSelector(customerIdSelector)
  const owner = useSelector(usernameSelector)

  const [
    _duplicateWhiteboard,
    duplicateWhiteboardResult,
  ] = whiteboardApi.useDuplicateWhiteboardMutation()

  const duplicateWhiteboard = ({
    keyToCopy,
    newName,
    type,
  }: {
    keyToCopy: string
    newName: string
    type?: SelectedSwitchType
  }) => {
    _duplicateWhiteboard({
      eventCode,
      customerCode,
      keyToCopy,
      newName,
      type,
      owner,
    })
  }

  return [duplicateWhiteboard, duplicateWhiteboardResult] as [
    typeof duplicateWhiteboard,
    typeof duplicateWhiteboardResult,
  ]
}

export const useLockWhiteboardMutation = ({ key, locked }: { key: string; locked: boolean }) => {
  const eventCode = useSelector(eventIdSelector)
  const customerCode = useSelector(customerIdSelector)

  const [_lockWhiteboard, lockWhiteboardResult] = whiteboardApi.useLockWhiteboardMutation()
  const lockWhiteboard = () => {
    _lockWhiteboard({
      key,
      lock: !locked,
      customerCode,
      eventCode,
    })
  }

  return [lockWhiteboard, lockWhiteboardResult] as [
    typeof lockWhiteboard,
    typeof lockWhiteboardResult,
  ]
}

export const useLazyGetKamsQuery = () => {
  const [_getKams, getKamsResult, promiseInfo] = whiteboardApi.useLazyGetKamsQuery()

  const getKams = ({ searchText }: { searchText: string }) => _getKams({ searchText })

  return [getKams, getKamsResult, promiseInfo] as [
    typeof getKams,
    typeof getKamsResult,
    typeof promiseInfo,
  ]
}

export const useShareTemplateMutation = () => {
  const eventCode = useSelector(eventIdSelector)
  const customerCode = useSelector(customerIdSelector)

  const [_shareTemplate, shareTemplateResult] = whiteboardApi.useShareTemplateMutation()

  const shareTemplate = ({
    key,
    kamsToShare,
    pdf64,
  }: {
    key: string
    kamsToShare: string[]
    pdf64: string
  }) =>
    _shareTemplate({
      key,
      kamsToShare,
      customerCode,
      eventCode,
      pdf64,
    })

  return [shareTemplate, shareTemplateResult] as [typeof shareTemplate, typeof shareTemplateResult]
}

export const { useGetImageURLMutation } = whiteboardApi
