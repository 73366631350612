import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import SwiperCore, { Navigation, Pagination } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Moco } from '../../model/product'
import { maxHeight } from '../../style/DigitalEventsCommonComponents'
import { pxToRem, spacing } from '../../style/theme'
import { MocoImage } from './MocoCard'

SwiperCore.use([Pagination, Navigation])

const SmallPreviewImg = styled(MocoImage)`
  width: 100%;
  max-height: 10rem;
  cursor: pointer;
`
const OtherColorsWrapper = styled.div`
  position: relative;
  .swiper-container,
  .swiper-wrapper {
    margin-top: ${pxToRem(spacing(1))}rem;
    margin-bottom: ${pxToRem(spacing(1))}rem;
  }
  .swiper-container {
    @media (max-height: ${maxHeight}) {
      max-width: 90%;
    }
  }
  .swiper-slide {
    background-position: center;
    background-size: cover;
    background-position: center center;
    &.swiper-slide-active {
      transform: scale(1.5);
      opacity: 1;
    }
    opacity: 0.3;
    transform: scale(1);
    transition: transform 0.2s;
  }
  overflow: hidden;
`

const OtherColors: React.FC<{
  mocos: Moco[]
  onProductSelect: (moco: Moco) => void
  selectedItem: string
}> = ({ mocos, onProductSelect, selectedItem }) => {
  const [swiperRef, setSwiperRef] = useState<SwiperCore>()
  const startIndex = mocos.findIndex(moco => selectedItem === moco.mocoCode)
  return (
    <OtherColorsWrapper>
      <Swiper
        initialSlide={startIndex}
        onSwiper={swiper => {
          setSwiperRef(swiper)
        }}
        onSlideChange={swiper => {
          onProductSelect(mocos[swiper.activeIndex])
        }}
        slidesPerView={3}
        centeredSlides={true}
        grabCursor={true}
        slidesPerGroup={1}
        loop={false}
        className="swiper"
      >
        {mocos.map((moco, index) => {
          return (
            <SwiperSlide
              key={moco.mocoCode}
              onClick={() => {
                onProductSelect(moco)
                swiperRef?.slideTo(index)
              }}
            >
              <SmallPreviewImg
                angle="quarter"
                key={moco.mocoCode}
                className={selectedItem === moco.mocoCode ? 'selected' : ''}
                moco={moco}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </OtherColorsWrapper>
  )
}

export default withTranslation('common')(OtherColors)
