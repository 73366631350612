import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { staggeredBaseQueryWithBailOut } from '../../libs/services'
import app_config from '../../config/app/config'
import { GeocodingResult } from './Model/aaModel'

export const aaAddressApi = createApi({
  reducerPath: 'aaAddressApi',
  baseQuery: staggeredBaseQueryWithBailOut(`${app_config.apiAddressUrl}/aa`),
  endpoints: builder => ({
    getAddressAutocomplete: builder.query<string[], string>({
      query: address => ({
        url: `/map/address/autocomplete/${encodeURIComponent(address.replace('/', ''))}`,
      }),
    }),
    getAddressGeocode: builder.query<GeocodingResult, string>({
      query: address => ({
        url: `/map/address/geocode/${encodeURIComponent(address.replace('/', ''))}`,
      }),
    }),
  }),
})

export const useGetAddressAutocomplete = (address: string) => {
  return aaAddressApi.useGetAddressAutocompleteQuery(address, { skip: !address })
}

export const useGetAddressGeocode = (address: string) => {
  return aaAddressApi.useGetAddressGeocodeQuery(address, { skip: !address })
}
