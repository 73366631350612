import React, { SVGProps } from 'react'

const FilterIcon: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="44px"
      height="44px"
      viewBox="0 0 44 44"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
    >
      <path d="M24.7500002,19.2500423 L24.7500002,41.2500907 L19.2499995,36.4375802 L19.2499995,19.2500423 L2.74999735,2.75000605 L41.2500023,2.75000605 L24.7500002,19.2500423 Z M41.2472523,0 L2.75326297,0 C0.310489225,0 -0.922026558,2.96373308 0.808668976,4.69451814 L16.4999991,20.3891464 L16.4999991,36.4375802 C16.4999991,37.2306132 16.8422882,37.9849742 17.4391242,38.5071316 L22.9391249,43.3180953 C24.6955158,44.8550049 27.5000005,43.6344319 27.5000005,41.2485439 L27.5000005,20.3891464 L43.1917603,4.69451814 C44.9191043,2.96725653 43.6949245,0 41.2472523,0 Z" />
    </svg>
  )
}

export default FilterIcon
