import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { isWallDevice } from '../../../libs/url'
import { Brand } from '../../../model/brand'
import { CartProduct } from '../../../model/cart'
import { Moco, Product } from '../../../model/product'
import { WishlistProduct } from '../../../model/wishlist'
import {
  breakpoints,
  breakpointsNoUnit,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
  spacing,
} from '../../../style/theme'
import PurchaseControls from '../../Couvettes/Couvette/PurchaseControls/PurchaseControls'
import Price from '../../Price'
import ProductIcons from '../../ProductIcons'
import { useSelector } from 'react-redux'
import { cartEditableSelector } from '../../../store/cart/selectors'

const Wrapper = styled.div`
  z-index: 2;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: ${getFluidSizeWithFullFormula(
    'px',
    17,
    44,
    breakpointsNoUnit.XS,
    breakpointsNoUnit.XL,
  )};
`

const Info = styled.div`
  padding: 0;
  flex-direction: column;

  .product-name,
  .product-prices {
    font-size: 0.92em;
    padding-bottom: 0.5em;
    font-weight: normal;
    margin: 0;
    .upc {
      font-size: 1rem;
      margin-top: 0.3vh;
    }
  }

  .product-prices__srPrice {
    font-size: 0.58em;
  }
`

const StyleName = styled.div`
  font-size: 0.67em;
  margin-top: 0.4em;
`

const AddWishlist = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`

const Release = styled.span`
  font-size: 0.67em;
`

const Tecnology = styled.span`
  font-size: 0.67em;
  font-weight: 900;
  line-height: 1.5;
  text-align: left;
`

const ReleaseAndIcons = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  svg {
    width: ${pxToRem(spacing(3))}rem;
    height: ${pxToRem(spacing(3))}rem;
    color: ${palette.congressBlue};
  }
`

const StyledProductIcons = styled(ProductIcons)`
  @media screen and (max-width: ${breakpoints.M}) {
    .product-icons {
      flex-wrap: wrap;
      .release {
        display: inline-block;
        width: 100%;
      }
    }
  }
`

const ListOfPurchaseControls = styled.div`
  display: flex;
  flex-wrap: wrap;

  .purchase-controls {
    font-size: 0.67em;
    margin-left: 0.67em;
  }

  @media screen and (max-width: ${breakpoints.M}) {
    .list-of-purchase-controls {
      align-items: flex-end;
    }
  }
`

const lensPropertiesDict: Record<string, string> = {
  ph: 'Filters.item_lensproperties_photochromic',
  pl: 'Filters.item_lensproperties_polarized',
}

type Props = {
  cartProducts: CartProduct[]
  moco: Moco
  model: Product
  wishlistProducts: WishlistProduct[]
  brand: Brand
}

const ProductItemTable: React.FC<Props> = ({
  model,
  brand,
  cartProducts,
  moco,
  wishlistProducts,
}) => {
  const { t } = useTranslation()
  const {
    colorCode,
    frontFamilyColor,
    lensProperties,
    modelCode,
    sizes,
    srPrice,
    whsPrice,
    enabledDoors,
  } = moco
  const { styleName } = model

  const cartEditable = useSelector(cartEditableSelector)

  const purchaseControls = Object.values(sizes)
    .sort((a, b) => (Number(a.size) < Number(b.size) ? -1 : 1))
    .map(size => {
      const itemsSizeInWishlist = wishlistProducts.filter(wlp => wlp.size === size.size)

      return (
        <PurchaseControls
          key={size.upc}
          productSizes={[{ ...size, enabledDoors }]}
          cartEditable={false}
          showWishlist={true}
          itemsInWishlist={itemsSizeInWishlist}
          cartProducts={cartProducts}
          brandCode={brand.code}
          disableWishlist={!cartEditable}
        />
      )
    })
  const release = Object.values(sizes)[0].release

  return (
    <Wrapper className="product-item-table">
      <Info className="product-item-info">
        <h2 className="product-name">
          {modelCode} {colorCode}{' '}
          {frontFamilyColor &&
            frontFamilyColor.label &&
            `- ${frontFamilyColor && frontFamilyColor.label}`}{' '}
        </h2>
        {styleName && <StyleName className="product-style-name">{styleName.label}</StyleName>}
      </Info>

      <Info className="product-item-info">
        <Price currency={brand.currency} whsPrice={whsPrice} srPrice={srPrice} variant="vertical" />
      </Info>

      <AddWishlist className="product-page-addWishlist">
        <ReleaseAndIcons className="product-item-release-and-icons">
          {release && <Release className="release">{release}</Release>}
          <StyledProductIcons
            moco={moco}
            hasRTR={model.hasRTR}
            hiddenIcons={
              isWallDevice() ? ['adv', 'discontinued'] : ['vto', 'rtr', 'discontinued', 'ideal']
            }
          >
            {lensProperties && t(lensPropertiesDict[lensProperties.id.toLowerCase()]) && (
              <Tecnology className="technology">
                {t(lensPropertiesDict[lensProperties.id.toLowerCase()])}
              </Tecnology>
            )}
          </StyledProductIcons>
        </ReleaseAndIcons>

        <ListOfPurchaseControls className="list-of-purchase-controls">
          {purchaseControls}
        </ListOfPurchaseControls>
      </AddWishlist>
    </Wrapper>
  )
}

export default ProductItemTable
