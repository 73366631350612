import styled, { css } from 'styled-components'
import { palette } from '../../style/theme'

export const TurquoiseNotification = styled.div`
  display: inline-block;
  width: auto;
  color: ${palette.white};
  font-size: 14px;
  background-color: ${palette.turquoise};
  border-radius: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`

export const NotificationGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const notificationPadding = '10px 20px'

export const column1Style = css`
  text-transform: uppercase;
  font-weight: bold;
  padding-right: 20px;
  padding: ${notificationPadding};
`

export const NotificationColumn1 = styled.div`
  ${column1Style}
  display: flex;
`

export const NotificationButton = styled.button`
  background: transparent;
  border-color: transparent;
`

export const NotificationCloseButton = styled(NotificationButton)`
  color: ${palette.black};
  padding: ${notificationPadding};
`
