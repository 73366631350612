import { DislikeApi } from '../../api/restApi'
import { extractModelCodeFromMocoCode } from '../../libs/productsV2'
import { getDeviceFromUrl, getRoomFromUrl } from '../../libs/url'
import { AppThunk } from '../../model/model'
import { dataLoadedFail } from '../app/actions'
import { cartProductsSelector } from '../cart/selectors'
import {
  addNotificationAction,
  removeOrphanDislikeNotificationAction,
  showNotificationsAction,
} from '../notifications/actions'
import { slice } from './slice'

export const setDislikeList = (
  dislikes: { id: string; items: string[] },
  addedItems = [],
): AppThunk => {
  return dispatch => {
    dispatch(removeOrphanDislikeNotificationAction(dislikes.items))
    if (addedItems.length) {
      dispatch(showNotificationsAction('dislikes'))
    }
    dispatch(slice.actions.loadDislikeSuccess(dislikes))
  }
}

export const toggleDislikeListAction = (
  items: { mocoCode: string; upcs: string[]; modelCode: string | undefined }[],
): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()
    const cartProducts = cartProductsSelector(state)
    const {
      dislike: { listId },
    } = state
    const {
      app: { eventId, customerId, lang },
      auth: { token },
    } = state

    if (!listId) {
      return // dislike list not loaded
    }

    const notInCartItems = items.filter(
      item =>
        !cartProducts.find(({ mocoCode }: { mocoCode: string }) => mocoCode === item.mocoCode),
    )
    const itemsToAdd = notInCartItems.filter(item => !state.dislike.items.includes(item.mocoCode))

    if (itemsToAdd.length) {
      const isHeaderDislike = itemsToAdd.length > 1
      const addedMocoCodes = itemsToAdd.map(i => i.mocoCode)
      const notificationMessage = isHeaderDislike
        ? itemsToAdd[0].modelCode || extractModelCodeFromMocoCode(itemsToAdd[0].mocoCode)
        : `${itemsToAdd.map(i => i.mocoCode.replace('__', ' ')).join('')}`
      dispatch(
        addNotificationAction({
          codes: addedMocoCodes,
          message: notificationMessage,
          notificationType: 'dislikes',
        }),
      )
    }

    const apiPayload = {
      wcsToken: token,
      lang,
      customerId,
      eventId,
      dislikeListId: listId,
      items: notInCartItems,
      room: getRoomFromUrl(),
      device: getDeviceFromUrl(),
    }

    if (!token) {
      return Promise.reject('missing token')
    }

    const dislikeApi = new DislikeApi(token, lang)
    dislikeApi
      .toggle(apiPayload)
      .then(response => {
        const { dislikeList, addedItems } = response

        dispatch(setDislikeList(dislikeList, addedItems))

        return response
      })
      .catch(error => {
        return dispatch(dataLoadedFail(error))
      })
  }
}

export const loadDislikeSuccess = slice.actions.loadDislikeSuccess

export default {
  ...slice.actions,
  setDislikeList,
  toggleDislikeListAction,
}
