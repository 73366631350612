import React, { useCallback } from 'react'
import styled from 'styled-components/macro'

import { useSearchParams } from '../../hooks/useSearchParams'
import { BrandCategorySport } from '../../model/afa'
import { getFluidSizeWithFullFormula, palette, pxToRem } from '../../style/theme'
import { useTranslation } from 'react-i18next'
import { formatName } from '../../helpers/genericHelper'

const Wrapper = styled.div`
  color: ${palette.tangaroa};

  &:not(:last-of-type)::after {
    content: '';
    display: block;
    border-bottom: 1px solid ${palette.platinum};
    margin-top: ${pxToRem(16)}rem;
    margin-bottom: ${pxToRem(24)}rem;
  }
`

const Title = styled.div`
  font-size: ${getFluidSizeWithFullFormula('px', 18, 24, 1366, 3840)};
  margin-bottom: ${pxToRem(12)}rem;
  text-transform: capitalize;
  font-family: GilmerLight, sans-serif;
`

const Keylooks = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(12)}rem;
`

const Keylook = styled.div`
  font-size: ${getFluidSizeWithFullFormula('px', 14, 24, 1366, 3840)};
  cursor: pointer;

  &.active {
    color: ${palette.shadowBlue};
  }
`

type Props = {
  sport: BrandCategorySport
}

const AfaKeylooksSidebarSport: React.FC<Props> = ({ sport }) => {
  const { t } = useTranslation()

  const [searchParams, setSearchParams] = useSearchParams()

  const keylookParam = searchParams.get('keylook')

  const setSelectedKeylook = useCallback(
    (keylookCode: string) => {
      searchParams.set('keylook', keylookCode)
      setSearchParams(searchParams, { replace: true })
    },
    [setSearchParams, searchParams],
  )

  return (
    <Wrapper>
      <Title>
        {t(
          `Afa.${
            sport.name.toLowerCase() === 'theme' ? 'CollectionThemes' : sport.name.toLowerCase()
          }`,
        )}
      </Title>
      <Keylooks>
        {sport.keylooks.map(({ code, name }) => (
          <Keylook
            key={code}
            onClick={() => setSelectedKeylook(code)}
            className={keylookParam === code ? 'active' : ''}
          >
            {formatName(name)}
          </Keylook>
        ))}
      </Keylooks>
    </Wrapper>
  )
}

export default AfaKeylooksSidebarSport
