import map from 'mout/object/map'

import { FacetsAPI } from '../../api/restApi'
import { requestCalculateFacetsFromCart } from '../../libs/calculate-cart-facets'
import { isActiveBrandInFilteredCart } from '../../libs/cart'
import { convertSelectedFiltersIntoQueryParams, getSelectedFilters } from '../../libs/filters'
import { handleErrors } from '../../libs/handleError'
import { toggleInArray } from '../../libs/utils'
import {
  CartFilters,
  FilterData,
  FilterType,
  PlpFilters,
  WishlistFilters,
} from '../../model/filters'
import { AppThunk, RootState } from '../../model/model'
import { disableKidCategoryMode } from '../app/actions'
import { customerIdSelector, eventIdSelector, languageSelector } from '../app/selectors'
import { tokenSelector } from '../auth/selectors'
import { brandsSelector, starsBrandsSelector } from '../brands/selectors'
import { setActiveBrandAction, updateAnalytics } from '../cart/actions'
import {
  activeBrandCodeSelector,
  cartBrandsSelector,
  cartProductsDetailsSelector,
  cartProductsFilteredByActiveDoorsSelector,
} from '../cart/selectors'
import couvetteActions from '../couvettes/actions'
import { customerTypeSelector } from '../customer/selectors'
import { isCartPageSelector } from '../showButtonsSelectors'
import {
  cartModeSelector,
  selectedMonthSelector,
  starsAllProductsDetailsSelector,
  starsAllStarProductsSelector,
} from '../stars/selectors'
import {
  filterActivePageNameSelector,
  filtersForActivePageSelector,
  isSegmentSelectedSelector,
  plpFiltersSelector,
  selectedCartFiltersSelector,
} from './selectors'
import { slice } from './slice'

export const applyFilters = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()
    const selectedBrands = state.filters[state.filters.pageType].brand_slug.selected
    const activeBrands = state.filters[state.filters.pageType].brand_slug.active

    // we have to disable kid category mode if from the brands panel we select more than 1 brand, or if we select a different brand from the active one.
    const needDisableKidCategoryMode =
      selectedBrands.length > 1 ||
      (activeBrands.length === 1 && selectedBrands[0] !== activeBrands[0])
    if (needDisableKidCategoryMode) {
      dispatch(disableKidCategoryMode())
    }

    dispatch(slice.actions._applyFilters())

    if (!isCartPageSelector()) {
      return
    }

    dispatch(updateAnalytics())
    const updatedState = getState()
    const cartBrands = cartBrandsSelector(updatedState)
    const activeBrand = activeBrandCodeSelector(updatedState)

    if (!isActiveBrandInFilteredCart(cartBrands, activeBrand)) {
      const newActiveBrand = (cartBrands.length > 0 && cartBrands[0].brand.code) || null
      dispatch(setActiveBrandAction(newActiveBrand))
    }
  }
}

// export for test puroposes only TODO: test only exposed functions
export const _getToggeledFilters = (
  filters: CartFilters | PlpFilters | WishlistFilters,
  filterKey: keyof (CartFilters & PlpFilters & WishlistFilters),
  filterOptionIds: string | string[],
  type: FilterType,
  areSelected?: boolean,
) => {
  const filterEntry = Object.entries(filters).find(([key]) => key === filterKey)
  if (!filterEntry) {
    return filters
  }
  const [key, filterItem] = filterEntry

  const safeArrayFilterOptionsIds: string[] = ([] as string[]).concat(filterOptionIds)
  const updatedSelected =
    type === 'checkbox'
      ? safeArrayFilterOptionsIds.reduce((selected, filterOptionId) => {
          const isSelected =
            areSelected !== undefined ? areSelected : selected.includes(filterOptionId)
          return toggleInArray(selected, filterOptionId, isSelected)
        }, filterItem.selected)
      : safeArrayFilterOptionsIds

  const updatedFilters = {
    ...filters,
    [key]: {
      ...filterItem,
      selected: updatedSelected,
    },
  }
  return updatedFilters
}

const getUpdatedFacets = (
  state: RootState,
  updatedFilters: CartFilters | PlpFilters | WishlistFilters,
) => {
  const eventId = eventIdSelector(state)
  const customerId = customerIdSelector(state)
  // this function is called by resetFilters that triggers also when eventId || customerId can be undefined
  if (!eventId || !customerId) {
    return Promise.resolve([])
  }

  const selectedFilters = getSelectedFilters(updatedFilters)
  const activeFiltersAsQueryString = convertSelectedFiltersIntoQueryParams(selectedFilters, state)

  const token = tokenSelector(state)
  if (!token) {
    return Promise.reject('missing token')
  }

  const customerType = customerTypeSelector(state)
  const catalog = cartModeSelector(state) === 'stars' ? 'STARS' : 'WHOLESALE'
  const selectedMonth = selectedMonthSelector(state)

  const lang = languageSelector(state)
  const assortmentApi = new FacetsAPI(token, lang, customerId, eventId)
  return assortmentApi
    .facets(
      `${activeFiltersAsQueryString}&customerType=${customerType}&catalog=${catalog}${
        catalog === 'STARS' ? `&month=${selectedMonth}&month_to=${selectedMonth}` : ''
      }`,
    )
    .then(handleErrors)
}

const updatePlpFiltersCounters = (
  updatedFilters: CartFilters | PlpFilters | WishlistFilters,
): AppThunk => (dispatch, getState) =>
  getUpdatedFacets(getState(), updatedFilters).then(facets =>
    dispatch(slice.actions.updateFiltersCounters({ facets: facets, pageType: 'plp' })),
  )

export const rebuildFiltersCounters = (): AppThunk => (dispatch, getState) => {
  const state = getState()
  const cartProducts = cartProductsFilteredByActiveDoorsSelector(state)
  const cartProductDetails = cartProductsDetailsSelector(state)
  const starsProducts = starsAllStarProductsSelector(state)
  const starsProductDetails = starsAllProductsDetailsSelector(state)
  const cartMode = cartModeSelector(state)
  const products = cartMode === 'stars' ? starsProducts : cartProducts
  const productsDetails = cartMode === 'stars' ? starsProductDetails : cartProductDetails
  const filters = selectedCartFiltersSelector(state)
  return requestCalculateFacetsFromCart(products, productsDetails, filters).then(facet => {
    dispatch(slice.actions.updateFiltersCounters({ facets: facet, pageType: 'cart' }))
  })
}

const updateFiltersAndCounters = (
  updatedFilters: CartFilters | PlpFilters | WishlistFilters,
): AppThunk => (dispatch, getState) => {
  const activePageName = filterActivePageNameSelector(getState())

  dispatch(slice.actions.updateFilters({ filters: updatedFilters, pageType: activePageName }))
  const action =
    activePageName === 'cart' ? rebuildFiltersCounters() : updatePlpFiltersCounters(updatedFilters)
  return dispatch(action)
}

export const initPlpFiltersCounters = (): AppThunk => (dispatch, getState) => {
  const state = getState()
  const plpFilters = plpFiltersSelector(state)
  dispatch(updatePlpFiltersCounters(plpFilters))
}

export const initFiltersCounters = (): AppThunk => (dispatch, getState) => {
  const activePageName = filterActivePageNameSelector(getState())
  const action = activePageName === 'cart' ? rebuildFiltersCounters() : initPlpFiltersCounters()
  dispatch(action)
}

export const toggleFilters = (
  filterKey: keyof CartFilters,
  filterOptionIds: string | string[],
  type: FilterType = 'checkbox',
  areSelected?: boolean,
): AppThunk => (dispatch, getState) => {
  const state = getState()
  const filtersForActivePage = filtersForActivePageSelector(state)

  const updatedFilters = _getToggeledFilters(
    filtersForActivePage,
    filterKey,
    filterOptionIds,
    type,
    areSelected,
  )

  return dispatch(updateFiltersAndCounters(updatedFilters))
}

export const toggleSegmentFilterAction = (segmentId: string): AppThunk => (dispatch, getState) => {
  const state = getState()
  const brands = brandsSelector(state)
  const starsBrands = starsBrandsSelector(state)
  const cartMode = cartModeSelector(state)
  const modeBrands = cartMode === 'units' ? brands : starsBrands
  const isSegmentSelected = isSegmentSelectedSelector(state, segmentId)
  const toggleBrands = modeBrands
    .filter(({ segment }) => segment === segmentId)
    .map(({ slug }) => slug)
  dispatch(toggleFilters('brand_slug', toggleBrands, 'checkbox', isSegmentSelected))
  dispatch(toggleFilters('segment', [segmentId], 'checkbox', isSegmentSelected))
}

const _getResettedFilters = (
  filters: CartFilters | PlpFilters | WishlistFilters,
  filtersToExclude: string[],
  resetActive: boolean,
) => {
  const keysToExclude = filtersToExclude.concat(['segment', 'category'])
  const resettedFilters = map(filters, (filterItem: FilterData, key: string) =>
    !keysToExclude.includes(key) && filterItem.selected && filterItem.active
      ? {
          ...filterItem,
          active: resetActive ? [] : filterItem.active,
          selected: [],
          counter: map(filterItem.counter, () => null),
        }
      : filterItem,
  )

  return resettedFilters
}

const _resetFiltersAction = (
  filtersToExclude: string[] = [],
  considerSelected: boolean,
  updateCountersOnly = false,
): AppThunk => (dispatch, getState) => {
  const filtersForActivePage = filtersForActivePageSelector(getState())
  const activePageName = filterActivePageNameSelector(getState())
  if (activePageName === 'cart' && updateCountersOnly === true) {
    return dispatch(updateFiltersAndCounters(filtersForActivePage))
  }

  if (activePageName === 'plp') {
    filtersToExclude.push('brand_slug')
  }
  const updatedFilters = _getResettedFilters(
    filtersForActivePage,
    filtersToExclude,
    !considerSelected,
  )
  return dispatch(updateFiltersAndCounters(updatedFilters))
}

export const resetFiltersAction = (filtersToExclude: (keyof CartFilters)[] = []) =>
  _resetFiltersAction(filtersToExclude, true)

export const fullResetFiltersAction = (
  filtersToExclude = [] as string[],
  updateCountersOnly = false,
) => _resetFiltersAction(filtersToExclude, false, updateCountersOnly)

export const resetPlpFiltersAction = (): AppThunk => (dispatch, getState) => {
  dispatch(slice.actions.resetFilters('plp'))
  const updatedState = getState()
  const updatedPlpFilters = plpFiltersSelector(updatedState)
  dispatch(couvetteActions.resetSortBy())
  dispatch(updatePlpFiltersCounters(updatedPlpFilters))
  // TODO: questo era il fix a
  // https://abstractsrl.atlassian.net/browse/RED-4280
  // ma non ha senso perché facciamo una chiamata generale alle couvette senza brand, quindi viene giù non si sa cosa, inoltre partono 3
  // dispatch(couvetteActions.sortCouvetteBy('release'))
}

export const _getResettedSegmentFilters = (
  filters: CartFilters | PlpFilters | WishlistFilters,
): CartFilters | PlpFilters | WishlistFilters => {
  return {
    ...filters,
    brand_slug: {
      ...filters.brand_slug,
      selected: [],
      active: [],
    },
    segment: {
      ...('segment' in filters ? filters.segment : { active: [], counter: {}, options: [] }),
      selected: [],
    },
  }
}

export const resetSegmentFiltersAction = (): AppThunk => (dispatch, getState) => {
  const state = getState()
  const updatedFilters = _getResettedSegmentFilters(state.filters.plp)
  return dispatch(updateFiltersAndCounters(updatedFilters))
}

export const applySegmentFilter = slice.actions.applySegmentFilter
export const resetFilters = slice.actions.resetFilters
export const updateFilters = slice.actions.updateFilters
export const updateFiltersCounters = slice.actions.updateFiltersCounters
export const preselectBrandsAction = slice.actions.preselectBrandsAction
export const setCategoryFilter = slice.actions.setCategoryFilter
export const setFiltersActivePageName = slice.actions.setFiltersActivePageName
export const toggleBrandsBarAction = slice.actions.toggleBrandsBarAction

const filtersActions = {
  ...slice.actions,
  applyFilters,
  initPlpFiltersCounters,
  toggleFilters,
  toggleSegmentFilterAction,
  rebuildFiltersCounters,
  resetFiltersAction,
  fullResetFiltersAction,
  resetPlpFiltersAction,
  resetSegmentFiltersAction,
}

export default filtersActions
