import styled from 'styled-components'
import { breakpoints, getFluidSizeWithFullFormula, palette } from '../../../../style/theme'
import Backdrop from '../../../../styleguide/Backdrop'

export const Wrapper = styled.div`
  float: right;
  height: 100%;
  padding-top: ${getFluidSizeWithFullFormula('px', 40, 114, 1366, 3840)};
  padding-left: ${getFluidSizeWithFullFormula('px', 32, 114, 1366, 3840)};
  padding-bottom: ${getFluidSizeWithFullFormula('px', 18, 116, 1366, 3840)};
  padding-right: ${getFluidSizeWithFullFormula('px', 32, 114, 1366, 3840)};
  display: flex;
  flex-direction: column;
  background-color: ${palette.tangaroa};
  color: ${palette.white};
  position: relative;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px gray;
  padding: 1rem 0;
  font-size: ${getFluidSizeWithFullFormula('px', 28, 40, 1366, 3840)};
`

export const Body = styled.div`
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  font-size: ${getFluidSizeWithFullFormula('px', 16, 24, 1366, 3840)};
  gap: ${getFluidSizeWithFullFormula('px', 16, 44, 1366, 3840)};
`

export const SortBackdrop = styled(Backdrop)`
  @media (min-width: ${breakpoints.XL}) {
    left: 250px;

    &.is-open {
      > div {
        transform: translateX(0) !important;
      }
    }

    & > div {
      right: unset !important;
      top: unset !important;
      left: 0 !important;
      bottom: 0 !important;
      transform: translateX(-100%) !important;
    }
  }

  & > div {
    left: unset;
    right: 0;
    top: 0;
    bottom: unset;
    transform: translateX(100%);

    .is-open & {
      transform: translateX(0);
    }
  }
`
