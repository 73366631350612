import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import Loading from '../components/Loading'
import PageSocketManager, { PageSocketManagerProps } from '../components/PageSocketManager'
import app_config from '../config/app/config'
import { isAuthenticated, setPostLoginRedirectUri } from '../libs/auth'
import { isTableDevice } from '../libs/url'
import {
  appLoadingSelector,
  deviceNameSelector,
  roomNameSelector,
  roomTypeSelector,
} from '../store/app/selectors'
import { authErrorSelector, tokenSelector } from '../store/auth/selectors'

export const LoginPage: React.FC<PageSocketManagerProps> = ({ emitEvent }) => {
  const appLoading = useSelector(appLoadingSelector)
  const authError = useSelector(authErrorSelector)
  const token = useSelector(tokenSelector)
  const roomName = useSelector(roomNameSelector)
  const deviceName = useSelector(deviceNameSelector)
  const roomType = useSelector(roomTypeSelector)

  useEffect(() => {
    if (isTableDevice()) {
      emitEvent('open_login_page')
    }
  }, [emitEvent])

  if (!authError && appLoading) {
    return <Loading />
  }

  if (isAuthenticated(token)) {
    return <Redirect to={'/events'} />
  } else {
    setPostLoginRedirectUri(roomName, deviceName, roomType)
    window.location.href = app_config.loginUrl as string
    return null
  }
}

export default PageSocketManager(LoginPage)
