import React, { SVGProps } from 'react'

const ArrowDownWithBody: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    {...props}
  >
    <path
      d="M7.996 16a.816.816 0 0 0 .524-.203l7.238-6.856a.766.766 0 0 0 .03-1.078.792.792 0 0 0-1.078-.03l-5.952 5.631V.762a.762.762 0 0 0-1.524 0v12.702l-5.952-5.63c-.29-.275-.793-.251-1.077.03-.294.29-.26.802.03 1.077l7.237 6.856c.185.181.343.191.524.203z"
      fill="#fff"
    />
  </svg>
)

export default ArrowDownWithBody
