import { CatalogImages, CatalogImagesDegrees, CatalogImagesPi } from '../model/product'
import { isIPadView } from './url'
import { CartProduct } from '../model/cart'

export const getProductImageUrl = ({
  path,
  imwidth = 600,
  scale,
}: {
  path: string
  imwidth?: number
  scale?: boolean
}) => {
  // scale directly proportional to innerWidth but round to nearest hundred
  const imwidthScaled = scale
    ? Math.round((window.innerWidth * imwidth) / 1366 / 100) * 100
    : imwidth
  return `https://redcarpet-im2.luxottica.com/cdn-record-files-pi/${path}?imwidth=${imwidthScaled}`
}
export const getProductImageAngledWithFallback = ({
  catalogImages,
  angle,
  imwidth = 300,
  shadow,
}: {
  catalogImages?: CatalogImages | CartProduct
  angle: 'quarter' | 'front'
  imwidth?: number
  shadow?: boolean
}) => {
  const getPath = () => {
    if (!catalogImages) {
      return ''
    }
    if ('pi21' in catalogImages && catalogImages.pi21?.[angle]) {
      return shadow ? catalogImages.pi21[angle].shadow : catalogImages.pi21[angle].base
    } else if ('pi20' in catalogImages && catalogImages.pi20?.[angle]) {
      return shadow ? catalogImages.pi20[angle].shadow : catalogImages.pi20[angle].base
    } else {
      const degree = angle === 'quarter' ? '030' : '000'
      if ('degrees' in catalogImages && catalogImages.degrees?.[degree]) {
        return catalogImages.degrees[degree]
      } else {
        return 'base' in catalogImages ? catalogImages.base || '' : catalogImages.urlImage || ''
      }
    }
  }

  const productPath = getPath()
  const url = getProductImageUrl({ path: productPath, imwidth })
  return url
}
export const getDegrees = (degrees?: CatalogImagesDegrees, fullscreen = false) => {
  const imwidth = isIPadView() ? (fullscreen ? 1500 : 890) : 1500
  const allDegrees = [
    '000',
    '030',
    '060',
    '090',
    '120',
    '150',
    '180',
    '210',
    '240',
    '270',
    '300',
    '330',
  ] as (keyof CatalogImagesDegrees)[]
  const images = allDegrees.map(degree => {
    const url = getProductImageUrl({ path: degrees?.[degree] || '', imwidth })
    return {
      preview: url,
      file: url,
      angle: parseInt(degree),
    }
  })
  return images
}
export const getPi = (pi?: CatalogImagesPi, fullscreen = false, shadow = true) => {
  const imwidth = isIPadView() ? (fullscreen ? 1500 : 890) : 1500
  const sortOder = [
    'quarter',
    'lateral',
    'back',
    'front',
    'closedFront',
  ] as (keyof CatalogImagesPi)[]
  const sortedPi = sortOder.map(degree => {
    const path = shadow ? pi?.[degree].shadow || '' : pi?.[degree].base || ''
    const url = getProductImageUrl({ path: path, imwidth })
    return {
      preview: url,
      file: url,
    }
  })
  return sortedPi
}
