import styled from 'styled-components'

interface Props {
  gutter?: number
  [key: string]: any
}

const Row = styled.div`
  position: relative;
  display: flex;
  margin-left: ${({ gutter = 0 }: Props) => gutter / -2}px;
  margin-right: ${({ gutter = 0 }: Props) => gutter / -2}px;

  > * {
    padding-left: ${({ gutter = 0 }: Props) => gutter / 2}px;
    padding-right: ${({ gutter = 0 }: Props) => gutter / 2}px;
  }
`

export default Row
