import QRCodeBase from 'qrcode.react'
import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'

import { palette } from '../../style/theme'
import { useDispatch, useSelector } from 'react-redux'
import { virtualMirrorConfigSelector } from '../../store/pdp/selectors'

export const QRCodeWrapper = styled.div`
  text-align: center;
  height: 100%;
`

export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  width: auto;
`
export const QRCodeBaseWrapper = styled.div<{
  height?: number
}>`
  height: 100%;
  display: flex;
  align-items: center;

  canvas {
    height: ${({ height }) => height}px;
    padding: 16px;
    max-height: 100%;
    max-width: 100vw;
    border: solid 1px ${palette.gallery};
  }

  div[size='160'] {
    height: unset;
    width: unset;
  }
`

export const StyledQRCode = styled(QRCodeBase)`
  padding: 16px;
  max-height: 100%;
  max-width: 100vw;
  border: solid 1px ${palette.gallery};
`

type Props = {
  url?: string
  size: number
  className?: string
  upc?: string
}

const QRCode: React.FC<Props> = ({ upc, url, size, className }) => {
  const dispatch = useDispatch()
  const configData = useSelector(virtualMirrorConfigSelector)

  const vmapp = useMemo(() => {
    if (Object.keys(configData).length > 0 && configData.products.length > 0) {
      const qrConfig = {
        ...configData,
        selector: '#vmmv-qr-container',
        products: configData.products.filter((product: any) => product.upc === upc),
      }
      const vm = new window.vmmv.VMWidgetQRCode(qrConfig)
      return vm
    }
  }, [configData, upc])

  useEffect(() => {
    if (vmapp?.root) {
      // eslint-disable-next-line
      console.info('vm qr init')
      vmapp.init()
    }

    return () => {
      if (vmapp?.root) {
        // eslint-disable-next-line
        console.info('vm qr unmount')
        vmapp.unmount()
      }
    }
  }, [dispatch, vmapp])

  return (
    <QRCodeWrapper className={className}>
      <Container>
        <QRCodeBaseWrapper height={size} data-url={url} id={!url ? 'vmmv-qr-container' : undefined}>
          {url && <StyledQRCode height={size} width={undefined} value={url} renderAs={'svg'} />}
        </QRCodeBaseWrapper>
      </Container>
    </QRCodeWrapper>
  )
}

export default QRCode
