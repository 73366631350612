import React from 'react'
import styled from 'styled-components'
import { palette } from '../../style/theme'

const Message = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: ${palette.white};
  font-size: 32px;
`
const NoAppointmentsMessage = () => {
  return <Message>No Appointments</Message>
}

export default NoAppointmentsMessage
