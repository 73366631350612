import styled, { css } from 'styled-components/macro'
import {
  breakpointsCross,
  getFluidSizeWithFullFormula as gF,
  palette,
  pxToRem,
} from '../../../../../style/theme'

export const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Done = styled.button`
  margin-left: ${pxToRem(40)}rem;
  font-size: ${gF('px', 13, 24, 1366, 3840)};
  font-family: GilmerBold, sans-serif;
  color: ${palette.white};
  background-color: ${palette.tangaroa};
  border-radius: 4px;
  padding: 8px 21px;
`

export const BoardsBarWrapper = styled.div<{ sidebarOpen?: boolean }>`
  position: relative;
  left: 0;

  @media screen and (max-width: ${breakpointsCross.XL.max}) {
    ${({ sidebarOpen }) =>
      sidebarOpen &&
      css`
        left: ${gF('px', 177, 550, 768, 3840)};
      `}
  }

  @media screen and (min-width: ${breakpointsCross.XL.min}) {
    left: 550px;
  }

  transition: 0.3s left linear;
`

export const Actions = styled.div<{ selectedAction?: number | null; sidebarOpen?: boolean }>`
  background-color: white;
  color: ${palette.tangaroa};
  font-family: 'GilmerMedium', sans-serif;
  border-radius: ${gF('px', 32, 80, 1366, 3840)};
  box-shadow: 0 4px 4px 0 rgba(3, 20, 52, 0.17);
  border: 1px solid #dee0e5;
  display: inline-flex;
  cursor: pointer;
  position: relative;
  transition: 0.3s left linear;
  left: 0;

  @media screen and (max-width: ${breakpointsCross.XL.max}) {
    ${({ sidebarOpen }) =>
      sidebarOpen &&
      css`
        left: ${gF('px', 177, 550, 768, 3840)};
      `}
  }

  @media screen and (min-width: ${breakpointsCross.XL.min}) {
    left: 550px;
  }

  > * {
    font-family: 'GilmerMedium', sans-serif;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: ${gF('px', 25, 64, 1366, 3840)};
    padding: ${gF('px', 13, 26, 1366, 3840)} ${gF('px', 28, 63, 1366, 3840)};

    & span {
      font-size: ${gF('px', 12, 24, 1366, 3840)};
      display: flex;
      justify-content: center;
      align-items: center;

      & img {
        width: ${gF('px', 16, 48, 1366, 3840)};
        height: ${gF('px', 16, 48, 1366, 3840)};
      }
    }

    &.multiselectEnabled {
      pointer-events: none;
    }

    &.disabled {
      color: #dee0e5;
      cursor: default !important;
      pointer-events: none;
    }

    &:hover {
      background-color: #dee0e5;
    }

    &.selected {
      background-color: ${palette.shadowBlue};
      color: white;
      position: relative;
    }
    &:first-of-type {
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }

    &:last-of-type {
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }

    &:not(:last-child) {
      border-right: 1px solid #dee0e5;
    }
  }
`

export const ButtonContainer = styled.div`
  &:hover {
    background-color: transparent;
  }

  span.disabled {
    filter: opacity(0.3);
    cursor: not-allowed;

    img {
      pointer-events: none;
    }
  }
`

export const Menu = styled(Actions)<{ variant?: string }>`
  position: absolute;
  bottom: ${gF('px', 48, 108, 1024, 3840)};
  border-radius: ${pxToRem(12)}rem;
  width: max-content;
  left: unset;
  right: 0;

  div {
    gap: 5px;
    img {
      width: 20px;
    }
  }

  ${({ variant }) =>
    variant === 'vertical' &&
    css`
      flex-direction: column;
      height: auto;

      > * {
        padding: ${pxToRem(12)}rem ${pxToRem(24)}rem;
        justify-content: left;
        border: none !important;

        &:first-of-type {
          border-top-left-radius: inherit;
          border-top-right-radius: inherit;
          border-bottom-left-radius: unset;
        }

        &:last-of-type {
          border-top-left-radius: unset;
          border-top-right-radius: unset;
          border-bottom-right-radius: inherit;
          border-bottom-left-radius: inherit;
        }
      }
    `}
`

export const MultiselectMenu = styled(Actions)<{ variant?: string }>`
  position: absolute;
  bottom: ${gF('px', 48, 108, 1024, 3840)};
  border-radius: ${pxToRem(12)}rem;
  width: max-content;
  padding: ${gF('px', 13, 18, 1366, 3840)} ${gF('px', 28, 63, 1366, 3840)};
  height: auto;

  > div {
    padding: 0;

    &:hover {
      background-color: transparent !important;
    }
  }
`

export const InputFileButton = styled.div`
  label {
    font-size: ${gF('px', 10.3411, 24, 1024, 3840)} !important;
    cursor: pointer;
  }
  input {
    display: none;
  }
`
