import {
  DuplicateInfo,
  WrapperRow,
  Option,
  Wrapper,
  TopDiv,
  ListWrapper,
  ListShadow,
  StyledList,
} from '../AfaDuplicateCartOnDoorsModal'
import { useTranslation } from 'react-i18next'
import {
  ButtonCancel,
  ButtonConfirm,
  ButtonsGroup,
  ModalTitle,
} from '../../../../components/AfaCommonComponents'
import React, { useMemo, useState } from 'react'
import AfaDuplicateCartSearchBar from '../components/AfaDuplicateCartSearchBar'
import filterDoors from '../helpers/filterDoors'
import { useGetDoorsWithProducts } from '../../../../services/afaCart'
import { useSelector } from 'react-redux'
import { activeDoorsSelector } from '../../../../store/customer/selectors'

type Props = {
  sourceDoorIdData: string
  setSourceDoorIdData: React.Dispatch<React.SetStateAction<string>>
  setSourceDoorId: React.Dispatch<React.SetStateAction<string>>
  setDestinationDoorIds: React.Dispatch<React.SetStateAction<string[]>>
  onClose: () => void
  listCSS: {
    itemSize: number
    listHeight: number
    scrollbarColor: string
    scrollbarBackgroundColor: string
  }
}
const FirstPageAfaDuplicate: React.FC<Props> = ({
  sourceDoorIdData,
  setSourceDoorIdData,
  setSourceDoorId,
  setDestinationDoorIds,
  onClose,
  listCSS,
}) => {
  const doors = useSelector(activeDoorsSelector)
  const { t } = useTranslation()
  const [doorSearch, setDoorSearch] = useState('')

  const { doorIdsWithProducts } = useGetDoorsWithProducts()

  const filteredDoors = useMemo(() => {
    return doors.filter(door => doorIdsWithProducts.includes(door.id))
  }, [doorIdsWithProducts, doors])

  const sortedFilteredDoors = filterDoors(filteredDoors, doorSearch).sort((door1, door2) => {
    if (door1.id < door2.id) {
      return -1
    }
    if (door1.id > door2.id) {
      return 1
    }
    return 0
  })

  return (
    <Wrapper>
      <TopDiv>
        <ModalTitle>{t('Afa.duplicateOrder')}</ModalTitle>
        <DuplicateInfo>{t('Afa.selectDoorFromDuplicate')}:</DuplicateInfo>
        <AfaDuplicateCartSearchBar doorSearch={doorSearch} setDoorSearch={setDoorSearch} />
        <ListWrapper>
          <StyledList
            height={listCSS.listHeight}
            width={'100%'}
            itemCount={sortedFilteredDoors.length}
            itemSize={listCSS.itemSize}
            scrollbarColor={listCSS.scrollbarColor}
            scrollbarBackgroundColor={listCSS.scrollbarBackgroundColor}
          >
            {({ index, style }) => {
              const door = sortedFilteredDoors[index]

              return (
                <WrapperRow key={`${door.id}-${index}`} style={style}>
                  <Option>
                    <input
                      type="checkbox"
                      name="source"
                      id={`source-${door.id}`}
                      onChange={() => setSourceDoorIdData(door.id)}
                      checked={sourceDoorIdData === door.id}
                    />
                    <label htmlFor={`source-${door.id}`}>
                      {door.id} - {door.name}
                    </label>
                  </Option>
                </WrapperRow>
              )
            }}
          </StyledList>
          <ListShadow />
        </ListWrapper>
      </TopDiv>
      <ButtonsGroup>
        <ButtonCancel onClick={() => onClose()}>
          {t('Checkout.ImportExport.ModalCancel')}
        </ButtonCancel>
        <ButtonConfirm
          disabled={!sourceDoorIdData}
          onClick={() => {
            if (!sourceDoorIdData) {
              throw new Error('no sourceDoorIdData')
            }
            setSourceDoorId(sourceDoorIdData)
            if (filteredDoors.length === 2) {
              const destinationDoor = filteredDoors.find(x => x.id !== sourceDoorIdData)
              setDestinationDoorIds([destinationDoor?.id || ''])
            }
          }}
        >
          {t('Checkout.Footer.Next')}
        </ButtonConfirm>
      </ButtonsGroup>
    </Wrapper>
  )
}

export default FirstPageAfaDuplicate
