import { format } from 'date-fns'
import { useEffect, useRef, useState } from 'react'
import Calendar, { CalendarProps } from 'react-calendar'
import styled, { css } from 'styled-components'
import { getFluidSizeWithFullFormula as gF, palette, pxToRem, spacing } from '../style/theme'

const buttonMonths = css`
  border-radius: 50%;
  min-width: unset;
  font-weight: bold;
  width: ${gF('px', 24, 36, 1366, 3840)};
  height: ${gF('px', 24, 36, 1366, 3840)};
  background-color: ${palette.tangaroa};
  color: ${palette.white};
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    background-color: ${palette.mischka};
    color: ${palette.santasGray};
  }
  &:focus {
    background-color: ${palette.tangaroa};
    color: ${palette.white};
  }
`

const Wrapper = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
`

export const Button = styled.button`
  font-size: ${gF('px', 14, 24, 1366, 3840)};
  width: ${gF('px', 203, 325, 1366, 3840)};
  height: ${gF('px', 42, 68, 1366, 3840)};
  line-height: 1;
  border: solid 1px ${palette.tangaroa};
  border-radius: ${pxToRem(spacing(0.5))}rem;
  background: ${palette.white};
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
  padding-left: ${pxToRem(16)}rem;
  display: flex;
  gap: 0.5rem;
  user-select: none;
  letter-spacing: 0.3px;

  svg {
    width: 0.9em;
    height: 0.9em;
  }
`

const CustomCalendar = styled(Calendar)<{ openingPosition?: string }>`
  font-family: 'GilmerMedium', sans-serif;
  border: none;
  padding: 1em;
  border-radius: 1em;
  box-shadow: rgba(100, 100, 111, 0.2) 0 0.5em 2em 0;
  position: absolute;
  bottom: ${props => (props.openingPosition === 'top' ? '115%' : 'unset')};
  left: 0;
  z-index: 999;
  width: clamp(${pxToRem(252)}rem, 30vw, ${pxToRem(508)}rem);
  margin-top: 10px;

  .react-calendar__navigation {
    height: auto;

    button:focus,
    button:disabled {
      background-color: transparent;
    }

    & button:enabled:hover,
    & button:enabled:focus {
      background-color: ${palette.tangaroa};
    }

    span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
      &:hover {
        background-color: transparent;
      }
    }

    .react-calendar__navigation__label {
      order: 0;
      flex-grow: 0 !important;
      text-decoration: underline;
      font-family: GilmerBold;
      color: ${palette.cloudBurst};
      font-size: ${gF('px', 14, 22, 1366, 3840)};
      padding: 0;
      user-select: none;

      &:hover {
        background-color: transparent;
      }
    }

    .react-calendar__navigation__prev-button {
      ${buttonMonths}
      order: 1;
      margin-left: auto;
    }

    .react-calendar__navigation__next-button {
      ${buttonMonths}
      order: 2;
      margin-left: ${gF('px', 16, 24, 1366, 3840)};
    }

    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }
  }

  .react-calendar__month-view__weekdays {
    color: ${palette.cloudBurst};
    font-family: GilmerBold;
    background-color: ${palette.extraLight};
    border-radius: 0.3em;
    margin-top: 1.2em;
    padding: 0.2em 0;
  }

  .react-calendar__month-view__weekdays__weekday {
    text-decoration: underline;
    font-size: 0;
    line-height: 1.85;
    margin: 0;

    &::first-letter {
      font-size: ${gF('px', 15, 20, 1366, 3840)};
    }
  }

  .react-calendar__month-view__days {
    .react-calendar__month-view__days__day {
      color: ${palette.tangaroa};
    }

    .react-calendar__tile {
      text-decoration: underline;
      padding: 0.5em;

      abbr {
        height: 1.5em;
        width: 1.5em;
        border-radius: 50%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        background-color: transparent;
      }
      &:hover abbr {
        background-color: ${palette.tangaroa};
        color: ${palette.white};
      }
    }

    .react-calendar__tile:disabled {
      background-color: transparent;
      color: ${palette.santasGray};

      &:hover abbr {
        background-color: ${palette.mischka};
        color: ${palette.santasGray};
      }
    }

    .react-calendar__tile--now {
      background-color: transparent;
    }

    .react-calendar__tile--active {
      background-color: transparent;

      abbr {
        background-color: ${palette.tangaroa};
        color: ${palette.white};
      }
    }
  }

  .react-calendar__year-view__months,
  .react-calendar__decade-view__years,
  .react-calendar__century-view__decades {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 1em;
    column-gap: 0.5em;

    .react-calendar__tile {
      border-radius: 5rem;
      padding: 0.5em 2em;
      border: 2px solid ${palette.tangaroa};
      background-color: ${palette.white};
      width: 100%;
      color: ${palette.tangaroa};
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;

      &:hover {
        background-color: ${palette.tangaroa};
        color: ${palette.white};
      }
    }

    .react-calendar__tile--hasActive {
      border: 2px solid ${palette.tangaroa};
      background-color: ${palette.tangaroa};
      color: ${palette.white};

      &::before {
        content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.802 3.052a.58.58 0 0 1-.18.42l-9.547 9.26a.963.963 0 0 1-.67.268.963.963 0 0 1-.67-.269L.18 8.313a.585.585 0 0 1 0-.84l.472-.458a.625.625 0 0 1 .867 0l3.67 3.56a.312.312 0 0 0 .431 0l8.665-8.401a.624.624 0 0 1 .866 0l.473.458c.115.11.18.262.18.42z'/%3E%3C/svg%3E");
        max-height: 1em;
        width: 1.2em;
        display: block;
        margin-right: 0.5em;
      }
    }

    .react-calendar__tile:disabled {
      border: 2px solid ${palette.mischka};
      background-color: ${palette.mischka};
      color: ${palette.santasGray};
    }
  }
`

type Props = CalendarProps & {
  format: string
  dayPlaceholder?: string
  monthPlaceholder?: string
  yearPlaceholder?: string
  value?: Date | null
  calendarIcon: JSX.Element
  locale?: string
  openingPosition?: string
}

export const CustomDatePicker: React.FC<Props> = ({
  calendarIcon,
  value,
  format: dateFormat,
  dayPlaceholder,
  monthPlaceholder,
  yearPlaceholder,
  openingPosition,
  ...props
}) => {
  const [open, _setOpen] = useState(false)
  const calendarRef = useRef<any>(null)
  const buttonRef = useRef<any>(null)

  useEffect(() => {
    const deliveryList = document.getElementById('delivery-list')

    const bottomCalendar = document
      .getElementsByClassName('react-calendar')[0]
      ?.getBoundingClientRect()?.bottom
    const topList = deliveryList?.getBoundingClientRect().top || 0
    const heightList = deliveryList?.getBoundingClientRect().height || 0

    const delta = bottomCalendar - (topList + heightList)

    if (delta > 0) {
      deliveryList?.scrollTo({
        top: deliveryList.scrollTop + delta + 10,
        behavior: 'smooth',
      })
    }
  }, [open])

  const handleClickOutside = (event: Event) => {
    if (buttonRef.current && buttonRef.current.contains(event.target as Node)) {
      _setOpen(!open)
    } else if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
      _setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  useEffect(() => {
    if (value) {
      _setOpen(false)
    }
  }, [value])

  return (
    <Wrapper>
      <Button ref={buttonRef}>
        {calendarIcon}{' '}
        {value
          ? format(value, dateFormat)
          : dateFormat
              .replace('MM', monthPlaceholder || 'MM')
              .replace('dd', dayPlaceholder || 'DD')
              .replace('yyyy', yearPlaceholder || 'YYYY')}
      </Button>
      {open && (
        <CustomCalendar
          openingPosition={openingPosition}
          inputRef={calendarRef}
          value={value}
          {...props}
        />
      )}
    </Wrapper>
  )
}
