import { WishlistApi } from '../../api/restApi'
import { loadMissingProductDetails } from '../../libs/productsV2'
import { getDeviceFromUrl, getRoomFromUrl } from '../../libs/url'
import { AppThunk } from '../../model/model'
import { WishlistProduct } from '../../model/wishlist'
import { dataLoadedFail } from '../app/actions'
import {
  customerIdSelector,
  eventIdSelector,
  isDigitalEventSelector,
  languageSelector,
} from '../app/selectors'
import { tokenSelector } from '../auth/selectors'
import { customerTypeSelector } from '../customer/selectors'
import { setDislikeList } from '../dislike/actions'
import { addNotificationAction, showNotificationsAction } from '../notifications/actions'
import { wishlistIdSelector, wishlistProductDetailsSelector } from './selectors'
import { slice } from './slice'
import { errorNotification } from '../../components/Notification/notifications'

export const toggleWishlistAction = (
  items: {
    brandCode: string
    upc: string
    mocoCode: string
    size: string
    skuCode: string
    categoryId: string
    toDelete?: boolean
  }[],
): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()
    const isDe = isDigitalEventSelector(state)
    const {
      app: { eventId, customerId, lang },
      auth: { token },
    } = state
    const wishlistId = wishlistIdSelector(state)

    const apiPayload = {
      wcsToken: token,
      lang,
      customerId,
      eventId,
      wishlistId,
      products: items.map(item => {
        const [modelCode, colorCode] = item.mocoCode.split('__')
        return {
          ...item,
          modelCode,
          colorCode,
        }
      }),
      room: isDe ? 'de' : getRoomFromUrl(),
      device: isDe ? 'ipad-only' : getDeviceFromUrl(),
    }

    if (!token) {
      return Promise.reject('missing token')
    }

    dispatch(slice.actions.setWishlistLoading())

    const wishlistApi = new WishlistApi(token, lang)
    wishlistApi
      .toggle(apiPayload)
      .then(response => {
        const { wishlist, dislikeList } = response

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        dispatch(loadWishlistSuccess(wishlist))
        dispatch(setDislikeList(dislikeList))

        return response
      })
      .catch(error => {
        return dispatch(dataLoadedFail(error))
      })
  }
}

export const loadWishlistSuccess = (
  wishlist: {
    id: string
    products: WishlistProduct[]
  },
  preventRecursive = false,
): AppThunk => {
  return function(dispatch, getState) {
    const state = getState()
    const wishlistProductDetails = wishlistProductDetailsSelector(state)
    const missingModelCodes = wishlist.products
      .filter(
        ({ modelCode, colorCode, upc }) =>
          !wishlistProductDetails[modelCode] ||
          !wishlistProductDetails[modelCode].mocos[colorCode] ||
          !wishlistProductDetails[modelCode].mocos[colorCode].sizes[upc],
      )
      .map(({ modelCode, mocoCode }) => ({ modelCode, mocoCode }))

    const token = tokenSelector(state)
    const lang = languageSelector(state)
    const customerId = customerIdSelector(state)
    const eventId = eventIdSelector(state)
    const customerType = customerTypeSelector(state)

    if (missingModelCodes.length) {
      loadMissingProductDetails(
        missingModelCodes.map(({ modelCode }) => modelCode),
        { token, lang, customerId, eventId },
        true,
        'wishlist',
        customerType,
      )
        .then(productsChunks => {
          const products = productsChunks?.flatMap(chunk => chunk) || []
          return dispatch(slice.actions.saveWishlistProductsDetails(products))
        })
        .catch(error => {
          const missingModelCodes = error.data as
            | { modelCode: string; colorCode: string; size: string }[]
            | undefined

          if (!missingModelCodes?.length || preventRecursive) {
            errorNotification({
              message: error.message,
            })
            return
          }

          dispatch(
            addNotificationAction({
              codes: ['missingModelCodes'],
              message: '',
              notificationType: 'missingModelCodes',
              notificationPayload: {
                missingModelCodes: (missingModelCodes || []).map(m => ({
                  modelCode: m.modelCode,
                  colorCode: m.colorCode,
                  size: m.size,
                })),
              },
            }),
          )
          dispatch(showNotificationsAction('missingModelCodes'))

          if (!token) {
            return Promise.reject('missing token')
          }

          const wishlistApi = new WishlistApi(token, lang)
          wishlistApi.get({ customerId, eventId }).then(result => {
            if (result) dispatch(loadWishlistSuccess(result.wishlist, true))
          })
        })
    }

    return dispatch(slice.actions.saveWishlistProducts(wishlist))
  }
}

export const getWishlist = (): AppThunk => (dispatch, getState) => {
  const state = getState()
  const customerId = customerIdSelector(state)
  const eventId = eventIdSelector(state)
  const token = tokenSelector(state)
  const lang = languageSelector(state)

  if (!token) {
    return Promise.reject('missing token')
  }

  const wishlistApi = new WishlistApi(token, lang)
  return wishlistApi
    .get({ customerId, eventId })
    .then(response => {
      if (!response) return
      const { wishlist, dislikeList } = response

      dispatch(loadWishlistSuccess(wishlist))
      dispatch(setDislikeList(dislikeList))

      return response
    })
    .catch(error => {
      return dispatch(dataLoadedFail(error))
    })
}

export const saveWishlistProductsDetails = slice.actions.saveWishlistProductsDetails
export const saveWishlistProducts = slice.actions.saveWishlistProducts
export const setWishlistCurrentCategoryId = slice.actions.setWishlistCurrentCategoryId

const wishlistActions = {
  ...slice.actions,
  toggleWishlistAction,
  loadWishlistSuccess,
  getWishlist,
}

export default wishlistActions
