import styled from 'styled-components/macro'
import { breakpoints, getFluidSizeWithFullFormula, palette, pxToRem } from '../../../style/theme'
import Icon from '../../../styleguide/Icon'

export const Wrapper = styled.div<{
  centerElements: boolean
}>`
  padding: 0 ${pxToRem(32)}rem;
  height: ${pxToRem(64)}rem;
  display: flex;
  font-weight: 500;
  color: ${palette.tangaroa};
  font-family: 'GilmerMedium', sans-serif;
  letter-spacing: 0.3px;
  align-items: center;
  justify-content: ${({ centerElements }) => (centerElements ? 'center' : 'space-between')};
  border-bottom: 1px solid ${palette.lightGray};
  background-color: ${palette.white};

  @media (min-width: ${breakpoints.XL}) {
    background-color: ${palette.extraLight};
    height: 100%;
    padding: 0 0 ${pxToRem(32)}rem;
    margin: 0;
    flex-direction: column;
  }
`

export const Filter = styled.div`
  font-size: ${pxToRem(14)}rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: ${getFluidSizeWithFullFormula('px', 12, 24, 1366, 3840)};
  text-transform: capitalize;

  @media (min-width: ${breakpoints.XL}) {
    order: 2;

    img {
      display: none;
    }
  }

  > * {
    margin: 0 4px;
  }
`

export const Selection = styled.div`
  text-transform: uppercase;
  color: ${palette.shadowBlue};
  height: 100%;
  display: flex;
  box-sizing: border-box;
  padding-top: 4px;
  font-size: ${getFluidSizeWithFullFormula('px', 14, 28, 1366, 3840)};

  @media (min-width: ${breakpoints.XL}) {
    flex-direction: column;
    justify-content: center;

    span {
      height: auto !important;
      margin: ${pxToRem(46)}rem 0 !important;
      text-transform: capitalize;

      &.selected {
        border-color: transparent !important;
      }
    }
  }

  span {
    margin: 0 ${pxToRem(24)}rem;
    height: 100%;
    align-items: center;
    display: flex;
    border-bottom: 4px solid transparent;
    cursor: pointer;

    &.selected {
      color: ${palette.tangaroa};
      border-color: ${palette.tangaroa};
    }
  }
`

export const Sort = styled.div`
  font-size: ${getFluidSizeWithFullFormula('px', 12, 24, 1366, 3840)};
  position: relative;

  div {
    &:first-of-type {
      display: flex;
      align-items: center;

      span {
        display: flex;
        align-items: center;

        &:first-of-type {
          margin-right: ${pxToRem(24)}rem;
          color: ${palette.santasGray};
        }

        &:last-of-type {
          cursor: pointer;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.XL}) {
    order: 3;
    margin-top: ${pxToRem(64)}rem;

    div {
      &:first-of-type {
        span {
          &:first-of-type {
            display: none;
          }
        }
      }
    }
  }
`

export const Arrow = styled(Icon)`
  margin-left: 6px;
`

export const SortingValues = styled.div`
  background-color: ${palette.white};
  border-radius: ${pxToRem(10)}rem;
  box-shadow: 0 4px 4px 0 rgba(0, 51, 17, 0.25);
  border: solid 1px ${palette.mischka};
  padding: ${pxToRem(24)}rem;
  position: absolute;
  right: -${pxToRem(6)}rem;
  top: ${pxToRem(24)}rem;
  width: ${getFluidSizeWithFullFormula('px', 200, 300, 1080, 3840)};
`
