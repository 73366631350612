import { useSelector } from 'react-redux'
import { getSelectedSwitch, getSelectedWhiteboard } from '../../../../../store/whiteboard/selectors'
import RCIcon from '../../../../../components/UI/RCIcon'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Heading, Wrapper, PencilIconWrapper, WhiteboardName } from './style'
import { useTranslation } from 'react-i18next'
import {
  useGetVisibleWhiteboardsQuery,
  useSaveWhiteboardMutation,
} from '../../../../../services/whiteboard'
import {
  errorNotification,
  successNotification,
} from '../../../../../components/Notification/notifications'

import BoardsBar from '../BoardsBar'
import { useGet4KDevice } from '../../../../../hooks/useGet4KDevice'

const Header = () => {
  const whiteboard = useSelector(getSelectedWhiteboard)
  const selectedSwitch = useSelector(getSelectedSwitch)
  const { data } = useGetVisibleWhiteboardsQuery('', selectedSwitch)
  const currentWhiteboardFromGetVisible = useMemo(() => {
    return data?.whiteboards.find(whiteboardFromList => whiteboardFromList.key === whiteboard?.key)
  }, [data?.whiteboards, whiteboard?.key])

  const whiteboardIsLocked = currentWhiteboardFromGetVisible?.locked || false
  const whiteboardOwner = currentWhiteboardFromGetVisible?.isOwner || false

  const { t } = useTranslation()
  const is4kDevice = useGet4KDevice()

  const [saveWhiteboard, saveWhiteboardResult] = useSaveWhiteboardMutation()

  const inputRef = useRef<HTMLInputElement>(null)

  const [whiteboardName, setWhiteboardName] = useState('')

  useEffect(() => {
    if (whiteboard?.name) {
      setWhiteboardName(whiteboard?.name.substring(8))
    }
  }, [whiteboard?.name])

  useEffect(() => {
    if (inputRef.current) {
      if (whiteboardName.length > 1) {
        const tempElem = document.createElement('span')
        tempElem.style.fontSize = window.getComputedStyle(inputRef.current).fontSize
        tempElem.style.fontFamily = window.getComputedStyle(inputRef.current).fontFamily
        tempElem.style.position = 'absolute'
        tempElem.style.opacity = '0'
        tempElem.innerHTML = whiteboardName
        document.body.appendChild(tempElem)
        const width = tempElem.offsetWidth
        document.body.removeChild(tempElem)
        inputRef.current.style.width = `${width + 20}px`
      } else {
        inputRef.current.style.width = '2ch'
      }
    }
  }, [whiteboardName])

  useEffect(() => {
    if (saveWhiteboardResult.status === 'fulfilled') {
      if (saveWhiteboardResult.data.warnings.length) {
        saveWhiteboardResult.data.warnings.forEach(warning => {
          errorNotification({
            message: warning.message,
          })
        })
      } else if (saveWhiteboardResult.error || saveWhiteboardResult.isError) {
        errorNotification({
          message: t('Whiteboard.errorUpdateWhiteboard'),
        })
      } else {
        successNotification({
          message: t('Whiteboard.whiteboardUpdated'),
        })
      }
    }
  }, [saveWhiteboardResult, t])

  const handleWhiteboardNameChange = (value: HTMLInputElement['value']) => {
    //Regex that checks whether the first character typed is text or numeric (no special characters)
    const regexPattern = /^[0-9a-zA-Z]/

    if ((regexPattern.test(value) && whiteboardName.length === 0) || whiteboardName.length > 0) {
      setWhiteboardName(value)
    }
  }

  const setFocusOnInput = () => {
    if (inputRef.current) {
      inputRef.current.focus()
      inputRef.current.setSelectionRange(whiteboardName.length, whiteboardName.length)
    }
  }

  const handleOnBlur = () => {
    if (whiteboardName.length === 0) {
      setWhiteboardName(whiteboard?.name.substring(8) || '')
    } else if (whiteboard && whiteboardName !== whiteboard.name.substring(8)) {
      saveWhiteboard({
        selectedWhiteboard: {
          ...whiteboard,
          name: whiteboard.name.substring(0, 8) + whiteboardName,
        },
        whiteboardHasChanged: true,
      })
    }
  }

  const blurOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputRef.current) {
      event.preventDefault()
      inputRef.current.blur()
    }
  }

  return (
    <Wrapper>
      <Heading>
        <span>{t('Whiteboard.whiteboard')}: </span>
        <WhiteboardName>
          <span>{whiteboard?.name?.substring(0, 8)}</span>
          <input
            type="text"
            onChange={e => handleWhiteboardNameChange(e.target.value)}
            value={whiteboardName}
            ref={inputRef}
            className={whiteboardName.length === 0 ? 'empty-name' : ''}
            onBlur={() => handleOnBlur()}
            onKeyDown={e => blurOnEnter(e)}
          />
        </WhiteboardName>
        {whiteboardOwner || !whiteboardIsLocked ? (
          <PencilIconWrapper onClick={() => setFocusOnInput()}>
            <RCIcon type="icon-pencil" />
          </PencilIconWrapper>
        ) : (
          <RCIcon type="icon-lock" />
        )}
      </Heading>

      {!is4kDevice && <BoardsBar isLocked={!whiteboardOwner && whiteboardIsLocked} />}
    </Wrapper>
  )
}

export default Header
