import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getDeviceBasePath } from '../../../libs/url'
import { palette } from '../../../style/theme'
import { useSendAnalytics } from '../../../services/analytics'

const Wrapper = styled.div`
  background-color: #20364d;
  border-radius: 1vw;
  display: flex;
  padding: 0.5vw;
  min-width: 12vw;

  input {
    display: none;
  }
`

const Button = styled.button`
  display: flex;
  flex: 1 1 0px;
  align-items: center;
  justify-content: center;
  padding: 0.75vw;
  color: ${palette.cornflowerBlueAA};
  border-radius: 1vw;
  font-size: min(1vw, 35px);

  &.active {
    background-color: white;
    color: black;
  }
`

const PageSwitcher: React.FC = () => {
  const { t } = useTranslation()

  const history = useHistory()

  const handleSwitch = (path: 'plp' | 'map') => {
    history.replace(`${getDeviceBasePath()}/assortment-advisor/${path}${window.location.search}`)
  }

  const [sendAnalytics] = useSendAnalytics()

  return (
    <Wrapper>
      <Button
        className={window.location.pathname.includes('plp') ? 'active' : ''}
        onClick={() => handleSwitch('plp')}
      >
        {t('AA.Products')}
      </Button>
      <Button
        className={window.location.pathname.includes('map') ? 'active' : ''}
        onClick={() => {
          handleSwitch('map')
          sendAnalytics('MAP_BASE_ACCESS')
        }}
      >
        {t('AA.Generics.Map')}
      </Button>
    </Wrapper>
  )
}

export default PageSwitcher
