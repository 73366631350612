// sort function based on rules:
// If a code can be transformed into a number, it will be sorted as a number in ascending order.
// If a code cannot be transformed into a number, it will be sorted as a string using the localeCompare method.
// e.g. codes ["0OO7029", "11194", "FO123", "456", "ABC", "DEF"] wil be sorted as ["456", "11194", "0OO7029", "ABC", "DEF", "FO123"]
export const sortCodes = (codeA = '', codeB = '') => {
  const numA = Number(codeA)
  const numB = Number(codeB)

  if (!isNaN(numA) && !isNaN(numB)) {
    // Both codeA and codeB can be transformed into numbers, so sort them numerically
    return numA - numB
  }

  if (!isNaN(numA)) {
    // codeA can be transformed into a number, while codeB cannot, so sort codeA before codeB
    return -1
  }

  if (!isNaN(numB)) {
    // codeB can be transformed into a number, while codeA cannot, so sort codeB before codeA
    return 1
  }

  // Both codeA and codeB cannot be transformed into numbers, so sort them as strings using localeCompare
  return codeA.localeCompare(codeB)
}
