import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AssortmentAdvisorState } from '../../model/model'
import { resetForNewClient } from '../extraActions'
import { GeocodingResult } from '../../flows/AssortmentAdvisor/Model/aaModel'

const DEFAULT_STATE = {
  isMapBrandsFilterOpen: false,
  searchedAddress: undefined,
  selectedDoorId: '',
  getDefaultAddress: true,
  mapDragged: false,
} as AssortmentAdvisorState

export const slice = createSlice({
  name: 'assortmentAdvisor',
  initialState: DEFAULT_STATE,
  reducers: {
    setMapBrandsFilterOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isMapBrandsFilterOpen = payload
    },
    setSearchedAddress: (state, { payload }: PayloadAction<GeocodingResult>) => {
      state.searchedAddress = payload
    },
    setSelectedDoorId: (state, { payload }: PayloadAction<string>) => {
      state.selectedDoorId = payload
    },
    setDefaultAddress: (state, { payload }: PayloadAction<boolean>) => {
      state.getDefaultAddress = payload
    },
    setMapDragged: (state, { payload }: PayloadAction<boolean>) => {
      state.mapDragged = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, () => {
      return DEFAULT_STATE
    })
  },
})

export default slice.reducer
