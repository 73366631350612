import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled from 'styled-components'

import AfaFooter from '../../../components/AfaFooter'
import { scrollbarCss } from '../../../components/CommonComponents'
import Header from '../../../components/Header'
import devices from '../../../config/devices'
import useFetchInitialData from '../../../hooks/useFetchInitialData'
import socketManager from '../../../libs/socketManager'
import { getDeviceAbsolutePath } from '../../../libs/url'
import { breakpoints, palette, pxToRem } from '../../../style/theme'
import AfaCartFilterButton from '../../AfaCart/AfaCartFilterButton'
import AfaCartFilters from '../../AfaCart/AfaCartFilters'
import { NextButtonAsLink } from '../components/AfaCheckoutCommonComponents'
import AfaStepper from '../components/AfaStepper'
import AfaSubHeader from '../components/AfaSubHeader'
import ProcessTable from './ProcessTable'
import AppliedFilters from './AppliedFilters'
import { useGetAfaCartQuery } from '../../../services/afaCart'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const FilterWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`

const Content = styled.div`
  ${scrollbarCss};
  background-color: ${palette.lightGray};
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  gap: ${pxToRem(16)}rem;

  @media screen and (max-width: ${breakpoints.L}) {
    flex-direction: column;
  }
`

const AfaCheckoutProcessPage: React.FC = () => {
  useFetchInitialData({ loadCustomer: true })
  const getCartQuery = useGetAfaCartQuery()
  const cartProducts = getCartQuery.data?.items || []
  const cartQuantity = cartProducts.reduce((result, item) => {
    result += item.unconfirmedQuantity
    return result
  }, 0)

  const { assortment } = useParams<{
    assortment: string
  }>()

  const { t } = useTranslation()

  useEffect(() => {
    socketManager.emitSignal('open_afa_checkout_page', {}, devices.table)
  }, [])

  return (
    <>
      <Wrapper>
        <Header hideButtons />
        <FilterWrapper>
          <AfaCartFilters />
          <AfaSubHeader activeLink="process" />
          <AfaStepper activeStep={1} />
          <Content>
            <AppliedFilters />
            <ProcessTable />
          </Content>
        </FilterWrapper>
      </Wrapper>
      <AfaFooter
        hideSearch
        leftColContent={<AfaCartFilterButton />}
        centralColContent={
          <NextButtonAsLink
            className={!cartQuantity ? 'disabled' : ''}
            to={`${getDeviceAbsolutePath()}/${assortment}/checkout/addresses`}
          >
            {t('Checkout.Footer.Next')}
          </NextButtonAsLink>
        }
      />
    </>
  )
}

export default AfaCheckoutProcessPage
