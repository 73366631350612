import React from 'react'
import styled from 'styled-components'
import ResizeIcon from './ResizeIcon'
import ShowPanelIcon from './ShowPanelIcon'
import { palette } from '../../../style/theme'

const Button = styled.button`
  border-radius: 1vw;
  color: ${palette.cornflowerBlueAA};
  display: flex;
  font-size: 1vw;
  align-items: center;
  justify-content: center;

  svg {
    width: 2em;
    height: 2em;
  }
`

type Props = {
  togglePanelCollapsed: () => void
  collapsed?: boolean
  className?: string
}

const SwitchButton: React.FC<Props> = ({ togglePanelCollapsed, className, collapsed }) => {
  return (
    <Button onClick={togglePanelCollapsed} className={className}>
      {collapsed ? <ShowPanelIcon /> : <ResizeIcon />}
    </Button>
  )
}

export default SwitchButton
