import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import starsActions from '../../store/stars/actions'
import { clustersFilteredSelector, selectedClustersIdsSelector } from '../../store/stars/selectors'
import { breakpoints, breakpointsNoUnit, palette, pxToRem, spacing } from '../../style/theme'
import { PlpLayerWrapper } from '../CommonComponents'
import SearchIcon from '../icons/SearchIcon'
import Checkbox from '../../styleguide/Checkbox'

const Wrapper = styled(PlpLayerWrapper)`
  padding-top: ${pxToRem(spacing(2))}rem;
  padding-bottom: ${pxToRem(spacing(2))}rem;
  max-height: ${pxToRem(600)}rem;
  min-width: 24em;
  width: max-content;
  top: auto;

  @media (max-width: ${breakpoints.M}) {
    min-width: 27em;
  }

  @media (min-width: ${breakpointsNoUnit.M + 1}px) {
    bottom: 1em;
    transform: translate(100%, 50%);
  }
`

const InputWrapper = styled.div`
  padding: ${pxToRem(spacing(0.5))}rem ${pxToRem(spacing(2))}rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${palette.black};
  border-radius: ${pxToRem(spacing(1))}rem;
  border: solid 1px ${palette.alto};
`

const TextInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
`

const IconSearch = styled(SearchIcon)`
  width: ${pxToRem(spacing(3))}rem;
  height: ${pxToRem(spacing(3))}rem;
`

const UlMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const LiMenu = styled.li`
  display: flex;
  align-items: center;
  width: max-content;
  gap: ${pxToRem(spacing(1.25))}rem;
  padding-top: ${pxToRem(spacing(2.5))}rem;
  padding-bottom: ${pxToRem(spacing(2.5))}rem;
  border-bottom: 1px solid ${palette.alto};

  .ant-checkbox-inner {
    background: ${palette.selago};
    border: 0.025rem solid ${palette.cornflowerBlue};
  }
  .ant-checkbox-input:checked + .ant-checkbox-inner {
    background: ${palette.congressBlue};
  }
`

const LiText = styled.span`
  font-size: 0.875rem;
  color: ${palette.biscay};
  text-transform: uppercase;
`

const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${pxToRem(spacing(5))}rem;
  margin-top: ${pxToRem(spacing(3))}rem;
`

const MenuButton = styled.button`
  width: 100%;
  border-radius: 3rem;
  border: solid 1px ${palette.congressBlue};
  background-color: ${palette.white};
  color: ${palette.congressBlue};
  text-transform: uppercase;
  font-size: 0.875rem;
  text-align: center;

  &.clickable {
    background-color: ${palette.congressBlue};
    color: ${palette.white};
  }
`

type Props = {
  open: boolean
}

const ClusterMenu: React.FC<Props> = ({ open }) => {
  const { t } = useTranslation()

  const [searchTerm, setSearchTerm] = useState('')

  const clusters = useSelector(clustersFilteredSelector)
  const selectedClustersIds = useSelector(selectedClustersIdsSelector)

  const dispatch = useDispatch()

  return !open ? null : (
    <Wrapper scrollbarColor={palette.alto}>
      <InputWrapper>
        <TextInput
          placeholder="Search"
          onChange={e => setSearchTerm(e.currentTarget.value.toLowerCase())}
        />
        <IconSearch />
      </InputWrapper>
      <UlMenu>
        {clusters
          .filter(({ description }) => {
            return searchTerm ? description.toLowerCase().includes(searchTerm) : true
          })
          .sort((a, b) => (a.id > b.id ? 1 : -1))
          .map(({ id, description }) => (
            <LiMenu key={id}>
              <Checkbox
                checked={selectedClustersIds.includes(id)}
                onChange={() =>
                  dispatch(
                    starsActions.setSelectedClustersIds(
                      selectedClustersIds.includes(id)
                        ? selectedClustersIds.filter(selectedId => selectedId !== id)
                        : selectedClustersIds.concat(id),
                    ),
                  )
                }
              >
                <LiText>{description}</LiText>
              </Checkbox>
            </LiMenu>
          ))}
      </UlMenu>
      <ButtonsWrapper>
        <MenuButton
          className={selectedClustersIds.length === clusters.length ? 'clickable' : ''}
          onClick={() => dispatch(starsActions.setSelectedClustersIds([]))}
        >
          {t('FooterNav.cart_deselectall')}
        </MenuButton>
        <MenuButton
          className={selectedClustersIds.length < clusters.length ? 'clickable' : ''}
          onClick={() =>
            dispatch(starsActions.setSelectedClustersIds(clusters.map(({ id }) => id)))
          }
        >
          {t('FooterNav.cart_selectall')}
        </MenuButton>
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default ClusterMenu
