import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import RCIcon from '../../components/UI/RCIcon'
import { getCurrencySymbol } from '../../helpers/genericHelper'
import { getDeviceAbsolutePath } from '../../libs/url'
import { AfaProduct } from '../../model/afa'
import { useGetBrandsQuery } from '../../services/afa'
import {
  breakpointsCross,
  getFluidFontSize,
  getFluidSizeWithFullFormula as gF,
  palette,
  pxToRem,
} from '../../style/theme'
import { useDispatch, useSelector } from 'react-redux'
import whiteboardActions from '../../store/whiteboard/actions'
import { itemsPinModalSelector, openPinModalSelector } from '../../store/whiteboard/selectors'
import { generateSkuCode } from '../../libs/whiteboard'
import { showSrPriceSelector, showWhsPriceSelector } from '../../store/customer/selectors'
import { getProductImageUrl } from '../../libs/productImages'
import afaActions from '../../store/afa/actions'
import { getLowestPriceFromMocos } from '../../libs'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  grid-template-rows: ${gF('px', 217, 368, 1366, 3840)};
  background-color: ${palette.white};
`

const Image = styled.img`
  width: ${gF('px', 217, 368, 1366, 3840)};
  height: ${gF('px', 217, 368, 1366, 3840)};
  display: block;
  object-fit: contain;
  position: relative;

  &:before {
    content: '';
    background-image: url('/assets/images/transparent_placeholder.png');
    background-repeat: no-repeat;
    background-position: center;
    width: ${gF('px', 217, 368, 1366, 3840)};
    height: ${gF('px', 217, 368, 1366, 3840)};
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
  }
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${gF('px', 16, 32, 1366, 3840)};
  justify-content: space-between;
`

export const TitleWrapper = styled.div<{ isProductPinned: boolean }>`
  display: grid;
  grid-template-columns: ${props =>
    props.isProductPinned
      ? 'auto max-content'
      : `calc(100% - ${gF('px', 38, 72, 1366, 3840)}) auto`};
  justify-content: space-between;
`

export const Title = styled.div`
  font-weight: bold;
  color: ${palette.tangaroa};
  word-break: break-word;
  text-transform: uppercase;

  @media (max-width: ${breakpointsCross.L.min}) {
    font-size: ${pxToRem(18)}rem;
    line-height: ${pxToRem(22)}rem;
  }

  @media (min-width: ${breakpointsCross.L.over}) and (max-width: ${breakpointsCross.L.max}) {
    font-size: ${pxToRem(24)}rem;
    line-height: ${pxToRem(28)}rem;
  }

  @media (min-width: ${breakpointsCross.XL.min}) {
    font-size: ${pxToRem(32)}rem;
  }
`

export const Pinned = styled.div`
  text-transform: capitalize;
  border-radius: 26px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
  border: solid 0.7px ${palette.softGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: ${gF('px', 8, 16, 1366, 3840)};
  font-family: Avenir, sans-serif;
  font-size: ${gF('px', 14, 28, 1366, 3840)};
  font-weight: 900;
  letter-spacing: 0.6px;
  color: ${palette.cloudBurst};
  background-color: ${palette.white};
  width: ${gF('px', 103, 180, 1366, 3840)};
  height: ${gF('px', 32, 56, 1366, 3840)};

  @media screen and (max-width: ${breakpointsCross.M.max}) {
    width: ${gF('px', 57, 103, 768, 1366)};
    height: ${gF('px', 18, 32, 768, 1366)};
  }
`

export const CheckedIcon = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='currentColor' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.802 3.052a.58.58 0 0 1-.18.42l-9.547 9.26a.963.963 0 0 1-.67.268.963.963 0 0 1-.67-.269L.18 8.313a.585.585 0 0 1 0-.84l.472-.458a.625.625 0 0 1 .867 0l3.67 3.56a.312.312 0 0 0 .431 0l8.665-8.401a.624.624 0 0 1 .866 0l.473.458c.115.11.18.262.18.42z'/%3E%3C/svg%3E");
  width: ${gF('px', 14, 28, 1366, 3840)};
  height: ${gF('px', 14, 28, 1366, 3840)};
  background-repeat: no-repeat;
  background-size: contain;
`

export const PinButton = styled.div`
  cursor: pointer;
  background-color: ${palette.tangaroa};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${palette.white};
  height: ${gF('px', 21, 72, 768, 3840)};
  width: ${gF('px', 21, 72, 768, 3840)};

  img {
    height: 55%;
    width: 55%;
  }
`

const Code = styled.div`
  text-transform: uppercase;
  color: ${palette.tangaroa};
  margin-top: ${gF('px', 2, 10, 768, 3840)};
  font-size: ${getFluidFontSize('13px')};
`

const Colors = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${palette.tangaroa};
  gap: ${pxToRem(8)}rem;
  margin-top: ${gF('px', 2, 10, 768, 3840)};

  span:first-of-type {
    font-size: ${getFluidFontSize('13px')};
  }

  span:last-of-type {
    text-transform: uppercase;
    font-size: ${getFluidFontSize('10px')};
    font-weight: bold;
  }
`

const PricesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const RetailPrice = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(10)}rem;
  width: 100%;
  justify-content: space-between;

  span:first-of-type {
    font-size: ${getFluidFontSize('11px')};
    color: ${palette.manatee};
    white-space: nowrap;
  }

  span:last-of-type {
    font-size: ${getFluidFontSize('14px')};
    color: ${palette.tangaroa};
  }
`

const WHSPrice = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(10)}rem;
  text-align: right;
  width: 100%;
  justify-content: space-between;

  span:first-of-type {
    font-size: ${getFluidFontSize('11px')};
    color: ${palette.manatee};
  }

  span:last-of-type {
    font-size: ${getFluidFontSize('14px')};
    color: ${palette.tangaroa};
    font-weight: bold;
  }
`

const Divider = styled.div`
  height: 1px;
  background-color: ${palette.platinum};
`

interface Props {
  product: AfaProduct
  keylook: string
}

const AfaKeylooksProduct: React.FC<Props> = ({ product, keylook }) => {
  const { assortment, brandCode } = useParams<{ assortment: string; brandCode: string }>()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const products = useSelector(itemsPinModalSelector)
  const [isProductPinned, setIsProductPinned] = useState(false)
  const openPinModal = useSelector(openPinModalSelector)
  const showSrPrice = useSelector(showSrPriceSelector)
  const showWhsPrice = useSelector(showWhsPriceSelector)

  const brands = useGetBrandsQuery()
  const brand = brands.data?.find(brand => brand.parentCode === brandCode)

  const mocos = Object.values(product.mocos)
  const moco = mocos.find(({ keylooks }) => keylooks?.includes(keylook))
  const colors = mocos.length

  useEffect(() => {
    setIsProductPinned(
      products.some(
        productPinned =>
          productPinned.modelCode === product.modelCode &&
          productPinned.name === product.name &&
          !openPinModal,
      ),
    )
  }, [products, product, openPinModal])

  const filterMocosByKeylook = (product: AfaProduct) => {
    const mocosArray = Object.values(product.mocos)
    const keyLookMoco = mocosArray.filter(({ keylooks }) => keylooks?.includes(keylook))[0]
    return { ...product, mocos: { selectedColor: keyLookMoco } }
  }

  const {
    lowestPrice: lowestWhsPrice,
    hasPriceVariation: whsPriceHasVariation,
  } = getLowestPriceFromMocos(mocos, 'whsPrice')
  const {
    lowestPrice: lowestSrPrice,
    hasPriceVariation: srPriceHasVariation,
  } = getLowestPriceFromMocos(mocos, 'srPrice')

  const productToPin = filterMocosByKeylook(product)

  return (
    <Wrapper>
      <Link
        to={{
          pathname: `${getDeviceAbsolutePath()}/${assortment}/pdp/${product.modelCode}`,
          search: `?tab=details${moco ? `&color=${moco.colorCode}` : ''}`,
          state: { keylook },
        }}
        onClick={() => dispatch(afaActions.setPreviousUrl(window.location.href))}
      >
        <Image src={getProductImageUrl({ path: moco?.catalogImgPath || '' })} alt="" />
      </Link>
      <Details>
        <TitleWrapper isProductPinned={isProductPinned}>
          <Title>{product.name.toLowerCase()}</Title>
          {isProductPinned ? (
            <Pinned>
              <CheckedIcon />
              <span>{t('Whiteboard.pinned')}</span>
            </Pinned>
          ) : (
            <PinButton
              onClick={() => {
                dispatch(whiteboardActions.resetItemsPinModal())
                dispatch(whiteboardActions.setSelectedSkuCode(generateSkuCode([productToPin])))
                dispatch(whiteboardActions.setOpenPinModal(true))
                dispatch(whiteboardActions.setItemsPinModal([productToPin]))
              }}
            >
              <RCIcon type="pin" />
            </PinButton>
          )}
        </TitleWrapper>
        <Code>{product.modelCode}</Code>
        <Colors>
          <span>{t('Afa.Keylook.AvailableColors')}</span>
          <span>
            {colors} {t('Afa.Keylook.Colors')}
          </span>
        </Colors>
        {(showWhsPrice || showSrPrice) && <Divider />}
        <PricesWrapper>
          {showWhsPrice && (
            <RetailPrice>
              <span>
                {t(
                  srPriceHasVariation || whsPriceHasVariation ? 'Afa.WHSPriceFrom' : 'Afa.WHSPrice',
                )}
              </span>
              <span>{getCurrencySymbol(lowestWhsPrice, brand?.currency)}</span>
            </RetailPrice>
          )}
          {showSrPrice && (
            <WHSPrice>
              <span>
                {t(srPriceHasVariation || whsPriceHasVariation ? 'Afa.srpFrom' : 'Afa.srp')}
              </span>
              <span>{getCurrencySymbol(lowestSrPrice, brand?.currency)}</span>
            </WHSPrice>
          )}
        </PricesWrapper>
      </Details>
    </Wrapper>
  )
}

export default AfaKeylooksProduct
