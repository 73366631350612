import React from 'react'
import { useSelector } from 'react-redux'

import { importExportStepSelector } from '../../../../store/checkout/selectors'
import Comparison from './Comparison'
import ImportExportPage from './ImportExportPage'

const ImportExport: React.FC = () => {
  const importExportStep = useSelector(importExportStepSelector)

  return (
    <>
      {(importExportStep === 0 || importExportStep === 1) && <ImportExportPage />}
      {importExportStep === 2 && <Comparison />}
    </>
  )
}

export default ImportExport
