import classnames from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import app_config from '../../../config/app/config'
import { useChangeShopCategory } from '../../../hooks/useChangeShopCategory'
import { useHandleTableWallPageSwitch } from '../../../hooks/useHandleTableWallPageSwitch'
import { isExtraIpadDevice, isLargeRoom } from '../../../libs/url'
import { toggleSearch } from '../../../store/actions'
import { assortmentsViewTypeSelector } from '../../../store/cart/selectors'
import { switchLabelKeySelector } from '../../../store/content/selectors'
import { showCartTotalSelector } from '../../../store/customer/selectors'
import { showSwitchButtonSelector } from '../../../store/pdp/selectors'
import { showShopCategorySwitcherSelector } from '../../../store/showButtonsSelectors'
import { cartModeSelector } from '../../../store/stars/selectors'
import { HeaderWrapper } from '../../../style/CommonComponents'
import {
  breakpointsCross,
  getFluidFontSize,
  palette,
  pxToRem,
  spacing,
  zIndex,
} from '../../../style/theme'
import { IpadHeaderButtons } from '../../CommonComponents'
import DownIcon from '../../icons/DownIcon'
import SwitcherIcon from '../../icons/SwitchIcon'
import UpIcon from '../../icons/UpIcon'
import RCIcon from '../../UI/RCIcon'
import RedCarpetLogo from '../../UI/RedCarpetLogo'
import CartSummary from '../CartSummary'

const Wrapper = styled.div`
  @media (min-width: ${breakpointsCross.L.min}) {
    .hide-on-desktop {
      display: none;
    }
  }

  @media (max-width: ${breakpointsCross.L.max}) {
    .hide-on-tablet {
      display: none;
    }
  }
`

const Header = styled(HeaderWrapper)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`

const SubheaderTablet = styled.div`
  background-color: ${palette.white};
  z-index: ${zIndex.header};
  width: 100%;
  height: 5.5vh;
  border-bottom: solid 1px ${palette.mercury};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;

  > *:first-child {
    grid-column-start: 2;
  }
`

const CentralCol = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  font-size: ${getFluidFontSize('14px', 15)};
`

const RightCol = styled.div`
  position: relative;
`

const CategorySwitchers = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
`

const ShopToggle = styled.button`
  padding: 0.5rem 1rem;
  line-height: 1;
  width: 15em;
  font-size: ${getFluidFontSize('12px')};
  text-align: center;
  text-transform: uppercase;
  border-radius: 3rem;
  background-color: ${palette.white};
  color: ${palette.congressBlue};
  border: solid 1px ${palette.congressBlue};

  &.active {
    background-color: ${palette.congressBlue};
    color: ${palette.white};
  }
`

const Title = styled.h1`
  color: ${palette.mineShaftDark};
  line-height: 1;
  text-align: center;
`

const Button = styled.button`
  font-size: ${getFluidFontSize('11px')};
  color: #005192;
  text-transform: uppercase;
  display: flex;
  padding: 0.5em 1em;

  &.disable {
    opacity: 0.2;
  }

  .rc-icon:first-child {
    margin-right: 0.5em;
  }
  .rc-icon:last-child {
    margin-left: 0.5em;
  }
`

const CartSummaryWrapperTablet = styled.div`
  background-color: ${palette.white};
  padding: ${pxToRem(spacing(2))}rem;
  width: 100%;
  position: absolute;
`

const TitleTablet = styled(Title)`
  width: 100%;
  padding: ${pxToRem(spacing(2.5))}rem 0 ${pxToRem(spacing(1.5))}rem;
`

const toggleAnaliticsVisible = (
  isAnaliticsVisible: boolean,
  setIsAnaliticsVisible: (flag: boolean) => void,
) => {
  setIsAnaliticsVisible(!isAnaliticsVisible)
}

type Props = {
  title: string
}

const CartHeader: React.FC<Props> = ({ title }) => {
  const { t } = useTranslation()

  const showCartSummary = useSelector(showCartTotalSelector)
  const switchLabelKey = useSelector(switchLabelKeySelector)
  const showSwitchButton = useSelector(showSwitchButtonSelector)
  const showShopCategorySwitcher = useSelector(showShopCategorySwitcherSelector)
  const isStarsCart = useSelector(cartModeSelector) === 'stars'
  const assortmentViewType = useSelector(assortmentsViewTypeSelector)

  const changeShopCategory = useChangeShopCategory()
  const handleSwitch = useHandleTableWallPageSwitch()
  const dispatch = useDispatch()

  const [isAnaliticsVisible, setIsAnaliticsVisible] = useState(false)

  const disableSwitch =
    !isLargeRoom() && (assortmentViewType.category === '1' || assortmentViewType.category === '2')

  return (
    <Wrapper>
      <Header>
        <RedCarpetLogo />
        <CentralCol>
          <Title className="hide-on-tablet">{title}</Title>
        </CentralCol>
        {!isStarsCart ? (
          <RightCol>{showCartSummary && <CartSummary className="hide-on-tablet" />}</RightCol>
        ) : null}
      </Header>
      <SubheaderTablet className="hide-on-desktop">
        <CentralCol>
          {showShopCategorySwitcher && (
            <CategorySwitchers>
              <ShopToggle
                id={`footer-linkto-shop-cat${app_config.sunCategory}-button`}
                className={classnames({
                  'btn-link': true,
                  'shop-toggles': true,
                  active: assortmentViewType.category === '2',
                })}
                onClick={() => {
                  changeShopCategory(assortmentViewType.category ? undefined : '2')
                }}
              >
                {t('CartPage.mySunShop')}
              </ShopToggle>
              <ShopToggle
                id={`footer-linkto-${app_config.opticalCategory}-button`}
                className={classnames({
                  'btn-link': true,
                  'shop-toggles': true,
                  active: assortmentViewType.category === '1',
                })}
                onClick={() => {
                  changeShopCategory(assortmentViewType.category ? undefined : '1')
                }}
              >
                {t('CartPage.myOpticalShop')}
              </ShopToggle>
            </CategorySwitchers>
          )}
        </CentralCol>

        {!isStarsCart ? (
          <RightCol>
            <IpadHeaderButtons>
              {showCartSummary && (
                <Button
                  onClick={() => toggleAnaliticsVisible(isAnaliticsVisible, setIsAnaliticsVisible)}
                >
                  <span>{t('Header.item_viewAnalytics')}</span>
                  {isAnaliticsVisible ? <UpIcon /> : <DownIcon />}
                </Button>
              )}
              <Button onClick={() => dispatch(toggleSearch())}>
                <RCIcon type="search" /> <span>{t('FooterNav.item_search')}</span>
              </Button>
              {!isExtraIpadDevice() && showSwitchButton && (
                <Button
                  id="switcher-myshop-button"
                  className={classnames({ disable: disableSwitch })}
                  disabled={disableSwitch}
                  onClick={handleSwitch}
                >
                  <SwitcherIcon /> <span>{t(switchLabelKey)}</span>
                </Button>
              )}
            </IpadHeaderButtons>
            {showCartSummary && isAnaliticsVisible && (
              <CartSummaryWrapperTablet>
                <CartSummary />
              </CartSummaryWrapperTablet>
            )}
          </RightCol>
        ) : null}
      </SubheaderTablet>
      <CentralCol>
        <TitleTablet className="hide-on-desktop">{title}</TitleTablet>
      </CentralCol>
    </Wrapper>
  )
}

export default CartHeader
