import { uniqBy } from 'lodash'

import { Moco, Size } from '../model/product'
import { WishlistProduct } from '../model/wishlist'

export const isInWishlist = (upc: string, products: { upc: string }[]) => {
  if (products) {
    return !!products.find(product => product.upc === upc)
  }
  return false
}

export const groupProductsByCouvettes = (
  products: { productModel: { brand: { code: string }; modelCode: string }; upc: string }[] = [],
) => {
  let couvettes = products.map(item => item.productModel)
  couvettes = uniqBy(couvettes, 'modelCode')
  couvettes = couvettes.sort((itemA, itemB) => {
    if (itemA.brand.code !== itemB.brand.code) {
      return itemA.brand.code < itemB.brand.code ? -1 : 1
    }
    return itemA.modelCode < itemB.modelCode ? -1 : 1
  })

  couvettes = couvettes.map((item: any) => {
    const mocosInWishlist: Moco[] = []
    const mocosNotInWishlist: Moco[] = [] as Moco[]
    /*eslint no-unexpected-multiline: 1*/
    item.mocos.forEach((moco: any) => {
      const filteredSizes = moco.sizes.filter((size: Size) => isInWishlist(size.upc, products))

      if (filteredSizes.length > 0) {
        mocosInWishlist.push(moco)
      } else {
        mocosNotInWishlist.push(moco)
      }

      moco.sizesInWishlist = filteredSizes
    })
    /*eslint no-unexpected-multiline: 1*/
    item.mocos = mocosInWishlist as any
    /*eslint no-unexpected-multiline: 1*/
    item.additionalMocos = mocosNotInWishlist
    return item
  })
  return couvettes
}

export const countItemsInWishlist = (wishlistProducts: WishlistProduct[] = []) => {
  return wishlistProducts ? wishlistProducts.length : 0
}
