import { Base64File } from '../model/model'

export const convertToBase64 = (file: File | Blob): Promise<Base64File> =>
  new Promise((res, rej) => {
    const reader = new FileReader()

    reader.addEventListener('loadend', () => {
      if (typeof reader.result !== 'string') {
        return rej(new Error('reader result is not a string'))
      }

      res(reader.result)
    })

    reader.readAsDataURL(file)
  })
