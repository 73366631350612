import classnames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import app_config from '../../../../config/app/config'

import { getDefaultDoorId } from '../../../../libs/doors'
import { extractModelCodeFromMocoCode } from '../../../../libs/productsV2'
import { isPageMatches } from '../../../../libs/url'
import { CartProduct, MocoInCart } from '../../../../model/cart'
import { Moco } from '../../../../model/product'
import { WishlistProduct } from '../../../../model/wishlist'
import { openPDP, toggleDislikeListAction } from '../../../../store/actions'
import { brandEnabledSelector, brandsSelector } from '../../../../store/brands/selectors'
import { cartEditableSelector, cartViewTypeSelector } from '../../../../store/cart/selectors'
import { customerDoorsSelector } from '../../../../store/customer/selectors'
import { dislikeItemsSelector } from '../../../../store/dislike/selectors'
import { cartModeSelector } from '../../../../store/stars/selectors'
import { breakpoints, getFluidFontSize, palette, pxToRem, spacing } from '../../../../style/theme'
import DislikeBtn from '../../../DislikeBtn'
import Price from '../../../Price'
import ProductImage from '../../../Product/ProductImage/ProductImage'
import ProductIcons from '../../../ProductIcons'
import StarsIcon from '../../../UI/StarsIcon'
import PurchaseControls from '../PurchaseControls/PurchaseControls'
import { errorNotification } from '../../../Notification/notifications'
import { getProductImageUrl } from '../../../../libs/productImages'

const Item = styled.div`
  position: relative;
  min-height: 21vmin;
  border-bottom: ${pxToRem(1)}rem solid ${palette.mercury};
  background: ${palette.white};
  z-index: 0;
  overflow: hidden;

  .couvette-item__prod-image.couvette-item__prod-image--dislike img {
    opacity: 0.2;
  }

  @media screen and (max-width: ${breakpoints.M}) and (orientation: landscape) {
    min-height: 21vmin;
  }

  .couvette-item__prod-image {
    height: 100%;
  }

  .couvette-item--column1 {
    transition: opacity 0.4s ease-in-out;
    opacity: 1;
  }

  &.couvette-item--dislike .couvette-item__grid {
    opacity: 0.2;
  }

  .couvette-item__grid {
    display: flex;
    height: 100%;
    justify-content: space-between;
  }

  .couvette-item__dislike-btn {
    position: absolute;
    bottom: 1.1rem;
    left: 1.1rem;
    z-index: 1;
    @media screen and (max-width: ${breakpoints.L}) {
      bottom: 0.8rem;
      left: 1.1rem;
      button {
        padding: 0;
      }
    }
  }

  .couvette-item__column {
    &.couvette-item--column1 {
      width: 70%;
    }
    &.couvette-item--column2 {
      text-align: right;
      padding: 4%;
      padding-left: 0;

      @media (min-width: ${breakpoints.L}) {
        /* make room for the scroll bar */
        .assortments & {
          padding-right: 30px;
        }
      }
    }
  }
`

const ProdInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-transform: uppercase;
  white-space: nowrap;
  color: ${palette.biscay};
  height: 100%;

  .prod-info__sku {
    color: ${palette.curiousBlue};
  }
`

const BriefInfo = styled.div`
  padding-bottom: 1em;

  .adv {
    color: ${palette.kobi};
    width: 1.1em;
    height: 1em;
  }
`

const Icons = styled(ProductIcons)`
  font-size: ${getFluidFontSize('20px', 8)};
  justify-content: flex-end;
`

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .purchase-controls {
    margin-top: 0.5em;
  }
`

const FrontColor = styled.div`
  color: ${palette.curiousBlue};
  font-size: ${getFluidFontSize('10px')};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  span:nth-of-type(2) {
    margin-left: 0.3rem;
  }
  @media screen and (max-width: ${breakpoints.L}) {
    span {
      display: block;
    }
  }
`

const StyledStarsIcon = styled(StarsIcon)`
  color: ${palette.congressBlue};
  position: absolute;
  left: ${pxToRem(spacing(1))}rem;
  top: ${pxToRem(spacing(1))}rem;
  width: 1.5rem;
  height: 1.5rem;
`

type Props = {
  cartItems?: CartProduct[]
  moco: Moco | MocoInCart
  deleteIcon?: boolean
  hideAdvIcon?: boolean
  hideCarryOverIcon?: boolean
  hideNewIcon?: boolean
  hideDiscIcon?: boolean
  showWishlist?: boolean
  onCartPage?: boolean
  itemsInWishlist?: WishlistProduct[]
  dislikable?: boolean
  hasRTR?: boolean
  style?: any
  starsUpcs?: string[]
}

export const CouvetteItemV2: React.FC<Props> = ({
  cartItems,
  deleteIcon,
  dislikable = true,
  hideAdvIcon = false,
  hideCarryOverIcon = false,
  hideNewIcon = false,
  hideDiscIcon = true,
  itemsInWishlist,
  moco,
  onCartPage,
  showWishlist,
  hasRTR,
  style,
  starsUpcs,
}) => {
  const { t } = useTranslation()

  const customerDoors = useSelector(customerDoorsSelector)
  const cartEditable = useSelector(cartEditableSelector)
  const brandDisabled = !useSelector(brandEnabledSelector(moco.brandCode))
  const dislikeItems = useSelector(dislikeItemsSelector)
  const mode = useSelector(cartModeSelector)
  const cartViewType = useSelector(cartViewTypeSelector)
  const brands = useSelector(brandsSelector)

  const dispatch = useDispatch()

  const defaultDoorId = getDefaultDoorId(customerDoors)

  const showLockedError = () => {
    errorNotification({
      message: t('DigitalPreviewBrandsPage.locked'),
      className: 'rc-notification',
    })
    return false
  }
  const {
    mocoCode,
    catalogImages,
    colorCode,
    modelCode,
    sizes,
    srPrice,
    whsPrice,
    enabledDoors,
    brandCode,
  } = moco
  const isDisliked = Boolean(
    dislikeItems && dislikeItems.find(dislikeItem => dislikeItem === mocoCode),
  )

  const brand = brands.find(({ code }) => code === brandCode)
  const currency = brand?.currency || brands[0]?.currency || ''
  const imgPath = getProductImageUrl({ path: catalogImages?.base })
  const showDislikeButton = (!cartItems || cartItems.length === 0) && cartEditable && dislikable

  const hiddenIcons = [
    hideCarryOverIcon && 'color-refresh',
    hideNewIcon && 'new',
    hideAdvIcon && 'adv',
    hideDiscIcon && 'discontinued',
    isPageMatches('cart') && cartViewType === 'tableView' && 'star',
    !(isPageMatches('cart') && cartViewType === app_config.viewType.wall) && 'ideal',
  ].filter(Boolean) as string[]

  return (
    <Item
      className="couvette-item"
      id={`${modelCode}-${moco.colorCode}`}
      style={style}
      onClick={() => brandDisabled && showLockedError()}
    >
      {mode === 'stars' && onCartPage ? <StyledStarsIcon /> : null}
      {showDislikeButton && (
        <div className="couvette-item__dislike-btn">
          <DislikeBtn
            handleClick={(e: React.MouseEvent) => {
              e.stopPropagation()
              if (brandDisabled) {
                return showLockedError()
              }

              const payloadItem = [
                {
                  upcs: Object.values(sizes).map(size => size.upc),
                  mocoCode,
                  modelCode: extractModelCodeFromMocoCode(mocoCode),
                },
              ]

              dispatch(toggleDislikeListAction(payloadItem))
            }}
          />
        </div>
      )}
      <div className="couvette-item__grid">
        <div className="couvette-item__column couvette-item--column1">
          <div
            className={classnames({
              'couvette-item__prod-image': true,
              'couvette-item__prod-image--dislike': isDisliked,
            })}
            // PDP is opened only when clicking on the image to prevent
            // accidental opening when interacting with the purchase control
            onClick={() => !brandDisabled && dispatch(openPDP(mocoCode, modelCode))}
          >
            <ProductImage clickable={false} imgPath={imgPath} />
          </div>
        </div>
        <div className="couvette-item__column couvette-item--column2">
          <ProdInfo className="couvette-item__prod-info prod-info">
            <BriefInfo className="brief-info">
              <Price whsPrice={whsPrice} srPrice={srPrice} variant="vertical" currency={currency} />
              {modelCode && (
                <FrontColor className="prod-info__front-color">
                  <span>{onCartPage && modelCode}</span>
                  <span>{colorCode}</span>
                </FrontColor>
              )}
              <Icons moco={moco as Moco} hasRTR={hasRTR} hiddenIcons={hiddenIcons} />
            </BriefInfo>
            <Controls className="prod-info__controls">
              {Object.values(sizes)
                .sort((s1, s2) => Number(s1.size) - Number(s2.size))
                .map(size => {
                  const filteredCartProducts = cartItems
                    ? cartItems.filter(itemSize => itemSize.upc === size.upc)
                    : []
                  const itemsSizeInWishlist =
                    itemsInWishlist && itemsInWishlist.filter(el => el.upc === size.upc)
                  if (mode === 'stars' && onCartPage && !starsUpcs?.includes(size.upc)) {
                    return null
                  }
                  return (
                    <PurchaseControls
                      key={size.upc}
                      productSizes={[{ ...size, enabledDoors }]}
                      cartProducts={filteredCartProducts}
                      cartEditable={cartEditable}
                      doors={defaultDoorId ? [defaultDoorId] : []}
                      showWishlist={showWishlist}
                      itemsInWishlist={itemsSizeInWishlist}
                      deleteIcon={deleteIcon}
                      disableWishlist={!cartEditable}
                      brandCode={moco.brandCode}
                      onCartPage={onCartPage}
                    />
                  )
                })}
            </Controls>
          </ProdInfo>
        </div>
      </div>
    </Item>
  )
}

export default CouvetteItemV2
