import React from 'react'
import { useTranslation } from 'react-i18next'
import SizeDate from './SizeDate'
import { SizeDropTitle } from './DropRowsStyles'

type SizeSeasonDateProps = {
  date: string
  type?: string
}

const SizeSeasonDate: React.FC<SizeSeasonDateProps> = ({ date, type }) => {
  const { t } = useTranslation()
  return (
    <SizeDate date={date} className="seasonDate" type={type}>
      <SizeDropTitle>
        {type === 'customDelivery' ? t('Afa.delivery') : t('Afa.shipping')}
      </SizeDropTitle>
    </SizeDate>
  )
}

export default SizeSeasonDate
