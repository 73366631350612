import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { generateMaterialLabelKey } from '../../libs/couvettes'
import { capitalizeFirstLetter } from '../../libs/utils'
import { StarsAnalyticsData } from '../../model/stars'
import { starsAnalyticsCartSelector, starsAnalyticsPlpSelector } from '../../store/stars/selectors'
import {
  breakpoints,
  breakpointsCross,
  getFluidFontSize,
  palette,
  pxToRem,
  spacing,
} from '../../style/theme'
import DoughnutGraph from '../Graphs/DoughnutGraph'

const Wrapper = styled.div`
  background: ${palette.white};
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: ${pxToRem(spacing(3))}rem;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${pxToRem(spacing(5))}rem;
  color: ${palette.biscay};
`

const Title = styled.div`
  font-size: ${getFluidFontSize('22px')};
  font-weight: bold;
`

const Subtittle = styled.div`
  font-size: ${getFluidFontSize('16px')};
`

const RecapTable = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: ${pxToRem(spacing(9))}rem;

  @media (max-width: ${breakpointsCross.L.max}) {
    margin-bottom: ${pxToRem(spacing(3))}rem;
  }
`

const RecapCell = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${getFluidFontSize('14px')};
  font-weight: bold;
`

const RecapLabel = styled.div`
  color: ${palette.hibiscus};
  font-weight: bold;
  text-transform: uppercase;
`

const RecapValue = styled.div`
  color: ${palette.congressBlue};
`

const Graphs = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;
  gap: 4%;

  .cart & {
    grid-template-columns: repeat(2, 48%);
    grid-template-rows: repeat(2, 48%);

    @media (max-width: ${breakpoints.L}) {
      grid-template-columns: repeat(4, 22%);
      grid-template-rows: 1fr;
    }
  }

  .plp & {
    grid-template-columns: repeat(4, 22%);
  }
`

const GraphCell = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const GraphTitle = styled.h2`
  font-size: ${getFluidFontSize('14px')};
  font-weight: bold;
  text-transform: uppercase;
  color: ${palette.biscay};
  margin-bottom: 1em;
`

type Props = {
  variant: 'cart' | 'plp'
  className?: string
  analytics: StarsAnalyticsData
}

const StarsAnalytics: React.FC<Props> = ({ variant, className, analytics }) => {
  const { t } = useTranslation()
  const backgroundColors = [palette.congressBlue, palette.hibiscus]

  const {
    skus,
    mh,
    clustersDoors,
    activeDoors,
    man,
    woman,
    unisex,
    materials,
    polar,
    totalNew,
    carryOver,
  } = analytics

  return (
    <Wrapper className={[variant, className].join(' ')}>
      <Header>
        <Title>{t('CartPage.AssortmentStatistics')}</Title>
        {variant === 'cart' && <Subtittle>{t('CartPage.DataAcrossAllBrands')}</Subtittle>}
      </Header>
      <RecapTable>
        <RecapCell>
          <RecapLabel>{t('GenericWords.sku')}</RecapLabel>
          <RecapValue>{skus}</RecapValue>
        </RecapCell>

        <RecapCell>
          <RecapLabel>{t('GenericWords.new')}</RecapLabel>
          <RecapValue>{totalNew}</RecapValue>
        </RecapCell>

        <RecapCell>
          <RecapLabel>{t('CartPage.Mh')}</RecapLabel>
          <RecapValue>{mh}</RecapValue>
        </RecapCell>

        <RecapCell>
          <RecapLabel>{t('GenericWords.doors')}</RecapLabel>
          <RecapValue>{variant === 'cart' ? activeDoors : clustersDoors}</RecapValue>
        </RecapCell>

        <RecapCell>
          <RecapLabel>{t('Chart.label_polar')}</RecapLabel>
          <RecapValue>{polar}</RecapValue>
        </RecapCell>
      </RecapTable>

      <Graphs>
        <GraphCell>
          <GraphTitle>{t('CartPage.NewVsCarryOver')}</GraphTitle>
          <DoughnutGraph
            data={[carryOver, totalNew]}
            labels={[t('Chart.label_carryover'), t('Chart.label_newmodel')]}
            config={{ backgroundColor: backgroundColors }}
          />
        </GraphCell>
        <GraphCell>
          <GraphTitle>{t('CartPage.GenderMix')}</GraphTitle>
          <DoughnutGraph
            data={[man, woman, unisex]}
            labels={[t('GenericWords.man'), t('GenericWords.woman'), t('GenericWords.unisex')]}
            config={{ backgroundColor: backgroundColors.concat(palette.biscay) }}
          />
        </GraphCell>
        <GraphCell>
          <GraphTitle>{t('CartPage.MaterialMix')}</GraphTitle>
          <DoughnutGraph
            data={Object.values(materials)}
            labels={Object.keys(materials).map(material =>
              capitalizeFirstLetter(t(generateMaterialLabelKey(material))),
            )}
            config={{
              backgroundColor: backgroundColors.concat([
                palette.biscay,
                palette.turquoise,
                palette.butterScotch,
                palette.elfGreen,
                palette.tropicalBlue,
                palette.doveGray,
              ]),
            }}
          />
        </GraphCell>
        <GraphCell>
          <GraphTitle>{t('CartPage.Polar')}</GraphTitle>
          <DoughnutGraph
            data={[polar, skus - polar]}
            labels={[t('Chart.label_polar'), t('ProductPage.others')]}
            config={{ backgroundColor: backgroundColors }}
          />
        </GraphCell>
      </Graphs>
    </Wrapper>
  )
}

export const StarsCartAnalytics: React.FC = () => {
  const analytics = useSelector(starsAnalyticsCartSelector)
  return <StarsAnalytics variant="cart" analytics={analytics} />
}

export const StarsPlpAnalytics: React.FC = () => {
  const analytics = useSelector(starsAnalyticsPlpSelector)
  return <StarsAnalytics variant="plp" analytics={analytics} />
}
