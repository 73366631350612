import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import afaActions from '../../store/afa/actions'
import { filtersOpenSelector } from '../../store/afa/selectors'
import Backdrop from '../../styleguide/Backdrop'
import AfaFiltersLayer from './AfaFiltersLayer'
import styled from 'styled-components'
import { breakpoints, getFluidSizeWithFullFormula } from '../../style/theme'
import { useGetUpdatedPlpFacetsQuery } from '../../services/afaProducts'

const FiltersBackdrop = styled(Backdrop)`
  & > div {
    width: min(${getFluidSizeWithFullFormula('px', 697, 1223, 1366, 3840)}, 100%);
    height: min(${getFluidSizeWithFullFormula('px', 623, 1280, 1366, 3840)}, 100%);
  }

  & > div {
    @media (max-width: ${breakpoints.M}) {
      transform: translateY(100%);

      &.is-open {
        transform: translateX(0);
      }

      top: unset !important;
    }

    top: unset;
    bottom: 0;
    transform: translateX(-100%);

    .is-open & {
      transform: translateX(0);
    }
  }
`

const AfaFilters: React.FC = () => {
  const filtersOpen = useSelector(filtersOpenSelector)

  const dispatch = useDispatch()

  const facetsQuery = useGetUpdatedPlpFacetsQuery()
  const categories = facetsQuery.data?.facets.slice().filter(c => c.name !== 'category') || []

  const numberOfResults = facetsQuery.data?.count || 0

  const showResults = () => {
    dispatch(afaActions.closeCartFilter())
  }

  return (
    <FiltersBackdrop onClick={() => dispatch(afaActions.toggleFiltersOpen())} open={filtersOpen}>
      <AfaFiltersLayer
        categories={categories}
        showLoader={facetsQuery.isFetching}
        showResults={showResults}
        defaultSelectedCategoryName={''}
        numberOfResults={numberOfResults}
      />
    </FiltersBackdrop>
  )
}

export default AfaFilters
