import styled from 'styled-components/macro'
import {
  breakpoints,
  getFluidSizeWithFullFormula,
  getFluidSizeWithFullFormula as gF,
  getFluidSizeWithFullFormulaNegative,
  palette,
  pxToRem,
} from '../../../../style/theme'
import { NavLink } from 'react-router-dom'

export const CardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &:hover {
    color: inherit;
  }

  @media (min-width: ${breakpoints.XL}) {
    justify-content: flex-start;
  }
`

export const CreationInfo = styled.div`
  margin-top: ${pxToRem(16)}rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: ${gF('px', 12, 18, 1366, 3840)};
  color: ${palette.tangaroa};
`

export const Card = styled.div`
  padding: ${gF('px', 16, 32, 1366, 3840)};
  background-color: ${palette.white};
  border: 1px solid ${palette.mischka};
  display: grid;
  grid-template-rows: auto max-content min-content 100%;
  height: 100%;
  align-items: flex-start;

  h3 {
    font-weight: bold;
    text-align: initial;
    word-break: break-all;
  }

  &:hover {
    color: inherit;
  }
`

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    &:last-of-type {
      cursor: pointer;
      position: relative;
    }
  }
`

export const WhiteboardImageWrapper = styled(NavLink)`
  margin-bottom: ${gF('px', 16, 24, 1366, 3840)};
  min-height: ${gF('px', 189, 364, 1366, 3840)};
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
`

export const WhiteboardImage = styled.img`
  width: ${gF('px', 189, 364, 1366, 3840)};
  height: unset !important;
  transform: scale(1.35);
`

export const Title = styled.div`
  min-height: ${pxToRem(44)}rem;
  line-height: normal;
  height: ${gF('px', 44, 72, 1366, 3840)};

  h3 {
    color: ${palette.tangaroa};
    word-break: break-all;
    font-family: GilmerBold, sans-serif;
    font-size: ${gF('px', 16, 30, 1366, 3840)};
  }
`

export const Sports = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: ${pxToRem(15)}rem;
  margin-top: ${pxToRem(24)}rem;
  font-family: GilmerMedium, sans-serif;

  span {
    border-radius: ${pxToRem(16)}rem;
    padding: ${pxToRem(6)}rem ${pxToRem(18)}rem;
    outline: 1px solid ${palette.extraLight};
    text-align: center;
    text-transform: capitalize;
    color: ${palette.tangaroa};
    font-size: ${gF('px', 12, 24, 1366, 3840)};
  }

  @media (min-width: ${breakpoints.XL}) {
    grid-template-columns: auto auto auto auto;
  }
`

export const LockIcon = styled.div`
  &.hide {
    visibility: hidden;
  }

  img {
    cursor: default !important;
    width: ${gF('px', 22, 44, 1366, 3840)};
    height: ${gF('px', 22, 44, 1366, 3840)};
  }
`

export const Button = styled.button`
  position: relative;
  display: flex;
  width: ${gF('px', 16, 34, 1366, 3840)};
  height: ${gF('px', 16, 34, 1366, 3840)};
  align-items: center;
  justify-content: center;
  border-radius: 12px;

  &.active {
    background-color: ${palette.extraLight};
  }

  svg {
    height: ${getFluidSizeWithFullFormula('px', 16, 24, 1024, 3840)};
    width: ${getFluidSizeWithFullFormula('px', 16, 24, 1024, 3840)};
    padding: ${gF('px', 3, 6, 1024, 3840)};
  }
`

export const OptionsMenuHeader = styled.div`
  text-align: left;
  width: 100%;
  border-bottom: 1px solid ${palette.mischka};
  padding-bottom: ${gF('px', 16, 32, 1366, 3840)};
  line-height: 1.38;

  h3 {
    color: ${palette.tangaroa};
    font-family: 'Avenir-Heavy', sans-serif;
    font-size: ${gF('px', 16, 30, 1366, 3840)};
  }

  div {
    margin-top: ${gF('px', 16, 32, 1366, 3840)};
    display: flex;
    color: ${palette.santasGray};
    font-family: GilmerMedium, sans-serif;
    font-size: ${gF('px', 12, 20, 1366, 3840)};
    align-items: center;
    gap: ${gF('px', 16, 32, 1366, 3840)};
  }
`

export const OptionsMenu = styled.div<{ moveCentrally: boolean }>`
  position: absolute;
  background-color: ${palette.white};
  padding: ${gF('px', 24, 48, 1366, 3840)};
  border-radius: ${pxToRem(10)}rem;
  box-shadow: 0 4px 4px 0 rgba(0, 51, 17, 0.25);
  border: solid 1px ${palette.mischka};
  display: flex;
  flex-direction: column;
  right: ${({ moveCentrally }) =>
    moveCentrally ? getFluidSizeWithFullFormulaNegative('px', 32, 44, 1366, 3840) : '-6px'};
  top: ${gF('px', 16, 32, 1366, 3840)};
  width: 350px;
  align-items: flex-start;
  cursor: default;
  z-index: 2;

  @media (min-width: ${breakpoints.XL}) {
    width: 690px;
  }
`

export const OptionsMenuBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const OptionsMenuAction = styled.div`
  margin: ${getFluidSizeWithFullFormula('px', 16, 32, 1366, 3840)} 0;
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  font-family: GilmerMedium, sans-serif;
  font-size: ${getFluidSizeWithFullFormula('px', 12, 24, 1366, 3840)};
  color: ${palette.tangaroa};

  span {
    display: flex;
    align-items: center;
  }

  &.disabled {
    color: ${palette.santasGray};
    pointer-events: none;

    img {
      filter: opacity(0.4);
    }
  }

  span {
    display: flex;
    align-items: center;

    img {
      width: ${gF('px', 20, 40, 1366, 3840)};
      height: ${gF('px', 20, 40, 1366, 3840)};
      margin-right: ${gF('px', 6, 12, 1366, 3840)};
    }
  }
`

export const Info = styled.div`
  font-weight: normal;
  margin-left: ${gF('px', 24, 50, 1366, 3840)};

  margin-top: ${gF('px', 8, 16, 1366, 3840)};
  font-family: GilmerRegular, sans-serif;
  font-size: ${gF('px', 12, 24, 1366, 3840)};
  flex-basis: 100%;
  text-align: left;
`

export const InfoNotOwner = styled(Info)`
  margin-left: ${gF('px', 3, 5, 1366, 3840)};
`
export const Input = styled.input`
  color: ${palette.cloudBurst};
  border: solid 1px ${palette.shadowBlue};
  border-radius: 4px;
  font-size: ${gF('px', 14, 24, 1366, 3840)};

  &:focus {
    outline: none;
  }
`

export const ModalContent = styled.div`
  color: ${palette.tangaroa};
  font-family: 'GilmerRegular', sans-serif;
`

export const Debug = styled.span`
  position: absolute;
  bottom: 96%;
  left: 0.25vw;
  font-size: ${gF('px', 11.5, 23, 1366, 3840)};
  white-space: nowrap;
`
