import { Button, Icon } from 'antd'
import classnames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import app_config from '../../config/app/config'
import { useHandleTableWallPageSwitch } from '../../hooks/useHandleTableWallPageSwitch'
import { isExtraIpadDevice, isPageMatches } from '../../libs/url'
import { toggleSearch } from '../../store/actions'
import { showFooterModal } from '../../store/app/actions'
import { isKamUserSelector } from '../../store/app/selectors'
import { switchLabelKeySelector } from '../../store/content/selectors'
import {
  isPdpVisibleSelector,
  pdpViewTypeSelector,
  showSwitchButtonSelector,
} from '../../store/pdp/selectors'
import { isSearchVisibleSelector } from '../../store/search/selectors'
import { showGridButtonSelector } from '../../store/showButtonsSelectors'
import { HeaderWrapper } from '../../style/CommonComponents'
import { getFluidSize, palette, pxToRem } from '../../style/theme'
import { IpadHeaderButtons } from '../CommonComponents'
import BarcodeIcon from '../icons/BarcodeIcon'
import RedCarpetLogo from '../UI/RedCarpetLogo'

const Wrapper = styled(HeaderWrapper)`
  position: relative;
  padding: ${getFluidSize(`${pxToRem(15)}rem`, 15)} 2vw;
  height: auto;

  &.page-checkout {
    background-color: ${palette.white};
  }
`

const ScanButton = styled(Button)`
  display: flex;
  align-items: center;
`

const BarcodeIconStyled = styled(BarcodeIcon)`
  margin-right: 0.5em;
  height: 0.9em;
`

type Props = {
  prependElement?: JSX.Element
  className?: string
  hideButtons?: boolean
  toggleSearchAction?: () => void
}

export const Header: React.FC<Props> = ({
  prependElement,
  className,
  hideButtons,
  toggleSearchAction = toggleSearch,
}) => {
  const isPdpVisible: boolean = useSelector(isPdpVisibleSelector)
  const pdpViewType: string = useSelector(pdpViewTypeSelector)
  const showSwitchButton: boolean = useSelector(showSwitchButtonSelector)
  const showGridButton: boolean = useSelector(showGridButtonSelector)
  const switchLabelKey = useSelector(switchLabelKeySelector)
  const isKamUser = useSelector(isKamUserSelector)
  const isSearchVisible = useSelector(isSearchVisibleSelector)
  const isCheckoutPage = isPageMatches('checkout')

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const handleSwitch = useHandleTableWallPageSwitch()

  const switchButtonLabel = t(switchLabelKey)

  return (
    <Wrapper
      className={`${classnames({
        header: true,
        'wall-view': pdpViewType === app_config.viewType.wall,
        [className || '']: !!className,
        'page-checkout': isCheckoutPage,
      })}`}
    >
      <RedCarpetLogo />
      {!hideButtons && (
        <IpadHeaderButtons>
          {prependElement}
          {isKamUser && !isCheckoutPage ? (
            <ScanButton
              onClick={() => {
                dispatch(showFooterModal('codeScanner'))
              }}
            >
              <BarcodeIconStyled />
              <span className="scan-btn__label">{t('Header.scan')}</span>
            </ScanButton>
          ) : null}
          {!isPageMatches('cart') && !isPageMatches('wishlist') && !isCheckoutPage && (
            <Button
              onClick={() => dispatch(toggleSearchAction())}
              className={classnames({
                'btn-search': true,
                active: isSearchVisible,
              })}
            >
              <Icon type="search" /> {t('FooterNav.item_search')}{' '}
            </Button>
          )}
          {!isExtraIpadDevice() && showSwitchButton && (
            <Button id="switcher-moodboard-button" className="btn-link" onClick={handleSwitch}>
              <Icon type="switcher" /> {switchButtonLabel}
            </Button>
          )}
          {showGridButton && !isPdpVisible && (
            <Button id="switcher-grid-button" className="btn-link" onClick={handleSwitch}>
              <Icon type="switcher" /> {t('FooterNav.item_grid')}
            </Button>
          )}
        </IpadHeaderButtons>
      )}
    </Wrapper>
  )
}

export default Header
