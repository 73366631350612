import { Layout } from 'antd'
import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import Loading from '../../components/Loading'
import PageSocketManager, { PageSocketManagerProps } from '../../components/PageSocketManager'
import PLPManager from '../../components/PLPManager'
import devices from '../../config/devices'
import socketManager from '../../libs/socketManager'
import { isPageMatches } from '../../libs/url'
import { getCouvettes } from '../../store/actions'
import { appLoadingSelector } from '../../store/app/selectors'
import { brandsSelector } from '../../store/brands/selectors'
import {
  couvettesBrandsSelector,
  couvettesNoMorePagesSelector,
  isCouvetteFirstRequestSelector,
  isCouvettesLoadingSelector,
} from '../../store/couvettes/selectors'
import { plpFiltersSelector } from '../../store/filters/selectors'
import { isPdpVisibleSelector } from '../../store/pdp/selectors'
import FooterNav from '../FooterNav'
import useFetchInitialData from '../../hooks/useFetchInitialData'
import { useSearchParams } from '../../hooks/useSearchParams'
import { useGetInitiativesQuery } from '../../services/initiatives'

const { Content } = Layout

const StyledLayout = styled(Layout)`
  .ant-layout-content {
    display: flex;
    flex-direction: column;
  }

  .brand-prada .sunglasses,
  .eyeglasses > a {
    color: #222;
  }
`

const FilteredPLP: React.FC<PageSocketManagerProps> = ({ socketIoEvent }) => {
  useFetchInitialData({ loadCustomer: true, loadContent: true, loadBrands: true })
  const dispatch = useDispatch()
  const brands = useSelector(brandsSelector)
  const appLoading = useSelector(appLoadingSelector)
  const filters = useSelector(plpFiltersSelector)
  const couvettesBrands = useSelector(couvettesBrandsSelector)
  const isPdpVisible = useSelector(isPdpVisibleSelector)
  const isFirstRequest = useSelector(isCouvetteFirstRequestSelector)
  const couvettesLoading = useSelector(isCouvettesLoadingSelector)
  const couvettesNoMorePages = useSelector(couvettesNoMorePagesSelector)
  const { data: initiatives } = useGetInitiativesQuery()

  const [searchParams] = useSearchParams()
  const selectedInitiativeParam = searchParams.get('initiative')
  const selectedInitiative = initiatives?.initiatives.find(
    ({ initiativecode }) => initiativecode === selectedInitiativeParam,
  )
  const selectedInitiativeBrands = brands.filter(
    ({ code, subBrands }) =>
      selectedInitiative?.brands.includes(code) ||
      subBrands.some(subBrand => selectedInitiative?.brands.includes(subBrand.code)),
  )

  const getBrand = () => {
    const brandSlug = filters.brand_slug.active.length === 1 && filters.brand_slug.active[0]
    return brands.find(brandItem => brandItem.slug === brandSlug)
  }

  const brand = getBrand()
  const emitNavigationEvent = useCallback(() => {
    if (brand) {
      socketManager.emitSignal('open_filteredPlp_singleBrand', { brand }, [devices.table])
    } else {
      socketManager.emitSignal('open_filteredPlp_multiBrand', {}, [devices.table])
    }
  }, [brand])

  useEffect(() => {
    emitNavigationEvent()
  }, [emitNavigationEvent])

  useEffect(() => {
    // NOTE: filtered page can be multibrand or singlebrand. Everytime this changes we should send the navigation event
    if (isFirstRequest === false) {
      emitNavigationEvent()
    }
  }, [isFirstRequest, emitNavigationEvent])

  const socketIoEventRef = useRef('')
  useEffect(() => {
    // NOTE: when couvette video ends, table should broadcast again the navigation event
    if (socketIoEventRef.current === 'do_play_video' && socketIoEvent === 'do_ended_video') {
      // NOTE: we want the signal to be sent only for couvette videos, not the pdp video
      if (!isPdpVisible) {
        emitNavigationEvent()
      }
    }
  }, [socketIoEvent, emitNavigationEvent, isPdpVisible])
  socketIoEventRef.current = socketIoEvent

  useEffect(() => {
    // NOTE: when pdp gets closed, table should broadcast again the navigation event
    if (isPdpVisible === false) {
      emitNavigationEvent()
    }
  }, [isPdpVisible, emitNavigationEvent])

  const getNextPage = () => {
    if (!couvettesNoMorePages) {
      dispatch(getCouvettes({ appendAdvContent: true }))
    }
  }

  const isBrandLogoVisible =
    selectedInitiativeBrands.length > 1 ||
    filters.brand_slug.active.length > 1 ||
    couvettesBrands.length > 1 ||
    (selectedInitiativeBrands.length === 0 &&
      isPageMatches('products') &&
      filters.brand_slug.active.length !== 1)

  if (appLoading) {
    return <Loading />
  }

  return (
    <>
      <StyledLayout className="single-brand-page device-table filtered-plp has-couvettes">
        <Content>
          <PLPManager showBrandLogo={isBrandLogoVisible} getNextPage={getNextPage} />
        </Content>
        {couvettesLoading && <Loading />}
      </StyledLayout>
      <FooterNav />
    </>
  )
}

const FilteredPlpManager = PageSocketManager(FilteredPLP, ['do_play_video', 'do_ended_video'])

export default FilteredPlpManager
