import React from 'react'
import styled from 'styled-components'

import {
  getFluidFontSize,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
} from '../../../style/theme'

export const DoorSwitchButton = styled.button<{ active?: boolean; disabled?: boolean }>`
  display: inline;
  font-weight: normal;
  color: ${props => (props.disabled ? 'rgba(0, 0, 0, 0.25)' : palette.congressBlue)};
  font-size: ${getFluidFontSize('0.75rem')};
  border: 1px solid ${palette.alto};
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 3px 7px;
  margin-left: 16px;
  text-transform: uppercase;
  outline: none;
  ${props =>
    props.disabled
      ? `border-color:${palette.alto};`
      : props.active
      ? `border-color:${palette.cornflowerBlue};`
      : ''};
  ${props =>
    props.disabled
      ? `background-color:${palette.selago};`
      : props.active
      ? `background-color:${palette.wildSand};`
      : ''};
  ${props => (props.disabled ? 'box-shadow: none;' : '')}

  &.afa {
    background-color: ${palette.extraLight};
    color: ${palette.tangaroa};
    padding: ${pxToRem(11)}rem ${pxToRem(24)}rem;
    border: 0;
    font-size: ${getFluidSizeWithFullFormula('px', 12, 20, 1366, 3840)};
  }
`

type Props = {
  active?: boolean
  children: string
  onClick: () => void
  disabled?: boolean
  className?: string
}

const SwitchButton: React.FC<Props> = ({ active, children, disabled, onClick, className }) => (
  <DoorSwitchButton className={className} onClick={onClick} disabled={disabled} active={active}>
    {children}
  </DoorSwitchButton>
)

export default SwitchButton
