import { debounce } from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { setStickyHeaderAction } from '../store/app/actions'
import { PageWrapper } from '../style/DigitalEventsCommonComponents'

const Wrapper = styled(PageWrapper)`
  overflow: auto;
`

const ScrollablePageWrapper: React.FC<{ background?: string }> = ({ children, background }) => {
  const dispatch = useDispatch()
  return (
    <Wrapper
      background={background}
      onScroll={debounce(
        ev => {
          const isHeaderSticky = (ev.target as HTMLDivElement).scrollTop > 0
          dispatch(setStickyHeaderAction(isHeaderSticky))
        },
        200,
        {
          leading: true,
          trailing: false,
        },
      )}
    >
      {children}
    </Wrapper>
  )
}

export default ScrollablePageWrapper
