import { RootState } from '../../model/model'

export const isSummaryOpenSelector = (state: RootState) => state.afaCartSummary.openSummary

export const cartSummarySelectedPageSelector = (state: RootState) =>
  state.afaCartSummary.cartSummarySelectedPage

export const displayAsSelector = (state: RootState) => state.afaCartSummary.displayAs

export const showFiltersSelector = (state: RootState) => state.afaCartSummary.showFilters

export const displayAsLayerOpenSelector = (state: RootState) =>
  state.afaCartSummary.displayAsLayerOpen

export const filtersAppliedSelector = (state: RootState) => state.afaCartSummary.filtersApplied

export const showMultidoorNotificationSelector = (state: RootState) =>
  state.afaCartSummary.showMultidoorNotification

export const filterIDsLengthSelector = (state: RootState) => state.afaCartSummary.filterIDs
