import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CheckoutFilters } from '../../../../model/checkout'
import checkoutActions from '../../../../store/checkout/actions'
import {
  selectCheckoutFilters,
  selectCheckoutFiltersToShow,
} from '../../../../store/checkout/selectors'
import { FiltersRecapPanelCheckout } from '../../../../components/ModalFilterBy/FiltersRecapPanel/FiltersRecapPanel'
import { showFooterModal } from '../../../../store/app/actions'

type ParentProps = {
  className?: string
}

export const CheckoutFiltersRecapPanel: React.FC<ParentProps> = ({ className }) => {
  const filters = useSelector(selectCheckoutFilters)
  const filtersToShow = useSelector(selectCheckoutFiltersToShow())
  const isApplyFilterButtonVisible = Object.values(filters).some(
    ({ selected, active }) => selected.length || active.length,
  )

  const dispatch = useDispatch()

  const toggleFilter = (key: keyof CheckoutFilters, selectedId: string) => {
    dispatch(checkoutActions.toggleFilter(key, selectedId))
  }

  return (
    <FiltersRecapPanelCheckout
      filtersToShow={filtersToShow}
      isApplyFilterButtonVisible={isApplyFilterButtonVisible}
      resetFilters={() => {
        dispatch(checkoutActions.resetSelectedFilters())
        dispatch(checkoutActions.updateFiltersAndCounters())
      }}
      applyFilters={() => {
        dispatch(checkoutActions.applyFilters())
        dispatch(showFooterModal(null))
      }}
      toggleFilter={toggleFilter}
      className={className}
    />
  )
}
