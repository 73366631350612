import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { CardContentOperation } from '../../../../model/cart'
import { useSendEmailCartMutation, useUploadCartMutation } from '../../../../services/cart'
import { filteredCartProductsSelector } from '../../../../store/cart/selectors'
import { getFluidFontSize, getFluidSize, palette, pxToRem, spacing } from '../../../../style/theme'
import { horizontalScrollbarCss } from '../../../../components/CommonComponents'
import RCIcon from '../../../../components/UI/RCIcon'
import { StyledTableTh, StyledTableTitle } from '../Common'
import ExportCart from './ExportCart'
import Upload from './Upload'

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  font-weight: 400;
  color: ${palette.tangaroa};
  gap: ${pxToRem(spacing(3))}rem;
`

const Box = styled.div`
  border-radius: ${pxToRem(spacing(0.5))}rem;
  background-color: ${palette.white};
  display: flex;
  flex-direction: column;
`

const BoxTitle = styled.div`
  font-weight: bold;
  padding-bottom: ${getFluidSize(`${pxToRem(20)}rem`, 15)};
  font-size: ${getFluidFontSize('12px', 15)};
  text-transform: uppercase;
  background-image: linear-gradient(
    to bottom,
    ${palette.blueGradient} 4%,
    ${palette.whiteGradient}
  );
  padding: ${pxToRem(20)}rem;
  color: ${palette.biscay};

  img {
    margin-right: ${pxToRem(spacing(1))}rem;
  }

  .rc-icon {
    font-size: ${getFluidFontSize('16px', 15)};
  }
`

const ExcelTable = styled.div`
  grid-column: 1/3;
`

const TableWrapper = styled.div`
  ${horizontalScrollbarCss}
  background-color: ${palette.white};

  table {
    color: black;
    white-space: nowrap;
    min-width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: ${pxToRem(spacing(2))}rem ${pxToRem(spacing(4.5))}rem;
    }
  }
`

const ExportCartContainer = styled.div`
  padding-top: ${getFluidSize(`${pxToRem(50)}rem`, 15)};
`

const TableTh = styled(StyledTableTh)``

const TableTitle = styled(StyledTableTitle)``

function TableItem({
  xlsBrand,
  xlsSkuCode,
  xlsColor,
  xlsModel,
  xlsCollection,
  xlsUpc,
  xlsPolarized,
  xlsItemNotes,
  xlsName,
  xlsCustomerName,
  xlsDoorId,
  xlsReference,
  xlsQuantity,
  xlsNewOld,
  xlsDay,
  xlsMonth,
  xlsYear,
}: CardContentOperation) {
  const newType = (xlsNewOld: boolean) => {
    if (xlsNewOld) {
      return <RCIcon type="checkout-import-export-new" />
    } else {
      return ''
    }
  }

  const deliveryDate = (xlsDay: string, xlsMonth: string, xlsYear: string) => {
    if (xlsDay != '' && xlsMonth != '' && xlsYear != '') {
      return new Date(`${xlsYear}-${xlsMonth}-${xlsDay}`).toLocaleDateString()
    } else {
      return ''
    }
  }

  return (
    <tr>
      <td>{xlsBrand}</td>
      <td>{xlsSkuCode}</td>
      <td>{xlsColor}</td>
      <td>{xlsModel}</td>
      <td>{xlsCollection}</td>
      <td>{xlsUpc}</td>
      <td>{newType(xlsNewOld)}</td>
      <td>{xlsPolarized}</td>
      <td>{xlsItemNotes}</td>
      <td>{xlsName}</td>
      <td>{xlsCustomerName}</td>
      <td>{xlsDoorId}</td>
      <td>{deliveryDate(xlsDay, xlsMonth, xlsYear)}</td>
      <td>{xlsReference}</td>
      <td>{xlsQuantity}</td>
    </tr>
  )
}

const ImportExportPage: React.FC = () => {
  const { t } = useTranslation()

  const [, uploadCartResult] = useUploadCartMutation()
  const uploadEnded = uploadCartResult.isSuccess

  const cartProducts = useSelector(filteredCartProductsSelector)
  const itemsInCart = cartProducts.length > 0

  const [sendEmailCart] = useSendEmailCartMutation()

  const tableHeader: [string][] = [
    [t('Checkout.ImportExport.Brand')],
    [t('Checkout.ImportExport.SkuCode')],
    [t('Checkout.ImportExport.ColorCode')],
    [t('Checkout.ImportExport.ModelCode')],
    [t('Checkout.ImportExport.Collection')],
    [t('Checkout.ImportExport.Upc')],
    [t('Checkout.ImportExport.New')],
    [t('Checkout.ImportExport.Polar')],
    [t('Checkout.ImportExport.OrderNotes')],
    [t('Checkout.ImportExport.Name')],
    [t('Checkout.ImportExport.Description')],
    [t('Checkout.ImportExport.CustomerCode')],
    [t('Checkout.ImportExport.DeliveryDate')],
    [t('Checkout.ImportExport.Reference')],
    [t('Checkout.ImportExport.Quantity')],
  ]

  return (
    <Wrapper>
      <Box>
        <Upload />
      </Box>

      <Box>
        <BoxTitle>
          <RCIcon type="newExport" />
          <span>{t('Checkout.ImportExport.ExportTitle')}</span>
        </BoxTitle>

        <ExportCartContainer>
          <ExportCart sendEmailCart={sendEmailCart} itemsInCart={itemsInCart} />
        </ExportCartContainer>
      </Box>

      {uploadEnded && (
        <ExcelTable>
          <TableTitle>{t('Checkout.ImportExport.EditedCart')}</TableTitle>
          <TableWrapper>
            <table className="full-screen">
              <tr>
                {tableHeader.map((column, index) => {
                  const columnName: string = column[0].toString()
                  return <TableTh key={index}>{columnName}</TableTh>
                })}
              </tr>

              {uploadCartResult.data?.content.map(contentResult => {
                return <TableItem key={contentResult.xlsUpc} {...contentResult} />
              })}
            </table>
          </TableWrapper>
        </ExcelTable>
      )}
    </Wrapper>
  )
}

export default ImportExportPage
