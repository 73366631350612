import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  CheckoutCurrentPage,
  CheckoutFilters,
  CheckoutFiltersOptions,
  CheckoutFiltersString,
  CheckoutState,
  FooterButton,
} from '../../model/checkout'
import { FilterCounters } from '../../model/filters'
import { UploadCartPayload } from '../../model/cart'
import { resetForNewClient } from '../extraActions'

const blankFilterData = {
  active: [],
  selected: [],
  counter: {},
  options: [],
}

const DEFAULT_STATE: CheckoutState = {
  sidebar: {
    step: 0,
    open: true,
  },
  importExport: {
    step: 0,
  },
  upload: {
    step: 0,
  },
  pagination: {
    totalItems: 0,
    itemsEachPage: 200,
    currentPage: 0,
  },
  step: 0,
  page: 'process',
  selectedAddresses: [],
  footerButtons: [],
  orderNotes: '',
  filters: {
    brand: blankFilterData,
    deliveryDate: blankFilterData,
    model: { selected: '', active: '', counter: {} },
    reference: { selected: '', active: '', counter: {} },
    quantity: blankFilterData,
    release: blankFilterData,
    category: blankFilterData,
  },
}

export const slice = createSlice({
  name: 'checkout',
  initialState: DEFAULT_STATE,
  reducers: {
    setFooterButtons: (state, { payload }: PayloadAction<FooterButton[]>) => {
      state.footerButtons = payload
    },
    setSidebarOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.sidebar.open = payload
    },
    setSidebarStep: (state, { payload }: PayloadAction<number>) => {
      state.sidebar.step = payload
    },
    setStep: (state, { payload }: PayloadAction<number>) => {
      state.step = payload
    },
    setPaginationTotalItems: (state, { payload }: PayloadAction<number>) => {
      state.pagination.totalItems = payload
    },
    setPaginationItemsEachPage: (state, { payload }: PayloadAction<number>) => {
      state.pagination.itemsEachPage = payload
    },
    setPaginationCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.pagination.currentPage = payload
    },
    nextStep: state => {
      state.step += 1
    },
    nextImportExportStep: state => {
      state.importExport.step += 1
    },
    setImportExportStep: (state, { payload }: PayloadAction<number>) => {
      state.importExport.step = payload
    },
    nextUploadtStep: state => {
      state.upload.step += 1
    },
    previusStep: state => {
      if (state.step > 0) {
        state.step -= 1
      }
    },
    previusImportExportStep: state => {
      if (state.importExport.step > 0) {
        state.importExport.step -= 1
      }
    },
    paginationPreviousPage: state => {
      if (state.pagination.currentPage > 0) {
        state.pagination.currentPage -= 1
      }
    },
    paginationNextPage: state => {
      if (
        state.pagination.totalItems / state.pagination.itemsEachPage >
        state.pagination.currentPage + 1
      ) {
        state.pagination.currentPage += 1
      }
    },
    setPage: (state, { payload }: PayloadAction<CheckoutCurrentPage>) => {
      state.page = payload
    },
    resetCheckout: state => {
      state.step = DEFAULT_STATE.step
      state.page = DEFAULT_STATE.page
    },
    setSelectedAddress: (
      state,
      { payload }: PayloadAction<{ doorId: string; selectedAddressId: string }>,
    ) => {
      const id = state.selectedAddresses.findIndex(({ doorId }) => doorId === payload.doorId)
      if (id === -1) {
        state.selectedAddresses.push(payload)
      } else {
        state.selectedAddresses[id] = payload
      }
    },
    setOrderNotes: (state, { payload }: PayloadAction<string>) => {
      state.orderNotes = payload
    },
    setFileData: (state, { payload }: PayloadAction<UploadCartPayload | undefined>) => {
      state.upload.fileData = payload
    },
    initFilters: (
      state,
      {
        payload,
      }: PayloadAction<{
        brand: {
          counter: FilterCounters
          options: string[]
        }
        deliveryDate: {
          counter: FilterCounters
          options: string[]
        }
        release: {
          counter: FilterCounters
          options: string[]
        }
        category: {
          counter: FilterCounters
          options: string[]
        }
        quantity: {
          counter: FilterCounters
          options: string[]
        }
      }>,
    ) => {
      state.filters.brand.counter = payload.brand.counter
      state.filters.brand.options = payload.brand.options

      state.filters.deliveryDate.counter = payload.deliveryDate.counter
      state.filters.deliveryDate.options = payload.deliveryDate.options

      state.filters.release.counter = payload.release.counter
      state.filters.release.options = payload.release.options

      state.filters.category.counter = payload.category.counter
      state.filters.category.options = payload.category.options

      state.filters.quantity.counter = payload.quantity.counter
      state.filters.quantity.options = payload.quantity.options
    },
    setSelectedFilterString: (
      state,
      { payload }: PayloadAction<{ key: keyof CheckoutFiltersString; value: string }>,
    ) => {
      state.filters[payload.key].selected = payload.value
    },
    setSelectedFilterOptions: (
      state,
      { payload }: PayloadAction<{ key: keyof CheckoutFiltersOptions; selectedOptions: string[] }>,
    ) => {
      state.filters[payload.key].selected = payload.selectedOptions
    },
    setActiveFilterOptions: (
      state,
      { payload }: PayloadAction<{ key: keyof CheckoutFiltersOptions; selectedOptions: string[] }>,
    ) => {
      state.filters[payload.key].active = payload.selectedOptions
    },
    resetFilters: state => {
      state.filters.brand.active = []
      state.filters.brand.selected = []

      state.filters.deliveryDate.active = []
      state.filters.deliveryDate.selected = []

      state.filters.release.active = []
      state.filters.release.selected = []

      state.filters.category.active = []
      state.filters.category.selected = []

      state.filters.quantity.active = []
      state.filters.quantity.selected = []

      state.filters.model.active = ''
      state.filters.model.selected = ''

      state.filters.reference.active = ''
      state.filters.reference.selected = ''
    },
    resetSelectedFilters: state => {
      state.filters.brand.selected = []
      state.filters.deliveryDate.selected = []
      state.filters.release.selected = []
      state.filters.category.selected = []
      state.filters.model.selected = ''
      state.filters.reference.selected = ''
      state.filters.quantity.selected = []
    },
    applyFilters: state => {
      state.filters.brand.active = state.filters.brand.selected

      state.filters.deliveryDate.active = state.filters.deliveryDate.selected

      state.filters.release.active = state.filters.release.selected

      state.filters.category.active = state.filters.category.selected

      state.filters.model.active = state.filters.model.selected

      state.filters.reference.active = state.filters.reference.selected

      state.filters.quantity.active = state.filters.quantity.selected
    },
    updateFiltersCounters: (
      state,
      {
        payload: { facets },
      }: PayloadAction<{
        facets: {
          name: keyof CheckoutFilters
          options: FilterCounters
        }[]
      }>,
    ) => {
      facets.forEach(facet => {
        Object.keys((state.filters && state.filters[facet.name]?.counter) || {}).forEach(key => {
          const facetInState = state.filters && state.filters[facet.name]
          if (facetInState) {
            facetInState.counter[key] = 0
          }
        })

        Object.assign((state.filters && state.filters[facet.name]?.counter) || {}, facet.options)
      })
    },
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, () => DEFAULT_STATE)
  },
})

export default slice.reducer
