import { slice } from './slice'

export const addNotificationAction = slice.actions.addNotificationAction
export const showNotificationsAction = slice.actions.showNotificationsAction
export const removeNotificationAction = slice.actions.removeNotificationAction
export const disableNotEditableCartNotificationAction =
  slice.actions.disableNotEditableCartNotificationAction
export const setSlowCartNotificationAction = slice.actions.setSlowCartNotificationAction
export const addOrUpdateNotificationAction = slice.actions.addOrUpdateNotificationAction
export const setSocketConnectionStatus = slice.actions.setSocketConnectionStatus
export const removeOrphanDislikeNotificationAction =
  slice.actions.removeOrphanDislikeNotificationAction
