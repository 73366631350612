import React, { useState } from 'react'
import ChevronLeftIcon from '../../../components/icons/ChevronLeftIcon'
import ChevronRightIcon from '../../../components/icons/ChevronRightIcon'
import {
  breakpoints,
  breakpointsNoUnit,
  getFluidFontSize,
  palette,
  pxToRem,
  spacing,
} from '../../../style/theme'

import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import AppointmentCard from './AppointmentCard'
import NoAppointmentsMessage from '../../../components/NoAppointmentsMessage'
import styled from 'styled-components'
import { Appointment } from '../../../model/appointment'

const Wrapper = styled.div`
  height: 100%;
  overflow: hidden;
`

const AppointmentWrapper = styled.div`
  display: flex;
  height: 100%;
  padding-top: ${pxToRem(spacing(2))}rem;
`
const AppointmentContainer = styled.div`
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: ${pxToRem(spacing(4))}rem 0;

  @media (max-width: ${breakpoints.S}) {
    padding: 0;
  }
  @media (max-height: ${breakpoints.XS}) {
    padding: 0;
  }
`

const Header = styled.div`
  height: 30px;
  display: flex;
  flex-direction: row;
`

const Today = styled.div`
  flex: 1;
  font-size: ${getFluidFontSize('15px')};
`

const ControllerWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  svg {
    min-width: 2rem;
  }
`

const CalendarSwiper = styled(Swiper)<{ showPagination?: boolean }>`
  width: 100%;
  height: 100%;

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    height: 90%;
  }

  .swiper-pagination-bullet-active {
    background-color: ${palette.white};
  }

  .swiper-pagination-bullet {
    visibility: ${({ showPagination }) => (showPagination ? 'visible' : 'hidden')};
  }
`

const CalendarAppointment: React.FC<{ selectedDayAppointments: Appointment[] | undefined }> = ({
  selectedDayAppointments,
}) => {
  const [slider, setSlider] = useState<SwiperCore>()
  const [, setCurrentIndex] = useState(0) //only to allow the component to evaluate the arrows after a change

  const cardsControlBreakPoint = () => {
    const breakPoint = slider?.currentBreakpoint
    return breakPoint >= 1920 ? 3 : 2
  }

  return (
    <Wrapper>
      {selectedDayAppointments?.length ? (
        <AppointmentWrapper>
          <AppointmentContainer>
            <Header>
              <Today>TODAY [{selectedDayAppointments?.length}]</Today>
              {selectedDayAppointments.length > cardsControlBreakPoint() && (
                <ControllerWrapper>
                  {!slider?.isBeginning ? (
                    <ChevronLeftIcon
                      fill={'white'}
                      onClick={() => {
                        slider?.slidePrev()
                      }}
                    />
                  ) : null}
                  {!slider?.isEnd ? (
                    <ChevronRightIcon
                      fill={'white'}
                      onClick={() => {
                        slider?.slideNext()
                      }}
                    />
                  ) : (
                    <svg viewBox="0 0 24 24" />
                  )}
                </ControllerWrapper>
              )}
            </Header>
            <CalendarSwiper
              onSlideChange={swiper => {
                setCurrentIndex(swiper.activeIndex)
              }}
              onSwiper={swiper => setSlider(swiper)}
              slidesPerView={3}
              spaceBetween={30}
              slidesPerGroup={3}
              pagination={{
                clickable: true,
              }}
              showPagination={selectedDayAppointments?.length > cardsControlBreakPoint()}
              breakpoints={{
                [breakpointsNoUnit.XS]: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                  spaceBetween: 30,
                },
                [breakpointsNoUnit.M]: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                  spaceBetween: 30,
                },
                [breakpointsNoUnit.L]: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                  spaceBetween: 30,
                },
              }}
            >
              {selectedDayAppointments.map(a => (
                <SwiperSlide key={a.idAppointment}>
                  <AppointmentCard appointment={a} />
                </SwiperSlide>
              ))}
            </CalendarSwiper>
          </AppointmentContainer>
        </AppointmentWrapper>
      ) : (
        <NoAppointmentsMessage />
      )}
    </Wrapper>
  )
}

export default CalendarAppointment
