import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import AfaFooter from '../components/AfaFooter'
import AfaSearch from '../components/AfaSearch/AfaSearch'
import { AfaHeader } from '../components/Header/AfaHeader'
import Loading from '../components/Loading'
import useFetchInitialData from '../hooks/useFetchInitialData'
import { useHandleOpenAfaCategoryPage } from '../hooks/useHandleOpenPages'
import { sortCategoriesFunction } from '../libs/afa'
import { getDeviceAbsolutePath } from '../libs/url'
import { BrandCategorySport, CategoriesFieldOfBrand } from '../model/afa'
import { useGetBrandCategoriesQuery } from '../services/afa'
import { useGetCategoriesPageContentsQuery } from '../services/afaContents'
import { useGetUpdatedPlpFacetsQuery } from '../services/afaProducts'
import { breakpoints, getFluidSizeWithFullFormula, palette, pxToRem } from '../style/theme'
import { getAfaFilterTranslation } from '../components/AfaFilters/AfaFiltersLayer'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${palette.alabaster};
  --header-height: ${pxToRem(100)}rem;
`

const Main = styled.main`
  flex: 1;
  display: grid;
  grid-template-rows: 2fr 1fr;
  overflow: hidden;
  padding: ${pxToRem(32)}rem;
`

const Title = styled.h2`
  color: ${palette.tangaroa};
  font-size: ${getFluidSizeWithFullFormula('px', 28, 64, 1366, 3840)};
  text-align: center;
  margin-top: ${getFluidSizeWithFullFormula('px', 40, 70, 1920, 3840)};
  margin-bottom: ${getFluidSizeWithFullFormula('px', 25, 40, 1920, 3840)};
`

const Block = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const Sports = styled.div`
  font-size: ${getFluidSizeWithFullFormula('px', 14, 32, 1366, 3840)};
  gap: 1em;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, minmax(220px, 1fr));
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  max-width: 72%;

  @media (max-width: ${breakpoints.M}) {
    max-width: none;
  }
`

const SportCard = styled(Link)`
  font-size: ${getFluidSizeWithFullFormula('px', 14, 32, 1366, 3840)};
  background-color: #d5e4f5;
  overflow: hidden;
  display: grid;
  grid-template-rows: 3.7fr 1fr;
  grid-template-columns: 100%;
  border-radius: 0 0 1em 1em;
  height: 100%;
`

const ImageWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
`

const SportImage = styled.img`
  width: 100%;
  height: 100%;
  display: block;
`

const SportText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette.tangaroa};
  color: ${palette.white};
  font-size: ${getFluidSizeWithFullFormula('px', 18, 40, 1366, 3840)};
  border-radius: 0 0 1em 1em;
  height: 100%;
`

const Categories = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(180px, 1fr));
  place-items: center;
  font-size: ${getFluidSizeWithFullFormula('px', 14, 32, 1366, 3840)};
  gap: 1em;
  grid-template-rows: 100%;
  overflow: hidden;
  max-width: 76%;
  margin: 0 auto;
  height: 100%;

  @media (max-width: ${breakpoints.M}) {
    max-width: none;
  }
`

const CategoryCard = styled(Link)`
  background-color: #d5e4f5;
  border-radius: 0 0 1em 1em;
  overflow: hidden;
  display: grid;
  grid-template-rows: 3.2fr 1fr;
  grid-template-columns: 100%;
  height: 100%;
  width: 100%;
`

const CategoryImage = styled.img`
  width: 100%;
  height: 100%;
  display: block;
`

const CategoryText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette.tangaroa};
  color: ${palette.white};
  font-size: ${getFluidSizeWithFullFormula('px', 14, 33, 1366, 3840)};
  border-radius: 0 0 ${pxToRem(8)}rem ${pxToRem(8)}rem;
  height: 100%;

  @media (min-width: ${breakpoints.L}) {
    border-radius: 0 0 ${pxToRem(16)}rem ${pxToRem(16)}rem;
  }

  @media (min-width: ${breakpoints.XL}) {
    border-radius: 0 0 ${pxToRem(24)}rem ${pxToRem(24)}rem;
  }
`

const AfaCategoriesPage: React.FC = () => {
  useFetchInitialData({ loadCustomer: true, loadBrands: true })

  const { assortment, brandCode } = useParams<{ assortment: string; brandCode: string }>()
  const { t } = useTranslation()

  useHandleOpenAfaCategoryPage(brandCode)

  const categoriesQuery = useGetBrandCategoriesQuery(brandCode)
  const sports = categoriesQuery.data?.sports || []
  const categories = categoriesQuery.data?.categories || []
  const collectionThemes = sports.find(({ name }) => name.toLowerCase() === 'theme')

  const contentQuery = useGetCategoriesPageContentsQuery(brandCode)
  const facetsQuery = useGetUpdatedPlpFacetsQuery()

  const sortedCategories = Object.assign<CategoriesFieldOfBrand[], CategoriesFieldOfBrand[]>(
    [],
    categories,
  ).sort(sortCategoriesFunction())

  const sortedSports = Object.assign<BrandCategorySport[], BrandCategorySport[]>([], sports)
    .filter(({ name }) => name.toLowerCase() !== 'theme')
    .sort((a, b) => a.name.localeCompare(b.name))

  const sportsFacet = facetsQuery.data?.facets.find(({ name }) => name === 'sport')?.options
  const defaultCategory =
    [...sortedCategories].sort(sortCategoriesFunction(true))[0]?.name || 'Apparel'

  return (
    <Wrapper>
      <AfaSearch />

      <AfaHeader />

      <Main>
        <Block>
          <Title>{t('Afa.exploreByKeyLookAndSports')}</Title>
          {categoriesQuery.isFetching && <Loading />}
          <Sports>
            {collectionThemes && collectionThemes.keylooks.length > 0 && (
              <SportCard
                to={`${getDeviceAbsolutePath()}/${assortment}/single-brand/${brandCode}/key-looks?sport=theme`}
              >
                <ImageWrapper>
                  <SportImage
                    src={contentQuery.data?.sports['collection-themes']?.url}
                    alt={contentQuery.data?.sports['collection-themes']?.alt}
                  />
                </ImageWrapper>
                <SportText>{t('Afa.CollectionThemes')}</SportText>
              </SportCard>
            )}
            {sortedSports.map(({ name, keylooks }) => {
              const cardImageData = contentQuery.data?.sports[name.toLowerCase()]
              const sportFacet = sportsFacet?.find(sport => sport.name === name)?.results
              const sportParam =
                facetsQuery.isLoading || facetsQuery.isFetching || (sportFacet && sportFacet > 0)
                  ? `?sport=${name}`
                  : ''

              return (
                <SportCard
                  key={name}
                  to={`${getDeviceAbsolutePath()}/${assortment}/single-brand/${brandCode}/${
                    keylooks.length > 0 ? 'key-looks' : defaultCategory
                  }${sportParam}`}
                >
                  <ImageWrapper>
                    <SportImage src={cardImageData?.url} alt={cardImageData?.alt} />
                  </ImageWrapper>
                  <SportText>
                    {getAfaFilterTranslation(t, `Afa.${name.toLowerCase()}`, name)}
                  </SportText>
                </SportCard>
              )
            })}
          </Sports>
        </Block>
        <Block>
          <Title>{t('Afa.exploreByCategory')}</Title>
          <Categories>
            {sortedCategories.map(({ name }) => {
              const cardImageData = contentQuery.data?.categories[name.toLowerCase()]
              return (
                <CategoryCard
                  key={name}
                  to={`${getDeviceAbsolutePath()}/${assortment}/single-brand/${brandCode}/${name}`}
                >
                  <ImageWrapper>
                    <CategoryImage src={cardImageData?.url} alt={cardImageData?.alt} />
                  </ImageWrapper>
                  <CategoryText>{t(`Afa.${name.toLowerCase()}`)}</CategoryText>
                </CategoryCard>
              )
            })}
          </Categories>
        </Block>
      </Main>

      <AfaFooter />
    </Wrapper>
  )
}

export default AfaCategoriesPage
