import React, { SVGProps } from 'react'
import { palette } from '../../style/theme'

const AfaCheckoutFileIcon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      d="M8.666 1.333H3.999a1.333 1.333 0 0 0-1.333 1.334v10.666a1.333 1.333 0 0 0 1.333 1.334h8a1.334 1.334 0 0 0 1.334-1.334V6L8.666 1.333z"
      stroke={palette.tangaroa}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.666 1.333V6h4.667"
      stroke={palette.tangaroa}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default AfaCheckoutFileIcon
