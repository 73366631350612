import React, { memo, useEffect } from 'react'

import { VtoImage } from '@luxottica/vto-image'

import { apiLog } from '../../../api/restApi'
import app_config from '../../../config/app/config'
import log from '../../../libs/log'
import Video from './VtoVideo'

interface Props {
  divId: string
  videoId: string
  hasVideo: boolean
  upc: string
  slideIndex: number
}

const VtoRender: React.FC<Props> = ({ divId, videoId, hasVideo, upc, slideIndex }) => {
  useEffect(() => {
    return () => {
      try {
        VtoImage.closeImage({
          divId: divId,
        })
          .then(() => log.info('unmounted render ' + divId))
          .catch(error => apiLog(error))
      } catch (e) {
        log.error(e)
      }
    }
  }, [divId])

  return hasVideo ? (
    <Video
      src={`${app_config.assetsUrl}/vto/video/${videoId}/${upc}/${videoId}_${upc}.mp4`}
      slideIndex={slideIndex}
    />
  ) : (
    <div id={divId} className="render image" />
  )
}

export default memo(VtoRender)
