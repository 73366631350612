import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components/macro'

import { AfaCartProduct, AfaSize, DropType, UpdateAfaCartProductPayload } from '../../model/afa'
import { breakpointsCross, getFluidSizeWithFullFormula } from '../../style/theme'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { activeDoorsIdsSelector } from '../../store/customer/selectors'
import { AfaCatalogId, DdMmYyyyDateString } from '../../model/model'
import { afaCartAdjustSelectors } from '../../store/afaCartAdjust/selectors'
import afaCartAdjustActions from '../../store/afaCartAdjust/actions'
import { convertDdMmYyyyToDate } from '../../libs/time'
import { errorNotification } from '../../components/Notification/notifications'
import { openAddItemsInCartSelector } from '../../store/whiteboard/selectors'
import { useGetAfaCartQuery } from '../../services/afaCart'
import { useSearchParams } from '../../hooks/useSearchParams'
import { DebuggerFeatures } from '../../Debug'
import { AfaCartAdjustType } from '../../store/afaCartAdjust/slice'
import { appSelectors } from '../../store/app/selectors'

const Input = styled.input`
  background-color: transparent;
  border: none;
  outline: none;
  text-align: inherit;
  font-size: clamp(16px, ${getFluidSizeWithFullFormula('px', 16, 38.9, 1366, 3840)}, 38.9px);
  width: 100%;
  min-height: clamp(40px, ${getFluidSizeWithFullFormula('px', 40, 97.2, 1366, 3840)}, 97.2px);

  &[disabled] {
    opacity: 0.5;
  }

  @media (max-width: ${breakpointsCross.M.max}) {
    flex: 1;
  }
`

type DoorId = string
type QuantityByDoor = string

type Props = {
  size: AfaSize
  cartProducts: AfaCartProduct[]
  doorId: string
  dropType: DropType
  availabilityDate: DdMmYyyyDateString
  minDeliveryDate: DdMmYyyyDateString
  customDeliveryDate?: DdMmYyyyDateString
  className?: string
  status: string
  disabled?: boolean
  availability?: number
  updatedAvailability?: number
  setUpdatedAvailability: React.Dispatch<React.SetStateAction<number | undefined>>
  updateAfaCart: (products: UpdateAfaCartProductPayload[]) => void
  afaCatalogId?: 'A' | 'L' | 'P' //P: Prebook; L: Late Prebook; A: At Once
}

const calculateValuesByDoor = (cartProducts: AfaCartProduct[], doors: string[]) => {
  const initialValues = doors.reduce((result, doorId) => {
    result[doorId] = '0'
    return result
  }, {} as Record<DoorId, QuantityByDoor>)

  const updatedValues = cartProducts.reduce((result, cartProduct) => {
    // Values are stored ad string to be able to set them to empty string onFocus when the value is 0
    result[cartProduct.doorId] = (
      Number(result[cartProduct.doorId]) + cartProduct.unconfirmedQuantity
    ).toString()
    return result
  }, initialValues)

  return updatedValues
}

const getValueOfCurrentDoor = (valuesByDoor: Record<string, string>, doorId: string) => {
  // the value can be undefined if customer doors are not loaded yet
  return valuesByDoor[doorId] === undefined ? '0' : valuesByDoor[doorId]
}

export const SizeInput: React.FC<Props> = ({
  className,
  size,
  cartProducts,
  status,
  doorId,
  dropType,
  availabilityDate,
  minDeliveryDate,
  customDeliveryDate,
  disabled,
  availability,
  updatedAvailability,
  setUpdatedAvailability,
  updateAfaCart,
  afaCatalogId,
}) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const isCartAdjustOpen = useSelector(afaCartAdjustSelectors.isOpen)
  const [searchParams] = useSearchParams()
  const selectedDeliveryDate = searchParams.get('delivery') || ''

  const cartQuery = useGetAfaCartQuery()
  const cartProductsQuery = useMemo(() => {
    return cartQuery.data?.items || []
  }, [cartQuery.data])

  const sizeInputRef = useRef<HTMLInputElement>(null)

  const isWBModal = useSelector(openAddItemsInCartSelector)
  const productsPreAddedToCart = useSelector(afaCartAdjustSelectors.cartProducts)
  const alwaysExpiredDrop = useSelector(
    appSelectors.isActiveDebugger(DebuggerFeatures.AFA_ALWAYS_EXPIRED_DROP),
  )
  const availabilityCheckBypass = useSelector(
    appSelectors.isActiveDebugger(DebuggerFeatures.AFA_AVAILABILITY_CHECK_BYPASS),
  )

  const cartProductOnDoors = useMemo(() => {
    return cartProducts.filter(cartProduct => {
      return (
        cartProduct.upc === size.upc &&
        cartProduct.deliveryDate === (customDeliveryDate || minDeliveryDate) &&
        cartProduct.dropType === dropType
      )
    })
  }, [cartProducts, size.upc, customDeliveryDate, minDeliveryDate, dropType])

  const cartProductOnDoorsAllDeliveries = useMemo(() => {
    return cartProductsQuery.filter(cartProduct => {
      return cartProduct.upc === size.upc && cartProduct.dropType === dropType
    })
  }, [cartProductsQuery, size.upc, dropType])

  const preAddedProductsOnDoors = useMemo(() => {
    return productsPreAddedToCart.filter(
      product =>
        product.upc === size.upc &&
        product.availabilityDate === availabilityDate &&
        product.dropType === dropType,
    )
  }, [productsPreAddedToCart, size.upc, availabilityDate, dropType])

  const activeDoors = useSelector(activeDoorsIdsSelector)

  const [valuesByDoor, setValuesByDoor] = useState<Record<DoorId, QuantityByDoor>>(
    calculateValuesByDoor(cartProductOnDoors, activeDoors),
  )

  useEffect(() => {
    setValuesByDoor(
      calculateValuesByDoor(isWBModal ? preAddedProductsOnDoors : cartProductOnDoors, activeDoors),
    )
  }, [cartProductOnDoors, activeDoors, isWBModal, preAddedProductsOnDoors])

  const updateCart = (val: string, updatedAvailability?: number) => {
    let quantity = val === '' || isNaN(val as any) || val === undefined ? 0 : Number(val)

    if (!availabilityCheckBypass && updatedAvailability !== undefined && updatedAvailability < 0) {
      const availableQuantity = quantity + updatedAvailability
      if (availableQuantity < 0) {
        quantity = 0
        setUpdatedAvailability(availableQuantity)
      } else {
        quantity = availableQuantity
        setUpdatedAvailability(0)
      }
      setValuesByDoor(oldValue => {
        if (oldValue[doorId] && Number(oldValue[doorId]) > 0) {
          errorNotification({
            message: t('Afa.Cart.cannotSetQuantityGreaterThenAvailability', {
              quantity: availableQuantity,
              modelCode: size.modelCode,
            }),
          })
        }
        return { ...oldValue, [doorId]: availableQuantity.toString() }
      })
    }

    const cartProducts = cartProductOnDoors
      .filter(cartProduct => cartProduct.doorId === doorId)
      .map(cartProduct => ({ ...cartProduct }))

    const totalQuantity = cartProducts.reduce((result, cartProduct) => {
      return result + cartProduct.unconfirmedQuantity
    }, 0)

    const isAdding = quantity > totalQuantity

    const sortedCartProducts = cartProducts.sort((a, b) => {
      return (
        (convertDdMmYyyyToDate(a.deliveryDate).getTime() -
          convertDdMmYyyyToDate(b.deliveryDate).getTime()) *
        (isAdding ? 1 : -1)
      )
    })
    let deltaQuantity = Math.abs(quantity - totalQuantity)

    if (isAdding) {
      if (sortedCartProducts.length > 0) {
        sortedCartProducts[0].unconfirmedQuantity += deltaQuantity
      }
    } else {
      while (deltaQuantity > 0) {
        const cartProduct = sortedCartProducts.find(
          ({ unconfirmedQuantity }) => unconfirmedQuantity > 0,
        )
        if (cartProduct) {
          cartProduct.unconfirmedQuantity--
        }
        deltaQuantity--
      }
    }
    const updatedProducts =
      sortedCartProducts.length > 0
        ? sortedCartProducts.map(cartProduct => {
            return {
              ...cartProduct,
              quantity: cartProduct.unconfirmedQuantity,
              afaCatalogId,
            }
          })
        : [
            {
              upc: size.upc,
              quantity,
              doorId,
              modelCode: size.modelCode,
              colorCode: size.colorCode,
              brandCode: size.brandCode,
              brandCodeParent: size.brandCodeParent,
              status,
              dropType,
              availabilityDate,
              minDeliveryDate,
              deliveryDate: customDeliveryDate || minDeliveryDate,
              afaCatalogId,
            },
          ]

    const mappedProducts = !alwaysExpiredDrop
      ? updatedProducts
      : updatedProducts.map(cartProduct => {
          return {
            ...cartProduct,
            dropType: 'DROP' as DropType,
            afaCatalogId: 'P' as AfaCatalogId,
            availabilityDate: '01-01-1970',
            minDeliveryDate: '01-01-1970',
            deliveryDate: '01-01-1970',
          }
        })

    isCartAdjustOpen || isWBModal
      ? dispatch(afaCartAdjustActions.upsertProducts(mappedProducts))
      : updateAfaCart(mappedProducts)
  }

  return (
    <Input
      className={className}
      value={getValueOfCurrentDoor(valuesByDoor, doorId)}
      disabled={
        disabled || (availability === undefined && (dropType === 'SEASON' || afaCatalogId === 'L'))
      }
      type="number"
      ref={sizeInputRef}
      onChange={e => {
        if (e.target.value === '-') {
          return
        }

        const absTargetValue = Math.abs(Number(e.target.value))

        let typingUpdatedAvailability = updatedAvailability

        if (availability !== undefined) {
          const quantitiesOnOtherDoors = Object.entries(valuesByDoor)
            .filter(([cartProductDoorId]) => cartProductDoorId !== doorId)
            .reduce((result, [, quantity]) => result + Number(quantity), 0)

          const quantitiesOnOtherDeliveries = cartProducts
            .filter(
              cartProduct =>
                cartProduct.upc === size.upc &&
                cartProduct.deliveryDate !== minDeliveryDate &&
                cartProduct.availabilityDate === availabilityDate &&
                cartProduct.dropType === dropType,
            )
            .reduce((result, cartProduct) => result + cartProduct.unconfirmedQuantity, 0)

          const quantitiesOnOtherDoorsAndDeliveries = cartProductOnDoorsAllDeliveries
            .filter(
              cartProduct =>
                cartProduct.doorId !== doorId || cartProduct.deliveryDate !== selectedDeliveryDate,
            )
            .reduce((result, product) => result + Number(product.unconfirmedQuantity), 0)

          typingUpdatedAvailability =
            availability -
            absTargetValue -
            (isCartAdjustOpen === AfaCartAdjustType.MANAGE_QUANTITIES
              ? quantitiesOnOtherDoorsAndDeliveries
              : quantitiesOnOtherDoors - quantitiesOnOtherDeliveries)

          setUpdatedAvailability(typingUpdatedAvailability)
        }

        setValuesByDoor(oldValue => ({ ...oldValue, [doorId]: absTargetValue.toString() }))
        updateCart(absTargetValue.toString(), typingUpdatedAvailability)
      }}
      onFocus={() => {
        const oldValue = valuesByDoor[doorId]
        setValuesByDoor(oldValues => ({ ...oldValues, [doorId]: '' }))
        setTimeout(() => {
          if (oldValue !== '0') {
            setValuesByDoor(oldValues => ({ ...oldValues, [doorId]: oldValue }))
          }
        }, 1)
      }}
      onKeyDown={e => {
        if (e.key == 'Enter') {
          updateCart(e.currentTarget.value)
        }
      }}
    />
  )
}
