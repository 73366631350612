import React, { SVGProps } from 'react'

const OpenOthersIcon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <defs>
      <filter id="8wav9mjn1a" colorInterpolationFilters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g
            filter="url(#8wav9mjn1a)"
            transform="translate(-1489 -484) translate(1036 234) translate(453 85)"
          >
            <g fill="#000" fillRule="nonzero">
              <path
                d="M15.84 2.88H.48c-.265 0-.48.215-.48.48v15.36c0 .265.215.48.48.48h15.36c.265 0 .48-.215.48-.48V3.36c0-.265-.215-.48-.48-.48zm-.48 15.36H.96V3.84h14.4v14.4z"
                transform="translate(2.4 167.4)"
              />
              <path
                d="M18.72 0H3.36c-.265 0-.48.215-.48.48s.215.48.48.48h14.88v14.88c0 .265.215.48.48.48s.48-.215.48-.48V.48c0-.265-.215-.48-.48-.48zM4.32 11.52h3.36v3.36c0 .265.215.48.48.48s.48-.215.48-.48v-3.36H12c.265 0 .48-.215.48-.48s-.215-.48-.48-.48H8.64V7.2c0-.265-.215-.48-.48-.48s-.48.215-.48.48v3.36H4.32c-.265 0-.48.215-.48.48s.215.48.48.48z"
                transform="translate(2.4 167.4)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default OpenOthersIcon
