import classnames from 'classnames'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import devices from '../../../config/devices'
import { isTableDevice } from '../../../libs/url'
import { CmsContent } from '../../../model/content'
import { EmitEvent } from '../../../model/model'
import { AdvDatesAttribute } from '../../../model/product'
import { openedBrandsSelector } from '../../../store/brands/selectors'
import { contentAdvSelector } from '../../../store/content/selectors'
import { play_video } from '../../../store/couvetteVideo/actions'
import { couvetteVideoIdSelector } from '../../../store/couvetteVideo/selectors'
import { palette } from '../../../style/theme'
import PageSocketManager, { PageSocketManagerProps } from '../../PageSocketManager'

const Svg = styled.svg`
  width: 1.2em;
  height: 1.2em;

  #background {
    fill: white;
  }
  #text,
  #outline {
    fill: ${palette.kobi};
  }
  &.interactive {
    cursor: pointer;
    #text {
      fill: ${palette.white};
    }
    #background {
      fill: transparent;
    }
    #outline {
      fill: ${palette.congressBlue};
    }
  }
  &.active {
    #text {
      fill: ${palette.congressBlue};
    }
    #outline {
      fill: ${palette.cornflowerBlue};
    }
    #background {
      fill: ${palette.selago};
    }
  }
`

type Props = {
  mocoCode: string
  dates?: AdvDatesAttribute | undefined
  emitEvent: EmitEvent
} & PageSocketManagerProps

export const AdvIcon: React.FC<Props> = ({ mocoCode, dates, emitEvent }) => {
  const advContents = useSelector(contentAdvSelector)
  const playingMediaId = useSelector(couvetteVideoIdSelector)
  const openedBrands = useSelector(openedBrandsSelector)

  const dispatch = useDispatch()

  const advContent = advContents[mocoCode]

  const mediaToSend = advContent?.advLandscape || advContent?.advPortrait

  const typeClass = mediaToSend ? 'interactive' : 'static'

  const getUniqueMediaId = (mediaToSend: CmsContent) =>
    mediaToSend ? `${mediaToSend.id}_${mocoCode}` : ''

  const emitToggleAdv = (e?: React.MouseEvent) => {
    // "e" needs to be declared "undefined" because the test goes wrong
    e?.stopPropagation()
    // if the component is associated to a moco with no adv contents it should not
    // trigger any kind of event

    if (!mediaToSend || !isTableDevice()) return

    const mediaUniqueId = getUniqueMediaId(mediaToSend)
    if (playingMediaId && playingMediaId === mediaUniqueId) {
      emitEvent('ended_video')
    } else {
      emitEvent(
        'play_video',
        {
          brand: openedBrands[0],
        },
        [devices.table],
      )

      dispatch(play_video(mediaToSend, mediaUniqueId, dates as string[]))
    }
  }

  return (
    <Svg
      id={`adv-button-${getUniqueMediaId(mediaToSend)}`}
      onClick={emitToggleAdv}
      className={classnames({
        anticon: true,
        'rc-icon': true,
        adv: true,
        [typeClass]: true,
        active: playingMediaId && playingMediaId === getUniqueMediaId(mediaToSend),
      })}
      viewBox="0 0 44 44"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <path
          id="outline"
          d="m42.312,4c2.59,0 4.688,2.014 4.688,4.5l0,26.997c0,2.485 -2.099,4.5 -4.688,4.5l-1.56,0l0,0.003l-3.126,0l0,-0.003l-35.928,0c-2.588,0 -4.686,-2.015 -4.686,-4.5l0,-22.498l-0.012,0l0,-3l0.012,0l0,-1.499c0,-2.486 2.098,-4.5 4.686,-4.5l40.614,0z"
          fill="#63b1e5"
        />
        <path
          id="background"
          d="m44.066,35.497l0,-26.998c0,-0.828 -0.705,-1.499 -1.572,-1.499l-40.857,0c-0.865,0 -1.57,0.671 -1.57,1.5l0,26.997c0,0.828 0.705,1.499 1.57,1.499l40.857,0c0.867,0 1.572,-0.671 1.572,-1.5"
          fill="#e9f3fc"
        />
        <path
          id="text"
          d="m3,28.912l5.58,-12.912l2.353,0l5.617,12.912l-3.21,0l-1.112,-2.736l-5.015,0l-1.076,2.736l-3.137,0zm8.261,-5.143l-1.586,-4.012l-1.569,4.012l3.155,0zm5.945,5.143l0,-12.912l4.267,0c4.158,0 7.642,1.769 7.642,6.492c0,4.177 -3.41,6.42 -7.259,6.42l-4.65,0zm2.845,-10.286l0,7.66l1.477,0c2.644,0 4.632,-1.003 4.632,-3.94c0,-2.534 -2.024,-3.72 -4.431,-3.72l-1.679,0l0.001,0zm14.242,10.286l-5.161,-12.912l3.246,0l3.191,8.462l0.037,0l3.246,-8.462l3.046,0l-5.326,12.912l-2.28,0l0.001,0z"
          fill="${palette.congressBlue}"
        />
      </g>
    </Svg>
  )
}

const AdvIconManager = PageSocketManager(AdvIcon)

export default AdvIconManager
