import { useParams, useRouteMatch } from 'react-router'

import { getDeviceAbsolutePath } from '../libs/url'

export const useIsKeyLooksPage = () => {
  const { assortment, brandCode } = useParams<{ assortment: string; brandCode: string }>()

  return useRouteMatch(
    `${getDeviceAbsolutePath()}/${assortment}/single-brand/${brandCode}/key-looks`,
  )
}
