import { CartProduct, CartState, UpdateCartPayloadItem } from '../model/cart'

const areItemAndProductEqual = (item: UpdateCartPayloadItem | CartProduct, product: CartProduct) =>
  item.upc === product.upc &&
  item.doorId === product.doorId &&
  (item.confirmedQuantity || 0) === (product.confirmedQuantity || 0) && // sometimes confirmedQuantity is undefined on one product and 0 on the other
  item.status === product.status

const currentProductIsToRemove = (
  currentProduct: CartProduct,
  itemsToRemove: (UpdateCartPayloadItem | CartProduct)[],
) => itemsToRemove.find(item => areItemAndProductEqual(item, currentProduct))

const updateCurrentProduct = (
  currentProduct: CartProduct,
  itemsToUpdate: UpdateCartPayloadItem[],
): CartProduct => {
  const itemToUpdate = itemsToUpdate.find(item => areItemAndProductEqual(item, currentProduct))

  return itemToUpdate
    ? {
        ...currentProduct,
        quantity: itemToUpdate.quantity,
        notes: itemToUpdate.notes,
        deliveryDate: itemToUpdate.deliveryDate,
        reference: itemToUpdate.reference,
        minDeliveryDate: itemToUpdate.minDeliveryDate,
      }
    : currentProduct
}

const omitOldQnt = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  oldQnt,
  ...cartProduct
}: UpdateCartPayloadItem): Omit<UpdateCartPayloadItem, 'oldQnt'> => ({
  ...cartProduct,
})

/**
 * @param {*} items
 * @param {*} reorderItems
 * @param {*} currentCart
 */
export const getUpdatedCart = (
  items: UpdateCartPayloadItem[],
  state: { cart: CartState },
  warnings?: { upc: string; doorId: string; faultmessage: string }[],
) => {
  const currentCart = state.cart.cartData
  const currentCartProducts = currentCart?.products || []
  const itemsToRemove = items.filter(({ quantity, status }) => quantity === 0 && status === '1')
  const currentCartToRemove = currentCartProducts.filter(product =>
    warnings?.find(
      ({ upc, doorId }) =>
        upc === product.upc &&
        doorId === doorId &&
        !items.find(item => item.upc === upc && item.doorId === doorId),
    ),
  )
  const itemsToAddOrUpdate = items.filter(item => {
    const quantityIncreased = item.quantity > 0
    const notesInserted = item.notes && item.notes.length > 0
    const referenceInserted = item.reference && item.reference.length > 0
    const dateInserted = item.deliveryDate && item.deliveryDate.length > 0

    return quantityIncreased || notesInserted || referenceInserted || dateInserted
  })

  const itemsToUpdate = itemsToAddOrUpdate.filter(item => {
    const productInCart = currentCartProducts?.find(
      ({ upc, doorId, status }) =>
        upc === item.upc && doorId === item.doorId && status === item.status,
    )
    return Boolean(productInCart)
  })
  const itemsToAdd = itemsToAddOrUpdate
    .filter(item => !itemsToUpdate.includes(item))
    .map(omitOldQnt)

  const toRemove = [...itemsToRemove, ...currentCartToRemove]

  const updatedProducts = currentCartProducts
    ?.filter(currentProduct => !currentProductIsToRemove(currentProduct, toRemove))
    .map(currentProduct => updateCurrentProduct(currentProduct, itemsToUpdate))
    .concat(itemsToAdd)
  const updatedCart = {
    ...currentCart,
    loading: false,
    loaded: true,
    products: updatedProducts,
  }

  return updatedCart
}
