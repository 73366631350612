import jwt from 'jsonwebtoken'

import app_config from '../config/app/config'
import { DecodedToken } from '../model/model'
import { deleteIntersessionCustomerId } from './customer'
import { deleteIntersessionEventId } from './event'

export const setIntersessionAuthToken = (authToken: string) => {
  if (authToken) {
    localStorage.setItem(app_config.authKey, authToken)
  }
}

export const deleteIntersessionAuthToken = () => {
  localStorage.removeItem(app_config.authKey)
}

export const getIntersessionAuthToken = () => {
  const token = localStorage.getItem(app_config.authKey)
  const decoded = token && (jwt.decode(token) as DecodedToken)
  if (!decoded) {
    return null
  }

  const expiration = decoded.exp * 1000
  const now = Date.now()
  const isExpired = expiration < now
  const THIRTY_MINUTES = 1000 * 60 * 30
  const isExpiring = expiration - now < THIRTY_MINUTES

  if (isExpired || isExpiring) {
    deleteIntersessionAuthToken()
  }

  return isExpired || isExpiring ? null : token
}

export const getWCSToken = (token: string | null) => {
  const decoded = token && (jwt.decode(token) as DecodedToken)

  return !decoded || !decoded.userData ? '' : decoded.userData.wcsToken
}

export const getUserName = (token: string | null) => {
  const decoded = token && (jwt.decode(token) as DecodedToken)

  return !decoded ? '' : decoded.userData.userId
}

export const isTokenExpired = (token: string) => {
  const decodedToken = jwt.decode(token) as DecodedToken
  return token && decodedToken && Date.now() / 1000 <= decodedToken.exp
}

export const isTokenValid = (token: string) => {
  const decodedToken = jwt.decode(token)
  return token && decodedToken
}

export const isAuthenticated = (token: string | null) => {
  const isValid = token && isTokenValid(token)

  return !!isValid
}

export const getRole = (token: string | null) => {
  const decoded = token && (jwt.decode(token) as DecodedToken)
  return decoded && decoded.userData ? decoded.userData.role : ''
}

const POST_LOGIN_REDIRECT_URI_KEY = 'rc-post-login-redirect-uri'
export const setPostLoginRedirectUri = (
  roomName?: string,
  deviceName?: string,
  roomType?: string,
) => {
  if (roomName && deviceName && roomType) {
    sessionStorage.setItem(POST_LOGIN_REDIRECT_URI_KEY, `/${roomName}/${roomType}/${deviceName}`)
  }
}

export const getPostLoginRedirectUri = () => {
  return sessionStorage.getItem(POST_LOGIN_REDIRECT_URI_KEY)
}

export const deletePostLoginRedirectUri = () => {
  sessionStorage.removeItem(POST_LOGIN_REDIRECT_URI_KEY)
}

export const deleteIntersessionInfo = () => {
  deleteIntersessionAuthToken()
  deleteIntersessionEventId()
  deleteIntersessionCustomerId()
}
