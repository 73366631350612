import { RootState } from '../../model/model'
import { createSelector } from '@reduxjs/toolkit'

export const afaCartIsLoadingSelector = (state: RootState) => state.afaCart.isLoading

export const afaCartIsUpdatungSelector = (state: RootState) => state.afaCart.isUpdating

export const afaCartDataSelector = (state: RootState) => state.afaCart.data

export const afaCartProductDetailsSelector = (modelCode: string) => (state: RootState) =>
  state.afaCart.data && state.afaCart.data[modelCode]

export const afaCartAllProductDetailsSelector = (state: RootState) => state.afaCart.data

export const afaCartErrorSelector = (state: RootState) => state.afaCart.error

export const afaCartIsErrorSelector = (state: RootState) => state.afaCart.isError

export const getSelectedKeys = (state: RootState) => state.afaCart.selectedKeys

export const getDeliveryReference = (state: RootState) => state.afaCart.deliveryReferences

const dropsRealtime = createSelector(
  (state: RootState) => state.afaCart.dropsRealtime,
  dropsRealtime => dropsRealtime,
)

const isCancellationDateDialogOpen = createSelector(
  (state: RootState) => state.afaCart.cancellationDateDialogOpen,
  cancellationDateDialogOpen => cancellationDateDialogOpen,
)

export const afaCartSelectors = { dropsRealtime, isCancellationDateDialogOpen }
