import React from 'react'
import styled from 'styled-components'

import { Badge } from 'antd'
import { palette, pxToRem } from '../../../style/theme'
import { getAntBadgeCountCss } from '../../CommonComponents'

const BadgeWrapper = styled.div`
  display: flex;
`

const StyledBadge = styled(Badge)`
  font: inherit;
  align-items: center;
  align-content: center;
  justify-content: center;
  /* min-height to match spinner, otherwise when spinner appears the container grows bigger */
  min-height: ${pxToRem(14)}rem;

  ${getAntBadgeCountCss(palette.hibiscus)}

  &.cart-badge {
    .ant-badge-count {
      border-radius: ${pxToRem(10)}rem 0 0 ${pxToRem(10)}rem;
    }
  }

  &.processed {
    ${getAntBadgeCountCss(palette.dustyGray)}

    .ant-badge-count {
      border-radius: ${pxToRem(10)}rem;
    }

    &.cart-badge-processed {
      .ant-badge-count {
        border-radius: 0 ${pxToRem(10)}rem ${pxToRem(10)}rem 0;
      }
    }
  }
`

type Props = {
  count: number
  processed?: number
}

const FooterBadge: React.FC<Props> = ({ count, processed }) => {
  const processedCount = processed || 0

  return (
    <BadgeWrapper>
      <StyledBadge
        overflowCount={999999}
        count={count}
        className={processedCount > 0 ? 'cart-badge' : ''}
      />

      <StyledBadge
        overflowCount={999999}
        count={processedCount}
        className={count > 0 ? 'processed cart-badge-processed' : 'processed'}
      />
    </BadgeWrapper>
  )
}

export default FooterBadge
