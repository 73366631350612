import { createSelector } from '@reduxjs/toolkit'

import { getBrandByBrandCode } from '../../libs/brand'
import { Brand } from '../../model/brand'
import { RootState } from '../../model/model'
import { brandsSelector } from '../brands/selectors'

export const couvettesSelector = (state: RootState) => state.couvettes

export const couvettesItemsSelector = (state: RootState) => state.couvettes.items

export const couvettesBrandsSelector = createSelector(
  brandsSelector,
  couvettesSelector,
  (brands: Brand[], couvettes) =>
    couvettes.brands
      .map(brandCode => {
        const brand = getBrandByBrandCode(brands, brandCode)
        return brand ? brand.slug : undefined
      })
      .filter(Boolean),
)

export const loadedProductsSelector = (state: RootState) => state.couvettes.loadedProducts

export const isCouvettesLoadingSelector = (state: RootState) =>
  state.couvettes.loading && !state.couvettes.loaded

export const isCouvettesLoadedgSelector = (state: RootState) =>
  !state.couvettes.loading && state.couvettes.loaded

export const isCouvetteFirstRequestSelector = (state: RootState) =>
  state.couvettes.pagination.isFirstRequest

export const couvettesNoMorePagesSelector = (state: RootState) => state.couvettes.noMorePages

export const isGridViewSelector = (state: RootState) => state.couvettes.isGridView

export const thereIsMorePagesSelector = (state: RootState) => !state.couvettes.noMorePages

export const sortSelector = (state: RootState) => state.couvettes.pagination.sort

export const isComingFromFiltersSelector = (state: RootState) => state.couvettes.isComingFromFilters
