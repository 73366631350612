import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux'
import { customerDoorsSelector } from '../../../store/customer/selectors'
import { useGetSelectedDoorId, useSelectDoorId } from '../Hooks/usePreselectDefaultDoor'
import CloseIcon from '../../../components/icons/CloseIcon'
import { palette } from '../../../style/theme'
import assortmentAdvisorActions from '../../../store/assortmentAdvisor/actions'
import { useSearchParams } from '../../../hooks/useSearchParams'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  transition: transform 0.25s;
  transform: translateY(150%);
  background-color: ${palette.blueZodiac};
  z-index: 1000;
  min-width: 40vw;
  display: flex;
  flex-direction: column;
  font-size: 1vw;

  &.is-open {
    transform: translateY(-100%);
  }
`

const Doors = styled.div`
  max-height: 40vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`

const Door = styled.div`
  color: white;
  padding: 1vw 1.5vw;

  &.selected {
    background-color: ${palette.cornflowerBlueAA};
  }

  input {
    display: none;
  }

  label {
    cursor: pointer;
  }
`

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  gap: 2vw;
  color: white;
  padding: 1.5vw;

  input {
    flex: 1;
    font-size: inherit;
    line-height: 1;
    height: 1.5em;
  }

  button {
    width: 1.25em;
    height: 1.25em;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`

type Props = {
  isOpen: boolean
  setLayerOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const DoorsLayer: React.FC<Props> = ({ isOpen, setLayerOpen }) => {
  const dispatch = useDispatch()
  const [searchTerm, setSearchTerm] = useState('')

  const doors = useSelector(customerDoorsSelector)

  const selectedDoorId = useGetSelectedDoorId()
  const selectDoorId = useSelectDoorId()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (doors.length === 1 && !selectedDoorId) {
      selectDoorId(doors[0].id)
    }
  }, [doors, selectedDoorId, selectDoorId])

  return (
    <Wrapper className={isOpen ? 'is-open' : ''}>
      <Doors>
        {doors
          .filter(({ id, name }) =>
            !searchTerm
              ? true
              : id.toLowerCase().includes(searchTerm) || name.toLowerCase().includes(searchTerm),
          )
          .map(({ id, name }) => (
            <Door key={id} className={selectedDoorId === id ? 'selected' : ''}>
              <input
                type="radio"
                name="door-selection"
                value={id}
                id={`door-selection-${id}`}
                onClick={() => {
                  dispatch(assortmentAdvisorActions.setDefaultAddress(true))
                  selectDoorId(id)
                  setLayerOpen(false)
                  if (selectedDoorId === id) {
                    searchParams.delete('searchedAddress')
                    setSearchParams(searchParams, { replace: true })
                  } else {
                    dispatch(assortmentAdvisorActions.setMapDragged(false))
                  }
                }}
              />
              <label htmlFor={`door-selection-${id}`}>
                {id} {name}
              </label>
            </Door>
          ))}
      </Doors>

      <SearchBox>
        <input
          type="text"
          onChange={e => setSearchTerm(e.currentTarget.value.toLowerCase())}
          value={searchTerm}
        />
        <button type="reset" onClick={() => setSearchTerm('')}>
          <CloseIcon />
        </button>
      </SearchBox>
    </Wrapper>
  )
}

export default DoorsLayer
