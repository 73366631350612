import { createGlobalStyle } from 'styled-components'

import app_config from '../config/app/config'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Space Grotesk';
    src: url('${app_config.publicUrl}/assets/fonts/space-grotesk/SpaceGrotesk-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
      font-family: 'Space Grotesk';
      src: url('${app_config.publicUrl}/assets/fonts/space-grotesk/SpaceGrotesk-Medium.woff2') format('woff2');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Space Grotesk';
      src: url('${app_config.publicUrl}/assets/fonts/space-grotesk/SpaceGrotesk-Light.woff') format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Space Grotesk';
      src: url('${app_config.publicUrl}/assets/fonts/space-grotesk/SpaceGrotesk-Bold.woff2') format('woff2');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Space Grotesk';
      src: url('${app_config.publicUrl}/assets/fonts/space-grotesk/SpaceGrotesk-Regular.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }

  button {
    cursor: pointer;
  }

  body,
  #root {
    font-family: 'Space Grotesk';
  }
`

export default GlobalStyle
