import styled from 'styled-components/macro'
import { breakpointsCross, palette, pxToRem } from '../../../../style/theme'
import { getFluidSizeWithFullFormula as gF } from '../../../../style/theme'

export const Wrapper = styled.div`
  background-color: ${palette.extraLight};
  border-radius: 8px;
  color: ${palette.cloudBurst};
  font-size: ${pxToRem(14)}rem;
  margin-bottom: ${gF('px', 20, 40, 1366, 3840)};
  padding: ${pxToRem(16)}rem ${pxToRem(24)}rem;

  @media screen and (max-height: ${breakpointsCross.S.max}) {
    margin-bottom: 0;
  }
`

export const Header = styled.div`
  margin-bottom: ${pxToRem(4)}rem;
`

export const Title = styled.div`
  font-size: ${gF('px', 16, 32, 1366, 3840)};
  font-family: GilmerBold;
  padding-bottom: ${pxToRem(4)}rem;
`

export const Subtitle = styled.div`
  font-size: ${gF('px', 14, 24, 1366, 3840)};
`

export const Icon = styled.div`
  padding-right: 5px;
  display: inline-block;
`

export const ChildrenWrapper = styled.div`
  max-height: ${gF('px', 60, 90, 1366, 3840)};
  overflow-y: auto;
`
