import { useSelector } from 'react-redux'

import {
  cartProductsFilteredByActiveDoorsSelector,
  mocosInCartFilteredBy_doors_activeBrand_filters__Selector,
} from '../store/cart/selectors'
import {
  cartModeSelector,
  filteredStarsProductsSelector,
  starsMocosFilteredSelecor,
} from '../store/stars/selectors'

const useGetCartOrStarProductsAndMocoNumericalOrdered = () => {
  const mocosInCart = useSelector(mocosInCartFilteredBy_doors_activeBrand_filters__Selector)
  const cartProducts = useSelector(cartProductsFilteredByActiveDoorsSelector)
  const starsProducts = useSelector(filteredStarsProductsSelector)
  const starsMocos = useSelector(starsMocosFilteredSelecor)
  const mode = useSelector(cartModeSelector)

  return mode === 'stars'
    ? {
        mocos: starsMocos.sort((a, b) => (a.modelCode < b.modelCode ? -1 : 1)),
        products: starsProducts,
      }
    : {
        mocos: mocosInCart,
        products: cartProducts,
      }
}

export default useGetCartOrStarProductsAndMocoNumericalOrdered
