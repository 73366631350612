import { Button, Icon } from 'antd'
import classnames from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CustomerState, PricePrefs } from '../../../../model/customer'
import { getFluidFontSize, palette } from '../../../../style/theme'
import { OnOffToggle } from '../../../../styleguide/OnOffToggle'

const MenuTitle = styled.span`
  font-size: ${getFluidFontSize('1.2rem')} !important;
`

const StyledIcon = styled(Icon)`
  font-size: ${getFluidFontSize('1.2rem')} !important;
`

const StyledCheckboxWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  span:last-child {
    border-bottom: none;
  }
  .ant-checkbox-wrapper {
    padding: 5px 0;
  }
`

const CheckboxContent = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  box-shadow: none;
  font-size: 1rem;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid ${palette.alto};
  color: ${palette.congressBlue};
`
const LabelText = styled.span`
  padding: 5px 0;
  font-size: ${getFluidFontSize('1rem')} !important;
  white-space: normal;
`

const OptionInfo = styled.span`
  display: block;
  font-size: ${getFluidFontSize('1rem')} !important;
  padding-top: 10px;
  text-wrap: wrap;
  color: ${palette.congressBlue};
`

const SHOW_CART_TOTAL = 'showCartTotal'
const SHOW_SR_PRICE = 'showSrPrice'
const SHOW_WHS_PRICE = 'showWhsPrice'

type Props = {
  customer: CustomerState
  goBack: () => void
  onChangePricePrefs: (pricePref: PricePrefs) => void
}

export const FooterLoginPricePrefs: React.FC<Props> = ({
  customer,
  goBack,
  onChangePricePrefs,
}) => {
  const { t } = useTranslation()
  const isAfa = window.location.href.includes('afa')

  const options: {
    label: string
    info?: string
    value: keyof PricePrefs & keyof CustomerState
  }[] = isAfa
    ? [
        {
          label: t('FooterNav.price_settings_showSrPrice'),
          value: SHOW_SR_PRICE,
        },
        { label: t('FooterNav.price_settings_showWhsPrice'), value: SHOW_WHS_PRICE },
      ]
    : [
        {
          label: t('FooterNav.price_settings_showSrPrice'),
          value: SHOW_SR_PRICE,
        },
        { label: t('FooterNav.price_settings_showWhsPrice'), value: SHOW_WHS_PRICE },
        { label: t('FooterNav.price_settings_showTotalPrice'), value: SHOW_CART_TOTAL },
      ]

  const [pricePrefs, setPricePrefs] = useState(
    options.reduce((acc, opt) => {
      acc[opt.value] = !!customer[opt.value]
      return acc
    }, {} as PricePrefs),
  )

  const onChange = (el: { target: { checked: boolean } }, field: keyof PricePrefs) => {
    const newPricePrefs = { ...pricePrefs }
    newPricePrefs[field] = el.target.checked
    setPricePrefs(newPricePrefs)
    onChangePricePrefs(newPricePrefs)
  }

  return (
    <div className={classnames('menu-container', 'price-prefs', 'checkbox-switch')}>
      <div className="menu-header">
        <Button onClick={goBack}>
          <StyledIcon type="left" onClick={goBack} />
        </Button>
        <MenuTitle className="menu-title">{t('FooterNav.price_settings')}</MenuTitle>
      </div>
      <div className="menu-list">
        <StyledCheckboxWrapper>
          {options.map(option => (
            <CheckboxContent key={option.value}>
              <LabelText>{option.label}</LabelText>
              <OnOffToggle
                key={option.value}
                onChange={el => onChange(el, option.value)}
                checked={pricePrefs[option.value]}
              />
            </CheckboxContent>
          ))}
        </StyledCheckboxWrapper>
        <OptionInfo>{t('FooterNav.price_settings_srPriceInfo')}</OptionInfo>
      </div>
    </div>
  )
}

export default FooterLoginPricePrefs
