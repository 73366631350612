import { useGetAfaCartQuery } from '../services/afaCart'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { afaCartDataSelector } from '../store/afaCart/selectors'

const useAfaGetOutOfAssortmentCartProducts = () => {
  const cartQuery = useGetAfaCartQuery()
  const cartProductsDetails = useSelector(afaCartDataSelector)

  const cartProducts = useMemo(() => {
    return cartQuery.data?.items || []
  }, [cartQuery.data])

  const outOfAssortmentCartProducts = useMemo(() => {
    return cartProducts.filter(({ outOfAssortment, modelCode, colorCode }) => {
      return (
        outOfAssortment === true ||
        (cartProductsDetails &&
          (!cartProductsDetails[modelCode] || !cartProductsDetails[modelCode]?.mocos[colorCode]))
      )
    })
  }, [cartProducts, cartProductsDetails])

  return outOfAssortmentCartProducts
}

export default useAfaGetOutOfAssortmentCartProducts
