import { Button, Icon, Radio } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { getFluidFontSize } from '../../../../style/theme'

export const MenuTitle = styled.span`
  font-size: ${getFluidFontSize('1.2rem')} !important;
`

export const StyledIcon = styled(Icon)`
  font-size: ${getFluidFontSize('1.2rem')} !important;
`

export const StyledRadioButton = styled(Radio.Button)`
  font-size: ${getFluidFontSize('0.8rem')} !important;
`

type Props = {
  lang: string
  languages: { code: string; name: string }[]
  goBack: () => void
  onLanguageChanged: (langCode: string) => void
}

export const FooterLoginLanguageMenu: React.FC<Props> = ({
  lang,
  languages,
  goBack,
  onLanguageChanged,
}) => {
  const { t } = useTranslation()
  const option_languages = Object.values(languages || [])

  return (
    <div className="menu-container">
      <div className="menu-header">
        <Button onClick={goBack}>
          <StyledIcon type="left" onClick={goBack} />
        </Button>
        <MenuTitle className="menu-title">{t('FooterNav.language_submenu')}</MenuTitle>
      </div>
      <div className="menu-list">
        <Radio.Group value={lang}>
          {option_languages.map(el => (
            <StyledRadioButton
              value={el.code}
              key={el.code}
              onChange={() => onLanguageChanged(el.code)}
            >
              {el.name}{' '}
            </StyledRadioButton>
          ))}
        </Radio.Group>
      </div>
    </div>
  )
}

export default FooterLoginLanguageMenu
