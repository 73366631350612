import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import CartIcon from '../../../components/icons/CartIcon'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { cartProductsFilteredByActiveDoorsSelector } from '../../../store/cart/selectors'
import { countItemsInCart } from '../../../libs/cart'
import { getDeviceBasePath } from '../../../libs/url'
import { palette, getFluidSizeWithFullFormula } from '../../../style/theme'

const LinkAsButton = styled(Link)`
  color: white;
  display: flex;
  font-size: min(1vw, 35px);
  align-items: center;
  gap: 0.5vw;
  margin-right: ${getFluidSizeWithFullFormula('px', 16, 36, 1366, 3840)};
`

const IconWrapper = styled.div`
  border-radius: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    width: 1.25em;
    height: 1.25em;
  }
`

const Badge = styled.div`
  border-radius: 3rem;
  background-color: ${palette.cornflowerBlueAA};
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2em;
  height: 2em;
  line-height: 1;
  font-size: 0.75vw;
  padding: 0.25em;
  position: absolute;
  top: -1.5em;
  right: -1.5em;
  color: black;
`

const Text = styled.div`
  text-transform: capitalize;
`

const CartButton: React.FC = () => {
  const { t } = useTranslation()

  const cartProductsFilteredByActiveDoors = useSelector(cartProductsFilteredByActiveDoorsSelector)
  const itemsInCart = countItemsInCart(cartProductsFilteredByActiveDoors)

  return (
    <LinkAsButton to={`${getDeviceBasePath()}/cart`}>
      <Text>{t('AA.cart')}</Text>
      <IconWrapper>
        <CartIcon />
        {!!itemsInCart && <Badge>{itemsInCart}</Badge>}
      </IconWrapper>
    </LinkAsButton>
  )
}

export default CartButton
