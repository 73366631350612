import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { palette, pxToRem } from '../../../style/theme'
import { FilterKey } from '../../../model/afa'
import { useSearchParams } from '../../../hooks/useSearchParams'
import CloseIcon from '../../../components/icons/CloseIcon'
import { getAfaFilterTranslation } from '../../../components/AfaFilters/AfaFiltersLayer'

const Wrapper = styled.div`
  padding: ${pxToRem(8)}rem ${pxToRem(18)}rem;
  font-size: ${pxToRem(12)}rem;
  border-radius: 3rem;
  background-color: ${palette.shadowBlue};
  color: white;
  display: flex;
  align-items: center;
  gap: ${pxToRem(6)}rem;

  svg {
    cursor: pointer;
    width: 1.2em;
    height: 1.2em;
  }
`

type Props = {
  filterKey: FilterKey
  option: string
}

const FilterChip: React.FC<Props> = ({ filterKey, option }) => {
  const { t } = useTranslation()

  const [searchParams, setSearchParams] = useSearchParams()

  const options = searchParams.get(filterKey)?.split(',')

  return (
    <Wrapper>
      <span>{getAfaFilterTranslation(t, `Afa.fO.${filterKey}.${option}`, option)}</span>
      <CloseIcon
        onClick={() => {
          const updatedOptions = options
            ?.filter(selectedOption => selectedOption !== option)
            .join(',')
          if (!updatedOptions) {
            searchParams.delete(filterKey)
          } else {
            searchParams.set(filterKey, updatedOptions)
          }
          setSearchParams(searchParams, { replace: true })
        }}
      />
    </Wrapper>
  )
}

export default FilterChip
