import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useGetPlpProductsQuery } from '../../services/afaProducts'
import { breakpoints, getFluidFontSize, palette, pxToRem, spacing } from '../../style/theme'
import AfaFilterButton from './AfaFilterButton'
import AfaSortButton from './AfaSortButton'

const Wrapper = styled.div`
  display: none;

  @media screen and (max-width: ${breakpoints.M}) {
    display: flex;
    justify-content: space-between;
    padding: 0 ${pxToRem(spacing(3))}rem 0;
    gap: ${pxToRem(spacing(6))}rem;
    font-size: ${getFluidFontSize('12px')};
    border-bottom: solid 1px #cecccc;
    height: ${pxToRem(64)}rem;
  }
`

const AfaSearchResutlTopFilterButton = styled(AfaFilterButton)`
  color: ${palette.tangaroa};
`

const LeftCol = styled.div`
  display: flex;
  grid-row-start: 3;

  @media screen and (max-width: ${breakpoints.M}) {
    gap: ${pxToRem(spacing(3))}rem;
    justify-content: end;
    grid-column-start: auto;
    grid-row-start: auto;
  }
`

const NumberOfProducts = styled.div`
  color: ${palette.santasGray};
  display: none;
  align-items: center;
  text-transform: capitalize;

  @media screen and (max-width: ${breakpoints.M}) {
    display: flex;
  }
`

const AfaSearchResultTopbar: React.FC = () => {
  const { t } = useTranslation()

  const productsQuery = useGetPlpProductsQuery()

  return (
    <Wrapper>
      <AfaSearchResutlTopFilterButton />

      <LeftCol>
        <NumberOfProducts>
          {productsQuery.data?.count} {t('Afa.products')}
        </NumberOfProducts>
        <AfaSortButton />
      </LeftCol>
    </Wrapper>
  )
}

export default AfaSearchResultTopbar
