import { isEqual } from 'lodash'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Notification, NotificationsState, NotificationType } from '../../model/notifications'
import { resetForNewClient } from '../extraActions'

const DEFAULT_STATE: NotificationsState = {
  dislikes: [],
  fastAddsToCart: [],
  missingModelCodes: [],
  showNotEditableCartNotification: true,
  slowCartNotification: false,
  socketConnectionStatus: {
    connected: false,
    reconnectionAttempts: 0,
    lostConnectionTimestamp: 0,
  },
  orientationAlert: [
    {
      id: 'orientationAlert',
      show: false,
      codes: [],
      notificationType: 'orientationAlert',
    },
  ],
}

const isSameNotification = (notification: Notification) => (
  alreadyAddedNotification: Notification,
) => isEqual(alreadyAddedNotification.codes.sort(), notification.codes.sort())

type AddNotificationPayload = Omit<Notification, 'show'>
export const slice = createSlice({
  name: 'notifications',
  initialState: DEFAULT_STATE,
  reducers: {
    showNotificationsAction: (state, { payload = 'dislikes' }: PayloadAction<NotificationType>) => {
      state[payload].forEach(notification => {
        notification.show = true
      })
    },
    addNotificationAction: (state, { payload }: PayloadAction<AddNotificationPayload>) => {
      state[payload.notificationType].push({
        ...payload,
        show: false,
      })
    },
    addOrUpdateNotificationAction: (state, { payload }: PayloadAction<AddNotificationPayload>) => {
      const notification = {
        ...payload,
        show: false,
      }
      const notificationToUpdate = state.fastAddsToCart.find(isSameNotification(notification))
      if (!notificationToUpdate) {
        state.fastAddsToCart.push(notification)
      } else {
        state.fastAddsToCart = state.fastAddsToCart.map(notification =>
          !isSameNotification(notificationToUpdate)(notification)
            ? notification
            : {
                ...notification,
                payload: {
                  ...notification.notificationPayload,
                  qntToAdd:
                    (notification.notificationPayload?.qntToAdd || 0) +
                    (payload.notificationPayload?.qntToAdd || 0),
                },
              },
        )
      }
    },
    removeNotificationAction: (
      state,
      {
        payload: { codes, notificationType = 'dislikes', id },
      }: PayloadAction<Pick<Notification, 'notificationType' | 'id'> & { codes?: string[] }>,
    ) => {
      const notifications = state[notificationType].filter(
        notification =>
          (id && notification.id !== id) ||
          (codes && !notification.codes.every(code => codes.includes(code))),
      )

      state[notificationType] = notifications
    },
    removeOrphanDislikeNotificationAction: (state, { payload }: PayloadAction<string[]>) => {
      state.dislikes = state.dislikes.filter(notification =>
        notification.codes.every(notificationCode => payload.includes(notificationCode)),
      )
    },
    disableNotEditableCartNotificationAction: state => {
      state.showNotEditableCartNotification = false
    },
    setSlowCartNotificationAction: (state, { payload }: PayloadAction<boolean>) => {
      state.slowCartNotification = payload
    },
    setSocketConnectionStatus: (
      state,
      { payload }: PayloadAction<{ connected: boolean; reconnectionAttempts: number }>,
    ) => {
      const hasStatusChanged = state.socketConnectionStatus.connected !== payload.connected
      const connectionLostTimestamp = hasStatusChanged
        ? payload.connected
          ? 0
          : Date.now()
        : state.socketConnectionStatus.connectionLostTimestamp

      state.socketConnectionStatus.connected = payload.connected
      state.socketConnectionStatus.reconnectionAttempts = payload.reconnectionAttempts
      state.socketConnectionStatus.connectionLostTimestamp = connectionLostTimestamp
    },
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, state => {
      const socketConnectionStatus = state.socketConnectionStatus
      return {
        ...DEFAULT_STATE,
        socketConnectionStatus: socketConnectionStatus,
      }
    })
  },
})

export default slice.reducer
