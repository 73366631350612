import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Notification } from '../../components/Notification'
import OrientationAlert from '../../components/UI/OrientationAlert'
import UndoNotificationDislike from '../../components/UI/UndoNotification/UndoNotificationDislike'
import UndoNotificationFastAddsCart from '../../components/UI/UndoNotification/UndoNotificationFastAddsCart'
import useOrientationAlert from '../../hooks/useOrientationAlert'
import { isUpdateCartPayload } from '../../hooks/useUpdateCart'
import { languageSelector, roomTypeSelector } from '../../store/app/selectors'
import { cartEditableSelector } from '../../store/cart/selectors'
import {
  disableNotEditableCartNotificationAction,
  removeNotificationAction,
  setSlowCartNotificationAction,
} from '../../store/notifications/actions'
import {
  notificationsSelector,
  showNotEditableCartNotificationSelector,
  slowCartNotificationSelector,
  socketConnectionStatusSelector,
} from '../../store/notifications/selectors'
import { isTableDevice, isTouchDevice } from '../../libs/url'
import { pxToRem } from '../../style/theme'
import styled from 'styled-components'

const NotificationWrpper = styled.div`
  position: fixed;
  bottom: 6vh;
  right: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1020;
  max-width: ${pxToRem(450)}rem;
  > *:not(:last-child) {
    margin-bottom: 1em;
  }
`

const getSocketConnetionLostMessage = (
  socketConnectionStatus: { connectionLostTimestamp?: number },
  language: string,
) => {
  const connectionLostTimestamp = socketConnectionStatus.connectionLostTimestamp || Date.now()
  const now = Date.now()
  const SIXTY_SECONDS = 60000
  const sixtySecondsPassed = now - connectionLostTimestamp >= SIXTY_SECONDS
  return sixtySecondsPassed
    ? `Connection lost at ${new Date(connectionLostTimestamp).toLocaleTimeString(
        language,
      )}. Attempting to reconnect.`
    : 'Restoring connection, please wait.'
}

const NotificationContainer: React.FC = () => {
  const notifications = useSelector(notificationsSelector)
  const cartEditable = useSelector(cartEditableSelector)
  const showNotEditableCartNotification = useSelector(showNotEditableCartNotificationSelector)
  const slowCartNotification = useSelector(slowCartNotificationSelector)
  const socketConnectionStatus = useSelector(socketConnectionStatusSelector)
  const language = useSelector(languageSelector)
  const roomType = useSelector(roomTypeSelector)

  const { t } = useTranslation()

  const dispatch = useDispatch()

  useOrientationAlert()

  const socketNotificationTimedOut =
    !isTableDevice() &&
    !isTouchDevice() &&
    roomType?.toLowerCase() !== 'ipad-only' &&
    Date.now() - (socketConnectionStatus.connectionLostTimestamp || Date.now()) >= 5000

  return (
    <NotificationWrpper>
      {notifications.dislikes
        .filter(notification => notification.show)
        .map(notification => (
          <UndoNotificationDislike
            key={notification.codes.toString()}
            codes={notification.codes}
            message={notification.message}
          />
        ))}
      {notifications.fastAddsToCart
        .filter(notification => notification.show)
        .map(
          notification =>
            isUpdateCartPayload(notification.notificationPayload) && (
              <UndoNotificationFastAddsCart
                key={notification.codes.toString()}
                codes={notification.codes}
                message={notification.message}
                payload={notification.notificationPayload}
              />
            ),
        )}
      {notifications.orientationAlert
        .filter(notification => notification.show)
        .map(notification => (
          <OrientationAlert key={notification.id} />
        ))}
      {notifications.missingModelCodes
        .filter(notification => notification.show)
        .map((notification, index) => {
          return (
            <Notification
              key={index}
              dangerouslySetInnerHTML={`${t(
                'Errors.missing_model_codes',
              )}: ${notification.notificationPayload?.missingModelCodes.map(
                (m: { modelCode: string; colorCode: string; size: string }) =>
                  `<br />${m.modelCode} ${m.colorCode} ${m.size}`,
              )}`}
              type="notice"
              onClose={() =>
                dispatch(
                  removeNotificationAction({
                    codes: ['missingModelCodes'],
                    notificationType: 'missingModelCodes',
                  }),
                )
              }
            />
          )
        })}
      {!cartEditable && showNotEditableCartNotification && (
        <Notification
          text={t('Errors.customer_no_editable_cart')}
          type="notice"
          onClose={() => dispatch(disableNotEditableCartNotificationAction())}
        />
      )}
      {slowCartNotification && (
        <Notification
          text={t('Errors.slow_cart')}
          type="notice"
          onClose={() => dispatch(setSlowCartNotificationAction(false))}
        />
      )}
      {!socketConnectionStatus.connected && !socketNotificationTimedOut && (
        <Notification
          text={getSocketConnetionLostMessage(socketConnectionStatus, language)}
          type="notice"
        />
      )}
    </NotificationWrpper>
  )
}

export default NotificationContainer
