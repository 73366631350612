import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'

import Categories from '../../components/Categories/Categories'
import Loading from '../../components/Loading'
import { useHandleOpenCategoryPage } from '../../hooks/useHandleOpenPages'
import { isTableDevice } from '../../libs/url'
import { appLoadingSelector } from '../../store/app/selectors'
import { brandsLoadedSelector, brandsSelector } from '../../store/brands/selectors'
import FooterNav from '../FooterNav'

const CategoriesPage: React.FC = () => {
  const brands = useSelector(brandsSelector)
  const appLoading = useSelector(appLoadingSelector)
  const brandsLoaded = useSelector(brandsLoadedSelector)
  const { brandSlug } = useParams<{ brandSlug: string }>()

  useHandleOpenCategoryPage(brandSlug)

  if (!brands || !brandsLoaded) {
    return <Redirect to={isTableDevice() ? '/events' : ''} />
  }

  if (appLoading) {
    return <Loading />
  }

  return (
    <>
      <div className={`layout single-brand-page device-table brand-${brandSlug}`}>
        <div className="layout-content">
          <Categories />
        </div>
      </div>
      <FooterNav />
    </>
  )
}

export default CategoriesPage
