import { useEffect, useMemo, useRef, useState } from 'react'
import { useGetWhiteboardFacetsQuery } from '../../../../services/afaProducts'
import { useDispatch, useSelector } from 'react-redux'
import whiteboardActions from '../../../../store/whiteboard/actions'
import {
  categoriesSportsSelector,
  sportPromisesAreFulfilledSelector,
} from '../../../../store/whiteboard/selectors'
import { useSearchParams } from '../../../../hooks/useSearchParams'

/**
 * A mutation hook that gets all sports availables in each products category at first. Then it checks whitch category was selected before clicking on filters CTA and give back its sports only
 * @param {string | undefined} selectedCategory - The category selected. By default is undefined
 * @returns {() => string[]} An array of unique sport names to render in the filter tab
 */
const useGetFiltersSports = (selectedCategory: string | undefined) => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const paramSelectedCategory = searchParams.get('category')
  const [sports, setSports] = useState<string[]>([])
  const originalSportsList = useRef<string[]>([])
  const categoriesSports = useSelector(categoriesSportsSelector)
  const sportPromisesAreFulfilled = useSelector(sportPromisesAreFulfilledSelector)
  const shouldSkip = selectedCategory !== 'filter' || sportPromisesAreFulfilled

  const facetsQueryApparel = useGetWhiteboardFacetsQuery('Apparel', shouldSkip)
  const facetsQueryFootwear = useGetWhiteboardFacetsQuery('Footwear', shouldSkip)
  const facetsQueryAccessories = useGetWhiteboardFacetsQuery('Accessories', shouldSkip)
  const facetsQueryGoggles = useGetWhiteboardFacetsQuery('Goggles', shouldSkip)
  const facetsQueryHelmets = useGetWhiteboardFacetsQuery('Helmets', shouldSkip)
  const facetPromises = useMemo(() => {
    return [
      facetsQueryApparel,
      facetsQueryFootwear,
      facetsQueryAccessories,
      facetsQueryGoggles,
      facetsQueryHelmets,
    ]
  }, [
    facetsQueryAccessories,
    facetsQueryApparel,
    facetsQueryFootwear,
    facetsQueryGoggles,
    facetsQueryHelmets,
  ])

  useEffect(() => {
    if (facetPromises.every(promise => promise.status === 'fulfilled') && !sports.length) {
      dispatch(whiteboardActions.setSportPromisesAreFulfilled(true))

      Promise.all(facetPromises)
        .then(data => {
          const sportsInCategories = [] as { categoryName: string; categorySports: string[] }[]

          const sports = data.reduce((sum, promise) => {
            const setSports = new Set<string>()

            const sportFacets = promise.data?.facets.find(facet => facet.name === 'sport')
            const categoryName = promise.originalArgs?.category

            sportFacets?.options.forEach(sport => setSports.add(sport.name))

            sportsInCategories.push({
              categoryName: categoryName || '',
              categorySports:
                sportFacets?.options.reduce((sum, sport) => {
                  sum.push(sport.name)
                  return sum
                }, [] as string[]) || [],
            })

            return new Set([...sum, ...setSports])
          }, new Set<string>())

          if (sports.size) {
            setSports([...sports])
            originalSportsList.current = [...sports]
            dispatch(whiteboardActions.setCategoriesSports(sportsInCategories))
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('An error occurred in useGetFiltersSports hook:', error)
        })
    }
  }, [dispatch, facetPromises, sports.length])

  useEffect(() => {
    if (sports.length) {
      dispatch(whiteboardActions.setSportFiltersAreLoading(false))

      if (paramSelectedCategory) {
        const sportsInSelectedCategory = categoriesSports?.find(
          category => category.categoryName === paramSelectedCategory,
        )
        if (sportsInSelectedCategory) {
          setSports(sportsInSelectedCategory.categorySports)
        }
      } else {
        setSports(originalSportsList.current)
      }
    } else {
      dispatch(whiteboardActions.setSportFiltersAreLoading(true))
    }
  }, [categoriesSports, dispatch, paramSelectedCategory, sports.length])

  return sports
}

export default useGetFiltersSports
