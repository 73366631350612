import React from 'react'
import styled from 'styled-components'

import { formatDoorAddress } from '../../libs/doors'
import { isStarsDoor } from '../../libs/stars'
import { MocoInCart } from '../../model/cart'
import { Door as DoorModel } from '../../model/customer'
import { Size } from '../../model/product'
import { getFluidFontSize } from '../../style/theme'
import RCIcon from '../UI/RCIcon'
import DoorPurchaseControls from './DoorPurchaseControls'

const DoorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`

const Title = styled.div`
  font-size: ${getFluidFontSize('12px')};
  position: relative;
  align-self: flex-start;
  margin-bottom: 1em;
  white-space: nowrap;
  width: 100%;
  font-weight: 500;

  img {
    margin-left: 0.5em;
  }
`

const TitleMain = styled.div`
  margin-bottom: 0;
  font-weight: bold;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
`

const TitleSub = styled.div`
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
`

type Props = {
  door: DoorModel
  enabledDoors: string[]
  sizes: Size[]
  cartItems?: MocoInCart
  showNotes: boolean
  brandCode: string
}

const Door: React.FC<Props> = ({ door, cartItems, sizes, showNotes, enabledDoors, brandCode }) => {
  const isStarsDoors = isStarsDoor(door, brandCode)

  return (
    <DoorWrapper className="door-wrapper">
      <Title className="door-head-title">
        <TitleMain className="door-head-title__main">
          {door.id} – {door.name}
          {isStarsDoors && <RCIcon type="icons-star-outline"></RCIcon>}
        </TitleMain>
        <TitleSub className="door-head-title__sub">{formatDoorAddress(door)}</TitleSub>
      </Title>
      {Object.values(sizes).map((size, index) => {
        const cartItemSize =
          cartItems &&
          cartItems.sizes &&
          Object.values(cartItems.sizes).find(
            cartSize =>
              cartSize.upc === size.upc && cartSize.doors?.find(({ doorId }) => doorId === door.id),
          )
        return (
          <DoorPurchaseControls
            key={`door-size-${index}`}
            doorIds={[door.id]}
            productSize={size}
            cartItemSize={cartItemSize}
            showNotes={showNotes}
            doorId={door.id}
            enabledDoors={enabledDoors}
            brandCode={brandCode}
          />
        )
      })}
    </DoorWrapper>
  )
}

export default Door
