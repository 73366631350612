import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import CheckIcon from '../../components/icons/CheckIcon'
import { useIsKeyLooksPage } from '../../hooks/useIsKeyLooksPage'
import { useSearchParams } from '../../hooks/useSearchParams'
import { useGetBrandCategoriesQuery } from '../../services/afa'
import { useGetAllPlpFacetsQuery } from '../../services/afaProducts'
import { breakpoints, getFluidSize, palette, pxToRem, spacing } from '../../style/theme'
import { getAfaFilterTranslation } from '../../components/AfaFilters/AfaFiltersLayer'

const SportButtons = styled.div`
  display: flex;
  gap: ${getFluidSize('10px', 20)};
  margin: 0 auto;

  @media screen and (max-width: ${breakpoints.L}) {
    margin: 0;
  }
`

const SportButton = styled.button`
  display: flex;
  align-items: center;
  color: ${palette.white};
  background-color: ${palette.tangaroa};
  text-transform: uppercase;
  border-radius: 20rem;
  padding: 0 ${pxToRem(24)}rem;
  gap: ${pxToRem(spacing(2))}rem;
  height: ${pxToRem(60)}rem;
  border: 2px solid ${palette.white};

  &.active {
    background-color: ${palette.white};
    color: ${palette.tangaroa};
    border: none;
  }

  & .check-icon {
    width: ${pxToRem(24)}rem;
    height: ${pxToRem(24)}rem;
  }

  @media screen and (max-width: ${breakpoints.L}) {
    padding: 0 ${pxToRem(18)}rem;
    gap: ${pxToRem(spacing(1))}rem;
    height: ${pxToRem(34)}rem;
    border: 1px solid ${palette.white};

    & .check-icon {
      width: ${pxToRem(16)}rem;
      height: ${pxToRem(16)}rem;
    }
  }

  @media screen and (min-width: ${breakpoints.XL}) {
    text-transform: capitalize;
    font-size: ${pxToRem(24)}rem;
  }
`

const AfaPlpFooterSportSwitcher: React.FC<{ brandCode?: string }> = ({ brandCode }) => {
  const { t } = useTranslation()

  const location = useLocation()

  const [searchParams, setSearchParams] = useSearchParams()
  const selectedSports = useMemo(() => {
    return searchParams.get('sport')?.split(',') || []
  }, [searchParams])

  const params = useParams<{ assortment: string; brandCode: string }>()

  const isKeylooksPage = useIsKeyLooksPage()

  const facetsQuery = useGetAllPlpFacetsQuery()

  const categoriesQuery = useGetBrandCategoriesQuery(brandCode || params.brandCode)

  const keylooksSports =
    categoriesQuery.data?.sports.filter(({ keylooks }) => keylooks.length > 0) || []
  const facetSports = facetsQuery.data?.facets.find(item => item.name === 'sport')?.options || []

  const sports = isKeylooksPage ? keylooksSports : facetSports

  const sortedSportNames = sports
    .map(({ name }) => name)
    .sort((a, b) => (a.toLowerCase() === 'theme' ? -1 : a.localeCompare(b)))

  const removeSportParam = useCallback(() => {
    searchParams.delete('sport')
    searchParams.delete('start')
    setSearchParams(searchParams, { replace: true, state: location.state })
  }, [location.state, searchParams, setSearchParams])

  const toggleSport = useCallback(
    (sport: string) => {
      if (selectedSports.includes(sport)) {
        selectedSports.splice(
          selectedSports.findIndex(s => s === sport),
          1,
        )
      } else {
        selectedSports.push(sport)
      }

      if (selectedSports.length) {
        searchParams.set('sport', selectedSports.join(','))
        searchParams.delete('start')
        setSearchParams(searchParams, { replace: true, state: location.state })
      } else {
        removeSportParam()
      }
    },
    [location.state, removeSportParam, searchParams, selectedSports, setSearchParams],
  )

  useEffect(() => {
    if (isKeylooksPage) {
      selectedSports.forEach(sport => {
        if (!sortedSportNames.includes(sport)) {
          toggleSport(sport)
        }
      })
    }
  }, [isKeylooksPage, sortedSportNames, selectedSports, toggleSport])

  return (
    <SportButtons>
      <SportButton
        className={!selectedSports.length ? 'active' : ''}
        onClick={() => {
          removeSportParam()
        }}
      >
        {!selectedSports.length && <CheckIcon className="check-icon" />}
        {t('Afa.all')}
      </SportButton>
      {sortedSportNames.map(sportName => {
        return (
          <SportButton
            key={sportName}
            className={selectedSports.includes(sportName) ? 'active' : ''}
            onClick={() => {
              toggleSport(sportName)
            }}
          >
            {selectedSports.includes(sportName) && <CheckIcon className="check-icon" />}
            {getAfaFilterTranslation(t, `Afa.${sportName.toLowerCase()}`, sportName)}
          </SportButton>
        )
      })}
    </SportButtons>
  )
}

export default AfaPlpFooterSportSwitcher
