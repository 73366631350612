import React from 'react'
import { useDispatch } from 'react-redux'

import { extractModelCodeFromMocoCode } from '../../../libs/productsV2'
import { toggleDislikeListAction } from '../../../store/actions'
import UndoNotification from './'

type Props = {
  message?: string
  codes: string[]
}

const UndoNotificationDislike: React.FC<Props> = ({ codes, message }) => {
  const dispatch = useDispatch()
  const onUndo = () =>
    codes.length &&
    dispatch(
      toggleDislikeListAction(
        codes.map(mocoCode => ({
          mocoCode,
          upcs: [],
          modelCode: extractModelCodeFromMocoCode(mocoCode),
        })),
      ),
    )

  return (
    <UndoNotification
      onUndo={onUndo}
      codes={codes}
      message={message || ''}
      notificationType="dislikes"
    />
  )
}

export default UndoNotificationDislike
