import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import Loading from '../../../components/Loading'
import { DoorShippingAddress } from '../../../model/cart'
import { useGetAfaFilteredCheckoutProducts } from '../../../services/afaCart'
import { useConfirmCartMutation, useGetDoorShippingAddressQuery } from '../../../services/cart'
import afaActions from '../../../store/afa/actions'
import { selectedAddressesSelector } from '../../../store/afa/selectors'
import {
  breakpointsCross,
  getFluidFontSize,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
  spacing,
} from '../../../style/theme'

const ScrollableWrapper = styled.div`
  flex: 1;
  overflow: auto;
`

const TableWrapper = styled.div`
  background-color: ${palette.white};
  padding: 2rem;
  border-radius: ${pxToRem(spacing(1))}rem;
  margin-bottom: ${pxToRem(spacing(4))}rem;
  color: ${palette.cloudBurst};
  border: 1px solid ${palette.platinum};
  margin-inline: 2vw;

  &.error {
    background-color: ${palette.pomegranate};
    color: ${palette.white};
    text-align: center;
  }
`

const Table = styled.table`
  width: 100%;
  padding: 0 ${pxToRem(spacing(2))}rem;
  margin: 0 auto;

  th {
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 1rem;
    font-size: clamp(16px, ${getFluidSizeWithFullFormula('px', 16, 28, 1366, 3840)}, 28px);

    @media (max-width: ${breakpointsCross.L.max}) {
      span {
        margin-left: ${pxToRem(5)}rem;
      }
    }
  }
`

const TBodyBorder = styled.tbody`
  position: relative;
  display: flex;
  padding: 1rem 1.5rem;
  font-weight: bold;
  font-size: ${getFluidFontSize('14px', 15)};
  border: ${pxToRem(1)}rem solid ${palette.softGrey};
  border-radius: ${pxToRem(5)}rem;
  margin: ${pxToRem(5)}rem 0;
  max-width: ${pxToRem(1541)}rem;

  &.border {
    border: ${pxToRem(1)}rem solid ${palette.tangaroa};
  }
`

const AddressLabel = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  span {
    font-weight: bold;
    font-size: clamp(18px, ${getFluidSizeWithFullFormula('px', 18, 24, 1366, 3840)}, 24px);
  }

  .radioInput {
    display: none;
  }

  .realRadio {
    width: clamp(20px, ${getFluidSizeWithFullFormula('px', 20, 32, 1366, 3840)}, 32px);
    height: clamp(20px, ${getFluidSizeWithFullFormula('px', 20, 32, 1366, 3840)}, 32px);
    border: ${pxToRem(1)}rem solid ${palette.tangaroa};
    border-radius: 50%;
    margin-right: ${pxToRem(15)}rem;
    box-sizing: border-box;
    padding: ${pxToRem(3)}rem;
  }

  .realRadio::after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background-color: ${palette.tangaroa};
    border-radius: 50%;
    transform: scale(0);
  }

  .radioInput:checked + .realRadio::after {
    transform: scale(1);
  }
`

const LoadingWrapper = styled.div`
  top: 0;
  left: 0;
  background: none;
  position: absolute;
  height: 100vh;
  width: 100vw;
  pointer-events: visible;
  display: block;
  z-index: 9999;
`

type DoorRowProps = {
  address: DoorShippingAddress
}

const formatDoorShippingAddress = (address: DoorShippingAddress): string => {
  const { addressid, lastname, name, zipcode, city, countrycode } = address
  const zipCodeAndCity = `${zipcode ?? ''} ${city ?? ''}`.trim()
  const addressItems = [addressid, lastname, name, zipCodeAndCity, countrycode]
  const addressItemsWithoutEmptyValues = addressItems
    .filter(text => ![null, ''].some(noValue => noValue === text))
    .map(each => `${each}`.trim())
  return addressItemsWithoutEmptyValues.join(' - ')
}

const AddressRow: React.FC<DoorRowProps> = ({ address }) => (
  <span>{formatDoorShippingAddress(address)}</span>
)

const sortAddress = (a: DoorShippingAddress, b: DoorShippingAddress) => {
  if (a.addresstype === 'WE' && b.addresstype !== 'WE') {
    return -1
  } else if (a.addresstype !== 'WE' && b.addresstype === 'WE') {
    return 1
  } else {
    return a.addressid.localeCompare(b.addressid)
  }
}

const Content: React.FC = () => {
  const { t } = useTranslation()

  const { isLoading, isError, data: doors } = useGetDoorShippingAddressQuery()

  const selectedAddresses = useSelector(selectedAddressesSelector)

  const [filteredCheckoutProducts] = useGetAfaFilteredCheckoutProducts()

  const doorsInCart = useMemo(() => filteredCheckoutProducts?.map(({ doorId }) => doorId), [
    filteredCheckoutProducts,
  ])

  const doorsShippingAddressesInCart = useMemo(
    () =>
      filteredCheckoutProducts?.reduce((result, product) => {
        const { doorId, doorAddressId } = product

        if (!doorAddressId) {
          return result
        }

        if (doorId in result) {
          !result[doorId].includes(doorAddressId) && result[doorId].push(doorAddressId)
        } else {
          result[doorId] = [doorAddressId]
        }

        return result
      }, {} as Record<string, string[]>),
    [filteredCheckoutProducts],
  )

  const [, confirmCartResult] = useConfirmCartMutation()

  const dispatch = useDispatch()

  return (
    <ScrollableWrapper>
      {isLoading && <Loading />}

      {/* different loading to disable the footer buttons on submit to back office */}
      {confirmCartResult.isLoading && (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )}

      {((!isLoading && doors?.length === 0) || isError) && (
        <TableWrapper className="error">
          <Table>
            <thead>
              <tr>
                <th>
                  <span>{t('Checkout.Address.Error')}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <span>{t('Checkout.Address.ErrorMessage')}</span>
            </tbody>
          </Table>
        </TableWrapper>
      )}

      {doors
        ?.filter(({ id }) => doorsInCart?.includes(id))
        .sort((a, b) => a.id.localeCompare(b.id))
        .map(door => (
          <TableWrapper key={door.id}>
            <Table>
              <thead>
                <tr>
                  <th>
                    <span>{`${door.id} ${door.name}`.trim()}</span>
                  </th>
                </tr>
              </thead>
              {/* TODO: Add door.addresses with different door.id and address.addressid */}
              {[...door.addresses]
                .sort((a, b) => sortAddress(a, b))
                .map((address, i) => {
                  const defaultAddresses = door.addresses.filter(
                    ({ addresstype }) => addresstype === 'WE',
                  )
                  const selectedByDefault =
                    (defaultAddresses.length === 1 && address.addresstype === 'WE') ||
                    (doorsShippingAddressesInCart &&
                      doorsShippingAddressesInCart[door.id] &&
                      doorsShippingAddressesInCart[door.id].length === 1 &&
                      doorsShippingAddressesInCart[door.id].includes(address.addressid))
                  const selectedAddress = selectedAddresses.find(({ doorId }) => doorId === door.id)
                  if (selectedByDefault && !selectedAddress) {
                    dispatch(
                      afaActions.setSelectedAddress({
                        doorId: door.id,
                        selectedAddressId: address.addressid,
                      }),
                    )
                  }
                  const checked = selectedAddress?.selectedAddressId === address.addressid
                  return (
                    <tr key={address.addressid}>
                      <TBodyBorder className={checked ? 'border' : ''} key={i}>
                        <AddressLabel>
                          <input
                            className="radioInput"
                            type="radio"
                            name={door.id}
                            value={address.addressid}
                            checked={checked}
                            onChange={() => {
                              dispatch(
                                afaActions.setSelectedAddress({
                                  doorId: door.id,
                                  selectedAddressId: address.addressid,
                                }),
                              )
                            }}
                          />
                          <div className="realRadio"></div>
                          <AddressRow address={address} />
                        </AddressLabel>
                      </TBodyBorder>
                    </tr>
                  )
                })}
            </Table>
          </TableWrapper>
        ))}
    </ScrollableWrapper>
  )
}

export default Content
