import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import WarningIcon from '../../../../components/icons/WarningIcon'

import { CardDeltaOperation } from '../../../../model/cart'
import { useUploadCartMutation } from '../../../../services/cart'
import { breakpoints, getFluidFontSize, palette, pxToRem, spacing } from '../../../../style/theme'
import { StyledTableTh, StyledTableTitle, StyledTableWrapper } from '../Common'
import RCIcon from '../../../../components/UI/RCIcon'
import { errorNotification } from '../../../../components/Notification/notifications'

const Wrapper = styled.div`
  color: ${palette.tangaroa};
`

const ComparisonTable = styled.div`
  position: relative;
`

const TableTitle = styled(StyledTableTitle)`
  position: sticky;
  top: 0;
  z-index: 1;
  outline: 2px solid ${palette.linkWater};
  outline-offset: -1px;
`

const TableWrapper = styled(StyledTableWrapper)`
  text-align: center;

  tr {
    &.delete,
    &.error {
      td:first-of-type,
      td:nth-of-type(2) {
        text-decoration: line-through;
      }
    }
  }

  td {
    position: relative;

    &.color {
      color: ${palette.tree};
    }

    &.colorBlue {
      color: ${palette.greyBlue};
    }

    & > .arrow {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(50%, -50%);
      font-size: ${getFluidFontSize('20px')};
    }
  }

  table {
    width: 100%;
  }
`

export const ComparisonTableHead = styled.thead`
  position: sticky;
  top: ${pxToRem(spacing(8))}rem;
  z-index: 1;

  @media (min-width: ${breakpoints.XL}) {
    top: ${pxToRem(spacing(10))}rem;
  }
`

const AddBadge = styled.span`
  padding: ${pxToRem(10)}rem ${pxToRem(15)}rem;
  border-radius: ${pxToRem(5)}rem;
  border: 1px solid ${palette.cucumber};
  background-color: ${palette.cucumber};
  color: ${palette.zuchini};
`

const RemoveBadge = styled.span`
  padding: ${pxToRem(10)}rem ${pxToRem(15)}rem;
  border-radius: ${pxToRem(5)}rem;
  border: 1px solid ${palette.raspberry};
  background-color: ${palette.raspberry};
  color: ${palette.pomegranate};
`

const EditBadge = styled.span`
  padding: ${pxToRem(10)}rem ${pxToRem(15)}rem;
  border-radius: ${pxToRem(5)}rem;
  border: 1px solid ${palette.lemon};
  background-color: ${palette.lemon};
  color: ${palette.mango};
`

const BadgeError = styled.span<{
  error?: boolean
}>`
  color: ${palette.pomegranate};

  svg {
    color: ${({ error }) => (error ? palette.pomegranate : palette.mango)};
  }
`

const TableTh = styled(StyledTableTh)``

function TableItem({
  doorId,
  skuCode,
  actquant,
  newquant,
  actreference,
  newreference,
  actdelivery,
  newdelivery,
  fault,
  message,
}: CardDeltaOperation) {
  const { t } = useTranslation()

  const isDelete = actquant > 0 && newquant == 0

  const actionBadge = (prevQt: number, newQt: number, fault?: boolean, message?: string) => {
    if (fault || !!message) {
      return (
        <BadgeError error={fault}>
          <WarningIcon /> {message || t('Errors.parsingUploadedCartRow')}
        </BadgeError>
      )
    }

    if (prevQt == 0 && newQt > 0) {
      return <AddBadge>{t('Checkout.ImportExport.AddItem')}</AddBadge>
    } else if (prevQt > 0 && newQt == 0) {
      return <RemoveBadge>{t('Checkout.ImportExport.RemoveItem')}</RemoveBadge>
    } else if (prevQt != 0 && newQt != 0) {
      return <EditBadge>{t('Checkout.ImportExport.EditItem')}</EditBadge>
    }
  }

  return (
    <tr className={[isDelete ? 'delete' : '', fault ? 'error' : ''].join(' ')}>
      <td>{doorId}</td>
      <td>{skuCode}</td>
      <td colSpan={fault ? 7 : 1} style={{ textAlign: fault ? 'left' : 'center' }}>
        {actionBadge(actquant, newquant, fault, message)}
      </td>
      {!fault && (
        <>
          <td className="colorBlue">
            {actquant}
            <span className="arrow">
              <RCIcon type="checkout-import-export-comparison-arrow" />
            </span>
          </td>
          <td className="color">{newquant}</td>
          <td className="colorBlue">
            {actreference}
            <span className="arrow">
              <RCIcon type="checkout-import-export-comparison-arrow" />
            </span>
          </td>
          <td>{newreference}</td>
          <td className="colorBlue">
            {actdelivery}
            <span className="arrow">
              <RCIcon type="checkout-import-export-comparison-arrow" />
            </span>
          </td>
          <td>{newdelivery}</td>
        </>
      )}
    </tr>
  )
}

const dataChangedOrError = ({
  actdelivery,
  newdelivery,
  actreference,
  newreference,
  actquant,
  newquant,
  fault,
  message,
}: CardDeltaOperation) => {
  const deliveryChanged = actdelivery !== newdelivery
  const referenceChanged = actreference !== newreference
  const quantityChanged = actquant !== newquant
  return deliveryChanged || referenceChanged || quantityChanged || fault || !!message
}

const Comparison: React.FC = () => {
  const { t } = useTranslation()

  const [, uploadCartResult] = useUploadCartMutation()

  const tableHeader: [string, keyof CardDeltaOperation | 'action'][] = [
    [t('Checkout.ImportExport.DoorId'), 'doorId'],
    [t('Checkout.ImportExport.ModelCode'), 'skuCode'],
    [t('Checkout.ImportExport.Action'), 'action'],
    [t('Checkout.ImportExport.PrevQty'), 'actquant'],
    [t('Checkout.ImportExport.NewQty'), 'newquant'],
    [t('Checkout.ImportExport.PrevRef'), 'actreference'],
    [t('Checkout.ImportExport.NewRef'), 'newreference'],
    [t('Checkout.ImportExport.PrevDate'), 'actdelivery'],
    [t('Checkout.ImportExport.DeliveryDate'), 'newdelivery'],
  ]

  const uploadFault = uploadCartResult.data?.fault
  useEffect(() => {
    if (uploadFault) {
      errorNotification({
        message: t('Checkout.ImportExport.BlockingErrorsInUploadedFile'),
      })
    }
  }, [uploadFault, t])

  const sortedItems = uploadCartResult.data?.items.filter(dataChangedOrError).sort((a, b) => {
    return (a.message || '').localeCompare(b.message || '') * -1
  })

  return (
    <Wrapper>
      <ComparisonTable>
        <TableTitle>{t('Checkout.ImportExport.ComparisonList')}</TableTitle>
        <TableWrapper>
          <table>
            <ComparisonTableHead>
              <tr>
                {tableHeader.map((column, index) => {
                  const columnName: string = column[0].toString()
                  return <TableTh key={index}>{columnName}</TableTh>
                })}
              </tr>
            </ComparisonTableHead>
            {sortedItems?.map(deltaResult => {
              return <TableItem key={deltaResult.upc} {...deltaResult} />
            })}
          </table>
        </TableWrapper>
      </ComparisonTable>
    </Wrapper>
  )
}

export default Comparison
