import { TFunction } from 'i18next'
import filter from 'mout/object/filter'
import get from 'mout/object/get'
import map from 'mout/object/map'
import reduce from 'mout/object/reduce'

import { Brand } from '../model/brand'
import { CartProduct } from '../model/cart'
import { Release } from '../model/events'
import {
  CartFilters,
  FilterData,
  PlpFilters,
  QueryParameter,
  WishlistFilters,
} from '../model/filters'
import { CategoryId, RootState } from '../model/model'
import { Product } from '../model/product'
import { selectedEventSelector } from '../store/app/selectors'
import { isPLPPage } from './url'
import { keys } from './utils'
import { isSubBrandCategory } from '../helpers/genericHelper'

/**
 * Formats label for filters.
 * @param {string} baseLabel Initial label
 * @returns {string} Formatted label
 */
export const formatFilterLabel = (baseLabel: string) => {
  const filterLabel = baseLabel.toLowerCase()
  return filterLabel === 'category' ? 'collection' : filterLabel
}

/**
 * Updates release filters according to data passed with event model
 * @param {object} filters Current filters state
 * @param {array} releases Array of releases available for event
 * @returns {object} Updated filters
 */
export const setReleaseFilters = (
  filters: CartFilters | PlpFilters | WishlistFilters,
  releases: Release[] = [],
) => {
  return {
    ...filters,
    release: {
      selected: [],
      active: [],
      counter: releases.reduce((counter: Record<string, number | undefined>, { label }) => {
        counter[label] = undefined
        return counter
      }, {}),
      options: releases.map(obj => ({
        name: obj.label,
        id: obj.keys.join(','),
        label: obj.label
          .split(' ')
          .join('')
          .toLowerCase(),
      })),
    },
  }
}

export const filtersData = [
  {
    propertyName: 'gender',
  },
  {
    propertyName: 'category',
  },
  {
    propertyName: 'ageGroup',
  },
  {
    propertyName: 'sizeLabel',
    filterName: 'size',
  },
  {
    propertyName: 'type',
  },
  {
    propertyName: 'geofit',
    filterName: 'geoFit',
  },
  {
    propertyName: 'asianFit',
  },
  {
    propertyName: 'lensProperties',
  },
  {
    propertyName: 'frameShape',
  },
  {
    propertyName: 'segment',
  },
  {
    propertyName: 'frontMaterial',
  },
  {
    propertyName: 'tags',
  },
  {
    propertyName: 'release',
  },
  {
    propertyName: 'frontFamilyColor',
  },
  {
    propertyName: 'deliveryDate',
  },
  {
    propertyName: 'reference',
  },
  {
    propertyName: 'brandCode',
    filterName: 'brand',
  },
  {
    propertyName: 'quantity',
  },
  {
    propertyName: 'modelCode',
    filterName: 'model',
  },
  {
    propertyName: 'reference',
  },
  {
    propertyName: 'brandCode',
    filterName: 'brand',
  },
]

export const extractPropertyValueForFilter = (
  propertyValue: string | { id: string } | string[],
) => {
  return (
    (Array.isArray(propertyValue) && propertyValue) ||
    (typeof propertyValue === 'object' && 'id' in propertyValue && propertyValue.id) ||
    (typeof propertyValue !== 'object' && propertyValue) ||
    undefined
  )
}

const getNewTag = (tag: string) => {
  if (tag === 'bs' || tag === 'bestseller') {
    return 'BESTSELLER'
  } else {
    return tag.toUpperCase()
  }
}

export const propertyMatchesFilterValue = (
  propertyValue: string[] | string,
  propertyName: string,
  filterValue?: { selected: string[] | string },
  releases?: string[],
) => {
  const propertyValueSafeArray = ([] as string[]).concat(propertyValue).filter(Boolean)

  return propertyValueSafeArray.some(propertyValue => {
    const propertyId = extractPropertyValueForFilter(propertyValue)
    if (!propertyId) {
      return false
    }

    if (Array.isArray(propertyId)) {
      return propertyId.some(propId => filterValue?.selected.includes(propId))
    }

    if (propertyName === 'brandCode' && Array.isArray(filterValue?.selected)) {
      return filterValue?.selected.some(brandCodes => brandCodes.split(',').includes(propertyId))
    }

    if (propertyName === 'quantity') {
      return filterValue?.selected.includes(propertyId.toString())
    }

    if (
      !Array.isArray(filterValue?.selected) &&
      (propertyName === 'modelCode' || propertyName === 'reference')
    ) {
      if (propertyName === 'modelCode') {
        return filterValue && propertyId.toLowerCase().includes(filterValue.selected.toLowerCase())
      } else {
        return filterValue?.selected.toLowerCase() === propertyId.toLowerCase()
      }
    }

    if (
      propertyName === 'release' &&
      !!releases?.length &&
      filterValue?.selected.includes('Other')
    ) {
      return !releases.includes(propertyId)
    }

    if (propertyName === 'tags') {
      return filterValue?.selected.includes(getNewTag(propertyId))
    }

    return filterValue?.selected.includes(propertyId)
  })
}

export const matchFilters = (
  activeFilters: CartFilters,
  cartProductsDetails: Record<string, Product>,
  cartProduct: CartProduct,
) => {
  const { modelCode, colorCode, upc } = cartProduct
  const productDetails = cartProductsDetails[modelCode]

  return keys(activeFilters)
    .filter(filterName => filterName !== 'brand_slug')
    .every(filterName => {
      const filterValue = activeFilters[filterName]
      const filterData = filtersData.find(
        fd => fd.filterName === filterName || fd.propertyName === filterName,
      )
      if (!filterData) {
        return false
      }
      const { propertyName } = filterData
      const propertyValue =
        productDetails &&
        (get(productDetails, `${propertyName}.id`) ||
        get(productDetails, `mocos.${colorCode}.${propertyName}.id`) ||
        get(productDetails, `mocos.${colorCode}.${propertyName}`) || // tags
          get(productDetails, `mocos.${colorCode}.sizes.${upc}.${propertyName}`))

      return propertyMatchesFilterValue(propertyValue, propertyName, filterValue)
    })
}

const getDeliveryDate = (cartProduct: CartProduct) => {
  const deliveryDateYYYYMMDD = cartProduct.deliveryDate
    ?.split('-')
    .reverse()
    .join('-')
  const minDeliveryDateYYYYMMDD = cartProduct.minDeliveryDate
    ?.split('-')
    .reverse()
    .join('-')

  const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1))
  const deliveryDate = new Date(deliveryDateYYYYMMDD || 0)
  const minDeliveryDate = new Date(minDeliveryDateYYYYMMDD || 0)
  const isDeliveryDateValid = deliveryDate && !isNaN(deliveryDate.getDate())
  const isMinDeliveryDateValid = minDeliveryDate && !isNaN(minDeliveryDate.getDate())

  if (
    isDeliveryDateValid &&
    (deliveryDate >= minDeliveryDate || (!isMinDeliveryDateValid && deliveryDate >= tomorrow))
  ) {
    return cartProduct.deliveryDate || ''
  } else if (isMinDeliveryDateValid) {
    return cartProduct.minDeliveryDate || ''
  } else {
    return `${tomorrow
      .getDate()
      .toString()
      .padStart(2, '0')}-${(tomorrow.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${tomorrow.getFullYear()}`
  }
}

export const matchedFilters = (
  activeFilters: CartFilters,
  cartProductsDetails: Record<string, Product>,
  cartProduct: CartProduct,
  releases?: string[],
) => {
  const { modelCode, colorCode, upc } = cartProduct
  const productDetails = cartProductsDetails[modelCode]

  return keys(activeFilters)
    .filter(filterName => filterName !== 'brand_slug')
    .reduce((result, filterName) => {
      const filterValue = activeFilters[filterName]
      if (!filterValue?.selected.length) {
        return result
      }
      const filterData = filtersData.find(
        fd => fd.filterName === filterName || fd.propertyName === filterName,
      )
      if (!filterData) {
        return result
      }
      const { propertyName } = filterData
      const propertyValue =
        (productDetails &&
          (get(productDetails, `${propertyName}.id`) ||
          get(productDetails, `mocos.${colorCode}.${propertyName}.id`) ||
          get(productDetails, `mocos.${colorCode}.${propertyName}`) || // tags
            get(productDetails, `mocos.${colorCode}.sizes.${upc}.${propertyName}`))) ||
        (filterData.propertyName === 'deliveryDate'
          ? getDeliveryDate(cartProduct)
          : get(cartProduct, `${propertyName}`))

      result[filterName] = propertyMatchesFilterValue(
        propertyValue,
        propertyName,
        filterValue,
        releases,
      )
      return result
    }, {} as Record<keyof CartFilters, boolean>)
}

const extractPropertyValue = (
  cartProduct: CartProduct,
  cartModel: Product,
  filterData: { propertyName: string; filterName?: string },
) => {
  const propertyName = filterData.propertyName as keyof Product
  const { colorCode, upc } = cartProduct

  return (
    (cartModel &&
      (cartModel[propertyName] ||
        get(cartModel, `${propertyName}.id`) ||
        get(cartModel, `mocos.${colorCode}.${propertyName}.id`) ||
        get(cartModel, `mocos.${colorCode}.${propertyName}`) ||
        get(cartModel, `mocos.${colorCode}.sizes.${upc}.${propertyName}`))) ||
    (filterData.propertyName === 'deliveryDate'
      ? getDeliveryDate(cartProduct)
      : get(cartProduct, `${propertyName}`))
  )
}

function getUpdatedFilterCounter(
  counter: Record<string, string> = {},
  value: { id: string } | string | string[],
  cartProduct: CartProduct,
  product: Record<string, Product>,
  mocoCode: string,
  filter: CartFilters,
  filterName: string,
  releases?: string[],
) {
  if (!value) {
    return counter || {}
  }

  const propertyValue = extractPropertyValueForFilter(value)

  if (!propertyValue) {
    return counter || {}
  }

  const counterUpdate = Array.isArray(propertyValue)
    ? propertyValue.reduce((result, optionId) => {
        const option = filterName === 'tags' ? getNewTag(optionId) : optionId
        result[option] = Object.assign(counter[option] || result[option] || {}, {
          [mocoCode]: matchedFilters(filter, product, cartProduct, releases),
        })
        return result
      }, {} as Record<string, Record<string, Record<keyof CartFilters, boolean>>>)
    : {
        [propertyValue]: Object.assign(counter[propertyValue] || {}, {
          [mocoCode]: matchedFilters(filter, product, cartProduct, releases),
        }),
      }
  return Object.assign(counter, counterUpdate)
}

export function calculateFacetsFromCart({
  cartProducts,
  cartProductsDetails,
  filters,
  releases,
}: {
  cartProducts: CartProduct[]
  cartProductsDetails: Record<string, Product>
  filters: any
  releases?: string[]
}) {
  const filtersValuesByMoco = cartProducts.map(cartProduct => {
    const { modelCode, mocoCode } = cartProduct
    const cartModel = cartProductsDetails[modelCode]
    const filtersDataAndValues = filtersData.map(filterData => {
      const propertyValue = extractPropertyValue(cartProduct, cartModel, filterData)

      return Object.assign({}, filterData, { propertyValue })
    })

    return {
      filterData: filtersDataAndValues,
      cartProduct,
      mocoCode,
    }
  })

  const filtersObj = filtersValuesByMoco.reduce((result, { filterData, cartProduct, mocoCode }) => {
    filterData.forEach(singleFilterData => {
      const filterName = singleFilterData.filterName || singleFilterData.propertyName
      const value =
        filterName === 'release' &&
        !!releases?.length &&
        !releases.includes(singleFilterData.propertyValue)
          ? 'Other'
          : singleFilterData.propertyValue

      result[filterName] = getUpdatedFilterCounter(
        result[filterName],
        value,
        cartProduct,
        cartProductsDetails,
        mocoCode,
        filters,
        filterName,
        releases,
      )
    })

    return result
  }, {} as Record<string, Record<string, string>>)

  const facets = Object.entries(filtersObj).map(([key, value]) => ({
    name: key,
    active: Boolean(Object.keys(value).length),
    options: map(value, (value: Record<string, Record<string, boolean>>) => {
      return Object.values(value).filter(filters => {
        return Object.keys(filters).every(filter => filters[filter] || filter === key)
      }).length
    }),
  }))

  return facets
}

const mapFilterNameToQueryParameter: QueryParameter = {
  brand_slug: 'brand',
  tags: 'tags',
  frameShape: 'frameShape',
  lensProperties: 'lensProperties',
  frontFamilyColor: 'frontFamilyColor',
}

const convertFilterNameToQueryParameterKey = (filterName: string | number | keyof QueryParameter) =>
  mapFilterNameToQueryParameter[filterName] || filterName

const convertBrandSlugToBrandCodes = (optionId: string, state: RootState) => {
  const { isKidCategoryModeEnabled } = state.app
  const filtersActiveCategory = state.filters.plp.category.active[0] as CategoryId | undefined

  const brand = state.brands.items.find((brand: { slug: string }) => brand.slug === optionId)
  if (!brand) return ''

  const brandGroup = brand.group ? brand.group : []
  const isPlp = isPLPPage()
  const brands = isPlp
    ? brand.subBrands
        .filter(subBrand =>
          isSubBrandCategory(subBrand, filtersActiveCategory || '', isKidCategoryModeEnabled),
        )
        .map(({ code }) => code)
    : brandGroup

  return brands.join(',')
}

const convertOptionId = (filterName: string, state: RootState) => (optionId: string) =>
  filterName === 'brand_slug' ? convertBrandSlugToBrandCodes(optionId, state) : optionId

const convertReleases = (filterItem: FilterData, eventReleases: string[]) => {
  const otherReleases = Object.keys(filterItem.counter).filter(
    (option: string) => !eventReleases.includes(option),
  )
  const releases = filterItem.selected
    .filter(release => release.toLowerCase() !== 'other')
    .concat(otherReleases)
  return releases
}

export const convertSelectedFiltersIntoQueryParams = (
  selectedFilters: Record<string, { selected: string[] }>,
  state: RootState,
) => {
  const event = selectedEventSelector(state)
  const eventReleases = event?.releases
    ? event.releases.map(release => release.label).concat('other')
    : []
  const queryString = reduce(
    selectedFilters,
    (result: string, filterItem: FilterData, key: string) => {
      if (key === 'segment') {
        return result
      }
      const selectedIds =
        key === 'release' && eventReleases.length && filterItem.selected.includes('other')
          ? convertReleases(filterItem, eventReleases).join(',')
          : filterItem.selected.map(convertOptionId(key, state)).join(',')

      return `${result}${convertFilterNameToQueryParameterKey(key)}=${
        key === 'tags' ? selectedIds.toLowerCase() : selectedIds
      }&`
    },
    '?',
  )
  return queryString.substring(0, queryString.length - 1)
}

export const getCounterValue = (filter: FilterData, id: string) => {
  return filter.counter[id] || 0
}

export const getActiveFilters = (filters: CartFilters | PlpFilters | WishlistFilters) =>
  filter(filters, (filter: { active: string[] }) => filter && filter.active && filter.active.length)

export const getSelectedFilters = (filters: CartFilters | PlpFilters | WishlistFilters) =>
  filter(
    filters,
    (filter: { selected: string[] }) => filter && filter.selected && filter.selected.length,
  )

export const isPlpFilters = (
  filters: CartFilters | PlpFilters | WishlistFilters,
): filters is PlpFilters => 'category' in filters

export const isCartFilters = (
  filters: CartFilters | PlpFilters | WishlistFilters,
): filters is CartFilters => !isPlpFilters(filters)

export const getLabel = ({
  filterName,
  optionName,
  brands,
  optionLabel,
  t,
}: {
  filterName: string
  optionName: string
  brands: Brand[]
  optionLabel: string
  t: TFunction
}) => {
  if (['release', 'deliveryDate'].includes(filterName)) {
    return optionName
  } else if (filterName === 'brand') {
    const masterBrand = brands.find(({ code }) => optionName.split(',').includes(code))
    if (masterBrand) {
      return masterBrand.brand
    }

    const juniorBrand = brands.find(({ juniorBrands }) =>
      optionName.split(',').some(code => juniorBrands.includes(code)),
    )
    if (juniorBrand) {
      return `${juniorBrand.brand} ${juniorBrand.juniorBrandDefinition}`
    }

    return optionName
  } else if (filterName === 'category' && ['1', '2'].includes(optionLabel)) {
    return t(
      `Filters.item_${formatFilterLabel(filterName)}_${optionLabel === '1' ? 'optical' : 'sun'}`,
    )
  } else if (filterName === 'model' || filterName === 'reference' || filterName === 'quantity') {
    return `${t(`Filters.item_${filterName}`)}: ${optionName}`
  } else {
    return t(`Filters.item_${formatFilterLabel(filterName)}_${optionLabel}`)
  }
}
