import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { getFluidSizeWithFullFormula, palette } from '../../style/theme'
import { AfaCartProduct, AfaSize, DropType, UpdateAfaCartProductPayload } from '../../model/afa'
import {
  getCartRowAvailableQuantity,
  getUpcAvailabilityByDelivery,
  sortAfaSizes,
} from '../../libs/afa'
import { SizeInput } from './SizeInput'
import { useParams } from 'react-router'
import { useAfaSelectedColor } from '../../hooks/useAfaSelectedColor'
import { useGetAfaCartQuery, useGetSelectedDelivery } from '../../services/afaCart'
import { useSearchParams } from '../../hooks/useSearchParams'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { afaCartProductDetailsSelector } from '../../store/afaCart/selectors'
import { afaCartAdjustSelectors } from '../../store/afaCartAdjust/selectors'
import { DdMmYyyyDateString, Upc } from '../../model/model'
import {
  Debug,
  SizeAvailability,
  SizeAvailabilityContainer,
  SizeContainer,
  SizeInputContainer,
  SizeLabel,
  SizeRowContainer,
  SizeRowContent,
  SizeRowHeader,
  SizeRowPrice,
  SizeRowTitle,
  Sizes,
  SizeWrapper,
} from './DropRowsStyles'
import classnames from 'classnames'
import { useGetCartAvailabilityQuery } from '../../services/afaProduct'
import { DebuggerFeatures } from '../../Debug'
import { AfaCartAdjustType } from '../../store/afaCartAdjust/slice'
import { appSelectors } from '../../store/app/selectors'
import Price from '../../components/Price'
import { useGetBrandsQuery } from '../../services/afa'

const StyledPrices = styled(Price)`
  gap: 4ch;

  .product-prices__whsPrice {
    text-transform: initial;
  }

  .product-prices__whsPrice,
  .product-prices__srPrice {
    font-size: inherit;
    gap: 2ch;

    span:first-child {
      color: ${palette.philippineSilver};
    }

    span:last-child {
      font-size: clamp(16px, ${getFluidSizeWithFullFormula('px', 16, 32, 1366, 3840)}, 32px);
      color: ${palette.tangaroa};
    }
  }
`

const Spinner = styled.div`
  @keyframes rotate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > div {
    animation: rotate 1.5s linear infinite;
  }
`

const AvailabilityAlert = styled.div`
  font-size: ${getFluidSizeWithFullFormula('px', 10, 20, 1366, 3840)};
  text-align: center;
  height: 1em;
  grid-column-start: 2;
`

type SizeRowProps = {
  title?: string
  availability?: {
    availableQuantity: number
    upc: Upc
    afaCatalogId?: 'A' | 'L' | 'P' //P: Prebook; L: Late Prebook; A: At Once
  }[]
  dropType: DropType
  availabilityDate: DdMmYyyyDateString
  minDeliveryDate: DdMmYyyyDateString
  customDeliveryDate?: DdMmYyyyDateString
  updateAfaCart: (products: UpdateAfaCartProductPayload[]) => void
  disabled: boolean
  upcs?: string[]
  isLatePrebook?: boolean
  afaCatalogId?: 'A' | 'L' | 'P' //P: Prebook; L: Late Prebook; A: At Once
  prices: {
    wholesale: number | null
    retail: number | null
  }
}

type SizeProps = {
  size: AfaSize
  availability?: number
  cartProducts: AfaCartProduct[]
  dropType: DropType
  availabilityDate: DdMmYyyyDateString
  minDeliveryDate: DdMmYyyyDateString
  customDeliveryDate?: DdMmYyyyDateString
  doorId: string
  updateAfaCart: (products: UpdateAfaCartProductPayload[]) => void
  disabled: boolean
  disabledForSelectedDoor: boolean
  upcs?: string[]
  isManageQuantities: boolean
  isLatePrebook?: boolean
  afaCatalogId?: 'A' | 'L' | 'P' //P: Prebook; L: Late Prebook; A: At Once
}

const Size: React.FC<SizeProps> = ({
  size,
  availability,
  cartProducts,
  dropType,
  availabilityDate,
  minDeliveryDate,
  customDeliveryDate,
  doorId,
  updateAfaCart,
  disabled,
  disabledForSelectedDoor,
  upcs,
  isManageQuantities,
  isLatePrebook,
  afaCatalogId,
}) => {
  const { t } = useTranslation()
  const showUpcs = useSelector(appSelectors.isActiveDebugger(DebuggerFeatures.AFA_SHOW_UPCS))
  const cartQuery = useGetAfaCartQuery()
  const cartProductsQuery = useMemo(() => {
    return cartQuery.data?.items || []
  }, [cartQuery.data])

  const productQuantitiesInCart = useMemo(() => {
    const cartProductsBase = isManageQuantities ? cartProductsQuery : cartProducts
    return cartProductsBase
      .filter(
        cartProduct =>
          cartProduct.upc === size.upc &&
          cartProduct.availabilityDate === availabilityDate &&
          cartProduct.dropType === dropType,
      )
      .reduce((result, cartProduct) => result + cartProduct.unconfirmedQuantity, 0)
  }, [availabilityDate, cartProducts, isManageQuantities, size.upc, dropType, cartProductsQuery])

  const [updatedAvailability, setUpdatedAvailability] = useState(
    availability !== undefined ? availability - productQuantitiesInCart : undefined,
  )

  useEffect(() => {
    setUpdatedAvailability(
      availability !== undefined ? availability - productQuantitiesInCart : undefined,
    )
  }, [availability, productQuantitiesInCart])

  const showAvailabilityAlert = updatedAvailability !== undefined && updatedAvailability < 0

  const upcDisabled = upcs && !upcs.includes(size.upc)

  return (
    <SizeWrapper>
      <SizeContainer>
        <SizeLabel
          className={classnames({
            disabled:
              (updatedAvailability === undefined && dropType !== 'DROP') ||
              showAvailabilityAlert ||
              disabledForSelectedDoor,
          })}
        >
          {size.size !== '' ? size.size : 'N/A'}
        </SizeLabel>
        <SizeInputContainer>
          <SizeInput
            size={size}
            cartProducts={cartProducts}
            doorId={doorId}
            status="1"
            availability={availability}
            updatedAvailability={updatedAvailability}
            setUpdatedAvailability={setUpdatedAvailability}
            dropType={dropType}
            availabilityDate={availabilityDate}
            minDeliveryDate={minDeliveryDate}
            customDeliveryDate={customDeliveryDate}
            updateAfaCart={updateAfaCart}
            disabled={disabled || upcDisabled || disabledForSelectedDoor}
            afaCatalogId={afaCatalogId}
          />
          {disabled && (
            <Spinner>
              <div>{'҉'}</div>
            </Spinner>
          )}
          <SizeAvailabilityContainer
            className={classnames({
              sizeAvailabilityAlert: showAvailabilityAlert,
            })}
          >
            <SizeAvailability>
              {dropType === 'DROP' && !isLatePrebook && !upcDisabled ? '999+' : updatedAvailability}
            </SizeAvailability>
          </SizeAvailabilityContainer>
        </SizeInputContainer>
        <AvailabilityAlert>
          {showAvailabilityAlert && (
            <span>
              {t('Afa.Cart.totalAvailable')}: {availability}
            </span>
          )}
        </AvailabilityAlert>
        {showUpcs && (
          <Debug onClick={() => navigator.clipboard.writeText(size.upc)}>{size.upc}</Debug>
        )}
      </SizeContainer>
    </SizeWrapper>
  )
}

const SizeRow: React.FC<SizeRowProps> = ({
  title,
  children,
  availability,
  dropType,
  availabilityDate,
  minDeliveryDate,
  customDeliveryDate,
  updateAfaCart,
  disabled,
  upcs,
  isLatePrebook,
  afaCatalogId,
  prices,
}) => {
  const { t } = useTranslation()

  const { modelCode } = useParams<{ modelCode: string }>()

  const selectedDelivery = useGetSelectedDelivery()
  const isCartAdjustOpen = useSelector(afaCartAdjustSelectors.isOpen)
  const adjustCartProducts = useSelector(afaCartAdjustSelectors.cartProducts)
  const brandsQuery = useGetBrandsQuery()

  const [searchParams] = useSearchParams()
  const selectedDoorId = searchParams.get('doorId') || ''
  const selectedModelCode = searchParams.get('model') || ''
  const selectedColorCode = searchParams.get('color') || ''
  const { selectedColor } = useAfaSelectedColor(modelCode || selectedModelCode)

  const productDetails = useSelector(afaCartProductDetailsSelector(selectedModelCode))

  const productDetailsColor = Object.values(productDetails?.mocos || {}).find(
    ({ colorCode }) => colorCode === selectedColorCode,
  )

  const cartQuery = useGetAfaCartQuery()
  const cartProductsQuery = useMemo(() => {
    return cartQuery.data?.items || []
  }, [cartQuery.data])

  const cartProducts = useMemo(() => {
    return isCartAdjustOpen ? adjustCartProducts : cartProductsQuery
  }, [isCartAdjustOpen, adjustCartProducts, cartProductsQuery])

  const cartProductsOfColor = useMemo(() => {
    return cartProducts.filter(
      ({ colorCode }) => colorCode === selectedColor?.colorCode || productDetailsColor?.colorCode,
    )
  }, [cartProducts, productDetailsColor?.colorCode, selectedColor?.colorCode])

  const showRowInManageQuantities =
    isCartAdjustOpen === AfaCartAdjustType.MANAGE_QUANTITIES &&
    cartProductsOfColor.some(cartProduct => {
      return (
        cartProduct.deliveryDate === selectedDelivery?.deliveryDate &&
        cartProduct.availabilityDate === availabilityDate &&
        cartProduct.deliveryDate === (customDeliveryDate || minDeliveryDate) &&
        cartProduct.dropType === dropType
      )
    })
  const showRowInOutOfStock =
    isCartAdjustOpen === AfaCartAdjustType.OUT_OF_STOCK &&
    cartProductsOfColor.some(cartProduct => {
      return (
        cartProduct.availabilityDate === availabilityDate &&
        cartProduct.deliveryDate === (customDeliveryDate || minDeliveryDate) &&
        cartProduct.dropType === dropType
      )
    })
  const showRowInExpiredDrop = isCartAdjustOpen === AfaCartAdjustType.EXPIRED_DROPS

  const availabilityQuery = useGetCartAvailabilityQuery()
  const availabilityByUpc = useMemo(
    () =>
      cartProductsQuery.map(cartProduct => {
        const availableQuantity =
          cartProduct && getCartRowAvailableQuantity(cartProduct, availabilityQuery.data)
        return {
          upc: cartProduct.upc,
          availableQuantity,
          availabilityDate: cartProduct.availabilityDate,
        }
      }),
    [availabilityQuery.data, cartProductsQuery],
  )

  const upcsAvailabilityByDelivery = useMemo(() => {
    return selectedDelivery?.deliveryDate
      ? cartProducts.map(({ availabilityDate, upc }) => {
          const availabilitySize = availabilityByUpc.find(
            availability =>
              availability.upc === upc && availability.availabilityDate === availabilityDate,
          )?.availableQuantity

          return {
            upc,
            availability:
              isCartAdjustOpen === AfaCartAdjustType.MANAGE_QUANTITIES
                ? availabilitySize
                : getUpcAvailabilityByDelivery({
                    cartProducts: cartProductsQuery,
                    deliveryDate: selectedDelivery.deliveryDate,
                    availabilityDate: availabilityDate,
                    availabilityByUpc,
                    upc,
                  }),
          }
        })
      : []
  }, [
    availabilityByUpc,
    cartProducts,
    cartProductsQuery,
    isCartAdjustOpen,
    selectedDelivery?.deliveryDate,
  ])

  const sizes = selectedColor?.sizes || productDetailsColor?.sizes || {}

  const allSizesAreDisabledForSelectedDoor = !Object.values(sizes).some(
    size => size.doors && size.doors.includes(selectedDoorId),
  )

  const brandCode = Object.values(sizes)[0]?.brandCodeParent
  const brand = (brandsQuery?.currentData || []).find(({ code }) => code === brandCode)

  if (allSizesAreDisabledForSelectedDoor) {
    return null
  }

  const showRow =
    !isCartAdjustOpen || showRowInManageQuantities || showRowInOutOfStock || showRowInExpiredDrop

  return showRow ? (
    <SizeRowContainer>
      <SizeRowHeader>
        {title && <SizeRowTitle>{title}</SizeRowTitle>}
        <SizeRowPrice>
          {brand && (prices.wholesale !== null || prices.retail !== null) && (
            <StyledPrices
              variant="horizontal"
              whsPrice={prices.wholesale || 0}
              srPrice={prices.retail || 0}
              currency={brand.currency}
              wholesaleLabel={t('Afa.WHSPrice')}
            />
          )}
        </SizeRowPrice>
      </SizeRowHeader>
      <SizeRowContent>
        {children}
        <Sizes>
          {Object.values(sizes)
            .filter(size => {
              return (
                isCartAdjustOpen !== AfaCartAdjustType.OUT_OF_STOCK ||
                (isCartAdjustOpen === AfaCartAdjustType.OUT_OF_STOCK &&
                  cartProductsOfColor.find(({ upc }) => size.upc === upc))
              )
            })
            .sort((a, b) => sortAfaSizes(a.size, b.size))
            .map(size => {
              const upcAvailabilityByDelivery = upcsAvailabilityByDelivery?.find(
                ({ upc }) => upc === size.upc,
              )?.availability

              const sizeAvailability = availability?.find(({ upc }) => upc === size.upc)
                ?.availableQuantity

              // if sizeAvailability is defined but availableQuantity is undefined, it's a Drop
              // and we don't need to count availability
              const sizeAvailabilityWithFallback =
                availability && sizeAvailability === undefined
                  ? undefined
                  : upcAvailabilityByDelivery || sizeAvailability

              const disabledForSelectedDoor = !!size.doors && !size.doors.includes(selectedDoorId)

              return (
                <Size
                  isLatePrebook={isLatePrebook}
                  upcs={upcs}
                  key={size.upc}
                  size={size}
                  availability={
                    isCartAdjustOpen === AfaCartAdjustType.MANAGE_QUANTITIES
                      ? sizeAvailabilityWithFallback
                      : sizeAvailability
                  }
                  isManageQuantities={isCartAdjustOpen === AfaCartAdjustType.MANAGE_QUANTITIES}
                  cartProducts={cartProductsOfColor}
                  dropType={dropType}
                  availabilityDate={availabilityDate}
                  minDeliveryDate={minDeliveryDate}
                  customDeliveryDate={customDeliveryDate}
                  doorId={selectedDoorId}
                  updateAfaCart={updateAfaCart}
                  disabled={disabled}
                  disabledForSelectedDoor={disabledForSelectedDoor}
                  afaCatalogId={
                    availability?.find(({ upc }) => upc === size.upc)?.afaCatalogId || afaCatalogId
                  }
                />
              )
            })}
        </Sizes>
      </SizeRowContent>
    </SizeRowContainer>
  ) : (
    <></>
  )
}

export default SizeRow
