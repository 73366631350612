import { Layout } from 'antd'
import React, { Component } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from 'styled-components'

import BrandsSlider from '../../components/BrandsSlider'
import CouvetteContainer from '../../components/CouvetteContainer'
import FooterApplyButton from '../../components/FooterNav/FooterNavRightCol/FooterApplyButton'
import Header from '../../components/Header'
import PageSocketManager, { PageSocketManagerProps } from '../../components/PageSocketManager'
import devices from '../../config/devices'
import { mapDispatchToProps } from '../../libs'
import { Brand } from '../../model/brand'
import { BrandCode, RootState } from '../../model/model'
import { Product } from '../../model/product'
import { WishlistProduct } from '../../model/wishlist'
import {
  preselectBrandsAction,
  setFiltersActivePageName,
  setWishlistCurrentCategoryId,
  showFooterModal,
  showNotificationsAction,
} from '../../store/actions'
import { brandsSelector } from '../../store/brands/selectors'
import { isPdpVisibleSelector } from '../../store/pdp/selectors'
import {
  wishlistCouvettesSelector,
  wishlistCurrentCategoryIdSelector,
  wishlistFilterItemsSelector,
  wishlistProductsSelector,
} from '../../store/wishlist/selectors'
import FooterNav from '../FooterNav'

const Wrapper = styled(Layout)`
  background: #eee;
  .couvette {
    overflow-y: auto;
    align-self: flex-start;
    justify-content: flex-start;
  }
  .couvette-single-wrapper {
    padding-bottom: 0;
  }
  .couvettes {
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    align-items: self-start;
    &-wrapper {
      .ReactVirtualized__Grid {
        .ReactVirtualized__Grid__innerScrollContainer {
          .couvette {
            overflow: visible;
            height: 100%;
          }
        }
      }
    }
  }
  .couvette-single-wrapper {
    position: relative;
    background: transparent;
    max-height: 100%;
    box-shadow: 0 2vh 2vw #dcdcdc;
  }
  .couvette-item {
    margin-top: -4px;
  }
  .not-preferred-items-wrapper {
    .couvette-item {
      border-color: #fff;
      margin-bottom: 8px;
    }
  }
  .size-n,
  .sku i {
    display: none;
  }

  .couvette-full {
    padding-bottom: 3.5vh;
    .couvette-single-wrapper {
      .show-more-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1em;
        background-color: #fff;
        font-size: 0.9em;
        .show-more-mocos {
          color: #999;
          display: flex;
          .label {
            display: flex;
            align-items: center;
            text-transform: uppercase;
          }
          i {
            margin-left: 1rem;
          }
        }
        .show-more-additional-mocos {
          display: flex;
          margin-left: auto; // trick to simulate justify self: flex-end
          .label {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            color: #005192;
          }
          .show-more-additional-mocos-icon-container {
            border: 1px solid #005192;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 1rem;
            height: 2.3rem;
            width: 2.3rem;
            @media screen and (max-width: 2000px) {
              height: 1.3rem;
              width: 1.3rem;
            }
          }
        }
      }
      .show-more-container-bottom {
        position: fixed;
        bottom: 0;
        z-index: 1;
      }
    }
  }
`

type OwnProps = Record<string, unknown>

type ConnectedProps = {
  brands: Brand[]
  wishlistProducts: WishlistProduct[]
  isPdpVisible: boolean
  couvettes: {
    loading: boolean
    loaded: boolean
    items: Product[]
  }
  wishlistCurrentCategoryId: string | 0 | undefined
  wishlistFilterItems: (categoryId: string | 0 | undefined) => Product[]
  actions: {
    setFiltersActivePageName: typeof setFiltersActivePageName
    showNotificationsAction: typeof showNotificationsAction
    showFooterModal: typeof showFooterModal
    preselectBrandsAction: typeof preselectBrandsAction
    setWishlistCurrentCategoryId: typeof setWishlistCurrentCategoryId
  }
}

type Props = OwnProps & ConnectedProps & WithTranslation & PageSocketManagerProps

type State = {
  activeCouvetteId: null | string | undefined
  isFiltersBrandOpen: boolean
  showFiltersBrandBar: boolean
}

class TableWishlistPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = this.getInitialState()
  }

  getInitialState = () => ({
    activeCouvetteId: null,
    isFiltersBrandOpen: false,
    showFiltersBrandBar: false,
  })

  componentDidMount = () => {
    this.emitNavigationEvent()
    this.props.actions.showNotificationsAction('missingModelCodes')
    this.props.actions.setWishlistCurrentCategoryId(undefined)
    this.setState({ activeCouvetteId: null })
  }

  componentDidUpdate = (prevProps: Props) => {
    const { socketIoEvent, isPdpVisible } = this.props

    // NOTE: when couvette video ends, table should broadcast again the navigation event
    if (prevProps.socketIoEvent === 'do_play_video' && socketIoEvent === 'do_ended_video') {
      // NOTE: we want the signal to be sent only for couvette videos, not the pdp video
      if (!isPdpVisible) {
        this.emitNavigationEvent()
      }
    }

    // NOTE: when pdp gets closed, table should broadcast again the navigation event
    if (prevProps.isPdpVisible === true && isPdpVisible === false) {
      this.emitNavigationEvent()
    }
  }

  emitNavigationEvent = () => {
    this.props.emitEvent('open_tableWishlist_page', {}, devices.table)
  }

  onWishlistBrandClicked = (brand: Brand | undefined) => {
    const wishlistBrandCouvettes = this.props.wishlistFilterItems(
      this.props.wishlistCurrentCategoryId,
    )
    if (brand) {
      const firstBrandCouvette = wishlistBrandCouvettes.find(
        (couvette: any) =>
          couvette.brandCode === brand.code || brand.group.includes(couvette.brandCode),
      )
      this.setState({
        activeCouvetteId: firstBrandCouvette?.modelCode,
      })
    } else {
      this.setState({
        activeCouvetteId: wishlistBrandCouvettes[0]
          ? wishlistBrandCouvettes[0].modelCode
          : undefined,
      })
    }
  }

  render = () => {
    const { wishlistProducts, brands } = this.props

    const wishlistBrandsMap = wishlistProducts.reduce((result, { brandCode }) => {
      result[brandCode] = true
      return result
    }, {} as Record<BrandCode, true>)
    const wishlistBrands = brands.filter(
      brand => wishlistBrandsMap[brand.code] || brand.group.find(code => wishlistBrandsMap[code]),
    )

    return (
      <>
        <Wrapper className="layout has-couvettes">
          <Header {...this.props} prependElement={<FooterApplyButton hideOnIpad={false} />} />

          {wishlistBrands.length !== 0 ? (
            <BrandsSlider
              brands={wishlistBrands}
              maxSlides={6}
              onBrandClicked={this.onWishlistBrandClicked}
            />
          ) : null}

          <CouvetteContainer
            activeCouvetteId={this.state.activeCouvetteId}
            showBrandLogo={true}
            hideHeader={false}
            isWishlist={true}
            couvettes={this.props.couvettes}
          />
        </Wrapper>
        <FooterNav />
      </>
    )
  }
}

function mapStateToProps(state: RootState): Omit<ConnectedProps, 'actions'> {
  return {
    brands: brandsSelector(state),
    wishlistProducts: wishlistProductsSelector(state),
    isPdpVisible: isPdpVisibleSelector(state),
    couvettes: wishlistCouvettesSelector(state),
    wishlistCurrentCategoryId: wishlistCurrentCategoryIdSelector(state),
    wishlistFilterItems: wishlistFilterItemsSelector(state),
  }
}

const ConnectedTableWishlist = connect(mapStateToProps, mapDispatchToProps())(TableWishlistPage)

const TableWishlistManager = PageSocketManager(ConnectedTableWishlist, [
  'do_play_video',
  'do_ended_video',
])

export default withTranslation('common')(TableWishlistManager) as React.ComponentType<OwnProps>
