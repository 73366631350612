import React from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components/macro'

import AfaFooter from '../../components/AfaFooter'
import AfaSearch from '../../components/AfaSearch/AfaSearch'
import AfaHeader from '../../components/Header/AfaHeader'
import useFetchInitialData from '../../hooks/useFetchInitialData'
import { useHandleOpenKeyLooksPage, useShowMoodboard } from '../../hooks/useHandleOpenPages'
import { useSearchParams } from '../../hooks/useSearchParams'
import { getDeviceFromUrl } from '../../libs/url'
import AfaMoodboard from '../AfaMoodboard/AfaMoodboard'
import AfaPlpFooter from '../AfaPlp/AfaPlpFooter'
import AfaKeylooksMain, { CustomAfaModal } from './AfaKeylooksMain'
import AfaKeylooksSidebar from './AfaKeylooksSidebar'
import { useDispatch, useSelector } from 'react-redux'
import { openPinModalSelector } from '../../store/whiteboard/selectors'
import WhiteboardModalPin from '../Whiteboard/WhiteboardModalPin'
import whiteboardActions from '../../store/whiteboard/actions'
import { useGetBrandCategoriesQuery } from '../../services/afa'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`

const Main = styled.main`
  overflow-y: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Body = styled.div`
  overflow-y: hidden;
  flex: 1;
  display: grid;
  grid-template-columns: max-content 1fr;
`

const AfaKeylooks: React.FC = () => {
  useFetchInitialData({ loadCustomer: true })
  const openPinModal = useSelector(openPinModalSelector)
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()

  const keylookCode = searchParams.get('keylook') || undefined
  const sport = keylookCode?.split('-')[0]

  useHandleOpenKeyLooksPage(keylookCode)

  const { brandCode } = useParams<{ brandCode: string }>()
  const { moodboardOpenOn } = useShowMoodboard(brandCode, sport, keylookCode)
  const categoriesQuery = useGetBrandCategoriesQuery(brandCode)
  const sports = categoriesQuery.data?.sports || []
  const selectedKeylookSport = sports.find(({ keylooks }) =>
    keylooks?.find(({ code }) => code === keylookCode),
  )
  const selectedKeylook = selectedKeylookSport?.keylooks.find(({ code }) => code === keylookCode)
  const device = getDeviceFromUrl()

  return moodboardOpenOn === device ? (
    <AfaMoodboard sport={sport} keylook={keylookCode} />
  ) : (
    <Wrapper>
      <AfaSearch />
      <AfaHeader />

      <Main>
        <Body>
          <AfaKeylooksSidebar />
          <AfaKeylooksMain />
        </Body>
      </Main>

      <AfaPlpFooter />

      <AfaFooter />

      <CustomAfaModal
        isOpen={openPinModal}
        closeOnBackdropClick
        onRequestClose={() => {
          dispatch(whiteboardActions.setOpenPinModal(false))
          dispatch(whiteboardActions.resetItemsPinModal())
        }}
        fullscreen={false}
      >
        <WhiteboardModalPin keylookName={selectedKeylook?.name} />
      </CustomAfaModal>
    </Wrapper>
  )
}

export default AfaKeylooks
