import React, { SVGProps } from 'react'

const ArrowRight: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="currentColor"
    {...props}
  >
    <path d="M 0.02298365,13.064 C 2.0109837,11.253667 3.9989837,9.443333 5.9869837,7.633 5.9073837,6.971483 5.0569837,6.672547 4.6656637,6.162769 3.1181037,4.753512 1.5705437,3.344258 0.02298365,1.935 c 0,-0.645 0,-1.29 0,-1.935 0.48256,0.160465 0.79962,0.650821 1.21662005,0.946563 2.11646,1.85048 4.23292,3.700956 6.34938,5.551437 0,0.667667 0,1.335333 0,2.003 -2.47733,2.166335 -4.95467,4.332665 -7.43200005,6.499 -0.29712,-0.151666 -0.0688,-0.659077 -0.134,-0.97459 0,-0.32047 0,-0.64094 0,-0.96141 z" />
  </svg>
)

export default ArrowRight
