import { format } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Appointment } from '../../model/appointment'
import { selectAppointmentBackground } from '../../store/digitalEvents/selectors'
import {
  breakpoints,
  getFluidFontSize,
  getFluidFontSizeDe,
  palette,
  pxToRem,
  spacing,
} from '../../style/theme'

const Wrapper = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  align-items: center;
  display: grid;
  grid-template-columns: 1.3fr 0.8fr 3fr;
  gap: ${pxToRem(spacing(2))}rem;

  @media (max-width: ${breakpoints.S}) {
    grid-template-columns: 2fr 5.5fr;
  }
`

const AppointmentDateWrapper = styled.div`
  position: relative;
  align-self: center;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  padding: ${pxToRem(spacing(2))}rem;
  justify-content: center;
  overflow: hidden;
  height: 100%;

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    /* ensure that fills all space without overlfow */
    height: 100vh;
    width: 1px;
    top: -8px;
    bottom: 0;
    right: 0;
  }
`

const AppointmentDate = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${getFluidFontSizeDe(11, 16)};
`
const Day = styled.span`
  font-size: ${getFluidFontSizeDe(38, 54)};
  line-height: 1;
`

const Month = styled.span`
  text-transform: uppercase;
`
const TimeAndButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const StyledLink = styled(Link)`
  display: flex;
  align-self: flex-end;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: underline;
  color: ${palette.white};
  font-size: ${getFluidFontSizeDe(13, 16)};

  &:hover {
    color: ${palette.white};
  }
`

const IconWrapper = styled.div`
  width: 6.5vh;
  height: 6.5vh;
  border-radius: 6vh;
  overflow: hidden;
  background-color: ${palette.bigStone};

  @media (max-width: ${breakpoints.S}) {
    display: none;
  }
`

const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
`

const DetailsWrapper = styled.div`
  font-size: ${getFluidFontSize('10px')};
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${pxToRem(spacing(1))}rem 0;

  @media (max-width: ${breakpoints.M}) {
    place-self: start;
  }
`

const Tipology = styled.span`
  opacity: 0.8;
  text-transform: uppercase;
  font-size: 1rem;
`

const Title = styled.span`
  margin-bottom: 0.5em;
  font-weight: 500;
  font-size: ${getFluidFontSizeDe(20, 22)};
  line-height: 1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-bottom: ${getFluidFontSizeDe(3, 4)};
  @media (max-width: ${breakpoints.M}) {
    margin-bottom: 0em;
  }
`

const Time = styled.span`
  opacity: 0.8;
  font-size: ${getFluidFontSizeDe(14, 16)};
`

const NextAppointment: React.FC<{ appointment: Appointment }> = ({ appointment }) => {
  const { idAppointment, startDate, appointmentType, subject } = appointment
  const { t } = useTranslation()
  const image = useSelector(selectAppointmentBackground(appointment))

  return (
    <Wrapper key={idAppointment}>
      <AppointmentDateWrapper>
        <AppointmentDate>
          <Day>{format(new Date(startDate), 'd')}</Day>
          <span>{format(new Date(startDate), 'do').replaceAll(/[0-9]/g, '')}</span>
        </AppointmentDate>
        <Month>{format(new Date(startDate), 'LLL')}</Month>
      </AppointmentDateWrapper>
      <IconWrapper>
        <Img src={image} />
      </IconWrapper>
      <DetailsWrapper>
        <Tipology>{appointmentType.description}</Tipology>
        <Title>{subject}</Title>
        <TimeAndButton>
          <Time>{format(new Date(startDate), 'h.mm aaa')}</Time>{' '}
          <StyledLink to={`/digital-events/appointment/${idAppointment}`}>
            {t('Dashboard.appointments_button')}
          </StyledLink>
        </TimeAndButton>
      </DetailsWrapper>
    </Wrapper>
  )
}

export default NextAppointment
