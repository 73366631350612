import React from 'react'
import styled from 'styled-components'

import { palette } from '../style/theme'

const Label = styled.label<{
  backgroundColorChecked?: string
  backgroundColorUnchecked?: string
  borderColor?: string
}>`
  color: inherit;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  > span {
    position: relative;
    vertical-align: center;
    display: flex;
    align-items: center;
    align-content: center;
  }

  span::before {
    content: '';
    width: 1em;
    height: 1em;
    display: inline-block;
    vertical-align: center;
    border-radius: 2em;
    border: solid 1px ${({ borderColor }) => borderColor ?? palette.cornflowerBlue};
    background-color: ${({ backgroundColorUnchecked }) =>
      backgroundColorUnchecked ?? palette.selago};
    margin-right: 1em;
  }

  input[type='radio']:checked + span::after {
    content: '';
    width: 0.5em;
    height: 0.5em;
    border-radius: 2em;
    background-color: ${({ backgroundColorChecked }) =>
      backgroundColorChecked ?? palette.cornflowerBlue};
    position: absolute;
    top: 0.5em;
    left: 0.25em;
  }

  input[type='radio']:disabled + span {
    opacity: 0.5;
  }
`

const Text = styled.span``

const Input = styled.input`
  display: none;
`

type Props = {
  value?: any
  checked: boolean
  children?: any
  onChange: (e: any) => void
  disabled?: boolean
  className?: string
  backgroundColorChecked?: string
  backgroundColorUnchecked?: string
  borderColor?: string
}

const Radio: React.FC<Props> = ({ value, checked, onChange, children, disabled, ...props }) => (
  <Label {...props}>
    <Input
      value={value}
      checked={checked}
      type="radio"
      disabled={disabled}
      onChange={e => {
        e.stopPropagation()
        onChange && onChange(e)
      }}
    />
    <Text>{children}</Text>
  </Label>
)

export default Radio
