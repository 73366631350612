import { Button, Col } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Row from '../../styleguide/Row'

const UsernameCol = styled(Col)`
  font-size: 1rem;
`

const ColUserEmpty = styled(Col)`
  padding-top: 50px;
`

const sendClearSessionEvent = () => {
  localStorage.clear()
  window.location.reload()
}

const EmptyUser: React.FC<{ username: string }> = ({ username }) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Row>
        <UsernameCol span={3}>
          <span>{username}</span>
        </UsernameCol>
        <ColUserEmpty span={18} style={{ textAlign: 'center' }}>
          <h1>{t('User.noEvents')}</h1>
          <Button onClick={() => sendClearSessionEvent()}>{t('User.backToLogin')}</Button>
        </ColUserEmpty>
        <Col span={3} />
      </Row>
    </React.Fragment>
  )
}

export default EmptyUser
