import { Brand } from '../model/brand'
import { CartProduct } from '../model/cart'

const analyticsWorker = new Worker('./calculate-analytics.worker', { type: 'module' })

/** METHODS */
/**
 * Calculated cart analytics statistics
 * @param {array} products Array of cart products
 * @returns {object} Total cart statistics as well as separate statistics for each brand in cart
 */
export const requestCalculateAnalytics = (
  cartProducts: CartProduct[],
  brands: Brand[],
  cartProductsDetails = {},
) => {
  return new Promise(res => {
    let requestId: string | undefined = undefined
    const cb = (e: { data: { requestId: string; response: unknown } }) => {
      if (e.data.requestId === requestId) {
        analyticsWorker.removeEventListener('message', cb)
        res(e.data.response)
      }
    }
    analyticsWorker.addEventListener('message', cb)
    requestId = (Date.now() + Math.random()).toString()
    analyticsWorker.postMessage({ cartProducts, brands, cartProductsDetails, requestId })
  })
}
