import React, { SVGProps } from 'react'

const TrashIcon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="currentColor"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.517 2.923h4.905a.434.434 0 0 1 .435.434.413.413 0 0 1-.434.434h-1.346v10.375a3.255 3.255 0 0 1-3.256 3.255H5.636a3.256 3.256 0 0 1-3.256-3.255V3.79H1.013a.413.413 0 0 1-.434-.434c0-.24.194-.434.434-.434h4.905v-.825A1.519 1.519 0 0 1 7.437.578h2.561a1.519 1.519 0 0 1 1.52 1.52v.825zm-4.54-1.285a.651.651 0 0 0-.191.46v.825h3.863v-.825a.651.651 0 0 0-.651-.65H7.437a.651.651 0 0 0-.46.19zm6.532 14.216c.448-.448.7-1.055.7-1.688V3.79H3.247v10.374a2.387 2.387 0 0 0 2.387 2.388h6.186c.633 0 1.24-.252 1.688-.7zM6.051 13.93a.434.434 0 0 1-.741-.307v-7.38a.434.434 0 0 1 .868 0v7.38a.434.434 0 0 1-.127.307zm2.666.127a.433.433 0 0 0 .434-.434v-7.38a.434.434 0 1 0-.868 0v7.38a.434.434 0 0 0 .434.434zm3.28-.127a.434.434 0 0 1-.74-.307v-7.38a.434.434 0 0 1 .868 0v7.38a.434.434 0 0 1-.127.307z"
      />
    </g>
  </svg>
)

export default TrashIcon
