import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Loading from '../../components/Loading'
import { setIsGridView } from '../../store/actions'
import { appLoadingSelector } from '../../store/app/selectors'
import { couvettesSelector } from '../../store/couvettes/selectors'
import { isPlpStarsModeSelector } from '../../store/stars/selectors'
import CouvetteContainer from '../CouvetteContainer'
import CouvetteStars from '../CouvetteStars'
import GridView from '../GridView/GridView'
import Header from '../Header'
import { categoryIsLoadingSelector } from '../../store/initiatives/selectord'

type Props = {
  showBrandLogo: boolean
  getNextPage: () => void
}

const PLPManager: React.FC<Props> = ({ showBrandLogo, getNextPage }) => {
  const dispatch = useDispatch()

  const couvettes = useSelector(couvettesSelector)
  const isStars = useSelector(isPlpStarsModeSelector)
  const appLoading = useSelector(appLoadingSelector)
  const categoryIsLoading = useSelector(categoryIsLoadingSelector)

  useEffect(() => {
    return () => {
      dispatch(setIsGridView(false))
    }
  }, [dispatch])

  return (
    <>
      <Header />
      {!isStars ? (
        couvettes.isGridView ? (
          <GridView getNextPage={getNextPage} />
        ) : (
          <>
            <CouvetteContainer
              activeCouvetteId={null}
              hideHeader={false}
              showBrandLogo={showBrandLogo}
              getNextPage={getNextPage}
              couvettes={couvettes}
            />
            {(appLoading || couvettes.loading || categoryIsLoading) && <Loading />}
          </>
        )
      ) : (
        <CouvetteStars />
      )}
    </>
  )
}

export default PLPManager
