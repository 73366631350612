import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DisplayMedia from '../components/DisplayMedia'
import VtoModal from '../components/VirtualMirrorModal'
import socketManager from '../libs/socketManager'
import { Product } from '../model/product'
import { multibrandWallSelector } from '../store/app/selectors'
import { openVTOView, searchPdpCouvetteSuccess } from '../store/pdp/actions'
import {
  isVtoModalVisibleSelector,
  pdpCouvetteSelector,
  virtualMirrorAvailabilitySelector,
} from '../store/pdp/selectors'

type State = {
  upc: string
  couvette: Product | undefined
}

const VTOView: React.FC = () => {
  const couvette = useSelector(pdpCouvetteSelector)
  const virtualMirrorAvailability = useSelector(virtualMirrorAvailabilitySelector)
  const [state, setState] = useState<State>({
    upc: '',
    couvette: undefined,
  })

  const multibrandWall = useSelector(multibrandWallSelector)
  const showVto = useSelector(isVtoModalVisibleSelector)

  const dispatch = useDispatch()

  useEffect(() => {
    socketManager.bindListener('do_open_vto', (pageData: State) => {
      setState(pageData)
    })
  })

  useEffect(() => {
    if (state?.upc && !!couvette && Object.keys(virtualMirrorAvailability).length > 0) {
      dispatch(openVTOView(state?.upc))
    }
  }, [couvette, dispatch, state?.upc, virtualMirrorAvailability])

  useEffect(() => {
    if (state?.couvette) {
      dispatch(searchPdpCouvetteSuccess(state?.couvette))
    }
  }, [dispatch, state?.couvette])

  return showVto ? <VtoModal /> : <DisplayMedia media={multibrandWall} loop={true} />
}

export default VTOView
