import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import ChevronDownIcon from '../../components/icons/ChevronDownIcon'
import afaActions from '../../store/afa/actions'
import { sortOpenSelector } from '../../store/afa/selectors'
import {
  breakpoints,
  duration,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
} from '../../style/theme'

const Button = styled.div`
  display: flex;
  gap: ${pxToRem(6)}rem;
  align-items: center;
  text-transform: capitalize;
  color: ${palette.tangaroa};
`

const AnimatedChevronIcon = styled(ChevronDownIcon)`
  width: ${getFluidSizeWithFullFormula('px', 16, 24, 1366, 3840)};
  height: ${getFluidSizeWithFullFormula('px', 16, 24, 1366, 3840)};
  transition: transform ${duration.short}ms;
  transform: rotate(-90deg);

  &.is-open {
    transform: rotate(90deg);
  }

  @media (max-width: ${breakpoints.M}) {
    transform: unset;

    &.is-open {
      transform: rotate(180deg);
    }
  }
`

const AfaSortButton: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isOpen = useSelector(sortOpenSelector)

  return (
    <Button onClick={() => dispatch(afaActions.toggleSortOpen())}>
      {t('Afa.sortBy')}
      <AnimatedChevronIcon className={isOpen ? 'is-open' : ''} />
    </Button>
  )
}

export default AfaSortButton
