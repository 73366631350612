import { Icon } from 'antd'
import classnames from 'classnames'
import React, { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import app_config from '../../../config/app/config'
import { useChangeShopCategory } from '../../../hooks/useChangeShopCategory'
import { countItemsInCart, countItemsProcessed } from '../../../libs/cart'
import { hasDisabledDoors } from '../../../libs/doors'
import { isExtraIpadDevice, isPageMatches } from '../../../libs/url'
import { countItemsInWishlist } from '../../../libs/wishlist'
import { ModalType } from '../../../model/model'
import { footerModalVisibleSelector, getIsMassiveOrderEnabled } from '../../../store/app/selectors'
import {
  assortmentsViewTypeSelector,
  cartProductsFilteredByActiveDoorsSelector,
} from '../../../store/cart/selectors'
import { customerDoorsSelector, customerNameSelector } from '../../../store/customer/selectors'
import { isPdpVisibleSelector } from '../../../store/pdp/selectors'
import {
  disableCartButtonSelector,
  isWishlistPageSelector,
  showShopCategorySwitcherSelector,
} from '../../../store/showButtonsSelectors'
import {
  isWishlistLoadingSelector,
  wishlistProductsSelector,
} from '../../../store/wishlist/selectors'
import { breakpointsCross, pxToRem } from '../../../style/theme'
import { FooterButton, footerLinksAndButtonsStyle } from '../../CommonComponents'
import TextBadge from '../../TextBadge'
import RCIcon from '../../UI/RCIcon'
import LinkTo from '../FooterLink'
import FooterApplyButton from './FooterApplyButton'
import AACta from '../../../flows/AssortmentAdvisor/Components/AACta'

const Wrapper = styled.div`
  display: grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  gap: 1rem;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  grid-column-start: 3;
`

const SwitchButton = styled.button`
  ${footerLinksAndButtonsStyle};

  @media (max-width: ${breakpointsCross.L.max}) {
    display: none;
  }
`
SwitchButton.displayName = 'SwitchButton'

const DoorsButton = styled(FooterButton)``

const WishlistButton = styled(LinkTo)`
  ${footerLinksAndButtonsStyle};
  position: relative;
  .ant-spin {
    margin-left: ${pxToRem(0.5)}rem;
  }
`

const CartButton = styled(LinkTo)`
  ${footerLinksAndButtonsStyle};
  position: relative;
  .ant-spin {
    margin-left: ${pxToRem(0.5)}rem;
  }
`

const AccountButton = styled(FooterButton)`
  text-align: left;

  .label {
    max-width: 15em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

type Props = {
  toggleModal: (type: ModalType) => void
}

export const FooterNavRightCol: React.FC<Props> = ({ toggleModal }) => {
  const { t } = useTranslation()

  const shopCategory = useSelector(assortmentsViewTypeSelector)?.category
  const assortmentViewType = useSelector(assortmentsViewTypeSelector)
  const wishlistProducts = useSelector(wishlistProductsSelector)
  const isWishlistPage = useSelector(isWishlistPageSelector)
  const showShopCategorySwitcher = useSelector(showShopCategorySwitcherSelector)
  const isMassiveOrderEnabled = useSelector(getIsMassiveOrderEnabled)
  const cartProductsFilteredByActiveDoors = useSelector(cartProductsFilteredByActiveDoorsSelector)
  const itemsInCart = countItemsInCart(cartProductsFilteredByActiveDoors)
  const itemsProcessed = countItemsProcessed(cartProductsFilteredByActiveDoors)
  const disableCartButton = useSelector(disableCartButtonSelector)
  const customerName = useSelector(customerNameSelector)
  const customerDoors = useSelector(customerDoorsSelector)
  const modalVisible = useSelector(footerModalVisibleSelector)
  const isPdpOpen = useSelector(isPdpVisibleSelector)
  const isWishlistLoading = useSelector(isWishlistLoadingSelector)

  const changeShopCategory = useChangeShopCategory()

  const changeModal = (type: ModalType): MouseEventHandler<HTMLButtonElement> => e => {
    e.stopPropagation()
    toggleModal(type)
  }

  return (
    <Wrapper className="footer-nav-right-col">
      <AACta />
      {isWishlistPage && <FooterApplyButton hideOnIpad={true} />}
      {!isPdpOpen && showShopCategorySwitcher && (
        <>
          <SwitchButton
            id={`footer-linkto-shop-cat${app_config.sunCategory}-button`}
            className={classnames({
              active: shopCategory === app_config.sunCategory,
            })}
            onClick={() => changeShopCategory(assortmentViewType.category ? undefined : '2')}
          >
            {t('CartPage.mySunShop')}
          </SwitchButton>
          <SwitchButton
            id={`footer-linkto-${app_config.opticalCategory}-button`}
            className={classnames({
              active: shopCategory === app_config.opticalCategory,
            })}
            onClick={() => changeShopCategory(assortmentViewType.category ? undefined : '1')}
          >
            {t('CartPage.myOpticalShop')}
          </SwitchButton>
        </>
      )}
      {!isExtraIpadDevice() && (
        <DoorsButton
          className={classnames({
            'footer-doors-button': true,
            active: modalVisible === 'doors' || hasDisabledDoors(customerDoors),
          })}
          onClick={changeModal('doors')}
        >
          <RCIcon type="doors-list" />
          <span className="label">{t('GenericWords.doors')}</span>
          <Icon type={modalVisible === 'doors' ? 'up' : 'down'} theme="outlined" />
          {isMassiveOrderEnabled && <TextBadge message={t('FooterNav.massiveOrder')} />}
        </DoorsButton>
      )}
      <WishlistButton
        type="wishlist"
        count={countItemsInWishlist(wishlistProducts)}
        disabled={disableCartButton || isWishlistLoading}
        className={classnames({
          active: isPageMatches('wishlist'),
        })}
      />
      <CartButton
        type="cart"
        count={itemsInCart}
        processed={itemsProcessed}
        disabled={disableCartButton}
        className={classnames({
          disabled: disableCartButton,
          active: isPageMatches('cart'),
        })}
      />
      {!isExtraIpadDevice() && (
        <AccountButton
          className={classnames({
            'account-button': true,
            active: modalVisible === 'login',
          })}
          onClick={() => toggleModal('login')}
        >
          <RCIcon type="account" />
          <div className="label">{customerName}</div>
        </AccountButton>
      )}
    </Wrapper>
  )
}

export default FooterNavRightCol
