import React from 'react'
import { useTranslation } from 'react-i18next'
import { AfaCartProduct, AfaSize } from '../../model/afa'
import {
  SizeRowContainer,
  SizeRowTitle,
  SizeContainer,
  SizeDropTitle,
  SizeInputContainer,
  SizeLabel,
  SizeRowContent,
  Sizes,
  SizeWrapper,
  SizeAvailabilityContainer,
  SizeAvailability,
} from './DropRowsStyles'
import styled from 'styled-components'
import { breakpointsCross, getFluidSizeWithFullFormula as gF, palette } from '../../style/theme'
import SizeDate from './SizeDate'
import { sortAfaSizes } from '../../libs/afa'
import { isPageMatches } from '../../libs/url'
import { useDispatch } from 'react-redux'
import afaCartAdjustActions from '../../store/afaCartAdjust/actions'

const ExpiredRowTitle = styled(SizeRowTitle)`
  display: flex;
  align-items: center;
  column-gap: 1ch;
`

const ExpiredRowTitleImg = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23wr6ukatxja)'%3E%3Cpath d='M14.489 1.5H1.51C.678 1.5 0 2.178 0 3.011v7.567c0 .834.678 1.512 1.511 1.512.203 0 .393.08.535.224a.744.744 0 0 1 .215.538c-.054 3.002.006 3.027.29 3.148a.406.406 0 0 0 .43-.083c.11-.107 1.8-1.798 3.61-3.608a.743.743 0 0 1 .53-.22h7.368c.833 0 1.511-.677 1.511-1.51V3.01c0-.833-.678-1.511-1.511-1.511zm.749 9.078a.75.75 0 0 1-.75.75H7.122a1.5 1.5 0 0 0-1.068.442l-3.042 3.041a61.036 61.036 0 0 1 .012-1.945 1.499 1.499 0 0 0-.433-1.086 1.5 1.5 0 0 0-1.078-.452.75.75 0 0 1-.75-.75V3.011a.75.75 0 0 1 .75-.75h12.977a.75.75 0 0 1 .75.75l-.001 7.567z' fill='%23031434'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='wr6ukatxja'%3E%3Cpath fill='%23fff' transform='translate(0 .5)' d='M0 0h16v16H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  width: 1em;
  height: 1em;
  background-repeat: no-repeat;
  background-size: contain;
`

const Subtitle = styled.div`
  display: flex;
  justify-content: space-between;
`
const SubTitleInfo = styled.span`
  font-family: GilmerMedium, sans-serif;
  font-size: ${gF('px', 14, 28, 1366, 3840)};
  color: ${palette.cloudBurst};
`

const RemoveExpiredDrop = styled.span`
  font-family: GilmerMedium, sans-serif;
  font-size: ${gF('px', 12, 24, 1366, 3840)};
  color: ${palette.cloudBurst};
  cursor: pointer;
`

const SizeFakeInput = styled.div`
  background-color: transparent;
  border: 1px solid;
  outline: none;
  font-size: clamp(16px, ${gF('px', 16, 38.9, 1366, 3840)}, 38.9px);
  width: clamp(60px, ${gF('px', 60, 145.7, 1366, 3840)}, 145.7px);
  min-height: clamp(40px, ${gF('px', 40, 97.2, 1366, 3840)}, 97.2px);
  display: flex;
  align-items: center;
  justify-content: center;

  &.disabled {
    color: ${palette.santasGray};
  }

  @media (max-width: ${breakpointsCross.M.max}) {
    flex: 1;
    width: 100%;
  }
`
const InfoExpired = styled(SizeRowContainer)``

type Props = {
  sizes: AfaSize[]
  cartProducts: AfaCartProduct[]
}

const SizeRowExpiredDrop: React.FC<Props> = ({ sizes, cartProducts }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const deliveryDate = cartProducts[0].deliveryDate
  const isCustomDelivery = cartProducts[0].deliveryDate !== cartProducts[0].minDeliveryDate

  if (!cartProducts.length || !sizes.length) {
    return null
  }

  const sizesToRender = sizes.map(({ upc, size }) => {
    const cartProduct = cartProducts.filter(
      cartProduct => cartProduct.upc === upc && cartProduct.deliveryDate === deliveryDate,
    )

    const quantity = cartProduct.reduce((sum, item) => {
      sum += item.unconfirmedQuantity || 0
      return sum
    }, 0)

    return {
      size,
      quantity,
      upc,
    }
  })

  const isPdp = isPageMatches('pdp')

  return (
    <SizeRowContainer>
      <SizeRowTitle>{t('Afa.Cart.Expired.Title')}</SizeRowTitle>

      <InfoExpired className="expired">
        <ExpiredRowTitle>
          <ExpiredRowTitleImg />
          {t('Afa.Cart.Expired.Title')}
        </ExpiredRowTitle>
        <Subtitle>
          <SubTitleInfo>
            {isPdp ? t('Afa.Cart.Expired.Pdp') : t('Afa.Cart.Expired.Info')}
          </SubTitleInfo>
          <RemoveExpiredDrop
            onClick={() =>
              dispatch(afaCartAdjustActions.setCartExpiredProductsToRemove(cartProducts))
            }
          >
            {t('Afa.Cart.removeItems')}
          </RemoveExpiredDrop>
        </Subtitle>
      </InfoExpired>

      <SizeRowContent>
        <SizeDate
          date={deliveryDate}
          className="expiredDate"
          type={isCustomDelivery ? 'customDelivery' : ''}
        >
          <SizeDropTitle>{isCustomDelivery ? t('Afa.delivery') : t('Afa.exDrop')}</SizeDropTitle>
        </SizeDate>
        <Sizes>
          {sizesToRender
            .sort((a, b) => sortAfaSizes(a.size, b.size))
            .map(({ size, quantity, upc }) => {
              return (
                <SizeWrapper key={upc}>
                  <SizeContainer>
                    <SizeLabel className={'disabled'}>{size !== '' ? size : 'N/A'}</SizeLabel>
                    <SizeInputContainer>
                      <SizeFakeInput className={'disabled'}>{quantity}</SizeFakeInput>
                      <SizeAvailabilityContainer className={'disabled'}>
                        <SizeAvailability>{quantity}</SizeAvailability>
                      </SizeAvailabilityContainer>
                    </SizeInputContainer>
                  </SizeContainer>
                </SizeWrapper>
              )
            })}
        </Sizes>
      </SizeRowContent>
    </SizeRowContainer>
  )
}

export default SizeRowExpiredDrop
