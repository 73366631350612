import queryString from 'qs'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createApi } from '@reduxjs/toolkit/query/react'

import app_config from '../config/app/config'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { AfaCart, FilterKey, UpdateAfaCartProductPayload, UpdateAfaCartResult } from '../model/afa'
import {
  AfaCartExcel,
  AfaCartSplit,
  CardDeltaOperation,
  SplitPayload,
  UploadCartPayload,
} from '../model/cart'
import { Base64File, TDispatch } from '../model/model'
import { customerIdSelector, eventIdSelector } from '../store/app/selectors'
import { activeDoorsSelector, doorLoadingStatusSelector } from '../store/customer/selectors'
import { useSearchParams } from '../hooks/useSearchParams'
import {
  afaCartAllProductDetailsSelector,
  afaCartDataSelector,
  afaCartIsLoadingSelector,
  getSelectedKeys,
} from '../store/afaCart/selectors'
import { useGetAfaWarehouse } from './afa'
import { loadMissingAfaCartProductDetails } from '../store/afaCart/actions'
import { convertDdMmYyyyToDate } from '../libs/time'
import { useGetAfaCartProducts } from '../hooks/useGetAfaCartProducts'
import { applyAfaFilters, isAfaCartProductMatching } from '../libs/afa'
import { errorNotification } from '../components/Notification/notifications'
import { ResultAndWarningsResponse } from '../model/whiteboard'
import { v4 as uuidv4 } from 'uuid'
import afaActions from '../store/afa/actions'

export const TAG_CART = 'afa-cart'

export const afaCartApi = createApi({
  reducerPath: 'afaCartApi',
  tagTypes: [TAG_CART],
  baseQuery: staggeredBaseQueryWithBailOut(`${app_config.apiUrl}/afa`),
  endpoints: builder => ({
    getAfaCart: builder.query<
      AfaCart,
      {
        customerId: string
        eventId: string
      }
    >({
      query: ({ eventId, customerId }) => ({
        url: `/cart/event/${eventId}/customer/${customerId}`,
      }),
      providesTags: [TAG_CART],
    }),

    updateAfaCartProducts: builder.mutation<
      UpdateAfaCartResult,
      {
        customerId: string
        eventId: string
        products: UpdateAfaCartProductPayload[]
      }
    >({
      query: ({ eventId, customerId, products }) => ({
        url: `/cart/event/${eventId}/customer/${customerId}`,
        body: products,
        method: 'PUT',
      }),
    }),

    duplicateCartOnDoors: builder.mutation<
      ResultAndWarningsResponse,
      {
        eventId: string
        customerId: string
        sourceDoorId: string
        destinationDoorIds: string[]
        upcs?: string[]
      }
    >({
      query: ({ eventId, customerId, sourceDoorId, destinationDoorIds, upcs }) => ({
        url: `/cart/event/${eventId}/customer/${customerId}/duplicate`,
        body: {
          sourceDoorId,
          destinationDoorIds,
          upcs,
        },
        method: 'PUT',
      }),
      invalidatesTags: [TAG_CART],
    }),

    downloadAfaCartFile: builder.query<
      { files: { filename: string; file: Base64File }[] },
      {
        eventId: string
        customerId: string
        showPriceRT: boolean
        showPriceWS: boolean
        showImage: boolean
        fileType: ('excel' | 'pdf')[]
      }
    >({
      query: ({ eventId, customerId, showPriceWS, showPriceRT, showImage, fileType }) => ({
        url: `/cart/event/${eventId}/customer/${customerId}/download?${queryString.stringify({
          showPriceWS,
          showPriceRT,
          showImage,
          fileType,
        })}`,
      }),
    }),

    sendEmailCart: builder.mutation<
      {
        result: boolean
        message: string
      },
      {
        eventId: string
        customerId: string
        receiversAddresses: string[]
        showPriceWS: boolean
        showPriceRT: boolean
        showImage: boolean
        fileType: ('excel' | 'pdf')[]
      }
    >({
      query: ({
        eventId,
        customerId,
        receiversAddresses,
        showPriceWS,
        showPriceRT,
        showImage,
        fileType,
      }) => ({
        url: `/cart/event/${eventId}/customer/${customerId}/mail?${queryString.stringify({
          showPriceRT,
          showPriceWS,
          showImage,
          fileType,
        })}`,
        method: 'POST',
        body: {
          receivers: receiversAddresses.map(mailaddress => ({ mailaddress })),
        },
      }),
    }),

    uploadCart: builder.mutation<
      {
        fault?: boolean
        message?: string
        items: CardDeltaOperation[]
        content: AfaCartExcel[]
      },
      {
        eventId: string
        customerId: string
        onlyCheck: boolean
        uploadCartPayload: UploadCartPayload
      }
    >({
      query: ({ eventId, customerId, onlyCheck, uploadCartPayload }) => ({
        url: `/cart/event/${eventId}/customer/${customerId}/upload?onlyCheck=${onlyCheck}`,
        method: 'POST',
        body: uploadCartPayload,
      }),
      invalidatesTags: (_, __, arg) => (arg.onlyCheck ? [] : [TAG_CART]),
    }),

    confirmCart: builder.mutation<
      {
        result: boolean
        warnings: {
          skuCode: string
          doorId: string
          deliveryDate: string
          dropId: string
          message: string
        }[]
      },
      {
        eventId: string
        customerId: string
        cartItemsKeys: string[]
      }
    >({
      query: ({ eventId, customerId, cartItemsKeys }) => ({
        url: `/cart/event/${eventId}/customer/${customerId}/confirm`,
        method: 'POST',
        body: {
          cartItemsKeys,
        },
      }),
      invalidatesTags: [TAG_CART],
    }),

    setCancellationDates: builder.mutation<
      ResultAndWarningsResponse,
      {
        eventId: string
        customerId: string
        cancellationDates: { deliveryDate: string; cancellationDate: string }[]
      }
    >({
      query: ({ eventId, customerId, cancellationDates }) => ({
        url: `/cart/event/${eventId}/customer/${customerId}/cancellation-dates`,
        method: 'POST',
        body: {
          cancellationDates,
        },
      }),
    }),

    setDeliveryDate: builder.mutation<
      ResultAndWarningsResponse,
      {
        eventId: string
        customerId: string
        payload: { deliveryDateFrom: string; deliveryDateTo: string; check: boolean; key: string[] }
      }
    >({
      query: ({ eventId, customerId, payload }) => ({
        url: `/cart/event/${eventId}/customer/${customerId}/delivery-date`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (_, __, request) => (!request.payload.check ? [TAG_CART] : []),
    }),

    splitDeliveryDate: builder.mutation<ResultAndWarningsResponse, AfaCartSplit>({
      query: ({ eventId, customerId, payload }) => ({
        url: `/cart/event/${eventId}/customer/${customerId}/split-delivery-date`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (_, __, request) => (!request.payload.check ? [TAG_CART] : []),
    }),

    adjustQuantities: builder.mutation<
      {
        result: boolean
        warnings: {
          modelCode: string
          colorCode: string
          oldQty: number
          oldDate: string
          availableQty: number
          availableDate: string
          message: string
        }[]
      },
      {
        eventId: string
        customerId: string
        simulate: boolean
        keys?: string[]
      }
    >({
      query: ({ eventId, customerId, simulate, keys }) => ({
        url: `/cart/event/${eventId}/customer/${customerId}/adjust-quantities${
          simulate ? '?simulate=true' : ''
        }`,
        method: 'POST',
        body: keys ? { keys } : undefined,
      }),
      invalidatesTags: (_, __, queryParams) => (queryParams.simulate ? [] : [TAG_CART]),
    }),
  }),
})

export const useGetAfaCartQuery = (skip?: boolean) => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)
  const activeDoors = useSelector(activeDoorsSelector)
  const doorsLoadingStatus = useSelector(doorLoadingStatusSelector)

  const query = afaCartApi.useGetAfaCartQuery(
    {
      eventId,
      customerId,
    },
    { skip },
  )

  const data = useMemo(
    () =>
      query.data && doorsLoadingStatus === 'ok'
        ? {
            ...query.data,
            items: query.data?.items.filter(cartProduct =>
              activeDoors.find(activeDoor => activeDoor.id === cartProduct.doorId),
            ),
          }
        : query.data,
    [query.data, activeDoors, doorsLoadingStatus],
  )

  const cartProductsDetails = useSelector(afaCartDataSelector)
  const missingModelCodes = useMemo(() => {
    return [
      ...new Set(
        query.data?.items
          .filter(
            ({ modelCode, outOfAssortment }) =>
              !outOfAssortment &&
              (!cartProductsDetails || cartProductsDetails[modelCode] === undefined),
          )
          .map(({ modelCode }) => modelCode),
      ),
    ]
  }, [query.data, cartProductsDetails])

  const dispatch = useDispatch()

  const afawarehouse = useGetAfaWarehouse()

  const cartProductDetailsLoading = useSelector(afaCartIsLoadingSelector)

  useEffect(() => {
    if (missingModelCodes?.length && afawarehouse && !cartProductDetailsLoading) {
      dispatch(loadMissingAfaCartProductDetails(missingModelCodes, afawarehouse))
    }
  }, [missingModelCodes, afawarehouse, cartProductDetailsLoading, dispatch])

  return {
    ...query,
    data,
    error: doorsLoadingStatus === 'ko' ? 'error loading customer doors' : undefined,
    isError: query.isError || doorsLoadingStatus === 'ko',
    isSuccess: query.isSuccess && doorsLoadingStatus !== 'ko',
  }
}

export const useGetFilteredAfaCartQuery = (categoryToIgnore?: FilterKey) => {
  const cartQuery = useGetAfaCartQuery()
  const cartProductsDetails = useSelector(afaCartAllProductDetailsSelector)

  const [searchParams] = useSearchParams()

  const getFilterValue = (filterKey: FilterKey) =>
    (categoryToIgnore !== filterKey && searchParams.get(filterKey)?.split(',')) || null

  const sizes = getFilterValue('size')
  const sports = getFilterValue('sport')
  const colors = getFilterValue('colorFacet')
  const technologies = getFilterValue('technology')
  const genders = getFilterValue('gender')
  const season = getFilterValue('release')
  const families = getFilterValue('family')
  const productTypes = getFilterValue('productType')
  const collections = getFilterValue('collection')
  const limitedReleases = getFilterValue('limitedRelease')
  const fabrics = getFilterValue('fabric')
  const fabricGroups = getFilterValue('fabricGroup')
  const fits = getFilterValue('fit')
  const afaStyles = getFilterValue('afaStyle')
  const adv = getFilterValue('adv')
  const hasKeylooks = getFilterValue('hasKeylooks')
  const afacatalogid = getFilterValue('afacatalogid')

  const areFiltersApplied = useMemo(() => {
    return (
      sizes ||
      sports ||
      colors ||
      technologies ||
      genders ||
      season ||
      families ||
      productTypes ||
      collections ||
      limitedReleases ||
      fabrics ||
      fabricGroups ||
      fits ||
      afaStyles ||
      adv ||
      hasKeylooks ||
      afacatalogid
    )
  }, [
    afaStyles,
    collections,
    colors,
    fabricGroups,
    fabrics,
    families,
    fits,
    genders,
    limitedReleases,
    productTypes,
    season,
    sizes,
    sports,
    technologies,
    adv,
    hasKeylooks,
    afacatalogid,
  ])

  const isCartPage = window.location.pathname.includes('cart')
  const isCheckoutPage = window.location.pathname.includes('checkout')

  const filteredItems = useMemo(() => {
    if (!cartQuery.data?.items) {
      return []
    }

    return cartQuery.data.items.filter(cartProduct => {
      const cartProductDetails = (cartProductsDetails || {})[cartProduct.modelCode]

      if (!cartProductDetails) {
        // filter out if any filter is applied
        return !areFiltersApplied
      }

      if (!isCartPage && !isCheckoutPage) {
        return true
      }

      return applyAfaFilters(
        {
          sizes,
          sports,
          colors,
          technologies,
          genders,
          season,
          families,
          productTypes,
          collections,
          limitedReleases,
          fabrics,
          fabricGroups,
          fits,
          afaStyles,
          adv,
          hasKeylooks,
          afacatalogid,
        },
        cartProductDetails,
        cartProduct,
      )
    })
  }, [
    cartProductsDetails,
    cartQuery.data?.items,
    isCartPage,
    isCheckoutPage,
    sizes,
    sports,
    colors,
    technologies,
    genders,
    season,
    families,
    productTypes,
    collections,
    limitedReleases,
    fabrics,
    fabricGroups,
    fits,
    afaStyles,
    areFiltersApplied,
    adv,
    hasKeylooks,
    afacatalogid,
  ])

  return {
    ...cartQuery,
    data:
      cartQuery.data === undefined
        ? undefined
        : {
            ...cartQuery.data,
            items: filteredItems,
          },
  }
}

export const useGetDoorsWithProducts = (skip?: boolean) => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)
  const doorsLoadingStatus = useSelector(doorLoadingStatusSelector)

  const query = afaCartApi.useGetAfaCartQuery(
    {
      eventId,
      customerId,
    },
    { skip },
  )

  const data = useMemo(
    () =>
      query.data && doorsLoadingStatus === 'ok'
        ? {
            ...query.data,
            items: query.data?.items,
          }
        : query.data,
    [query.data, doorsLoadingStatus],
  )

  const doorIdsWithProducts = useMemo(() => {
    return (
      data?.items.reduce((result, cartProduct) => {
        if (!result.includes(cartProduct.doorId)) {
          result.push(cartProduct.doorId)
        }
        return result
      }, [] as string[]) || []
    )
  }, [data?.items])

  const cartProductsCountByDoor = useMemo(() => {
    const result = data?.items.reduce((result, { doorId, unconfirmedQuantity }) => {
      if (!(doorId in result)) {
        result[doorId] = 0
      }
      result[doorId] += unconfirmedQuantity
      return result
    }, {} as Record<string, number>)
    return result || {}
  }, [data?.items])

  return {
    doorIdsWithProducts: doorIdsWithProducts,
    cartProductsCountByDoor,
    isError: query.isError,
    isFetching: query.isFetching,
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
    isUninitialized: query.isUninitialized,
  }
}

export const useGetAfaFilteredCheckoutProducts = () => {
  const cartProductsDetails = useSelector(afaCartAllProductDetailsSelector)

  const brandsFiltered = useMemo(() => {
    return [
      ...new Set(
        Object.values(cartProductsDetails || {})
          .map(product => product?.brandCode || '')
          .filter(brandCode => brandCode.length),
      ),
    ]
  }, [cartProductsDetails])

  const cartQuery = useGetAfaCartQuery()

  const filteredCartQueryItems = cartQuery.data?.items.filter(
    ({ status, brandCode }) => status === '1' && brandsFiltered?.includes(brandCode),
  )

  return [filteredCartQueryItems] as const
}

export const useUpdateAfaCartProductsMutation = (cacheKey = 'update-afa-cart') => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  const dispatch: TDispatch = useDispatch()

  const [
    _updateAfaCartProduct,
    updateAfaCartProductResult,
  ] = afaCartApi.useUpdateAfaCartProductsMutation({
    fixedCacheKey: cacheKey,
  })

  const { isLoading } = updateAfaCartProductResult
  const debounceTimeoutRef = useRef<number | null>(null)
  const [triggerUpdateCart, setTriggerUpdateCart] = useState(false)

  const updateRequestsRef = useRef<Record<string, UpdateAfaCartProductPayload>>({})

  const triggerDebounce = useCallback(() => {
    if (debounceTimeoutRef.current) {
      window.clearTimeout(debounceTimeoutRef.current)
    }

    debounceTimeoutRef.current = window.setTimeout(() => {
      setTriggerUpdateCart(true)
    }, 1500)
  }, [])

  const placeCalls = useCallback(() => {
    if (isLoading) {
      triggerDebounce()
      return
    }

    const products = Object.values(updateRequestsRef.current)
    const productKeys = Object.keys(updateRequestsRef.current)

    productKeys.forEach(key => {
      delete updateRequestsRef.current[key]
    })

    _updateAfaCartProduct({ eventId, customerId, products })
      .then(response => {
        if (!('data' in response && response.data.result)) {
          dispatch(afaCartApi.util.invalidateTags([TAG_CART]))
          'data' in response &&
            response.data.warnings.forEach(({ faultmessage }) =>
              errorNotification({ message: faultmessage }),
            )
        } else {
          if ('data' in response) {
            dispatch(
              afaCartApi.util.updateQueryData('getAfaCart', { customerId, eventId }, draft => {
                draft.items = draft.items.map(product => {
                  const updatedKey = response.data.items.find(({ keyFE }) => keyFE === product.key)
                    ?.key
                  return updatedKey ? { ...product, key: updatedKey } : product
                })
              }),
            )
          }
        }
      })
      .catch(() => {
        dispatch(afaCartApi.util.invalidateTags([TAG_CART]))
      })

    dispatch(afaActions.setAfaCartIsUpdating(false))
  }, [dispatch, _updateAfaCartProduct, eventId, customerId, isLoading, triggerDebounce])

  useEffect(() => {
    if (triggerUpdateCart) {
      setTriggerUpdateCart(false)
      placeCalls()
    }
  }, [triggerUpdateCart, placeCalls])

  const updateAfaCartProduct = useCallback(
    (products: UpdateAfaCartProductPayload[]) => {
      dispatch(afaActions.setAfaCartIsUpdating(true))
      triggerDebounce()

      dispatch(
        afaCartApi.util.updateQueryData('getAfaCart', { customerId, eventId }, draft => {
          if (!draft.items) return
          let updatedItems = draft.items?.slice() || []
          products.forEach(product => {
            const {
              upc,
              quantity,
              doorId,
              doorAddressId,
              reference,
              status,
              modelCode,
              colorCode,
              brandCode,
              brandCodeParent,
              dropType,
              availabilityDate,
              minDeliveryDate,
              deliveryDate,
              key,
              afaCatalogId,
            } = product

            if (quantity === 0 && !key) {
              return
            }

            const productInRef = Object.values(updateRequestsRef.current).find(cartProduct =>
              isAfaCartProductMatching(cartProduct, product),
            )
            const productKey = productInRef?.key || key || uuidv4()
            updateRequestsRef.current[productKey] = { ...product, key: productKey }

            if (quantity === 0) {
              updatedItems = updatedItems.filter(cartProduct => cartProduct.key !== key)
            } else {
              const productInCart = draft.items.find(cartProduct =>
                isAfaCartProductMatching(cartProduct, product),
              )
              if (productInCart) {
                productInCart.unconfirmedQuantity = quantity
                productInCart.reference = reference || productInCart.reference
              } else {
                updatedItems.push({
                  key: productKey,
                  doorId,
                  doorAddressId: doorAddressId || '',
                  reference: reference || '',
                  unconfirmedQuantity: quantity,
                  editable: true,
                  insertedAt: new Date().toISOString(),
                  masterPrice: { unitPriceRt: '', unitPriceWs: '' },
                  modelCode,
                  status,
                  brandCode,
                  brandCodeParent,
                  colorCode,
                  dropType,
                  availabilityDate,
                  minDeliveryDate,
                  deliveryDate,
                  upc,
                  skuCode: '',
                  afaCatalogId,
                })
              }
            }
          })
          draft.items = updatedItems
        }),
      )
    },
    [dispatch, eventId, customerId, triggerDebounce],
  )
  return [updateAfaCartProduct, updateAfaCartProductResult] as const
}

export const useUpdateAfaCartProductsMutationDebounced = (cacheKey = 'update-afa-cart') => {
  return useUpdateAfaCartProductsMutation(cacheKey)
}

export const useDuplicateCartOnDoorsMutation = () => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  const [
    _duplicateCartOnDoors,
    duplicateCartOnDoorsResult,
  ] = afaCartApi.useDuplicateCartOnDoorsMutation()

  const duplicateCartOnDoors = useCallback(
    (payload: { sourceDoorId: string; destinationDoorIds: string[]; upcs?: string[] }) =>
      _duplicateCartOnDoors({ ...payload, eventId, customerId }),
    [_duplicateCartOnDoors, eventId, customerId],
  )
  return [duplicateCartOnDoors, duplicateCartOnDoorsResult] as const
}

export const useSendEmailAfaCartMutation = () => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  const [_sendEmailCart, sendEmailCartResult] = afaCartApi.useSendEmailCartMutation()
  const sendEmailCart = ({
    addresses,
    showPriceWS,
    showPriceRT,
    showImage,
    fileType,
  }: {
    addresses: string[]
    showPriceWS: boolean
    showPriceRT: boolean
    showImage: boolean
    fileType: ('excel' | 'pdf')[] | undefined
  }) =>
    eventId &&
    customerId &&
    _sendEmailCart({
      eventId,
      customerId,
      receiversAddresses: addresses,
      showPriceWS,
      showPriceRT,
      showImage,
      fileType: fileType || ['excel'],
    })
  return [sendEmailCart, sendEmailCartResult] as [typeof sendEmailCart, typeof sendEmailCartResult]
}

export const useUploadAfaCartMutation = () => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  const [_uploadCart, uploadCartResult] = afaCartApi.useUploadCartMutation({
    fixedCacheKey: 'cartUploadResult',
  })
  const uploadCart = useCallback(
    (onlyCheck: boolean, uploadCartPayload: UploadCartPayload) =>
      eventId && customerId && _uploadCart({ eventId, customerId, onlyCheck, uploadCartPayload }),
    [_uploadCart, customerId, eventId],
  )

  return [uploadCart, uploadCartResult] as [typeof uploadCart, typeof uploadCartResult]
}

export const useConfirmAfaCartMutation = () => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  const afaCartQuery = useGetAfaCartQuery()
  const cartItemsKeys = afaCartQuery.data?.items.map(({ key }) => key) || []

  const [_confirmCart, confirmCartResult] = afaCartApi.useConfirmCartMutation({
    fixedCacheKey: 'confirmCartResult',
  })
  const confirmCart = () =>
    eventId &&
    customerId &&
    _confirmCart({
      eventId,
      customerId,
      cartItemsKeys,
    })
  return [confirmCart, confirmCartResult] as [typeof confirmCart, typeof confirmCartResult]
}

export const useSetCancellationDatesMutation = () => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  const [
    _setCancellationDates,
    setCancellationDatesResult,
  ] = afaCartApi.useSetCancellationDatesMutation()
  const setCancellationDates = (
    cancellationDates: { deliveryDate: string; cancellationDate: string }[],
  ) =>
    eventId &&
    customerId &&
    _setCancellationDates({
      eventId,
      customerId,
      cancellationDates,
    })
  return [setCancellationDates, setCancellationDatesResult] as [
    typeof setCancellationDates,
    typeof setCancellationDatesResult,
  ]
}

export const useSetDeliveryDateMutation = () => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)
  const selectedKeys = useSelector(getSelectedKeys)

  const [_setDeliveryDate, setDeliveryDateResult] = afaCartApi.useSetDeliveryDateMutation()
  const setDeliveryDate = (payload: {
    deliveryDateFrom: string
    deliveryDateTo: string
    check: boolean
  }) =>
    _setDeliveryDate({
      eventId,
      customerId,
      payload: {
        ...payload,
        key: [...new Set(selectedKeys[payload.deliveryDateFrom])],
      },
    })

  return [setDeliveryDate, setDeliveryDateResult] as [
    typeof setDeliveryDate,
    typeof setDeliveryDateResult,
  ]
}

export const useSplitDeliveryDateMutation = () => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  const [_splitDeliveryDate, splitDeliveryDateResult] = afaCartApi.useSplitDeliveryDateMutation()
  const splitDeliveryDate = (payload: SplitPayload) =>
    _splitDeliveryDate({
      eventId,
      customerId,
      payload,
    })

  return [splitDeliveryDate, splitDeliveryDateResult] as [
    typeof splitDeliveryDate,
    typeof splitDeliveryDateResult,
  ]
}

export const useAdjustQuantitiesMutation = ({ cacheKey = '' }: { cacheKey?: string }) => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  const [_adjustQuantities, adjustQuantitiesResult] = afaCartApi.useAdjustQuantitiesMutation({
    fixedCacheKey: cacheKey,
  })
  const adjustQuantities = ({ simulate = false, keys }: { simulate?: boolean; keys?: string[] }) =>
    eventId &&
    customerId &&
    _adjustQuantities({
      eventId,
      customerId,
      simulate,
      keys,
    })
  return [adjustQuantities, adjustQuantitiesResult] as [
    typeof adjustQuantities,
    typeof adjustQuantitiesResult,
  ]
}

export const useGetCartDeliveryDatesQuery = () => {
  const { cartProductsByBrandCode, brandsInCart, isFetching } = useGetAfaCartProducts()

  const [searchParams] = useSearchParams()

  const selectedBrand = useMemo(() => {
    return searchParams.get('brand') || brandsInCart[0]?.code
  }, [searchParams, brandsInCart])

  const cartProducts = cartProductsByBrandCode[selectedBrand]

  const deliveryDates = useMemo(
    () =>
      cartProducts
        ? [
            ...new Set(
              cartProducts.map(
                ({ deliveryDate, cancellationDate }) =>
                  `${deliveryDate}__${cancellationDate || deliveryDate}`,
              ),
            ),
          ]
            .map(str => {
              const [deliveryDate, cancellationDate] = str.split('__')
              return {
                deliveryDate,
                cancellationDate,
              }
            })
            .sort(
              (a, b) =>
                convertDdMmYyyyToDate(a.deliveryDate).getTime() -
                convertDdMmYyyyToDate(b.deliveryDate).getTime(),
            )
        : undefined,
    [cartProducts],
  )

  return {
    isFetching: isFetching,
    data: deliveryDates,
  }
}

export const useGetSelectedDelivery = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const cartDeliveriesQuery = useGetCartDeliveryDatesQuery()

  const selectedDateFromQs = searchParams.get('delivery')

  const selectedDelivery = useMemo(() => {
    if (!cartDeliveriesQuery.data) {
      return
    }

    const selectedDate = cartDeliveriesQuery.data.find(
      ({ deliveryDate }) => deliveryDate === selectedDateFromQs,
    )

    const selectedDateWithfallback =
      selectedDateFromQs && selectedDate ? selectedDate : cartDeliveriesQuery.data[0]

    return selectedDateWithfallback
  }, [cartDeliveriesQuery.data, selectedDateFromQs])

  useEffect(() => {
    if (selectedDelivery && selectedDelivery.deliveryDate !== selectedDateFromQs) {
      searchParams.set('delivery', selectedDelivery.deliveryDate)
      setSearchParams(searchParams, { replace: true })
    }
  }, [searchParams, selectedDateFromQs, selectedDelivery, setSearchParams])

  return selectedDelivery
}

export const useDownloadAfaCartFileQuery = ({
  showPriceWS = false,
  showPriceRT = false,
  showImage,
  startDownload,
  fileType,
}: {
  startDownload: boolean
  showPriceWS?: boolean
  showPriceRT?: boolean
  showImage: boolean
  fileType: ('excel' | 'pdf')[] | undefined
}) => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  return afaCartApi.useDownloadAfaCartFileQuery(
    { eventId, customerId, showPriceWS, showPriceRT, showImage, fileType: fileType || [] },
    { skip: !eventId || !customerId || !startDownload || !fileType || !fileType?.length },
  )
}
