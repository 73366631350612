import classnames from 'classnames'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { cartModeSelector } from '../../../store/stars/selectors'
import { breakpointsCross, footerHeight } from '../../../style/theme'
import { CartHeader, HeaderTitle } from '../../CommonComponents'
import { StarsCartAnalytics } from '../../StarsAnalytics/StarsAnalytics'
import TableCartAnalytics from '../../TableCart/TableCartAnalytics'
import RCIcon from '../../UI/RCIcon'

const ForIPad = styled.div`
  @media (min-width: ${breakpointsCross.XL.min}) {
    display: none;
  }
`

const CartSummaryDimmer = styled.div`
  display: block;
  width: 0;
  height: 0;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: opacity 0.2s;
  opacity: 0;
  &.visible {
    opacity: 1;
    width: 100vw;
    height: 100vh;
  }
`

const CartSummaryClose = styled.div`
  display: flex;
  position: fixed;
  z-index: 2;
  background: white;
  border-radius: 10px;
  height: 3.5vw;
  width: 3.5vw;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s;
  opacity: 0;
  left: -10vw;
  &.visible {
    top: 22vh;
    left: 6.1vw;
    opacity: 1;
  }
`

const CartSummary = styled.div`
  width: 42.3vw;
  margin-left: clamp(10px, 1vw, 30px);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${breakpointsCross.XL.max}) {
    z-index: 2;
    width: 90vw;
    height: 80vh;
    position: fixed;
    right: 0;
    bottom: ${footerHeight};
    transform: translateX(100%);
    transition: transform 0.2s;
    &.visible {
      transform: translateX(0%);
    }
  }
`

const CartAnalyticsContainer: React.FC<{ toggleAnalytics: () => void; visible: boolean }> = ({
  toggleAnalytics,
  visible,
}) => {
  const { t } = useTranslation()

  const mode = useSelector(cartModeSelector)

  return (
    <Fragment>
      <ForIPad>
        <CartSummaryDimmer
          onClick={toggleAnalytics}
          className={classnames({
            'cart-summary-dimmer': true,
            visible,
          })}
        />
        <CartSummaryClose
          data-testid="cart-summary-close"
          className={classnames({
            visible,
          })}
          onClick={toggleAnalytics}
        >
          <RCIcon type="close" />
        </CartSummaryClose>
      </ForIPad>
      <CartSummary
        className={classnames({
          'cart-summary': true,
          visible,
        })}
      >
        <CartHeader className={mode === 'stars' ? 'stars' : ''}>
          <HeaderTitle className="header-title">{t('GenericWords.summary')}</HeaderTitle>
        </CartHeader>

        {mode === 'stars' ? <StarsCartAnalytics /> : <TableCartAnalytics />}
      </CartSummary>
    </Fragment>
  )
}

export default CartAnalyticsContainer
