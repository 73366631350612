import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useUpdateAfaCartProductsMutation } from '../services/afaCart'
import { errorNotification, successNotification } from '../components/Notification/notifications'
import { afaCartAdjustSelectors } from '../store/afaCartAdjust/selectors'
import afaCartAdjustActions from '../store/afaCartAdjust/actions'
import RemoveSelectedFromCart from '../components/RemoveSelectedFromCart/RemoveSelectedFromCart'

const AfaRemoveExpiredItemsModal: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const adjustRemovedKeys = useSelector(afaCartAdjustSelectors.removedKeys)
  const isCartAdjustOpen = useSelector(afaCartAdjustSelectors.isOpen)
  const expiredCartProductsToRemove = useSelector(
    afaCartAdjustSelectors.expiredCartProductsToRemove,
  )
  const [updatedCart, setUpdatedCart] = useState(false)

  const [updateCartProducts, updateAfaCartProductResult] = useUpdateAfaCartProductsMutation(
    'remove-expired-drops-products',
  )

  useEffect(() => {
    if (updatedCart) {
      if (updateAfaCartProductResult.isSuccess || updateAfaCartProductResult.isError) {
        if (updateAfaCartProductResult.isSuccess) {
          successNotification({ message: t('Afa.Cart.Adjust.Success') })
        } else {
          errorNotification({
            message: t('Afa.errorTryingUpdateCart'),
          })
        }
        setUpdatedCart(false)
        dispatch(afaCartAdjustActions.setCartExpiredProductsToRemove([]))
      }
    }
  }, [
    t,
    updateAfaCartProductResult.isError,
    updateAfaCartProductResult.isSuccess,
    dispatch,
    updatedCart,
  ])

  const removeItems = () => {
    updateCartProducts(expiredCartProductsToRemove.map(product => ({ ...product, quantity: 0 })))
    if (isCartAdjustOpen) {
      const removedKeys = [
        ...new Set(expiredCartProductsToRemove.map(({ key }) => key).concat(adjustRemovedKeys)),
      ]
      dispatch(afaCartAdjustActions.setRemovedKeys(removedKeys))
    }
    setUpdatedCart(true)
  }

  return (
    <RemoveSelectedFromCart
      onCancel={() => dispatch(afaCartAdjustActions.setCartExpiredProductsToRemove([]))}
      onRemoveItems={removeItems}
      title={t('Afa.Cart.Remove')}
      confirmText={t('Afa.Cart.Expired.SubtitleModal')}
      cartProducts={expiredCartProductsToRemove}
      isExpiredDrop={true}
    />
  )
}

export default AfaRemoveExpiredItemsModal
