import { RootState } from '../../model/model'
import { createSelector } from '@reduxjs/toolkit'

const showNotifications = createSelector(
  (state: RootState) => state.afaCartNotifications.showNotifications,
  showNotifications => showNotifications,
)

export const initialNotificationCountSelector = createSelector(
  (state: RootState) => state.afaCartNotifications.initialNotificationCount,
  initialNotificationCount => initialNotificationCount,
)

export const initialNotificationsListSelector = createSelector(
  (state: RootState) => state.afaCartNotifications.initialNotificationsList,
  initialNotificationsList => initialNotificationsList,
)

export const currentNotificationsListSelector = createSelector(
  (state: RootState) => state.afaCartNotifications.currentNotificationsList,
  currentNotificationsList => currentNotificationsList,
)

export const afaCartNotificationsSelectors = { showNotifications }
