import { CatalogImages } from '../../../model/product'

export type AAProduct = {
  modelCode: string
  name?: string
  category: string
  brand: string
  mocos: Record<string, AAMoco>
}

export const PositioningValues = [
  'young',
  'innovative',
  'sophisticated',
  'sport',
  'everyday',
] as const

export type Positioning = typeof PositioningValues[number]

export type MapView = 'trends' | 'brands' | 'demographics'

export type AAMoco = {
  modelCode: string
  colorCode: string
  modelName?: string
  category: string
  brand: string

  rank: number
  match: number
  positioning: Positioning
  positioningMatch: Record<Positioning, number>
  isAdv: boolean
  isNew: boolean
  isColorRefresh: boolean
  catalogImages: CatalogImages
  whsPrice: number
  srPrice: number
  sizes: Record<string, AASize>
}

export type AASize = {
  upc: string
  size: string
  colorCode: string
  skuCode: string
  enabledDoors: string[]
  starsDoors: string[]
  modelCode: string
  sizeLabel: string
  brandCode: string
}

export type MapData = {
  Longitude: number
  Latitude: number
  Data: number
}

export type MapTrends = Record<Positioning, number>[]

export type MapDemographics = {
  country: string
  zipCode: number
  type: string
  description: string
  quantity: number
  percentage: number
}

export type MapDemographicsByDescription = Record<string, MapDemographics>

export type MapDemographicsByTypeAndDescription = Record<string, MapDemographicsByDescription>

export type AddressDetailsOpenStreetMapApi = {
  'ISO3166-2-lvl4'?: string
  'ISO3166-2-lvl6'?: string
  neighbourhood?: string
  house_number?: string
  road?: string
  building?: string
  unit?: string
  floor?: string
  entrance?: string
  postcode?: string
  postcode_district?: string
  city_district?: string
  suburb?: string
  hamlet?: string
  village?: string
  town?: string
  township?: string
  city?: string
  municipality?: string
  district?: string
  region?: string
  state_district?: string
  county?: string
  state?: string
  province?: string
  island?: string
  country?: string
  country_code?: string
  public_transport?: string
  railway?: string
  bus_stop?: string
  airport?: string
  amenity?: string
  shop?: string
  office?: string
  historic?: string
  place?: string
  tourism?: string
  subdistrict?: string
}
export type OpenStreetMapApi = {
  address: AddressDetailsOpenStreetMapApi
  place_id: number
  licence: string
  osm_type: string
  osm_id: number
  boundingbox: [string, string, string, string]
  lat: string
  lon: string
  display_name: string
  class: string
  type: string
  importance: number
}

export type OpenStreetMapApiResponse = OpenStreetMapApi[]

export type Assortment = {
  doorId: string
  modelCode: string
  colorCode: string
  rank: number
  brandCode: string
  categoryMix: string
  positioning: Record<Positioning, number>
}

export const CHOICE_VALUE = ['match-desc', 'brand-asc', 'whsPrice-asc', 'whsPrice-desc'] as const
export type Choice = typeof CHOICE_VALUE[number]

export type GeocodingResult = {
  latlng: google.maps.LatLngLiteral
  name: {
    short: string
    long: string
  }
  country: {
    short: string
    long: string
  }
  bounds?: {
    northeast: google.maps.LatLngLiteral
    southwest: google.maps.LatLngLiteral
  }
  zipCode?: string
}
