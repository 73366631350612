import styled from 'styled-components/macro'
import {
  breakpointsCross,
  getFluidSizeWithFullFormula as gF,
  palette,
  pxToRem,
} from '../../../../../style/theme'

export const Wrapper = styled.aside`
  grid-area: aside;
  position: relative;
  z-index: 20000;
  overflow-y: auto;
  background-color: ${palette.extraLight};
`

export const Selection = styled.div`
  display: flex;
  height: ${gF('px', 82, 151, 1080, 3840)};
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: ${gF('px', 16, 26, 1366, 3840)};
  row-gap: ${gF('px', 8, 16, 1366, 3840)};

  img {
    width: ${gF('px', 24, 48, 1366, 3840)};
    height: ${gF('px', 24, 48, 1366, 3840)};
    filter: grayscale(100%) brightness(20%);
  }

  span {
    font-family: GilmerBold, sans-serif;
    text-transform: capitalize;
    font-size: ${gF('px', 14, 26, 1366, 3840)};
    letter-spacing: ${gF('px', -0.55, -1.14, 1366, 3840)};
    color: ${palette.tangaroa};
  }

  &.selected {
    background-color: ${palette.white};
  }
  &.filter {
    padding: ${gF('px', 55, 110, 1366, 3840)} 0;
  }
`

export const SelectionsContainer = styled.div`
  width: ${gF('px', 139, 266, 1366, 3840)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: ${palette.extraLight};
  overflow-y: auto;
`

export const Categories = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: ${breakpointsCross.L.max}) {
    justify-content: flex-start;
  }
`

export const IconWrapper = styled.div`
  position: fixed;
  width: ${gF('px', 32, 48, 1366, 3840)};
  height: ${gF('px', 32, 48, 1366, 3840)};
  border-radius: 50%;
  background-color: ${palette.tangaroa};
//  right: -${pxToRem(16)}rem;
  bottom: ${pxToRem(49 + 16)}rem;

  @media screen and (min-width: ${breakpointsCross.XL.max}) {
    bottom: ${pxToRem(120 + 16)}rem;
    display: none;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transform: rotate(0deg);
  transition: 0.3s left linear;
  cursor: pointer;

  &.open {
    transform: rotate(180deg);
  }

  &.moveY {
    transform: translateY(-32px);
  }

  svg {
    path {
      fill: white;
    }
  }
`
