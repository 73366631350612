import { format } from 'date-fns'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useSearchParams } from '../../hooks/useSearchParams'
import { convertDdMmYyyyToDate } from '../../libs/time'
import { useGetCartDeliveryDatesQuery, useGetSelectedDelivery } from '../../services/afaCart'
import AfaTabSwitcher from '../../styleguide/AfaTabSwitcher'
import { pxToRem, zIndex } from '../../style/theme'

const Switcher = styled(AfaTabSwitcher)`
  padding-left: 1.25rem;
  position: sticky;
  top: 0;
  z-index: ${zIndex.afaCartDropSwitcher};
  background-color: white;
  padding-top: ${pxToRem(32)}rem;
`

const AfaCartDeliveriesSwitcher: React.FC = () => {
  const { t } = useTranslation()

  const [searchParams, setSearchParams] = useSearchParams()

  const selectedDeliveryDetails = useGetSelectedDelivery()

  const setSelectedDelivery = useCallback(
    (delivery: string) => {
      searchParams.set('delivery', delivery)
      setSearchParams(searchParams, { replace: true })
    },
    [setSearchParams, searchParams],
  )

  const deliveriesQuery = useGetCartDeliveryDatesQuery()

  return (
    <Switcher
      selectedTabId={selectedDeliveryDetails?.deliveryDate}
      setSelectedTabId={(delivery: string) => setSelectedDelivery(delivery)}
      tabs={
        [...new Set(deliveriesQuery.data?.map(({ deliveryDate }) => deliveryDate))].map(
          (deliveryDate, index) => ({
            id: deliveryDate,
            label: `${t('Afa.Cart.Delivery')} ${index + 1} - ${format(
              convertDdMmYyyyToDate(deliveryDate),
              'dd/MM/uuuu',
            )}`,
          }),
        ) || []
      }
    />
  )
}

export default AfaCartDeliveriesSwitcher
