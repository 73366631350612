import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedBoard } from '../../../../../store/whiteboard/selectors'
import whiteboardActions from '../../../../../store/whiteboard/actions'
import Modal from '../../../components/Modal'

import { BodyModal } from '../BoardsBar/style'
import ButtonsContainer from '../../../components/ButtonsContainer'

const DeleteBoardModal: React.FC<{ onCancel: () => void }> = ({ onCancel }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const board = useSelector(getSelectedBoard)

  const handleConfirmDelete = () => {
    if (board) {
      dispatch(whiteboardActions.removeBoard(board.key))
      dispatch(whiteboardActions.setIsDeleteBoardModalOpen(false))
    }
  }

  return (
    <Modal fullsize={true} title={t('Whiteboard.deleteBoard.title')} onCancel={onCancel}>
      <BodyModal>{t('Whiteboard.deleteBoard.subtitle')}</BodyModal>
      <ButtonsContainer
        classNameProp="delete-board"
        cancelText={t('Whiteboard.cancel')}
        confirmText={t('Whiteboard.deleteBoard.confirm')}
        onConfirm={handleConfirmDelete}
        onCancel={onCancel}
      />
    </Modal>
  )
}

export default DeleteBoardModal
