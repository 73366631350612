import React, { useState } from 'react'
import { CustomDatePicker } from '../CustomDatePicker'
import CalendarIconAfa from '../icons/CalendarIconAfa'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import {
  Wrapper,
  ModalTitle,
  DeliveryDate,
  DeliveryAvailable,
  NextDelivery,
  TopDiv,
  ButtonsGroup,
  ButtonCancel,
  ButtonConfirm,
  ButtonSave,
  LineBreak,
  SetDeliveryDate,
  MergeDeliveries,
  BodyInfo,
} from '../AfaCommonComponents'
import {
  useGetCartDeliveryDatesQuery,
  useGetSelectedDelivery,
  useSetDeliveryDateMutation,
} from '../../services/afaCart'
import Loading from '../Loading'
import { DdMmYyyyDateString } from '../../model/model'
import { convertDateToDdMmYyyyString, convertDdMmYyyyToDate } from '../../libs/time'
import { useSelector } from 'react-redux'
import { languageSelector } from '../../store/app/selectors'
import { AfaCartProduct } from '../../model/afa'
import { getLaterMinDeliveryDateFromCartProducts } from '../../libs/cart'
import { errorNotification, successNotification } from '../Notification/notifications'

type Props = {
  onCancel: () => void
  selectedProducts: AfaCartProduct[]
}

const AfaChangeDateModal: React.FC<Props> = ({ onCancel, selectedProducts }) => {
  const { t } = useTranslation()

  const [newDeliveryDate, setNewDeliveryDate] = useState<DdMmYyyyDateString | null>(null)

  const [showMergeInfo, setShowMergeInfo] = useState(false)

  const language = useSelector(languageSelector)

  const cartDeliveriesQuery = useGetCartDeliveryDatesQuery()
  const cartDeliveries = cartDeliveriesQuery.data || []

  const selectedDeliveryDetails = useGetSelectedDelivery()

  const [setDeliveryDate, SetDeliveryDateResult] = useSetDeliveryDateMutation()

  if (!selectedProducts.length) {
    return (
      <Wrapper>
        <div>no selected products</div>
      </Wrapper>
    )
  }

  const deliveryDate = selectedProducts[0].deliveryDate

  const deliveryIndex = cartDeliveries.findIndex(
    cartDelivery => cartDelivery.deliveryDate === selectedDeliveryDetails?.deliveryDate,
  )

  const nextDelivery =
    deliveryIndex > -1 && cartDeliveries ? cartDeliveries[deliveryIndex + 1] : undefined

  const nextDeliveryDate = nextDelivery?.deliveryDate

  const checkMergeNeeded = (payload: { deliveryDateFrom: string; deliveryDateTo: string }) =>
    setDeliveryDate({ ...payload, check: true })

  const updateNewDate = (payload: { deliveryDateFrom: string; deliveryDateTo: string }) =>
    setDeliveryDate({ ...payload, check: false })

  const formatDate = 'dd/MM/uuuu'

  const locale = language.split('-')[0]

  if (cartDeliveriesQuery.isFetching || SetDeliveryDateResult.isLoading) {
    return (
      <Wrapper>
        <Loading isFullPage={false} />
      </Wrapper>
    )
  }

  const minDeliveryDate = getLaterMinDeliveryDateFromCartProducts(selectedProducts, deliveryDate)

  const dates = {
    deliveryDateFrom: deliveryDate,
    deliveryDateTo: newDeliveryDate ? newDeliveryDate : minDeliveryDate,
  }

  return showMergeInfo ? (
    <MergeDeliveries>
      <ModalTitle>{t('Afa.mergeOrder')}</ModalTitle>
      <BodyInfo>
        {t('Afa.infoDeliverySet')}{' '}
        {newDeliveryDate
          ? format(convertDdMmYyyyToDate(newDeliveryDate), formatDate)
          : '"Error in data loading"'}
        ,
        <br />
        {t('Afa.infoMergeDeliveries')}.
      </BodyInfo>
      <ButtonsGroup>
        <ButtonCancel onClick={() => setShowMergeInfo(false)}>
          {t('Checkout.ImportExport.ModalCancel')}
        </ButtonCancel>
        <ButtonConfirm
          onClick={async () => {
            try {
              if (await updateNewDate(dates)) {
                successNotification({
                  message: t('Afa.successUpdateDelivery'),
                })
                onCancel()
              } else {
                errorNotification({
                  message: t('Afa.errorUpdateDelivery'),
                })
                setShowMergeInfo(false)
              }
            } catch (err) {
              errorNotification({
                message: t('Afa.errorUpdateDelivery'),
              })
            }
          }}
        >
          {t('Checkout.ImportExport.ModalConfirm')}
        </ButtonConfirm>
      </ButtonsGroup>
    </MergeDeliveries>
  ) : (
    <Wrapper>
      <TopDiv>
        <ModalTitle>{t('Afa.changeDeliveryDate')}</ModalTitle>
        <DeliveryDate>
          {t('Afa.delivery')} {deliveryIndex + 1} -{' '}
          {format(convertDdMmYyyyToDate(deliveryDate), formatDate)}
        </DeliveryDate>
        <DeliveryAvailable>
          {t('Afa.deliveryAvailableFrom')}:{' '}
          {format(convertDdMmYyyyToDate(minDeliveryDate), formatDate)}
        </DeliveryAvailable>
        <LineBreak />
        <SetDeliveryDate>{t('Afa.setDeliveryDate')}</SetDeliveryDate>
        {nextDeliveryDate ? (
          <NextDelivery>
            {t('Afa.yourCurrentNextDeliveryIsSetFor')}:{' '}
            {format(convertDdMmYyyyToDate(nextDeliveryDate), formatDate)}
          </NextDelivery>
        ) : (
          <NextDelivery />
        )}
        <CustomDatePicker
          calendarIcon={<CalendarIconAfa />}
          value={newDeliveryDate ? convertDdMmYyyyToDate(newDeliveryDate) : null}
          minDetail={'decade'}
          onChange={(date: Date) => setNewDeliveryDate(convertDateToDdMmYyyyString(date))}
          monthPlaceholder={t('Afa.monthPlaceholder')}
          dayPlaceholder={t('Afa.dayPlaceholder')}
          yearPlaceholder={t('Afa.yearPlaceholder')}
          defaultActiveStartDate={convertDdMmYyyyToDate(deliveryDate)}
          format="dd/MM/yyyy"
          locale={locale}
          minDate={convertDdMmYyyyToDate(minDeliveryDate)}
        />
      </TopDiv>
      <ButtonsGroup>
        <ButtonCancel onClick={() => onCancel()}>
          {t('Checkout.ImportExport.ModalCancel')}
        </ButtonCancel>
        <ButtonSave
          disabled={!newDeliveryDate}
          onClick={async () => {
            if (newDeliveryDate === null || newDeliveryDate === deliveryDate) {
              onCancel()
            } else {
              try {
                const splitResult = await checkMergeNeeded(dates)
                const mergeIsNeeded =
                  ('data' in splitResult && !splitResult.data.result) ||
                  ('data' in splitResult && splitResult.data.warnings.length) ||
                  'error' in splitResult
                if (mergeIsNeeded) {
                  setShowMergeInfo(true)
                } else {
                  if (await updateNewDate(dates)) {
                    successNotification({
                      message: t('Afa.successUpdateDelivery'),
                    })
                    onCancel()
                  } else {
                    errorNotification({
                      message: t('Afa.errorUpdateDelivery'),
                    })
                    onCancel()
                  }
                }
              } catch (err) {
                errorNotification({
                  message: t('Afa.errorUpdateDelivery'),
                })
              }
            }
          }}
        >
          {t('Afa.saveChanges')}
        </ButtonSave>
      </ButtonsGroup>
    </Wrapper>
  )
}

export default AfaChangeDateModal
