import React from 'react'
import styled from 'styled-components/macro'
import { palette } from '../../../../style/theme'
import { Positioning } from '../../Model/aaModel'
import WallMapSwitcher from './WallMapSwitcher'
import { useSelector } from 'react-redux'
import { roomTypeSelector } from '../../../../store/app/selectors'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 1.25vw;
`

const Track = styled.div<{
  value: number
  showGradient: boolean
}>`
  color: ${palette.cornflowerBlueAA};
  font-size: max(1vw, 15px);
  position: relative;
  flex: 1;

  input {
    -webkit-appearance: none;
    width: 100%;
    height: 0.25em;
    background-color: #17335f;
    background-image: ${({ showGradient, value }) =>
      showGradient
        ? `linear-gradient(to right, ${palette.cornflowerBlueAA} ${value}%, #17335f ${value}%);`
        : null};
    outline: none;
    border: 0;
    transition: opacity 0.2s;
  }

  input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 2.4em;
    height: 1.6em;
    border-radius: 0.8em;
    background: ${palette.white};
    cursor: pointer;
    border: solid 2px #0e213d;
  }

  input::-moz-range-thumb {
    width: 2.4em;
    height: 1.6em;
    border-radius: 0.8em;
    background: ${palette.white};
    cursor: pointer;
    border: solid 2px #0e213d;
  }

  .text {
    color: #021f38;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 1.9em;
    width: 2em;
    transform: translateX(-50%);
    pointer-events: none;

    left: calc((${({ value }) => value} * (100% - 2.4em) / 100) + 1.2em);
  }
`

const Labels = styled.div`
  padding-top: 0.5em;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: max(0.8em, 13px);
`

type Props = {
  value: string
  onChange: (value: string) => void
  step?: string
  min?: string
  max?: string
  showValue?: boolean
  leftLabel?: string
  rightLabel?: string
  positioning?: Positioning
  label?: string
}

const Slider: React.FC<Props> = ({
  min,
  max,
  value,
  step,
  showValue,
  leftLabel,
  rightLabel,
  onChange,
  label,
  positioning,
}) => {
  const dividend = (parseInt(value) - parseInt(min || '0')) * 100
  const divisor = parseInt(max || '100') - parseInt(min || '0')
  const percentage = dividend === 0 || divisor === 0 ? 0 : dividend / divisor
  const roomType = useSelector(roomTypeSelector)

  return (
    <Wrapper>
      <Track value={percentage} showGradient={!!showValue}>
        <input
          type="range"
          min={min}
          max={max}
          value={value}
          step={step}
          onChange={e => {
            onChange(e.currentTarget.value)
          }}
        />
        {showValue && <span className="text">{label ? label : value}</span>}
        <Labels>
          <span>{leftLabel}</span>
          <span>{rightLabel}</span>
        </Labels>
      </Track>

      {positioning && roomType !== 'ipad-only' && <WallMapSwitcher positioning={positioning} />}
    </Wrapper>
  )
}

export default Slider
