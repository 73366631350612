import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import Loading from '../../components/Loading'
import { useDuplicateCartOnDoorsMutation } from '../../services/afaCart'
import { activeDoorsSelector } from '../../store/customer/selectors'
import {
  breakpoints,
  getFluidSizeWithFullFormula as gF,
  getFluidSizeWithFullFormula,
  palette,
} from '../../style/theme'
import { StyledCheckBox } from '../CheckoutPage/components/Common'
import AfaDuplicateCartSearchBar from '../AfaCart/AfaDuplicateCart/components/AfaDuplicateCartSearchBar'
import filterDoors from '../AfaCart/AfaDuplicateCart/helpers/filterDoors'
import { ListShadow } from '../AfaCart/AfaDuplicateCart/AfaDuplicateCartOnDoorsModal'
import { errorNotification } from '../../components/Notification/notifications'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  color: ${palette.tangaroa};
  overflow-y: auto;
`

const Title = styled.div`
  font-size: ${getFluidSizeWithFullFormula('px', 26, 48, 1366, 3840)};
  text-transform: capitalize;
  font-family: GilmerMedium;
  @media (max-width: ${breakpoints.L}) {
    font-family: GilmerBold;
  }
`

const Subtitle = styled.div`
  font-size: ${getFluidSizeWithFullFormula('px', 20, 40, 1366, 3840)};
  font-family: GilmerRegular;
  margin-top: ${gF('px', 18, 40, 1366, 3840)};
`

const MainContent = styled.div`
  position: relative;
  flex: 1;
  margin-top: ${gF('px', 32, 72, 1366, 3840)};
`

const DoorsWrapper = styled.div<{ viewportHeight: number }>`
  overflow-y: auto;
  height: ${props =>
    props.viewportHeight > parseInt(breakpoints.L)
      ? props.viewportHeight / 2.5
      : props.viewportHeight / 3}px;
  display: flex;
  flex-direction: column;
  gap: ${gF('px', 24, 40, 1366, 3840)};
  padding: 0 ${gF('px', 48, 80, 1366, 3840)};
  &::-webkit-scrollbar {
    display: block !important;
    background-clip: padding-box;
    width: 6px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track {
    background: ${palette.bermudaGray};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track-piece {
    background: ${palette.extraLight};
    border-radius: 4px;
    border: 1px solid ${palette.extraLight};
  }
  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border-radius: 4px;
    box-shadow: inset 0 0 0 10px;
  }
  &::-webkit-scrollbar-corner {
    border-radius: 4px;
  }
  // avoid to show the scrollbar space on mobile devices
  @media (hover: none) and (pointer: coarse) {
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
`

const Door = styled.div`
  width: ${gF('px', 525, 1137.5, 1366, 3840)};
  min-height: ${gF('px', 60, 92, 1366, 3840)};
  border: solid ${gF('px', 1, 3, 1366, 3840)} ${palette.mischka};
  padding: 0 ${gF('px', 25, 32, 1366, 3840)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0.5rem;
  gap: 1rem;
  font-size: ${gF('px', 18, 40, 1366, 3840)};
  font-weight: 500;

  & label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const CheckBox = styled(StyledCheckBox)`
  width: ${gF('px', 20, 32, 1366, 3840)};
  height: ${gF('px', 20, 32, 1366, 3840)};

  label {
    font-size: ${gF('px', 18, 24, 1366, 3840)};
    font-weight: bold;
    color: ${palette.cloudBurst};
    margin-left: 1em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${palette.cloudBurst};
    @media (max-width: ${breakpoints.L}) {
      color: ${palette.blackPearl};
    }
  }
`

const DoorWithCheck = styled(Door)`
  border: 0;
  justify-content: center;
  font-size: ${gF('px', 18, 32, 1366, 3840)};
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &::before {
      content: '';
      display: inline-block;
      height: 0.8em;
      width: 0.8em;
      background-size: 0.8em 0.8em;
      background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='rgb(117, 165, 62)' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.802 3.052a.58.58 0 0 1-.18.42l-9.547 9.26a.963.963 0 0 1-.67.268.963.963 0 0 1-.67-.269L.18 8.313a.585.585 0 0 1 0-.84l.472-.458a.625.625 0 0 1 .867 0l3.67 3.56a.312.312 0 0 0 .431 0l8.665-8.401a.624.624 0 0 1 .866 0l.473.458c.115.11.18.262.18.42z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      margin-right: 0.5em;
    }
  }
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  font-family: GilmerBold;
`

const CancelButton = styled.button`
  padding: 1rem;
  border: solid 1px ${palette.tangaroa};
  text-transform: uppercase;
  border-radius: 4px;
`

const PrimaryButton = styled.button`
  padding: 1rem;
  border: solid 3px ${palette.tangaroa};
  text-transform: uppercase;
  background-color: ${palette.tangaroa};
  color: white;
  border-radius: 4px;
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
const ButtonConfirm = styled(PrimaryButton)`
  width: 15em;
`

const Warning = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  font-family: GilmerRegular, sans-serif;
  font-size: ${gF('px', 14, 32, 1366, 3840)};
  margin-bottom: ${gF('px', 32, 72, 1366, 3840)};
`

const FromDoorInfo = styled.h3`
  font-size: ${gF('px', 20, 40, 1366, 3840)};
  font-family: GilmerLight, sans-serif;
  font-weight: normal;
  margin-top: ${gF('px', 18, 40, 1366, 3840)};
  span {
    font-family: GilmerMedium, sans-serif;
  }
  @media (max-width: ${breakpoints.L}) {
    font-family: GilmerRegular, sans-serif;
  }
`

const DuplicateWarning = styled.h3`
  font-size: ${gF('px', 20, 40, 1366, 3840)};
  margin-top: ${gF('px', 32, 40, 1366, 3840)};
  margin-bottom: ${gF('px', 32, 72, 1366, 3840)};
  font-weight: 400;
  font-family: GilmerLight, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${palette.cloudBurst};
  @media (max-width: ${breakpoints.L}) {
    color: ${palette.tangaroa};
    font-family: GilmerRegular, sans-serif;
  }
`
const WarningImg = styled.div`
  height: 1em;
  width: 1em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23F18200'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.6 4.8a.8.8 0 1 0-1.6 0V8a.8.8 0 1 0 1.6 0V4.8zm-.8 5.6a.8.8 0 1 0 0 1.6h.008a.8.8 0 1 0 0-1.6H7.8z' fill='%23fff'/%3E%3C/svg%3E");
`
export const DuplicateDataOnDoorErrorImage = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16' cy='16' r='15.5' fill='%23FFEDD8' stroke='%23F18200'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17 12a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0v-4zm-1 7a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H16z' fill='%23F18200'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  height: 2.1em;
  width: 2.1em;
  background-size: 2.1em 2.1em;
  margin-top: 2em;
`

type Props = {
  sourceDoorId: string
  upcs: string[]
  close: () => void
  destinationDoorIds: string[]
  setDestinationDoorIds: React.Dispatch<React.SetStateAction<string[]>>
}

const DuplicateOrderModalContent: React.FC<Props> = ({
  sourceDoorId,
  close,
  upcs,
  destinationDoorIds,
  setDestinationDoorIds,
}) => {
  const { t } = useTranslation()

  const [duplicateProductOnDoors, duplicateProductOnDoorsResult] = useDuplicateCartOnDoorsMutation()

  const doors = useSelector(activeDoorsSelector)

  const fromDoorObj = doors.find(({ id }) => id === sourceDoorId)

  const fromDoorName = `${fromDoorObj?.id} - ${fromDoorObj?.name}`

  const notCurrentDoors = doors.filter(({ id }) => id !== sourceDoorId)

  const [doorSearch, setDoorSearch] = useState('')

  useEffect(() => {
    notCurrentDoors.length === 1 &&
      !destinationDoorIds.length &&
      setDestinationDoorIds([notCurrentDoors[0].id])
  }, [notCurrentDoors, destinationDoorIds, setDestinationDoorIds])

  const deduplicatedWarnings = [
    ...new Set(duplicateProductOnDoorsResult.data?.warnings.map(({ message }) => message) || []),
  ]

  const handleErrors = () => {
    if (deduplicatedWarnings.length) {
      deduplicatedWarnings.forEach(message => {
        errorNotification({ message })
      })
    }
  }

  return (
    <Wrapper>
      <Title>{t('Afa.duplicateOrder')}</Title>
      {duplicateProductOnDoorsResult.isUninitialized && (
        <>
          <FromDoorInfo>
            {t('Afa.Cart.FromDoor')} <span>{fromDoorName}</span>
          </FromDoorInfo>
          <DuplicateWarning>
            <WarningImg />
            <span>{t('Afa.Cart.DuplicateWarning')}</span>
          </DuplicateWarning>
          <AfaDuplicateCartSearchBar doorSearch={doorSearch} setDoorSearch={setDoorSearch} />
          <MainContent>
            <DoorsWrapper viewportHeight={window.innerHeight}>
              {filterDoors(notCurrentDoors, doorSearch).map(({ id, name }) => (
                <Door key={id}>
                  <CheckBox
                    id={id}
                    borderColor={palette.tangaroa}
                    accentColor={palette.tangaroa}
                    checked={destinationDoorIds.includes(id)}
                    onChange={e =>
                      setDestinationDoorIds(
                        e.currentTarget.checked
                          ? destinationDoorIds.concat(id)
                          : destinationDoorIds.filter(currentId => currentId !== id),
                      )
                    }
                  />
                  <label htmlFor={id}>
                    {id} - {name}
                  </label>
                </Door>
              ))}
            </DoorsWrapper>
            <ListShadow />
          </MainContent>
          <Buttons>
            <CancelButton
              onClick={() => {
                close()
              }}
            >
              {t('GenericWords.cancel')}
            </CancelButton>
            <PrimaryButton
              disabled={destinationDoorIds.length === 0}
              onClick={() => duplicateProductOnDoors({ sourceDoorId, destinationDoorIds, upcs })}
            >
              {t('Afa.duplicateOrder')}
            </PrimaryButton>
          </Buttons>
        </>
      )}

      {duplicateProductOnDoorsResult.isLoading && <Loading isFullPage={false} />}

      {duplicateProductOnDoorsResult.data && (
        <>
          {duplicateProductOnDoorsResult.data.result ? (
            <>
              <Subtitle>{t('Afa.dataDuplicatedOnDoor')}</Subtitle>
              <MainContent>
                {duplicateProductOnDoorsResult.data.result &&
                  notCurrentDoors
                    .filter(door => destinationDoorIds.includes(door.id))
                    .map(({ id, name }) => (
                      <DoorWithCheck key={id}>
                        <span>
                          {id} - {name}
                        </span>
                      </DoorWithCheck>
                    ))}
              </MainContent>
            </>
          ) : (
            <>
              <Subtitle>{t('Afa.duplicateDataGeneralError')}</Subtitle>
              <MainContent />
            </>
          )}

          {!!duplicateProductOnDoorsResult.data.warnings.length && (
            <Warning>
              <DuplicateDataOnDoorErrorImage />
              <div>{t('Afa.duplicateDataOnDoorError')}</div>
            </Warning>
          )}

          <Buttons>
            <ButtonConfirm
              onClick={() => {
                close()
                handleErrors()
              }}
            >
              {t('Afa.done')}
            </ButtonConfirm>
          </Buttons>
        </>
      )}
    </Wrapper>
  )
}

export default DuplicateOrderModalContent
