import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import MediaPlayer from '../../components/MediaPlayer'
import { formatAdvDate } from '../../libs'
import { CmsContent } from '../../model/content'
import { getFluidFontSize, palette } from '../../style/theme'
import LastCartProduct from '../PDP/WallPdpContent/LastCartProduct'

const Wrapper = styled.div`
  background-color: ${palette.black};
  height: 100%;

  video {
    display: block;
  }
`

const Img = styled.img`
  height: 100%;
  width: 100%;
  margin: auto;
  object-fit: contain;
  display: block;

  &.invert {
    filter: invert(1);
    max-width: 20%;
  }
`

const AdvActiveDates = styled.div`
  position: absolute;
  top: 0;
  padding-top: 1.733rem;
  left: 0;
  color: ${palette.white};
  font-weight: bold;
  z-index: 1;
  background: none;
  padding-left: 2.143rem;
  text-transform: uppercase;
  font-size: ${getFluidFontSize('14px')};
`

const Video: React.FC<{
  media: CmsContent
  handleVideoPlaying?: () => void
  handleVideoEnded?: () => void
  isPaused: boolean
  loop: boolean
}> = ({ loop, media, isPaused, handleVideoEnded, handleVideoPlaying }) => {
  const { t } = useTranslation()
  return (
    <MediaPlayer
      videoUrl={media.url}
      isPaused={isPaused}
      loop={!!loop}
      handleVideoEnded={handleVideoEnded}
      handleVideoPlaying={handleVideoPlaying}
    >
      {t('Errors.error_browser_videos')}
    </MediaPlayer>
  )
}

const Image: React.FC<{
  media: CmsContent & { invert?: boolean }
  handleVideoEnded?: () => void
  isPaused: boolean
}> = ({ media, isPaused, handleVideoEnded }) => {
  if (isPaused) {
    // pausing an image will trigger the exit from the displaymedia
    handleVideoEnded && handleVideoEnded()
    return null
  } else {
    return <Img src={media.url} alt="" className={media.invert ? 'invert' : ''} />
  }
}

type Props = {
  className?: string
  media: CmsContent
  dates?:
    | string[]
    | null
    | {
        fromDate: string
        toDate: string
      }
  handleVideoPlaying?: () => void
  handleVideoEnded?: () => void
  isPaused?: boolean
  loop?: boolean
  displayLast?: boolean
}

export const DisplayMedia: React.FC<Props> = ({
  loop,
  media,
  dates,
  displayLast,
  isPaused = false,
  handleVideoEnded,
  handleVideoPlaying,
  className,
}) => {
  const { t } = useTranslation()
  const fromDate = Array.isArray(dates)
    ? dates[0]
    : dates === null || dates === undefined
    ? undefined
    : dates.fromDate
  const toDate = Array.isArray(dates)
    ? dates[1]
    : dates === null || dates === undefined
    ? undefined
    : dates.toDate

  return !media ? null : (
    <Wrapper className={['display-media', className, media.type].join(' ')}>
      {media.type === 'video' ? (
        <Video
          loop={!!loop}
          media={media}
          isPaused={isPaused}
          handleVideoEnded={handleVideoEnded}
          handleVideoPlaying={handleVideoPlaying}
        />
      ) : (
        <Image media={media} isPaused={isPaused} handleVideoEnded={handleVideoEnded} />
      )}
      {displayLast && <LastCartProduct />}
      {fromDate && (
        <AdvActiveDates className="adv-active-dates">
          <div>
            {t('WallCartContent.validityPeriod')} {formatAdvDate(fromDate)}{' '}
            {toDate && `– ${formatAdvDate(toDate)}`}
          </div>
        </AdvActiveDates>
      )}
    </Wrapper>
  )
}

export default DisplayMedia
