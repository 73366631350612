import { useHistory } from 'react-router-dom'

type Action = (() => void) | 'goBack' | 'push'

/**
 * A custom hook to handle action dispatching based on the provided action type.
 * @param {Action} action - The action to be dispatched. It can be a function or one of the predefined strings: 'goBack' or 'push'.
 * @param {string} linkToPush - The optional link that must be used with the 'push' action.
 * @returns {() => void} A function to be called for dispatching the specified action.
 */
const useActionDispatcher = (): ((action: Action, linkToPush?: string) => void) => {
  const history = useHistory()

  const dispatchAction = (action: Action, linkToPush?: string) => {
    if (typeof action === 'function') {
      action()
    } else if (typeof action === 'string') {
      switch (action) {
        case 'goBack':
          history.goBack()
          break
        case 'push':
          history.push(linkToPush || '')
          break
      }
    }
  }

  return dispatchAction
}

export default useActionDispatcher
