import React from 'react'
import styled from 'styled-components'
import { getFluidSizeWithFullFormula as gF } from '../../style/theme'

const Icon = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16' cy='16' r='16' fill='%23F18200'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.2 9.6a1.6 1.6 0 0 0-3.2 0V16a1.6 1.6 0 0 0 3.2 0V9.6zm-1.6 11.2a1.6 1.6 0 1 0 0 3.2h.016a1.6 1.6 0 0 0 0-3.2H15.6z' fill='%23fff'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  width: ${gF('px', 32, 64, 1366, 3840)};
  height: ${gF('px', 32, 64, 1366, 3840)};
`

const ErrorIcon: React.FC = () => <Icon />

export default ErrorIcon
