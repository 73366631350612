import React from 'react'
import styled from 'styled-components'
import {
  breakpoints,
  getFluidFontSize,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
} from '../../../style/theme'
import FilterChip from './FilterChip'
import { Section } from '../components/AfaCheckoutCommonComponents'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from '../../../hooks/useSearchParams'
import { FilterKey } from '../../../model/afa'

const Wrapper = styled.div`
  padding: 0;
  @media (max-width: ${breakpoints.L}) {
    padding: 0 2vw;
  }
`
const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${pxToRem(24)}rem;
  font-size: ${getFluidFontSize('12px', 15)};
  width: 100%;
  border-radius: ${pxToRem(10)}rem;
  color: ${palette.biscay};
  background-color: ${palette.white};
  border: solid 1px ${palette.mischka};
  padding: 0 ${getFluidSizeWithFullFormula('px', 20, 90, 1366, 3840)};
`

const Title = styled.div`
  text-transform: uppercase;
  font-size: ${pxToRem(24)}rem;
  font-family: GilmerBold;
  color: ${palette.tangaroa};
  letter-spacing: 0.3px;

  @media (max-width: ${breakpoints.L}) {
    font-size: ${pxToRem(16)}rem;
    letter-spacing: 0.8px;
  }
`

const AppliedFilters: React.FC = () => {
  const { t } = useTranslation()

  const [searchParams] = useSearchParams()

  const filtersKey: FilterKey[] = [
    'size',
    'sport',
    'colorFacet',
    'technology',
    'gender',
    'release',
    'family',
    'productType',
    'collection',
    'limitedRelease',
    'fabric',
    'fabricGroup',
    'fit',
    'afaStyle',
    'adv',
    'hasKeylooks',
    'afacatalogid',
  ]
  const appliedFilters = filtersKey.map(filterKey => ({
    filterKey,
    selectedOptions: searchParams.get(filterKey)?.split(','),
  }))

  const filtersToShow = appliedFilters.filter(({ selectedOptions }) => selectedOptions)

  return !filtersToShow.length ? null : (
    <Wrapper>
      <Filters>
        <Section>
          <Title>{t('Afa.Checkout.appliedFilters')}</Title>
          {filtersToShow.flatMap(({ filterKey, selectedOptions }) =>
            selectedOptions?.map(option => (
              <FilterChip key={`${filterKey}-${option}`} filterKey={filterKey} option={option} />
            )),
          )}
        </Section>
      </Filters>
    </Wrapper>
  )
}

export default AppliedFilters
