import { createSelector } from '@reduxjs/toolkit'

import { isPageMatches } from '../../libs/url'
import { RootState } from '../../model/model'
import { isCartViewSelector } from '../cart/selectors'
import { isPdpVisibleSelector } from '../pdp/selectors'
import { isPLPPageSelector } from '../showButtonsSelectors'

export const switchLabelKeySelector = createSelector(
  () => isPageMatches('cart'),
  isCartViewSelector,
  isPdpVisibleSelector,
  isPLPPageSelector,
  (isCartPage, isCartView, isPdpVisible, isPLPPage) => {
    let switchButtonLabel = 'FooterNav.item_switch'

    if (isPLPPage) {
      switchButtonLabel = 'FooterNav.item_grid'
    }

    if (isCartPage) {
      switchButtonLabel = isCartView ? 'Header.recommended' : 'WallCartPage.cart'
    }

    if (isPdpVisible) {
      switchButtonLabel = 'PdpPage.moodbard'
    }

    return switchButtonLabel
  },
)

export const contentAdvSelector = (state: RootState) => state.content.adv
