import React, { SVGProps } from 'react'

const ClusterIcon: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 24"
      height="24"
      width="25"
      fill="currentColor"
      {...props}
    >
      <path d="M 12.5 0.19726562 L 0.75 4.6503906 L 0.75 5.6738281 L 8.0664062 8.8769531 L 0.75 11.650391 L 0.75 12.673828 L 6.8417969 15.341797 L 0.75 17.650391 L 0.75 18.673828 L 12.5 23.818359 L 24.25 18.673828 L 24.25 18.183594 L 24.25 17.650391 L 18.158203 15.341797 L 24.25 12.673828 L 24.25 12.183594 L 24.25 11.650391 L 16.933594 8.8769531 L 24.25 5.6738281 L 24.25 5.1835938 L 24.25 4.6503906 L 12.5 0.19726562 z M 12.5 1.8027344 L 21.535156 5.2246094 L 14.929688 8.1171875 L 12.5 7.1972656 L 10.070312 8.1171875 L 3.4648438 5.2246094 L 12.5 1.8027344 z M 12.5 8.8027344 L 12.964844 8.9785156 L 12.5 9.1816406 L 12.035156 8.9785156 L 12.5 8.8027344 z M 10.03125 9.7382812 L 12.5 10.818359 L 14.96875 9.7382812 L 21.535156 12.224609 L 16.152344 14.582031 L 12.5 13.197266 L 8.8476562 14.582031 L 3.4648438 12.224609 L 10.03125 9.7382812 z M 12.5 14.802734 L 14.1875 15.441406 L 12.5 16.181641 L 10.8125 15.441406 L 12.5 14.802734 z M 8.8066406 16.201172 L 12.5 17.818359 L 16.193359 16.201172 L 21.535156 18.224609 L 12.5 22.181641 L 3.4648438 18.224609 L 8.8066406 16.201172 z " />
    </svg>
  )
}

export default ClusterIcon
