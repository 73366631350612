import { Button, Icon } from 'antd'
import classnames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import GotoCheckoutButton from '../../components/FooterNav/FooterCheckout'
import FooterNavBrand from '../../components/FooterNav/FooterNavBrand/FooterNavBrand'
import FooterNavLeftCol from '../../components/FooterNav/FooterNavLeftCol'
import FooterNavPdpInfo from '../../components/FooterNav/FooterNavPdpInfo'
import FooterNavRightCol from '../../components/FooterNav/FooterNavRightCol'
import app_config from '../../config/app/config'
import { useHandleTableWallPageSwitch } from '../../hooks/useHandleTableWallPageSwitch'
import { getBrandByBrandCode } from '../../libs/brand'
import { hasDisabledDoors } from '../../libs/doors'
import { isPageMatches } from '../../libs/url'
import { ModalType } from '../../model/model'
import { resetFiltersAction, showFooterModal } from '../../store/actions'
import { footerModalVisibleSelector, isKamUserSelector } from '../../store/app/selectors'
import { brandsSelector } from '../../store/brands/selectors'
import { cartEditableSelector, cartViewTypeSelector } from '../../store/cart/selectors'
import { customerDoorsSelector } from '../../store/customer/selectors'
import { activeFiltersSelector } from '../../store/filters/selectors'
import {
  isPdpVisibleSelector,
  pdpCouvetteSelector,
  pdpViewTypeSelector,
} from '../../store/pdp/selectors'
import { isCartPageSelector } from '../../store/showButtonsSelectors'
import { cartModeSelector } from '../../store/stars/selectors'
import { breakpoints, footerHeight, getFluidFontSize, palette, pxToRem } from '../../style/theme'
import CheckoutFooterButton from '../CheckoutPage/components/Footer/Buttons'

const StyledFooter = styled.footer`
  font-size: ${getFluidFontSize('10px')};
  display: grid;
  grid-template-columns: auto 19% auto;
  position: relative;
  z-index: 100010;
  width: 100%;
  height: max(${footerHeight}, 3.5em); /* 3.5em is the height of the home button */
  color: ${palette.congressBlue};
  text-transform: uppercase;
  text-align: center;
  border-top: 1px solid ${palette.silver};
  background: ${palette.white};
  box-shadow: 0 -2px 4px 1px rgba(220, 220, 220, 0.5), 0 -1px 0 0 ${palette.alto};

  .invisible {
    visibility: hidden;

    &:not(.go-to-checkout):not(.back-to-brands) {
      &.btn-play-video {
        box-shadow: none;
      }

      &.btn-play-video-primary {
        background: ${palette.white};
        font-weight: bold;
        border: ${pxToRem(1)}rem solid ${palette.concrete};
        box-shadow: 0 ${pxToRem(2)}rem ${pxToRem(4)}rem 0 rgba(0, 0, 0, 0.2);
        margin-right: 1vw;
      }

      &.apply-button {
        background-color: ${palette.congressBlue};
        border-color: ${palette.congressBlue};
        color: ${palette.white};
        min-width: auto;
        position: absolute;
        right: 28vw;
      }

      &.disabled {
        color: rgba(0, 0, 0, 0.25);
        background-color: ${palette.strangeWhite};
        border-color: ${palette.littleGrey};
        text-shadow: none;
        box-shadow: none;
      }
    }
  }

  .space-betwenn {
    justify-content: space-between;
  }

  .title {
    font-size: 2em;
  }

  .count-products {
    display: none;
  }

  &.active {
    background-color: ${palette.athensGray};
  }

  .page-wishlist & {
    grid-template-columns: 1.2fr 0.5fr 1.2fr;
  }

  &.page-checkout {
    background-color: ${palette.white};
  }

  .page-cart & {
    background-color: ${palette.white};
    @media (min-width: ${breakpoints.L}) {
      grid-template-columns: 1.2fr 0.2fr 1.5fr;
    }
  }

  @media screen and (max-width: ${breakpoints.M}) {
    .has-couvettes & {
      &:after {
        content: url('${app_config.publicUrl}/assets/swipe.svg');
        position: absolute;
        display: block;
        top: -6vh;
        left: 5%;
        pointer-events: none;
        background: #e0e0e0;
        width: 90%;
        height: 4vh;
        padding: 0.5vh;
        border-radius: 1rem;
      }
    }

    .ReactModal__Body--open &,
    .order-by-modal-open &,
    .search-modal-open &,
    .account-modal-open & {
      &:after {
        display: noneblock;
      }
    }
  }
`
const StyledCoverableFooter = styled(StyledFooter)`
  z-index: -1;
`

const FooterNavCentralCol: React.FC = () => {
  const isPdpVisible = useSelector(isPdpVisibleSelector)
  const pdpCouvette = useSelector(pdpCouvetteSelector)
  const brands = useSelector(brandsSelector)
  const cartViewType = useSelector(cartViewTypeSelector)
  const isCartPage = useSelector(isCartPageSelector)
  const isKamUser = useSelector(isKamUserSelector)
  const cartViewMode = useSelector(cartModeSelector)
  const isCartEditable = useSelector(cartEditableSelector)
  const isCheckoutPage = isPageMatches('checkout')

  if (isPdpVisible && !!pdpCouvette) {
    return (
      <FooterNavPdpInfo
        pdpCouvette={pdpCouvette}
        brand={getBrandByBrandCode(brands, pdpCouvette.brandCode)}
      />
    )
  }

  const footerHasCentralBrandLogo =
    isPageMatches('single-brand', 'plp', 'wishlist') ||
    (isPageMatches('cart') && cartViewType === app_config.viewType.wall)

  if (footerHasCentralBrandLogo) {
    return <FooterNavBrand isBrandColVisible={!isPageMatches('cart', 'wishlist')} />
  }

  if (isCartPage && isKamUser && cartViewMode !== 'stars' && isCartEditable) {
    return <GotoCheckoutButton />
  }

  if (isCheckoutPage) {
    return <CheckoutFooterButton />
  }

  return null
}

type Props = {
  className?: string
}

const FooterNav: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation()

  const history = useHistory()

  const activeFilters = useSelector(activeFiltersSelector)
  const modalVisible = useSelector(footerModalVisibleSelector)
  const pdpViewType = useSelector(pdpViewTypeSelector)
  const customerDoors = useSelector(customerDoorsSelector)
  const isCheckoutPage = isPageMatches('checkout')

  const dispatch = useDispatch()

  const handleSwitch = useHandleTableWallPageSwitch()

  const toggleModal = (modalType: ModalType) => {
    dispatch(showFooterModal(modalVisible === modalType ? null : modalType))
  }

  const filteredArr = Object.keys(activeFilters).filter(
    filter => filter !== 'brand_slug' && filter !== 'category',
  )
  const thereAreFiltersActive =
    (activeFilters.brand_slug && activeFilters.brand_slug.active.length > 1) ||
    filteredArr.length > 0

  return pdpViewType === app_config.viewType.table ? (
    <StyledFooter
      className={`${classnames({
        'footer-nav': true,
        'modal-open': modalVisible,
        'page-checkout': isCheckoutPage,
        active: hasDisabledDoors(customerDoors),
      })} ${className}`}
    >
      <FooterNavLeftCol
        backToBrandsEvent={() => {
          if (history.location.pathname.includes('cart')) {
            dispatch(resetFiltersAction())
          }
        }}
        goBackEvent={() => {
          if (history.location.pathname.includes('cart')) {
            dispatch(resetFiltersAction())
          }
          history.goBack()
        }}
        thereAreFiltersActive={thereAreFiltersActive}
        toggleModal={toggleModal}
      />

      <FooterNavCentralCol />

      <FooterNavRightCol toggleModal={toggleModal} />
    </StyledFooter>
  ) : (
    <StyledCoverableFooter
      className={`${classnames({
        'footer-nav': true,
        'modal-open': modalVisible,
      })} ${className}`}
    >
      <Button className="btn-link" onClick={handleSwitch}>
        <Icon type="switcher" /> {t('PdpPage.product_page')}
      </Button>
    </StyledCoverableFooter>
  )
}

export default FooterNav
