import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import Backdrop from '../../../../styleguide/Backdrop'
import { breakpoints, getFluidSizeWithFullFormula as gF, palette } from '../../../../style/theme'
import { useDispatch, useSelector } from 'react-redux'
import BrandLogo from './BrandLogo'
import assortmentAdvisorSelectors from '../../../../store/assortmentAdvisor/selectors'
import assortmentAdvisorActions from '../../../../store/assortmentAdvisor/actions'
import CloseIcon from './CloseIcon'
import { useSearchParams } from '../../../../hooks/useSearchParams'
import { useTranslation } from 'react-i18next'
import { useGetMapBrandCodesQuery } from '../../services'
import { brandsSelector } from '../../../../store/brands/selectors'

const ControlsLabel = styled.div`
  font-size: ${gF('px', 28, 56, 1366, 3840)};
  width: min-content;
  line-height: 1;
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  gap: ${gF('px', 10, 20, 1366, 3840)};
  margin-right: ${gF('px', 52, 104, 1366, 3840)};
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  color: ${palette.white};
  align-items: center;
  gap: ${gF('px', 55, 110, 1366, 3840)};
`

const Brands = styled.div`
  flex-grow: 1;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, ${gF('px', 130, 260, 1366, 3840)});
  padding: ${gF('px', 52, 104, 1366, 3840)};
  gap: ${gF('px', 7, 14, 1366, 3840)};
`

const IconButton = styled.div`
  cursor: pointer;
  display: flex;
  background-color: ${palette.cloudBurst};
  border-radius: 18px;
  padding: ${gF('px', 7, 14, 1366, 3840)};
  justify-content: center;
  align-items: center;

  & > span:last-of-type {
    border: 2px ${palette.cornflowerBlueAA} solid;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${gF('px', 6, 12, 1366, 3840)};
  }

  & > span:first-of-type {
    color: ${palette.white};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 ${gF('px', 23, 46, 1366, 3840)};
    font-size: ${gF('px', 16, 32, 1366, 3840)};
  }
`

const Button = styled.div`
  cursor: pointer;
  border-radius: 12px;
  padding: ${gF('px', 15, 30, 1366, 3840)} ${gF('px', 45, 90, 1366, 3840)};
  color: ${palette.white};
  font-size: ${gF('px', 16, 32, 1366, 3840)};
`

const ApplyButton = styled(Button)`
  background-color: ${palette.cornflowerBlueAA};
`

const ResetButton = styled(Button)`
  background-color: ${palette.cloudBurst};
`

const BrandsBackdrop = styled(Backdrop)`
  z-index: 1001;
  & > div {
    transform: translateX(-100%);

    &.is-open {
      transform: translateX(0);
    }

    width: calc(100% - 20vh);
    background-color: ${palette.firefly};
    border-radius: 22px;
    left: ${gF('px', 54, 108, 1366, 3840)};
    top: ${gF('px', 50, 200, 1366, 3840)};
    padding: ${gF('px', 35, 70, 1366, 3840)} ${gF('px', 28, 56, 1366, 3840)};
    flex-direction: column;
    overflow-y: auto;

    @media (max-width: ${breakpoints.M}) {
      left: 4vw !important;
      top: 6vh !important;
      bottom: auto;
      width: 92vw !important;
      height: 88vh !important;
    }

    @media (min-width: ${breakpoints.XL}) {
      width: min(${gF('px', 1140, 2280, 1366, 3840)}, 100%);
    }
  }
`

const Brand = styled.div`
  width: ${gF('px', 130, 260, 1366, 3840)};
  height: ${gF('px', 84, 168, 1366, 3840)};
  border-radius: 12px;
  border: 1px ${palette.blueZodiac} solid;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${gF('px', 8, 16, 1366, 3840)};
  cursor: pointer;

  &.selected {
    background-color: ${palette.white};
  }
`

const BrandsOverlay: FC = () => {
  const { t } = useTranslation()
  const brandsQuery = useGetMapBrandCodesQuery()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedBrandCode, setSelectedBrandCode] = useState('All')
  const isOpen = useSelector(assortmentAdvisorSelectors.isMapBrandsFilterOpen)
  const searchParamsBrandCode = searchParams.get('mapBrand') || 'All'
  const brands = useSelector(brandsSelector)
  const brandCodes = brands.map(({ code }) => code)
  // TODO: to remove when backend filters subbrands
  const filteredBrands = (brandsQuery.data || []).filter(brand => brandCodes.includes(brand))

  useEffect(() => {
    if (isOpen) {
      setSelectedBrandCode(searchParamsBrandCode)
    }
  }, [searchParamsBrandCode, isOpen])

  const handleApply = () => {
    searchParams.set('mapBrand', selectedBrandCode)
    setSearchParams(searchParams, { replace: true })
    dispatch(assortmentAdvisorActions.setMapBrandsFilterOpen(false))
  }

  return (
    <BrandsBackdrop
      onClick={() => dispatch(assortmentAdvisorActions.setMapBrandsFilterOpen(false))}
      open={isOpen}
    >
      <Header>
        <ControlsLabel>{t('AA.Map.Brands.Title')}</ControlsLabel>
        <IconButton
          onClick={() => dispatch(assortmentAdvisorActions.setMapBrandsFilterOpen(false))}
        >
          <span>{t('AA.Map.Brands.Filter')}</span>
          <span>
            <CloseIcon />
          </span>
        </IconButton>
      </Header>
      <Brands>
        {filteredBrands.map(brand => {
          const selected = selectedBrandCode === brand

          return (
            <Brand
              className={selected ? 'selected' : undefined}
              key={brand}
              onClick={() => setSelectedBrandCode(brand)}
            >
              <BrandLogo brandCode={brand} selected={selected} />
            </Brand>
          )
        })}
      </Brands>
      <Buttons>
        {selectedBrandCode !== '' && (
          <ResetButton onClick={() => setSelectedBrandCode('All')}>
            {t('AA.Map.Brands.Reset')}
          </ResetButton>
        )}
        {selectedBrandCode !== searchParamsBrandCode && (
          <ApplyButton onClick={handleApply}>{t('AA.Map.Brands.Apply')}</ApplyButton>
        )}
      </Buttons>
    </BrandsBackdrop>
  )
}

export default BrandsOverlay
