import { getStarsAssortment } from '../../api/restApi'
import { categoryIdToSlug, getQueryParamsFromFilters } from '../../libs/couvettes'
import { isNotUndefined } from '../../libs/utils'
import { AppThunk } from '../../model/model'
import { QueryParams } from '../../model/product'
import { dataLoadedFail, showFooterModal } from '../app/actions'
import {
  customerIdSelector,
  eventIdSelector,
  isKidCategoryModeEnabledSelector,
  languageSelector,
  selectedEventSelector,
} from '../app/selectors'
import { tokenSelector } from '../auth/selectors'
import { activePlpBrandCodesSelector, openedBrandsSelector } from '../brands/selectors'
import { customerStarsSelector } from '../customer/selectors'
import { selectedMonthSelector, starsAssortmentLoadedBrandCodesSelector } from './selectors'
import { slice } from './slice'
import { isPlpFilteredSelector, plpFiltersCategorySelector } from '../filters/selectors'
import { isSubBrandCategory } from '../../helpers/genericHelper'
import { History } from 'history'
import { getDeviceAbsolutePath } from '../../libs/url'

export const loadStarsAssortment = (brandCodes?: string[]): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()
    const eventId = eventIdSelector(state)
    const customerId = customerIdSelector(state)
    const token = tokenSelector(state)
    const lang = languageSelector(state)
    const isStarsCustomer = customerStarsSelector(state)
    const plpBrands = activePlpBrandCodesSelector(state)
    const loadedBrandsCodes = starsAssortmentLoadedBrandCodesSelector(state)
    const brandCodesToLoad = (brandCodes ? brandCodes : plpBrands.filter(isNotUndefined)).filter(
      code => !loadedBrandsCodes.includes(code),
    )

    const BRANDS_BATCH_SIZE = 4
    const brandBatches = brandCodesToLoad.reduce((result, brand, index) => {
      if (index % BRANDS_BATCH_SIZE === 0) {
        result.push([brand])
      } else {
        result[result.length - 1].push(brand)
      }

      return result
    }, [] as string[][])

    if (isStarsCustomer && eventId && customerId && brandBatches.length > 0) {
      brandBatches.forEach(brandCodes => {
        const queryParams: Omit<
          QueryParams,
          'customerId' | 'eventId' | 'customerType' | 'catalog'
        > = {
          brand: brandCodes.join(','),
        }
        getStarsAssortment(eventId, customerId, token || '', lang, queryParams)
          .then(starsAssortment => {
            dispatch(
              slice.actions.setAssortment({
                starsAssortment,
                brandCodes: brandCodes,
              }),
            )
          })
          .catch(err => {
            dispatch(dataLoadedFail(err))
          })
      })
    }
  }
}

export const loadFilteredStarsAssortment = ({
  brandCodes,
  history,
}: {
  brandCodes?: string[]
  history: History
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()
    const isPlpFiltered = isPlpFilteredSelector(state)
    const filtersActiveCategory = plpFiltersCategorySelector(state)
    const openedBrands = openedBrandsSelector(state)

    if (!isPlpFiltered && openedBrands.length === 1) {
      dispatch(
        slice.actions.setFilteredAssortment({
          starsAssortment: {},
          brandCodes: [],
        }),
      )
      dispatch(loadStarsAssortment())
      dispatch(showFooterModal(null))
      return
    }
    if (openedBrands.length === 1) {
      const pageSlug = categoryIdToSlug(filtersActiveCategory)
      history.push(`${getDeviceAbsolutePath()}/single-brand/${openedBrands[0].slug}/${pageSlug}`)
    } else {
      history.push(`${getDeviceAbsolutePath()}/products`)
    }

    const eventId = eventIdSelector(state)
    const customerId = customerIdSelector(state)
    const token = tokenSelector(state)
    const lang = languageSelector(state)
    const event = selectedEventSelector(state)
    const isKidCategoryModeEnabled = isKidCategoryModeEnabledSelector(state)
    const isStarsCustomer = customerStarsSelector(state)
    const brandCodesToLoad = brandCodes
      ? brandCodes
      : openedBrands
          .flatMap(({ subBrands }) => subBrands)
          .filter(subBrand =>
            isSubBrandCategory(subBrand, filtersActiveCategory || '', isKidCategoryModeEnabled),
          )
          .map(({ code }) => code)

    const month = selectedMonthSelector(state)

    const params = getQueryParamsFromFilters(
      isKidCategoryModeEnabled,
      state.filters.plp,
      state.app.customerId,
      state.brands,
      state.couvettes.pagination.start,
      event,
    )

    if (isStarsCustomer && eventId && customerId) {
      const queryParams: Omit<
        QueryParams,
        'customerId' | 'eventId' | 'customerType' | 'catalog'
      > = {
        ...params,
        month,
        month_to: month,
        brand: brandCodesToLoad.join(','),
      }
      dispatch(
        slice.actions.setFilteredAssortment({
          starsAssortment: {},
          brandCodes: [],
        }),
      )
      getStarsAssortment(eventId, customerId, token || '', lang, queryParams)
        .then(starsAssortment => {
          dispatch(
            slice.actions.setFilteredAssortment({
              starsAssortment,
              brandCodes: brandCodesToLoad,
            }),
          )
        })
        .catch(err => {
          dispatch(dataLoadedFail(err))
        })
    }
  }
}

const starsActions = {
  ...slice.actions,
  loadStarsAssortment,
  loadFilteredStarsAssortment,
}

export default starsActions
