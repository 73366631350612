import { createSelector } from 'reselect'
import { RootState } from '../../model/model'

export const filtersOpenSelector = (state: RootState) => state.afa.filtersOpen

export const cartFiltersOpenSelector = (state: RootState) => state.afa.cartFiltersOpen

export const searchOpenSelector = (state: RootState) => state.afa.searchOpen

export const sortOpenSelector = (state: RootState) => state.afa.sortOpen

export const afaProductsSelector = (state: RootState) => state.afa.products

export const selectedAddressesSelector = (state: RootState) => state.afa.selectedAddresses

export const cancellationDatesSelector = (state: RootState) => state.afa.cancellationDates

export const duplicateCartOnDoorModalOpenSelector = (state: RootState) =>
  state.afa.duplicateCartOnDoorModalOpen

export const cartAdjustAllModalOpenSelector = (state: RootState) => state.afa.cartAdjustAllModalOpen

export const cartAdjustAllKeysSelector = (state: RootState) => state.afa.cartAdjustAllKeys

export const adjustAllIsSimulate = (state: RootState) => state.afa.adjustAllIsSimulate

export const shareCartOnDoorModalOpenSelector = (state: RootState) =>
  state.afa.shareCartOnDoorModalOpen

export const importExportStepSelector = (state: RootState) => state.afa.checkout.importExport.step

export const selectAfaFileData = (state: RootState) => state.afa.checkout.upload.fileData

export const previousUrlSelector = (state: RootState) => state.afa.previousUrl

export const afaCartIsUpdatingSelector = createSelector(
  (state: RootState) => state.afa.cartIsUpdating,
  cartIsUpdating => cartIsUpdating,
)
