import React from 'react'

const BarcodeIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    viewBox="0 0 16.475 12"
    height="12"
    width="16.475"
    fill="currentColor"
    className={['barcode-icon', className].join(' ')}
  >
    <path d="M 1.234375 0 A 1.237 1.237 0 0 0 0 1.234375 L 0 10.765625 A 1.237 1.237 0 0 0 1.234375 12 L 15.240234 12 A 1.236 1.236 0 0 0 16.476562 10.765625 L 16.476562 1.234375 A 1.236 1.236 0 0 0 15.240234 0 L 1.234375 0 z M 1.234375 0.73046875 L 15.240234 0.73046875 A 0.5 0.5 0 0 1 15.740234 1.2304688 L 15.740234 10.759766 A 0.5 0.5 0 0 1 15.240234 11.259766 L 1.234375 11.259766 A 0.5 0.5 0 0 1 0.734375 10.759766 L 0.734375 1.2304688 A 0.5 0.5 0 0 1 1.234375 0.73046875 z M 2.1621094 2.2578125 L 2.1621094 9.7402344 L 2.8984375 9.7402344 L 2.8984375 2.2578125 L 2.1621094 2.2578125 z M 3.7226562 2.2578125 L 3.7226562 9.7402344 L 4.4570312 9.7402344 L 4.4570312 2.2578125 L 3.7226562 2.2578125 z M 5.2675781 2.2578125 L 5.2675781 9.7402344 L 6.0039062 9.7402344 L 6.0039062 2.2578125 L 5.2675781 2.2578125 z M 6.3847656 2.2578125 L 6.3847656 9.7402344 L 7.1210938 9.7402344 L 7.1210938 2.2578125 L 6.3847656 2.2578125 z M 8.46875 2.2578125 L 8.46875 9.7402344 L 9.203125 9.7402344 L 9.203125 2.2578125 L 8.46875 2.2578125 z M 9.3476562 2.2578125 L 9.3476562 9.7402344 L 10.082031 9.7402344 L 10.082031 2.2578125 L 9.3476562 2.2578125 z M 10.789062 2.2578125 L 10.789062 9.7402344 L 11.523438 9.7402344 L 11.523438 2.2578125 L 10.789062 2.2578125 z M 12.496094 2.2578125 L 12.496094 9.7402344 L 13.230469 9.7402344 L 13.230469 2.2578125 L 12.496094 2.2578125 z M 13.816406 2.2578125 L 13.816406 9.7402344 L 14.552734 9.7402344 L 14.552734 2.2578125 L 13.816406 2.2578125 z " />
  </svg>
)

export default BarcodeIcon
