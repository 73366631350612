import { Icon } from 'antd'
import classnames from 'classnames'
import React from 'react'
import styled from 'styled-components'

import { CmsContentType } from '../../../model/content'
import { palette } from '../../../style/theme'
import RCIcon from '../RCIcon'

const Button = styled.button`
  display: flex;
  align-content: center;
  color: ${palette.white};
  background-color: ${palette.mariner};
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid ${palette.gallery};
  border-radius: 0.25em;
  width: 1em;
  height: 1em;

  .icon-toggler__text {
    margin-left: 4px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`

type Props = {
  className: string
  handleClick: () => void
  headerIcon?: boolean
  iconActive: string
  iconInactive: string
  isActive: boolean
  textActive: string
  textInactive: string
  mediaType?: CmsContentType
  classSmartLook: string
}

const IconToggler: React.FC<Props> = ({
  className,
  headerIcon,
  iconActive,
  iconInactive,
  isActive,
  mediaType,
  textActive,
  textInactive,
  classSmartLook,
  handleClick,
}) => {
  return (
    <Button
      className={`${classnames('icon-toggler', className)} ${classSmartLook}`}
      onClick={handleClick}
    >
      {mediaType === 'video' && (
        <Icon type={isActive ? iconActive : iconInactive} className="icon-toggler__icon" />
      )}
      {mediaType === 'image' &&
        (isActive ? (
          <Icon type={iconActive} />
        ) : (
          <RCIcon type={headerIcon ? 'image-white' : 'image-blue'} />
        ))}
      {!headerIcon && mediaType !== 'image' && (
        <span className="icon-toggler__text">{isActive ? textActive : textInactive}</span>
      )}
    </Button>
  )
}

export default IconToggler
