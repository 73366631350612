import React from 'react'
import styled from 'styled-components'

import { palette } from '../style/theme'

const Label = styled.label`
  color: inherit;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 1.2em;

  &.afa {
    .input-custom {
      border: 1px solid ${palette.tangaroa};

      &:after {
        border: 1.7px solid ${palette.tangaroa};
        border-top: 0;
        border-left: 0;
      }
    }
  }

  > span {
    position: relative;
    vertical-align: center;
    display: flex;
    align-items: center;
    align-content: center;
  }

  input[type='checkbox']:checked + span:after {
    opacity: 1;
  }

  input[type='checkbox']:disabled ~ span {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.without-input {
    > span {
      margin-bottom: 0.5em;
    }

    .input-custom {
      display: none;
    }
  }
`

const CheckBox = styled.span`
  font-size: inherit;
  font-feature-settings: 'tnum';
  font-variant: tabular-nums;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-block;
  line-height: 1;
  position: relative;
  vertical-align: middle;
  top: -0.09em;
`
const CheckBoxInner = styled.span`
  position: relative;
  top: 0;
  left: 0;
  display: block;
  transition: all 0.3s;
  border-collapse: separate;
  width: 1.3em;
  height: 1.3em;
  border: 1px solid ${palette.cornflowerBlue};
  border-radius: 4px;
  background: ${palette.selago};

  &:after {
    position: absolute;
    left: 40%;
    top: 20%;
    display: table;
    width: 0.25em;
    height: 0.35em;
    content: ' ';
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    opacity: 0;
    transform: rotate(45deg) scale(2);
    border: 1.7px solid ${palette.congressBlue};
    border-top: 0;
    border-left: 0;
  }
`

const CheckBoxLabel = styled.span`
  padding-left: 1em;
`

const Input = styled.input`
  display: none;
`

type Props = {
  value?: any
  checked: boolean
  onChange: (e: any) => void
  disabled?: boolean
  className?: string
  children?: any
  [key: string]: any
}

const Checkbox: React.FC<Props> = ({ value, checked, onChange, disabled, children, ...props }) => (
  <Label {...props}>
    <CheckBox>
      <Input
        value={value}
        checked={checked}
        type="checkbox"
        disabled={disabled}
        onChange={(e: any) => {
          e.stopPropagation()
          onChange && onChange(e)
        }}
      />
      <CheckBoxInner className="input-custom" />
      {children && <CheckBoxLabel>{children}</CheckBoxLabel>}
    </CheckBox>
  </Label>
)

export default Checkbox
