import reduce from 'mout/object/reduce'

import { Brand } from '../model/brand'

export const sortBrands = (brands: { brand: Brand }[]) =>
  brands.sort((a, b) => {
    if (!a.brand || !b.brand) {
      return 0
    }
    const brandA = a.brand.code.toUpperCase()
    const brandB = b.brand.code.toUpperCase()
    if (brandA < brandB) return -1
    if (brandA > brandB) return 1
    return 0
  })

export const getGridDimension = (numBrands: number) => {
  if (numBrands <= 18) return 'max-18'
  if (numBrands <= 22) return 'max-22'
  if (numBrands <= 26) return 'max-26'
  if (numBrands <= 29) return 'max-29'
  return ''
}

export const getRecommendedBrands = (recommendedProducts: { brand: Brand }[]) => {
  const brandsObj = reduce(
    recommendedProducts,
    (result: Record<string, Brand>, product: { brand: Brand }) => {
      result[product.brand.code] = product.brand
      return result
    },
    {},
  )

  return Object.keys(brandsObj).map(key => brandsObj[key])
}

export const notInBrands = (brands: { brand: Brand }[]) => (brand: Brand) =>
  !brands.find(cartBrand => cartBrand.brand.code === brand.code)

export const createBrandsQueryParams = (brands: Brand[], isKidCategoryModeEnabled: boolean) => {
  const brandGroups = brands.flatMap(({ group, code }) => (group ? group : code))
  return isKidCategoryModeEnabled
    ? brands.flatMap(brand => brand.juniorBrands)
    : brandGroups.filter(code => !brands.some(brand => brand.juniorBrands.includes(code)))
}

export const getBrandByBrandCode = (brands: Brand[], brandCode: string | undefined | null) =>
  brands.find(b => b.code === brandCode || (brandCode && b.group.includes(brandCode)))

export const isBrandJunior = (brands: Brand[], brandCode: string) =>
  brands.some(b => b.code !== brandCode && b.juniorBrands.includes(brandCode))

export const brandExistAndIsAdult = (
  brands: Brand[],
  brandCode: string | undefined,
  subbrandsToMainBrandsMap: Record<string, string>,
) => {
  const brand = brands.find(b => brandCode && b.code === subbrandsToMainBrandsMap[brandCode])
  const isJunior = brand && brandCode && brand.juniorBrands.includes(brandCode)
  return brand && !isJunior
}

export const getBrandLogo = ({ code }: { code: string }) => `${code}_Logo.svg`

export const getBrandAlternativeLogo = ({ code }: { code: string }) => `${code}_Logo_white.svg`
