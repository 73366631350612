import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useSearchParams } from '../../hooks/useSearchParams'
import { getFluidFontSize, palette } from '../../style/theme'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${palette.tangaroa};
  background-color: ${palette.white};
  font-size: ${getFluidFontSize('15px')};
  border-radius: 0.3em;
  height: min-content;
  align-self: flex-end;
  box-sizing: border-box;
  width: 100%;
`

const SwitchButton = styled.label`
  cursor: pointer;
  text-align: center;

  input {
    display: none;
  }

  flex: 1;
`

const Label = styled.div`
  text-transform: capitalize;
  padding: 0.5em;
  font-size: inherit;
  color: ${palette.tangaroa};
  border-radius: 0.2em;

  input:checked ~ & {
    background-color: ${palette.tangaroa};
    color: ${palette.white};
  }
`

type Props = {
  inputName?: string
}

const AfaPdpTabSwitcher: React.FC<Props> = ({ inputName }) => {
  const { t } = useTranslation()

  const [searchParams, setSearchParams] = useSearchParams()
  const selectedTab = searchParams.get('tab') || 'details'

  const setSelectedTab = (tab: 'details' | 'dropAndSizes') => {
    searchParams.set('tab', tab)
    setSearchParams(searchParams, { replace: true })
  }

  return (
    <Wrapper>
      <SwitchButton>
        <input
          checked={selectedTab === 'details'}
          type="radio"
          name={inputName || 'afaPdpTabSwitcher'}
          onChange={e => setSelectedTab(e.currentTarget.value as 'details' | 'dropAndSizes')}
          value="details"
        />
        <Label>{t('Afa.productDetails')}</Label>
      </SwitchButton>
      <SwitchButton>
        <input
          checked={selectedTab === 'dropAndSizes'}
          type="radio"
          name={inputName || 'afaPdpTabSwitcher'}
          onChange={e => setSelectedTab(e.currentTarget.value as 'details' | 'dropAndSizes')}
          value="dropAndSizes"
        />
        <Label>{t('Afa.dropAndSize')}</Label>
      </SwitchButton>
    </Wrapper>
  )
}

export default AfaPdpTabSwitcher
