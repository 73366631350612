import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled from 'styled-components'

import Loading from '../../components/Loading/index'
import { useSearchParams } from '../../hooks/useSearchParams'
import { useGetProductQuery, useGetRelatedProductsQuery } from '../../services/afaProduct'
import {
  breakpoints,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
  spacing,
} from '../../style/theme'
import AfaPlpProductCard from '../AfaPlp/AfaPlpProductCard'

const Wrapper = styled.div`
  display: block;
  background-color: ${palette.wildSand};
  font-size: ${getFluidSizeWithFullFormula('px', 32, 181, 1366, 3840)};
  padding: 1em;
  width: 100%;
  min-height: 100%;
`

const Title = styled.div`
  color: #031434;
  font-size: ${getFluidSizeWithFullFormula('px', 28, 64, 1366, 3840)};
  gap: ${pxToRem(spacing(5))}rem;
  margin-bottom: 1em;
  text-transform: capitalize;

  @media screen and (max-width: ${breakpoints.L}) {
    font-weight: bold;
  }
`

const Products = styled.div`
  overflow-x: auto;
`

const ProductsInner = styled.div`
  display: grid;
  gap: ${pxToRem(spacing(2))}rem;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(max(309px, 17vw), 1fr));
`

const AfaPdpRelated: React.FC = () => {
  const { t } = useTranslation()

  const { modelCode } = useParams<{ modelCode: string }>()
  const [searchParams] = useSearchParams()

  const selectedColorCode = searchParams.get('color') || undefined
  const productQuery = useGetProductQuery(modelCode)
  const product = productQuery.data
  const productKeylooks = product?.mocos?.[selectedColorCode || '']?.keylooks || []

  const relatedQuery = useGetRelatedProductsQuery(modelCode, selectedColorCode)
  const relatedProducts = relatedQuery?.currentData || []

  return !relatedProducts.length ? null : (
    <Wrapper id="complete-the-look">
      <Title>{t('Afa.completeTheLook')}</Title>
      <Products>
        {relatedQuery.isFetching && <Loading />}
        <ProductsInner>
          {relatedProducts?.map(product => {
            const preselectColorCode = Object.values(product.mocos).find(({ keylooks }) =>
              keylooks?.some(keylook => productKeylooks.includes(keylook)),
            )?.colorCode
            return (
              <AfaPlpProductCard
                key={product.modelCode}
                product={product}
                preselectColorCode={preselectColorCode}
                withLink
              />
            )
          })}
        </ProductsInner>
      </Products>
    </Wrapper>
  )
}

export default AfaPdpRelated
