export const fastArrayConcat = (...arrays: any[]) => {
  // https://dev.to/uilicious/javascript-array-push-is-945x-faster-than-array-concat-1oki

  const saveArray = arrays.map(arr => (Array.isArray(arr) ? arr : [arr]))
  const finalSize = saveArray.reduce((size, arr) => size + arr.length, 0)
  const result = Array(finalSize)

  let lastArrayEnd = 0
  for (let j = 0; j < saveArray.length; j++) {
    for (let i = 0; i < saveArray[j].length; i++) {
      result[lastArrayEnd + i] = saveArray[j][i]
    }
    lastArrayEnd += saveArray[j].length
  }

  return result
}

export const sefelyConvertToArray = (x: any) => [].concat(x).filter(Boolean)

export const quantityExists = <T>(q: T | null | undefined | boolean): q is T =>
  q !== undefined && q !== null && q !== false

export const rgba = (r: number, g: number, b: number, a = 1) => `rgba(${r}, ${g}, ${b}, ${a})`

export const toggleInArray = <T>(array: T[], element: T, isInArray: boolean) =>
  isInArray ? array.filter(item => item !== element) : array.concat(element)

export const isEmptyObj = (obj: Record<string, unknown>, mandatoryKeys?: string[]) =>
  typeof obj === 'undefined' ||
  (Boolean(Object.keys(obj)[0]) === false && obj.constructor === Object) ||
  (mandatoryKeys && !Object.keys(obj).some(k => mandatoryKeys.includes(k)))

export const MODAL_TRANSITION_DURATION_MS = 300

export const isNotUndefined = <T>(x: T | undefined): x is T => x !== undefined

export const isNotNull = <T>(x: T | null): x is T => x !== null

export const keys = Object.keys as <T>(o: T) => Extract<keyof T, string>[]

export const capitalizeFirstLetter = (word: string) => word.charAt(0).toUpperCase() + word.slice(1)
