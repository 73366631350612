import { Radio } from 'antd'
import { RadioChangeEvent } from 'antd/es/radio'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { isPageMatches } from '../../../libs/url'
import { showFooterModal } from '../../../store/app/actions'
import { setSorting, sortCouvetteBy } from '../../../store/couvettes/actions'
import { sortSelector } from '../../../store/couvettes/selectors'
import { useGetInitiativesQuery } from '../../../services/initiatives'
import { slice } from '../../../store/couvettes/slice'
import { searchCouvettes } from '../../../store/actions'
import { filtersSelector } from '../../../store/filters/selectors'
import initiativesActions from '../../../store/initiatives/actions'
import { getEvidenceCategories } from '../FooterNavBrand/FooterNavBrand'
import { useSearchParams } from '../../../hooks/useSearchParams'

const RadioGroup = Radio.Group

const FooterOrderBy = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const filters = useSelector(filtersSelector)
  const plpCategory = filters.category?.active[0] || ''
  const sort = useSelector(sortSelector)

  const { data: initiatives } = useGetInitiativesQuery()

  const [searchParams] = useSearchParams()
  const selectedInitiativeCode = searchParams.get('initiative')

  const selectedInitiative = initiatives?.initiatives.find(
    ({ initiativecode }) => initiativecode === selectedInitiativeCode,
  )
  const selectedInitiativeUPCs = selectedInitiative?.products.map(({ upc }) => upc)
  const selectedInitiativeBrands = selectedInitiative?.brands || []

  const changeSorting = (newSorting: string) => {
    if (selectedInitiative && selectedInitiativeUPCs) {
      dispatch(initiativesActions.setCategoryIsLoading(true))
      dispatch(initiativesActions.setLaunchInitiative(false))
      dispatch(slice.actions.removeCouvettes())
      dispatch(
        searchCouvettes(
          '',
          selectedInitiativeUPCs,
          getEvidenceCategories(selectedInitiativeBrands, plpCategory),
          newSorting,
        ),
      )
    } else if (isPageMatches('plp')) {
      dispatch(sortCouvetteBy(newSorting))
    }
  }

  const onChange = (e: RadioChangeEvent) => {
    const newSorting = e.target.value
    dispatch(setSorting(newSorting))
    changeSorting(newSorting)
    dispatch(showFooterModal(null))
  }

  return (
    <RadioGroup onChange={onChange} value={sort}>
      <Radio value="release">{t('FooterNav.orderBy_release')} </Radio>
      <Radio value="marketing">{t('FooterNav.orderBy_marketing_theme')} </Radio>
      <Radio value="bestseller">{t('FooterNav.orderBy_best_seller')} </Radio>
    </RadioGroup>
  )
}

export default FooterOrderBy
