import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { tokenSelector } from '../store/auth/selectors'
import {
  customerIdSelector,
  customersSelector,
  eventIdSelector,
  eventsSelector,
} from '../store/app/selectors'
import { useHistory } from 'react-router'
import { getEvents } from '../store/app/actions'

export const useEventAndCustomerCheck = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const token = useSelector(tokenSelector)
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)
  const events = useSelector(eventsSelector)
  const customers = useSelector(customersSelector)
  const eventsLoaded = !!events
  const customersLoaded = !!customers

  useEffect(() => {
    if (token && !eventsLoaded) {
      dispatch(getEvents(token))
    }
  }, [token, dispatch, eventsLoaded])

  useEffect(() => {
    if (token && eventsLoaded) {
      if (!eventId) {
        history.push('/events', { replace: true })
        return
      }
      if (eventId && customersLoaded && !customerId) {
        history.push('/customers', { replace: true })
        return
      }
    }
  }, [token, eventsLoaded, eventId, customerId, customersLoaded, history])

  const eventAndCustomerLoaded = useMemo(() => {
    return !!token && eventsLoaded && !!eventId && customersLoaded && !!customerId
  }, [token, eventsLoaded, eventId, customersLoaded, customerId])

  return eventAndCustomerLoaded
}
