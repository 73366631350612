import React, { RefObject, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized'
import styled from 'styled-components'
import { getProductSizesFromMoco } from '../../../libs/productsV2'
import { MocoInCart } from '../../../model/cart'
import { Door as DoorModel } from '../../../model/customer'
import { Moco, Product } from '../../../model/product'
import { toggleAdvancedDoorSelectionAction } from '../../../store/actions'
import { getIsMassiveOrderEnabled } from '../../../store/app/selectors'
import { cartEditableSelector } from '../../../store/cart/selectors'
import { customerDoorsSelector, showNotesSelector } from '../../../store/customer/selectors'
import { advancedDoorSelectionOpenSelector } from '../../../store/pdp/selectors'
import { productDetailsPadding } from '../../../style/CommonComponents'
import {
  breakpointsNoUnit,
  getFluidFontSize,
  getFluidSizeWithFullFormula,
  palette,
} from '../../../style/theme'
import Door from '../../Door'
import MassiveOrderDoor from '../../Door/MassiveOrderDoor'
import ProductDetail from '../../Product/ProductDetail/ProductDetail'
import RCIcon from '../../UI/RCIcon'
import { selectFilteredCheckoutProducts } from '../../../store/checkout/selectors'

const Wrapper = styled.div`
  width: min(45%, 950px);
  display: flex;
  flex-direction: column;
`

const DetailsPanelTitle = styled.div`
  padding: 2vh 0 0.6vh;
  display: flex;
  align-items: center;
  background: ${palette.wildSand};

  svg,
  span {
    cursor: pointer;
  }
`
const DetailsPanelTitleText = styled.span`
  ${productDetailsPadding};
  padding-right: 0.5em;
  text-transform: lowercase;
  font-size: ${getFluidSizeWithFullFormula(
    'px',
    17,
    32,
    breakpointsNoUnit.XS,
    breakpointsNoUnit.XL,
  )};
  font-family: 'Avenir-Roman', sans-serif;
  padding-top: 0.5em;
  padding-bottom: 0.5em;

  &:first-letter {
    text-transform: uppercase;
  }
`

const PanelContent = styled.div`
  overflow: auto;
  transition: flex 0.2s;
  &.closed {
    flex: 0;
  }
  &.opened {
    flex: 1;
  }
  .ReactVirtualized__Grid__innerScrollContainer {
    margin-top: 2vh;
  }
`

const DetailPanelContent = styled.div`
  max-height: 0;
  overflow: auto;
  transition: max-height 0.2s ease;

  &.opened {
    max-height: 100%;
  }
`

const MassiveTitle = styled.div`
  font-weight: 900;
`

const MassiveOrder = styled.div``

const DoorsList = styled(List)`
  .ReactVirtualized__List {
    display: flex;
    justify-content: center;
  }
  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }
`

const DoorsInDetailsPanel = styled.div`
  padding-top: 3em;
  padding-top: min(3em, 2vh);
  width: 100%;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-items: flex-start;
`

function getSpaceFactor(numberOfSizes: number) {
  switch (numberOfSizes) {
    case 1:
      return numberOfSizes
    case 2:
      return numberOfSizes * 1.1
    case 3:
      return numberOfSizes
    case 4:
    default:
      return numberOfSizes * 0.85
  }
}

function getRowHeight(
  doorInDetailsRef: RefObject<HTMLElement>,
  height: number,
  numberOfSizes: number,
  massiveOrder = false,
) {
  const el = doorInDetailsRef.current
  const r = el && el.getBoundingClientRect()
  const h = el
    ? r?.height
    : height /
      (5 - (massiveOrder ? getSpaceFactor(numberOfSizes) * 0.8 : getSpaceFactor(numberOfSizes)))

  return h && Math.ceil(h)
}

const DoorWrapperStyled = styled.div`
  width: 100%;
  padding: 1em auto;
  padding-top: 1em;
  ${productDetailsPadding};
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.65);
`

const DoorsPanelTitle = styled.div<{ isClosed: boolean }>`
  width: ${props => (props.isClosed ? '100%' : 'auto')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${palette.alto};
  border-radius: 10px;
  position: relative;
  margin: 0 0.3vw 1.17vh;
  padding: 0.5em 1em;
  background: white;
  z-index: 1;
  cursor: pointer;
  height: 3em;
  .arrow-icon {
    fill: ${palette.congressBlue};
  }
`

const PanelTitleWrapper = styled.div<{ isClosed: boolean }>`
  ${props =>
    props.isClosed
      ? `
flex: 0;
align-items: flex-end;
display: flex;
`
      : ''}
`

const DoorsPanelTitleText = styled.span`
  color: ${palette.congressBlue};
  font-size: ${getFluidFontSize('14px')};
`

const MassiveOrderDoors: React.FC<{
  moco: Moco
  mocoInCart?: MocoInCart
  activeDoors: DoorModel[]
  title: string
}> = ({ activeDoors, moco, mocoInCart, title }) => {
  const doorIds = activeDoors
    .filter(activeDoor => moco.enabledDoors.includes(activeDoor.id))
    .map(door => door.id)

  return (
    <DoorWrapperStyled className="door">
      <MassiveTitle className="door-head-title door-head-title-massive">{title}</MassiveTitle>
      {Object.values(moco.sizes).map(size => {
        const cartItemSize = mocoInCart && mocoInCart.sizes[size.upc]

        return (
          <MassiveOrderDoor
            doorIds={doorIds}
            size={size}
            cartItemSize={cartItemSize}
            key={size.upc}
            enabledDoors={moco.enabledDoors}
            brandCode={moco.brandCode}
          />
        )
      })}
    </DoorWrapperStyled>
  )
}

type DoorWrapperInnerProps = {
  door: DoorModel
  style?: Record<string, string | number>
  moco: Moco
  mocoInCart?: MocoInCart
  showNotes: boolean
  model: Product
}

const DoorWrapperInner: any = (
  { door, style, moco, mocoInCart, showNotes, model }: DoorWrapperInnerProps,
  ref: any,
) => {
  const sizes = getProductSizesFromMoco([moco])
  const sizesWithBrandCode = sizes.map(s => ({ ...s, brandCode: model.brandCode }))

  return door.enabled || door.mainDoor ? (
    <DoorWrapperStyled ref={ref} className="door" style={style}>
      <Door
        door={door}
        sizes={sizesWithBrandCode}
        cartItems={mocoInCart}
        showNotes={showNotes}
        enabledDoors={moco.enabledDoors}
        brandCode={moco.brandCode}
      />
    </DoorWrapperStyled>
  ) : null
}
const DoorWrapper = React.forwardRef<
  RefObject<typeof DoorWrapperStyled | null>,
  DoorWrapperInnerProps
>(DoorWrapperInner)

DoorWrapper.displayName = 'DoorWrapper'

type Props = {
  model: Product
  moco: Moco
  mocoInCart?: MocoInCart
}

export const PDPRightCol: React.FC<Props> = ({ moco, model, mocoInCart }) => {
  const { t } = useTranslation()
  const doorInDetailsRef = useRef(null)
  const dispatch = useDispatch()
  const isMassiveOrderEnabled = useSelector(getIsMassiveOrderEnabled)
  const showNotes = useSelector(showNotesSelector)
  const editable = useSelector(cartEditableSelector)
  const advancedDoorSelectionOpen = useSelector(advancedDoorSelectionOpenSelector)
  const doors = useSelector(customerDoorsSelector)
  const checkoutProductsFiltered = useSelector(selectFilteredCheckoutProducts)

  const defaultDoor = doors.find(door => door.mainDoor)
  const activeDoors = doors.filter(door => door.enabled)
  const activeDoorsPlusMain = doors.filter(door => door.enabled || door.mainDoor)

  const activeDoorsPlusMainSorted = activeDoorsPlusMain.sort((a, b) => {
    if (a.mainDoor) {
      return -1
    } else if (b.mainDoor) {
      return 1
    } else {
      return Number(a.id) - Number(b.id)
    }
  })

  const showMassiveOrderDoor = isMassiveOrderEnabled && editable && activeDoorsPlusMain.length

  const massiveOrderTitle = `${t('ProductPage.allSelectedDoors')} (${activeDoors.length}/${
    doors.length
  })`

  const cartProductsQuantity = checkoutProductsFiltered.reduce(
    (tot, cartProduct) => tot + cartProduct.quantity,
    0,
  )

  const confirmedQty = checkoutProductsFiltered.reduce(
    (tot, cartProduct) => tot + (cartProduct.confirmedQuantity || 0),
    0,
  )

  return (
    <Wrapper className="pdp-right-col">
      <DetailsPanelTitle onClick={() => dispatch(toggleAdvancedDoorSelectionAction())}>
        <DetailsPanelTitleText>{t('ProductPage.item_mainDetails')}</DetailsPanelTitleText>
        {advancedDoorSelectionOpen && <RCIcon arrow type="down" />}
        {!advancedDoorSelectionOpen && <RCIcon arrow type="up" />}
      </DetailsPanelTitle>

      <DetailPanelContent className={advancedDoorSelectionOpen ? 'closed' : 'opened'}>
        <ProductDetail model={model} moco={moco} />
      </DetailPanelContent>

      {!(editable && activeDoorsPlusMain.length > 1 && advancedDoorSelectionOpen) && (
        <DoorsInDetailsPanel>
          {showMassiveOrderDoor && (
            <MassiveOrderDoors
              activeDoors={activeDoors}
              moco={moco}
              mocoInCart={mocoInCart}
              title={massiveOrderTitle}
            />
          )}
          {(editable || cartProductsQuantity > 0 || confirmedQty > 0) && defaultDoor && (
            <DoorWrapper
              door={defaultDoor}
              moco={moco}
              mocoInCart={mocoInCart}
              showNotes={showNotes}
              model={model}
              ref={doorInDetailsRef}
            />
          )}
        </DoorsInDetailsPanel>
      )}

      {(editable || cartProductsQuantity > 0 || confirmedQty > 0) &&
        activeDoorsPlusMain.length > 1 && (
          <>
            <PanelContent
              className={[advancedDoorSelectionOpen ? 'opened' : 'closed', 'doors-panel'].join(' ')}
            >
              <MassiveOrder className="massive-order">
                {showMassiveOrderDoor && (
                  <MassiveOrderDoors
                    activeDoors={activeDoors}
                    moco={moco}
                    mocoInCart={mocoInCart}
                    title={massiveOrderTitle}
                  />
                )}
              </MassiveOrder>
              <AutoSizer style={{ height: '100%', width: '100%' }}>
                {({ width, height }: any) => {
                  const cache = new CellMeasurerCache({
                    defaultWidth: width,
                    minWidth: width,
                    minHeight: getRowHeight(
                      doorInDetailsRef,
                      height,
                      Object.values(moco.sizes).length,
                      true,
                    ),
                    defaultHeight: getRowHeight(
                      doorInDetailsRef,
                      height,
                      Object.values(moco.sizes).length,
                      true,
                    ),
                    fixedHeight: false,
                    fixedWidth: true,
                  })
                  return (
                    <DoorsList
                      rowCount={activeDoorsPlusMainSorted.length}
                      deferredMeasurementCache={cache}
                      rowHeight={cache.rowHeight}
                      height={height}
                      width={width}
                      rowRenderer={({ index, style, key, parent }: any) => (
                        <CellMeasurer
                          cache={cache}
                          columnIndex={0}
                          key={key}
                          parent={parent}
                          rowIndex={index}
                        >
                          <DoorWrapper
                            key={key}
                            door={activeDoorsPlusMainSorted[index]}
                            style={style}
                            moco={moco}
                            mocoInCart={mocoInCart}
                            showNotes={showNotes}
                            model={model}
                          />
                        </CellMeasurer>
                      )}
                    />
                  )
                }}
              </AutoSizer>
            </PanelContent>
            <PanelTitleWrapper isClosed={!advancedDoorSelectionOpen}>
              <DoorsPanelTitle
                isClosed={!advancedDoorSelectionOpen}
                onClick={() => dispatch(toggleAdvancedDoorSelectionAction())}
                className="doors-panel-title"
              >
                <DoorsPanelTitleText>
                  {t(
                    `ProductPage.item_${
                      advancedDoorSelectionOpen
                        ? 'closeAdvanceDoorsSelection'
                        : 'advanceDoorsSelection'
                    }`,
                  )}{' '}
                  ({activeDoorsPlusMain.length})
                </DoorsPanelTitleText>
                <RCIcon arrow type={advancedDoorSelectionOpen ? 'down' : 'up'} />
              </DoorsPanelTitle>
            </PanelTitleWrapper>
          </>
        )}
    </Wrapper>
  )
}

export default PDPRightCol
