import { useEffect, useState } from 'react'
import { breakpoints } from '../style/theme'

const useMediaQuery = (type: 'min' | 'max', breakpoint: 'XS' | 'S' | 'M' | 'L' | 'XL') => {
  const query = `(${type}-width: ${breakpoints[breakpoint]})`
  const [matches, setMatches] = useState(window.matchMedia(query).matches)

  useEffect(() => {
    window.matchMedia(query).addEventListener('change', e => setMatches(e.matches))
  }, [query])

  return matches
}

export default useMediaQuery
