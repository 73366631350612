import { format, isPast } from 'date-fns'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import RCIcon from '../../components/UI/RCIcon'
import { Initiative } from '../../model/initiative'
import { GlassBox } from '../../style/DigitalEventsCommonComponents'
import {
  breakpoints,
  getFluidFontSize,
  getFluidFontSizeDe,
  palette,
  pxToRem,
  spacing,
} from '../../style/theme'

const Wrapper = styled(GlassBox)`
  margin-right: ${pxToRem(spacing(4))}rem;
  position: relative;
  border-radius: ${pxToRem(spacing(2))}rem;
  overflow: hidden;
  padding: ${pxToRem(spacing(1.5))}rem;
`

const StyledLink = styled(Link)`
  display: grid;
  grid-template-columns: 3fr 8fr;
  font-size: ${getFluidFontSize('11px')};
  gap: ${pxToRem(spacing(4))}rem;
  height: 190px;

  @media (max-width: ${breakpoints.M}) {
    grid-template-columns: 4fr 8fr;
  }

  @media (max-width: ${breakpoints.S}) {
    grid-template-columns: 4fr 9fr;
    gap: ${pxToRem(spacing(2))}rem;
    height: 86px;
  }
`

const ImgWrapper = styled.div`
  border-radius: ${pxToRem(spacing(1))}rem 0 0 ${pxToRem(spacing(1))}rem;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: ${palette.bigStone};
`

const Img = styled.img`
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
`

const BottomVideo = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: ${pxToRem(spacing(1))}rem;

  img {
    width: ${pxToRem(spacing(2.5))}rem;
    height: ${pxToRem(spacing(2.5))}rem;
  }
`

const InitiativeDate = styled.div`
  margin-bottom: ${pxToRem(spacing(1))}rem;
  color: ${palette.white};
  opacity: 0.6;
`

const Text = styled.div`
  color: ${palette.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Title = styled.h3`
  font-size: ${getFluidFontSizeDe(18, 28)};
  margin-bottom: 0;
  font-weight: 500;
  color: ${palette.white};
  display: inline;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`

const Description = styled.div`
  font-size: ${getFluidFontSizeDe(15, 21)};
  margin-bottom: 0;
  opacity: 0.7;
  line-height: 1.2;

  @media (max-width: ${breakpoints.S}) {
    line-height: 1;
  }
`

const InitiativeTile: React.FC<{ initiative: Initiative }> = ({ initiative }) => (
  <Wrapper className="initiative-box">
    <StyledLink to={`/digital-events/initiative/${initiative.id}`}>
      <ImgWrapper>
        <Img src={initiative.posterImage} alt={initiative.title} />
        {initiative.length && (
          <BottomVideo>
            <RCIcon type="icon-podcast"></RCIcon>
          </BottomVideo>
        )}
      </ImgWrapper>
      <Text>
        {initiative.startDate && initiative.endDate && !isPast(new Date(initiative.endDate)) && (
          <InitiativeDate>{format(new Date(initiative.startDate), 'MMM d Y')}</InitiativeDate>
        )}
        <Title>{initiative.title}</Title>
        <Description>{initiative.description}</Description>
      </Text>
    </StyledLink>
  </Wrapper>
)

export default InitiativeTile
