import React from 'react'
import styled from 'styled-components'

import { breakpoints, getFluidFontSize, palette } from '../../style/theme'
import CheckIcon from '../UI/CheckIcon'

export const TextBadgeWrapper = styled.span`
  position: absolute;
  left: 70%;
  transform: translateX(-50%);
  top: -0.6rem;
  background: ${palette.butterScotch};
  border-radius: 24px;
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 3px 5px;
  margin-top: -10px;
  margin-right: -20px;
  @media screen and (max-width: ${breakpoints.M}) {
    margin-top: 0;
    margin-right: 0;
    border-radius: 12px;
  }
`

export const TextBadgeContainer = styled.div`
  display: flex;
  align-items: center;
`

export const TextBadgeIcon = styled.div`
  position: relative;
  width: 0.8rem;
  height: 0.8rem;
  background: white;
  border-radius: 50%;
  margin-right: 5px;
  @media screen and (max-width: ${breakpoints.M}) {
    height: 10px;
    width: 10px;
  }
  svg {
    position: absolute;
    left: 0;
    top: 0;
  }
`

export const TextBadgeMessage = styled.div`
  text-transform: uppercase;
  white-space: pre;
  color: white;
  font-size: ${getFluidFontSize('10px')};
  font-weight: 900;
`

const TextBadge: React.FC<{ message: string }> = ({ message }) => (
  <TextBadgeWrapper>
    <TextBadgeContainer>
      <TextBadgeIcon>
        <CheckIcon colorCode={palette.butterScotch} />
      </TextBadgeIcon>
      <TextBadgeMessage>{message}</TextBadgeMessage>
    </TextBadgeContainer>
  </TextBadgeWrapper>
)

export default TextBadge
