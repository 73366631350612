import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import RCIcon from '../../../../../components/UI/RCIcon'
import afaCartSummaryActions from '../../../../../store/afaCartSummary/actions'
import { filtersAppliedSelector } from '../../../../../store/afaCartSummary/selectors'
import { duration, palette } from '../../../../../style/theme'
import { DataCartSummary } from '../../AfaCartSummary'

export const ShowFilters = styled.div`
  position: absolute;
  top: 110%;
  left: 0;
  background-color: ${palette.white};
  padding: 1.5em;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 51, 17, 0.25);
  border: 1px solid ${palette.platinum};
  opacity: 0;
  pointer-events: none;
  transition: all ${duration.enteringScreen}ms;
  transition: opacity ${duration.enteringScreen}ms, max-height 0 ${duration.enteringScreen}ms;
  max-height: 0;
  z-index: 2;

  &.open {
    opacity: 1;
    pointer-events: initial;
    max-height: 100vh;
  }
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 40em;
  padding-bottom: 1.5em;
  border-bottom: 1px solid ${palette.santasGray};
`
export const Title = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  color: ${palette.tangaroa};
`
export const ResetButton = styled.button`
  font-size: 1em;
  text-decoration: underline;
  position: relative;
  top: 0.75em;
`
export const Categories = styled.div`
  display: grid;
  grid-template-columns: 40% 1fr;
  grid-template-rows: 1fr 30%;
  padding: 1.5em 0;
`
export const CategoriesList = styled.ul`
  grid-column: 1/2;
  grid-row: 1/2;
`
export const CategoriesItem = styled.li`
  width: 100%;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.8em;
  font-size: 1.1em;
  & span.avd {
    text-transform: uppercase;
  }
  &.hovered {
    color: ${palette.santasGray};
    fill: ${palette.santasGray};
  }
`
export const OptionsList = styled.ul`
  grid-column: 2/3;
  grid-row: 1/2;
  min-height: 18em;
`
export const OptionCategory = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-transform: capitalize;
  padding-left: 5em;
  font-size: 1.1em;
  margin-bottom: 0.8em;
  & input {
    accent-color: ${palette.black};
    margin-right: 1em;
    width: 1.2em;
    height: 1.2em;
  }
  & label.avd {
    text-transform: uppercase;
  }
`
export const ShowResults = styled.div`
  grid-column: 2/3;
  grid-row: 2/3;
  margin-left: 5em;
  & div {
    margin-top: -0.8em;
    position: relative;
    top: 1.5em;
    text-transform: uppercase;
    text-align: center;
    border-radius: 4px;
    padding: 0.6em 0;
    background-color: ${palette.tangaroa};
    color: ${palette.white};
  }
`
type Props = {
  options: DataCartSummary[]
  className?: string
}

const AfaCartSummaryFilters: React.FC<Props> = ({ options, className }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const filtersApplied = useSelector(filtersAppliedSelector)

  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined)

  const categoriesToShow = useMemo(() => {
    return options.filter(
      ({ data, categoryKey }) =>
        (categoryKey === 'brand' && data.length > 1) ||
        (categoryKey !== 'brand' && data.length > 0),
    )
  }, [options])

  const categoryToShow = categoriesToShow.find(
    ({ categoryKey }) => categoryKey === selectedCategory,
  )

  useEffect(() => {
    if (!selectedCategory && categoriesToShow.length > 0) {
      setSelectedCategory(categoriesToShow[0].categoryKey)
    }
  }, [selectedCategory, categoriesToShow])

  const getCheckedLabels = (categoryKey: string, idProp: string) =>
    filtersApplied[categoryKey]?.some((id: string) => id === idProp) || false

  const toggleCheckLabel = (categoryKey: string, idProp: string) => {
    if (getCheckedLabels(categoryKey, idProp)) {
      dispatch(
        afaCartSummaryActions.setFilters({
          categoryKey,
          filtersApplied: filtersApplied[categoryKey]?.filter(id => id !== idProp) || [],
        }),
      )
    } else {
      dispatch(
        afaCartSummaryActions.setFilters({
          categoryKey,
          filtersApplied: (filtersApplied[categoryKey] || []).concat(idProp) || [],
        }),
      )
    }
  }

  return (
    <ShowFilters className={className} onClick={e => e.stopPropagation()}>
      <Header>
        <Title>{t('GenericWords.filters')}</Title>
        <ResetButton onClick={() => dispatch(afaCartSummaryActions.resetFilters())}>
          {t('Afa.resetAllFilters')}
        </ResetButton>
      </Header>
      <Categories>
        <CategoriesList>
          {categoriesToShow.map(({ categoryKey }) => {
            const isCategoryAvd = categoryKey.toLowerCase().includes('avd')
            return (
              <CategoriesItem
                key={categoryKey}
                className={selectedCategory === categoryKey ? 'hovered' : ''}
                onClick={() => setSelectedCategory(categoryKey)}
              >
                <span className={isCategoryAvd ? 'avd' : ''}>{t(`Afa.${categoryKey}`)}</span>
                <RCIcon arrow type="right" />
              </CategoriesItem>
            )
          })}
        </CategoriesList>
        <OptionsList>
          {selectedCategory !== undefined &&
            categoryToShow &&
            categoryToShow.data
              .filter(({ quantity }) => quantity > 0)
              .map(({ elementKey }) => {
                const isElementAvd = elementKey.toLowerCase().includes('avd')
                return (
                  <OptionCategory
                    onClick={() => dispatch(afaCartSummaryActions.setFilterID(elementKey))}
                    key={elementKey}
                  >
                    <input
                      checked={getCheckedLabels(categoryToShow.categoryKey, elementKey)}
                      onChange={() => toggleCheckLabel(categoryToShow.categoryKey, elementKey)}
                      type="checkbox"
                      id={elementKey}
                      name="options"
                      value={elementKey}
                    />
                    {isElementAvd ? (
                      <label className="avd" htmlFor={elementKey}>
                        {elementKey}
                      </label>
                    ) : (
                      <label htmlFor={elementKey}>{t(`Afa.${elementKey.toLowerCase()}`)}</label>
                    )}
                  </OptionCategory>
                )
              })}
        </OptionsList>
        <ShowResults>
          <div onClick={() => dispatch(afaCartSummaryActions.setShowFilters(false))}>
            {t('Afa.showResults')}
          </div>
        </ShowResults>
      </Categories>
    </ShowFilters>
  )
}

export default AfaCartSummaryFilters
