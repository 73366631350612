import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { getLabel } from '../../../../libs/filters'
import { CheckoutFilters } from '../../../../model/checkout'
import { brandsSelector } from '../../../../store/brands/selectors'
import checkoutActions from '../../../../store/checkout/actions'
import { palette, pxToRem, spacing } from '../../../../style/theme'
import RCIcon from '../../../../components/UI/RCIcon'

const Wrapper = styled.div`
  padding: ${pxToRem(8)}rem ${pxToRem(10)}rem;
  border-radius: 4px;
  border: solid 1px ${palette.cornflowerBlue};
  background-color: ${palette.whiteGradient};
  display: flex;
  align-items: center;

  .close-icon {
    margin-left: ${pxToRem(spacing(2))}rem;
    cursor: pointer;
  }
`

type Props = {
  filterToShow: {
    filterKey: keyof CheckoutFilters
    selectedId: string
    selected: boolean
    label: string
    optionName: string
    quantity?: number
  }
}

const FilterChip: React.FC<Props> = ({ filterToShow }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const brands = useSelector(brandsSelector)
  const { filterKey, label, optionName, selectedId } = filterToShow

  const labelToShow = getLabel({
    filterName: filterKey,
    optionName,
    brands,
    optionLabel: label,
    t,
  })

  const removeFilter = () => {
    dispatch(checkoutActions.toggleFilter(filterKey, selectedId))
    dispatch(checkoutActions.applyFilters())
  }

  return (
    <Wrapper>
      <span>{labelToShow}</span>
      <RCIcon type="close" iconClass="close-icon" onClick={() => removeFilter()} />
    </Wrapper>
  )
}

export default FilterChip
