import { getProductsListWidth, WhiteboardProductType } from '../../../../../model/whiteboard'
import { useDispatch, useSelector } from 'react-redux'
import {
  getItemsToUnpin,
  getSelectedBoardItems,
  getSelectedWhiteboard,
  sportFiltersAreLoadingSelector,
  whiteboardFiltersSelector,
} from '../../../../../store/whiteboard/selectors'
import React, { useEffect, useRef, useState } from 'react'
import { convertPxToPercentage } from '../../../../../libs/whiteboard'
import whiteboardActions from '../../../../../store/whiteboard/actions'
import { useTranslation } from 'react-i18next'
import RCIcon from '../../../../../components/UI/RCIcon'
import { useGetPlpProductsQuery } from '../../../../../services/afaProducts'
import classnames from 'classnames'
import { useSearchParams } from '../../../../../hooks/useSearchParams'
import { v4 as uuidv4 } from 'uuid'
import {
  Wrapper,
  ClearIcon,
  CategoryWrapper,
  InputWrapper,
  Input,
  ScrollWrapper,
  TitleWrapper,
  Grid,
  Header,
  BackHeader,
  Item,
  DeleteIcon,
  MobileDraggableImage,
  PinnedItemsWrapper,
  PinnedItemsTitleWrapper,
  PinnedItemsTitle,
  PinnedItemsEdit,
  PinnedGrid,
  CustomButtonCancel,
  ItemsWrapper,
  ItemName,
  ItemWrapper,
  SearchResult,
  NonDraggableImage,
  IconSearch,
  CustomButtonsGroup,
  FiltersTitle,
  FilterCategoriesWrapper,
  FilterCategory,
  FiltersApply,
  FiltersApplyButton,
} from './style'
import { useScroll } from '../../../../../hooks/useScroll'
import {
  useGetPinnedItemsForWhiteboardMutation,
  usePinItemToWhiteBoardMutation,
} from '../../../../../services/whiteboard'
import { ButtonSave } from '../../../../../components/AfaCommonComponents'
import Loading from '../../../../../components/Loading'
import { breakpointsCross, getFluidSizeWithFullFormula } from '../../../../../style/theme'
import useViewportUpdate from '../useViewportUpdate'
import { useLazyGetProductQuery } from '../../../../../services/afaProduct'
import { sortCodes } from '../../../../../helpers/sortCodes'
import { getProductImageUrl } from '../../../../../libs/productImages'
import noBackground from '../../../../../components/icons/noBackground.svg'
import { viewportSelector } from '../../../../../store/viewport/selectors'
import { getFilteredProductsBySport } from '../../../../../helpers/genericHelper'
import useGetFiltersSports from '../useGetFiltersSports'

type Props = {
  selectedCategory?: string
  boardRef?: HTMLDivElement | null
}

const ProductsList = ({ selectedCategory, boardRef }: Props) => {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const family = searchParams.get('family')
  const productsQuery = useGetPlpProductsQuery()
  const products = productsQuery?.data?.products || []
  const whiteboardFilters = useSelector(whiteboardFiltersSelector)
  const filteredProducts = whiteboardFilters.size
    ? getFilteredProductsBySport(products, whiteboardFilters)
    : products
  const [, handleScroll, wrapperRef] = useScroll('top', true)
  const filterSportAreLoading = useSelector(sportFiltersAreLoadingSelector)
  const selectedWhiteboard = useSelector(getSelectedWhiteboard)
  const itemsToUnpin = useSelector(getItemsToUnpin)
  const getPinnedItemsForWhiteboard = useGetPinnedItemsForWhiteboardMutation()
  const pinnedItems = getPinnedItemsForWhiteboard.data?.items
  const pinnedItemsFiltered = pinnedItems?.filter(item => item.invalid !== true)
  const [isDragging, setIsDragging] = useState(false)

  const { width: viewportWidth } = useSelector(viewportSelector)

  const isFilterSelected = selectedCategory === 'filter'

  const is4K = viewportWidth > parseInt(breakpointsCross.XL.max)

  const getProduct = useLazyGetProductQuery()

  const [filter, setFilter] = useState('')
  const ref = useRef<HTMLInputElement>(null)

  const pinnedItemsWithSKU = pinnedItemsFiltered
    ?.map(item => {
      return {
        ...item,
        id: `${item.productNumber}-${item.colorCode}`,
      }
    })
    .filter(item => itemsToUnpin.every(skuCode => skuCode !== item.id))
    ?.slice()
    .sort((prodA, prodB) => sortCodes(prodA.productNumber, prodB.productNumber))
    .filter(
      item =>
        item.colorName.toLowerCase().includes(filter.toLowerCase()) ||
        item.productName.toLowerCase().includes(filter.toLowerCase()) ||
        item.colorCode.toLowerCase().includes(filter.toLowerCase()) ||
        item.productNumber.toLowerCase().includes(filter.toLowerCase()),
    )

  useEffect(() => {
    return () => {
      localStorage.removeItem('scrollTop')
    }
    // dependencies cause items flickering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const productsWithFamilies =
    filteredProducts
      .filter(
        product =>
          product.category.toLowerCase() === selectedCategory?.toLowerCase() && !!product.family,
      )
      .reduce((res, product) => {
        const family = product.family || ''
        const mocos = Object.values(product.mocos).map(moco => ({
          name: product.name,
          color: moco.colorCode,
          modelCode: moco.modelCode,
          image: moco.catalogImgPath,
          id: `${moco.modelCode}-${moco.colorCode}`,
          order: `${product.plpOrder}-${moco.order}`,
        }))

        res[family] = [...(res[family] || []), ...mocos]

        return res
      }, {} as Record<string, { name: string; color: string; modelCode: string; image: string; id: string; order: string }[]>) ||
    {}

  const productsWithFamiliesArray = Object.entries(productsWithFamilies)
  const searchResults = productsWithFamiliesArray.reduce((res, item) => {
    res += item[1].length
    return res
  }, 0)

  const boardItems = useSelector(getSelectedBoardItems)

  const [cursorDeltaPosition, setCursorDeltaPosition] = useState({ x: 0, y: 0 })
  const [edit, setEdit] = useState(false)
  const { t } = useTranslation()
  const [pinItemsToWhiteboard, pinItemsToWhiteboardResult] = usePinItemToWhiteBoardMutation()
  const { viewportUpdated } = useViewportUpdate()

  const onDrag = (e: React.DragEvent) => {
    if (!!e.clientX && !!e.clientY) {
      e.currentTarget.setAttribute('attr-x', `${e.clientX}`)
      e.currentTarget.setAttribute('attr-y', `${e.clientY}`)
    }
  }

  const onDragStart = (e: React.DragEvent) => {
    const { clientX, clientY } = e
    const { left, top } = e.currentTarget.getBoundingClientRect()
    setCursorDeltaPosition({
      x: left - clientX,
      y: top - clientY,
    })
  }

  const onTouchStart = (e: any) => {
    const wrapper = document.getElementById('pinned-item-wrapper') as HTMLDivElement
    if (wrapper?.style) {
      wrapper.style.overflow = 'hidden'
    }
    setIsDragging(true)
    const { clientX, clientY } = e.targetTouches[0]
    e.targetTouches[0].target.style.zIndex = 1000
    e.targetTouches[0].target.style.position = 'fixed'
    const { left, top } = e.target.getBoundingClientRect()
    const scrollTop = Number(localStorage.getItem('scrollTop'))
    setCursorDeltaPosition({
      x: clientX - left,
      y: clientY - top + scrollTop,
    })
  }

  const onTouchMove = (e: any) => {
    const touchLocation = e.targetTouches[0]
    const { x, y } = cursorDeltaPosition
    const image = touchLocation.target
    image.style.left = touchLocation.pageX - x + 'px'
    image.style.top = touchLocation.pageY - y + 'px'
    image.setAttribute('attr-x', `${touchLocation.pageX - x}`)
    image.setAttribute('attr-y', `${touchLocation.pageY - y}`)
  }

  const addItemToBoard = async (
    element: HTMLElement,
    modelCode: string,
    colorCode: string,
    fromTouch?: boolean,
  ) => {
    if (boardRef && wrapperRef.current) {
      const wrapperBoundingRect = wrapperRef.current.getBoundingClientRect()
      dispatch(whiteboardActions.setItemIsBeingAddedToTheBoard(true))
      const { left, top, bottom, right } = boardRef.getBoundingClientRect()
      /**
       * elementX/elementY posizione del cursore
       */
      const elementX = Number(element.getAttribute('attr-x'))
      const elementY = Number(element.getAttribute('attr-y'))
      /**
       * x, y posizione del cursore rispetto all'item selezionato
       */
      const { x, y } = cursorDeltaPosition
      const leftOffset = convertPxToPercentage(elementX - left + x)
      const topOffset = convertPxToPercentage(elementY - top + y)
      const deltaX =
        convertPxToPercentage(elementX) +
        convertPxToPercentage(x) +
        (fromTouch ? 5 : 10) -
        convertPxToPercentage(right)
      const deltaY =
        convertPxToPercentage(elementY) +
        convertPxToPercentage(y) -
        (fromTouch ? 5 : 10) -
        convertPxToPercentage(bottom)
      const renderedItemHeight = convertPxToPercentage(150)

      const xPosition = deltaX > 0 ? convertPxToPercentage(right) - 5 : leftOffset
      const yPosition =
        deltaY > 0
          ? convertPxToPercentage(bottom) - Number((renderedItemHeight * 2).toFixed(2))
          : topOffset

      if (
        elementX >= left &&
        elementX <= right &&
        elementY >= top &&
        elementY <= bottom &&
        elementX >= wrapperBoundingRect.right
      ) {
        const { data: product } = await getProduct(modelCode)

        if (!product) {
          return
        }

        const moco = product.mocos[colorCode]
        const itemToAdd: WhiteboardProductType = {
          id: uuidv4(),
          selected: false,
          product: {
            image: moco.catalogImgPath,
            category: selectedCategory || '',
            subCategory: family || '',
            colorCode,
            productName: product.name,
            itemInCart: false,
            productNumber: moco.modelCode,
            colorName: moco.colorDescription,
            suggestedPrice: Number(moco.srPrice),
            wholesalePrice: Number(moco.whsPrice),
            materialCode: moco.sizes ? Object.values(moco.sizes)?.[0]?.materialCode : '',
            invalid: boardItems.find(item => item.product.image === moco.catalogImgPath)?.product
              .invalid,
          },
          settings: {
            style: {
              zIndex: 1,
              width: 10,
              height: 10,
            },
            position: {
              x: xPosition - (fromTouch ? convertPxToPercentage(x) : 0),
              y: yPosition - (fromTouch ? convertPxToPercentage(y) : 0),
            },
          },
          value: '',
          type: 'items',
        }

        dispatch(whiteboardActions.addItemToBoard(itemToAdd))
      }
    }
  }

  const onTouchEnd = async (event: any, modelCode: string, colorCode: string) => {
    const wrapper = document.getElementById('pinned-item-wrapper') as HTMLDivElement

    if (wrapper?.style) {
      wrapper.style.overflow = ''
    }

    setIsDragging(false)
    //TODO: gestire dimensioni immagini
    const image = event.target
    image.style.zIndex = 1
    image.style.position = 'absolute'
    image.style.left = ''
    image.style.top = ''

    await addItemToBoard(image, modelCode, colorCode, true)
  }

  const onDragEnd = async (event: React.DragEvent, modelCode: string, colorCode: string) => {
    await addItemToBoard(event.currentTarget as HTMLElement, modelCode, colorCode)
  }

  const handleCancel = () => {
    dispatch(whiteboardActions.emptyPinnedItemsList())
    setEdit(false)
  }

  const handleConfirm = () => {
    if (pinnedItemsFiltered) {
      const productsToUnpin = pinnedItemsFiltered.filter(product => {
        const skuCode = `${product.productNumber}-${product.colorCode}`
        return itemsToUnpin.includes(skuCode)
      })

      pinItemsToWhiteboard({
        whiteboardId: selectedWhiteboard?.key || '',
        products: productsToUnpin,
      })
    }
    setEdit(false)
  }

  const setFamily = (family?: string) => {
    family ? searchParams.set('family', family) : searchParams.delete('family')
    setSearchParams(searchParams, { replace: true })
  }

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    setTimeoutId(
      setTimeout(() => {
        if (selectedCategory !== 'pinned') {
          filter.length > 2
            ? searchParams.set('searchTerm', filter)
            : searchParams.delete('searchTerm')
          setSearchParams(searchParams, { replace: true })
        }
      }, 500),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  const [showGroupped, setShowGroupped] = useState<boolean>(true)

  useEffect(() => {
    if (filter.length > 2) {
      setTimeout(() => {
        setShowGroupped(false)
      }, 1500)
    } else if (family) {
      setShowGroupped(false)
    }
    if (!family && filter.length < 3) {
      setShowGroupped(true)
    }
  }, [filter, family])

  useEffect(() => {
    if (wrapperRef.current) {
      const parent = wrapperRef.current.parentNode as HTMLElement
      const footer = document.getElementsByTagName('footer')[0] as HTMLElement
      const footerRect = footer.getBoundingClientRect()
      const board = document.getElementById('board') as HTMLElement
      const { top } = board.getBoundingClientRect()
      wrapperRef.current.style.height = isFilterSelected
        ? '100%'
        : `${footerRect.top - top - footerRect.height}px`
      parent.style.height = isFilterSelected
        ? getFluidSizeWithFullFormula('px', 510, 840, 1366, 3840)
        : `${footerRect.top - top}px`
      parent.style.width = `${getProductsListWidth()}px`
      //parent.style.marginLeft = `-${getProductsListWidth()}px`
      parent.style.marginTop = isFilterSelected ? 'auto' : 'unset'
      parent.style.transform = isFilterSelected && is4K ? 'translateY(-32px)' : 'unset'
    }
  }, [wrapperRef, viewportUpdated, selectedCategory, isFilterSelected, is4K])

  useEffect(() => {
    setFilter('')
    if (selectedCategory !== 'pinned') {
      setEdit(false)
    }
  }, [selectedCategory])

  const clearInput = () => {
    setFilter('')
    searchParams.delete('searchTerm')
    setSearchParams(searchParams, { replace: true })
  }

  const handleScrollPinned = (e: any) => {
    const scroll = e.target['scrollTop']
    localStorage.setItem('scrollTop', scroll.toString())
  }

  useEffect(() => {
    const wrapper = document.getElementById('pinned-item-wrapper') as HTMLDivElement

    /*    const handleTouchStart = () => {
      //wrapper.style.overflow = 'hidden'
    }*/

    const handleTouchEnd = () => {
      wrapper.style.cssText = ''
    }

    if (selectedCategory === 'pinned') {
      wrapper.addEventListener('scroll', handleScrollPinned)
      //wrapper.addEventListener('touchstart', handleTouchStart)
      wrapper.addEventListener('touchend', handleTouchEnd)
    }

    return () => {
      if (wrapper) {
        wrapper.removeEventListener('scroll', handleScrollPinned)
        //wrapper.removeEventListener('touchstart', handleTouchStart)
        wrapper.removeEventListener('touchend', handleTouchEnd)
      }
    }
  }, [selectedCategory])

  const sports = useGetFiltersSports(selectedCategory)
  const [filtersApplied, setFiltersApplied] = useState<Set<string>>(new Set())

  const updateFilters = (name: string) => {
    const updatedSet = new Set(filtersApplied)
    if (updatedSet.has(name)) {
      updatedSet.delete(name)
    } else {
      updatedSet.add(name)
    }
    setFiltersApplied(updatedSet)
  }

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setFilter(e.currentTarget.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && ref.current) {
      ref.current.blur()
    }
  }

  const resetParams = () => {
    searchParams.delete('searchTerm')
    searchParams.delete('category')
    searchParams.delete('family')
    setSearchParams(searchParams, { replace: true })
  }

  return (
    <Wrapper open={selectedCategory}>
      <ScrollWrapper
        edit={edit}
        ref={wrapperRef}
        className={classnames({ isDragging })}
        // using localstorage for scroll handle and not query param as it is causing a bug with unpinned items. This issue with query params occurs when the pinned bar is opened and list of pinned is long. In the moment when the scroll parameter is changed, the pinned list in Redux is being cleared and it makes impossible to edit pinned items (see Sidebar component, useEffect hook)
        onScroll={handleScroll}
        family={!!family}
        isFilterSelected={isFilterSelected}
      >
        {!edit && !family && !isFilterSelected && (
          <InputWrapper>
            <Input
              ref={ref}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={filter}
              placeholder={t('Whiteboard.searchProducts')}
            />
            <IconSearch />
            {filter.length > 0 && (
              <ClearIcon onClick={clearInput}>
                <RCIcon type="icon-close" size="100%" />
              </ClearIcon>
            )}
          </InputWrapper>
        )}
        {selectedCategory === 'pinned' ? (
          <PinnedItemsWrapper id="pinned-item-wrapper" edit={edit}>
            <PinnedItemsTitleWrapper>
              {edit ? (
                <PinnedItemsTitle>{t('Whiteboard.editItems')}</PinnedItemsTitle>
              ) : (
                <PinnedItemsTitle>
                  {t('Whiteboard.savedItems')} <span>({pinnedItemsWithSKU?.length})</span>
                </PinnedItemsTitle>
              )}
              {!edit && (
                <PinnedItemsEdit
                  onClick={() => {
                    setEdit(true)
                    setFilter('')
                  }}
                >
                  {t('Checkout.ImportExport.EditItem')}
                </PinnedItemsEdit>
              )}
            </PinnedItemsTitleWrapper>

            <PinnedGrid className={classnames({ families: !family })} edit={edit}>
              {pinnedItemsWithSKU?.length ? (
                pinnedItemsWithSKU.map(
                  ({ id, productNumber, colorCode, image, productName, colorName }) => {
                    return (
                      <ItemWrapper key={id}>
                        <Item
                          className={classnames({
                            'draggable-disabled': boardItems.find(item => item.id === id),
                          })}
                          draggable={!boardItems.find(item => item.id === id)}
                          onDragStart={onDragStart}
                          onDrag={onDrag}
                          onDragEnd={async event => {
                            await onDragEnd(event, productNumber, colorCode)
                          }}
                        >
                          <MobileDraggableImage
                            onTouchCancel={async event => {
                              await onTouchEnd(event, productNumber, colorCode)
                            }}
                            onTouchMove={onTouchMove}
                            onTouchEnd={async event => {
                              await onTouchEnd(event, productNumber, colorCode)
                            }}
                            onTouchStart={onTouchStart}
                            loading="lazy"
                            draggable={!boardItems.find(item => item.id === id)}
                            src={
                              image
                                ? getProductImageUrl({ path: image, imwidth: 100 })
                                : noBackground
                            }
                            alt=""
                            isImageValid={!!image?.length}
                          />
                          <NonDraggableImage
                            draggable={false}
                            loading="lazy"
                            src={
                              image
                                ? getProductImageUrl({ path: image, imwidth: 100 })
                                : noBackground
                            }
                            alt=""
                            isImageValid={!!image?.length}
                          />
                          {edit && (
                            <DeleteIcon
                              onClick={() => dispatch(whiteboardActions.removePinnedItem(id))}
                            >
                              <RCIcon type="icon-trash" />
                            </DeleteIcon>
                          )}
                        </Item>
                        <ItemName>
                          <div>{productName}</div>
                          <span>{colorName}</span>
                        </ItemName>
                      </ItemWrapper>
                    )
                  },
                )
              ) : filter.length ? (
                <>
                  <span></span>
                  <SearchResult className="centered">
                    {t('Whiteboard.noSearchResults')}
                  </SearchResult>
                  <span></span>
                </>
              ) : (
                <div></div>
              )}
            </PinnedGrid>
            {edit && (
              <CustomButtonsGroup>
                <CustomButtonCancel onClick={handleCancel}>
                  {t('Checkout.ImportExport.ModalCancel')}
                </CustomButtonCancel>
                <ButtonSave
                  className={itemsToUnpin.length ? '' : 'disabled'}
                  onClick={handleConfirm}
                >
                  {t('Checkout.ImportExport.ModalConfirm')}
                </ButtonSave>
              </CustomButtonsGroup>
            )}

            {getPinnedItemsForWhiteboard.isLoading && <Loading isFullPage={false} />}
          </PinnedItemsWrapper>
        ) : (
          <ItemsWrapper className={isFilterSelected ? 'filter' : ''}>
            {isFilterSelected && (
              <>
                <FiltersTitle>
                  <h3>{is4K ? t('GenericWords.filters') : t('GenericWords.filter')}</h3>
                  <h4>{t('Whiteboard.sport')}</h4>
                </FiltersTitle>
                {filterSportAreLoading ? (
                  <FilterCategoriesWrapper>
                    <Loading isFullPage={false} />
                  </FilterCategoriesWrapper>
                ) : (
                  <FilterCategoriesWrapper>
                    {sports.map(sport => {
                      return (
                        <FilterCategory
                          onClick={() => updateFilters(sport)}
                          checked={filtersApplied.has(sport)}
                          key={sport}
                        >
                          <div />
                          <span>{t(`Afa.${sport.toLowerCase()}`)}</span>
                        </FilterCategory>
                      )
                    })}
                  </FilterCategoriesWrapper>
                )}
                <FiltersApply>
                  <FiltersApplyButton
                    onClick={() => {
                      dispatch(whiteboardActions.resetWBFilters())
                      dispatch(whiteboardActions.setWBFilters([...filtersApplied]))
                      resetParams()
                      !is4K &&
                        dispatch(whiteboardActions.setSelectedCategory({ category: 'filter' }))
                    }}
                  >
                    {t('Checkout.Process.ApplyDate')}
                  </FiltersApplyButton>
                </FiltersApply>
              </>
            )}

            {!searchResults && !productsQuery.isFetching && !isFilterSelected && (
              <SearchResult className="centered">{t('Whiteboard.noSearchResults')}</SearchResult>
            )}

            {searchResults > 0 && !showGroupped && !family && (
              <SearchResult>
                {t('Whiteboard.searchResults')} {filter} ({searchResults})
              </SearchResult>
            )}

            {productsQuery.isFetching ? (
              <Loading isFullPage={false} />
            ) : (
              productsWithFamiliesArray
                .filter(([category]) => (family ? category === family : true))
                .map(([category, items]) => {
                  const sortedItems = items.sort((a, b) => {
                    const [aBefore, aAfter] = a.order.split('-')
                    const [bBefore, bAfter] = b.order.split('-')
                    if (parseInt(aBefore) === parseInt(bBefore)) {
                      return parseInt(aAfter) - parseInt(bAfter)
                    }
                    return parseInt(aBefore) - parseInt(bBefore)
                  })

                  const itemsToShow = showGroupped ? sortedItems.slice(0, 3) : sortedItems

                  const fillArray = showGroupped
                    ? new Array(3 - itemsToShow.length)
                        .fill('')
                        .map((_, index) => <span key={index} />)
                    : []

                  return (
                    <CategoryWrapper key={category}>
                      {showGroupped && (
                        <Header>
                          <TitleWrapper>
                            <h4>
                              {!edit ? (
                                <>
                                  <span>{category} </span>
                                  <small onClick={() => setFamily(category)}>
                                    {t('Whiteboard.viewAll')}
                                  </small>
                                </>
                              ) : (
                                t('Whiteboard.editItems')
                              )}
                            </h4>
                          </TitleWrapper>
                        </Header>
                      )}
                      {family && (
                        <BackHeader onClick={() => setFamily()}>
                          <RCIcon type="icon-chevron-left" />
                          {category}
                        </BackHeader>
                      )}
                      <Grid className={classnames({ families: showGroupped })}>
                        {itemsToShow.map(({ image, id, name, color, modelCode }) => (
                          <Item
                            key={id}
                            className={classnames({
                              'draggable-disabled': boardItems.find(item => item.id === id),
                            })}
                            draggable={!boardItems.find(item => item.id === id)}
                            onDragStart={onDragStart}
                            onDrag={onDrag}
                            onDragEnd={async event => {
                              await onDragEnd(event, modelCode, color)
                            }}
                          >
                            <MobileDraggableImage
                              loading="lazy"
                              onTouchMove={onTouchMove}
                              onTouchEnd={async event => {
                                await onTouchEnd(event, modelCode, color)
                              }}
                              onTouchStart={onTouchStart}
                              className={classnames({ whitFamily: showGroupped })}
                              draggable={!boardItems.find(item => item.id === id)}
                              src={
                                image
                                  ? getProductImageUrl({ path: image, imwidth: 100 })
                                  : noBackground
                              }
                              alt=""
                              isImageValid={!!image?.length}
                            />
                            <NonDraggableImage
                              loading="lazy"
                              draggable={false}
                              className={classnames({ whitFamily: showGroupped })}
                              src={
                                image
                                  ? getProductImageUrl({ path: image, imwidth: 100 })
                                  : noBackground
                              }
                              alt=""
                              isImageValid={!!image.length}
                            />
                            {!showGroupped && (
                              <div className="product-name">
                                <div>{name || modelCode}</div>
                                <div>{color}</div>
                              </div>
                            )}
                            {edit && (
                              <DeleteIcon
                                onClick={() => dispatch(whiteboardActions.removePinnedItem(id))}
                              >
                                <RCIcon type="icons-trash" />
                              </DeleteIcon>
                            )}
                          </Item>
                        ))}
                        {fillArray}
                        {showGroupped && (
                          <RCIcon onClick={() => setFamily(category)} type="icon-chevron-right" />
                        )}
                      </Grid>
                    </CategoryWrapper>
                  )
                })
            )}
          </ItemsWrapper>
        )}
      </ScrollWrapper>

      {(pinItemsToWhiteboardResult.isLoading || productsQuery.isFetching) && !isFilterSelected && (
        <Loading isFullPage={false} />
      )}
    </Wrapper>
  )
}

export default ProductsList
