import React, { ReactElement } from 'react'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { getBrandByBrandCode } from '../../libs/brand'
import { Moco } from '../../model/product'
import { brandsSelector } from '../../store/brands/selectors'
import { GlassBox, maxHeight, XSHeight } from '../../style/DigitalEventsCommonComponents'
import { breakpointsCross, getFluidFontSize, pxToRem, spacing } from '../../style/theme'
import { millisToMinutesAndSeconds } from './helper'
import { getProductImageAngledWithFallback } from '../../libs/productImages'

const MocoWrapper = styled.div`
  * {
    color: white !important;
  }
  display: flex;
  justify-content: space-between;
  padding: ${pxToRem(spacing(2))}rem;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  @media (max-height: ${XSHeight}) {
    min-height: 100px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  max-height: 20vh;
`

const BrandTitle = styled.span`
  text-transform: uppercase;
  font-size: ${getFluidFontSize('14px')};
  @media (max-height: ${XSHeight}) {
    font-size: ${getFluidFontSize('11px')};
  }
`

const TimeBadge = styled(GlassBox)`
  border: none;
  @media (max-height: ${XSHeight}) {
    display: none;
  }
  background-color: rgba(255, 255, 255, 0.15);
  margin-top: ${pxToRem(spacing(1))}rem;
  max-width: ${pxToRem(spacing(8))}rem;
  padding: ${pxToRem(spacing(1) / 2)}rem ${pxToRem(spacing(1))}rem;
  text-align: center;
  border-radius: ${pxToRem(spacing(2))}rem;
  &::before {
    backdrop-filter: none; // for some reason it causes sometimes a non-consistent blurring of the text issue
    border-radius: ${pxToRem(spacing(2))}rem;
    padding: ${pxToRem(spacing(1) / 2)}rem ${pxToRem(spacing(1))}rem;
  }
  overflow: hidden;
  font-size: ${getFluidFontSize('14px')};
`

const MocoData = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-height: ${maxHeight}) {
    justify-content: center;
  }
`

const StyledGlassBox = styled(GlassBox)`
  margin-left: ${pxToRem(spacing(4))}rem;
  @media (max-width: ${breakpointsCross.M.max}), (max-height: ${maxHeight}) {
    padding: 0;
  }
  height: 100%;
  width: 100%;
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  @media (max-height: ${maxHeight}) {
    width: 40%;
  }
`

const MocoCode = styled.span`
  opacity: 60%;
  font-size: ${getFluidFontSize('14px')};
  @media (max-height: ${XSHeight}) {
    font-size: ${getFluidFontSize('11px')};
  }
`

export const MocoImage: React.FC<{
  moco?: Moco
  className?: string
  onClick?: () => void
  angle?: 'front' | 'quarter'
}> = ({ moco, className, onClick, angle = 'front' }) => {
  if (!moco) return null
  return (
    <img
      className={className}
      src={getProductImageAngledWithFallback({ angle, catalogImages: moco.catalogImages })}
      onClick={onClick}
      alt=""
    />
  )
}

const ModelLabel = styled.span`
  text-transform: capitalize;
  font-size: ${getFluidFontSize('18px')};
`

const BrandAndModelComponent: React.FC<{
  moco?: Moco
  styleName?: string
  children?: ReactElement<any, any>
  className?: string
}> = ({ moco, styleName, children, className }) => {
  const brands = useSelector(brandsSelector)
  const brand = getBrandByBrandCode(brands, moco?.brandCode)

  return (
    <div className={className}>
      <ModelLabel>{styleName?.toLocaleLowerCase()}</ModelLabel>
      <div>
        <BrandTitle>{brand?.brand}</BrandTitle>{' '}
        <MocoCode>{moco?.mocoCode.replaceAll('__', '-')}</MocoCode>
      </div>
      {children}
    </div>
  )
}

export const BrandAndModel = BrandAndModelComponent

const MocoCard: React.FC<{ moco?: Moco; time: number; styleName?: string; modelCode: string }> = ({
  moco,
  time,
  styleName,
  modelCode,
}) => {
  if (moco) {
    return (
      <StyledGlassBox>
        <MocoWrapper>
          <MocoData>
            <BrandAndModel moco={moco} styleName={styleName} />
            <TimeBadge>
              <span>{millisToMinutesAndSeconds(time)}</span>
            </TimeBadge>
          </MocoData>
          <ImageWrapper>
            <MocoImage moco={moco} />
          </ImageWrapper>
        </MocoWrapper>
      </StyledGlassBox>
    )
  } else return <span>Not found: {modelCode}</span>
}

export default withTranslation('common')(MocoCard)
