import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Brand } from '../../../model/brand'
import { Product } from '../../../model/product'
import { palette } from '../../../style/theme'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: ${palette.black};
  text-transform: none;
`

export const ColorsInfo = styled.div`
  display: flex;
  flex-direction: row;
`

Wrapper.displayName = 'FooterNavPdpInfo'

const Divider = styled.span`
  margin: 0 1em;
`

type Props = {
  pdpCouvette: Product
  brand?: Brand
}

const FooterNavPdpInfo: React.FC<Props> = ({ brand, pdpCouvette: { mocos } }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <ColorsInfo>
        {Object.keys(mocos).length}
        &nbsp;
        {t(Object.keys(mocos).length > 1 ? 'FooterNav.pdp_info_colors' : 'FooterNav.pdp_info_color')
          .trim()
          .toLowerCase()}
      </ColorsInfo>
      <Divider>|</Divider>
      <span className="brand">{brand?.brand}</span>
    </Wrapper>
  )
}

export default FooterNavPdpInfo
