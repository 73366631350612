import React, { useRef } from 'react'
import styled from 'styled-components'

import {
  DigitalEventsBoxBorderRadius,
  duration,
  palette,
  pxToRem,
  spacing,
  zIndex,
} from '../../style/theme'

const Wrapper = styled.div`
  position: absolute;
  top: -${pxToRem(spacing(2))}rem;
  right: 0;
  transform: translateY(0);
  background-color: ${palette.white};
  padding: ${pxToRem(spacing(3))}rem;
  border-radius: ${DigitalEventsBoxBorderRadius};
  transition: all ${duration.enteringScreen}ms;
  opacity: 0;
  pointer-events: none;
  z-index: ${zIndex.subMenu};
  overflow: hidden;

  &.is-open {
    transform: translateY(-100%);
    opacity: 1;
    pointer-events: initial;
  }
`

const SubMenu: React.FC<{ open: boolean; className?: string }> = ({
  open,
  children,
  className = '',
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  return (
    <Wrapper
      ref={wrapperRef}
      className={open ? 'is-open ' + className : className}
      aria-hidden={!open}
      onClick={e => e.stopPropagation()}
    >
      {children}
    </Wrapper>
  )
}

export default SubMenu
