import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ContentState } from '../../model/content'
import { resetForNewClient } from '../extraActions'

const DEFAULT_STATE: ContentState = {
  adv: {},
  loading: false,
  loaded: false,
}

export const slice = createSlice({
  name: 'content',
  initialState: DEFAULT_STATE,
  reducers: {
    setAdvContent: (
      state,
      { payload }: PayloadAction<{ contents: Record<string, any>; append: boolean }>,
    ) => {
      state.adv = payload.append ? { ...state.adv, ...payload.contents } : payload.contents
      state.loaded = true
      state.loading = false
    },
    loadingAdv: state => {
      state.loaded = false
      state.loading = true
    },
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, () => DEFAULT_STATE)
  },
})

export default slice.reducer
