import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import React from 'react'
import styled from 'styled-components'

import { getFluidFontSize, palette } from '../style/theme'

const ToggleCheckbox = styled(Checkbox)`
  .ant-checkbox-group {
    width: 100%;
    .ant-checkbox-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;
      padding: 32px;
      border: 2px solid ${palette.wildSand};
      border-radius: 4px;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
      margin: 0 0 2px 0;
      .ant-checkbox-disabled {
        opacity: 0;
      }
      .ant-checkbox {
        width: 83px;
      }
      > span:not(:first-child) {
        width: 100%;
        overflow: hidden;
      }
    }
  }
  .ant-checkbox-input {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  .ant-checkbox-inner {
    cursor: pointer;
    width: 83px;
    height: 33px;
    background-color: ${palette.white};
    border: 1px solid ${palette.alto};
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    display: block;
    border-radius: 100px;
    position: relative;
  }
  .ant-checkbox-inner:before {
    content: 'OFF';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    font-size: 0.6rem;
  }
  .ant-checkbox-inner:after {
    content: '';
    position: absolute;
    top: -2px;
    left: -1px;
    width: 35px;
    height: 35px;
    background: ${palette.white};
    border-radius: 35px;
    opacity: 1;
    border: 1px solid ${palette.alto};
    transform: none;
  }
  .ant-checkbox-input:checked + .ant-checkbox-inner {
    background: ${palette.congressBlue};
  }
  .ant-checkbox-input:checked + .ant-checkbox-inner:after {
    left: calc(100% + 1px);
    transform: translateX(-100%);
  }
  .ant-checkbox-input:checked + .ant-checkbox-inner:before {
    content: 'ON';
    display: block;
    position: absolute;
    font-size: 0.6rem;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    color: ${palette.white};
  }
  .ant-checkbox-inner:active:after {
    width: 35px;
  }
`

const ToggleLabel = styled.span`
  white-space: nowrap;
  font-size: ${getFluidFontSize('0.75rem')};
`

type Props = {
  onChange: (e: CheckboxChangeEvent) => void
  checked: boolean
  label?: string
}

export const OnOffToggle: React.FC<Props> = ({ onChange, checked, label }) => {
  return (
    <ToggleCheckbox
      onChange={e => {
        e.stopPropagation()
        onChange(e)
      }}
      checked={checked}
    >
      {label && <ToggleLabel onClick={e => e.stopPropagation()}>{label}</ToggleLabel>}
    </ToggleCheckbox>
  )
}
