import { format } from 'date-fns'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { StarsAssortmentPayload, StarsCartMode, StarsState } from '../../model/stars'
import { resetForNewClient } from '../extraActions'

const DEFAULT_STATE: StarsState = {
  assortment: {},
  filteredAssortment: {},
  mode: 'units',
  selectedMonth: format(new Date(), 'yyyy_MM'),
  selectedClustersIds: [],
  showInOut: false,
  loadedBrandCodes: [],
  loadedFilteredBrandCodes: [],
  loaded: false,
}

export const slice = createSlice({
  name: 'stars',
  initialState: DEFAULT_STATE,
  reducers: {
    setAssortment: (state, { payload }: PayloadAction<StarsAssortmentPayload>) => {
      state.assortment = { ...state.assortment, ...payload.starsAssortment }
      state.loadedBrandCodes = [...state.loadedBrandCodes, ...payload.brandCodes]
    },
    setFilteredAssortment: (state, { payload }: PayloadAction<StarsAssortmentPayload>) => {
      state.filteredAssortment = payload.starsAssortment
      state.loadedFilteredBrandCodes = payload.brandCodes
    },
    setMode: (state, { payload }: PayloadAction<StarsCartMode>) => {
      state.mode = payload
    },
    setSelectedMonth: (state, { payload }: PayloadAction<string>) => {
      state.selectedMonth = payload
    },
    resetStarsFilters: state => {
      state.selectedMonth = format(new Date(), 'yyyy_MM')
      state.selectedClustersIds = []
      state.showInOut = false
    },
    setSelectedClustersIds: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedClustersIds = payload
    },
    setShowInOut: (state, { payload }: PayloadAction<boolean>) => {
      state.showInOut = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, () => DEFAULT_STATE)
  },
})

export default slice.reducer
