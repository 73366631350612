import { Collapse } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import Checkbox from '../../../../styleguide/Checkbox'

import { Brand } from '../../../../model/brand'
import { BrandCode } from '../../../../model/model'
import { getFluidFontSize, palette } from '../../../../style/theme'

const { Panel } = Collapse

const BrandsSelectorWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 0 max(1.8vw, 20px);
`

const StyledCollapse = styled(Collapse as any)`
  min-width: 15em;
  position: relative;

  .ant-collapse-item {
    .ant-collapse-header {
      width: 100%;
      color: ${palette.congressBlue};
      font-size: ${getFluidFontSize('14px')};
      padding: 0.5em 40px 0.5em 1em;
      border-color: ${palette.alto};
      box-shadow: 0 1px 2px rgb(0 0 0 / 15%);

      .anticon {
        left: auto;
        right: 12px;
      }
    }

    &:not(.ant-collapse-item-active) {
      .anticon {
        transform: translateY(-50%) rotate(-90deg);
      }
    }

    .ant-collapse-content-box {
      max-height: calc(10vh + 12vw);
      padding: 0;
      overflow: auto;
      font-size: ${getFluidFontSize('11px')};
      position: absolute;
      width: 100%;
      z-index: 1;
      box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
      background-color: ${palette.white};
      display: flex;
      flex-direction: column;
    }
  }
`

const BrandsList = styled.div`
  flex: 1;
  padding: 0 1em;
  overflow: auto;

  > *:not(:last-child) {
    border-bottom: 1px solid ${palette.alto};
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
`

const optionStyle = css`
  padding: 0.75em 1em;
  font-weight: 400;
  white-space: nowrap;

  .ant-checkbox {
    font-size: inherit;

    .ant-checkbox-inner {
      border: 1px solid ${palette.cornflowerBlue};
      border-radius: 4px;
      background: ${palette.selago};
      &:after {
        border: 1px solid ${palette.congressBlue};
        border-top: 0;
        border-left: 0;
        margin-left: -1px;
        margin-top: -1px;
      }
    }
  }
`

const SelectAllRow = styled.div`
  ${optionStyle}
  padding: 0.75em 2em;
  border-bottom: 1px solid ${palette.gray};
`

const Option = styled(Checkbox)`
  ${optionStyle}
  display: block;
`
Option.displayName = 'Option'

type Props = {
  brands: Pick<Brand, 'code' | 'group' | 'brand'>[]
  onChangeSelectedBrands: (selectedBrands: BrandCode[]) => void
  selectedBrands: string[]
  className?: string
  useBrandGroup?: boolean
}

const TableCartAnalyticsBrands: React.FC<Props> = ({
  brands,
  onChangeSelectedBrands,
  selectedBrands,
  className,
  useBrandGroup,
}) => {
  const { t } = useTranslation()

  return (
    <BrandsSelectorWrapper className={className}>
      <StyledCollapse>
        <Panel key="item_filterby_brands" header={t('Filters.item_filterby_brands')}>
          {!!brands.length && (
            <SelectAllRow className="select-all-row">
              <Checkbox
                checked={brands.every(brand =>
                  selectedBrands.includes(useBrandGroup ? brand.group.join(',') : brand.code),
                )}
                onChange={e =>
                  onChangeSelectedBrands(
                    e.target.checked
                      ? brands.map(({ code, group }) => (useBrandGroup ? group.join(',') : code))
                      : [],
                  )
                }
              >
                {t('Filters.item_select_all')}
              </Checkbox>
            </SelectAllRow>
          )}
          <BrandsList>
            {brands.map(({ brand, code, group }) => (
              <Option
                className="brand-option"
                key={code}
                checked={selectedBrands.includes(useBrandGroup ? group.join(',') : code)}
                onChange={(e: any) => {
                  onChangeSelectedBrands(
                    e.target.checked
                      ? selectedBrands.concat(useBrandGroup ? group.join(',') : code)
                      : selectedBrands.filter(
                          brand => brand !== (useBrandGroup ? group.join(',') : code),
                        ),
                  )
                }}
              >
                {brand}
              </Option>
            ))}
          </BrandsList>
        </Panel>
      </StyledCollapse>
    </BrandsSelectorWrapper>
  )
}

export default TableCartAnalyticsBrands
