import styled from 'styled-components'
import React from 'react'
import RCIcon from '../../../../components/UI/RCIcon'
import { useDispatch, useSelector } from 'react-redux'
import {
  breakpointsNoUnit,
  getFluidSize,
  getFluidSizeWithFullFormula,
  minSupportedWidth,
  palette,
  pxToRem,
  spacing,
} from '../../../../style/theme'
import { useTranslation } from 'react-i18next'
import checkoutActions from '../../../../store/checkout/actions'
import {
  checkoutCurrentPageSelector,
  checkoutStepSelector,
} from '../../../../store/checkout/selectors'
import { selectedEventSelector } from '../../../../store/app/selectors'
import { isPageMatches } from '../../../../libs/url'

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${getFluidSizeWithFullFormula('px', 2, 12, minSupportedWidth, breakpointsNoUnit.M)};
`

const HeaderCheckout = styled.div`
  padding: ${getFluidSize(`${pxToRem(10)}rem`, 15)} 2vw;
  background-color: ${palette.white};
  color: ${palette.congressBlue};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.disable {
    padding: ${getFluidSize(`${pxToRem(20)}rem`, 15)} 2vw;
  }
`

const HeaderLeftElement = styled.div`
  font-weight: 900;
  font-family: 'Avenir-Heavy', sans-serif;
  font-size: ${pxToRem(12)}rem;
`

const HeaderButtonWrapper = styled.div`
  display: flex;
  gap: ${pxToRem(16)}rem;
`

const Button = styled.button<{
  currentPage?: boolean
}>`
  padding: ${pxToRem(10)}rem ${pxToRem(16)}rem;
  font-size: ${pxToRem(10)}rem;
  font-family: 'Avenir-Roman', sans-serif;
  border-radius: 4px;
  cursor: pointer;
  ${({ currentPage }) =>
    currentPage
      ? `background-color: ${palette.selago}; border: 1px solid ${palette.cornflowerBlue};`
      : ''}
`

const ButtonLabel = styled.span`
  margin-left: ${pxToRem(spacing(1))}rem;
  font-size: ${pxToRem(10)}rem;
  text-transform: uppercase;
`

const SubHeader: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const event = useSelector(selectedEventSelector)
  const page = useSelector(checkoutCurrentPageSelector)
  const isCheckoutPage = isPageMatches('checkout')
  const step = useSelector(checkoutStepSelector)
  const isThankYouPage = isCheckoutPage && step === 2
  const isAddressPage = isCheckoutPage && step === 1

  return (
    <HeaderWrapper>
      <HeaderCheckout className={isThankYouPage || isAddressPage ? 'disable' : ''}>
        <HeaderLeftElement>{event?.description}</HeaderLeftElement>

        {isCheckoutPage && !isThankYouPage && !isAddressPage && (
          <HeaderButtonWrapper>
            <Button
              currentPage={page === 'process'}
              onClick={() => dispatch(checkoutActions.setPage('process'))}
            >
              <RCIcon type="cart" />
              <ButtonLabel>{t('Checkout.Header.Process')}</ButtonLabel>
            </Button>
            <Button
              currentPage={page === 'import-export'}
              onClick={() => dispatch(checkoutActions.setPage('import-export'))}
            >
              <RCIcon type="checkout-import-export" />
              <ButtonLabel>{t('Checkout.Header.ImportExport')}</ButtonLabel>
            </Button>
          </HeaderButtonWrapper>
        )}
      </HeaderCheckout>
    </HeaderWrapper>
  )
}

export default SubHeader
