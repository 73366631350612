import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import ArrowRight from '../../components/icons/ArrowRight'
import { disableKidCategoryMode, enableKidCategoryMode } from '../../store/app/actions'
import { GlassBoxNoBackground } from '../../style/DigitalEventsCommonComponents'
import { breakpoints, getFluidFontSizeDe, getFluidSize, pxToRem, spacing } from '../../style/theme'

const Wrapper = styled(GlassBoxNoBackground)`
  padding: 0;
`

const StyledLink = styled(Link)`
  padding: ${pxToRem(spacing(4.5))}rem ${pxToRem(spacing(4))}rem ${pxToRem(spacing(4.5))}rem
    ${pxToRem(spacing(6))}rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: inherit;
  text-transform: capitalize;
  font-size: ${getFluidFontSizeDe(18, 26)};
  line-height: ${getFluidSize('16px')};

  &:active,
  &:hover {
    color: inherit;
  }

  @media (max-width: ${breakpoints.S}) {
    padding-top: ${pxToRem(spacing(2.5))}rem;
    padding-bottom: ${pxToRem(spacing(2.5))}rem;
  }
`

const ArrowRightStyled = styled(ArrowRight)`
  height: 0.8em;
  width: auto;
`

const CategoryLink: React.FC<{
  label: string
  slug: string
  category: 'sun' | 'optical' | 'electronic'
  isJunior?: boolean
}> = ({ label, slug, category, isJunior }) => {
  const dispatch = useDispatch()

  return (
    <Wrapper>
      <StyledLink
        to={`/de/ipad-only/table/single-brand/${slug}/${category}`}
        onClick={() => dispatch(isJunior ? enableKidCategoryMode() : disableKidCategoryMode())}
      >
        {label}
        <ArrowRightStyled />
      </StyledLink>
    </Wrapper>
  )
}
export default CategoryLink
