export const hideSubtitles = (hide: boolean) => {
  sessionStorage.setItem('subtitles-disabled', hide.toString())
}

export const isSubtitleHidden = () => {
  return sessionStorage.getItem('subtitles-disabled') === true.toString()
}

export const extractModelFromModelCode = (model: string): string[] => {
  if (!model) return [] as string[]
  const splittedMc = model.split(' ')
  const code = splittedMc.pop()
  return [splittedMc.join(' '), code ? code : '']
}

export function millisToMinutesAndSeconds(millis: number) {
  const minutes = Math.floor(millis / 60000)
  const seconds = +((millis % 60000) / 1000).toFixed(0)
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
}
