import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { getBrandByBrandCode } from '../../../libs/brand'
import { CartProduct } from '../../../model/cart'
import { PdpContent } from '../../../model/content'
import { CouvetteVideoState } from '../../../model/couvettes'
import { Moco, Product } from '../../../model/product'
import {
  changeSelectedSku,
  getSeeThroughWithoutContent,
  setSeethroughModalVisibility,
  setSeeThroughSelectedMoco,
} from '../../../store/actions'
import { brandsSelector } from '../../../store/brands/selectors'
import { cartProductsSelector, convertModelIntoMocosInCart } from '../../../store/cart/selectors'
import {
  isSeethroughModalVisibleSelector,
  pdpMocosSortedSelector,
  pdpSeeThemOnAvailableForMocosSelector,
  seeThroughSelectedMocoSelector,
  virtualMirrorAvailabilitySelector,
} from '../../../store/pdp/selectors'
import { wishlistProductsSelector } from '../../../store/wishlist/selectors'
import { breakpoints, palette } from '../../../style/theme'
import { transparentScrollbarCss } from '../../CommonComponents'
import ThumbsNavigation from '../../Couvettes/Couvette/TableThumbsNavigation/ThumbsNavigation'
import ProductModalView from '../../Product/ProductModalView'
import PDPLeftCol from '../PDPLeftCol'
import PDPRightCol from '../PDPRightCol'

const PdpContainer = styled.div<{ noMargin: boolean }>`
  position: absolute;
  bottom: ${props => (props.noMargin ? '0vh' : '5vh')};
  left: 0;
  width: 100%;
  max-height: ${props => (props.noMargin ? '100%' : 'calc(100% - 5vh)')};
  overflow: auto;
  padding-top: 5vh;
  padding-bottom: 2vh;
  ${transparentScrollbarCss}

  .ant-collapse-content-box {
    padding: 0 !important;
  }
`

const PdpSingleProduct = styled.div`
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  border-radius: 1rem;
  background: ${palette.white};
  margin: 2vw 5vw 0 20vw;
  overflow: hidden;
  width: 75vw;
  height: 65vh;

  &.is-invisibile {
    visibility: hidden;
  }

  @media screen and (max-height: ${breakpoints.M}) {
    height: 75vh;
  }

  @media screen and (max-width: ${breakpoints.L}) {
    margin-left: 17vw;
  }
`
PdpSingleProduct.displayName = 'PdpSingleProduct'

type Props = {
  closeModal?: () => void
  code: string
  content?: PdpContent
  model: Product
  couvetteVideo?: CouvetteVideoState
  isWallPdp?: boolean
}

const TablePdpContent: React.FC<Props> = ({
  code,
  model,
  closeModal,
  content,
  couvetteVideo,
  isWallPdp = false,
}) => {
  const isSeethroughModalVisible = useSelector(isSeethroughModalVisibleSelector)
  const seeThemOnAvailableForMocos = useSelector(pdpSeeThemOnAvailableForMocosSelector)
  const mocos = useSelector(pdpMocosSortedSelector)
  const brands = useSelector(brandsSelector)
  const cartProducts = useSelector(cartProductsSelector)
  const wishlistProducts = useSelector(wishlistProductsSelector)
  const virtualMirrorAvailability = useSelector(virtualMirrorAvailabilitySelector)
  const seeThroughSelectedMoco = useSelector(seeThroughSelectedMocoSelector)

  const [selectedMocoIndex, setSelectedMocoIndex] = useState(
    mocos?.findIndex(moco => moco.mocoCode === code) || 0,
  )

  const dispatch = useDispatch()

  const productsListRef = useRef<HTMLDivElement>(null)
  const observerRef = useRef<IntersectionObserver>()
  useEffect(() => {
    if (productsListRef.current) {
      const options = {
        root: productsListRef.current,
        rootMargin: '0px',
        threshold: 0.8,
      }
      observerRef.current = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          const target = entry.target as HTMLElement
          const index = target.dataset.index
          if (index && entry.isIntersecting) {
            setSelectedMocoIndex(Number(index))
          }
        })
      }, options)

      const targets = productsListRef.current?.querySelectorAll('.pdp-single-product')
      targets?.forEach(target => observerRef.current?.observe(target))
    }
  }, [])

  const showModalProductView = useCallback(
    (moco: Moco) => {
      dispatch(setSeeThroughSelectedMoco(moco))
      dispatch(setSeethroughModalVisibility(true))

      const { lensColor, mocoCode, brandCode } = moco
      dispatch(getSeeThroughWithoutContent(mocoCode, brandCode, lensColor?.id))
    },
    [dispatch],
  )

  const isSeethroughModalVisibleRef = useRef(isSeethroughModalVisible)
  useEffect(() => {
    isSeethroughModalVisibleRef.current = isSeethroughModalVisible
  }, [isSeethroughModalVisible])

  const handleThumbClick = useCallback(
    (mocoCode: string, index: number) => {
      if (!mocos) {
        return
      }

      const selectedMocoCard = productsListRef.current?.querySelectorAll('.pdp-single-product')[
        index
      ]
      const scrollPos = selectedMocoCard?.getBoundingClientRect()
      if (scrollPos !== undefined) {
        const productCardVerticalMargin = Math.round(window.innerWidth * 0.02)
        productsListRef.current?.scrollTo({
          top: (scrollPos.height + productCardVerticalMargin) * index,
        })
      }

      dispatch(changeSelectedSku(mocoCode))
      dispatch(setSeethroughModalVisibility(false))

      if (isSeethroughModalVisibleRef.current) {
        const mocoToShow = mocos?.find(m => m.mocoCode === mocoCode)
        if (mocoToShow) {
          showModalProductView(mocoToShow)
        }
      }
    },
    [dispatch, mocos, showModalProductView],
  )

  const brand = getBrandByBrandCode(brands, model.brandCode)

  const couvetteColorCodes = Object.keys(model.mocos)
  const cartProductsForMocos = cartProducts.filter(
    ({ modelCode, colorCode }: CartProduct) =>
      modelCode === model.modelCode && couvetteColorCodes.includes(colorCode),
  )
  const mocosInCart = convertModelIntoMocosInCart(model, cartProductsForMocos, brands)

  useEffect(() => {
    const index = mocos?.findIndex(moco => moco.mocoCode === code)
    if (index !== undefined) {
      handleThumbClick(code, index)
    }
  }, [code, mocos, handleThumbClick])

  return !model || !mocos ? null : (
    <React.Fragment>
      <ThumbsNavigation
        activeKey={mocos[selectedMocoIndex]?.mocoCode || code}
        mocos={mocos}
        onProductSelected={handleThumbClick}
      />
      <PdpContainer
        ref={productsListRef}
        onClick={closeModal}
        className="pdp-container"
        data-testid="table-pdp"
        noMargin={isWallPdp}
      >
        {mocos.map((moco, index) => {
          const wishlistMocos = wishlistProducts.filter(
            ({ mocoCode }: { mocoCode: string }) => mocoCode === moco.mocoCode,
          )
          const mocoInCart = mocosInCart.find(m => m.mocoCode === moco.mocoCode)
          return (
            brand && (
              <PdpSingleProduct
                onClick={e => e.stopPropagation()}
                key={index}
                data-index={index}
                className={[
                  'pdp-single-product',
                  isSeethroughModalVisible ? 'is-invisibile' : 'is-visibile',
                ].join(' ')}
              >
                <PDPLeftCol
                  brand={brand}
                  model={model}
                  moco={moco}
                  modelVideo={model.modelVideo}
                  couvetteVideo={couvetteVideo}
                  cartProducts={cartProductsForMocos}
                  wishlistProducts={wishlistMocos}
                  content={content}
                  showModalProductView={showModalProductView}
                  virtualMirrorAvailability={virtualMirrorAvailability}
                  isSeeThemOnAvailable={seeThemOnAvailableForMocos[code]}
                />
                <PDPRightCol model={model} moco={moco} mocoInCart={mocoInCart} />
              </PdpSingleProduct>
            )
          )
        })}
      </PdpContainer>
      {isSeethroughModalVisible && (
        <ProductModalView
          key={seeThroughSelectedMoco && seeThroughSelectedMoco.mocoCode}
          content={content}
          brandLogo={brand?.logo}
          hideModalProductView={() => {
            dispatch(setSeethroughModalVisibility(false))
          }}
          model={model}
          isSeeThemOnAvailable={seeThemOnAvailableForMocos[code]}
          moco={seeThroughSelectedMoco}
        />
      )}
      <button
        data-testid="pdp-close-button"
        onClick={closeModal}
        className="ant-modal-close left"
      />
    </React.Fragment>
  )
}

export default TablePdpContent
