import { Action, Middleware } from '@reduxjs/toolkit'

import devices, { cartSynchedDevices, DeviceData, sessionSynchedDevices } from '../config/devices'
import socketManager from '../libs/socketManager'
import { isTableDevice } from '../libs/url'
import { DeviceName } from '../model/device'
import i18n from 'i18next'

const SYNC_ACTION_MESSAGE = 'sync_action'

const syncActions: Record<string, DeviceData[]> = {
  'couvetteVideo/_play_video': sessionSynchedDevices,
  'couvetteVideo/_pause_video': sessionSynchedDevices,
  'couvetteVideo/_end_video': sessionSynchedDevices,
  'pdp/closeVTOView': [devices.table, devices.vto],
  'pdp/loadingContentSuccess': [devices.table, devices.wall],
  'pdp/setSeethroughModalVisibility': [devices.table, devices.wall],
  'pdp/clearPdpModel': [devices.table, devices.vto],
  'pdp/searchPdpCouvetteSuccess': [devices.table, devices.wall, devices.ledright, devices.ledleft],
  'brands/setBrandActivationPlaying': sessionSynchedDevices,
  'brands/setBrandDiscoveryPlaying': sessionSynchedDevices,
  'brands/setBrandActivationPlayed': sessionSynchedDevices,
  'brands/setBrandDiscoveryPlayed': sessionSynchedDevices,
  'brands/setOpenedBrand': sessionSynchedDevices,
  'brands/resetOpenedBrand': sessionSynchedDevices,
  'wishlist/saveWishlistProductsDetails': sessionSynchedDevices,
  'wishlist/saveWishlistProducts': sessionSynchedDevices,
  'app/toggleMassiveOrder': [devices.table, devices.wall, devices.extraipad],
  'app/removeError': sessionSynchedDevices,
  'customer/setBrandVisited': sessionSynchedDevices,
  'customer/setShowNotes': sessionSynchedDevices,
  'filters/setFiltersActivePageName': cartSynchedDevices,
  'cart/toggleCategoryAssortment': [devices.table, devices.wall],
  'cart/changeAssortmentViewType': [devices.table, devices.wall],
  'cart/switchCartType': [devices.table, devices.wall],
  'cart/resetCartView': [devices.table, devices.wall],
  'cart/setSourceMode': cartSynchedDevices,
  'cart/setMyShopActiveTab': cartSynchedDevices,
  'cart/toggleMyShopAssortmentCollapse': cartSynchedDevices,
  'cart/loadCart': sessionSynchedDevices,
  'cart/setActiveBrandCodeAction': sessionSynchedDevices,
  'cart/setMyShopLastAddedProductUpcs': cartSynchedDevices,
  'stars/setMode': sessionSynchedDevices,
}

const syncFromTableActions: Record<string, DeviceData[]> = {
  'app/_setRoomType': sessionSynchedDevices,
  'app/setRoomName': sessionSynchedDevices,
  'app/delCustomerId': sessionSynchedDevices,
  'app/setCustomerId': sessionSynchedDevices,
  'app/setEventList': sessionSynchedDevices,
  'app/_setCustomerList': sessionSynchedDevices,
  'app/setEventId': sessionSynchedDevices,
  'app/setEventReleases': sessionSynchedDevices,
  'app/delEventId': sessionSynchedDevices,
  'app/storeError': sessionSynchedDevices,
  'app/changeLanguageAction': sessionSynchedDevices,
  'dislike/loadDislikeSuccess': sessionSynchedDevices,
  'brands/loadBrandsSuccess': sessionSynchedDevices,
  'brands/loadingBrands': sessionSynchedDevices,
  'content/setAdvContent': sessionSynchedDevices,
  'customer/loadCustomerAction': sessionSynchedDevices,
  'customer/setCustomerDoors': sessionSynchedDevices,
  'customer/loadingCustomerDoors': sessionSynchedDevices,
  'customer/loadCustomerDoorsError': sessionSynchedDevices,
  'customer/loadingCustomerPricePreferences': sessionSynchedDevices,
  'customer/loadedCustomerPricePreferences': sessionSynchedDevices,
  'customer/loadCustomerPricePreferencesError': sessionSynchedDevices,
  'customer/setCustomerPricePreferences': sessionSynchedDevices,
  'auth/loggedIn': sessionSynchedDevices,
  'filters/preselectBrandsAction': sessionSynchedDevices,
  'filters/resetFilters': cartSynchedDevices,
  'filters/updateFilters': cartSynchedDevices,
  'filters/updateFiltersCounters': cartSynchedDevices,
  'filters/_applyFilters': cartSynchedDevices,
  'cart/loadRecommended': [devices.table, devices.wall],
  'cart/loadAssortments': sessionSynchedDevices,
  'cart/setCartSectionLoaded': sessionSynchedDevices,
  'stars/setSelectedMonth': sessionSynchedDevices,
  'pdp/openVTOView': sessionSynchedDevices,
  'pdp/setVirtualMirrorConfig': sessionSynchedDevices,
  resetForNewClient: sessionSynchedDevices,
}

/**
 * @description: auto send the action to other devices via Socket.io
 *
 * add SYNC_ACTION_KEY as a key in the action object with an array of devices to sync with
 */
export const syncDevicesMiddleware: Middleware = store => {
  socketManager.bindListener(
    SYNC_ACTION_MESSAGE,
    ({ response }: { response: Action & { device: DeviceName; sync?: boolean; payload: any } }) => {
      const action = response
      const devicesToSync = syncActions[action.type] || syncFromTableActions[action.type] || []

      if (devices.isCurrentDeviceToSync(devicesToSync, action.device) && action.sync) {
        if (action.type !== 'app/changeLanguageAction') {
          store.dispatch({
            ...action,
            sync: false,
          })
        } else {
          !action.payload.loading &&
            i18n.changeLanguage(action.payload.language, () => {
              store.dispatch({
                ...action,
                sync: false,
              })
            })
        }
      }
    },
  )

  return (next: (action: Action & { sync?: boolean }) => void) => {
    return (action: Action & { payload: unknown; sync?: boolean }) => {
      if (
        (action.sync === undefined && // never sinched
          syncActions[action.type] &&
          syncActions[action.type].length) ||
        (isTableDevice() &&
          syncFromTableActions[action.type] &&
          syncFromTableActions[action.type].length)
      ) {
        const actionToSend = {
          type: action.type,
          payload: action.payload,
          sync: true,
        }
        if (socketManager.isReadySync) {
          socketManager.emitSignal(SYNC_ACTION_MESSAGE, actionToSend)
        } else
          socketManager.isReady.then((socket: any) =>
            socket.emitSignal(SYNC_ACTION_MESSAGE, actionToSend),
          )
      }

      return next(action)
    }
  }
}
