import { differenceInMilliseconds, isAfter, isSameDay } from 'date-fns'

import { createSelector } from '@reduxjs/toolkit'

import { getAppointmentToDisplay } from '../../libs/appointments'
import { Appointment } from '../../model/appointment'
import { Initiative, InitiativeCategory } from '../../model/initiative'
import { RootState } from '../../model/model'
import { DEFAULT_STATE } from './slice'

export const homePageCardsBgSelector = (state: RootState): typeof DEFAULT_STATE.homePage.cardsBg =>
  state.digitalEvents.homePage.cardsBg
export const homePageBgSelector = (state: RootState): typeof DEFAULT_STATE.homePage.background =>
  state.digitalEvents.homePage.background

export const initiativesSelector = (state: RootState): Initiative[] | undefined => {
  const initiatives = state.digitalEvents.initiatives
  const appointments = state.digitalEvents.appointments?.filter(({ initiative }) => !!initiative)
  const initiativesWithAppointmentData = initiatives?.map(initiative => {
    const { category } = initiative
    const matchingAppointments = appointments
      ?.filter(
        ({ initiative, startDate }) =>
          initiative?.description.toLowerCase().replace(' ', '') === category &&
          (isSameDay(new Date(startDate), new Date()) || isAfter(new Date(startDate), new Date())),
      )
      .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())
    const matchingAppointment = matchingAppointments?.length ? matchingAppointments[0] : null
    const appointmentData = matchingAppointment
      ? {
          startDate: matchingAppointment.startDate,
          endDate: matchingAppointment.endDate,
          length: differenceInMilliseconds(
            new Date(matchingAppointment.endDate),
            new Date(matchingAppointment.startDate),
          ),
        }
      : {}
    return {
      ...initiative,
      ...appointmentData,
    }
  })

  return initiativesWithAppointmentData
}

export const initiativeSelector = (id: string) =>
  createSelector(initiativesSelector, initiatives =>
    initiatives?.find(initiative => initiative.id === id),
  )

export const dashboardBackgroundSelector = (
  state: RootState,
): typeof DEFAULT_STATE.dashboardPage.background => state.digitalEvents.dashboardPage.background

export const categoriesPageDEContentsSelector = (brandCode?: string) => (state: RootState) =>
  brandCode ? state.digitalEvents.singleBrandPage[brandCode] : undefined

export const selectCollectionHighlightsBg = (brandCode?: string) => (state: RootState) =>
  brandCode ? state.digitalEvents.singleBrandPage[brandCode]?.background : undefined

export const appointmentsSelector = (state: RootState) => state.digitalEvents.appointments

export const appointmentBackgroundSelector = (
  state: RootState,
): typeof DEFAULT_STATE.appointmentPage.background => state.digitalEvents.appointmentPage.background

export const appointmentSelector = (appointmentId: number) => (state: RootState) =>
  state.digitalEvents.appointments?.find(appointment => appointmentId === appointment.idAppointment)

export const calendarPageBackgroundSelector = (
  state: RootState,
): typeof DEFAULT_STATE.calendarPage.background => state.digitalEvents.calendarPage.background

export const initiativeBackgroundSelector = (
  state: RootState,
): typeof DEFAULT_STATE.initiativePage.background => state.digitalEvents.initiativePage.background

export const brandsBackgroundSelector = (
  state: RootState,
): typeof DEFAULT_STATE.brandsPage.background => state.digitalEvents.brandsPage.background

export const backgroundsLoadedSelector = (state: RootState) => state.digitalEvents.backgroundsLoaded

const multibrandBackgroundMap: { [appointmentId: string]: string } = {}
export const selectAppointmentBackground = (appointment?: Appointment) => (state: RootState) => {
  if (!appointment) {
    return ''
  }

  const appointmentType = appointment.appointmentType.description
    .trim()
    .toLowerCase()
    .replace(/\s+/, '_')
  const appointmentId = appointment.idAppointment.toString()
  const brands = appointment.brands

  if (appointmentType === 'cart_review') {
    return state.digitalEvents.calendarPage.cartReviewBackgrounds[0]
  }

  if (appointmentType === 'initiative_presentation') {
    const initiativeCategory = appointment.initiative?.description
      .toLowerCase()
      .replace(' ', '') as InitiativeCategory
    const backgorunds = initiativeCategory
      ? state.digitalEvents.calendarPage.initiativeBackgrounds[initiativeCategory]
      : ['']
    return (backgorunds && backgorunds[0]) || ''
  }

  if (appointmentType === 'meeting_(generic)') {
    return state.digitalEvents.calendarPage.meetingBackgrounds[0]
  }

  if (!appointment.brands.length) {
    return ''
  }

  if (brands.length === 1) {
    const brandBackgrounds = state.digitalEvents.calendarPage.brandsBackgrounds[brands[0].code]

    return brandBackgrounds ? brandBackgrounds[0] : ''
  }

  if (multibrandBackgroundMap[appointmentId]) {
    return multibrandBackgroundMap[appointmentId]
  }

  const multibrandBackgrounds = state.digitalEvents.calendarPage.brandsBackgrounds.multibrand || []
  const alreadyUsedBackgrounds = Object.values(multibrandBackgroundMap)

  const backgroundsUsedMap = multibrandBackgrounds.reduce((result, background) => {
    result[background] = alreadyUsedBackgrounds.filter(bg => bg === background).length
    return result
  }, {} as { [background: string]: number })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const backgroundsSortedByUse = Object.entries(backgroundsUsedMap).sort((a, b) => a[1] - b[1])
  const lessUsedBackground = backgroundsSortedByUse.length ? backgroundsSortedByUse[0][0] : ''

  if (lessUsedBackground) {
    multibrandBackgroundMap[appointmentId] = lessUsedBackground
  }

  return lessUsedBackground
}

const mapAppointmentDescriptionToInitiativeCategory: Record<string, string> = {
  Leonardo: 'leonardo',
  STARS: 'stars',
  'Store Innovation': 'storeinnovation',
  Sustainability: 'sustainability',
  Other: 'other',
}
export const selectInitiativeAppointment = (category?: InitiativeCategory) => (
  state: RootState,
) => {
  const appointments = appointmentsSelector(state)
  const initiativeAppointments = appointments?.filter(
    appointment =>
      mapAppointmentDescriptionToInitiativeCategory[appointment.initiative?.description || ''] ===
      category,
  )
  return getAppointmentToDisplay(initiativeAppointments || [])[0]
}

export const selectAppointmentInitiative = (description: string) =>
  createSelector(initiativesSelector, initiatives =>
    initiatives?.find(
      initiative =>
        initiative.category === mapAppointmentDescriptionToInitiativeCategory[description],
    ),
  )

export const appointmentSelectedDateSelector = (state: RootState) =>
  state.digitalEvents.selectedAppointmentDate
