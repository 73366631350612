import styled from 'styled-components/macro'
import {
  breakpoints,
  duration,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
} from '../../../style/theme'
import CloseIcon from '../../icons/CloseIcon'
import ArrowDownWithBody from '../../icons/ArrowDownWithBody'
import { afaVerticalScrollbarCss } from '../../CommonComponents'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  background-color: ${palette.tangaroa};
  height: 30vh;
  width: 100vw;
  font-size: ${getFluidSizeWithFullFormula('px', 34, 54, 1366, 3840)};
  color: ${palette.white};
  transition: height ${duration.short};
  padding: 1.5vw;
  min-height: ${getFluidSizeWithFullFormula('px', 249, 640, 1366, 3840)};
  overflow: hidden;

  @media screen and (max-width: ${breakpoints.M}) {
    flex-direction: column;
    height: 90vh;
    padding-bottom: 4vw;
  }
`

export const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
`

export const CloseIconStyled = styled(CloseIcon)`
  width: ${getFluidSizeWithFullFormula('px', 16, 48, 1367, 3840)};
  height: ${getFluidSizeWithFullFormula('px', 16, 48, 1367, 3840)};
`

export const SearchWrapper = styled.div`
  display: flex;
  padding: 2vw;
  align-items: center;

  @media screen and (max-width: ${breakpoints.M}) {
    padding: 4vw 10.5vw;
  }
`

export const TextBox = styled.input.attrs({ type: 'text' })`
  background-color: transparent;
  border: none;
  font-size: inherit;
  border-radius: 0;
  outline: none;
  width: 100%;
  color: ${palette.white};
  height: 1.5em;
  max-width: ${pxToRem(1400)}rem;

  &::placeholder {
    color: ${palette.white};
  }
`

export const ArrowRight = styled(ArrowDownWithBody)`
  transform: rotate(-90deg);
  width: 1em;
  height: 1em;
`

export const Suggestions = styled.div<{
  searchWrapperHeight: number
  searchWrapperPaddingRight: string
}>`
  padding-left: 2vw;
  width: calc(100% - ${({ searchWrapperPaddingRight }) => searchWrapperPaddingRight});
  display: flex;
  flex-direction: column;
  justify-content: start;
  font-size: 1.5rem;
  position: relative;
  height: calc(100% - ${({ searchWrapperHeight }) => searchWrapperHeight}px);
  overflow-y: auto;
  ${afaVerticalScrollbarCss}

  @media screen and (max-width: ${breakpoints.M}) {
    padding-left: 10.5vw;
  }
`

export const SearchContainer = styled.div<{ closeBtnWrapperHeight: number }>`
  height: calc(100% - ${({ closeBtnWrapperHeight }) => closeBtnWrapperHeight}px);
`

export const LoadingWrapper = styled.div`
  height: 60%;
  position: absolute;
  width: 100%;
  bottom: 0;
  @media screen and (max-width: ${breakpoints.M}) {
    height: 80%;
  }
`
