import { createSelector } from '@reduxjs/toolkit'

import { getRole } from '../../libs/auth'
import { RootState } from '../../model/model'
import { tokenSelector } from '../auth/selectors'
import { DebuggerFeatures } from '../../Debug'

export const appLoadingSelector = (state: RootState) =>
  state.brands.loading || state.dislike.loading || state.app.loadingLanguage

export const appLoadedSelector = (state: RootState) =>
  state.wishlist.loaded && state.brands.loaded && state.dislike.loaded && !state.app.loadingLanguage

export const eventIdSelector = (state: RootState) => state.app.eventId

export const customerIdSelector = (state: RootState) => state.app.customerId

export const eventsSelector = (state: RootState) => state.app.events

export const errorsSelector = (state: RootState) => state.app.errors

export const appSelector = (state: RootState) => state.app

export const roomTypeSelector = (state: RootState) => state.app.roomType

export const roomNameSelector = (state: RootState) => state.app.roomName

export const deviceNameSelector = (state: RootState) => state.app.deviceName

export const getIsMassiveOrderEnabled = (state: RootState) => state.app.massiveOrder

export const languageSelector = (state: RootState) => state.app.lang

export const languagesSelector = (state: RootState) => state.app.languages

export const customersSelector = (state: RootState) => state.app.customers

export const footerModalVisibleSelector = (state: RootState) => state.app.footerModalVisible

export const isKidCategoryModeEnabledSelector = (state: RootState) =>
  state.app.isKidCategoryModeEnabled

export const springSummerImageSelector = (state: RootState) => state.app.content.springsummerImage

export const contentSelector = (state: RootState) => state.app.content

export const contentLoadingSelector = (state: RootState) => state.app.loading

export const contentLoadedSelector = (state: RootState) => state.app.loaded

export const contentIntroSelector = (state: RootState) => state.app.content.intro

export const scanCameraModeSelector = (state: RootState) => state.app.scanCameraMode

export const selectedEventSelector = createSelector(
  eventsSelector,
  eventIdSelector,
  (events, eventId) => {
    return events?.find(({ id }) => id === eventId)
  },
)

export const newReleasesSelector = createSelector(selectedEventSelector, event => {
  return event?.newReleases || []
})

export const isDigitalEventSelector = createSelector(
  selectedEventSelector,
  roomTypeSelector,
  roomNameSelector,
  (selectedEvent, roomType, roomName) => {
    const isShowRoom = roomType && roomName && roomName !== 'de'
    return !isShowRoom && selectedEvent?.type === 'RE'
  },
)

export const isReEventSelector = createSelector(selectedEventSelector, selectedEvent => {
  return selectedEvent?.type === 'RE'
})

export const isKamUserSelector = createSelector(tokenSelector, token => {
  return getRole(token).toLowerCase() === 'kam'
})

export const cmsTagSelector = createSelector(selectedEventSelector, event => event?.cmsTag)

export const cmsTagAfaSelector = createSelector(selectedEventSelector, event => event?.cmsTagAfa)

export const isStickyHeaderSelector = (state: RootState) => state.app.isStickyHeader

export const showRtrSelector = (modelHasRtr: boolean) =>
  createSelector(isDigitalEventSelector, isDigitalEvent => {
    return modelHasRtr && !isDigitalEvent
  })

export const multibrandWallSelector = (state: RootState) => state.app.content.multibrandWall

export const afaBrandSelectedSelector = createSelector(
  (state: RootState) => state.app.afaBrandSelected,
  brand => (brand?.code ? brand : undefined),
)

export const appShowSpinner = createSelector(
  (state: RootState) => state.app.showSpinner,
  showSpinner => showSpinner,
)

export const environmentSelector = createSelector(
  (state: RootState) => state.app.environment,
  environment => environment,
)

const activeDebuggers = createSelector(
  (state: RootState) => state.app.debug.active,
  debug => debug,
)

const isActiveDebugger = (debuggerFeature: DebuggerFeatures) => {
  return createSelector(activeDebuggers, activeDebuggers =>
    activeDebuggers.includes(debuggerFeature),
  )
}

export const appSelectors = {
  isActiveDebugger,
  activeDebuggers,
}
