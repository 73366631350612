import { Icon } from 'antd'
import classnames from 'classnames'
import React, { Component, Fragment } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { mapDispatchToProps } from '../../../../libs'
import { isIPadView, isPageMatches } from '../../../../libs/url'
import { CartProduct } from '../../../../model/cart'
import { RootState } from '../../../../model/model'
import { Mocos, Product } from '../../../../model/product'
import { WishlistProduct } from '../../../../model/wishlist'
import { cartProductsFilteredByActiveDoorsSelector } from '../../../../store/cart/selectors'
import {
  wishlistProductDetailsSelector,
  wishlistProductsSelector,
} from '../../../../store/wishlist/selectors'
import { breakpoints, palette } from '../../../../style/theme'
import { scrollbarCss } from '../../../CommonComponents'
import RCIcon from '../../../UI/RCIcon'
import CouvetteItemV2 from '../CouvetteItem'

const AdditionalMocosWrapper = styled.div<{
  margin: boolean
}>`
  box-shadow: 0 2vh 2vw #dcdcdc;

  ${({ margin }) => margin && 'margin-top: 2vh;'}
`

const CouvetteSingleWrapper = styled.div`
  -webkit-overflow-scrolling: auto !important;
  width: 100%;

  max-height: 100%;
  overflow-y: auto;
  background-color: ${palette.wildSand};
  border-radius: 0 0 0.74vh 0.74vh;
  ${scrollbarCss}
  &.full-height {
    @media screen and (max-width: ${breakpoints.M}) {
      padding-bottom: 0;
    }
  }
  .mocos-list {
    box-shadow: 0 2vh 2vw ${palette.alto};
  }
  > [data-scroll^='product-']:last-of-type {
    margin-bottom: 2.5vh;
  }
`

const ShowMore = styled.div`
  background-color: ${palette.white};
  z-index: 1;
  width: 100%;
  border-top: 1px solid ${palette.silver};
  display: flex;
  justify-content: center;
  align-items: center;

  .arrow-icon {
    height: 1vw;
    width: 1vw;
    opacity: 0.3;
    @media screen and (max-width: ${breakpoints.M}) {
      height: 3vh;
      width: 3vh;
    }
  }
  &.active {
    display: block;
  }
`

type Props = {
  mocos: Mocos
  showWishlist: boolean
  model: Product
  upcsRTR: string[]
}

type ConnectedProps = {
  wishlistProducts: WishlistProduct[]
  cartProducts: CartProduct[]
  wishlistProductsDetails: Record<string, Product>
}

type State = {
  scrollIsNotComplete: boolean
  accordion: boolean
}

class CouvetteBodyV2 extends Component<Props & ConnectedProps & WithTranslation, State> {
  visibleCouvettesCount = isIPadView() ? 3 : 4
  scrollableItemsCount = isIPadView() ? 2 : 3
  scrollingElementRef = React.createRef<HTMLDivElement>()
  containerElementRef = React.createRef<HTMLDivElement>()

  constructor(props: Props & ConnectedProps & WithTranslation) {
    super(props)

    this.state = {
      scrollIsNotComplete: true,
      accordion: false,
    }
  }

  componentDidMount = () => {
    this.handleShowMoreVisibility()
  }

  handleShowMoreVisibility = () => {
    const scrollingElement = this.scrollingElementRef.current
    const containerElement = this.containerElementRef.current
    const scrollingElementBounds = scrollingElement?.getBoundingClientRect()
    const containerElementBounts = containerElement?.getBoundingClientRect()
    const threshold = 40
    const scrollIsNotComplete =
      !scrollingElementBounds ||
      !containerElementBounts ||
      scrollingElementBounds.bottom === containerElementBounts.bottom ||
      scrollingElementBounds.bottom - containerElementBounts.bottom > threshold ||
      (containerElement !== null &&
        containerElement.scrollTop + containerElement.clientHeight + threshold <
          containerElement.scrollHeight)

    this.setState({ scrollIsNotComplete })
  }

  scrollToEdge = () => {
    const containerElement = this.containerElementRef.current
    const containerElementBounds = containerElement?.getBoundingClientRect()

    containerElement?.scrollBy({
      top: containerElementBounds ? containerElementBounds.bottom : 0,
      left: 0,
      behavior: 'smooth',
    })
    this.handleShowMoreVisibility()
  }

  scrollToAdditionalMocos = () => {
    const scrollingElement = this.scrollingElementRef.current
    const containerElement = this.containerElementRef.current
    const mocosInWishlistContainer = scrollingElement?.querySelector('.mocos-list')
    const mocosInWishlistContainerBounds = mocosInWishlistContainer?.getBoundingClientRect()

    containerElement?.scrollTo({
      top: mocosInWishlistContainerBounds
        ? mocosInWishlistContainerBounds.height + window.innerHeight * 0.02 // + 2vh for margin
        : 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  toggleAccordionState = () =>
    this.setState({ accordion: !this.state.accordion }, () => {
      this.state.accordion && this.scrollToAdditionalMocos()
      this.handleShowMoreVisibility()
    })

  renderCouvetteItems = (mocos = ([] as unknown) as Mocos) => {
    const { cartProducts, showWishlist, wishlistProducts, upcsRTR } = this.props

    const orderedMocos = Object.values(mocos).sort((m1, m2) => m1.plpOrder - m2.plpOrder)
    return orderedMocos.map((moco, index) => {
      const cartItems = cartProducts.filter(
        cartProduct =>
          cartProduct.mocoCode === moco.mocoCode &&
          Object.values(moco.sizes).find(({ upc }) => upc === cartProduct.upc),
      )
      const itemsInWishlist = wishlistProducts.filter(({ mocoCode }) => mocoCode === moco.mocoCode)
      const hasRTR = Object.keys(moco.sizes).some(upc => upcsRTR.includes(upc))
      return (
        <div key={index}>
          <CouvetteItemV2
            moco={moco}
            cartItems={cartItems}
            showWishlist={showWishlist}
            deleteIcon={false}
            itemsInWishlist={itemsInWishlist}
            hasRTR={hasRTR}
          />
        </div>
      )
    })
  }

  renderPLPModelShowMore = () => {
    const { mocos } = this.props
    const { scrollIsNotComplete } = this.state

    const isWishlist = isPageMatches('wishlist')
    const shouldRenderPLPShowMore =
      !isWishlist && Object.keys(mocos).length > this.visibleCouvettesCount && scrollIsNotComplete

    return (
      shouldRenderPLPShowMore && (
        <ShowMore className="couvette-show-more" onClick={this.scrollToEdge}>
          <RCIcon arrow type="down" />
        </ShowMore>
      )
    )
  }

  renderAdditionalMocos = () => {
    const { scrollIsNotComplete, accordion } = this.state
    const { mocos, t, wishlistProductsDetails } = this.props

    const model = wishlistProductsDetails[Object.values(mocos)[0].modelCode]

    const additionalMocos = model
      ? Object.keys(model.mocos)
          .filter(moco => !Object.keys(mocos).includes(moco))
          .reduce((result, current) => {
            result[current] = model.mocos[current]
            return result
          }, {} as Mocos)
      : {}

    const mocosCount =
      Object.values(mocos).length + (accordion ? Object.values(additionalMocos).length : 0)
    const additionalMocosCount = Object.values(additionalMocos).length
    const isWishlist = isPageMatches('wishlist')
    const shouldRenderShowMoreMocos = mocosCount >= this.visibleCouvettesCount
    const shouldRenderShowMoreAdditionalMocos = additionalMocosCount > 0
    const shouldRenderShowMore =
      isWishlist && (shouldRenderShowMoreAdditionalMocos || scrollIsNotComplete)
    const shouldApplyStyle = shouldRenderShowMoreMocos && this.containerElementRef.current

    return (
      shouldRenderShowMore && (
        <Fragment>
          <div
            style={{
              width: shouldApplyStyle ? this.containerElementRef.current?.clientWidth : 'auto',
            }}
            className={classnames({
              'show-more-container': true,
              'show-more-container-bottom': shouldRenderShowMoreMocos,
            })}
          >
            {shouldRenderShowMoreMocos && scrollIsNotComplete && (
              <div
                className={classnames({
                  'show-more-mocos': true,
                })}
                onClick={this.scrollToEdge}
              >
                <div className="label">{t('Wishlist.scroll')}</div>
                <div>
                  <Icon type="down" />
                </div>
              </div>
            )}
            {shouldRenderShowMoreAdditionalMocos && (
              <div className="show-more-additional-mocos" onClick={this.toggleAccordionState}>
                <div className="label">
                  {accordion ? t('Wishlist.show_less') : t('Wishlist.show_more')}
                </div>
                <div className="show-more-additional-mocos-icon-container">
                  {accordion ? <RCIcon type="minus" /> : <RCIcon type="plus" />}
                </div>
              </div>
            )}
          </div>
          {accordion && (
            <AdditionalMocosWrapper
              margin={Object.keys(additionalMocos).length > 0}
              className="additional-mocos-wrapper"
            >
              {this.renderCouvetteItems(additionalMocos)}
            </AdditionalMocosWrapper>
          )}
        </Fragment>
      )
    )
  }

  render = () => {
    const { mocos } = this.props
    const mocosValues = mocos

    return (
      <Fragment>
        <CouvetteSingleWrapper
          onScroll={this.handleShowMoreVisibility}
          ref={this.containerElementRef}
          className={classnames({
            'couvette-single-wrapper': true,
            'full-height': Object.values(mocosValues).length > 3,
          })}
          key={mocosValues[0] ? mocosValues[0].modelCode : null}
        >
          <div className="mocos-container" ref={this.scrollingElementRef}>
            <div className="mocos-list">{this.renderCouvetteItems(mocosValues)}</div>
          </div>
          {this.renderAdditionalMocos()}
        </CouvetteSingleWrapper>
        {this.renderPLPModelShowMore()}
      </Fragment>
    )
  }
}

const mapStateToProps = (state: RootState): ConnectedProps => ({
  wishlistProducts: wishlistProductsSelector(state),
  cartProducts: cartProductsFilteredByActiveDoorsSelector(state),
  wishlistProductsDetails: wishlistProductDetailsSelector(state),
})

const ConnectedCouvetteBody = withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps())(CouvetteBodyV2),
) as React.ComponentType<Props>

export default ConnectedCouvetteBody
