import { format } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Appointment } from '../../../model/appointment'
import { selectAppointmentBackground } from '../../../store/digitalEvents/selectors'
import {
  breakpoints,
  DigitalEventsBoxBorderRadius,
  getFluidFontSize,
  palette,
  pxToRem,
  spacing,
} from '../../../style/theme'
import Chip from '../../../components/Chip/Chip'
import ClockIcon from '../../../components/icons/ClockIconDE'
import { selectedAppointmentDate } from '../../../store/digitalEvents/actions'
import { BrandLogoImage } from '../../../style/CommonComponents'
import { useGetBrandLogo } from '../../../services/afaContents'
import { brandSelectorByCode } from '../../../store/brands/selectors'

const CardContainer = styled(Link)<{ background: string }>`
  display: grid;
  color: ${palette.white};
  padding: ${pxToRem(spacing(6))}rem ${pxToRem(spacing(4))}rem;
  grid-template-rows: min-content 1fr min-content;
  background-color: ${palette.bigStone};
  border-radius: ${DigitalEventsBoxBorderRadius};
  background-image: linear-gradient(to bottom, transparent 30%, rgba(0, 0, 0, 0.4)),
    linear-gradient(to top, transparent 30%, rgba(0, 0, 0, 0.3)),
    url(${({ background }) => background});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

  &:hover {
    color: ${palette.white};
  }
`

const CardHeader = styled.div`
  display: flex;
  align-items: center;
`

const Time = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const Hour = styled.div`
  display: inline-block;
  font-size: ${getFluidFontSize('10px')};

  @media (max-width: ${breakpoints.M}) {
    font-size: ${getFluidFontSize('15px')};
  }
`

const EndHour = styled.span`
  @media (max-width: ${breakpoints.M}) {
    display: none;
  }
`

const BrandImageWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-start;
`

const StyledBrandLogoImage = styled(BrandLogoImage)`
  width: 100px;
  height: auto;
`

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 2rem;
  line-height: 1;
  padding-bottom: ${pxToRem(spacing(2))}rem;
`
const CardText = styled.div`
  padding: ${pxToRem(spacing(0.5))}rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

  @media (max-width: ${breakpoints.M}) {
    -webkit-line-clamp: 2;
  }
`
const CardFooter = styled.div`
  padding-top: ${pxToRem(spacing(2))}rem;
`

type Props = {
  appointment: Appointment
}

const BrandImage = ({ appointment }: Props) => {
  const brandCode = appointment.brands[0].code || ''
  const { brandLogo, isSuccess } = useGetBrandLogo(brandCode)
  const brand = useSelector(brandSelectorByCode(brandCode))
  return brandLogo || !isSuccess ? (
    <StyledBrandLogoImage className="alternative" src={brand?.brand || brandCode} />
  ) : (
    <span>{brand?.brand || brandCode}</span>
  )
}

const AppointmentCard = ({ appointment }: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const startDate = format(new Date(appointment.startDate), 'hh:mm a')
  const endDate = format(new Date(appointment.endDate), 'hh:mm a')
  const background = useSelector(selectAppointmentBackground(appointment))

  return (
    <CardContainer
      onClick={() =>
        dispatch(selectedAppointmentDate(new Date(appointment.startDate).getTime().toString()))
      }
      background={background}
      to={`/digital-events/appointment/${appointment.idAppointment}`}
    >
      <CardHeader>
        <Time>
          <ClockIcon />
          <Hour>
            {startDate}
            <EndHour> - {endDate}</EndHour>
          </Hour>
        </Time>
        <BrandImageWrapper>
          <BrandImage appointment={appointment} />
        </BrandImageWrapper>
      </CardHeader>
      <CardBody>
        <CardText>{appointment.subject}</CardText>
      </CardBody>
      <CardFooter>
        {appointment.speaker && (
          <Chip
            label={
              appointment.appointmentType.idAppointmentType === 1
                ? t('Appointments.host')
                : t('Appointments.public')
            }
            text={appointment.speaker}
          />
        )}
      </CardFooter>
    </CardContainer>
  )
}

export default AppointmentCard
