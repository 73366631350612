import classnames from 'classnames'
import React from 'react'
import styled from 'styled-components/macro'

import ProductIcons from '../../../components/ProductIcons'
import { handleImageError } from '../../../libs/handleError'
import { MocoInCart } from '../../../model/cart'
import { getFluidFontSize } from '../../../style/theme'

import { getProductImageAngledWithFallback } from '../../../libs/productImages'

const Wrapper = styled.div`
  min-width: 9em;
  height: 100%;

  &.is-discontinued {
    opacity: 0.4;
  }
`

const Header = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5em 0.5em 0;
  height: 1.5em;
  > * {
    font-size: ${getFluidFontSize('9px')};
  }
`

const Title = styled.div`
  font-weight: bold;
  margin-right: 0.5em;
`

const CloserProductIcons = styled(ProductIcons)`
  > *:not(:last-child) {
    margin-right: 0.25em;
  }
`

const ThumbWrapper = styled.div`
  height: calc(100% - 1.5em);
`

const Thumb = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

type Props = {
  product: MocoInCart
}

const LedListItem: React.FC<Props> = ({ product }) => {
  // product is typed as MocoInCart but can contain a CartProduct if it's out of assortment
  // correctly typing it requires refactoring a lot of child components
  const productImage = getProductImageAngledWithFallback({
    catalogImages: product.catalogImages || product,
    angle: 'quarter',
  })

  return (
    <Wrapper
      className={classnames({
        'led-wall-product': true,
      })}
    >
      <Header>
        <Title className="product-title">{product.modelCode}</Title>
        <CloserProductIcons
          moco={product}
          hasRTR={false}
          hiddenIcons={['adv', 'vto', 'rtr', 'star', 'discontinued', 'ideal']}
          fromPlanogram={true}
        />
      </Header>

      <ThumbWrapper>
        <Thumb
          src={productImage}
          alt="img"
          onError={handleImageError}
          loading="lazy"
          className="product-thumb-image"
        />
      </ThumbWrapper>
    </Wrapper>
  )
}

export default LedListItem
