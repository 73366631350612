import React from 'react'
import styled from 'styled-components'
import { getFluidSizeWithFullFormula as gF } from '../../style/theme'

const Icon = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16' cy='16' r='16' fill='%2375A53E'/%3E%3Cpath d='M24.239 11.147c0 .173-.07.338-.196.46l-10.416 10.1a1.05 1.05 0 0 1-.73.293 1.05 1.05 0 0 1-.731-.293l-4.97-4.82a.639.639 0 0 1 0-.917l.514-.5a.681.681 0 0 1 .946 0l4.004 3.883a.34.34 0 0 0 .47 0l9.453-9.164a.68.68 0 0 1 .945 0l.515.5a.634.634 0 0 1 .196.458z' fill='%23fff'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  width: ${gF('px', 32, 64, 1366, 3840)};
  height: ${gF('px', 32, 64, 1366, 3840)};
`

const SuccessIcon: React.FC = () => <Icon />

export default SuccessIcon
