import { useEffect } from 'react'
import { useSearchParams } from './useSearchParams'
import { Positioning } from '../flows/AssortmentAdvisor/Model/aaModel'
import { POSITIONINGS } from '../flows/AssortmentAdvisor/consts'

const useSetDefaultPositionParam = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const defaultSelectedPositioning = POSITIONINGS[0] as Positioning
  const selectedPositioning = searchParams.get('positioning') as Positioning | null

  useEffect(() => {
    if (!selectedPositioning) {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set('positioning', defaultSelectedPositioning)
      setSearchParams(searchParams, { replace: true })
    }
  }, [defaultSelectedPositioning, selectedPositioning, setSearchParams])
}

export default useSetDefaultPositionParam
