import classnames from 'classnames'
import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'

import { handleImageError } from '../../../../../libs/handleError'
import { NullableProductAttribute } from '../../../../../model/product'
import { palette } from '../../../../../style/theme'

const Wrapper = styled.div`
  background: white;
  position: relative;
  width: 100%;
  text-align: center;
  padding: 1vh 0;
  cursor: pointer;

  &.active {
    border: 0.5vmin solid ${palette.curiousBlue};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }

  &.active:after {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(125%, -50%);
    content: '';
    border-top: 1.4vmin solid transparent;
    border-bottom: 1.4vmin solid transparent;
    border-left: 1.4vmin solid ${palette.curiousBlue};
  }

  .wall-pdp-content__sidebar & {
    height: 12.85vh;
  }
`
Wrapper.displayName = 'ThumbItem'

const Image = styled.img`
  width: 90%;
  height: 90%;
  margin: 0 auto;
  aspect-ratio: 2;
`

const Labels = styled.span`
  color: ${palette.curiousBlue};
  display: flex;
  justify-content: space-between;
  padding: 0 1vw;
`

type Props = {
  activeKey?: string
  code: string
  onClick: MouseEventHandler
  productThumbnail: string
  modelCode: string
  colorCode: string
  frontFamilyColor: NullableProductAttribute
}

const ThumbItem: React.FC<Props> = ({
  activeKey,
  code,
  onClick,
  productThumbnail,
  modelCode,
  colorCode,
  frontFamilyColor,
}) => (
  <Wrapper
    id={`${code}-thumb`}
    className={classnames({
      'product-thumb': true,
      active: activeKey === code,
    })}
    key={code}
    onClick={onClick}
  >
    <Image src={productThumbnail} onError={handleImageError} loading="lazy" />
    <Labels>
      <span className="model-color-code">
        {modelCode} {colorCode}
      </span>
      <span className="front-family-color">{frontFamilyColor && frontFamilyColor.label}</span>
    </Labels>
  </Wrapper>
)

export default ThumbItem
