import styled from 'styled-components/macro'
import { breakpoints, getFluidSizeWithFullFormula, palette, pxToRem } from '../../../style/theme'
import RCIcon from '../../../components/UI/RCIcon'
import React, { ForwardedRef } from 'react'

const Wrapper = styled.div`
  z-index: 100000;
  background-color: rgba(3, 20, 52, 0.3);
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  color: ${palette.tangaroa};
`

const ModalContent = styled.div<{
  fullsize: boolean
}>`
  border-radius: 10px;
  max-width: 2776px;
  width: ${({ fullsize }) => (fullsize ? '80vw' : 'auto')};
  background-color: ${palette.white};
  padding: ${getFluidSizeWithFullFormula('px', 32, 88, 1366, 3840)};
  display: grid;
  justify-content: center;
  text-align: center;
  position: absolute;

  @media (max-width: ${breakpoints.L}) {
    min-width: 300px;
  }
`

const CloseButton = styled.span`
  cursor: pointer;
  position: absolute;
  bottom: ${getFluidSizeWithFullFormula('px', 32, 88, 1366, 3840)};
  right: ${getFluidSizeWithFullFormula('px', 32, 88, 1366, 3840)};
  z-index: 1;

  img {
    width: ${getFluidSizeWithFullFormula('px', 24, 48, 1366, 3840)};
    height: ${getFluidSizeWithFullFormula('px', 24, 48, 1366, 3840)};
  }

  @media (max-width: ${breakpoints.L}) {
    bottom: unset;
    top: ${getFluidSizeWithFullFormula('px', 32, 88, 1366, 3840)};
  }
`

const ModalTitle = styled.h2`
  font-size: ${getFluidSizeWithFullFormula('px', 26, 48, 1366, 3840)};
  margin-bottom: ${pxToRem(27)}rem;
  font-family: GilmerBold, sans-serif;
  color: ${palette.cloudBurst};
`

const Content = styled.div`
  font-size: ${getFluidSizeWithFullFormula('px', 20, 40, 1366, 3840)};
  font-family: 'GilmerMedium', sans-serif;
  width: 100%;
  color: ${palette.cloudBurst};
`

type Props = {
  className?: string
  onCancel: () => void
  title: string
  fullsize?: boolean
  position?: {
    right?: number
    left?: number
    top?: number
    bottom?: number
  }
  ref?: ForwardedRef<HTMLDivElement>
}

const Modal: React.FC<Props> = React.forwardRef(
  ({ className, onCancel, title, children, fullsize = false }, ref) => {
    return (
      <Wrapper
        className={className}
        ref={ref}
        onClick={e => {
          if (e.target === e.currentTarget) {
            onCancel()
          }
        }}
      >
        <ModalContent fullsize={fullsize}>
          <CloseButton onClick={onCancel}>
            <RCIcon type="icon-close" />
          </CloseButton>
          {title && <ModalTitle>{title}</ModalTitle>}
          <Content>{children}</Content>
        </ModalContent>
      </Wrapper>
    )
  },
)

Modal.displayName = 'Modal'

export default Modal
