import i18n from 'i18next'

import { apiCustomerList, apiEvents, apiReleases, ContentAPI } from '../../api/restApi'
import { getIntersessionAuthToken } from '../../libs/auth'
import { setReleaseFilters } from '../../libs/filters'
import { handleErrors } from '../../libs/handleError'
import log, { convertErrorToErrorData } from '../../libs/log'
import { getCLensToMocoCode } from '../../libs/productsV2'
import { AppLangCode, AppThunk, Customer, ModalType, RoomType, RootState } from '../../model/model'
import { loginFailed } from '../auth/actions'
import { changeAssortmentViewType } from '../cart/actions'
import { toggleBrandsBarAction, updateFilters } from '../filters/actions'
import { closePDP, loadPdpContent, searchPdpCouvette } from '../pdp/actions'
import { hideSearch } from '../search/actions'
import { footerModalVisibleSelector } from './selectors'
import { slice } from './slice'
import { subscribeToAppMessagesAction } from './subscriptions'
import doorActions from '../customer/actions'
import { RcEvent } from '../../model/events'
import { deleteIntersessionEventId, getIntersessionEventId } from '../../libs/event'
import { deleteIntersessionCustomerId, getIntersessionCustomerId } from '../../libs/customer'

export { subscribeToAppMessagesAction }

export const setRoomType = (roomType: RoomType): AppThunk => dispatch => {
  dispatch(slice.actions._setRoomType(roomType))
}

export const setCustomerList = (customers: Customer[] | null): AppThunk => dispatch => {
  dispatch(slice.actions.setCustomers({ customers }))
}

const setEvents = (events: RcEvent[]): AppThunk<string | undefined> => dispatch => {
  const intersessionEventId = getIntersessionEventId()
  deleteIntersessionEventId()

  if (intersessionEventId && events.find(({ id }) => id === intersessionEventId)) {
    dispatch(slice.actions.setEvents({ events, eventId: intersessionEventId }))
    return intersessionEventId
  }

  if (events.length === 1) {
    dispatch(slice.actions.setEvents({ events, eventId: events[0].id }))
    return events[0].id
  }

  dispatch(slice.actions.setEvents({ events }))

  return
}

const setCustomers = (customers: Customer[]): AppThunk<string | undefined> => dispatch => {
  const intersessionCustomerId = getIntersessionCustomerId()
  deleteIntersessionCustomerId()

  if (intersessionCustomerId && customers.find(({ id }) => id === intersessionCustomerId)) {
    dispatch(slice.actions.setCustomers({ customers, customerId: intersessionCustomerId }))
    return intersessionCustomerId
  }

  if (customers.length === 1) {
    dispatch(slice.actions.setCustomers({ customers, customerId: customers[0].id }))
    return customers[0].id
  }

  dispatch(slice.actions.setCustomers({ customers }))

  return
}

export function dataLoadedFail(error: any, errorType?: string, goToHome = false) {
  if (error.status === 401) {
    // user has an invalid token
    return loginFailed('Session expired. Please login again.')
  } else if (!getIntersessionAuthToken()) {
    // user has no token, so we have to avoid propagating store errors while react is processing logout,
    // in a valid state a user not logged has no other devices than the one he is using
    return slice.actions.storeErrorWithoutSync({
      goToHome,
      ...convertErrorToErrorData(error, errorType),
    })
  } else {
    // other type of errors
    return slice.actions.storeError({ goToHome, ...convertErrorToErrorData(error, errorType) })
  }
}

export const getEventReleases = (eventId: string, assortment: string): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()
    const { auth, filters } = state

    apiReleases(auth.token || '', eventId, assortment)
      .then(handleErrors)
      .then(releases => {
        if (releases) {
          dispatch(slice.actions.setEventReleases({ eventId: eventId, releases: releases }))
          const releaseFilters = setReleaseFilters(filters.plp, releases)
          dispatch(updateFilters({ filters: releaseFilters, pageType: 'plp' }))
        }
      })
      .catch(err => {
        log.error(err, 'getEventReleases')
        return dispatch(dataLoadedFail(err))
      })
  }
}

export const retrieveCustomerList = (eventId: string): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setCustomerList(null))
    const state = getState()
    return (
      !!eventId &&
      apiCustomerList(state.auth.token || '', eventId)
        .then(handleErrors)
        .then(data => dispatch(setCustomerList(data)))
        .catch(err => {
          log.error(err, 'retrieveCustomerList')
          return dispatch(dataLoadedFail(err))
        })
    )
  }
}

export const changeLanguage = (language: string): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()
    const {
      app,
      pdp: { isPdpVisible, modelCode, couvette, content },
    } = state
    const prevLanguage = app.lang
    dispatch(slice.actions.changeLanguageAction({ language: prevLanguage, loading: true }))

    i18n.changeLanguage(language, () => {
      if (prevLanguage !== language && isPdpVisible) {
        dispatch(
          searchPdpCouvette({
            modelCode,
            language,
          }),
        )

        const cLensAndMocos = getCLensToMocoCode(Object.values(couvette?.mocos || {}))

        dispatch(
          loadPdpContent({
            mocos: Object.keys(content),
            cLensAndMocos,
            brand: couvette?.brandCode,
            lang: language,
          }),
        )
      }
      return dispatch(
        slice.actions.changeLanguageAction({ language: language as AppLangCode, loading: false }),
      )
    })
  }
}

export const showFooterModal = (
  footerModalVisible: ModalType | null,
  autoClosePdp = true,
): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()
    const currentModalVisible = footerModalVisibleSelector(state)
    if (
      autoClosePdp &&
      footerModalVisible !== ('login' || 'afaLogin') &&
      currentModalVisible !== ('login' || 'afaLogin')
    ) {
      dispatch(closePDP())
    }
    dispatch(slice.actions.showFooterModal(footerModalVisible))
  }
}

export const closeFooterModal = (autoclosePdp = true): AppThunk => {
  return dispatch => {
    dispatch(showFooterModal(null, autoclosePdp))
    dispatch(toggleBrandsBarAction(false))
    dispatch(changeAssortmentViewType(undefined))
  }
}

// Types of modals existing
// Each type has 'isOpened' method to check whether it is currently opened or not
// and 'closeModal' action to close all modals of type
const modalTypes = {
  footerModal: {
    isOpened: (state: RootState) => !!state.app.footerModalVisible,
    closeModal: closeFooterModal,
  },
  pdpModal: {
    isOpened: (state: RootState) => !!state.pdp.isPdpVisible,
    closeModal: closePDP,
  },
  searchModal: {
    isOpened: (state: RootState) => !!state.search.isSearchVisible,
    closeModal: hideSearch,
  },
}

// Private action responsible for closing only modals of types provided
const closeModals = (modalTypesToClose = Object.values(modalTypes)): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()
    modalTypesToClose.forEach(modalType => {
      modalType.isOpened(state) && dispatch(modalType.closeModal() as any)
    })
  }
}

export const closeSwitchModals = (): AppThunk => {
  return dispatch => dispatch(closeModals([modalTypes.footerModal, modalTypes.searchModal]))
}

export const closeAllModals = (): AppThunk => {
  return dispatch =>
    dispatch(closeModals([modalTypes.footerModal, modalTypes.pdpModal, modalTypes.searchModal]))
}

export const getGeneralContent = (): AppThunk => dispatch => {
  dispatch(slice.actions.setGeneralContentLoading())
  const contentAPI = new ContentAPI()
  contentAPI
    .get({ type: 'general' })
    .then(handleErrors)
    .then(content => {
      dispatch(slice.actions.setGeneralContent(content))
    })
    .catch(error => {
      dispatch(dataLoadedFail(error))
    })
}

export const getEvents = (token: string): AppThunk => dispatch => {
  return apiEvents(token)
    .then(handleErrors)
    .then(events => {
      const eventId = dispatch(setEvents(events))
      if (eventId) {
        return apiCustomerList(token, eventId)
          .then(handleErrors)
          .then(customers => {
            dispatch(setCustomers(customers))
          })
      }
    })
    .catch(error => {
      dispatch(dataLoadedFail(error))
    })
}

export const delCustomerId = (): AppThunk => dispatch => {
  dispatch(slice.actions.delCustomerId())
  dispatch(doorActions.resetCustomerState())
}

export const setDeviceName = slice.actions.setDeviceName
export const setRoomName = slice.actions.setRoomName
export const setGeneralContent = slice.actions.setGeneralContent
export const enableKidCategoryMode = slice.actions.enableKidCategoryMode
export const disableKidCategoryMode = slice.actions.disableKidCategoryMode
export const setScanCameraMode = slice.actions.setScanCameraMode
export const setStickyHeaderAction = slice.actions.setStickyHeaderAction
export const resetErrors = slice.actions.resetErrors
export const toggleMassiveOrder = slice.actions.toggleMassiveOrder
export const setEventReleases = slice.actions.setEventReleases
export const changeLanguageAction = slice.actions.changeLanguageAction
export const removeError = slice.actions.removeError
export const setCustomerId = slice.actions.setCustomerId
export const setCustomerIdWithoutSync = slice.actions.setCustomerIdWithoutSync
export const setEventListWithoutSync = slice.actions.setEventListWithoutSync
export const setEventId = slice.actions.setEventId
export const setEventIdWithoutSync = slice.actions.setEventIdWithoutSync
export const delEventId = slice.actions.delEventId
export const storeError = slice.actions.storeError
export const storeErrorWithoutSync = slice.actions.storeErrorWithoutSync
export const setEnvironment = slice.actions.setEnvironment

const appActions = {
  ...slice.actions,
  setRoomType,
  setCustomerList,
  dataLoadedFail,
  getEventReleases,
  retrieveCustomerList,
  changeLanguage,
  showFooterModal,
  closeFooterModal,
  closeSwitchModals,
  closeAllModals,
  getGeneralContent,
  getEvents,
  setEvents,
  setCustomers,
}

export default appActions
