import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useGetPlpProductsQuery } from '../../services/afaProducts'
import {
  breakpoints,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
  spacing,
} from '../../style/theme'
import AfaCategorySwitcher from './AfaCategorySwitcher'
import AfaFilterButton from './AfaFilterButton'
import AfaSortButton from './AfaSortButton'

const Wrapper = styled.div`
  padding: 0 ${pxToRem(spacing(3))}rem ${pxToRem(spacing(6))}rem;
  gap: ${pxToRem(56)}rem;
  border-right: solid 1px #cecccc;
  display: grid;
  grid-template-rows: 10fr min-content min-content;
  grid-template-columns: 1fr;
  font-size: ${getFluidSizeWithFullFormula('px', 12, 24, 1366, 3840)};

  @media screen and (max-width: ${breakpoints.M}) {
    padding-bottom: 0;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 1fr;
    border-bottom: solid 1px #cecccc;
    height: ${pxToRem(64)}rem;
    gap: ${pxToRem(6)}rem;
  }
`

const AfaPlpFilterButton = styled(AfaFilterButton)`
  color: ${palette.tangaroa};
  grid-row-start: 2;

  @media screen and (max-width: ${breakpoints.L}) {
    grid-row-start: auto;
  }
`

const LeftCol = styled.div`
  display: flex;
  grid-row-start: 3;

  @media screen and (max-width: ${breakpoints.M}) {
    gap: ${pxToRem(spacing(3))}rem;
    justify-content: end;
    grid-column-start: auto;
    grid-row-start: auto;
  }
`

const NumberOfProducts = styled.div`
  color: ${palette.santasGray};
  display: none;
  align-items: center;
  text-transform: capitalize;

  @media screen and (max-width: ${breakpoints.M}) {
    display: flex;
  }
`

const AfaPlpTopbar: React.FC = () => {
  const { t } = useTranslation()

  const productsQuery = useGetPlpProductsQuery()

  return (
    <Wrapper>
      <AfaPlpFilterButton />
      <AfaCategorySwitcher />
      <LeftCol>
        <NumberOfProducts>
          {productsQuery.data?.count} {t('Afa.products')}
        </NumberOfProducts>
        <AfaSortButton />
      </LeftCol>
    </Wrapper>
  )
}

export default AfaPlpTopbar
