import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppointmentApi } from '../../model/appointment'
import {
  ContentDigitalEventsAppointmentTilesBackground,
  ContentDigitalEventsBackgrounds,
  SingleBrandPageContents,
} from '../../model/content'
import { Initiative } from '../../model/initiative'
import { DigitalEventState } from '../../model/model'
import { resetForNewClient } from '../extraActions'

export const DEFAULT_STATE: DigitalEventState = {
  backgroundsLoaded: false,
  homePage: {
    cardsBg: { calendar: '', dashboard: '', brands: '' },
    background: { image: '', video: '' },
  },
  dashboardPage: {
    background: { image: '', video: '' },
  },
  appointmentPage: {
    background: { image: '', video: '' },
  },
  calendarPage: {
    background: { image: '', video: '' },
    brandsBackgrounds: {},
    cartReviewBackgrounds: [],
    initiativeBackgrounds: {
      leonardo: [],
      stars: [],
      storeinnovation: [],
      sustainability: [],
      other: [],
    },
    meetingBackgrounds: [],
  },
  highlightsPage: {
    background: { image: '', video: '' },
  },
  brandsPage: {
    background: { image: '', video: '' },
  },
  initiativePage: {
    background: { image: '', video: '' },
  },
  initiatives: null,
  appointments: null,
  singleBrandPage: {},
  selectedAppointmentDate: '',
}

export const slice = createSlice({
  name: 'digitalEvents',
  initialState: DEFAULT_STATE,
  reducers: {
    setDigitalEventsBackgrounds: (
      state,
      { payload }: PayloadAction<ContentDigitalEventsBackgrounds>,
    ) => {
      state.backgroundsLoaded = true
      state.homePage = payload.homepage
      state.dashboardPage.background = payload.dashboard.background
      state.appointmentPage.background = payload.appointment.background
      state.calendarPage.background = payload.calendar.background
      state.highlightsPage.background = payload.highlights.background
      state.brandsPage.background = payload.brands.background
      state.initiativePage.background = payload.initiative.background
    },
    setDigitalEventsAppointmentTileBackground: (
      state,
      { payload }: PayloadAction<ContentDigitalEventsAppointmentTilesBackground>,
    ) => {
      Object.assign(state.calendarPage, payload)
    },
    setInitiatives: (state, { payload }: PayloadAction<Initiative[]>) => {
      state.initiatives = payload
    },
    setSingleBrandPageContents: (state, { payload }: PayloadAction<SingleBrandPageContents>) => {
      Object.assign(state.singleBrandPage, payload)
    },
    setAppointments: (state, { payload }: PayloadAction<AppointmentApi[]>) => {
      state.appointments = payload.map(appointment => ({ ...appointment, teamsLink: '' }))
    },
    setAppointmentLink: (
      state,
      { payload }: PayloadAction<{ appointmentId: number; link: string }>,
    ) => {
      state.appointments =
        state.appointments?.map(appointment =>
          appointment.idAppointment === payload.appointmentId
            ? { ...appointment, teamsLink: payload.link }
            : appointment,
        ) || null
    },
    setSelectedAppointmentDate: (state, { payload }: PayloadAction<string>) => {
      state.selectedAppointmentDate = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, () => DEFAULT_STATE)
  },
})

export default slice.reducer
