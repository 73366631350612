import { useDispatch, useSelector } from 'react-redux'
import whiteboardActions from '../../../../../store/whiteboard/actions'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import RCIcon from '../../../../../components/UI/RCIcon'
import { useSearchParams } from '../../../../../hooks/useSearchParams'
import { Selection, SelectionsContainer, Wrapper, Categories, IconWrapper } from './style'
import { CategoriesFieldOfBrand } from '../../../../../model/afa'
import { useGetBrandCategoriesQuery } from '../../../../../services/afa'
import { useHistory, useParams } from 'react-router'
import {
  getProductsListWidth,
  sortWhiteboardCategoriesFunction,
} from '../../../../../model/whiteboard'
import classnames from 'classnames'
import {
  categoriesSportsSelector,
  getSelectedCategory,
  whiteboardFiltersSelector,
} from '../../../../../store/whiteboard/selectors'
import { useTranslation } from 'react-i18next'
import { viewportSelector } from '../../../../../store/viewport/selectors'
import { breakpointsCross } from '../../../../../style/theme'
import { useGet4KDevice } from '../../../../../hooks/useGet4KDevice'
import { useGetPlpProductsQuery } from '../../../../../services/afaProducts'
import { getFilteredProductsBySport } from '../../../../../helpers/genericHelper'
import { useGetPinnedItemsForWhiteboardMutation } from '../../../../../services/whiteboard'

type Props = {
  selectedSection?: string
}

const Sidebar: React.FC<Props> = ({ selectedSection }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const { brandCode } = useParams<{ assortment: string; brandCode: string }>()
  const categoriesQuery = useGetBrandCategoriesQuery(brandCode)
  const categories = categoriesQuery.data?.categories || []
  const sortedCategories = Object.assign<CategoriesFieldOfBrand[], CategoriesFieldOfBrand[]>(
    [],
    categories,
  ).sort(sortWhiteboardCategoriesFunction)
  const sortedCategoriesNames = sortedCategories.map(({ name }) => name)

  const wrapperRef = useRef<HTMLElement>(null)
  const { width: viewportWidth } = useSelector(viewportSelector)
  const categoriesSports = useSelector(categoriesSportsSelector)

  const filtersApplied = useSelector(whiteboardFiltersSelector)
  const arrayFiltersApplied = [...filtersApplied]
  const [checkPinned, setCheckPinned] = useState(false)
  const [checkApparel, setCheckApparel] = useState(false)
  const is4kDevice = useGet4KDevice()

  const filteredCategories =
    categoriesSports?.reduce((sum, category) => {
      const categoryHasSportSelected = category.categorySports.some(sport =>
        arrayFiltersApplied.includes(sport),
      )
      if (categoryHasSportSelected) {
        sum.push(category.categoryName)
      }
      return sum
    }, [] as string[]) || []

  const history = useHistory()
  const selectedCategory = useSelector(getSelectedCategory)

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (selectedCategory === 'pinned') {
        dispatch(whiteboardActions.emptyPinnedItemsList())
      }
    })
    return () => {
      unlisten()
    }
  }, [history, dispatch, selectedCategory])

  const categoriesNames = arrayFiltersApplied.length
    ? ['pinned'].concat(filteredCategories)
    : ['pinned'].concat(sortedCategoriesNames)

  const toggleSidebar = () => {
    if (selectedCategory === 'filter') {
      dispatch(whiteboardActions.setSelectedCategory({ category: 'filter' }))
    } else {
      const category = searchParams.get('category')
      dispatch(
        whiteboardActions.setSelectedCategory({
          category: category ? category.toLowerCase() : 'pinned',
        }),
      )
    }
  }
  const handleSelectedCategory = useCallback(
    (name: string) => {
      localStorage.setItem('scrollTop', '0')

      dispatch(
        whiteboardActions.setSelectedCategory({ category: name.toLowerCase(), is4K: is4kDevice }),
      )
      if (name === 'pinned') {
        searchParams.delete('category')
      } else {
        searchParams.set('category', name)
      }
      searchParams.delete('family')

      searchParams.delete('searchTerm')
      setSearchParams(searchParams, { replace: true })
    },
    [dispatch, is4kDevice, searchParams, setSearchParams],
  )

  const wrapperWidth = (wrapperRef.current as HTMLElement)?.clientWidth || 0

  const productsQuery = useGetPlpProductsQuery()
  const products = productsQuery?.data?.products || []
  const filteredProducts = filtersApplied.size
    ? getFilteredProductsBySport(products, filtersApplied)
    : products

  const getPinnedItemsForWhiteboard = useGetPinnedItemsForWhiteboardMutation()
  const pinnedItems = getPinnedItemsForWhiteboard.data?.items
  const pinnedItemsFiltered = pinnedItems?.filter(item => item.invalid !== true)

  const handleFilterCategorySelection = () => {
    if (selectedCategory !== 'filter' || !is4kDevice) {
      dispatch(whiteboardActions.setSelectedCategory({ category: 'filter' }))
    }
  }

  useEffect(() => {
    if (is4kDevice) {
      if (!checkPinned) {
        handleSelectedCategory('pinned')
        setCheckPinned(true)
      } else if (pinnedItemsFiltered && pinnedItemsFiltered.length === 0 && !checkApparel) {
        const apparel = document.getElementById('whiteboard-category-apparel') as HTMLElement
        apparel.click()
        setCheckApparel(true)
      }
    }
  }, [
    filteredProducts,
    pinnedItemsFiltered,
    checkPinned,
    is4kDevice,
    checkApparel,
    handleSelectedCategory,
  ])

  return (
    <Wrapper id="whiteboard-categories" ref={wrapperRef}>
      <SelectionsContainer>
        <Categories>
          {categoriesNames.map(name => (
            <Selection
              className={[
                selectedSection === name.toLowerCase() && 'selected',
                'whiteboard-category',
              ]
                .filter(Boolean)
                .join(' ')}
              key={name}
              id={`whiteboard-category-${name.toLowerCase()}`}
              onClick={() => handleSelectedCategory(name)}
            >
              <RCIcon type={name.toLowerCase()} />
              <span>{name}</span>
            </Selection>
          ))}
        </Categories>
        <Selection
          className={`filter ${selectedCategory === 'filter' ? 'selected' : ''}`.trim()}
          onClick={() => handleFilterCategorySelection()}
        >
          {selectedCategory === 'filter' ? (
            <RCIcon type="close"></RCIcon>
          ) : (
            <RCIcon type="filter-dark"></RCIcon>
          )}
          <span>{t('GenericWords.filter')}</span>
        </Selection>
      </SelectionsContainer>
      <IconWrapper
        onClick={() => toggleSidebar()}
        className={classnames({
          open: selectedSection || is4kDevice,
          moveY: selectedCategory === 'filter' && viewportWidth > parseInt(breakpointsCross.XL.max),
        })}
        style={{
          left: selectedSection ? wrapperWidth + getProductsListWidth() - 16 : wrapperWidth - 16,
        }}
      >
        <RCIcon arrow type="icon-chevron-right" />
      </IconWrapper>
    </Wrapper>
  )
}

export default Sidebar
