import React, { SVGProps } from 'react'

const ArrowFilterIcon: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="16"
      height="8"
      viewBox="0 0 16 8"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.243935 0.218361C0.541842 -0.0727886 1.00224 -0.0727885 1.30015 0.218361L7.58327 6.38544L13.9206 0.218362C14.2185 -0.0727874 14.6789 -0.0727873 14.9768 0.218362C15.2747 0.509512 15.2747 0.959471 14.9768 1.25062L8.31449 7.70885C8.12491 7.89413 7.85409 8 7.58327 8C7.31244 8 7.04162 7.89413 6.85204 7.70885L0.216851 1.22415C0.0814387 1.09181 0.000193262 0.906533 0.000193279 0.721256C0.000193295 0.535979 0.0814406 0.350701 0.243935 0.218361ZM7.25828 6.70306C7.25828 6.67659 7.25828 6.67659 7.25828 6.70306V6.70306Z"
        fill="#09538F"
      />
    </svg>
  )
}

export default ArrowFilterIcon
