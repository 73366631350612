import styled, { css } from 'styled-components/macro'
import {
  breakpointsCross,
  duration,
  getFluidSizeWithFullFormula as gF,
  palette,
  pxToRem,
} from '../../../../../style/theme'
import { getHexOpacity } from '../../../../../helpers/genericHelper'

export const BackgroundLayer = styled.div<{
  WBToolbarHeight: number
  WBToolbarWidth: number
  filtersOpen: boolean
}>`
  opacity: ${({ filtersOpen }) => (filtersOpen ? 1 : 0)};
  transition: all ${duration.enteringScreen}ms;
  pointer-events: ${({ filtersOpen }) => (filtersOpen ? 'initial' : 'none')};
  position: absolute;
  left: ${({ WBToolbarWidth }) => WBToolbarWidth}px;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(1, 14, 24, 0.58);
  display: flex;
  align-items: end;
  color: ${palette.white};
  line-height: 1;

  * {
    line-height: inherit;
    user-select: none;
  }

  & > div {
    opacity: ${({ filtersOpen }) => (filtersOpen ? 1 : 0)};
    transition: all ${duration.standard}ms;
  }

  @media (max-width: ${breakpointsCross.XL.max}) {
    top: ${({ WBToolbarHeight }) => WBToolbarHeight}px;
    left: 0;
    right: 0;
    align-items: start;
    background-color: ${getHexOpacity(palette.tangaroa, 30)};
  }
`

export const FiltersWrapper = styled.div`
  width: ${gF('px', 697, 1223, 1366, 3840)};
  height: ${gF('px', 523, 1280, 1366, 3840)};
  padding: ${gF('px', 40, 114, 1366, 3840)} ${gF('px', 32, 114, 1366, 3840)};
  padding-bottom: ${gF('px', 31, 116, 1366, 3840)};
  background-color: ${palette.tangaroa};

  @media (max-height: ${breakpointsCross.S.max}) {
    height: 70vh;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding-bottom: 1.5em;
  border-bottom: ${gF('px', 1, 2, 1366, 3840)} solid ${palette.cloudBurst};
`

export const Title = styled.div`
  font-family: 'GilmerRegular', sans-serif;
  font-size: ${gF('px', 28, 40, 1366, 3840)};
  letter-spacing: 0.28px;
  text-transform: capitalize;
`

export const Reset = styled.div`
  font-family: 'GilmerMedium', sans-serif;
  font-size: ${gF('px', 12, 24, 1366, 3840)};
  letter-spacing: 0.3px;
  text-decoration: underline;
  cursor: pointer;
`
export const WBCategories = styled.div`
  display: grid;
  grid-template-columns: 40% 1fr;
  grid-template-rows: 1fr 30%;
  padding-top: ${gF('px', 57, 66, 1366, 3840)};
  height: calc(100% - ${gF('px', 80, 60, 1366, 3840)});
`

export const CategoriesList = styled.ul`
  grid-column: 1/2;
  grid-row: 1/2;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  row-gap: ${gF('px', 16, 42, 1366, 3840)};

  @media (max-width: ${breakpointsCross.XL.max}) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

export const WBCategoriesItem = styled.li`
  width: 100%;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'GilmerMedium', sans-serif;
  font-size: ${gF('px', 16, 28, 1366, 3840)};
  letter-spacing: 0.28px;
  cursor: pointer;

  svg {
    width: ${gF('px', 12, 24, 1366, 3840)};
    height: ${gF('px', 12, 24, 1366, 3840)};
    fill: ${palette.white};
  }

  &.selected {
    span {
      color: ${palette.shadowBlue};
    }
    svg {
      fill: ${palette.shadowBlue};
    }
  }
`

export const OptionsList = styled.ul`
  grid-column: 2/3;
  grid-row: 1/2;
  height: ${gF('px', 252, 646, 1366, 3840)};
  overflow-y: auto;
`

export const WBOptionCategory = styled.li<{ checked: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: ${gF('px', 72, 69, 1366, 3840)};
  margin-bottom: ${gF('px', 17, 40, 1366, 3840)};
  cursor: pointer;

  div {
    ${({ checked }) =>
      checked
        ? css`
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 0a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm18.063 7.537a.607.607 0 0 0 0-.878l-.494-.478a.652.652 0 0 0-.905 0l-9.046 8.771a.326.326 0 0 1-.45 0l-3.833-3.716a.653.653 0 0 0-.905 0l-.493.478a.611.611 0 0 0 0 .878l4.758 4.613c.185.18.437.28.699.28.262 0 .513-.1.699-.28l9.97-9.668z' fill='%237888A5'/%3E%3C/svg%3E%0A");
          `
        : css`
            background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.75 1.125H2.25c-.621 0-1.125.504-1.125 1.125v13.5c0 .621.504 1.125 1.125 1.125h13.5c.621 0 1.125-.504 1.125-1.125V2.25c0-.621-.504-1.125-1.125-1.125zM2.25 0A2.25 2.25 0 0 0 0 2.25v13.5A2.25 2.25 0 0 0 2.25 18h13.5A2.25 2.25 0 0 0 18 15.75V2.25A2.25 2.25 0 0 0 15.75 0H2.25z' fill='%23fff'/%3E%3C/svg%3E%0A");
          `}
    width: ${gF('px', 18, 28, 1366, 3840)};
    height: ${gF('px', 18, 28, 1366, 3840)};
    margin-right: ${pxToRem(16)}rem;
    background-repeat: no-repeat;
    background-size: cover;
  }

  span {
    text-transform: capitalize;
    font-family: 'GilmerRegular', sans-serif;
    font-size: ${gF('px', 16, 28, 1366, 3840)};
    letter-spacing: 0.28px;
    ${({ checked }) =>
      checked &&
      css`
        color: ${palette.shadowBlue};
      `}
  }
`

export const WBShowResults = styled.div`
  grid-column: 2/3;
  grid-row: 2/3;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;

  div {
    width: ${gF('px', 356, 558, 1366, 3840)};
    height: ${gF('px', 56, 66, 1366, 3840)};
    line-height: ${gF('px', 56, 66, 1366, 3840)};
    text-transform: uppercase;
    text-align: center;
    border-radius: 4px;
    color: ${palette.tangaroa};
    background-color: ${palette.white};
    font-family: 'GilmerBold', sans-serif;
    font-size: ${gF('px', 13, 24, 1366, 3840)};
    letter-spacing: 0.3px;
  }
`

export const Results = styled.span`
  margin-left: ${gF('px', 5, 7.5, 1366, 3840)};
`
