import React, { SVGProps } from 'react'

const CalendarIconEmpty: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-icon="calendar-empty"
      width="1em"
      height="1em"
      viewBox="0 0 16.5 16.5"
      fill="currentColor"
      aria-hidden="true"
      focusable="false"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.725 0c.59 0 1.067.436 1.067.973v.433h4.03V.974C9.823.436 10.3 0 10.89 0c.59 0 1.067.435 1.067.973v.432h1.437C14.833 1.406 16 2.47 16 3.784v9.837C16 14.936 14.833 16 13.393 16H2.607C1.167 16 0 14.935 0 13.622V3.784C0 2.47 1.167 1.406 2.607 1.406H3.66V.973C3.659.436 4.136 0 4.725 0zM2.133 8.973v4.649c0 .239.213.432.474.432h10.786c.261 0 .474-.194.474-.432V8.973H2.133zm11.734-1.946H2.133V3.784c0-.239.213-.432.474-.432H3.66v.432c0 .537.477.973 1.066.973.59 0 1.067-.436 1.067-.973v-.432h4.03v.432c0 .538.478.973 1.067.973.59 0 1.067-.435 1.067-.973v-.432h1.437c.261 0 .474.193.474.432v3.243z"
      />
    </svg>
  )
}

export default CalendarIconEmpty
