import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
  width: 1em;
  height: 1em;
`

const WarningIcon: React.FC<{ className?: string }> = ({ className }) => (
  <Svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26.771 23.641"
    height="32"
    width="32"
    fill="currentColor"
  >
    <path d="M13.576.137c-.386 0-.44.131-.504.183-.064.052-.097.09-.13.128a2.918 2.918 0 00-.188.236c-.13.178-.29.415-.484.714a99.615 99.615 0 00-1.518 2.448c-1.22 2.018-2.8 4.706-4.36 7.406-1.559 2.7-3.097 5.41-4.234 7.477a99.744 99.744 0 00-1.361 2.538c-.162.318-.288.574-.377.776a2.94 2.94 0 00-.111.281c-.016.048-.03.095-.043.176-.013.081-.102.195.092.53.193.333.335.314.411.343.077.03.126.039.175.049.098.02.188.033.298.045.22.024.506.042.862.06.711.037 1.7.066 2.879.09 2.358.048 5.475.072 8.593.073 3.118 0 6.234-.023 8.592-.07a99.01 99.01 0 002.879-.093c.356-.018.641-.036.861-.06.11-.012.201-.025.3-.045.048-.01.096-.02.173-.049.077-.029.219-.01.412-.344.193-.334.105-.448.092-.53-.013-.08-.03-.127-.045-.175a2.939 2.939 0 00-.111-.28 16.17 16.17 0 00-.378-.777c-.324-.634-.792-1.503-1.36-2.536a451.711 451.711 0 00-4.235-7.479c-1.56-2.7-3.14-5.388-4.36-7.406-.61-1.01-1.13-1.85-1.517-2.448a16.306 16.306 0 00-.482-.714 2.922 2.922 0 00-.188-.236c-.033-.038-.067-.076-.13-.128-.064-.051-.118-.183-.503-.183zm-.002 2.006l.047.07c.371.573.886 1.406 1.492 2.408A450.914 450.914 0 0119.457 12c1.556 2.695 3.091 5.4 4.22 7.452a98.123 98.123 0 011.339 2.498l.035.072-.08.006c-.682.035-1.662.066-2.832.09-2.341.048-5.453.07-8.563.07-3.11 0-6.223-.022-8.564-.07a98.407 98.407 0 01-2.832-.09l-.082-.006c.015-.03.018-.042.035-.074.31-.608.775-1.47 1.34-2.496a449.782 449.782 0 014.219-7.451 450.602 450.602 0 014.343-7.381 99.217 99.217 0 011.492-2.408l.047-.07zm.063 5.507c-.497 0-.993.139-1.041.418-.096.557.52 7.986.621 8.542.102.557.837.557.931 0 .095-.556.634-7.985.534-8.542-.05-.279-.548-.418-1.045-.418zm.049 10.486a1.09 1.09 0 00-1.09 1.09 1.09 1.09 0 001.09 1.09 1.09 1.09 0 001.09-1.09 1.09 1.09 0 00-1.09-1.09z" />
  </Svg>
)

export default WarningIcon
