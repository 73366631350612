import styled from 'styled-components/macro'
import {
  breakpoints,
  breakpointsCross,
  getFluidSizeWithFullFormula as gF,
  palette,
  pxToRem,
} from '../../style/theme'
import AfaHeader from '../../components/Header/AfaHeader'
import AfaFooter from '../../components/AfaFooter'

import Icon from '../../styleguide/Icon'
import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useDispatch, useSelector } from 'react-redux'
import {
  getSelectedSwitch,
  openCreateNewWhiteboardSelector,
  openTemplateModalSelector,
  previewIsUpdatingSelector,
  whiteboardsGridFiltersSelector,
} from '../../store/whiteboard/selectors'
import {
  useDuplicateWhiteboardMutation,
  useGetVisibleWhiteboardsQuery,
} from '../../services/whiteboard'
import AfaModal from '../../components/AfaModal'
import whiteboardActions from '../../store/whiteboard/actions'
import WhiteboardModalCreation from './WhiteboardModalCreation'
import WhiteboardCard from './components/WhiteboardCard'
import { firstLetterCapital } from '../../helpers/genericHelper'
import { viewportSelector } from '../../store/viewport/selectors'
import { useGet4KDevice } from '../../hooks/useGet4KDevice'
import classnames from 'classnames'
import WhiteboardToolbar from './WhiteboardToolbar'
import { errorNotification, successNotification } from '../../components/Notification/notifications'
import Loading from '../../components/Loading'
import WhiteboardFilters from './WhiteboardPage/components/Filters/WhiteboardFilters'
import { Owner } from './WhiteboardPage/components/Filters/WhiteboardFilters'
import WhiteboardModalTemplate from './WhiteboardPage/components/ModalTemplate/WhiteboardModalTemplate'
import AfaSearch from '../../components/AfaSearch/AfaSearch'
import SearchWB from './WhiteboardPage/components/SearchWB/SearchWB'

const Wrapper = styled.div`
  height: 100%;
  display: grid;
  font-family: 'GilmerMedium', sans-serif;
  grid-template-rows: min-content auto min-content;
`

const Main = styled.main`
  position: relative;
  background-color: ${palette.lightGray};
  overflow: scroll;

  display: grid;
  grid-template-rows: min-content min-content;

  @media (min-width: ${breakpoints.XL}) {
    grid-template-columns: ${pxToRem(266)}rem auto;
    grid-template-rows: 100%;
    grid-auto-rows: minmax(0, 1fr);
    overflow: hidden;
  }
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${pxToRem(14)}rem;
  font-family: 'GilmerMedium', sans-serif;
  font-weight: bold;
  padding: 0 ${pxToRem(32)}rem;
  margin-top: ${pxToRem(15)}rem;

  @media (min-width: ${breakpoints.XL}) {
    display: none;
  }

  i {
    font-size: ${pxToRem(16)}rem;
    margin-right: 6px;
  }
`

const SearchWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 2rem;
  z-index: 2;
`

const NewWhiteboardWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding: ${pxToRem(95)}rem 0 ${pxToRem(64)}rem;

  @media (min-width: ${breakpointsCross.XL.min}) {
    position: fixed;
    width: 100%;
    bottom: ${pxToRem(90)}rem;
    padding-right: ${pxToRem(325)}rem;
    background-color: ${palette.lightGray};
    padding-top: ${pxToRem(32)}rem;
  }
`

export const NewWhiteboardButton = styled.button`
  font-size: ${gF('px', 13, 26, 1366, 3840)};
  background-color: ${palette.tangaroa};
  color: ${palette.white};
  text-transform: uppercase;
  padding: ${pxToRem(18)}rem ${pxToRem(20)}rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &.hide {
    visibility: hidden;
  }

  i {
    margin-right: ${pxToRem(6)}rem;
  }
`

const GridContainer = styled.div`
  overflow: auto;
  position: relative;
  padding: ${pxToRem(86)}rem ${pxToRem(32)}rem ${pxToRem(15)}rem;
  display: flex;
  flex-direction: column;
  padding-top: ${pxToRem(24)}rem;

  h1 {
    font-size: ${gF('px', 30, 64, 1366, 3840)};
    color: ${palette.tangaroa};
  }
`

const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpointsCross.XL.min}) {
    padding-bottom: ${pxToRem(120)}rem;
  }
`

const NoResults = styled.div`
  position: relative;
  height: 100%;

  span {
    position: absolute;
    top: 50%;
    left: 39%;
    white-space: nowrap;
    font-size: ${gF('px', 16, 32, 1366, 3840)};
    color: ${palette.tangaroa};
  }
`

const Grid = styled.div`
  display: grid;
  gap: ${gF('px', 16, 32, 1366, 3840)};
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @media (min-width: ${breakpoints.S}) and (max-width: ${breakpointsCross.M.min}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (min-width: ${breakpoints.M}) and (max-width: ${breakpointsCross.L.min}) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (min-width: ${breakpoints.L}) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  grid-auto-columns: minmax(0, 1fr);
`

const NoWhiteboards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${pxToRem(86)}rem 0;

  @media (min-width: ${breakpoints.XL}) {
    justify-content: flex-start;
  }

  button {
    margin-top: ${pxToRem(48)}rem;
    font-size: ${gF('px', 14, 24, 1366, 3840)};

    @media (min-width: ${breakpoints.XL}) {
      margin-top: auto;
    }
  }

  h1 {
    font-size: ${gF('px', 30, 64, 1366, 3840)};
    color: ${palette.tangaroa};
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: ${pxToRem(148)}rem;
`

const BodyWrapper = styled.div`
  text-align: center;

  h2 {
    color: ${palette.santasGray};
    font-size: ${gF('px', 26, 48, 1366, 3840)};
    text-align: center;
  }
`

const Title = styled.h1`
  margin-top: ${pxToRem(40)}rem;
  margin-bottom: ${pxToRem(64)}rem;
  text-align: center;
  font-size: ${pxToRem(64)}rem;
  color: ${palette.tangaroa};

  @media (max-width: ${breakpointsCross.XL.max}) {
    display: none;
  }
`

const BoardNumber = styled.div`
  width: 100%;
  text-align: center;
  display: none;
  font-size: ${pxToRem(32)}rem;
  color: ${palette.santasGray};
  margin-bottom: ${pxToRem(48)}rem;
  margin-top: -${pxToRem(60)}rem;

  @media (min-width: ${breakpointsCross.XL.max}) {
    display: block;
  }
`

const getSingular = (string: string) => string.replace('s', '')

const WhiteboardsListPage = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const whiteboardSwitchSelection = useSelector(getSelectedSwitch)
  const openCreateNewWhiteboard = useSelector(openCreateNewWhiteboardSelector)
  const isTemplateModalOpen = useSelector(openTemplateModalSelector)
  const [searchTextValue, setSearchTextValue] = useState('')
  const [duplicateWhiteboard, duplicateWhiteboardResult] = useDuplicateWhiteboardMutation()
  const whiteboardsQuery = useGetVisibleWhiteboardsQuery(searchTextValue, 'whiteboard')
  const templatesQuery = useGetVisibleWhiteboardsQuery(searchTextValue, 'template')

  const whiteboardsDataToShow =
    whiteboardSwitchSelection === 'whiteboard' ? whiteboardsQuery?.data : templatesQuery?.data
  const whiteboardsToShow = whiteboardsDataToShow?.whiteboards || []

  useEffect(() => {
    if (templatesQuery.data) {
      dispatch(whiteboardActions.setTemplates(templatesQuery.data))
    }
  }, [templatesQuery, dispatch])

  useEffect(() => {
    if (whiteboardsQuery.data) {
      dispatch(whiteboardActions.setWhiteboards(whiteboardsQuery.data))
    }
  }, [whiteboardsQuery, dispatch])

  useEffect(() => {
    dispatch(whiteboardActions.resetWhiteboardsGridFilters())
  }, [dispatch])

  const totalBoards = whiteboardsDataToShow?.total || 0
  const { width: viewportWidth } = useSelector(viewportSelector)
  const is4kDevice = useGet4KDevice()
  const previewIsUpdating = useSelector(previewIsUpdatingSelector)
  const filtersApplied = useSelector(whiteboardsGridFiltersSelector)
  const filteredWhiteboards = filtersApplied.length
    ? whiteboardsToShow.filter(whiteboard => {
        const whiteboardFilters = new Set([
          ...whiteboard.sports,
          ...whiteboard.subcategories,
          whiteboard.release,
          whiteboard.isOwner ? ('created by me' as Owner) : ('created by others' as Owner),
        ])

        return filtersApplied.every(
          ({ filters }) =>
            filters.length < 1 || filters.some(filter => whiteboardFilters.has(filter)),
        )
      })
    : whiteboardsToShow

  const noResults = !filteredWhiteboards.length && !!filtersApplied.length

  useEffect(() => {
    dispatch(whiteboardActions.setFilteredWhiteboardsLength(filteredWhiteboards.length))
  }, [filteredWhiteboards.length, dispatch])

  /**
   * Based on current UI, this function checks whether the card looped is the first one on the left of each row of the grid. Counting rows of 4 products for iPad viewports and 5 products on 4k viewport.
   * This function is needed to know in which position to open the card menu.
   * https://app.zeplin.io/project/62ac499898244506156291a4/screen/62e3f3452267f06457b7b966
   * https://app.zeplin.io/project/62ac499898244506156291a4/screen/633adb347bcd59727f71f683
   * @returns {boolean}
   */
  const checkIfCardIsFirstOfRow = (index: number): boolean => {
    let isFirst: boolean
    if (viewportWidth >= parseInt(breakpoints.XL)) {
      isFirst = index % 5 === 0
    } else {
      isFirst = index % 4 === 0
    }
    return isFirst
  }

  const handleNotification = useCallback(
    (result: any, messages: { result: string; error: string }) => {
      if (result.status === 'fulfilled') {
        if (result.error || result.isError) {
          errorNotification({ message: messages.error })
        } else if (result.data.warnings.length) {
          result.data.warnings.forEach((warning: { message: string }) => {
            errorNotification({ message: warning.message })
          })
        } else if (result.data.result) {
          successNotification({ message: messages.result })
        }
      } else if (result.status === 'rejected') {
        errorNotification({ message: messages.error })
      }
    },
    [],
  )

  useEffect(() => {
    const actions = {
      DUPLICATE: 'duplicate',
      SAVE_AS_TEMPLATE: 'saveAsTemplate',
      SAVE_AS_WHITEBOARD: 'saveAsWhiteboard',
    }

    const messages: { result: Record<string, string>; error: Record<string, string> } = {
      result: {
        saveAsTemplate: t('Whiteboard.whiteboardSavedAsTemplate'),
        saveAsWhiteboard: t('Whiteboard.templateSavedAsWhiteboard'),
        duplicate: t('Whiteboard.whiteboardDuplicated'),
      },
      error: {
        saveAsTemplate: t('Whiteboard.errorWhiteboardSavedAsTemplate'),
        saveAsWhiteboard: t('Whiteboard.errorTemplateSavedAsWhiteboard'),
        duplicate: t('Whiteboard.errorDuplicateWhiteboard'),
      },
    }

    const result = duplicateWhiteboardResult
    const isSaveAsTemplateAction = result?.originalArgs?.type === 'template'
    const isSaveAsWhiteboardAction = result?.originalArgs?.type === 'whiteboard'

    let type: string

    if (isSaveAsTemplateAction) {
      type = actions.SAVE_AS_TEMPLATE
    } else if (isSaveAsWhiteboardAction) {
      type = actions.SAVE_AS_WHITEBOARD
    } else {
      type = actions.DUPLICATE
    }

    const notificationData = {
      result: messages.result[type],
      error: messages.error[type],
    }

    handleNotification(result, notificationData)
  }, [duplicateWhiteboardResult, t, handleNotification])

  const [lastWhiteboardVisitedkey, setLastWhiteboardVisitedKey] = useState<string>('')

  useEffect(() => {
    const lastWhiteboardKey = localStorage.getItem('lastWhiteboardVisitedKey')
    if (lastWhiteboardKey) {
      setLastWhiteboardVisitedKey(lastWhiteboardKey)
    }
  }, [])

  const [queryIsFetching, setQueryIsFetching] = useState(false)

  useEffect(() => {
    if (whiteboardsQuery.isFetching) {
      setQueryIsFetching(true)
    }
    if (queryIsFetching && !whiteboardsQuery.isFetching) {
      dispatch(whiteboardActions.setPreviewIsUpdating(false))
      setQueryIsFetching(false)
    }
  }, [whiteboardsQuery, dispatch, queryIsFetching])

  const whiteboardToolbar = document.getElementById('whiteboard-toolbar') as HTMLElement
  const whiteboardToolbarWidth = whiteboardToolbar?.clientWidth || 0

  return (
    <Wrapper>
      <AfaHeader />
      <Main>
        <WhiteboardToolbar totalBoards={totalBoards} filteredBoards={filteredWhiteboards?.length} />

        {!is4kDevice && (!!totalBoards || !!searchTextValue.length) && (
          <Actions>
            <SearchWB
              setSearchTextValue={setSearchTextValue}
              whiteboardSwitchSelection={whiteboardSwitchSelection}
            />
            <NewWhiteboardButton
              className={classnames({ hide: !totalBoards })}
              onClick={() => dispatch(whiteboardActions.setOpenCreateNewWhiteboard(true))}
            >
              <Icon type="plus" />
              {t('Whiteboard.new')} {t(`Whiteboard.${whiteboardSwitchSelection}`)}
            </NewWhiteboardButton>
          </Actions>
        )}

        {!totalBoards && !searchTextValue.length && (
          <NoWhiteboards>
            <Title>
              {whiteboardSwitchSelection === 'whiteboard'
                ? t('Whiteboard.whiteboardTitle')
                : t('Whiteboard.yourTemplate')}
            </Title>
            <InfoWrapper>
              <BodyWrapper>
                <h2>
                  {t(`Whiteboard.no${firstLetterCapital(whiteboardSwitchSelection)}s.topLine`, {
                    type: getSingular(whiteboardSwitchSelection),
                  })}
                </h2>
                <h2>
                  {t(`Whiteboard.no${firstLetterCapital(whiteboardSwitchSelection)}s.bottomLine`, {
                    type: getSingular(whiteboardSwitchSelection),
                  })}
                </h2>
              </BodyWrapper>
            </InfoWrapper>
            <NewWhiteboardButton
              onClick={() => dispatch(whiteboardActions.setOpenCreateNewWhiteboard(true))}
            >
              <Icon type="plus" />
              {t('Whiteboard.new')} {t(`Whiteboard.${whiteboardSwitchSelection}`)}
            </NewWhiteboardButton>
          </NoWhiteboards>
        )}

        {
          <GridContainer id="wb-grid-container">
            {!!totalBoards && (
              <GridWrapper>
                <Title>
                  {' '}
                  {whiteboardSwitchSelection === 'whiteboard'
                    ? t('Whiteboard.whiteboardTitle')
                    : t('Whiteboard.yourTemplate')}
                </Title>
                <BoardNumber>
                  {filteredWhiteboards?.length} {t('Whiteboard.boards')}
                </BoardNumber>
                {noResults ? (
                  <NoResults>
                    <span>{t('Whiteboard.noSearchResults')}</span>
                  </NoResults>
                ) : (
                  <Grid>
                    {filteredWhiteboards.map((whiteboard, index) => {
                      const isLastWhiteboardVisited =
                        (lastWhiteboardVisitedkey && lastWhiteboardVisitedkey === whiteboard.key) ||
                        false
                      const currentPreviewIsUpdating = previewIsUpdating && isLastWhiteboardVisited

                      return (
                        <WhiteboardCard
                          whiteboardCardData={whiteboard}
                          key={whiteboard.key}
                          isFirstCardOfRow={checkIfCardIsFirstOfRow(index)}
                          duplicateWhiteboard={duplicateWhiteboard}
                          currentPreviewIsUpdating={currentPreviewIsUpdating}
                        />
                      )
                    })}
                  </Grid>
                )}
              </GridWrapper>
            )}

            {!totalBoards && !!searchTextValue.length && (
              <NoResults>
                <span>{t('Whiteboard.noSearchResults')}</span>
              </NoResults>
            )}

            {is4kDevice && (!!totalBoards || !!searchTextValue.length) && (
              <NewWhiteboardWrapper>
                <SearchWrapper>
                  <SearchWB
                    setSearchTextValue={setSearchTextValue}
                    whiteboardSwitchSelection={whiteboardSwitchSelection}
                  />
                </SearchWrapper>

                <NewWhiteboardButton
                  style={{ marginLeft: `-${whiteboardToolbarWidth}px` }}
                  onClick={() => dispatch(whiteboardActions.setOpenCreateNewWhiteboard(true))}
                >
                  <Icon type="plus" />
                  {t('Whiteboard.new')} {t(`Whiteboard.${whiteboardSwitchSelection}`)}
                </NewWhiteboardButton>
              </NewWhiteboardWrapper>
            )}
          </GridContainer>
        }

        <AfaModal
          isOpen={openCreateNewWhiteboard}
          onRequestClose={() => {
            dispatch(whiteboardActions.setOpenCreateNewWhiteboard(false))
          }}
          fullscreen={false}
        >
          <WhiteboardModalCreation />
        </AfaModal>

        <AfaModal
          isOpen={isTemplateModalOpen}
          onRequestClose={() => {
            dispatch(whiteboardActions.setOpenTemplateModal(false))
          }}
          fullscreen={false}
        >
          <WhiteboardModalTemplate />
        </AfaModal>

        <AfaSearch />

        <WhiteboardFilters searchTextValue={searchTextValue} />
      </Main>
      <AfaFooter />

      {(duplicateWhiteboardResult.isLoading ||
        (whiteboardsQuery.isFetching && !previewIsUpdating)) && <Loading isFullPage={false} />}
    </Wrapper>
  )
}

export default WhiteboardsListPage
