import { format } from 'date-fns'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { isStickyHeaderSelector } from '../../store/app/selectors'
import {
  iconButtonStyle,
  XSHeight,
  scrollMaxHeight,
} from '../../style/DigitalEventsCommonComponents'
import { breakpoints, palette, pxToRem, spacing } from '../../style/theme'
import Icon from '../../styleguide/Icon'
import BrandsIcon from '../icons/BrandsIcon'
import CalendarIcon from '../icons/CalendarIcon'
import DashboardIcon from '../icons/DashboardIcon'
import HomeIconDE from '../icons/HomeIconDE'
import app_config from '../../config/app/config'

const StyledBrandsIcon = styled(BrandsIcon)`
  width: 70% !important;
`

const stickyCss = css`
  position: sticky;
  top: 0;
  backdrop-filter: blur(10px);
  mask-image: linear-gradient(rgba(0, 0, 0, 1) 65%, rgba(0, 0, 0, 0));
  z-index: 2;
`

const Header = styled.header`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  width: 100%;
  padding: ${pxToRem(spacing(6))}rem;

  @media (max-width: ${breakpoints.S}) {
    padding: ${pxToRem(spacing(3))}rem;
  }

  @media (max-height: ${scrollMaxHeight}) {
    ${stickyCss}
  }

  &.sticky {
    ${stickyCss}
  }
`

const LateralCol = styled.div`
  display: grid;
  gap: ${pxToRem(spacing(4))}rem;
  grid-auto-flow: column;
  align-items: center;
  place-self: center start;

  &:last-child {
    place-self: center end;
  }

  @media (max-width: ${breakpoints.S}) {
    gap: ${pxToRem(spacing(2.5))}rem;
  }
`

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  place-self: center;
`

const Logo = styled.img`
  max-width: 340px;
  filter: brightness(1);

  @media (max-width: ${breakpoints.S}) {
    max-width: 250px;
  }
`

const DateStyled = styled.div`
  opacity: 0.6;
  text-shadow: 0 16px 42px rgba(0, 0, 0, 0.5);
  font-size: 1rem;
  text-transform: uppercase;
  color: ${palette.white};

  @media (max-width: ${breakpoints.S}) and (max-height: ${XSHeight}) {
    font-size: ${pxToRem(spacing(1.75))}rem;
  }
`

const ButtonLink = styled(Link)`
  ${iconButtonStyle}

  &.active {
    background-color: ${palette.white};
    color: ${palette.black};
    fill: ${palette.black};
  }
`

const Button = styled.button`
  ${iconButtonStyle}
`

const HeaderLinkButton: React.FC<{ link: string; active: boolean; onClick?: () => void }> = ({
  link,
  children,
  active,
  onClick,
}) => {
  return (
    <ButtonLink to={active ? '#' : link} onClick={onClick} className={active ? 'active' : ''}>
      {children}
    </ButtonLink>
  )
}

const HeaderButton: React.FC<{ onClick?: () => void }> = ({ children, onClick }) => {
  return <Button onClick={onClick}>{children}</Button>
}

const DigitalEventsHeader: React.FC<{
  currentPage?: 'home' | 'dashboard' | 'calendar' | 'brands'
}> = ({ currentPage }) => {
  const history = useHistory()
  const isStickyHeader = useSelector(isStickyHeaderSelector)
  const logoPath = `${app_config.publicUrl}/assets/lux-red-carpet-logo-new-white.svg`

  const homePath = '/digital-events'
  return (
    <Header className={isStickyHeader ? 'sticky' : ''}>
      <LateralCol>
        {currentPage !== 'home' && (
          <>
            <HeaderButton
              aria-label="back"
              onClick={() => {
                if (!history.length) {
                  history.push(homePath)
                } else {
                  history.goBack()
                }
              }}
            >
              <Icon type="left" />
            </HeaderButton>
            <HeaderLinkButton link={homePath} active={false}>
              <HomeIconDE />
            </HeaderLinkButton>
          </>
        )}
        <DateStyled>{format(new Date(), 'MMM d Y p')}</DateStyled>
      </LateralCol>
      <LogoLink to="/digital-events">
        <Logo src={logoPath} alt="red-carpet-logo" />
      </LogoLink>
      <LateralCol>
        <HeaderLinkButton link="/digital-events/dashboard" active={currentPage === 'dashboard'}>
          <DashboardIcon />
        </HeaderLinkButton>
        <HeaderLinkButton link="/digital-events/calendar" active={currentPage === 'calendar'}>
          <CalendarIcon />
        </HeaderLinkButton>
        <HeaderLinkButton link="/digital-events/brands" active={currentPage === 'brands'}>
          <StyledBrandsIcon />
        </HeaderLinkButton>
      </LateralCol>
    </Header>
  )
}

export default DigitalEventsHeader
