import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import config from '../../../../config/app/config'
import { useTranslation } from 'react-i18next'
import Footer from '../../Components/Footer'
import { useGetMapDataQuery } from '../../services'
import { useGetAddressAutocomplete } from '../../addressServices'
import { MapData, Positioning } from '../../Model/aaModel'
import { useGetSelectedDoorId, usePreselectDefaultDoor } from '../../Hooks/usePreselectDefaultDoor'
import { useSearchParams } from '../../../../hooks/useSearchParams'
import {
  breakpointsCross,
  getFluidSizeWithFullFormula as gF,
  palette,
} from '../../../../style/theme'
import SearchAddress from '../../Components/SearchAddress'
import { useDispatch, useSelector } from 'react-redux'
import { viewportSelector } from '../../../../store/viewport/selectors'
import MapControls from './MapControls'
import BrandsOverlay from './BrandsOverlay'
import { brandsSelector } from '../../../../store/brands/selectors'
import MapViewSwitcher from './MapViewSwitcher'
import assortmentAdvisorSelectors from '../../../../store/assortmentAdvisor/selectors'
import assortmentAdvisorActions from '../../../../store/assortmentAdvisor/actions'
import DemographicsStatistics from '../../Components/DemographicsStatistics'
import { useHandleOpenAaMapPage } from '../../../../hooks/useHandleOpenPages'
import { getDeviceFromUrl } from '../../../../libs/url'
import useZoomBlocker from '../../Hooks/useZoomBlocker'
import Loading from '../../../../components/Loading'
import GoogleMapReact from 'google-map-react'
import { MAP_INITIAL_ZOOM, MAP_MAX_ZOOM, MAP_MIN_ZOOM, POSITIONINGS } from '../../consts'
import { appSelectors, languageSelector } from '../../../../store/app/selectors'
import useBlockTwoFingersTouch from '../../Hooks/useBlockTwoFingersTouch'
import { createGradients } from '../../../../helpers/genericHelper'
import DebugOverlay, { DefaultInputs } from './DebugOverlay'
import { DebuggerFeatures } from '../../../../Debug'

const Wrapper = styled.div`
  font-family: EuclidCircularB-Regular, sans-serif;
  width: 100%;
  height: 100%;
  padding: ${gF('px', 12, 24, 1366, 3840)};
  padding-bottom: 0;
  padding-top: ${gF('px', 0, 50, 1366, 3840)};
  background-image: radial-gradient(
    circle at 13% -33%,
    ${palette.nileBlue},
    ${palette.blueZodiac} 39%,
    ${palette.blackPearl} 90%
  );
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-bottom: ${gF('px', 26, 52, 1366, 3840)};
`

const MapViewSwitcherWithMargin = styled(MapViewSwitcher)`
  margin-left: auto;
`

const Title = styled.div`
  font-size: ${gF('px', 44, 88, 1366, 3840)};
  color: white;

  span.blue {
    color: ${palette.cornflowerBlueAA};
  }
`

const Content = styled.div`
  position: relative;
  border-radius: 1vw;
  overflow: hidden;
  flex: 1;
`

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .gmnoprint {
    display: none;
  }
`

const Legend = styled.div`
  position: absolute;
  bottom: 1vw;
  right: 1vw;
  border-radius: 1vw;
  background-color: ${palette.blueZodiac};
  padding: 1.5vw;
  display: flex;
  flex-direction: column;
  gap: 1vw;
  -webkit-backdrop-filter: blur(18px);
  backdrop-filter: blur(18px);
`

const LegendLabels = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 3vw;
  line-height: 1;
`

const LegendLabelValue = styled.div`
  font-size: ${gF('px', 13, 26, 1366, 3840)};
`

const LegendLabelName = styled.div`
  font-size: ${gF('px', 20, 40, 1366, 3840)};
`

const ZoomWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 1vh;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 15px;
  overflow: hidden;
  background-color: ${palette.blueZodiac};
  border: ${gF('px', 1, 2, 1366, 3840)} solid rgba(110, 141, 253, 0.5);
  padding: ${gF('px', 5, 10, 1366, 3840)} 0;
  cursor: pointer;

  div {
    padding: ${gF('px', 2.5, 5, 1366, 3840)} ${gF('px', 10, 20, 1366, 3840)};
    font-size: ${gF('px', 30, 60, 1366, 3840)};
    line-height: ${gF('px', 15, 30, 1366, 3840)};
    color: ${palette.cornflowerBlueAA};
    user-select: none;

    &:first-of-type {
      border-right: ${gF('px', 1, 2, 1366, 3840)} solid rgba(110, 141, 253, 0.5);
    }
    &:active {
      color: ${palette.cornFlower};
    }
  }
`

const getGradient = (field: Positioning | 'brands', inputs: DefaultInputs) => {
  const startingOpacity = !isNaN(inputs.colorStartingOpacity) ? inputs.colorStartingOpacity : 0
  const endingOpacity = !isNaN(inputs.colorEndingOpacity) ? inputs.colorEndingOpacity : 0
  const steps = !isNaN(inputs.colorSteps) ? inputs.colorSteps : 0

  const gradientMap: Record<Positioning | 'brands', string[]> = {
    innovative: createGradients(palette.AAinnovative, startingOpacity, endingOpacity, steps),
    sophisticated: createGradients(palette.AAsophisticated, startingOpacity, endingOpacity, steps),
    young: createGradients(palette.AAyoung, startingOpacity, endingOpacity, steps),
    everyday: createGradients(palette.AAeveryday, startingOpacity, endingOpacity, steps),
    sport: createGradients(palette.AAsport, startingOpacity, endingOpacity, steps),
    brands: createGradients(palette.AAbrands, startingOpacity, endingOpacity, steps),
  }

  return gradientMap[field]
}

const LegendGradient = styled.div`
  width: 100%;
  height: 0.5vw;
  border-radius: 3rem;

  &.innovative {
    background-image: linear-gradient(
      to right,
      ${palette.AAinnovativeDarker},
      ${palette.AAinnovative}
    );
  }

  &.sophisticated {
    background-image: linear-gradient(
      to right,
      ${palette.AAsophisticatedDarker},
      ${palette.AAsophisticated}
    );
  }

  &.young {
    background-image: linear-gradient(to right, ${palette.AAyoungDarker}, ${palette.AAyoung});
  }

  &.sport {
    background-image: linear-gradient(to right, ${palette.AAsportDarker}, ${palette.AAsport});
  }

  &.everyday {
    background-image: linear-gradient(to right, ${palette.AAeverydayDarker}, ${palette.AAeveryday});
  }

  &.brands {
    background-image: linear-gradient(to right, ${palette.AAbrandsDarker}, ${palette.AAbrands});
  }
`

type HeatmapPoint = {
  weight: number
  location: google.maps.LatLng
}

declare global {
  interface Window {
    initMap: () => void
  }
}

export interface MarkerMap {
  [key: string]: google.maps.Marker
}

export interface MarkerCircles {
  [key: string]: google.maps.Circle
}

const defaultSelectedPositioning = POSITIONINGS[0] as Positioning

export const DEFAULT_INPUTS = {
  logarithmicBase: 6.5,
  radius: 85,
  maxIntensity: 9,
  mapOpacity: 0.98,
  colorStartingOpacity: 50,
  colorEndingOpacity: 95,
  colorSteps: 5,
} as DefaultInputs

const HeatMap: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  useZoomBlocker()
  useBlockTwoFingersTouch()

  const [searchParams, setSearchParams] = useSearchParams()
  const selectedPositioning = searchParams.get('positioning') as Positioning | null
  const showDebugOverlay = useSelector(
    appSelectors.isActiveDebugger(DebuggerFeatures.FRAMES_ASSORTMENT_ADVISOR_OVERLAY),
  )
  const language = useSelector(languageSelector)
  const { width } = useSelector(viewportSelector)
  const brands = useSelector(brandsSelector)
  const selectedDoorIdQuery = useGetSelectedDoorId()
  const searchedAddress = useSelector(assortmentAdvisorSelectors.searchedAddressSelector)
  const mapView = searchParams.get('mapView') || 'trends'
  const selectedDoorId = useSelector(assortmentAdvisorSelectors.getSelectedDoorId)
  const selectedBrandCode = searchParams.get('mapBrand') || 'All'
  const selectedBrand =
    selectedBrandCode === 'All'
      ? 'All'
      : brands.find(({ code }) => code === selectedBrandCode)?.brand || ''

  const [map, setMap] = useState<google.maps.Map>()
  const [heatmapLayer, setHeatmapLayer] = useState<google.maps.visualization.HeatmapLayer>()
  const [addressToSearch, setAddressToSearch] = useState('')
  const [demographicsToggle, setDemographicsToggle] = useState<'age' | 'income'>('age')
  const [, setMarkers] = useState<MarkerMap>({})
  const [zipCodeMarkers, setZipCodeMarkers] = useState<MarkerMap>({})
  const [demographicsCircles, setDemographicsCircles] = useState<MarkerCircles>({})
  const deviceType = getDeviceFromUrl()
  const isWall = deviceType === 'wall'
  const isDragged = useSelector(assortmentAdvisorSelectors.mapDraggedSelector)
  const [heatmapPoints, setHeatmapPoints] = useState<HeatmapPoint[]>([])
  const [bounds, setBounds] = useState<google.maps.LatLngBounds>()
  const [encodedBounds, setEncodedBounds] = useState('')
  const currentZoom = map?.getZoom()

  const [defaultInputs, setDefaultInputs] = useState<DefaultInputs>(DEFAULT_INPUTS)

  useEffect(() => {
    if (heatmapLayer) {
      const { mapOpacity, maxIntensity, radius } = defaultInputs
      heatmapLayer.setOptions({
        opacity: !isNaN(mapOpacity) ? mapOpacity : 0,
        maxIntensity: !isNaN(maxIntensity) ? maxIntensity : 0,
        radius: !isNaN(radius) ? radius : 0,
      })
    }
  }, [defaultInputs, heatmapLayer])

  useEffect(() => {
    if (bounds) {
      const boundsObject = {
        northeast: {
          lat: bounds
            .getNorthEast()
            .lat()
            .toString(),
          lng: bounds
            .getNorthEast()
            .lng()
            .toString(),
        },
        southwest: {
          lat: bounds
            .getSouthWest()
            .lat()
            .toString(),
          lng: bounds
            .getSouthWest()
            .lng()
            .toString(),
        },
      }

      const boundsString = JSON.stringify(boundsObject)
      setEncodedBounds(boundsString)
    }
  }, [bounds])

  const mapParams = useMemo(() => {
    return mapView === 'trends'
      ? selectedPositioning || defaultSelectedPositioning
      : selectedBrandCode
  }, [mapView, selectedBrandCode, selectedPositioning])

  const mapDataQuery = useGetMapDataQuery(
    {
      param: encodeURIComponent(`${mapParams}III${encodedBounds}`),
    },
    { skip: !mapParams || !encodedBounds },
  )

  let initialZoomLevel: number | undefined

  const defaultProps = {
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
    scaleControl: false,
    keyboardShortcuts: false,
    minZoom: MAP_MIN_ZOOM,
    zoom: MAP_INITIAL_ZOOM,
    maxZoom: MAP_MAX_ZOOM,
    scrollwheel: true,
    center: {
      lat: parseFloat(searchedAddress?.latlng.lat.toString() || '41.9028'),
      lng: parseFloat(searchedAddress?.latlng.lng.toString() || '12.4964'),
    }, // default to Rome
    mapId: config.googleMapId,
    gestureHandling: 'greedy',
  }

  const { data: customAddresses } = useGetAddressAutocomplete(addressToSearch)

  const setMarker = useCallback(
    ({ lat, lng }) => {
      if (map) {
        const svgMarker = {
          path:
            'M42.092 28.319c0 .867-.439 1.51-1.317 1.926a5.826 5.826 0 0 1-1.31.422c-.456.09-1.095.135-1.916.135-.98 0-1.89-.098-2.728-.296a27.802 27.802 0 0 1-2.45-.692 27.67 27.67 0 0 0-2.457-.693 12.193 12.193 0 0 0-2.77-.295c-.72 0-1.31.053-1.765.16a7.659 7.659 0 0 0-1.022.296v6.739a.956.956 0 0 1-.245.65c-.163.186-.386.28-.667.28a.896.896 0 0 1-.667-.288.9.9 0 0 1-.279-.642v-18.85c0-.878.44-1.514 1.317-1.908.394-.18.817-.318 1.267-.414.45-.096 1.104-.144 1.96-.144 1.002 0 1.93.101 2.786.304.856.203 1.684.437 2.483.701.8.265 1.613.498 2.441.701.828.203 1.726.304 2.694.304.935 0 1.672-.081 2.213-.245.54-.163.968-.245 1.284-.245.405 0 .697.099.878.296.18.197.27.447.27.752v11.046zm10.785-10.21a22.159 22.159 0 0 0-3.159-6.039 22.731 22.731 0 0 0-4.788-4.788 22.129 22.129 0 0 0-6.047-3.159A21.262 21.262 0 0 0 31.975 3c-2.41 0-4.71.375-6.9 1.123-2.19.75-4.2 1.802-6.03 3.159a22.806 22.806 0 0 0-4.78 4.788 21.964 21.964 0 0 0-3.15 6.039c-.743 2.19-1.115 4.49-1.115 6.9 0 2.398.374 4.695 1.123 6.89a22.241 22.241 0 0 0 3.15 6.048 22.376 22.376 0 0 0 10.819 7.93c2.19.748 4.49 1.123 6.9 1.123 2.409 0 4.709-.375 6.9-1.123a22.285 22.285 0 0 0 6.038-3.15 22.563 22.563 0 0 0 4.788-4.78 22.118 22.118 0 0 0 3.159-6.047A21.202 21.202 0 0 0 54 25.009c0-2.41-.375-4.71-1.123-6.9zM27 55.06a.99.99 0 0 1 .327-.758c.219-.202.521-.303.908-.303h7.515c.407 0 .717.103.93.31.213.207.32.462.32.765s-.114.597-.342.882l-3.706 4.588A1.176 1.176 0 0 1 32 61c-.387 0-.705-.152-.952-.455l-3.706-4.588c-.228-.294-.342-.593-.342-.896',
          fillColor: 'white',
          fillOpacity: 1,
          strokeWeight: 0,
          rotation: 0,
          scale: width / parseInt(breakpointsCross.M.min),
          anchor: new google.maps.Point(30, 60),
        }
        const markerId = `${lat}-${lng}`
        const marker = new google.maps.Marker({
          position: { lat, lng },
          map,
          icon: svgMarker,
          zIndex: 5,
        })
        setMarkers(prevMarkers => {
          if (Object.keys(prevMarkers).length > 0) {
            Object.keys(prevMarkers).forEach(key => {
              prevMarkers[key].setMap(null)
            })
          }
          return { [markerId]: marker }
        })
      }
    },
    [map, width],
  )

  const [mapCoordinates, setMapCoordinates] = useState({
    lat: defaultProps.center.lat,
    lng: defaultProps.center.lng,
  })

  const handleMapUpdate = useCallback(
    (latitude: number, longitude: number) => {
      setMarker({ lat: latitude, lng: longitude })
      setMapCoordinates({ lat: latitude, lng: longitude })
      map && map.setCenter({ lat: latitude, lng: longitude })
    },
    [setMarker, map],
  )

  const restoreCenter = useCallback(() => {
    if (searchedAddress) {
      handleMapUpdate(searchedAddress.latlng.lat, searchedAddress.latlng.lng)
      dispatch(assortmentAdvisorActions.setMapDragged(false))
    }
  }, [dispatch, handleMapUpdate, searchedAddress])

  useEffect(() => {
    if (selectedDoorIdQuery && selectedDoorId !== selectedDoorIdQuery) {
      dispatch(assortmentAdvisorActions.setSelectedDoorId(selectedDoorIdQuery))
      searchParams.delete('searchedAddress')
      setSearchParams(searchParams, { replace: true })
    }
  }, [dispatch, searchParams, selectedDoorId, selectedDoorIdQuery, setSearchParams])

  useEffect(() => {
    if (mapDataQuery.data && heatmapLayer) {
      if (mapDataQuery.isLoading || mapDataQuery.isFetching || mapView === 'demographics') {
        heatmapLayer.setOptions({ gradient: ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0)'] })
      } else {
        if (mapView === 'trends' && selectedPositioning) {
          heatmapLayer.setData(heatmapPoints)
          heatmapLayer.setOptions({ gradient: getGradient(selectedPositioning, defaultInputs) })
        }
        if (mapView === 'brands' && selectedBrandCode) {
          heatmapLayer.setData(heatmapPoints)
          heatmapLayer.setOptions({ gradient: getGradient('brands', defaultInputs) })
        }
      }
    }
  }, [
    mapDataQuery.data,
    selectedPositioning,
    heatmapLayer,
    mapView,
    selectedBrandCode,
    mapDataQuery.isLoading,
    mapDataQuery.isFetching,
    heatmapPoints,
    defaultInputs,
  ])

  usePreselectDefaultDoor()
  useHandleOpenAaMapPage()

  useEffect(() => {
    if (searchedAddress) {
      restoreCenter()
    }
  }, [restoreCenter, searchedAddress])

  useEffect(() => {
    if (mapView !== 'demographics') {
      if (Object.keys(demographicsCircles).length > 0) {
        Object.keys(demographicsCircles).forEach(key => {
          demographicsCircles[key].setMap(null)
        })
      }
      if (Object.keys(zipCodeMarkers).length > 0) {
        Object.keys(zipCodeMarkers).forEach(key => {
          zipCodeMarkers[key].setMap(null)
        })
      }
    }
  }, [mapView, demographicsCircles, zipCodeMarkers])

  const getPoints = (mapData: MapData[]) => {
    return mapData.map(dataPoint => {
      const { Latitude, Longitude, Data } = dataPoint
      return {
        location: new google.maps.LatLng(Latitude, Longitude),
        weight: Data,
      }
    })
  }

  const handleApiLoaded = (map: any) => {
    setMap(map)
    const heatmap = new google.maps.visualization.HeatmapLayer({
      map: map,
      radius: defaultInputs.radius,
      maxIntensity: defaultInputs.maxIntensity,
      opacity: defaultInputs.mapOpacity,
      gradient: getGradient(defaultSelectedPositioning, defaultInputs),
    })
    setHeatmapLayer(heatmap)
  }

  useEffect(() => {
    if (map && mapDataQuery.data && mapDataQuery.data.length > 0) {
      const transformedDataArray = mapDataQuery.data?.map(dataPoint => {
        //logarithmicBase value controls the base of the logarithmic transformation
        // A smaller base will result in a less significant boost for smaller values and a less substantial reduction for larger values
        const logarithmicBase = !isNaN(defaultInputs.logarithmicBase)
          ? defaultInputs.logarithmicBase
          : 0

        const transformedData = Math.log(dataPoint.Data + 1) / Math.log(logarithmicBase)
        return {
          ...dataPoint,
          Data: transformedData,
        }
      })

      const bounds = map.getBounds()
      if (bounds) {
        setHeatmapPoints(getPoints(transformedDataArray))
      }
    }
  }, [defaultInputs.logarithmicBase, heatmapPoints.length, map, mapDataQuery.data])

  const handleZoom = (symbol: string) => {
    const ZOOM_STEP = 0.5
    const roundToHalf = (number: number) => {
      const rounded = Math.round(number * 2) / 2
      return rounded
    }

    if (map) {
      const currentZoom = map.getZoom()

      if (symbol === '+' && currentZoom && currentZoom !== MAP_MAX_ZOOM) {
        map.setZoom(roundToHalf(currentZoom) + ZOOM_STEP)
      } else if (symbol === '-' && currentZoom && currentZoom !== MAP_MIN_ZOOM) {
        map.setZoom(roundToHalf(currentZoom) - ZOOM_STEP)
      }
    }
  }

  useEffect(() => {
    if (currentZoom) {
      const colorEndingOpacity = 'colorEndingOpacity' as keyof DefaultInputs
      const radius = 'radius' as keyof DefaultInputs
      const maxIntensity = 'maxIntensity' as keyof DefaultInputs

      if (currentZoom < 13) {
        setDefaultInputs({ ...defaultInputs, [maxIntensity]: 8, [radius]: 40 })
      } else if (currentZoom < 14) {
        setDefaultInputs({ ...defaultInputs, [radius]: 50 })
      } else if (currentZoom < 15) {
        setDefaultInputs({ ...defaultInputs, [colorEndingOpacity]: 85, [radius]: 80 })
      } else {
        setDefaultInputs({ ...DEFAULT_INPUTS })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentZoom])

  return (
    <Wrapper>
      <Header>
        <Title>
          {mapView === 'trends' && (
            <>
              <span className="blue">{t('AA.area')}</span> <span>{t('AA.trends')}</span>
            </>
          )}
          {mapView === 'brands' && (
            <>
              <span className="blue">{t('AA.area')}</span>{' '}
              <span>{t('AA.brands').toLowerCase()}</span>
            </>
          )}
          {mapView === 'demographics' && <span>{t('AA.demographics')}</span>}
        </Title>
        <MapViewSwitcherWithMargin />
      </Header>

      <Content>
        {(mapDataQuery.isLoading || mapDataQuery.isFetching) && <Loading isFullPage={false} />}

        <MapWrapper>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: config.googleMapsApiKey,
              libraries: ['visualization'],
              language: language.substring(0, 2),
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            center={mapCoordinates}
            options={{
              disableDefaultUI: defaultProps.disableDefaultUI,
              disableDoubleClickZoom: defaultProps.disableDoubleClickZoom,
              scaleControl: defaultProps.scaleControl,
              keyboardShortcuts: defaultProps.keyboardShortcuts,
              minZoom: defaultProps.minZoom,
              maxZoom: defaultProps.maxZoom,
              scrollwheel: defaultProps.scrollwheel,
              mapId: defaultProps.mapId,
              gestureHandling: defaultProps.gestureHandling,
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map }) => handleApiLoaded(map)}
            onChange={() => {
              if (map) {
                const bounds = map.getBounds()
                if (bounds) {
                  setBounds(bounds)
                }
              }
            }}
            onDrag={() => {
              if (map) {
                if (!isDragged) {
                  dispatch(assortmentAdvisorActions.setMapDragged(true))
                }
                const zoomLevel = map.getZoom()
                initialZoomLevel = zoomLevel
              }
            }}
            onDragEnd={() => {
              if (map) {
                const zoomLevel = map.getZoom()
                if (initialZoomLevel === zoomLevel) {
                  map.setZoom(initialZoomLevel || MAP_MIN_ZOOM)
                }
              }
            }}
          />
        </MapWrapper>

        <SearchAddress
          setAddressToSearch={setAddressToSearch}
          customAddresses={customAddresses}
          restoreCenter={restoreCenter}
        />

        <MapControls
          setDemographicsToggle={setDemographicsToggle}
          demographicsToggle={demographicsToggle}
        />

        {mapView === 'demographics' && (
          <DemographicsStatistics
            setDemographicsCircles={setDemographicsCircles}
            map={map}
            mapView={mapView}
            demographicsToggle={demographicsToggle}
            setZipCodeMarkers={setZipCodeMarkers}
          />
        )}

        {mapView !== 'demographics' && (
          <Legend>
            <LegendLabels>
              <LegendLabelValue>{t('AA.Less')}</LegendLabelValue>
              <LegendLabelName>
                {mapView === 'trends'
                  ? t(`AA.${selectedPositioning}`)
                  : selectedBrand === 'All'
                  ? t('AA.Map.Brands.All')
                  : selectedBrand}
              </LegendLabelName>
              <LegendLabelValue>{t('AA.More')}</LegendLabelValue>
            </LegendLabels>
            <LegendGradient
              className={mapView === 'trends' ? selectedPositioning || '' : 'brands'}
            />
          </Legend>
        )}

        <ZoomWrapper>
          <div onClick={() => handleZoom('+')}>+</div>
          <div onClick={() => handleZoom('-')}>-</div>
        </ZoomWrapper>
      </Content>

      {!isWall && <Footer />}
      <BrandsOverlay />

      {showDebugOverlay && (
        <DebugOverlay setDefaultInputs={setDefaultInputs} defaultInputs={defaultInputs} />
      )}
    </Wrapper>
  )
}

export default HeatMap
