import {
  DataCartSummary,
  DataCartSummeryTable,
} from '../containers/AfaCart/AfaCartSummary/AfaCartSummary'
import { BrandCode, DateString } from '../model/model'
import { useGetAfaCartQuery } from '../services/afaCart'
import { useSelector } from 'react-redux'
import { afaCartDataSelector } from '../store/afaCart/selectors'
import { AfaCartProduct, AfaProduct } from '../model/afa'
import { useMemo } from 'react'
import { convertStringToFloat } from '../libs/numbers'
import { filtersAppliedSelector } from '../store/afaCartSummary/selectors'
import { applyAfaFilters } from '../libs/afa'

type Gender = string
export type Family = string
type AvdCode = string

const upsertKey = (
  obj: Record<string, { quantity: number; value: number }>,
  key: string,
  cartProduct: AfaCartProduct,
) => {
  if (!obj[key]) {
    obj[key] = {
      quantity: cartProduct.unconfirmedQuantity,
      value:
        convertStringToFloat(cartProduct.masterPrice.unitPriceWs) * cartProduct.unconfirmedQuantity,
    }
  } else {
    obj[key].quantity += cartProduct.unconfirmedQuantity
    obj[key].value +=
      convertStringToFloat(cartProduct.masterPrice.unitPriceWs) * cartProduct.unconfirmedQuantity
  }
}

const buildGraphData = (
  cartProducts: AfaCartProduct[],
  productsDetails: Record<string, AfaProduct | null>,
) => {
  const brand = cartProducts.reduce((result, item) => {
    upsertKey(result, item.brandCodeParent, item)

    return result
  }, {} as Record<BrandCode, { quantity: number; value: number }>)

  const gender = cartProducts.reduce((result, item) => {
    const productDetails = productsDetails[item.modelCode] as AfaProduct | undefined
    if (!productDetails) {
      return result
    }

    if (productDetails.mocos[item.colorCode]) {
      upsertKey(result, productDetails.mocos[item.colorCode].gender, item)
    }

    return result
  }, {} as Record<Gender, { quantity: number; value: number }>)

  const category = cartProducts.reduce((result, item) => {
    const productDetails = productsDetails[item.modelCode] as AfaProduct | undefined
    if (!productDetails) {
      return result
    }

    upsertKey(result, productDetails.category, item)

    return result
  }, {} as Record<Gender, { quantity: number; value: number }>)

  const afaStyle = cartProducts.reduce((result, item) => {
    const productDetails = productsDetails[item.modelCode] as AfaProduct | undefined
    if (!productDetails) {
      return result
    }

    if (productDetails.afaStyle) {
      upsertKey(result, productDetails.afaStyle, item)
    }

    return result
  }, {} as Record<Gender, { quantity: number; value: number }>)

  const sport = cartProducts.reduce((result, item) => {
    const productDetails = productsDetails[item.modelCode] as AfaProduct | undefined
    if (!productDetails) {
      return result
    }

    const mocoDetails = productDetails.mocos[item.colorCode]
    if (!mocoDetails) {
      return result
    }

    mocoDetails.sports.forEach(sport => {
      upsertKey(result, sport, item)
    })

    return result
  }, {} as Record<Gender, { quantity: number; value: number }>)

  const avd = cartProducts.reduce((result, item) => {
    const productDetails = productsDetails[item.modelCode] as AfaProduct | undefined
    if (!productDetails) {
      return result
    }

    const mocoDetails = productDetails.mocos[item.colorCode]
    if (!mocoDetails) {
      return result
    }

    mocoDetails.availabilityDataList.forEach(({ avdCode }) => {
      upsertKey(result, avdCode, item)
    })

    return result
  }, {} as Record<Gender, { quantity: number; value: number }>)

  return Object.entries({
    afaStyle,
    avd,
    sport,
    category,
    gender,
    brand,
  })
    .map(([categoryKey, categoryValues]) => ({
      categoryKey,
      data: Object.entries(categoryValues || {}).map(([elementKey, optionValues]) => ({
        elementKey,
        value: optionValues.value,
        quantity: optionValues.quantity,
      })),
    }))
    .filter(entry => entry.data.length)
}

const buildTableData = (
  cartProducts: AfaCartProduct[],
  productsDetails: Record<string, AfaProduct | null>,
) => {
  const totals = {} as Record<Family, { quantity: number; value: number }>
  const deliveryDates = {} as Record<
    DateString,
    Record<Family, { quantity: number; value: number }>
  >

  const data = cartProducts.reduce((familyMap, item) => {
    const productDetails = productsDetails[item.modelCode] as AfaProduct | undefined
    if (!productDetails || !productDetails.family) {
      return familyMap
    }

    const mocoDetails = productDetails.mocos[item.colorCode]
    if (!mocoDetails) {
      return familyMap
    }

    const { family } = productDetails

    totals[family] = {
      quantity: (totals[family]?.quantity || 0) + item.unconfirmedQuantity,
      value:
        (totals[family]?.value || 0) +
        convertStringToFloat(item.masterPrice.unitPriceWs) * item.unconfirmedQuantity,
    }

    familyMap[family] = mocoDetails.availabilityDataList.reduce((avdCodeMap, { avdCode }) => {
      const existingEntry = familyMap[family] && familyMap[family][avdCode]

      avdCodeMap[avdCode] = {
        quantity: (existingEntry?.quantity || 0) + item.unconfirmedQuantity,
        value:
          (existingEntry?.value || 0) +
          convertStringToFloat(item.masterPrice.unitPriceWs) * item.unconfirmedQuantity,
      }
      return avdCodeMap
    }, {} as Record<AvdCode, { quantity: number; value: number }>)

    return familyMap
  }, {} as Record<Family, Record<AvdCode, { quantity: number; value: number }>>)

  const itemsGroupedByDeliveryDate = cartProducts.reduce((groups, item) => {
    const groupKey = item.deliveryDate
    if (!groups[groupKey]) {
      groups[groupKey] = []
    }
    groups[groupKey].push(item)
    return groups
  }, {} as Record<DateString, AfaCartProduct[]>)

  Object.entries(itemsGroupedByDeliveryDate).forEach(([deliveryDate, items]) => {
    const familyTotals: Record<Family, { quantity: number; value: number }> = {}

    items.forEach(item => {
      const productDetails = productsDetails[item.modelCode] as AfaProduct | undefined
      if (!productDetails || !productDetails.family) {
        return
      }

      const mocoDetails = productDetails.mocos[item.colorCode]
      if (!mocoDetails) {
        return
      }

      const { family } = productDetails

      if (!familyTotals[family]) {
        familyTotals[family] = { quantity: 0, value: 0 }
      }

      familyTotals[family].quantity += item.unconfirmedQuantity
      familyTotals[family].value +=
        convertStringToFloat(item.masterPrice.unitPriceWs) * item.unconfirmedQuantity
    })

    deliveryDates[deliveryDate] = familyTotals
  })

  return Object.entries(data).map(([productType, avdData]) => ({
    productType,
    avdData: Object.entries(avdData || {}).map(([avdCode, { quantity, value }]) => ({
      avdCode,
      quantity,
      value,
    })),
    total: totals[productType],
    deliveryDates: Object.keys(deliveryDates).reduce((deliveryDatesAcc, date) => {
      const valueAndQtyObj = deliveryDates[date][productType]
      if (valueAndQtyObj) {
        deliveryDatesAcc[date] = valueAndQtyObj
      } else {
        deliveryDatesAcc[date] = { quantity: 0, value: 0 }
      }
      return deliveryDatesAcc
    }, {} as Record<DateString, { quantity: number; value: number }>),
  }))
}

const getFilteredProds = (
  cartProducts: AfaCartProduct[] | undefined,
  productsDetails: Record<string, AfaProduct | null>,
  appliedFilters: Record<string, string[]>,
) =>
  cartProducts?.filter(cartProduct => {
    const productDetails =
      productsDetails && (productsDetails[cartProduct.modelCode] as AfaProduct | undefined)

    if (!productDetails) {
      return false
    }

    const convertAppliedFiltersValues = (values?: string[]) => (values?.length ? values : undefined)

    return applyAfaFilters(
      {
        sports: convertAppliedFiltersValues(appliedFilters.sport),
        genders: convertAppliedFiltersValues(appliedFilters.gender),
        categories: convertAppliedFiltersValues(appliedFilters.category),
        afaStyles: convertAppliedFiltersValues(appliedFilters.afaStyle),
        avds: convertAppliedFiltersValues(appliedFilters.avd),
      },
      productDetails,
      cartProduct,
    )
  })

export const useGetAfaCartSummaryData = (): {
  options?: DataCartSummary[]
  filteredData?: {
    graphs: DataCartSummary[]
    table: DataCartSummeryTable[]
  }
} => {
  const cartQuery = useGetAfaCartQuery()

  const prodsDetails = useSelector(afaCartDataSelector)
  const appliedFilters = useSelector(filtersAppliedSelector)

  const data = useMemo(() => {
    if (!prodsDetails) {
      return {}
    }

    const getFilteredProds_ignoreKey = (key: string) =>
      getFilteredProds(cartQuery.data?.items, prodsDetails, {
        ...appliedFilters,
        [key]: [],
      }) || []

    const filteredProds = getFilteredProds(cartQuery.data?.items, prodsDetails, appliedFilters)

    const filteredProds_no_seasOrPerm = getFilteredProds_ignoreKey('afaStyle')
    const filteredProds_no_avd = getFilteredProds_ignoreKey('avd')
    const filteredProds_no_sport = getFilteredProds_ignoreKey('sport')
    const filteredProds_no_cat = getFilteredProds_ignoreKey('category')
    const filteredProds_no_gender = getFilteredProds_ignoreKey('gender')
    const filteredProds_no_brand = getFilteredProds_ignoreKey('brand')

    const table = buildTableData(filteredProds || [], prodsDetails)
    const graphs = buildGraphData(filteredProds || [], prodsDetails)

    const getGraphData = (categoryKey: string, graphsData: DataCartSummary[]) =>
      graphsData.find(graphData => graphData.categoryKey === categoryKey)?.data || []

    const options = {
      afaStyle: getGraphData('afaStyle', buildGraphData(filteredProds_no_seasOrPerm, prodsDetails)),
      avd: getGraphData('avd', buildGraphData(filteredProds_no_avd, prodsDetails)),
      sport: getGraphData('sport', buildGraphData(filteredProds_no_sport, prodsDetails)),
      category: getGraphData('category', buildGraphData(filteredProds_no_cat, prodsDetails)),
      gender: getGraphData('gender', buildGraphData(filteredProds_no_gender, prodsDetails)),
      brand: getGraphData('brand', buildGraphData(filteredProds_no_brand, prodsDetails)),
    }
    return {
      options: Object.entries(options).map(([categoryKey, data]) => ({
        categoryKey,
        data: data,
      })),
      filteredData: {
        graphs,
        table,
      },
    }
  }, [cartQuery.data?.items, appliedFilters, prodsDetails])

  return data
}
