import React, { ComponentType } from 'react'
import { Subtract } from 'utility-types'

import socketManager from '../../libs/socketManager'
import { getDeviceFromUrl } from '../../libs/url'
import { Brand } from '../../model/brand'
import { EmitEvent } from '../../model/model'

type State = {
  pageData: PageData | null
  timestamp: null
  event?: string
}

export type PageData = {
  timestamp: number
  device: string
  brand?: Brand
} & Record<string, any>

export type PageSocketManagerProps = {
  pageData?: PageData
  socketIoEvent: string
  emitEvent: EmitEvent
}

const PageSocketManager = <P extends PageSocketManagerProps>(
  WrappedComponent: ComponentType<P>,
  events?: string[],
) => {
  class HOC extends React.Component<Subtract<P, PageSocketManagerProps>, State> {
    state: State = {
      pageData: null,
      timestamp: null,
    }
    eventsMap: Record<string, (pageData: any) => void> = {}

    constructor(props: any) {
      super(props)

      if (events) {
        events.forEach(evt => {
          const eventHandler = this.handleEvent.bind(this, evt)
          this.eventsMap[evt] = eventHandler
          socketManager.bindListener(evt, eventHandler)
        })
      }
    }

    componentWillUnmount = () => {
      this.removeEventListeners()
    }

    /**
     * Socket event handler
     */
    handleEvent = (evt: string, pageData: PageData) => {
      if (
        pageData.device !== getDeviceFromUrl() &&
        (!this.state.pageData || pageData.timestamp > this.state.pageData.timestamp)
      ) {
        this.setState({
          pageData,
          event: evt,
        })
      }
    }

    /**
     * When the page constructor gets run, it means that a new page got loaded. In this case
     * the component emits the navigation event, that will be broadcasted by the socket.
     * @return {Void}
     */
    emitEvent = socketManager.emitSignal.bind(socketManager)

    /**
     * Remove all listeners bound to existing events
     */
    removeEventListeners = () => {
      Object.keys(this.eventsMap).forEach(evt => {
        socketManager.removeListener(evt, this.eventsMap[evt])
      })
    }

    render() {
      return (
        <WrappedComponent
          {...(this.props as P)}
          pageData={this.state.pageData}
          socketIoEvent={this.state.event}
          emitEvent={this.emitEvent}
        />
      )
    }
  }

  return HOC
}

export default PageSocketManager
