import styled, { css } from 'styled-components/macro'
import { breakpoints, getFluidSizeWithFullFormula as gF, palette, pxToRem } from '../style/theme'

export const Wrapper = styled.div`
  padding-left: ${gF('px', 110, 224, 1366, 3840)};
  padding-right: ${gF('px', 110, 224, 1366, 3840)};
  background-color: ${palette.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 100%;
`

export const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const ModalTitle = styled.span`
  align-self: center;
  font-size: ${gF('px', 26, 48, 1366, 3840)};
  text-align: center;
  color: ${palette.tangaroa};
  font-family: 'GilmerMedium', sans-serif;
  text-transform: capitalize;

  @media (max-width: ${breakpoints.L}) {
    font-family: GilmerBold;
  }
`

export const DeliveryDate = styled.span`
  margin: ${gF('px', 76, 100, 1366, 3840)} 0 ${gF('px', 12, 24, 1366, 3840)};
  flex-grow: 0;
  font-size: ${gF('px', 22, 32, 1366, 3840)};
  font-family: GilmerBold;
  text-align: left;
  text-transform: uppercase;
  color: ${palette.cloudBurst};
`

export const SetDeliveryDate = styled.span`
  font-size: ${gF('px', 18, 24, 1366, 3840)};
  font-family: GilmerBold;
  text-align: left;
  color: ${palette.cloudBurst};
  letter-spacing: 0.9px;
`

export const LineBreak = styled.div`
  width: 100%;
  margin: ${gF('px', 24, 54, 1366, 3840)} 0 ${gF('px', 24, 80, 1366, 3840)};
  border-bottom: solid ${gF('px', 1, 4, 1366, 3840)} rgba(0, 0, 0, 0.2);
`

export const grayTexts = css`
  flex-grow: 0;
  font-size: ${gF('px', 14, 24, 1366, 3840)};
  text-align: left;
  color: ${palette.santasGray};
`

export const DeliveryAvailable = styled.span`
  ${grayTexts};
  letter-spacing: 0.7px;

  @media screen and (max-width: ${breakpoints.L}) {
    text-transform: uppercase;
  }
`

export const NextDelivery = styled.span`
  ${grayTexts};
  letter-spacing: 0.7px;
  margin: ${gF('px', 16, 24, 1366, 3840)} 0 ${gF('px', 12, 48, 1366, 3840)};
`

export const ButtonsGroup = styled.div`
  margin-top: ${gF('px', 90, 130, 1366, 3840)};
  align-self: center;
  display: flex;
  gap: ${gF('px', 40, 64, 1366, 3840)};
  font-family: GilmerBold;
`

const buttonStyle = css`
  font-size: ${gF('px', 13, 24, 1366, 3840)};
  flex-grow: 0;
  height: ${gF('px', 56, 72, 1366, 3840)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${pxToRem(8)}rem;
  padding: ${gF('px', 19, 24, 1366, 3840)} ${gF('px', 21, 24, 1366, 3840)};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  text-align: center;
  text-transform: uppercase;
  user-select: none;

  &[disabled],
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
`

const buttonsSave = css`
  border-radius: 4px;
  background-color: ${palette.tangaroa};
  color: ${palette.white};
`

export const ButtonCancel = styled.button`
  font-family: GilmerBold, sans-serif;
  width: ${gF('px', 169, 150, 1366, 3840)};
  border-radius: 4px;
  ${buttonStyle};
  border: solid 1px ${palette.tangaroa};
  background-color: ${palette.white};
  color: ${palette.tangaroa};
  white-space: nowrap;
`

export const ButtonSave = styled.button`
  font-family: GilmerBold, sans-serif;
  ${buttonStyle};
  ${buttonsSave};
`

export const ButtonConfirm = styled.button`
  ${buttonStyle}
  ${buttonsSave}
  width: 15em;
  &:disabled {
    cursor: not-allowed;
    pointer-events: all;
  }
`

export const MergeDeliveries = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`

export const BodyInfo = styled.div`
  font-size: ${gF('px', 20, 48, 1366, 3840)};
  font-weight: bold;
  text-align: center;
  color: ${palette.tangaroa};
`
