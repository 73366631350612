import { BarHoverOptions, BarOptions } from 'chart.js'

import { isIPadView } from '../../libs/url'
import { rgba } from '../../libs/utils'

export const BAR_MIN_VALUE = 10 // Bar chart value starting from which it sets stepSize to integer automatically

export const barChartDefaultConfig: Partial<BarOptions & BarHoverOptions> = {
  backgroundColor: rgba(70, 120, 170, 1),
  borderWidth: 1,
  hoverBackgroundColor: rgba(23, 62, 92, 1),
  borderColor: rgba(0, 81, 146, 0.4),
}

export const barChartDefaultOptions: {
  legend: { position: string }
  scales: { yAxes: { ticks: { beginAtZero: boolean; stepSize?: number | null; max?: number } }[] }
} = {
  legend: { position: 'top' },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
}

export const doughnutDefaultOptions = {
  legend: {
    position: 'bottom',
    labels: {
      boxWidth: isIPadView() ? 20 : 40,
    },
  },
}

export const getHeaderColumns = (t: (s: string) => string) => ({
  key: { title: '' },
  models: { title: t('Chart.label_models') },
  skus_sun: { title: `${t('Chart.label_skus')} ${t('Chart.label_sun')}` },
  skus_optical: { title: `${t('Chart.label_skus')} ${t('Chart.label_optical')}` },
  total_items: {
    title: t('Chart.label_totalunits'),
    classNames: [
      'bordered',
      'bordered_top',
      'bordered_bottom',
      'bordered_left',
      'bordered_right',
      'grey_bg',
    ],
  },
  new_model: {
    title: t('Chart.label_newmodel'),
    classNames: ['bordered', 'bordered_left', 'bordered_top', 'bordered_bottom', 'coupled_right'],
  },
  color_refresh: {
    title: t('Chart.label_colorrefresh'),
    classNames: ['bordered', 'bordered_top', 'bordered_bottom', 'coupled_left', 'coupled_right'],
  },
  total_new: {
    title: t('Chart.label_totalnew'),
    classNames: [
      'bordered',
      'bordered_top',
      'bordered_bottom',
      'coupled_left',
      'coupled_right',
      'grey_bg',
    ],
  },
  carry_over: {
    title: t('Chart.label_carryover'),
    classNames: ['bordered', 'bordered_top', 'bordered_right', 'bordered_bottom', 'coupled_left'],
  },
  sun: {
    title: t('Chart.label_sun'),
    classNames: [
      'bordered',
      'bordered-padded',
      'bordered_left',
      'bordered_top',
      'bordered_bottom',
      'coupled_right',
      'grey_bg',
    ],
  },
  optical: {
    title: t('Chart.label_optical'),
    classNames: [
      'bordered',
      'bordered-padded',
      'bordered_right',
      'bordered_top',
      'bordered_bottom',
      'coupled_left',
      'grey_bg',
      'ipad-size-fix',
    ],
  },
  man: {
    title: t('Chart.label_man'),
    classNames: [
      'bordered',
      'bordered-padded',
      'bordered_left',
      'bordered_top',
      'bordered_bottom',
      'coupled_right',
    ],
  },
  unisex: {
    title: t('Chart.label_unisex'),
    classNames: [
      'bordered',
      'bordered-padded',
      'bordered_top',
      'bordered_bottom',
      'coupled_left',
      'coupled_right',
    ],
  },
  woman: {
    title: t('Chart.label_woman'),
    classNames: [
      'bordered',
      'bordered-padded',
      'bordered_right',
      'bordered_top',
      'bordered_bottom',
      'coupled_left',
      'ipad-size-fix',
    ],
  },
  junior: {
    title: t('Chart.label_junior'),
    classNames: [
      'bordered',
      'bordered-padded',
      'bordered_top',
      'bordered_bottom',
      'bordered_left',
      'bordered_right',
      'grey_bg',
      'ipad-size-fix',
    ],
  },
  polar: {
    title: t('Chart.label_polar'),
    classNames: [
      'bordered',
      'bordered-padded',
      'bordered_top',
      'bordered_bottom',
      'bordered_left',
      'bordered_right',
      'grey_bg',
      'ipad-size-fix',
    ],
  },
  adv: {
    title: t('Chart.label_adv'),
    classNames: [
      'bordered',
      'bordered-padded',
      'bordered_top',
      'bordered_bottom',
      'bordered_left',
      'bordered_right',
      'grey_bg',
    ],
  },
  best_seller: {
    title: t('Chart.label_bestseller'),
    classNames: [
      'bordered',
      'bordered_top',
      'bordered_bottom',
      'bordered_left',
      'bordered_right',
      'grey_bg',
      'ipad-last-column-fix',
    ],
  },
})
