import React, { SVGProps } from 'react'

const ShowPanelIcon: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1842.000000, -47.000000)" fill="#5093F9">
          <g transform="translate(1836.000000, 41.000000)">
            <g transform="translate(6.000000, 6.000000)">
              <path d="M17.9828364,24.0527783 L17.9828364,11.9558168 C17.9828364,11.2298829 18.3432136,10.866916 19.0640853,10.866916 L27.0270634,10.866916 C27.7479351,10.866916 28.1083123,11.2298829 28.1083123,11.9558168 L28.1083123,24.0527783 C28.1083123,24.7729047 27.7479351,25.1332001 27.0270634,25.1332001 L19.0640853,25.1332001 C18.3432136,25.1332001 17.9828364,24.7729047 17.9828364,24.0527783 L17.9828364,24.0527783 Z M27.6277312,26.4943551 C28.2169176,26.4943551 28.6716068,26.3382503 28.9921504,26.0263891 C29.3124597,25.7144118 29.4726144,25.2522533 29.4726144,24.6397974 L29.4726144,11.3603187 C29.4726144,10.7477467 29.3124597,10.2855882 28.9921504,9.97361085 C28.6716068,9.66186582 28.2169176,9.50576101 27.6277312,9.50576101 L8.37226876,9.50576101 C7.77160096,9.50576101 7.31257688,9.66186582 6.99496222,9.97361085 C6.67758186,10.2855882 6.51871595,10.7477467 6.51871595,11.3603187 L6.51871595,24.6397974 C6.51871595,25.2522533 6.67758186,25.7144118 6.99496222,26.0263891 C7.31257688,26.3382503 7.77160096,26.4943551 8.37226876,26.4943551 L27.6277312,26.4943551 Z M5.84084119,27.1791721 C5.28012419,26.6317599 5,25.8137196 5,24.7248188 L5,11.2836601 C5,10.1947593 5.28012419,9.37532522 5.84084119,8.82512544 C6.40144104,8.27515796 7.23384687,8 8.33794154,8 L27.6535059,8 C28.7633413,8 29.5984418,8.27643561 30.1591588,8.82942297 C30.7197587,9.38241033 31,10.2005668 31,11.2836601 L31,24.7248188 C31,25.8080282 30.7197587,26.6246748 30.1591588,27.1748746 C29.5984418,27.724842 28.7633413,28 27.6535059,28 L8.33794154,28 C7.23384687,28 6.40144104,27.726352 5.84084119,27.1791721 L5.84084119,27.1791721 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ShowPanelIcon
