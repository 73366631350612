import React, { SVGProps } from 'react'
import styled, { css } from 'styled-components'

type SortActiveIconProps = SVGProps<SVGSVGElement> & { order: 'ASC' | 'DESC' }

const SvgWrapper = styled.div`
  display: flex;
  ${({ rotate }: { rotate: boolean }) =>
    rotate &&
    css`
      svg {
        rotate: 180deg;
      }
    `}
`

const SortActiveIcon: React.FC<SortActiveIconProps> = ({ order, ...props }) => (
  <SvgWrapper rotate={order === 'DESC'}>
    <svg
      viewBox="0 0 1024 1024"
      data-icon="caret-up"
      width="1em"
      height="1em"
      fill="currentColor"
      aria-hidden="true"
      {...props}
    >
      <path d="M858.9 689 530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z" />
    </svg>
  </SvgWrapper>
)

export default SortActiveIcon
