import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { BlobProvider } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import { useGetSingleWhiteboardQuery } from '../../services/whiteboard'
import { breakpoints, getFluidSizeWithFullFormula as gF, palette, pxToRem } from '../../style/theme'
import { WhiteboardDownloadModalType } from '../../model/whiteboard'
import RCIcon from '../../components/UI/RCIcon'
import Modal from './components/Modal'
import DownloadDocument from './components/DownloadDocument'
import { getSelectedSwitch, getSelectedWhiteboard } from '../../store/whiteboard/selectors'
import { useSelector } from 'react-redux'
import useCheckIfImageNeedsProxy from '../../hooks/useCheckIfImageNeedsProxy'
import { successNotification } from '../../components/Notification/notifications'
import noBackground from '../../components/icons/noBackgroundWithBorder.svg'
import { environmentSelector } from '../../store/app/selectors'

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${gF('px', 432, 900, 1366, 3840)};
  background: #fff;
  border: 1px solid #a0a0a0;
  border-radius: 4px;
  padding-left: ${gF('px', 16, 24, 1366, 3840)};
  overflow: hidden;
  font-size: ${gF('px', 14, 24, 1366, 3840)};
  font-family: GilmerMedium, sans-serif;
  margin-bottom: ${gF('px', 54, 108, 1366, 3840)};

  .prefix {
    color: ${palette.shadowBlue};
  }

  input {
    flex-grow: 1;
    border: none;
    outline: none;
    margin: ${gF('px', 4, 17, 1366, 3840)};
    margin-left: 0;
  }
`

const Body = styled.div`
  font-family: GilmerRegular, sans-serif;
  text-align: left;
  color: ${palette.cloudBurst};
  font-size: ${gF('px', 22, 32, 1366, 3840)};
  margin-top: ${gF('px', 96, 148, 1366, 3840)};
  margin-bottom: ${gF('px', 116, 407, 1366, 3840)};
`

const Label = styled.p`
  padding-bottom: ${gF('px', 20, 32, 1366, 3840)};
  font-family: GilmerBold, sans-serif;

  span {
    font-family: GilmerBold, sans-serif;
    text-transform: uppercase;
  }

  @media screen and (max-width: ${breakpoints.L}) {
    font-family: GilmerRegular, sans-serif;
  }
`

const Info = styled.p`
  font-size: ${gF('px', 14, 28, 1366, 3840)};
  font-family: GilmerMedium, sans-serif;
  color: ${palette.santasGray};
`

const Subtitle = styled.p`
  font-family: GilmerRegular, sans-serif;
  color: ${palette.tangaroa};
  font-size: ${gF('px', 20, 30, 1366, 3840)};
`

const Button = styled.button`
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-size: ${gF('px', 13, 24, 1366, 3840)};
  font-family: GilmerBold, sans-serif;
  text-transform: uppercase;
  border: 1px solid ${palette.tangaroa};
  border-radius: 4px;
  cursor: pointer;
  background-color: ${palette.tangaroa};

  a,
  span {
    padding: 0 ${pxToRem(20)}rem;
    color: ${palette.white};
    text-decoration: none;
    display: inline-block;
    padding: ${gF('px', 18, 18, 1366, 3840)} ${gF('px', 61, 128, 1366, 3840)};
  }
`

const Icon = styled.div`
  display: inline-block;
  padding-right: ${gF('px', 6, 10, 1366, 3840)};
`

const WhiteboardDownloadModal: React.FC<WhiteboardDownloadModalType> = ({
  name,
  id,
  setDownloadModalOpen,
}) => {
  const { t } = useTranslation()

  const prefix = name.split('_')[0]
  const secondPart = name?.replace(/[a-zA-Z0-9\s]{1,}_/, '') || ''

  const selectedWhiteboard = useSelector(getSelectedWhiteboard)
  const environment = useSelector(environmentSelector)

  const [editableFileName, setEditableFileName] = useState(secondPart)

  const selectedType = useSelector(getSelectedSwitch)

  const whiteboardQuery = useGetSingleWhiteboardQuery(selectedType, id)
  const whiteboard = selectedWhiteboard || whiteboardQuery.data

  const getImage = (image: string) =>
    image?.indexOf('cdn-record-files-pi/') > -1 ? image.split('cdn-record-files-pi/')[1] : image

  const [images, setImages] = useState<Record<string, string>>({})
  const { fetchImage } = useCheckIfImageNeedsProxy()
  const [itemsInBoard, setItemsInBoard] = useState(0)

  const imagesToFetch = useMemo(() => {
    return whiteboard?.boards.flatMap(({ items }) => {
      return items.map(({ id, product }) => {
        return {
          id,
          url: product.image,
        }
      })
    })
  }, [whiteboard?.boards])

  useEffect(() => {
    if (imagesToFetch) {
      imagesToFetch.forEach(item => {
        const { id, url } = item
        fetchImage(getImage(url), environment)
          .then(localUrl => setImages(images => ({ ...images, [id]: localUrl })))
          .catch(() => setImages(images => ({ ...images, [id]: noBackground })))
          .finally(() => setItemsInBoard(itemsInBoard => itemsInBoard + 1))
      })
    }
  }, [fetchImage, imagesToFetch, environment])

  const fileName = `${prefix}_${editableFileName}.pdf`

  const loading = !whiteboard || (imagesToFetch && imagesToFetch.length !== itemsInBoard)

  const handleDownloadMessage = () => {
    setDownloadModalOpen(false)
    successNotification({
      message: t('Whiteboard.downloadModal.completed'),
    })
  }

  return (
    <Modal title={t('Whiteboard.downloadModal.title')} onCancel={() => setDownloadModalOpen(false)}>
      <Subtitle>{t('Whiteboard.downloadModal.subtitle')}</Subtitle>

      <Body>
        <Label>
          <span>{t('Whiteboard.downloadModal.fileName')}</span>
        </Label>

        <InputWrapper>
          <span className="prefix">{prefix + '_'}</span>
          <input value={editableFileName} onChange={e => setEditableFileName(e.target.value)} />
        </InputWrapper>

        <Label>
          <span>{t('Whiteboard.downloadModal.type')}: </span>
          {t('Whiteboard.downloadModal.fileType')}
        </Label>
        <Info>{t('Whiteboard.downloadModal.fileTypeNote')}</Info>
      </Body>

      {loading && (
        <Button>
          <span>{t('Whiteboard.downloadModal.loading')}</span>
        </Button>
      )}

      {!loading && (
        <Button>
          <BlobProvider document={<DownloadDocument whiteboard={whiteboard} images={images} />}>
            {({ blob: _blob }) => {
              // Trick for Safari Mobile: We create a new blob with a type different from 'application/pdf' to prevent it from opening in the browser instead of downloading
              const blob = new Blob([_blob as Blob], { type: 'application/octet-stream' })
              if (!blob) return null
              const url = URL.createObjectURL(blob) || ''
              return (
                <a href={url} download={fileName} rel="noreferrer" onClick={handleDownloadMessage}>
                  <Icon>
                    <RCIcon type="icon-download-white" />
                  </Icon>
                  {t('Whiteboard.downloadModal.cta')}
                </a>
              )
            }}
          </BlobProvider>
        </Button>
      )}
    </Modal>
  )
}

export default WhiteboardDownloadModal
