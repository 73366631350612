import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'

import { isMocoDiscontinued } from '../../libs/productsV2'
import { isLedDevice } from '../../libs/url'
import { MocoInCart } from '../../model/cart'
import { Moco } from '../../model/product'
import { activeDoorsIdsSelector } from '../../store/customer/selectors'
import BestsellerIcon from '../Couvettes/Couvette/BestsellerIcon'
import AdvIcon from '../UI/AdvIcon'
import RCIcon from '../UI/RCIcon'
import StarsIcon from '../UI/StarsIcon'

const StyledBestsellerIcon = styled(BestsellerIcon)`
  span.bestseller-count {
    font-weight: normal;
    font-size: 0.4em;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 0.67em;
  }
`

type Props = {
  moco: Moco | MocoInCart
  hasRTR?: boolean
  hiddenIcons?: (string | undefined)[]
  fromPlanogram?: boolean
  className?: string
}

const ProductIcons: React.FC<Props> = ({
  moco,
  hasRTR = false,
  hiddenIcons = [],
  children,
  fromPlanogram = false,
  className = '',
}) => {
  const activeDoorsIds = useSelector(activeDoorsIdsSelector)
  const isStarsMoco = Object.values(moco.sizes || {}).some((size: { starsDoors: string[] }) =>
    size.starsDoors?.some(starsDoor => activeDoorsIds.includes(starsDoor)),
  )

  const {
    advDates,
    mocoCode,
    isAdv,
    isBestseller,
    isCart,
    isNew,
    isDigitalPreview,
    newOld,
    sizes,
    skuBestSellerRanking,
  } = moco

  const hasVto = Object.values(sizes || {}).some(s => !!s.hasVto)
  const isIA = moco.tags?.includes('ideal')
  const isDiscontinued = isMocoDiscontinued(moco) && !isStarsMoco
  const isDiscontinuedStars = isMocoDiscontinued(moco) && isStarsMoco
  const isLed = isLedDevice()

  return (
    <Wrapper className={['product-icons', className].join(' ')}>
      {!hiddenIcons.includes('discontinued') && isDiscontinued && (
        <RCIcon type="discontinued" iconClass="discontinued-icon" />
      )}
      {!hiddenIcons.includes('discontinued') && isDiscontinuedStars && (
        <RCIcon type="discontinued" iconClass="stars-discontinued" />
      )}
      {isStarsMoco && !hiddenIcons.includes('star') && <StarsIcon />}
      {!hiddenIcons.includes('bestseller') && isBestseller && skuBestSellerRanking && (
        <StyledBestsellerIcon count={Number(skuBestSellerRanking.id)} className="" />
      )}
      {!hiddenIcons.includes('new') && (isNew || isDigitalPreview) && newOld?.label === 'N' && (
        <RCIcon type={isLed ? 'new-filled' : 'new-pink'} iconClass="new-icon" />
      )}
      {!hiddenIcons.includes('cart') && isCart && !isStarsMoco && (
        <RCIcon
          type={fromPlanogram ? 'cart-small-plano' : 'cart-small'}
          iconClass="led-wall-adjust-icon"
        />
      )}
      {!hiddenIcons.includes('color-refresh') &&
        (isNew || isDigitalPreview) &&
        newOld?.label === 'A' && <RCIcon type={'color-plp'} iconClass="color-refresh-icon" />}
      {!hiddenIcons.includes('adv') && isAdv && <AdvIcon mocoCode={mocoCode} dates={advDates} />}
      {!hiddenIcons.includes('vto') && hasVto && <RCIcon type="vto-pink" iconClass="vto-icon" />}
      {!hiddenIcons.includes('rtr') && hasRTR && <RCIcon type="3D-plp" iconClass="rtr-icon" />}
      {!hiddenIcons.includes('ideal') && isIA && (
        <RCIcon type="ia-pink" iconClass="ideal-assortment-icon" />
      )}

      {children}
    </Wrapper>
  )
}

export default ProductIcons
