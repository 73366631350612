import { MutableRefObject } from 'react'

import { Door } from '../model/customer'
import { CategoryId, Upc } from '../model/model'
import { InOut, Module, StarProduct, StarsAssortment, StarsAssortmentByBrand } from '../model/stars'

export const isUpcIn = (inOutData: InOut[], upc: Upc) => {
  return inOutData.some(({ inUpcs }) => inUpcs.includes(upc))
}

export const isUpcOut = (inOutData: InOut[], upc: Upc) => {
  return inOutData.some(({ outUpcs }) => outUpcs.includes(upc))
}

export const isUpcNeitherInNorOut = (inOutData: InOut[], upc: Upc) => {
  return !isUpcIn(inOutData, upc) && !isUpcOut(inOutData, upc)
}

export const starsModulesToShowSelector_cb = (
  starsAssortmentByBrand: StarsAssortmentByBrand | undefined,
  selectedMonth: string | undefined,
  selectedClustersIds: string[],
  selectedCategory: CategoryId | undefined,
  selectedDoorsIds: string[],
): Module[] => {
  const selectedClusters = starsAssortmentByBrand?.clusters.filter(
    ({ id, categoryId, month, doorsIds }) => {
      const matchId = selectedClustersIds.length ? selectedClustersIds.includes(id) : true
      const matchDoors = doorsIds.some(doorId => selectedDoorsIds.includes(doorId))
      const matchMonth = month === selectedMonth
      const matchCategory = selectedCategory ? categoryId === selectedCategory : true

      return matchId && matchDoors && matchMonth && matchCategory
    },
  )

  const modulesIdAllowedBySelectedClusters = [
    ...new Set(selectedClusters?.flatMap(({ modulesIds }) => modulesIds)),
  ]

  const modules = starsAssortmentByBrand?.families
    .slice()
    .sort((f1, f2) => f1.order - f2.order)
    .flatMap(family => family.modules.slice().sort((m1, m2) => m1.rank - m2.rank))

  const modulesToShow = modules?.filter(({ id, month, categoryId }) => {
    const matchMonth = month === selectedMonth
    const matchCluster = modulesIdAllowedBySelectedClusters.includes(id)
    const matchCategory = selectedCategory ? categoryId === selectedCategory : true

    return matchMonth && matchCluster && matchCategory
  })

  type ModuleName = string
  const deduplicatedModulesToShow = modulesToShow?.reduce((result, module, index) => {
    const key = `${module.name}_${module.brandCode}_${module.categoryId}_${module.month}`
    result[key] = {
      ...(result[key] || module),
      productsUpcs: [...new Set((result[key]?.productsUpcs || []).concat(module.productsUpcs))],
      order: index,
    }

    return result
  }, {} as Record<ModuleName, Module & { order: number }>)

  return Object.values(deduplicatedModulesToShow || []).sort((m1, m2) => m1.order - m2.order)
}

export const clustersFilteredSelector_cb = (
  starsAssortmentByOpendBrand: StarsAssortmentByBrand | undefined,
  activeDoorsIds: string[],
  selectedMonth: string,
  selectedCategoryId: string | undefined,
) => {
  return !starsAssortmentByOpendBrand
    ? []
    : starsAssortmentByOpendBrand.clusters.filter(({ doorsIds, month, categoryId }) => {
        const matchDoors = doorsIds.some(doorId => activeDoorsIds.includes(doorId))
        const matchMonth = month === selectedMonth
        const matchCategory = selectedCategoryId ? categoryId === selectedCategoryId : true

        return matchDoors && matchMonth && matchCategory
      })
}

export const sortBySkyCodeCb = (p1: { skuCode: string }, p2: { skuCode: string }) =>
  p1.skuCode < p2.skuCode ? -1 : 1

export const getOutUpcsOfCurrentModule = (
  inOutData: InOut[],
  module: Module,
  starsAssortment: StarsAssortmentByBrand | undefined,
) => {
  return inOutData
    .filter(({ moduleId }) => moduleId === module.id || moduleId === module.duplicatedModuleId)
    .flatMap(({ outUpcs }) => outUpcs)
    .filter(outUpc => starsAssortment && starsAssortment?.upcs.some(({ upc }) => upc === outUpc))
}

// TODO: merge with old couvettes addCheckForLongPress after migrating to react-window
export const addCheckForLongPress = (
  direction: 'right' | 'left',
  el: null,
  listElementRef: MutableRefObject<HTMLElement | undefined>,
  intervalReference: MutableRefObject<any>,
  clickHandler: { (): void; (): void; (): void; (): void; (): void },
) => {
  if (el) {
    intervalReference.current = true
    const clearFunc = () => {
      window.onmouseleave = null
      window.onmouseup = null
      window.ondragleave = null
      window.onblur = null
      if (intervalReference.current !== true) clearInterval(intervalReference.current)
      intervalReference.current = null
    }
    window.onmouseleave = clearFunc
    window.onmouseup = clearFunc
    window.ondragleave = clearFunc
    window.onblur = clearFunc

    setTimeout(() => {
      if (!intervalReference.current) {
        clearInterval(intervalReference.current)
        intervalReference.current = null
        clickHandler()
      } else {
        if (intervalReference.current && intervalReference.current !== true) {
          clearInterval(intervalReference.current)
        }
        intervalReference.current = setInterval(() => {
          if (listElementRef.current) {
            if (direction === 'right') {
              listElementRef.current.scrollLeft += listElementRef.current?.clientWidth / 50
            } else if (direction === 'left') {
              listElementRef.current.scrollLeft -= listElementRef.current?.clientWidth / 50
            }
          }
        }, 16)
      }
    }, 200)
  }
}

export const getStarsProductDoorsIds = (
  starsAssortment: StarsAssortment,
  starsProduct: StarProduct,
) => {
  const productClusters = starsAssortment[starsProduct.brandCode].clusters.filter(
    cluster => cluster.month === starsProduct.month && starsProduct.clusterIds.includes(cluster.id),
  )
  const productDoorsIds = [...new Set(productClusters.flatMap(({ doorsIds }) => doorsIds))]
  return productDoorsIds
}

export const isStarsDoor = (door: Door, brandCode: string) => door.starsBrands?.includes(brandCode)

export const isStarsProductEligible = (
  starsAssortment: StarsAssortment,
  starsProduct: StarProduct,
) => {
  const productModule = starsAssortment[starsProduct.brandCode].families
    .flatMap(({ modules }) => modules)
    .find(({ month, id }) => month === starsProduct.month && starsProduct.moduleId === id)
  const isEligible = productModule?.name.toLocaleLowerCase() === 'eligible'
  return isEligible
}
