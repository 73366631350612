import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AfaCartProduct, UpdateAfaCartProductPayload } from '../../model/afa'
import { v4 as uuidv4 } from 'uuid'

import { resetForNewClient } from '../extraActions'

export enum AfaCartAdjustType {
  OUT_OF_STOCK = 1,
  EXPIRED_DROPS,
  MANAGE_QUANTITIES,
}

export type AfaCartAdjustIsOpen = false | AfaCartAdjustType

const DEFAULT_STATE = {
  isOpen: false as AfaCartAdjustIsOpen,
  cartProducts: [] as AfaCartProduct[],
  isEdited: false,
  removedKeys: [] as string[],
  expiredCartProductsToRemove: [] as AfaCartProduct[],
}

export const slice = createSlice({
  name: 'afaCartAdjust',
  initialState: DEFAULT_STATE,
  reducers: {
    setIsOpen: (state, { payload }: PayloadAction<AfaCartAdjustIsOpen>) => {
      if (payload === false) {
        state.cartProducts = []
      }
      state.isOpen = payload
    },

    setCartProducts: (state, { payload }: PayloadAction<AfaCartProduct[]>) => {
      state.cartProducts = payload
    },

    setCartExpiredProductsToRemove: (state, { payload }: PayloadAction<AfaCartProduct[]>) => {
      state.expiredCartProductsToRemove = payload
    },

    setIsEdited: (state, { payload }: PayloadAction<boolean>) => {
      state.isEdited = payload
    },

    setRemovedKeys: (state, { payload }: PayloadAction<string[]>) => {
      state.removedKeys = payload
    },

    upsertProducts: (state, { payload }: PayloadAction<UpdateAfaCartProductPayload[]>) => {
      const payloadCartProducts = payload.map(payloadProduct => {
        const cartProduct = state.cartProducts.find(
          ({ key, upc, doorId, dropType, availabilityDate, deliveryDate, afaCatalogId }) =>
            payloadProduct.key === key ||
            (payloadProduct.upc === upc &&
              payloadProduct.doorId === doorId &&
              payloadProduct.dropType === dropType &&
              payloadProduct.availabilityDate === availabilityDate &&
              payloadProduct.deliveryDate === deliveryDate &&
              payloadProduct.afaCatalogId === afaCatalogId),
        )
        if (cartProduct) {
          return { ...cartProduct, unconfirmedQuantity: payloadProduct.quantity }
        } else {
          return {
            unconfirmedQuantity: payloadProduct.quantity,
            key: payloadProduct.key || uuidv4(),
            doorId: payloadProduct.doorId,
            editable: true,
            skuCode: `${payloadProduct.modelCode}__${payloadProduct.colorCode}`,
            upc: payloadProduct.upc,
            masterPrice: { unitPriceRt: '0.0', unitPriceWs: '0.0' },
            status: payloadProduct.status,
            modelCode: payloadProduct.modelCode,
            colorCode: payloadProduct.colorCode,
            brandCodeParent: payloadProduct.brandCodeParent,
            brandCode: payloadProduct.brandCode,
            reference: payloadProduct.reference || '',
            doorAddressId: payloadProduct.doorAddressId || '',
            dropType: payloadProduct.dropType,
            availabilityDate: payloadProduct.availabilityDate,
            minDeliveryDate: payloadProduct.minDeliveryDate,
            deliveryDate: payloadProduct.deliveryDate,
            afaCatalogId: payloadProduct.afaCatalogId,
            insertedAt: new Date().toISOString(),
          }
        }
      })
      state.cartProducts = state.cartProducts.map(cartProduct => {
        const payloadCartProduct = payloadCartProducts.find(({ key }) => key === cartProduct.key)
        if (
          payloadCartProduct &&
          payloadCartProduct.unconfirmedQuantity !== cartProduct.unconfirmedQuantity
        ) {
          state.isEdited = true
        }
        return payloadCartProduct ? payloadCartProduct : cartProduct
      })
      const newCartProducts = payloadCartProducts.filter(({ unconfirmedQuantity, key }) => {
        const cartProduct = state.cartProducts.find(
          stateCartProduct => stateCartProduct.key === key,
        )
        const isNewCartProduct = unconfirmedQuantity > 0 && !cartProduct
        if (isNewCartProduct) {
          state.isEdited = true
        }
        return isNewCartProduct
      })
      state.cartProducts = state.cartProducts.concat(newCartProducts)
    },

    reset: () => DEFAULT_STATE,
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, () => DEFAULT_STATE)
  },
})

export default slice.reducer
