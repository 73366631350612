import { useSelector } from 'react-redux'

import { createApi } from '@reduxjs/toolkit/query/react'

import app_config from '../config/app/config'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { customerIdSelector, eventIdSelector } from '../store/app/selectors'
import { convertDateToYyyyMmDdString } from '../libs/time'

const Actions = [
  'ADD_TO_CART_ACTION',
  'GO_TO_PDP_ACTION',
  'BRAND_PAGE_ACCESS',
  'HOME_PAGE_ACCESS',
  'MAP_BRANDS_ACCESS',
  'MAP_BASE_ACCESS',
  'MAP_DEMOGRAPHICS_ACCESS',
] as const
type AnalyticsAction = typeof Actions[number]

export const analyticsApi = createApi({
  reducerPath: 'analyticsApi',
  baseQuery: staggeredBaseQueryWithBailOut(`${app_config.apiUrl}/analytics`),
  endpoints: builder => ({
    sendAnalytics: builder.mutation<
      {
        result: boolean
      },
      {
        eventId: string
        customer: string
        action: string
        dateAction: string
      }
    >({
      query: ({ eventId, customer, action, dateAction }) => ({
        url: '/sendAnalytics',
        method: 'POST',
        body: {
          eventId,
          customer,
          action,
          dateAction,
        },
      }),
    }),
  }),
})

export const useSendAnalytics = () => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  const [_sendAnalytics, sendAnalyticsResult] = analyticsApi.useSendAnalyticsMutation()

  const sendAnalytics = (action: AnalyticsAction) => {
    const dateAction = convertDateToYyyyMmDdString(new Date())

    _sendAnalytics({ action, dateAction, eventId, customer: customerId })
  }

  return [sendAnalytics, sendAnalyticsResult] as [typeof sendAnalytics, typeof sendAnalyticsResult]
}
