import React, { SVGProps } from 'react'

const BoldCalendarIcon: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 15 16.4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.43 0a1 1 0 0 1 1 1v.445h3.78V1a1 1 0 1 1 2 0v.444h1.346A2.444 2.444 0 0 1 15 3.889V14a2.444 2.444 0 0 1-2.445 2.445H2.445A2.444 2.444 0 0 1 0 14V3.889a2.444 2.444 0 0 1 2.444-2.444h.986V1a1 1 0 0 1 1-1zM2 9.222V14c0 .245.199.444.444.444h10.111A.444.444 0 0 0 13 14V9.222H2zm11-2H2V3.89c0-.245.199-.444.444-.444h.986v.444a1 1 0 1 0 2 0v-.444h3.78v.445a1 1 0 1 0 2 0v-.445h1.346c.246 0 .445.199.445.444v3.333z"
        fill="currentColor"
      />
    </svg>
  )
}

export default BoldCalendarIcon
