import { useCallback, useEffect, useRef, useState } from 'react'

interface Dimensions {
  width: number
  height: number
}

/**
 * Hook that returns the dimensions of an element and updates them when the window is resized.
 *
 * @param {React.RefObject<HTMLElement> | HTMLElement} ref - The ref object or element to measure.
 * @returns {Dimensions} An object containing the width and height of the element.
 */

export const useElementDimensions = (
  ref: React.RefObject<HTMLElement> | HTMLElement,
): Dimensions => {
  const [dimensions, setDimensions] = useState<Dimensions>({ width: 0, height: 0 })

  const elementRef = useRef<HTMLElement | null>(null)

  const updateDimensions = useCallback(() => {
    if (elementRef.current) {
      const { offsetWidth, offsetHeight } = elementRef.current
      setDimensions({ width: offsetWidth, height: offsetHeight })
    }
  }, [])

  useEffect(() => {
    if (ref) {
      if (ref instanceof HTMLElement) {
        elementRef.current = ref
      } else {
        elementRef.current = ref.current
      }
      updateDimensions()
      window.addEventListener('resize', updateDimensions)
      return () => window.removeEventListener('resize', updateDimensions)
    }
  }, [ref, updateDimensions])

  return dimensions
}
