import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { getFluidSizeWithFullFormula as gF, palette } from '../../../style/theme'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import assortmentAdvisorSelectors from '../../../store/assortmentAdvisor/selectors'
import assortmentAdvisorActions from '../../../store/assortmentAdvisor/actions'
import { useGetAddressGeocode } from '../addressServices'
import { customerDoorsSelector } from '../../../store/customer/selectors'
import { useGetSelectedDoorId } from '../Hooks/usePreselectDefaultDoor'
import { useSearchParams } from '../../../hooks/useSearchParams'

const iconsCss = css`
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 50%;
`

const Wrapper = styled.div`
  position: absolute;
  z-index: 999;
  bottom: ${gF('px', 18.5, 37, 1366, 3840)};
  left: ${gF('px', 17.5, 35, 1366, 3840)};
  height: ${gF('px', 66, 132, 1366, 3840)};
  width: ${gF('px', 400, 800, 1366, 3840)};
  border-radius: 20px;
`

const WrapperLayer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
`

const AddressesList = styled.ul`
  position: absolute;
  bottom: 130%;
  left: 0;
  background-color: white;
  padding: ${gF('px', 40, 65, 1366, 3840)};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  row-gap: ${gF('px', 13, 26, 1366, 3840)};
  max-width: 90vw;
`

const Address = styled.li`
  display: flex;
  height: ${gF('px', 37, 52, 1366, 3840)};
  white-space: nowrap;
  align-items: center;
  column-gap: ${gF('px', 17, 34, 1366, 3840)};
  font-family: 'EuclidCircularB-Regular', sans-serif;
  font-size: ${gF('px', 16, 32, 1366, 3840)};
  line-height: 1.19;
  letter-spacing: -0.11px;
  cursor: pointer;

  span {
    text-overflow: ellipsis;
    overflow-x: hidden;
    max-width: 100%;
  }
`

const FlagAddress = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg width='28.957' height='28.957' viewBox='0 0 28.957 28.957' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='9vzxm6gy3a' d='M0 0h14.478v16.891H0z'/%3E%3C/defs%3E%3Cg transform='translate(7.24 6.435)' fill='none' fill-rule='evenodd'%3E%3Cmask id='f36wcltvqb' fill='%23fff'%3E%3Cuse xlink:href='%239vzxm6gy3a'/%3E%3C/mask%3E%3Cpath d='M.986 15.896a.618.618 0 0 1-.182-.44V2.494c0-.61.288-1.047.864-1.309.258-.119.535-.212.83-.28.296-.067.724-.1 1.285-.1.66 0 1.271.069 1.836.208.565.139 1.109.3 1.631.482.524.182 1.058.343 1.604.482a7.13 7.13 0 0 0 1.762.208c.614 0 1.099-.055 1.455-.167.356-.11.637-.166.841-.166.273 0 .468.065.586.196.117.131.176.304.176.518v7.606c0 .595-.288 1.031-.864 1.309a3.602 3.602 0 0 1-.864.297c-.303.064-.72.095-1.25.095a7.3 7.3 0 0 1-1.791-.208 20.695 20.695 0 0 1-1.61-.476 19.86 19.86 0 0 0-1.62-.476 7.53 7.53 0 0 0-1.824-.208c-.47 0-.856.038-1.16.113a4.444 4.444 0 0 0-.67.208v4.63a.647.647 0 0 1-.165.447.558.558 0 0 1-.438.184.577.577 0 0 1-.432-.19' fill='%235093F9' mask='url(%23f36wcltvqb)'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 9.6px;
  height: 100%;
  min-width: ${gF('px', 37, 52, 1366, 3840)};
  background-color: ${palette.hawkesBlue};
`

const PinAddress = styled.div`
  height: 100%;
  min-width: ${gF('px', 65, 130, 1366, 3840)};
  background-color: ${palette.sanMarino};
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  position: relative;
  cursor: pointer;
`

const dragCss = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const FlagIcon = styled.div<{ mapDragged: boolean }>`
  ${dragCss};
  background-image: url("data:image/svg+xml,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='dpxc6rd1aa' d='M0 0h14.5v16.917H0z'/%3E%3C/defs%3E%3Cg transform='translate(7.25 6.444)' fill='none' fill-rule='evenodd'%3E%3Cmask id='ikzh45vy0b' fill='%23fff'%3E%3Cuse xlink:href='%23dpxc6rd1aa'/%3E%3C/mask%3E%3Cpath d='M.988 15.92a.62.62 0 0 1-.182-.44V2.497c0-.612.288-1.049.865-1.311.258-.12.535-.213.831-.28.296-.068.725-.101 1.287-.101.66 0 1.273.07 1.839.208.565.14 1.11.3 1.633.483.524.183 1.06.344 1.606.483a7.14 7.14 0 0 0 1.765.208c.614 0 1.1-.055 1.457-.167.357-.11.638-.167.843-.167.273 0 .468.066.586.197a.747.747 0 0 1 .176.519v7.617c0 .596-.288 1.033-.865 1.31a3.607 3.607 0 0 1-.865.299c-.304.064-.721.095-1.253.095-.645 0-1.243-.07-1.793-.208-.55-.14-1.087-.298-1.611-.477a19.89 19.89 0 0 0-1.623-.477 7.542 7.542 0 0 0-1.827-.209c-.47 0-.858.038-1.161.114a4.45 4.45 0 0 0-.672.208v4.637a.648.648 0 0 1-.165.447.56.56 0 0 1-.439.185.578.578 0 0 1-.432-.19' fill='%23FFF' mask='url(%23ikzh45vy0b)'/%3E%3C/g%3E%3C/svg%3E");
  width: ${gF('px', 29, 58, 1366, 3840)};
  height: ${gF('px', 29, 58, 1366, 3840)};
  background-color: ${palette.cornflowerBlueAA};
  z-index: 10;
  ${iconsCss};

  ${({ mapDragged }) =>
    mapDragged &&
    css`
      background-color: ${palette.rockBlue};
    `}
`

const FirstRingDragged = styled.div`
  ${dragCss};
  background-color: ${palette.shipCove};
  z-index: 9;
  border-radius: 50%;
  width: ${gF('px', 35, 70, 1366, 3840)};
  height: ${gF('px', 35, 70, 1366, 3840)};
`

const SecondRingDragged = styled.div`
  ${dragCss};
  background-color: ${palette.steelBlue};
  z-index: 8;
  border-radius: 50%;
  width: ${gF('px', 45, 90, 1366, 3840)};
  height: ${gF('px', 45, 90, 1366, 3840)};
`

const InputWrapper = styled.div`
  background-color: ${palette.rhino}14;
  backdrop-filter: blur(18px);
  height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 0 ${gF('px', 21.5, 43, 1366, 3840)};
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;

  input {
    width: 100%;
    height: ${gF('px', 35, 70, 1366, 3840)};
    line-height: ${gF('px', 35, 70, 1366, 3840)};
    background-color: transparent;
    border: none;
    color: ${palette.white};
    font-family: 'EuclidCircularB-Regular', sans-serif;
    font-size: ${gF('px', 16, 32, 1366, 3840)};
    line-height: 1.19;
    letter-spacing: -0.11px;
    caret-color: ${palette.cornflowerBlueAA};
    -webkit-caret-color: ${palette.cornflowerBlueAA}; /* Safari */
    -moz-caret-color: ${palette.cornflowerBlueAA}; /* Firefox */

    &:focus {
      outline: none;
    }
  }
`

const AddressWrapper = styled(InputWrapper)``

const AddressLocated = styled.div`
  width: 100%;
  font-family: 'EuclidCircularB-Regular', sans-serif;
  font-size: ${gF('px', 15, 30, 1366, 3840)};
  line-height: 1.27;
  letter-spacing: -0.1px;
`

const StreetAndCity = styled.div`
  text-transform: capitalize;
  color: ${palette.white};
`

const ZipCodeAndCountry = styled.div`
  color: ${palette.cornflowerBlueAA};
  text-transform: capitalize;
`

const CancelIcon = styled.div<{ hide: boolean }>`
  background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23001625' stroke-width='1.28' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='m7.347 7.347 8.945 8.945M16.292 7.347l-8.945 8.945'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-color: ${palette.white};
  min-width: ${gF('px', 23.5, 47, 1366, 3840)};
  min-height: ${gF('px', 23.5, 47, 1366, 3840)};
  cursor: pointer;
  ${iconsCss}

  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}
`

const SearchIcon = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m20.492 19.588-3.364-3.364a6.397 6.397 0 1 0-.904.904l3.364 3.364a.64.64 0 1 0 .904-.904zm-8.317-2.295a5.124 5.124 0 0 1-5.118-5.118 5.124 5.124 0 0 1 5.118-5.118 5.124 5.124 0 0 1 5.118 5.118 5.124 5.124 0 0 1-5.118 5.118z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
  background-color: ${palette.bigStone};
  min-width: ${gF('px', 32, 64, 1366, 3840)};
  min-height: ${gF('px', 32, 64, 1366, 3840)};
  cursor: pointer;
  ${iconsCss}
`

type Props = {
  setAddressToSearch: React.Dispatch<React.SetStateAction<string>>
  customAddresses: string[] | undefined
  restoreCenter: () => void
}

const SearchAddress: React.FC<Props> = ({ setAddressToSearch, customAddresses, restoreCenter }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const inputRef = useRef<HTMLInputElement>(null)
  const cancelIconRef = useRef<HTMLDivElement>(null)
  const addressesRef = useRef<HTMLUListElement>(null)

  const selectedDoorIdQuery = useGetSelectedDoorId()
  const doors = useSelector(customerDoorsSelector)
  const selectedDoor = doors.find(door => door.id === selectedDoorIdQuery)
  const selectedDoorAddress = selectedDoor?.addresses?.[0]

  const searchedAddressParam = searchParams.get('searchedAddress')

  const selectedDoorAddressText =
    searchedAddressParam ||
    (selectedDoorAddress
      ? `${selectedDoorAddress.name} ${selectedDoorAddress.city}`
      : 'Metropolitan City of Rome Capital, Italy')
  const searchedAddress = useSelector(assortmentAdvisorSelectors.searchedAddressSelector)

  const [addressToGeocode, setAddressToGeocode] = useState<string>('')
  const addressGeocodeQuery = useGetAddressGeocode(addressToGeocode)

  const [digit, setDigit] = useState('')
  const [showInput, setShowInput] = useState(false)

  const mapDragged = useSelector(assortmentAdvisorSelectors.mapDraggedSelector)

  useEffect(() => {
    if (selectedDoorAddressText) {
      setAddressToGeocode(selectedDoorAddressText)
    }
  }, [selectedDoorAddressText])

  useEffect(() => {
    if (addressGeocodeQuery.isError) {
      setAddressToGeocode('Metropolitan City of Rome Capital, Italy')
    }
  }, [addressGeocodeQuery.isError])

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (digit.length) {
      timer = setTimeout(async () => {
        setAddressToSearch(digit)
      }, 500)
      return () => clearTimeout(timer)
    } else {
      setAddressToSearch('')
    }
  }, [digit, setAddressToSearch])

  useEffect(() => {
    if (showInput) {
      inputRef.current && inputRef.current.focus()
    }
  }, [showInput])

  const handleClickOutsideInput = (event: Event) => {
    if (cancelIconRef.current && cancelIconRef.current.contains(event.target as Node)) {
      inputRef.current && inputRef.current.focus()
    } else if (
      (inputRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        addressesRef.current &&
        !addressesRef.current.contains(event.target as Node)) ||
      (!digit && inputRef.current && !inputRef.current.contains(event.target as Node))
    ) {
      setShowInput(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideInput, true)
    return () => {
      document.removeEventListener('click', handleClickOutsideInput, true)
    }
  })

  useEffect(() => {
    if (
      addressGeocodeQuery.data &&
      !addressGeocodeQuery.isLoading &&
      !addressGeocodeQuery.isFetching
    ) {
      dispatch(assortmentAdvisorActions.setSearchedAddress(addressGeocodeQuery.data))
    }
  }, [
    addressGeocodeQuery.data,
    addressGeocodeQuery.isLoading,
    addressGeocodeQuery.isFetching,
    dispatch,
  ])

  return (
    <Wrapper>
      <WrapperLayer>
        <PinAddress
          onClick={() => {
            if (mapDragged) {
              restoreCenter()
            }
          }}
        >
          <FlagIcon mapDragged={mapDragged} />
          {mapDragged && (
            <>
              <FirstRingDragged />
              <SecondRingDragged />
            </>
          )}
        </PinAddress>
        {showInput ? (
          <InputWrapper>
            <input
              ref={inputRef}
              type="text"
              onChange={e => setDigit(e.target.value)}
              value={digit}
              placeholder="Ex: 777 3rd Ave, New York, USA"
              autoFocus={true}
            />
            <CancelIcon ref={cancelIconRef} onClick={() => setDigit('')} hide={!digit.length} />
          </InputWrapper>
        ) : (
          <AddressWrapper>
            {searchedAddress && (
              <AddressLocated>
                <StreetAndCity>{searchedAddress.name.long}</StreetAndCity>
                <ZipCodeAndCountry>
                  {searchedAddress.zipCode} {searchedAddress.country.long}
                </ZipCodeAndCountry>
              </AddressLocated>
            )}
            {searchedAddress && <SearchIcon onClick={() => setShowInput(true)} />}
          </AddressWrapper>
        )}

        {customAddresses && !!digit.length && showInput && (
          <AddressesList ref={addressesRef}>
            {customAddresses.length ? (
              customAddresses
                .slice()
                .reverse()
                .map(address => {
                  return (
                    <Address
                      onClick={() => {
                        setAddressToGeocode(address)
                        setShowInput(false)
                        setDigit('')

                        searchParams.set('searchedAddress', address)
                        setSearchParams(searchParams, { replace: true })
                      }}
                      key={address}
                    >
                      <FlagAddress />
                      <span>{address}</span>
                    </Address>
                  )
                })
            ) : (
              <Address>
                {t('Afa.noResultsFor')} {digit}
              </Address>
            )}
          </AddressesList>
        )}
      </WrapperLayer>
    </Wrapper>
  )
}

export default SearchAddress
