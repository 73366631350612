import classnames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AutoSizer, Grid } from 'react-virtualized'
import styled from 'styled-components'
import { addCheckForLongPress } from '../../libs/couvettes'
import { isIPadView, isTouchDevice } from '../../libs/url'
import { Product } from '../../model/product'
import {
  wishlistCurrentCategoryIdSelector,
  wishlistFilterItemsSelector,
} from '../../store/wishlist/selectors'
import { breakpoints, palette } from '../../style/theme'
import { LeftArrow, RightArrow } from '../Arrow/Arrow'
import Couvette from '../Couvettes/Couvette/Couvette'
import PlpSidebar from '../PlpSidebar/PlpSidebar'
import appActions from '../../store/app/actions'
import { appShowSpinner } from '../../store/app/selectors'

const calculateColumnWidth = (items: Product[], isWishlist: boolean) => ({
  index,
}: {
  index: number
}): number => {
  const screenWidth = document.body.scrollWidth

  // If it is wishlist container, calculate last empty column width
  // When last brand in brands slider is selected, first couvette of that brand should be attached
  // to the left side of the screen. If next couvettes don't occupy all space left
  // we stretch last <Grid/> column.
  if (isWishlist && index === items.length) {
    if (!items.length) return 0
    const lastCouvetteBrand = items[index - 1].brandCode
    const fixedCouvettesCount = items.filter(i => i.brandCode === lastCouvetteBrand).length
    const fixedCouvettesWidth =
      fixedCouvettesCount * calculateColumnWidth(items, isWishlist)({ index: 0 })
    return fixedCouvettesWidth <= screenWidth ? screenWidth - fixedCouvettesWidth : 0
  }

  const width = isIPadView() ? 0.3616 : 0.2115
  const margin = 0.0117
  const marginWidth = index === items.length - 1 ? margin : 2 * margin
  return screenWidth * (width + marginWidth)
}

const scrollCouvettesListener = (
  shouldFetchPage: React.MutableRefObject<boolean>,
  getNextPage?: () => void,
) => ({
  scrollLeft,
  clientWidth,
  scrollWidth,
}: {
  scrollLeft: number
  clientWidth: number
  scrollWidth: number
}) => {
  if (
    shouldFetchPage.current &&
    getNextPage &&
    scrollLeft &&
    scrollWidth - scrollLeft < clientWidth * 2
  ) {
    getNextPage()
    shouldFetchPage.current = false
  }
}

type PropsFilterItem = {
  filterItems: Product[]
  hideHeader: boolean
  showBrandLogo: boolean
  isWishlist: boolean
  index: number
}

const FilterItem: React.FC<PropsFilterItem> = ({
  index,
  filterItems,
  hideHeader,
  isWishlist,
  showBrandLogo,
}) => {
  const couvette = filterItems[index]

  // Space for withlist that makes last couvette scroll to the left side of the screen
  return isWishlist && index === filterItems.length && index < filterItems.length ? (
    <div />
  ) : couvette &&
    couvette.mocos &&
    ((Object.keys(couvette.mocos) && Object.keys(couvette.mocos).length) ||
      couvette.mocos.length) ? (
    <Couvette couvette={couvette} hideHeader={hideHeader} showBrandLogo={showBrandLogo} />
  ) : null
}

const CouvetteWrapper = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  background-color: ${palette.wildSand};
  display: flex;
  flex-direction: row;
  flex: 1;

  &.swipe {
    overflow-x: hidden;
  }

  .ReactVirtualized__Grid {
    &:focus {
      outline: none;
    }

    .ReactVirtualized__Grid__innerScrollContainer > div {
      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  @media (max-width: ${breakpoints.M}) {
    flex-direction: column;
  }
`

const Couvettes = styled.div`
  display: inline-flex;
  flex: 1;
  overflow: hidden;
  position: relative;
`

type Props = {
  activeCouvetteId: string | null | undefined
  getNextPage?: () => void
  hideHeader: boolean
  showBrandLogo: boolean
  isWishlist?: boolean
  couvettes: { items: Product[]; loading: boolean }
}

const CouvetteContainer: React.FC<Props> = ({
  getNextPage,
  activeCouvetteId,
  isWishlist = false,
  hideHeader,
  showBrandLogo,
  couvettes,
}) => {
  const [currentSection, setCurrentSection] = useState<{
    columnStopIndex: number
    columnStartIndex: number
  } | null>(null)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [clientWidth, setClientWidth] = useState(0)
  const [scrollWidth, setScrollWidth] = useState(0)
  const shouldFetchPage = useRef(true)
  const intervalReference = useRef(null)
  const gridRef = useRef<Grid>(null)
  const leftArrowRef = useRef(null)
  const rightArrowRef = useRef(null)
  const overscanColumnCount = isIPadView() ? 5 : 7
  const { items } = couvettes

  const wishlistFilterItems = useSelector(wishlistFilterItemsSelector)
  const wishlistCurrentCategoryId = useSelector(wishlistCurrentCategoryIdSelector)

  const showSpinner = useSelector(appShowSpinner)

  const couvetteIndex = (isWishlist
    ? wishlistFilterItems(wishlistCurrentCategoryId)
    : items
  ).findIndex(item => item.modelCode === activeCouvetteId)
  const columnWidth = calculateColumnWidth(items, isWishlist)({ index: 0 })

  useEffect(() => {
    if (activeCouvetteId && couvetteIndex > -1) {
      const scrollLeft = couvetteIndex * columnWidth
      gridRef.current?.scrollToPosition({
        scrollTop: 0,
        scrollLeft,
      })
    }
  }, [isWishlist, wishlistCurrentCategoryId, activeCouvetteId, couvetteIndex, columnWidth])

  useEffect(() => {
    if (!couvettes.loading) {
      shouldFetchPage.current = true
    }

    const onWindowResizeHandler = () => {
      gridRef.current?.recomputeGridSize()
    }
    window.addEventListener('resize', onWindowResizeHandler)

    return () => window.removeEventListener('resize', onWindowResizeHandler)
  }, [couvettes.loading])

  const moveRight = () => {
    addCheckForLongPress(
      'right',
      rightArrowRef.current,
      gridRef,
      intervalReference,
      clientWidth,
      () => {
        gridRef.current?.scrollToCell({
          columnIndex: currentSection?.columnStopIndex || 0,
          rowIndex: 0,
        })
      },
    )
  }

  const moveLeft = () => {
    addCheckForLongPress(
      'left',
      leftArrowRef.current,
      gridRef,
      intervalReference,
      clientWidth,
      () => {
        const computedColumnIndex = (currentSection?.columnStartIndex || 0) - (isIPadView() ? 1 : 2)
        gridRef.current?.scrollToCell({
          columnIndex: computedColumnIndex >= 0 ? computedColumnIndex : 0,
          rowIndex: 0,
        })
      },
    )
  }

  const isScrolledLeft = scrollLeft

  const isScrolledRight =
    scrollWidth - clientWidth === scrollLeft || scrollWidth - clientWidth - scrollLeft < 0

  const hasMoreItems = currentSection && currentSection.columnStopIndex < couvettes.items.length - 1
  const isTouch = isTouchDevice()

  const dispatch = useDispatch()

  useEffect(() => {
    if (showSpinner) {
      dispatch(appActions.setShowSpinner(false))
    }
  }, [dispatch, showSpinner])

  return (
    <CouvetteWrapper
      className={classnames({
        'couvettes-wrapper': true,
        collapsed: hideHeader,
      })}
    >
      <PlpSidebar />

      <Couvettes className="couvettes">
        {!isScrolledLeft || isTouch ? null : (
          <LeftArrow ref={leftArrowRef} onMouseDown={moveLeft} />
        )}
        {(isScrolledRight && !hasMoreItems && !(couvettes.items.length && couvettes.loading)) ||
        isTouch ||
        !couvettes.items.length ||
        couvettes.items.length <= 4 ? null : (
          <RightArrow ref={rightArrowRef} onMouseDown={moveRight} />
        )}
        <AutoSizer style={{ height: '100%' }}>
          {({ height, width }) => (
            <Grid
              cellRenderer={({ columnIndex, key, style }) => (
                <div key={key} style={style}>
                  <FilterItem
                    index={couvettes.items.length ? columnIndex : columnIndex - 1}
                    filterItems={
                      isWishlist ? wishlistFilterItems(wishlistCurrentCategoryId) : couvettes.items
                    }
                    hideHeader={hideHeader}
                    isWishlist={isWishlist}
                    showBrandLogo={showBrandLogo}
                  />
                </div>
              )}
              columnCount={
                isWishlist
                  ? wishlistFilterItems(wishlistCurrentCategoryId).length + 1
                  : couvettes.items.length
              }
              columnWidth={calculateColumnWidth(
                isWishlist ? wishlistFilterItems(wishlistCurrentCategoryId) : couvettes.items,
                isWishlist,
              )}
              height={height}
              overscanColumnCount={overscanColumnCount}
              onSectionRendered={setCurrentSection}
              ref={gridRef}
              rowCount={1}
              rowHeight={height}
              scrollToAlignment={'start'}
              style={{ paddingLeft: '1.17vw' }}
              width={width}
              onScroll={e => {
                setScrollWidth(e.scrollWidth)
                setClientWidth(e.clientWidth)
                setScrollLeft(e.scrollLeft)
                scrollCouvettesListener(shouldFetchPage, getNextPage)(e)
              }}
            />
          )}
        </AutoSizer>
      </Couvettes>
    </CouvetteWrapper>
  )
}

export default CouvetteContainer
