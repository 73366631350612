import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import ImportExport from './components/ImportExport'
import Process from './components/Process'
import Stepper from './components/Stepper'
import SubHeader from './components/SubHeader'
import { scrollbarCss } from '../../components/CommonComponents'
import Header from '../../components/Header'
import PageSocketManager, { PageSocketManagerProps } from '../../components/PageSocketManager'
import devices from '../../config/devices'
import socketManager from '../../libs/socketManager'
import checkoutActions from '../../store/checkout/actions'
import { checkoutCurrentPageSelector } from '../../store/checkout/selectors'
import { palette } from '../../style/theme'
import FooterNav from '../FooterNav/index'
import useFetchInitialData from '../../hooks/useFetchInitialData'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Content = styled.div`
  ${scrollbarCss};
  background-color: ${palette.gallery};
  padding: 0 2vw;
  height: 100%;
`

const CheckoutPage: React.FC<PageSocketManagerProps> = ({ emitEvent }) => {
  useFetchInitialData({ loadCustomer: true, loadContent: true, loadBrands: true })
  const dispatch = useDispatch()
  const page = useSelector(checkoutCurrentPageSelector)

  useEffect(() => {
    return () => {
      dispatch(checkoutActions.resetCheckout())
      dispatch(checkoutActions.resetFilters())
    }
  }, [dispatch])

  useEffect(() => {
    emitEvent('open_checkout_page', {}, devices.table)
    socketManager.emitJoinSignal()
  })

  return (
    <>
      <Wrapper>
        <Header />
        <SubHeader />
        <Stepper />
        <Content>
          {page === 'process' && <Process />}
          {page === 'import-export' && <ImportExport />}
        </Content>
      </Wrapper>
      <FooterNav />
    </>
  )
}

export default PageSocketManager(CheckoutPage)
