import { DdMmYyyyDateString } from '../model/model'

const padZero = (num: number) => `${num < 10 ? '0' : ''}${num}`

export const convertUnixTimeTimestampToYYYYMMDDHHMMSS = (timestamp: number, postfix = 0) => {
  const date = new Date(timestamp)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()

  const result = Number(
    `${year}${padZero(month)}${padZero(day)}${padZero(hours)}${padZero(minutes)}${padZero(
      seconds,
    )}${postfix}`,
  )

  return result
}

export const convertDdMmYyyyToDate = (ddMmYyyyDateString: DdMmYyyyDateString): Date => {
  const [day, month, year] = ddMmYyyyDateString.split('-')

  return new Date(Number(year), Number(month) - 1, Number(day))
}

export const convertTimestampYyyyMmDdHhMmSsToDate = (YyyyMmDdHhMmSsToDate: number): Date => {
  const dateString = YyyyMmDdHhMmSsToDate.toString()
  const year = parseInt(dateString.substring(0, 4), 10)
  const month = parseInt(dateString.substring(4, 6), 10) - 1 // Months are 0-based
  const day = parseInt(dateString.substring(6, 8), 10)
  const hour = parseInt(dateString.substring(8, 10), 10)
  const minute = parseInt(dateString.substring(10, 12), 10)
  const second = parseInt(dateString.substring(12, 14), 10)

  return new Date(year, month, day, hour, minute, second)
}

export const convertDateToDdMmYyyyString = (date: Date): DdMmYyyyDateString => {
  const year = date.getFullYear()
  const day = date.getDate()
  const month = date.getMonth() + 1

  return `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`
}

export const convertDateToYyyyMmDdString = (date: Date): DdMmYyyyDateString => {
  const year = date.getFullYear()
  const day = date.getDate()
  const month = date.getMonth() + 1

  return `${year}${month < 10 ? '0' : ''}${month}${day < 10 ? '0' : ''}${day}`
}

export const sortDdMmYyyyDates = (date1: DdMmYyyyDateString, date2: DdMmYyyyDateString) => {
  const date1Reversed = date1
    .split('-')
    .reverse()
    .join('-')
  const date2Reversed = date2
    .split('-')
    .reverse()
    .join('-')

  return date1Reversed < date2Reversed ? -1 : 1
}
