import { Col } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { formatFilterLabel, getCounterValue } from '../../libs/filters'
import { FilterData, PlpFilters, ToggleFilters } from '../../model/filters'
import BaseFilter from './BaseFilter/BaseFilter'

const getControlClassFromOptionName = (
  optionName: keyof PlpFilters,
  optionObject: { label: string },
) => {
  const mapOptionNameToClass: Partial<Record<keyof PlpFilters, string>> = {
    frontFamilyColor: `round-color ${optionObject.label}`,
    category: 'filter-radio',
  }

  return mapOptionNameToClass[optionName] || ''
}

const FilterTitle = styled.div`
  margin-bottom: 1em;
  font-weight: bold;
  font-size: 1.2em;
`

type Props = {
  toggleSelectedFilters: ToggleFilters
  colClass?: string
  filterName: keyof PlpFilters
  wrapperClass?: string
  translationLabel?: string
  customRender?: () => void
  filter: FilterData
  type?: 'checkbox' | 'radio'
  className?: string
  withoutInput?: boolean
}

const FilterCategory: React.FC<Props> = ({
  filterName,
  wrapperClass = 'filter-wrapper-vertical',
  translationLabel = filterName || '',
  customRender,
  colClass = '',
  type = 'checkbox',
  toggleSelectedFilters,
  filter,
  className,
  withoutInput,
}) => {
  const { t } = useTranslation()
  return (
    <Col className={className ? `${colClass} ${className}` : colClass}>
      <FilterTitle>{t(`Filters.item_${formatFilterLabel(translationLabel)}`)}</FilterTitle>
      <div className={wrapperClass}>
        {customRender ||
          filter.options.map((optionObject, idx) => (
            <BaseFilter
              controlClass={getControlClassFromOptionName(filterName, optionObject)}
              key={idx}
              toggle={() => toggleSelectedFilters(filterName, optionObject.id, type)}
              quantity={getCounterValue(filter, optionObject.id)}
              type={type}
              optionLabel={optionObject.label}
              optionName={optionObject.name}
              filterName={filterName}
              value={optionObject.id}
              checked={filter.selected.includes(optionObject.id)}
              withoutInput={withoutInput}
            />
          ))}
      </div>
    </Col>
  )
}

export default FilterCategory
