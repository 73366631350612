import { useEffect, useState } from 'react'

const useViewportUpdate = (delay?: 250) => {
  const [viewportUpdated, setViewportUpdated] = useState(true)
  const innerWidth = window.innerWidth

  useEffect(() => {
    if (innerWidth) {
      setViewportUpdated(false)
      const interval = setTimeout(() => {
        setViewportUpdated(true)
        clearInterval(interval)
      }, delay)
    }
  }, [innerWidth, delay])

  return {
    viewportUpdated,
  }
}

export default useViewportUpdate
