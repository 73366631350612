import { Icon } from 'antd'
import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'

import { palette } from '../../style/theme'

const Button = styled.button`
  display: flex;
  padding: 0.5em 0.8em;
  color: ${palette.congressBlue};
  &[disabled] {
    opacity: 0.5;
  }
`
Button.displayName = 'DislikeBtn'

const DislikeBtn: React.FC<{
  handleClick: MouseEventHandler
  className?: string
}> = ({ handleClick, className }) => (
  <Button className={['couvette-header__dislike', className].join(' ')} onClick={handleClick}>
    <Icon type="close" theme="outlined" />
  </Button>
)

export default DislikeBtn
