import React from 'react'

import afaActions from '../../store/afa/actions'
import Header from './'
import styled from 'styled-components/macro'
import { getFluidFontSize, getFluidSize, palette } from '../../style/theme'

type Props = {
  prependElement?: JSX.Element
}

const StyledHeader = styled(Header)`
  .ant-btn {
    color: ${palette.tangaroa};
    padding: 0;
    text-transform: capitalize;
    font-weight: 500;
    min-width: 0;
    margin-left: ${getFluidSize('32px')};
    font-size: ${getFluidFontSize('12px')};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`

export const AfaHeader: React.FC<Props> = ({ prependElement }) => {
  return (
    <StyledHeader
      prependElement={prependElement}
      toggleSearchAction={afaActions.toggleSearchOpen}
    />
  )
}

export default AfaHeader
