import React from 'react'
import ProcessTable from './ProcessTable'
import Address from './Address'
import ThankYou from '../ThankYou/ThankYou'
import { useSelector } from 'react-redux'
import { checkoutStepSelector } from '../../../../store/checkout/selectors'

const Process: React.FC = () => {
  const step = useSelector(checkoutStepSelector)

  return (
    <>
      {step === 0 && <ProcessTable />}
      {step === 1 && <Address />}
      {step === 2 && <ThankYou />}
    </>
  )
}

export default Process
