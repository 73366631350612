import { getDeviceFromUrl } from '../libs/url'
import { DeviceName } from '../model/device'

export type Devices = Record<DeviceName, DeviceData>

type Manager = {
  not: (excludedDevices: DeviceData[]) => DeviceData[]
  isCurrentDeviceAuthorized: (autorizedDevices: DeviceData | DeviceData[]) => boolean
  isCurrentDeviceToSync: (
    synchedDevices: DeviceData[],
    requestDevice: DeviceName,
    synchWithItself?: boolean,
  ) => boolean
}
type DeviceManager = Devices & Manager

const deviceManger: Manager = {
  not: function(excludedDevices: DeviceData[]) {
    const excludedDevicesSafeArray = ([] as DeviceData[]).concat(excludedDevices)
    return (
      Object.keys(this)
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        .filter(key => !excludedDevicesSafeArray.includes(devices[key as DeviceName]))
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        .map(key => devices[key as DeviceName])
    )
  },
  isCurrentDeviceAuthorized: function(autorizedDevices = [] as DeviceData[]) {
    const authorizedDeviceSafeArray = ([] as DeviceData[]).concat(autorizedDevices)
    return Boolean(
      !authorizedDeviceSafeArray.length ||
        authorizedDeviceSafeArray.find(device => {
          const currentDevice = getDeviceFromUrl() || ''
          return device.routes.root_url === currentDevice.toLowerCase()
        }),
    )
  },
  isCurrentDeviceToSync: function(
    synchedDevices: DeviceData[],
    requestDevice: DeviceName,
    synchWithItself = false,
  ) {
    const currentDevice = getDeviceFromUrl()
    const isToSync = (device?: string) =>
      !!synchedDevices.find(synchedDevice => synchedDevice.routes.root_url === device)

    return (
      (requestDevice !== currentDevice || synchWithItself) &&
      [currentDevice, requestDevice].map(device => isToSync(device)).every(Boolean)
    )
  },
}

export type DeviceData = {
  name: string
  routes: {
    root_url: string
  }
  ip: string
}

const devices: DeviceManager = Object.assign(Object.create(deviceManger), {
  table: {
    name: 'Touch Table',
    routes: {
      root_url: 'table',
    },
    ip: '',
  },
  wall: {
    name: 'Touch Wall',
    routes: {
      root_url: 'wall',
    },
    ip: '',
  },
  ledleft: {
    name: 'LedWall Marketing',
    routes: {
      root_url: 'led-left',
    },
    ip: '',
  },
  ledright: {
    name: 'LedWall Marketing',
    routes: {
      root_url: 'led-right',
    },
    ip: '',
  },
  extraipad: {
    name: 'Extra iPad',
    routes: {
      root_url: 'extra-ipad',
    },
    ip: '',
  },
  vto: {
    name: 'VTO iPad',
    routes: {
      root_url: 'vto-ipad',
    },
  },
})

export default devices

export const cartSynchedDevices = [devices.table, devices.wall, devices.ledright, devices.ledleft]
export const sessionSynchedDevices = [
  devices.table,
  devices.extraipad,
  devices.wall,
  devices.ledright,
  devices.ledleft,
  devices.vto,
]
