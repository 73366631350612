import React from 'react'
import styled from 'styled-components'

import { palette } from '../../../style/theme'

const Wrapper = styled.div`
  display: flex;
  height: 20vh;
  width: 20vw;
  background: ${palette.black};
  background-size: cover;
`

const Text = styled.div`
  width: 100%;
  text-align: center;
  align-self: center;
  font-size: 32px;
  letter-spacing: 1px;
  line-height: 38px;
  color: ${palette.white};
  padding: 20px;
`

type Props = {
  imageUrl: string
  onClick: () => void
  style?: Record<string, any>
}
const BannerBtn: React.FC<Props> = ({ imageUrl, onClick, style, children }) => {
  const styleRules = style ? style : {}
  return (
    <Wrapper
      className="banner-btn"
      onClick={onClick}
      style={{
        background: `url(${imageUrl})`,
        ...styleRules,
      }}
    >
      <Text>{children}</Text>
    </Wrapper>
  )
}

export default BannerBtn
