import React, { ReactElement } from 'react'

import RCIcon from '../../../../components/UI/RCIcon'
import { ChildrenWrapper, Header, Icon, Subtitle, Title, Wrapper } from './style'

type Props = {
  title?: string
  subtitle?: string
  iconType?: string
  children?: ReactElement<any, any>
}

const Notification: React.FC<Props> = ({ title, iconType, subtitle, children }) => {
  return (
    <Wrapper>
      <Header>
        <Title>
          <Icon>
            <RCIcon type={iconType || 'chat_bubble_outline'} />
          </Icon>{' '}
          {title}
        </Title>
        <Subtitle>{subtitle}</Subtitle>
      </Header>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Wrapper>
  )
}

export default Notification
