import { debounce } from 'lodash'
import React, { CSSProperties, useCallback, useRef } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeGrid as Grid } from 'react-window'
import styled from 'styled-components'
import { useSearchParams } from '../../hooks/useSearchParams'
import { AfaProduct } from '../../model/afa'
import { useGetPlpProductsQuery } from '../../services/afaProducts'
import { breakpoints, breakpointsNoUnit, pxToRem } from '../../style/theme'
import AfaPlpProductCard from './AfaPlpProductCard'

const CardWrapper = styled.div`
  padding: ${pxToRem(17)}rem;

  @media screen and (max-width: ${breakpoints.L}) {
    padding: ${pxToRem(10)}rem;
  }
`

type Props = {
  products: AfaProduct[]
}

const getRowHeight = () => {
  const vw = window.innerWidth
  if (vw < breakpointsNoUnit.S) {
    return Math.min(Math.round(0.59 * vw), 460)
  } else if (vw < breakpointsNoUnit.M) {
    return Math.min(Math.round(0.49 * vw), 540)
  } else if (vw < breakpointsNoUnit.L) {
    return Math.min(Math.round(0.42 * vw), 620)
  } else if (vw < breakpointsNoUnit.XL) {
    return Math.min(Math.round(0.38 * vw), 730)
  } else {
    return Math.min(Math.round(0.32 * vw), 1200)
  }
}

const getColumnCount = (width: number) => {
  if (width > 1800) {
    return Math.round(width / 675)
  } else {
    return Math.round(width / 390)
  }
}

const Cell: React.FC<{
  columnIndex: number
  rowIndex: number
  style: CSSProperties
  data: {
    columnCount: number
    products: AfaProduct[]
  }
}> = ({ columnIndex, rowIndex, style, data }) => {
  const productIndex = rowIndex * data.columnCount + columnIndex
  const product = data.products[productIndex]

  return !product ? null : (
    <CardWrapper key={product.modelCode} style={style}>
      <AfaPlpProductCard product={product} withLink />
    </CardWrapper>
  )
}
Cell.displayName = 'Cell'

const AfaPlpProductsList: React.FC<Props> = ({ products }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const initialScrollTopRef = useRef(
    (() => {
      const scrollTop = searchParams.get('scrollTop')
      return scrollTop ? Number(scrollTop) : 0
    })(),
  )

  const productsQuery = useGetPlpProductsQuery()

  const listHeightRef = useRef(0)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(
    debounce((scrollTop: number) => {
      const updatedSearchParams = new URLSearchParams(location.search)
      updatedSearchParams.set('scrollTop', scrollTop.toString())
      setSearchParams(updatedSearchParams, { replace: true })
    }, 20),
    [],
  )

  return (
    <AutoSizer>
      {({ height, width }) => {
        listHeightRef.current = height

        const columnCount = getColumnCount(width)
        const rowCount = Math.ceil(products.length / columnCount)

        return (
          <Grid
            height={height}
            width={width}
            className="plp-grid"
            columnCount={columnCount}
            columnWidth={Math.round(width / columnCount)}
            rowCount={rowCount}
            rowHeight={getRowHeight()}
            initialScrollTop={initialScrollTopRef.current}
            onScroll={({ scrollTop }) => {
              if (!productsQuery.data || productsQuery.isFetching) {
                return
              }

              if (handleScroll) {
                handleScroll(scrollTop)
              }

              const THRESHOLD = 200
              const scrollHeight = getRowHeight() * rowCount
              const thresholdPassed = scrollTop > scrollHeight - listHeightRef.current - THRESHOLD

              const { start, rows, count } = productsQuery.data
              const thereAreMoreProducts = start + rows < count

              if (thresholdPassed && thereAreMoreProducts) {
                searchParams.set('start', (start + rows).toString())
                setSearchParams(searchParams, { replace: true })
              }
            }}
            itemData={{
              columnCount,
              products,
            }}
          >
            {Cell}
          </Grid>
        )
      }}
    </AutoSizer>
  )
}

export default AfaPlpProductsList
