import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'

import StarsIcon from '../../../components/UI/StarsIcon'
import { orderCartMocos } from '../../../libs/cart'
import { MocoInCart } from '../../../model/cart'
import { activeBrandCodeSelector } from '../../../store/cart/selectors'
import { cartModeSelector } from '../../../store/stars/selectors'
import { palette, pxToRem, spacing } from '../../../style/theme'
import LedListItem from '../LedListItem'
import { BrandLogoImage } from '../../../style/CommonComponents'
import { useGetBrandLogo } from '../../../services/afaContents'
import { brandsSelector } from '../../../store/brands/selectors'

const Wrapper = styled.div`
  border: 2px solid ${palette.doveGray};
  height: 100%;
  display: flex;
  flex: 0;
  flex-direction: column;
  margin: 0 10px;

  @media (max-height: 2159px) {
    margin: 0 1em;
  }
`

const Head = styled.div`
  height: calc(100% / 12);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  color: ${palette.white};

  @media (max-height: 2159px) {
    height: 4em;
  }
`

const Logo = styled(BrandLogoImage)`
  width: 7em;
  height: 100%;
  object-fit: contain;
`

const ContentWrapper = styled.div`
  flex: 1;
  overflow: hidden;
`

const Content = styled.div`
  --gap: 5px;
  --row-height: calc((100% / var(--number-of-rows)) - 1px);
  --row-inner-height: calc(var(--row-height) - var(--gap));

  display: grid;
  grid-template-rows: repeat(var(--number-of-rows), var(--row-inner-height));
  grid-auto-flow: column;
  justify-content: center;
  height: 100%;
  row-gap: var(--gap);
  column-gap: 22px;
  background: repeating-linear-gradient(
    ${palette.white},
    ${palette.white} var(--row-inner-height),
    ${palette.black} var(--row-inner-height),
    ${palette.black} var(--row-height)
  );

  --number-of-rows: 11;

  @media (max-height: 2159px) {
    --gap: 3px;
    --number-of-rows: 8;
    --row-height: calc((100% / var(--number-of-rows)));
    gap: var(--gap);
  }

  @media (max-height: 768px) {
    --number-of-rows: 6;
  }
`

const StyledStarsIcon = styled(StarsIcon)`
  position: absolute;
  height: ${pxToRem(spacing(3.5))}rem;
  width: ${pxToRem(spacing(3.5))}rem;
  top: ${pxToRem(spacing(0.5))}rem;
  left: ${pxToRem(spacing(0.5))}rem;
  color: ${palette.white};
`

type Props = {
  brandSlug: string
  items: MocoInCart[]
  className?: string
}

const LedListCouvette: React.FC<Props> = ({ brandSlug, items, className }) => {
  const activeBrand = useSelector(activeBrandCodeSelector)
  const sortedItems = orderCartMocos(items)
  const selected = brandSlug === activeBrand
  const isStars = useSelector(cartModeSelector) === 'stars'
  const brands = useSelector(brandsSelector)
  const brand = brands?.find(({ code }) => code === brandSlug)
  const { brandLogo, isSuccess } = useGetBrandLogo(brandSlug)

  return (
    <>
      {sortedItems.length > 0 && (
        <Wrapper
          className={`brand-block ledBrandContainer${
            selected ? ' selectedBrand' : ''
          } ${className || ''}`}
          id={brandSlug}
        >
          <Head className="brand-block-head" style={{ position: 'relative' }}>
            {isStars && <StyledStarsIcon />}
            {brandLogo || !isSuccess ? (
              <Logo
                className="brand-block-head-logo alternative"
                src={brandLogo}
                alt={brand?.brand || brandSlug}
              />
            ) : (
              <span>{brand?.brand || brandSlug}</span>
            )}
          </Head>
          <ContentWrapper>
            <Content className="brand-block-content">
              {items
                .sort((a, b) => (a.mocoCode > b.mocoCode ? 1 : -1))
                .map((item, ind) => (
                  <LedListItem product={item} key={`cart-${brandSlug}-${item.mocoCode}-${ind}`} />
                ))}
            </Content>
          </ContentWrapper>
        </Wrapper>
      )}
    </>
  )
}

export default LedListCouvette
