import React from 'react'
import styled from 'styled-components'
import { useShowMoodboard } from '../hooks/useHandleOpenPages'
import { getFluidFontSize, getFluidSize, palette } from '../style/theme'
import { Icon } from 'antd'
import { useTranslation } from 'react-i18next'
import { useGetAfaProductSport } from '../hooks/useGetAfaProductSport'
import { useParams } from 'react-router'
import { useGetProductQuery } from '../services/afaProduct'

const MoodboardButton = styled.div`
  color: ${palette.tangaroa};
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${getFluidSize('6px')};
  font-size: ${getFluidFontSize('12px')};
`

type Props = {
  className?: string
}

const AfaSwitchMoodboardButton: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation()

  const sport = useGetAfaProductSport()

  const { modelCode } = useParams<{ modelCode: string }>()
  const productQuery = useGetProductQuery(modelCode)
  const product = productQuery.data || undefined

  const { thereIsMoodboard, switchMoodboard } = useShowMoodboard(product?.brandCodeParent, sport)

  return !thereIsMoodboard ? null : (
    <MoodboardButton onClick={switchMoodboard} className={className}>
      <Icon type="switcher" />
      <span>{t('Afa.PdpMoodboardButton')}</span>
    </MoodboardButton>
  )
}

export default AfaSwitchMoodboardButton
