import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { StarProduct } from '../../../model/stars'
import { brandEnabledSelector } from '../../../store/brands/selectors'
import { openPDP } from '../../../store/pdp/actions'
import { starsAssortmentByOpenedBrandSelector } from '../../../store/stars/selectors'
import { breakpoints, palette, pxToRem, spacing } from '../../../style/theme'
import Loading from '../../Loading'
import ProductImage from '../../Product/ProductImage/ProductImage'
import ProductIcons from '../../ProductIcons'
import { getProductImageUrl } from '../../../libs/productImages'

const ModuleItem = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${palette.white};
  border-radius: 4px;
  border: solid 1px ${palette.athensGray};
  display: flex;
  flex-direction: column;
`

const ModuleItemHeader = styled.div`
  background-color: ${palette.wildSand};
  border-bottom: solid 1px ${palette.athensGray};
  padding-left: ${pxToRem(spacing(2))}rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: ${pxToRem(spacing(0.625))}rem;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`

const ItemHeader = styled.p`
  font-size: ${pxToRem(spacing(2.5))}rem;
  color: ${palette.mineShaft};
  height: ${pxToRem(spacing(5.5))}rem;

  @media (max-width: ${breakpoints.L}) {
    font-size: ${pxToRem(spacing(1.25))}rem;
    height: ${pxToRem(spacing(2.75))}rem;
  }
`

const ModuleItemBody = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const ModuleItemBodyIcons = styled.div`
  position: absolute;
  top: ${pxToRem(spacing(3))}rem;
  right: ${pxToRem(spacing(3))}rem;

  @media (max-width: ${breakpoints.L}) {
    top: ${pxToRem(spacing(1.5))}rem;
    right: ${pxToRem(spacing(1.5))}rem;
  }
`

const BiggerProductIcons = styled(ProductIcons)`
  img,
  svg {
    width: ${pxToRem(spacing(5))}rem;
    height: ${pxToRem(spacing(5))}rem;
  }

  @media (max-width: ${breakpoints.L}) {
    img,
    svg {
      width: ${pxToRem(spacing(2.5))}rem;
      height: ${pxToRem(spacing(2.5))}rem;
    }
  }
`

type Props = {
  product: StarProduct
}

const CouvetteStarsModuleItem: React.FC<Props> = ({ product }) => {
  const dispatch = useDispatch()
  const openedBrandAssortment = useSelector(starsAssortmentByOpenedBrandSelector)
  const productDetails =
    openedBrandAssortment && openedBrandAssortment.productDetails[product.modelCode]
  const moco = productDetails && productDetails?.mocos[product.colorCode]
  const size = moco && moco?.sizes[product.upc]
  const imgPath = moco && getProductImageUrl({ path: moco.catalogImages.base })
  const brandDisabled = !useSelector(brandEnabledSelector(moco?.brandCode || ''))

  if (!size) {
    return <Loading />
  }

  return (
    <ModuleItem
      onClick={() => !brandDisabled && dispatch(openPDP(product.mocoCode, product.modelCode))}
    >
      <ModuleItemHeader>
        <ItemHeader>{size?.modelCode}</ItemHeader>
        <ItemHeader>{size?.colorCode}</ItemHeader>
        <ItemHeader>{size?.size}</ItemHeader>
      </ModuleItemHeader>
      <ModuleItemBody>
        <ModuleItemBodyIcons>
          {moco && (
            <BiggerProductIcons moco={moco} hiddenIcons={['cart', 'rtr', 'ideal', 'star']} />
          )}
        </ModuleItemBodyIcons>
        <ProductImage clickable={false} imgPath={imgPath} />
      </ModuleItemBody>
    </ModuleItem>
  )
}

export default CouvetteStarsModuleItem
