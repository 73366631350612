import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import useGetCartOrStarProductsAndMoco from '../../../hooks/useGetCartOrStarProductsAndMoco'
import { getDeviceFromUrl, isIpadOnlyRoom } from '../../../libs/url'
import { CartProduct, MocoInCart } from '../../../model/cart'
import {
  getCart,
  rebuildFiltersCounters,
  setActiveBrandAction,
  showFooterModal,
} from '../../../store/actions'
import { isKamUserSelector, isKidCategoryModeEnabledSelector } from '../../../store/app/selectors'
import { starsBrandsSelector } from '../../../store/brands/selectors'
import {
  activeBrandCodeSelector,
  assortmentsViewTypeSelector,
  brandsGroupMapSelector,
  cartBrandsSelector,
} from '../../../store/cart/selectors'
import { selectCartDefaultActiveBrand } from '../../../store/cart/selectors/cartBrand'
import starsActions, { loadStarsAssortment } from '../../../store/stars/actions'
import {
  cartModeSelector,
  starsAssortmentLoadedBrandCodesSelector,
  starsCartBrandSelector,
} from '../../../store/stars/selectors'
import { breakpointsCross, palette } from '../../../style/theme'
import { CartBrandAndMocos, CartWrapper, scrollbarCss } from '../../CommonComponents'
import ChartIcon from '../../icons/ChartIcon'
import ChevronLeftIcon from '../../icons/ChevronLeftIcon'
import Loading from '../../Loading'
import TableCartBrands from '../../TableCart/TableCartBrands'
import TableCartGrid from '../../TableCart/TableCartGrid'
import CartAnalyticsContainer from '../CartAnalyticsContainer'
import CartHeader from '../CartHeader'
import { isCategoryElectronicsOptical, isCategoryOptical } from '../../../libs/couvettes'
import useFetchInitialData from '../../../hooks/useFetchInitialData'

const TableCartGridStyled = styled(TableCartGrid)`
  flex: 1;
  overflow: hidden;
  margin: 0 clamp(10px, 1vw, 30px);

  .ReactVirtualized__Grid,
  .ReactVirtualized__List {
    ${scrollbarCss}
    &::-webkit-scrollbar-track {
      background: ${palette.white};
    }
    &::-webkit-scrollbar-track-piece {
      background: ${palette.white};
      border: 1px solid ${palette.white};
    }
  }

  .slick-slide {
    min-height: 59vh;
  }
`
const IpadCartSummary = styled.div`
  border-top-left-radius: 8px;
  background-image: linear-gradient(to bottom, ${palette.tropicalBlue}, ${palette.selago});
  margin-left: clamp(10px, 1vw, 30px);
  display: flex;
  flex-direction: column;
  color: ${palette.biscay};
  cursor: pointer;

  &.stars {
    background: ${palette.blueStars};
    color: ${palette.white};
  }

  .cart-ipad-summary-head {
    padding: 0.75em;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      width: min-content;
      margin-left: 0.25em;
      line-height: 1.2;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .cart-ipad-summary-icon {
    flex: 1;
    height: 2em;
    width: 2em;
    margin: 0 auto;
  }

  @media (min-width: ${breakpointsCross.XL.min}) {
    display: none;
  }
`

const TableCartContent: React.FC<{
  className?: string
}> = ({ className }) => {
  useFetchInitialData({ loadCustomer: true, loadContent: true, loadBrands: true })
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [isAnalyticsVisible, setIsAnalyticsVisible] = useState(false)

  const isKamUser = useSelector(isKamUserSelector)
  const cartBrands = useSelector(cartBrandsSelector)
  const activeBrandCart = useSelector(activeBrandCodeSelector)
  const shopCategory = useSelector(assortmentsViewTypeSelector)
  const starsBrands = useSelector(starsBrandsSelector)
  const starsCartBrands = useSelector(starsCartBrandSelector)
  const mode = useSelector(cartModeSelector)
  const isStarsCart = mode === 'stars'
  const loadedStarsBrands = useSelector(starsAssortmentLoadedBrandCodesSelector)
  const defaultActiveBrand = useSelector(selectCartDefaultActiveBrand)
  const isJuniorMode = useSelector(isKidCategoryModeEnabledSelector)
  const brandsGroupMap = useSelector(brandsGroupMapSelector)

  const activeBrand = activeBrandCart || defaultActiveBrand

  const deviceType = getDeviceFromUrl()

  const loadedStarsBrandsGroupMap = useMemo(() => {
    const loadedStarsBrandsGroupMap = Object.fromEntries(
      Object.entries(brandsGroupMap).filter(([key]) => loadedStarsBrands.includes(key)),
    )
    return loadedStarsBrandsGroupMap
  }, [brandsGroupMap, loadedStarsBrands])

  useEffect(() => {
    if (!isStarsCart) {
      if (
        deviceType !== 'wall' && // fix: 5653 if device is wall the selected brand comes from the table when brand is switched on table, in this case don't select the first brand, both because it makes no sense, and because it triggers a change on the selected brand on table (big 5653)
        cartBrands.length > 0 &&
        !cartBrands.find(({ brand }) => brand.code === activeBrand)
      ) {
        dispatch(setActiveBrandAction(cartBrands[0].brand.code))
      }
    } else {
      const loadedStarsBrandsGroupMapKeys = Object.keys(loadedStarsBrandsGroupMap)
      if (
        loadedStarsBrandsGroupMapKeys.length > 0 &&
        !loadedStarsBrandsGroupMapKeys.includes(activeBrand || '')
      ) {
        const parentBrand = Object.entries(loadedStarsBrandsGroupMap).find(([, group]) =>
          group.includes(activeBrand || ''),
        )?.[0]
        dispatch(setActiveBrandAction(parentBrand ? parentBrand : loadedStarsBrandsGroupMapKeys[0]))
      }
    }
  }, [dispatch, deviceType, activeBrand, cartBrands, isStarsCart, loadedStarsBrandsGroupMap])

  const { mocos, products } = useGetCartOrStarProductsAndMoco()

  const filteredStarsBrands = useMemo(() => {
    return starsBrands
      .flatMap(({ subBrands }) => subBrands)
      .filter(({ junior }) => junior === isJuniorMode)
  }, [starsBrands, isJuniorMode])

  useEffect(() => {
    if (isStarsCart && filteredStarsBrands.length > 0) {
      dispatch(loadStarsAssortment(filteredStarsBrands.map(({ code }) => code)))
    }
  }, [dispatch, filteredStarsBrands, isStarsCart])

  useEffect(() => {
    dispatch(getCart())

    return () => {
      dispatch(starsActions.resetStarsFilters())
    }
  }, [dispatch])

  const selectedCategory = shopCategory?.category

  useEffect(() => {
    if (isIpadOnlyRoom() && selectedCategory && ['1', '2'].includes(selectedCategory)) {
      dispatch(showFooterModal('planogram'))
    }
  }, [dispatch, selectedCategory])

  const toggleAnalytics = () => {
    setIsAnalyticsVisible(!isAnalyticsVisible)
  }

  const { sunMocos, optMocos } = mocos.reduce(
    (result, mocoInCart) => {
      const isOptical =
        isCategoryOptical(mocoInCart.categoryId) ||
        isCategoryElectronicsOptical(mocoInCart.categoryId)
      result[isOptical ? 'optMocos' : 'sunMocos'].push(mocoInCart)
      return result
    },
    { sunMocos: [] as (CartProduct | MocoInCart)[], optMocos: [] as (CartProduct | MocoInCart)[] },
  )

  const starsAssortmentLoaded =
    // if we disable some doors after having loaded the cart
    // we end up with more loaded stars brands than active ones
    loadedStarsBrands.length >= filteredStarsBrands.length

  useEffect(() => {
    if (starsAssortmentLoaded) {
      dispatch(rebuildFiltersCounters())
    }
  }, [dispatch, starsAssortmentLoaded])

  return (
    <CartWrapper className={['cart', className].join(' ')}>
      <CartHeader title={mode !== 'stars' ? t('Header.cart') : t('Header.starsCart')} />

      {isStarsCart && !starsAssortmentLoaded ? (
        <Loading />
      ) : (
        <CartBrandAndMocos className="cart-page device-table">
          <TableCartBrands
            brands={(mode === 'stars' ? starsCartBrands : cartBrands) || []}
            activeBrand={activeBrand}
          />

          <TableCartGridStyled
            category="sun"
            mocosInCart={sunMocos}
            cartProducts={products}
            brands={mode !== 'stars' ? cartBrands : starsCartBrands || []}
            activeBrand={activeBrand}
          />
          <TableCartGridStyled
            category="optical"
            mocosInCart={optMocos}
            cartProducts={products}
            brands={mode !== 'stars' ? cartBrands : starsCartBrands || []}
            activeBrand={activeBrand}
          />
          {isKamUser ? (
            <>
              <IpadCartSummary
                className={['cart-ipad-summary', mode === 'stars' ? 'stars' : undefined].join(' ')}
                onClick={toggleAnalytics}
              >
                <div className="cart-ipad-summary-head">
                  <ChevronLeftIcon />
                  {mode === 'stars' ? (
                    <span>{t('GenericWords.summary')}</span>
                  ) : (
                    <span>{t('GenericWords.order_summary')}</span>
                  )}
                </div>
                <ChartIcon className="cart-ipad-summary-icon" />
              </IpadCartSummary>
              <CartAnalyticsContainer
                visible={isAnalyticsVisible}
                toggleAnalytics={toggleAnalytics}
              />
            </>
          ) : null}
        </CartBrandAndMocos>
      )}
    </CartWrapper>
  )
}

export default TableCartContent
