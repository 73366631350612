import {
  breakpoints,
  breakpointsCross,
  duration,
  palette,
  pxToRem,
  zIndex,
} from '../../../style/theme'
import styled from 'styled-components/macro'
import React from 'react'
import { useTranslation } from 'react-i18next'
import RCIcon from '../../../components/UI/RCIcon'
import ChartIcon from '../../../components/icons/ChartIcon'
import { useDispatch, useSelector } from 'react-redux'
import {
  cartSummarySelectedPageSelector,
  displayAsLayerOpenSelector,
  isSummaryOpenSelector,
  showFiltersSelector,
} from '../../../store/afaCartSummary/selectors'
import AfaCartSummaryHeader from './components/AfaCartSummaryHeader'
import { getFluidSizeWithFullFormula as gF } from '../../../style/theme'
import AfaCartSummaryHeaderPages from './components/AfaCartSummaryHeaderPages'
import AfaCartSummaryPageOne from './components/AfaCartSummaryPageOne'
import AfaCartSummaryPageTwo from './components/AfaCartSummaryPageTwo'
import AfaCartSummaryPagesSelector from './components/smallComponents/AfaCartSummaryPagesSelector'
import { useGetAfaCartSummaryData } from '../../../hooks/useGetAfaCartSummaryData'
import afaCartSummaryActions from '../../../store/afaCartSummary/actions'
import { DateString } from '../../../model/model'

const AfaCartSummaryWrapper = styled.div`
  display: flex;
  width: 45%;
  transition: transform ${duration.enteringScreen}ms ease-in-out;

  @media screen and (max-width: ${breakpoints.L}) {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    z-index: ${zIndex.afaCartSummary};

    &.close {
      transform: translateX(calc(100% - ${pxToRem(120)}rem));
    }

    &.open {
      transform: translateX(0);
    }
  }
`

const ColumnSummary = styled.div`
  cursor: pointer;
  background-color: ${palette.mischka};
  width: ${pxToRem(120)}rem;
  gap: ${pxToRem(16)}rem;
  padding: ${pxToRem(32.5)}rem ${pxToRem(8)}rem;
  padding-left: ${pxToRem(20)}rem;
  display: none;
  flex-direction: column;
  align-items: center;

  & > svg {
    margin-top: auto;
    margin-bottom: auto;
    height: 2em;
    width: 2em;
  }

  @media screen and (max-width: ${breakpoints.L}) {
    display: flex;
  }
`

const SummaryWrapper = styled.div`
  background-color: ${palette.extraLight};
  transition: width 1s ease-in-out;
  font-size: ${gF('px', 15, 27, 1366, 3840)};
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${breakpoints.L}) {
    width: calc(100vw - ${pxToRem(120)}rem);
  }
`

const Title = styled.div`
  display: flex;
  color: ${palette.tangaroa};
  font-size: ${pxToRem(14)}rem;
  align-items: center;
  font-family: GilmerBold;
  gap: ${pxToRem(8)}rem;
  text-transform: uppercase;
`

const NoDataToShow = styled.div`
  font-size: 2em;
  text-align: center;
  white-space: pre-line;
  padding-top: 4em;
  color: ${palette.santasGray};
`

const MainWrapper = styled.div`
  background-color: ${palette.extraLight};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 clamp(64px, 2em, 88px) clamp(80px, 1.1em, 88px) clamp(88px, 1.1em, 88px);
  flex: 1;

  & footer {
    display: flex;
    justify-content: flex-end;

    @media (max-width: ${breakpointsCross.L.min}) {
      display: none;
    }
  }

  @media (max-width: ${breakpoints.M}) {
    display: block;
    padding: 0 clamp(32px, 2em, 88px) clamp(16px, 1.1em, 88px) clamp(16px, 1.1em, 88px);
  }
`

export type DataCartSummary = {
  categoryKey: string
  data: {
    elementKey: string
    value: number
    quantity: number
  }[]
}

export type DataCartSummeryTable = {
  productType: string
  total: {
    quantity: number
    value: number
  }
  avdData: {
    avdCode: string
    quantity: number
    value: number
  }[]
  deliveryDates: Record<DateString, { quantity: number; value: number }>
}

const AfaCartSummary: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const showFilters = useSelector(showFiltersSelector)
  const displayAsLayerOpen = useSelector(displayAsLayerOpenSelector)
  const isSummaryOpen = useSelector(isSummaryOpenSelector)
  const cartSummarySelectedPage = useSelector(cartSummarySelectedPageSelector)

  const { filteredData, options } = useGetAfaCartSummaryData()

  const noData = !filteredData?.graphs.length && !filteredData?.table.length

  const closeFilters = () => {
    displayAsLayerOpen && dispatch(afaCartSummaryActions.setDisplayAsLayerOpen(false))
    showFilters && dispatch(afaCartSummaryActions.setShowFilters(false))
  }

  return (
    <AfaCartSummaryWrapper
      onClick={() => closeFilters()}
      className={isSummaryOpen ? 'open' : 'close'}
    >
      <ColumnSummary
        onClick={() => {
          dispatch(afaCartSummaryActions.toggleSummary())
          closeFilters()
        }}
      >
        <Title>
          {!isSummaryOpen ? (
            <>
              <RCIcon arrow type="left" />
              {t('GenericWords.order_summary')}
            </>
          ) : (
            <>
              <RCIcon arrow type="right" />
              {t('GenericWords.order_details')}
            </>
          )}
        </Title>
        <ChartIcon />
      </ColumnSummary>
      <SummaryWrapper>
        <AfaCartSummaryHeader />
        <MainWrapper>
          {!noData ? (
            <>
              <AfaCartSummaryHeaderPages options={options || []} />
              {cartSummarySelectedPage === 'graphs' && (
                <AfaCartSummaryPageOne data={filteredData.graphs} />
              )}

              {cartSummarySelectedPage === 'table' && (
                <AfaCartSummaryPageTwo tableData={filteredData.table} />
              )}
            </>
          ) : (
            <NoDataToShow>{t('Afa.noDataToShow').replace('.', '.\n')}</NoDataToShow>
          )}
          <footer>
            <AfaCartSummaryPagesSelector noData={noData} />
          </footer>
        </MainWrapper>
      </SummaryWrapper>
    </AfaCartSummaryWrapper>
  )
}

export default AfaCartSummary
