import { RootState } from '../../model/model'

export const notificationsSelector = (state: RootState) => ({
  dislikes: state.notifications.dislikes,
  fastAddsToCart: state.notifications.fastAddsToCart,
  missingModelCodes: state.notifications.missingModelCodes,
  orientationAlert: state.notifications.orientationAlert,
})

export const showNotEditableCartNotificationSelector = (state: RootState) =>
  state.notifications.showNotEditableCartNotification

export const slowCartNotificationSelector = (state: RootState) =>
  state.notifications.slowCartNotification

export const socketConnectionStatusSelector = (state: RootState) =>
  state.notifications.socketConnectionStatus
