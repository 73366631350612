import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getDeviceBasePath, isCategoriesPage, isPageMatches, isPLPPage } from '../../../libs/url'
import styled from 'styled-components'
import RCIcon from '../../../components/UI/RCIcon'
import { pxToRem } from '../../../style/theme'
import { useSelector } from 'react-redux'
import { brandsSelector, openedBrandsSelector } from '../../../store/brands/selectors'
import { isKidCategoryModeEnabledSelector } from '../../../store/app/selectors'
import { useGetCustomerHasAssortmentQuery } from '../services'
import { Link } from 'react-router-dom'
import { useSendAnalytics } from '../../../services/analytics'

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: ${pxToRem(6)}rem;
  line-height: 1;
  color: inherit;
  text-decoration: none;
  font-family: inherit;

  & > img {
    height: 1.1em;
    width: 1.1em;
  }
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  width: min-content;
  text-align: left;
  font-weight: 900;
`

const AACta: React.FC = () => {
  const { t } = useTranslation()
  const brands = useSelector(brandsSelector)

  const openedBrands = useSelector(openedBrandsSelector)
  const isJunior = useSelector(isKidCategoryModeEnabledSelector)

  const baseUrl = `${getDeviceBasePath()}/assortment-advisor`
  const openedBrandCodes = openedBrands.map(({ code }) => code)

  const filteredBrands = useMemo(() => {
    const filteredBrands = brands.filter(brand => {
      return brand.subBrands.some(({ junior }) => !junior)
    })
    return filteredBrands
  }, [brands])
  const filteredBrandCodes = filteredBrands.map(({ code }) => code)

  const hasAssortmentQuery = useGetCustomerHasAssortmentQuery()
  const showCta =
    hasAssortmentQuery.data === true &&
    brands.length > 0 &&
    (isPLPPage() || isCategoriesPage() || isPageMatches('choose-brand'))

  const [sendAnalytics] = useSendAnalytics()

  const isSingleBrand = isPageMatches('single-brand')

  return showCta ? (
    <StyledLink
      onClick={() => sendAnalytics(isSingleBrand ? 'BRAND_PAGE_ACCESS' : 'HOME_PAGE_ACCESS')}
      to={
        openedBrands.length > 0
          ? `${baseUrl}/plp?brands=${openedBrandCodes.join(',')}${isJunior ? '&junior=true' : ''}`
          : `${baseUrl}/plp?brands=${filteredBrandCodes.join(',')}`
      }
    >
      <RCIcon type="assortment-advisor" />
      <Text>{t('AA.assortmentAdvisor')}</Text>
    </StyledLink>
  ) : null
}

export default AACta
