import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CmsContent } from '../../model/content'
import { CouvetteVideoState } from '../../model/couvettes'
import { resetForNewClient } from '../extraActions'

const DEFAULT_STATE: CouvetteVideoState = {
  media: null,
  mediaUniqueId: null,
  dates: null,
  isPaused: false,
}

export const slice = createSlice({
  name: 'couvetteVideo',
  initialState: DEFAULT_STATE,
  reducers: {
    _play_video: (
      state,
      { payload }: PayloadAction<{ media: CmsContent; mediaUniqueId: string; dates?: string[] }>,
    ) => {
      state.media = payload.media
      state.mediaUniqueId = payload.mediaUniqueId
      state.dates = payload.dates
      state.isPaused = false
    },
    _pause_video: state => {
      state.isPaused = true
    },
    _end_video: () => {
      return DEFAULT_STATE
    },
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, () => DEFAULT_STATE)
  },
})

export default slice.reducer
