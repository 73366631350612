import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { breakpointsCross, fluidFontSize, palette, pxToRem } from '../../style/theme'
import DuplicateIcon from '../../components/icons/DuplicateIcon'
import { useGetFilteredAfaCartQuery } from '../../services/afaCart'
import { Door } from '../../model/customer'
import { useGetExpiredCartProducts } from '../../hooks/useGetExpiredCartProducts'

const DuplicateInfoButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette.white};
  color: ${palette.tangaroa};
  text-transform: uppercase;
  font-family: GilmerBold;
  border-radius: 20rem;
  padding: ${pxToRem(6)}rem ${pxToRem(20)}rem;
  border: 2px solid ${palette.white};
  font-size: ${fluidFontSize};
  box-shadow: 0 ${pxToRem(4)}rem ${pxToRem(4)}rem 0 rgba(3, 20, 52, 0.17);

  &[disabled] {
    opacity: 0.5;
  }

  &.bottom {
    display: none;
    margin-top: ${pxToRem(93)}rem;
    margin-bottom: ${pxToRem(53)}rem;

    @media (min-width: ${breakpointsCross.XL.min}) {
      display: block;
    }
  }

  @media (min-width: ${breakpointsCross.XL.min}) {
    display: none;
  }

  svg {
    margin-right: ${pxToRem(6)}rem;
  }
`

type Props = {
  activeDoors: Door[]
  selectedDoorId: string
  colorCode: string
  modelCode: string
  variant: 'top' | 'bottom'
  setDuplicateInfoModalOpen: (state: boolean) => void
}

const DuplicateButton: React.FC<Props> = ({
  activeDoors,
  selectedDoorId,
  colorCode,
  modelCode,
  variant,
  setDuplicateInfoModalOpen,
}) => {
  const { t } = useTranslation()

  const getCartQuery = useGetFilteredAfaCartQuery()
  const { expiredDropsCartProducts } = useGetExpiredCartProducts()
  const expiredProductsKeys = expiredDropsCartProducts.map(({ key }) => key)
  const thereAreProductsInCartForThisDoor = getCartQuery.data?.items.some(
    cartProduct =>
      !expiredProductsKeys.includes(cartProduct.key) &&
      cartProduct.modelCode === modelCode &&
      cartProduct.colorCode === colorCode &&
      cartProduct.doorId === selectedDoorId,
  )

  return activeDoors.length > 1 ? (
    <DuplicateInfoButton
      className={variant}
      disabled={!thereAreProductsInCartForThisDoor}
      onClick={() => setDuplicateInfoModalOpen(true)}
    >
      <DuplicateIcon />
      {t('Afa.duplicateInfo')}
    </DuplicateInfoButton>
  ) : null
}

export default DuplicateButton
