import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { AlphaNumericKeyboardInput } from '../../components/KeyboardInput'
import TestingSpeedWidget from '../../components/TestingSpeedWidget'
import RedCarpetLogo from '../../components/UI/RedCarpetLogo'
import app_config from '../../config/app/config'
import socketManager from '../../libs/socketManager'
import { keys } from '../../libs/utils'
import { DeviceName, RoomType } from '../../model/model'
import { setDeviceName, setRoomName, setRoomType } from '../../store/app/actions'
import { roomNameSelector, roomTypeSelector } from '../../store/app/selectors'
import { breakpoints, palette, pxToRem } from '../../style/theme'

const roomNameLabels = {
  ...app_config.roomType,
  FULL: 'extra large',
  IPAD_ONLY: 'iPad/laptop only',
}

const getMapRoomType = (baseUrl: string) => ({
  [app_config.roomType.SMALL]: [
    ['table', ['iPad']],
    ['wall', ['Wall screen']],
  ],
  [app_config.roomType.MEDIUM]: [
    ['table', ['Touchtable']],
    ['wall', ['Wall screen']],
    ['vto-ipad', ['VTO iPad'], baseUrl],
  ],
  [app_config.roomType.LARGE]: [
    ['table', ['Touchtable']],
    ['wall', ['Wall screen']],
    ['led-left', ['Videowall']],
    ['vto-ipad', ['VTO iPad'], baseUrl],
  ],
  [app_config.roomType.FULL]: [
    ['table', ['Touchtable']],
    ['wall', ['Wall screen']],
    ['extra-ipad', ['Extra iPad'], baseUrl],
    ['led-left', ['Left Videowall']],
    ['led-right', ['Right VideoWall']],
    ['vto-ipad', ['VTO iPad'], baseUrl],
  ],
  [app_config.roomType.IPAD_ONLY]: [['table', ['iPad', 'iPad/laptop']]],
})

const borderRadius = '8px'

const Wrapper = styled.div`
  background-color: white;
  padding: ${pxToRem(60)}rem;
  color: ${palette.mineShaft};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  .red-carpet-logo {
    height: 60px;
  }

  input:checked + .home-page__card {
    border-color: ${palette.cornflowerBlue};
  }

  .keyboard-input {
    max-width: ${pxToRem(225)}rem;
  }
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${pxToRem(100)}rem;
`

const MainWrapper = styled.main`
  overflow: auto;
  flex: 1;
  max-width: ${pxToRem(1245)}rem;
  width: 100%;
  padding-top: calc(97vh - 1100px);
  margin: 0 auto;
`

const Main = styled.div`
  display: grid;
  grid-template-columns: 100%;
  gap: ${pxToRem(30)}rem;
`

const Title = styled.h1`
  text-transform: uppercase;
  font-size: ${pxToRem(20)}rem;
`

const Subtitle = styled.h2`
  font-size: ${pxToRem(20)}rem;
  margin-bottom: ${pxToRem(15)}rem;
`

const RoomList = styled.div`
  display: grid;
  grid-gap: ${pxToRem(30)}rem;
  grid-template-columns: repeat(5, 1fr);
`

const RoomInput = styled.input`
  display: none;
`

const cardStyle = css`
  background-color: ${palette.wildSand};
  border: solid 1px ${palette.geyser};
  padding: ${pxToRem(15)}rem;
  border-radius: ${borderRadius};
  height: 100%;
`

const Card = styled.div`
  ${cardStyle}
`

const CardRoomTitle = styled.p`
  font-size: ${pxToRem(20)}rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: ${pxToRem(16)}rem;
`

const CardRoomDevices = styled.div`
  color: ${palette.doveGray};
  font-size: ${pxToRem(16)}rem;
`

const CardRoomDevicesLabel = styled.p`
  margin-bottom: 0;
`

const DeviceList = styled.ul`
  padding-left: 0;
  margin-bottom: 0;
  display: grid;
  grid-gap: ${pxToRem(30)}rem;
  grid-template-columns: repeat(6, 1fr);
`

const RoomsUl = styled.ul`
  font-style: italic;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  li::before {
    content: '-';
    margin-right: 0.5em;
  }
`

const CardDevice = styled.div`
  ${cardStyle}
  padding: 100% 0 0 0;
  position: relative;
  &:hover,
  &:active {
    border-color: ${palette.cornflowerBlue};
  }
`

const CardDeviceLabel = styled.span`
  text-transform: uppercase;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TableCheckSpeedContainer = styled.div`
  text-align: right;
  display: none;
`

const TableCheckSpeedContainerDesktop = styled(TableCheckSpeedContainer)`
  @media screen and (min-width: ${breakpoints.XL}) {
    display: block !important;
  }
`

const SpeedButton = styled.button`
  border: solid 1px ${palette.geyser};
  border-radius: ${borderRadius};
  background: ${palette.wildSand} !important;
  padding: 0.5em 1em;
  font: inherit;
  appearance: none;
  &:focus {
    color: ${palette.doveGray};
    border-color: ${palette.geyser};
  }
  &.active {
    color: ${palette.doveGray};
    border: solid 1px ${palette.cornflowerBlue};
  }
  @media screen and (min-width: ${breakpoints.XL}) {
    display: none !important;
  }
`

type CheckSpeedButtonProps = {
  active: boolean
  handleClick: (active: boolean) => void
}

const CheckSpeedButton: React.FC<CheckSpeedButtonProps> = ({ active, handleClick }) => {
  const { t } = useTranslation()
  return (
    <SpeedButton
      onClick={() => {
        handleClick(!active)
      }}
      className={`check-speed-btn ${active ? 'active' : ''}`}
    >
      {t('RoomForm.checkSpeed')}
    </SpeedButton>
  )
}

const SettingsPage: React.FC = () => {
  const { t } = useTranslation()
  const selectedRoomName = useSelector(roomNameSelector)
  const roomType = useSelector(roomTypeSelector)
  const history = useHistory()
  const dispatch = useDispatch()
  const [testingSpeed, setTestingSpeed] = useState(false)
  const baseUrl = `/${selectedRoomName}`
  const mapRoomType = getMapRoomType(baseUrl)

  return (
    <Wrapper className="home-page">
      <Header className="home-page__header">
        <RedCarpetLogo />
        {testingSpeed && <TestingSpeedWidget />}
        <CheckSpeedButton active={testingSpeed} handleClick={setTestingSpeed} />
      </Header>
      <MainWrapper>
        <Main className="home-page__main">
          <TableCheckSpeedContainerDesktop>
            <CheckSpeedButton active={testingSpeed} handleClick={setTestingSpeed} />
          </TableCheckSpeedContainerDesktop>
          <Title>{t('RoomForm.title')}</Title>
          <div>
            <Subtitle>{t('RoomForm.selectConfiguration')}</Subtitle>
            <RoomList>
              {keys(app_config.roomType).map(roomKey => {
                const roomTypeName = app_config.roomType[roomKey] as RoomType
                const roomLabel = roomNameLabels[roomKey]
                return (
                  <label key={roomKey}>
                    <RoomInput
                      type="radio"
                      name="room-selection"
                      checked={roomTypeName === roomType}
                      onChange={() => dispatch(setRoomType(roomTypeName))}
                      data-testid={roomTypeName}
                    />
                    <Card className="home-page__card">
                      <CardRoomTitle>{roomLabel}</CardRoomTitle>
                      <CardRoomDevices>
                        <CardRoomDevicesLabel>{t('RoomForm.devices')}</CardRoomDevicesLabel>
                        <RoomsUl>
                          {mapRoomType[roomTypeName].map(([deviceKey, [deviceName]]) => (
                            <li key={Array.isArray(deviceKey) ? deviceKey[0] : deviceKey}>
                              {deviceName}
                            </li>
                          ))}
                        </RoomsUl>
                        {roomTypeName === app_config.roomType.IPAD_ONLY && (
                          <>
                            <div>or</div>
                            <RoomsUl>
                              <li>laptop</li>
                            </RoomsUl>
                          </>
                        )}
                      </CardRoomDevices>
                    </Card>
                  </label>
                )
              })}
            </RoomList>
          </div>
          <div>
            <Subtitle>
              {t('RoomForm.insert')}{' '}
              {t(
                roomType === app_config.roomType.IPAD_ONLY
                  ? 'RoomForm.username'
                  : 'RoomForm.roomName',
              )}
            </Subtitle>
            <AlphaNumericKeyboardInput
              placeholder={t(
                roomType === app_config.roomType.IPAD_ONLY
                  ? 'RoomForm.input_username_placeholder'
                  : 'RoomForm.input_room_placeholder',
              )}
              isFirstLetterUppercase={false}
              uppercaseAfterSpace={false}
              onChange={(roomName: string) => dispatch(setRoomName(roomName))}
              inputClassName="ant-input"
              value={selectedRoomName}
            />
          </div>
          <div>
            {selectedRoomName && (
              <>
                <Subtitle>{t('RoomForm.selectDevice')}</Subtitle>
                <DeviceList>
                  {mapRoomType[roomType || app_config.roomType.FULL]?.map(
                    ([slug, [defaultDeviceName, overrideDeviceName], to]) => (
                      <CardDevice
                        className="home-page__card"
                        key={Array.isArray(slug) ? slug[0] : slug}
                        onClick={() => {
                          dispatch(setDeviceName(slug as DeviceName))
                          // emitJoinSignal is mandatory to join room with socket io during first load
                          socketManager.emitJoinSignal()

                          // if (isAuthenticated(token)) {
                          history.push(
                            `${to ||
                              `${baseUrl}/${roomType?.replace(' ', '-').toLowerCase()}`}/${slug}${
                              slug === 'table' ? '/choose-brand' : ''
                            }`,
                          )
                          // } else {
                          //   window.location.replace(
                          //     'https://poctecluxrouter.azurewebsites.net/signin'
                          //   )
                          // }
                        }}
                      >
                        <CardDeviceLabel>{overrideDeviceName || defaultDeviceName}</CardDeviceLabel>
                      </CardDevice>
                    ),
                  )}
                </DeviceList>
              </>
            )}
          </div>
        </Main>
      </MainWrapper>
    </Wrapper>
  )
}

export default SettingsPage
