import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { fullResetFiltersAction } from '../../store/actions'

import starsActions from '../../store/stars/actions'
import { cartModeSelector } from '../../store/stars/selectors'
import { palette, pxToRem, spacing } from '../../style/theme'
import { isPageMatches } from '../../libs/url'
import { useHistory } from 'react-router-dom'

const Switcher = styled.div`
  width: calc(${pxToRem(180)}rem - (${pxToRem(spacing(2))}rem * 2));
`

const activeBorderHeight = '3px'
const SwitcherButton = styled.button`
  width: ${pxToRem(spacing(9))}rem;
  text-align: center;
  color: ${palette.hibiscus};
  background-color: ${palette.white};
  opacity: 0.8;
  border: 1px solid lightgrey;
  text-transform: uppercase;
  font-size: 0.75rem;
  padding-top: ${activeBorderHeight};

  &.activeButton {
    position: relative;
    font-weight: bold;
    opacity: 1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: ${activeBorderHeight};
      background-color: ${palette.blueStars};
    }
  }

  &.in {
    border-right: none;
  }

  &.out {
    border-left: none;
  }
`

type Props = {
  className?: string
  onChange?: (mode: 'units' | 'stars') => void
}
const StarsWholesaleSwitch: React.FC<Props> = ({ className, onChange }) => {
  const { t } = useTranslation()

  const selectedMode = useSelector(cartModeSelector)

  const dispatch = useDispatch()

  const isSearchResultPage = isPageMatches('products')

  const history = useHistory()

  const resetFilters = () => {
    if (isSearchResultPage) {
      history.goBack()
    }
    dispatch(fullResetFiltersAction(['brand_slug']))
  }

  return (
    <Switcher className={['stars-wholesale-switch', className].join(' ')}>
      <SwitcherButton
        className={selectedMode === 'units' ? 'activeButton in' : ''}
        onClick={() => {
          dispatch(starsActions.setMode('units'))
          resetFilters()
          onChange && onChange('units')
        }}
      >
        {t('Plp.full')}
      </SwitcherButton>
      <SwitcherButton
        className={selectedMode === 'stars' ? 'activeButton out' : ''}
        onClick={() => {
          dispatch(starsActions.setMode('stars'))
          resetFilters()
          onChange && onChange('stars')
        }}
      >
        {t('GenericWords.stars')}
      </SwitcherButton>
    </Switcher>
  )
}

export default StarsWholesaleSwitch
