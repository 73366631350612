import { format } from 'date-fns'
import { breakpointsNoUnit } from '../style/theme'
import { AfaProduct } from '../model/afa'
import { WhiteboardItemTypeSettings, WhiteboardType } from '../model/whiteboard'
import { SubBrand } from '../model/brand'

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const openAutoDevice = () => {}

export const today = () => {
  return format(new Date(), 'yyy-MM-dd')
}

export const getCurrencySymbol = (value: number | string, currency = 'EUR') =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency: currency.toUpperCase() })
    .format(Number(value))
    .replace(/^(\D+)/, '$1 ')

export const formatName = (name = '') =>
  name.charAt(0).toUpperCase() +
  name
    .slice(1)
    .toLowerCase()
    .replace('_', ' ')

export const firstLetterCapital = (str: string): string => {
  if (!str.length) {
    return str
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const isSubBrandCategory = (subBrand: SubBrand, category: string, isJuniorMode: boolean) => {
  return (
    isJuniorMode === subBrand.junior &&
    ((subBrand.electronic && category === 'E') ||
      (subBrand.electronic && category === 'K') ||
      (subBrand.optical && category === '1') ||
      (subBrand.sun && category === '2'))
  )
}

const getIndexInRange = (num: number, steps: number) => {
  let index
  index = Math.floor(num / steps) + 1
  if (num % steps == 0) {
    index--
  }
  return index
}

export const paddingTopBottom = 48

export const sizesRow = 109
export const rowGap = 20
const steps = 6
export const columnWidth = steps * 20
export const manageQtyRow = 84 + paddingTopBottom

export const sizesRow4k = 160
export const rowGap4k = 36
const steps4k = 7
export const columnWidth4k = steps4k * 25
export const manageQtyRow4k = 137 + paddingTopBottom
export const productCard = 209
export const productCard4k = 297

export const calculateVirtualRowHeightAfaCart = (viewportHeight: number, numberOfSizes = 1) => {
  const firstProductCardClosed = document.querySelector('.afa-cart-product-card') as HTMLElement
  const result = {
    open: 0,
    close: firstProductCardClosed?.offsetHeight || 0,
    rowsToVisualize: 0,
  }
  if (viewportHeight <= breakpointsNoUnit.L) {
    result.rowsToVisualize = getIndexInRange(numberOfSizes, steps)
    result.open =
      result.close +
      manageQtyRow +
      getIndexInRange(numberOfSizes, steps) * sizesRow +
      rowGap * (result.rowsToVisualize - 1)
    if (!result.close) result.close = productCard
  } else {
    result.rowsToVisualize = getIndexInRange(numberOfSizes, steps4k)
    result.open =
      result.close +
      manageQtyRow4k +
      getIndexInRange(numberOfSizes, steps4k) * sizesRow4k +
      rowGap4k * (result.rowsToVisualize - 1)
    if (!result.close) result.close = productCard4k
  }

  return result
}

/**
 * Returns a new HEX color code with the specified opacity.
 * @param {string} hexColor - The original HEX color code.
 * @param {number} opacity - The desired opacity value, from 0 to 100.
 * @returns {string} A new HEX color code with the specified opacity.
 */
export const getHexOpacity = (hexColor: string, opacity: number): string => {
  switch (true) {
    case opacity > 99:
      return `${hexColor}FF`
    case opacity < 1:
      return `${hexColor}00`
    default:
      return `${hexColor}${Math.round((opacity * 255) / 100).toString(16)}`
  }
}

/**
 * Converts a string to camel case.
 * @param {string} str - The input string to convert.
 * @returns {string} The input string in camel case.
 */
export const convertStringToCamelCase = (str: string): string => {
  const words = str.split(/\s+/)

  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      return word.toLowerCase()
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    }
  })

  return camelCaseWords.join('')
}

/**
 * Get all sports available in AfaPlpProductsQuery
 * @param {AfaProduct[]} products - The array of products from query
 * @param {string} category - If there is a category selected, then sports will be filtered for products in that category only
 * @returns {string[]} An array of unique sport values
 */
export const getSportsInProductsQuery = (
  products: AfaProduct[],
  category: string | null,
): string[] => {
  const setSports = products
    .filter(product => {
      if (category) {
        return product.category.toLowerCase() === category.toLowerCase()
      } else {
        return true
      }
    })
    .reduce((sum, product) => {
      const setProductSports = new Set<string>()

      Object.values(product.mocos).forEach(moco => {
        moco.sports.forEach(sport => {
          setProductSports.add(sport)
        })
      })

      return new Set([...sum, ...setProductSports])
    }, new Set<string>())

  return [...setSports]
}

/**
 * Filter products by sports
 * @param {AfaProduct[]} products - The array of products to filter
 * @param {Set<string>} sports - The values to be used to filter the products
 * @returns {AfaProduct[]} An updated array of products
 */
export const getFilteredProductsBySport = (
  products: AfaProduct[],
  sports: Set<string>,
): AfaProduct[] => {
  const filteredProducts = products.reduce((sum, product) => {
    const filteredMocos = Object.fromEntries(
      Object.entries(product.mocos).filter(([, obj]) =>
        obj.sports.some(sport => sports.has(sport)),
      ),
    )

    if (!Object.entries(filteredMocos).length) {
      return sum
    } else {
      const updatedProduct = { ...product, mocos: filteredMocos }
      return [...sum, updatedProduct]
    }
  }, [] as AfaProduct[])

  return filteredProducts
}

/**
 * Check if click is inside the 4 coordinates that define an element position and dimension in the page
 * @param {string} elementId - The element ID to check
 * @param {number} x - The horizontal position of the click in the page
 * @param {number} y - The vertical position of the click in the page
 * @returns {boolean} Click was inside the element boundaries or not
 */
export const clickIsInsideElementBoundaries = (
  elementId: string,
  x: number,
  y: number,
): boolean => {
  const element = document.getElementById(elementId)
  if (!element) {
    return false
  }
  const { top, left, bottom, right } = element.getBoundingClientRect()
  return x >= left && x <= right && y >= top && y <= bottom
}

/**
 * Function that gets all positions and styles of elements in a whiteboard (all boards) and return them as a string in order to check if there are differences with another whiteboard state
 * updatedElementSettings is necessary to avoid counting little position changements when rerendering the page
 * @param {WhiteboardType | undefined} selectedWhiteboard - The whiteboard from where to take the elements
 * @returns {string} An array of WhiteboardItemTypeSettings stringified
 */
export const getWhiteboardState = (selectedWhiteboard: WhiteboardType | undefined): string => {
  if (!selectedWhiteboard) return ''
  const whiteboardItemsPositionAndStyle = selectedWhiteboard.boards.reduce((sum, board) => {
    const boardItems = board.items
    const boardImages = board.images
    const boardTexts = board.texts
    const boardElements = [...boardItems, ...boardImages, ...boardTexts]

    boardElements.forEach(element => {
      const updatedElementSettings = {
        position: {
          x: parseFloat(element.settings.position.x.toString().slice(0, 13)),
          y: parseFloat(element.settings.position.y.toString().slice(0, 13)),
        },
        style: {
          ...element.settings.style,
          width: parseFloat(element.settings.style.width.toString().slice(0, 15)),
          height: parseFloat(element.settings.style.height.toString().slice(0, 15)),
        },
      }
      sum.push(updatedElementSettings)
    })
    return sum
  }, [] as WhiteboardItemTypeSettings[])
  return JSON.stringify(whiteboardItemsPositionAndStyle)
}

/**
 * Function that gets all positions and styles of elements in a whiteboard's first board and return them as a string in order to check if there are differences with another whiteboard's first board state
 * updatedElementSettings is necessary to avoid counting little position changements when rerendering the page
 * @param {WhiteboardType | undefined} selectedWhiteboard - The whiteboard from where to take the elements
 * @returns {string} An array of WhiteboardItemTypeSettings stringified
 */
export const getFirstBoardState = (selectedWhiteboard: WhiteboardType | undefined): string => {
  if (!selectedWhiteboard) return ''
  const firstBoardItems = selectedWhiteboard.boards[0].items
  const firstBoardImages = selectedWhiteboard.boards[0].images
  const firstBoardTexts = selectedWhiteboard.boards[0].texts
  const firstBoardElements = [...firstBoardItems, ...firstBoardImages, ...firstBoardTexts]

  firstBoardElements.reduce((sum, element) => {
    const updatedElementSettings = {
      position: {
        x: parseFloat(element.settings.position.x.toString().slice(0, 13)),
        y: parseFloat(element.settings.position.y.toString().slice(0, 13)),
      },
      style: {
        ...element.settings.style,
        width: parseFloat(element.settings.style.width.toString().slice(0, 15)),
        height: parseFloat(element.settings.style.height.toString().slice(0, 15)),
      },
    }
    sum.push(updatedElementSettings)
    return sum
  }, [] as WhiteboardItemTypeSettings[])

  return JSON.stringify(firstBoardElements)
}

/**
 * Convert a HEX color to RGB format.
 * @param {HexColor} color - The base color in hexadecimal format.
 * @returns {string} The RGB color in the format "rgb(R, G, B)".
 */
export const hexToRgb = (color: HexColor): string => {
  const hexColor = color.replace(/^#/, '')

  const r = parseInt(hexColor.slice(0, 2), 16)
  const g = parseInt(hexColor.slice(2, 4), 16)
  const b = parseInt(hexColor.slice(4, 6), 16)

  return `rgb(${r}, ${g}, ${b})`
}

/**
 * Convert a HEX color and opacity to an RGBA color.
 * @param {HexColor} color - The base color in hexadecimal format.
 * @param {number} opacity - The opacity value (between 0 and 100).
 * @returns {string} The RGBA color in the format "rgba(R, G, B, A)".
 */
export const hexToRgba = (color: HexColor, opacity: number): string => {
  const hexColor = color.replace(/^#/, '')

  const r = parseInt(hexColor.slice(0, 2), 16)
  const g = parseInt(hexColor.slice(2, 4), 16)
  const b = parseInt(hexColor.slice(4, 6), 16)
  const alpha = opacity / 100

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

type Gradient = string
type HexColor = string
type RgbaColor = string
/**
 * Generate a gradient by interpolating the opacity of a given color.
 * @param {HexColor} color - The base color in hexadecimal format.
 * @param {number} startingOpacity - The starting opacity value (between 0 and 100).
 * @param {number} endingOpacity - The ending opacity value (between 0 and 100).
 * @param {number} steps - The number of intervals in the gradient.
 * @returns {Gradient[]} An array of gradient colors with varying opacities.
 */
export const createGradients = (
  color: HexColor,
  startingOpacity: number,
  endingOpacity: number,
  steps: number = 1,
): Gradient[] => {
  const start = startingOpacity > 99 ? 100 : startingOpacity < 1 ? 0 : startingOpacity
  const end = endingOpacity > 99 ? 100 : endingOpacity < 1 ? 0 : endingOpacity

  const step = start > end ? -1 : 1
  const numSteps = Math.abs(end - start) + 1

  const baseColor = [hexToRgba(color, 0)]

  const gradient: RgbaColor[] = [...Array(numSteps)].reduce((sum, _, index) => {
    const opacity = start + step * index
    if (index % steps === 0) {
      sum.push(hexToRgba(color, opacity))
    }
    return sum
  }, [] as RgbaColor[])

  return baseColor.concat(gradient)
}
