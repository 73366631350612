import React, { useRef } from 'react'
import { AutoSizer, CellMeasurer, CellMeasurerCache, Grid } from 'react-virtualized'
import styled from 'styled-components'

import { getSingleProductHeight } from '../../../../libs/couvettes'
import { CartProduct, MocoInCart } from '../../../../model/cart'
import { Moco } from '../../../../model/product'
import { WishlistProduct } from '../../../../model/wishlist'
import { getFluidFontSize } from '../../../../style/theme'
import { CartContent, CartHeader, HeaderTitle, scrollbarCss } from '../../../CommonComponents'
import CouvetteItemV2 from '../../../Couvettes/Couvette/CouvetteItem'
import { useSelector } from 'react-redux'
import { recommendationIsLoadingSelector } from '../../../../store/cart/selectors'
import Loading from '../../../Loading'

export const CartHeaderControl = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 1.3rem;
`
CartHeaderControl.displayName = 'CartHeaderControl'

const Category = styled.div`
  .ReactVirtualized__Grid,
  .ReactVirtualized__List {
    ${scrollbarCss}
  }
  display: flex;
  flex-direction: column;
  flex: 1;
  box-shadow: 0 2px 24px 8px rgba(0, 0, 0, 0.04);

  &:last-of-type {
    margin-left: 2vw;
  }

  &.hide {
    display: none;
  }
`

const StyledHeaderTitle = styled(HeaderTitle)`
  font-size: ${getFluidFontSize('12px')};
`

const StyledCartContent = styled(CartContent)<{ maxImageHeight?: number }>``

StyledHeaderTitle.displayName = 'StyledHeaderTitle'
StyledCartContent.displayName = 'CartContent'

interface Props {
  cartProducts: CartProduct[]
  category?: string
  deleteIcon?: boolean
  dislikable?: boolean
  mocos: (MocoInCart | Moco)[]
  showWishlist?: boolean
  wishlistItems: WishlistProduct[]
  headerLeftActions: JSX.Element
  expanded: boolean
  isIpadView: boolean
  className?: string
}

export const MyShopGrid: React.FC<Props> = ({
  cartProducts,
  category,
  deleteIcon = false,
  dislikable,
  mocos,
  showWishlist = false,
  wishlistItems,
  headerLeftActions,
  expanded,
  isIpadView,
  className,
}) => {
  const columnCount = (expanded ? 2 : 1) * (isIpadView ? 1 : 2)
  const rowCount = columnCount === 1 ? mocos.length : Math.ceil(mocos.length / columnCount)
  const isLoading = useSelector(recommendationIsLoadingSelector)

  const ref = useRef<HTMLDivElement>(null)

  return (
    <Category className={['category', className].join(' ')}>
      <CartHeader>
        <StyledHeaderTitle>{category}</StyledHeaderTitle>
        <CartHeaderControl>{headerLeftActions}</CartHeaderControl>
      </CartHeader>
      <StyledCartContent maxImageHeight={getSingleProductHeight()} data-type={category} ref={ref}>
        {isLoading && <Loading isFullPage={false} />}
        <AutoSizer>
          {({ height, width }) => {
            const cache = new CellMeasurerCache({
              defaultWidth: width,
              minWidth: width,
              minHeight: getSingleProductHeight(),
              defaultHeight: getSingleProductHeight(),
              fixedHeight: false,
              fixedWidth: true,
            })
            return (
              <Grid
                columnCount={columnCount}
                columnWidth={width / columnCount}
                rowCount={rowCount}
                width={width}
                height={height}
                estimatedRowSize={getSingleProductHeight()}
                overscanRowCount={4}
                rowHeight={cache.rowHeight}
                cellRenderer={({ columnIndex, key, rowIndex, style, parent }) => {
                  const index = rowIndex * columnCount + columnIndex
                  const moco = mocos[index]
                  if (!moco) return null
                  const mocoCode = moco.mocoCode
                  const filteredCartProducts = cartProducts.filter(
                    cartProduct =>
                      cartProduct.mocoCode === mocoCode &&
                      Object.keys(moco.sizes).includes(cartProduct.upc),
                  )

                  const itemsInWishlist =
                    wishlistItems &&
                    wishlistItems.filter(wishlistItem => wishlistItem.mocoCode === mocoCode)
                  return (
                    <CellMeasurer
                      cache={cache}
                      columnIndex={columnIndex}
                      key={key}
                      parent={parent}
                      rowIndex={rowIndex}
                    >
                      <CouvetteItemV2
                        style={style}
                        key={key}
                        moco={moco}
                        cartItems={filteredCartProducts}
                        hideAdvIcon={false}
                        hideCarryOverIcon={false}
                        hideNewIcon={false}
                        showWishlist={showWishlist}
                        deleteIcon={Boolean(deleteIcon && filteredCartProducts.length)}
                        dislikable={dislikable}
                        itemsInWishlist={itemsInWishlist}
                        onCartPage={true}
                      />
                    </CellMeasurer>
                  )
                }}
              />
            )
          }}
        </AutoSizer>
      </StyledCartContent>
    </Category>
  )
}

export default MyShopGrid
