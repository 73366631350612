import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import {
  breakpoints,
  breakpointsCross,
  buttonBorderRadius,
  DigitalEventsBoxBorderRadius,
  getFluidFontSize,
  getFluidFontSizeDe,
  palette,
  pxToRem,
  spacing,
} from './theme'

export const maxHeight = '768px'

export const scrollMaxHeight = '725px'

export const XSHeight = '600px'

export const mainContentLateralPadding = css`
  padding-right: ${pxToRem(spacing(12))}rem;
  padding-left: ${pxToRem(spacing(12))}rem;

  @media (max-width: ${breakpoints.M}) {
    padding-right: ${pxToRem(spacing(8))}rem;
    padding-left: ${pxToRem(spacing(8))}rem;
  }

  @media (max-width: ${breakpoints.S}) {
    padding-right: ${pxToRem(spacing(6))}rem;
    padding-left: ${pxToRem(spacing(6))}rem;
  }
`

export const mainContentVerticalPadding = css`
  padding-top: ${pxToRem(spacing(2))}rem;
  padding-bottom: ${pxToRem(spacing(2))}rem;

  @media (max-width: ${breakpoints.M}) {
    padding-top: ${pxToRem(spacing(1))}rem;
    padding-bottom: ${pxToRem(spacing(1))}rem;
  }

  @media (max-width: ${breakpoints.S}) {
    padding-top: ${pxToRem(spacing(1))}rem;
    padding-bottom: ${pxToRem(spacing(1))}rem;
  }
`

export const mainContentPadding = css`
  ${mainContentVerticalPadding}
  ${mainContentLateralPadding}
`

export const glassCSSBackground = css`
  &::before {
    border-radius: ${DigitalEventsBoxBorderRadius};
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(30px);
    box-shadow: 20px 21px 30px 0 rgba(107, 89, 114, 0.05);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
`

export const glassCSS = css`
  & {
    border-radius: ${DigitalEventsBoxBorderRadius};
    border: solid 0.5px rgba(255, 255, 255, 0.32);
    position: relative;
    padding: ${pxToRem(spacing(2))}rem ${pxToRem(spacing(3))}rem;
    color: ${palette.white};
    overflow: hidden;

    ${glassCSSBackground}

    & > * {
      position: relative;
    }
  }
`

export const glassCssNoBackground = css`
  & {
    border-radius: ${DigitalEventsBoxBorderRadius};
    border: solid 0.5px rgba(255, 255, 255, 0.32);
    position: relative;
    padding: ${pxToRem(spacing(2))}rem ${pxToRem(spacing(3))}rem;
    color: ${palette.white};
    overflow: hidden;

    & > * {
      position: relative;
    }
  }
`

export const GlassBox = styled.div`
  ${glassCSS}
`

export const GlassBoxNoBackground = styled.div`
  ${glassCssNoBackground}
`

export const iconButtonStyle = css`
  min-width: 0;
  backdrop-filter: blur(25px);
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1) 33%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxToRem(spacing(7.5))}rem;
  height: ${pxToRem(spacing(7.5))}rem;
  border-radius: 3em;
  padding: 0;
  background-color: transparent;
  appearance: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s, fill 0.3s;
  color: ${palette.white};
  fill: ${palette.white};

  &:hover {
    color: ${palette.curiousBlue};
    fill: ${palette.curiousBlue};
  }

  svg {
    width: ${pxToRem(spacing(3))}rem;
    height: auto;

    @media (max-width: ${breakpoints.S}) {
      width: ${pxToRem(spacing(2))}rem;
    }
  }

  @media (max-width: ${breakpoints.S}) {
    width: ${pxToRem(spacing(5.5))}rem;
    height: ${pxToRem(spacing(5.5))}rem;
  }
`

export const IconButton = styled.button`
  ${iconButtonStyle}
`

export const DigitalEventsExternalPagesCloseButton = styled(IconButton)`
  position: absolute;
  z-index: 1;
  right: 1.5rem;
  top: 1.5rem;
`

export const selectEventAndCustomerHeaderButton = css`
  justify-content: flex-end;
  border-radius: ${buttonBorderRadius};
  box-shadow: 0 ${pxToRem(2)}rem ${pxToRem(4)}rem 0 rgba(0, 0, 0, 0.1);
  border: solid 1px ${palette.alto};
  background-color: white;
  font-size: ${getFluidFontSize('20px', 15)};
  @media (max-width: ${breakpointsCross.L.min}) {
    font-size: 1rem;
  }
  color: ${palette.congressBlue};
  text-transform: uppercase;
  padding: 0.5vh 0.5vw;
  white-space: nowrap;
  min-width: 0;
  place-self: center right;
  cursor: pointer;
`

export const PageWrapper = styled.div<{ background?: string }>`
  background-color: ${palette.defaultDeBg};
  background-image: url(${({ background }) => background});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`

export const MainFullBleed = styled.main`
  display: block;
  flex: 1;
`

export const Main = styled(MainFullBleed)`
  ${mainContentPadding}
`

export const Badge = styled.div`
  min-width: ${pxToRem(spacing(2))}rem;
  height: ${pxToRem(spacing(2))}rem;
  border-radius: ${pxToRem(spacing(1))}rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${palette.white};
  font-size: 0.5rem;
  background-color: ${palette.red};
  position: absolute;
  top: -${pxToRem(spacing(1))}rem;
  right: -${pxToRem(spacing(1))}rem;
`

export const Spin = styled.div`
  @keyframes rotation {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  position: relative;
  width: 1em;
  height: 1em;

  > * {
    position: relative;
    z-index: 2;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    width: 1em;
    height: 1em;
    top: calc(50% - 0.5em);
    left: calc(50% - 0.5em);
    border: solid 0.2em transparent;
    border-top-color: currentColor;
    border-right-color: currentColor;
    box-sizing: border-box;
    border-radius: 50%;
  }

  &:before {
    animation: rotation 3s infinite;
  }

  &:after {
    animation: rotation 3s ease-in-out infinite;
  }
`

export const ctaStyle = css`
  height: 1.5em;
  box-sizing: content-box;
  padding: ${pxToRem(spacing(1))}rem ${pxToRem(spacing(5))}rem;
  border-radius: 3px;
  box-shadow: 20px 21px 30px 0 rgba(107, 89, 114, 0.24);
  font-weight: 600;
  font-size: 1rem;
  color: ${palette.bigStone};
  text-transform: uppercase;
  background-color: ${palette.white};

  img {
    height: 1.5em;
    width: 1.5em;
    margin-right: 0.5em;
  }
`

export const CtaButton = styled.button`
  ${ctaStyle}
`

export const CtaLink = styled(Link)`
  ${ctaStyle}
`

export const scrollbarCssDe = css`
  overflow-x: hidden !important;

  & {
    color: rgba(0, 0, 0, 0);
    transition: color 0.3s ease;
    &::-webkit-scrollbar-track {
      border: 1px solid rgba(0, 0, 0, 0);
    }
    &:hover {
      &::-webkit-scrollbar-track {
        border-color: ${palette.white};
      }
      color: ${palette.white};
    }
    & > * {
      color: white;
    }
  }
  &::-webkit-scrollbar {
    display: block !important;
    background-clip: padding-box;
    width: 6px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track-piece {
  }
  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border-radius: 4px;
    box-shadow: inset 0 0 0 10px;
  }
  &::-webkit-scrollbar-corner {
    border-radius: 4px;
  }
`

export const DeLink = styled(Link)`
  color: ${palette.white};
  text-decoration: underline;
  font-size: 1rem;
  text-underline-offset: 0.25em;
  text-transform: uppercase;

  &:hover {
    color: ${palette.white};
  }
`

export const BoxTitle = styled.h2`
  text-transform: uppercase;
  color: ${palette.white};
  margin: 0;
  font-weight: normal;
  font-size: ${getFluidFontSizeDe(16, 22)};
`
export const ScrollContainer = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-height: ${scrollMaxHeight}) {
    ${scrollbarCssDe};
    overflow-y: auto;
    overflow-x: hidden;
  }
`

export const OnDisplayTitle = styled.span`
  font-size: ${getFluidFontSize('16px')};
  flex: 0;
  color: ${palette.white};
  padding-bottom: ${pxToRem(spacing(2))}rem;
  text-transform: uppercase;
`
