import classnames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { openPDP } from '../../../store/actions'
import { brandEnabledSelector } from '../../../store/brands/selectors'
import { getFluidFontSize, palette } from '../../../style/theme'
import BrandLogo from '../../Couvettes/Couvette/BrandLogo'
import ProductImage from '../../Product/ProductImage/ProductImage'
import { errorNotification } from '../../Notification/notifications'
import { getProductImageUrl } from '../../../libs/productImages'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${palette.white};
  box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0.5vw;
  flex: 1;
  margin: 1vmin;
  font-size: ${getFluidFontSize('10px', 20)};
`

const Header = styled.div`
  border-top-left-radius: 0.5vw;
  border-top-right-radius: 0.5vw;
  border-bottom: 1px solid ${palette.gallery};
  background-color: ${palette.alabaster};
  display: flex;
  width: 100%;
`

const CampaignLabel = styled.div`
  text-transform: uppercase;
  padding: 10px 5px;
  text-align: center;
  border-top-left-radius: 0.5vw;

  &.displayed {
    width: 30%;
  }
  &.new {
    background: linear-gradient(0deg, ${palette.chalky} 0%, ${palette.diSerria} 100%);
  }
  &.carry-over {
    background: linear-gradient(0deg, ${palette.riptide} 0%, ${palette.turquoise} 100%);
  }
  &.digital-preview {
    background: linear-gradient(0deg, ${palette.curiousBlue} 0%, ${palette.biscay} 100%);
    color: white;
  }
`
CampaignLabel.displayName = 'CampaignLabel'

const MaterialCode = styled.div`
  margin-left: 0.5vw;
  display: flex;
  align-items: center;
`

const StyledBrandLogo = styled(BrandLogo)`
  position: absolute;
  background-color: ${palette.white};
  width: 4.5vw;
  min-width: 52px;
  height: 3vw;
  min-height: 35px;
  right: 1vw;
  top: 0;
  padding: 0.2vw;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
  border: 0.7px solid ${palette.gallery};
  border-radius: 8px;

  .brand-logo {
    display: flex;
    height: 100%;
  }
`

const Image = styled(ProductImage)`
  overflow: hidden;
  flex: 1;
`
Image.displayName = 'ProductImageGrid'

type Props = {
  mocoCode: string
  modelCode: string
  modelTagLabel: string
  brandCode: string
  catalogImgPath: string
}

const GridItem: React.FC<Props> = ({
  modelCode,
  mocoCode,
  brandCode,
  modelTagLabel,
  catalogImgPath,
}) => {
  const { t } = useTranslation()

  const brandDisabled = !useSelector(brandEnabledSelector(brandCode))

  const dispatch = useDispatch()

  const showLockedError = () => {
    errorNotification({
      message: t('DigitalPreviewBrandsPage.locked'),
      className: 'rc-notification',
    })
    return false
  }
  return (
    <Wrapper>
      <Header>
        <CampaignLabel
          className={classnames({
            campaignLabel: true,
            new: modelTagLabel === 'New',
            'digital-preview': modelTagLabel === 'Digital Preview',
            'carry-over': modelTagLabel !== 'New' && modelTagLabel !== 'Digital Preview', // Carry Over and old releases will have the same style
            displayed: modelTagLabel,
          })}
        >
          {modelTagLabel === 'New' && t('Plp.newLabel')}
          {modelTagLabel === 'Digital Preview' && t('Plp.digitalPreviewLabel')}
          {modelTagLabel === 'Carry Over' && t('Plp.carryOverLabel')}
          {modelTagLabel !== 'New' &&
            modelTagLabel !== 'Digital Preview' &&
            modelTagLabel !== 'Carry Over' &&
            modelTagLabel}
        </CampaignLabel>
        <MaterialCode>{modelCode}</MaterialCode>
        <StyledBrandLogo brandCode={brandCode} />
      </Header>

      <Image
        onClick={() => {
          brandDisabled ? showLockedError() : dispatch(openPDP(mocoCode, modelCode))
        }}
        clickable={false}
        imgPath={getProductImageUrl({ path: catalogImgPath })}
      />
    </Wrapper>
  )
}

export default GridItem
