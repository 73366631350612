import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import app_config from '../config/app/config'
import { VirtualMirrorTransitionLenses } from '../model/virtualMirror'

export const virtualMirrorApi = createApi({
  reducerPath: 'virtualMirrorApi',
  baseQuery: staggeredBaseQueryWithBailOut(`${app_config.apiUrl}/virtual-mirror`),
  endpoints: builder => ({
    getTransitionLenses: builder.query<VirtualMirrorTransitionLenses, void>({
      query: () => '/transition-lenses',
    }),
  }),
})

export const useGetVirtualMirrorTransitionLenses = () => {
  return virtualMirrorApi.useGetTransitionLensesQuery()
}
