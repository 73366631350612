import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { Moco, Product } from '../../../model/product'
import { productDetailsPadding } from '../../../style/CommonComponents'
import { getFluidFontSize, palette } from '../../../style/theme'

const Wrapper = styled.div`
  ${productDetailsPadding};
  text-transform: lowercase;
  display: flex;
  margin-bottom: 3em;
  background: ${palette.wildSand};
  font-size: ${getFluidFontSize('10px')};
`

const columnsCss = css`
  p {
    line-height: 1.43;
    padding: 0 0 0.5em;
    margin: 0;
    &:first-letter {
      text-transform: uppercase;
    }

    span {
      font-weight: bold;
      display: inline-block;
      text-transform: lowercase;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
`
const FirstColumn = styled.div`
  ${columnsCss}
  width: 40%;
`

const SecondColumn = styled.div`
  ${columnsCss}
  flex: 1;
  border-left: 1px solid ${palette.silver};
  padding-left: min(2vw, 40px);
`

type Props = {
  model: Product
  moco: Moco
}

const ProductDetail: React.FC<Props> = ({ model, moco }) => {
  const { t } = useTranslation()

  const {
    category,
    frameShape,
    frontMaterial,
    gender,
    geofit,
    lensBase,
    marketingTheme,
    templeMaterial,
    type,
    rxAble,
  } = model

  const {
    bridgeDesign,
    flex,
    progressiveFriendly,
    frontColor,
    weight,
    templeColor,
    lensColor,
    lensColorType,
    lensMaterial,
    lensProperties,
    contrastEnhancement,
    lensBaseColor,
    lightTransmission,
    lensProtection,
  } = moco

  return (
    <Wrapper className="product-item-detail">
      <FirstColumn className="first-column">
        {category && (
          <p>
            {t('ProductPage.item_category')}{' '}
            <span>{category.id === '1' ? t('GenericWords.optical') : t('GenericWords.sun')}</span>
          </p>
        )}
        {gender && (
          <p>
            {t('ProductPage.item_gender')} <span>{gender.label}</span>
          </p>
        )}
        {marketingTheme && (
          <p>
            {t('ProductPage.item_theme')} <span>{marketingTheme.label}</span>
          </p>
        )}
        {frameShape && (
          <p>
            {t('ProductPage.item_shape')} <span>{frameShape.label}</span>
          </p>
        )}
        {frontMaterial && (
          <p>
            {t('ProductPage.item_frontmaterial')} <span>{frontMaterial.label}</span>
          </p>
        )}
        {templeMaterial && (
          <p>
            {t('ProductPage.item_templematerial')} <span>{templeMaterial.label}</span>
          </p>
        )}
        {type && (
          <p>
            {t('ProductPage.item_type')} <span>{type.label}</span>
          </p>
        )}
        {bridgeDesign && (
          <p>
            {t('ProductPage.item_bridgedesign')} <span>{bridgeDesign}</span>
          </p>
        )}
        {geofit && (
          <p>
            {t('ProductPage.item_geofit')} <span>{geofit.label}</span>
          </p>
        )}
        {lensBase && (
          <p>
            {t('ProductPage.item_base')} <span>{lensBase.label}</span>
          </p>
        )}
        {flex && (
          <p>
            {t('ProductPage.item_flex')} <span>{flex}</span>
          </p>
        )}
        {rxAble && (
          <p>
            {t('ProductPage.item_rxable')} <span>{rxAble.label}</span>
          </p>
        )}
        {progressiveFriendly && (
          <p>
            {t('ProductPage.item_progressivefriendly')} <span>{progressiveFriendly.label}</span>
          </p>
        )}
        {weight > 0 && (
          <p>
            {t('ProductPage.item_weight')} <span>{weight}</span>
          </p>
        )}
      </FirstColumn>
      <SecondColumn className="second-column">
        {frontColor && (
          <p>
            {t('ProductPage.item_frontcolor')} <span>{frontColor.label}</span>
          </p>
        )}
        {templeColor && (
          <p>
            {t('ProductPage.item_templecolor')} <span>{templeColor.label}</span>
          </p>
        )}
        {lensColor && (
          <p>
            {t('ProductPage.item_lenscolor')} <span>{lensColor.label}</span>
          </p>
        )}
        {lensColorType && (
          <p>
            {t('ProductPage.item_lenscolortype')} <span>{lensColorType.label}</span>
          </p>
        )}
        {lensMaterial && (
          <p>
            {t('ProductPage.item_lensmaterial')} <span>{lensMaterial.label}</span>
          </p>
        )}
        {lensProperties && (
          <p>
            {t('ProductPage.item_lensproperty')} <span>{lensProperties.label}</span>
          </p>
        )}
        {contrastEnhancement && (
          <p>
            {t('ProductPage.item_contrastenhancement')} <span>{contrastEnhancement.label}</span>
          </p>
        )}
        {lensBaseColor && (
          <p>
            {t('ProductPage.item_lensbasecolor')} <span>{lensBaseColor.label}</span>
          </p>
        )}
        {lightTransmission && (
          <p>
            {t('ProductPage.item_lighttransmission')} <span>{lightTransmission.label}</span>
          </p>
        )}
        {lensProtection && (
          <p>
            {t('ProductPage.item_lensprotection')} <span>{lensProtection.label}</span>
          </p>
        )}
      </SecondColumn>
    </Wrapper>
  )
}

export default ProductDetail
