import { useMemo } from 'react'
import { isNotUndefined } from '../libs/utils'
import { AfaCartProduct } from '../model/afa'
import { useGetFilteredAfaCartQuery } from '../services/afaCart'
import { useSearchParams } from './useSearchParams'
import { useGetBrandsQuery } from '../services/afa'

export const useGetAfaCartProducts = () => {
  const cartQuery = useGetFilteredAfaCartQuery()
  const brands = useGetBrandsQuery()
  const [searchParams] = useSearchParams()

  const cartProducts = useMemo(() => {
    return cartQuery.data?.items || []
  }, [cartQuery.data])

  const selectedBrandCode = searchParams.get('brand') || cartProducts[0]?.brandCodeParent

  const cartProductsByBrandCode = useMemo(() => {
    return cartProducts.reduce((result, product) => {
      if (!result[product.brandCodeParent]) {
        result[product.brandCodeParent] = []
      }
      result[product.brandCodeParent].push(product)
      return result
    }, {} as Record<string, AfaCartProduct[]>)
  }, [cartProducts])

  const brandsInCart = useMemo(() => {
    return Object.keys(cartProductsByBrandCode)
      .map(brandCodeParent => brands.data?.find(brand => brand.code === brandCodeParent))
      .filter(isNotUndefined)
  }, [cartProductsByBrandCode, brands.data])

  const cartItemsLength = useMemo(() => {
    return cartProducts.reduce((result, item) => {
      result += item.unconfirmedQuantity
      return result
    }, 0)
  }, [cartProducts])

  const cartItemsBySelectedBrandCodeLength = useMemo(() => {
    return cartProducts.reduce((result, item) => {
      if (item.brandCodeParent === selectedBrandCode) {
        result += item.unconfirmedQuantity
      }
      return result
    }, 0)
  }, [cartProducts, selectedBrandCode])

  const doorIdsInCart = useMemo(() => {
    return cartProducts.reduce((result, cartProduct) => {
      if (!result.includes(cartProduct.doorId)) {
        result.push(cartProduct.doorId)
      }
      return result
    }, [] as string[])
  }, [cartProducts])

  return {
    cartProducts,
    cartProductsByBrandCode,
    cartItemsLength,
    cartItemsBySelectedBrandCodeLength,
    brandsInCart,
    doorIdsInCart,
    isLoading: cartQuery.isLoading,
    isFetching: cartQuery.isFetching,
  }
}
