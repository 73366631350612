import { breakpointsNoUnit } from '../style/theme'
import { useEffect, useState } from 'react'

export const useGet4KDevice = () => {
  const [is4KDevice, setIs4KDevice] = useState(screen.width >= breakpointsNoUnit.XL)
  const handleViewportSize = () => setIs4KDevice(screen.width >= breakpointsNoUnit.XL)

  useEffect(() => {
    addEventListener('resize', handleViewportSize)

    return () => {
      removeEventListener('resize', handleViewportSize)
    }
  }, [])
  return is4KDevice
}
