import React from 'react'
import styled from 'styled-components'
import SwiperCore, { Navigation, Pagination } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'

import { CollectionHighlightProduct, VideoHighlight } from '../../model/brand'
import { Moco, Product } from '../../model/product'
import { XSHeight } from '../../style/DigitalEventsCommonComponents'
import { pxToRem, spacing } from '../../style/theme'
import MocoCard from './MocoCard'

SwiperCore.use([Pagination, Navigation])

const SeenProductWrapper = styled.div`
  max-height: 30%;
  width: 100%;
  padding-left: ${pxToRem(spacing(4))}rem;

  .swiper-wrapper,
  .swiper-container {
    overflow: visible !important;
  }

  @media (max-height: ${XSHeight}) {
    padding-left: 0;
  }

  @media (max-height: ${XSHeight}) {
    min-height: 100px;
    .swiper-wrapper,
    .swiper-container {
      height: 100%;
    }
  }
`

const ProductWrapper = styled.div<{ cleared: boolean }>`
  position: relative;
  ${props =>
    props.cleared
      ? `
 cursor: pointer;
`
      : 'opacity: 30%;'}
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const VisibleProducts = styled.div`
  width: 100%;
  height: 100%;
`

type Props = {
  setSwiperRef: React.Dispatch<React.SetStateAction<SwiperCore | undefined>>
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
  loadedProducts: Product[]
  videoData: VideoHighlight
  maximumTimeReached: React.MutableRefObject<number>
  currentInDisplayIndex: React.MutableRefObject<number>
  setVideoTime: (milliseconds: number) => void
  getModelByModelCode: (modelCode: any) => Product | undefined
  getMocoByModelCode: (modelCode?: string | undefined) => Moco | undefined
}

const SeenProductsSlider: React.FC<Props> = ({
  setSwiperRef,
  setCurrentIndex,
  loadedProducts,
  videoData,
  maximumTimeReached,
  currentInDisplayIndex,
  setVideoTime,
  getModelByModelCode,
  getMocoByModelCode,
}) => {
  return (
    <SeenProductWrapper>
      <VisibleProducts>
        <Swiper
          onSwiper={swiper => {
            setSwiperRef(swiper)
          }}
          onSlideChange={swiper => {
            setCurrentIndex(swiper.activeIndex)
          }}
          breakpoints={{
            0: {
              slidesPerView: 2.5,
            },
            1600: {
              slidesPerView: 3.2,
            },
          }}
          grabCursor={true}
          slidesPerGroup={1}
          loop={false}
          className="swiper"
        >
          {!!loadedProducts.length &&
            ([] as { milliseconds: number; product: CollectionHighlightProduct }[])
              .concat(
                videoData.initialItem ? { milliseconds: 0, product: videoData.initialItem } : [],
              )
              .concat(videoData.timeline)
              .map((visibleProduct, index) => (
                <SwiperSlide key={index}>
                  <ProductWrapper
                    cleared={maximumTimeReached.current >= visibleProduct.milliseconds}
                    onClick={() => {
                      currentInDisplayIndex.current = index
                      maximumTimeReached.current >= visibleProduct.milliseconds
                      setVideoTime(visibleProduct.milliseconds)
                    }}
                  >
                    <MocoCard
                      styleName={
                        getModelByModelCode(visibleProduct.product.product?.replaceAll('_', ' '))
                          ?.styleName?.label
                      }
                      time={visibleProduct.milliseconds}
                      moco={getMocoByModelCode(
                        visibleProduct.product.product?.replaceAll('_', ' '),
                      )}
                      modelCode={visibleProduct.product.product}
                    />
                  </ProductWrapper>
                </SwiperSlide>
              ))}
          <SwiperSlide></SwiperSlide>
        </Swiper>
      </VisibleProducts>
    </SeenProductWrapper>
  )
}

export default SeenProductsSlider
