import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'

import AfaFooter from '../../../components/AfaFooter'
import Header from '../../../components/Header'
import useFetchInitialData from '../../../hooks/useFetchInitialData'
import { getDeviceAbsolutePath } from '../../../libs/url'
import { UpdateAfaCartProductPayload } from '../../../model/afa'
import {
  useConfirmAfaCartMutation,
  useGetAfaFilteredCheckoutProducts,
  useUpdateAfaCartProductsMutation,
} from '../../../services/afaCart'
import { selectedAddressesSelector } from '../../../store/afa/selectors'
import { NextButton } from '../components/AfaCheckoutCommonComponents'
import AfaStepper from '../components/AfaStepper'
import AfaSubHeader from '../components/AfaSubHeader'
import { palette } from '../../../style/theme'
import Content from './Content'
import Loading from '../../../components/Loading'
import AfaCartFilterButton from '../../AfaCart/AfaCartFilterButton'
import AfaCartFilters from '../../AfaCart/AfaCartFilters'

const HeaderAndContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${palette.lightGray};
  overflow: hidden;
`

const SubHeaderAndContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  position: relative;
`

const StepperAndContentContainer = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const AfaCheckoutAddressesPage: React.FC = () => {
  useFetchInitialData({ loadCustomer: true })

  const [updateAfaCartProducts, updateAfaCartProductsResult] = useUpdateAfaCartProductsMutation()
  const [isConfirming, setIsConfirming] = useState(false)
  const [confirmCart, confirmCartResult] = useConfirmAfaCartMutation()

  const [filteredCheckoutProducts] = useGetAfaFilteredCheckoutProducts()

  const selectedAddresses = useSelector(selectedAddressesSelector)

  const doorsInCart = useMemo(() => filteredCheckoutProducts?.map(({ doorId }) => doorId), [
    filteredCheckoutProducts,
  ])

  const addressSelectedForDoor = (door: string) =>
    selectedAddresses.find(({ doorId }) => doorId === door)
  const addressSelectedForAllDoorsInCart = doorsInCart?.every(door => addressSelectedForDoor(door))

  const { assortment } = useParams<{
    assortment: string
  }>()

  const history = useHistory()

  useEffect(() => {
    if (confirmCartResult.status === 'fulfilled') {
      history.push(`${getDeviceAbsolutePath()}/${assortment}/checkout/thank-you`)
    } else if (confirmCartResult.status === 'rejected') {
      // eslint-disable-next-line no-alert
      alert(`There was an error submitting the cart: ${confirmCartResult.error.toString()}`)
    }
  }, [confirmCartResult, assortment, history])

  const { t } = useTranslation()

  const submitInProgress = updateAfaCartProductsResult.isLoading || confirmCartResult.isLoading

  useEffect(() => {
    if (updateAfaCartProductsResult.isSuccess && isConfirming) {
      confirmCart()
      setIsConfirming(false)
    }
  }, [updateAfaCartProductsResult.isSuccess, confirmCart, isConfirming])

  return (
    <>
      <Wrapper>
        <HeaderAndContentWrapper>
          <Header hideButtons />
          <SubHeaderAndContent>
            <AfaSubHeader hideImportSwitch />
            <AfaCartFilters />
            <StepperAndContentContainer>
              <AfaStepper activeStep={2} />
              <Content />
              {submitInProgress && <Loading />}
            </StepperAndContentContainer>
          </SubHeaderAndContent>
        </HeaderAndContentWrapper>
      </Wrapper>
      <AfaFooter
        hideSearch
        leftColContent={<AfaCartFilterButton />}
        centralColContent={
          <NextButton
            disabled={!addressSelectedForAllDoorsInCart}
            onClick={() => {
              if (!filteredCheckoutProducts) {
                throw new Error('missing filteredCheckoutProducts')
              }
              setIsConfirming(true)
              updateAfaCartProductsResult.reset()

              updateAfaCartProducts(
                filteredCheckoutProducts.map(product => {
                  const selectedAddressId = selectedAddresses.find(
                    ({ doorId }) => doorId === product.doorId,
                  )?.selectedAddressId

                  const {
                    upc,
                    unconfirmedQuantity,
                    doorId,
                    status,
                    reference,
                    key,
                    modelCode,
                    colorCode,
                    brandCode,
                    brandCodeParent,
                    doorAddressId,
                    dropType,
                    availabilityDate,
                    minDeliveryDate,
                    deliveryDate,
                  } = product

                  const payload: UpdateAfaCartProductPayload = {
                    upc,
                    quantity: unconfirmedQuantity,
                    doorId,
                    status,
                    reference,
                    key,
                    modelCode,
                    colorCode,
                    brandCode,
                    brandCodeParent,
                    doorAddressId: selectedAddressId || doorAddressId,
                    dropType,
                    availabilityDate,
                    minDeliveryDate,
                    deliveryDate,
                  }

                  return payload
                }),
              )
            }}
          >
            {t('Checkout.Footer.Submit')}
          </NextButton>
        }
      />
    </>
  )
}

export default AfaCheckoutAddressesPage
