import React, { SVGProps } from 'react'

const IconStatistics: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    {...props}
  >
    <path d="M 20.375,16.5 C 19.833976,16.43394 20.035612,15.776654 20,15.404993 20,11.894995 20,8.3849976 20,4.875 20.0862,4.3104677 20.737858,4.5501728 21.125,4.5 c 0.590732,0.1060781 0.310555,0.803738 0.375,1.2150085 0,3.4699972 0,6.9399945 0,10.4099915 -0.07691,0.575905 -0.745504,0.316512 -1.125,0.375 z m -4.5,0 c -0.541024,-0.06606 -0.339388,-0.723346 -0.375,-1.095007 0,-2.009998 0,-4.019995 0,-6.029993 0.0862,-0.5645323 0.737858,-0.3248272 1.125,-0.375 0.590732,0.1060781 0.310555,0.803738 0.375,1.215009 0,1.969997 0,3.939994 0,5.909991 -0.07691,0.575905 -0.745504,0.316512 -1.125,0.375 z m -9,0 C 6.3339764,16.433938 6.535612,15.776654 6.5,15.404993 6.519424,14.351031 6.4609285,13.288619 6.529625,12.24 6.7446323,11.834274 7.2627478,12.057226 7.625,12 8.2157323,12.106078 7.9355554,12.803738 8,13.215009 7.9806058,14.228973 8.0390322,15.251377 7.970375,16.26 7.7696152,16.667235 7.2262793,16.441206 6.875,16.5 Z m 4.5,0 C 10.833976,16.43394 11.035612,15.776654 11,15.404993 11,12.394995 11,9.3849976 11,6.375 11.0862,5.8104677 11.737858,6.0501728 12.125,6 c 0.590732,0.1060781 0.310555,0.803738 0.375,1.2150085 0,2.9699975 0,5.9399945 0,8.9099915 -0.07691,0.575905 -0.745504,0.316512 -1.125,0.375 z m 12.75,3 c 0.564532,0.0862 0.324827,0.737858 0.375,1.125 C 24.393922,21.215732 23.696262,20.935555 23.284991,21 15.892412,20.9941 8.4986502,21.01171 1.1068083,20.9912 0.48010791,20.907065 0.45688712,20.201358 0.5,19.709995 0.5,14.264996 0.5,8.8199982 0.5,3.375 0.58620317,2.8104677 1.2378582,3.0501728 1.625,3 2.2157323,3.1060781 1.9355554,3.803738 2,4.2150085 2,9.3100057 2,14.405003 2,19.5 c 7.375,0 14.75,0 22.125,0 z" />
  </svg>
)

export default IconStatistics
