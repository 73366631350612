import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useSearchParams } from '../../hooks/useSearchParams'
import { AfaFacet } from '../../model/afa'
import AfaFilterChip from './AfaFilterChip'
import { getAfaFilterTranslation } from './AfaFiltersLayer'
import { breakpoints, palette, pxToRem } from '../../style/theme'
import { newCollection, useAfaFilters } from '../../hooks/useAfaFilters'
import { useSelector } from 'react-redux'
import { newReleasesSelector } from '../../store/app/selectors'

const Wrapper = styled.div`
  border-top: solid 1px ${palette.cornflowerBlue};
  display: flex;
  gap: 1rem;
  border-bottom: solid 1px ${palette.cornflowerBlue};
  padding: ${pxToRem(32)}rem 0;

  @media (min-width: ${breakpoints.L}) {
    padding-bottom: 0;
    border-bottom: 0;
  }
`

type Props = {
  facets: AfaFacet[]
}

const AfaFiltersChips: React.FC<Props> = ({ facets }) => {
  const { t } = useTranslation()

  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedFilters] = useAfaFilters(facets)
  const newReleases = useSelector(newReleasesSelector)

  const hasKeyLookOn = searchParams.get('hasKeylooks') === 'true'
  const advOn = searchParams.get('adv') === 'X'
  const seasonParams = searchParams.get('season')
  const seasonsSelected = seasonParams?.split(',') || []
  const allNewReleasesAreSelected =
    newReleases.length > 0 && newReleases.every(release => seasonsSelected.includes(release))

  const facetNameMap = {
    _keylook_facet_: {
      active: hasKeyLookOn,
      paramName: 'hasKeylooks',
    },
    adv: {
      active: advOn,
      paramName: 'adv',
    },
    [t('CollectionsPage.newCollection')]: {
      active: allNewReleasesAreSelected,
      paramName: newCollection,
    },
  } as Record<string, { active: boolean; paramName: string }>

  const updateNewReleases = (addReleases: boolean) => {
    if (selectedFilters) {
      const updatedSeasonFilters = { ...selectedFilters['season'] }

      newReleases.forEach(release => {
        updatedSeasonFilters[release] = addReleases
      })

      const categoryValues = Object.entries(updatedSeasonFilters)
        .filter(([_, active]) => active)
        .map(([optionName]) => optionName)

      if (categoryValues.length > 0) {
        searchParams.set('season', categoryValues.join(','))
      } else {
        searchParams.delete('season')
      }
    }
  }

  return (
    <Wrapper>
      {facets
        .filter(facet => facet.name !== 'season')
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(facet => {
          const active = facetNameMap[facet.name]?.active

          return (
            <AfaFilterChip
              disabled={facet.options.length === 0 && !active}
              key={facet.name}
              name={facet.name}
              active={active}
              onChange={() => {
                const paramName = facetNameMap[facet.name]?.paramName
                if (!paramName) {
                  return
                }

                searchParams.delete('start')
                if (active) {
                  searchParams.delete(paramName)
                  paramName === newCollection && updateNewReleases(false)
                } else {
                  switch (paramName) {
                    case 'adv':
                      searchParams.set(paramName, 'X')
                      break
                    case newCollection:
                      searchParams.set(paramName, 'true')
                      updateNewReleases(true)
                      break
                    default:
                      searchParams.set(paramName, 'true')
                      break
                  }
                }

                setSearchParams(searchParams, { replace: true })
              }}
            >
              {getAfaFilterTranslation(t, `Afa.fC.${facet.name}`, facet.name)}
            </AfaFilterChip>
          )
        })}
    </Wrapper>
  )
}

export default AfaFiltersChips
