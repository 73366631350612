import { useCallback, useEffect, useMemo } from 'react'

import app_config from '../config/app/config'

// Code copied and adapted from
// https://github.com/kybarg/react-barcode-reader/blob/master/src/index.js

let firstCharTime = 0
let lastCharTime = 0
let stringWriting = ''
let callIsScanner = false
let testTimer: number | undefined
let scanButtonCounter = 0

const initScannerDetection = () => {
  firstCharTime = 0
  stringWriting = ''
  scanButtonCounter = 0
}

const scannerDetectionTest = ({
  minLength,
  avgTimeByChar,
  onScanButtonLongPressed,
  scanButtonLongPressThreshold,
  onScan,
  onError,
}: Options) => (s?: string) => {
  // If string is given, test it
  if (s) {
    firstCharTime = 0
    lastCharTime = 0
    stringWriting = s
  }

  if (!scanButtonCounter) {
    scanButtonCounter = 1
  }

  // If all condition are good (length, time...), call the callback and re-initialize the plugin for next scanning
  // Else, just re-initialize
  if (
    stringWriting.length >= minLength &&
    lastCharTime - firstCharTime < stringWriting.length * avgTimeByChar
  ) {
    if (onScanButtonLongPressed && scanButtonCounter > scanButtonLongPressThreshold) {
      onScanButtonLongPressed(stringWriting, scanButtonCounter)
    } else if (onScan) {
      onScan(stringWriting, scanButtonCounter)
    }

    initScannerDetection()
    return true
  }

  let errorMsg = ''
  if (stringWriting.length < minLength) {
    errorMsg = `String length should be greater or equal ${minLength}`
  } else {
    if (lastCharTime - firstCharTime > stringWriting.length * avgTimeByChar) {
      errorMsg = `Average key character time should be less or equal ${avgTimeByChar}ms`
    }
  }

  if (onError) {
    onError(stringWriting, errorMsg)
  }

  initScannerDetection()

  return false
}

const isContentEditable = (element: HTMLElement) => {
  if (typeof element.getAttribute !== 'function') {
    return false
  }

  return !!element.getAttribute('contenteditable')
}

const isInput = (element?: HTMLElement) => {
  if (!element) {
    return false
  }

  const { tagName } = element
  const editable = isContentEditable(element)

  return tagName === 'INPUT' || tagName === 'TEXTAREA' || editable
}

const handleKeyPress = (options: Options) => (e: KeyboardEvent) => {
  const {
    scanButtonKeyCode,
    onKeyDetect,
    stopPropagation,
    preventDefault,
    endChar,
    startChar,
    timeBeforeScanTest,
    onReceive,
  } = options

  const { target } = e
  if (target instanceof window.HTMLElement && isInput(target)) {
    return
  }

  // If it's just the button of the scanner, ignore it and wait for the real input
  if (scanButtonKeyCode && e.which === scanButtonKeyCode) {
    scanButtonCounter += 1
    // Cancel default
    e.preventDefault()
    e.stopImmediatePropagation()
  }
  // Fire keyDetect event in any case!
  if (onKeyDetect) {
    onKeyDetect(e)
  }

  if (stopPropagation) {
    e.stopImmediatePropagation()
  }
  if (preventDefault) {
    e.preventDefault()
  }

  if (firstCharTime && endChar.indexOf(e.which) !== -1) {
    e.preventDefault()
    e.stopImmediatePropagation()
    callIsScanner = true
  } else if (!firstCharTime && startChar.indexOf(e.which) !== -1) {
    e.preventDefault()
    e.stopImmediatePropagation()
    callIsScanner = false
  } else {
    if (typeof e.which !== 'undefined') {
      stringWriting += String.fromCharCode(e.which)
    }
    callIsScanner = false
  }

  if (!firstCharTime) {
    firstCharTime = Date.now()
  }
  lastCharTime = Date.now()

  if (testTimer) {
    clearTimeout(testTimer)
  }

  if (callIsScanner) {
    scannerDetectionTest(options)()
    testTimer = undefined
  } else {
    testTimer = window.setTimeout(scannerDetectionTest(options), timeBeforeScanTest)
  }

  if (onReceive) {
    onReceive(e)
  }
}

const inIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

type Options = {
  testCode?: string
  scanButtonKeyCode?: number
  timeBeforeScanTest: number
  avgTimeByChar: number
  minLength: number
  endChar: number[]
  startChar: number[]
  scanButtonLongPressThreshold: number
  onKeyDetect?: (e: KeyboardEvent) => void
  onReceive?: (e: KeyboardEvent) => void
  stopPropagation: boolean
  preventDefault: boolean
  onScanButtonLongPressed?: (stringWriting: string, scanButtonCounter: number) => void
  onScan?: (data: string, scanButtonCounter: number) => void
  onError?: (data: string, errorMsg: string) => void
}

const config: Options = {
  timeBeforeScanTest: 100,
  avgTimeByChar: 40,
  minLength: app_config.search.minCharactersCount,
  endChar: [9, 13],
  startChar: [],
  scanButtonLongPressThreshold: 3,
  stopPropagation: false,
  preventDefault: false,
}

export const useBarCodeReader = (
  onScan: (reading?: string) => void,
  disabled?: boolean,
  testCode?: string,
) => {
  const options = useMemo(
    () => ({
      ...config,
      onScan,
    }),
    [onScan],
  )

  const keyPressHandler = useCallback((e: KeyboardEvent) => handleKeyPress(options)(e), [options])
  useEffect(() => {
    if (!disabled) {
      if (inIframe()) {
        window.parent.document.addEventListener('keypress', keyPressHandler)
      }

      window.document.addEventListener('keypress', keyPressHandler)
    }

    return () => {
      if (!disabled) {
        if (inIframe()) {
          window.parent.document.removeEventListener('keypress', keyPressHandler)
        }

        window.document.removeEventListener('keypress', keyPressHandler)
      }
    }
  }, [options, keyPressHandler, disabled])

  if (testCode) {
    scannerDetectionTest(options)(testCode)
  }
}
