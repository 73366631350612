import React from 'react'
import styled from 'styled-components'

import { OpticalTipsContent } from '../../../model/content'
import { breakpoints, getFluidFontSize, palette } from '../../../style/theme'

const Wrapper = styled.div`
  margin-bottom: 3.5vh;
  background: ${palette.white};
  padding: 0.8vmax;
  color: ${palette.mineShaft};
  border: 1px solid ${palette.alto};
  border-radius: 16px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  &:nth-child(3) .icon > * {
    width: 70%;
    height: 70%;
  }

  @media screen and (max-width: ${breakpoints.L}) {
    margin-bottom: 2.2vh;
    border-radius: 8px;
    padding: 1rem 0.75rem;
  }
`
Wrapper.displayName = 'OpticianTipsBlock'

const Title = styled.h1`
  font-size: ${getFluidFontSize('18px')};
  letter-spacing: 0.07rem;
  margin-bottom: 0.75em;
`
Title.displayName = 'Title'

const List = styled.ul`
  list-style: disc;
  margin-left: 1em;
`

const ListElement = styled.li`
  font-size: ${getFluidFontSize('12px')};
  margin-bottom: 1.5vh;
  letter-spacing: 0.4px;
`
ListElement.displayName = 'ListElement'

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.6vw;
  height: 4.6vh;
  position: absolute;
  top: 0;
  left: -4vw;
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px ${palette.alto};
  > * {
    width: 90%;
    height: 90%;
  }

  @media screen and (max-width: ${breakpoints.L}) {
    border-radius: 8px;
    width: 3.5vw;
    height: 4.65vh;
    left: -5vw;
  }
`

type Props = {
  icon: JSX.Element
  title: string
  tips?: OpticalTipsContent
}

const OpticianTipsBlock: React.FC<Props> = ({ icon, title, tips }) => {
  return tips && tips.items && tips.items.length ? (
    <Wrapper className="optician-tips-block">
      <Title>{title}</Title>
      <List>
        {tips.items.map((paragraph, i) => (
          <ListElement key={i}>{paragraph}</ListElement>
        ))}
      </List>
      <Icon className="icon">{icon}</Icon>
    </Wrapper>
  ) : null
}

export default OpticianTipsBlock
