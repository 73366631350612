import { RootState } from '../../model/model'
import { createSelector } from '@reduxjs/toolkit'

export const isOpen = createSelector(
  (state: RootState) => state.afaCartAdjust.isOpen,
  isOpen => isOpen,
)

const cartProducts = createSelector(
  (state: RootState) => state.afaCartAdjust.cartProducts,
  cartProducts => cartProducts,
)

const isEdited = createSelector(
  (state: RootState) => state.afaCartAdjust.isEdited,
  isEdited => isEdited,
)

const removedKeys = createSelector(
  (state: RootState) => state.afaCartAdjust.removedKeys,
  removedKeys => removedKeys,
)

const expiredCartProductsToRemove = createSelector(
  (state: RootState) => state.afaCartAdjust.expiredCartProductsToRemove,
  expiredCartProductsToRemove => expiredCartProductsToRemove,
)

export const afaCartAdjustSelectors = {
  isOpen,
  cartProducts,
  isEdited,
  removedKeys,
  expiredCartProductsToRemove,
}
