import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

import Loading from '../components/Loading'
import useFetchInitialData from '../hooks/useFetchInitialData'
import { useHandleOpenChooseBrandPage } from '../hooks/useHandleOpenPages'
import { getDeviceAbsolutePath } from '../libs/url'
import { AfaBrand } from '../model/afa'
import { useGetBrandsQuery } from '../services/afa'
import { useGetBrandLogo } from '../services/afaContents'
import appActions, { getEventReleases } from '../store/app/actions'
import { eventIdSelector } from '../store/app/selectors'
import {
  breakpoints,
  getFluidSizeWithFullFormula as gF,
  palette,
  pxToRem,
  spacing,
} from '../style/theme'
import { BackButton, FOOTER_CSS, LeftCol } from '../components/AfaFooter'
import Icon from '../styleguide/Icon'
import { useTranslation } from 'react-i18next'
import RedCarpetLogo from '../components/UI/RedCarpetLogo'

const NUMBER_OF_COLUMNS = 2

const ChooseBrandPageLayout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Header = styled.div`
  padding: ${gF('px', 30, 60, 1366, 3840)} ${gF('px', 32, 64, 1366, 3840)};
`

const Footer = styled.div`
  ${FOOTER_CSS}
`

const BrandsGridWrapper = styled.div`
  background-color: ${palette.wildSand};
  flex: 1;
  display: grid;
  overflow: hidden;
  padding: ${spacing(pxToRem(5))}rem;
  gap: ${spacing(pxToRem(2))}rem;
  grid-template-rows: 1fr;

  @media (max-width: ${breakpoints.L}) {
    background-color: ${palette.white};
  }
`

const BrandSection = styled.div`
  color: ${palette.congressBlue};
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: ${spacing(pxToRem(1))}rem;
  padding: 2vh 2vw;
`

const colGap = 64
const BrandsGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${NUMBER_OF_COLUMNS}, 1fr);
  width: 100%;
  max-width: ${pxToRem(NUMBER_OF_COLUMNS * (904 + colGap))}rem;
  margin: 0 auto;
  height: 100%;
  max-height: ${pxToRem(668)}rem;
  row-gap: 2vw;
  column-gap: ${pxToRem(colGap)}rem;
  grid-template-rows: repeat(auto-fill, 1fr);
`

const BrandLogoWrapper = styled(Link)`
  display: flex;
  align-items: center;
  align-self: center;
  justify-self: center;
  justify-content: center;
  width: ${pxToRem(480)}rem;
  height: ${pxToRem(270)}rem;
  border-radius: ${pxToRem(24)}rem;
  box-sizing: border-box;
  cursor: pointer;
  background-color: ${palette.white};
  width: 100%;
  height: 100%;

  &.disabled {
    opacity: 0.5;
  }

  img {
    width: ${gF('px', 195, 320, 1366, 3840)};
    height: auto;
  }
`

const Logo = styled.img`
  &.alternative {
    filter: invert(1);
  }
`

const BrandLogo: React.FC<{
  brand: AfaBrand
  logoVariant?: 'standard' | 'alternative'
}> = ({ brand, logoVariant }) => {
  const dispatch = useDispatch()

  const { assortment } = useParams<{ assortment: string }>()

  const { brandLogo, isSuccess } = useGetBrandLogo(brand.code)

  return (
    <BrandLogoWrapper
      onClick={() => dispatch(appActions.setAfaBrand(brand))}
      to={`${getDeviceAbsolutePath()}/${
        assortment === 'frames' ? '' : `${assortment}/`
      }single-brand/${brand.code}`}
    >
      {brandLogo || isSuccess ? (
        <Logo className={logoVariant} src={brandLogo} alt={brand.name || brand.code} />
      ) : (
        <span>{brand.name || brand.code}</span>
      )}
    </BrandLogoWrapper>
  )
}

const AfaChooseBrandPage: React.FC = () => {
  useFetchInitialData({ loadCustomer: true })

  useHandleOpenChooseBrandPage()

  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const { assortment } = useParams<{ assortment: string }>()

  const eventId = useSelector(eventIdSelector)

  const brandsQuery = useGetBrandsQuery()
  const brands = brandsQuery.data || []

  useEffect(() => {
    dispatch(getEventReleases(eventId, assortment))
  }, [dispatch, eventId, assortment])

  if (brandsQuery.isSuccess && brands.length === 1) {
    return (
      <Redirect
        to={`${getDeviceAbsolutePath()}/${
          assortment === 'frames' ? '' : `${assortment}/`
        }single-brand/${brands[0].code}`}
      />
    )
  }

  return (
    <ChooseBrandPageLayout className="device-table">
      <Header>
        <RedCarpetLogo />
      </Header>
      <BrandsGridWrapper>
        {brandsQuery.isFetching ? (
          <Loading />
        ) : (
          <BrandSection>
            <BrandsGridContainer>
              {brands.map(brand => (
                <BrandLogo key={brand.code} brand={brand} />
              ))}
            </BrandsGridContainer>
          </BrandSection>
        )}
      </BrandsGridWrapper>
      <Footer>
        <LeftCol>
          <BackButton onClick={() => history.goBack()}>
            <Icon type="left" />
            <div>{t('CustomersList.back')}</div>
          </BackButton>
        </LeftCol>
      </Footer>
    </ChooseBrandPageLayout>
  )
}

export default AfaChooseBrandPage
