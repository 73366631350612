import { memoize } from 'lodash'

import { createSelector } from '@reduxjs/toolkit'

import { getDeliveryDate } from '../../libs/cart'
import { getIntersessionCustomerId } from '../../libs/customer'
import { getIntersessionEventId } from '../../libs/event'
import { isNotUndefined } from '../../libs/utils'
import { CartProductStatus, CheckoutProduct } from '../../model/cart'
import { CheckoutFiltersOptions } from '../../model/checkout'
import { RcEvent } from '../../model/events'
import { FilterCounters } from '../../model/filters'
import { BrandCode, ModelCode, RootState } from '../../model/model'
import { Product } from '../../model/product'
import { cartApi } from '../../services/cart'
import { selectedEventSelector } from '../app/selectors'
import { brandsSelector, subbrandToMainBrandMapSelector } from '../brands/selectors'
import {
  cartProductsDetailsSelector,
  cartProductsFilteredByActiveDoorsSelector,
  cartProductsSelector,
} from '../cart/selectors'
import { getProductImageUrl } from '../../libs/productImages'

export const checkoutFooterButtonsSelector = (state: RootState) => state.checkout.footerButtons

export const isSidebarOpenSelector = (state: RootState) => state.checkout.sidebar.open

export const sidebarStepSelector = (state: RootState) => state.checkout.sidebar.step

export const paginationTotalItemsSelector = (state: RootState) =>
  state.checkout.pagination.totalItems

export const paginationItemsEachPageSelector = (state: RootState) =>
  state.checkout.pagination.itemsEachPage

export const paginationCurrentPageSelector = (state: RootState) =>
  state.checkout.pagination.currentPage

export const checkoutStepSelector = (state: RootState) => state.checkout.step

export const checkoutCurrentPageSelector = (state: RootState) => state.checkout.page

export const importExportStepSelector = (state: RootState) => state.checkout.importExport.step

export const selectedAddressesSelector = (state: RootState) => state.checkout.selectedAddresses

const selectDoorsShippingAddressesResult = cartApi.endpoints.getDoorShippingAddress.select({
  eventId: getIntersessionEventId() || '',
  customerId: getIntersessionCustomerId() || '',
})

const selectDoorsShippingAddresses = createSelector(
  selectDoorsShippingAddressesResult,
  result => result?.data || [],
)

export const selectCheckoutFilters = (state: RootState) => state.checkout.filters

export const orderNotesSelector = (state: RootState) => state.checkout.orderNotes

export const selectCheckoutProducts = createSelector(
  cartProductsFilteredByActiveDoorsSelector,
  cartProductsDetailsSelector,
  (cardProducts, cartProductDetails) => {
    return cardProducts.map(cartProduct => {
      const { modelCode, colorCode, upc } = cartProduct
      const model = cartProductDetails && cartProductDetails[modelCode]
      const moco = model?.mocos && model.mocos[colorCode]

      const checkoutProduct: CheckoutProduct = {
        ...cartProduct,
        imageUrl: moco ? getProductImageUrl({ path: moco.catalogImages.base, imwidth: 300 }) : '',
        sizeMasterData: moco?.sizes[upc],
      }

      return checkoutProduct
    })
  },
)

export const selectFilteredCheckoutProducts = createSelector(
  selectCheckoutProducts,
  selectCheckoutFilters,
  selectedEventSelector,
  (cardProducts, checkoutFilters, event) => {
    const eventReleases = event?.releases?.map(release => release.label)
    return cardProducts.filter(
      ({
        brandCode,
        deliveryDate,
        minDeliveryDate,
        modelCode,
        reference,
        quantity,
        categoryId,
        sizeMasterData,
      }) => {
        const matchBrand =
          checkoutFilters.brand.active.length === 0 ||
          checkoutFilters.brand.active.some(brands => brands.includes(brandCode))
        const matchDeliveryDate =
          checkoutFilters.deliveryDate.active.length === 0 ||
          ((!!deliveryDate || !!minDeliveryDate) &&
            checkoutFilters.deliveryDate.active.includes(
              getDeliveryDate(deliveryDate, minDeliveryDate),
            ))
        const matchModelCode =
          checkoutFilters.model.active.length === 0 ||
          modelCode.toLowerCase().includes(checkoutFilters.model.active.toLowerCase())
        const matchReference =
          checkoutFilters.reference.active.length === 0 ||
          (!!reference &&
            reference.toLowerCase().includes(checkoutFilters.reference.active.toLowerCase()))
        const matchQuantity =
          checkoutFilters.quantity.active.length === 0 ||
          checkoutFilters.quantity.active.includes(quantity.toString())
        const matchCategory =
          checkoutFilters.category.active.length === 0 ||
          checkoutFilters.category.active.includes(categoryId)
        const matchRelease =
          checkoutFilters.release.active.length === 0 ||
          (!!sizeMasterData?.release &&
            (checkoutFilters.release.active.includes(sizeMasterData.release) ||
              (checkoutFilters.release.active.includes('Other') &&
                !eventReleases?.includes(sizeMasterData.release))))

        return (
          matchBrand &&
          matchDeliveryDate &&
          matchModelCode &&
          matchReference &&
          matchQuantity &&
          matchCategory &&
          matchRelease
        )
      },
    )
  },
)

export const selectDoorsInCheckout = createSelector(
  selectFilteredCheckoutProducts,
  checkoutProducts =>
    checkoutProducts.filter(({ status }) => status === '1').map(({ doorId }) => doorId),
)

export const doorsShippingAddressesInCartSelector = createSelector(
  selectFilteredCheckoutProducts,
  checkoutProducts => {
    const doorsShippingAddresses = checkoutProducts
      .filter(({ status }) => status === '1')
      .reduce((result, product) => {
        const { doorId, doorAddressId } = product

        if (!doorAddressId) {
          return result
        }

        if (doorId in result) {
          !result[doorId].includes(doorAddressId) && result[doorId].push(doorAddressId)
        } else {
          result[doorId] = [doorAddressId]
        }

        return result
      }, {} as Record<string, string[]>)

    return doorsShippingAddresses
  },
)

export const _selectCheckoutFiltersInitvalues_cb = (
  checkoutProducts: CheckoutProduct[],
  productDetails: Record<ModelCode, Product>,
  subbrandToMainBrandMap: Record<BrandCode, BrandCode>,
  event?: RcEvent,
) => {
  const eventReleases = event?.releases?.map(release => release.label)
  const initValues = checkoutProducts
    .filter(
      ({ status, mocoCode }, index) =>
        status === '1' &&
        index === checkoutProducts.findIndex(product => product.mocoCode === mocoCode),
    )
    .reduce(
      (result, checkoutProduct) => {
        const {
          brandCode,
          categoryId,
          deliveryDate,
          modelCode,
          colorCode,
          upc,
          minDeliveryDate,
        } = checkoutProduct
        //const mainBrand = subbrandToMainBrandMap[brandCode]

        result.brand.counter[brandCode] = (result.brand.counter[brandCode] || 0) + 1

        result.category.counter[categoryId] = (result.category.counter[categoryId] || 0) + 1

        const deliveryDatee = getDeliveryDate(deliveryDate, minDeliveryDate)

        if (deliveryDatee) {
          result.deliveryDate.counter[deliveryDatee] =
            (result.deliveryDate.counter[deliveryDatee] || 0) + 1
        }

        const masterDataSize = productDetails[modelCode]?.mocos[colorCode]?.sizes[upc]
        if (masterDataSize) {
          const { release } = masterDataSize
          if (eventReleases?.includes(release)) {
            result.release.counter[release] = (result.release.counter[release] || 0) + 1
          }
        }

        return result
      },
      {
        brand: {
          counter: {} as FilterCounters,
          options: [] as string[],
        },
        deliveryDate: {
          counter: {} as FilterCounters,
          options: [] as string[],
        },
        release: {
          counter: {} as FilterCounters,
          options: [] as string[],
        },
        category: {
          counter: {} as FilterCounters,
          options: [] as string[],
        },
        quantity: {
          counter: {} as FilterCounters,
          options: [] as string[],
        },
      },
    )

  initValues.brand.options = Object.keys(initValues.brand.counter)
  initValues.deliveryDate.options = Object.keys(initValues.deliveryDate.counter)
  initValues.release.options = Object.keys(initValues.release.counter)
  initValues.category.options = Object.keys(initValues.category.counter)
  initValues.quantity.options = Object.keys(initValues.quantity.counter)

  initValues.release.options.push('Other')

  return initValues
}

export const selectCheckoutFiltersInitvalues = createSelector(
  selectCheckoutProducts,
  cartProductsDetailsSelector,
  subbrandToMainBrandMapSelector,
  selectedEventSelector,
  memoize(
    _selectCheckoutFiltersInitvalues_cb,
    (filteredCheckoutProducts, cartProductsDetails, subbrandToMainBrandMap, event) =>
      filteredCheckoutProducts.length +
      Object.keys(cartProductsDetails).length +
      (event?.releases?.length || 0),
  ),
)

export const selectCheckoutBrands = createSelector(
  brandsSelector,
  selectCheckoutFiltersInitvalues,
  (brands, initValues) => {
    const brandsWithJunior = brands.flatMap(
      ({ brand, group, juniorBrands: juniorBrandCodes, juniorBrandDefinition }) => {
        const adultBrandCodes = group.filter(subCode => !juniorBrandCodes.includes(subCode))

        const adultBrands = adultBrandCodes.map(adultBrandCode => ({
          code: adultBrandCode,
          brand,
          group: adultBrandCodes,
        }))

        const juniorBrands = juniorBrandCodes.map(juniorBrandCode => ({
          code: juniorBrandCode,
          brand: `${brand} ${juniorBrandDefinition}`,
          group: juniorBrandCodes,
        }))

        return adultBrands.concat(juniorBrands)
      },
    )

    const resultWithDuplicatedNames = brandsWithJunior.filter(brand =>
      initValues.brand.options.includes(brand.code),
    )

    const deduplicatedResult = Object.values(
      resultWithDuplicatedNames.reduce((result, brand) => {
        result[brand.brand] = brand
        return result
      }, {} as Record<string, { brand: string; code: string; group: string[] }>),
    )

    return deduplicatedResult
  },
)

export const selectConfirmCartPayload = createSelector(
  cartProductsSelector,
  selectDoorsShippingAddresses,
  selectedAddressesSelector,
  (checkoutProducts, doorsShippingAddresses, selectedAddresses) => {
    return checkoutProducts
      .map(({ skuCode, upc, doorId, status, deliveryDate, minDeliveryDate, quantity }) => {
        const door = doorsShippingAddresses.find(door => door.id === doorId)
        const doorDefaultShippingAddress = door?.addresses.find(
          ({ addresstype }) => addresstype === 'WE',
        )
        const selectedAddress = selectedAddresses.find(address => address.doorId === doorId)
        const doorAddressId =
          selectedAddress?.selectedAddressId || doorDefaultShippingAddress?.addressid
        const deliveryDateUpdated = getDeliveryDate(deliveryDate, minDeliveryDate)
        return doorAddressId && status === '1'
          ? {
              skuCode,
              upc,
              doorId,
              doorAddressId,
              quantity,
              deliveryDate: deliveryDateUpdated !== deliveryDate ? deliveryDateUpdated : undefined,
              status: '2' as CartProductStatus,
            }
          : undefined
      })
      .filter(isNotUndefined)
  },
)

export const selectCheckoutFiltersToShow = (onlyActive?: boolean) =>
  createSelector(selectCheckoutFilters, filters => {
    const filtersToShow = Object.entries(filters)
      .flatMap(([filterKey, filterData]) => {
        const filter = onlyActive ? filterData.active : filterData.selected
        const isFilterWithOptions = Array.isArray(filter) && 'options' in filterData
        if (!isFilterWithOptions) {
          if (!Array.isArray(filter) && !!filter) {
            return [
              {
                filterKey: filterKey as keyof CheckoutFiltersOptions,
                selectedId: filter,
                selected: true,
                label: filter,
                optionName: filter,
                quantity: filterData.counter[filter],
              },
            ]
          }
          return undefined
        }

        return filter.map(selectedId => ({
          filterKey: filterKey as keyof CheckoutFiltersOptions,
          selectedId,
          selected: true,
          label: selectedId,
          optionName: selectedId,
          quantity: filterData.counter[selectedId],
        }))
      })
      .filter(isNotUndefined)
    return filtersToShow
  })

export const selectFileData = (state: RootState) => state.checkout.upload.fileData
