import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { resetForNewClient } from '../extraActions'

const DEFAULT_STATE = {
  showNotifications: false,
  initialNotificationCount: 0,
  initialNotificationsList: [] as string[],
  currentNotificationsList: [] as string[],
}

export const slice = createSlice({
  name: 'afaCartNotifications',
  initialState: DEFAULT_STATE,
  reducers: {
    setShowNotifications: (state, { payload }: PayloadAction<boolean>) => {
      state.showNotifications = payload
    },

    setInitialNotificationCount: (state, { payload }: PayloadAction<number>) => {
      state.initialNotificationCount = payload
    },

    setInitialNotificationsList: (state, { payload }: PayloadAction<string[]>) => {
      state.initialNotificationsList = payload
    },

    setCurrentNotificationsList: (state, { payload }: PayloadAction<string[]>) => {
      state.currentNotificationsList = payload
    },

    cartNotificationsReset: state => {
      return state
    },

    reset: () => DEFAULT_STATE,
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, () => DEFAULT_STATE)
  },
})

export default slice.reducer
