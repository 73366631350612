import React from 'react'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  color: white;
`

const Text = styled.span`
  font-weight: lighter;
  opacity: 0.7;
`

const Highlighted = styled.span`
  font-weight: bold;
`

type Props = {
  suggestion: string
  searchTerm: string
  applySearch: (searchTerm: string) => void
}

const AfaSearchSuggestion: React.FC<Props> = ({ suggestion, searchTerm, applySearch }) => {
  const searchTermStartIndex = suggestion.toLowerCase().indexOf(searchTerm.toLowerCase())
  const firstPart = suggestion.substring(0, searchTermStartIndex)
  const highlighted = suggestion.substring(
    searchTermStartIndex,
    searchTermStartIndex + searchTerm.length,
  )
  const lastPart = suggestion.substring(searchTermStartIndex + searchTerm.length)

  return (
    <Wrapper onClick={() => applySearch(suggestion)}>
      <Text>{firstPart}</Text>
      <Highlighted data-testid="highlighted">{highlighted}</Highlighted>
      <Text>{lastPart}</Text>
    </Wrapper>
  )
}

export default AfaSearchSuggestion
