import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import StarsIcon from '../components/UI/StarsIcon'
import { logout } from '../store/auth/actions'
import { selectEventAndCustomerHeaderButton } from './DigitalEventsCommonComponents'
import { breakpoints, breakpointsCross, getFluidFontSize, palette } from './theme'

export const Wrapper = styled.div`
  background-color: ${palette.mineShaft};
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
`

export const Content = styled.div`
  background-color: ${palette.white};
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const TableHead = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-image: linear-gradient(to bottom, #d3e8f8 4%, #e9f3fc);
  padding: 2vh;
`

export const HeadLeft = styled.div`
  font-size: ${getFluidFontSize('20px', 15)};
  margin-left: 0.2vw;
  color: ${palette.curiousBlue};
  font-weight: bold;
  align-self: center;

  @media (max-width: ${breakpointsCross.L.min}) {
    font-size: 1rem;
  }
`

export const HeadTitle = styled.div`
  text-align: center;
  font-size: ${getFluidFontSize('20px', 15)};
  font-weight: bold;
  letter-spacing: 0.05em;
  color: ${palette.congressBlue};
  place-self: center;

  @media (max-width: ${breakpointsCross.L.min}) {
    font-size: 1rem;
  }
`

export const CustomerList = styled.div`
  height: 100%;
  width: 80%;
  overflow: auto;

  &.expand {
    width: 100%;
  }

  @media (max-width: ${breakpoints.S}) {
    width: 85%;
  }
`

export const EventsList = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding: 0 3vh 3vh 3vh;
`

export const SelectTable = styled.table`
  width: 100%;
  text-align: center;
  font-size: ${getFluidFontSize('20px', 15)};
  letter-spacing: 1px;
  line-height: 1.25;

  @media (max-width: ${breakpointsCross.L.min}) {
    font-size: 1rem;
  }
`

export const SelectTableHead = styled.thead`
  color: ${palette.hibiscus};
  text-transform: uppercase;
  background-color: ${palette.white};
  position: sticky;
  outline: 3px solid ${palette.white};
  outline-offset: -1px;
  top: 0;

  & > tr {
    box-shadow: none;
  }
`

export const SelectTableBody = styled.tbody`
  color: ${palette.curiousBlue};

  &:before {
    height: 1px;
    content: '.';
    color: white;
    display: block;
  }
`

export const SelectTr = styled.tr`
  border: 2px solid ${palette.wildSand};
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);

  &:hover {
    background-color: #e6f7ff;
  }
`

export const SelectTh = styled.th`
  padding: 5vh 0 2vh 0;
  font-weight: bold;
  background-color: ${palette.white};
  color: ${palette.hibiscus};
`

export const SelectTd = styled.td`
  padding: 2vh 0;
  max-width: 500px;

  @media (max-width: ${breakpoints.L}) {
    max-width: 250px;
  }
`

export const StyledStarsIcon = styled(StarsIcon)`
  height: 3.5rem;
  width: 3.5rem;
  color: ${palette.congressBlue};

  @media (max-width: ${breakpoints.L}) {
    height: 1.5rem;
    width: 1.5rem;
  }
`

export const SelectTableButton = styled.button`
  padding: 1em 5em;
  font-size: 0.875em;
  font-weight: bold;
  line-height: 1.2;
  color: ${palette.congressBlue};
  background-color: ${palette.white};
  border: 1px solid ${palette.concrete};
  border-radius: 22.5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: ${breakpoints.XL}) {
    border-radius: 3rem;
  }

  @media (max-width: ${breakpoints.S}) {
    padding: 1em 2em;
  }
`

const Button = styled.button`
  ${selectEventAndCustomerHeaderButton}
  padding: 1vh 1vw;
  text-transform: capitalize;

  &:hover {
    color: ${palette.curiousBlue};
  }
`

export const LogoutButton: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
    <Button
      onClick={() => {
        dispatch(logout())
      }}
    >
      {t('FooterNav.menu_logout')}
    </Button>
  )
}
