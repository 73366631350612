import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { getAfaFilterTranslation } from '../../components/AfaFilters/AfaFiltersLayer'
import AfaModal from '../../components/AfaModal'
import { useSearchParams } from '../../hooks/useSearchParams'
import { AfaMoco, AfaProduct, AfaSize } from '../../model/afa'
import {
  useGetAvdInfoHtmlQuery,
  useGetSizeGuideHtmlQuery,
  useGetTechnologyIconsQuery,
  useGetTechnologyInfoHtmlQuery,
} from '../../services/afaContents'
import {
  breakpoints,
  breakpointsCross,
  getFluidFontSize,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
  spacing,
} from '../../style/theme'

const Wrapper = styled.div<{ $order?: number }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: ${palette.tangaroa};
  overflow-y: auto;

  ${({ $order }) =>
    !!$order &&
    css`
      @media (min-width: ${breakpoints.L}) {
        order: ${$order};
      }
    `}

  @media (min-width: ${breakpointsCross.M.over}) and (max-width: ${breakpoints.XL}) {
    gap: 1rem;
  }
`

const ProductInfoTitle = styled.div`
  font-weight: bold;
  margin-bottom: ${pxToRem(spacing(2))}rem;
  text-transform: uppercase;
  font-size: ${getFluidSizeWithFullFormula('px', 14, 48, 1024, 3840)};

  @media (min-width: ${breakpointsCross.M.over}) and (max-width: ${breakpointsCross.XL.max}) {
    margin-bottom: 0;
  }
`

const ProductInfoText = styled.div`
  font-size: ${getFluidFontSize('14px')};
`

const Icon = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`

const TechnologiesBadges = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
  grid-template-rows: repeat(auto-fill, 5rem);
  gap: ${pxToRem(spacing(1))}rem;
`

const Features = styled.div`
  font-size: ${getFluidFontSize('16px')};
`

const FeaturesIcons = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 2rem);
  grid-template-rows: repeat(auto-fill, 2rem);
  gap: ${pxToRem(spacing(1))}rem;
`

const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  max-width: ${pxToRem(800)}rem;

  @media (min-width: ${breakpoints.L}) {
    order: 1;
  }
`

const ModalButton = styled.button`
  text-decoration: underline;
  font-size: ${getFluidFontSize('14px')};
`

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  z-index: -1;
  position: relative;
`

type Props = {
  product?: AfaProduct
}

const AttributesWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  row-gap: ${getFluidSizeWithFullFormula('px', 16, 32, 1366, 3840)};
  color: ${palette.tangaroa};
  overflow-y: auto;

  @media (min-width: ${breakpoints.L}) {
    grid-template-columns: max-content 1fr;
  }
`

const AttributeName = styled.div`
  font-size: ${getFluidFontSize('14px')};
  word-break: break-word;
  height: max-content;

  @media (min-width: ${breakpoints.L}) {
    width: ${getFluidSizeWithFullFormula('px', 180, 350, 1366, 3840)};
  }
`

const AttributeValue = styled.div`
  font-size: ${getFluidFontSize('14px')};
  height: max-content;
  font-weight: bold;
  overflow-y: auto;
`

const ModalButtonsWrapper = styled.div`
  margin-top: auto;
`

const IframeModal = styled(AfaModal)`
  & > button {
    z-index: 2;
  }

  & > iframe {
    z-index: 1;
    width: 100%;

    @media (min-width: ${breakpoints.L}) {
      width: 98%;
    }
  }
`

const attributesListCategories = ['Helmets', 'Goggles']

const attributesToShow = [
  'frameMaterial',
  'lensColorDescription',
  'lensExtTreatment',
  'lensMateriallensProperties',
  'vltTransmission',
  'polarized',
  'recommendedUse',
  'templeColor',
  'weatherCondition',
  'colorDescription',
  'lensMaterial',
  'lensColoringPerceived',
  'productFeatures',
]

const filterAttributes = (mocoOrSize: AfaMoco | AfaSize) => {
  const attributes = Object.entries(mocoOrSize).filter(([attribute, value]) => {
    return attributesToShow.includes(attribute) && !!value
  })
  return attributes
}

const getAttributes = (moco: AfaMoco) => {
  const attributesFromMoco = filterAttributes(moco)
  const attributesFromSizes = Object.values(moco.sizes).flatMap(size => filterAttributes(size))
  const mergedAttributes = attributesFromMoco.concat(attributesFromSizes)
  const uniqueAttributes = Object.fromEntries(mergedAttributes)
  return uniqueAttributes
}

const ProductDescription: React.FC<{
  productInfo?: string
  productFeatures?: string
}> = ({ productInfo, productFeatures, children }) => {
  const { t } = useTranslation()
  return (
    <Wrapper $order={2}>
      {(!!productInfo || !!productFeatures) && (
        <ProductInfoTitle>{t('Afa.productInfo')}</ProductInfoTitle>
      )}
      {productInfo ? (
        <Wrapper>
          <ProductInfoText dangerouslySetInnerHTML={{ __html: productInfo }} />
          {children}
        </Wrapper>
      ) : (
        children
      )}
    </Wrapper>
  )
}

const AfaPdpInfo: React.FC<Props> = ({ product }) => {
  const { t } = useTranslation()

  const [sizeGuideOpen, setSizeGuideOpen] = useState(false)
  const [avdInfoOpen, setAvdInfoOpen] = useState(false)
  const [technologyInfoOpen, setTechnologyInfoOpen] = useState(false)

  const [searchParams] = useSearchParams()
  const selectedColorCode = searchParams.get('color')
  const selectedColor =
    product &&
    Object.values(product.mocos).find((color, index) =>
      selectedColorCode ? color.colorCode === selectedColorCode : index === 0,
    )

  const productCategory = product?.category?.toLocaleLowerCase()

  const sizeGuideQuery = useGetSizeGuideHtmlQuery(
    {
      brandCode: product?.brandCodeParent,
      sport: productCategory === 'footwear' ? productCategory : selectedColor?.sports?.[0],
    },
    {
      skip: !sizeGuideOpen,
    },
  )
  const avdInfoQuery = useGetAvdInfoHtmlQuery(product?.brandCodeParent, { skip: !avdInfoOpen })
  const technologyInfoQuery = useGetTechnologyInfoHtmlQuery(product?.brandCodeParent, {
    skip: !technologyInfoOpen,
  })

  const technologyIconsQuery = useGetTechnologyIconsQuery(product?.brandCodeParent)
  const technologyIcons = technologyIconsQuery.data || {
    tech: [],
    kspTechnology: [],
    kspSustainability: [],
  }

  const attributes = selectedColor && getAttributes(selectedColor)
  const featuresIcons = technologyIcons.tech.filter(({ tag }) =>
    selectedColor?.technologies?.includes(tag),
  )

  return !attributesListCategories.includes(product?.category || '') ? (
    <>
      <ProductDescription
        productInfo={selectedColor?.productInfo}
        productFeatures={selectedColor?.productFeatures}
      >
        <TechnologiesBadges>
          {technologyIcons.kspTechnology
            .filter(({ tag }) => selectedColor?.technologies?.includes(tag))
            .map(({ url, tag }) => (
              <Icon key={tag} src={url} alt="" />
            ))}
          {technologyIcons.kspSustainability
            .filter(({ tag }) => selectedColor?.technologies?.includes(tag))
            .map(({ url, tag }) => (
              <Icon key={tag} src={url} alt="" />
            ))}
        </TechnologiesBadges>

        {!!selectedColor?.productFeatures && (
          <Features dangerouslySetInnerHTML={{ __html: selectedColor.productFeatures }} />
        )}

        {featuresIcons.length ? (
          <FeaturesIcons>
            {featuresIcons.map(({ url, tag }) => (
              <Icon key={tag} src={url} alt="" />
            ))}
          </FeaturesIcons>
        ) : (
          ''
        )}
      </ProductDescription>
      <ModalButtons>
        <ModalButton onClick={() => setSizeGuideOpen(true)}>{t('Afa.sizeGuide')}</ModalButton>
        <IframeModal isOpen={sizeGuideOpen} onRequestClose={() => setSizeGuideOpen(false)}>
          {!!sizeGuideQuery.data?.url && <Iframe src={sizeGuideQuery.data.url} />}
        </IframeModal>

        <ModalButton onClick={() => setAvdInfoOpen(true)}>{t('Afa.avdInfo')}</ModalButton>
        <IframeModal isOpen={avdInfoOpen} onRequestClose={() => setAvdInfoOpen(false)}>
          {!!avdInfoQuery.data?.url && <Iframe src={avdInfoQuery.data.url} />}
        </IframeModal>

        <ModalButton onClick={() => setTechnologyInfoOpen(true)}>
          {t('Afa.technologyInfo')}
        </ModalButton>
        <IframeModal
          isOpen={technologyInfoOpen}
          onRequestClose={() => setTechnologyInfoOpen(false)}
        >
          {!!technologyInfoQuery.data?.url && <Iframe src={technologyInfoQuery.data.url} />}
        </IframeModal>
      </ModalButtons>
    </>
  ) : (
    <Wrapper>
      <ProductInfoTitle>{t('Afa.productInfo')}</ProductInfoTitle>
      <TechnologiesBadges>
        {technologyIcons.kspTechnology
          .filter(({ tag }) => selectedColor?.technologies?.includes(tag))
          .map(({ url, tag }) => (
            <Icon key={tag} src={url} alt="" />
          ))}
        {technologyIcons.kspSustainability
          .filter(({ tag }) => selectedColor?.technologies?.includes(tag))
          .map(({ url, tag }) => (
            <Icon key={tag} src={url} alt="" />
          ))}
      </TechnologiesBadges>
      <Wrapper>
        <AttributesWrapper>
          {attributes &&
            Object.entries(attributes).map(([attribute, value]) => {
              return (
                <>
                  <AttributeName>
                    {getAfaFilterTranslation(t, `Afa.fC.${attribute}`, attribute)}:
                  </AttributeName>
                  {attribute === 'productFeatures' ? (
                    <AttributeValue dangerouslySetInnerHTML={{ __html: value }} />
                  ) : (
                    <AttributeValue>
                      {getAfaFilterTranslation(t, `Afa.fO.${attribute}.${value}`, value)}
                    </AttributeValue>
                  )}
                </>
              )
            })}
        </AttributesWrapper>
        <ModalButtonsWrapper>
          <ModalButtons>
            <ModalButton onClick={() => setSizeGuideOpen(true)}>{t('Afa.sizeGuide')}</ModalButton>
            <IframeModal isOpen={sizeGuideOpen} onRequestClose={() => setSizeGuideOpen(false)}>
              {!!sizeGuideQuery.data?.url && <Iframe src={sizeGuideQuery.data.url} />}
            </IframeModal>

            <ModalButton onClick={() => setAvdInfoOpen(true)}>{t('Afa.avdInfo')}</ModalButton>
            <IframeModal isOpen={avdInfoOpen} onRequestClose={() => setAvdInfoOpen(false)}>
              {!!avdInfoQuery.data?.url && <Iframe src={avdInfoQuery.data.url} />}
            </IframeModal>

            <ModalButton onClick={() => setTechnologyInfoOpen(true)}>
              {t('Afa.technologyInfo')}
            </ModalButton>
            <IframeModal
              isOpen={technologyInfoOpen}
              onRequestClose={() => setTechnologyInfoOpen(false)}
            >
              {!!technologyInfoQuery.data?.url && <Iframe src={technologyInfoQuery.data.url} />}
            </IframeModal>
          </ModalButtons>
        </ModalButtonsWrapper>
      </Wrapper>
    </Wrapper>
  )
}

export default AfaPdpInfo
