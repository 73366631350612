import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setPostLoginRedirectUri } from '../libs/auth'
import socketManager from '../libs/socketManager'
import { getUrlParts, isRoomTypeMatches } from '../libs/url'
import { DeviceName, RoomType } from '../model/model'
import { setDeviceName, setRoomName, setRoomType } from '../store/actions'
import { deviceNameSelector, roomNameSelector, roomTypeSelector } from '../store/app/selectors'

const useSyncRoomName = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const roomName = useSelector(roomNameSelector)
  const roomType = useSelector(roomTypeSelector)
  const deviceName = useSelector(deviceNameSelector)
  const decodedURL: { roomName?: string; roomType?: RoomType; device?: DeviceName } = getUrlParts(
    location.pathname,
  )

  useEffect(() => {
    if (
      (decodedURL.roomName || roomName) &&
      (decodedURL.device || deviceName) &&
      (decodedURL.roomType || roomType)
    )
      setPostLoginRedirectUri(
        decodedURL.roomName || roomName,
        decodedURL.device || deviceName,
        decodedURL.roomType || roomType,
      )
    if (decodedURL.roomName !== roomName && decodedURL.roomName) {
      dispatch(setRoomName(decodedURL.roomName))
    }
    if (decodedURL.roomType && ((roomType && !isRoomTypeMatches(roomType)) || !roomType)) {
      dispatch(setRoomType(decodedURL.roomType))
    }
    if (decodedURL.device !== deviceName && decodedURL.device) {
      dispatch(setDeviceName(decodedURL.device))
    }
    if (decodedURL.roomName !== roomName && decodedURL.roomName) {
      socketManager.emitJoinSignal()
    }
  }, [decodedURL, roomName, deviceName, roomType, dispatch])
}

export default useSyncRoomName
