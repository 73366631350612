import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Content } from '../../model/content'
import { setBrandActivationPlayed, setBrandActivationPlaying } from '../../store/actions'
import {
  isBrandActivationPlayedSelector,
  isBrandActivationPlayingSelector,
} from '../../store/brands/selectors'
import DisplayMedia from '../DisplayMedia'

type Props = {
  brandCode: string
  media: Content
  videoPlayedCallback: () => void
}

const ActivationVideo: React.FC<Props> = ({ brandCode, media, videoPlayedCallback }) => {
  const isBrandActivationPlaying = useSelector(isBrandActivationPlayingSelector)
  const isBrandActivationPlayed = useSelector(isBrandActivationPlayedSelector)

  const dispatch = useDispatch()

  useEffect(() => {
    const setPlayingToFalseAtUnmounting = () => {
      isBrandActivationPlaying && dispatch(setBrandActivationPlaying({ brandCode, playing: false }))
    }
    return isBrandActivationPlaying ? setPlayingToFalseAtUnmounting : undefined
  }, [dispatch, brandCode, isBrandActivationPlaying])

  return (
    <DisplayMedia
      media={media}
      loop={false}
      handleVideoEnded={() => {
        isBrandActivationPlaying &&
          dispatch(setBrandActivationPlaying({ brandCode, playing: false }))
        !isBrandActivationPlayed && dispatch(setBrandActivationPlayed({ brandCode, played: true }))
        typeof videoPlayedCallback === 'function' && videoPlayedCallback()
      }}
      handleVideoPlaying={() => {
        !isBrandActivationPlaying &&
          dispatch(setBrandActivationPlaying({ brandCode, playing: true }))
        isBrandActivationPlayed && dispatch(setBrandActivationPlayed({ brandCode, played: false }))
      }}
    />
  )
}

export default ActivationVideo
