import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import {
  breakpoints,
  getFluidSizeWithFullFormula as gF,
  palette,
  pxToRem,
} from '../../../style/theme'
import { useTranslation } from 'react-i18next'
import RCIcon from '../../../components/UI/RCIcon'
import { scrollbarCss } from '../../../components/CommonComponents'
import { useDispatch, useSelector } from 'react-redux'
import { afaCartDataSelector } from '../../../store/afaCart/selectors'
import {
  adjustAllIsSimulate,
  cartAdjustAllKeysSelector,
  cartAdjustAllModalOpenSelector,
} from '../../../store/afa/selectors'
import Loading from '../../../components/Loading'
import afaActions from '../../../store/afa/actions'
import AfaCartAdjustAllNotNeeded from './AfaCartAdjustAllNotNeeded'
import {
  errorNotification,
  successNotification,
} from '../../../components/Notification/notifications'
import afaCartAdjustActions from '../../../store/afaCartAdjust/actions'
import { useAdjustQuantitiesMutation } from '../../../services/afaCart'

const sizeGridRows = css`
  display: grid;
  grid-template-columns: 5fr 1.5fr 1.5fr 1.5fr;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'GilmerRegular', sans-serif;
  color: ${palette.tangaroa};
  align-items: center;
  overflow: hidden;
`

const Title = styled.div`
  font-size: ${gF('px', 26, 48, 1366, 3840)};
  margin-bottom: ${gF('px', 32, 40, 1366, 3840)};
  font-family: GilmerMedium, sans-serif;
  @media (max-width: ${breakpoints.L}) {
    font-family: GilmerBold, sans-serif;
  }
`

const Subtitle = styled.div`
  font-size: ${gF('px', 20, 40, 1366, 3840)};
  margin-bottom: ${gF('px', 72, 87, 1366, 3840)};
`

const MainWrapper = styled.div`
  width: ${gF('px', 690, 1370, 1366, 3840)};
  margin-bottom: ${gF('px', 72, 165, 1366, 3840)};
`

const HeaderWrapper = styled.div`
  ${sizeGridRows}
`

const GridHeader = styled.div<{ centered?: boolean }>`
  border-bottom: solid ${gF('px', 1, 4, 1366, 3840)} ${palette.mischka};
  color: ${palette.manatee};
  padding-bottom: ${gF('px', 16, 32, 1366, 3840)};
  margin-bottom: ${gF('px', 24, 64, 1366, 3840)};
  position: sticky;
  top: 0;
  background-color: ${palette.white};
  text-align: ${props => (props.centered ? 'center' : 'left')};
  font-family: GilmerBold, sans-serif;
  font-size: ${gF('px', 16, 32, 1366, 3840)};
`

const ProductsWrapper = styled.div<{ viewportHeight: number }>`
  ${scrollbarCss};
  display: flex;
  flex-direction: column;
  height: ${props =>
    props.viewportHeight > parseInt(breakpoints.L)
      ? props.viewportHeight / 2.5
      : props.viewportHeight / 3}px;
  overflow-y: auto;
`

const ProductRow = styled.div`
  ${sizeGridRows}
  & > .arrow {
    font-size: ${gF('px', 32, 57, 1366, 3840)};
  }
`

const ProductCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${gF('px', 2, 22, 1366, 3840)};
  align-self: center;
  margin-bottom: ${gF('px', 29, 40, 1366, 3840)};
  color: ${palette.tangaroa};
  @media (max-width: ${breakpoints.L}) {
    color: ${palette.cloudBurst};
  }
`

const ProductName = styled.span`
  font-family: GilmerMedium, sans-serif;
  font-size: ${gF('px', 16, 28, 1366, 3840)};
`

const ProductColor = styled.span`
  font-size: ${gF('px', 14, 28, 1366, 3840)};
`

const QuantityCell = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  font-size: ${gF('px', 16, 28, 1366, 3840)};
  align-self: center;
  margin-bottom: ${gF('px', 29, 40, 1366, 3840)};

  &.prev-quantity {
    color: ${palette.shadowBlue};
    font-family: GilmerMedium, sans-serif;
  }

  &.new-quantity {
    color: ${palette.darkGreen};
    font-family: GilmerBold, sans-serif;
  }
`

const DoneButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: ${pxToRem(4)}rem;
  font-size: ${gF('px', 13, 24, 1366, 3840)};
  padding: ${gF('px', 20, 27, 1366, 3840)} ${gF('px', 83, 182, 1366, 3840)};
  height: ${gF('px', 56, 72, 1366, 3840)};
  min-width: ${gF('px', 120, 150, 1366, 3840)};
  font-family: GilmerBold, sans-serif;
  color: ${palette.white};
  background-color: ${palette.tangaroa};
  text-transform: uppercase;
`
type Props = {
  notNeededCallback?: () => void
  neededCallback?: () => void
  isOpen?: boolean
}

const AfaCartAdjustAll: React.FC<Props> = ({ notNeededCallback, neededCallback, isOpen }) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const cartData = useSelector(afaCartDataSelector)
  const checkAdjustOpen = useSelector(cartAdjustAllModalOpenSelector)
  const keysToAdjust = useSelector(cartAdjustAllKeysSelector)

  const isSimulate = useSelector(adjustAllIsSimulate)

  const [adjustQuantities, adjustQuantitiesResult] = useAdjustQuantitiesMutation({
    cacheKey: 'adjustAll',
  })

  useEffect(() => {
    if (
      checkAdjustOpen &&
      (adjustQuantitiesResult.isSuccess || adjustQuantitiesResult.isError) &&
      !isSimulate &&
      !adjustQuantitiesResult.isLoading
    ) {
      if (!notNeededCallback) {
        dispatch(afaActions.toggleCartAdjustAllModalOpen())
      }
      if (adjustQuantitiesResult.isError) {
        errorNotification({
          message: t('Afa.Cart.AdjustAll.Error'),
        })
      } else if (
        adjustQuantitiesResult.data?.result &&
        adjustQuantitiesResult.data?.warnings.length
      ) {
        adjustQuantitiesResult.data?.warnings.forEach(
          ({
            modelCode,
            colorCode,
            message,
          }: {
            modelCode: string
            colorCode: string
            message: string
          }) => {
            errorNotification({
              message: t('Afa.Cart.AdjustAll.ErrorWithMessage', {
                mocoCode: `${modelCode}-${colorCode}`,
                error: message,
              }),
            })
          },
        )
      } else {
        successNotification({
          message: t('Afa.Cart.AdjustAll.Success'),
        })
      }
      if (notNeededCallback) {
        notNeededCallback()
      }
      dispatch(afaCartAdjustActions.setIsEdited(true))
      dispatch(afaCartAdjustActions.reset())
      adjustQuantitiesResult.reset()
    }
  }, [adjustQuantitiesResult, checkAdjustOpen, dispatch, t, notNeededCallback, isSimulate])

  useEffect(() => {
    if (
      isOpen &&
      !adjustQuantitiesResult.isLoading &&
      (adjustQuantitiesResult.isSuccess || adjustQuantitiesResult.isError)
    ) {
      const adjustSuccess = !isSimulate && adjustQuantitiesResult.isSuccess
      const noNeedAdjust = (adjustQuantitiesResult.data?.warnings.length || 0) === 0

      if (noNeedAdjust || adjustSuccess) {
        notNeededCallback && notNeededCallback()
      } else {
        neededCallback && neededCallback()
      }
    }
  }, [notNeededCallback, adjustQuantitiesResult, neededCallback, isOpen, isSimulate])

  if (adjustQuantitiesResult.isLoading) {
    return (
      <Wrapper>
        <Loading isFullPage={false} />
      </Wrapper>
    )
  }

  return (adjustQuantitiesResult.data?.warnings.length || 0) > 0 ? (
    <Wrapper>
      <Title>{t('Afa.Cart.AdjustAll.Title')}</Title>
      <Subtitle>{t('Afa.Cart.AdjustAll.Subtitle')}</Subtitle>
      <MainWrapper>
        <HeaderWrapper>
          <GridHeader>{t('Afa.Cart.AdjustAll.Product')}</GridHeader>
          <GridHeader centered>{t('Afa.Cart.AdjustAll.PrevQty')}</GridHeader>
          <GridHeader />
          <GridHeader centered>{t('Afa.Cart.AdjustAll.NewQty')}</GridHeader>
        </HeaderWrapper>
        <ProductsWrapper viewportHeight={window.innerHeight}>
          {adjustQuantitiesResult.data?.warnings
            .slice()
            .sort((a: { modelCode: string }, b: { modelCode: string }) =>
              a.modelCode.localeCompare(b.modelCode),
            )
            .map(
              (
                item: {
                  modelCode: string
                  colorCode: string
                  oldQty: number
                  availableQty: number
                },
                index: number,
              ) => {
                const productDetails = cartData && cartData[item.modelCode]
                const moco =
                  productDetails &&
                  Object.values(productDetails.mocos).find(
                    ({ colorCode }) => colorCode === item.colorCode,
                  )

                return (
                  <ProductRow key={`product-${index}-row-${item.modelCode}-${item.colorCode}`}>
                    <ProductCell>
                      <ProductName>{productDetails?.name || item.modelCode}</ProductName>
                      <ProductColor>{moco?.colorDescription || item.colorCode}</ProductColor>
                    </ProductCell>
                    <QuantityCell className="prev-quantity">{item.oldQty}</QuantityCell>
                    <QuantityCell className="arrow">
                      <RCIcon type="checkout-import-export-comparison-arrow" />
                    </QuantityCell>
                    <QuantityCell className="new-quantity">{item.availableQty}</QuantityCell>
                  </ProductRow>
                )
              },
            )}
        </ProductsWrapper>
      </MainWrapper>
      <DoneButton
        onClick={() => {
          adjustQuantities({ keys: keysToAdjust })
          dispatch(afaActions.setAdjustAllIsSimulate(false))
        }}
      >
        {t('Afa.Cart.AdjustAll.Done')}
      </DoneButton>
    </Wrapper>
  ) : (
    <AfaCartAdjustAllNotNeeded
      onClose={() => {
        dispatch(afaActions.toggleCartAdjustAllModalOpen())
      }}
    />
  )
}

export default AfaCartAdjustAll
