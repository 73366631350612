import { useEffect } from 'react'
import { Rnd } from 'react-rnd'
import { ResizeDirection } from 're-resizable'
import { DraggableData, DraggableEvent } from 'react-draggable'
import { WhiteboardItemType } from '../../../../../../model/whiteboard'
import { convertPercentageToPx, roundNumber } from '../../../../../../libs/whiteboard'

import { Wrapper, DraggableComponent, Image } from '../style'
import useDraggable from '../useDraggable'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { getMultiselectEnabled } from '../../../../../../store/whiteboard/selectors'
import { useSearchParams } from '../../../../../../hooks/useSearchParams'

type Props = {
  image: WhiteboardItemType
  updatePosition: () => void
  reference: any //React.MutableRefObject<Rnd | null>
  whiteboardIsLocked: boolean
}

const DraggableImage = ({ image, updatePosition, reference, whiteboardIsLocked }: Props) => {
  /**
   * x, y: rappresentano le coordinate iniziali dell'elemento selezionato. NON vengono aggiornate durante il d&d. SONO aggiornate alla fine del d&d
   */
  const {
    id,
    selected,
    settings: {
      position: { x, y },
      style: { zIndex, width, height },
    },
  } = image

  const isTouchDevice = 'ontouchstart' in window

  const {
    handleMouseMove,
    handleResizeStop,
    handleDragStop,
    handleClick,
    handleResizeStart,
    handleDrag,
    handleResize,
  } = useDraggable(image, 'images', x, y, reference)

  const multiselectEnabled = useSelector(getMultiselectEnabled)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (reference) {
      reference.current?.updateSize({
        width: convertPercentageToPx(width),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height])

  useEffect(() => {
    if (reference) {
      reference.current?.updatePosition({
        x: convertPercentageToPx(x),
        y: convertPercentageToPx(y),
      })
    }
  }, [reference, x, y])

  useEffect(() => {
    if (reference) {
      const element = reference.current?.resizableElement.current as HTMLElement
      const { width, height } = element.getBoundingClientRect()

      element.setAttribute('starting-x', `${convertPercentageToPx(x)}`)
      element.setAttribute('starting-y', `${convertPercentageToPx(y)}`)
      element.setAttribute('starting-width', `${roundNumber(width)}`)
      element.setAttribute('starting-width', `${roundNumber(height)}`)
      element.setAttribute('id', `${id}`)
    }
  }, [id, reference, x, y])

  const board = document.getElementById('board') as HTMLDivElement
  const minWidth = board?.clientWidth * 0.1
  const props = { enableResizing: !whiteboardIsLocked ? undefined : !whiteboardIsLocked }

  return reference ? (
    <DraggableComponent
      {...props}
      ref={(c: Rnd | null) => (reference.current = c)}
      style={{
        zIndex,
      }}
      minWidth={`${minWidth}px`}
      className={classnames({
        selected,
        isTouchDevice,
        multiselectEnabled,
        image: true,
        whiteboardIsLocked,
      })}
      cancel={!selected ? '.wrapper' : ''}
      resizeHandleWrapperClass="cursor-pointer"
      onMouseMove={handleMouseMove}
      onDrag={(e: DraggableEvent, data: DraggableData) =>
        !whiteboardIsLocked && !multiselectEnabled && handleDrag(e, data)
      }
      onResizeStart={() => {
        const resizing = searchParams.get('resizing')

        if (!resizing) {
          searchParams.set('resizing', 'true')
          setSearchParams(searchParams, { replace: true })
        }

        handleResizeStart()
      }}
      onResizeStop={() => {
        handleResizeStop()
        setTimeout(() => {
          searchParams.delete('resizing')
          setSearchParams(searchParams, { replace: true })
        }, 200)
      }}
      onResize={(e: MouseEvent | TouchEvent, dir: ResizeDirection) => {
        handleResize(dir)
        /*        setCurrentWidth(convertPxToPercentage(elementRef.style.width))
        setCurrentHeight(convertPxToPercentage(elementRef.style.height))*/
      }}
      onDragStop={() => {
        if (whiteboardIsLocked) return
        handleDragStop()
        updatePosition()
      }}
      bounds="parent"
      lockAspectRatio
      default={{
        x: convertPercentageToPx(x),
        y: convertPercentageToPx(y),
        width: convertPercentageToPx(width),
      }}
    >
      <Wrapper
        className="wrapper"
        onClick={() => !isTouchDevice && handleClick()}
        onTouchEnd={() => handleClick()}
      >
        <Image draggable={false} src={image.value} alt="" />
      </Wrapper>
    </DraggableComponent>
  ) : null
}

export default DraggableImage
