import React, { SVGProps } from 'react'

const SearchIcon: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 24"
      height="24"
      width="25"
      fill="currentColor"
      {...props}
    >
      <path d="m17.842 16.898-4.526-4.526a.416.416 0 0 0-.298-.123h-.362A7.297 7.297 0 0 0 7.298 0 7.297 7.297 0 0 0 0 7.3a7.297 7.297 0 0 0 12.25 5.36v.359c0 .112.045.217.122.298l4.526 4.526a.421.421 0 0 0 .597 0l.347-.347a.421.421 0 0 0 0-.597zM7.298 13.474a6.171 6.171 0 0 1-6.175-6.176 6.171 6.171 0 0 1 6.175-6.175 6.171 6.171 0 0 1 6.176 6.175 6.171 6.171 0 0 1-6.176 6.176z" />
    </svg>
  )
}

export default SearchIcon
