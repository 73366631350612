import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'

import { activeDoorsSelector } from '../../../../store/customer/selectors'
import {
  breakpoints,
  breakpointsCross,
  getFluidSizeWithFullFormula as gfs,
  palette,
  pxToRem,
} from '../../../../style/theme'
import AfaTabSwitcher from '../../../../styleguide/AfaTabSwitcher'
import SizeRows from '../../../AfaPdp/SizeRows'
import { useSearchParams } from '../../../../hooks/useSearchParams'
import { sortDoorsList } from '../../../../libs/doors'

const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: max-content 1fr;
  overflow: hidden;

  @media (min-width: ${breakpoints.L}) {
    & > div:first-child {
      order: 2;
    }
    grid-auto-rows: 1fr max-content;
  }
`

const Drops = styled.div`
  padding: 2.5rem clamp(20px, ${gfs('px', 20, 64, 1366, 3840)}, 64px);
  background-color: ${palette.white};
  border: 1px solid ${palette.mischka};
  border-radius: ${pxToRem(4)}rem ${pxToRem(4)}rem 0 0;
  overflow-y: auto;

  @media (max-width: ${breakpointsCross.L.max}) {
    flex-direction: column;
    border-radius: 0 0 ${pxToRem(4)}rem ${pxToRem(4)}rem;
  }
`

type Props = {
  className?: string
  cartIsLoadingUpdates?: boolean
}

const WBColorDoorsAndDrops: React.FC<Props> = ({ className, cartIsLoadingUpdates }) => {
  const activeDoors = useSelector(activeDoorsSelector)
  const [selectedDoorPosition, setSelectedDoorPosition] = useState(-1)

  const [searchParams, setSearchParams] = useSearchParams()
  const selectedDoorId = searchParams.get('doorId') || ''

  const setSelectedDoorId = useCallback(
    (doorId: string) => {
      searchParams.set('doorId', doorId)
      setSearchParams(searchParams, { replace: true })
    },
    [searchParams, setSearchParams],
  )

  const sortedTabs = sortDoorsList(
    activeDoors.map(door => ({ ...door, units: 0 })),
    { key: 'id', ascending: true },
  )

  useEffect(() => {
    if (!selectedDoorId && activeDoors.length > 0) {
      setSelectedDoorId(activeDoors.find(({ mainDoor }) => mainDoor)?.id || activeDoors[0].id)
    }

    if (selectedDoorId && sortedTabs.find(door => door.id === selectedDoorId)) {
      const oldSelectedIndex = sortedTabs.findIndex(door => door.id === selectedDoorId)
      setSelectedDoorPosition(oldSelectedIndex)
    }

    if (
      selectedDoorId &&
      !sortedTabs.find(door => door.id === selectedDoorId) &&
      sortedTabs[selectedDoorPosition]
    ) {
      setSelectedDoorId(sortedTabs[selectedDoorPosition].id)
    }
  }, [activeDoors, selectedDoorId, selectedDoorPosition, setSelectedDoorId, sortedTabs])

  return (
    <Wrapper className={className}>
      {selectedDoorId && (
        <AfaTabSwitcher
          selectedTabId={selectedDoorId}
          setSelectedTabId={setSelectedDoorId}
          tabs={sortedTabs.map(door => ({ id: door.id, label: door.name }))}
          cartIsLoadingUpdates={cartIsLoadingUpdates}
        />
      )}

      <Drops>
        <SizeRows cartIsLoadingUpdates={cartIsLoadingUpdates} selectedDoorId={selectedDoorId} />
      </Drops>
    </Wrapper>
  )
}

export default WBColorDoorsAndDrops
