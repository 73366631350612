import React, { SVGProps } from 'react'

const CartIcon: React.FC<SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 44 44" fill="currentColor">
    <path d="M38.36 21.938H13.083l-2.75-14.625h31.08L38.36 21.938zm-2.916 14.625A2.444 2.444 0 0133 34.124a2.444 2.444 0 012.444-2.438 2.444 2.444 0 012.445 2.438 2.444 2.444 0 01-2.445 2.438zm-17.11-2.438a2.444 2.444 0 01-2.445 2.438 2.444 2.444 0 01-2.445-2.438 2.444 2.444 0 012.445-2.438 2.444 2.444 0 012.444 2.438zm23.832-29.25H9.876l-.637-3.384A1.832 1.832 0 007.437 0H.917A.915.915 0 000 .914v.61c0 .504.41.913.917.913H6.93l5.342 28.408A4.85 4.85 0 0011 34.125 4.882 4.882 0 0015.889 39c2.7 0 4.889-2.183 4.889-4.875a4.84 4.84 0 00-.656-2.438h11.09a4.84 4.84 0 00-.656 2.438A4.882 4.882 0 0035.444 39c2.7 0 4.89-2.183 4.89-4.875 0-1.37-.569-2.608-1.482-3.494a1.833 1.833 0 00-1.778-1.381H14.459l-.917-4.875h25.314c.868 0 1.618-.608 1.795-1.455l3.31-15.844a1.83 1.83 0 00-1.795-2.201z" />
    <path d="M38.36 24.438H13.083l-2.75-14.625h31.08zm-2.916 14.625A2.444 2.444 0 0133 36.624a2.444 2.444 0 012.444-2.438 2.444 2.444 0 012.445 2.438 2.444 2.444 0 01-2.445 2.438zm-17.11-2.438a2.444 2.444 0 01-2.445 2.438 2.444 2.444 0 01-2.445-2.438 2.444 2.444 0 012.445-2.438 2.444 2.444 0 012.444 2.438zm23.832-29.25H9.876l-.637-3.384A1.832 1.832 0 007.437 2.5H.917A.915.915 0 000 3.414v.61c0 .504.41.914.917.914H6.93l5.342 28.407A4.85 4.85 0 0011 36.625a4.882 4.882 0 004.889 4.875c2.7 0 4.889-2.183 4.889-4.875a4.84 4.84 0 00-.656-2.438h11.09a4.84 4.84 0 00-.656 2.438 4.882 4.882 0 004.888 4.875c2.7 0 4.89-2.183 4.89-4.875 0-1.37-.569-2.608-1.482-3.494a1.833 1.833 0 00-1.778-1.381H14.459l-.917-4.875h25.314c.868 0 1.618-.608 1.795-1.455l3.31-15.844a1.83 1.83 0 00-1.795-2.201z" />
  </svg>
)

export default CartIcon
