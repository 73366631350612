import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import AfaFooter from '../../components/AfaFooter'
import AfaSearch from '../../components/AfaSearch/AfaSearch'
import useFetchInitialData from '../../hooks/useFetchInitialData'
import AfaCartBrands from './AfaCartBrands'
import AfaCartContent from './AfaCartContent'
import AfaCartHeader from './AfaCartHeader'
import AfaCartSummary from './AfaCartSummary/AfaCartSummary'
import AfaModal from '../../components/AfaModal'
import {
  cartAdjustAllModalOpenSelector,
  duplicateCartOnDoorModalOpenSelector,
  shareCartOnDoorModalOpenSelector,
} from '../../store/afa/selectors'
import {
  afaCartApi,
  TAG_CART,
  useGetAfaCartQuery,
  useGetSelectedDelivery,
  useUpdateAfaCartProductsMutation,
} from '../../services/afaCart'
import afaActions from '../../store/afa/actions'
import { useSearchParams } from '../../hooks/useSearchParams'
import AfaCancellationDateModal from '../../components/AfaCancellationDateModal/AfaCancellationDateModal'
import AfaDuplicateCartOnDoorsModal from './AfaDuplicateCart/AfaDuplicateCartOnDoorsModal'
import AfaCartAdjustAll from './AfaCartAdjustAll/AfaCartAdjustAll'
import AfaShareCart from './AfaShareCart/AfaShareCart'
import AfaCartAdjust from './AfaCartAdjust'
import AfaCartFilterButton from './AfaCartFilterButton'
import AfaCartFilters from './AfaCartFilters'
import { errorNotification, successNotification } from '../../components/Notification/notifications'
import useAfaGetOutOfAssortmentCartProducts from '../../hooks/useAfaGetOutOfAssortmentCartProducts'
import afaCartOutOfAssortmentActions from '../../store/afaCartOutOfAssortment/actions'
import RemoveSelectedFromCart from '../../components/RemoveSelectedFromCart/RemoveSelectedFromCart'
import { afaCartOutOfAssortmentIsOpenSelector } from '../../store/afaCartOutOfAssortment/selectors'
import { afaCartAdjustSelectors } from '../../store/afaCartAdjust/selectors'
import { afaCartSelectors } from '../../store/afaCart/selectors'
import afaCartActions from '../../store/afaCart/actions'
import { AfaCartSubmitButton } from './AfaCartSubmitButton'
import { useAfaCheckCartNotifications } from '../../hooks/useAfaCheckCartNotifications'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Main = styled.main`
  flex: 1;
  display: flex;
  overflow: hidden;
  position: relative;
`

const AfaModalShare = styled(AfaModal)`
  > div {
    overflow: visible;
  }
`

const AfaCart: React.FC = () => {
  useFetchInitialData({ loadCustomer: true })

  const { t } = useTranslation()

  const openOutOfAssortmentModal = useSelector(afaCartOutOfAssortmentIsOpenSelector)
  const duplicateCartOnDoorModalOpen = useSelector(duplicateCartOnDoorModalOpenSelector)
  const isCartAdjustOpen = useSelector(afaCartAdjustSelectors.isOpen)
  const checkAdjustCartOnDoorModalOpen = useSelector(cartAdjustAllModalOpenSelector)
  const shareCartOnDoorModalOpen = useSelector(shareCartOnDoorModalOpenSelector)
  const isCancellationDateDialogOpen = useSelector(afaCartSelectors.isCancellationDateDialogOpen)
  const { resetCartNotifications } = useAfaCheckCartNotifications()

  const [updateCartProducts, updateAfaCartProductResult] = useUpdateAfaCartProductsMutation(
    'remove-out-of-assortment-products',
  )

  const outOfAssortmentCartProducts = useAfaGetOutOfAssortmentCartProducts()

  const getCartQuery = useGetAfaCartQuery()
  const cartProducts = useMemo(() => {
    return getCartQuery.data?.items || []
  }, [getCartQuery.data?.items])

  const dispatch = useDispatch()

  const [searchParams, setSearchParams] = useSearchParams()
  const selectedDelivery = searchParams.get('delivery') || ''

  const setSelectedDelivery = useCallback(
    (delivery: string) => {
      searchParams.set('delivery', delivery)
      setSearchParams(searchParams, { replace: true })
    },
    [setSearchParams, searchParams],
  )

  const selectedDeliveryDetails = useGetSelectedDelivery()

  useEffect(() => {
    if (!selectedDelivery && selectedDeliveryDetails) {
      setSelectedDelivery(selectedDeliveryDetails.deliveryDate)
    }
  }, [selectedDelivery, setSelectedDelivery, selectedDeliveryDetails])

  useEffect(() => {
    if (updateAfaCartProductResult.isError) {
      errorNotification({
        message: t('Afa.errorTryingUpdateCart'),
      })
    } else if (
      updateAfaCartProductResult.data?.warnings &&
      updateAfaCartProductResult.data?.warnings.length > 0
    ) {
      updateAfaCartProductResult.data?.warnings.forEach(message => {
        errorNotification({
          message: message.faultmessage,
        })
      })
    } else if (updateAfaCartProductResult.isSuccess && updateAfaCartProductResult.data?.result) {
      successNotification({
        message: t('Afa.Cart.Adjust.Success'),
      })
    }
  }, [updateAfaCartProductResult, t])

  useEffect(() => {
    if (updateAfaCartProductResult.isSuccess) {
      dispatch(afaCartOutOfAssortmentActions.setIsOpen(false))
      updateAfaCartProductResult.reset()
    }
  }, [dispatch, updateAfaCartProductResult, updateAfaCartProductResult.isSuccess])

  const removeAllItems = () => {
    updateCartProducts(
      outOfAssortmentCartProducts.map(cartProduct => ({ ...cartProduct, quantity: 0 })),
    )
  }

  const onCloseDuplicate = () => {
    duplicateCartOnDoorModalOpen && dispatch(afaActions.toggleDuplicateCartOnDoorModalOpen())
  }

  useEffect(() => {
    dispatch(afaCartApi.util.invalidateTags([TAG_CART]))
  }, [dispatch])

  const allKeys = useMemo(() => {
    return [...new Set(cartProducts.map(({ key }) => key))]
  }, [cartProducts])

  useEffect(() => {
    dispatch(afaActions.setCartAdjustAllKeys(allKeys))
  }, [allKeys, dispatch])

  useEffect(() => {
    return () => {
      resetCartNotifications()
    }
  }, [resetCartNotifications])

  return isCartAdjustOpen ? (
    <AfaCartAdjust />
  ) : (
    <Wrapper>
      <AfaSearch />

      <AfaCartHeader />

      <Main>
        <AfaCartFilters />
        <AfaCartBrands />
        <AfaCartContent />
        <AfaCartSummary />
      </Main>

      <AfaFooter
        leftColContent={<AfaCartFilterButton />}
        centralColContent={<AfaCartSubmitButton cartProducts={cartProducts} />}
      />

      <AfaModal isOpen={duplicateCartOnDoorModalOpen} onRequestClose={() => onCloseDuplicate()}>
        <AfaDuplicateCartOnDoorsModal onClose={() => onCloseDuplicate()} />
      </AfaModal>

      <AfaModal
        isOpen={checkAdjustCartOnDoorModalOpen}
        onRequestClose={() => {
          dispatch(afaActions.setCartAdjustAllKeys(allKeys))
          dispatch(afaActions.toggleCartAdjustAllModalOpen())
        }}
        fullscreen={false}
      >
        <AfaCartAdjustAll />
      </AfaModal>

      <AfaModalShare
        isOpen={shareCartOnDoorModalOpen}
        onRequestClose={() => {
          dispatch(afaActions.toggleShareCartOnDoorModalOpen())
        }}
        fullscreen={false}
      >
        <AfaShareCart onClose={() => dispatch(afaActions.toggleShareCartOnDoorModalOpen())} />
      </AfaModalShare>

      <AfaModal
        isOpen={isCancellationDateDialogOpen}
        onRequestClose={() => {
          dispatch(afaCartActions.setCancellationDateDialogOpen(false))
        }}
      >
        <AfaCancellationDateModal
          onCancel={() => {
            dispatch(afaCartActions.setCancellationDateDialogOpen(false))
          }}
        />
      </AfaModal>

      <AfaModal
        isOpen={openOutOfAssortmentModal}
        onRequestClose={() => dispatch(afaCartOutOfAssortmentActions.setIsOpen(false))}
      >
        <RemoveSelectedFromCart
          onCancel={() => dispatch(afaCartOutOfAssortmentActions.setIsOpen(false))}
          onRemoveItems={removeAllItems}
          title={t('Afa.Cart.Remove')}
          confirmText={t('Afa.Cart.RemoveOutOfAssortmentConfirm')}
          cartProducts={outOfAssortmentCartProducts}
          updateCartIsLoading={updateAfaCartProductResult.isLoading}
        />
      </AfaModal>
    </Wrapper>
  )
}

export default AfaCart
