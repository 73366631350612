import { createSelector } from '@reduxjs/toolkit'

import { isNotUndefined } from '../../../libs/utils'
import { activePlpBrandCodesSelector, brandsSelector } from '../../brands/selectors'
import { cartModeSelector, starsCartBrandSelector } from '../../stars/selectors'
import { activeBrandCodeSelector, filteredCartProductsSelector } from './cart'

export const brandCodesInCartSelector = createSelector(
  filteredCartProductsSelector,
  starsCartBrandSelector,
  brandsSelector,
  cartModeSelector,
  (productsInCart, starsCartBrands, brands, cartModeSelector): string[] =>
    cartModeSelector === 'units'
      ? [...new Set(productsInCart.map(({ brandCode }) => brandCode))]
          .map(brandCode => brands.find(({ code }) => code === brandCode))
          .filter(isNotUndefined)
          .sort((b1, b2) => (b1.brand > b2.brand ? 1 : -1))
          .map(({ code }) => code)
      : starsCartBrands
          ?.sort((b1, b2) => (b1.brand.brand > b2.brand.brand ? 1 : -1))
          .map(({ brand }) => brand.code) || [],
)

export const selectCartDefaultActiveBrand = createSelector(
  activeBrandCodeSelector,
  activePlpBrandCodesSelector,
  brandCodesInCartSelector,
  (cartActiveBrandCode, plpActiveBrands, brandCodesInCart) => {
    const plpActiveBrandInCart =
      plpActiveBrands && brandCodesInCart.some(code => plpActiveBrands.includes(code))
        ? plpActiveBrands[0]
        : null
    return cartActiveBrandCode || plpActiveBrandInCart || brandCodesInCart[0] || null
  },
)
