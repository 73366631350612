import { createSelector } from '@reduxjs/toolkit'

import { calculateStarsAnalytics } from '../../libs/analytics'
import { createBrandsQueryParams, sortBrands } from '../../libs/brand'
import { getBrandsFromAssortment, getBrandsFromCartItems } from '../../libs/cart'
import { matchFilters } from '../../libs/filters'
import {
  clustersFilteredSelector_cb,
  getOutUpcsOfCurrentModule,
  getStarsProductDoorsIds,
  isStarsProductEligible,
  isUpcIn,
  isUpcNeitherInNorOut,
  sortBySkyCodeCb,
  starsModulesToShowSelector_cb,
} from '../../libs/stars'
import { isPageMatches } from '../../libs/url'
import { isNotUndefined } from '../../libs/utils'
import { CartFilters } from '../../model/filters'
import { BrandCode, ModelCode, RootState } from '../../model/model'
import { Product } from '../../model/product'
import {
  Module,
  StarProduct,
  StarsAssortment,
  StarsAssortmentByBrand,
  StarsInOutModuleCount,
} from '../../model/stars'
import { isKidCategoryModeEnabledSelector } from '../app/selectors'
import {
  activePlpBrandCodesSelector,
  brandsSelector,
  openedBrandsSelector,
} from '../brands/selectors'
import {
  activeBrandCodeSelector,
  activeBrandSelector,
  allBrandCodesMapSelector,
  mocosInCart,
} from '../cart/selectors'
import { activeDoorsIdsSelector } from '../customer/selectors'
import {
  activeCartFiltersSelector,
  isPlpFilteredSelector,
  plpFiltersCategorySelector,
} from '../filters/selectors'

const nonFilteredStarsAssortmentSelector = (state: RootState) => state.stars.assortment

export const filteredStarsAssortmentSelector = (state: RootState) => state.stars.filteredAssortment

export const starsAssortmentSelector = createSelector(
  isPlpFilteredSelector,
  nonFilteredStarsAssortmentSelector,
  filteredStarsAssortmentSelector,
  () => isPageMatches('cart'),
  () => isPageMatches('products'),
  (isPlpFiltered, nonFilteredAssortment, filteredAssortment, isCartPage, isSearchPage) => {
    return (isPlpFiltered && !isCartPage) || isSearchPage
      ? filteredAssortment
      : nonFilteredAssortment
  },
)

export const starsAssortmentByOpenedBrandSelector = createSelector(
  openedBrandsSelector,
  starsAssortmentSelector,
  isKidCategoryModeEnabledSelector,
  () => isPageMatches('products'),
  (openedBrands, starsAssortment, isKidCategoryModeEnabled, isSearchPage) => {
    const brandGroups = createBrandsQueryParams(openedBrands, isKidCategoryModeEnabled)
    const allBrandsInAssortment = Object.keys(starsAssortment)
    const brands = isSearchPage
      ? Object.keys(starsAssortment)
      : brandGroups?.length
      ? brandGroups
      : allBrandsInAssortment
    const assortmentByBrandGroup = brands.reduce(
      (res, brandCode) => {
        const assortment = starsAssortment[brandCode]
        if (!assortment) {
          return res
        }
        res.inOut = res.inOut.concat(assortment.inOut)
        res.families = res.families.concat(assortment.families)
        res.clusters = res.clusters.concat(assortment.clusters)
        res.upcs = res.upcs.concat(assortment.upcs)
        res.productDetails = { ...res.productDetails, ...assortment.productDetails }
        return res
      },
      {
        inOut: [],
        families: [],
        clusters: [],
        upcs: [],
        productDetails: {},
      } as StarsAssortmentByBrand,
    )
    return assortmentByBrandGroup
  },
)

export const starsAssortmentFamilySelector = (familyName: string) =>
  createSelector(
    starsAssortmentByOpenedBrandSelector,
    starsAssortment =>
      starsAssortment && starsAssortment?.families.find(family => family.name === familyName),
  )

export const selectedMonthSelector = (state: RootState) => state.stars.selectedMonth

export const selectedClustersIdsSelector = (state: RootState) => state.stars.selectedClustersIds

export const starsShowInOutSelector = (state: RootState) => state.stars.showInOut

export const cartModeSelector = (state: RootState) => state.stars.mode

export const starsUpcsByActiveCartBrandSelector = createSelector(
  brandsSelector,
  starsAssortmentSelector,
  activeBrandCodeSelector,
  (brands, starsAssortment, activeBrand) => {
    const currentSelectedBrand = brands.find(b => b.code === activeBrand)
    const brandUpcs =
      currentSelectedBrand?.group?.flatMap(brandCode => starsAssortment[brandCode]?.upcs) || []

    return brandUpcs.filter(isNotUndefined)
  },
)

export const starsMocosByActiveCartBrandSelecor = createSelector(
  starsAssortmentSelector,
  starsUpcsByActiveCartBrandSelector,
  (starsAssortment, starsUpcs) => {
    return starsUpcs
      .map(({ modelCode, colorCode, brandCode }) =>
        brandCode
          ? starsAssortment[brandCode].productDetails[modelCode].mocos[colorCode]
          : undefined,
      )
      .filter(isNotUndefined)
  },
)

export const starsBrandsSelector = createSelector(
  brandsSelector,
  starsAssortmentSelector,
  (brands, stars) => {
    if (stars) {
      const cartBrands = getBrandsFromAssortment(stars, brands)
      return sortBrands(Object.values(cartBrands))
    }
  },
)

export const starsModelSelector = (
  modelCode: ModelCode,
  brandCode: BrandCode,
  isSearch: boolean,
) => (state: RootState): Product | undefined => {
  const brandStarsAssortment = isSearch
    ? state.stars.filteredAssortment[brandCode]
    : state.stars.assortment[brandCode]
  return brandStarsAssortment?.productDetails[modelCode]
}

export const starsAllProductsDetailsSelector = createSelector(
  starsAssortmentSelector,
  starsAssortment => {
    return Object.values(starsAssortment)
      .flatMap(({ productDetails }) => Object.values(productDetails))
      .reduce((result, product) => {
        result[product.modelCode] = product
        return result
      }, {} as Record<string, Product>)
  },
)

export const starsAllModulesSelector = createSelector(starsAssortmentSelector, starsAssortment => {
  return Object.values(starsAssortment).flatMap(({ families }) =>
    families.flatMap(({ modules }) => modules),
  )
})

export const starsAllClustersSelector = createSelector(starsAssortmentSelector, starsAssortment => {
  return Object.values(starsAssortment).flatMap(({ clusters }) => clusters)
})

export const starsAllStarProductsSelector = createSelector(
  starsAssortmentSelector,
  starsAssortment => {
    return Object.values(starsAssortment).flatMap(({ upcs }) => upcs)
  },
)

const starsProductMatchCriteria = (
  starsAssortment: StarsAssortment,
  allBrandsCodeMap: Record<string, boolean>,
  activeDoorsIds: string[],
  starsProductsDetails: Record<string, Product>,
  filters: CartFilters,
  selectedMonth: string,
) => (starsProduct: StarProduct) => {
  const matchBrand = starsProduct.brandCode && allBrandsCodeMap[starsProduct.brandCode]

  const matchMonth = selectedMonth ? starsProduct.month === selectedMonth : true

  const productDoorsIds = getStarsProductDoorsIds(starsAssortment, starsProduct)
  const matchDoors = productDoorsIds.some(starsDoorId => activeDoorsIds.includes(starsDoorId))

  return (
    matchBrand &&
    matchMonth &&
    matchDoors &&
    matchFilters(filters, starsProductsDetails, starsProduct)
  )
}

function filteredStarsProductsSelector_cb(
  starsAssortment: StarsAssortment,
  starsProducts: StarProduct[],
  starsProductsDetails: Record<string, Product>,
  activeFilters: CartFilters,
  activeDoorsIds: string[],
  allBrandsCodeMap: Record<string, boolean>,
  selectedMonth: string,
) {
  return starsProducts.filter(
    starsProductMatchCriteria(
      starsAssortment,
      allBrandsCodeMap,
      activeDoorsIds,
      starsProductsDetails,
      activeFilters,
      selectedMonth,
    ),
  )
}

export const filteredStarsProductsSelector = createSelector(
  starsAssortmentSelector,
  starsUpcsByActiveCartBrandSelector,
  starsAllProductsDetailsSelector,
  activeCartFiltersSelector,
  activeDoorsIdsSelector,
  allBrandCodesMapSelector,
  selectedMonthSelector,
  filteredStarsProductsSelector_cb,
)

export const starsMocosFilteredSelecor = createSelector(
  filteredStarsProductsSelector,
  starsAllProductsDetailsSelector,
  brandsSelector,
  (filteredStarsProducts, starsProductsDetails, brands) => {
    return mocosInCart(filteredStarsProducts, starsProductsDetails, brands)
  },
)

export const starsAnalyticsCartSelector = createSelector(
  starsAllStarProductsSelector,
  starsAllProductsDetailsSelector,
  starsAllModulesSelector,
  starsAllClustersSelector,
  activeDoorsIdsSelector,
  selectedMonthSelector,
  (
    allStarsProducts,
    starsProductDetails,
    allModules,
    allClusters,
    activeDoorsIds,
    selectedMonth,
  ) => {
    return calculateStarsAnalytics(
      allStarsProducts.filter(({ month }) => month === selectedMonth),
      starsProductDetails,
      allModules.filter(({ month }) => month === selectedMonth),
      allClusters,
      activeDoorsIds,
    )
  },
)

export const clustersSelector = createSelector(
  activePlpBrandCodesSelector,
  starsAssortmentSelector,
  (openedBrandCodes, starsAssortment) => {
    return (openedBrandCodes && starsAssortment[openedBrandCodes[0]].clusters) || []
  },
)

export const clustersFilteredSelector = createSelector(
  starsAssortmentByOpenedBrandSelector,
  activeDoorsIdsSelector,
  selectedMonthSelector,
  plpFiltersCategorySelector,
  clustersFilteredSelector_cb,
)

export const plpMonthsSelector = createSelector(
  starsAssortmentByOpenedBrandSelector,
  plpFiltersCategorySelector,
  (starsAssortment, selectedCategory) => {
    const families = starsAssortment?.families || []

    const modules = families.flatMap(({ modules }) => modules)

    const months = modules
      .filter(({ categoryId }) => categoryId === selectedCategory)
      .map(({ month }) => month)

    return [...new Set(months)]
  },
)

export const cartMonthsSelector = createSelector(
  starsAssortmentSelector,
  starsUpcsByActiveCartBrandSelector,
  starsAllProductsDetailsSelector,
  activeCartFiltersSelector,
  activeDoorsIdsSelector,
  allBrandCodesMapSelector,
  (
    starsAssortment,
    starsProducts,
    starsProductsDetails,
    activeFilters,
    activeDoorsIds,
    allBrandsCodeMap,
  ) => {
    const starsProductsFiltered = filteredStarsProductsSelector_cb(
      starsAssortment,
      starsProducts,
      starsProductsDetails,
      activeFilters,
      activeDoorsIds,
      allBrandsCodeMap,
      '',
    )

    const months = starsProductsFiltered.map(({ month }) => month)

    return [...new Set(months)]
  },
)

export const monthsSelector = createSelector(
  plpMonthsSelector,
  cartMonthsSelector,
  () => isPageMatches('cart'),
  (plpMonths, cartMonths, isCartPage) => {
    return isCartPage ? cartMonths : plpMonths
  },
)

export const starsModulesToShowSelector = createSelector(
  starsAssortmentByOpenedBrandSelector,
  selectedMonthSelector,
  selectedClustersIdsSelector,
  plpFiltersCategorySelector,
  activeDoorsIdsSelector,
  starsModulesToShowSelector_cb,
)

export const filteredInOutDataSelector = createSelector(
  starsAssortmentByOpenedBrandSelector,
  selectedMonthSelector,
  selectedClustersIdsSelector,
  clustersFilteredSelector,
  (starsAssortment, selectedMonth, selectedClustersIds, clustersFiltered) => {
    const clustersFilteredId = clustersFiltered.map(cluster => cluster.id)
    const inOutData =
      starsAssortment?.inOut.filter(
        ({ clusterId, month }) =>
          month === selectedMonth &&
          ((selectedClustersIds.length === 0 && clustersFilteredId.includes(clusterId)) ||
            selectedClustersIds.includes(clusterId)),
      ) || []

    return inOutData
  },
)

export const inOutDataFilteredByInOutButtonSelector = createSelector(
  filteredInOutDataSelector,
  starsShowInOutSelector,
  (filteredInOutData, showInOut) => {
    return showInOut ? filteredInOutData : []
  },
)

export const starsAssormentModuleProductsSelector = (module: Module) =>
  createSelector(
    starsAssortmentByOpenedBrandSelector,
    inOutDataFilteredByInOutButtonSelector,
    (starsAssortment, inOutData) => {
      const productsOut = starsAssortment
        ? getOutUpcsOfCurrentModule(inOutData, module, starsAssortment)
            .map(upc => starsAssortment.upcs.find(starsProduct => starsProduct.upc === upc))
            .filter(isNotUndefined)
            .sort(sortBySkyCodeCb)
        : []

      const allProducts = (
        (!!starsAssortment &&
          module?.productsUpcs.map(upc =>
            starsAssortment.upcs.find(starsProduct => starsProduct.upc === upc),
          )) ||
        []
      ).filter(isNotUndefined)

      const productsIn = allProducts
        .filter(product => isUpcIn(inOutData, product.upc))
        .sort(sortBySkyCodeCb)

      const products = allProducts
        .filter(product => isUpcNeitherInNorOut(inOutData, product.upc))
        .sort(sortBySkyCodeCb)

      return {
        productsIn,
        productsOut,
        products,
      }
    },
  )

export const starsModulesProductsCountSelector = createSelector(
  starsAssortmentByOpenedBrandSelector,
  inOutDataFilteredByInOutButtonSelector,
  starsModulesToShowSelector,
  (starsAssortment, inOutData, modules) => {
    if (!starsAssortment) {
      return
    }

    const modulesProductsCount = modules.reduce((result, module) => {
      const count = {
        products: 0,
        productsIn: 0,
        productsOut: getOutUpcsOfCurrentModule(inOutData, module, starsAssortment).length,
      }
      module.productsUpcs.forEach(upc => {
        if (isUpcIn(inOutData, upc)) {
          count.productsIn += 1
        } else if (isUpcNeitherInNorOut(inOutData, upc)) {
          count.products += 1
        }
      })
      result[module.id] = count
      return result
    }, {} as StarsInOutModuleCount)

    return modulesProductsCount
  },
)

export const starsProductsCountSelector = createSelector(
  starsModulesProductsCountSelector,
  modulesProductsCount => {
    if (modulesProductsCount) {
      const productsCount = Object.values(modulesProductsCount).reduce(
        (count, module) => count + module.products + module.productsIn + module.productsOut,
        0,
      )
      return productsCount
    }
    return 0
  },
)

export const starsEnableMonthButtonSelector = createSelector(
  starsAssortmentByOpenedBrandSelector,
  plpFiltersCategorySelector,
  activeDoorsIdsSelector,
  (starsAssortment, category, selectedDoorsIds) => {
    const clusters = starsAssortment?.clusters
      .filter(({ categoryId, doorsIds }) => {
        return categoryId === category && doorsIds.some(doorId => selectedDoorsIds.includes(doorId))
      })
      .map(({ id }) => id)
    const families = starsAssortment?.families.some(({ modules }) => {
      return modules.some(({ clustersIds, categoryId }) => {
        return (
          categoryId === categoryId && clustersIds.some(clustedId => clusters?.includes(clustedId))
        )
      })
    })
    return families
  },
)

export const starsAllCartFilteredProductsSelector = createSelector(
  starsAssortmentSelector,
  starsAllStarProductsSelector,
  starsAllProductsDetailsSelector,
  activeCartFiltersSelector,
  activeDoorsIdsSelector,
  allBrandCodesMapSelector,
  selectedMonthSelector,
  filteredStarsProductsSelector_cb,
)

export const starsCartBrandSelector = createSelector(
  starsAllCartFilteredProductsSelector,
  starsBrandsSelector,
  starsAllProductsDetailsSelector,
  (products, brands, assortment) => {
    if (brands) {
      const filteredBrands = getBrandsFromCartItems(
        products,
        brands.map(brand => brand.brand),
        assortment,
      )
      return sortBrands(Object.values(filteredBrands))
    }
  },
)

export const onlyOneClusterIsActiveSelector = createSelector(
  clustersFilteredSelector,
  selectedClustersIdsSelector,
  (clustersFiltered, selectedClustersIds) =>
    selectedClustersIds.length === 1 || clustersFiltered.length === 1, // one cluster is selected or there is only one possible selection
)

export const starsMocosSelector = createSelector(
  starsAllProductsDetailsSelector,
  productDetails => {
    const mocos = Object.values(productDetails).flatMap(product => Object.values(product.mocos))
    return mocos
  },
)

export const starsMocosByActiveDoorsAndSelectedMonthSelector = (filterEligible: boolean) =>
  createSelector(
    starsAllStarProductsSelector,
    activeDoorsIdsSelector,
    selectedMonthSelector,
    starsAssortmentSelector,
    (starsProducts, activeDoorsIds, selectedMonth, starsAssortment) => {
      const starsProductsByActiveDoors = starsProducts.filter(starsProduct => {
        const productDoorsIds = getStarsProductDoorsIds(starsAssortment, starsProduct)
        const isEligible = isStarsProductEligible(starsAssortment, starsProduct)

        const matchDoors = productDoorsIds.some(starsDoorsId =>
          activeDoorsIds.includes(starsDoorsId),
        )

        const matchMonth = starsProduct.month === selectedMonth

        return matchDoors && matchMonth && (!filterEligible || (filterEligible && !isEligible))
      })

      const starsMocosByActiveDoors = starsProductsByActiveDoors.map(
        ({ brandCode, modelCode, colorCode }) =>
          starsAssortment[brandCode].productDetails[modelCode].mocos[colorCode],
      )

      return starsMocosByActiveDoors
    },
  )

export const starsAnalyticsPlpSelector = createSelector(
  starsAssortmentByOpenedBrandSelector,
  starsModulesToShowSelector,
  starsAllProductsDetailsSelector,
  selectedClustersIdsSelector,
  activeDoorsIdsSelector,
  selectedMonthSelector,
  (
    starsAssortmentByOpendBrand,
    modulesToShow,
    starsProductDetails,
    selectedClustersIds,
    activeDoorsIds,
    selectedMonth,
  ) => {
    const upcsShown = modulesToShow.flatMap(({ productsUpcs }) => productsUpcs)
    const starsProductsShown = (starsAssortmentByOpendBrand?.upcs || []).filter(
      ({ upc, month }) => month === selectedMonth && upcsShown.includes(upc),
    )

    const clustersByOpenedBrand = starsAssortmentByOpendBrand?.clusters || []
    return calculateStarsAnalytics(
      starsProductsShown,
      starsProductDetails,
      modulesToShow,
      selectedClustersIds.length
        ? clustersByOpenedBrand.filter(({ id }) => selectedClustersIds.includes(id))
        : clustersByOpenedBrand,
      activeDoorsIds,
    )
  },
)

export const nonFilteredStarsAssortmentLoadedBrandCodesSelector = (state: RootState) =>
  state.stars.loadedBrandCodes

export const filteredStarsAssortmentLoadedBrandCodesSelector = (state: RootState) =>
  state.stars.loadedFilteredBrandCodes

export const starsAssortmentLoadedBrandCodesSelector = createSelector(
  isPlpFilteredSelector,
  nonFilteredStarsAssortmentLoadedBrandCodesSelector,
  filteredStarsAssortmentLoadedBrandCodesSelector,
  () => isPageMatches('cart'),
  () => isPageMatches('products'),
  (
    isPlpFiltered,
    nonFilteredStarsAssortmentLoadedBrandCodes,
    filteredStarsAssortmentLoadedBrandCodes,
    isCartPage,
    isSearchPage,
  ) => {
    return (isPlpFiltered && !isCartPage) || isSearchPage
      ? filteredStarsAssortmentLoadedBrandCodes
      : nonFilteredStarsAssortmentLoadedBrandCodes
  },
)

export const isPlpStarsModeSelector = createSelector(cartModeSelector, cartMode => {
  return cartMode === 'stars'
})

export const isCartStarsModeSelector = createSelector(
  cartModeSelector,
  activeBrandSelector,
  activeDoorsIdsSelector,
  (cartMode, activeBrand, activeDoorsIds) => {
    return (
      cartMode === 'stars' &&
      !!activeBrand?.starsDoorsIds.length &&
      activeBrand.starsDoorsIds.some(doorId => activeDoorsIds.includes(doorId))
    )
  },
)
