import classnames from 'classnames'
import React from 'react'
import styled, { keyframes } from 'styled-components'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  &.fullpage {
    width: 100vw;
    height: 100vh;
  }
`

const Background = styled.div`
  background: transparent;
`

const SpinWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1015;
`

const ldsRing = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  width: 64px;
  height: 64px;
  position: relative;

  div {
    width: 100%;
    height: 100%;
    position: absolute;

    border: 8px solid #1a1a1a;
    border-radius: 50%;
    border-color: #1a1a1a transparent transparent transparent;

    animation: ${ldsRing} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`

const resetEvent = (e: React.MouseEvent) => {
  e.preventDefault()
  e.stopPropagation()
}

type Props = {
  isFullPage?: boolean
  className?: string
}

const RtlLoader: React.FC<Props> = ({ isFullPage = true, className }) => (
  <Wrapper
    onClick={resetEvent}
    className={classnames({
      'loading-wrapper': true,
      fullpage: isFullPage,
      [className || '']: !!className,
    })}
    data-testid="RtlLoader"
  >
    <Background className={classnames('loading loading-background')} />
    <SpinWrapper className={classnames('loading')}>
      <Spinner>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </Spinner>
    </SpinWrapper>
  </Wrapper>
)

export default RtlLoader
