import React from 'react'

type Props = {
  className?: string
}

const UpIcon: React.FC<Props> = ({ className, ...props }) => (
  <svg
    viewBox="64 64 896 896"
    className={['rc-icon', className].join(' ')}
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
    focusable="false"
    {...props}
  >
    <path d="M890.5 755.3L537.9 269.2c-12.8-17.6-39-17.6-51.7 0L133.5 755.3A8 8 0 0 0 140 768h75c5.1 0 9.9-2.5 12.9-6.6L512 369.8l284.1 391.6c3 4.1 7.8 6.6 12.9 6.6h75c6.5 0 10.3-7.4 6.5-12.7z"></path>
  </svg>
)

UpIcon.displayName = 'UpIcon'

export default UpIcon
