import React from 'react'
import { useSelector } from 'react-redux'
import { RouteChildrenProps, Switch } from 'react-router-dom'
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper/core'

import Fullscreen360 from './components/ModalFullscreen360'
import PrivateRoute from './components/PrivateRoute'
import VtoModal from './components/VirtualMirrorModal'
import CalendarPage from './containers/CalendarPage/CalendarPage'
import CollectionHighlights from './containers/CollectionHighlights/CollectionHighlights'
import ContactManagementPage from './containers/ContactManagementPage'
import DigitalEventsAppointmentPage from './containers/DigitalEventsAppointmentPage'
import DigitalEventsBrandsPage from './containers/DigitalEventsBrandsPage'
import DigitalEventsCategoriesPage from './containers/DigitalEventsCategoriesPage/DigitalEventsCategoriesPage'
import Dashboard from './containers/DigitalEventsDashboard/Dashboard'
import HomePageDigitalEvents from './containers/DigitalEventsHomepage'
import DigitalEventsInitiativePage from './containers/DigitalEventsInitiativePage'
import KamCalendarPage from './containers/KamCalendarPage'
import ModalContainer from './containers/ModalContainer'
import NotificationContainer from './containers/NotificationContainer'
import useFetchInitialData from './hooks/useFetchInitialData'
import { isVtoModalVisibleSelector } from './store/pdp/selectors'
import GlobalStyleDigitalEvents from './style/GlobalStyleDigitalEvents'
import SwiperStyle from './style/SwiperStyle'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

const DigitalEvents: React.FC<RouteChildrenProps> = ({ match }) => {
  const showVtoModal = useSelector(isVtoModalVisibleSelector)

  useFetchInitialData({ loadCustomer: true, loadContent: true, loadBrands: true })

  return (
    <>
      <GlobalStyleDigitalEvents />
      <SwiperStyle />
      <Switch>
        <PrivateRoute exact path="/digital-events/brands" component={DigitalEventsBrandsPage} />
        <PrivateRoute
          exact
          path="/digital-events/appointment/:id"
          component={DigitalEventsAppointmentPage}
        />
        <PrivateRoute
          exact
          path="/digital-events/initiative/:id"
          component={DigitalEventsInitiativePage}
        />
        <PrivateRoute exact path="/digital-events/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/digital-events/calendar" component={CalendarPage} />
        <PrivateRoute
          exact
          path="/digital-events/single-brand/:brandSlug"
          component={DigitalEventsCategoriesPage}
        />
        <PrivateRoute
          path="/digital-events/collection-highlight/:brand/:highlightId?"
          component={CollectionHighlights}
        />
        <PrivateRoute
          exact
          path="/digital-events/external/contact-management"
          component={ContactManagementPage}
        />
        <PrivateRoute
          exact
          path="/digital-events/external/kam-calendar"
          component={KamCalendarPage}
        />
        <PrivateRoute path="/digital-events" component={HomePageDigitalEvents} />
      </Switch>
      <ModalContainer match={match} />
      <NotificationContainer />
      {showVtoModal && <VtoModal />}
      <Fullscreen360 />
    </>
  )
}

export default DigitalEvents
