import React, { Component } from 'react'
import { Button } from 'antd'
import styled from 'styled-components'

import FooterNav from '../FooterNav/index'
const SlTest = styled.div`
  padding: 2rem;
  h1 {
    margin: 2rem 0;
  }
  .ant-btn {
    margin-right: 2rem;
    &:last-child {
      margin: 0;
    }
  }
`

class SmartlookTestPage extends Component {
  constructor(props) {
    super(props)
  }

  render = () => {
    return (
      <>
        <SlTest className="container-sl-test">
          <h1>SmartlookTestPage</h1>
          <Button
            onClick={() =>
              window.smartlook('track', 'error-generic', {
                message: 'Message 1: Generic error 1: []',
              })
            }
          >
            error-generic 1
          </Button>
          <Button
            onClick={() =>
              window.smartlook('track', 'error-generic', {
                message: 'Message 2: Generic error 2: []',
              })
            }
          >
            error-generic 2
          </Button>
          <Button
            onClick={() =>
              window.smartlook('track', 'error-generic', {
                message: 'Message 3: Generic error 3: []',
              })
            }
          >
            error-generic 3
          </Button>
          <Button
            onClick={() =>
              window.smartlook('track', 'error-generic', {
                message: 'Message 4: Generic error 4: []',
              })
            }
          >
            error-generic 4
          </Button>
          <Button
            onClick={() =>
              window.smartlook('track', 'error-cart', {
                message: 'Message 1: Cart error 1: []',
              })
            }
          >
            error-cart 1
          </Button>
          <Button
            onClick={() =>
              window.smartlook('track', 'error-cart', {
                message: 'Message 2: Cart error 2: []',
              })
            }
          >
            error-cart 2
          </Button>
          <Button
            onClick={() =>
              window.smartlook('track', 'error-cart', {
                message: 'Message 3: Cart error 3: []',
              })
            }
          >
            error-cart 3
          </Button>
          <Button
            onClick={() =>
              window.smartlook('track', 'error-cart', {
                message: 'Message 4: Cart error 4: []',
              })
            }
          >
            error-cart 4
          </Button>
        </SlTest>
        <FooterNav />
      </>
    )
  }
}

export default SmartlookTestPage
