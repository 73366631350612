import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { getDeviceAbsolutePath } from '../../../libs/url'
import { useConfirmCartMutation } from '../../../services/cart'
import { cartEditableSelector } from '../../../store/cart/selectors'
import checkoutActions from '../../../store/checkout/actions'
import { palette } from '../../../style/theme'

const Wrapper = styled.div<{
  showButtonAsLink: boolean
}>`
  ${({ showButtonAsLink }) =>
    !showButtonAsLink
      ? `
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      `
      : ''}
`

const Button = styled.button<{
  showButtonAsLink: boolean
}>`
  ${({ showButtonAsLink }) =>
    !showButtonAsLink
      ? `
  border-radius: 2.5em;
  padding: 0.5em 1.1em 0.5em;
  background-color: ${palette.congressBlue};
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  font-weight: 400;
  text-align: center;
  touch-action: manipulation;
  color: ${palette.white};
  text-transform: uppercase;
  min-width: 7em;
  `
      : ''}
`

type Props = {
  showButtonAsLink?: boolean
}

const GotoCheckoutButton: React.FC<Props> = ({ showButtonAsLink = false }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const isCartEditable = useSelector(cartEditableSelector)
  const [, confirmCartResult] = useConfirmCartMutation()

  return (
    <Wrapper showButtonAsLink={showButtonAsLink}>
      {isCartEditable && (
        <Button
          className={showButtonAsLink ? 'sub-menu hidden' : ''}
          showButtonAsLink={showButtonAsLink}
          onClick={() => {
            dispatch(checkoutActions.resetCheckout())
            confirmCartResult.reset()
            history.push(`${getDeviceAbsolutePath()}/checkout`, { replace: true })
          }}
        >
          {t('Checkout.Footer.SubmitOrder')}
        </Button>
      )}
    </Wrapper>
  )
}

export default GotoCheckoutButton
