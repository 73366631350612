import { SyntheticEvent } from 'react'

import log from '../libs/log'

type Error = {
  message: string
  operationNamespace: string
  status?: number
  externalUrl?: string
  origin?: string
  stack?: string
  localUrl?: string
}

export const logError = (error: Error) => {
  const origin = error.origin ? `${error.origin} ` : ''
  const status = error.status !== undefined && error.status !== 200 ? ` - ${error.status}` : ''
  const externalUrl = error.externalUrl ? `\nExternal url: ${error.externalUrl}` : ''
  const stack = error.stack ? `\nStack: ${error.stack}` : ''
  // eslint-disable-next-line no-console
  console.error(
    `${origin}Error${status}: ${error.operationNamespace}\n` +
      `Message: ${error.message}\n` +
      `${error.localUrl ? `Local url: ${error.localUrl}\n` : ''}` +
      `${externalUrl}` +
      stack,
  )
  log.error(error).then()
  const jsonError = JSON.stringify(error)
  window.smartlook && window.smartlook('track', `error${origin ? `-${origin}` : ''}`, { jsonError })
}

export const logCmsError = ({
  message,
  operationNamespace,
}: {
  message: string
  operationNamespace: string
}) => {
  logError({ message, origin: 'CMS', operationNamespace })
}

/**
 * Handles response from server by providing JSON or throwing error.
 * @param {Object} res Response by server
 * @returns Promise.reject or fetch.json (Promise)
 */
export const handleErrors = (res?: Response | never[]) => {
  if (!res || !('json' in res) || typeof res.json !== 'function') {
    return Promise.reject({
      status: '500',
      origin: 'RC-API response',
      message: 'Server response is not a JSON',
    })
  }

  // in case of error we have to resolve the promise in order to fetch the custom error message
  const resolvedPromise = res.json().catch(() => {
    return Promise.reject({
      status: res.status,
      origin: 'RC-API response',
      message: `statusText: ${res.statusText} - Type: ${res.type}`,
    })
  })

  return res.ok
    ? resolvedPromise.then(data => {
        if (typeof data === 'object' && data.errors) {
          data.errors.map(logError)
          return Promise.resolve(data.data)
        }
        return Promise.resolve(data)
      })
    : resolvedPromise.then(data => {
        logError(data)
        return Promise.reject(data)
      })
}

export const handleNonBlockingErrors = (res?: Response) => {
  if (!res || !res.json || typeof res.json !== 'function') {
    return Promise.resolve()
  }

  return res.json().catch(() => {
    log.warn(`Non blocking error on url ${res.url}`)
    return undefined
  })
}

/**
 * Hides image if error occured while loading.
 * @param {Object} e Error event (React.SyntheticEvent)
 * @returns undefined
 */
export const handleImageError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
  if (e.currentTarget instanceof HTMLElement) {
    e.currentTarget.style.display = 'none'
    e.currentTarget.className = ''
  }
}
