export const getSeasonCodeFromReleaseCode = (releaseCode: string) => {
  const releaseMapping: { [key: string]: string } = {
    FW: 'FW',
    SS: 'SS',
    // add more realse codes to associate it with season code  e.g.
    // N1: 'FW',
    // N2: 'SS'
  }

  return releaseMapping[releaseCode]
}
