import React, { SVGProps } from 'react'

const ClockIcon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="currentColor"
    {...props}
  >
    <path d="M9.6 0C4.307 0 0 4.307 0 9.6c0 5.293 4.307 9.6 9.6 9.6 5.293 0 9.6-4.307 9.6-9.6 0-5.293-4.307-9.6-9.6-9.6zm0 18.24C4.836 18.24.96 14.364.96 9.6.96 4.836 4.836.96 9.6.96c4.764 0 8.64 3.876 8.64 8.64 0 4.764-3.876 8.64-8.64 8.64z" />
    <path d="M9.6 3.36a.48.48 0 00-.48.48v5.28H5.76a.48.48 0 000 .96H9.6a.48.48 0 00.48-.48V3.84a.48.48 0 00-.48-.48z" />
  </svg>
)

export default ClockIcon
