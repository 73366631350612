import React from 'react'
import styled, { css } from 'styled-components'
import {
  getFluidFontSize,
  getFluidSizeWithFullFormula as gF,
  palette,
  pxToRem,
  spacing,
} from '../../style/theme'
import { AfaCartProduct } from '../../model/afa'
import { useGetProductMasterData } from '../../services/afaProduct'
import { useTranslation } from 'react-i18next'
import { ButtonConfirm } from '../AfaCommonComponents'
import Loading from '../Loading'
import { isPageMatches } from '../../libs/url'

const fontSize = css`
  font-size: ${gF('em', 1.5, 15, 1366, 3840)};
`
const Wrapper = styled.div`
  padding: 0 ${pxToRem(spacing(12))}rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  h1 {
    font-size: ${gF('em', 2, 20, 1366, 3840)};
    font-family: 'GilmerBold', sans-serif;
    color: ${palette.tangaroa};
  }
  h3 {
    margin-top: 1em;
    ${fontSize};
    font-family: 'GilmerMedium', sans-serif;
    color: ${palette.tangaroa};
  }
`

const Table = styled.table`
  width: ${gF('em', 50, 550, 1366, 3840)};
  margin-top: ${pxToRem(16)}rem;

  thead {
    border-bottom: 1px solid ${palette.mischka};

    th {
      color: ${palette.santasGray};
      ${fontSize};
      text-transform: uppercase;
      font-family: 'GilmerBold', sans-serif;
      text-align: end;
      padding: ${gF('em', 1, 10, 1366, 3840)} 0;

      &:first-of-type {
        text-align: left;
      }

      &.minimum-width {
        width: 1%;
        white-space: nowrap;
      }
    }
  }
`

type TableRowType = {
  product: AfaCartProduct
}

const Tr = styled.tr`
  td {
    padding: ${gF('em', 0.9, 10, 1366, 3840)} 0 0;
    text-align: center;
    font-size: ${gF('px', 16, 28, 1366, 3840)};
    position: relative;

    &:first-of-type {
      left: 0;
    }

    &.gray-th {
      color: ${palette.shadowBlue};
      font-weight: 900;
    }
    &.arrow {
      ${fontSize};
      color: ${palette.tangaroa};
      bottom: 0.2em;
    }
    &:first-of-type div {
      text-align: left;
      font-family: GilmerMedium, sans-serif;

      &:last-of-type {
        font-size: ${gF('px', 14, 28, 1366, 3840)};
        font-family: GilmerRegular, sans-serif;
      }
    }
    &:last-of-type {
      color: ${palette.darkGreen};
      font-family: GilmerBold, sans-serif;
    }
  }
`

const ModalButtons = styled.div`
  display: flex;
  justify-content: center;

  button {
    &:not(:last-of-type) {
      margin-right: 40px;
    }
  }
`

const Button = styled.button`
  font-size: ${getFluidFontSize('13px')};
  font-family: 'GilmerBold', sans-serif;
  text-transform: uppercase;
  display: flex;
  padding: 19px 21px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  background-color: ${palette.tangaroa};
  color: white;
`

const CancelButton = styled(Button)`
  border: 1px solid ${palette.tangaroa};
  color: ${palette.tangaroa};
  background-color: white;
`

const TableWrapper = styled.div`
  height: 100%;
  overflow: auto;
  padding: 25px 0;
`

const TableScrollWrapper = styled.div`
  overflow-y: scroll;
  height: 100%;
`

const TableRow: React.FC<TableRowType> = ({ product }) => {
  const { brandCodeParent, modelCode, unconfirmedQuantity, colorCode } = product

  const productMasterData = useGetProductMasterData({ modelCode, brandCode: brandCodeParent })
  const colorDescription = productMasterData?.mocos[colorCode]?.colorDescription || colorCode

  return (
    <Tr>
      <td>
        <div>{productMasterData?.name || modelCode}</div>
        <div>{colorDescription}</div>
      </td>
      <td className="gray-th">{unconfirmedQuantity}</td>
      <td className="arrow">&#x27F6;</td>
      <td>0</td>
    </Tr>
  )
}

type Props = {
  title: string
  confirmText?: string
  cartProducts: AfaCartProduct[]
  onCancel: () => void
  onRemoveItems: () => void
  isExpiredDrop?: boolean
  updateCartIsLoading?: boolean
}

const RemoveSelectedFromCart: React.FC<Props> = ({
  title,
  confirmText,
  cartProducts,
  onCancel,
  onRemoveItems,
  isExpiredDrop,
  updateCartIsLoading,
}) => {
  const { t } = useTranslation()
  const productsByMoco = cartProducts.reduce(
    (res, product) => ({
      ...res,
      [`${product.modelCode}-${product.colorCode}`]: {
        ...product,
        unconfirmedQuantity:
          (res[`${product.modelCode}-${product.colorCode}`]?.unconfirmedQuantity || 0) +
          product.unconfirmedQuantity,
      },
    }),
    {} as Record<string, AfaCartProduct>,
  )
  const isCartPage = isPageMatches('cart')

  return (
    <Wrapper>
      <h1>{title}</h1>
      <h3>{confirmText}</h3>
      <TableWrapper>
        <TableScrollWrapper>
          {updateCartIsLoading && isCartPage ? (
            <Loading />
          ) : (
            <Table>
              <thead>
                <tr>
                  <th>{t('Afa.Cart.RemoveItemsTable.Product')}</th>
                  <th className="minimum-width">{t('Afa.Cart.RemoveItemsTable.PrevQuantity')}</th>
                  <th></th>
                  <th className="minimum-width">{t('Afa.Cart.RemoveItemsTable.NewQuantity')}</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(productsByMoco).map(product => (
                  <TableRow key={product.key} product={product} />
                ))}
              </tbody>
            </Table>
          )}
        </TableScrollWrapper>
      </TableWrapper>
      {isExpiredDrop ? (
        <ButtonConfirm onClick={onRemoveItems}>{t('Afa.Cart.Adjust.Done')}</ButtonConfirm>
      ) : (
        <ModalButtons>
          <CancelButton onClick={onCancel}>{t('Afa.Cart.Cancel')}</CancelButton>
          <Button onClick={onRemoveItems}>{t('Afa.Cart.RemoveItems')}</Button>
        </ModalButtons>
      )}
    </Wrapper>
  )
}

export default RemoveSelectedFromCart
