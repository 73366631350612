import { apiBrands } from '../../api/restApi'
import { handleErrors } from '../../libs/handleError'
import { AppThunkPromise } from '../../model/model'
import { dataLoadedFail } from '../app/actions'
import { brandsSelector } from './selectors'
import { slice } from './slice'
import { getCart } from '../cart/actions'

/* METHODS */

export const getBrands = (customerId: string, eventId: string): AppThunkPromise => async (
  dispatch,
  getState,
) => {
  const state = getState()
  if (!customerId || !eventId) {
    return Promise.reject()
  }

  // show spinner only at first load
  const brands = brandsSelector(state)
  if (!brands?.length) {
    dispatch(slice.actions.loadingBrands())
  }

  try {
    const res = await apiBrands({
      authToken: state.auth.token || '',
      customerId: customerId || state.app.customerId,
      eventId: eventId || state.app.eventId,
    })
    const data = await handleErrors(res)
    dispatch(slice.actions.loadBrandsSuccess(data))
    return
  } catch (err) {
    dispatch(dataLoadedFail(err))
    return
  }
}

export const loadBrands = (
  customerId: string,
  eventId: string,
): AppThunkPromise => async dispatch => {
  dispatch(getBrands(customerId, eventId)).then(() => {
    if (!['afa', 'choose-assortment'].some(afaPath => window.location.pathname.includes(afaPath))) {
      dispatch(getCart(customerId, eventId))
    }
  })
}

export const setBrandActivationPlaying = slice.actions.setBrandActivationPlaying
export const setBrandDiscoveryPlaying = slice.actions.setBrandDiscoveryPlaying
export const setBrandActivationPlayed = slice.actions.setBrandActivationPlayed
export const setBrandDiscoveryPlayed = slice.actions.setBrandDiscoveryPlayed
export const loadBrandsSuccess = slice.actions.loadBrandsSuccess
export const loadingBrands = slice.actions.loadingBrands

export default {
  ...slice.actions,
  getBrands,
}
