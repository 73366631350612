import React, { SVGProps } from 'react'

const PlayIcon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12.574615 15.732149"
    height="15.732149"
    width="12.574615"
    fill="currentColor"
    data-testid="play-icon"
    {...props}
  >
    <path d="M 1.5507812,0 C 0.7024063,0 0,0.7004532 0,1.5488281 V 14.185547 c 0,0.307027 0.090447,0.605397 0.2597656,0.859375 0.470681,0.706022 1.4445707,0.901374 2.1503906,0.429688 L 11.888672,9.1562495 c 0.169598,-0.113065 0.316542,-0.258859 0.429688,-0.427734 v -0.002 c 0.470086,-0.705985 0.27447,-1.678267 -0.431641,-2.148437 L 2.4101562,0.2578125 2.4082031,0.2558594 C 2.1544091,0.0889345 1.855373,0 1.5507812,0 Z m 0,1.2988281 c 0.051408,0 0.098372,0.013894 0.1425782,0.042969 l 9.4726566,6.3183596 h 0.002 c 0.11989,0.07983 0.148273,0.225687 0.06836,0.345703 -0.02032,0.02975 -0.04268,0.05189 -0.07031,0.07031 l -9.4765629,6.3164073 -0.00195,0.002 c -0.1201801,0.08031 -0.2643842,0.05167 -0.3457032,-0.07031 -0.026681,-0.04002 -0.041016,-0.0897 -0.041016,-0.138672 V 1.5488281 c 0,-0.1456251 0.1043749,-0.25 0.25,-0.25 z" />
  </svg>
)

export default PlayIcon
