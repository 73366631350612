
import classnames from 'classnames'
import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { MyShopTab } from '../../../../model/cart'
import { setMyShopActiveTab } from '../../../../store/actions'
import { myShopActiveTabSelector } from '../../../../store/cart/selectors'
import { getFluidFontSize, palette } from '../../../../style/theme'

const Wrapper = styled.div`
  display: flex;
`

const Button = styled.button`
  text-align: center;
  text-transform: uppercase;
  font-size: ${getFluidFontSize('11px')};
  padding: 0.5vh 1vw;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.2vh;
  color: ${palette.kobi};
  box-shadow: 2px 0 4px 0 rgb(0 0 0 / 5%);
  border-top: 6px solid transparent;
  margin-left: 0;

  &.active {
    border-top-color: ${palette.congressBlue};
    color: ${palette.hibiscus};
  }
`

const tabs = (t: TFunction): { value: MyShopTab; label: string }[] => [
  {
    value: 'futureTrends',
    label: t('WallCartPage.futureTrends'),
  },
  {
    value: 'bestsellers',
    label: t('WallCartPage.best_seller'),
  },
  {
    value: 'adv',
    label: t('WallCartPage.adv'),
  },
]

const MyShopCategorySwitcher: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const selectedTab = useSelector(myShopActiveTabSelector) || ''

  return (
    <Wrapper>
      {tabs(t).map(tab => (
        <Button
          key={tab.value}
          className={classnames({
            active: selectedTab === tab.value,
          })}
          onClick={() => dispatch(setMyShopActiveTab(tab.value))}
        >
          {tab.label}
        </Button>
      ))}
    </Wrapper>
  )
}

export default MyShopCategorySwitcher
