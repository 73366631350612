import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import AfaFooter from '../../../components/AfaFooter'
import { horizontalScrollbarCss } from '../../../components/CommonComponents'
import Header from '../../../components/Header'
import useFetchInitialData from '../../../hooks/useFetchInitialData'
import { AfaCartExcel } from '../../../model/cart'
import { useGetAfaCartQuery, useUploadAfaCartMutation } from '../../../services/afaCart'
import { importExportStepSelector } from '../../../store/afa/selectors'
import afaActions from '../../../store/afa/actions'
import DownIcon from '../../../components/icons/DownIcon'

import {
  breakpoints,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
  spacing,
} from '../../../style/theme'
import {
  NextButton,
  StyledTableTh,
  StyledTableTitle,
} from '../components/AfaCheckoutCommonComponents'
import AfaSubHeader from '../components/AfaSubHeader'
import ExportCart from './ExportCartAfa'
import Upload from './Upload'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
`

const Button = styled.button`
  font-size: ${getFluidSizeWithFullFormula('px', 16, 24, 1366, 3840)};
  font-family: GilmerBold, sans-serif;
  padding-left: 20px;

  &.disabled {
    opacity: 0.2;
    cursor: auto;
  }
`

const ArrowLeft = styled(DownIcon)`
  transform: rotate(90deg);
`

const ArrowRight = styled(DownIcon)`
  transform: rotate(270deg);
`

const Content = styled.section`
  font-weight: normal;
  background-color: ${palette.lightGray};
  color: ${palette.tangaroa};
  padding-top: ${getFluidSizeWithFullFormula('px', 35, 80, 1366, 3840)};
  padding-bottom: ${getFluidSizeWithFullFormula('px', 35, 80, 1366, 3840)};
  padding-left: ${getFluidSizeWithFullFormula('px', 32, 64, 1366, 3840)};
  padding-right: ${getFluidSizeWithFullFormula('px', 32, 64, 1366, 3840)};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
`

const Title = styled.div`
  font-size: ${getFluidSizeWithFullFormula('px', 24, 64, 1366, 3840)};
  font-weight: bold;
  line-height: normal;
  margin-bottom: ${getFluidSizeWithFullFormula('px', 37, 80, 1366, 3840)};
`

const ImportExportBoxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${getFluidSizeWithFullFormula('px', 16, 33, 1366, 3840)};
`

const Box = styled.div`
  border-radius: 10px;
  border: solid 1px ${palette.mischka};
  background-color: ${palette.white};
  padding-block: clamp(32px, ${getFluidSizeWithFullFormula('px', 32, 92, 1366, 3840)}, 92px);
  padding-inline: clamp(32px, ${getFluidSizeWithFullFormula('px', 32, 233, 1366, 3840)}, 233px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

const ExcelTable = styled.div`
  grid-column: 1/3;
  background-color: ${palette.white};
  margin-top: ${getFluidSizeWithFullFormula('px', 16, 32, 1366, 3840)};
  border-top-left-radius: clamp(
    10px,
    ${getFluidSizeWithFullFormula('px', 10, 20, 1366, 3840)},
    20px
  );
  border-top-right-radius: clamp(
    10px,
    ${getFluidSizeWithFullFormula('px', 10, 20, 1366, 3840)},
    20px
  );
`

const TableWrapper = styled.div`
  ${horizontalScrollbarCss};
  background-color: ${palette.white};

  table {
    color: black;
    white-space: nowrap;
    min-width: 100%;
    border-collapse: collapse;

    tr {
      border-bottom: 1px solid ${palette.platinum};

      @media (min-width: ${breakpoints.L}) {
        border-bottom-width: 2px;
      }
    }

    th,
    td {
      padding: ${pxToRem(spacing(2))}rem ${pxToRem(spacing(4.5))}rem;
    }

    td {
      font-size: ${getFluidSizeWithFullFormula('px', 14, 24, 1366, 3840)};
      font-weight: bold;
      color: ${palette.cloudBurst};

      @media (min-width: ${breakpoints.L}) {
        font-family: Avenir, sans-serif;
      }
    }
  }
`

const TableTh = styled(StyledTableTh)``

const TableTitle = styled(StyledTableTitle)``

const TableContainer = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% - 6px);
    width: 1px;
    background-color: ${palette.platinum};

    @media (min-width: ${breakpoints.L}) {
      width: 2px;
      height: 100%;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: calc(100% - 6px);
    width: 1px;
    background-color: ${palette.platinum};

    @media (min-width: ${breakpoints.L}) {
      width: 2px;
      height: 100%;
    }
  }
`

function TableItem({
  xlsBrand,
  xlsSkuCode,
  xlsColour,
  xlsModel,
  xlsUpc,
  xlsDoorId,
  xlsCustomerName,
  xlsName,
  xlsDeliveryDate,
  xlsReference,
  xlsQuantity,
}: AfaCartExcel) {
  const deliveryDate = (xlsDeliveryDate: string) => {
    if (xlsDeliveryDate != '') {
      const chunks = xlsDeliveryDate.split('/')
      return new Date(`${chunks[2]}-${chunks[1]}-${chunks[0]}`).toLocaleDateString()
    } else {
      return ''
    }
  }

  return (
    <tr>
      <td>{xlsBrand}</td>
      <td>{xlsSkuCode}</td>
      <td>{xlsColour}</td>
      <td>{xlsModel}</td>
      <td>{xlsUpc}</td>
      <td>{xlsDoorId}</td>
      <td>{xlsCustomerName}</td>
      <td>{xlsName}</td>
      <td>{deliveryDate(xlsDeliveryDate)}</td>
      <td>{xlsReference}</td>
      <td>{xlsQuantity}</td>
    </tr>
  )
}

const AfaCheckoutImportExportPage: React.FC = () => {
  useFetchInitialData({ loadCustomer: true })

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [scrolledLeft, setScrolledLeft] = useState(false)
  const [scrolledRight, setScrolledRight] = useState(false)

  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)
  const tableRef = useRef<HTMLDivElement>(null)

  const moveLeftOrRight = (direction: 'left' | 'right') => {
    if (intervalRef.current) return
    intervalRef.current = setInterval(() => {
      if (tableRef.current) {
        if (direction === 'left') {
          tableRef.current.scrollLeft -= 10
        } else if (direction === 'right') {
          tableRef.current.scrollLeft += 10
        }
      }
    }, 10)
  }

  const stopMovingRightOrLeft = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }

  useEffect(() => {
    return () => stopMovingRightOrLeft()
  }, [])

  const handleArrowButtons = useCallback(() => {
    if (tableRef.current) {
      const scrolledLeft = tableRef.current.scrollLeft === 0
      const scrolledRight =
        tableRef.current.scrollLeft + tableRef.current.clientWidth >= tableRef.current.scrollWidth

      setScrolledLeft(scrolledLeft)
      setScrolledRight(scrolledRight)
    }
  }, [])

  const showArrows = !(scrolledLeft && scrolledRight)

  const importExportStep = useSelector(importExportStepSelector)

  const [, uploadCartResult] = useUploadAfaCartMutation()
  const uploadEnded = uploadCartResult.isSuccess

  useEffect(() => {
    if (uploadEnded) {
      handleArrowButtons()
      window.addEventListener('resize', handleArrowButtons)
      return () => window.removeEventListener('resize', handleArrowButtons)
    }
  }, [uploadEnded, handleArrowButtons])

  const cartQuery = useGetAfaCartQuery()
  const itemsInCart = cartQuery.data?.items ? cartQuery.data?.items.length > 0 : false

  const tableHeader: [string][] = [
    [t('Checkout.ImportExport.Brand')],
    [t('Checkout.ImportExport.SkuCode')],
    [t('Checkout.ImportExport.ColorCode')],
    [t('Checkout.ImportExport.ModelCode')],
    [t('Checkout.ImportExport.Upc')],
    [t('Checkout.ImportExport.CustomerCode')],
    [t('Checkout.ImportExport.Description')],
    [t('Checkout.ImportExport.Name')],
    [t('Checkout.ImportExport.DeliveryDate')],
    [t('Checkout.ImportExport.Reference')],
    [t('Checkout.ImportExport.Quantity')],
  ]

  return (
    <>
      <Wrapper>
        <Header hideButtons />
        <AfaSubHeader activeLink="import-export" />

        <Content>
          <Title>{t('Afa.Checkout.ImportExportCart')}</Title>

          <ImportExportBoxes>
            <Box>
              <Upload />
            </Box>

            <Box>
              <ExportCart itemsInCart={itemsInCart} />
            </Box>
          </ImportExportBoxes>

          {uploadEnded && (
            <ExcelTable>
              <TableTitle>
                <Flex>
                  <div>{t('Checkout.ImportExport.EditedCart')}</div>
                  {showArrows && (
                    <Flex>
                      <Button
                        onMouseDown={() => moveLeftOrRight('left')}
                        onMouseUp={stopMovingRightOrLeft}
                        onMouseLeave={stopMovingRightOrLeft}
                        onTouchStart={() => moveLeftOrRight('left')}
                        onTouchEnd={stopMovingRightOrLeft}
                        className={scrolledLeft ? 'disabled' : ''}
                      >
                        <ArrowLeft />
                      </Button>
                      <Button
                        onMouseDown={() => moveLeftOrRight('right')}
                        onMouseUp={stopMovingRightOrLeft}
                        onMouseLeave={stopMovingRightOrLeft}
                        onTouchStart={() => moveLeftOrRight('right')}
                        onTouchEnd={stopMovingRightOrLeft}
                        className={scrolledRight ? 'disabled' : ''}
                      >
                        <ArrowRight />
                      </Button>
                    </Flex>
                  )}
                </Flex>
              </TableTitle>

              <TableContainer>
                <TableWrapper ref={tableRef} onScroll={handleArrowButtons}>
                  <table className="full-screen">
                    <tr>
                      {tableHeader.map((column, index) => {
                        const columnName: string = column[0].toString()
                        return <TableTh key={index}>{columnName}</TableTh>
                      })}
                    </tr>

                    {uploadCartResult.data?.content.map(contentResult => {
                      return (
                        <TableItem
                          key={`${contentResult.xlsUpc}-${contentResult.xlsDeliveryDate}-${contentResult.xlsDoorId}`}
                          {...contentResult}
                        />
                      )
                    })}
                  </table>
                </TableWrapper>
              </TableContainer>
            </ExcelTable>
          )}
        </Content>
      </Wrapper>

      <AfaFooter
        {...(uploadEnded && importExportStep === 1
          ? {
              centralColContent: (
                <NextButton
                  onClick={() => {
                    dispatch(afaActions.setImportExportStep(2))
                  }}
                >
                  {t('Afa.Checkout.Footer.Import')}
                </NextButton>
              ),
            }
          : {})}
      />
    </>
  )
}

export default AfaCheckoutImportExportPage
