import React from 'react'
import { Switch, Route, match } from 'react-router-dom'
import PrivateRoute from '../../components/PrivateRoute'
import SingleBrandPage from '../../containers/SingleBrandPage/SingleBrandPage'
import CategoriesPage from '../../containers/CategoriesPage'
import TableCartContainer from '../../containers/TableCartContainer'
import FilteredPLP from '../../containers/FilteredPLP/FilteredPLP'
import TableWishlistPage from '../../containers/TableWishlistPage'
import IntroVideo from '../../containers/IntroVideo'
import ChooseBrandPage from '../../containers/ChooseBrandPage'

type Props = {
  match: match
  token: string | null
}

// eslint-disable-next-line
const Routes = ({ match, token }: Props) => {
  return (
    <Switch>
      <Route exact path={match.url} component={IntroVideo} />
      <Route exact path={`${match.url}/choose-brand`} render={() => <ChooseBrandPage />} />
      <PrivateRoute
        exact
        path={`${match.url}/single-brand/:brandSlug`}
        component={CategoriesPage}
      />
      <PrivateRoute
        exact
        path={`${match.url}/single-brand/:brandSlug/:category`}
        component={SingleBrandPage}
      />
      <PrivateRoute exact path={`${match.url}/cart`} component={TableCartContainer} />
      <PrivateRoute exact path={`${match.url}/wishlist`} component={TableWishlistPage} />
      <PrivateRoute exact path={`${match.url}/products`} component={FilteredPLP} />
      <PrivateRoute exact path={`${match.url}/products/:category`} component={FilteredPLP} />
    </Switch>
  )
}

export default Routes
