import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import afaActions from '../../store/afa/actions'
import { searchOpenSelector } from '../../store/afa/selectors'
import Backdrop from '../../styleguide/Backdrop'
import AfaSearchLayer from './AfaSearchLayer/AfaSearchLayer'

const SearchBackdrop = styled(Backdrop)`
  z-index: 999999999;
`

const AfaSearch: React.FC = () => {
  const dispatch = useDispatch()

  const open = useSelector(searchOpenSelector)

  return (
    <SearchBackdrop onClick={() => dispatch(afaActions.toggleSearchOpen())} open={open}>
      <AfaSearchLayer />
    </SearchBackdrop>
  )
}

export default AfaSearch
