import React from 'react'
import styled from 'styled-components'

import app_config from '../../../config/app/config'
import { getFluidSizeWithFullFormula } from '../../../style/theme'

const StyledRedCarpetLogo = styled.img`
  height: 4vh;
  max-height: 100%;
  width: ${getFluidSizeWithFullFormula('px', 292, 682, 1366, 3840)};
`
const RedCarpetLogo: React.FC<{
  white?: boolean
  className?: string
}> = ({ white = false, className }) => {
  const logoPath = `${app_config.publicUrl}/assets/lux-red-carpet-logo-new${
    white ? '-white' : ''
  }.svg`
  return <StyledRedCarpetLogo className={className} src={logoPath} alt="RED CARPET" />
}

export default RedCarpetLogo
