import React from 'react'

const CheckIcon: React.FC<{ colorCode?: string }> = ({ colorCode = '#005192' }) => (
  <svg viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M20.39.2L6.515 14.367 1.61 9.36a.586.586 0 0 0-.84 0l-.561.572a.616.616 0 0 0 0 .859l5.886 6.01a.586.586 0 0 0 .84 0L21.792 1.631a.616.616 0 0 0 0-.859L21.231.2a.586.586 0 0 0-.841 0z"
        id="c"
      />
    </defs>
    <g transform="translate(11 14)" fill="none" fillRule="evenodd">
      <mask id="d" fill="#fff">
        <use xlinkHref="#c" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#c" />
      <g mask="url(#d)" fill={colorCode}>
        <path d="M-11-14h44v44h-44z" />
      </g>
    </g>
  </svg>
)

export default CheckIcon
