import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { cmsTagSelector, languageSelector } from '../store/app/selectors'
import { customerNationalitySelector } from '../store/customer/selectors'
import { getDigitalEventsBackgrounds } from '../store/digitalEvents/actions'
import { backgroundsLoadedSelector } from '../store/digitalEvents/selectors'

const useFetchBackgrounds = () => {
  const dispatch = useDispatch()
  const language = useSelector(languageSelector)
  const country = useSelector(customerNationalitySelector)
  const cmsTag = useSelector(cmsTagSelector)
  const backgroundsLoaded = useSelector(backgroundsLoadedSelector)

  useEffect(() => {
    if (!backgroundsLoaded) {
      dispatch(getDigitalEventsBackgrounds({ language, country, cmsTag }))
    }
  }, [dispatch, backgroundsLoaded, language, country, cmsTag])
}

export default useFetchBackgrounds
