import { useLocationStateValue } from './useLocationState'
import { useParams } from 'react-router-dom'
import { getDeviceAbsolutePath } from '../libs/url'

/**
 * Get the URL for redirecting the page to the keylook of the product clicked
 * @param {string} brandCodeParent - The brand code of the parent category
 * @param {string[]} keylooks - The keylooks of the moco
 * @returns {string} The URL where to redirect the navigation
 */
export const useGetKeylookUrlWithState = (
  brandCodeParent?: string,
  keylooks?: string[],
): string | undefined => {
  const { assortment } = useParams<{ assortment: string }>()
  const previousKeylook = useLocationStateValue('keylook')
  const absolutePath = getDeviceAbsolutePath()

  if (!brandCodeParent || !keylooks || keylooks.length === 0) {
    return undefined
  }
  const sortedKeylooks = keylooks.slice().sort((a, b) => (a === 'Theme' ? -1 : a.localeCompare(b)))
  const sports = [...new Set(sortedKeylooks.map(keylook => keylook?.split('-')[0]))]

  if (previousKeylook && keylooks.includes(previousKeylook)) {
    const keylookParam = previousKeylook.replace(/ /g, '+')
    const keylookSport = keylookParam.split('-')[0]
    const sportParam = [...new Set([keylookSport].concat(sports))]
    const url = `${absolutePath}/${assortment}/single-brand/${brandCodeParent}/key-looks?keylook=${keylookParam}&sport=${sportParam}`
    return url
  } else {
    const keylookParam = sortedKeylooks[0].replace(/ /g, '+')
    const sportParam = sports.join(',')
    const url = `${absolutePath}/${assortment}/single-brand/${brandCodeParent}/key-looks?keylook=${keylookParam}&sport=${sportParam}`
    return url
  }
}
