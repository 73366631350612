import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import NoAppointmentsMessage from '../../components/NoAppointmentsMessage'
import { Appointment } from '../../model/appointment'
import { BoxTitle, DeLink, GlassBox } from '../../style/DigitalEventsCommonComponents'
import { breakpoints, pxToRem, spacing } from '../../style/theme'
import NextAppointment from './NextAppointment'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${pxToRem(spacing(2))}rem;
`

const Content = styled(GlassBox)`
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: ${pxToRem(spacing(2))}rem;
  padding: ${pxToRem(spacing(6))}rem ${pxToRem(spacing(5))}rem;
  gap: ${pxToRem(spacing(2))}rem;
  display: grid;
  grid-template-rows: 1fr 1fr;

  @media (max-width: ${breakpoints.M}) {
    padding: ${pxToRem(spacing(3))}rem;
  }

  @media (max-width: ${breakpoints.S}) {
    padding: ${pxToRem(spacing(2))}rem ${pxToRem(spacing(3))}rem;
  }
`

const NextAppointments: React.FC<{ nextAppointments: Appointment[] | undefined }> = ({
  nextAppointments,
}) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Header>
        <BoxTitle>{t('Dashboard.upcoming')}</BoxTitle>
        <DeLink to="/digital-events/calendar">{t('Dashboard.view_all')}</DeLink>
      </Header>

      <Content>
        {nextAppointments?.length ? (
          nextAppointments.map(appointment => (
            <NextAppointment key={appointment.idAppointment} appointment={appointment} />
          ))
        ) : (
          <NoAppointmentsMessage />
        )}
      </Content>
    </Wrapper>
  )
}

export default NextAppointments
