import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import AfaCartSummaryPagesSelector from './smallComponents/AfaCartSummaryPagesSelector'
import { breakpoints, breakpointsCross, palette, pxToRem } from '../../../../style/theme'
import { formatMoney } from '../../../../libs'
import { useGetAfaCartQuery } from '../../../../services/afaCart'
import { AfaCart } from '../../../../model/afa'
import { convertStringToFloat } from '../../../../libs/numbers'
import { useGetBrandsQuery } from '../../../../services/afa'
import { useSelector } from 'react-redux'
import { showWhsPriceSelector } from '../../../../store/customer/selectors'
import React from 'react'

const Header = styled.header`
  white-space: nowrap;
  padding: clamp(88px, 3.5em, 130px) clamp(88px, 3.5em, 130px) 0;

  @media (max-width: ${breakpoints.L}) {
    background-color: ${palette.white};
    padding: clamp(39px, 2.5em, 88px) clamp(32px, 2em, 88px) 0 clamp(16px, 1.1em, 88px);
  }
`
const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  align-items: flex-end;

  & span {
    font-size: ${pxToRem(64)}rem;
    color: ${palette.tangaroa};
    font-family: GilmerMedium, sans-serif;
    letter-spacing: 0.28px;

    @media (max-width: ${breakpoints.L}) {
      font-size: ${pxToRem(24)}rem;
      letter-spacing: 1px;
      font-family: GilmerBold, sans-serif;
    }
  }

  @media (min-width: ${breakpointsCross.L.over}) {
    & div {
      display: none;
    }
  }
`

const BottomHeader = styled.div`
  display: flex;
  justify-content: start;
  text-transform: lowercase;
  color: ${palette.cloudBurst};
  padding-top: 2em;
  padding-bottom: 1em;

  & div {
    font-size: ${pxToRem(40)}rem;
    padding: 0 2em;
    color: ${palette.tangaroa};
    font-family: GilmerMedium;

    @media (max-width: ${breakpoints.L}) {
      font-size: ${pxToRem(24)}rem;
      font-family: GilmerRegular;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &.center {
      border-left: 1px solid ${palette.mischka};
      border-right: 1px solid ${palette.mischka};
    }
  }

  @media (max-width: ${breakpoints.L}) {
    justify-content: center;
  }
`

const CartValue = styled.span`
  text-transform: uppercase;
`

const calculateTotals = (afaCart?: AfaCart) => ({
  doors: [...new Set(afaCart?.items.map(({ doorId }) => doorId))].length || 0,
  items: afaCart?.items.reduce((acc, cartProduct) => acc + cartProduct.unconfirmedQuantity, 0) || 0,
  cartValue:
    afaCart?.items.reduce(
      (acc, cartProduct) =>
        acc +
        cartProduct.unconfirmedQuantity * convertStringToFloat(cartProduct.masterPrice.unitPriceWs),
      0,
    ) || 0,
})

const AfaCartSummaryHeader: React.FC = () => {
  const { t } = useTranslation()
  const cartQuery = useGetAfaCartQuery()
  const showWhsPrice = useSelector(showWhsPriceSelector)

  const { doors, items, cartValue } = calculateTotals(cartQuery.data)

  const brandsQuery = useGetBrandsQuery()
  const currency = brandsQuery.data?.[0].currency || ''

  return (
    <Header>
      <TopHeader>
        <span>{t('GenericWords.order_summary')}</span>
        <AfaCartSummaryPagesSelector noData={!items} />
      </TopHeader>
      <BottomHeader>
        <div>
          {doors.toLocaleString()} {t('GenericWords.doors')}
        </div>
        <div className="center">
          {items.toLocaleString()} {t('Afa.Cart.Items')}
        </div>
        {showWhsPrice && (
          <div>
            <CartValue>{formatMoney(currency, cartValue)}</CartValue> {t('WallCartPage.cart')}{' '}
            {t('GenericWords.value')}
          </div>
        )}
      </BottomHeader>
    </Header>
  )
}

export default AfaCartSummaryHeader
