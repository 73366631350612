import {
  DuplicateInfo,
  DoorDuplicate,
  TopDiv,
  Wrapper,
  ButtonConfirmMod,
  DuplicateDataOnDoorErrorImage,
  DuplicateDataOnDoorErrorText,
  WrapperRow,
  ListWrapper,
  ListShadow,
  ErrorMessage,
} from '../AfaDuplicateCartOnDoorsModal'
import { useTranslation } from 'react-i18next'
import { ModalTitle } from '../../../../components/AfaCommonComponents'
import { StyledList } from '../AfaDuplicateCartOnDoorsModal'
import { errorNotification } from '../../../../components/Notification/notifications'
import React from 'react'
import { useSelector } from 'react-redux'
import { activeDoorsSelector } from '../../../../store/customer/selectors'

type Props = {
  duplicateCartOnDoorsResult: {
    data?: {
      warnings: { message: string }[]
    }
  }
  destinationDoorIds: string[]
  onClose: () => void
  listCSS: {
    itemSize: number
    listHeight: number
    scrollbarColor: string
    scrollbarBackgroundColor: string
  }
}

const ThirdPageAfaDuplicate: React.FC<Props> = ({
  duplicateCartOnDoorsResult,
  destinationDoorIds,
  onClose,
  listCSS,
}) => {
  const doors = useSelector(activeDoorsSelector)
  const { t } = useTranslation()

  const doorsFiltered = doors.filter(({ id }) => destinationDoorIds.includes(id))

  const deduplicatedWarnings = [
    ...new Set(duplicateCartOnDoorsResult.data?.warnings.map(({ message }) => message) || []),
  ]

  const error = !!deduplicatedWarnings.length

  const handleErrors = () => {
    if (deduplicatedWarnings.length) {
      deduplicatedWarnings.forEach(message => {
        errorNotification({ message })
      })
    }
  }

  return (
    <Wrapper>
      <TopDiv>
        <ModalTitle>{t('Afa.duplicateOrder')}</ModalTitle>
        {!error ? (
          <>
            <DuplicateInfo>{t('Afa.dataDuplicatedOnDoor')}</DuplicateInfo>
            <ListWrapper>
              <StyledList
                height={listCSS.listHeight}
                width={'100%'}
                itemCount={doorsFiltered.length}
                itemSize={listCSS.itemSize}
                scrollbarColor={listCSS.scrollbarColor}
                scrollbarBackgroundColor={listCSS.scrollbarBackgroundColor}
              >
                {({ index, style }) => {
                  const door = doorsFiltered[index]
                  return (
                    <WrapperRow key={`${door.id}-${index}`} style={style}>
                      <DoorDuplicate>
                        <span>
                          {door.id} - {door.name}
                        </span>
                      </DoorDuplicate>
                    </WrapperRow>
                  )
                }}
              </StyledList>
              <ListShadow />
            </ListWrapper>
          </>
        ) : (
          <ErrorMessage>{t('Afa.duplicateDataGeneralError')}</ErrorMessage>
        )}
      </TopDiv>
      <TopDiv>
        {error && (
          <TopDiv>
            <DuplicateDataOnDoorErrorImage />
            <DuplicateDataOnDoorErrorText>
              {t('Afa.duplicateDataOnDoorError')}
            </DuplicateDataOnDoorErrorText>
          </TopDiv>
        )}
        <ButtonConfirmMod
          onClick={() => {
            onClose()
            handleErrors()
          }}
        >
          {t('Afa.done')}
        </ButtonConfirmMod>
      </TopDiv>
    </Wrapper>
  )
}

export default ThirdPageAfaDuplicate
