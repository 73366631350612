export const customerKey = 'rc-intersession-customer-id'

export const storeIntersessionCustomerId = (customerId: string) => {
  if (customerId) {
    localStorage.setItem(customerKey, customerId)
  }
}

export const getIntersessionCustomerId = () => localStorage.getItem(customerKey)

export const deleteIntersessionCustomerId = () => {
  localStorage.removeItem(customerKey)
}
