import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { footerHeight, pxToRem, spacing } from '../../../style/theme'
import { useSelector } from 'react-redux'
import { isDigitalEventSelector } from '../../../store/app/selectors'
import { getDeviceAbsolutePath } from '../../../libs/url'
import { useGetBrandLogo } from '../../../services/afaContents'
import { brandSelectorByCode } from '../../../store/brands/selectors'

const Wrapper = styled.div`
  height: ${footerHeight};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 ${pxToRem(spacing(1))}rem;
  box-sizing: border-box;

  & > a {
    flex-grow: 1;
  }

  .brand-footer-logo-img {
    object-fit: contain;
    max-height: ${footerHeight};
    padding: ${pxToRem(spacing(1.25))}rem 0;
    width: auto;
    margin: 0 auto;
  }
`

type Props = {
  slug: string
  brandCode: string
}

export const BrandFooterLogo: React.FC<Props> = ({ slug, brandCode }) => {
  const isDe = useSelector(isDigitalEventSelector)
  const brand = useSelector(brandSelectorByCode(brandCode))
  const { brandLogo, isSuccess } = useGetBrandLogo(brandCode)
  return (
    <Wrapper>
      <Link
        to={
          isDe
            ? `/digital-events/single-brand/${slug}`
            : `${getDeviceAbsolutePath()}/single-brand/${slug}`
        }
      >
        {brandLogo || !isSuccess ? (
          <img
            className={'brand-footer-logo-img'}
            src={brandLogo}
            alt={brand?.brand || brandCode}
          />
        ) : (
          <span>{brand?.brand || brandCode}</span>
        )}
      </Link>
    </Wrapper>
  )
}

export default BrandFooterLogo
