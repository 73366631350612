import React, { ForwardedRef } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { isPdpVisibleSelector } from '../../store/pdp/selectors'
import { palette, zIndex } from '../../style/theme'
import RCIcon from '../UI/RCIcon'

const arrowStyle = css`
  svg {
    height: 50%;
    width: 50%;
  }
  position: absolute;
  min-height: 40px;
  min-width: 40px;
  height: 3vmin;
  width: 3vmin;
  background: rgb(217 217 217 / 70%);
  top: calc(50% - 18px);
  right: 0;
  z-index: ${zIndex.plpArrows};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px;
  border-radius: 5px;
  border: 1px solid ${palette.alto};
`

const DxArrow = styled.div`
  ${arrowStyle}
`

const SxArrow = styled.div`
  ${arrowStyle}
  right: auto;
  left: 0;
  transform: rotate(180deg);
`

export const LeftArrow: React.FC<{
  onClick?: () => void
  onMouseDown: () => void
  ref: ForwardedRef<HTMLDivElement>
}> = React.forwardRef(({ onClick, onMouseDown }, ref) => {
  const isPdpOpen = useSelector(isPdpVisibleSelector)

  return (
    <>
      {!isPdpOpen && (
        <SxArrow ref={ref} className="sxarrow" onClick={onClick} onMouseDown={onMouseDown}>
          <RCIcon type="sxarrow" />
        </SxArrow>
      )}
    </>
  )
})
LeftArrow.displayName = 'LeftArrow'

export const RightArrow: React.FC<{
  onClick?: () => void
  onMouseDown: () => void
  ref: ForwardedRef<HTMLDivElement>
}> = React.forwardRef(({ onClick, onMouseDown }, ref) => {
  const isPdpOpen = useSelector(isPdpVisibleSelector)

  return (
    <>
      {!isPdpOpen && (
        <DxArrow ref={ref} className="dxarrow" onClick={onClick} onMouseDown={onMouseDown}>
          <RCIcon type="dxarrow" />
        </DxArrow>
      )}
    </>
  )
})
RightArrow.displayName = 'RightArrow'
