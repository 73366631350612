import { slice } from './slice'
import { AppThunk } from '../../model/model'
import {
  cartProductsDetailsSelector,
  cartProductsFilteredByActiveDoorsSelector,
} from '../cart/selectors'
import { requestCalculateFacetsFromCart } from '../../libs/calculate-cart-facets'
import { selectCheckoutFilters } from './selectors'
import { CheckoutFilters } from '../../model/checkout'
import { selectedEventSelector } from '../app/selectors'

const updateFiltersAndCounters = (): AppThunk => (dispatch, getState) => {
  const state = getState()
  const cartProducts = cartProductsFilteredByActiveDoorsSelector(state)
  const cartProductsDetails = cartProductsDetailsSelector(state)
  const filters = selectCheckoutFilters(state)
  const event = selectedEventSelector(state)
  const releases = event?.releases?.map(release => release.label)
  return requestCalculateFacetsFromCart(cartProducts, cartProductsDetails, filters, releases).then(
    facets => {
      dispatch(slice.actions.updateFiltersCounters({ facets }))
    },
  )
}

const toggleFilter = (key: keyof CheckoutFilters, selectedId: string): AppThunk => (
  dispatch,
  getState,
) => {
  const state = getState()
  const filters = selectCheckoutFilters(state)
  const filterSelected = filters[key].selected
  const isFilterString = key === 'model' || key === 'reference'
  if (Array.isArray(filterSelected) && !isFilterString) {
    dispatch(
      slice.actions.setSelectedFilterOptions({
        key,
        selectedOptions: filterSelected.includes(selectedId)
          ? filterSelected.filter(id => id !== selectedId)
          : filterSelected.concat(selectedId),
      }),
    )
  } else if (isFilterString) {
    dispatch(
      slice.actions.setSelectedFilterString({
        key,
        value: filterSelected === selectedId ? '' : selectedId,
      }),
    )
  }
  dispatch(updateFiltersAndCounters())
}

const checkoutActions = {
  ...slice.actions,
  updateFiltersAndCounters,
  toggleFilter,
}

export default checkoutActions
