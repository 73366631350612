import { getAllCouvettes, getAllCouvettesByPost } from '../../api/restApi'
import app_config from '../../config/app/config'
import { couvetteContainsUpc } from '../../libs/couvettes'
import { handleErrors } from '../../libs/handleError'
import log from '../../libs/log'
import { isUpcCode } from '../../libs/productsV2'
import { AppThunk, AppThunkPromise, RootState, TDispatch } from '../../model/model'
import { Moco, Mocos, Product, QueryParams, Sizes } from '../../model/product'
import { StarsAssortment } from '../../model/stars'
import { getAdvContents, updateFiltersCounters } from '../actions'
import { dataLoadedFail } from '../app/actions'
import {
  customerIdSelector,
  eventIdSelector,
  eventsSelector,
  languageSelector,
} from '../app/selectors'
import { subbrandToMainBrandMapSelector } from '../brands/selectors'
import { setCouvettes } from '../couvettes/actions'
import { customerTypeSelector } from '../customer/selectors'
import initiativesActions from '../initiatives/actions'
import { openPDP } from '../pdp/actions'
import starsActions from '../stars/actions'
import { cartModeSelector, selectedMonthSelector } from '../stars/selectors'
import { slice } from './slice'

const dataSearchServerError = (error: Error): AppThunk => {
  return dispatch => {
    dispatch(
      slice.actions.searchModelsSuccess({
        couvettes: null,
      }),
    )
    dispatch(dataLoadedFail(error, app_config.errorTypes.SEARCH_FAILED))
  }
}

const handleWholesaleSearchResults = ({
  data,
  query,
  dispatch,
  getState,
  filterByUPC,
}: {
  data: { items: Product[]; nextModel: string }
  query: string
  dispatch: TDispatch
  getState: () => RootState
  filterByUPC?: boolean
}): {
  productsFound: boolean
  warnings: string[]
} => {
  const models: Product[] | null = data && data.items && data.items.length ? data.items : null
  const start = data && data.nextModel
  const warnings = []

  const isUpc = isUpcCode(query)

  if (!isUpc || !models || !models.length || filterByUPC) {
    dispatch(
      slice.actions.searchModelsSuccess({
        couvettes: models,
        start,
      }),
    )
    !isUpc && models && models.length && dispatch(setCouvettes({ items: models || [], start }))
  } else {
    const model = models[0]
    const mocosValues = Object.values(model.mocos).sort(
      (m1: Moco, m2: Moco) => m1.plpOrder - m2.plpOrder,
    )

    let moco: Moco = mocosValues[0]

    if (!couvetteContainsUpc(model, query)) {
      warnings.push(app_config.errorTypes.SEARCH_DISPLAYING_SIMILAR_SIZE_OR_COLOR)
    } else {
      moco = mocosValues.find((m: Moco) => m.sizes[query]) as Moco
      if (moco) {
        // If user searched by UPC we need to move MOCO containing that UPC to the top of couvette (RED-1340)
        moco.plpOrder = -1
      }
    }

    dispatch(
      slice.actions.searchModelsSuccess({
        couvettes: [model],
        start,
      }),
    )
    dispatch(openPDP(moco?.mocoCode, model.modelCode))
  }

  const state = getState()
  const eventId = eventIdSelector(state)
  const events = eventsSelector(state)
  const subbrandToMainBrandMap = subbrandToMainBrandMapSelector(state)
  if (events && events.length > 0 && eventId) {
    dispatch(
      getAdvContents(
        eventId,
        events,
        [...new Set(models?.map(({ brandCode }) => brandCode) || [])].map(
          brandCode => subbrandToMainBrandMap[brandCode],
        ),
      ),
    )
  }

  const productsFound = !!models
  return {
    productsFound,
    warnings,
  }
}

const handleStarsSearchResults = ({
  data,
  query,
  dispatch,
}: {
  data: StarsAssortment
  query: string
  dispatch: TDispatch
}): {
  productsFound: boolean
  warnings: string[]
} => {
  const brandCodes = Object.keys(data)

  dispatch(
    starsActions.setFilteredAssortment({
      starsAssortment: data,
      brandCodes,
    }),
  )

  const isUpc = isUpcCode(query)
  if (isUpc) {
    const moco = Object.values(data)
      .flatMap(({ productDetails }) => Object.values(productDetails))
      .flatMap(({ mocos }) => Object.values(mocos))
      .find(({ sizes }) => Object.values(sizes).some(({ upc }) => upc === query))
    if (moco) {
      dispatch(openPDP(moco.mocoCode, moco.modelCode))
    }
  }

  const productsFound = !!brandCodes.length
  return {
    productsFound,
    warnings: [],
  }
}

export const filterCatalogueByUpcs = (items: Product[], upcs: string[]) => {
  const filteredItems = items.reduce((acc, item) => {
    const newMocos = Object.entries(item.mocos).reduce((mocoAcc, [mocoKey, mocoValue]) => {
      const newSizes = Object.entries(mocoValue.sizes).reduce((sizeAcc, [sizeKey, sizeValue]) => {
        if (upcs.includes(sizeKey)) {
          sizeAcc[sizeKey] = sizeValue
        }
        return sizeAcc
      }, {} as Sizes)

      if (Object.keys(newSizes).length > 0) {
        mocoAcc[mocoKey] = { ...mocoValue, sizes: newSizes }
      }
      return mocoAcc
    }, {} as Mocos)

    if (Object.keys(newMocos).length > 0) {
      acc.push({
        ...item,
        mocos: newMocos,
      })
    }

    return acc
  }, [] as Product[])

  return filteredItems
}

export const searchCouvettes = (
  query: string,
  upcs?: string[],
  category?: string,
  sorting?: string,
): AppThunkPromise<{
  productsFound: boolean
  warnings: string[]
}> => {
  return (dispatch, getState) => {
    const state = getState()

    if (!state.auth.token) {
      return Promise.reject('missing token')
    }

    const catalog = cartModeSelector(state) === 'stars' ? 'STARS' : 'WHOLESALE'

    if (catalog === 'STARS') {
      dispatch(
        starsActions.setFilteredAssortment({
          starsAssortment: {},
          brandCodes: [],
        }),
      )
    }

    const month = selectedMonthSelector(state)
    const monthParams =
      catalog === 'STARS'
        ? {
            month,
            month_to: month,
          }
        : {}

    const customerType = customerTypeSelector(state)

    const queryParams: QueryParams = {
      customerId: customerIdSelector(state),
      eventId: eventIdSelector(state),
      showAttribute: true,
      customerType,
      catalog,
      ...monthParams,
    }

    if (query) {
      queryParams.search = query
    }

    if (!query && upcs) {
      queryParams.upc = upcs.join(',')
      queryParams.noPagination = true
      if (category) {
        queryParams.category = category
      }
      if (sorting) {
        queryParams.sort = sorting
      }
      queryParams.showFacet = true
    }
    const lang = languageSelector(state)
    return (queryParams.upc
      ? getAllCouvettesByPost(state.auth.token, lang, queryParams)
      : getAllCouvettes(state.auth.token, lang, queryParams)
    )
      .then(handleErrors)
      .then(data => {
        if (catalog === 'WHOLESALE') {
          const responseData = data
          if (!query && upcs) {
            const items = data.items as Product[]
            responseData.items = filterCatalogueByUpcs(items, upcs)
            if (responseData && responseData.filters && responseData.filters.length) {
              dispatch(updateFiltersCounters({ facets: responseData.filters, pageType: 'plp' }))
            }
          }

          return handleWholesaleSearchResults({
            data: responseData,
            query,
            dispatch,
            getState,
            filterByUPC: !query,
          })
        }

        return handleStarsSearchResults({
          data,
          query,
          dispatch,
        })
      })
      .catch(err => {
        log.error(err, 'getAllCouvettes')
        dispatch(dataSearchServerError(err))
        return {
          productsFound: false,
          warnings: ['ModalProductSearch.server_error_message'],
        }
      })
      .finally(() => dispatch(initiativesActions.setCategoryIsLoading(false)))
  }
}

export const toggleSearch = slice.actions.toggleSearch
export const hideSearch = slice.actions.hideSearch
export const showSearch = slice.actions.showSearch
export const searchModelsSuccess = slice.actions.searchModelsSuccess
export const resetSearchState = slice.actions.resetSearchState
