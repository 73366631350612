import React from 'react'
import styled from 'styled-components/macro'
import { getDeviceFromUrl } from '../../../libs/url'
import BackButton from './BackButton'
import CartButton from './CartButton'
import DoorsButton from './DoorsButton'
import PageSwitcher from './PageSwitcher'
import { getFluidSizeWithFullFormula } from '../../../style/theme'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  position: relative;
  padding: 0 ${getFluidSizeWithFullFormula('px', 24, 48, 1366, 3840)};
  height: ${getFluidSizeWithFullFormula('px', 104, 208, 1366, 3840)};
  font-family: 'EuclidCircularB-Regular', sans-serif;

  > *:only-child {
    grid-column-start: 2;
  }
`

const BackButtonStyled = styled(BackButton)`
  place-self: center start;
`

const RightCol = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 3vw;
  width: 100%;
`

const Footer: React.FC = () => {
  const deviceType = getDeviceFromUrl()

  return (
    <Wrapper>
      {deviceType !== 'wall' && <BackButtonStyled />}

      <PageSwitcher />

      {deviceType !== 'wall' && (
        <RightCol>
          <DoorsButton />
          <CartButton />
        </RightCol>
      )}
    </Wrapper>
  )
}

export default Footer
