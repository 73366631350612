import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { breakpoints, palette, pxToRem } from '../../../../../style/theme'
import { useSelector, useDispatch } from 'react-redux'
import { cartSummarySelectedPageSelector } from '../../../../../store/afaCartSummary/selectors'
import afaCartSummaryActions from '../../../../../store/afaCartSummary/actions'

const PagesSelector = styled.div`
  text-transform: capitalize;

  & div {
    display: inline-block;
    color: ${palette.tangaroa};
    font-size: ${pxToRem(24)}rem;
    font-family: GilmerMedium;
    letter-spacing: 0.3px;
    padding: 1em 2em;
    -webkit-box-shadow: inset 0px -0.3em 0px -1px ${palette.mischka};
    -moz-box-shadow: inset 0px -0.3em 0px -1px ${palette.mischka};
    box-shadow: inset 0px -0.3em 0px -1px ${palette.mischka};
    margin-left: 1em;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;

    @media (max-width: ${breakpoints.L}) {
      font-size: ${pxToRem(12)}rem;
    }

    &.active {
      -webkit-box-shadow: inset 0px -0.55em 0px -1px ${palette.tangaroa};
      -moz-box-shadow: inset 0px -0.55em 0px -1px ${palette.tangaroa};
      box-shadow: inset 0px -0.55em 0px -1px ${palette.tangaroa};
    }

    &.disabled {
      color: ${palette.mischka};
      cursor: not-allowed;
    }
  }
`
type Props = {
  noData?: boolean
}
const AfaCartSummaryPagesSelector: React.FC<Props> = ({ noData }) => {
  const { t } = useTranslation()

  const cartSummarySelectedPage = useSelector(cartSummarySelectedPageSelector)

  const dispatch = useDispatch()

  return (
    <PagesSelector>
      <div
        onClick={() => dispatch(afaCartSummaryActions.setCartSummarySelectedPage('graphs'))}
        className={cartSummarySelectedPage === 'graphs' ? 'active' : ''}
      >
        {t('Afa.page')} 1
      </div>
      {noData ? (
        <div className="disabled">{t('Afa.page')} 2</div>
      ) : (
        <div
          onClick={() => dispatch(afaCartSummaryActions.setCartSummarySelectedPage('table'))}
          className={cartSummarySelectedPage === 'graphs' ? '' : 'active'}
        >
          {t('Afa.page')} 2
        </div>
      )}
    </PagesSelector>
  )
}

export default AfaCartSummaryPagesSelector
