import qs from 'qs'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { createApi } from '@reduxjs/toolkit/query/react'

import app_config from '../config/app/config'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import {
  AfaCartProduct,
  AfaDrop,
  AfaDropRealtime,
  AfaDropsRealtimeByUpc,
  AfaMoco,
  AfaProduct,
} from '../model/afa'
import { customerIdSelector, eventIdSelector, languageSelector } from '../store/app/selectors'
import { useGetAfaWarehouse } from './afa'
import { afaProductsApi } from './afaProducts'
import { useGetAfaCartQuery } from './afaCart'
import { Upc } from '../model/model'
import { isCartProductFromDrop } from '../libs/afa'
import { useGetExpiredCartProducts } from '../hooks/useGetExpiredCartProducts'
import useAfaGetOutOfAssortmentCartProducts from '../hooks/useAfaGetOutOfAssortmentCartProducts'

const TAG_DROPS = 'afa-drops'
const TAG_DROPS_REALTIME = 'afa-drops-realtime'

export const afaProductApi = createApi({
  reducerPath: 'afaProductApi',
  tagTypes: [TAG_DROPS, TAG_DROPS_REALTIME],
  baseQuery: staggeredBaseQueryWithBailOut(`${app_config.apiUrl}/afa`),
  endpoints: builder => ({
    getProduct: builder.query<
      AfaProduct | null,
      {
        modelCode: string
        customerId: string
        eventId: string
        language: string
        afawarehouse: string
      }
    >({
      query: ({ modelCode, ...params }) => ({
        url: `/product/${modelCode}?${qs.stringify(params)}`,
      }),
    }),

    getProductDrops: builder.query<
      Record<Upc, AfaDrop[]>,
      {
        eventId: string
        doorIds?: string[]
        upcs: string[]
        customerId: string
      }
    >({
      query: ({ upcs, doorIds, customerId, ...query }) => ({
        url: `/product/drops?${qs.stringify(query)}`,
        method: 'POST',
        body: {
          upcs,
          doorIds,
          customerId,
        },
      }),
      providesTags: [TAG_DROPS],
    }),

    getProductDropsRealtime: builder.query<
      AfaDropsRealtimeByUpc,
      {
        eventId: string
        doorIds?: string[]
        upcs: AfaDropRealtime[]
        customerId: string
      }
    >({
      query: ({ upcs, doorIds, customerId, ...query }) => ({
        url: `/product/drops/realtime?${qs.stringify(query)}`,
        method: 'POST',
        body: {
          upcs,
          doorIds,
          customerId,
        },
      }),
      providesTags: [TAG_DROPS_REALTIME],
    }),
  }),
})

export const useGetProductMasterData = ({
  modelCode,
  brandCode,
}: {
  modelCode: string
  brandCode: string
}) => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)
  const afawarehouse = useGetAfaWarehouse()

  const { product } = afaProductsApi.useGetProductsQuery(
    { eventId, customerId, brandCode, afawarehouse: afawarehouse || '' },
    {
      skip: afawarehouse === undefined,
      selectFromResult: ({ data }) => ({
        product: data?.products.find(product => product.modelCode === modelCode),
      }),
    },
  )

  return product
}

export const useGetProductQuery = (modelCode?: string, options?: { skip?: boolean }) => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)
  const language = useSelector(languageSelector)
  const afawarehouse = useGetAfaWarehouse()

  return afaProductApi.useGetProductQuery(
    { modelCode: modelCode || '', eventId, customerId, language, afawarehouse: afawarehouse || '' },
    { skip: afawarehouse === undefined || !modelCode || options?.skip },
  )
}

export const useLazyGetProductQuery = () => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)
  const language = useSelector(languageSelector)
  const afawarehouse = useGetAfaWarehouse()

  const [trigger] = afaProductApi.useLazyGetProductQuery()

  const getProduct = (modelCode: string) => {
    return trigger({
      modelCode: modelCode || '',
      eventId,
      customerId,
      language,
      afawarehouse: afawarehouse || '',
    })
  }
  return getProduct
}

export const useGetRelatedProductsQuery = (modelCode?: string, colorCode?: string) => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)
  const afawarehouse = useGetAfaWarehouse()

  const productQuery = useGetProductQuery(modelCode)
  const product = productQuery.data

  const productKeylooks = product?.mocos?.[colorCode || '']?.keylooks?.join(',') || ''

  const query = afaProductsApi.useGetProductsQuery(
    {
      eventId,
      customerId,
      afawarehouse: afawarehouse || '',
      keylook: productKeylooks,
      sort: 'keylook,name,model',
      keylookfull: true,
    },
    {
      skip: afawarehouse === undefined || !product?.modelCode || !productKeylooks,
    },
  )

  const queryWithoutSourceProduct = useMemo(
    () => ({
      ...query,
      currentData: query.currentData?.products.filter(product => product.modelCode !== modelCode),
    }),
    [query, modelCode],
  )

  return queryWithoutSourceProduct
}

export const useGetCartDropsQuery = () => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  const cartQuery = useGetAfaCartQuery()

  const [upcs, doorIds] = useMemo(() => {
    const [_upcs, _doorIds] = (cartQuery.data || { items: [] }).items.reduce(
      (result, { upc, doorId }) => {
        result[0].push(upc)
        result[1].push(doorId)
        return result
      },
      [[], []] as string[][],
    )
    const upcs = [...new Set(_upcs)]
    const doorIds = [...new Set(_doorIds)]

    return [upcs, doorIds]
  }, [cartQuery.data])

  return afaProductApi.useGetProductDropsQuery(
    {
      eventId,
      // doorIds,
      upcs,
      customerId,
    },
    { skip: !doorIds.length || !upcs.length },
  )
}

export const useGetMocoDropsQuery = (moco: AfaMoco | undefined) => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  const upcs = Object.values(moco?.sizes || {}).map(({ upc }) => upc)

  return afaProductApi.useGetProductDropsQuery(
    {
      eventId,
      customerId,
      upcs,
    },
    {
      skip: upcs.length === 0,
    },
  )
}

export const useGetCartAvailabilityQuery = (cartProducts?: AfaCartProduct[]) => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)
  const cartQuery = useGetAfaCartQuery()
  const cartProductsToQuery = cartProducts !== undefined ? cartProducts : cartQuery.data?.items

  const cartUpcs = useMemo(() => [...new Set(cartProductsToQuery?.map(({ upc }) => upc))], [
    cartProductsToQuery,
  ])
  const cartDoors = useMemo(() => [...new Set(cartProductsToQuery?.map(({ doorId }) => doorId))], [
    cartProductsToQuery,
  ])

  return afaProductApi.useGetProductDropsQuery(
    {
      eventId,
      customerId,
      upcs: cartUpcs,
    },
    {
      skip: !cartDoors.length || cartUpcs.length === 0,
    },
  )
}

export const useLazyGetCartAvailabilityRealtimeQuery = (cartProducts?: AfaCartProduct[]) => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)
  const cartQuery = useGetAfaCartQuery()
  const cartProductsToQuery = cartProducts !== undefined ? cartProducts : cartQuery.data?.items
  const { data: cartAvailability } = useGetCartAvailabilityQuery(cartProductsToQuery)
  const [_getCartDrops, result] = afaProductApi.useLazyGetProductDropsRealtimeQuery()
  const { expiredDropsCartProducts } = useGetExpiredCartProducts()
  const outOfAssortmentCartProducts = useAfaGetOutOfAssortmentCartProducts()

  const excludedKeys = useMemo(() => {
    const outOfAssortmentKeys = outOfAssortmentCartProducts.map(({ key }) => key)
    const expiredDropsKeys = expiredDropsCartProducts.map(({ key }) => key)
    return outOfAssortmentKeys.concat(expiredDropsKeys)
  }, [outOfAssortmentCartProducts, expiredDropsCartProducts])

  const realtimeDropsPayload = Object.values(cartAvailability || {}).flatMap(drops => {
    return drops.flatMap(drop => {
      const cartProducts = (cartProductsToQuery || []).filter(cartProduct => {
        return isCartProductFromDrop(cartProduct, drop) && !excludedKeys.includes(cartProduct.key)
      })

      const dropsWithCartProducts = cartProducts.map(({ key, deliveryDate }) => {
        return {
          ...drop,
          key,
          deliveryDate,
        }
      })
      return dropsWithCartProducts
    })
  })

  const getCartDrops = useCallback(() => {
    if (realtimeDropsPayload.length > 0) {
      return _getCartDrops({
        eventId,
        customerId,
        upcs: realtimeDropsPayload,
      })
    } else {
      return Promise.resolve({ data: {} })
    }
  }, [realtimeDropsPayload, _getCartDrops, customerId, eventId])

  return [getCartDrops, result] as const
}
