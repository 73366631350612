import React from 'react'

const DuplicateIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    viewBox="0 0 16 16"
    height="16"
    width="16"
    fill="currentColor"
    className={['duplicate-icon', className].join(' ')}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.392 0h5.562c.381 0 .711 0 .984.022.288.024.578.076.858.218.415.212.752.55.964.964.142.28.194.57.218.858.022.272.022.603.022.984v5.562c0 .381 0 .712-.022.984a2.244 2.244 0 0 1-.218.858 2.206 2.206 0 0 1-.964.964c-.28.142-.57.194-.858.218-.273.022-.603.022-.984.022h-1.3v1.3c0 .381 0 .711-.022.984a2.245 2.245 0 0 1-.218.858 2.206 2.206 0 0 1-.964.964c-.28.142-.57.194-.858.218C9.32 16 8.99 16 8.608 16H3.046c-.38 0-.712 0-.984-.022a2.243 2.243 0 0 1-.858-.218 2.206 2.206 0 0 1-.964-.964 2.245 2.245 0 0 1-.218-.858C0 13.665 0 13.335 0 12.954V7.392c0-.381 0-.712.022-.985.024-.288.076-.577.218-.857.212-.415.55-.752.964-.964.28-.142.57-.195.858-.218.272-.022.603-.022.984-.022h1.3v-1.3c0-.38 0-.712.022-.984.023-.289.076-.578.218-.858.212-.415.549-.752.964-.964.28-.142.57-.194.857-.218C6.68 0 7.011 0 7.393 0zM3.075 5.86c-.418 0-.686 0-.89.017-.195.016-.263.043-.293.058a.691.691 0 0 0-.302.303c-.016.03-.042.098-.058.293-.017.203-.018.471-.018.89v5.504c0 .418.001.686.018.89.016.195.042.263.058.293a.69.69 0 0 0 .302.302c.03.016.098.043.293.058.204.017.472.018.89.018h5.504c.419 0 .687-.001.89-.018.195-.015.263-.042.293-.058a.691.691 0 0 0 .303-.302c.015-.03.042-.098.058-.293.016-.204.017-.472.017-.89V7.421c0-.419 0-.687-.017-.89-.016-.195-.043-.263-.058-.293a.692.692 0 0 0-.303-.303c-.03-.015-.098-.042-.293-.058a12.295 12.295 0 0 0-.89-.017H3.075zm8.58 4.28V7.392c0-.381 0-.712-.023-.985a2.245 2.245 0 0 0-.218-.857 2.206 2.206 0 0 0-.964-.964 2.245 2.245 0 0 0-.858-.218 12.972 12.972 0 0 0-.984-.022H5.86V3.075c0-.418 0-.686.017-.89.016-.195.043-.263.058-.293a.691.691 0 0 1 .303-.302c.03-.016.098-.042.293-.058.203-.017.471-.018.89-.018h5.504c.418 0 .686.001.89.018.195.016.263.042.293.058a.69.69 0 0 1 .302.302c.016.03.043.098.058.293.017.204.018.472.018.89v5.504c0 .419-.001.687-.018.89-.015.195-.042.263-.058.293a.691.691 0 0 1-.302.303c-.03.015-.098.042-.293.058-.204.016-.472.017-.89.017h-1.27z"
      fill="#031434"
    />
  </svg>
)

export default DuplicateIcon
