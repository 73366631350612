import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isIPadView, isTouchDevice } from '../../../libs/url'
import classnames from 'classnames'
import { breakpointsNoUnit, getFluidFontSize, palette } from '../../../style/theme'
import styled from 'styled-components'

const ErrorMessage = styled.span`
  font-size: ${getFluidFontSize('14px')};
  color: ${palette.red};
`
const Wrapper = styled.div`
  .ant-input {
    font-size: ${getFluidFontSize('16px')};
  }
`

class KeyboardInput extends Component {
  state = {
    isEligibleDevice: false,
    errorMessage: null,
    input: null,
    showKeyboard: false,
  }

  isTable = () => (window.innerWidth > 0 ? window.innerWidth : screen.width) >= breakpointsNoUnit.XL

  componentDidMount = () => {
    if (isTouchDevice() && this.isTable()) {
      this.setState({ isEligibleDevice: true })
    }

    if (this.props.showKeyboardOnInit) {
      this.handleFocus()
    }

    if (this.props.forceShow) this.setState({ showKeyboard: true })
    this.input.addEventListener('input', this.handleChange)
  }

  componentWillUnmount = () => {
    this.input.removeEventListener('input', this.handleChange)
  }

  handleChange = event => {
    this.props.onChange(event.target.value)
  }

  handleEnter = event => {
    if (event.key === 'Enter') {
      this.hideKeyboard()
    }
  }

  handleFocus = () => {
    if (typeof this.props.onFocus === 'function') {
      this.props.onFocus()
      this.props.textarea && this.input.focus()
    }
    this.setState({ showKeyboard: !isIPadView() })
  }

  handleFocusLost = event => {
    const { relatedTarget } = event
    if (
      !relatedTarget ||
      (!relatedTarget.classList?.contains('keyboard-button') &&
        !relatedTarget.classList?.contains('keyboard') &&
        !relatedTarget.classList?.contains('keyboard-row') &&
        !relatedTarget.classList?.contains('react-draggable-transparent-selection') &&
        !this.props.hidingKeyboardDisabled &&
        (isIPadView() || this.state.showKeyboard !== false)) // avoids this.props.onHideKeyboard to be called twice
    ) {
      if (typeof this.props.onBlur === 'function') {
        this.props.onBlur()
        this.props.textarea && this.input.blur()
      }
      this.hideKeyboard()
    }
  }

  hideKeyboard = () => {
    if (!this.props.hidingKeyboardDisabled) {
      this.setState({ showKeyboard: false })
      if (this.props.onHideKeyboard) {
        this.props.onHideKeyboard()
      }
    } else {
      this.onSubmit()
    }
  }

  onSubmit = () => {
    const { onSubmit, validateInput } = this.props
    if (validateInput) {
      const validationResult = validateInput(this.input.value)
      const { hasError, error } = validationResult
      const errorMessage = hasError ? error : null
      this.setState({
        errorMessage,
      })

      if (hasError) return
    }
    onSubmit()
  }

  render = () => {
    const { textarea } = this.props

    return (
      <Wrapper className={classnames('keyboard-input', this.props.containerClassName)}>
        {textarea ? (
          <textarea
            data-testid="alphanumeric-keybard-input"
            disabled={!this.props.enabled ? 'disabled' : ''}
            name={this.props.name}
            className={this.props.inputClassName + ' input'}
            placeholder={this.props.placeholder}
            value={this.props.value}
            type={this.props.type}
            onFocus={this.handleFocus}
            onBlur={this.handleFocusLost}
            min={this.props.min}
            max={this.props.max}
            step={this.props.step}
            pattern={this.props.pattern}
            onChange={this.handleChange}
            readOnly={this.props.readOnly === true}
            onKeyUp={this.handleEnter}
            autoFocus={this.props.autoFocus}
            ref={e => {
              this.input = e
            }}
          />
        ) : (
          <input
            data-testid="alphanumeric-keybard-input"
            disabled={!this.props.enabled ? 'disabled' : ''}
            name={this.props.name}
            className={this.props.inputClassName + ' input'}
            placeholder={this.props.placeholder}
            value={this.props.value}
            type={this.props.type}
            onFocus={this.handleFocus}
            onBlur={this.handleFocusLost}
            min={this.props.min}
            max={this.props.max}
            step={this.props.step}
            pattern={this.props.pattern}
            onChange={this.handleChange}
            readOnly={this.props.readOnly === true}
            onKeyUp={this.handleEnter}
            autoFocus={this.props.autoFocus}
            ref={e => {
              this.input = e
            }}
          />
        )}
        {this.state.errorMessage && <ErrorMessage>{this.state.errorMessage}</ErrorMessage>}
      </Wrapper>
    )
  }
}

KeyboardInput.propTypes = {
  containerClassName: PropTypes.string,
  enabled: PropTypes.any,
  forceShow: PropTypes.bool,
  hidingKeyboardDisabled: PropTypes.bool,
  inputClassName: PropTypes.any,
  max: PropTypes.any,
  min: PropTypes.any,
  name: PropTypes.any,
  onChange: PropTypes.func,
  onHideKeyboard: PropTypes.func,
  onSubmit: PropTypes.func,
  pattern: PropTypes.any,
  placeholder: PropTypes.any,
  readOnly: PropTypes.any,
  showKeyboardOnInit: PropTypes.bool,
  step: PropTypes.any,
  type: PropTypes.any,
  validateInput: PropTypes.func,
  value: PropTypes.any.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  textarea: PropTypes.bool,
  autoFocus: PropTypes.bool,
}

export default KeyboardInput
