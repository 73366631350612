import React, { SVGProps } from 'react'

const HomeIconDE: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21.357001 19.014372"
    width="18.071308"
    height="21.607241"
    fill="currentColor"
    {...props}
  >
    <path d="m 10.68,-2.6698077 a 1.5,1.5 0 0 0 -1.046999,0.4199999 l -8.573,8.3380001 A 3.495,3.495 0 0 0 9.6169599e-7,8.5961923 V 20.184192 c 0,0.822 0.67699999830401,1.5 1.50000003830401,1.5 H 19.857 c 0.822,0 1.5,-0.677 1.5,-1.5 V 8.5961923 c 0,-0.946 -0.383,-1.851 -1.06,-2.51 L 11.725,-2.2478078 A 1.493,1.493 0 0 0 10.68,-2.6698077 Z m -0.351999,1.1389999 a 0.494,0.494 0 0 1 0.699999,0 L 19.6,6.8031923 c 0.484,0.47 0.757,1.118 0.757,1.793 V 20.184192 c 0,0.28 -0.22,0.5 -0.5,0.5 H 1.500001 a 0.493,0.493 0 0 1 -0.5,-0.5 V 8.5961923 c 0,-0.675 0.274,-1.322 0.758,-1.791 z" />
  </svg>
)

export default HomeIconDE
