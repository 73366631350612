import styled, { css } from 'styled-components/macro'
import { Rnd } from 'react-rnd'
import {
  breakpointsCross,
  getFluidFontSize,
  getFluidSizeWithFullFormula as gF,
  palette,
  pxToRem,
} from '../../../../../style/theme'
import { checkImageValidity } from '../ProductsList/style'

export const HorizontalCenter = styled.div`
  height: 100%;
  width: 1px;
  border-left: 1px solid red;
  position: absolute;
  top: 0;
  left: 50%;
`

export const VerticalCenter = styled.div`
  height: 1px;
  width: 100%;
  border-top: 1px solid red;
  position: absolute;
  top: 50%;
  left: 0;
`

export const DraggableComponent = styled(Rnd)`
  position: relative;
  text-align: center;
  min-width: 10%;
  box-sizing: content-box;
  outline: 3px solid transparent;
  border-radius: 4px;
  cursor: default !important;
  outline-offset: -3px;

  &.text {
    min-height: ${pxToRem(40)}rem;
    height: unset;

    .wrapper {
      position: relative;
    }

    .overlay {
      background-color: transparent;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000000000;
    }

    textarea {
      resize: none;
      width: 100%;
      min-height: 40px;
      height: 100%;
      line-height: 1.5em;
      padding: ${pxToRem(10)}rem;
      font-size: ${getFluidFontSize('12px')};
      text-align: center;
      cursor: inherit;
      font-family: GilmerMedium, sans-serif;
      color: ${palette.tangaroa};
      outline: none;
      border: none;
      background-color: transparent;
      //caret-color: transparent;

      @media (min-width: ${breakpointsCross.XL.min}) {
        padding: ${pxToRem(20)}rem;
        font-size: ${pxToRem(28)}rem;
      }

      &[editing='true'] {
        caret-color: initial;
      }

      &.no-blink {
        caret-color: transparent;
      }

      &.selected {
        cursor: grab !important;
      }

      &::placeholder {
        color: ${palette.santasGray};
      }
    }
  }

  &.selected {
    cursor: grab !important;
    outline-color: ${palette.shadowBlue};

    .cursor-pointer {
      display: block;
    }
  }

  &.whiteboardIsLocked {
    cursor: default !important;
  }

  &.multiselectEnabled {
    cursor: default !important;

    .cursor-pointer {
      > div {
        display: none;
      }
    }
  }

  &.isTouchDevice {
    .cursor-pointer {
      > div {
        border-radius: 4px;
        border: 3px solid ${palette.shadowBlue};
        background-color: white;
      }
    }
  }

  .cursor-pointer {
    display: none;

    > div {
      :nth-of-type(1),
      :nth-of-type(2),
      :nth-of-type(3),
      :nth-of-type(4) {
        display: none;
      }

      &:nth-of-type(5),
      &:nth-of-type(6),
      &:nth-of-type(7),
      &:nth-of-type(8) {
        width: ${gF('px', 26, 48, 1366, 3840)} !important;
        height: ${gF('px', 26, 48, 1366, 3840)} !important;
      }
    }
  }
`

export const Image = styled.img`
  display: block;
  height: 100%;
  object-fit: contain;
  width: 100%;
  background-color: ${palette.white};
  ${checkImageValidity};
  border: none;
`

export const Info = styled.p<{ inline?: boolean }>`
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: GilmerMedium, sans-serif;
  font-size: ${pxToRem(10)}rem;
  display: ${({ inline }) => (inline ? 'inline' : 'block')};

  @media (min-width: ${breakpointsCross.M.over}) and (max-width: ${breakpointsCross.XL.max}) {
    font-size: ${pxToRem(14)}rem;
  }

  @media (min-width: ${breakpointsCross.XL.min}) {
    font-size: ${pxToRem(22)}rem;
  }

  &.OOA {
    color: ${palette.santasGray};
    letter-spacing: 0.5px;
    //font-size: ${gF('px', 8, 18, 1366, 3840)};
    white-space: nowrap;
  }
  &.product-name {
    //font-size: ${gF('px', 10, 22, 1366, 3840)};
    width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    font-family: GilmerBold, sans-serif;
  }
  &.product-number,
  &.color-code,
  &.color-name,
  &.suggested-price,
  &.wholesale-price {
    //font-size: ${gF('px', 8, 18, 1366, 3840)};
  }
  &.wholesale-price {
    font-family: 'GilmerBold', sans-serif;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  div {
    font-size: calc(12px + 12 * ((100vw - 1024px) / 2816));
    font-family: GilmerMedium, sans-serif;
    display: inline-block;
    text-align: center;
    resize: none;
    width: 100%;
    min-height: 44px;
    height: 100%;
    line-height: ${pxToRem(26)}rem;
    padding: ${pxToRem(10)}rem;
  }

  @media (min-width: ${breakpointsCross.XL.min}) {
    min-height: 96px;
  }
`

export const Resize = styled.div`
  width: 100%;
  position: relative;
  text-align: right;

  span {
    position: absolute;
    user-select: none;
    width: ${gF('px', 26, 48, 1366, 3840)};
    height: ${gF('px', 26, 48, 1366, 3840)};
    right: -10px;
    bottom: -10px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 3px solid ${palette.shadowBlue};
    background-color: white;
  }
`

export const Label = styled.div<{ width: number; height: number }>`
  pointer-events: none;

  &.top {
    width: 200%;
    left: -50%;
    position: absolute;
    top: 0;
    transform: translate(0, -100%);
    text-align: center;
    align-items: center;
  }

  &.bottom {
    padding-top: 10px;
    width: 200%;
    left: -50%;
    position: relative;
    text-align: center;
    align-items: center;
  }

  &.right {
    width: 200%;
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
    text-align: left;
    padding-left: 10px;
  }

  &.left {
    width: 200%;
    position: absolute;
    top: 50%;
    transform: translate(-100%, -50%);
    text-align: right;
    padding-right: 10px;
  }
`

export const Icon = styled.span`
  display: block;
  position: absolute;
  line-height: 1;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  width: ${gF('px', 32, 64, 1366, 3840)};
  height: ${gF('px', 32, 64, 1366, 3840)};
`

const DIMENSIONS = 10 //percent

const commonCornersCss = css`
  width: ${DIMENSIONS}%;
  height: ${DIMENSIONS}%;
  position: absolute;
  z-index: 9;
  border: 3px solid ${palette.shadowBlue};
  border-radius: 4px;
  background-color: white;
  pointer-events: none;
`

const position = `-${DIMENSIONS * 0.45}%`

export const TopLeft = styled.div`
  ${commonCornersCss};
  top: ${position};
  left: ${position};
`

export const TopRight = styled.div`
  ${commonCornersCss};
  top: ${position};
  right: ${position};
`

export const BottomLeft = styled.div`
  ${commonCornersCss};
  left: ${position};
  bottom: ${position};
`

export const BottomRight = styled.div`
  ${commonCornersCss};
  right: ${position};
  bottom: ${position};
`
