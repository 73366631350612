import styled, { css } from 'styled-components/macro'
import { getFluidSizeWithFullFormula as gF, palette } from './style/theme'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { StyledCheckBox } from './containers/CheckoutPage/components/Common'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from './hooks/useSearchParams'
import { useTranslation } from 'react-i18next'
import appActions from './store/app/actions'
import { appSelectors } from './store/app/selectors'

const Wrapper = styled.div`
  position: absolute;
  top: 1vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${Number.MAX_SAFE_INTEGER};
  font-size: ${gF('px', 13, 26, 1366, 3840)};

  * {
    user-select: none;
  }
`

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const buttonsCss = css`
  border-radius: ${gF('px', 5, 10, 1366, 3840)};
  text-align: center;
  width: 45%;
`

const HideButton = styled.div`
  background-color: ${palette.mango};
  ${buttonsCss}
`

const DestroyButton = styled.div`
  background-color: ${palette.milanoRed};
  color: white;
  ${buttonsCss}
`

const dbgH = 20 //debugger item min-height in px for iPad view

const Menu = styled.div<{ rows: number }>`
  border-radius: 20rem;
  background-color: ${palette.white};
  color: ${palette.tangaroa};
  font-family: GilmerBold, serif;
  border: ${gF('px', 2, 4, 1366, 3840)} solid ${palette.white};
  box-shadow: 0 ${gF('px', 4, 8, 1366, 3840)} ${gF('px', 4, 8, 1366, 3840)} 0 rgba(3, 20, 52, 0.17);
  width: ${gF('px', 90, 180, 1366, 3840)};
  height: ${gF('px', 35, 70, 1366, 3840)};
  transition: height 0.3s ease-in-out, border-radius 0.3s ease-in-out, width 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;

  &:active {
    box-shadow: 0 ${gF('px', 2, 4, 1366, 3840)} ${gF('px', 2, 4, 1366, 3840)} 0
      rgba(3, 20, 52, 0.17);
    transform: translateY(${gF('px', 2, 4, 1366, 3840)});
  }

  &.open {
    height: ${({ rows }) => gF('px', 90 + dbgH * 1.5 * rows, 180 + dbgH * 3 * rows, 1366, 3840)};
    border-radius: 1rem;
    width: ${gF('px', 240, 480, 1366, 3840)};
  }
`

const Toggle = styled.button`
  width: 100%;
  min-height: ${gF('px', 35, 70, 1366, 3840)};
  text-transform: uppercase;
`

const DebuggerList = styled.ul`
  width: 100%;
  padding: ${gF('px', 10, 20, 1366, 3840)};
  text-transform: capitalize;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: ${gF('px', 10, 20, 1366, 3840)};

  * {
    cursor: pointer;
  }

  > *:last-child {
    margin-top: auto;
  }
`

const DebuggerItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: ${gF('px', 5, 10, 1366, 3840)};
  min-height: ${gF('px', dbgH, dbgH * 2, 1366, 3840)};
`

const CheckBox = styled(StyledCheckBox)`
  width: ${gF('px', 15, 30, 1366, 3840)};
  height: ${gF('px', 15, 30, 1366, 3840)};
  border-color: black;

  &:checked {
    background: black;
    border-color: black;
  }
`

export enum DebuggerFeatures {
  TOKEN_TO_LOCALHOST,
  SHOW_TEXT_KEYS,
  SHOW_REDUX_STATE,
  FRAMES_ASSORTMENT_ADVISOR_OVERLAY,
  FRAMES_SHOW_BRAND_CODES,
  AFA_AVAILABILITY_CHECK_BYPASS,
  AFA_ADJUST_CHECKOUT_BYPASS,
  AFA_SHOW_UPCS,
  AFA_WHITEBOARD_DEBUGGER,
  AFA_ALWAYS_EXPIRED_DROP,
}

const DEBUGGER_KEY_LC = 'RcDebugger' //key written in local storage
const DEBUGGER_PARAM = 'debug' //key written in url param

type Debugger = {
  id: number
  label: string
  category?: 'afa' | 'frames'
}

const listOfDebuggers = [
  {
    id: DebuggerFeatures.SHOW_TEXT_KEYS,
    label: 'show text keys',
  },
  {
    id: DebuggerFeatures.SHOW_REDUX_STATE,
    label: 'show redux state (console)',
  },
  {
    id: DebuggerFeatures.TOKEN_TO_LOCALHOST,
    label: 'token to localhost',
  },
  {
    id: DebuggerFeatures.AFA_SHOW_UPCS,
    label: 'show upcs',
    category: 'afa',
  },
  {
    id: DebuggerFeatures.AFA_WHITEBOARD_DEBUGGER,
    label: 'whiteboards debugger',
    category: 'afa',
  },
  {
    id: DebuggerFeatures.AFA_ADJUST_CHECKOUT_BYPASS,
    label: 'bypass adjust checkout',
    category: 'afa',
  },
  {
    id: DebuggerFeatures.AFA_AVAILABILITY_CHECK_BYPASS,
    label: 'bypass availability check',
    category: 'afa',
  },
  {
    id: DebuggerFeatures.AFA_ALWAYS_EXPIRED_DROP,
    label: 'always expired drop',
    category: 'afa',
  },
  {
    id: DebuggerFeatures.FRAMES_ASSORTMENT_ADVISOR_OVERLAY,
    label: 'show AA heatmap overlay',
    category: 'frames',
  },
  {
    id: DebuggerFeatures.FRAMES_SHOW_BRAND_CODES,
    label: 'show brand codes',
    category: 'frames',
  },
] as Debugger[]

const Debug: React.FC = () => {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const [searchParams, setParams] = useSearchParams()
  const wrapperRef = useRef<HTMLDivElement>(null)

  const activeDebuggers = useSelector(appSelectors.activeDebuggers)
  const reduxState = useSelector(state => state)

  const debugParam = searchParams.get(DEBUGGER_PARAM)
  const storedDebugger = localStorage.getItem(DEBUGGER_KEY_LC)
  const isAfa = window.location.href.includes('afa')

  const [open, setOpen] = useState(false)
  const [hide, setHide] = useState(false)

  if (activeDebuggers.includes(DebuggerFeatures.SHOW_REDUX_STATE)) {
    // eslint-disable-next-line no-console
    console.clear()
    // eslint-disable-next-line no-console
    console.log(reduxState)
  }

  const toggleDebugger = (debuggerItem: number) => {
    dispatch(appActions.toggleActiveDebug(debuggerItem))
  }

  const setDestroy = useCallback(() => {
    setHide(true)
    setOpen(false)
    dispatch(appActions.setDebug([]))
    localStorage.removeItem(DEBUGGER_KEY_LC)
  }, [dispatch])

  const handleClickOutside = (event: Event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setOpen(false)
    }
  }

  const handleDebugToggle = useCallback(
    (event: KeyboardEvent) => {
      //(CTRL for Windows || COMMAND for Mac) + SHIFT + SPACE
      if (
        (event.ctrlKey || event.metaKey) &&
        event.shiftKey &&
        event.code.toLowerCase() === 'space'
      ) {
        setOpen(false)

        if (storedDebugger) {
          setDestroy()
        } else {
          localStorage.setItem(DEBUGGER_KEY_LC, 'true')
          setHide(false)
        }
      }
    },
    [setDestroy, storedDebugger],
  )

  const splitDebuggers = (debuggerItem: Debugger) => {
    const { category } = debuggerItem
    if (category) {
      return isAfa ? debuggerItem.category === 'afa' : debuggerItem.category === 'frames'
    } else {
      return true
    }
  }

  useEffect(() => {
    if (activeDebuggers.includes(DebuggerFeatures.SHOW_TEXT_KEYS)) {
      i18n.changeLanguage('cimode')
    } else {
      i18n.changeLanguage()
    }
  }, [activeDebuggers, i18n])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    document.addEventListener('keydown', handleDebugToggle)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
      document.removeEventListener('keydown', handleDebugToggle)
    }
  }, [handleDebugToggle])

  useEffect(() => {
    if (debugParam) {
      localStorage.setItem(DEBUGGER_KEY_LC, 'true')
      searchParams.delete(DEBUGGER_PARAM)
      setParams(searchParams, { replace: true })
    }
  }, [debugParam, searchParams, setParams])

  useEffect(() => {
    if (storedDebugger) {
      setHide(false)
    } else {
      setHide(true)
    }
  }, [storedDebugger])

  if (hide) return null
  return (
    <Wrapper ref={wrapperRef}>
      <Container>
        <Menu rows={listOfDebuggers.filter(splitDebuggers).length} className={open ? 'open' : ''}>
          <Toggle className={open ? 'open' : ''} onClick={() => setOpen(!open)}>
            debug
          </Toggle>
          <DebuggerList>
            {listOfDebuggers.filter(splitDebuggers).map(debuggerItem => {
              const { id, label } = debuggerItem

              return (
                <DebuggerItem onClick={() => toggleDebugger(id)} key={id}>
                  <CheckBox readOnly checked={activeDebuggers.includes(id)} />
                  <label>{label}</label>
                </DebuggerItem>
              )
            })}

            <ButtonsWrapper>
              <HideButton onClick={() => setHide(true)}>hide</HideButton>
              <DestroyButton onClick={() => setDestroy()}>destroy</DestroyButton>
            </ButtonsWrapper>
          </DebuggerList>
        </Menu>
      </Container>
    </Wrapper>
  )
}

export default Debug
