import React, { createRef, useEffect } from 'react'
import { useParams } from 'react-router'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import AfaFooter from '../../components/AfaFooter'
import AfaSearch from '../../components/AfaSearch/AfaSearch'
import AfaModal from '../../components/AfaModal'
import AfaSwitchMoodboardButton from '../../components/AfaSwitchPdpMoodboardButton'
import AfaHeader from '../../components/Header/AfaHeader'
import useFetchInitialData from '../../hooks/useFetchInitialData'
import { useGetAfaProductSport } from '../../hooks/useGetAfaProductSport'
import { useHandleOpenAfaPdpPage, useShowMoodboard } from '../../hooks/useHandleOpenPages'
import { useSearchParams } from '../../hooks/useSearchParams'
import { getDefaultColor } from '../../libs/afa'
import { getDeviceFromUrl } from '../../libs/url'
import { useGetProductQuery } from '../../services/afaProduct'
import { breakpoints, pxToRem } from '../../style/theme'
import AfaMoodboard from '../AfaMoodboard/AfaMoodboard'
import AfaPdpDetailsTab from './AfaPdpDetailsTab'
import AfaPdpDropAndSizeTab from './AfaPdpDropAndSizeTab'
import AfaRemoveExpiredItemsModal from '../AfaRemoveExpiredItemsModal'
import { CustomAfaModal } from '../AfaKeylooks/AfaKeylooksMain'
import { openPinModalSelector } from '../../store/whiteboard/selectors'
import whiteboardActions from '../../store/whiteboard/actions'
import WhiteboardModalPin from '../Whiteboard/WhiteboardModalPin'
import afaCartAdjustActions from '../../store/afaCartAdjust/actions'
import { afaCartAdjustSelectors } from '../../store/afaCartAdjust/selectors'

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  --header-height: ${pxToRem(160)}rem;

  @media screen and (max-width: ${breakpoints.L}) {
    --header-height: ${pxToRem(100)}rem;
  }
`

const Main = styled.main`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
`

const AfaPdp: React.FC = () => {
  useFetchInitialData({ loadCustomer: true })
  const openPinModal = useSelector(openPinModalSelector)

  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const selectedColorCode = searchParams.get('color')

  const location = useLocation()

  const { modelCode } = useParams<{ modelCode: string }>()

  const mainRef = createRef<HTMLDivElement>()

  const productQuery = useGetProductQuery(modelCode)
  const product = productQuery.data || undefined

  const defaultColor = getDefaultColor(product)

  const selectedColor = selectedColorCode ? product?.mocos[selectedColorCode] : defaultColor

  const sport = useGetAfaProductSport()

  useHandleOpenAfaPdpPage(product?.brandCodeParent, sport)
  const { moodboardOpenOn } = useShowMoodboard(product?.brandCodeParent, sport)

  const selectedTab = searchParams.get('tab') || 'details'

  const expiredCartProductsToRemove = useSelector(
    afaCartAdjustSelectors.expiredCartProductsToRemove,
  )

  useEffect(() => {
    mainRef.current?.scrollTo(0, 0)
  }, [location.key, mainRef])

  const device = getDeviceFromUrl()
  return moodboardOpenOn === device ? (
    <AfaMoodboard sport={selectedColor?.sports?.[0]} brandCode={product?.brandCodeParent} />
  ) : (
    <Wrapper>
      <AfaSearch />

      <AfaHeader prependElement={<AfaSwitchMoodboardButton />} />

      {/* the key is to force the rerendering of the element when the model code changes, to scroll it back to top, RED-5345 */}
      <Main ref={mainRef} key={modelCode}>
        {selectedTab === 'details' ? <AfaPdpDetailsTab /> : <AfaPdpDropAndSizeTab />}
      </Main>

      <AfaFooter productBrand={product?.brandCodeParent} />

      <CustomAfaModal
        isOpen={openPinModal}
        closeOnBackdropClick
        onRequestClose={() => {
          dispatch(whiteboardActions.setOpenPinModal(false))
          dispatch(whiteboardActions.resetItemsPinModal())
        }}
        fullscreen={false}
      >
        <WhiteboardModalPin />
      </CustomAfaModal>

      <AfaModal
        isOpen={expiredCartProductsToRemove.length > 0}
        onRequestClose={() => dispatch(afaCartAdjustActions.setCartExpiredProductsToRemove([]))}
      >
        <AfaRemoveExpiredItemsModal />
      </AfaModal>
    </Wrapper>
  )
}

export default AfaPdp
