import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import React from 'react'
import { Bar } from 'react-chartjs-2'

import { BAR_MIN_VALUE } from './config'

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title)

/**
 * Returns stepSize of bar chart
 * If max chart value is smaller than BAR_MIN_VALUE, sets stepSize to 1 to prevent decimal stepSizes
 * Otherwise Chart library sets it automatically to integer
 */
const getBarStepSize = (totalMaxValue = 0, isChartWithMaxValue = false) => {
  return totalMaxValue < BAR_MIN_VALUE ? (isChartWithMaxValue ? BAR_MIN_VALUE : 1) : undefined
}

const BarGraph: React.FC<{
  datasets: { data: number[]; label: string }[]
  labels: string[]
  max?: number
  className?: string
  showLegend?: boolean
}> = ({ datasets, labels, max, className, showLegend }) => {
  // Calculating maximum value in bar chart
  let barMaxValue = 0
  const stackedItemsCount = datasets.length ? datasets[0].data.length : 0
  for (let i = 0; i < stackedItemsCount; i++) {
    const itemValue = datasets.reduce((prev, currentDataset) => prev + currentDataset.data[i], 0)
    if (itemValue > barMaxValue) barMaxValue = itemValue
  }

  return (
    <div>
      <Bar
        data={{
          labels,
          datasets,
        }}
        options={{
          plugins: {
            legend: {
              display: !!showLegend,
              reverse: true,
              labels: {
                font: {
                  size: 18,
                },
              },
            },
          },
          maintainAspectRatio: false,
          scales: {
            xAxes: {
              stacked: true,
            },
            yAxes: {
              stacked: true,
              max,
              beginAtZero: true,
              ticks: {
                stepSize: getBarStepSize(barMaxValue, !!max),
              },
            },
          },
          hover: {
            mode: 'nearest' as const,
          },
        }}
        width={100}
        height={400}
        className={className}
      />
    </div>
  )
}

export default BarGraph
