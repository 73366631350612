import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'
import qs from 'qs'

import RCIcon from '../../components/UI/RCIcon'
import { useShowMoodboard } from '../../hooks/useHandleOpenPages'
import { useIsKeyLooksPage } from '../../hooks/useIsKeyLooksPage'
import { useSearchParams } from '../../hooks/useSearchParams'
import { getDeviceAbsolutePath } from '../../libs/url'
import { cmsTagAfaSelector } from '../../store/app/selectors'
import {
  breakpoints,
  getFluidFontSize,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
} from '../../style/theme'
import AfaPlpFooterSportSwitcher from './AfaPlpFooterSportSwitcher'
import { useGetBrandCategoriesQuery } from '../../services/afa'
import { CategoriesFieldOfBrand } from '../../model/afa'
import { sortCategoriesFunction } from '../../libs/afa'

const Wrapper = styled.div`
  position: relative;
  display: grid;
  background-color: ${palette.tangaroa};
  grid-template-columns: 1fr auto 1fr;
  padding: 1em 2em;

  @media screen and (max-width: ${breakpoints.L}) {
    grid-template-columns: 1fr auto auto;
  }
`

const PlpSwitcher = styled.div`
  display: flex;
  width: fit-content;
  color: ${palette.white};
  border-radius: ${pxToRem(4)}rem;
  border: solid 1px ${palette.shadowBlue};
  font-size: ${getFluidSizeWithFullFormula('px', 14, 20, 1366, 3840)};
`

const PlpSwitcherTab = styled(Link)`
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: ${pxToRem(10)}em ${pxToRem(20)}em;
  border-radius: 4px;
  text-decoration: none;
  color: ${palette.white};
  line-height: 1;

  &.active {
    background-color: ${palette.white};
    color: ${palette.tangaroa};
    font-weight: bold;
    text-decoration: none;
  }
`

const Button = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: ${getFluidFontSize('12px')};
  color: ${palette.white};
  gap: ${pxToRem(6)}rem;
  cursor: pointer;
  font-weight: bold;

  @media screen and (max-width: ${breakpoints.M}) {
    display: none;
  }
`

const AfaPlpFooter: React.FC = () => {
  const { t } = useTranslation()

  const location = useLocation()

  const { assortment, brandCode } = useParams<{ assortment: string; brandCode: string }>()

  const categoriesQuery = useGetBrandCategoriesQuery(brandCode)
  const categories = categoriesQuery.data?.categories || []

  const isKeylooks = useIsKeyLooksPage()

  const isPreviousStateFromSwitcher = location.state === 'from-plp-switcher'
  const cmsTagAfa = useSelector(cmsTagAfaSelector) || ''

  const year = cmsTagAfa.slice(4)
  const season = cmsTagAfa.substr(2, 2)

  const [searchParams] = useSearchParams()
  const sportsNames = searchParams.get('sport')

  const keylookCode = searchParams.get('keylook') || ''
  const sport = keylookCode?.split('-')[0]

  const { thereIsMoodboard, switchMoodboard } = useShowMoodboard(brandCode, sport, keylookCode)

  const defaultCategory =
    Object.assign<CategoriesFieldOfBrand[], CategoriesFieldOfBrand[]>([], categories).sort(
      sortCategoriesFunction(true),
    )[0]?.name || 'Apparel'

  const searchParamsObj: Record<string, string> = {}
  if (sportsNames) {
    searchParamsObj.sport = sportsNames
  }
  if (keylookCode) {
    searchParamsObj.keylook = keylookCode
  }
  const search = Object.keys(searchParamsObj).length ? `?${qs.stringify(searchParamsObj)}` : ''

  return (
    <Wrapper>
      <PlpSwitcher>
        <PlpSwitcherTab
          to={{
            pathname: `${getDeviceAbsolutePath()}/${assortment}/single-brand/${brandCode}/key-looks`,
            state: 'from-plp-switcher',
            search,
          }}
          replace={isPreviousStateFromSwitcher}
          className={isKeylooks ? 'active' : ''}
        >
          {t('Afa.Keylooks')} {year} {season}
        </PlpSwitcherTab>
        <PlpSwitcherTab
          to={{
            pathname: `${getDeviceAbsolutePath()}/${assortment}/single-brand/${brandCode}/${defaultCategory}`,
            state: 'from-plp-switcher',
            search,
          }}
          className={!isKeylooks ? 'active' : ''}
          replace={isPreviousStateFromSwitcher}
        >
          {t('Afa.AllProducts')}
        </PlpSwitcherTab>
      </PlpSwitcher>

      <AfaPlpFooterSportSwitcher />

      {thereIsMoodboard && (
        <Button onClick={switchMoodboard}>
          <RCIcon type="afa_moodboard" />
          {t('Afa.Keylook.Moodboard')}
        </Button>
      )}
    </Wrapper>
  )
}

export default AfaPlpFooter
