import { Spin } from 'antd'
import classnames from 'classnames'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  &.fullpage {
    width: 100vw;
    height: 100vh;
  }
`

const Background = styled.div`
  background: transparent;
`

const SpinWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1015;

  .cart-page & {
    position: fixed;
  }
`

const resetEvent = (e: React.MouseEvent) => {
  e.preventDefault()
  e.stopPropagation()
}

type Props = {
  isFullPage?: boolean
  tip?: string
  className?: string
}

const Loading: React.FC<Props> = ({ tip, isFullPage = true, className }) => (
  <Wrapper
    onClick={resetEvent}
    className={classnames({
      'loading-wrapper': true,
      fullpage: isFullPage,
      [className || '']: !!className,
    })}
    data-testid="loading"
  >
    <Background className={classnames('loading loading-background')} />
    <SpinWrapper className={classnames('loading')}>
      <Spin size="large" tip={tip} />
    </SpinWrapper>
  </Wrapper>
)

export default Loading
