import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import AppointmentBrand from '../../components/AppointmentBrand'
import AppointmentStatus from '../../components/AppointmentStatus/AppointmentStatus'
import DigitalEventsFooter from '../../components/DigitalEventsFooter/DigitalEventsFooter'
import DigitalEventsHeader from '../../components/DigitalEventsHeader/DigitalEventsHeader'
import useFetchAppointments from '../../hooks/useFetchAppointments'
import useFetchBackgrounds from '../../hooks/useFetchBackgrounds'
import {
  appointmentBackgroundSelector,
  appointmentSelector,
} from '../../store/digitalEvents/selectors'
import {
  Main,
  PageWrapper,
  ScrollContainer,
  maxHeight,
  XSHeight,
} from '../../style/DigitalEventsCommonComponents'
import { breakpoints } from '../../style/theme'
import AppointmentDescription from './components/AppointmentDescription'
import BackgroundVideo from '../../components/BackgroundVideo'

const MainWrapper = styled(Main)`
  padding-top: 7vh;
  padding-bottom: 14vh;
  height: 100%;
  grid-template-columns: 5fr 2fr 5fr;
  display: grid;
  align-items: center;
  overflow: hidden;

  @media (max-width: ${breakpoints.S}) {
    padding-top: 0;
    padding-bottom: 10vh;
  }
  @media (max-height: ${maxHeight}) {
    padding-top: 0;
    padding-bottom: 0;
  }
  @media (max-height: ${XSHeight}) {
    padding-top: 0;
    padding-bottom: 10vh;
  }
`

const DigitalEventsAppointmentPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const background = useSelector(appointmentBackgroundSelector)
  const appointment = useSelector(appointmentSelector(Number(id)))
  const brands = appointment?.brands || []

  useFetchBackgrounds()
  useFetchAppointments()

  return (
    <PageWrapper background={background.image}>
      <BackgroundVideo background={background.video} />
      <ScrollContainer>
        <DigitalEventsHeader />
        <MainWrapper>
          <AppointmentDescription appointment={appointment} />

          <AppointmentBrand appointmentBrands={brands} />

          <AppointmentStatus appointment={appointment} />
        </MainWrapper>
        <DigitalEventsFooter />
      </ScrollContainer>
    </PageWrapper>
  )
}

export default DigitalEventsAppointmentPage
