import * as H from 'history'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import SwiperCore, { Pagination } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'

import PlayIcon from '../../components/icons/PlayIcon'
import { isNotUndefined } from '../../libs/utils'
import { brandSelector } from '../../store/brands/selectors'
import { categoriesPageDEContentsSelector } from '../../store/digitalEvents/selectors'
import { maxHeight } from '../../style/DigitalEventsCommonComponents'
import {
  breakpoints,
  DigitalEventsBoxBorderRadius,
  getFluidFontSize,
  getFluidFontSizeDe,
  getFluidSize,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
  spacing,
} from '../../style/theme'
import Modal from '../../styleguide/Modal'
import { useGetBrandLogo } from '../../services/afaContents'

SwiperCore.use([Pagination])

const VideoModal = styled(Modal)`
  * {
    background-color: ${palette.black};
  }

  .ant-modal-close {
    color: ${palette.white};

    .ant-modal-close-x {
      font-size: ${getFluidFontSize('20px')};
    }
  }
`

const Wrapper = styled.div<{ background?: string }>`
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(${({ background }) => background});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: ${DigitalEventsBoxBorderRadius};
  background-color: black;
  color: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const lateralPadding = 6
const verticalPadding = 8
const LogoWrapper = styled.div`
  padding: ${pxToRem(spacing(verticalPadding))}rem ${pxToRem(spacing(lateralPadding))}rem
    ${pxToRem(spacing(3))}rem ${pxToRem(spacing(lateralPadding))}rem;
  flex: 1;
  display: flex;

  @media (max-width: ${breakpoints.M}) {
    padding: ${pxToRem(spacing(verticalPadding / 2))}rem ${pxToRem(spacing(lateralPadding / 2))}rem
      ${pxToRem(spacing(1.5))}rem ${pxToRem(spacing(lateralPadding / 2))}rem;
  }
`

const Logo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
  max-height: ${getFluidSize('80px')};
  max-width: ${pxToRem(140)}rem;
  filter: invert(1);
`

const TitleAndDescription = styled.div`
  display: grid;
  grid-template-columns: 5fr 4fr;
  gap: ${pxToRem(spacing(4))}rem;
  margin-bottom: ${getFluidFontSize('20px')};
  padding: 0 ${pxToRem(spacing(lateralPadding))}rem;
  align-items: end;

  @media (max-width: ${breakpoints.M}) {
    gap: ${pxToRem(spacing(2))}rem;
    margin-bottom: ${pxToRem(spacing(3))}rem;
    padding: 0 ${pxToRem(spacing(lateralPadding / 2))}rem;
    display: flex;
    flex-direction: column;
  }
`

const Title = styled.h3`
  font-size: ${getFluidFontSizeDe(34, 64)};
  line-height: 0.9;
  color: ${palette.white};
  margin: 0;
`

const Description = styled.p`
  font-size: ${getFluidFontSizeDe(12, 22)};
  line-height: 1.2;
  color: ${palette.white};
  margin: 0;

  @media (max-width: ${breakpoints.M}) and (max-height: ${maxHeight}) {
    display: none;
    visibility: hidden;
  }
`

const SliderWrapper = styled.div`
  overflow: hidden;
  padding: 0 0 ${pxToRem(spacing(6))}rem ${pxToRem(spacing(lateralPadding))}rem;

  @media (max-width: ${breakpoints.M}) {
    padding: 0 0 ${pxToRem(spacing(3))}rem ${pxToRem(spacing(lateralPadding / 2))}rem;
  }

  .swiper-container {
    display: flex;
    flex-direction: column-reverse;
  }

  .swiper-pagination {
    position: static;
    text-align: left;
    margin-top: ${pxToRem(spacing(4))}rem;
  }

  .swiper-pagination-bullet {
    background-color: ${palette.white};
  }
`

const VideoTileWrapper = styled.div`
  border-radius: 5px;
  color: white;
  height: 200px;
  padding: 1rem;
  backdrop-filter: blur(25px);
  box-shadow: 20px 21px 30px 0 rgba(107, 89, 114, 0.05);
  background-image: linear-gradient(
    to bottom,
    rgba(231, 231, 249, 0.2),
    rgba(172, 153, 228, 0.1) -17%
  );

  @media (max-width: ${breakpoints.M}) {
    height: 160px;
  }

  @media (max-width: ${breakpoints.S}) {
    height: 110px;
  }
`

const VideoTileContent = styled.div<{ background: string }>`
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(${({ background }) => background});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 2px;
  color: white;
  height: 100%;
  padding: 1rem;
  display: flex;
  align-items: flex-end;
  padding-bottom: 1em;
  font-size: ${getFluidSizeWithFullFormula('px', 10, 20, 960, 1920)};

  @media (max-width: ${breakpoints.S}) {
    justify-content: center;
    align-items: center;
    padding-top: 1em;
  }
`

const PlayAndTitle = styled.div`
  gap: 1rem;
  display: grid;
  grid-template-columns: min-content auto;

  svg {
    height: 1em;
  }
`

const PlayIconWrapper = styled.div`
  width: ${getFluidSize('40px')};
  height: ${getFluidSize('40px')};
  border-radius: ${getFluidSize('40px')};
  backdrop-filter: blur(50px);
  box-shadow: 40px 42px 60px 0 rgba(107, 89, 114, 0.24);
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1) -100%
  );
  color: ${palette.white};
  display: flex;
  align-items: center;
  justify-content: center;
`

const VideoTitle = styled.h4`
  font-size: inherit;
  line-height: 1;
  color: ${palette.white};
  margin: 0;
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.S}) {
    display: none;
  }
`

const VideoPlayer = styled.video`
  width: 100%;
  height: 100%;

  &:focus {
    outline: none;
  }
`

const VideoSlide: React.FC<{
  onClick: () => void
  posterImage: string
  title: string
}> = ({ onClick, posterImage, title }) => (
  <VideoTileWrapper onClick={onClick}>
    <VideoTileContent background={posterImage}>
      <PlayAndTitle>
        <PlayIconWrapper>
          <PlayIcon />
        </PlayIconWrapper>
        <VideoTitle>{title}</VideoTitle>
      </PlayAndTitle>
    </VideoTileContent>
  </VideoTileWrapper>
)

const NewCollectionBox: React.FC<{ brandSlug: string; history: H.History }> = ({
  brandSlug,
  history,
}) => {
  const brand = useSelector(brandSelector(brandSlug))
  const contents = useSelector(categoriesPageDEContentsSelector(brand?.code || ''))
  const [videoInModal, setVideoInModal] = useState('')
  const newCollectionBox = contents?.newCollectionTile
  const videoShoppable = contents?.videoShoppable || []
  const videoProject = contents?.videoProject
  const videoTrailer = contents?.videoTrailer

  const { brandLogo, isSuccess } = useGetBrandLogo(brand?.code || '')

  return !newCollectionBox ? (
    <Wrapper />
  ) : (
    <>
      <Wrapper background={newCollectionBox.background}>
        <LogoWrapper>
          {brandLogo || !isSuccess ? (
            <Logo src={brandLogo} alt={brand?.brand || brand?.code} />
          ) : (
            <span>{brand?.brand || brand?.code}</span>
          )}
        </LogoWrapper>
        <div>
          <TitleAndDescription>
            <Title>{newCollectionBox.title}</Title>
            <Description>{newCollectionBox.description}</Description>
          </TitleAndDescription>
          <SliderWrapper>
            <Swiper slidesPerView={2.17} spaceBetween={10} freeMode={true} pagination={true}>
              {[videoProject, videoTrailer]
                .filter(isNotUndefined)
                .map(({ id, title, posterImage, url }) => (
                  <SwiperSlide key={id}>
                    <VideoSlide
                      onClick={() => setVideoInModal(url)}
                      posterImage={posterImage}
                      title={title}
                    />
                  </SwiperSlide>
                ))}
              {videoShoppable.map(({ id, title, posterImage }) => (
                <SwiperSlide key={id}>
                  <VideoSlide
                    onClick={() =>
                      history.push(`/digital-events/collection-highlight/${brandSlug}/${id}`)
                    }
                    posterImage={posterImage}
                    title={title}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </SliderWrapper>
        </div>
      </Wrapper>
      <VideoModal
        visible={!!videoInModal}
        onCancel={() => setVideoInModal('')}
        width="100%"
        zIndex={1020}
        style={{ height: '100%', paddingBottom: 0, top: 0 }}
        bodyStyle={{ paddingTop: '3rem', display: 'flex', alignItems: 'center' }}
        footer={null}
      >
        <VideoPlayer src={videoInModal} controls autoPlay />
      </VideoModal>
    </>
  )
}

export default NewCollectionBox
