import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { ImageFullOr360 } from '../../components/ImageFullOr360'
import { getBrandByBrandCode } from '../../libs/brand'
import { Brand } from '../../model/brand'
import { Moco, Product } from '../../model/product'
import { image360Selector } from '../../store/pdp/selectors'
import { getFluidFontSize, palette, pxToRem, spacing } from '../../style/theme'
import Modal from '../../styleguide/Modal'

const StyledModal = styled(Modal)`
  .ant-modal-footer {
    display: none;
  }

  .ant-modal-body {
    font-size: ${getFluidFontSize('14px')} !important;
    display: flex;
    flex-direction: column;
  }

  .ant-modal-close {
    position: absolute;
    font-size: ${getFluidFontSize('25px')};
  }
`

const ModalHead = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid ${palette.black};
  padding-bottom: ${pxToRem(spacing(4))}rem;
  margin-bottom: ${pxToRem(spacing(4))}rem;
`

const ModalTitle = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: ${getFluidFontSize('25px')};
`

const ModalBody = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

const Image = styled(ImageFullOr360)`
  position: relative;
  height: 100%;
  z-index: 1;
  grid-area: 2 / 1 / 3 / 2;
  display: flex;
  flex-direction: column;
`

interface Props {
  visible: boolean
  moco?: Moco
  model?: Product
  onClose?: () => void
  brands: Brand[]
}

const ZoomModal: React.FC<Props> = ({ visible, moco, model, onClose, brands }) => {
  const tre60InfoMoco = useSelector(image360Selector)

  if (!moco || !model) return null
  const brand = getBrandByBrandCode(brands, moco?.brandCode)

  return (
    <StyledModal visible={visible} onCancel={onClose} style={{ minWidth: '50vw' }}>
      <ModalHead>
        <ModalTitle>
          {brand?.brand} {model.styleName?.label}
        </ModalTitle>
      </ModalHead>
      <ModalBody>
        <Image
          key={moco.mocoCode}
          className="product-grid-image"
          fullscreen={false}
          mocoCode={moco.mocoCode}
          button360disabled={true}
          tre60InfoMoco={tre60InfoMoco}
          catalogImages={moco.catalogImages}
        />
      </ModalBody>
    </StyledModal>
  )
}

export default ZoomModal
