import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from '../../../hooks/useSearchParams'
import { customerDoorsSelector } from '../../../store/customer/selectors'
import { ASSORTMENT_ADVISOR_SELECTED_DOOR_ID_PARAM_NAME } from '../consts'

export const useGetSelectedDoorId = () => {
  const [searchParams] = useSearchParams()
  const selectedDoorId = searchParams.get(ASSORTMENT_ADVISOR_SELECTED_DOOR_ID_PARAM_NAME)

  return selectedDoorId
}

export const useSelectDoorId = () => {
  const [, setSearchParams] = useSearchParams()

  const selectDoorId = useCallback(
    (doorId: string) => {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set(ASSORTMENT_ADVISOR_SELECTED_DOOR_ID_PARAM_NAME, doorId)
      setSearchParams(searchParams, { replace: true })
    },
    [setSearchParams],
  )

  return selectDoorId
}
export const usePreselectDefaultDoor = () => {
  const selectedDoorId = useGetSelectedDoorId()
  const selectDoorId = useSelectDoorId()

  const doors = useSelector(customerDoorsSelector)
  const mainDoor = doors.find(({ mainDoor }) => mainDoor)

  if (!selectedDoorId && mainDoor) {
    selectDoorId(mainDoor.id)
  }
}
