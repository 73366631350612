import { FC } from 'react'

type Props = {
  className?: string
}

const CloseIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="#FFF"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="m11.5 11.5 14 14M25.5 11.5l-14 14" />
      </g>
    </svg>
  )
}

export default CloseIcon
