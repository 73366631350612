import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { isIpadOnlyRoom, isIPadView } from '../../../../libs/url'
import { openVTOView } from '../../../../store/actions'
import { pdpCouvetteSelector } from '../../../../store/pdp/selectors'
import PageSocketManager, { PageSocketManagerProps } from '../../../PageSocketManager'
import RCIcon from '../../../UI/RCIcon'

type Props = {
  upc?: string
  className?: string
}

export const ShowVtoButton: React.FC<Props & PageSocketManagerProps> = ({
  className,
  emitEvent,
  upc,
}) => {
  const { t } = useTranslation()
  const couvette = useSelector(pdpCouvetteSelector)

  const dispatch = useDispatch()

  return (
    <button
      className={['open-vto-button', className].join(' ')}
      onClick={() => {
        if (isIPadView() || isIpadOnlyRoom()) {
          return dispatch(openVTOView(upc))
        }

        emitEvent('open_vto', { upc, couvette })
      }}
    >
      <RCIcon type="vto-blue" />
      {t('Vto.tryThemOn')}
    </button>
  )
}

export default PageSocketManager(ShowVtoButton)
