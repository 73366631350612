import styled, { css } from 'styled-components/macro'
import { breakpointsCross, getFluidSizeWithFullFormula as gF, palette } from '../../style/theme'
import { useTranslation } from 'react-i18next'
import { ButtonsWrapper, Title, Wrapper } from './components/WhiteboardCommonComponents'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ButtonCancel, ButtonSave } from '../../components/AfaCommonComponents'
import { useDispatch, useSelector } from 'react-redux'
import whiteboardActions from '../../store/whiteboard/actions'
import { errorNotification, successNotification } from '../../components/Notification/notifications'
import {
  getSelectedSwitch,
  itemsPinModalSelector,
  latestSaveWhiteboardSelector,
  openWhiteboardSettingsSelector,
  whiteboardFromTemplateSelector,
  whiteboardToUpdateIdSelector,
  whiteboardToUpdatePreviewProductsSelector,
} from '../../store/whiteboard/selectors'
import { Release, WhiteboardType, releases } from '../../model/whiteboard'
import {
  usePinItemToWhiteBoardMutation,
  useSaveWhiteboardMutation,
  useDuplicateWhiteboardMutation,
  useGetSingleWhiteboardQuery,
} from '../../services/whiteboard'
import Loading from '../../components/Loading'
import { v4 as uuidv4 } from 'uuid'
import { useHistory, useParams } from 'react-router'
import { getDeviceAbsolutePath } from '../../libs/url'
import { useGetProductQuery } from '../../services/afaProduct'
import { useGetBrandsQuery } from '../../services/afa'
import { generateSkuCode } from '../../libs/whiteboard'
import { cmsTagAfaSelector, customerIdSelector, eventIdSelector } from '../../store/app/selectors'
import { getSeasonCodeFromReleaseCode } from '../../helpers/getSeasonCodeFromReleaseCode'
import { EMPTY_BOARD } from '../../store/whiteboard/slice'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding: 0 ${gF('px', 78, 597, 1366, 3840)};
  margin: ${gF('px', 80, 148, 1366, 3840)} auto ${gF('px', 87, 407, 500, 3840)};
`

const SectionTitle = styled.h2`
  text-transform: uppercase;
  font-family: GilmerBold, sans-serif;
  font-size: ${gF('px', 22, 32, 1366, 3840)};
  color: ${palette.cloudBurst};
  margin-bottom: ${gF('px', 24, 29, 1366, 3840)};
  letter-spacing: 1.1px;
  align-self: flex-start;
`

const ChoicesWrapper = styled(ButtonsWrapper)`
  margin-bottom: ${gF('px', 80, 92, 1366, 3840)};
  text-align: start;
`

// ripristinare se ritorna la scelta multipla per la release
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ReleaseChoice = styled.div<{ showBullet?: boolean }>`
  width: ${gF('px', 423, 668, 1366, 3840)};
  height: ${({ showBullet = true }) => (showBullet ? gF('px', 103, 128, 1366, 3840) : 'auto')};
  border-radius: ${gF('px', 4, 8, 1366, 3840)};
  border: solid ${gF('px', 1, 2, 1366, 3840)} ${palette.platinum};
  background-color: ${palette.white};
  padding: ${gF('px', 24, 32, 1366, 3840)} ${gF('px', 32, 40, 1366, 3840)};
  display: ${({ showBullet = true }) => (showBullet ? 'grid' : 'block')};
  grid-template-columns: ${gF('px', 20, 40, 1366, 3840)} 1fr;
  grid-column-gap: ${gF('px', 24, 40, 1366, 3840)};
  grid-template-rows: auto;
  grid-template-areas: 'bullet title';
  grid-row-gap: ${gF('px', 10, 4, 1366, 3840)};
  place-items: center start;
  cursor: pointer;

  &.selected {
    border: solid ${gF('px', 1, 2, 1366, 3840)} ${palette.tangaroa};
  }

  @media screen and (max-width: ${breakpointsCross.XL.max}) {
    grid-template-areas: 'bullet title';
  }
`

const SingleReleaseChoice = styled.div`
  width: ${gF('px', 423, 668, 1366, 3840)};
  border-radius: ${gF('px', 4, 8, 1366, 3840)};
  border: solid ${gF('px', 1, 2, 1366, 3840)} ${palette.platinum};
  background-color: ${palette.white};
  padding: ${gF('px', 24, 32, 1366, 3840)} ${gF('px', 32, 40, 1366, 3840)};
`

const radioSizes = css`
  background-size: contain;
  background-repeat: no-repeat;
  width: ${gF('px', 20, 40, 1366, 3840)};
  height: ${gF('px', 20, 40, 1366, 3840)};
  grid-area: bullet;
`

// ripristinare se ritorna la scelta multipla per la release
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FullCircle = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23b9h0gdwyma)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.75 10a8.75 8.75 0 1 1-17.5 0 8.75 8.75 0 0 1 17.5 0zM20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10zm-10 6.25a6.25 6.25 0 1 0 0-12.5 6.25 6.25 0 0 0 0 12.5z' fill='%23031434'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='b9h0gdwyma'%3E%3Cpath fill='%23fff' d='M0 0h20v20H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  ${radioSizes}
`

// ripristinare se ritorna la scelta multipla per la release
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EmptyCircle = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 37.5c9.665 0 17.5-7.835 17.5-17.5S29.665 2.5 20 2.5 2.5 10.335 2.5 20 10.335 37.5 20 37.5zm0 2.5c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20z' fill='%23DEE0E6'/%3E%3C/svg%3E%0A");
  ${radioSizes}
`

const ReleaseTitle = styled.h3`
  font-family: GilmerMedium, sans-serif;
  font-size: ${gF('px', 18, 24, 1366, 3840)};
  color: ${palette.cloudBurst};
  text-transform: uppercase;
  grid-area: title;
`

const WhiteboardNameWrapper = styled.div`
  font-family: GilmerMedium, sans-serif;
  display: flex;
  align-items: center;
  width: ${gF('px', 423, 669, 1366, 3840)};
  height: ${gF('px', 42, 68, 1366, 3840)};
  padding: ${gF('px', 16, 24, 1366, 3840)};
  border: solid 1px ${palette.shadowBlue};
  border-radius: 4px;
  font-size: ${gF('px', 14, 24, 1366, 3840)};
  letter-spacing: 0.3px;
  cursor: text;
`

const Prefix = styled.span`
  color: ${palette.shadowBlue};
`

const Input = styled.input`
  color: ${palette.cloudBurst};
  border: none;

  &:focus {
    outline: none;
  }
`

const ButtonsGroup = styled.div`
  display: flex;
  gap: ${gF('px', 40, 80, 1366, 3840)};
  font-family: GilmerBold, sans-serif;
`

const WhiteboardModalSettings: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)
  const latestSaveWhiteboard = useSelector(latestSaveWhiteboardSelector)
  const [duplicateWhiteboard, duplicateWhiteboardResult] = useDuplicateWhiteboardMutation()
  const openWhiteboardSettings = useSelector(openWhiteboardSettingsSelector)

  const whiteboardFromTemplate = useSelector(whiteboardFromTemplateSelector)

  const inputRef = useRef<HTMLInputElement>(null)

  const [chosenRelease, setChosenRelease] = useState<Release>('FW')
  const [prefix, setPrefix] = useState('')
  const [whiteboardName, setWhiteboardName] = useState('')
  const selectedType = useSelector(getSelectedSwitch)
  const whiteboardToUpdateId = useSelector(whiteboardToUpdateIdSelector)
  const whiteboardToUpdatePreviewProducts = useSelector(whiteboardToUpdatePreviewProductsSelector)

  const whiteboardToUpdateQuery = useGetSingleWhiteboardQuery(selectedType, whiteboardToUpdateId)
  const whiteboardToUpdate = whiteboardToUpdateQuery.data as WhiteboardType
  const isWhiteboardUpdate = !!whiteboardToUpdate && !!whiteboardToUpdateId

  const { assortment, brandCode, modelCode } = useParams<{
    assortment: string
    brandCode: string
    modelCode: string
  }>()
  const brandsQuery = useGetBrandsQuery()
  const brands = brandsQuery?.data || []
  const brand = brands.find(item => item.code === brandCode)

  const productQuery = useGetProductQuery(modelCode)
  const product = productQuery.data

  useEffect(() => {
    if (isWhiteboardUpdate) {
      setChosenRelease(whiteboardToUpdate.release)
      setPrefix(whiteboardToUpdate.name.slice(0, 8))
      setWhiteboardName(whiteboardToUpdate.name.slice(8))
    } else {
      setWhiteboardName('')
    }
  }, [isWhiteboardUpdate, whiteboardToUpdate])

  const onCancel = useCallback(() => {
    dispatch(whiteboardActions.setOpenPinModal(false))
    dispatch(whiteboardActions.setOpenWhiteboardSettings(false))
    dispatch(whiteboardActions.resetItemsPinModal())
    setWhiteboardName('')
    dispatch(whiteboardActions.setWhiteboardFromTemplate(null))
    dispatch(whiteboardActions.setWhiteboardToUpdateId(''))
  }, [dispatch])

  const [saveWhiteboard, saveWhiteboardResult] = useSaveWhiteboardMutation()
  const [pinItemsToWhiteboard] = usePinItemToWhiteBoardMutation()
  const products = useSelector(itemsPinModalSelector)

  const handleSaveWhiteboardFromTemplate = () => {
    if (whiteboardFromTemplate) {
      duplicateWhiteboard({
        keyToCopy: whiteboardFromTemplate,
        newName: `${prefix}${whiteboardName.trim()}`,
        type: selectedType,
      })
      onCancel()
    }
  }

  const name = `${prefix}${whiteboardName.trim()}`
  const whiteboardToSave: WhiteboardType = useMemo(() => {
    const updatedWhiteboard: WhiteboardType = {
      ...whiteboardToUpdate,
      name: name,
      release: chosenRelease,
      previewProducts: whiteboardToUpdatePreviewProducts,
    }

    const newWhiteboard: WhiteboardType = {
      name: name,
      release: chosenRelease,
      key: `${uuidv4()
        .substring(0, 23)
        .toUpperCase()}-${customerId.replace(/^0+/, '')}-${eventId}`,
      boards: [{ ...EMPTY_BOARD, key: uuidv4(), name: 'Board 1' }],
    }

    return isWhiteboardUpdate ? updatedWhiteboard : newWhiteboard
  }, [
    chosenRelease,
    customerId,
    eventId,
    isWhiteboardUpdate,
    name,
    whiteboardToUpdate,
    whiteboardToUpdatePreviewProducts,
  ])

  const handleSaveWhiteboard = () => {
    if (!isWhiteboardUpdate) localStorage.removeItem('lastWhiteboardVisitedKey')

    try {
      saveWhiteboard({ selectedWhiteboard: whiteboardToSave, whiteboardHasChanged: true })
      products.length &&
        pinItemsToWhiteboard({
          whiteboardId: whiteboardToSave.key,
          products: products,
        })
    } catch (e) {
      onCancel()
    } finally {
      dispatch(whiteboardActions.setPreviewIsUpdating(false))
    }
  }

  useEffect(() => {
    if (latestSaveWhiteboard) {
      if (openWhiteboardSettings) {
        if (latestSaveWhiteboard.result) {
          dispatch(
            whiteboardActions.setLastPinnedWhiteboard({
              id: whiteboardToSave.key,
              name: whiteboardToSave.name,
              skuItemPinned: generateSkuCode(products),
            }),
          )

          if (!isWhiteboardUpdate) {
            history.location.pathname = history.location.pathname.replace('single-brand/', '')

            history.push(
              `${getDeviceAbsolutePath()}/${assortment}/${brand?.code ||
                product?.brandCodeParent}/whiteboard/${whiteboardToSave.key}`,
            )
            successNotification({
              message: t(`Whiteboard.${selectedType}Created`),
            })
          } else {
            successNotification({
              message: t('Whiteboard.whiteboardUpdated'),
            })
          }
          onCancel()
        } else {
          onCancel()
          if (latestSaveWhiteboard.warnings.length) {
            latestSaveWhiteboard.warnings.forEach(warning => {
              errorNotification({
                message: warning.message,
              })
            })
          } else {
            errorNotification({
              message:
                selectedType === 'whiteboard'
                  ? 'Whiteboard.errorTemplateSavedAsWhiteboard'
                  : 'Whiteboard.errorWhiteboardSavedAsTemplate',
            })
          }
        }
      } else {
        if (latestSaveWhiteboard.warnings.length) {
          latestSaveWhiteboard.warnings.forEach(warning => {
            errorNotification({
              message: warning.message,
            })
          })
        }
      }
      dispatch(whiteboardActions.setLatestSaveWhiteboard(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestSaveWhiteboard])

  const cmsTagAfaFromStorage = sessionStorage.getItem('cmsTagAfa')
  const cmsTagAfa = useSelector(cmsTagAfaSelector) || cmsTagAfaFromStorage

  useEffect(() => {
    if (cmsTagAfa && !cmsTagAfaFromStorage) {
      sessionStorage.setItem('cmsTagAfa', cmsTagAfa)
      const release = releases.find(
        release => cmsTagAfa.toLowerCase().indexOf(release.toLowerCase()) > -1,
      )

      if (release) {
        setChosenRelease(release)
      }
    }
  }, [cmsTagAfa, cmsTagAfaFromStorage])

  useEffect(() => {
    if (cmsTagAfa) {
      const parts = cmsTagAfa.match(/(\d{4})(\w{2})/)
      const cmsYear = parts?.[1] || ''
      const cmsRelease = (parts?.[2] || '') as Release
      setPrefix(`${cmsRelease} ${cmsYear}_`)
      setChosenRelease(cmsRelease)
    }
  }, [cmsTagAfa])

  useEffect(() => {
    if (duplicateWhiteboardResult.status === 'fulfilled') {
      if (duplicateWhiteboardResult.error || duplicateWhiteboardResult.isError) {
        errorNotification({ message: t('Whiteboard.errorDuplicateWhiteboard') })
      } else if (duplicateWhiteboardResult.data.warnings.length) {
        duplicateWhiteboardResult.data.warnings.forEach((warning: { message: string }) => {
          errorNotification({ message: warning.message })
        })
      } else if (duplicateWhiteboardResult.data.result) {
        successNotification({ message: t(`Whiteboard.${selectedType}Created`) })
      }
      duplicateWhiteboardResult.reset()
    } else if (duplicateWhiteboardResult.status === 'rejected') {
      errorNotification({ message: t('Whiteboard.errorDuplicateWhiteboard') })
      duplicateWhiteboardResult.reset()
    }
  }, [duplicateWhiteboardResult, selectedType, t])

  return (
    <Wrapper>
      <Title>{t(`Whiteboard.${selectedType}Settings`)}</Title>
      {whiteboardToUpdateQuery.isFetching ? (
        <ContentWrapper>
          <Loading isFullPage={false} />
        </ContentWrapper>
      ) : (
        <ContentWrapper>
          <SectionTitle>{t('Afa.fC.release')}</SectionTitle>

          <ChoicesWrapper>
            <SingleReleaseChoice>
              <ReleaseTitle>
                {getSeasonCodeFromReleaseCode(chosenRelease)
                  ? t(`Whiteboard.release${getSeasonCodeFromReleaseCode(chosenRelease)}`)
                  : chosenRelease}
              </ReleaseTitle>
            </SingleReleaseChoice>
          </ChoicesWrapper>

          <SectionTitle>{t('Whiteboard.chooseWhiteboardName')}</SectionTitle>

          <WhiteboardNameWrapper
            onClick={() => inputRef.current !== null && inputRef.current.focus()}
          >
            <Prefix>{prefix}</Prefix>
            <Input
              ref={inputRef}
              type="text"
              value={whiteboardName}
              onChange={event => {
                //Regex that checks whether the first character typed is text or numeric (no special characters)
                const regexPattern = /^[0-9a-zA-Z]/
                if (
                  (regexPattern.test(event.target.value) && whiteboardName.length === 0) ||
                  whiteboardName.length > 0
                ) {
                  setWhiteboardName(event.target.value)
                }
              }}
            />
          </WhiteboardNameWrapper>
        </ContentWrapper>
      )}

      <ButtonsGroup>
        <ButtonCancel onClick={() => onCancel()}>
          {t('Checkout.ImportExport.ModalCancel')}
        </ButtonCancel>
        <ButtonSave
          disabled={whiteboardName === ''}
          onClick={() =>
            whiteboardFromTemplate ? handleSaveWhiteboardFromTemplate() : handleSaveWhiteboard()
          }
        >
          {isWhiteboardUpdate
            ? t(`Whiteboard.update_${selectedType}`)
            : t(`Whiteboard.newCreation.title_${selectedType}`)}
        </ButtonSave>
      </ButtonsGroup>

      {saveWhiteboardResult.isLoading && <Loading isFullPage={false} />}
    </Wrapper>
  )
}

export default WhiteboardModalSettings
