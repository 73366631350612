import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled from 'styled-components/macro'

import RCIcon from '../../components/UI/RCIcon'
import { formatName } from '../../helpers/genericHelper'
import { useShowMoodboard } from '../../hooks/useHandleOpenPages'
import { useSearchParams } from '../../hooks/useSearchParams'
import { useGetBrandCategoriesQuery } from '../../services/afa'
import { useGetBrandContentQuery } from '../../services/afaContents'
import { useGetKeylookProductsQuery } from '../../services/afaProducts'
import {
  breakpoints,
  breakpointsCross,
  getFluidFontSize,
  getFluidSizeWithFullFormula as gF,
  palette,
  pxToRem,
} from '../../style/theme'
import AfaKeylooksProduct from './AfaKeylooksProduct'
import { useScroll } from '../../hooks/useScroll'
import { useDispatch, useSelector } from 'react-redux'
import whiteboardActions from '../../store/whiteboard/actions'
import AfaModal from '../../components/AfaModal'
import { itemsPinModalSelector } from '../../store/whiteboard/selectors'
import { AfaProduct } from '../../model/afa'

const Wrapper = styled.div<{ showMoodboardRow: boolean }>`
  overflow-y: hidden;
  background-color: ${palette.wildSand};
  padding: 0 ${pxToRem(16)}rem 0 ${gF('px', 16, 149, 1920, 3840)};
  display: grid;
  grid-template-rows: ${({ showMoodboardRow }) =>
    showMoodboardRow ? 'max-content max-content' : `${pxToRem(68)}rem 1fr`};
  height: 100%;

  @media screen and (max-width: ${breakpoints.L}) {
    padding-left: ${pxToRem(16)}rem;
  }

  @media screen and (min-width: ${breakpointsCross.L.max}) {
    grid-template-rows: max-content 1fr;
  }
`

const Buttons = styled.div`
  margin: ${pxToRem(16)}rem 0;
  display: none;
  justify-content: end;

  @media screen and (max-width: ${breakpoints.M}) {
    display: flex;
  }
`

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${getFluidFontSize('12px')};
  color: ${palette.tangaroa};
  gap: ${pxToRem(6)}rem;
  cursor: pointer;
  font-weight: bold;
`

const ContentWrapper = styled.div`
  overflow-y: hidden;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: ${gF('px', 16, 149, 1920, 3840)};
  height: 100%;

  @media screen and (max-width: ${breakpoints.L}) {
    gap: ${pxToRem(16)}rem;
  }
`

const LeftColumn = styled.div`
  display: grid;
  grid-auto-rows: max-content max-content;
  grid-row-gap: ${gF('px', 24, 48, 1920, 3840)};
  height: 100%;
`

const Title = styled.div`
  font-size: ${gF('px', 24, 64, 1920, 3840)};
  margin-bottom: ${gF('px', 8, 48, 1368, 3840)};
  margin-top: ${gF('px', 8, 68, 1368, 3840)};
  color: ${palette.tangaroa};
  font-weight: bold;

  @media screen and (max-width: ${breakpoints.M}) {
    height: 0;
    visibility: hidden;
  }
`

const ImageButtons = styled.div`
  display: flex;
  justify-content: center;
`

const PinButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${palette.tangaroa};
  border-radius: 4px;
  color: ${palette.white};
  font-family: GilmerBold, sans-serif;
  font-size: ${gF('px', 13, 24, 1920, 3840)};
  text-transform: uppercase;
  padding: ${gF('px', 19, 24, 1920, 3840)} ${gF('px', 21, 24, 1920, 3840)};
  gap: ${gF('px', 9, 20, 1920, 3840)};
  cursor: pointer;

  img {
    width: ${gF('px', 9, 24, 768, 3840)};
    height: ${gF('px', 9, 24, 768, 3840)};
  }
`

const KeylookImage = styled.img`
  background-color: ${palette.white};
  width: ${gF('px', 313, 1138, 1366, 3840)};
  height: ${gF('px', 401, 1326, 1366, 3840)};
  max-height: 55vh;

  @media screen and (max-width: ${breakpoints.M}) {
    width: ${gF('px', 262, 533, 768, 1366)};
    height: ${gF('px', 311, 621, 768, 1366)};
  }
`

const Products = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: ${gF('px', 217, 368, 1366, 3840)};
  gap: ${gF('px', 16, 30, 1366, 3840)};
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: ${pxToRem(16)}rem;
  direction: rtl;

  * {
    direction: ltr;
  }

  @media screen and (max-width: ${breakpointsCross.L.max}) {
    grid-template-columns: 1fr;
    margin-top: 0;
  }
`

const RightColumn = styled.div<{ height: number }>`
  display: flex;
  width: 100%;
  overflow-y: auto;
  height: ${({ height }) => `${height}px`};
`

export const CustomAfaModal = styled(AfaModal)`
  width: ${gF('px', 644, 1000, 1366, 3840)};
  padding: ${gF('px', 40, 80, 1366, 3840)};
  overflow-y: auto;
`

const AfaKeylooksMain: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { brandCode } = useParams<{ brandCode: string }>()
  const [searchParams, setSearchParams] = useSearchParams()

  const productsQuery = useGetKeylookProductsQuery(true)
  const categoriesQuery = useGetBrandCategoriesQuery(brandCode)

  const itemsPinned = useSelector(itemsPinModalSelector)

  const products = productsQuery.data?.products || []
  const sports = categoriesQuery.data?.sports || []
  const collectionThemes = sports.find(({ name }) => name.toLowerCase() === 'theme')

  const keylookCode = searchParams.get('keylook') || ''

  const selectedKeylookSport = sports.find(({ keylooks }) =>
    keylooks?.find(({ code }) => code === keylookCode),
  )
  const selectedKeylook = selectedKeylookSport?.keylooks.find(({ code }) => code === keylookCode)

  const selectedCollectionTheme = collectionThemes?.keylooks.find(
    ({ code }) => code === keylookCode,
  )

  const keyLooksImagesQuery = useGetBrandContentQuery(brandCode, selectedKeylookSport?.name)
  const keyLookImage = (keylookCode && keyLooksImagesQuery.data?.keyLooksImages[keylookCode]) || ''

  const sport = keylookCode?.split('-')[0]
  const { thereIsMoodboard, switchMoodboard } = useShowMoodboard(brandCode, sport, keylookCode)

  const [setRef, handleScroll, ref] = useScroll('top')

  const el = document.getElementById('wrapper-keylooks') as HTMLElement
  const heightRightColumn = el?.getBoundingClientRect()?.height

  useEffect(() => {
    const scroll = searchParams.get('scrollTop')
    if (Number(scroll)) {
      setTimeout(() => {
        const updatedSearchParams = new URLSearchParams(location.search)
        updatedSearchParams.set('scrollTop', Number(scroll).toString())
        setSearchParams(updatedSearchParams, { replace: true })
        const element = ref.current as HTMLElement
        element.scrollTop = Number(scroll)
      }, 0)
    }
  }, [ref, searchParams, setSearchParams])

  const filterMocosByKeylook = (product: AfaProduct) => {
    const mocosArray = Object.values(product.mocos)
    const keyLookMoco = mocosArray.filter(({ keylooks }) => keylooks?.includes(keylookCode))[0]
    return { ...product, mocos: { selectedColor: keyLookMoco } }
  }

  const productsToPin = products.map(product => filterMocosByKeylook(product))

  return (
    <Wrapper showMoodboardRow={thereIsMoodboard}>
      {thereIsMoodboard && (
        <Buttons>
          <Button onClick={switchMoodboard}>
            <RCIcon type="afa_moodboard" />
            {t('Afa.Keylook.Moodboard')}
          </Button>
        </Buttons>
      )}
      <Title>
        {formatName(
          selectedCollectionTheme ? selectedCollectionTheme?.name : selectedKeylook?.name,
        )}
      </Title>
      <ContentWrapper id="wrapper-keylooks">
        <LeftColumn>
          <KeylookImage src={keyLookImage} alt="" />
          <ImageButtons>
            <PinButton
              onClick={() => {
                !itemsPinned.length && dispatch(whiteboardActions.setOpenPinModal(true))
                !itemsPinned.length && dispatch(whiteboardActions.setItemsPinModal(productsToPin))
              }}
            >
              <RCIcon type="pin" />
              <span>
                {window.innerWidth > parseInt(breakpoints.L)
                  ? t('Whiteboard.pinLookToBoard')
                  : t('Whiteboard.pinToBoard')}
              </span>
            </PinButton>
          </ImageButtons>
        </LeftColumn>
        <RightColumn ref={setRef} onScroll={handleScroll} height={heightRightColumn}>
          <Products>
            {[...products]
              .sort((p1, p2) => p1.plpOrder - p2.plpOrder)
              .map(product => {
                return (
                  <AfaKeylooksProduct
                    key={`${product.brandCode}-${product.modelCode}-${product.category}`}
                    keylook={keylookCode}
                    product={product}
                  />
                )
              })}
          </Products>
        </RightColumn>
      </ContentWrapper>
    </Wrapper>
  )
}

export default AfaKeylooksMain
