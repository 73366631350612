import { useSelector } from 'react-redux'

import { createApi } from '@reduxjs/toolkit/query/react'

import app_config from '../config/app/config'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { CmsContent } from '../model/content'
import { MocoCode } from '../model/model'
import { selectedEventSelector } from '../store/app/selectors'

type AdvContent = {
  advLandscape?: CmsContent
  advPortrait?: CmsContent
}

export const contentApi = createApi({
  reducerPath: 'contentApi',
  baseQuery: staggeredBaseQueryWithBailOut(`${app_config.apiUrl}/content`),
  endpoints: builder => ({
    getAdvContents: builder.query<
      { data: Record<MocoCode, AdvContent>; errors: unknown[] },
      {
        eventId: string
        release: string
        brandCodeParent: string
      }
    >({
      query: ({ eventId, release, brandCodeParent }) => ({
        url: `/type/adv?type=adv&eventId=${eventId}&cmsTag=${release}&brand=${brandCodeParent}`,
      }),
    }),
  }),
})

export const useGetAdvContentsQuery = (brandCodeParent: string | undefined, isAfa = true) => {
  const event = useSelector(selectedEventSelector)
  const release = (isAfa ? event?.cmsTagAfa || event?.cmsTag : event?.cmsTag) || ''
  const eventId = event?.id || ''

  return contentApi.useGetAdvContentsQuery(
    {
      eventId,
      release,
      brandCodeParent: brandCodeParent || '',
    },
    { skip: !event || !brandCodeParent },
  )
}
