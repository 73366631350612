import { addMinutes, isAfter } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Appointment } from '../../model/appointment'
import { isKamUserSelector } from '../../store/app/selectors'
import { joinAppointment } from '../../store/digitalEvents/actions'
import { CtaButton, Spin } from '../../style/DigitalEventsCommonComponents'

const JoinUsButton: React.FC<{ appointment: Appointment }> = ({ appointment }) => {
  const [loading, setLoading] = useState(false)
  const [now, setNow] = useState(new Date())
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isKamUser = useSelector(isKamUserSelector)

  const isAppointmentStartingIn5Minutes = isAfter(
    addMinutes(now, 5),
    new Date(appointment.startDate),
  )
  const showButton = !isKamUser && isAppointmentStartingIn5Minutes

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date())
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return showButton ? (
    <CtaButton
      disabled={loading}
      onClick={async () => {
        setLoading(true)
        await dispatch(
          joinAppointment(appointment.idAppointment, appointment.contacts[0].idContact),
        )
        setLoading(false)
      }}
    >
      {loading ? <Spin /> : t('Appointments.joinUs')}
    </CtaButton>
  ) : null
}

export default JoinUsButton
