import { breakpoints, breakpointsCross, palette } from '../../../../style/theme'
import AfaCartSummaryDoughnut from './smallComponents/AfaCartSummaryDoughnut'
import styled from 'styled-components/macro'
import { DataCartSummary } from '../AfaCartSummary'
import { useGetBrandsQuery } from '../../../../services/afa'
import React from 'react'

const ChartsWrapper = styled.div`
  display: grid;
  gap: 2em 1em;
  grid-template-columns: repeat(3, 1fr);
  overflow-y: auto;
  max-height: 60vh;

  @media (max-height: ${breakpointsCross.S.max}) {
    max-height: 50vh;
  }

  @media (min-width: ${breakpoints.L}) {
    grid-template-columns: repeat(2, 1fr);
    gap: 3em;
  }
`

type Props = {
  data: DataCartSummary[]
}

const doughnutColors = [
  palette.bilobaFlower,
  palette.wewak,
  palette.calypso,
  palette.monteCarlo,
  palette.cornflowerBlueDarker,
  palette.zombie,
  palette.carissma,
]

const AfaCartSummaryPageOne: React.FC<Props> = ({ data }) => {
  const brandsQuery = useGetBrandsQuery()
  const theCustomerHasMoreThanOneBrand = brandsQuery.data?.length && brandsQuery.data.length > 1

  return (
    <ChartsWrapper>
      {data
        .filter(
          ({ categoryKey }) =>
            categoryKey.toLocaleLowerCase() !== 'brand' || theCustomerHasMoreThanOneBrand,
        )
        .map(({ categoryKey, data }) => (
          <AfaCartSummaryDoughnut
            key={categoryKey}
            graphData={{
              categoryKey,
              data,
            }}
            doughnutColors={doughnutColors}
          />
        ))}
    </ChartsWrapper>
  )
}

export default AfaCartSummaryPageOne
