import { debounce } from 'lodash'
import { MutableRefObject, UIEventHandler, useCallback, useEffect, useRef } from 'react'
import { useSearchParams } from './useSearchParams'
import { firstLetterCapital } from '../helpers/genericHelper'

export const useScroll = (
  scrollDirection: 'top' | 'left',
  useLocalstorage = false,
): [
  (instance: HTMLDivElement | null) => void,
  UIEventHandler<HTMLDivElement> | undefined,
  MutableRefObject<HTMLDivElement | null>,
] => {
  const [searchParams, setSearchParams] = useSearchParams()
  const scrollType = `scroll${firstLetterCapital(scrollDirection)}` as 'scrollLeft' | 'scrollTop'

  const ref = useRef<HTMLDivElement | null>(null)
  const setRef = useCallback(node => {
    ref.current = node
  }, [])

  useEffect(() => {
    const scroll = useLocalstorage ? localStorage.getItem(scrollType) : searchParams.get(scrollType)
    if (scroll && ref.current) {
      const key: keyof HTMLElement = scrollType
      const htmlElement = ref.current as HTMLElement
      htmlElement[key] = Number(scroll)
    }
  }, [ref, scrollType, searchParams, useLocalstorage])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(
    debounce(e => {
      const scroll = e.target[scrollType]

      if (useLocalstorage) {
        localStorage.setItem(scrollType, scroll.toString())
        return
      }

      const updatedSearchParams = new URLSearchParams(location.search)
      updatedSearchParams.set(scrollType, scroll.toString())
      setSearchParams(updatedSearchParams, { replace: true })
    }, 100),
    [],
  ) as UIEventHandler<HTMLDivElement> | undefined

  return [setRef, handleScroll, ref]
}
