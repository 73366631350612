import React from 'react'
import styled from 'styled-components'

import { buttonBorderRadius, palette } from '../../style/theme'

const Wrapper = styled.div`
  position: absolute;
  top: 1.5rem;
  left: 50%;
  border: solid 1px ${palette.geyser};
  border-radius: ${buttonBorderRadius};
  background-color: #807a7a;
  padding: 0 2rem 0 1rem;

  @media screen and (min-width: 3000px) {
    top: 18%;
    left: 57%;
  }

  iframe {
    width: 30vw;
    height: 20vw;
    background-color: ${palette.white};
    border: none;
  }
`

const TestingSpeedWidget: React.FC = () => {
  return (
    <Wrapper data-testid="speed-widget">
      <iframe src="//openspeedtest.com/Get-widget.php"></iframe>
    </Wrapper>
  )
}

export default TestingSpeedWidget
