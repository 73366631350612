import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteChildrenProps, Switch } from 'react-router-dom'

import DisplayMedia from '../components/DisplayMedia'
import PageSocketManager, {
  PageData,
  PageSocketManagerProps,
} from '../components/PageSocketManager'
import app_config from '../config/app/config'
import LedList from '../containers/LedList'
import useFetchInitialData from '../hooks/useFetchInitialData'
import { isAuthenticated } from '../libs/auth'
import { getDeviceBasePath } from '../libs/url'
import { BrandContent } from '../model/brand'
import { AppContent } from '../model/model'
import { contentSelector } from '../store/app/selectors'
import { tokenSelector } from '../store/auth/selectors'

const pageEventsMap: Record<string, ((data: PageData) => string) | undefined | string> = {
  do_autologin: undefined,
  do_logout: '',
  do_open_categories_page: data => {
    if (!data.brand) {
      // this happens when the table is reloaded while in categories page RED-1839
      return ''
    }

    const urlType = data.brandVisited ? 'navigation' : 'activation'
    return `/${data.brand.slug}/${urlType}`
  },
  do_play_activation_video: data => {
    if (!data.brand) {
      // this happens when the table is reloaded while in categories page RED-1839
      return ''
    }
    return `/${data.brand.slug}/activation`
  },
  do_open_chooseAssortment_page: () => '/welcome',
  do_open_chooseBrand_page: data => {
    // show welcome page only the first time user visit 'choosebrand'
    return data.welcomeVideoVisited ? '/multibrand' : '/welcome'
  },
  do_open_keyLooks_page: () => '/afa-navigation',
  do_open_afaPlp_page: () => '/afa-navigation',
  do_open_afaPdp_page: () => '/afa-navigation',
  do_open_filteredPlp_multiBrand: '/multibrand',
  do_open_filteredPlp_singleBrand: data => {
    return data.brand ? `/${data.brand.slug}/navigation` : ''
  },
  do_open_login_page: '/login',
  do_open_selectCustomers_page: '/welcome',
  do_open_singleBrandPlp_page: data => {
    return data.brand ? `/${data.brand.slug}/navigation` : ''
  },
  do_open_tableCart_page: '/cart',
  do_open_tableWishlist_page: '/multibrand',
  do_play_digitalPreview_video: '/digital-preview',
  do_switch_onlyCart: '/cart',
  do_open_checkout_page: '/welcome',
}

export type VideoNamesMap = Record<
  'intro' | 'digitalPreview' | 'welcome' | 'multibrand',
  keyof AppContent
> &
  Record<'activation' | 'navigation', keyof BrandContent>

type OwnProps = {
  videoNamesMap: VideoNamesMap
  category: string
  revert?: boolean
  side: 'left' | 'right'
}

type Props = OwnProps & RouteChildrenProps & PageSocketManagerProps

const LedView: React.FC<Props> = ({
  match,
  location,
  pageData,
  videoNamesMap,
  category,
  revert,
  socketIoEvent,
  side,
}) => {
  useFetchInitialData({ loadCustomer: true, loadContent: true, loadBrands: true })

  const [redirect, setRedirect] = useState('')
  const [viewType, setViewType] = useState(app_config.viewType.leds as string)

  const content = useSelector(contentSelector)
  const token = useSelector(tokenSelector)

  const matchUrl = match?.url

  useEffect(() => {
    const redirectAction = pageEventsMap[socketIoEvent]
    if (typeof redirectAction === 'string') {
      setRedirect(`${matchUrl}${redirectAction}`)
    } else if (redirectAction && pageData) {
      setRedirect(`${matchUrl}${redirectAction(pageData)}`)
    }
  }, [socketIoEvent, matchUrl, pageData])

  const pageDataViewType = pageData?.viewType
  useEffect(() => {
    if (pageDataViewType) {
      setViewType(pageDataViewType)
    }
  }, [pageDataViewType])

  const handleDigitalPreviewEnded = () => {
    setRedirect(`${match?.url}/multibrand`)
  }

  const handleVideoEnded = () => {
    setRedirect(`${match?.url}/${pageData?.brand?.slug}/navigation`)
  }

  const redirectToUse = !isAuthenticated(token) ? getDeviceBasePath() : redirect
  if (redirectToUse && redirectToUse !== location.pathname) {
    return <Redirect to={redirectToUse} />
  }

  if (!match) {
    return <Redirect to="/" />
  }

  return (
    <Switch>
      <Route
        exact
        path={`${match.url}`}
        render={() => <DisplayMedia media={content[videoNamesMap.intro]} loop={true} />}
      />
      <Route
        exact
        path={`${match.url}/login`}
        render={() => <DisplayMedia media={content[videoNamesMap.intro]} loop={true} />}
      />
      <Route
        exact
        path={`${match?.url}/afa-navigation`}
        render={() => (
          <DisplayMedia
            media={side === 'left' ? pageData?.ledLeft : pageData?.ledRight}
            loop={true}
          />
        )}
      />
      <Route
        exact
        path={`${match.url}/digital-preview`}
        render={() => (
          <DisplayMedia
            media={content[videoNamesMap.digitalPreview]}
            loop={false}
            handleVideoEnded={handleDigitalPreviewEnded}
          />
        )}
      />
      <Route
        exact
        path={`${match.url}/welcome`}
        render={() => <DisplayMedia media={content[videoNamesMap.welcome]} loop={true} />}
      />
      <Route
        exact
        path={`${match.url}/multibrand`}
        render={() => <DisplayMedia media={content[videoNamesMap.multibrand]} loop={true} />}
      />
      <Route
        exact
        path={`${match.url}/:brand/activation`}
        render={() => {
          if (pageData && pageData.brand)
            return (
              <DisplayMedia
                media={pageData.brand[videoNamesMap.activation]}
                loop={false}
                handleVideoEnded={handleVideoEnded}
              />
            )
          return <Redirect to={match.url} />
        }}
      />
      <Route
        exact
        path={`${match.url}/:brand/navigation`}
        render={() => {
          if (pageData && pageData.brand)
            return <DisplayMedia media={pageData.brand[videoNamesMap.navigation]} loop={true} />
          return <Redirect to={match.url} />
        }}
      />
      <Route
        exact
        path={`${match.url}/cart`}
        render={() => (
          <LedList
            category={
              pageData && pageData.roomType === app_config.roomType.LARGE
                ? viewType === app_config.viewType.wall
                  ? app_config.sunCategory
                  : viewType
                : category
            }
            revert={revert}
          />
        )}
      />
      <Route
        exact
        path={`${match.url}/checkout`}
        render={() => <DisplayMedia media={content[videoNamesMap.welcome]} loop={true} />}
      />
    </Switch>
  )
}

const LedViewManager = PageSocketManager(LedView, Object.keys(pageEventsMap))

export default LedViewManager as React.ComponentType<OwnProps>
