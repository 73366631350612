import React, { SVGProps } from 'react'

const CalendarIcon: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.333"
      height="26.667"
      viewBox="0 0 6.174 7.056"
      fill="currentColor"
      {...props}
    >
      <path d="M5.512.882h-.661V.165A.166.166 0 004.685 0h-.11a.166.166 0 00-.165.165v.717H1.764V.165A.166.166 0 001.599 0h-.11a.166.166 0 00-.166.165v.717H.66A.662.662 0 000 1.543v4.851c0 .365.296.662.661.662h4.851a.662.662 0 00.662-.662v-4.85a.662.662 0 00-.662-.662zm-4.85.44h4.85c.121 0 .22.1.22.221v.662H.442v-.662c0-.12.1-.22.22-.22zm4.85 5.293H.662c-.122 0-.221-.1-.221-.22v-3.75h5.292v3.75c0 .12-.1.22-.22.22zM2.04 4.41c.091 0 .166-.075.166-.166v-.55a.166.166 0 00-.166-.166h-.55a.166.166 0 00-.166.165v.551c0 .091.074.166.165.166zm1.323 0c.091 0 .166-.075.166-.166v-.55a.166.166 0 00-.166-.166h-.55a.166.166 0 00-.166.165v.551c0 .091.074.166.165.166zm1.323 0c.091 0 .166-.075.166-.166v-.55a.166.166 0 00-.166-.166h-.55a.166.166 0 00-.166.165v.551c0 .091.074.166.165.166zM3.362 5.733c.091 0 .166-.075.166-.166v-.55a.166.166 0 00-.166-.166h-.55a.166.166 0 00-.166.165v.551c0 .091.074.166.165.166zm-1.323 0c.091 0 .166-.075.166-.166v-.55a.166.166 0 00-.166-.166h-.55a.166.166 0 00-.166.165v.551c0 .091.074.166.165.166zm2.646 0h-.55a.166.166 0 01-.166-.166v-.55c0-.092.074-.166.165-.166h.551c.091 0 .166.074.166.165v.551c0 .091-.075.166-.166.166z" />
    </svg>
  )
}

export default CalendarIcon
