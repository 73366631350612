import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Redirect } from 'react-router-dom'
import styled from 'styled-components'

import AfaImage from '../assets/images/assortments/afa.png'
import FramesImage from '../assets/images/assortments/frames.png'
import InstrumentsImage from '../assets/images/assortments/instruments.png'
import LensesImage from '../assets/images/assortments/lenses.png'
import Header from '../components/Header'
import Loading from '../components/Loading/index'
import devices from '../config/devices'
import useFetchInitialData from '../hooks/useFetchInitialData'
import socketManager from '../libs/socketManager'
import { getBaseUrl } from '../libs/url'
import { useGetSelectedCustomerAssortmentTypesQuery } from '../services/afa'
import { useGetAssortmentContentQuery } from '../services/afaContents'
import { breakpoints, getFluidSizeWithFullFormula, palette, pxToRem } from '../style/theme'
import { useDispatch } from 'react-redux'
import doorActions from '../store/customer/actions'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ChooseAssortmentHeader = styled(Header)`
  @media (max-width: ${breakpoints.M}) {
    border-bottom: none;
  }
`

const Main = styled.main`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  font-size: ${getFluidSizeWithFullFormula('px', 36, 64, 1366, 3840)};
  gap: 1em;
  background-color: ${palette.wildSand};
  padding: 2em;

  & > a:nth-of-type(odd) {
    place-self: end;
  }

  & > a:nth-of-type(even) {
    align-self: end;
  }

  @media (max-width: ${breakpoints.L}) {
    background-color: white;
  }

  @media (min-width: ${breakpoints.L}) {
    background-color: ${palette.lightGray};
  }
`

const AssortmentWrapper = styled(Link)`
  width: min-content;
  height: min-content;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const AssortmentImagePlaceholder = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  aspect-ratio: 1.77;
  position: relative;
  background-color: ${palette.concrete};
`

const AssortmentImage = styled.img`
  min-width: ${getFluidSizeWithFullFormula('px', 480, 904, 1366, 3840)};
  width: ${getFluidSizeWithFullFormula('px', 480, 904, 1366, 3840)};
  height: ${getFluidSizeWithFullFormula('px', 270, 509, 1366, 3840)};
`

const AssortmentText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette.tangaroa};
  color: ${palette.white};
  padding: ${getFluidSizeWithFullFormula('px', 22, 56, 1366, 3840)} 0;
  font-size: ${getFluidSizeWithFullFormula('px', 28, 40, 1366, 3840)};
  border-bottom-left-radius: ${pxToRem(8)}rem;
  border-bottom-right-radius: ${pxToRem(8)}rem;
`

const getAssortmentImage = (assortment: string): string => {
  switch (assortment) {
    case 'frames':
      return FramesImage
    case 'afa':
      return AfaImage
    case 'instruments':
      return InstrumentsImage
    case 'lenses':
      return LensesImage
    default:
      return AfaImage
  }
}

const ChooseAssortmentPage: React.FC = () => {
  useFetchInitialData()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(doorActions.resetCustomerState())
  }, [dispatch])

  useEffect(() => {
    socketManager.emitSignal('open_chooseAssortment_page', {}, [devices.table])
  }, [])

  const assortmentsQuery = useGetSelectedCustomerAssortmentTypesQuery()
  const assortments = assortmentsQuery.data || []

  const assortmentContentQuery = useGetAssortmentContentQuery()
  const assortmentContent = assortmentContentQuery.data || {}

  return assortments.length === 1 ? (
    <Redirect
      to={`${getBaseUrl()}/${assortments[0] === 'frames' ? '' : `${assortments[0]}/`}choose-brand`}
    />
  ) : (
    <Wrapper>
      <ChooseAssortmentHeader hideButtons={true} />

      <Main>
        {assortmentsQuery.isFetching && <Loading />}
        {assortments.map(assortment => (
          <AssortmentWrapper
            key={assortment}
            to={`${getBaseUrl()}/${assortment === 'frames' ? '' : 'afa/'}choose-brand`}
          >
            {assortmentContentQuery.isFetching ? (
              <AssortmentImagePlaceholder />
            ) : (
              <AssortmentImage
                src={assortmentContent[assortment] || getAssortmentImage(assortment)}
                alt=""
              />
            )}
            <AssortmentText>{t(`Afa.${assortment}`)}</AssortmentText>
          </AssortmentWrapper>
        ))}
      </Main>
    </Wrapper>
  )
}

export default ChooseAssortmentPage
