import React from 'react'
import SizeSeasonDate from './SizeSeasonDate'
import SizeRow from './SizeRow'
import { useTranslation } from 'react-i18next'
import { AfaCatalogId, DdMmYyyyDateString, Upc } from '../../model/model'
import { UpdateAfaCartProductPayload } from '../../model/afa'
import { sortDdMmYyyyDates } from '../../libs/time'

type Props = {
  availabilityDate: DdMmYyyyDateString
  minDeliveryDate: DdMmYyyyDateString
  customDeliveriesDates?: DdMmYyyyDateString[]
  availability: {
    availableQuantity: number
    upc: Upc
    afaCatalogId?: AfaCatalogId
  }[]
  updateAfaCart: (products: UpdateAfaCartProductPayload[]) => void
  disabled: boolean
  prices: {
    wholesale: number | null
    retail: number | null
  }
}

const SizeRowSeasonDate: React.FC<Props> = ({
  availabilityDate,
  customDeliveriesDates,
  minDeliveryDate,
  availability,
  updateAfaCart,
  disabled,
  prices,
}) => {
  const { t } = useTranslation()

  const isLatePrebook = availability[0]?.afaCatalogId === 'L'

  return (
    <>
      <SizeRow
        availability={availability}
        title={isLatePrebook ? t('Afa.chooseSizeOfLatePrebook') : t('Afa.chooseSizeOfInSeasonSale')}
        dropType={'SEASON'}
        availabilityDate={availabilityDate}
        minDeliveryDate={minDeliveryDate}
        updateAfaCart={updateAfaCart}
        disabled={disabled}
        prices={prices}
      >
        <SizeSeasonDate date={minDeliveryDate} />
      </SizeRow>
      {customDeliveriesDates &&
        customDeliveriesDates
          .slice()
          .sort(sortDdMmYyyyDates)
          .map(deliveryDate => (
            <SizeRow
              key={deliveryDate}
              availability={availability}
              dropType={'SEASON'}
              availabilityDate={availabilityDate}
              minDeliveryDate={minDeliveryDate}
              customDeliveryDate={deliveryDate}
              updateAfaCart={updateAfaCart}
              disabled={disabled}
              prices={prices}
            >
              <SizeSeasonDate date={deliveryDate} type="customDelivery" />
            </SizeRow>
          ))}
    </>
  )
}

export default SizeRowSeasonDate
