import { Col, Icon, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { getGridDimension } from '../../libs/brand'
import { toggleBrandsBarAction, toggleFilters } from '../../store/actions'
import { starsBrandsSelector } from '../../store/brands/selectors'
import {
  activePlpBrandsSelector,
  brandsBarOpenedSelector,
  brandsWithStatusSelector,
} from '../../store/filters/selectors'
import { cartModeSelector } from '../../store/stars/selectors'
import Brand from '../Brand/Brand'
import { FiltersRecapPanelBrand } from '../ModalFilterBy/FiltersRecapPanel/FiltersRecapPanel'

const FiltersBrandBarsStyled = styled.div`
  position: fixed;
  z-index: 2000;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(calc(100% - (4vh + 5vh)));
  transition: transform 250ms ease-in-out;
  text-transform: uppercase;
  background: #f8f8f8;
  &.active {
    transform: translateY(-5vh);
  }
`

const Title = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #005192;
  text-align: center;
  font-size: 1.5vh;
  font-weight: bold;
  height: 4vh;

  .anticon {
    transition: all 0.4s ease;
  }

  &.active {
    .anticon {
      transform: rotate(180deg);
      transition: all 0.4s ease;
    }
  }
`

const FiltersBrands = styled.div`
  display: flex;
`

const BrandsGridContainer = styled.div`
  display: grid;
  flex: 1;
  height: auto;
  grid-template-columns: repeat(auto-fill, minmax(16vh, 1fr));
  grid-auto-flow: row;
  align-items: center;
  justify-items: center;
  margin: 1% 0;
  column-gap: 20px;
  row-gap: 20px;

  .brand-logo {
    & > img {
      width: 7vw;
    }
  }

  &__max-18 {
    grid-template-columns: repeat(auto-fill, minmax(21vh, 1fr));
  }

  &__max-22 {
    grid-template-columns: repeat(auto-fill, minmax(17vh, 1fr));
    @media screen and (max-width: 1366px) {
      grid-template-columns: repeat(auto-fill, minmax(16vh, 1fr));
    }
  }

  &__max-26 {
    grid-template-columns: repeat(auto-fill, minmax(17vh, 1fr));
    @media screen and (max-width: 1366px) {
      grid-template-columns: repeat(auto-fill, minmax(18vh, 1fr));
    }
  }
`

const FilterBrand = styled.div`
  .brand-logo {
    padding: 2.5vh;
    margin: 2% 0;
    border: 1px solid transparent;
    transition: border 0.1s ease-out;

    & > img {
      height: 1.7vw;
      width: 6vw;
    }

    &.no-elements {
      border: 1px solid rgba(0, 0, 0, 0);
    }

    &.active {
      border-color: #dadada;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    }
  }
`

const BrandsPlank = styled(Row)`
  @media screen and (max-width: 1921px) {
    width: 100vw;
  }
`

export const FiltersBrandBars: React.FC = () => {
  const isFiltersBrandOpen = useSelector(brandsBarOpenedSelector)
  const activeBrands = useSelector(activePlpBrandsSelector)
  const brands = useSelector(brandsWithStatusSelector)
  const cartMode = useSelector(cartModeSelector)
  const starsBrands = useSelector(starsBrandsSelector)

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const gridDimension = getGridDimension(brands.length)
  const starsBrandsCodes = starsBrands.map(({ code }) => code)

  return (
    <FiltersBrandBarsStyled
      className={['filters-brand-bars', isFiltersBrandOpen ? 'active' : 'inactive'].join(' ')}
    >
      <BrandsPlank>
        <Title
          span={24}
          className={isFiltersBrandOpen ? 'active' : 'inactive'}
          onClick={() => dispatch(toggleBrandsBarAction())}
        >
          {`${t('GenericWords.brands')} (${activeBrands.selected &&
            activeBrands.selected.length} ${t('GenericWords.selected')})`}
          <Icon type="up" style={{ marginLeft: '10px' }} />
        </Title>
      </BrandsPlank>
      <FiltersBrands>
        <BrandsGridContainer
          className={gridDimension ? `brands-grid-container__${gridDimension}` : ''}
        >
          {activeBrands &&
            brands.map(brand => (
              <FilterBrand key={`brand-${brand.slug}`}>
                <Brand
                  notClickable={true}
                  selected={(brand as any).selected} // can it be removed?
                  hideCount={true}
                  slug={brand.slug}
                  logo={brand.logo}
                  selectBrand={slug => dispatch(toggleFilters('brand_slug', slug, 'checkbox'))}
                  active={cartMode === 'units' || starsBrandsCodes.includes(brand.code)}
                  brandCode={brand.code}
                />
              </FilterBrand>
            ))}
        </BrandsGridContainer>
        <div className="brands-recap-panel">
          <FiltersRecapPanelBrand title={t('Filters.item_segment')} />
        </div>
      </FiltersBrands>
    </FiltersBrandBarsStyled>
  )
}

export default FiltersBrandBars
