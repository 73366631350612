import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Brand } from '../../model/brand'
import { BrandCode, BrandsState } from '../../model/model'
import { resetForNewClient } from '../extraActions'

const DEFAULT_STATE: BrandsState = {
  items: [],
  loading: false,
  loaded: false,
}

export const slice = createSlice({
  name: 'brands',
  initialState: DEFAULT_STATE,
  reducers: {
    setBrandActivationPlaying: (
      state,
      { payload }: PayloadAction<{ brandCode: BrandCode; playing: boolean }>,
    ) => {
      state.items = state.items.map(brand =>
        brand.code === payload.brandCode
          ? { ...brand, isBrandActivationPlaying: payload.playing }
          : brand,
      )
    },
    setBrandDiscoveryPlaying: (
      state,
      { payload }: PayloadAction<{ brandCode: BrandCode; playing: boolean }>,
    ) => {
      state.items = state.items.map(brand =>
        brand.code === payload.brandCode
          ? { ...brand, isBrandDiscoveryPlaying: payload.playing }
          : brand,
      )
    },
    setBrandActivationPlayed: (
      state,
      {
        payload: { brandCode, played = true },
      }: PayloadAction<{ brandCode: BrandCode; played: boolean }>,
    ) => {
      state.items = state.items.map(brand =>
        brand.code === brandCode ? { ...brand, isBrandActivationPlayed: played } : brand,
      )
    },
    setBrandDiscoveryPlayed: (
      state,
      {
        payload: { brandCode, played = true },
      }: PayloadAction<{ brandCode: BrandCode; played: boolean }>,
    ) => {
      state.items = state.items.map(brand =>
        brand.code === brandCode ? { ...brand, isBrandDiscoveryPlayed: played } : brand,
      )
    },
    loadBrandsSuccess: (state, { payload }: PayloadAction<Brand[]>) => {
      state.items = payload || []
      state.loaded = true
      state.loading = false
    },
    loadingBrands: state => {
      state.loading = true
    },
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, () => DEFAULT_STATE)
  },
})

export default slice.reducer
