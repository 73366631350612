import { Button, Icon } from 'antd'
import classnames from 'classnames'
import { difference } from 'lodash'
import memoize from 'memoize-one'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { formatName } from '../../libs'
import {
  doDoorMatchSearch,
  formatDoorAddress,
  getDefaultChecked,
  sortDoorsList,
} from '../../libs/doors'
import { isIPadView } from '../../libs/url'
import { ItemsByDoors } from '../../model/cart'
import { Door, DoorsSortingBy } from '../../model/customer'
import { TDispatch } from '../../model/model'
import { toggleMassiveOrder } from '../../store/app/actions'
import { appSelector } from '../../store/app/selectors'
import { isStarsDoorSelector } from '../../store/brands/selectors'
import { cartSelector } from '../../store/cart/selectors'
import doorActions from '../../store/customer/actions'
import { customerDoorsSelector } from '../../store/customer/selectors'
import starsActions from '../../store/stars/actions'
import {
  breakpoints,
  getFluidFontSize,
  getFluidSize,
  getFluidSizeWithFullFormula,
  getNegativeFluidSize,
  palette,
  pxToRem,
  spacing,
} from '../../style/theme'
import Checkbox from '../../styleguide/Checkbox'
import { OnOffToggle } from '../../styleguide/OnOffToggle'
import { scrollbarCss } from '../CommonComponents'
import { AlphaNumericKeyboardInput } from '../KeyboardInput'
import StarsIcon from '../UI/StarsIcon'
import SwitchButton from './SwitchButton'
import { useAssortmentType } from '../../hooks/useAssortmentType'
import { useCartUnitsByDoor } from '../../hooks/useCartUnitsByDoor'
import { errorNotification } from '../Notification/notifications'

export const DoorsMenuTitle = styled.span`
  &.afa {
    font-family: GilmerMedium, sans-serif;
    font-size: ${getFluidSizeWithFullFormula('px', 24, 36, 1366, 3840)};
    text-transform: uppercase;
    color: ${palette.black};
  }
  font-size: ${getFluidFontSize('1.4rem')};
  font-weight: bold;
  margin-right: 2%;
  padding-left: 1%;

  &.afa {
    margin: 0;
    padding: 0;
  }
`

export const SearchField = styled.div`
  display: flex;
  margin-right: 4%;
  align-items: center;
  flex: 1;

  &.afa {
    width: ${getFluidSizeWithFullFormula('px', 643, 900, 1366, 3840)};
    margin: 0;

    .ant-input {
      border: 1px solid ${palette.shadowBlue};
      height: ${getFluidSizeWithFullFormula('px', 56, 70, 1366, 3840)};
      color: ${palette.shadowBlue};
    }
  }

  .keyboard-input {
    width: 100%;
  }
  .anticon-search {
    font-size: ${getFluidFontSize('1.4rem')};
    margin-left: -35px;
    z-index: 1;
  }
  .ant-input {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    font-size: 1rem;
    padding-right: 50px;
  }
`

export const SortingIcons = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${getFluidFontSize('0.75rem')};
  margin-left: ${getFluidSize('0.25rem')};
`

export const DoorsListTitleAndSearch = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  &.afa {
    flex-direction: column;
    align-items: start;
    gap: ${getFluidSizeWithFullFormula('px', 32, 48, 1366, 3840)};
  }
`

export const DoorsList = styled.div`
  &.afa {
    font-family: GilmerRegular, sans-serif;
  }
  padding: 0 3.5rem;
  @media screen and (max-width: ${breakpoints.M}) {
    padding: 0 2rem;
  }
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  .ant-checkbox-group {
    flex: 1;
    position: relative;
    width: 100%;
    table {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
    }
  }
`

const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  flex: 1;
`

export const DoorsTableHeader = styled.thead`
  font-size: ${getFluidFontSize('1rem')};
  color: ${palette.hibiscus};
  text-transform: uppercase;
  display: table;
  width: 100%;

  &.afa {
    font-family: GilmerMedium, sans-serif;
    color: ${palette.santasGray};

    td {
      & > div {
        justify-content: start;
      }

      :nth-child(4) {
        & > div {
          justify-content: center;
        }
      }
    }
  }

  td {
    padding-right: 0;
  }
  @media screen and (max-width: ${breakpoints.L}) {
    padding: 2vh 1vw;
  }
  .doors-menu-select-all {
    justify-self: flex-end;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  .sorting-icon {
    margin-left: 0.25rem;
  }
`

export const DoorsItem = styled.tr`
  &.afa {
    font-family: GilmerMedium, sans-serif;
    color: ${palette.black};
    box-shadow: unset;
    border: 1px solid ${palette.extraLight};
    margin: 0;
    &.doors-default {
      background-color: ${palette.extraLight};
    }
  }
  position: relative;
  font-size: ${getFluidFontSize('1rem')};
  color: ${palette.curiousBlue};
  border: 2px solid ${palette.wildSand};
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  align-items: center;
  margin: 0 0 2px;
  padding: 0 1vw;
  justify-items: start;
  display: table;
  width: 100%;
  &.doors-default {
    background-color: ${palette.wildSand};
  }
  .id-cell {
    position: relative;
  }
  .doors-item-default {
    position: absolute;
    left: ${getNegativeFluidSize('2rem')};
    top: 50%;
    bottom: 0;
    right: 0;
    width: ${getFluidSize('3rem')};
    height: ${getFluidSize('3rem')};
    transform: translateY(-50%);
    span {
      transform: translateX(-50%) translateY(-50%) rotate(-90deg);
      text-transform: uppercase;
      font-size: ${getFluidFontSize('0.7rem')};
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
  .ant-checkbox-inner {
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid ${palette.cornflowerBlue};
    border-radius: 4px;
    background: ${palette.selago};
    &::after {
      transform: rotate(45deg) scale(2);
      border: 1px solid ${palette.congressBlue};
      border-top: 0;
      border-left: 0;
      margin-left: 0.4rem;
    }
  }
`

const UnitCounter = styled.span`
  &.afa {
    color: ${palette.white};
    min-width: ${getFluidSizeWithFullFormula('px', 24, 36, 1366, 3840)};
    border-radius: 2rem;
    background-color: ${palette.tangaroa};
    font-size: ${getFluidSizeWithFullFormula('px', 12, 24, 1366, 3840)};
    padding: ${getFluidSizeWithFullFormula('px', 5, 12, 1366, 3840)};
    font-family: GilmberBold, sans-serif;
  }
`

export const DoorsScrollableBody = styled.tbody`
  ${scrollbarCss};
  overflow-y: auto;
  min-height: 8vh;
  display: block;
  margin: 0 ${getNegativeFluidSize('1rem')} 0 0;
  padding: 0 ${getFluidSize('1rem')};
  width: auto;

  &.afa {
    padding: 0;
    margin: 0;
  }
`

const StyledStarsIcon = styled(StarsIcon)`
  height: ${pxToRem(spacing(3.5))}rem;
  width: ${pxToRem(spacing(3.5))}rem;
  color: ${palette.congressBlue};
`

// OLD SASS
const DoorsListHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${palette.biscay};
  margin-bottom: ${pxToRem(1)}rem;

  &.afa {
    align-items: end;
    margin-bottom: ${getFluidSizeWithFullFormula('px', 32, 48, 1366, 3840)};

    .button-apply {
      font-family: GilmerBold, sans-serif;
      border-radius: 4px;
      background-color: ${palette.tangaroa};
      height: ${getFluidSizeWithFullFormula('px', 56, 70, 1366, 3840)};
      width: ${getFluidSizeWithFullFormula('px', 204, 240, 1366, 3840)};
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${getFluidSizeWithFullFormula('px', 13, 18, 1366, 3840)};
    }
  }

  .ant-checkbox-wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    font-size: ${pxToRem(1)}rem;
    color: ${palette.biscay};
  }
  .ant-button-apply {
    &.hidden {
      visibility: hidden;
    }
  }
`

const MassiveOrderBlock = styled.div`
  margin-right: ${pxToRem(2)}rem;
`

const MassiveOrderSwitch = styled.div`
  height: ${pxToRem(50)}rem;
  border: ${pxToRem(2)}rem solid ${palette.wildSand};
  display: flex;
  align-content: center;
  justify-self: flex-start;
  box-shadow: 0 ${pxToRem(4)}rem ${pxToRem(4)}rem 0 rgba(0, 0, 0, 0.02);
  border-radius: ${pxToRem(4)}rem;
  padding: 0 ${pxToRem(0.5)}rem;
`

const DoorsTable = styled.table`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .ant-checkbox-wrapper-disabled {
    visibility: hidden;
  }

  td {
    padding: 1.3vh 0.5vw;
    align-items: center;
    text-align: center;
    max-height: 8vh;
    width: auto;
    :first-child {
      width: 10%;
    }
    :nth-child(2) {
      width: 20%;
      @media screen and (max-width: ${breakpoints.L}) {
        width: 15%;
      }
    }
    :nth-child(3) {
      width: 25%;
    }
    :nth-child(4) {
      width: 10%;
      text-align: center;
      .horizontal-align {
        display: inline-flex;
        align-items: center;
      }
    }
    :nth-child(5) {
      width: 10%;
      text-align: center;
    }
    :last-child {
      width: 20%;
      text-align: right;
    }
  }

  &.afa {
    td {
      font-size: ${getFluidSizeWithFullFormula('px', 16, 24, 1366, 3840)};
      text-align: left;
      :nth-child(4) {
        text-align: center;
      }
      :last-child {
        text-align: right;
        width: 30%;
      }
    }
  }
`

const LabelAndSortingIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const getUnits = (doorId: string, itemsByDoors: ItemsByDoors) => {
  return itemsByDoors[doorId] ? itemsByDoors[doorId] : undefined
}

const getFormattedDoors = (
  doors: Door[],
  itemsByDoors: ItemsByDoors,
  sortingBy: DoorsSortingBy,
) => {
  const doorsWithUnits = doors.map(door => ({
    ...door,
    units: getUnits(door.id, itemsByDoors) || 0,
  }))

  return sortDoorsList(doorsWithUnits, sortingBy)
}

const formatDoors = memoize((doors, itemsByDoors, sortingBy) => {
  return getFormattedDoors(doors, itemsByDoors, sortingBy)
})

const checkDoorsChanged = (defaultChecked: string[], checkedList: string[]) => {
  let isChanged = false
  if (defaultChecked)
    isChanged =
      defaultChecked.length !== checkedList.length ||
      difference(defaultChecked, checkedList || []).length > 0

  return isChanged
}

type DoorProps = {
  door: Door
  itemsByDoors: ItemsByDoors
  checkedList: string[]
  setCheckedList: any
}

const DoorRow: React.FC<DoorProps> = ({ door, itemsByDoors, checkedList, setCheckedList }) => {
  const assortmentType = useAssortmentType()
  const isStarsDoor = useSelector(isStarsDoorSelector)
  const units = getUnits(door.id, itemsByDoors)
  const isMainDoor = door.mainDoor

  return (
    <DoorsItem
      onClick={e => e.stopPropagation()}
      className={classnames({
        'doors-default': door.mainDoor,
        [assortmentType]: true,
      })}
    >
      <td className="id-cell">
        <div className="store-id">{door.id}</div>
        {isMainDoor && assortmentType === 'frames' && (
          <span className="doors-item-default">
            <span>default</span>
          </span>
        )}
      </td>
      <td>{formatName(door.name, isIPadView() ? 44 : 63)}</td>
      <td>{formatDoorAddress(door)}</td>
      <td>{units && <UnitCounter className={assortmentType}>{units}</UnitCounter>}</td>
      {assortmentType === 'frames' && <td>{isStarsDoor(door) && <StyledStarsIcon />}</td>}
      <td>
        <Checkbox
          className={classnames({ [assortmentType]: true })}
          value={door.id}
          checked={checkedList.indexOf(door.id) > -1}
          onChange={() =>
            setCheckedList((state: any) =>
              state.indexOf(door.id) > -1
                ? state.filter((i: string) => i !== door.id)
                : [...state, door.id],
            )
          }
        />
      </td>
    </DoorsItem>
  )
}

const SortingIcon: React.FC<{ sortingBy: DoorsSortingBy; sortingKey: string }> = ({
  sortingBy,
  sortingKey,
}) => (
  <SortingIcons>
    <Icon
      className="sorting-icon"
      style={{
        visibility: sortingBy.key === sortingKey && sortingBy.ascending ? 'hidden' : 'visible',
      }}
      type="caret-up"
    />
    <Icon
      className="sorting-icon"
      style={{
        visibility: sortingBy.key === sortingKey && !sortingBy.ascending ? 'hidden' : 'visible',
      }}
      type="caret-down"
    />
  </SortingIcons>
)

export const ModalDoorsList: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TDispatch = useDispatch()
  const customerDoors = useSelector(customerDoorsSelector)
  const doorsLength = customerDoors.length
  const app = useSelector(appSelector)
  const cart = useSelector(cartSelector)
  const defaultChecked: string[] = getDefaultChecked(customerDoors)
  const assortmentType = useAssortmentType()
  const cartUnitsByDoor = useCartUnitsByDoor()

  const [searchQuery, setSearchQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const [checkedList, setCheckedList] = useState(defaultChecked)
  const [sortingBy, setSortingBy] = useState<DoorsSortingBy>({
    key: 'units',
    ascending: false,
  })

  const setSortingByKey = (key: string) => () =>
    setSortingBy({
      key,
      ascending: key === sortingBy.key ? !sortingBy.ascending : false,
    })

  const doorsList = formatDoors(customerDoors, cart.statistics?.itemsByDoors, sortingBy)
  const filteredDoors = doorsList.filter(door => {
    const matchSearch = doDoorMatchSearch(door, searchQuery)
    return matchSearch
  })

  return (
    <DoorsList className={classnames({ [assortmentType]: true })}>
      <DoorsListHeader className={classnames({ [assortmentType]: true })}>
        <DoorsListTitleAndSearch className={classnames({ [assortmentType]: true })}>
          <DoorsMenuTitle className={classnames({ [assortmentType]: true })}>
            {t('FooterNav.cart_doors')} ({defaultChecked.length}/{doorsLength})
          </DoorsMenuTitle>
          <SearchField className={classnames({ [assortmentType]: true })}>
            <AlphaNumericKeyboardInput
              isFirstLetterUppercase={false}
              uppercaseAfterSpace={false}
              inputClassName="ant-input"
              onChange={setSearchQuery}
              className="search-customer-field"
              value={searchQuery}
              placeholder={t('FooterNav.item_search')}
            />
            <Icon type="search" />
          </SearchField>
        </DoorsListTitleAndSearch>
        {assortmentType === 'frames' && (
          <MassiveOrderBlock>
            <MassiveOrderSwitch>
              <OnOffToggle
                onChange={() => dispatch(toggleMassiveOrder())}
                checked={app.massiveOrder}
                label={t('FooterNav.massiveOrder')}
              />
            </MassiveOrderSwitch>
          </MassiveOrderBlock>
        )}
        <Button
          href="#"
          className={classnames({
            'button-apply': true,
            hidden: !checkDoorsChanged(defaultChecked, checkedList),
            [assortmentType]: true,
          })}
          loading={loading}
          type="primary"
          onClick={e => {
            e.preventDefault()
            if (checkedList.length !== 0) {
              setLoading(true)
              dispatch(doorActions.updateDoors(checkedList)).then(() => setLoading(false))
              dispatch(starsActions.resetStarsFilters())
            } else {
              errorNotification({
                message: 'You cannot deselect all doors, at least one door must be selected',
              })
            }
          }}
        >
          {t(assortmentType === 'afa' ? 'Doors.Apply' : 'FooterNav.cart_doors_button')}
        </Button>
      </DoorsListHeader>
      <TableWrapper>
        <DoorsTable className={classnames({ [assortmentType]: true })}>
          <DoorsTableHeader className={classnames({ [assortmentType]: true })}>
            <tr>
              <td onClick={setSortingByKey('id')}>
                <LabelAndSortingIcons>
                  <span className="label">ID</span>
                  <SortingIcon sortingBy={sortingBy} sortingKey="id" />
                </LabelAndSortingIcons>
              </td>
              <td onClick={setSortingByKey('name')}>
                <LabelAndSortingIcons>
                  <span className="label">{t('FooterNav.cart_doors_name')}</span>
                  <SortingIcon sortingBy={sortingBy} sortingKey="name" />
                </LabelAndSortingIcons>
              </td>
              <td onClick={setSortingByKey('address')}>
                <LabelAndSortingIcons>
                  <span className="label">{t('FooterNav.cart_doors_addres')}</span>
                  <SortingIcon sortingBy={sortingBy} sortingKey="address" />
                </LabelAndSortingIcons>
              </td>
              <td onClick={setSortingByKey('units')}>
                <LabelAndSortingIcons>
                  <span className="label">{t('GenericWords.units')}</span>
                  <SortingIcon sortingBy={sortingBy} sortingKey="units" />
                </LabelAndSortingIcons>
              </td>
              {assortmentType === 'frames' && (
                <td onClick={setSortingByKey('stars')}>
                  <LabelAndSortingIcons>
                    <span className="label">{t('GenericWords.stars')}</span>
                    <SortingIcon sortingBy={sortingBy} sortingKey="stars" />
                  </LabelAndSortingIcons>
                </td>
              )}
              <td>
                <div className="horizontal-align">
                  <SwitchButton
                    className={classnames({ [assortmentType]: true })}
                    onClick={() =>
                      setCheckedList(
                        checkedList.filter(
                          (doorId: string) => !filteredDoors.map(door => door.id).includes(doorId),
                        ),
                      )
                    }
                  >
                    {t('FooterNav.cart_deselectall')}
                  </SwitchButton>
                  <SwitchButton
                    className={classnames({ [assortmentType]: true })}
                    onClick={() =>
                      setCheckedList(
                        checkedList
                          .filter(
                            (doorId: string) => !filteredDoors.find(door => door.id === doorId),
                          )
                          .concat(filteredDoors.map((item: Door) => item.id)),
                      )
                    }
                  >
                    {t('FooterNav.cart_selectall')}
                  </SwitchButton>
                </div>
              </td>
            </tr>
          </DoorsTableHeader>
          <DoorsScrollableBody className={`doors-table ${assortmentType}`}>
            {filteredDoors.map(door => (
              <DoorRow
                door={door}
                key={door.id}
                checkedList={checkedList}
                setCheckedList={setCheckedList}
                itemsByDoors={cartUnitsByDoor}
              />
            ))}
          </DoorsScrollableBody>
        </DoorsTable>
      </TableWrapper>
    </DoorsList>
  )
}

export default ModalDoorsList
