// function generates a unique file name by appending an incrementing count to the name if it already exists in the existingFiles array.
// e.g.input:  existingFiles = ['fileA', 'fileA_1', 'fileB']
// e.g. input: fileName = 'fileA'
// output: fileA_2

export const generateUniqueFileName = (name: string, existingFiles: string[]) => {
  let uniqueName = name
  let count = 1

  while (existingFiles.includes(uniqueName)) {
    uniqueName = `${name}_${count}`
    count++
  }

  return uniqueName
}
