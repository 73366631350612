import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Colors,
  ColorImagesWrapper,
  NotificationItem,
  ImageWrapper,
  Buttons,
  CancelButton,
  ColorDoorsAndDropsWithMargin,
  ColorImages,
  DoneButton,
  Header,
  Icon,
  Img,
  SelectedColorData,
  SelectedColorPlaceholder,
  Title,
  Wrapper,
  Subtitle,
  Main,
  SelectedColorWrapper,
} from './style'
import Loading from '../../../../components/Loading'
import { useGetProductQuery } from '../../../../services/afaProduct'

import { getSelectedBoard, getSelectedProducts } from '../../../../store/whiteboard/selectors'
import AfaPdpSelectedColorRecap from '../../../../containers/AfaPdp/AfaPdpSelectedColorRecap'
import whiteboardActions from '../../../../store/whiteboard/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from '../../../../hooks/useSearchParams'
import afaCartAdjustActions from '../../../../store/afaCartAdjust/actions'
import Notification from '../Notification'
import { useGetAfaCartProducts } from '../../../../hooks/useGetAfaCartProducts'
import { afaCartDataSelector } from '../../../../store/afaCart/selectors'
import { ProductType } from '../../../../model/whiteboard'
import RCIcon from '../../../../components/UI/RCIcon'
import { getProductImageUrl } from '../../../../libs/productImages'
import { v4 as uuidv4 } from 'uuid'
import { afaCartAdjustSelectors } from '../../../../store/afaCartAdjust/selectors'
import { UpdateAfaCartProductPayload } from '../../../../model/afa'

type Props = {
  onConfirm: (updateAfaCartProductPayloads: UpdateAfaCartProductPayload[]) => void
  onClose: (value: React.SetStateAction<boolean>) => void
}

const AddItemsToCartModal: React.FC<Props> = ({ onConfirm, onClose }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const selectedModelCode = searchParams.get('model') || ''
  const selectedColorCode = searchParams.get('color') || ''

  const resetQueryParams = useCallback(() => {
    searchParams.delete('model')
    searchParams.delete('color')
    setSearchParams(searchParams, { replace: true })
  }, [searchParams, setSearchParams])

  const selectedBoard = useSelector(getSelectedBoard)
  const selectedBoardItemsFiltered =
    (selectedBoard && selectedBoard.items.filter(item => item.product.invalid !== true)) || []
  const selectedProducts = useSelector(getSelectedProducts)
  const selectedProductsFiltered = selectedProducts.filter(item => item.product.invalid !== true)
  const productsDetails = useSelector(afaCartDataSelector)
  const productQuery = useGetProductQuery(selectedModelCode)
  const { cartProducts } = useGetAfaCartProducts()

  const productsInList =
    selectedProductsFiltered.length > 0
      ? selectedProductsFiltered.map(({ product }) => product)
      : selectedBoardItemsFiltered.map(({ product }) => product)

  const setSelectedProduct = useCallback(
    ({ productNumber = '', colorCode = '' }) => {
      searchParams.set('model', productNumber)
      searchParams.set('color', colorCode)

      setSearchParams(searchParams, { replace: true })
    },
    [searchParams, setSearchParams],
  )

  const selectedProduct = productsInList?.find(
    ({ productNumber, colorCode }) =>
      productNumber === selectedModelCode && colorCode === selectedColorCode,
  )
  const selectedProductDetails = productQuery?.data?.mocos?.[selectedColorCode]

  const uniqueCartProducts = cartProducts.filter(
    ({ modelCode, colorCode }, i) =>
      cartProducts.findIndex(
        cartProduct => cartProduct.modelCode === modelCode && cartProduct.colorCode === colorCode,
      ) === i,
  )

  const alreadyInCartProducts = useMemo(() => {
    return (
      uniqueCartProducts.filter(({ modelCode, colorCode }) =>
        productsInList?.find(
          product => product.productNumber === modelCode && product.colorCode === colorCode,
        ),
      ) || []
    )
  }, [productsInList, uniqueCartProducts])

  const notInCartProducts = useMemo(() => {
    return (
      productsInList?.filter(
        ({ colorCode, productNumber }) =>
          !alreadyInCartProducts.find(
            product => product.colorCode === colorCode && product.modelCode === productNumber,
          ),
      ) || []
    )
  }, [alreadyInCartProducts, productsInList])

  useEffect(() => {
    if (notInCartProducts.length > 0) {
      setSelectedProduct(notInCartProducts[0])
    }
    dispatch(afaCartAdjustActions.reset())
    // adding dependencies cause an inifitive re-rendering loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getImage = (image: string) =>
    image?.indexOf('cdn-record-files-pi/') > -1
      ? image?.split('cdn-record-files-pi/')[1]
      : getProductImageUrl({ path: image, imwidth: 200 })

  const productsPreAddedToCart = useSelector(afaCartAdjustSelectors.cartProducts)
  const productsPreAddedToCartFiltered = productsPreAddedToCart.filter(
    ({ unconfirmedQuantity }) => unconfirmedQuantity > 0,
  )

  const disableDoneButton = productsPreAddedToCartFiltered.length <= 0

  const handleSave = useCallback(() => {
    if (disableDoneButton) {
      return
    }

    const updateAfaCartProductPayloads = productsPreAddedToCart.map(product => {
      const quantity = product.unconfirmedQuantity
      return {
        ...product,
        quantity,
      }
    })

    onConfirm(updateAfaCartProductPayloads)
    dispatch(whiteboardActions.setOpenAddItemsInCart(false))
    resetQueryParams()
  }, [disableDoneButton, dispatch, onConfirm, productsPreAddedToCart, resetQueryParams])

  const isProductPreAdded = (product: ProductType) => {
    return productsPreAddedToCartFiltered.find(
      ({ colorCode, modelCode }) =>
        product.productNumber === modelCode && product.colorCode === colorCode,
    )
  }

  return (
    <Wrapper>
      <Main>
        <Header>
          <Title>{t('Whiteboard.addItemsToCartModal.title')}</Title>
          {notInCartProducts.length > 0 && (
            <Subtitle>
              {notInCartProducts.length}{' '}
              {notInCartProducts.length > 1
                ? t('Whiteboard.addItemsToCartModal.products')
                : t('Whiteboard.addItemsToCartModal.product')}
            </Subtitle>
          )}

          {!selectedBoard && <Loading />}

          {!!alreadyInCartProducts.length && (
            <Notification
              title={t('Whiteboard.addItemsToCartModal.alreadyInCartNotification.title')}
              iconType="afa-warning"
              subtitle={t('Whiteboard.addItemsToCartModal.alreadyInCartNotification.subtitle')}
            >
              <>
                {alreadyInCartProducts.map(({ modelCode, colorCode, key }) => {
                  const productDetails = productsDetails?.[modelCode]
                  return (
                    <NotificationItem key={key}>
                      {productDetails?.name} {modelCode}-{colorCode}
                    </NotificationItem>
                  )
                })}
              </>
            </Notification>
          )}

          {/*  { //QUESTA NOTIFICA AL MOMENTO NON DEVE ESSERE PRESENTE
            <Notification
              title={t('Whiteboard.addItemsToCartModal.completeCartNotification.title')}
              subtitle={t('Whiteboard.addItemsToCartModal.completeCartNotification.subtitle')}
            />
          } */}
        </Header>

        {selectedProduct && (
          <SelectedColorWrapper>
            <ImageWrapper className="active">
              <Img src={getImage(selectedProduct?.image || '')} className="active" alt="" />
              {isProductPreAdded(selectedProduct) && (
                <Icon className="active">
                  <RCIcon type="afa-success" size="100%" />
                </Icon>
              )}
            </ImageWrapper>
            {!selectedProductDetails && <SelectedColorPlaceholder />}
            {selectedProductDetails && (
              <AfaPdpSelectedColorRecap
                name={selectedProduct?.productName || ''}
                modelCode={selectedProductDetails.modelCode}
                selectedColor={selectedProductDetails}
              />
            )}
          </SelectedColorWrapper>
        )}

        {notInCartProducts.length > 0 && (
          <Colors>
            <ColorImagesWrapper>
              <ColorImages>
                {notInCartProducts
                  .filter(
                    ({ productNumber, colorCode }) =>
                      !(productNumber === selectedModelCode && colorCode === selectedColorCode),
                  )
                  .map(product => (
                    <ImageWrapper key={uuidv4()} onClick={() => setSelectedProduct(product)}>
                      <Img src={getImage(product.image)} alt="" />
                      {isProductPreAdded(product) && (
                        <Icon>
                          <RCIcon type="afa-success" size="100%" />
                        </Icon>
                      )}
                    </ImageWrapper>
                  ))}
              </ColorImages>
            </ColorImagesWrapper>

            <SelectedColorData>
              <ColorDoorsAndDropsWithMargin />
            </SelectedColorData>
          </Colors>
        )}

        <Buttons>
          <CancelButton
            onClick={() => {
              dispatch(afaCartAdjustActions.reset())
              dispatch(whiteboardActions.setOpenAddItemsInCart(false))
              onClose
            }}
          >
            {t('Whiteboard.addItemsToCartModal.cancel')}
          </CancelButton>
          <DoneButton disabled={disableDoneButton} onClick={() => handleSave()}>
            {t('Whiteboard.addItemsToCartModal.done')}
          </DoneButton>
        </Buttons>
      </Main>
    </Wrapper>
  )
}

export default AddItemsToCartModal
