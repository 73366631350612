import { isEqual, keys } from 'lodash'
import filter from 'mout/object/filter'
import find from 'mout/object/find'
import map from 'mout/object/map'
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'

import { getActiveFilters, getCounterValue, getSelectedFilters } from '../../libs/filters'
import { isNotUndefined } from '../../libs/utils'
import { CartFilters, FilterData, PlpFilters, WishlistFilters } from '../../model/filters'
import { CategoryId, RootState } from '../../model/model'

export const filtersForActivePageSelector = (state: RootState) =>
  state.filters[state.filters.pageType]

export const selectedFiltersSelector = (state: RootState) => {
  const filtersForActivePage = filtersForActivePageSelector(state)
  const selectedFilters: Partial<CartFilters> = filter(
    filtersForActivePage,
    (filterItem: FilterData, filterKey: keyof CartFilters) =>
      !['brand_slug', 'segment'].includes(filterKey) &&
      filterItem.selected &&
      filterItem.selected.length,
  )
  const selectedFiltersKey = keys(selectedFilters) as (keyof CartFilters)[]
  const selectedOptions = selectedFiltersKey.reduce(
    (result, filterKey) => {
      const filter = selectedFilters[filterKey]
      const filterActiveOptions = filter?.selected
        .map((selectedId: string) => {
          const filterOption = filter.options.find(
            (option: { id: string }) => option.id === selectedId,
          )

          return (
            filterOption && {
              filterKey,
              selectedId,
              selected: true,
              label: filterOption.label,
              optionName: filterOption.name,
              quantity: getCounterValue(filter, selectedId),
            }
          )
        })
        .filter(isNotUndefined)

      return filterActiveOptions ? [...result, ...filterActiveOptions] : result
    },
    [] as {
      filterKey: keyof CartFilters
      selectedId: string
      selected: boolean
      label: string
      optionName: string
      quantity: number
    }[],
  )

  return selectedOptions
}

export const thereAreFiltersToApplyselector = (state: RootState) => {
  const filters = state.filters[state.filters.pageType]
  return Boolean(
    find(
      filters,
      (filterItem: FilterData, key: string) =>
        !['segment', 'brand_slug'].includes(key) &&
        filterItem.selected &&
        filterItem.active &&
        !isEqual(filterItem.selected, filterItem.active),
    ),
  )
}

export const brandsBarOpenedSelector = (state: RootState) => state.filters.brandsBarOpened

export const segmentFiltersSelector = (state: RootState) => state.filters.plp.segment

export const segmentFiltersOptionsSelector = createSelector(
  segmentFiltersSelector,
  segmentFilters => {
    return (
      segmentFilters?.options.map(segmentOption => {
        return {
          filterKey: 'segment' as keyof CartFilters,
          selectedId: segmentOption.id,
          selected: segmentFilters.selected.includes(segmentOption.id),
          label: segmentOption.label,
          optionName: segmentOption.name,
        }
      }) || []
    )
  },
)

export const activePlpBrandsSelector = (state: RootState) => state.filters.plp['brand_slug']

export const brandsWithStatusSelector = (state: RootState) => {
  const brandFilter = state.filters.plp.brand_slug
  return state.brands.items.map(brand => ({
    ...brand,
    active: brandFilter.active.includes(brand.slug),
    selected: brandFilter.selected.includes(brand.slug),
  }))
}

export const filtersSelector = (state: RootState) => state.filters[state.filters.pageType]

export const plpFiltersSelector = (state: RootState) => state.filters.plp

export const cartFiltersSelector = (state: RootState) => state.filters.cart

export const activeFiltersSelector = createSelector(filtersSelector, getActiveFilters)

export const areAppliedFiltersChanged = (
  previousAppliedFilters: CartFilters | PlpFilters | WishlistFilters,
  currentAppliedFilters: Partial<CartFilters> | Partial<PlpFilters> | Partial<WishlistFilters>,
) => {
  const extractActiveKey = (
    appliedFilters: Partial<CartFilters> | Partial<PlpFilters> | Partial<WishlistFilters>,
  ) => map(appliedFilters, (filter: { active: boolean }) => filter.active)
  // TODO: areAppliedFiltersChanged returns true when filters are not changed??
  return isEqual(extractActiveKey(currentAppliedFilters), extractActiveKey(previousAppliedFilters))
}

const createFiltersSelector = createSelectorCreator(defaultMemoize, areAppliedFiltersChanged)

export const activeCartFiltersSelector = createFiltersSelector(
  cartFiltersSelector,
  getActiveFilters,
)

export const activePlpFiltersSelector = createFiltersSelector(plpFiltersSelector, getActiveFilters)

export const selectedCartFiltersSelector = createSelector(cartFiltersSelector, getSelectedFilters)

export const isSegmentSelectedSelector = (state: RootState, segmentId: string) =>
  state.filters.plp.segment.selected.includes(segmentId)

export const filterActivePageNameSelector = (state: RootState) => state.filters.pageType

export const plpFiltersCategorySelector = (state: RootState) =>
  state.filters.plp.category.active[0] as CategoryId | undefined

export const isPlpFilteredSelector = createSelector(activePlpFiltersSelector, activeFilters => {
  const thereAreFiltersOtherThanBrandAndCategory =
    Object.keys(activeFilters).filter(key => !['brand_slug', 'category'].includes(key)).length > 0
  const moreThanOneBrandActive = activeFilters.brand_slug?.active?.length > 1
  return thereAreFiltersOtherThanBrandAndCategory || moreThanOneBrandActive
})
