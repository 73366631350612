import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Loading from '../../components/Loading/index'
import { useSearchParams } from '../../hooks/useSearchParams'
import { useGetPlpProductsQuery } from '../../services/afaProducts'
import { breakpoints, palette, pxToRem } from '../../style/theme'
import { useSelector, useDispatch } from 'react-redux'
import { afaProductsSelector } from '../../store/afa/selectors'
import afaActions from '../../store/afa/actions'
import AfaPlpProductsList from './AfaPlpProductsList'
import { useParams } from 'react-router'

const Wrapper = styled.div`
  height: 100%;
  background-color: ${palette.alabaster};
  overflow: auto;
  display: flex;
  gap: ${pxToRem(24)}rem;
  flex-direction: column;

  // React-window uses overflow: auto inline, iOS allows to scroll horizontally even if there are no overflowing elements
  // Needs !important to override inline style
  // https://abstractsrl.atlassian.net/browse/RED-5825
  .plp-grid {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
`

const NumberOfProducts = styled.div`
  color: ${palette.tangaroa};
  display: flex;
  align-items: center;
  align-self: flex-end;
  font-size: ${pxToRem(24)}rem;

  @media screen and (max-width: ${breakpoints.L}) {
    display: none;
  }
`

const useScrollToTopEveryTimeQueryStringChanges = (
  scrollingElementRef: React.RefObject<HTMLDivElement>,
) => {
  const [searchParams] = useSearchParams()
  searchParams.delete('start')
  const searchParamsWithoutStart = searchParams.toString()

  useEffect(() => {
    if (scrollingElementRef.current) {
      scrollingElementRef.current.scrollTop = 0
    }
  }, [searchParamsWithoutStart, scrollingElementRef])
}

type Props = {
  hideNumberOfProducts?: boolean
}

const AfaPlpProducts: React.FC<Props> = ({ hideNumberOfProducts }) => {
  const { t } = useTranslation()

  const products = useSelector(afaProductsSelector)

  const destroyWithDifferentProducts = products[0]?.modelCode || ''

  const productsQuery = useGetPlpProductsQuery()

  const scrollingElementRef = useRef<HTMLDivElement>(null)

  useScrollToTopEveryTimeQueryStringChanges(scrollingElementRef)

  const dispatch = useDispatch()

  const { category } = useParams<{ category: string }>()
  useEffect(() => {
    dispatch(afaActions.setProducts([]))
  }, [category, dispatch])

  useEffect(() => {
    if (!productsQuery.isFetching && productsQuery.currentData) {
      dispatch(afaActions.concatProducts(productsQuery.currentData))
    }
  }, [productsQuery.isFetching, productsQuery.currentData, dispatch])

  return (
    <Wrapper>
      {!hideNumberOfProducts && (
        <NumberOfProducts>
          {productsQuery.data && productsQuery.data.count > 0
            ? `${productsQuery.data?.count} ${t('Afa.products')}`
            : ''}
        </NumberOfProducts>
      )}

      <AfaPlpProductsList key={destroyWithDifferentProducts} products={products} />

      {productsQuery.isFetching && <Loading isFullPage={false} />}
    </Wrapper>
  )
}

export default AfaPlpProducts
