import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import devices from '../config/devices'
import { buildMocoCode } from '../libs/productsV2'
import socketManager from '../libs/socketManager'
import { isTableDevice } from '../libs/url'
import { AfaProduct } from '../model/afa'
import { useGetAdvContentsQuery } from '../services/contents'
import { play_video } from '../store/actions'
import { brandSelectorByCodes } from '../store/brands/selectors'
import { couvetteVideoIdSelector } from '../store/couvetteVideo/selectors'
import { breakpoints, getFluidSizeWithFullFormula, palette, pxToRem } from '../style/theme'
import { afaProductCardChipCss } from './CommonComponents'

const ADVButton = styled.button`
  background-color: ${palette.congressBlue};
  color: white;

  &.active {
    background-color: ${palette.cornflowerBlue};
  }

  &[disabled] {
    background-color: transparent;
    color: ${palette.tangaroa};
  }
`

const ADVPLPButton = styled(ADVButton)`
  ${afaProductCardChipCss};
`

const ADVPDPButton = styled(ADVButton)`
  font-size: ${getFluidSizeWithFullFormula('px', 12, 24, 1366, 3840)};
  font-weight: bold;
  border: 1.5px solid ${palette.extraLight};
  border-radius: ${pxToRem(30)}rem;
  padding: ${pxToRem(7)}rem ${pxToRem(18)}rem;

  @media screen and (min-width: ${breakpoints.L}) {
    padding-block: ${getFluidSizeWithFullFormula('px', 7, 12, 1366, 3840)};
    padding-inline: ${getFluidSizeWithFullFormula('px', 18, 24, 1366, 3840)};
  }
`

type Props = {
  product?: AfaProduct
  isPDP?: boolean
}

const AfaAdvChip: React.FC<Props> = ({ product, isPDP }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const colors = Object.values(product?.mocos || {})

  const isAdv = colors.some(({ sizes }) => Object.values(sizes).some(({ adv }) => adv))

  const advContentQuery = useGetAdvContentsQuery(product?.brandCodeParent)
  const mocoCodes = colors.map(color => buildMocoCode(color))
  const mocoCodeWithAdvContent =
    advContentQuery.data && mocoCodes.find(mocoCode => advContentQuery.data?.data[mocoCode])
  const advContent = advContentQuery.data?.data[mocoCodeWithAdvContent || '']
  const videoContent = advContent?.advLandscape
  const imgContent = advContent?.advPortrait
  const mediaToSend = videoContent || imgContent

  const playingMediaId = useSelector(couvetteVideoIdSelector)
  const selectedBrand = useSelector(brandSelectorByCodes([product?.brandCodeParent || '']))[0]

  const mediaUniqueId = `${mediaToSend?.id}_${mocoCodeWithAdvContent}`
  const isPlaying = playingMediaId && playingMediaId === mediaUniqueId

  const ADVChipButton = isPDP ? ADVPDPButton : ADVPLPButton

  return !isAdv ? null : (
    <ADVChipButton
      className={isPlaying ? 'active' : ''}
      disabled={!mediaToSend || !isTableDevice()}
      onClick={() => {
        if (!mediaToSend) {
          return
        }

        if (isPlaying) {
          socketManager.emitSignal('ended_video')
        } else {
          socketManager.emitSignal(
            'play_video',
            {
              brand: selectedBrand,
            },
            [devices.table],
          )

          dispatch(play_video(mediaToSend, mediaUniqueId, []))
        }
      }}
    >
      {t('Afa.Adv')}
    </ADVChipButton>
  )
}

export default AfaAdvChip
