import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import DisplayMedia from '../../components/DisplayMedia'
import Loading from '../../components/Loading'
import TablePdpContent from '../../components/PDP/TablePdpContent'
import WallPdpContent from '../../components/PDP/WallPdpContent'
import app_config from '../../config/app/config'
import { getBrandByBrandCode } from '../../libs/brand'
import { isEmptyObj } from '../../libs/utils'
import { PdpContent } from '../../model/content'
import { Product } from '../../model/product'
import { getCartCached, savePdpInfo } from '../../store/actions'
import { brandsSelector } from '../../store/brands/selectors'
import { pdpSelector } from '../../store/pdp/selectors'

const Wrapper = styled.div`
  height: 100vh;
`

type Props = {
  content: PdpContent
  couvette: Product
  viewType: string
  code: string
  skuChangedByScrolling: boolean
}

const WallPdp: React.FC<Props> = ({ content, couvette, viewType, code, skuChangedByScrolling }) => {
  const pdp = useSelector(pdpSelector)
  const brands = useSelector(brandsSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (skuChangedByScrolling === false) {
      dispatch(savePdpInfo({ couvette }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (viewType === app_config.viewType.wall) {
      dispatch(getCartCached())
    }
  }, [viewType, dispatch])

  // To display wall, content object must be defined and contains only properties needed for wall.
  const contentKeysManadatory_wall = [
    'titolo',
    'content1',
    'content2',
    'content3',
    'cu1',
    'cu2',
    'cu3',
  ]

  const brand = getBrandByBrandCode(brands, couvette.brandCode)

  return brand ? (
    <Wrapper>
      {viewType === app_config.viewType.wall ? (
        isEmptyObj(content, contentKeysManadatory_wall) ? (
          <DisplayMedia
            media={brand.nav_wall}
            loop={false}
            handleVideoEnded={() => undefined}
            displayLast={true}
          />
        ) : (
          <WallPdpContent content={content} brand={brand} />
        )
      ) : (
        <TablePdpContent
          content={content}
          model={skuChangedByScrolling && pdp.couvette ? pdp.couvette : couvette}
          code={pdp.code || code}
          isWallPdp={true}
        />
      )}
    </Wrapper>
  ) : (
    <Loading />
  )
}

export default WallPdp
