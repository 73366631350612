import React, { SVGProps } from 'react'

const FolderIcon: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
    >
      <path
        d="M73.333 63.333A6.667 6.667 0 0 1 66.666 70H13.333a6.667 6.667 0 0 1-6.667-6.667V16.667A6.667 6.667 0 0 1 13.333 10h16.666l6.667 10h30a6.667 6.667 0 0 1 6.667 6.667v36.666z"
        stroke="#9A9FAD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default FolderIcon
