import styled from 'styled-components/macro'
import { breakpoints, getFluidSizeWithFullFormula, palette, pxToRem } from '../../../style/theme'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useGetAfaCartQuery } from '../../../services/afaCart'
import ExportCartAfa from '../../AfaCheckout/ImportExportPage/ExportCartAfa'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  max-height: calc(100vh - 8rem);
  width: 100%;
`

const Title = styled.div`
  text-align: center;
  text-transform: capitalize;
  color: ${palette.tangaroa};
  font-family: 'Gilmer', sans-serif;
  font-weight: bold;
  font-size: ${getFluidSizeWithFullFormula('px', 26, 48, 1366, 3840)};
  padding-bottom: ${getFluidSizeWithFullFormula('px', 24, 188, 1024, 3840)};
  margin-top: ${getFluidSizeWithFullFormula('px', 16, 16, 1024, 3840)};

  @media screen and (min-width: ${breakpoints.L}) {
    font-weight: 500;
  }

  @media screen and (min-width: ${breakpoints.XL}) {
    margin-top: ${pxToRem(90)}rem;
  }
`

type Props = {
  onClose: () => void
}
const AfaShareCart: React.FC<Props> = () => {
  const { t } = useTranslation()

  const cartQuery = useGetAfaCartQuery()
  const itemsInCart = cartQuery.data?.items ? cartQuery.data?.items.length > 0 : false

  return (
    <Wrapper>
      <Title>{t('Afa.Cart.ShareCart')}</Title>
      <ExportCartAfa itemsInCart={itemsInCart} />
    </Wrapper>
  )
}

export default AfaShareCart
