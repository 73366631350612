import { Icon } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { removeNotificationAction } from '../../../store/actions'
import {
  NotificationCloseButton,
  NotificationColumn1,
  NotificationGrid,
  TurquoiseNotification,
} from '../NotificationsCommonComponents'
import RotateIcon from '../RotateIcon'

const RotateIconBig = styled(RotateIcon)`
  width: 3em;
  height: 3em;
  margin-right: 1em;
`

const OrientationAlert = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  return (
    <TurquoiseNotification>
      <NotificationGrid>
        <NotificationColumn1>
          <RotateIconBig />
          {t('Errors.orientation_alert')}
        </NotificationColumn1>
        <NotificationCloseButton
          onClick={() =>
            dispatch(
              removeNotificationAction({
                id: 'orientationAlert',
                notificationType: 'orientationAlert',
              }),
            )
          }
        >
          <Icon type="close" theme="outlined" />
        </NotificationCloseButton>
      </NotificationGrid>
    </TurquoiseNotification>
  )
}

export default OrientationAlert
