import React from 'react'
import styled from 'styled-components'

const StyledSvg = styled.svg`
  height: 1em;
  width: 1em;
`

type Props = {
  className?: string
}

const StarsIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.233 4.233" className={className}>
      <path
        fill="none"
        stroke="currentColor"
        strokeDashoffset="1.962"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".5"
        d="M3.235 3.84 2.1 3.411l-1.107.422.056-1.212L.304 1.7l1.17-.322.647-.993L2.79 1.4l1.144.309-.758.948Z"
      />
    </StyledSvg>
  )
}

export default StarsIcon
