import React, { SVGProps } from 'react'

const RotateIcon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 11.475 11.276"
    fill="currentColor"
    {...props}
  >
    <path d="M9.67 4.05l.554-.002.556-.003-.274.297-.274.297-.282-.294z" />
    <path d="M0 0v8.904h2.37v2.372h9.105V5.27H6.109V0zm.864.866h4.38V5.27H2.371v2.771H.864zm2.372 5.271h7.373v4.273H3.236z" />
    <path d="M7.817.688a.434.434 0 00-.055.863 2.278 2.278 0 011.994 2.256c0 .036.006.078.01.118l-.45.002.179.186.28.294.46.482.447-.485.448-.485-.506.002v-.116A3.158 3.158 0 007.87.69C7.853.688 7.835.688 7.817.688z" />
  </svg>
)

export default RotateIcon
