import debug from 'debug'
import { apiLog } from '../api/restApi'

const BASE = 'red-carpet-app'
export const COLORS: Record<string, string> = {
  trace: 'lightblue',
  info: 'blue',
  warn: 'orange',
  error: 'red',
}

/** Generate a debug message */
export const generateMessage = ({
  level,
  message,
  source,
}: {
  level: string
  message: any
  source?: string
}) => {
  // Set the prefix which will cause debug to enable the message
  const namespace = `${BASE}:${level}`
  const createDebug = debug(namespace)

  if (createDebug.enabled) {
    // Set the color of the message based on the level
    createDebug.color = COLORS[level]

    if (source) {
      createDebug(source, message)
    } else {
      createDebug(message)
    }
  }
}

/**
 * This class is used to log messages to browser console
 * and to backend service... (TODO).
 *
 * How to use:
 *  import Log from './libs/Log'
 *  Log.info('AAA', 'app.js');
 *
 * To enable logging is necessary to add an entry 'debug'
 * on browser's localStorage with this value:
 *
 *  red-carpet-app:<level|*>
 *
 * Level should be a value such as: trace | info | warn | error | *
 * in order to enable a specific logging level.
 *
 * further info: https://levelup.gitconnected.com/step-up-your-console-messaging-game-in-your-react-app-42eee17659ec
 */
export default class Log {
  static trace(message: any, source?: string) {
    return generateMessage({ level: 'trace', message, source })
  }

  static info(message: any, source?: string) {
    return generateMessage({ level: 'info', message, source })
  }

  static warn(message: any, source?: string) {
    return generateMessage({ level: 'warn', message, source })
  }

  static async error(message: any, source?: string) {
    apiLog(message, source)
    return generateMessage({ level: 'error', message, source })
  }
}

const mapMessage = (message: string) => {
  switch (message) {
    case 'Failed to fetch':
    case 'cancelled':
    case 'The network connection was lost':
    case 'Origin https://redcarpet.luxottica.com is not allowed by Access-Control-Allow-Origin':
    case 'statusText: OK - Type: cors':
      return `Client network error (${message})`
    case 'not master and slaveOk=false':
      return `Database network error (${message})`
    default:
      return message
  }
}

export type extendedError = Error & {
  type?: string
}

export const convertErrorToErrorData = (error: extendedError, errorType?: string) => {
  const errorData = {
    message: mapMessage(error.message),
    stack: error.stack,
    origin: 'JavaScript',
  }
  const type = !errorType && error.type ? error.type : errorType

  return {
    errorData,
    type,
  }
}
