import React from 'react'
import { match, Switch } from 'react-router-dom'
import PrivateRoute from '../../components/PrivateRoute'
import AAChooseBrand from './Pages/ChooseBrand/ChooseBrand'
import AAPlp from './Pages/Plp/AAPlp'
import HeatMap from './Pages/Map/HeatMap'
import useFetchInitialData from '../../hooks/useFetchInitialData'

const AssortmentAdvisorRoutes: React.FC<{ match: match | null }> = ({ match }) => {
  useFetchInitialData({ loadCustomer: true, loadContent: true, loadBrands: true })

  return (
    <Switch>
      <PrivateRoute exact path={`${match?.url}/choose-brands`} component={AAChooseBrand} />

      <PrivateRoute exact path={`${match?.url}/plp`} component={AAPlp} />

      <PrivateRoute exact path={`${match?.url}/map`} component={HeatMap} />
    </Switch>
  )
}

export default AssortmentAdvisorRoutes
