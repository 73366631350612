import { format } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import Chip from '../../components/Chip/Chip'
import NoAppointmentsMessage from '../../components/NoAppointmentsMessage'
import RCIcon from '../../components/UI/RCIcon'
import { Appointment } from '../../model/appointment'
import { selectAppointmentBackground } from '../../store/digitalEvents/selectors'
import { BoxTitle, CtaLink } from '../../style/DigitalEventsCommonComponents'
import {
  breakpoints,
  breakpointsNoUnit,
  getFluidFontSizeDe,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
  spacing,
} from '../../style/theme'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const AppointmentWrapper = styled.div<{ bg?: string }>`
  padding: ${pxToRem(spacing(5))}rem ${pxToRem(spacing(6))}rem;
  ${({ bg }) =>
    bg
      ? `
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${bg});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        `
      : `background-color: ${palette.bigStone};`};
  border-radius: ${pxToRem(spacing(2))}rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;

  @media (max-width: ${breakpoints.S}) {
    padding: ${pxToRem(spacing(1))}rem ${pxToRem(spacing(2))}rem;
  }
`

const Header = styled(BoxTitle)`
  margin-bottom: ${pxToRem(spacing(2))}rem;
`

const AppointmentDate = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: ${getFluidFontSizeDe(14, 16)};
  margin-bottom: ${pxToRem(spacing(2))}rem;
`

const Day = styled.span`
  opacity: 0.6;
  text-transform: uppercase;
`

const Time = styled.span`
  display: flex;
  align-items: center;
  font-size: 1rem;

  .rc-icon {
    margin-right: ${pxToRem(spacing(0.7))}rem;
    width: 1.5em;
    height: 1.5em;
  }
`

const AppointmentTitle = styled.div`
  font-size: ${getFluidFontSizeDe(28, 40)};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1;
  padding-bottom: ${getFluidFontSizeDe(4, 5)};

  @media (max-width: ${breakpoints.S}) {
    -webkit-line-clamp: 2;
  }
`

const BottomBadgesAndButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  font-size: ${getFluidSizeWithFullFormula('px', 8, 15, breakpointsNoUnit.XS, breakpointsNoUnit.L)};

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
  }
`

const TitleAndDateWrapper = styled.div`
  width: 100%;
`

const ChipHighLight = styled(Chip)`
  margin: ${pxToRem(spacing(0.5))}rem 0;
`
const ButtonView = styled(CtaLink)`
  color: ${palette.black};
`

const HightlightAppointment: React.FC<{ highlightAppointment: Appointment | undefined }> = ({
  highlightAppointment,
}) => {
  const highlightAppointmentBackground = useSelector(
    selectAppointmentBackground(highlightAppointment),
  )
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Header>
        <div>{t('Dashboard.highlight_title')}</div>
      </Header>
      <AppointmentWrapper
        bg={highlightAppointmentBackground}
        key={highlightAppointment?.idAppointment}
      >
        {highlightAppointment ? (
          <>
            <TitleAndDateWrapper>
              <AppointmentDate>
                <Day>{format(new Date(highlightAppointment.startDate), 'MMM d Y')}</Day>
                <Time>
                  <RCIcon type="clock"></RCIcon>
                  {format(new Date(highlightAppointment.startDate), 'h.mm aaa')}
                </Time>
              </AppointmentDate>
              <AppointmentTitle>{highlightAppointment.subject}</AppointmentTitle>
            </TitleAndDateWrapper>
            <BottomBadgesAndButtons>
              <div>
                <ChipHighLight text={highlightAppointment.appointmentType.description} />
                {highlightAppointment.speaker && (
                  <ChipHighLight
                    label={t('Dashboard.host_label')}
                    text={highlightAppointment.speaker}
                  />
                )}
              </div>
              <ButtonView to={`/digital-events/appointment/${highlightAppointment.idAppointment}`}>
                {t('Dashboard.appointment_button')}
              </ButtonView>
            </BottomBadgesAndButtons>
          </>
        ) : (
          <NoAppointmentsMessage />
        )}
      </AppointmentWrapper>
    </Wrapper>
  )
}

export default HightlightAppointment
