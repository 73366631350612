import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { convertToBase64 } from '../../../../libs/files'
import { useUploadCartMutation } from '../../../../services/cart'
import checkoutActions from '../../../../store/checkout/actions'
import { getFluidFontSize, getFluidSize, palette, pxToRem, spacing } from '../../../../style/theme'
import Loading from '../../../../components/Loading'
import RCIcon from '../../../../components/UI/RCIcon'
import { CustomIconAndTextButton } from '../Common'

const BoxTitle = styled.div`
  font-weight: bold;
  padding-bottom: ${getFluidSize(`${pxToRem(20)}rem`, 15)};
  font-size: ${getFluidFontSize('12px', 15)};
  text-transform: uppercase;
  background-image: linear-gradient(to bottom, #d3e8f8 4%, #e9f3fc);
  padding: ${pxToRem(20)}rem;
  color: ${palette.biscay};

  img {
    margin-right: ${pxToRem(spacing(1))}rem;
  }

  .rc-icon {
    font-size: ${getFluidFontSize('16px', 15)};
  }
`

const ImportArea = styled.div`
  padding: ${pxToRem(spacing(2))}rem 0;
  border: dashed 1.5px #e3e3e3;
  height: 80%;
  aspect-ratio: 1 / 1;
  max-width: 100%;
  border-radius: 3rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  .rc-icon {
    width: 7em;
    height: 7em;
  }

  &.uploading {
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 4fr 3fr;

    .rc-icon {
      justify-self: center;
    }
  }
`

const Divider = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  text-transform: uppercase;

  &:before,
  &:after {
    content: '';
    flex: 1 1;
    border-bottom: 0.5px solid #979797;
    margin: auto;
  }

  &:before {
    margin-right: ${pxToRem(9)}rem;
  }

  &:after {
    margin-left: ${pxToRem(10)}rem;
  }
`

const DragAndDropText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${palette.biscay};
  gap: ${pxToRem(15)}rem;

  span:first-of-type {
    font-weight: 800;
    font-size: ${getFluidFontSize('14px')};
  }

  span:last-of-type {
    font-size: ${getFluidFontSize('14px')};
  }
`

const StyledButton = styled(CustomIconAndTextButton)`
  background-color: #005192;
  color: ${palette.white};
  letter-spacing: 1px;
  border-radius: ${pxToRem(22.5)}rem;
  font-size: ${getFluidFontSize('10px', 15)};
  padding: ${getFluidSize(`${pxToRem(15)}rem`, 15)} ${getFluidSize(`${pxToRem(25)}rem`, 15)};
`

const LoadingContainer = styled.div`
  position: relative;
`

const UploadState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-self: center;
  color: #69b4e6;
  gap: ${pxToRem(15)}rem;

  button {
    margin-top: 10px;
    color: white;
  }

  .rc-icon {
    width: 1em;
    height: 1em;

    :last-child {
      cursor: pointer;
    }
  }
`

const Upload: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [uploadCart, uploadCartResult] = useUploadCartMutation()

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: ['.xls', '.xlsx', '.csv', '.xlt', '.xml'],
  })

  const file: File | undefined = acceptedFiles[0]
  const uploadEnded = uploadCartResult.isSuccess

  const removeFile = () => {
    uploadCartResult.reset()
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1)
    dispatch(checkoutActions.setImportExportStep(0))
  }

  useEffect(() => {
    if (file) {
      convertToBase64(file).then((base64File: string) => {
        const fileData = {
          FileName: file.name,
          File: base64File.split('base64,')[1],
        }
        dispatch(checkoutActions.setFileData(fileData))
        uploadCart(true, fileData)
      })
    }
  }, [file, uploadCart, dispatch])

  useEffect(() => {
    if (uploadEnded) {
      dispatch(checkoutActions.nextImportExportStep())
    }
  }, [dispatch, uploadEnded])

  return (
    <>
      <BoxTitle>
        <RCIcon type="newImport" />
        <span>{t('Checkout.ImportExport.ImportTitle')}</span>
      </BoxTitle>

      {(uploadCartResult.isUninitialized || uploadCartResult.isError || !file) && (
        <ImportArea {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <RCIcon type="folder" iconClass="import" />
          <DragAndDropText>
            <span>{t('Checkout.ImportExport.DragAndDrop')}</span>
            <Divider>{t('Checkout.ImportExport.Divider')}</Divider>
            <span>{t('Checkout.ImportExport.Browse')}</span>
          </DragAndDropText>
          <StyledButton text={t('Checkout.ImportExport.Upload')} type="button" onClick={open} />
        </ImportArea>
      )}

      {uploadCartResult.isLoading && file && (
        <ImportArea className="uploading">
          <LoadingContainer>
            <Loading isFullPage={false} />
          </LoadingContainer>
          <UploadState>
            <span>{t('Checkout.ImportExport.Transfer')}</span>
            <span>{file.name}</span>
            <span></span>
            <StyledButton
              text={t('Checkout.ImportExport.Cancel')}
              type="button"
              onClick={removeFile}
            />
          </UploadState>
        </ImportArea>
      )}

      {uploadEnded && file && (
        <ImportArea className="uploading">
          <RCIcon type="checkout-import-export-upload-done" />
          <UploadState>
            <span>{t('Checkout.ImportExport.Complete')}</span>
            <span>{file.name}</span>
            <span></span>
            <StyledButton
              text={t('Checkout.ImportExport.Cancel')}
              type="button"
              onClick={removeFile}
            />
          </UploadState>
        </ImportArea>
      )}
    </>
  )
}

export default Upload
