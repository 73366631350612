import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import DownIcon from '../../components/icons/DownIcon'
import { duration, getFluidSizeWithFullFormula, pxToRem } from '../../style/theme'
import afaActions from '../../store/afa/actions'
import { useDispatch, useSelector } from 'react-redux'
import { cartFiltersOpenSelector } from '../../store/afa/selectors'

const Button = styled.button`
  font-size: ${getFluidSizeWithFullFormula('px', 12, 24, 1366, 3840)};
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding-left: ${pxToRem(32)}rem;
  padding-right: ${pxToRem(32)}rem;

  svg {
    transition: transform ${duration.standard}ms;
  }

  &.open svg {
    transform: rotate(180deg);
  }
`

const Label = styled.div``

const AfaCartFilterButton: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const filtersOpen = useSelector(cartFiltersOpenSelector)

  return (
    <Button
      className={filtersOpen ? 'open' : ''}
      onClick={() => dispatch(afaActions.toggleCartFiltersOpen())}
    >
      <Label>{t('Afa.Cart.filter')}</Label>
      <DownIcon />
    </Button>
  )
}

export default AfaCartFilterButton
