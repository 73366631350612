import React from 'react'

import { SizeInCart } from '../../../model/cart'
import { Size } from '../../../model/product'
import DoorPurchaseControls from '../DoorPurchaseControls'

type Props = {
  enabledDoors: string[]
  size: Size
  doorIds: string[]
  cartItemSize?: SizeInCart
  brandCode: string
}

const MassiveOrderDoor: React.FC<Props> = ({
  doorIds,
  size,
  cartItemSize,
  enabledDoors,
  brandCode,
}) => (
  <DoorPurchaseControls
    doorIds={doorIds}
    productSize={size}
    cartItemSize={cartItemSize}
    brandCode={brandCode}
    enabledDoors={enabledDoors}
    isMassiveDoor
  />
)

export default MassiveOrderDoor
