import { createSelector } from '@reduxjs/toolkit'

import { sortBrands } from '../../../libs/brand'
import { emptyTagGroup, getBrandsFromCartItems } from '../../../libs/cart'
import { isNewOrColorRefresh } from '../../../libs/productsV2'
import { MocoCode, RootState, Upc } from '../../../model/model'
import { Moco, Sizes } from '../../../model/product'
import { brandsSelector } from '../../brands/selectors'
import {
  activeBrandCodeSelector,
  activeBrandSelector,
  cartProductsDetailsSelector,
  cartProductsFilteredBy_NoJunior_Selector,
  myShopLastAddedProductUpcsSelector,
  selectedCartCategorySelector,
} from './cart'
import app_config from '../../../config/app/config'

export const brandRecommendationSelector = createSelector(
  activeBrandSelector,
  (state: RootState) => state.cart.recommendation,
  selectedCartCategorySelector,
  (activeBrand, recommendation, category) =>
    activeBrand ? recommendation[`${activeBrand.code}-${category}`] : undefined,
)

export const myShopBrandsSelector = createSelector(
  cartProductsFilteredBy_NoJunior_Selector,
  brandsSelector,
  cartProductsDetailsSelector,
  (filteredCartProducts, brands, productsDetails) => {
    const cartBrands = getBrandsFromCartItems(filteredCartProducts, brands, productsDetails)
    const allBrands = brands.map(brand => {
      const cartBrand = cartBrands[brand.code]
      return {
        ...(cartBrand || { brand }),
        isCartBrand: Boolean(cartBrand),
      }
    })

    return sortBrands(allBrands)
  },
)

const filterMocoSizesNotInCartOrJustAdded = (
  mocos: Moco[],
  upcInCartMap: Record<Upc, true>,
  myShopLastAddedProductUpcs?: Upc[],
) =>
  mocos
    .map(moco => ({
      ...moco,
      sizes: Object.entries(moco.sizes).reduce((result, [upc, size]) => {
        const inCart = upcInCartMap[size.upc]
        const justAddedToCart = myShopLastAddedProductUpcs?.includes(upc)

        if (!inCart || justAddedToCart) {
          result[upc] = size
        }
        return result
      }, {} as Sizes),
    }))
    .filter(moco => Object.keys(moco.sizes).length)

const sortMocsBylastSizeInsertedAt = (
  mocos: Record<MocoCode, Moco & { lastSizeInsertedAt: number }>,
) => {
  const mocosArr = Object.values(mocos)
  mocosArr.sort((a, b) => {
    return a.lastSizeInsertedAt > b.lastSizeInsertedAt ? -1 : 1
  })
  return mocosArr as Moco[]
}

const myShopMocosSelector = createSelector(
  cartProductsDetailsSelector,
  cartProductsFilteredBy_NoJunior_Selector,
  brandRecommendationSelector,
  selectedCartCategorySelector,
  activeBrandSelector,
  myShopLastAddedProductUpcsSelector,
  activeBrandCodeSelector,
  (
    cartProductDetails,
    cartProducts,
    brandRecommendation,
    cartCategory,
    activeBrand,
    myShopLastAddedProductUpcs,
    activeBrandCodeSelector,
  ) => {
    const recommendedMocos = brandRecommendation || emptyTagGroup
    const isSunCategory = !!cartCategory && app_config.sunCategories.includes(cartCategory)
    const isOpticalCategory = !!cartCategory && app_config.opticalCategories.includes(cartCategory)

    const cartMocos = cartProducts.reduce(
      (result, { modelCode, colorCode, insertedAt, upc }) => {
        const moco =
          recommendedMocos.futureTrends.find(recommendedMoco => {
            return (
              recommendedMoco.modelCode === modelCode && recommendedMoco.colorCode === colorCode
            )
          }) || cartProductDetails[modelCode]?.mocos[colorCode]

        if (
          !moco ||
          (isSunCategory && !app_config.sunCategories.includes(moco.categoryId)) ||
          (isOpticalCategory && !app_config.opticalCategories.includes(moco.categoryId)) ||
          !activeBrand?.group.includes(moco.brandCode)
        ) {
          return result
        }

        const { mocoCode } = moco
        const mocoInResults =
          result.futureTrends[mocoCode] || result.bestsellers[mocoCode] || result.adv[mocoCode]
        // TS doesn't get it but isMocoInResults can be undefined
        const _lastSizeInsertedAt = mocoInResults?.lastSizeInsertedAt
        const lastSizeInsertedAt =
          _lastSizeInsertedAt && _lastSizeInsertedAt > insertedAt ? _lastSizeInsertedAt : insertedAt
        const mocoToInsert = {
          ...moco,
          lastSizeInsertedAt: lastSizeInsertedAt,
          sizes: {
            ...mocoInResults?.sizes,
            [upc]: moco.sizes[upc],
          },
        }

        const mocoIsNewAdv = moco.isAdv && isNewOrColorRefresh(moco)
        const mocoIsEligibleForFutureTrends = moco.tags.includes('ideal') || mocoIsNewAdv
        if (mocoIsEligibleForFutureTrends) {
          result.futureTrends[mocoCode] = mocoToInsert
        }

        const mocoIsEligibleForBestseller = moco.isBestseller && moco.skuBestSellerRanking
        if (mocoIsEligibleForBestseller) {
          result.bestsellers[mocoCode] = mocoToInsert
        }

        const mocoIsEligibleForAdv = moco.isAdv
        if (mocoIsEligibleForAdv) {
          result.adv[mocoCode] = mocoToInsert
        }

        return result
      },
      {
        futureTrends: {} as Record<MocoCode, Moco & { lastSizeInsertedAt: number }>,
        bestsellers: {} as Record<MocoCode, Moco & { lastSizeInsertedAt: number }>,
        adv: {} as Record<MocoCode, Moco & { lastSizeInsertedAt: number }>,
      },
    )

    const upcInCartMap = cartProducts.reduce((map, { upc }) => {
      map[upc] = true
      return map
    }, {} as Record<Upc, true>)

    const subBrandsCodes = activeBrand?.subBrands.map(subBrand => subBrand.code)

    const inCategory = (moco: Moco) =>
      ((isSunCategory && app_config.sunCategories.includes(moco.categoryId)) ||
        (isOpticalCategory && app_config.opticalCategories.includes(moco.categoryId))) &&
      (activeBrandCodeSelector ? subBrandsCodes?.includes(moco.brandCode) : true)

    const result = {
      futureTrends: {
        cartMocos: sortMocsBylastSizeInsertedAt(cartMocos.futureTrends),
        recommendedMocos: filterMocoSizesNotInCartOrJustAdded(
          recommendedMocos.futureTrends.filter(inCategory),
          upcInCartMap,
          myShopLastAddedProductUpcs,
        ),
      },
      bestsellers: {
        cartMocos: sortMocsBylastSizeInsertedAt(cartMocos.bestsellers),
        recommendedMocos: filterMocoSizesNotInCartOrJustAdded(
          recommendedMocos.bestsellers.filter(inCategory),
          upcInCartMap,
          myShopLastAddedProductUpcs,
        ),
      },
      adv: {
        cartMocos: sortMocsBylastSizeInsertedAt(cartMocos.adv),
        recommendedMocos: filterMocoSizesNotInCartOrJustAdded(
          recommendedMocos.adv.filter(inCategory),
          upcInCartMap,
          myShopLastAddedProductUpcs,
        ),
      },
    }

    return result
  },
)

export const myShopActiveTabSelector = (state: RootState) =>
  state.cart.myShopActiveTab || 'futureTrends'

export const myShopMocoOfActiveTabSelector = createSelector(
  myShopMocosSelector,
  myShopActiveTabSelector,
  (myShopMocos, activeTab) => {
    return myShopMocos[activeTab]
  },
)

export const myShopAssortmetsCollapsedSelector = (state: RootState) =>
  state.cart.myShopAssortmetsCollapsed

export const recommendedBrandLoadedSelector = (state: RootState) =>
  Object.keys(state.cart.recommendation)

export const recommendationIsLoadingSelector = (state: RootState) =>
  !state.cart.recommendationLoadingStatus.loaded && state.cart.recommendationLoadingStatus.loading
