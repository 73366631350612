import React, { useRef, useState } from 'react'
import ReactCompareImage from 'react-compare-image'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import app_config from '../../config/app/config'
import { breakpoints, getFluidFontSize, palette } from '../../style/theme'
import Loading from '../Loading'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1vh;
`

const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5vh;
`

const Container = styled.div`
  position: relative;
  min-width: 100%;
  height: 48vh;
  background-color: ${palette.white};
  border-radius: 5.6px;
  box-shadow: 0 1px 28px 22px rgba(0, 0, 0, 0.04);
  @media screen and (max-width: ${breakpoints.M}) {
    height: auto;
  }
  div[dataenzyme='container'] div:first-of-type {
    div:first-child {
      position: absolute;
      width: 8vh !important;
      opacity: 0.5;
      z-index: 1;
    }
    div:nth-child(2) {
      width: 1px !important;
      background-color: ${palette.black};
      z-index: 2;
      border: none !important;
      div {
        display: none !important;
      }
    }
    div:nth-child(3) {
      position: absolute;
      width: 4vh !important;
      height: 4vh !important;
      background-image: url(${`${app_config.publicUrl}/assets/images/stControl.svg`}) !important;
      background-size: cover !important;
      z-index: 3;
    }
  }
  img {
    max-width: 100%;
  }
  input[type='range'] {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    opacity: 0;
  }
`

const Effect = styled.div`
  position: absolute;
  color: ${palette.congressBlue};
  font-size: ${getFluidFontSize('10px')};
  padding: 0.5vh 0.7vw;
  background-color: ${palette.white};
  bottom: 2vh;
  left: 3vw;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  &.right {
    left: auto;
    right: 3vw;
  }
`

const Disabled = styled.div`
  width: 84.4vh;
  height: 48vh;
  background-color: ${palette.alto};
  @media screen and (max-width: ${breakpoints.M}) {
    margin-top: 8vh;
    height: 35vh;
  }
`

const setPosition = (
  compareImagesRef: React.RefObject<HTMLDivElement>,
  rightTipRef: React.RefObject<HTMLDivElement>,
  leftTipRef: React.RefObject<HTMLDivElement>,
  setState: (state: { position: number; showLeftTip: boolean; showRightTip: boolean }) => void,
) => (position: number) => {
  const contWidth = compareImagesRef.current?.clientWidth
  const rightTipLeftPosition = rightTipRef.current?.offsetLeft
  const leftTipLeftPosition =
    leftTipRef.current && leftTipRef.current.offsetLeft + leftTipRef.current.clientWidth

  if (!leftTipLeftPosition || !contWidth || !rightTipLeftPosition) {
    return
  }

  const leftSide = leftTipLeftPosition / contWidth
  const rightSide = rightTipLeftPosition / contWidth
  const showLeftTip = leftSide < position
  const showRightTip = rightSide > position

  setState({
    position,
    showLeftTip,
    showRightTip,
  })
}

type Props = {
  imageAfter?: string
  imageBefore?: string
  isLoading: boolean
}

const SeeThrough: React.FC<Props> = ({ imageBefore, imageAfter, isLoading }) => {
  const { t } = useTranslation()

  const [state, setState] = useState({
    position: 0.5,
    showLeftTip: true,
    showRightTip: true,
  })
  const { position, showRightTip, showLeftTip } = state

  const compareImagesRef = useRef<HTMLDivElement>(null)
  const leftTipRef = useRef<HTMLDivElement>(null)
  const rightTipRef = useRef<HTMLDivElement>(null)

  return (
    <>
      {isLoading && (
        <SpinWrapper>
          <Loading isFullPage={false} />
        </SpinWrapper>
      )}

      <Wrapper className="st">
        {imageBefore && imageAfter ? (
          <Container className="st__container">
            <div ref={compareImagesRef}>
              <ReactCompareImage
                sliderPositionPercentage={position}
                onSliderPositionChange={setPosition(
                  compareImagesRef,
                  rightTipRef,
                  leftTipRef,
                  setState,
                )}
                leftImage={imageBefore}
                rightImage={imageAfter}
              />
            </div>

            <Effect
              style={{ visibility: showLeftTip ? 'visible' : 'hidden' }}
              className="st__effect left"
              ref={leftTipRef}
            >
              {t('ProductPage.withoutLens')}
            </Effect>

            <Effect
              style={{ visibility: showRightTip ? 'visible' : 'hidden' }}
              className="st__effect right"
              ref={rightTipRef}
            >
              {t('ProductPage.withLens')}
            </Effect>
          </Container>
        ) : (
          <Disabled className="st__disabled" />
        )}
      </Wrapper>
    </>
  )
}

export default SeeThrough
