import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { backgroundsLoadedSelector } from '../../store/digitalEvents/selectors'

const BGVideo = styled.video`
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
`

const BackgroundVideo: React.FC<{ background: string }> = ({ background }) => {
  const backgroundsLoaded = useSelector(backgroundsLoadedSelector)

  return background && backgroundsLoaded ? (
    <BGVideo autoPlay loop muted>
      <source src={background} type="video/mp4" />
    </BGVideo>
  ) : null
}

export default BackgroundVideo
