import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import app_config from '../../config/app/config'
import { mapDispatchToProps } from '../../libs'
import { getRoomFromUrl, isIpadOnlyRoom } from '../../libs/url'
import { isNotUndefined } from '../../libs/utils'
import { RootState } from '../../model/model'
import { VirtualMirrorAvailability } from '../../model/pdp'
import { Product } from '../../model/product'
import { closeVTO, openVTOView, setVirtualMirrorConfig, setVmUrlAction } from '../../store/actions'
import { eventIdSelector, languageSelector } from '../../store/app/selectors'
import { customerNationalitySelector } from '../../store/customer/selectors'
import {
  pdpCouvetteSelector,
  virtualMirrorAvailabilitySelector,
  virtualMirrorUnmountSelector,
  virtualMirrorUpcsAvailable,
  vtoSelectedUpcSelector,
} from '../../store/pdp/selectors'
import { breakpointsCross, getFluidFontSize, palette } from '../../style/theme'
import RCIcon from '../UI/RCIcon'
import RedCarpetLogo from '../UI/RedCarpetLogo'
import VirtualMirrorComponent from '../VirtualMirror'
import QrCode from './QRCode'
import pdpActions from '../../store/pdp/actions'
import { getProductImageUrl } from '../../libs/productImages'
import { VirtualMirrorProduct } from '../../model/virtualMirror'
import VMCarousel from '../VMCarousel/VMCarousel'
import { useGetVirtualMirrorTransitionLenses } from '../../services/virtualMirror'

export const VmSecondaryTitle = styled.p`
  font-size: ${getFluidFontSize('0.5rem')};
  color: ${palette.congressBlue};
  white-space: nowrap;
  overflow: hidden;
`

export const VmPrimaryTitle = styled.p`
  font-size: ${getFluidFontSize('0.7rem')};
`

const StyledCatalog = styled.div``

export const TitleWrapper = styled.div`
  top: 15px;

  @media (max-width: ${breakpointsCross.L.min}) {
    position: absolute;
    left: 0;
    top: 35px;
    width: 100%;
    height: 100%;
    padding: 0 1vh;
  }
`

const VtoContainer = styled.div`
  z-index: 10000000;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const VtoStyled = styled.div`
  background: white;
  width: 60%;
  height: 95%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1024px) {
    width: 95%;
    height: 95%;
  }
`

const VtoStyledHeader = styled.div`
  max-height: 7%;
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  @media screen and (max-width: 1024px) {
    &.vto-modal-close {
      right: 5.5vw;
    }
  }
`

const VtoStyledHeaderBtn = styled.button`
  border: 1px solid #dadada;
  border-radius: 8px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  appearance: none;
  font: inherit;

  @media screen and (max-width: 1024px) {
    height: 20px;
    width: 20px;
    &.vto-modal-close {
      right: 5.5vw;
    }
  }
`

const VtoStyledVm = styled.div<{
  isQr: boolean
}>`
  width: 95%;
  height: ${({ isQr }) => (isQr ? '78%' : '90%')};
  @media screen and (max-width: 1024px), (max-height: 900px) {
    height: ${({ isQr }) => (isQr ? '74%' : '90%')};
  }
  content: '';
  background: #f3f3f3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const VtoStyledCatalog = styled(StyledCatalog)`
  height: 14%;
  width: 95%;

  @media screen and (max-width: 1024px), (max-height: 900px) {
    height: 18%;
    width: 95%;
  }
`

type OwnProps = Record<string, unknown>

type ConnectedProps = {
  actions: {
    openVTOView: typeof openVTOView
    closeVTO: typeof closeVTO
    setVmUrlAction: typeof setVmUrlAction
    setVirtualMirrorConfig: typeof setVirtualMirrorConfig
  }
  couvette: Product | null
  lang: string
  selectedUpc: string | null
  upcs: (string | undefined)[]
  virtualMirrorAvailability: VirtualMirrorAvailability
}

type Props = OwnProps & ConnectedProps

export const getVirtualMirrorProducts = (
  couvette: Product | null,
  virtualMirrorAvailability: VirtualMirrorAvailability,
): VirtualMirrorProduct[] => {
  if (!couvette) return []

  const catalogueItems = Object.values(couvette.mocos)
    .filter(
      ({ mocoCode }) =>
        virtualMirrorAvailability[mocoCode] && virtualMirrorAvailability[mocoCode].isAvailable,
    )
    .map(moco => {
      return {
        upc: virtualMirrorAvailability[moco.mocoCode].upc || '',
        thumbnailUrl: getProductImageUrl({ path: moco.catalogImages.base, imwidth: 300 }),
        subtitle: '',
        code: moco.modelCode,
        colorCode: moco.colorCode,
        brand: {
          name: moco.brandCode,
          logoUrl: '',
        },
        name: `${moco.modelCode} ${moco.colorCode}`,
        lensColorLabel: (moco.frontColor && moco.frontColor.label) || moco.colorCode,
        isTransition: !!moco.isTransition,
        isEvolve: !!moco.isEvolve,
      }
    })

  return catalogueItems
}

const VtoModal: React.FC<Props> = ({
  couvette,
  virtualMirrorAvailability,
  actions,
  lang,
  selectedUpc,
  upcs,
}) => {
  const dispatch = useDispatch()
  const unmountStatus = useSelector(virtualMirrorUnmountSelector)
  const customerNationality = useSelector(customerNationalitySelector)
  const eventId = useSelector(eventIdSelector)
  const [switchToQrRequested, setSwitchToQrRequested] = useState(false)
  const { data: transitionLenses, isSuccess, isError } = useGetVirtualMirrorTransitionLenses()

  const [virtualMirrorProducts, setVirtualMirrorProducts] = useState<VirtualMirrorProduct[]>([])
  const [showQrCode, setShowQrCode] = useState(isIpadOnlyRoom())

  useEffect(() => {
    setVirtualMirrorProducts(getVirtualMirrorProducts(couvette, virtualMirrorAvailability))
    actions.setVmUrlAction(selectedUpc, upcs.filter(isNotUndefined))
  }, [couvette, virtualMirrorAvailability, selectedUpc, actions, upcs, showQrCode])

  useEffect(() => {
    window.vmmv.warmUp()
  }, [])

  // Vto requires a lang slug that observes the naming convention of the language codes (e.g. en-US)
  // This might not happen as in case of the "en" language.
  const langWithFallback = lang.indexOf('-') < 0 ? app_config.vtoLangDefault : lang

  const storeId = `${getRoomFromUrl()}-${eventId}`

  useEffect(() => {
    if (isSuccess || isError) {
      const products = virtualMirrorProducts
        .slice()
        .sort(({ upc }) => {
          return upc === selectedUpc ? -1 : 0
        })
        .map(product => {
          const transitionLens =
            (product.isTransition || product.isEvolve) && transitionLenses
              ? {
                  transitionLens: {
                    ...transitionLenses,
                    availableColors:
                      transitionLenses?.availableColors.filter(
                        color => color !== 'XTRACTIVE-NEWGEN-GGREEN', // TODO: remove this filter for VMMV 4.12
                      ) || [],
                    isEvolve: product.isEvolve,
                  },
                }
              : {}

          return {
            ...product,
            ...transitionLens,
            thumbnailUrl: product.thumbnailUrl + '&cors=true',
          }
        })

      const configData = {
        selector: '#vmmv-container',
        locale: langWithFallback,
        products,
        store: {
          id: storeId,
          storeId,
          type: 'redcarpet',
        },
        style: '',
        fromStore: true,
        vmInit: {
          source: 'VMMV_Red_Carpet',
          key: '59ec6093-9f57-4da5-8258-c494dce9e02a',
          channel: 'redcarpet',
          brand: 'redcarpet',
        },
        analytics: {
          adobeSessionId: '',
          sourcePosition: '',
          pageEnvironment: '',
          storeGlobalId: '',
          style: '',
          pagePlatform: '',
          pageBrand: '',
          storeId: '',
          storeRegion: langWithFallback,
          storeCompany: '',
          source: '',
        },
        isTryOnEnabled: true,
        vmmvModes: { isTryOnEnabled: true },
      }
      actions.setVirtualMirrorConfig(configData)
    }
  }, [
    virtualMirrorProducts,
    langWithFallback,
    selectedUpc,
    customerNationality,
    storeId,
    actions,
    isSuccess,
    isError,
    transitionLenses,
  ])

  useEffect(() => {
    if (unmountStatus === 'done') {
      dispatch(pdpActions.setVirtualMirrorUnmountStatus(false))
      // We need switchToQrRequested because unmountStatus === 'done' can be true when reopening
      // the modal but showQrCode can be both true or false depending on the room
      if (!showQrCode && switchToQrRequested) {
        setShowQrCode(true)
        setSwitchToQrRequested(false)
      }
    }
  }, [dispatch, showQrCode, switchToQrRequested, unmountStatus])

  const runCardActions = (upc: string) => {
    actions.openVTOView(upc)
    actions.setVmUrlAction(upc)
  }

  return (
    <VtoContainer>
      <VtoStyled>
        <VtoStyledHeader>
          <VtoStyledHeaderBtn
            onClick={() => {
              if (showQrCode) {
                setShowQrCode(false)
              } else {
                dispatch(pdpActions.setVirtualMirrorUnmountStatus('requested'))
                setSwitchToQrRequested(true)
              }
            }}
          >
            <RCIcon type={showQrCode ? 'vto-blue' : 'qr'} />
          </VtoStyledHeaderBtn>
          <RedCarpetLogo />
          <VtoStyledHeaderBtn
            className="vto-modal-close"
            onClick={() => {
              // if modal is in QR mode we don't need to wait for unmount
              actions.closeVTO(!showQrCode)
            }}
          >
            <RCIcon type="close" />
          </VtoStyledHeaderBtn>
        </VtoStyledHeader>
        <VtoStyledVm isQr={showQrCode}>
          {showQrCode ? (
            <QrCode size={450} upc={selectedUpc || undefined} />
          ) : (
            <VirtualMirrorComponent />
          )}
        </VtoStyledVm>
        {showQrCode && (
          <VtoStyledCatalog>
            <VMCarousel
              selectedUpc={selectedUpc}
              products={virtualMirrorProducts}
              runCardActions={runCardActions}
            />
          </VtoStyledCatalog>
        )}
      </VtoStyled>
    </VtoContainer>
  )
}

const mapStateToProps = (state: RootState): Omit<ConnectedProps, 'actions'> => ({
  couvette: pdpCouvetteSelector(state),
  lang: languageSelector(state),
  selectedUpc: vtoSelectedUpcSelector(state),
  upcs: virtualMirrorUpcsAvailable(state),
  virtualMirrorAvailability: virtualMirrorAvailabilitySelector(state),
})

export { VtoModal }

const connectedVtoModal = connect(
  mapStateToProps,
  mapDispatchToProps(),
)(VtoModal) as React.ComponentType<OwnProps>

export default connectedVtoModal
