import { Carousel } from 'antd'
import classnames from 'classnames'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import BrandLogo from '../../components/Couvettes/Couvette/BrandLogo'
import { Brand } from '../../model/brand'
import { breakpoints, getFluidFontSize, getFluidSize, palette } from '../../style/theme'
import {
  brandsSliderListSelector,
  wishlistCurrentCategoryIdSelector,
} from '../../store/wishlist/selectors'

const Wrapper = styled.div`
  padding-left: ${getFluidSize('20px')};
  position: relative;

  .ant-carousel {
    width: 32vw;
  }

  .slick-track {
    display: flex;
    gap: ${getFluidSize('10px', 20)};
  }
  .slick-slide {
    width: unset !important;
  }
  .slick-prev,
  .slick-next {
    width: ${getFluidSize('15px', 40)};
    height: ${getFluidSize('15px', 40)};
    &:before {
      font-size: ${getFluidFontSize('0.7rem')};
    }
  }
  .slick-next:before {
    content: '>';
  }
  .slick-prev:before {
    content: '<';
  }

  &.arrows-active {
    padding-left: ${getFluidSize('40px')};

    .brands-carousel {
      padding-left: ${getFluidSize('4px', 40)};
      padding-right: ${getFluidSize('1px', 40)};
    }
  }

  &.brands-slider {
    padding: 0.75rem 0;
    display: flex;
    align-items: center;
    .ant-carousel {
      width: 100%;
      .brands-carousel {
        height: 100%;
        .brand-logo {
          height: 2.6vw;
          width: 4.2vw;
          background-color: #fff;
          border: 1px solid #ededed;
          border-radius: 0.42vw;
          box-shadow: 0 0.05vw 0.42vw 0 rgba(0, 0, 0, 0.08);
          padding: 0.23vw 0.18vw 0.28vw;
          cursor: pointer;
        }
        img {
          height: 100%;
          width: 100%;
        }
      }

      .slick-arrow {
        &.slick-disabled {
          opacity: 0.2;
        }
      }

      .slick-prev,
      .slick-next {
        border: 1px solid #1864cd;
        border-radius: 50%;
        margin-top: 0;
        transform: translate(0, -50%);
        &:before {
          color: #1864cd;
          line-height: 1;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.L}) {
    background-color: ${palette.concrete};
    box-shadow: 0 10px 10px 2px rgb(0 0 0 / 4%);
    padding-bottom: 5vh;
  }
`

type Props = {
  brands: Brand[]
  maxSlides: number
  onBrandClicked: (brand: Brand | undefined) => void
}

const BrandsSlider: React.FC<Props> = ({ brands, maxSlides, onBrandClicked }) => {
  const slidesNumber = brands.length < maxSlides ? brands.length : maxSlides

  const wishlistCurrentCategoryId = useSelector(wishlistCurrentCategoryIdSelector)
  const brandsSliderList = useSelector(brandsSliderListSelector)

  brands = brandsSliderList(brands)

  useEffect(() => {
    onBrandClicked(undefined)
  }, [onBrandClicked, wishlistCurrentCategoryId])

  return (
    <Wrapper
      className={classnames({
        'brands-slider': true,
        'arrows-active': brands.length > maxSlides,
      })}
    >
      <Carousel
        dots={false}
        slidesToShow={slidesNumber}
        swipeToSlide={true}
        infinite={false}
        arrows={true}
        className="brands-carousel"
      >
        {brands.map(brand => (
          <BrandLogo
            key={brand.code}
            brandCode={brand.code}
            onClick={() => onBrandClicked(brand)}
          />
        ))}
      </Carousel>
    </Wrapper>
  )
}

export default BrandsSlider
