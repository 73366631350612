import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit'
import { errorNotification } from '../components/Notification/notifications'

const getErrorMessage = (action: any) => {
  if (action.payload?.data?.message) {
    return action.payload?.data?.message
  } else if (Array.isArray(action.payload?.data?.warnings)) {
    return action.payload.data.warnings
      .map((warning: { faultmessage: string }) => warning.faultmessage)
      .join(', ')
  }
}

export const rtkQueryErrorLogger: Middleware = () => next => action => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!

  if (isRejectedWithValue(action)) {
    errorNotification({
      message: `External call error: ${getErrorMessage(action)}. Origin: ${
        action.type.split('/')[0]
      } - ${action.meta.arg.endpointName}`,
    })
  }
  return next(action)
}
