import React from 'react'
import styled from 'styled-components'

import { palette } from '../../../../style/theme'
import RCIcon from '../../../UI/RCIcon'

const Icon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    width: 1.2em;
    height: 1.2em;
  }

  .bestseller-count {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.5em;
    font-weight: bold;
    margin-top: 0.2em;
    letter-spacing: normal;
    color: ${palette.hibiscus};
    .led-wall-product & {
      display: none;
    }
  }
`

type Props = {
  count: number
  type?: string
  className?: string
}

const BestsellerIcon: React.FC<Props> = ({ count, type = 'bestseller', className }) => (
  <Icon className={'bestseller rc-icon' + className}>
    <RCIcon type={type} replaceDefaultClass={true} iconClass="bestseller-icon" />
    <span className="bestseller-count">{count || ''}</span>
  </Icon>
)

export default BestsellerIcon
