import '../../../node_modules/antd/lib/notification/style/index.css'

import React, { FC } from 'react'

import NoticeIcon from './NoticeIcon'

// TODO: complete the mapping
const mapTypeToIcon: Record<string, FC> = {
  notice: NoticeIcon,
}

type Props = {
  type: string
  text?: string
  onClose?: () => void
  dangerouslySetInnerHTML?: string
}

export const Notification: React.FC<Props> = ({
  type,
  text,
  onClose,
  dangerouslySetInnerHTML = '',
}) => (
  <div className={`ant-notification-${type} ant-notification-${type}-closable`}>
    <div className={`ant-notification-${type}-content`}>
      <div className={`ant-notification-${type}-with-icon`}>
        {mapTypeToIcon[type] ? mapTypeToIcon[type]({}) : null}
        <div className={`ant-notification-${type}-message`}>
          <span className={`ant-notification-${type}-message-single-line-auto-margin`} />
          {typeof text === 'string' ? (
            <span>{text}</span>
          ) : (
            <span dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHTML }} />
          )}
        </div>
        <div className={`ant-notification-${type}-description`} />
      </div>
    </div>
    {Boolean(onClose) && (
      <a tabIndex={0} className={`ant-notification-${type}-close`} onClick={onClose}>
        <i aria-label="icon: close" className="anticon anticon-close ant-notification-close-icon">
          <svg
            viewBox="64 64 896 896"
            className=""
            data-icon="close"
            width="1em"
            height="1em"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z" />
          </svg>
        </i>
      </a>
    )}
  </div>
)
