import { Door } from '../../../../model/customer'

const filterDoors = (doors: Door[], doorSearch: string) => {
  return doors.filter(({ name, id }) =>
    doorSearch
      ? name.toLowerCase().includes(doorSearch.toLowerCase()) ||
        id.toLowerCase().includes(doorSearch.toLowerCase())
      : true,
  )
}

export default filterDoors
