import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import { getDeviceAbsolutePath } from '../../../libs/url'
import afaActions from '../../../store/afa/actions'
import { searchOpenSelector } from '../../../store/afa/selectors'
import { useBarCodeReader } from '../../../hooks/useBarCodeReader'
import { useGetSearchSuggestionsQuery } from '../../../services/afaProducts'
import Loading from '../../Loading'
import AfaSearchSuggestion from '../AfaSearchSuggestion'
import {
  Wrapper,
  CloseButtonWrapper,
  SearchWrapper,
  SearchContainer,
  CloseIconStyled,
  TextBox,
  Suggestions,
  ArrowRight,
  LoadingWrapper,
} from './style'

const AfaSearchLayer: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const open = useSelector(searchOpenSelector)

  const inputRef = useRef<HTMLInputElement>(null)
  const closeBtnWrapperRef = useRef<HTMLDivElement>(null)
  const searchWrapperRef = useRef<HTMLDivElement>(null)

  const [closeBtnWrapperHeight, setCloseBtnWrapperHeigth] = useState(0)
  const [searchWrapperHeight, setSearchWrapperHeight] = useState(0)
  const [searchWrapperPaddingRight, setSearchWrapperPaddingRight] = useState('')

  useEffect(() => {
    if (closeBtnWrapperRef.current && searchWrapperRef.current) {
      setCloseBtnWrapperHeigth(closeBtnWrapperRef.current.offsetHeight)
      setSearchWrapperHeight(searchWrapperRef.current.offsetHeight)

      const computedStyle = getComputedStyle(searchWrapperRef.current)
      setSearchWrapperPaddingRight(computedStyle.paddingRight)
    }
  }, [])

  const [searchTerm, setSearchTerm] = useState('')

  const searchSuggestionsQuery = useGetSearchSuggestionsQuery(searchTerm)

  const [suggestions, setSuggestions] = useState<string[]>()

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus()
    }

    if (!open) {
      setSearchTerm('')
      setSuggestions([])
    }
  }, [open])

  useEffect(() => {
    setSuggestions([])
  }, [searchTerm])

  useEffect(() => {
    setSuggestions(searchSuggestionsQuery.data)
  }, [searchSuggestionsQuery])

  const history = useHistory()
  const { assortment } = useParams<{ assortment: string }>()
  const applySearch = (searchTerm: string) => {
    dispatch(afaActions.toggleSearchOpen())

    history.push(
      `${getDeviceAbsolutePath()}/${
        assortment === 'frames' ? '' : `${assortment}/`
      }search-results?searchTerm=${searchTerm}`,
    )
  }

  const showSuggestions = !searchSuggestionsQuery.isFetching && searchTerm

  useBarCodeReader((reading?: string) => {
    if (reading) {
      setSearchTerm(reading)

      if (!open) {
        dispatch(afaActions.openSearch())
      }

      applySearch(reading)
    }
  })

  return (
    <Wrapper data-testid="afa-search-layer">
      <CloseButtonWrapper ref={closeBtnWrapperRef}>
        <button data-testid="close-button" onClick={() => dispatch(afaActions.toggleSearchOpen())}>
          <CloseIconStyled />
        </button>
      </CloseButtonWrapper>
      <SearchContainer closeBtnWrapperHeight={closeBtnWrapperHeight}>
        <SearchWrapper ref={searchWrapperRef}>
          <TextBox
            value={searchTerm}
            placeholder={t('Afa.searchPlaceholder')}
            ref={inputRef}
            onChange={e => setSearchTerm(e.currentTarget.value)}
            onKeyDown={e => {
              if (e.code === 'Enter') {
                applySearch(e.currentTarget.value)
                inputRef.current?.blur()
              }
            }}
          />
          <ArrowRight
            onClick={() => {
              applySearch(searchTerm)
            }}
          />
        </SearchWrapper>
        <Suggestions
          searchWrapperHeight={searchWrapperHeight}
          searchWrapperPaddingRight={searchWrapperPaddingRight}
        >
          {showSuggestions &&
            suggestions?.map(suggestion => (
              <AfaSearchSuggestion
                key={suggestion}
                suggestion={suggestion}
                searchTerm={searchTerm}
                applySearch={applySearch}
              />
            ))}
        </Suggestions>
      </SearchContainer>
      {searchSuggestionsQuery.isFetching && (
        <LoadingWrapper>
          <Loading isFullPage={false} />
        </LoadingWrapper>
      )}
    </Wrapper>
  )
}

export default AfaSearchLayer
