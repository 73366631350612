import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { Brand } from '../../../model/brand'
import cartActions from '../../../store/cart/actions'
import { activeBrandCodeSelector, cartViewTypeSelector } from '../../../store/cart/selectors'
import { customerStarsSelector, showCartTotalSelector } from '../../../store/customer/selectors'
import { pxToRem, spacing } from '../../../style/theme'
import { transparentScrollbarCss } from '../../CommonComponents'
import StarsWholesaleSwitch from '../../StarsWholesaleSwitch/StarsWholesaleSwitch'
import TableCartSingleBrand from '../TableCardSingleBrand'

const Brands = styled.div`
  display: flex;
  flex-direction: column;
  ${transparentScrollbarCss}
  overflow-y: auto;
  cursor: pointer;
  height: 100%;
  flex: 1;
  overflow-y: scroll;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(spacing(4))}rem;
  padding-bottom: ${pxToRem(spacing(9))}rem;
  max-width: 9rem;
`

export const TableCartBrands: React.FC<{
  activeBrand: string | null
  brands: {
    numberOfItems?: number
    brand: Brand
    amount?: number
    isCartBrand?: boolean
  }[]
}> = ({ activeBrand, brands }) => {
  const dispatch = useDispatch()

  const showAmounts = useSelector(showCartTotalSelector)
  const isStarsCustomer = useSelector(customerStarsSelector)
  const cartViewType = useSelector(cartViewTypeSelector)
  const activeBrandCode = useSelector(activeBrandCodeSelector)

  const sortedBrands = useMemo(() => {
    return brands
      .sort((b1, b2) => b1.brand.brand.localeCompare(b2.brand.brand))
      .filter(({ brand }) => brand)
  }, [brands])
  const newActiveBrandCode = useMemo(() => {
    return activeBrandCode
      ? undefined
      : sortedBrands.find(({ brand }) => brand.code === activeBrand)?.brand.code
  }, [activeBrandCode, sortedBrands, activeBrand])

  useEffect(() => {
    if (newActiveBrandCode) {
      dispatch(cartActions.setActiveBrandAction(newActiveBrandCode))
    }
  }, [newActiveBrandCode, dispatch])

  return (
    <Wrapper>
      <Brands className="cart-brands">
        {sortedBrands.map(({ amount, brand, numberOfItems, isCartBrand }) => (
          <TableCartSingleBrand
            key={brand.code}
            numberOfItems={numberOfItems}
            brand={brand}
            onClickBrand={({ code }: Brand) => {
              dispatch(cartActions.setActiveBrandAction(code))
            }}
            active={!!activeBrand && brand.group.includes(activeBrand)}
            amount={amount}
            showAmounts={showAmounts}
            isCartBrand={isCartBrand}
          />
        ))}
      </Brands>

      {isStarsCustomer && cartViewType === 'tableView' && <StarsWholesaleSwitch />}
    </Wrapper>
  )
}

export default TableCartBrands
