import { useMemo } from 'react'
import { useGetFilteredAfaCartQuery } from '../services/afaCart'
import { useSelector } from 'react-redux'
import { afaCartAllProductDetailsSelector } from '../store/afaCart/selectors'
import { AfaCartProduct, FilterKey } from '../model/afa'

const updateOptions = (options: Record<string, number>, masterDataOptions?: string | string[]) => {
  if (!masterDataOptions) {
    return
  }

  const safeArr: string[] = []
  safeArr.concat(masterDataOptions).forEach(option => {
    if (!options[option]) {
      options[option] = 0
    }

    options[option]++
  })
}

const useGetOptions = (query: { data?: { items: AfaCartProduct[] } }, category: FilterKey) => {
  const cartProductDetails = useSelector(afaCartAllProductDetailsSelector)

  const cartProducts = query.data?.items

  const options = useMemo(() => {
    const result = {} as Record<string, number>

    if (!cartProducts || !cartProductDetails) {
      return result
    }

    cartProducts.forEach(({ modelCode, colorCode, upc }) => {
      const prodDet = cartProductDetails[modelCode]
      if (!prodDet) {
        return
      }

      const moco = prodDet.mocos[colorCode]
      const proSize = prodDet.mocos[colorCode]?.sizes[upc]

      category === 'size' && updateOptions(result, proSize?.size)
      category === 'sport' && updateOptions(result, moco?.sports)
      category === 'colorFacet' && updateOptions(result, moco?.colorDescription)
      category === 'technology' && updateOptions(result, moco?.technologies)
      category === 'gender' && updateOptions(result, moco?.gender)
      category === 'release' && updateOptions(result, proSize?.season)
      category === 'family' && updateOptions(result, prodDet.family)
      category === 'productType' && updateOptions(result, prodDet.productType)
      category === 'collection' && updateOptions(result, prodDet.collection)
      category === 'limitedRelease' && updateOptions(result, proSize?.limitedRelease)
      category === 'fabric' && updateOptions(result, proSize?.fabric)
      category === 'fabricGroup' && updateOptions(result, prodDet.fabricGroup)
      category === 'afaStyle' && updateOptions(result, proSize?.afaStyle)
      category === 'adv' && updateOptions(result, proSize?.adv ? 'X' : '')
      category === 'hasKeylooks' && updateOptions(result, moco?.keylooks)
    })

    return result
  }, [cartProducts, cartProductDetails, category])

  return Object.entries(options).map(([name, results]) => ({ name, results }))
}

export const useGetAfaCartFiltersData = () => {
  const categories = {
    size: useGetOptions(useGetFilteredAfaCartQuery('size'), 'size'),
    sport: useGetOptions(useGetFilteredAfaCartQuery('sport'), 'sport'),
    colorFacet: useGetOptions(useGetFilteredAfaCartQuery('colorFacet'), 'colorFacet'),
    technology: useGetOptions(useGetFilteredAfaCartQuery('technology'), 'technology'),
    gender: useGetOptions(useGetFilteredAfaCartQuery('gender'), 'gender'),
    season: useGetOptions(useGetFilteredAfaCartQuery('release'), 'release'),
    family: useGetOptions(useGetFilteredAfaCartQuery('family'), 'family'),
    productType: useGetOptions(useGetFilteredAfaCartQuery('productType'), 'productType'),
    collection: useGetOptions(useGetFilteredAfaCartQuery('collection'), 'collection'),
    limitedRelease: useGetOptions(useGetFilteredAfaCartQuery('limitedRelease'), 'limitedRelease'),
    fabric: useGetOptions(useGetFilteredAfaCartQuery('fabric'), 'fabric'),
    fabricGroup: useGetOptions(useGetFilteredAfaCartQuery('fabricGroup'), 'fabricGroup'),
    fit: useGetOptions(useGetFilteredAfaCartQuery('fit'), 'fit'),
    afaStyle: useGetOptions(useGetFilteredAfaCartQuery('afaStyle'), 'afaStyle'),
    adv: useGetOptions(useGetFilteredAfaCartQuery('adv'), 'adv'),
    _keylook_facet_: useGetOptions(useGetFilteredAfaCartQuery('hasKeylooks'), 'hasKeylooks'),
  }

  return Object.entries(categories).map(([name, options]) => ({
    name,
    options,
  }))
}
