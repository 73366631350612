import { FC } from 'react'
import { useGetBrandLogo } from '../../../../services/afaContents'
import styled from 'styled-components/macro'
import { useSelector } from 'react-redux'
import { brandSelectorByCode } from '../../../../store/brands/selectors'

const Wrapper = styled.div`
  &:not(.selected) > img {
    filter: invert(1);
  }

  img {
    height: max(4vh, 25px);
    max-height: 100%;
  }
`

type Props = {
  brandCode: string
  selected: boolean
  className?: string
}

const BrandLogo: FC<Props> = ({ brandCode, selected, className }) => {
  const { brandLogo, isSuccess } = useGetBrandLogo(brandCode)
  const brand = useSelector(brandSelectorByCode(brandCode))

  return (
    <Wrapper className={[className, selected ? 'selected' : ''].join(' ')}>
      {brandLogo || !isSuccess ? (
        <img src={brandLogo} alt={brand?.brand || brandCode} />
      ) : (
        <span>{brand?.brand || brandCode}</span>
      )}
    </Wrapper>
  )
}

export default BrandLogo
