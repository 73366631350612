import React, { ChangeEventHandler } from 'react'
import styled from 'styled-components'

import { duration, palette } from '../style/theme'

const Wrapper = styled.label`
  display: flex;
  align-items: center;
  font-size: inherit;

  input {
    display: none;
  }

  .iconAndText {
    position: relative;
    line-height: 1;
    display: flex;
    align-content: center;
    height: 100%;
    align-items: center;
    font-weight: normal;
    color: ${palette.congressBlue};
    text-transform: uppercase;
    flex: 1;
  }

  input:disabled + .iconAndText {
    color: ${palette.gray};
  }

  .iconAndText::before {
    content: '';
    display: inline-block;
    width: 1.5em;
    height: 1em;
    border: solid 2px ${palette.cornflowerBlue};
    border-radius: 1rem;
    transition: background-color ${duration.short}ms;
  }

  input:disabled + .iconAndText::before {
    border-color: ${palette.alto};
  }

  .iconAndText::after {
    content: '';
    position: absolute;
    left: 0.25em;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    background-color: ${palette.cornflowerBlue};
    border-radius: 1em;
    transition: left ${duration.short}ms, background-color ${duration.short}ms;
  }

  input:disabled + .iconAndText::after {
    background-color: ${palette.alto};
  }

  input:checked + .iconAndText::before {
    background-color: ${palette.cornflowerBlue};
  }

  input:checked:disabled + .iconAndText::before {
    background-color: ${palette.alto};
  }

  input:checked + .iconAndText::after {
    left: 0.75em;
    background-color: ${palette.white};
  }

  .text {
    margin-left: 1em;
  }
`

type Props = {
  label?: string
  onChange: ChangeEventHandler
  disabled?: boolean
  checked: boolean
  className?: string
}

const Switch: React.FC<Props> = ({ label, onChange, disabled, checked, className }) => {
  return (
    <Wrapper className={className}>
      <input type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
      <span className="iconAndText">{label && <span className="text">{label}</span>}</span>
    </Wrapper>
  )
}

export default Switch
