import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getFluidFontSize, getFluidSize, palette, pxToRem } from '../../../../style/theme'
import StepperBuilder from './StepBuilder'
import { useDispatch, useSelector } from 'react-redux'
import { checkoutStepSelector } from '../../../../store/checkout/selectors'
import checkoutActions from '../../../../store/checkout/actions'
import { cartEditableSelector } from '../../../../store/cart/selectors'

const Wrapper = styled.div`
  padding: ${getFluidSize(`${pxToRem(25)}rem`, 50)} 2vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${palette.gallery};
`

const PageTitle = styled.div`
  font-size: ${getFluidFontSize('14px', 15)};

  h1 {
    font-family: 'Avenir-Light', sans-serif;
    color: ${palette.biscay};
    letter-spacing: 1px;
    font-size: ${getFluidFontSize(`${pxToRem(42.5)}rem`, 15)};
  }
`

const Stepper: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentStep = useSelector(checkoutStepSelector)
  const cartEditable = useSelector(cartEditableSelector)

  const steps = [
    { stepTitle: t('Checkout.Stepper.Process'), pageTitle: t('Checkout.Process.Title') },
    { stepTitle: t('Checkout.Stepper.Address'), pageTitle: t('Checkout.Address.Title') },
    { stepTitle: t('Checkout.Stepper.Confirm'), pageTitle: t('Checkout.Confirm.Title') },
  ]

  const handleClick = (index: number) => {
    index < currentStep && index >= 0 && cartEditable && dispatch(checkoutActions.setStep(index))
  }

  const step = steps[currentStep]

  return (
    <Wrapper>
      <PageTitle>
        <h1>{step.pageTitle}</h1>
      </PageTitle>

      <StepperBuilder
        steps={steps.map(({ stepTitle }) => stepTitle)}
        active={currentStep}
        onClick={handleClick}
      />
    </Wrapper>
  )
}

export default Stepper
