import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Content } from '../../model/content'
import { setBrandDiscoveryPlayed, setBrandDiscoveryPlaying } from '../../store/actions'
import {
  isBrandDiscoveryPlayedSelector,
  isBrandDiscoveryPlayingSelector,
} from '../../store/brands/selectors'
import DisplayMedia from '../DisplayMedia'

type Props = {
  brandCode: string
  media: Content
  videoPlayedCallback: () => void
}

const DiscoveryVideo: React.FC<Props> = ({ brandCode, media, videoPlayedCallback }) => {
  const isBrandDiscoveryPlaying = useSelector(isBrandDiscoveryPlayingSelector)
  const isBrandDiscoveryPlayed = useSelector(isBrandDiscoveryPlayedSelector)

  const dispatch = useDispatch()

  useEffect(() => {
    const setPlayingToFalseAtUnmounting = () => {
      isBrandDiscoveryPlaying && dispatch(setBrandDiscoveryPlaying({ brandCode, playing: false }))
    }
    return isBrandDiscoveryPlaying ? setPlayingToFalseAtUnmounting : undefined
  }, [dispatch, brandCode, isBrandDiscoveryPlaying])

  return (
    <DisplayMedia
      media={media}
      displayLast={true}
      handleVideoEnded={() => {
        isBrandDiscoveryPlaying && dispatch(setBrandDiscoveryPlaying({ brandCode, playing: false }))
        !isBrandDiscoveryPlayed && dispatch(setBrandDiscoveryPlayed({ brandCode, played: true }))
        typeof videoPlayedCallback === 'function' && videoPlayedCallback()
      }}
      handleVideoPlaying={() => {
        !isBrandDiscoveryPlaying && dispatch(setBrandDiscoveryPlaying({ brandCode, playing: true }))
        isBrandDiscoveryPlayed && dispatch(setBrandDiscoveryPlayed({ brandCode, played: false }))
      }}
    />
  )
}

export default DiscoveryVideo
