import classnames from 'classnames'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { horizontalScrollbarCss } from '../../components/CommonComponents'
import Loading from '../../components/Loading'
import { getCart } from '../../store/actions'
import { starsBrandsSelector } from '../../store/brands/selectors'
import { activeBrandCodeSelector } from '../../store/cart/selectors'
import {
  ledMocosByBrandsSelectorFactory,
  ledStarsMocosByBrandsAndActiveDoorsSelector,
} from '../../store/cart/selectors/leds'
import starsActions from '../../store/stars/actions'
import {
  cartModeSelector,
  starsAssortmentLoadedBrandCodesSelector,
} from '../../store/stars/selectors'
import { breakpoints, getFluidFontSize, palette } from '../../style/theme'
import LedListCouvette from './LedListCouvette'
import { isKidCategoryModeEnabledSelector } from '../../store/app/selectors'

const Wrapper = styled.div<{
  isStars?: boolean
}>`
  clear: both;
  font-size: ${getFluidFontSize('14px')};
  background: ${props => (props.isStars ? palette.blueStars : palette.black)};
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media (max-height: 2159px) {
    padding: 2em;
  }
`
Wrapper.displayName = 'LedList'

const Scrollable = styled.div`
  ${horizontalScrollbarCss}
  .led-list-couvette {
    color: rgba(0, 0, 0, 0.65);
  }

  display: flex;
  width: 100%;
  height: calc(100% + 20px);
  overflow-x: scroll;
  overflow-y: hidden;

  &.reverse {
    flex-direction: row-reverse;
    justify-content: end;
  }
`

const NotFoundMessage = styled.div`
  color: ${palette.white};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const EmpyCartMessage = styled.span`
  @media (min-width: ${breakpoints.M}) {
    font-size: ${getFluidFontSize('16px')};
  }
  @media (min-width: ${breakpoints.L}) {
    font-size: ${getFluidFontSize('32px')};
  }
  @media (min-width: ${breakpoints.XL}) {
    font-size: ${getFluidFontSize('64px')};
  }
`

function scrollToBrand(revert: boolean) {
  const activeBrand =
    document.querySelector('.selectedBrand') ||
    document.querySelector('.ledBrandContainer:first-child')
  activeBrand &&
    activeBrand.scrollIntoView &&
    activeBrand.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: revert ? 'end' : 'start',
    })
}

type Props = {
  revert?: boolean
  category?: string
}

export const LedList: React.FC<Props> = ({ revert = false, category = '1' }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const activeBrand = useSelector(activeBrandCodeSelector)
  const productsByBrand = useSelector(ledMocosByBrandsSelectorFactory(category))
  const starsProductsByBrand = useSelector(ledStarsMocosByBrandsAndActiveDoorsSelector(category))
  const starsAssortmentLoadedBrandCodes = useSelector(starsAssortmentLoadedBrandCodesSelector)
  const isStars = useSelector(cartModeSelector) === 'stars'
  const starsBrands = useSelector(starsBrandsSelector)
  const isJuniorMode = useSelector(isKidCategoryModeEnabledSelector)

  useEffect(() => {
    dispatch(getCart())
  }, [dispatch])

  useEffect(() => {
    scrollToBrand(revert)
  }, [activeBrand, revert])

  const filteredStarsBrands = useMemo(() => {
    return starsBrands
      .flatMap(({ subBrands }) => subBrands)
      .filter(({ junior }) => junior === isJuniorMode)
  }, [starsBrands, isJuniorMode])

  useEffect(() => {
    if (starsAssortmentLoadedBrandCodes.length === 0 && filteredStarsBrands.length > 0) {
      dispatch(starsActions.loadStarsAssortment(filteredStarsBrands.map(({ code }) => code)))
    }
  }, [dispatch, filteredStarsBrands, starsAssortmentLoadedBrandCodes])

  const isLoaded = isStars ? starsAssortmentLoadedBrandCodes.length > 0 : true

  const products = isStars ? starsProductsByBrand : productsByBrand
  return (
    <Wrapper
      className={classnames({
        'led-list': true,
        'wrapper-message-notfound': products.length === 0 && isLoaded,
      })}
      isStars={isStars}
    >
      <Scrollable
        className={classnames({
          reverse: revert,
        })}
      >
        {activeBrand &&
          products.length &&
          products.map(({ code, items }) => (
            <LedListCouvette
              className="led-list-couvette"
              key={code}
              brandSlug={code}
              items={items}
            />
          ))}
        {isLoaded && !products.length && (
          <NotFoundMessage className="message-notfound">
            <EmpyCartMessage>{t('CartPage.emptyCart')}</EmpyCartMessage>
          </NotFoundMessage>
        )}
      </Scrollable>
      {!isLoaded && <Loading />}
    </Wrapper>
  )
}

export default LedList
