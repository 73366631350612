import { VirtualMirrorProduct } from '../../model/virtualMirror'
import {
  Card,
  CarouselBottom,
  Carousel,
  CarouselContainer,
  VmImage,
  VmProductCode,
  VmProductName,
  CardsWrapper,
} from './style'

type Props = {
  products: VirtualMirrorProduct[]
  selectedUpc: string | null
  runCardActions: (upc: string) => void
}

const VMCarousel: React.FC<Props> = ({ products, selectedUpc, runCardActions }) => {
  const scrollToCard = (id: string) => {
    const targetCard = document.getElementById(id)

    if (targetCard) {
      const options = {
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      } as ScrollIntoViewOptions

      targetCard.style.transition = 'transform 0.15s'
      targetCard.style.transform = 'scale(0.90)'
      setTimeout(() => {
        targetCard.style.transform = 'scale(1)'
      }, 50)

      //https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
      targetCard.scrollIntoView(options)
    }
  }

  return (
    <CarouselContainer>
      <Carousel>
        <CardsWrapper>
          {products.map(product => {
            const { upc, code, lensColorLabel, thumbnailUrl } = product
            const id = `vm-card-${upc}`

            return (
              <Card
                id={id}
                className={selectedUpc === upc ? 'selected' : ''}
                key={upc}
                onClick={() => {
                  scrollToCard(id)
                  runCardActions(upc)
                }}
              >
                <VmImage src={thumbnailUrl} />
                <VmProductCode>{code}</VmProductCode>
                <VmProductName>{lensColorLabel}</VmProductName>
              </Card>
            )
          })}
        </CardsWrapper>
        <CarouselBottom />
      </Carousel>
    </CarouselContainer>
  )
}

export default VMCarousel
