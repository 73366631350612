import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import starsActions from '../../store/stars/actions'
import { monthsSelector, selectedMonthSelector } from '../../store/stars/selectors'
import { palette, pxToRem, spacing } from '../../style/theme'
import Radio from '../../styleguide/Radio'
import { format } from 'date-fns'

const UlMenu = styled.ul`
  color: ${palette.biscay};
`

const LiMenu = styled.li`
  padding: ${pxToRem(spacing(3))}rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${palette.alto};
  }

  .filter-by-month-cart & {
    padding: ${pxToRem(spacing(1))}rem 0;

    &:not(:last-child) {
      border-bottom: none;
    }
  }
`

const MonthMenu: React.FC = () => {
  const months = useSelector(monthsSelector)
  const selectedMonth = useSelector(selectedMonthSelector)
  const filteredMonths = useMemo(() => {
    const today = new Date(format(new Date(), 'yyyy-MM'))
    return months.filter(month => {
      const date = new Date(month.replace('_', '-'))
      return date >= today
    })
  }, [months])

  const dispatch = useDispatch()

  return (
    <UlMenu>
      {filteredMonths.sort().map(month => (
        <LiMenu key={month}>
          <Radio
            value={month}
            checked={selectedMonth === month}
            onChange={() => dispatch(starsActions.setSelectedMonth(month))}
          >
            {month.replace('_', ' ')}
          </Radio>
        </LiMenu>
      ))}
    </UlMenu>
  )
}

export default MonthMenu
