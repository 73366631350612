import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router'

import devices from '../config/devices'
import { useGetSelectedDoorId } from '../flows/AssortmentAdvisor/Hooks/usePreselectDefaultDoor'
import { Positioning } from '../flows/AssortmentAdvisor/Model/aaModel'
import socketManager from '../libs/socketManager'
import { getDeviceFromUrl, getUrlParts, isIpadOnlyRoom } from '../libs/url'
import { BrandContent } from '../model/brand'
import { Content } from '../model/content'
import {
  useGetBrandActivationQuery,
  useGetBrandContentQuery,
  useGetBrandLogo,
  useGetCategoriesPageContentsQuery,
} from '../services/afaContents'
import { preselectBrandsAction, setBrandVisited, setWelcomeVisited } from '../store/actions'
import { brandSelector, brandSelectorByCodes } from '../store/brands/selectors'
import { customerSelector, welcomeVisitedSelector } from '../store/customer/selectors'
import { useSearchParams } from './useSearchParams'
import { isPdpVisibleSelector } from '../store/pdp/selectors'

export const useHandleOpenCategoryPage = (
  brandSlug: string,
  afaContents?: Partial<BrandContent>,
) => {
  const selectedBrand = useSelector(brandSelector(brandSlug))
  const customer = useSelector(customerSelector)

  const dispatch = useDispatch()

  // we use refs here since a recreation of the brand object must not trigger a dispatch of an "open_categories_page" event
  // this event must be dispatched only if the user opens a new categories page, and that is reflected in the brandSlug
  const selectedBrandRef = useRef(selectedBrand)
  const brandVisitedRef = useRef(customer.visited[brandSlug])
  useEffect(() => {
    dispatch(preselectBrandsAction([brandSlug]))

    socketManager.emitSignal(
      'open_categories_page',
      {
        brand: {
          ...selectedBrandRef.current,
          ...afaContents,
          slug: brandSlug || 'nobrand',
        },
        brandVisited: brandVisitedRef.current,
      },
      devices.table,
    )

    //on unmount
    return () => {
      dispatch(setBrandVisited(brandSlug))
    }
  }, [brandSlug, dispatch, afaContents])
}

export const useHandleOpenAfaCategoryPage = (brandCode: string) => {
  const brand = useSelector(brandSelectorByCodes([brandCode]))[0]
  const brandSlug = brand?.slug
  const categoriesContentQuery = useGetCategoriesPageContentsQuery(brandCode)
  const navigationWall = categoriesContentQuery.data?.wallImage?.url
  const brandContentQuery = useGetBrandContentQuery(brandCode)
  const navigationImageLeft = brandContentQuery.data?.brandNavigationImage.sx
  const navigationImageRight = brandContentQuery.data?.brandNavigationImage.dx
  const brandActivationContentQuery = useGetBrandActivationQuery(brandCode)
  const brandActivationVideo = brandActivationContentQuery.data?.find(str => str)
  const { brandLogo } = useGetBrandLogo(brandCode)

  const afaContents = useMemo(
    () =>
      ({
        act_wall: {
          id: `act_wall-${brandCode}`,
          title: '',
          url: brandActivationVideo || navigationWall || brandLogo,
          type: brandActivationVideo ? 'video' : 'image',
          invert: !brandActivationVideo,
        },
        act_led_sx: {
          id: `act_led_sx-${brandCode}`,
          title: '',
          url: brandActivationVideo || navigationImageLeft || brandLogo,
          type: brandActivationVideo ? 'video' : 'image',
          invert: !brandActivationVideo,
        },
        act_led_dx: {
          id: `act_led_dx-${brandCode}`,
          title: '',
          url: brandActivationVideo || navigationImageRight || brandLogo,
          type: brandActivationVideo ? 'video' : 'image',
          invert: !brandActivationVideo,
        },
        nav_wall: {
          id: `nav_wall-${brandCode}`,
          title: '',
          url: navigationWall || brandLogo,
          type: 'image',
          invert: true,
        },
        nav_led_sx: {
          id: `nav_led_sx-${brandCode}`,
          title: '',
          url: navigationImageLeft || brandLogo,
          type: 'image',
          invert: !navigationImageLeft,
        },
        nav_led_dx: {
          id: `nav_led_dx-${brandCode}`,
          title: '',
          url: navigationImageRight || brandLogo,
          type: 'image',
          invert: !navigationImageRight,
        },
      } as Partial<BrandContent>),
    [
      brandCode,
      brandActivationVideo,
      navigationWall,
      brandLogo,
      navigationImageLeft,
      navigationImageRight,
    ],
  )
  useHandleOpenCategoryPage(brandSlug, afaContents)
}

export const useHandleOpenChooseBrandPage = () => {
  const dispatch = useDispatch()

  const welcomeVisited = useSelector(welcomeVisitedSelector)

  const emitNavigationEvent = useCallback(() => {
    socketManager.emitSignal(
      'open_chooseBrand_page',
      {
        welcomeVideoVisited: welcomeVisited,
      },
      [devices.table],
    )
  }, [welcomeVisited])

  useEffect(() => {
    emitNavigationEvent()

    return () => {
      if (!welcomeVisited) {
        dispatch(setWelcomeVisited())
      }
    }
  }, [dispatch, emitNavigationEvent, welcomeVisited])

  return emitNavigationEvent
}

const MOODBOARD_OPEN_ON_PARAM_NAME = 'moodboardOpenOn'

const getWallMoodboardUrl = () => `${getUrlParts().extrapath}${window.location.search}`

const useAddMoodboardOpenOnParamOnTable = (thereIsMoodboard: boolean) => {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const serchParamsRef = useRef(searchParams)
  useEffect(() => {
    const device = getDeviceFromUrl()
    if (!isIpadOnlyRoom() && thereIsMoodboard && device === 'table') {
      serchParamsRef.current.set(MOODBOARD_OPEN_ON_PARAM_NAME, 'wall')
      setSearchParams(serchParamsRef.current, { replace: true, state: location.state })
    }
  }, [thereIsMoodboard, setSearchParams, location.state])
}

export const useHandleOpenKeyLooksPage = (keyLookCode?: string) => {
  const { brandCode } = useParams<{ brandCode: string }>()
  const brandContentQuery = useGetBrandContentQuery(brandCode)
  const keyLookMoodboard = keyLookCode && brandContentQuery.data?.moodboardKeyLooks[keyLookCode]
  const sportMoodboard = brandContentQuery.data?.moodboardSport
  const navigationImageLeft = brandContentQuery.data?.brandNavigationImage.sx
  const navigationImageRight = brandContentQuery.data?.brandNavigationImage.dx
  const { brandLogo } = useGetBrandLogo(brandCode)

  const thereIsMoodboard = Boolean(keyLookMoodboard || sportMoodboard)
  const moodboardUrl = thereIsMoodboard ? getWallMoodboardUrl() : undefined

  useAddMoodboardOpenOnParamOnTable(thereIsMoodboard)

  const contents = useMemo(
    () =>
      ({
        wall: {
          id: `keyLooksPage-wall-${brandCode}`,
          title: '',
          url: moodboardUrl || brandLogo,
          type: moodboardUrl ? 'internalUrl' : 'image',
          invert: !moodboardUrl,
        },
        ledLeft: {
          id: `keyLooksPage-ledLeft-${brandCode}`,
          title: '',
          url: navigationImageLeft || brandLogo,
          type: 'image',
          invert: !navigationImageLeft,
        },
        ledRight: {
          id: `keyLooksPage-ledRight-${brandCode}`,
          title: '',
          url: navigationImageRight || brandLogo,
          type: 'image',
          invert: !navigationImageRight,
        },
      } as Record<'wall' | 'ledLeft' | 'ledRight', Content>),
    [brandCode, moodboardUrl, navigationImageLeft, navigationImageRight, brandLogo],
  )

  useEffect(() => {
    socketManager.emitSignal('open_keyLooks_page', contents, [devices.table])
  }, [contents])
}

export const useHandleOpenAfaPlpPage = () => {
  const { brandCode } = useParams<{ brandCode: string }>()
  const [searchParams] = useSearchParams()
  const sportsParam = searchParams.get('sport') || undefined
  const sports = (sportsParam || '').split(',')
  const categoriesContentQuery = useGetCategoriesPageContentsQuery(brandCode)
  const navigationWall = categoriesContentQuery.data?.wallImage?.url
  const brandContentQuery = useGetBrandContentQuery(
    brandCode,
    sports.length === 1 ? sports[0] : undefined,
  )
  const sportMoodboard = brandContentQuery.data?.moodboardSport
  const thereIsMoodboard = Boolean(sportMoodboard)
  const moodboardUrl = thereIsMoodboard ? getWallMoodboardUrl() : undefined
  const navigationImageLeft = brandContentQuery.data?.brandNavigationImage.sx
  const navigationImageRight = brandContentQuery.data?.brandNavigationImage.dx
  const { brandLogo } = useGetBrandLogo(brandCode)

  const contents = useMemo(
    () =>
      ({
        wall: {
          id: `afaPlpPage-wall-${brandCode}`,
          title: '',
          url:
            sportsParam && sports.length === 1 && moodboardUrl
              ? moodboardUrl
              : navigationWall
              ? navigationWall
              : brandLogo,
          type: sportsParam && sports.length === 1 && moodboardUrl ? 'internalUrl' : 'image',
          invert: !(sportsParam && sports.length === 1 && moodboardUrl),
        },
        ledLeft: {
          id: `afaPlpPage-ledLeft-${brandCode}`,
          title: '',
          url: navigationImageLeft || brandLogo,
          type: 'image',
          invert: !navigationImageLeft,
        },
        ledRight: {
          id: `afaPlpPage-ledRight-${brandCode}`,
          title: '',
          url: navigationImageRight || brandLogo,
          type: 'image',
          invert: !navigationImageRight,
        },
      } as Record<'wall' | 'ledLeft' | 'ledRight', Content>),
    [
      brandCode,
      sportsParam,
      sports.length,
      moodboardUrl,
      navigationWall,
      brandLogo,
      navigationImageLeft,
      navigationImageRight,
    ],
  )

  useEffect(() => {
    socketManager.emitSignal('open_afaPlp_page', contents, [devices.table])
  }, [contents])

  return {
    sport: sports[0],
  }
}

export const useHandleOpenAfaPdpPage = (brandCode?: string, sportCode?: string) => {
  const location = useLocation()
  const brandContentQuery = useGetBrandContentQuery(brandCode, sportCode)
  const sportMoodboard = brandContentQuery.data?.moodboardSport
  const navigationImageLeft = brandContentQuery.data?.brandNavigationImage.sx
  const navigationImageRight = brandContentQuery.data?.brandNavigationImage.dx
  const brandLogo = useGetBrandLogo(brandCode || '')

  const moodboardUrl = sportMoodboard && getWallMoodboardUrl()

  useAddMoodboardOpenOnParamOnTable(!!sportMoodboard)

  const contents = useMemo(
    () =>
      ({
        wall: {
          id: `afaPlpPage-wall-${brandCode}`,
          title: '',
          url: moodboardUrl || brandLogo,
          type: moodboardUrl ? 'internalUrl' : 'image',
          invert: !moodboardUrl,
        },
        ledLeft: {
          id: `afaPlpPage-ledLeft-${brandCode}`,
          title: '',
          url: navigationImageLeft || brandLogo,
          type: 'image',
          invert: !navigationImageLeft,
        },
        ledRight: {
          id: `afaPlpPage-ledRight-${brandCode}`,
          title: '',
          url: navigationImageRight || brandLogo,
          type: 'image',
          invert: !navigationImageRight,
        },
      } as Record<'wall' | 'ledLeft' | 'ledRight', Content>),
    [brandCode, moodboardUrl, navigationImageLeft, navigationImageRight, brandLogo],
  )

  useEffect(() => {
    if (brandCode) {
      socketManager.emitSignal('open_afaPdp_page', contents, [devices.table])
    }
  }, [contents, brandCode])

  const [searchParams, setSearchParams] = useSearchParams()

  return () => {
    searchParams.delete(MOODBOARD_OPEN_ON_PARAM_NAME)
    setSearchParams(searchParams, { replace: true, state: location.state })
  }
}

export const useShowMoodboard = (brandCode?: string, sportCode?: string, keyLookCode?: string) => {
  const location = useLocation()
  const brandContentQuery = useGetBrandContentQuery(brandCode, sportCode)
  const keyLookMoodboard = keyLookCode
    ? brandContentQuery.data?.moodboardKeyLooks[keyLookCode]
    : undefined
  const sportMoodboard = brandContentQuery.data?.moodboardSport

  const [searchParams, setSearchParams] = useSearchParams()
  const moodboardOpenOn = searchParams.get(MOODBOARD_OPEN_ON_PARAM_NAME)

  const switchMoodboard = useCallback(() => {
    if (isIpadOnlyRoom()) {
      if (moodboardOpenOn) {
        searchParams.delete(MOODBOARD_OPEN_ON_PARAM_NAME)
      } else {
        searchParams.set(MOODBOARD_OPEN_ON_PARAM_NAME, 'table')
      }
      setSearchParams(searchParams, { replace: true, state: location.state })
    } else {
      if (!moodboardOpenOn) {
        return
      }

      if (moodboardOpenOn === 'table') {
        searchParams.set(MOODBOARD_OPEN_ON_PARAM_NAME, 'wall')
      } else {
        searchParams.set(MOODBOARD_OPEN_ON_PARAM_NAME, 'table')
      }
      setSearchParams(searchParams, { replace: true, state: location.state })
    }
  }, [moodboardOpenOn, searchParams, setSearchParams, location.state])

  const thereIsMoodboard = Boolean(keyLookMoodboard || sportMoodboard)
  return {
    thereIsMoodboard,
    moodboardOpenOn,
    switchMoodboard,
  }
}

export const useHandleOpenAaChooseBrandPage = () => {
  useEffect(() => {
    socketManager.emitSignal('open_aaChooseBrand_page', [devices.table])
  }, [])
}

export const useHandleOpenAaPlpPage = () => {
  const selectedDoorId = useGetSelectedDoorId()
  const [searchParams] = useSearchParams()
  const positioning = searchParams.get('positioning')
  const mapView = searchParams.get('mapView')
  const searchedAddress = searchParams.get('searchedAddress')
  const isPdpVisible = useSelector(isPdpVisibleSelector)

  const contents = useMemo(() => {
    const positioningParam = positioning ? `&positioning=${positioning}` : ''
    const mapViewParam = mapView ? `&mapView=${mapView}` : ''
    const searchedAddressParam = searchedAddress ? `&searchedAddress=${searchedAddress}` : ''
    // Needed to keep isPdpVisible as useMemo dependency
    const title = isPdpVisible ? '' : ''

    return {
      wall: {
        id: 'aaPlpPage-wall',
        title,
        url: `assortment-advisor/map?aa-doorId=${selectedDoorId}${positioningParam}${mapViewParam}${searchedAddressParam}`,
        type: 'internalUrl',
      },
    } as Record<'wall', Content>
  }, [positioning, mapView, selectedDoorId, isPdpVisible, searchedAddress])

  useEffect(() => {
    socketManager.emitSignal('open_aaPlp_page', contents, [devices.table])
  }, [contents])
}

export const useHandleOpenAaMapPage = () => {
  const selectedDoorId = useGetSelectedDoorId()
  const [searchParams] = useSearchParams()
  const positioning = searchParams.get('positioning')

  const contents = useMemo(() => {
    // Needed to keep selectedDoorId and positioning as useMemo dependencies
    const title = selectedDoorId && positioning ? '' : ''

    return {
      wall: {
        id: 'aaPlpPage-wall',
        title,
        url: `assortment-advisor/plp${window.location.search}`,
        type: 'internalUrl',
      },
    } as Record<'wall', Content>
  }, [selectedDoorId, positioning])

  useEffect(() => {
    socketManager.emitSignal('open_aaPlp_page', contents, [devices.table])
  }, [contents])
}

export const useChangeAaMapSelectedPositioning = (positioning: Positioning) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedDoorId = useGetSelectedDoorId()

  const changeAaMapSelectedPositioning = () => {
    const wallUrl = `assortment-advisor/map?aa-doorId=${selectedDoorId}&positioning=${positioning}`

    const contents = {
      wall: {
        id: 'aaPlpPage-wall',
        title: '',
        url: wallUrl,
        type: 'internalUrl',
      },
    } as Record<'wall', Content>

    socketManager.emitSignal('open_aaPlp_page', contents, [devices.table])

    searchParams.set('positioning', positioning)
    setSearchParams(searchParams, { replace: true })
  }

  return changeAaMapSelectedPositioning
}

export const useToggleAaMapTrends = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedDoorId = useGetSelectedDoorId()

  const toggleAaMapTrends = (mode: 'plp' | 'map') => {
    const trendsEnabled = searchParams.get('trends') === 'true'
    const positioning = searchParams.get('positioning')
    const wallUrl = `assortment-advisor/map?aa-doorId=${selectedDoorId}&positioning=${positioning}${
      !trendsEnabled ? '&trends=true' : ''
    }`

    if (mode === 'plp') {
      const contents = {
        wall: {
          id: 'aaPlpPage-wall',
          title: '',
          url: wallUrl,
          type: 'internalUrl',
        },
      } as Record<'wall', Content>

      socketManager.emitSignal('open_aaPlp_page', contents, [devices.table])
    }

    if (trendsEnabled) {
      searchParams.delete('trends')
    } else {
      searchParams.set('trends', 'true')
    }
    setSearchParams(searchParams, { replace: true })
  }

  return toggleAaMapTrends
}
