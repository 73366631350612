import { useEffect, useRef, useState } from 'react'
import {
  BackgroundLayer,
  FiltersWrapper,
  Header,
  Reset,
  Title,
  WBCategories,
  WBCategoriesItem,
  WBOptionCategory,
  WBShowResults,
  OptionsList,
  CategoriesList,
  Results,
} from './style'
import { useDispatch, useSelector } from 'react-redux'
import { viewportSelector } from '../../../../../store/viewport/selectors'
import {
  filteredWhiteboardsLengthSelector,
  filtersOpenSelector,
  getSelectedSwitch,
  whiteboardsGridFiltersSelector,
} from '../../../../../store/whiteboard/selectors'
import whiteboardActions from '../../../../../store/whiteboard/actions'
import { useTranslation } from 'react-i18next'
import RCIcon from '../../../../../components/UI/RCIcon'
import { useGetVisibleWhiteboardsQuery } from '../../../../../services/whiteboard'
import { WhiteboardCardType } from '../../../../../model/whiteboard'
import { convertStringToCamelCase } from '../../../../../helpers/genericHelper'
import { getSeasonCodeFromReleaseCode } from '../../../../../helpers/getSeasonCodeFromReleaseCode'

const Categories = ['season', 'sport', 'category', 'createdBy'] as const
type Category = typeof Categories[number]

const Owners = ['created by me', 'created by others'] as const
export type Owner = typeof Owners[number]

const createFilters = (data: WhiteboardCardType[]): Record<Category, string[]> => {
  const releasesSet = new Set<string>()
  const sportsSet = new Set<string>()
  const categoriesSet = new Set<string>()

  data?.forEach(whiteboard => {
    releasesSet?.add(whiteboard.release)
    whiteboard?.sports?.forEach(sport => sportsSet.add(sport))
    whiteboard?.subcategories?.forEach(subcategory => categoriesSet.add(subcategory))
  })

  const releasesArray = Array.from(releasesSet).sort((a, b) => a.localeCompare(b))
  const sportsArray = Array.from(sportsSet).sort((a, b) => a.localeCompare(b))
  const categoriesArray = Array.from(categoriesSet).sort((a, b) => a.localeCompare(b))

  return {
    season: releasesArray,
    sport: sportsArray,
    category: categoriesArray,
    createdBy: [...Owners],
  }
}

type Props = {
  searchTextValue: string
}

const WhiteboardFilters: React.FC<Props> = ({ searchTextValue }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const whiteboardSwitchSelection = useSelector(getSelectedSwitch)
  const { data } = useGetVisibleWhiteboardsQuery(searchTextValue, whiteboardSwitchSelection)
  const whiteboardsGrid = data?.whiteboards

  const { width } = useSelector(viewportSelector)
  const filtersOpen = useSelector(filtersOpenSelector)
  const filtersApplied = useSelector(whiteboardsGridFiltersSelector)
  const filteredWhiteboardsLength = useSelector(filteredWhiteboardsLengthSelector)

  const whiteboardToolbar = document.getElementById('whiteboard-toolbar') as HTMLElement
  const whiteboardToolbarFilter = document.getElementById(
    'whiteboard-toolbar-filter',
  ) as HTMLElement
  const WBShowFiltersRef = useRef<HTMLDivElement>(null)

  const [WBToolbarHeight, setWBToolbarHeight] = useState(0)
  const [WBToolbarWidth, setWBToolbarWidth] = useState(0)
  const [selectedCategory, setSelectedCategory] = useState<Category>()

  useEffect(() => {
    if (!filtersOpen) {
      setSelectedCategory(undefined)
    }
  }, [filtersOpen])

  const handleClickOutside = (event: Event) => {
    if (
      filtersOpen &&
      WBShowFiltersRef.current &&
      whiteboardToolbarFilter &&
      !WBShowFiltersRef.current.contains(event.target as Node) &&
      !whiteboardToolbarFilter.contains(event.target as Node)
    ) {
      dispatch(whiteboardActions.toggleFiltersOpen())
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  useEffect(() => {
    if (whiteboardToolbar) {
      setWBToolbarHeight(whiteboardToolbar.offsetHeight)
      setWBToolbarWidth(whiteboardToolbar.offsetWidth)
    }
  }, [whiteboardToolbar, width])

  return (
    <BackgroundLayer
      filtersOpen={filtersOpen}
      WBToolbarHeight={WBToolbarHeight}
      WBToolbarWidth={WBToolbarWidth}
    >
      <FiltersWrapper ref={WBShowFiltersRef}>
        <Header>
          <Title>{t('GenericWords.filters')}</Title>
          <Reset onClick={() => dispatch(whiteboardActions.resetWhiteboardsGridFilters())}>
            {t('Afa.resetAllFilters')}
          </Reset>
        </Header>
        <WBCategories>
          <CategoriesList>
            {Categories.slice()
              .filter(category => {
                if (whiteboardSwitchSelection === 'whiteboard') {
                  return category !== 'createdBy'
                } else {
                  return category
                }
              })
              .map(category => {
                return (
                  <WBCategoriesItem
                    key={category}
                    onClick={() => setSelectedCategory(category)}
                    className={selectedCategory === category ? 'selected' : ''}
                  >
                    <span>{t(`Whiteboard.${category}`)}</span>
                    <RCIcon arrow type="right" />
                  </WBCategoriesItem>
                )
              })}
          </CategoriesList>
          <OptionsList>
            {selectedCategory &&
              whiteboardsGrid &&
              Object.values(createFilters(whiteboardsGrid)[selectedCategory]).map(filter => {
                let filterLabel
                if (selectedCategory === 'season') {
                  const filter_ = getSeasonCodeFromReleaseCode(filter)
                  filterLabel = filter_
                    ? t(`Whiteboard.filters.${convertStringToCamelCase(filter_)}`)
                    : filter
                } else {
                  filterLabel = t(`Whiteboard.filters.${convertStringToCamelCase(filter)}`)
                }

                const checked = !!filtersApplied
                  .find(({ category }) => category === selectedCategory)
                  ?.filters.includes(filter)

                return (
                  <WBOptionCategory
                    onClick={() =>
                      dispatch(
                        whiteboardActions.setWhiteboardsGridFilters({ selectedCategory, filter }),
                      )
                    }
                    checked={checked}
                    key={filter}
                  >
                    <div />
                    <span>{filterLabel}</span>
                  </WBOptionCategory>
                )
              })}
          </OptionsList>
          <WBShowResults>
            <div onClick={() => dispatch(whiteboardActions.toggleFiltersOpen())}>
              {t('Afa.showResults')}
              {!!filtersApplied.length && <Results>{`(${filteredWhiteboardsLength})`}</Results>}
            </div>
          </WBShowResults>
        </WBCategories>
      </FiltersWrapper>
    </BackgroundLayer>
  )
}

export default WhiteboardFilters
