import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { closeFullscreen360 } from '../../store/pdp/actions'
import {
  fullscreen360CatalogImages,
  fullscreen360CodeSelector,
  fullscreen360VisibleSelector,
} from '../../store/pdp/selectors'
import { palette } from '../../style/theme'
import ImageFullOr360 from '../ImageFullOr360'
import RCIcon from '../UI/RCIcon'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  background: ${palette.white};
  z-index: 10000000;
  height: 100%;
  padding-bottom: 3vh;

  .image-full-or-360 {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icon-360 {
      width: 4.1vw;
      height: 4.1vw;
    }
  }
`

const CloseBtn = styled.button`
  position: absolute;
  bottom: 1vh;
  right: 1vw;
  border: 1px solid ${palette.alto};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 1em;
  color: ${palette.congressBlue};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  .rc-icon {
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
  }
`

export const Fullscreen360 = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const visible = useSelector(fullscreen360VisibleSelector)
  const mocoCode = useSelector(fullscreen360CodeSelector)
  const catalogImages = useSelector(fullscreen360CatalogImages)

  const close = () => {
    dispatch(closeFullscreen360())
  }

  if (!visible || !mocoCode || !catalogImages) return <></>

  return (
    <Wrapper data-testid="fullscreen-360-wrapper">
      <ImageFullOr360
        className=""
        mocoCode={mocoCode}
        fullscreen={true}
        catalogImages={catalogImages}
      />
      <CloseBtn onClick={close}>
        <RCIcon type="close" />
        {t('GenericWords.close')}
      </CloseBtn>
    </Wrapper>
  )
}

export default Fullscreen360
