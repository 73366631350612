import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import Chip from '../../components/Chip/Chip'
import ClockIcon from '../../components/icons/ClockIcon'
import JoinUsButton from '../../components/JoinUsButton/JoinUsButton'
import useFetchAppointments from '../../hooks/useFetchAppointments'
import { getAppointmentToDisplay } from '../../libs/appointments'
import { appointmentsSelector } from '../../store/digitalEvents/selectors'
import { GlassBox } from '../../style/DigitalEventsCommonComponents'
import {
  breakpoints,
  breakpointsCross,
  getFluidFontSize,
  getFluidSize,
  palette,
  pxToRem,
  spacing,
} from '../../style/theme'

const Wrapper = styled(GlassBox)`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  padding: ${pxToRem(spacing(4.5))}rem ${pxToRem(spacing(6))}rem ${pxToRem(spacing(6))}rem;

  @media (max-width: ${breakpoints.M}) {
    padding: ${pxToRem(spacing(2))}rem;
  }
`

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;

  &.centered {
    justify-content: center;
  }
`

const LiveNow = styled.div`
  color: ${palette.white};
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.S}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Live = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin-right: 1em;

  &::before {
    content: '';
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    background-color: ${palette.red};
    margin-right: 0.5em;
  }
`

const LiveLabel = styled.div`
  opacity: 0.5;
`

const StartDate = styled.div`
  margin-right: 2em;
`

const StartTime = styled.div`
  display: flex;
  align-items: center;
  color: ${palette.white};

  svg {
    width: 1.5em;
    height: auto;
    margin-right: 0.5em;
  }
`

const Content = styled.div`
  flex: 1;
  font-size: ${getFluidFontSize('25px')};
  align-items: center;
  display: flex;
  @media (min-width: ${breakpointsCross.M.min}) {
    margin: ${getFluidSize('20px')} 0;
    font-size: 2.4rem;
  }
  &.centered {
    justify-content: center;
  }
`

const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  &.centered {
    justify-content: center;
  }
`

const HostChip = styled(Chip)`
  @media (max-width: ${breakpoints.S}) {
    display: none;
  }
`

const JoinAppointmetnBox: React.FC<{ brandCode?: string }> = ({ brandCode }) => {
  const { t } = useTranslation()
  const appointments = useSelector(appointmentsSelector)
  const [appointmentData, setAppointmentData] = useState(
    getAppointmentToDisplay(appointments || [], brandCode),
  )

  useFetchAppointments()

  useEffect(() => {
    setAppointmentData(getAppointmentToDisplay(appointments || [], brandCode))
  }, [appointments, brandCode])

  useEffect(() => {
    const interval = setInterval(() => {
      setAppointmentData(getAppointmentToDisplay(appointments || [], brandCode))
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [appointments, brandCode])

  const [appointment, timing] = appointmentData

  return (
    <Wrapper>
      {appointment && timing === 'running' && (
        <>
          <Header>
            <LiveNow>
              <Live>{t('Appointments.live')}</Live>{' '}
              <LiveLabel>{t('Appointments.thisEventIsLiveNow')}</LiveLabel>
            </LiveNow>
            <StartTime>
              <ClockIcon />
              {format(new Date(appointment.startDate), 'p')}
            </StartTime>
          </Header>
          <Content>{format(new Date(appointment.startDate), 'PP')}</Content>
          <Footer>
            {appointment.speaker && (
              <HostChip label={t('Appointments.host')} text={appointment.speaker} />
            )}
            <JoinUsButton appointment={appointment} />
          </Footer>
        </>
      )}
      {appointment && timing === 'just ended' && (
        <>
          <Header className="centered">
            <StartDate>{format(new Date(appointment.startDate), 'PP')}</StartDate>
            <StartTime>
              <ClockIcon />
              {format(new Date(appointment.startDate), 'p')}
            </StartTime>
          </Header>
          <Content className="centered">{t('Appointments.justEndedMsg')}</Content>
          <Footer className="centered">
            {appointment.speaker && (
              <HostChip label={t('Appointments.host')} text={appointment.speaker} />
            )}
          </Footer>
        </>
      )}
      {appointment && timing === 'future' && (
        <>
          <Header>
            <div></div>
            <StartTime>
              <ClockIcon />
              {format(new Date(appointment.startDate), 'p')}
            </StartTime>
          </Header>
          <Content>{format(new Date(appointment.startDate), 'PP')}</Content>
          <Footer>
            {appointment.speaker && (
              <HostChip label={t('Appointments.host')} text={appointment.speaker} />
            )}
          </Footer>
        </>
      )}
      {!appointment && (
        <>
          <Content>{t('Appointments.noAppointmentsMsg')}</Content>
        </>
      )}
    </Wrapper>
  )
}

export default JoinAppointmetnBox
