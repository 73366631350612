import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import app_config from '../../config/app/config'
import { useBarCodeReader } from '../../hooks/useBarCodeReader'
import { isUpcCode } from '../../libs/productsV2'
import { getDeviceAbsolutePath, isIPadView, isPageMatches } from '../../libs/url'
import { TDispatch } from '../../model/model'
import { end_video, hideSearch, searchCouvettes, showSearch } from '../../store/actions'
import { starsBrandsSelector } from '../../store/brands/selectors'
import { isSearchVisibleSelector } from '../../store/search/selectors'
import {
  breakpointsCross,
  getFluidFontSize,
  getFluidSize,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
  spacing,
  zIndex,
} from '../../style/theme'
import { AlphaNumericKeyboardInput } from '../KeyboardInput'
import Loading from '../Loading'
import StarsWholesaleSwitch from '../StarsWholesaleSwitch/StarsWholesaleSwitch'
import RCIcon from '../UI/RCIcon'
import RCLogo from '../UI/RedCarpetLogo'
import { errorNotification } from '../Notification/notifications'

const Wrapper = styled.div`
  position: fixed;
  top: 0vh;
  height: 100vh;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.92);
  border-radius: 0;
  left: 0;
  z-index: ${zIndex.searchModal};
`

const StarsWholesaleSwitchWithMargin = styled(StarsWholesaleSwitch)`
  margin-bottom: ${pxToRem(spacing(2))}rem;
`

export const SearchResultPlaceholder = styled.span`
  font-size: ${getFluidFontSize('14px')};
  top: ${getFluidSizeWithFullFormula('px', -18, 0, 1366, 3840)};
  position: absolute;
  left: 0;
  text-align: center;
  width: 100%;
`

export const ModalWrapperStyled = styled.div`
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: ${breakpointsCross.M.min}) {
    justify-content: flex-start;
    margin-top: 16vh;
  }
`

export const SearchInputContainerStyled = styled.div`
  width: 25rem;
  max-width: 100vw;
  min-width: 22vw;
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpointsCross.M.min}) {
    flex-direction: row-reverse;
    min-width: 35vw;
  }
  align-items: center;

  & .search-keyboard-input {
    z-index: ${zIndex.base};
    margin: ${pxToRem(spacing(1))}rem 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .keyboard-submit-button {
    color: transparent;
    background-image: url(${`${app_config.publicUrl}/assets/images/search.svg`});
    background-size: 38%;
    background-repeat: no-repeat;
    background-position: 50%;

    &:active {
      background-color: ${palette.denim};
    }

    &:after {
      content: '';
    }
  }

  & .red-carpet-logo {
    position: absolute;
    top: 2vh;
    left: 2vh;
  }

  & .ant-input {
    display: block;
    width: 100%;
    height: 3.6vh;
    min-height: ${getFluidSize('30px')};
    border: ${pxToRem(spacing(0.25))}rem solid ${palette.alto};
    border-radius: ${pxToRem(spacing(1))}rem;
    color: ${palette.biscay};
  }
`

export const ClearButtonContainer = styled.div`
  font-size: ${getFluidFontSize('14px')};
  color: ${palette.biscay};
  text-transform: uppercase;
  background: ${palette.white};
  box-shadow: 0 1px ${pxToRem(spacing(1))}rem 0 rgba(0, 0, 0, 0.2);
  border-radius: ${pxToRem(spacing(1))}rem;
  padding: 5px 10px;
  z-index: ${zIndex.base};
  width: auto;
  align-self: flex-end;
  display: flex;
  align-items: center;
  @media (max-width: ${breakpointsCross.M.min}) {
    align-self: flex-start;
    margin: 0.5rem 0;
  }
`

const SearchResult = styled.div`
  z-index: 10;
  position: relative;
  text-align: center;
  width: 100%;

  .search-result-placeholder {
    position: absolute;
    top: ${getFluidSizeWithFullFormula('px', -26, 0, 1024, 3840)};
    width: 100%;
  }
`

const ModalProductSearch: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const [productsFound, setProductsFound] = useState<boolean | undefined>(undefined)
  const [searching, setSearching] = useState(false)
  const isAfa = window.location.href.includes('afa')

  const dispatch: TDispatch = useDispatch()

  const { t } = useTranslation()

  const isSearchVisible = useSelector(isSearchVisibleSelector)
  const starsBrands = useSelector(starsBrandsSelector)

  useBarCodeReader((reading?: string) => {
    if (reading) {
      setSearchQuery(reading)

      if (!isSearchVisible) {
        dispatch(showSearch())
      }

      dispatch(searchCouvettes(reading))
    }
  }, isAfa)

  const closeModal = () => {
    setSearchQuery('')
    dispatch(hideSearch())
    dispatch(end_video())
    setProductsFound(undefined)
  }

  const history = useHistory()

  const isChooseBrandPage = isPageMatches('choose-brand')

  const isStarsCustomer = !!starsBrands.length

  return !isSearchVisible ? null : (
    <Wrapper data-testid="product-search-panel">
      {!isChooseBrandPage && !isIPadView() && <RCLogo />}
      <ModalWrapperStyled
        onClick={(e: React.MouseEvent) => {
          if (
            e.currentTarget?.classList.contains('search-result') ||
            e.currentTarget?.classList.contains('backdrop-spy')
          ) {
            closeModal()
          }
        }}
        className={'backdrop-spy'}
        data-testid="backdrop-spy"
      >
        <SearchResult>
          {searching ? (
            <Loading
              className="search-result-placeholder"
              tip={'Taking some time...'}
              isFullPage={false}
            />
          ) : productsFound === false ? (
            <SearchResultPlaceholder>
              {t('ModalProductSearch.item_no_products')}
            </SearchResultPlaceholder>
          ) : null}
        </SearchResult>
        <div onClick={e => e.stopPropagation()}>
          {isChooseBrandPage && isStarsCustomer && <StarsWholesaleSwitchWithMargin />}

          <SearchInputContainerStyled>
            {searchQuery.length > 0 && (
              <ClearButtonContainer
                onClick={() => {
                  setSearchQuery('')
                  closeModal()
                }}
              >
                <RCIcon type="close" />
                {t('GenericWords.cancel')}
              </ClearButtonContainer>
            )}
            <AlphaNumericKeyboardInput
              containerClassName="search-keyboard-input"
              placeholder={t('ModalProductSearch.input_search_placeholder')}
              value={searchQuery}
              isFirstLetterUppercase={false}
              uppercaseAfterSpace={false}
              showKeyboardOnInit={true}
              hidingKeyboardDisabled={true}
              onChange={setSearchQuery}
              onSubmit={() => {
                if (!searchQuery) {
                  return
                }

                setSearching(true)
                dispatch(searchCouvettes(searchQuery))
                  .then(({ productsFound, warnings }) => {
                    setSearching(false)
                    setProductsFound(productsFound)

                    if (warnings && warnings.length) {
                      warnings.forEach(warning => errorNotification({ message: t(warning) }))
                    }

                    if (!productsFound) {
                      return
                    }

                    closeModal()
                    if (!isUpcCode(searchQuery)) {
                      history.push(`${getDeviceAbsolutePath()}/products?searchQuery=${searchQuery}`)
                    }
                  })
                  .catch(() => {
                    setSearching(false)
                    setProductsFound(false)
                  })
              }}
              inputClassName="ant-input"
              validateInput={(inputVal: string) => {
                const {
                  errorTypes: { SEARCH_NOT_ENOUGH_CHARACTERS },
                  search: { minCharactersCount },
                } = app_config

                const errorMessage =
                  inputVal.length < app_config.search.minCharactersCount
                    ? t(SEARCH_NOT_ENOUGH_CHARACTERS, { count: minCharactersCount })
                    : null

                return {
                  hasError: !!errorMessage,
                  error: errorMessage,
                }
              }}
            />
          </SearchInputContainerStyled>
        </div>
      </ModalWrapperStyled>
    </Wrapper>
  )
}

export default ModalProductSearch
