import { flattenDeep, memoize, uniqBy } from 'lodash'

import { CartProduct } from '../../../../model/cart'
import { DislikeState } from '../../../../model/dislike'
import { Mocos } from '../../../../model/product'

export const getUniqueSizes = memoize((mocos: Mocos) => {
  const mocosArray = Object.values(mocos)
  const arrOfsizesForAllMocos = flattenDeep(mocosArray.map(el => Object.values(el.sizes)))
  let uniqueSizesKey = uniqBy(arrOfsizesForAllMocos, 'size')
    .map(el => el.size)
    .sort((a, b) => parseInt(a) - parseInt(b))
  if (!uniqueSizesKey) {
    uniqueSizesKey = []
  }
  return uniqueSizesKey
})

export const toggleDislikeAll = ({
  mocos,
  dislike,
  cartProducts,
  toggleDislike,
}: {
  mocos: Mocos
  dislike: DislikeState
  cartProducts: CartProduct[]
  toggleDislike: (
    items: { mocoCode: string; upcs: string[]; modelCode: string | undefined }[],
  ) => void
}) => {
  const mocoCodes = Object.values(mocos).map(m => m.mocoCode)

  const mocoCodesNotInCart = mocoCodes.filter(
    mocoCode => !cartProducts.find(product => product.mocoCode === mocoCode),
  )

  const itemsInDislike = dislike.items.filter(dislikeItem =>
    mocoCodesNotInCart.includes(dislikeItem),
  )

  const allItemsAreInDislike = mocoCodesNotInCart.length === itemsInDislike.length

  const mocoCodesToToggle = allItemsAreInDislike
    ? mocoCodes
    : mocoCodesNotInCart.filter(codeNotInCart => !itemsInDislike.includes(codeNotInCart))

  const payload = mocoCodesToToggle.map(mocoCode => {
    const moco = Object.values(mocos).find(m => m.mocoCode === mocoCode)
    const upcs = Object.keys(moco?.sizes || {})

    return {
      mocoCode,
      modelCode: moco?.modelCode,
      upcs,
    }
  })

  toggleDislike(payload)
}

export const defineIconsDisplayed = memoize((mocos: Mocos) => {
  const mocosArray = Object.values(mocos)
  let iconVto = false

  for (let i = 0; i < mocosArray.length; i++) {
    const hasVto = Object.values(mocosArray[i].sizes).some(s => !!s.hasVto)
    iconVto = iconVto || hasVto

    if (iconVto) break
  }

  return { iconVto }
})
