import { isAfter, isBefore, isSameDay } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Main, PageWrapper, ScrollContainer } from '../../style/DigitalEventsCommonComponents'
import CalendarSlider from './components/CalendarSlider'
import CalendarAppointment from './components/CalendarAppointment'
import DigitalEventsFooter from '../../components/DigitalEventsFooter/DigitalEventsFooter'
import DigitalEventsHeader from '../../components/DigitalEventsHeader/DigitalEventsHeader'
import useFetchAppointments from '../../hooks/useFetchAppointments'
import useFetchBackgrounds from '../../hooks/useFetchBackgrounds'
import { selectedAppointmentDate } from '../../store/digitalEvents/actions'
import {
  appointmentSelectedDateSelector,
  appointmentsSelector,
  calendarPageBackgroundSelector,
} from '../../store/digitalEvents/selectors'
import { Appointment } from '../../model/appointment'
import BackgroundVideo from '../../components/BackgroundVideo'

const MainWrapper = styled(Main)`
  display: grid;
  grid-template-columns: 1fr 6fr 1fr;
  column-gap: 30px;
  height: 100%;
  align-items: center;
  overflow: hidden;
  min-height: 400px;
`

const getAppointmentsForGivenDay = (day: Date, appointments: Appointment[] | null) =>
  appointments
    ?.filter(a => {
      const start = new Date(a.startDate)
      const end = new Date(a.endDate)
      return (
        isSameDay(day, start) || isSameDay(day, end) || (isBefore(start, day) && isAfter(end, day))
      )
    })
    .sort((a, b) => {
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    })

const CalendarPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const dateParam = new URLSearchParams(history.location.search).get('date')
  const [day, setDay] = useState<Date>(dateParam ? new Date(parseInt(dateParam)) : new Date())

  const appointmentSelectedDate = useSelector(appointmentSelectedDateSelector)
  const appointments = useSelector(appointmentsSelector)
  const background = useSelector(calendarPageBackgroundSelector)
  const selectedDayAppointments = getAppointmentsForGivenDay(day, appointments || [])

  useFetchBackgrounds()

  useFetchAppointments()

  useEffect(() => {
    if (appointmentSelectedDate) {
      setDay(new Date(parseInt(appointmentSelectedDate)))
      dispatch(selectedAppointmentDate(''))
    }
  }, [appointmentSelectedDate, dispatch])

  useEffect(() => {
    history.replace({
      search: `?date=${day.getTime().toString()}`,
    })
  }, [day, history])

  return (
    <PageWrapper background={background.image}>
      <BackgroundVideo background={background.video} />
      <ScrollContainer>
        <DigitalEventsHeader currentPage="calendar" />
        <MainWrapper>
          <CalendarSlider
            selectDate={day}
            onDayChange={(selectedDay: Date) => setDay(selectedDay)}
          />
          <CalendarAppointment selectedDayAppointments={selectedDayAppointments} />
        </MainWrapper>
        <DigitalEventsFooter />
      </ScrollContainer>
    </PageWrapper>
  )
}

export default CalendarPage
