import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteChildrenProps } from 'react-router-dom'

import Loading from '../../components/Loading'
import Fullscreen360 from '../../components/ModalFullscreen360'
import VtoModal from '../../components/VirtualMirrorModal'
import ModalContainer from '../../containers/ModalContainer'
import NotificationContainer from '../../containers/NotificationContainer'
import socketManager from '../../libs/socketManager'
import { isIpadOnlyRoom, isIPadView } from '../../libs/url'
import { showFooterModal } from '../../store/app/actions'
import { socketConnectionStatusSelector } from '../../store/notifications/selectors'
import { isVtoModalVisibleSelector } from '../../store/pdp/selectors'
import Routes from './Routes'
import { useEventAndCustomerCheck } from '../../hooks/useEventAndCustomerCheck'

const TableView: React.FC<RouteChildrenProps> = ({ match }) => {
  const showVtoModal = useSelector(isVtoModalVisibleSelector)
  const socketConnectionStatus = useSelector(socketConnectionStatusSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isIpadOnlyRoom()) {
      const activationVideoCb = () => dispatch(showFooterModal('activationVideo'))
      const discoveryVideoCb = () => dispatch(showFooterModal('discoveryVideo'))

      socketManager.bindListener('do_play_activation_video', activationVideoCb)
      socketManager.bindListener('do_play_discovery_video', discoveryVideoCb)

      return () => {
        socketManager.removeListener('do_play_activation_video', activationVideoCb)
        socketManager.removeListener('do_play_discovery_video', discoveryVideoCb)
      }
    }
  }, [dispatch])

  const loadedEventAndCustomer = useEventAndCustomerCheck()

  if (!loadedEventAndCustomer) {
    return <Loading />
  }

  return (
    <>
      <Routes match={match} />
      <ModalContainer match={match} />
      {!socketConnectionStatus.connected && <Loading />}
      <NotificationContainer />
      {showVtoModal && (isIPadView() || isIpadOnlyRoom()) && <VtoModal />}
      <Fullscreen360 />
    </>
  )
}

export default TableView
