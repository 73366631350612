import { format, formatDuration, intervalToDuration } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { getAppointmentTiming } from '../../libs/appointments'
import { Appointment } from '../../model/appointment'
import { appointmentsSelector } from '../../store/digitalEvents/selectors'
import { BoxTitle, GlassBox, Spin } from '../../style/DigitalEventsCommonComponents'
import {
  breakpoints,
  getFluidFontSize,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
  spacing,
} from '../../style/theme'
import ClockIcon from '../icons/ClockIconDE'
import JoinUsButton from '../JoinUsButton/JoinUsButton'

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`

const Title = styled(BoxTitle)`
  margin-bottom: ${pxToRem(spacing(2))}rem;
`

const Card = styled(GlassBox)`
  overflow: hidden;
  flex: 1;
  padding: ${pxToRem(spacing(6))}rem;

  @media (max-width: ${breakpoints.M}) {
    padding: ${pxToRem(spacing(4))}rem;
  }
`

const CardContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Content = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const EndedContent = styled(Content)`
  font-size: ${getFluidSizeWithFullFormula('px', 30, 72, 960, 1920)};
`

const Footer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`

const LabelFooter = styled.div`
  font-size: ${getFluidSizeWithFullFormula('px', 11, 13, 960, 1920)};
  opacity: 0.5;
  text-transform: uppercase;
`

const LiveNow = styled.div`
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: 1fr 5fr 1fr;
  gap: 1em;
  font-size: ${getFluidFontSize('17px')};

  @media (max-width: ${breakpoints.S}) {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    font-size: ${getFluidFontSize('14px')};
  }
`

const Live = styled.div`
  text-transform: uppercase;
  &::before {
    content: '';
    width: 0.35rem;
    height: 0.35rem;
    border-radius: 50%;
    background-color: ${palette.red};
    margin-right: 0.5em;
    display: inline-block;
    vertical-align: middle;
  }
`

const LiveLabel = styled.div`
  text-align: center;
`

const LiveTimer = styled.div`
  font-size: ${getFluidSizeWithFullFormula('px', 28, 55, 960, 1920)};
  font-weight: 300;
  padding-bottom: ${pxToRem(spacing(2))}rem;
  text-align: center;
`

const EndedTimer = styled(LiveTimer)`
  text-transform: uppercase;
  font-size: ${getFluidSizeWithFullFormula('px', 15, 36, 960, 1920)};
`

const StartDate = styled.div`
  text-transform: uppercase;
  margin-right: 2em;
`

const StartTime = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 1.5em;
    height: auto;
    margin-right: ${pxToRem(spacing(2))};
  }
`

const formatCountDown = (startDate: Date, endDate: Date) => {
  const duration = intervalToDuration({
    start: startDate,
    end: endDate,
  })

  return duration.days
    ? format(startDate, 'PP p')
    : formatDuration(duration, {
        format: ['hours', 'minutes', 'seconds'],
        delimiter: ' : ',
        zero: true,
        locale: {
          formatDistance: (_: string, value: number) => {
            return value < 10 ? `0${value}` : value
          },
        },
      })
}

const AppointmentStatus: React.FC<{ appointment?: Appointment }> = ({ appointment }) => {
  const { t } = useTranslation()
  const appointments = useSelector(appointmentsSelector)
  const loading = !appointments
  const [timing, setTiming] = useState(getAppointmentTiming(appointment))
  const [now, setNow] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => {
      setTiming(getAppointmentTiming(appointment))
      setNow(new Date())
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [appointment])

  return (
    <Container>
      <Title>{t('Appointments.upcomingEvent')}</Title>
      <Card>
        <CardContent>
          {appointment && timing === 'running' && (
            <>
              <Header>
                <LiveNow>
                  <Live>{t('Appointments.live')}</Live>{' '}
                  <LiveLabel>{t('Appointments.thisEventIsLiveNow')}</LiveLabel>
                </LiveNow>
              </Header>
              <Content>
                <LiveTimer>{formatCountDown(new Date(appointment.startDate), now)}</LiveTimer>
                <JoinUsButton appointment={appointment} />
              </Content>
              <Footer>
                <LabelFooter>{t('Appointments.infoDisable')}</LabelFooter>
              </Footer>
            </>
          )}
          {appointment && timing === 'future' && (
            <>
              <Header></Header>
              <Content>
                <LiveTimer>{formatCountDown(new Date(appointment.startDate), now)}</LiveTimer>
                <JoinUsButton appointment={appointment} />
              </Content>
              <Footer>
                <LabelFooter>{t('Appointments.infoDisable')}</LabelFooter>
              </Footer>
            </>
          )}
          {appointment && timing === 'just ended' && (
            <>
              <Header className="centered">
                <StartDate>{format(new Date(appointment.startDate), 'MMM d Y')}</StartDate>
                <StartTime>
                  <ClockIcon />
                  {format(new Date(appointment.startDate), 'p')}
                </StartTime>
              </Header>
              <Content className="centered">{t('Appointments.justEndedMsg')}</Content>
              <Footer></Footer>
            </>
          )}{' '}
          {appointment && timing === 'ended' && (
            <>
              <Header></Header>
              <EndedContent className="centered">{t('Appointments.endedMsg')}</EndedContent>
              <EndedTimer>{format(new Date(appointment.endDate), 'MMM d Y p')}</EndedTimer>
              <Footer></Footer>
            </>
          )}
          {!appointment && !loading && (
            <>
              <Content>{t('Appointments.noAppointmentsMsg')}</Content>
            </>
          )}
          {loading && (
            <Content>
              <LiveTimer>
                <Spin />
              </LiveTimer>
            </Content>
          )}
        </CardContent>
      </Card>
    </Container>
  )
}

export default AppointmentStatus
