import React from 'react'
import { Brand } from '../../../../model/brand'
import { useGetBrandLogo } from '../../../../services/afaContents'
import styled from 'styled-components/macro'
import { getFluidSizeWithFullFormula, palette } from '../../../../style/theme'
import { BrandLogoImage } from '../../../../style/CommonComponents'

const BrandLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  cursor: pointer;
  position: relative;
  height: 100%;
  user-select: none;

  padding: 0 3vw;

  &.disabled {
    opacity: 0.5;
  }

  &.selected {
    background-color: ${palette.white};
  }

  img {
    height: max(4vh, 25px);
    max-height: 100%;
  }

  border-radius: ${getFluidSizeWithFullFormula('px', 8, 12, 1024, 1920)};
  border: 2px solid ${palette.white};
`

const SelectedDot = styled.div`
  position: absolute;
  right: ${getFluidSizeWithFullFormula('px', 6.5, 10, 1024, 1920)};
  top: ${getFluidSizeWithFullFormula('px', 6.5, 10, 1024, 1920)};
  border-radius: 50%;
  height: ${getFluidSizeWithFullFormula('px', 6.5, 10, 1024, 1920)};
  width: ${getFluidSizeWithFullFormula('px', 6.5, 10, 1024, 1920)};
  background-color: ${palette.midnight};
`

const BrandLogo: React.FC<{
  brand: Brand
  onClick: () => void
  logoVariant?: 'standard' | 'alternative'
  selected: boolean
}> = ({ brand, onClick, logoVariant, selected }) => {
  const { code } = brand
  const { brandLogo, isSuccess } = useGetBrandLogo(code)
  return (
    <BrandLogoWrapper
      key={code}
      className={selected ? 'selected' : ''}
      onClick={() => {
        onClick()
      }}
    >
      {selected && <SelectedDot />}
      {brandLogo || !isSuccess ? (
        <BrandLogoImage
          className={logoVariant === 'alternative' ? 'alternative' : ''}
          src={brandLogo}
          alt={brand?.brand || code}
        />
      ) : (
        <span>{brand?.brand || code}</span>
      )}
    </BrandLogoWrapper>
  )
}

export default BrandLogo
