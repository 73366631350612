import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation, useRouteMatch } from 'react-router-dom'

import Fullscreen360 from '../../components/ModalFullscreen360'
import PageSocketManager, { PageData } from '../../components/PageSocketManager'
import VtoModal from '../../components/VirtualMirrorModal'
import ModalContainer from '../../containers/ModalContainer'
import NotificationContainer from '../../containers/NotificationContainer'
import { isAuthenticated } from '../../libs/auth'
import { isIpadOnlyRoom, isIPadView } from '../../libs/url'
import { changeLanguage } from '../../store/app/actions'
import { customerIdSelector } from '../../store/app/selectors'
import { logout } from '../../store/auth/actions'
import { tokenSelector } from '../../store/auth/selectors'
import { getCustomerData, setCustomerPricePreferences } from '../../store/customer/actions'
import { isVtoModalVisibleSelector } from '../../store/pdp/selectors'
import Routes from './Routes'
import { useParams } from 'react-router'

const pageEventsMap: Record<string, string | undefined> = {
  do_autologin: '/choose-brand',
  do_change_language: undefined,
  do_logout: '',
  do_update_price_prefs: undefined,
}

type Props = {
  pageData?: PageData | null
  socketIoEvent: string
}

const ExtraIPadView = ({ pageData, socketIoEvent }: Props) => {
  const [redirect, setRedirect] = useState<string>()
  const [allowMultipleEvent, setAllowMultipleEvent] = useState<boolean>(false)
  const { assortment } = useParams<{ assortment: string }>()

  const customerId = useSelector(customerIdSelector)
  const showVtoModal = useSelector(isVtoModalVisibleSelector)
  const token = useSelector(tokenSelector)

  const dispatch = useDispatch()
  const location = useLocation()
  const match = useRouteMatch()

  const triggerActionOnEvent = useCallback(
    (socketIoEvent: string): boolean => {
      let allowMultipleEvent = true

      switch (socketIoEvent) {
        case 'do_logout':
          dispatch(logout())
          allowMultipleEvent = false
          break
        case 'do_update_price_prefs':
          dispatch(setCustomerPricePreferences(pageData?.pricePrefs))
          break
        case 'do_change_language':
          dispatch(changeLanguage(pageData?.langCode))
          break
        default:
          break
      }

      return allowMultipleEvent
    },
    [dispatch, pageData?.langCode, pageData?.pricePrefs],
  )

  useEffect(() => {
    const didReceiveSocketEvent = Object.keys(pageEventsMap).includes(socketIoEvent)

    if (redirect && didReceiveSocketEvent) {
      // Ipad has to follow the table redirections just for some routes,
      // instead for the others it has to be independent. This is the reason why
      // we have to clean the redirect value after the redirection has happened.
      setRedirect(undefined)
    } else {
      if (allowMultipleEvent) {
        setAllowMultipleEvent(triggerActionOnEvent(socketIoEvent))
      }

      if (socketIoEvent) {
        if (socketIoEvent === 'do_autologin' && token) {
          dispatch(getCustomerData(pageData?.customerId, assortment || 'frames', token))
        }

        const redirectUrl = pageEventsMap[socketIoEvent]
        // redirectUrl is undefined when a socket event should not trigger any change of route
        if (redirectUrl !== undefined) {
          setRedirect(`${match.url}${redirectUrl}`)
        }
      }
    }
  }, [
    redirect,
    socketIoEvent,
    allowMultipleEvent,
    triggerActionOnEvent,
    token,
    dispatch,
    pageData?.customerId,
    match.url,
    assortment,
  ])

  if (redirect !== undefined && redirect !== location.pathname) {
    return <Redirect to={redirect} />
  }

  return (
    <Fragment>
      <Routes match={match} token={token} />
      {isAuthenticated(token) && customerId && (
        <Fragment>
          <ModalContainer match={match} />
          <NotificationContainer />
          {showVtoModal && (isIPadView() || isIpadOnlyRoom()) && <VtoModal />}
          <Fullscreen360 />
        </Fragment>
      )}
    </Fragment>
  )
}

const ExtraIPadViewManager = PageSocketManager(ExtraIPadView, Object.keys(pageEventsMap))
export default ExtraIPadViewManager
