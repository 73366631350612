import { AfaMoco, AfaProduct } from '../model/afa'
import { WhiteboardItemType, WhiteboardProductType } from '../model/whiteboard'

export const convertPxToVw = (px: number) => (100 * px) / window.innerWidth

export const convertVwToPx = (vw: number) => (window.innerWidth * vw) / 100

export const roundNumber = (num: number) => Number(num.toFixed(4))

export const getItemCenter = (
  item: WhiteboardItemType,
  type: 'horizontal center' | 'vertical center',
) =>
  roundNumber(
    (2 * item.settings.position[type === 'horizontal center' ? 'y' : 'x'] +
      item.settings.style[type === 'horizontal center' ? 'height' : 'width']) /
      2,
  )

export const convertPercentageToPx = (perc: number, fromHeight?: boolean) => {
  const board = document.getElementById('board')
  //TODO: remove condition
  if (board) {
    const { width, height } = board.getBoundingClientRect()
    return Number(((perc * (!fromHeight ? width : height)) / 100).toFixed(3))
  } else return Number(perc.toFixed(3))
}

export const convertPxToPercentage = (px: number | string, fromHeight?: boolean) => {
  const board = document.getElementById('board')
  const dimension = typeof px === 'string' ? Number(px.replace('px', '')) : px
  //TODO: remove condition
  if (board) {
    const { width, height } = board.getBoundingClientRect()
    return (dimension * 100) / (!fromHeight ? width : height)
  } else return dimension
}

export const convertVwToNumber = (vw: string) => Number(vw.replace('vw', ''))

export const convertPxToNumber = (vw: string) => Number(vw.replace('px', ''))

export const normalizeData = (data: WhiteboardProductType[]) => {
  type Dictionary = {
    [category: string]: WhiteboardProductType[]
  }

  const itemsDictionary = data.reduce((res, product) => {
    const { category } = product.product

    return {
      ...res,
      [category]: [...(res[category] || []), product],
    }
  }, {} as Dictionary) as Dictionary

  return Object.entries(itemsDictionary).map(([category, items]) => ({
    category,
    items,
  }))
}

export const generateSkuCode = (products: AfaProduct[]) => {
  let skuCode = ''
  if (products.length) {
    const firstMocosObject = Object.values(products[0].mocos)[0]
    skuCode = `${firstMocosObject.modelCode}-${firstMocosObject.colorCode}`
  }
  return skuCode
}

export const generateSkuCodeAfaMoco = (products: AfaMoco) => {
  let skuCode = ''
  if (products) {
    skuCode = `${products.modelCode}-${products.colorCode}`
  }
  return skuCode
}
