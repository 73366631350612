import { toInteger } from 'lodash'
import React, { createRef, CSSProperties, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Module } from '../../../model/stars'
import { starsAssormentModuleProductsSelector } from '../../../store/stars/selectors'
import { breakpoints, getFluidFontSizeDe, palette, pxToRem, spacing } from '../../../style/theme'
import { scrollbarCss } from '../../CommonComponents'
import RCIcon from '../../UI/RCIcon'
import CouvetteStarsModuleItem from '../CouvetteStarsModuleItem'
import { couvetteStarsModulePadding } from '../index'

const CouvetteModule = styled.div<{
  lastHeader: number
}>`
  background-color: ${palette.white};
  border-top-left-radius: ${pxToRem(spacing(1))}rem;
  border-top-right-radius: ${pxToRem(spacing(1))}rem;
  grid-template-rows: auto 1fr;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
    'header header-in header-out'
    'body body-in body-out';

  .couvette-stars-module-header:first-of-type {
    border-top-left-radius: ${pxToRem(spacing(1))}rem;
  }

  .couvette-stars-module-header:nth-of-type(${({ lastHeader }) => lastHeader}) {
    border-top-right-radius: ${pxToRem(spacing(1))}rem;
  }
`

const ModuleHeader = styled.div<{
  gridArea: string
  backgroundColor: string
  inOutTextPadding?: boolean
}>`
  grid-area: ${({ gridArea }) => gridArea};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-bottom: solid 1px ${palette.athensGray};
  padding-left: ${({ inOutTextPadding }) => pxToRem(spacing(inOutTextPadding ? 6 : 4))}rem;
  height: ${pxToRem(spacing(14.5))}rem;
  font-size: ${pxToRem(spacing(3))}rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: ${palette.white};

  @media (max-width: ${breakpoints.L}) {
    height: ${pxToRem(spacing(7.25))}rem;
    font-size: ${pxToRem(spacing(1.5))}rem;
  }
`

const ModuleBodyGrid = styled.div<{
  columns: number
}>`
  ${scrollbarCss}
  &::-webkit-scrollbar-track {
    background: ${palette.white};
  }
  &::-webkit-scrollbar-track-piece {
    background: ${palette.white};
    border: 1px solid ${palette.white};
  }
  height: 100%;
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  grid-auto-rows: calc(100% / 3 - (${pxToRem(spacing(4.5))}rem / 1.5));
  width: ${({ columns }) => pxToRem(spacing(columns * 80.2))}rem;
  padding: ${pxToRem(spacing(4.5))}rem;
  grid-column-gap: ${pxToRem(spacing(4.5))}rem;
  grid-row-gap: ${pxToRem(spacing(4.5))}rem;

  @media (max-width: ${breakpoints.L}) {
    grid-auto-rows: calc(100% / 3 - (${pxToRem(spacing(2.25))}rem / 1.5));
    width: ${({ columns }) => pxToRem(spacing(columns * 40.1))}rem;
    padding: ${pxToRem(spacing(2.25))}rem;
    grid-column-gap: ${pxToRem(spacing(2.25))}rem;
    grid-row-gap: ${pxToRem(spacing(2.25))}rem;
  }

  @media (max-height: ${breakpoints.XS}) {
    grid-auto-rows: calc(50% - (${pxToRem(spacing(2.25))}rem / 2));
  }
`

const EmptyModule = styled.div`
  color: ${palette.mineShaft};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${getFluidFontSizeDe(15, 25)};
`

const ModuleBodyWrapper = styled.div<{
  gridArea: string
}>`
  overflow: hidden;
  grid-area: ${({ gridArea }) => gridArea};
  border-right: 1px solid ${palette.athensGray};
  position: relative;
  height: 100%;
  width: max-content;

  &:last-of-type {
    border-right: 0;
  }
`

const ModuleBodyArrow = styled.div`
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  background-color: ${palette.white};
  border-top: 1px solid ${palette.silver};
  display: flex;
  justify-content: center;
  align-items: center;

  .arrow-icon {
    height: ${pxToRem(spacing(4.5))}rem;
    width: ${pxToRem(spacing(4.5))}rem;
    opacity: 0.3;
    @media (max-width: ${breakpoints.L}) {
      height: ${pxToRem(spacing(2.25))}rem;
      width: ${pxToRem(spacing(2.25))}rem;
    }
  }
`

type ModuleBodyProps = {
  gridArea: string
  columns?: number
}

const ModuleBody: React.FC<ModuleBodyProps> = ({ gridArea, columns = 2, children }) => {
  const [arrowVisible, setArrowVisible] = useState(false)
  const ref = createRef<HTMLDivElement>()

  const handleScroll = useCallback(() => {
    if (ref.current) {
      const isNotScrolledBottom =
        ref.current.scrollTop + ref.current.clientHeight !== ref.current.scrollHeight
      isNotScrolledBottom !== arrowVisible && setArrowVisible(isNotScrolledBottom)
    }
  }, [arrowVisible, ref])

  useEffect(() => {
    handleScroll()
  }, [handleScroll])

  return (
    <ModuleBodyWrapper gridArea={gridArea}>
      <ModuleBodyGrid columns={columns} ref={ref} onScroll={handleScroll}>
        {children}
      </ModuleBodyGrid>
      {arrowVisible && (
        <ModuleBodyArrow
          onClick={() => {
            ref.current &&
              ref.current.scrollTo({
                top: ref.current.scrollTop + ref.current.clientHeight / 3,
                behavior: 'smooth',
              })
          }}
        >
          <RCIcon arrow type="down" />
        </ModuleBodyArrow>
      )}
    </ModuleBodyWrapper>
  )
}

type ModuleProps = {
  module: Module
  style: CSSProperties
}

const CouvetteStarsModule: React.FC<ModuleProps> = ({ module, style }) => {
  const { t } = useTranslation()
  const { products, productsIn, productsOut } = useSelector(
    starsAssormentModuleProductsSelector(module),
  )
  const moduleName = `${module.familyName} - ${module.name}`

  return (
    <CouvetteModule
      lastHeader={1 + toInteger(!!productsIn.length) + toInteger(!!productsOut.length)}
      style={{
        ...style,
        width: toInteger(style?.width) - couvetteStarsModulePadding,
      }}
    >
      <ModuleHeader
        gridArea="header"
        className="couvette-stars-module-header"
        backgroundColor={palette.blueStars}
      >{`${module.familyName} - ${module.name}`}</ModuleHeader>
      {productsIn.length > 0 && (
        <ModuleHeader
          gridArea="header-in"
          className="couvette-stars-module-header"
          backgroundColor={palette.greenStarsIn}
          inOutTextPadding
        >
          {products.length === 0 && `${moduleName} - `}
          {t('Plp.in')}
        </ModuleHeader>
      )}
      {productsOut.length > 0 && (
        <ModuleHeader
          gridArea="header-out"
          className="couvette-stars-module-header"
          backgroundColor={palette.dustyGray}
          inOutTextPadding
        >
          {t('Plp.out')}
        </ModuleHeader>
      )}
      {products.length > 0 ? (
        <ModuleBody gridArea="body">
          {products.map(product => (
            <CouvetteStarsModuleItem key={product.upc} product={product} />
          ))}
        </ModuleBody>
      ) : (
        <ModuleBody gridArea="body">
          <EmptyModule>{t('Plp.emptyModule')}</EmptyModule>
        </ModuleBody>
      )}
      {productsIn.length > 0 && (
        <ModuleBody gridArea="body-in" columns={1}>
          {productsIn.map(
            product => product && <CouvetteStarsModuleItem key={product.upc} product={product} />,
          )}
        </ModuleBody>
      )}
      {productsOut.length > 0 && (
        <ModuleBody gridArea="body-out" columns={1}>
          {[...new Set(productsOut)].map(
            product => product && <CouvetteStarsModuleItem key={product.upc} product={product} />,
          )}
        </ModuleBody>
      )}
    </CouvetteModule>
  )
}

export default CouvetteStarsModule
