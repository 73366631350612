import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import app_config from '../../../config/app/config'
import { handleImageError } from '../../../libs/handleError'

const Image = styled.div`
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

type Props = {
  onClick?: () => void
  clickable: boolean
  imgPath?: string
  upc?: string
  className?: string
}

const ProductImage: React.FC<Props> = ({ onClick, clickable, imgPath, upc, className }) => {
  const imgOrPlaceholder = imgPath ? (
    <img
      data-testid="product-image"
      onClick={onClick}
      onError={handleImageError}
      src={imgPath}
      alt=""
      loading="lazy"
    />
  ) : (
    <img
      data-testid="product-image"
      onClick={onClick}
      onError={handleImageError}
      src={`${app_config.publicUrl}/assets/images/lens-placeholder.svg`}
      alt=""
      loading="lazy"
    />
  )
  const productClickable = clickable ? (
    <Link to={`/wall/product/${upc}`}>{imgOrPlaceholder}</Link>
  ) : (
    imgOrPlaceholder
  )

  return <Image className={['product-image', className].join(' ')}>{productClickable}</Image>
}

export default ProductImage
