import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled from 'styled-components'

import Loading from '../../components/Loading/index'
import { useGetProductQuery } from '../../services/afaProduct'
import {
  breakpoints,
  breakpointsCross,
  getFluidFontSize,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
} from '../../style/theme'
import AfaPdpColors from './AfaPdpColors'
import AfaPdpInfo from './AfaPdpInfo'
import AfaPdpNameAndImage from './AfaPdpNameAndImage'
import AfaPdpRelated from './AfaPdpRelated'
import AfaPdpTabSwitcher from './AfaPdpTabSwitcher'
import { useGetBrandCategoriesQuery } from '../../services/afa'

const Wrapper = styled.div`
  flex-grow: 1;
  color: ${palette.tangaroa};
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
`

const Model = styled.div`
  display: grid;
  grid-template-columns:
    minmax(179px, 10vw)
    ${getFluidSizeWithFullFormula('px', 579, 1606, 1366, 3840)}
    auto;
  gap: 2.5em;
  font-size: ${getFluidFontSize('16px')};
  padding-top: 2em;
  padding-left: 2em;
  padding-right: 2em;
  grid-template-rows: 100%;
  background-color: ${palette.wildSand};
  position: relative;
  height: 100%;
  width: 100%;
`

const RightCol = styled.div`
  padding-left: 2vw;
  height: 99%;
`

const RightColContent = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  height: 100%;
  gap: ${getFluidSizeWithFullFormula('px', 32, 200, 1920, 3840)};

  @media screen and (max-width: ${breakpointsCross.L.max}) {
    gap: ${pxToRem(32)}rem;
    flex-direction: column;
    justify-content: flex-start;
    max-width: ${getFluidSizeWithFullFormula('px', 423, 640, 1366, 3840)};
  }

  @media (min-width: ${breakpointsCross.M.over}) and (max-width: ${breakpointsCross.XL.max}) {
    gap: 1rem;
  }
`

const AfaPDPTabSwitcherContainer = styled.div`
  @media screen and (min-width: ${breakpoints.L}) {
    display: flex;
    justify-content: flex-end;

    > * {
      max-width: ${getFluidSizeWithFullFormula('px', 423, 640, 1366, 3840)};
    }
  }
`

const AfaPdpDetailsTab: React.FC = () => {
  const { modelCode } = useParams<{ modelCode: string }>()
  const { t } = useTranslation()

  const productQuery = useGetProductQuery(modelCode)
  const product = productQuery.data
  useGetBrandCategoriesQuery(product?.brandCodeParent || '')

  const colors = Object.values(product?.mocos || {})

  return (
    <Wrapper>
      <Model>
        {productQuery.isFetching && <Loading isFullPage={false} />}

        <AfaPdpColors colors={colors} />

        <AfaPdpNameAndImage product={product || undefined} />

        {product === null && !productQuery.isFetching && (
          <div>{t('Afa.NoProductFound', { modelCode })}</div>
        )}

        <RightCol>
          <RightColContent>
            <AfaPDPTabSwitcherContainer>
              <AfaPdpTabSwitcher />
            </AfaPDPTabSwitcherContainer>

            {product && <AfaPdpInfo product={product} />}
          </RightColContent>
        </RightCol>
      </Model>

      <AfaPdpRelated />
    </Wrapper>
  )
}

export default AfaPdpDetailsTab
