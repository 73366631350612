import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getDeviceAbsolutePath } from '../libs/url'
import { RootState, TDispatch } from '../model/model'
import { removeNotificationAction, showNotificationsAction } from '../store/notifications/actions'
import { notificationsSelector } from '../store/notifications/selectors'
import { isPdpVisibleSelector, isVtoModalVisibleSelector } from '../store/pdp/selectors'

let onOrientationChange:
  | undefined
  | (() => (dispatch: TDispatch, getState: () => RootState) => void)

const handleOrientationAlert = (dispatch: TDispatch, getState: () => RootState) => {
  const state = getState()
  const notifications = notificationsSelector(state)
  const isVtoModalVisible = isVtoModalVisibleSelector(state)
  const notificationShown = !!notifications.orientationAlert.find(
    ({ show }: { show: boolean }) => show,
  )
  const loginPath = getDeviceAbsolutePath()
  const skipNotification =
    window.location.pathname === loginPath ||
    window.location.pathname.includes('/customers') ||
    isVtoModalVisible

  if (window.device.portrait() && !skipNotification) {
    dispatch(showNotificationsAction('orientationAlert'))
  } else if (notificationShown) {
    onOrientationChange && window.removeEventListener('orientationchange', onOrientationChange)
    dispatch(
      removeNotificationAction({
        codes: [],
        id: 'orientationAlert',
        notificationType: 'orientationAlert',
      }),
    )
  }
}

const useOrientationAlert = () => {
  const dispatch = useDispatch()
  const isPdpPage = useSelector(isPdpVisibleSelector)
  const isHomePage =
    window.location.pathname.includes('/choose-brand') ||
    window.location.pathname === '/digital-events'
  const showAtLanding = isPdpPage || isHomePage

  useEffect(() => {
    if (window.device.desktop()) {
      return
    }
    if (showAtLanding && window.device.portrait()) {
      dispatch(showNotificationsAction('orientationAlert'))
    }

    onOrientationChange && window.removeEventListener('orientationchange', onOrientationChange)
    onOrientationChange = () => dispatch(handleOrientationAlert)
    window.addEventListener('orientationchange', onOrientationChange)

    return () =>
      onOrientationChange && window.removeEventListener('orientationchange', onOrientationChange)
  }, [dispatch, showAtLanding])
}

export default useOrientationAlert
