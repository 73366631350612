import { FC } from 'react'
import styled from 'styled-components'
import { useSearchParams } from '../../../../hooks/useSearchParams'
import { palette, pxToRem } from '../../../../style/theme'
import { useTranslation } from 'react-i18next'
import { getFluidSizeWithFullFormula as gF } from '../../../../style/theme'
import { useSelector } from 'react-redux'
import assortmentAdvisorSelectors from '../../../../store/assortmentAdvisor/selectors'
import { useGetDemographicsQuery } from '../../services'
import { useSendAnalytics } from '../../../../services/analytics'

const Wrapper = styled.div`
  background-color: #0c2644;
  border-radius: ${pxToRem(20)}rem;
  display: flex;
  flex-direction: row;
  height: ${gF('px', 62, 124, 1366, 3840)};
  padding: ${gF('px', 7, 14, 1366, 3840)};
  justify-content: center;
  align-items: center;
`

const Button = styled.div`
  cursor: pointer;
  border-radius: ${pxToRem(14)}rem;
  padding: ${gF('px', 14, 28, 1366, 3840)} ${gF('px', 20, 40, 1366, 3840)};
  color: ${palette.cornflowerBlueAA};
  font-size: ${gF('px', 17, 34, 1366, 3840)};
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    color: ${palette.blackPearl};
    background-color: ${palette.white};
  }
`

const Divider = styled.div`
  width: 1px;
  background-color: #5093f9;
  height: ${gF('px', 16, 32, 1366, 3840)};
`

type Props = {
  className?: string
}

const MapViewSwitcher: FC<Props> = ({ className }) => {
  const [sendAnalytics] = useSendAnalytics()

  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const searchedAddress = useSelector(assortmentAdvisorSelectors.searchedAddressSelector)
  const { currentData } = useGetDemographicsQuery(
    searchedAddress?.country.short || '',
    searchedAddress?.zipCode || '',
  )
  const mapView = searchParams.get('mapView') || 'trends'

  const hideDemographics =
    mapView !== 'demographics' &&
    currentData?.['Age'] === undefined &&
    currentData?.['Income'] === undefined

  const handleChangeTab = (newTab: 'trends' | 'demographics' | 'brands') => () => {
    if (newTab === 'brands') {
      sendAnalytics('MAP_BRANDS_ACCESS')
    }
    if (newTab === 'demographics') {
      sendAnalytics('MAP_DEMOGRAPHICS_ACCESS')
    }
    searchParams.set('mapView', newTab)
    setSearchParams(searchParams, { replace: true })
  }

  return (
    <Wrapper className={className}>
      <Button className={mapView === 'trends' ? 'active' : ''} onClick={handleChangeTab('trends')}>
        {t('AA.Map.Switcher.Trends')}
      </Button>
      {mapView === 'demographics' && <Divider />}
      <Button className={mapView === 'brands' ? 'active' : ''} onClick={handleChangeTab('brands')}>
        {t('AA.Map.Switcher.Brands')}
      </Button>
      {!hideDemographics && (
        <>
          {mapView === 'trends' && <Divider />}
          <Button
            className={mapView === 'demographics' ? 'active' : ''}
            onClick={handleChangeTab('demographics')}
          >
            {t('AA.Map.Switcher.Demographics')}
          </Button>
        </>
      )}
    </Wrapper>
  )
}

export default MapViewSwitcher
