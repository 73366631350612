import qs from 'qs'
import app_config from '../../config/app/config'
import { AppThunk, ModelCode, TDispatch } from '../../model/model'
import { customerIdSelector, eventIdSelector } from '../app/selectors'
import { slice } from './slice'
import { AfaProduct } from '../../model/afa'
import { afaCartIsLoadingSelector } from './selectors'
import i18n from '../../i18n'
import { staggeredBaseQueryWithBailOut } from '../../libs/services'
import { BaseQueryFn, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { errorNotification } from '../../components/Notification/notifications'

const loadMissingProductDetails = ({
  missingModelCodes,
  eventId,
  customerId,
  afawarehouse,
  dispatch,
  getState,
}: {
  missingModelCodes: string[]
  eventId: string
  customerId: string
  afawarehouse: string
  dispatch: TDispatch
  getState: () => unknown
}) => {
  const missingModelCodesNoDuplicates = [...new Set(missingModelCodes)]

  const chunkSize = 150
  const missingModelCodesChunks = []
  while (missingModelCodesNoDuplicates.length) {
    const chunk = missingModelCodesNoDuplicates.splice(0, chunkSize)
    missingModelCodesChunks.push(chunk)
  }

  return Promise.all(
    missingModelCodesChunks.map(missingModelCodesChunk => {
      const queryFn = staggeredBaseQueryWithBailOut(
        `${app_config.apiUrl}/afa/products`,
      ) as BaseQueryFn<{ url: string }, { products: AfaProduct[] }, FetchBaseQueryError>

      const controller = new AbortController()
      const signal = controller.signal

      return queryFn(
        {
          url: `search?${qs.stringify({
            eventId,
            customerId,
            afawarehouse,
            modelCodes: missingModelCodesChunk.join(','),
            rows: chunkSize,
          })}`,
        },
        {
          signal,
          dispatch,
          getState,
          endpoint: 'getCartProductDetails',
          type: 'query',
          extra: {},
        },
        {},
      )
    }),
  )
}

export const loadMissingAfaCartProductDetails = (
  missingModelCodes: ModelCode[],
  afawarehouse: string,
): AppThunk => (dispatch, getState) => {
  const state = getState()
  const isLoading = afaCartIsLoadingSelector(state)

  if (missingModelCodes.length && !isLoading) {
    dispatch(slice.actions.setIsLoading())

    const customerId = customerIdSelector(state)
    const eventId = eventIdSelector(state)

    loadMissingProductDetails({
      customerId,
      eventId,
      afawarehouse,
      missingModelCodes,
      dispatch,
      getState,
    }).then(queries => {
      const productDetails = queries.flatMap(({ data }) => data?.products || [])

      const productDetailsMap = productDetails.reduce((result, productDetail) => {
        result[productDetail.modelCode] = productDetail
        return result
      }, {} as Record<string, AfaProduct>)

      const cartProductDetails = missingModelCodes.reduce((result, modelCode) => {
        result[modelCode] = productDetailsMap[modelCode] || null
        if (!result[modelCode]) {
          errorNotification({
            message: i18n.t('Afa.Cart.missingCartProductDetails', { modelCode }),
          })
        }
        return result
      }, {} as Record<string, AfaProduct | null>)

      return dispatch(slice.actions.upsertData(cartProductDetails))
    })
  }
}

const afaCartActions = {
  ...slice.actions,
  loadMissingAfaCartProductDetails,
}

export default afaCartActions
