import React from 'react'

import LedView, { VideoNamesMap } from './LedView'

const LedRightView: React.FC = props => {
  const videoNamesMap: VideoNamesMap = {
    intro: 'intro',
    welcome: 'welcomeLeddx',
    navigation: 'nav_led_dx',
    activation: 'act_led_dx',
    multibrand: 'multibrandLeddx',
    digitalPreview: 'digitalPreviewLeddx',
  }
  return (
    <LedView {...props} category="1" revert={false} videoNamesMap={videoNamesMap} side="right" />
  )
}

export default LedRightView
