import { createSelector } from '@reduxjs/toolkit'

import { Brand } from '../../model/brand'
import { Door } from '../../model/customer'
import { RootState } from '../../model/model'
import { isKidCategoryModeEnabledSelector } from '../app/selectors'
import { activeDoorsSelector } from '../customer/selectors'
import { activePlpBrandsSelector, plpFiltersCategorySelector } from '../filters/selectors'
import { subBrandToCategoryIds } from '../../libs/couvettes'

export const brandsSliceSelector = (state: RootState) => state.brands

export const brandsSelector = (state: RootState) => state.brands.items

const activeDoorsIdsSelector = createSelector(activeDoorsSelector, activeDoors =>
  activeDoors.map(door => door.id),
)

export const normalBrandsSelector = createSelector(
  brandsSelector,
  activeDoorsIdsSelector,
  (allBrands, activeDoors) =>
    allBrands.filter(
      brand =>
        !(brand.starsDoorsIds || [])
          .concat((brand.subBrands || []).flatMap(({ starsDoorsIds }) => starsDoorsIds))
          .some(starsDoorId => activeDoors.includes(starsDoorId)),
    ),
)

export const starsBrandsSelector = createSelector(
  brandsSelector,
  activeDoorsIdsSelector,
  (allBrands, activeDoors) =>
    allBrands.filter(brand =>
      (brand.starsDoorsIds || [])
        .concat((brand.subBrands || []).flatMap(({ starsDoorsIds }) => starsDoorsIds))
        .some(starsDoorId => activeDoors.includes(starsDoorId)),
    ),
)

export const brandLoadingSelector = (state: RootState) =>
  state.brands.loading || !state.brands.loaded

export const brandsLoadedSelector = (state: RootState) =>
  state.brands.loaded && !state.brands.loading

export const subbrandToMainBrandMapSelector = createSelector(brandsSelector, brands =>
  brands.reduce((result, brand) => {
    brand.group.reduce((result, brandCodeInGroup) => {
      result[brandCodeInGroup] = brand.code
      return result
    }, result)
    return result
  }, {} as Record<string, string>),
)

export const juniorBrandsSelector = createSelector(brandsSelector, brands =>
  Object.values(brands).flatMap(({ juniorBrands }) => juniorBrands || []),
)

export const activePlpBrandCodesSelector = createSelector(
  brandsSelector,
  activePlpBrandsSelector,
  (brands, brandFilter) => {
    const activeBrandCodes = brands
      .filter(brand => brandFilter.active.includes(brand.slug))
      .map(({ code }) => code)

    return activeBrandCodes
  },
)

export const openedBrandsSelector = createSelector(
  brandsSelector,
  activePlpBrandCodesSelector,
  (brands, selectedBrandCodes) => {
    const currentSelectedBrand = brands.filter(
      b => selectedBrandCodes && b.group.some(code => selectedBrandCodes.includes(code)),
    )
    return currentSelectedBrand
  },
)

export const isOpenedBrandStarsSelector = createSelector(
  openedBrandsSelector,
  activeDoorsIdsSelector,
  plpFiltersCategorySelector,
  isKidCategoryModeEnabledSelector,
  (openedBrands, activeDoorsIds, categoryId, isJuniorMode) => {
    const brandsStarsDoorsIds = openedBrands.flatMap(({ subBrands }) => {
      const selectedSubBrand = subBrands.find(subBrand => {
        const categories = subBrandToCategoryIds(subBrand)
        return categoryId && categories.includes(categoryId) && subBrand.junior === isJuniorMode
      })
      return selectedSubBrand?.starsDoorsIds || []
    })

    return brandsStarsDoorsIds.some(doorId => activeDoorsIds.includes(doorId))
  },
)

export const isBrandActivationPlayingSelector = createSelector(openedBrandsSelector, openedBrands =>
  openedBrands.some(brand => brand.isBrandActivationPlaying),
)

export const isBrandActivationPlayedSelector = createSelector(openedBrandsSelector, openedBrands =>
  openedBrands.some(brand => brand.isBrandActivationPlayed),
)

export const brandHasActivationVideoSelector = createSelector(
  openedBrandsSelector,
  currentSelectedBrands => {
    if (!currentSelectedBrands.length) {
      return false
    }
    const brandHasActivationVideo = currentSelectedBrands.some(
      brand => brand.act_wall.type !== 'image',
    )
    return brandHasActivationVideo
  },
)

export const isBrandDiscoveryPlayingSelector = createSelector(openedBrandsSelector, openedBrands =>
  openedBrands.some(brand => brand.isBrandDiscoveryPlaying),
)

export const isBrandDiscoveryPlayedSelector = createSelector(openedBrandsSelector, openedBrands =>
  openedBrands.some(brand => brand.isBrandDiscoveryPlayed),
)

export const brandHasDiscoveryVideoSelector = createSelector(
  openedBrandsSelector,
  currentSelectedBrands => {
    if (!currentSelectedBrands.length) {
      return false
    }
    const brandHasDiscoveryVideo = currentSelectedBrands.some(
      brand => brand.discovery_wall.type !== 'image',
    )
    return brandHasDiscoveryVideo
  },
)

export const brandSelector = (brandSlug: string) =>
  createSelector(brandsSelector, (brands: Brand[]) => brands.find(({ slug }) => slug === brandSlug))

export const brandEnabledSelector = (brandCode: string) =>
  createSelector(
    brandsSelector,
    (brands: Brand[]) =>
      brands.find(({ code, group }) => code === brandCode || group.includes(brandCode))?.enabled,
  )

export const brandSelectorByCodes = (brandCodes: string[]) =>
  createSelector(brandsSelector, brands => brands.filter(brand => brandCodes.includes(brand.code)))

export const brandSelectorByCode = (brandCode: string) =>
  createSelector(brandsSelector, brands => brands.find(brand => brand.group.includes(brandCode)))

export const isStarsDoorSelector = createSelector(
  activePlpBrandCodesSelector,
  brandsSelector,
  (openedBrandCodes, brands) => (door: Door) => {
    // on the homepage a door is stars if it has at least one stars brand connected
    return openedBrandCodes.length > 0
      ? door.starsBrands && door.starsBrands.some(code => openedBrandCodes.includes(code))
      : !!brands.filter(({ subBrands }) =>
          door.starsBrands.some(brandCode => subBrands.find(({ code }) => code === brandCode)),
        ).length
  },
)

export const brandsByAssortmentSelector = (assortment: string) => (state: RootState) =>
  state.brands.items.filter(({ assortments }) => assortments.includes(assortment))
