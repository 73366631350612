import React, { useState } from 'react'
import { Button, CustomDatePicker } from '../CustomDatePicker'
import CalendarIconAfa from '../icons/CalendarIconAfa'
import { useTranslation } from 'react-i18next'
import { DdMmYyyyDateString } from '../../model/model'
import { addDays, format } from 'date-fns'
import styled from 'styled-components'
import { breakpoints, getFluidSizeWithFullFormula as gF, palette, pxToRem } from '../../style/theme'
import {
  Wrapper,
  ModalTitle,
  DeliveryDate,
  DeliveryAvailable,
  NextDelivery,
  TopDiv,
  ButtonsGroup,
  ButtonCancel,
  ButtonConfirm,
  ButtonSave,
  LineBreak,
  SetDeliveryDate,
  MergeDeliveries,
  BodyInfo,
} from '../AfaCommonComponents'
import { convertDateToDdMmYyyyString, convertDdMmYyyyToDate } from '../../libs/time'
import Loading from '../Loading'
import { isNotUndefined } from '../../libs/utils'
import { useSplitDeliveryDateMutation } from '../../services/afaCart'
import { useSelector } from 'react-redux'
import { languageSelector } from '../../store/app/selectors'
import { AfaCartProduct } from '../../model/afa'
import { getLaterMinDeliveryDateFromCartProducts, getMaxSplits } from '../../libs/cart'
import { Detail } from 'react-calendar'
import { errorNotification, successNotification } from '../Notification/notifications'
import { viewportSelector } from '../../store/viewport/selectors'
import { getSelectedKeys } from '../../store/afaCart/selectors'

const CustomWrapper = styled(Wrapper)`
  overflow-y: auto;
`

const SplitSection = styled(TopDiv)``
const SplitNumbers = styled(SetDeliveryDate)`
  font-family: GilmerMedium;
  letter-spacing: 0.9px;
`
const SplitInfo = styled(NextDelivery)`
  font-size: ${gF('px', 14, 20, 1366, 3840)};
  letter-spacing: 0.7px;
`
const Chevron = styled.div<{ showOptions: boolean }>`
  position: relative;
  left: 4px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' height='24px' width='24px' %3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  width: ${gF('px', 20, 30, 1366, 3840)};
  height: ${gF('px', 20, 30, 1366, 3840)};
  transform: ${props => props.showOptions && 'rotate(-180deg)'};
  transition: 100ms linear all;
`
const SplitDeliveryDate = styled(DeliveryDate)`
  font-family: GilmerBold;
  letter-spacing: 1.1px;
`

const SplitSelect = styled.div`
  position: relative;
  width: ${gF('px', 200, 435, 1366, 3840)};
  height: ${gF('px', 42, 68, 1366, 3840)};
  padding: 0.5em 1em;
  border-radius: 4px;
  border: 1px solid ${palette.black};
  margin-bottom: 3.5em;
  font-size: ${gF('px', 14, 24, 1366, 3840)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
`

const OptionsWrapper = styled.div`
  position: absolute;
  top: calc(100% + 2px);
  left: -1px;
  z-index: 999;
  border-radius: 10px;
  background-color: ${palette.white};
  border: 1px solid ${palette.black};
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`

const Option = styled.div`
  width: ${gF('px', 250, 485, 1366, 3840)};
  height: ${gF('px', 35, 60, 1366, 3840)};
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  user-select: none;

  &:hover {
    background-color: ${palette.mariner};
    color: ${palette.white};
    cursor: pointer;
  }
`

const SplitTitle = styled.span`
  color: ${palette.cloudBurst};
  font-size: ${pxToRem(24)}rem;
  font-family: GilmerMedium;
  margin-right: 1.5em;
  letter-spacing: 0.7px;

  @media screen and (max-width: ${breakpoints.L}) {
    font-size: ${pxToRem(14)}rem;
  }
`
const SplitList = styled.ul`
  width: 100%;
`

const ListItem = styled.li`
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  width: 100%;
`

const FakeCustomDatePicker = styled(Button)`
  background: ${palette.mischka};
  color: ${palette.santasGray};
  border: solid 1px ${palette.santasGray};
  cursor: unset;
`

type Props = {
  number: string
  selectedProducts: AfaCartProduct[]
  deliveryDate: DdMmYyyyDateString
  nextDeliveryDate?: DdMmYyyyDateString
  onCancel: () => void
}

const formatDate = 'dd/MM/uuuu'

const AfaCartSplitOrderModal: React.FC<Props> = ({
  number,
  selectedProducts,
  deliveryDate,
  nextDeliveryDate,
  onCancel,
}) => {
  const { t } = useTranslation()

  const [splitDates, setSplitDates] = useState<DdMmYyyyDateString[]>([])

  const maxSplits = getMaxSplits(selectedProducts)

  const disableDays = (date: Date, view: Detail) => {
    return (
      view === 'month' &&
      splitDates.some(
        splitDate =>
          date.getFullYear() === convertDdMmYyyyToDate(splitDate).getFullYear() &&
          date.getMonth() === convertDdMmYyyyToDate(splitDate).getMonth() &&
          date.getDate() === convertDdMmYyyyToDate(splitDate).getDate(),
      )
    )
  }

  const [showMergeInfo, setShowMergeInfo] = useState(false)

  const [splits, setSplits] = useState<number>(1)

  const [showOptions, setShowOptions] = useState(false)

  const minDeliveryDate = getLaterMinDeliveryDateFromCartProducts(selectedProducts, deliveryDate)

  const minSelectableSplitDate = addDays(convertDdMmYyyyToDate(deliveryDate), 1)

  const { width } = useSelector(viewportSelector)

  const setArrayDates = (date: Date, i: number) => {
    const dateString = convertDateToDdMmYyyyString(date)

    if (splitDates.length === i) {
      setSplitDates(splitDates => [...splitDates, dateString])
    } else {
      const newArray = splitDates.slice()
      newArray.splice(i, 1, dateString)
      setSplitDates(newArray)
    }
  }

  const setSplitsSelect = (value: number) => {
    setSplits(value)

    if (splitDates.length > value) {
      const newArray = splitDates.slice(0, value)
      setSplitDates(newArray)
    }
  }

  const [splitDeliveryDate, splitDeliveryDateResult] = useSplitDeliveryDateMutation()

  const language = useSelector(languageSelector)
  const locale = language.split('-')[0]
  const selectedKeys = useSelector(getSelectedKeys)
  const selectedKeysToSplit = selectedKeys[deliveryDate]

  return showMergeInfo ? (
    <MergeDeliveries>
      <ModalTitle>{t('Afa.mergeOrder')}</ModalTitle>
      <BodyInfo>
        {splitDeliveryDateResult.isLoading ? (
          <Loading isFullPage={false} />
        ) : (
          <>
            <div>{t('Afa.infoDeliverySet')}</div>
            {[...new Set(splitDeliveryDateResult.data?.warnings.map(({ message }) => message))]
              .map(message => {
                const match = message.match(/\d\d-\d\d-\d\d\d\d/)
                if (!match || !match.length) {
                  return undefined
                }
                const dateInMessage = match[0]
                const formattedDate = format(convertDdMmYyyyToDate(dateInMessage), formatDate)
                return formattedDate
              })
              .filter(isNotUndefined)
              .map((formattedDate, index) => (
                <div key={index}>{formattedDate}</div>
              ))}
            <div>{t('Afa.infoMergeDeliveries')}.</div>
          </>
        )}
      </BodyInfo>
      <ButtonsGroup>
        <ButtonConfirm
          disabled={splitDeliveryDateResult.isLoading}
          onClick={async () => {
            try {
              if (
                await splitDeliveryDate({
                  deliveryDateFrom: deliveryDate,
                  deliveryDateTo: splitDates,
                  itemsToSplit: selectedKeysToSplit,
                  check: false,
                })
              ) {
                successNotification({
                  message: t('Afa.successUpdateDelivery'),
                })
                onCancel()
              } else {
                errorNotification({
                  message: t('Afa.errorSplitDelivery'),
                })
                setShowMergeInfo(false)
              }
            } catch (err) {
              errorNotification({
                message: t('Afa.errorSplitDelivery'),
              })
            }
          }}
        >
          {t('GenericWords.ok')}
        </ButtonConfirm>
      </ButtonsGroup>
    </MergeDeliveries>
  ) : (
    <CustomWrapper>
      <TopDiv>
        <ModalTitle>{t('Afa.Cart.splitDelivery')}</ModalTitle>

        <SplitDeliveryDate>
          {t('Afa.delivery')} {number} - {format(convertDdMmYyyyToDate(deliveryDate), formatDate)}
        </SplitDeliveryDate>

        {minDeliveryDate && (
          <DeliveryAvailable>
            {t('Afa.deliveryAvailableFrom')}:{' '}
            {format(convertDdMmYyyyToDate(minDeliveryDate), formatDate)}
          </DeliveryAvailable>
        )}

        <LineBreak />

        <SplitSection>
          <SplitNumbers>{t('Afa.selectSplits')}</SplitNumbers>

          <SplitInfo>{t('Afa.splitInfo')}.</SplitInfo>

          <SplitSelect onClick={() => setShowOptions(!showOptions)}>
            <span>{splits + 1}</span>
            <Chevron showOptions={showOptions} />
            <OptionsWrapper>
              {showOptions &&
                new Array(maxSplits).fill(undefined).map((_, i) => (
                  <Option key={i + 1} onClick={() => setSplitsSelect(i + 1)}>
                    {i + 2}
                  </Option>
                ))}
            </OptionsWrapper>
          </SplitSelect>
        </SplitSection>

        <SetDeliveryDate>{t('Afa.setDeliveryDate')}</SetDeliveryDate>

        {nextDeliveryDate ? (
          <NextDelivery>
            {t('Afa.setNextDelivery')}:{' '}
            {format(convertDdMmYyyyToDate(nextDeliveryDate), formatDate)}
          </NextDelivery>
        ) : (
          <NextDelivery />
        )}

        <SplitList>
          <ListItem>
            <SplitTitle>
              {t('Afa.Cart.SplitOrder')
                .slice(0, 5)
                .toUpperCase()}{' '}
              1
            </SplitTitle>
            <FakeCustomDatePicker>
              <CalendarIconAfa />
              {format(convertDdMmYyyyToDate(deliveryDate), formatDate)}
            </FakeCustomDatePicker>
          </ListItem>
          {new Array(splits).fill(undefined).map((_, i) => {
            const splitString =
              t('Afa.Cart.SplitOrder')
                .slice(0, 5)
                .toUpperCase() +
              ' ' +
              (i + 2)

            return (
              <ListItem key={i}>
                <SplitTitle>{splitString}</SplitTitle>
                <CustomDatePicker
                  calendarIcon={<CalendarIconAfa />}
                  value={!splitDates[i] ? undefined : convertDdMmYyyyToDate(splitDates[i])}
                  minDetail={'decade'}
                  onChange={(date: Date) => setArrayDates(date, i)}
                  defaultActiveStartDate={minSelectableSplitDate}
                  format="dd/MM/yyyy"
                  monthPlaceholder={t('Afa.monthPlaceholder')}
                  dayPlaceholder={t('Afa.dayPlaceholder')}
                  yearPlaceholder={t('Afa.yearPlaceholder')}
                  minDate={minSelectableSplitDate}
                  locale={locale}
                  tileDisabled={({ date, view }) => disableDays(date, view)}
                  openingPosition={
                    width < parseInt(breakpoints.L) && splits > 2 ? 'top' : undefined
                  }
                />
              </ListItem>
            )
          })}
        </SplitList>
      </TopDiv>

      <ButtonsGroup>
        <ButtonCancel onClick={() => onCancel()}>
          {t('Checkout.ImportExport.ModalCancel')}
        </ButtonCancel>

        <ButtonSave
          disabled={splitDeliveryDateResult.isLoading || splitDates.length < splits}
          onClick={async () => {
            try {
              const splitResult = await splitDeliveryDate({
                deliveryDateFrom: deliveryDate,
                deliveryDateTo: splitDates,
                itemsToSplit: selectedKeysToSplit,
                check: true,
              })
              const mergeIsNeeded =
                ('data' in splitResult && !splitResult.data.result) ||
                ('data' in splitResult && !!splitResult.data.warnings.length) ||
                'error' in splitResult

              if (mergeIsNeeded) {
                setShowMergeInfo(true)
              } else {
                if (
                  await splitDeliveryDate({
                    deliveryDateFrom: deliveryDate,
                    deliveryDateTo: splitDates,
                    itemsToSplit: selectedKeysToSplit,
                    check: false,
                  })
                ) {
                  successNotification({
                    message: t('Afa.successUpdateDelivery'),
                  })
                  onCancel()
                } else {
                  errorNotification({
                    message: t('Afa.errorSplitDelivery'),
                  })
                  onCancel()
                }
              }
            } catch (err) {
              errorNotification({
                message: t('Afa.errorSplitDelivery'),
              })
            }
          }}
        >
          {t('Afa.saveChanges')}
        </ButtonSave>
      </ButtonsGroup>

      {splitDeliveryDateResult.isLoading && <Loading isFullPage={false} />}
    </CustomWrapper>
  )
}

export default AfaCartSplitOrderModal
