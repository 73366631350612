import React, { useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { breakpoints, duration, getFluidSizeWithFullFormula, palette, zIndex } from '../style/theme'
import CloseIcon from './icons/CloseIcon'

const CloseButton = styled.button`
  position: absolute;
  bottom: ${getFluidSizeWithFullFormula('px', 32, 88, 1366, 3840)};
  right: ${getFluidSizeWithFullFormula('px', 32, 88, 1366, 3840)};
  z-index: 1;

  @media (max-width: ${breakpoints.L}) {
    bottom: unset;
    top: ${getFluidSizeWithFullFormula('px', 32, 88, 1366, 3840)};
  }
`

const CloseIconStyled = styled(CloseIcon)`
  font-size: ${getFluidSizeWithFullFormula('px', 24, 48, 1366, 3840)};
  width: 1em;
  height: 1em;
  fill: ${palette.tangaroa};
`

const FADE_IN_CLASS = 'in'

const DURATION = duration.shortest

const Dialog = styled.dialog`
  border-radius: 10px;
  max-width: min(3245px, 95%);
  max-height: min(1760px, 95%);
  width: ${getFluidSizeWithFullFormula('px', 1082, 2776, 1366, 3840)};
  min-height: ${getFluidSizeWithFullFormula('px', 142, 542, 1366, 3840)};
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: ${palette.white};
  position: static;
  border: none;
  display: none;
  padding: ${getFluidSizeWithFullFormula('px', 32, 88, 1366, 3840)};
  overflow: hidden;

  &.fullscreen {
    width: 95%;
    height: 95%;
  }

  &&::backdrop {
    z-index: ${zIndex.afaPdpModal};
    background-color: rgba(3, 20, 52, 0.3);
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &[open],
  &[open]::backdrop {
    display: block;
    opacity: 0;
    transition: opacity ${DURATION}ms;
  }

  &[open].${FADE_IN_CLASS}, &[open].${FADE_IN_CLASS}::backdrop {
    opacity: 1;
  }
`

type Props = {
  isOpen: boolean
  onRequestClose: () => void
  id?: string
  closeOnBackdropClick?: boolean
  fullscreen?: boolean
  className?: string
}

const AfaModal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  id,
  closeOnBackdropClick,
  fullscreen = true,
  children,
  className,
}) => {
  const dialogRef = useRef<
    // TS does not have the right interface for HTMLDialogElement
    HTMLDialogElement & {
      close: () => void
      open: boolean
      showModal: () => void
      show: () => void
    }
  >(null)

  const close = useCallback(() => {
    dialogRef.current?.classList.remove(FADE_IN_CLASS)

    setTimeout(() => {
      dialogRef.current?.classList.remove(FADE_IN_CLASS)

      if (dialogRef.current?.open) {
        dialogRef.current?.close()
      }
      if (isOpen) {
        onRequestClose()
      }
    }, DURATION)
  }, [isOpen, onRequestClose])

  useEffect(() => {
    if (!dialogRef.current) {
      return
    }

    if (isOpen) {
      !dialogRef.current.open && dialogRef.current.showModal()
      dialogRef.current.classList.add(FADE_IN_CLASS)
    } else {
      close()
    }
  }, [isOpen, close])

  return (
    <Dialog
      id={id}
      ref={dialogRef}
      onClick={e => {
        if (!dialogRef.current || !closeOnBackdropClick) {
          return
        }

        if (e.target === dialogRef.current) {
          close()
        }
      }}
      className={[fullscreen ? 'fullscreen' : undefined, className].join(' ')}
    >
      <CloseButton onClick={close}>
        <CloseIconStyled />
      </CloseButton>
      {children}
    </Dialog>
  )
}

export default AfaModal
