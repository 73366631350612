import { Icon } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { NotificationsStateArray } from '../../../model/notifications'
import { removeNotificationAction } from '../../../store/notifications/actions'
import { palette } from '../../../style/theme'
import {
  column1Style,
  NotificationButton,
  NotificationCloseButton,
  NotificationGrid,
  notificationPadding,
  TurquoiseNotification,
} from '../NotificationsCommonComponents'

const UndoNotificationColumn1 = styled(NotificationButton)`
  ${column1Style}
  border-right: 1px solid ${palette.elfGreen};
`

const UndoNotificationColumn2 = styled.span`
  padding: ${notificationPadding};
  text-transform: lowercase;
  padding-left: 20px;
`

const UndoNotificationModelCode = styled.span`
  text-transform: uppercase;
`

type Props = {
  message: string
  codes: string[]
  showCountdown?: boolean
  onUndo?: () => void
  onCountdownExpired?: () => void
  notificationType: keyof NotificationsStateArray
}

const UndoNotification: React.FC<Props> = ({
  message,
  codes,
  showCountdown,
  notificationType,
  onUndo,
  onCountdownExpired,
}) => {
  const countdownIntervalTimerStep = 1
  const countdownInterval = useRef<ReturnType<typeof setInterval> | null>(null)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [countdownValue, setCountdownValue] = useState(5)

  const removeNotification = useCallback(() => {
    if (!notificationType) return

    dispatch(removeNotificationAction({ codes, notificationType }))
  }, [codes, dispatch, notificationType])

  const onUndoHandler = () => {
    removeNotification()
    onUndo && onUndo()
  }

  const onCountdownExpiredHandler = useCallback(() => {
    removeNotification()
    onCountdownExpired && onCountdownExpired()
  }, [onCountdownExpired, removeNotification])

  useEffect(() => {
    if (countdownInterval.current) return

    countdownInterval.current = setInterval(() => {
      setCountdownValue(current => current - countdownIntervalTimerStep)
    }, countdownIntervalTimerStep * 1000)

    return () => {
      if (!countdownInterval.current) return
      clearInterval(countdownInterval.current)
    }
  }, [])

  useEffect(() => {
    if (countdownValue <= 0) {
      onCountdownExpiredHandler()
    }
  }, [countdownValue, onCountdownExpiredHandler])

  return (
    <TurquoiseNotification>
      <NotificationGrid>
        {codes.length > 0 && (
          <UndoNotificationColumn1 className="undo-notification__column1" onClick={onUndoHandler}>
            {t('Plp.undo')}
            {showCountdown && <span>&nbsp;{countdownValue}</span>}
          </UndoNotificationColumn1>
        )}
        <UndoNotificationColumn2>
          <UndoNotificationModelCode>{message}&nbsp;</UndoNotificationModelCode>
          {codes.length ? <span>{t('Plp.removeFromList')}</span> : null}
        </UndoNotificationColumn2>
        <NotificationCloseButton onClick={onCountdownExpiredHandler}>
          <Icon type="close" theme="outlined" />
        </NotificationCloseButton>
      </NotificationGrid>
    </TurquoiseNotification>
  )
}

export default UndoNotification
