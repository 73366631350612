import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { breakpoints } from '../../style/theme'

const TimeContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  font-weight: 100;
  font-size: 8rem;
  margin-bottom: 0.1em;
  line-height: 1;

  @media (max-width: ${breakpoints.M}) {
    font-size: 4rem;
  }

  @media (max-width: ${breakpoints.S}) {
    font-size: 2.5rem;
  }
`

const DateContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  font-size: 1.1rem;
  gap: 2em;
  text-transform: uppercase;
  place-content: center;

  @media (max-width: ${breakpoints.S}) {
    font-size: 0.75rem;
  }
`

const Clock: React.FC = () => {
  const [time, setTime] = useState(new Date())
  const interval = useRef<NodeJS.Timeout | number>()
  useEffect(() => {
    interval.current = setInterval(() => {
      setTime(new Date())
    }, 1000)

    return () => {
      clearInterval(interval.current as number)
    }
  }, [])

  return (
    <div className="clock">
      <TimeContainer>
        <div className="clock__hours">{format(time, 'HH')}</div>
        <div className="clock__divider">:</div>
        <div className="clock__minutes">{format(time, 'mm')}</div>
      </TimeContainer>
      <DateContainer>
        <div className="clock_month">{format(time, 'MMM')}</div>
        <div className="clock_day">{format(time, 'dd')}</div>
        <div className="clock_day-of-week">{format(time, 'EEE')}</div>
      </DateContainer>
    </div>
  )
}

export default Clock
