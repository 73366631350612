import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import { getFluidSizeWithFullFormula, palette } from '../../../style/theme'

const Wrapper = styled.div`
  height: ${getFluidSizeWithFullFormula('px', 29, 48, 1366, 3840)};
  width: ${getFluidSizeWithFullFormula('px', 51, 90, 1366, 3840)};
`

const ToggleWrapper = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: rgba(68, 138, 244, 0.18);
  border-radius: ${getFluidSizeWithFullFormula('px', 32, 48, 1366, 3840)};
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  transition: 1s transform linear;

  &.active {
    background-color: ${palette.blue};
  }

  span {
    display: inline-block;
    left: ${getFluidSizeWithFullFormula('px', 4, 6, 1366, 3840)};
    top: ${getFluidSizeWithFullFormula('px', 3, 5, 1366, 3840)};
    position: absolute;
    height: ${getFluidSizeWithFullFormula('px', 23, 38, 1366, 3840)};
    width: ${getFluidSizeWithFullFormula('px', 23, 38, 1366, 3840)};
    border-radius: 50%;
    background-color: ${palette.cornflowerBlueAA};
    transition: 0.2s transform linear;

    &.active {
      background-color: ${palette.white};
      transform: translateX(${getFluidSizeWithFullFormula('px', 21, 40, 1366, 3840)});
    }
  }
`

const Toggle: React.FC<{ className?: string; active: boolean; action: () => void }> = ({
  className,
  active,
  action,
}) => {
  return (
    <Wrapper className={className}>
      <ToggleWrapper onClick={action} className={classnames({ active })}>
        <span className={classnames({ active })} />
      </ToggleWrapper>
    </Wrapper>
  )
}

export default Toggle
