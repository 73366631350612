import { useDispatch, useSelector } from 'react-redux'

import app_config from '../config/app/config'
import { getDeliveryDate } from '../libs/cart'
import { convertUnixTimeTimestampToYYYYMMDDHHMMSS } from '../libs/time'
import { quantityExists } from '../libs/utils'
import { CartProduct, UpdateCartPayloadItem } from '../model/cart'
import { Size } from '../model/product'
import {
  setActiveBrandCodeAction,
  setMyShopLastAddedProductUpcs,
  updateCartAction,
} from '../store/actions'
import { getIsMassiveOrderEnabled } from '../store/app/selectors'
import { revertableUpdateCartAction } from '../store/cart/actions/updateCart'
import {
  cartProductsSelector,
  cartViewTypeSelector,
  myShopLastAddedProductUpcsSelector,
} from '../store/cart/selectors'
import { checkIsMainDoorEnabled } from '../store/customer/selectors'
import { errorNotification } from '../components/Notification/notifications'

const keepOnlySizesWhereDoorIsEnabled = (doorId: string, finalQuantity?: number | null) => (
  productSize: Pick<Size, 'enabledDoors' | 'doors' | 'starsDoors'>,
) => {
  const itemIsToDelete = finalQuantity === 0
  if (itemIsToDelete) {
    return true // always allow item delete
  }

  const doorEnabled =
    (productSize.enabledDoors && productSize.enabledDoors.length) ||
    (productSize.doors && productSize.doors.length)
      ? productSize.enabledDoors?.includes(doorId) || productSize.doors?.includes(doorId)
      : false
  return doorEnabled
}

export type UpdateCartPayload = {
  doors: string[]
  productSizes: Pick<
    Size,
    | 'enabledDoors'
    | 'doors'
    | 'starsDoors'
    | 'upc'
    | 'modelCode'
    | 'mocoCode'
    | 'colorCode'
    | 'size'
    | 'sizeLabel'
    | 'brandCode'
    | 'skuCode'
  >[]
  qntToAdd: number | null
  cartProducts: CartProduct[]
  finalQuantity?: number | null
  notes?: string
  deliveryDate?: string
  reference?: string
}

export const isUpdateCartPayload = (payload: any): payload is UpdateCartPayload => {
  return (
    typeof payload === 'object' &&
    'doors' in payload &&
    'productSizes' in payload &&
    'cartProducts' in payload &&
    Array.isArray(payload.productSizes) &&
    Array.isArray(payload.cartProducts)
  )
}

const createCartItemsArray = ({
  doorIds,
  productSizes,
  qntToAdd,
  cartProducts,
  finalQuantity,
  notes,
  deliveryDate,
  reference,
}: {
  doorIds: string[]
  productSizes: Pick<
    Size,
    | 'enabledDoors'
    | 'doors'
    | 'starsDoors'
    | 'upc'
    | 'modelCode'
    | 'mocoCode'
    | 'colorCode'
    | 'size'
    | 'sizeLabel'
    | 'brandCode'
    | 'skuCode'
  >[]
  qntToAdd: number | null
  cartProducts: CartProduct[]
  finalQuantity?: number | null
  notes?: string
  deliveryDate?: string
  reference?: string
}): UpdateCartPayloadItem[] => {
  const payloadItems = doorIds.reduce((existingPayloadItems, doorId) => {
    const newPayloadItems: UpdateCartPayloadItem[] = productSizes
      .filter(keepOnlySizesWhereDoorIsEnabled(doorId, finalQuantity))
      .map(productSize => {
        const cartProduct = cartProducts.find(
          cartProduct =>
            cartProduct.upc === productSize.upc &&
            cartProduct.doorId === doorId &&
            ['0', '1'].includes(cartProduct.status),
        )
        const baseQuantity = cartProduct ? cartProduct.quantity : 0

        const { modelCode, mocoCode, upc, colorCode, size, brandCode, skuCode } = productSize

        // TEMP
        const [modelCodeFallback, colorCodeFallback] = mocoCode.split('__')
        return {
          insertedAt: cartProduct
            ? cartProduct.insertedAt
            : convertUnixTimeTimestampToYYYYMMDDHHMMSS(Date.now(), 1000),
          upc,
          skuCode,
          modelCode: modelCode || modelCodeFallback, // TEMP
          mocoCode,
          colorCode: colorCode || colorCodeFallback, // TEMP
          size,
          brandCode: brandCode || '',
          doorId,
          quantity: quantityExists(finalQuantity) ? finalQuantity : baseQuantity + (qntToAdd || 0),
          notes: notes !== undefined ? notes : cartProduct?.notes,
          oldQnt: cartProduct ? cartProduct.quantity : 0,
          confirmedQuantity: 0,
          status: cartProduct?.status ? cartProduct?.status : '1',
          categoryId: cartProduct?.categoryId || '',
          deliveryDate: deliveryDate
            ? deliveryDate
            : cartProduct && cartProduct.deliveryDate
            ? getDeliveryDate(cartProduct?.deliveryDate, cartProduct?.minDeliveryDate)
            : undefined,
          reference: reference !== undefined ? reference : cartProduct?.reference || '',
          minDeliveryDate: cartProduct?.minDeliveryDate,
          requestStatus: 'waiting',
        }
      })

    return [...existingPayloadItems, ...newPayloadItems]
  }, [] as UpdateCartPayloadItem[])

  return payloadItems
}

export const useUpdateMyShopUpcs = () => {
  const dispatch = useDispatch()
  const cartViewType = useSelector(cartViewTypeSelector)
  const myShopLastAddedProductUpcs = useSelector(myShopLastAddedProductUpcsSelector)
  const cartProducts = useSelector(cartProductsSelector)

  const updateMyShopUpcs = ({
    productSizes,
    remove,
  }: {
    productSizes: Pick<
      Size,
      | 'enabledDoors'
      | 'doors'
      | 'starsDoors'
      | 'upc'
      | 'modelCode'
      | 'mocoCode'
      | 'colorCode'
      | 'size'
      | 'sizeLabel'
      | 'brandCode'
      | 'skuCode'
    >[]
    remove?: boolean
  }) => {
    if (cartViewType === app_config.viewType.wall) {
      const oldUpcs = myShopLastAddedProductUpcs || []
      const productUpcs = productSizes.map(({ upc }) => upc)
      let newUpcs

      if (remove) {
        newUpcs = oldUpcs.filter(upc => !productUpcs.includes(upc))
      } else {
        const filteredUpcs = productUpcs.filter(
          productUpc =>
            !cartProducts.find(({ upc }) => upc === productUpc) &&
            !myShopLastAddedProductUpcs?.includes(productUpc),
        )
        newUpcs = oldUpcs.concat(filteredUpcs)
      }

      dispatch(setMyShopLastAddedProductUpcs(newUpcs))
    }
  }

  return updateMyShopUpcs
}

export const useUpdateCart = () => {
  const dispatch = useDispatch()
  const updateMyShopUpcs = useUpdateMyShopUpcs()

  const updateCart = ({
    doors,
    productSizes,
    qntToAdd,
    cartProducts,
    finalQuantity,
    notes,
    deliveryDate,
    reference,
  }: UpdateCartPayload) => {
    const items = createCartItemsArray({
      doorIds: doors,
      productSizes,
      qntToAdd,
      cartProducts,
      finalQuantity,
      notes,
      deliveryDate,
      reference,
    })

    if (!items.length) {
      return
    }

    if (items.some(({ quantity }) => quantity > 0)) {
      const lastInserted = items.sort((a, b) => b.insertedAt - a.insertedAt)[0]
      dispatch(setActiveBrandCodeAction(lastInserted.brandCode || null))
    }
    updateMyShopUpcs({ productSizes, remove: finalQuantity === 0 })
    dispatch(updateCartAction(items))
  }
  return updateCart
}

// Do not use this function to update quantities, add/remove items from the cart since it doesn't update the myShop
export const useUpdateCartMetadata = () => {
  const dispatch = useDispatch()

  const updateCartDirect = (items: UpdateCartPayloadItem[]) => {
    if (!items.length) {
      return
    }

    dispatch(updateCartAction(items))
  }
  return updateCartDirect
}

export const useRevertableUpdateCart = () => {
  const dispatch = useDispatch()

  const revertableUpdateCart = (updateCartPayload: UpdateCartPayload, codes?: string[]) => {
    const safeCodes: string[] = !codes?.length
      ? updateCartPayload.productSizes.map(
          size => `${size.mocoCode}__${size.size}__${size.sizeLabel}`,
        )
      : codes

    dispatch(revertableUpdateCartAction(updateCartPayload, safeCodes))
  }

  return revertableUpdateCart
}

export const useUpdateCartOnlyIfMainDoorIsEnabled = () => {
  const isMainDoorEnabled = useSelector(checkIsMainDoorEnabled)
  const isMassiveOrderEnabled = useSelector(getIsMassiveOrderEnabled)

  const updateCartOnlyIfMainDoorIsEnabled = (updateCartCb: () => void) => {
    if (!isMainDoorEnabled && !isMassiveOrderEnabled) {
      const mainDoorDisabledNotification = document.querySelectorAll(
        '.rc-notification-main-door-disabled',
      )
      if (!mainDoorDisabledNotification.length) {
        errorNotification({
          className: 'rc-notification rc-notification-main-door-disabled',
          message: 'You must enable the Default Door inside Doors Menu to add products to Cart',
        })
      }
    } else {
      updateCartCb()
    }
  }
  return updateCartOnlyIfMainDoorIsEnabled
}
