import styled, { css } from 'styled-components'

import { scrollbarCss } from '../components/CommonComponents'
import { analyticsPadding, breakpoints, palette, pxToRem, spacing, zIndex } from './theme'

export const CartContent = styled.div`
  background: ${palette.white};
  overflow: auto;
  overflow-x: hidden;
  height: 68vh;
  width: 100%;

  .ReactVirtualized__Grid,
  .ReactVirtualized__List {
    ${scrollbarCss}
  }

  &.analytics {
    background: ${palette.white};
    padding: ${analyticsPadding};
    overflow-y: hidden;

    .ant-table-body {
      @media screen and (max-width: ${breakpoints.L}) {
        zoom: 0.5;
      }
      @media screen and (max-width: ${breakpoints.M}) {
        zoom: 0.75;
      }
    }

    .purchase-controls .couvette-dislike-btn {
      display: inline-flex;
    }

    .couvette-item__dislike-btn {
      display: none;
    }
  }
`

export const HeaderTitle = styled.span`
  text-transform: uppercase;
  letter-spacing: 0.012vw;
  font-size: 1rem;
`

export const productDetailsPadding = css`
  padding-left: min(55px, 2vw);
  padding-right: min(55px, 2vw);
`

export const DigitalEventsBackButton = styled.button`
  position: absolute;
  z-index: 1;
  right: 1.5rem;
  top: 1.5rem;
  min-width: 0;
  backdrop-filter: blur(25px);
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1) 33%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 3em;
  padding: 0;
  background-color: transparent;
  appearance: none;
  border: none;
  color: white;

  svg {
    width: 0.5rem;
    height: 0.5rem;
  }
`

export const HeaderWrapper = styled.header`
  background-color: ${palette.white};
  z-index: ${zIndex.header};
  width: 100%;
  height: var(--header-height);
  padding: ${pxToRem(spacing(3.5))}rem ${pxToRem(spacing(3.5))}rem;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px ${palette.mercury};

  @media (max-width: ${breakpoints.M}) {
    padding: ${pxToRem(spacing(1.3))}rem ${pxToRem(spacing(1.3))}rem ${pxToRem(spacing(1.3))}rem
      ${pxToRem(spacing(2))}rem;
  }

  @media (max-width: ${breakpoints.S}) {
    padding: ${pxToRem(spacing(2))}rem;
  }
`

export const BrandLogoImage = styled.img`
  &.alternative {
    filter: invert(1);
  }
`
