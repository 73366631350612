import styled from 'styled-components/macro'
import {
  breakpointsCross,
  getFluidSizeWithFullFormula as gfs,
  palette,
  pxToRem,
} from '../../../../style/theme'
import WBColorDoorsAndDrops from './WBColorDoorsAndDrops'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-grow: 1;
  display: block;
  padding: ${gfs('px', 32, 64, 1366, 3840)};
  background-color: #f8f7f7;
  overflow: hidden;
  height: 100vh;
`

export const Main = styled.main`
  flex-grow: 1;
  display: grid;
  overflow: hidden;
  grid-auto-rows: max-content max-content 1fr max-content;
  height: 100%;
`

export const Header = styled.div`
  margin-bottom: 1rem;
`

export const Title = styled.div`
  color: ${palette.tangaroa};
  font-size: ${gfs('px', 24, 64, 1366, 3840)};
  font-family: GilmerBold, sans-serif;
`

export const Subtitle = styled.div`
  font-size: ${gfs('px', 18, 40, 1366, 3840)};
  color: ${palette.santasGray};
  margin-bottom: 2rem;

  @media screen and (max-height: ${breakpointsCross.S.max}) {
    margin-bottom: ${pxToRem(5)}rem;
  }
`

export const Colors = styled.div`
  display: grid;
  grid-template-columns: ${gfs('px', 113, 226, 1366, 3840)} auto min-content;
  overflow: hidden;
  height: 100%;
`

export const ColorImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
  overflow: hidden;
`

export const ColorImages = styled(ColorImagesWrapper)`
  flex-grow: 1;
  overflow-y: auto;
  padding-top: ${gfs('px', 17, 30, 1366, 3840)};
  padding-right: ${gfs('px', 14, 21, 1366, 3840)};
`

export const SelectedColorPlaceholder = styled.div`
  background-color: white;
  color: ${palette.tangaroa};
  border: 2px solid ${palette.shadowBlue};
  border-left: none;
  border-bottom-right-radius: ${pxToRem(4)}rem;
  border-top-right-radius: ${pxToRem(4)}rem;
  min-height: ${gfs('px', 98, 206, 1366, 3840)};
`

export const ImageWrapper = styled.div`
  aspect-ratio: 1;
  background-color: white;
  opacity: 0.5;
  height: ${gfs('px', 98, 208, 1366, 3840)};
  position: relative;
  flex-shrink: 0;

  &.active {
    opacity: 1;
    border: 2px solid ${palette.shadowBlue};
    border-right: none;
    border-top-left-radius: ${pxToRem(4)}rem;
    border-bottom-left-radius: ${pxToRem(4)}rem;
    flex-shrink: 0;
  }
`

export const Img = styled.img`
  height: 100%;
  object-fit: contain;
  object-position: left;
`

export const SelectedColorData = styled.div`
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const ColorDoorsAndDropsWithMargin = styled(WBColorDoorsAndDrops)`
  margin-top: 2rem;
  margin-left: 1.5rem;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${gfs('px', 40, 64, 1366, 3840)};
  margin-top: ${gfs('px', 15, 65, 1366, 3840)};
`

export const Button = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: ${pxToRem(4)}rem;
  font-size: ${gfs('px', 13, 24, 1366, 3840)};
  padding: ${gfs('px', 20, 27, 1366, 3840)} ${gfs('px', 20, 24, 1366, 3840)};
  height: ${gfs('px', 56, 72, 1366, 3840)};
  min-width: ${gfs('px', 120, 150, 1366, 3840)};
  font-family: GilmerBold, sans-serif;
`

export const CancelButton = styled(Button)`
  color: ${palette.tangaroa};
  border: 1px solid ${palette.tangaroa};
  background-color: ${palette.white};

  @media (min-width: ${breakpointsCross.L.min}) {
    border: 2px solid ${palette.tangaroa};
  }
`

export const DoneButton = styled(Button)<{
  disabled?: boolean
}>`
  color: ${palette.white};
  background-color: ${({ disabled }) => (disabled ? palette.philippineSilver : palette.tangaroa)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

export const Icon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1;
  transform: translate(50%, -50%);
  min-width: 14px;
  min-height: 14px;
  width: ${gfs('px', 14, 34, 1366, 3840)};
  height: ${gfs('px', 14, 34, 1366, 3840)};
`

export const NotificationItem = styled.div`
  color: ${palette.tangaroa};
  font-size: ${gfs('px', 14, 24, 1366, 3840)};
  line-height: 1.7;
  &::before {
    content: '';
    display: inline-block;
    height: 0.8em;
    width: 0.8em;
    background-size: 0.8em 0.8em;
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.802 3.052a.58.58 0 0 1-.18.42l-9.547 9.26a.963.963 0 0 1-.67.268.963.963 0 0 1-.67-.269L.18 8.313a.585.585 0 0 1 0-.84l.472-.458a.625.625 0 0 1 .867 0l3.67 3.56a.312.312 0 0 0 .431 0l8.665-8.401a.624.624 0 0 1 .866 0l.473.458c.115.11.18.262.18.42z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    margin-right: 0.5em;
  }
`

export const SelectedColorWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content 1fr;
  width: 100%;
`
