import { createSlice, original, PayloadAction } from '@reduxjs/toolkit'

import app_config from '../../config/app/config'
import { mergeNewProductDetailsInState } from '../../libs/productsV2'
import { isExtraIpadDevice, isTableDevice } from '../../libs/url'
import { getReverseViewType } from '../../libs/view'
import {
  CartProduct,
  CartState,
  LastAddedProductRecap,
  MyShopTab,
  TagGroup,
} from '../../model/cart'
import { CategoryId, Upc } from '../../model/model'
import { Product } from '../../model/product'
import { resetForNewClient } from '../extraActions'

const DEFAULT_STATE: CartState = {
  analytics: {
    currency: '',
    total: {
      adv: 0,
      best_seller: 0,
      carry_over: 0,
      color_refresh: 0,
      key: '',
      man: 0,
      models: 0,
      new_model: 0,
      optical: 0,
      polar: 0,
      price_optical: 0,
      price_sun: 0,
      price_total: 0,
      skus: 0,
      sun: 0,
      total_items: 0,
      total_new: 0,
      unisex: 0,
      woman: 0,
    },
    brands: [],
  },
  statistics: {
    itemsByDoors: {},
  },
  apiRequestsBuffer: [
    // {
    //   id,
    //   sku,
    //   upc,
    //   door,
    //   qnt,
    //   oldQnt,
    //   status,
    // }
  ],
  cartData: {
    timestamp: undefined,
    loading: false,
    loaded: false,
    updating: false,
    customerId: null,
    eventId: null,
    products: [],
    status: null,
    editable: true,
    backOfficeNote: undefined,
  },
  cartProductDetails: {},
  loadingCartProductDetails: false,
  loadedCartProductDetails: false,
  warnings: [],
  viewType: 'tableView',
  category: app_config.sunCategory,
  recommendation: {},
  recommendationLoadingStatus: {
    loading: false,
    loaded: false,
  },
  assortmentsViewType: {
    type: 'wallView',
    category: undefined,
  },
  activeBrand: '',
  myShopActiveTab: 'futureTrends',
  myShopAssortmetsCollapsed: true,
  myShopLastAddedProductUpcs: [],
}

export const slice = createSlice({
  name: 'cart',
  initialState: DEFAULT_STATE,
  reducers: {
    setCartProductsDetailsLoaded: state => {
      state.loadedCartProductDetails = true
      state.loadingCartProductDetails = false
    },
    setLastAddedProductRecap: (
      state,
      { payload }: PayloadAction<LastAddedProductRecap | undefined>,
    ) => {
      state.lastAddedProductRecap = payload
    },
    setCartUpdating: (state, { payload }: PayloadAction<boolean>) => {
      state.updating = payload
    },
    resetRecommended: state => {
      state.recommendation = DEFAULT_STATE.recommendation
    },
    loadRecommended: (
      state,
      { payload }: PayloadAction<{ recommendation: TagGroup; brand: string; category: string }>,
    ) => {
      state.recommendation[`${payload.brand}-${payload.category}`] = payload.recommendation
      state.recommendationLoadingStatus.loading = false
      state.recommendationLoadingStatus.loaded = true
    },
    toggleCategoryAssortment: state => {
      const { category } = state
      state.category =
        category === app_config.sunCategory ? app_config.opticalCategory : app_config.sunCategory
    },
    changeAssortmentViewType: (state, { payload }: PayloadAction<CategoryId | undefined>) => {
      state.assortmentsViewType = {
        type: payload ? 'leds' : 'wallView',
        category: payload ? payload : undefined,
      }
    },
    switchCartType: state => {
      const { viewType } = state
      state.viewType = getReverseViewType(viewType)
    },
    resetCartView: state => {
      const viewType =
        isTableDevice() || isExtraIpadDevice()
          ? app_config.viewType.table
          : app_config.viewType.wall
      state.viewType = viewType as 'wallView' | 'tableView'
    },
    saveCartProductsDetails: (state, { payload = [] }: PayloadAction<Product[] | undefined>) => {
      const actualProductDetails = original(state.cartProductDetails)
      if (actualProductDetails) {
        state.cartProductDetails = mergeNewProductDetailsInState(actualProductDetails, payload)
        state.loadedCartProductDetails = true
        state.loadingCartProductDetails = false
      }
    },
    setAnalytics: (
      state,
      { payload }: PayloadAction<{ analytics: any; statistics: Record<string, any> }>,
    ) => {
      const { analytics, statistics } = payload
      state.analytics = analytics || state.analytics
      state.statistics = statistics || state.statistics
    },
    setCartViewType: (state, { payload }: PayloadAction<'wallView' | 'tableView'>) => {
      state.viewType = payload
    },
    setMyShopActiveTab: (state, { payload }: PayloadAction<MyShopTab>) => {
      state.myShopActiveTab = payload
    },
    toggleMyShopAssortmentCollapse: state => {
      state.myShopAssortmetsCollapsed = !state.myShopAssortmetsCollapsed
    },
    loadingCartSection: (
      state,
      { payload = 'cartData' }: PayloadAction<string | undefined | null>,
    ) => {
      if (payload) {
        if (payload === 'recommendation') {
          state.recommendationLoadingStatus.loaded = false
          state.recommendationLoadingStatus.loading = true
        } else {
          state[payload].loaded = false
          state[payload].loading = true
        }
      }
    },
    setCartSectionLoaded: (
      state,
      { payload = 'cartData' }: PayloadAction<string | undefined | null>,
    ) => {
      if (payload) {
        state[payload].loaded = true
        state[payload].loading = false
      }
    },
    setWaitingApiRequestAsPending: state => {
      state.apiRequestsBuffer = state.apiRequestsBuffer.map(apiRequest =>
        apiRequest.requestStatus === 'waiting'
          ? { ...apiRequest, requestStatus: 'pending' }
          : apiRequest,
      )
    },
    resetPendingApiRequestAsWaiting: state => {
      state.apiRequestsBuffer = state.apiRequestsBuffer.map(apiRequest =>
        apiRequest.requestStatus === 'pending'
          ? { ...apiRequest, requestStatus: 'waiting' }
          : apiRequest,
      )
    },
    setPendingApiRequestAsFullfilled: state => {
      state.apiRequestsBuffer = state.apiRequestsBuffer.filter(
        apiRequest => apiRequest.requestStatus !== 'pending',
      )
    },
    loadCart: (
      state,
      { payload }: PayloadAction<{ products: CartProduct[]; backOfficeNote?: string }>,
    ) => {
      const cartData = payload

      state.cartData = {
        ...cartData,
        loaded: true,
        loading: false,
        updating: false,
      }
    },
    addToApiRequestBuffer: (state, { payload }: PayloadAction<any>) => {
      state.apiRequestsBuffer = payload
    },
    setActiveBrandCodeAction: (state, { payload }: PayloadAction<string | null>) => {
      if (state.activeBrand !== payload) {
        state.myShopLastAddedProductUpcs = []
      }
      state.activeBrand = payload
    },
    setActiveBrandCodeActionWithoutSync: (state, { payload }: PayloadAction<string | null>) => {
      if (state.activeBrand !== payload) {
        state.myShopLastAddedProductUpcs = []
      }
      state.activeBrand = payload
    },
    setMyShopLastAddedProductUpcs: (state, { payload }: PayloadAction<Upc[]>) => {
      state.myShopLastAddedProductUpcs = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, () => DEFAULT_STATE)
  },
})

export default slice.reducer
