import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useSearchParams } from '../../../../hooks/useSearchParams'
import { breakpoints, palette, pxToRem } from '../../../../style/theme'
import ResizeButton from '../../Components/ResizeButton'
import Slider from './Slider'
import { useHistory, useLocation } from 'react-router-dom'
import { getDeviceBasePath } from '../../../../libs/url'
import { debounce } from 'lodash'
import { Positioning } from '../../Model/aaModel'
import { ASSORTMENT_SIZE_DEFAULT, POSITIONINGS } from '../../consts'
import useGetFilteredMocos from '../../Hooks/useGetFilteredMocos'
import { useSelector } from 'react-redux'
import { viewportSelector } from '../../../../store/viewport/selectors'

const Wrapper = styled.div`
  flex: 264px;
  transition: flex 0.25s, opacity 0s 0.2s;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border-radius: 1vw;
  padding: 0 1.5vw;
  background-color: ${palette.blueZodiacAA};
  opacity: 1;
  position: relative;

  &::after {
    content: '';
    background-image: linear-gradient(to bottom, transparent, ${palette.blackPearl});
    height: 2em;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &.collapsed {
    flex: 0;
    padding: 0;
    opacity: 0;
    transition: flex 0.25s;
  }

  @media (min-width: ${breakpoints.L}) {
    flex: 314;
  }
`

const Scrollable = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 0.65em;
`

type Props = {
  collapsed: boolean
  setCollapsed: (collapsed: boolean) => void
}

const Title = styled.div<{ height: number }>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${({ height }) => (height < 1000 ? height * 0.0025 : height * 0.015)}px;
  margin-bottom: ${({ height }) => (height < 1000 ? height * 0.005 : height * 0.025)}px;
  font-size: max(1.5vw, 22px);
  color: ${palette.white};
  align-items: center;
  line-height: 2.4;

  & > img {
    width: 1.6em;
    height: 1.6em;
  }
`

const BrandsSubtitle = styled.div<{ height: number }>`
  display: flex;
  flex-direction: row;
  font-size: max(1vw, 16px);
  margin-bottom: ${({ height }) => (height < 1000 ? height * 0.005 : height * 0.015)}px;
  gap: ${pxToRem(16)}rem;
  align-items: center;

  & > div:last-of-type {
    color: ${palette.cornflowerBlueAA};
    text-decoration: underline;
    cursor: pointer;
    margin-left: auto;
  }
`

const Subtitle = styled.div`
  font-size: clamp(16px, 1vw, 33px);
  color: ${palette.white};
  padding-bottom: 0.5em;
`

const BrandsCounter = styled.div`
  font-size: ${pxToRem(16)}rem;
  background-color: ${palette.biscay};
  color: ${palette.cornflowerBlueAA};
  border-radius: ${pxToRem(8)}rem;
  display: flex;
  padding: 0.5em;
  justify-content: center;
  align-items: center;
  font-size: max(0.7vw, 12px);
  margin-bottom: 0.5em;
`

const SliderAndSubtitle = styled.div`
  margin-bottom: 1vw;
`

const PositioningWrapper = styled.div`
  margin-top: 2vw;
`

const PositioningItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5vw;
  margin-bottom: 0.75vw;
`

const PositioningSubtitle = styled.span`
  font-size: clamp(13px, 1vw, 30px);
  text-transform: uppercase;
  color: ${palette.cornflowerBlueAA};
`

const PositioningSlider = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const getAdjustedParams = ({
  field,
  oldValues,
  newValue,
}: {
  field: Positioning
  oldValues: Record<Positioning, number>
  newValue: number
}) => {
  type ToAdjustKeys = Exclude<Positioning, typeof field>

  delete oldValues[field]

  const newValues = new Map<ToAdjustKeys, number>()
  Object.entries(oldValues).forEach(([key, value]) => newValues.set(key as ToAdjustKeys, value))
  const sortedNewValues = new Map<ToAdjustKeys, number>([...newValues.entries()].sort())
  const sortedKeys = [...sortedNewValues.entries()]
    .sort(([, a], [, b]) => b - a)
    .map(([key]) => key) as ToAdjustKeys[]

  const getByKeyWithFallback = (key: ToAdjustKeys) => {
    return sortedNewValues.get(key) || 0
  }

  let offset = 0

  const adjust = (key: ToAdjustKeys, operation: 'sub' | 'add') => {
    const currentValue = getByKeyWithFallback(key)
    if (operation === 'add') {
      if (currentValue < 100) {
        offset++
        return currentValue + 1
      }
      return currentValue
    } else {
      if (currentValue > 0) {
        offset--
        return currentValue - 1
      }
      return currentValue
    }
  }

  offset =
    newValue +
    getByKeyWithFallback(sortedKeys[0]) +
    getByKeyWithFallback(sortedKeys[1]) +
    getByKeyWithFallback(sortedKeys[2]) +
    getByKeyWithFallback(sortedKeys[3]) -
    100
  while (offset !== 0) {
    if (offset > 0) {
      sortedKeys.forEach(key => {
        if (offset <= 0) return
        sortedNewValues.set(key, adjust(key, 'sub'))
      })
    } else {
      sortedKeys.reverse().forEach(key => {
        if (offset >= 0) return
        sortedNewValues.set(key, adjust(key, 'add'))
      })
    }
  }

  const newParams = new Map<Positioning, string>([[field, newValue.toString()]])

  sortedKeys.forEach(key => {
    newParams.set(key, getByKeyWithFallback(key).toString())
  })

  return {
    young: newParams.get('young')?.toString() || '',
    innovative: newParams.get('innovative')?.toString() || '',
    sophisticated: newParams.get('sophisticated')?.toString() || '',
    sport: newParams.get('sport')?.toString() || '',
    everyday: newParams.get('everyday')?.toString() || '',
  }
}

const ControlPanel: React.FC<Props> = ({ collapsed, setCollapsed }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const [, setFromChooseBrand] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [realTimeParams, setRealTimeParams] = useState(searchParams)
  const [forceRerender, setForceRerender] = useState(false)
  const { height } = useSelector(viewportSelector)

  const { mocos, defaultParams } = useGetFilteredMocos()

  const [assortmentSize, setAssortmentSize] = useState(ASSORTMENT_SIZE_DEFAULT.toString())
  const categoryMix = realTimeParams.get('mix') || defaultParams.mix.toString()
  const composition = realTimeParams.get('composition') || defaultParams.composition.toString()

  const basePositioning = (name: Positioning) => defaultParams[name].toString()

  const positionings = POSITIONINGS

  const isJunior = searchParams.get('junior') === 'true'

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setAssortmentSize(mocos.length.toString())
    }, 500)
    return () => clearTimeout(delayInputTimeoutId)
  }, [assortmentSize, mocos.length, realTimeParams])

  const getRealTimeParamsByName = (name: Positioning) =>
    realTimeParams.get(name) || basePositioning(name)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceParams = useCallback(
    debounce((newParams: Record<string, string | undefined>) => {
      Object.entries(newParams).forEach(([param, value]) => {
        value ? searchParams.set(param, value) : searchParams.delete(param)
      })
      setSearchParams(searchParams, { replace: true })
    }, 300),
    [searchParams, setSearchParams],
  )

  const setParams = useCallback(
    (newParams: Record<string, string | undefined>) => {
      Object.entries(newParams).forEach(([param, value]) => {
        value ? realTimeParams.set(param, value) : realTimeParams.delete(param)
      })
      setRealTimeParams(realTimeParams)
      setForceRerender(!forceRerender)
      debounceParams(newParams)
    },
    [debounceParams, realTimeParams, forceRerender],
  )

  useEffect(() => {
    if (location.state === 'from-choose-brand') {
      setFromChooseBrand(true)
    }
  }, [location.state])

  const brandsParams = (searchParams.get('brands') || '').split(',')

  const adjust = (field: Positioning, value: string) => {
    const oldValues = {
      young: Number(getRealTimeParamsByName('young')),
      innovative: Number(getRealTimeParamsByName('innovative')),
      sophisticated: Number(getRealTimeParamsByName('sophisticated')),
      sport: Number(getRealTimeParamsByName('sport')),
      everyday: Number(getRealTimeParamsByName('everyday')),
    }
    const newValue = Number(value)

    const newParams = getAdjustedParams({ newValue, oldValues, field })
    setParams(newParams)
  }

  const capitalized = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
  const url = `${getDeviceBasePath()}/assortment-advisor/choose-brands?brands=${searchParams.get(
    'brands',
  )}${isJunior ? '&junior=true' : ''}`

  return (
    <Wrapper className={collapsed ? 'collapsed' : ''}>
      <Title height={height}>
        <span>{t('AA.Plp.Panel.Title')}</span>
        <ResizeButton togglePanelCollapsed={() => setCollapsed(!collapsed)} />
      </Title>

      <Scrollable>
        <BrandsSubtitle height={height}>
          <Subtitle>{t('AA.Plp.Panel.Brands')}</Subtitle>
          <BrandsCounter>{brandsParams.length}</BrandsCounter>
          <Subtitle onClick={() => history.replace(url, 'from-plp')}>
            {t('AA.Plp.Panel.Filter')}
          </Subtitle>
        </BrandsSubtitle>

        <SliderAndSubtitle>
          <Subtitle>{t('AA.Plp.Panel.AssortmentSubtitle')}</Subtitle>
          <Slider
            min="0"
            max="50"
            value={assortmentSize}
            step="1"
            onChange={value => {
              setAssortmentSize(value)
              setParams({ size: value })
            }}
            leftLabel="0"
            rightLabel="50"
            showValue
          />
        </SliderAndSubtitle>

        <SliderAndSubtitle>
          <Subtitle>{t('AA.Plp.Panel.CategoryMixSubtitle')}</Subtitle>
          <Slider
            min="0"
            max="100"
            value={categoryMix}
            step="1"
            onChange={value => {
              setParams({ mix: value })
            }}
            leftLabel={t('AA.Plp.Panel.Sun')}
            rightLabel={t('AA.Plp.Panel.Optical')}
          />
        </SliderAndSubtitle>

        <SliderAndSubtitle>
          <Subtitle>{t('AA.Plp.Panel.CompositionSubtitle')}</Subtitle>
          <Slider
            min="0"
            max="100"
            value={composition}
            step="1"
            onChange={value => {
              setParams({ composition: value })
            }}
            leftLabel={t('AA.Plp.Panel.Novelties')}
            rightLabel={t('AA.Plp.Panel.Carryover')}
          />
        </SliderAndSubtitle>

        <PositioningWrapper>
          <Subtitle>{t('AA.Plp.Panel.PositioningAdjustSubtitle')}</Subtitle>

          {positionings.map(positioning => (
            <PositioningItem key={positioning}>
              <PositioningSlider>
                <PositioningSubtitle>
                  {t(`AA.Plp.Panel.${capitalized(positioning as Positioning)}`)}
                </PositioningSubtitle>
                <Slider
                  min="0"
                  max="100"
                  value={getRealTimeParamsByName(positioning as Positioning)}
                  step="1"
                  onChange={value => {
                    adjust(positioning as Positioning, value)
                  }}
                  positioning={positioning as Positioning}
                  leftLabel="0"
                  rightLabel="100"
                  showValue
                />
              </PositioningSlider>
            </PositioningItem>
          ))}
        </PositioningWrapper>
      </Scrollable>
    </Wrapper>
  )
}

export default ControlPanel
