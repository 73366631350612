import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { isPageMatches } from '../../libs/url'
import { isOpenedBrandStarsSelector, openedBrandsSelector } from '../../store/brands/selectors'
import starsActions from '../../store/stars/actions'
import {
  filteredInOutDataSelector,
  isPlpStarsModeSelector,
  monthsSelector,
  onlyOneClusterIsActiveSelector,
  starsEnableMonthButtonSelector,
  starsProductsCountSelector,
  starsShowInOutSelector,
} from '../../store/stars/selectors'
import {
  breakpoints,
  duration,
  getFluidFontSize,
  getFluidSizeWithFullFormula,
  palette,
  plpSidebarTabletLateralPadding,
  pxToRem,
  spacing,
  zIndex,
} from '../../style/theme'
import Switch from '../../styleguide/Switch'
import { PlpLayerWrapper, PlpLayerWrapperMonth } from '../CommonComponents'
import CalendarIcon from '../icons/CalendarIcon'
import ChevronDownIcon from '../icons/ChevronDownIcon'
import ClusterIcon from '../icons/ClusterIcon'
import IconStatistics from '../icons/IconStatistics'
import StarsLogo from '../icons/StarsLogo'
import { StarsPlpAnalytics } from '../StarsAnalytics/StarsAnalytics'
import StarsWholesaleSwitch from '../StarsWholesaleSwitch/StarsWholesaleSwitch'
import ClusterMenu from './ClusterMenu'
import MonthMenu from './MonthMenu'

const Wrapper = styled.div`
  --sidebar-width: min(12em, 21rem);

  display: flex;
  flex-direction: column;
  width: var(--sidebar-width);
  height: 100%;
  padding-top: min(4vw, ${pxToRem(spacing(14))}rem);
  background-color: ${palette.white};
  transition: width ${duration.short}ms;
  font-size: ${getFluidFontSize('12px')};

  .stars-wholesale-switch {
    align-self: center;
  }

  &.closed {
    --sidebar-width: min(6em, ${pxToRem(spacing(16))}rem);

    .stars-wholesale-switch {
      visibility: hidden;
    }
  }

  @media (max-width: ${breakpoints.M}) {
    flex-direction: row;
    width: 100%;
    height: var(--header-height);
    padding: 0 ${plpSidebarTabletLateralPadding};
    gap: ${pxToRem(spacing(2))}rem;
    align-items: center;

    .stars-wholesale-switch {
      order: -1;
    }

    &.closed {
      width: 100%;

      .stars-wholesale-switch {
        display: flex;
      }
    }
  }
`

const Backdrop = styled.div`
  background-color: ${palette.black};
  opacity: 0;
  position: absolute;
  top: 0;
  width: calc(100% - var(--sidebar-width));
  left: var(--sidebar-width);
  height: 100%;
  z-index: ${zIndex.plpMenus};
  transition: opacity ${duration.standard}ms;

  &.hide {
    pointer-events: none;
  }

  &.show {
    opacity: 0.5;
  }

  @media (max-width: ${breakpoints.M}) {
    left: 0;
    width: 100%;
    height: calc(100% - (var(--header-height) * 2));
    top: calc(var(--header-height) * 2);
  }
`

const LogoContainer = styled.div`
  flex: 1;

  @media (max-width: ${breakpoints.M}) {
    display: none;
  }
`

const Logo = styled(StarsLogo)`
  margin: 0 auto;
  width: min(52%, 150px);
  height: auto;
  display: block;
  opacity: 1;
  transition: opacity ${duration.shortest}ms;

  .closed & {
    opacity: 0;
  }

  .wholesale-mode & {
    visibility: hidden;
  }
`

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  gap: 2em;
  padding: 2em 0;
  margin-bottom: 2.5em;
  border-top: solid 1px ${palette.wildSand};
  border-bottom: solid 1px ${palette.wildSand};
  font-size: ${getFluidSizeWithFullFormula(12, 'px', 22, 1000, 3840)};

  svg,
  img {
    width: 1.5em;
    height: 1.5em;
  }

  .closed & {
    align-items: center;
  }

  .wholesale-mode & {
    visibility: hidden;
  }

  @media (max-width: ${breakpoints.M}) {
    height: 100%;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    border: none;
    margin: 0;
    padding: 0;
  }
`

const ButtonAndLayer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  width: 100%;
`

const buttonsStyle = css`
  padding: 0.25em 0 0.25em 2em;

  .closed & {
    padding: 0.25em 0 0.25em 0;
  }
`
const InOutSwitch = styled(Switch)`
  ${buttonsStyle}
  width: 100%;
  display: grid;
  grid-template-columns: auto min-content;
  height: 100%;
  cursor: pointer;

  .closed & {
    grid-template-columns: auto;
    place-content: center;
  }

  @media (max-width: ${breakpoints.M}) {
    .closed & {
      grid-template-columns: auto min-content;
      place-content: start;
    }
  }
`

const Button = styled.button`
  ${buttonsStyle}
  background-color: transparent;
  border: none;
  display: grid;
  align-items: center;
  grid-template-columns: min-content auto min-content;
  text-align: left;
  color: ${palette.congressBlue};
  width: 100%;

  &.active {
    border-radius: 4px;
    border-color: ${palette.congressBlue};
  }

  &:disabled {
    color: ${palette.gray};
    svg:first-child {
      fill: ${palette.alto};
    }
  }

  &:not(:disabled) {
    svg:first-child {
      fill: ${palette.cornflowerBlue};
    }
  }

  .closed & {
    grid-template-columns: auto;
    place-content: center;
  }

  @media (max-width: ${breakpoints.M}) {
    .closed & {
      grid-template-columns: min-content auto min-content;
      place-content: start;
    }

    &.closedMenu {
      outline-offset: 0;
    }
  }
`

const Label = styled.span`
  font-weight: normal;
  text-transform: uppercase;
  flex: 1;
  margin-left: 1em;

  .closed & {
    display: none;
  }

  @media (max-width: ${breakpoints.M}) {
    .closed & {
      display: block;
    }
  }
`

const Arrow = styled(ChevronDownIcon)`
  margin-right: 0;
  fill: ${palette.wildSand};
  transform: rotate(-90deg);
  place-self: center end;

  .closed & {
    display: none;
  }

  .closedMenu & {
    fill: ${palette.congressBlue};
  }

  @media (max-width: ${breakpoints.M}) {
    margin-left: ${pxToRem(spacing(2))}rem;
    transform: rotate(0);
    fill: ${palette.black};

    .closed & {
      display: block;
    }

    .closedMenu & {
      fill: ${palette.black};
      transform: rotate(180deg);
    }
  }
`

const ToggleButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

const buttonWidth = 3
const ToggleSidebarButton = styled.button`
  position: relative;
  margin-bottom: ${pxToRem(spacing(6))}rem;
  width: 2em;
  height: 2em;
  border-radius: 8px 0 0 8px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px ${palette.alto};
  background-color: ${palette.white};

  &::before,
  &::after {
    content: '';
    width: 60%;
    height: 2px;
    background-color: ${palette.biscay};
    position: absolute;
    left: 20%;
    top: calc(50% - 1.5px);
    transition: transform ${duration.short}ms;
  }

  .closed & {
    &::after {
      transform: rotate(90deg);
    }
  }

  @media (max-width: ${breakpoints.L}) {
    width: ${pxToRem(spacing(buttonWidth))}rem;
    height: ${pxToRem(spacing(buttonWidth))}rem;
  }

  @media (max-width: ${breakpoints.M}) {
    display: none;
  }
`

const AnalyticsLayerContainer = styled.div`
  position: relative;
  bottom: 0;

  @media (max-width: ${breakpoints.M}) {
    bottom: 0;
    align-self: end;
    flex: 1;
    height: 100%;
  }
`

const StyledIconStatistics = styled(IconStatistics)`
  color: ${palette.silver};
`

const AnalyticsLayerWrapper = styled(PlpLayerWrapper)`
  bottom: 0;
  width: 80vw;
  height: min(65vh, 1000px);
  max-height: none;
  transform: translate(100%, -100%);
  padding: 0;

  @media (max-width: ${breakpoints.M}) {
    left: calc(100% + ${plpSidebarTabletLateralPadding});
    transform: translate(-100%, 100%);
    width: 100vw;
  }
`

const PlpSidebar: React.FC = () => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(true)
  const [layerOpened, setLayerOpened] = useState(
    '' as '' | 'clusters' | 'months' | 'statistics' | 'inout',
  )

  const onlyOneClusterIsActive = useSelector(onlyOneClusterIsActiveSelector)
  const filteredInOutData = useSelector(filteredInOutDataSelector)
  const showInOut = useSelector(starsShowInOutSelector)
  const productsCount = useSelector(starsProductsCountSelector)
  const isPlpStarsMode = useSelector(isPlpStarsModeSelector)
  const isSelectedBrandStars = useSelector(isOpenedBrandStarsSelector)
  const selectedBrands = useSelector(openedBrandsSelector)
  const isMoreThanOneBrandSelected = selectedBrands.length > 1
  const isWhishlist = isPageMatches('wishlist')
  const enableMonthButton = useSelector(starsEnableMonthButtonSelector)
  const months = useSelector(monthsSelector)

  const dispatch = useDispatch()

  useEffect(() => {
    if (months.length === 1) {
      dispatch(starsActions.setSelectedMonth(months[0]))
    }
  }, [dispatch, months])

  return !isSelectedBrandStars || isMoreThanOneBrandSelected || isWhishlist ? null : (
    <Wrapper
      className={[
        isOpen ? 'open' : 'closed',
        isPlpStarsMode ? 'stars-mode' : 'wholesale-mode',
      ].join(' ')}
    >
      <Backdrop
        className={[layerOpened ? 'show' : 'hide'].join(' ')}
        onClick={() => setLayerOpened('')}
      />

      <LogoContainer>
        <Logo />
      </LogoContainer>

      <ButtonsContainer>
        <ButtonAndLayer>
          <Button
            disabled={!enableMonthButton}
            className={layerOpened === 'months' ? 'active' : ''}
            onClick={() => setLayerOpened(layerOpened === 'months' ? '' : 'months')}
          >
            <CalendarIcon />
            <Label>{t('Plp.month')}</Label>
            <Arrow />
          </Button>
          {layerOpened === 'months' && (
            <PlpLayerWrapperMonth>
              <MonthMenu />
            </PlpLayerWrapperMonth>
          )}
        </ButtonAndLayer>

        <ButtonAndLayer>
          <Button
            disabled={productsCount === 0}
            className={layerOpened === 'clusters' ? 'active' : ''}
            onClick={() => setLayerOpened(layerOpened === 'clusters' ? '' : 'clusters')}
          >
            <ClusterIcon />
            <Label>{t('Plp.cluster')}</Label>
            <Arrow />
          </Button>
          <ClusterMenu open={layerOpened === 'clusters'} />
        </ButtonAndLayer>

        <InOutSwitch
          label={isOpen ? t('Plp.inOut') : undefined}
          disabled={!onlyOneClusterIsActive || filteredInOutData.length === 0}
          onChange={() => dispatch(starsActions.setShowInOut(!showInOut))}
          checked={showInOut && onlyOneClusterIsActive}
        />

        <Button
          disabled={!onlyOneClusterIsActive}
          className={layerOpened === 'statistics' ? 'active' : ''}
          onClick={() => setLayerOpened(layerOpened === 'statistics' ? '' : 'statistics')}
        >
          <StyledIconStatistics />
          <Label>{t('Plp.statistics')}</Label>
          <Arrow />
        </Button>
      </ButtonsContainer>

      <StarsWholesaleSwitch />

      <ToggleButtonWrapper>
        <ToggleSidebarButton onClick={() => setIsOpen(!isOpen)} />
      </ToggleButtonWrapper>

      <AnalyticsLayerContainer>
        {layerOpened === 'statistics' && (
          <AnalyticsLayerWrapper>
            <StarsPlpAnalytics />
          </AnalyticsLayerWrapper>
        )}
      </AnalyticsLayerContainer>
    </Wrapper>
  )
}

export default PlpSidebar
