import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import PageSocketManager, { PageSocketManagerProps } from '../../components/PageSocketManager'
import devices from '../../config/devices'
import { isTableDevice } from '../../libs/url'
import { Brand } from '../../model/brand'
import { CmsContent } from '../../model/content'
import { CouvetteVideoState } from '../../model/couvettes'
import { TDispatch } from '../../model/model'
import { pause_video, play_video } from '../../store/actions'
import { openedBrandsSelector } from '../../store/brands/selectors'
import IconToggler from '../UI/IconToggler'

type Props = {
  headerIcon?: boolean
  emitEvent: (event: string) => void
  couvetteVideo?: CouvetteVideoState
  media: CmsContent
  uniqueId: string
  classSmartLook: string
  className?: string
}

const getUniqueMediaId = (mediaId: string, uniqueId: string) => `${mediaId}_${uniqueId}`

const videoTogglerHandler = (
  isVideoPlaying: boolean,
  uniqueId: string,
  media: CmsContent,
  emitEvent: any,
  dispatch: TDispatch,
  openedBrands: Brand[],
) => {
  if (!isTableDevice()) return false

  const mediaUniqueId = getUniqueMediaId(media.id || '', uniqueId)
  if (isVideoPlaying) {
    emitEvent('pause_video')
    dispatch(pause_video())
  } else {
    emitEvent(
      'play_video',
      {
        brand: openedBrands[0],
      },
      [devices.table],
    )
    dispatch(play_video(media, mediaUniqueId))
  }
}

export const PlayPauseVideoBtn: React.FC<Props & PageSocketManagerProps> = ({
  headerIcon,
  media,
  couvetteVideo,
  uniqueId,
  className,
  classSmartLook,
  emitEvent,
}) => {
  const { t } = useTranslation()
  const mediaUniqueId = getUniqueMediaId(media.id || '', uniqueId)
  const dispatch: TDispatch = useDispatch()
  const openedBrands = useSelector(openedBrandsSelector)

  const isActive = Boolean(
    !!couvetteVideo &&
      couvetteVideo.mediaUniqueId &&
      couvetteVideo.mediaUniqueId === mediaUniqueId &&
      !couvetteVideo.isPaused,
  )

  return (
    <IconToggler
      classSmartLook={classSmartLook}
      className={['header-controls__play', className].join(' ')}
      iconActive="pause"
      iconInactive="caret-right"
      textActive={t('ProductPage.pause')}
      textInactive={t('ProductPage.play')}
      headerIcon={headerIcon}
      isActive={isActive}
      handleClick={() =>
        videoTogglerHandler(isActive, uniqueId, media, emitEvent, dispatch, openedBrands)
      }
      mediaType={media.type}
    />
  )
}

const PlayPauseVideoBtnManager = PageSocketManager(PlayPauseVideoBtn)

export default PlayPauseVideoBtnManager
