import React, { Component } from 'react'

import log from '../../libs/log'
import { isIpadOnlyRoom } from '../../libs/url'

type Props = {
  handleVideoEnded?: () => void
  handleVideoPlaying?: () => void
  isPaused?: boolean
  loop: boolean
  videoUrl?: string
}

type State = {
  blobUrl?: string
  stateBlobUrl?: string
}

class MediaPlayer extends Component<Props, State> {
  state = {
    blobUrl: undefined,
    stateBlobUrl: undefined,
  }

  videoRef: undefined | React.RefObject<HTMLVideoElement> = undefined
  blobUrl: undefined | string = undefined

  constructor(props: Props) {
    super(props)

    this.videoRef = React.createRef()
  }

  componentDidMount = () => {
    // autoplay event is not working as expected
    const videoTag = this.videoRef?.current
    const playPromise = videoTag?.play() || Promise.reject('')

    playPromise
      .then(() => {
        this.requireForVideoBlob.bind(this)
      })
      .catch(err => {
        // Video couldn't be autoplayed because of autoplay policy. Mute it and play.
        log.info(err)
        if (videoTag) {
          videoTag.muted = true
          videoTag.play().catch(e => log.info(e))
        }
      })
  }

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.videoUrl !== this.props.videoUrl) {
      this.requireForVideoBlob()
      return
    }

    if (prevProps.isPaused !== this.props.isPaused) {
      this.handleTogglePlaying()
    }
  }

  componentWillUnmount = () => {
    if (this.videoRef?.current) {
      this.videoRef.current.pause()
      // https://stackoverflow.com/a/13302599
      ;(this.videoRef.current.pause as any)(0)
      this.videoRef.current.src = ''
    }
  }

  requireForVideoBlob = () => {
    // we use video blob instead of src because src is not cached by browser, instead blob does
    const { videoUrl } = this.props

    const blobXhr = new XMLHttpRequest()
    blobXhr.open('GET', videoUrl || '', true)
    blobXhr.responseType = 'blob'
    blobXhr.onload = () => {
      // we can set the blob only when it gets fully loaded, this is why we need to play src on first hand and then we can proceed by using the blob
      this.blobUrl = window.URL.createObjectURL(blobXhr.response)
    }
    blobXhr.send()
    blobXhr.onloadend = () => {
      if (this.videoRef?.current) {
        this.videoRef.current.play().catch(err => log.info(err))
      }
    }
  }

  handleTogglePlaying = () => {
    if (!this.videoRef?.current) {
      return
    }

    if (this.props.isPaused) {
      this.videoRef.current.pause()
    } else {
      this.videoRef.current.play().catch(() => {
        /* fail silently - the element has been killed and replaced */
      })
    }
  }

  handleEnded = () => {
    const { loop, handleVideoEnded } = this.props
    const { stateBlobUrl } = this.state
    if (!loop && handleVideoEnded) {
      // case no loop, when the video ends the end handler should be triggered
      handleVideoEnded()
      return
    }

    if (!stateBlobUrl && this.videoRef?.current) {
      if (this.blobUrl) {
        // we set loop parameter only after we get the blob, instead the onEnded video event wouldn't be triggered
        this.videoRef.current.loop = loop

        // we set the state on ended event because we don't want to interrupt the video playing by triggering a re-rendering
        this.setState({ stateBlobUrl: this.blobUrl }, () =>
          this.videoRef?.current?.play().catch(() => {
            /* fail silently - the element has been killed and replaced */
          }),
        )
      } else {
        // if blob isn't arrived yet, we trigger another src play
        this.videoRef.current.play().catch(() => {
          /* fail silently - the element has been killed and replaced */
        })
      }
    }
  }

  handlePlaying = () => {
    const { handleVideoPlaying } = this.props
    handleVideoPlaying && handleVideoPlaying()
  }

  render = () => {
    const { children, videoUrl } = this.props
    const { stateBlobUrl } = this.state

    return (
      <video
        controls={isIpadOnlyRoom()}
        disablePictureInPicture
        controlsList="nodownload"
        width="100%"
        height="100%"
        ref={this.videoRef}
        src={stateBlobUrl || videoUrl}
        onEnded={this.handleEnded.bind(this)}
        onPlaying={this.handlePlaying.bind(this)}
      >
        {children}
      </video>
    )
  }
}

export default MediaPlayer
