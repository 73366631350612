import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { end_video } from '../../store/actions'
import { couvetteVideoSelector } from '../../store/couvetteVideo/selectors'
import DisplayMedia from '../DisplayMedia'
import styled from 'styled-components'

const DisplayMediaWrapper = styled(DisplayMedia)`
  //.wall-view-container .couvette-video-player {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 102;
  //}
`

const CouvetteVideoPlayer: React.FC = () => {
  const couvetteVideo = useSelector(couvetteVideoSelector)

  const dispatch = useDispatch()

  return (
    couvetteVideo.media && (
      <DisplayMediaWrapper
        // className="couvette-video-player"
        media={couvetteVideo.media}
        dates={couvetteVideo.dates}
        loop={false}
        isPaused={couvetteVideo.isPaused}
        handleVideoEnded={() => {
          dispatch(end_video())
        }}
      />
    )
  )
}

export default CouvetteVideoPlayer
