import { addMinutes, isPast } from 'date-fns'

import { Appointment, AppointmentTiming } from '../model/appointment'

export const getAppointmentToDisplay = (
  appointments: null | Appointment[],
  brandCode?: string,
): [Appointment | undefined, AppointmentTiming] => {
  const brandAppointments = brandCode
    ? appointments?.filter(({ brands }) => !!brands.find(({ code }) => code === brandCode))
    : appointments

  // se c'è un appuntamento iniziato e non finito prendi quello
  // altrimenti, se c'è un appuntamento finito da 1 minuto prendi quello
  // altrimenti, se c'è un appuntamento futuro prendi quello
  // altrimenti non c'è nessun appuntamento
  const runningAppointment = brandAppointments?.filter(
    ({ startDate, endDate }) => isPast(new Date(startDate)) && !isPast(new Date(endDate)),
  )
  if (runningAppointment?.length) {
    return [runningAppointment[0], 'running']
  }

  const justEndedAppointments = brandAppointments?.filter(
    ({ endDate }) => isPast(new Date(endDate)) && !isPast(addMinutes(new Date(endDate), 1)),
  )
  if (justEndedAppointments?.length) {
    return [justEndedAppointments[0], 'just ended']
  }

  const futureAppointments = brandAppointments
    ?.filter(({ startDate }) => !isPast(new Date(startDate)))
    .sort((app1, app2) => new Date(app1.startDate).getTime() - new Date(app2.startDate).getTime())
  if (futureAppointments?.length) {
    return [futureAppointments[0], 'future']
  }

  return [undefined, 'none']
}

export const getAppointmentTiming = (appointment?: Appointment): AppointmentTiming => {
  if (!appointment) {
    return 'none'
  }

  const { startDate, endDate } = appointment

  if (isPast(new Date(startDate)) && !isPast(new Date(endDate))) {
    return 'running'
  }

  if (isPast(new Date(endDate)) && !isPast(addMinutes(new Date(endDate), 1))) {
    return 'just ended'
  }

  if (!isPast(new Date(startDate))) {
    return 'future'
  } else return 'ended'

  return 'none'
}
