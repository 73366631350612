import io from 'socket.io-client'

import app_config from '../config/app/config'
import devices, { DeviceData } from '../config/devices'
import log from '../libs/log'
import { RootState, TDispatch } from '../model/model'
import {
  deviceNameSelector,
  languageSelector,
  roomNameSelector,
  roomTypeSelector,
} from '../store/app/selectors'
import {
  resetPendingApiRequestAsWaiting,
  sendUpdateCartRequest,
} from '../store/cart/actions/updateCart'
import { setSocketConnectionStatus } from '../store/notifications/actions'

const { socketIoEnabled } = app_config
if (!socketIoEnabled) {
  throw new Error('You are trying to use socket.io, but it is disabled in app config')
}

const GET_ROOM_AND_DEVICE_DATA = 'get_room_and_device_data'

class SocketManager {
  socketSignals = {
    deviceJoinRoom: 'device_join_room',
    deviceLeftRoom: 'device_left_room',
  }

  RECONNECTION_TIMEOUT = 2000
  reconnectionAttempt = 0

  setIsReady: undefined | ((value: SocketManager) => void)
  getState: () => RootState = () => {
    throw new Error('socketManger.getState is not initialized. Did you call initSocket?')
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: TDispatch = () => {
    throw new Error('socketManger.dispatch is not initialized. Did you call initSocket?')
  }

  isReady = new Promise(resolve => (this.setIsReady = resolve))

  isReadySync = false

  /**
   * Calls the setter for the first time. After the socket is instantiated, the function
   * stores it in the browser window, than runs the connection and emits a join signal.
   * @return {Void}
   */
  initSocket(dispatch: TDispatch, getState: () => RootState) {
    this.dispatch = dispatch
    this.getState = getState
    window.socketInfo = {
      socket: this.setSocket(),
    }

    this.bindReconnectionEvent().openConnection()

    this.setIsReady && this.setIsReady(this)
    this.isReadySync = true

    this.bindListener(GET_ROOM_AND_DEVICE_DATA + '_request', () => {
      this.emitSignal(GET_ROOM_AND_DEVICE_DATA + '_response', {
        ...this.getDefaultData(),
      })
    })
    this.bindListener('refresh', () => {
      window.location.reload()
    })
  }

  /**
   * Initializes the basic data to be passed for each socket event.
   * @return {object}: default data
   */
  // eslint-disable-next-line class-methods-use-this
  getDefaultData() {
    if (!this.getState) {
      return
    }
    const state = this.getState()
    const roomType = roomTypeSelector(state)

    const reloaded =
      window.performance &&
      window.performance.navigation &&
      window.performance.navigation.type === 1

    const socket = this.getSocket()
    const socketId = socket ? socket.id : ''

    const device = deviceNameSelector(state)
    const room = roomNameSelector(state)
    const language = languageSelector(state)

    const defaultData = {
      device,
      room,
      roomType,
      timestamp: new Date().getTime(),
      reloaded,
      socketId,
      language,
    }
    return defaultData
  }

  /**
   * Socket getter. Fetches the socket if already exists or invokes the setter instead.
   * @return {object}: returns the socket instance
   */
  getSocket() {
    let socket = window.socketInfo.socket
    if (!socket) {
      socket = this.setSocket()
    }
    return socket
  }

  /**
   * Socket setter. Instantiates the socket
   * @return {object}: returns the socket instance
   */
  setSocket() {
    const { socketIoUrl } = app_config
    const socket = io(socketIoUrl as string, {
      reconnection: true,
      timeout: this.RECONNECTION_TIMEOUT,
      transports: ['websocket'],
    })

    return socket
  }

  /**
   * Runs the socket connection function.
   * @return {Void}
   */
  openConnection() {
    const socket = this.getSocket()
    if (!socket.connected) {
      socket.connect()
    }

    return this
  }

  /**
   * Binds the event of reconnection. The event 'reconnect' is the default name of
   * the SocketIO lib.
   * @return {Void}
   */
  bindReconnectionEvent() {
    this.bindListener('connect_error', () => {
      this.reconnectionAttempt++

      this.dispatch(
        setSocketConnectionStatus({
          connected: false,
          reconnectionAttempts: this.reconnectionAttempt,
        }),
      )
    })

    this.bindListener('connect', () => {
      this.reconnectionAttempt = 0
      const defaultData = this.getDefaultData()
      this.dispatch(setSocketConnectionStatus({ connected: true, reconnectionAttempts: 0 }))
      if (defaultData?.room) {
        log.warn(`Socket.io reconnected: device: ${defaultData.device}, room: ${defaultData.room}`)
        this.emitJoinSignal()
        this.dispatch(resetPendingApiRequestAsWaiting())

        // emitjoinsignal causes the cart to be sent via socket
        // so does sendUpdateCartRequest
        // to avoid netwotk congestion we delay the second event
        const NO_CONGESTION_TIMEOUT = 2000
        setTimeout(() => this.dispatch(sendUpdateCartRequest()), NO_CONGESTION_TIMEOUT)
      }
    })

    return this
  }

  /**
   * Emits the join signal after the socket gets instantiated
   * @return {Void}
   */
  emitJoinSignal() {
    const state = this.getState()
    const roomToJoin = roomNameSelector(state)
    const roomType = roomTypeSelector(state)

    if (roomToJoin && roomType !== 'ipad-only') {
      const joinSignal = this.socketSignals.deviceJoinRoom
      this.emitSignal(joinSignal)
    }
  }

  /**
   * Emits the signal we pass in input and sends the associated data to the socket.
   * @param {string} name: name of the event to emit
   * @param {object} data: data to pass to the socket server.
   * @return {Void}
   */
  emitSignal(name: string, data = {}, autorizedDevices: DeviceData | DeviceData[] = []) {
    if (!devices.isCurrentDeviceAuthorized(autorizedDevices)) {
      return
    }

    const socket = this.getSocket()
    const defaultData = this.getDefaultData()
    socket.emit(name, {
      ...defaultData,
      ...data,
    })
  }

  /**
   * Creates a binding between an event name and a handler.
   * @param {string} name: event name to listen to
   * @param {function} callback: the handler to be run on event got received
   * @return {Void}
   */
  bindListener<T>(name: string, callback: (payload: T) => void) {
    this.isReady.then(() => {
      const socket = this.getSocket()
      socket.off(name, callback)
      socket.on(name, callback)
    })
    return this
  }

  listenOnce(name: string) {
    const socket = this.getSocket()

    return new Promise((resolve, reject) => {
      socket.once(name, ({ response, error }: { response: any; error: Error }) => {
        if (error) {
          reject(error)
        } else {
          resolve(response)
        }
      })
    })
  }

  /**
   * Removes listener for socket event
   * @param {string} name: event name
   * @param {function} callback: the handler to be removed
   * @return {void}
   */
  removeListener(name: string, callback: (pageData: any) => void) {
    const socket = this.getSocket()
    socket.off(name, callback)

    return this
  }
}

const socketManager = new SocketManager()

export default socketManager
