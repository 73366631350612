import classnames from 'classnames'
import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import styled from 'styled-components'

import { OpticalTipsContent } from '../../model/content'
import RCIcon from '../UI/RCIcon'
import OpticianTipsBlock from './OpticianTipsBlock'

const Wrapper = styled.div`
  font-family: 'Avenir-Roman', 'Avenir-Roman-Custom', sans-serif;
  position: relative;
`
Wrapper.displayName = 'OpticianTips'

const BlankView = styled.div`
  height: 30vh;
`
BlankView.displayName = 'BlankView'

type Props = {
  frontalTips?: OpticalTipsContent
  templeTips?: OpticalTipsContent
  othersTips?: OpticalTipsContent
}

const OpticianTips: React.FC<Props> = ({ frontalTips, othersTips, templeTips }) => {
  const { t } = useTranslation()

  const displayTips = frontalTips || templeTips || othersTips

  return displayTips ? (
    <Wrapper className="ot__container">
      <OpticianTipsBlock
        tips={frontalTips}
        title={t('ProductPage.frontal')}
        icon={<RCIcon type="frontal" />}
      />
      <OpticianTipsBlock
        tips={templeTips}
        title={t('ProductPage.temple')}
        icon={<RCIcon type="temple" />}
      />
      <OpticianTipsBlock
        tips={othersTips}
        title={t('ProductPage.others')}
        icon={<RCIcon type="magnifier" />}
      />
    </Wrapper>
  ) : (
    <BlankView className={classnames('blank-view')} />
  )
}

export default withTranslation('common')(OpticianTips)
