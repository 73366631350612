import styled from 'styled-components/macro'
import {
  breakpoints,
  breakpointsCross,
  getFluidSizeWithFullFormula as gF,
  palette,
} from '../../style/theme'

export const SizeDropTitle = styled.div`
  font-size: ${gF('px', 11, 29, 1366, 3840)};
  line-height: normal;
  text-transform: capitalize;
  letter-spacing: ${gF('px', 0.5, 1.22, 1366, 3840)};
  margin-bottom: ${gF('px', 8, 49, 1366, 3840)};
`

export const SizeRowTitle = styled.h1`
  font-family: GilmerBold;
  font-size: clamp(16px, ${gF('px', 16, 32, 1366, 3840)}, 32px);
  color: ${palette.tangaroa};

  & span {
    text-transform: capitalize;
  }

  @media (min-width: ${breakpoints.L}) {
    letter-spacing: 1.6px;
  }
`

export const SizeRowContainer = styled.div`
  color: ${palette.tangaroa};
  display: flex;
  flex-direction: column;
  gap: ${gF('px', 24, 88, 1366, 3840)};

  &.expired {
    border-radius: 10px;
    padding: 1.5rem;
    background-color: ${palette.extraLight};
    gap: ${gF('px', 4, 6, 1366, 3840)};
  }
`

export const SizeRowHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const SizeRowPrice = styled.div`
  margin-left: auto;
  margin-right: ${gF('px', 4, 6, 1366, 3840)};
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const SizeRowContent = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: clamp(28px, ${gF('px', 28, 32, 1366, 3840)}, 32px);
  align-items: center;
  grid-template-rows: 1fr;
`

export const Sizes = styled.div`
  display: grid;
  grid-template-columns: repeat(6, minmax(auto, 1fr));
  grid-column-gap: 15px;
  align-items: center;
  height: fit-content;
  grid-row-gap: clamp(35px, ${gF('px', 35, 56.1, 1366, 3840)}, 56.1px);
`

export const SizeWrapper = styled.div`
  color: ${palette.cloudBurst};
  display: flex;
  justify-content: flex-end;
  gap: clamp(10px, ${gF('px', 10, 24.6, 1366, 3840)}, 24.6px);
  align-items: center;
  height: fit-content;
`

export const SizeLabel = styled.label`
  font-size: clamp(16px, ${gF('px', 16, 36, 1366, 3840)}, 36px);
  font-family: GilmerBold;

  &.disabled {
    color: ${palette.santasGray};
  }
`

export const SizeAvailability = styled.p`
  font-weight: bold;
  font-size: clamp(14px, ${gF('px', 14, 34, 1366, 3840)}, 34px);
  max-width: clamp(58px, ${gF('px', 58, 140.9, 1366, 3840)}, 140.9px);
  width: 100%;
  letter-spacing: 0.7px;

  @media (min-width: ${breakpoints.L}) {
    letter-spacing: 1.7px;
  }

  @media (max-width: ${breakpointsCross.M.max}) {
    width: 100%;
  }
`

export const SizeInputContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  position: relative;
  max-width: clamp(60px, ${gF('px', 120, 291.4, 1366, 3840)}, 291.4px);

  > *:first-child {
    width: 100%;
    border: clamp(1px, ${gF('px', 1, 2.4, 1366, 3840)}, 2.4px) solid ${palette.platinum};
    border-top-left-radius: clamp(4px, ${gF('px', 4, 9.7, 1366, 3840)}, 9.7px);
    border-bottom-left-radius: clamp(4px, ${gF('px', 4, 9.7, 1366, 3840)}, 9.7px);
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right-style: none;
  }

  > *:last-child {
    width: 100%;
    border: clamp(1px, ${gF('px', 1, 2.4, 1366, 3840)}, 2.4px) solid ${palette.platinum};

    &.sizeAvailabilityAlert {
      border-color: ${palette.darkYellow};
      background-color: ${palette.papayaWhip} !important;
      color: ${palette.goldDrop};
    }

    border-top-right-radius: clamp(4px, ${gF('px', 4, 9.7, 1366, 3840)}, 9.7px);
    border-bottom-right-radius: clamp(4px, ${gF('px', 4, 9.7, 1366, 3840)}, 9.7px);
  }
`

export const SizeContainer = styled.div`
  flex-grow: 1;
  display: grid;
  column-gap: clamp(10px, ${gF('px', 10, 24.6, 1366, 3840)}, 24.6px);
  row-gap: 0.25vw;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  place-items: center;
  position: relative;
`

export const SizeAvailabilityContainer = styled.div`
  align-items: center;
  display: flex;
  background-color: ${palette.extraLight};
  width: 100%;

  &.disabled {
    color: ${palette.santasGray};
  }

  @media (max-width: ${breakpointsCross.M.max}) {
    flex: 1;
  }
`

export const Debug = styled.span`
  position: absolute;
  bottom: 100%;
  left: 20%;
`
