import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import AppointmentBrand from '../../components/AppointmentBrand'
import AppointmentStatus from '../../components/AppointmentStatus'
import DigitalEventsFooter from '../../components/DigitalEventsFooter/DigitalEventsFooter'
import DigitalEventsHeader from '../../components/DigitalEventsHeader/DigitalEventsHeader'
import useFetchAppointments from '../../hooks/useFetchAppointments'
import useFetchBackgrounds from '../../hooks/useFetchBackgrounds'
import useFetchInitiatives from '../../hooks/useFetchInitiatives'
import {
  initiativeBackgroundSelector,
  initiativeSelector,
  selectInitiativeAppointment,
} from '../../store/digitalEvents/selectors'
import { Main, PageWrapper, ScrollContainer } from '../../style/DigitalEventsCommonComponents'
import InitiativeVideo from './components/InitiativeVideo'
import BackgroundVideo from '../../components/BackgroundVideo'

const Wrapper = styled(PageWrapper)``

const MainStyled = styled(Main)`
  display: flex;
  align-items: center;
`

const Grid = styled.div`
  grid-template-columns: 5fr 2fr 5fr;
  width: 100%;
  display: grid;
  align-items: center;
`

const DigitalEventsInitiativePage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const background = useSelector(initiativeBackgroundSelector)
  const initiative = useSelector(initiativeSelector(id))
  const appointment = useSelector(selectInitiativeAppointment(initiative?.category))
  const brands = appointment?.brands || []

  useFetchAppointments()

  useFetchBackgrounds()

  useFetchInitiatives()

  return (
    <Wrapper background={background.image}>
      <BackgroundVideo background={background.video} />
      <ScrollContainer>
        <DigitalEventsHeader />
        <MainStyled>
          <Grid>
            <InitiativeVideo initiative={initiative} />

            <AppointmentBrand appointmentBrands={brands} />

            <AppointmentStatus appointment={appointment} />
          </Grid>
        </MainStyled>
        <DigitalEventsFooter />
      </ScrollContainer>
    </Wrapper>
  )
}

export default DigitalEventsInitiativePage
