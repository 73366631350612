import React, { useCallback, useEffect, useRef, useState } from 'react'
import { isIPadView } from '../../../libs/url'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  flex: 1;
`

type Props = {
  tollerance?: number
}

function zoomable<P>(Component: React.FC<P>) {
  const ZoomableImage: React.VFC<P & Props> = props => {
    const { tollerance } = props

    const [xCord, setXCord] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [isZoomingActive, setIsZoomingActive] = useState(false)
    const [xOffset, setXOffset] = useState(0)
    const [yOffset, setYOffset] = useState(0)
    const [xInitial, setXInitial] = useState(0)
    const [yInitial, setYInitial] = useState(0)
    const [lastTap, setLastTap] = useState(0)

    const ref = useRef<HTMLDivElement>(null)
    const refTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)

    const toggleScroll = (state: boolean) => {
      if (document.getElementsByClassName('pdp-container')[1] !== undefined) {
        const element = document.getElementsByClassName('pdp-container')[1] as HTMLElement
        element.style.overflow = state ? 'auto' : 'hidden'
      }
    }

    const zoomingOn = useCallback(() => {
      toggleScroll(false)
      setIsZoomingActive(true)
    }, [])

    const zoomingOff = useCallback(() => {
      toggleScroll(true)
      setTimeout(() => {
        setIsZoomingActive(false)
        setXOffset(0)
        setYOffset(0)
      }, 100)
    }, [])

    const onDoubleClick = () => {
      setZoom(prev => {
        const isZoomed = prev > 1

        setXOffset(prev => (isZoomed ? 0 : prev))
        setYOffset(prev => (isZoomed ? 0 : prev))
        setXInitial(prev => (isZoomed ? 0 : prev))
        setYInitial(prev => (isZoomed ? 0 : prev))

        return isZoomed || isIPadView() ? 1 : 2
      })
    }

    const onGestureChange = (e: any) => {
      e.preventDefault()

      if (isIPadView()) return

      setZoom(e.scale < 1 ? 1 : e.scale)
    }

    const onTouchStart = (e: React.TouchEvent) => {
      let xInitial, yInitial

      if (zoom > 1) {
        toggleScroll(false)
        xInitial = e.touches[0].clientX - xOffset
        yInitial = e.touches[0].clientY - yOffset
      }

      setXCord(e.touches[0].clientX || 0)
      setXInitial(xInitial || 0)
      setYInitial(yInitial || 0)
    }

    const onTouchMove = (e: React.TouchEvent) => {
      const { clientX, clientY } = e.touches[0]

      if (zoom > 1) {
        if (isZoomingActive) return
        const currentX = clientX - xInitial
        const currentY = clientY - yInitial

        setXOffset(currentX)
        setYOffset(currentY)
        return
      }

      if (clientX > xCord + (tollerance || 0) || xCord - clientX > (tollerance || 0)) {
        setXCord(clientX || 0)
      }
    }

    const onTouchEnd = (e: React.TouchEvent) => {
      toggleScroll(true)

      const currentTime = new Date().getTime()
      const tapLength = currentTime - lastTap
      if (refTimeout.current) {
        clearTimeout(refTimeout.current)
      }
      if (tapLength > 0 && tapLength < 200) {
        if (!isZoomingActive) onDoubleClick()
        e.preventDefault()
      } else {
        refTimeout.current = setTimeout(() => {
          if (refTimeout.current) {
            clearTimeout(refTimeout.current)
          }
        }, 500)
      }
      setLastTap(currentTime)

      setXInitial(xOffset)
      setYInitial(yOffset)
      setLastTap(lastTap)
    }

    const getStylesObject = () => {
      return {
        transform: `translate3D(${xOffset}px, ${yOffset}px, 0px) scale(${zoom})`,
      }
    }

    useEffect(() => {
      ref.current?.addEventListener('gesturestart', zoomingOn)
      ref.current?.addEventListener('gestureend', zoomingOff)
      ref.current?.addEventListener('gesturechange', onGestureChange)

      return () => {
        ref.current?.removeEventListener('gesturestart', zoomingOn)
        ref.current?.removeEventListener('gestureend', zoomingOff)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ref.current?.removeEventListener('gesturechange', onGestureChange)
      }
    }, [zoomingOff, zoomingOn])

    return (
      <Wrapper
        ref={ref}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        onDoubleClick={onDoubleClick}
        className="zoomable"
      >
        <Component {...props} zoomStyle={getStylesObject()} zoomActive={zoom > 1} />
      </Wrapper>
    )
  }

  return ZoomableImage
}

export default zoomable
