import React from 'react'
import { Col } from 'antd'
import { breakpointsNoUnit, getFluidSizeWithFullFormula, palette } from '../../../style/theme'

import Row from '../../../styleguide/Row'

import styled from 'styled-components'

const MoodboardHugeTiles = styled(Row)`
  width: 100%;
  margin: 2vh 0;
  display: flex;
  justify-content: space-between;
  &::before,
  &::after {
    display: none;
  }
  div {
    width: 49%;
    height: ${getFluidSizeWithFullFormula('vh', 30, 50, 1024, breakpointsNoUnit.XL)};
    overflow: hidden;
    background: ${palette.wildSand};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    img {
      width: auto;
      max-height: 100%;
      max-width: 100%;
    }
  }
  @media screen and (max-width: 1366px) {
    div {
      border-radius: 4px;
    }
  }
`

type Props = {
  image1: string
  image2: string
  image3: string
}

const HugeTiles: React.FC<Props> = ({ image1, image2, image3 }) => (
  <MoodboardHugeTiles>
    <Col>{image1 ? <img src={image1} /> : image3 && <img src={image3} />}</Col>
    <Col>{image2 ? <img src={image2} /> : image3 && <img src={image3} />}</Col>
  </MoodboardHugeTiles>
)

export default HugeTiles
