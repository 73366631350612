import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { firstLetterCapital } from '../../../DigitalEventsDashboard/style'
import { ToggleFilters } from '../../../../model/filters'
import { BrandCode } from '../../../../model/model'
import checkoutActions from '../../../../store/checkout/actions'
import {
  selectCheckoutBrands,
  selectCheckoutFilters,
  selectCheckoutFiltersInitvalues,
} from '../../../../store/checkout/selectors'
import { breakpoints, duration, palette, pxToRem, spacing } from '../../../../style/theme'
import { CustomInputFilter } from '../Common'
import ArrowFilterIcon from '../../../../components/icons/ArrowFilterIcon'
import FilterCategory from '../../../../components/ModalFilterBy/FilterCategory'
import TableCartAnalyticsBrands from '../../../../components/TableCart/TableCartAnalytics/TableCartAnalyticsBrands'
import DateMenu from './DateMenu'
import { CheckoutFiltersRecapPanel } from './FiltersRecapPanelCheckout'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${pxToRem(spacing(6))}rem;
  padding: ${pxToRem(spacing(2))}rem;
  padding-bottom: 0;
  text-transform: uppercase;

  input {
    text-transform: uppercase;
  }
`

const Backdrop = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;

  &.hide {
    pointer-events: none;
  }
`

const SelectBrands = styled(TableCartAnalyticsBrands)`
  padding: 0;

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    height: 36.3px;
    border-radius: 0.25rem;
    line-height: 1.5;
    font-size: 14px;
  }

  .ant-collapse {
    min-width: 100%;
    border-radius: 6px;
    background-color: transparent;
    border-bottom: 1px solid #d9d9d9;

    > .ant-collapse-item {
      border-bottom: none;
    }

    .ant-collapse-header {
      color: inherit;
      box-shadow: none;
      padding-left: 0.5rem;
    }
  }

  .ant-collapse-item.ant-collapse-item-active .anticon {
    transform: translateY(-60%) rotate(180deg);
  }

  .ant-collapse-item:not(.ant-collapse-item-active) .anticon {
    transform: translateY(-60%) rotate(90deg);
  }
`

const WrapperSxFilter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5vh;
`

const WrapperCenterFilter = WrapperSxFilter

const InputWrapper = styled.div`
  position: relative;
  padding: ${pxToRem(spacing(1))}rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  border: solid 1px ${palette.alto};
  height: 36.3px;
  text-transform: uppercase;

  img,
  svg {
    width: 10px;
    height: 12px;
    transform: scaleY(1.3);
    margin-right: 0;
    transition: transform ${duration.short}ms;
  }

  &.is-open svg {
    transform: rotate(180deg);
  }
`

const Arrow = styled(ArrowFilterIcon)`
  margin-right: ${pxToRem(spacing(1))}rem;
  cursor: pointer;

  &.hidden {
    display: none;
  }
`

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${palette.tangaroa};
  text-transform: uppercase;
`

const FilterCategoryItem = styled(FilterCategory)`
  .filter-wrapper-vertical {
    display: flex;
    flex-direction: column;
  }

  .filter-wrapper-horizontal {
    display: flex;
    flex-wrap: wrap;
  }

  .ant-checkbox-wrapper {
    font-size: inherit;
    margin: 0 0 1em 0;

    @media screen and (max-width: ${breakpoints.S}) {
      margin: 0 0 1.3em 0;
    }
  }

  .ant-radio-wrapper {
    margin: 0 0 1em 0;

    @media screen and (max-width: ${breakpoints.S}) {
      margin: 0 0 1.3em 0;
    }
  }

  .ant-checkbox {
    & + span {
      padding-right: 0;
    }

    .ant-checkbox-inner {
      border: 0.9px solid ${palette.viking};
      border-radius: ${pxToRem(3.6)}rem;
      background-color: ${palette.selago};

      &:after {
        border-color: ${palette.viking};
      }
    }
  }

  .ant-radio {
    .ant-radio-inner {
      background-color: ${palette.selago};

      &:after {
        border-color: ${palette.viking};
      }
    }
  }
`

const FiltersRecapPanel = styled(CheckoutFiltersRecapPanel)`
  background: ${palette.zircon};
  margin: 0;
`

export const ModalFiltersCheckout: React.FC = () => {
  const { t } = useTranslation()

  const [layerOpened, setLayerOpened] = useState('' as '' | 'brands' | 'date' | 'quantity')

  const filters = useSelector(selectCheckoutFilters)
  const filtersInitValues = useSelector(selectCheckoutFiltersInitvalues)
  const checkoutBrands = useSelector(selectCheckoutBrands)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(checkoutActions.initFilters(filtersInitValues))
    dispatch(checkoutActions.updateFiltersAndCounters())
  }, [dispatch, filtersInitValues])

  const toggleSelectedFilters: ToggleFilters = (filterName: any, optionId) => {
    const options = optionId === '1' ? ['1', 'E'] : optionId === '2' ? ['2', 'K'] : optionId
    return dispatch(() => {
      dispatch(
        checkoutActions.setSelectedFilterOptions({
          key: filterName,
          selectedOptions: Array.isArray(options) ? options : [options],
        }),
      )
      dispatch(checkoutActions.updateFiltersAndCounters())
    })
  }
  const counter = {
    '1': (filters?.category?.counter['1'] || 0) + (filters?.category?.counter['E'] || 0),
    '2': (filters?.category?.counter['2'] || 0) + (filters?.category?.counter['K'] || 0),
  }

  const categoryMap: Record<string, { id: string; label: string }> = {
    '1': {
      id: '1',
      label: 'optical',
    },
    E: {
      id: '1',
      label: 'optical',
    },
    '2': {
      id: '2',
      label: 'sun',
    },
    K: {
      id: '2',
      label: 'sun',
    },
  }

  const options = [...new Set(filters.category.options.map(option => categoryMap[option].id))].map(
    id => ({
      id,
      label: categoryMap[id].label,
      name: categoryMap[id].label,
    }),
  )

  return (
    <Wrapper>
      <Backdrop
        className={[layerOpened ? '' : 'hide'].join(' ')}
        onClick={() => setLayerOpened('')}
      />
      <WrapperSxFilter>
        <SelectBrands
          brands={checkoutBrands}
          onChangeSelectedBrands={(selectedBrands: BrandCode[]) => {
            dispatch(
              checkoutActions.setSelectedFilterOptions({
                key: 'brand',
                selectedOptions: selectedBrands,
              }),
            )
            dispatch(checkoutActions.updateFiltersAndCounters())
          }}
          selectedBrands={filters.brand.selected}
          useBrandGroup={true}
        />

        <InputWrapper
          className={layerOpened === 'date' ? 'is-open' : ''}
          onClick={() => setLayerOpened(layerOpened === 'date' ? '' : 'date')}
        >
          <div>{firstLetterCapital(t('Checkout.Process.Filters.DeliveryDate'))}</div>
          <Arrow />
          <DateMenu
            open={layerOpened === 'date'}
            dates={filters.deliveryDate.counter}
            selectedDates={filters.deliveryDate.selected}
            onChange={(dates: string[]) => {
              dispatch(
                checkoutActions.setSelectedFilterOptions({
                  key: 'deliveryDate',
                  selectedOptions: dates,
                }),
              )
              dispatch(checkoutActions.updateFiltersAndCounters())
            }}
          />
        </InputWrapper>

        <CustomInputFilter
          type="input"
          value={filters.model.selected}
          placeholder={t('Checkout.Process.Filters.Model')}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(
              checkoutActions.setSelectedFilterString({
                key: 'model',
                value: ev.currentTarget.value,
              }),
            )
            dispatch(checkoutActions.updateFiltersAndCounters())
          }}
        />
      </WrapperSxFilter>

      <WrapperCenterFilter>
        <InputWrapper
          className={layerOpened === 'quantity' ? 'is-open' : ''}
          onClick={() => setLayerOpened(layerOpened === 'quantity' ? '' : 'quantity')}
        >
          <div>{firstLetterCapital(t('Checkout.Process.Filters.Quantity'))}</div>
          <Arrow />
          <DateMenu
            open={layerOpened === 'quantity'}
            dates={filters.quantity.counter}
            selectedDates={filters.quantity.selected}
            onChange={(dates: string[]) => {
              dispatch(
                checkoutActions.setSelectedFilterOptions({
                  key: 'quantity',
                  selectedOptions: dates,
                }),
              )
              dispatch(checkoutActions.updateFiltersAndCounters())
            }}
          />
        </InputWrapper>

        <CustomInputFilter
          type="input"
          value={filters.reference.selected}
          placeholder={t('Checkout.Process.Filters.Reference')}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(
              checkoutActions.setSelectedFilterString({
                key: 'reference',
                value: ev.currentTarget.value,
              }),
            )
            dispatch(checkoutActions.updateFiltersAndCounters())
          }}
        />

        <LabelWrapper>
          {filters.category && (
            <FilterCategoryItem
              filterName="category"
              translationLabel="category"
              filter={{
                ...filters.category,
                counter,
                options,
              }}
              type="radio"
              toggleSelectedFilters={toggleSelectedFilters}
            />
          )}
          {filters.release && (
            <FilterCategoryItem
              filterName="release"
              filter={{
                ...filters.release,
                options: filters.release.options.map(id => ({ id, label: id, name: id })),
              }}
              type="radio"
              toggleSelectedFilters={toggleSelectedFilters}
            />
          )}
        </LabelWrapper>
      </WrapperCenterFilter>

      <div>
        <FiltersRecapPanel />
      </div>
    </Wrapper>
  )
}

export default ModalFiltersCheckout
