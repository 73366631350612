import React from 'react'
import { match, Redirect, Switch } from 'react-router-dom'

import PrivateRoute from '../../components/PrivateRoute'
import AfaCart from '../../containers/AfaCart/AfaCart'
import AfaCategoriesPage from '../../containers/AfaCategoriesPage'
import AfaCheckoutAddressesPage from '../../containers/AfaCheckout/AddressesPage/AfaCheckoutAddressesPage'
import AfaCheckoutThankYouPage from '../../containers/AfaCheckout/AfaThankYouPage/AfaCheckoutThankYou'
import AfaCheckoutImportExportPage from '../../containers/AfaCheckout/ImportExportPage/index'
import AfaCheckoutProcessPage from '../../containers/AfaCheckout/ProcessPage/AfaCheckoutProcessPage'
import AfaChooseBrandPage from '../../containers/AfaChooseBrandPage'
import AfaKeylooks from '../../containers/AfaKeylooks/AfaKeylooks'
import AfaPdp from '../../containers/AfaPdp/AfaPdp'
import AfaPlp from '../../containers/AfaPlp/AfaPlp'
import AfaSearchResultPage from '../../containers/AfaPlp/AfaSearchResultPage'
import CategoriesPage from '../../containers/CategoriesPage'
import CheckoutPage from '../../containers/CheckoutPage/index'
import ChooseBrandPage from '../../containers/ChooseBrandPage'
import ContactManagementPage from '../../containers/ContactManagementPage'
import FilteredPLP from '../../containers/FilteredPLP/FilteredPLP'
import SingleBrandPage from '../../containers/SingleBrandPage/SingleBrandPage'
import SmartlookTestPage from '../../containers/SmartlookTestPage'
import TableCartContainer from '../../containers/TableCartContainer'
import TableWishlistPage from '../../containers/TableWishlistPage'
import AssortmentAdvisorRoutes from '../../flows/AssortmentAdvisor/Routes'
import WhiteboardsListPage from '../../containers/Whiteboard/WhiteboardsListPage'
import Index from '../../containers/Whiteboard/WhiteboardPage'
import DownloadPreview from '../../containers/Whiteboard/components/DownloadPreview'

const TableViewRoutes: React.FC<{ match: match | null }> = ({ match }) => {
  return (
    <Switch>
      <PrivateRoute path={`${match?.url}/assortment-advisor`} component={AssortmentAdvisorRoutes} />
      <PrivateRoute
        exact
        path={`${match?.url}/contact-management`}
        component={ContactManagementPage}
      />
      <PrivateRoute exact path={`${match?.url}/smartlooktestpage`} component={SmartlookTestPage} />
      <PrivateRoute
        exact
        path={`${match?.url}/:assortment/choose-brand`}
        component={AfaChooseBrandPage}
      />
      <PrivateRoute exact path={`${match?.url}/choose-brand`} component={ChooseBrandPage} />
      <PrivateRoute
        exact
        path={`${match?.url}/:assortment/single-brand/:brandCode`}
        component={AfaCategoriesPage}
      />
      <PrivateRoute exact path={`${match?.url}/:assortment/pdp/:modelCode`} component={AfaPdp} />
      <PrivateRoute
        exact
        path={`${match?.url}/:assortment/search-results`}
        component={AfaSearchResultPage}
      />
      <PrivateRoute
        exact
        path={`${match?.url}/single-brand/:brandSlug`}
        component={CategoriesPage}
      />
      <PrivateRoute
        exact
        path={`${match?.url}/:assortment/single-brand/:brandCode/key-looks`}
        component={AfaKeylooks}
      />
      <PrivateRoute
        exact
        path={`${match?.url}/:assortment/single-brand/:brandCode/:category`}
        component={AfaPlp}
      />
      <PrivateRoute
        exact
        path={`${match?.url}/single-brand/:brandSlug/:category`}
        component={SingleBrandPage}
      />
      <PrivateRoute exact path={`${match?.url}/cart`} component={TableCartContainer} />
      <PrivateRoute exact path={`${match?.url}/:assortment/cart`} component={AfaCart} />
      <PrivateRoute exact path={`${match?.url}/wishlist`} component={TableWishlistPage} />
      <PrivateRoute exact path={`${match?.url}/products`} component={FilteredPLP} />
      <PrivateRoute exact path={`${match?.url}/products/:category`} component={FilteredPLP} />
      <PrivateRoute exact path={`${match?.url}/checkout`} component={CheckoutPage} />
      <PrivateRoute
        exact
        path={`${match?.url}/:assortment/checkout`}
        component={AfaCheckoutProcessPage}
      />
      <PrivateRoute
        exact
        path={`${match?.url}/:assortment/checkout/import-export`}
        component={AfaCheckoutImportExportPage}
      />
      <PrivateRoute
        exact
        path={`${match?.url}/:assortment/checkout/addresses`}
        component={AfaCheckoutAddressesPage}
      />
      <PrivateRoute
        exact
        path={`${match?.url}/:assortment/checkout/thank-you`}
        component={AfaCheckoutThankYouPage}
      />
      <PrivateRoute
        exact
        path={`${match?.url}`}
        component={() => <Redirect to={`${match?.url}/choose-brand`} />}
      />
      <PrivateRoute
        exact
        path={`${match?.url}/:assortment/:brandCode/whiteboard`}
        component={WhiteboardsListPage}
      />
      <PrivateRoute
        exact
        path={`${match?.url}/:assortment/:brandCode/whiteboard/:whiteboardId`}
        component={Index}
      />
      <PrivateRoute
        exact
        path={`${match?.url}/:assortment/:brandCode/pdf-preview/:id`}
        component={DownloadPreview}
      />
    </Switch>
  )
}

export default TableViewRoutes
