import styled from 'styled-components'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  breakpoints,
  breakpointsNoUnit,
  getFluidSize,
  getFluidSizeWithFullFormula,
  minSupportedWidth,
  palette,
  pxToRem,
} from '../../../style/theme'
import { useTranslation } from 'react-i18next'
import { selectedEventSelector } from '../../../store/app/selectors'
import { useParams } from 'react-router-dom'
import { getDeviceAbsolutePath } from '../../../libs/url'
import { useHistory } from 'react-router'

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${getFluidSizeWithFullFormula('px', 2, 12, minSupportedWidth, breakpointsNoUnit.M)};

  @media (min-width: ${breakpoints.L}) {
    order: 4;
  }
`

const HeaderCheckout = styled.div`
  padding: ${getFluidSize(`${pxToRem(10)}rem`, 15)} 2vw;
  background-color: ${palette.tangaroa};
  color: ${palette.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: ${pxToRem(64)}rem;
`

const HeaderLeftElement = styled.div`
  letter-spacing: -0.32px;
  font-size: clamp(16px, ${getFluidSizeWithFullFormula('px', 16, 24, 1366, 3840)}, 24px);

  @media (min-width: ${breakpoints.L}) {
    letter-spacing: 0.3px;
  }
`

const HeaderButtonWrapper = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid ${palette.shadowBlue};

  @media (min-width: ${breakpoints.L}) {
    border-width: 2px;
  }
`

const LinkAsButton = styled.div`
  font-size: clamp(14px, ${getFluidSizeWithFullFormula('px', 14, 20, 1366, 3840)}, 20px);
  font-weight: bold;
  letter-spacing: 0.4px;
  color: ${palette.white};
  background-color: ${palette.tangaroa};
  padding-inline: clamp(20px, ${getFluidSizeWithFullFormula('px', 20, 76, 1366, 3840)}, 76px);

  &.active {
    color: ${palette.tangaroa};
    background-color: ${palette.white};
  }

  @media (min-width: ${breakpoints.L}) {
    letter-spacing: -0.6px;
  }

  padding-block: clamp(10px, ${getFluidSizeWithFullFormula('px', 10, 17, 1366, 3840)}, 17px);
  border-radius: 4px;
  cursor: pointer;
`

const ForwardSlashSpan = styled.span`
  @media (min-width: ${breakpoints.L}) {
    &:before {
      content: ' ';
    }

    &:after {
      content: ' ';
    }
  }
`

type Props = {
  hideImportSwitch?: boolean
  activeLink?: 'process' | 'import-export'
}

const AfaSubHeader: React.FC<Props> = ({ hideImportSwitch, activeLink }) => {
  const { assortment } = useParams<{
    assortment: string
  }>()

  const { t } = useTranslation()
  const history = useHistory()

  const event = useSelector(selectedEventSelector)

  return (
    <HeaderWrapper>
      <HeaderCheckout>
        <HeaderLeftElement>{event?.description}</HeaderLeftElement>

        {!hideImportSwitch && (
          <HeaderButtonWrapper>
            <LinkAsButton
              className={activeLink === 'process' ? 'active' : ''}
              onClick={() => history.push(`${getDeviceAbsolutePath()}/${assortment}/checkout`)}
            >
              {t('Checkout.Header.ProcessOrder')}
            </LinkAsButton>
            <LinkAsButton
              className={activeLink === 'import-export' ? 'active' : ''}
              onClick={() =>
                history.push(`${getDeviceAbsolutePath()}/${assortment}/checkout/import-export`)
              }
            >
              {t('Checkout.Header.Import')}
              <ForwardSlashSpan>/</ForwardSlashSpan>
              {t('Checkout.Header.Export')}
            </LinkAsButton>
          </HeaderButtonWrapper>
        )}
      </HeaderCheckout>
    </HeaderWrapper>
  )
}

export default AfaSubHeader
