import { useDispatch, useSelector } from 'react-redux'

import app_config from '../config/app/config'
import { getCLensToMocoCode } from '../libs/productsV2'
import socketManager from '../libs/socketManager'
import { isPageMatches } from '../libs/url'
import { TDispatch } from '../model/model'
import { PdpState } from '../model/pdp'
import { Moco } from '../model/product'
import {
  closeSwitchModals,
  loadPdpContent,
  switchCartType,
  switchPdpType,
  toggleGridView,
} from '../store/actions'
import { customerIdSelector, eventIdSelector } from '../store/app/selectors'
import { couvetteVideoSelector } from '../store/couvetteVideo/selectors'
import {
  isPdpVisibleSelector,
  isSeethroughModalVisibleSelector,
  pdpMocosSortedSelector,
  pdpSeeThemOnAvailableForMocosSelector,
  pdpSelector,
} from '../store/pdp/selectors'

const handleSwitchPdp = ({
  pdp,
  customerId,
  eventId,
  couvetteVideo,
  mocos,
  isSeethroughModalVisible,
  seeThemOnAvailableForMocos,
  dispatch,
}: {
  customerId: string
  pdp: PdpState
  mocos: Moco[] | null
  eventId: string
  couvetteVideo: unknown
  isSeethroughModalVisible: boolean
  seeThemOnAvailableForMocos: unknown
  dispatch: TDispatch
}) => {
  if (!pdp.couvette) return

  const index = mocos ? mocos?.findIndex(moco => moco.mocoCode === pdp.code) : -1

  if (mocos && index > -1) {
    const { content, couvette, modelCode, code, skuChangedByScrolling } = pdp
    const mocoCode = mocos[index].mocoCode
    const allMocoCodes = mocos.map(moco => moco.mocoCode)
    const cLensAndMocos = getCLensToMocoCode(mocos)

    if (!content[mocoCode]) {
      dispatch(
        loadPdpContent({
          mocos: allMocoCodes,
          cLensAndMocos,
          brand: pdp.couvette.brandCode,
        }),
      )
    }

    socketManager.emitSignal('switch_pdp_view', {
      customer: pdp.viewType === app_config.viewType.table && customerId,
      content: content && content[mocoCode],
      couvette,
      customerId,
      eventId,
      modelCode,
      viewType: pdp.viewType,
      code,
      skuChangedByScrolling,
      couvetteVideo,
      isSeethroughModalVisible,
      seeThemOnAvailableForMocos,
    })
    dispatch(switchPdpType())
  }
}

export const useHandleTableWallPageSwitch = () => {
  const dispatch = useDispatch()
  const isPdpVisible = useSelector(isPdpVisibleSelector)
  const customerId = useSelector(customerIdSelector)
  const eventId = useSelector(eventIdSelector)
  const couvetteVideo = useSelector(couvetteVideoSelector)
  const isSeethroughModalVisible = useSelector(isSeethroughModalVisibleSelector)
  const mocos = useSelector(pdpMocosSortedSelector)
  const seeThemOnAvailableForMocos = useSelector(pdpSeeThemOnAvailableForMocosSelector)
  const pdp = useSelector(pdpSelector)

  const handleTableWallPageSwitch = () => {
    // Close modals before switch
    dispatch(closeSwitchModals())

    if (isPdpVisible) {
      // In case we open a pdp from the cart page, this condition has the
      // priority. We want the switch pdp to be triggered, not the cart one.
      handleSwitchPdp({
        pdp,
        customerId,
        eventId,
        couvetteVideo,
        mocos,
        isSeethroughModalVisible,
        seeThemOnAvailableForMocos,
        dispatch,
      })
    } else {
      if (isPageMatches('plp')) {
        dispatch(toggleGridView())
      }
      if (isPageMatches('cart')) {
        dispatch(switchCartType())
      }
    }
  }

  return handleTableWallPageSwitch
}
