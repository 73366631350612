import React, { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PageSocketManager, { PageSocketManagerProps } from '../PageSocketManager'
import { end_video } from '../../store/couvetteVideo/actions'

const VideoSocket: React.FC<PageSocketManagerProps> = ({ children, socketIoEvent }) => {
  /**
   * This component is responsible for capturing all the video related events.
   * A video event is send in two possible cases:
   * 1) user event (play / pause buttons)
   * 2) every navigation event get any video to end (see navigationChannel on nodeJs)
   */

  const dispatch = useDispatch()

  useEffect(() => {
    if (socketIoEvent === 'do_ended_video') {
      dispatch(end_video())
    }
  }, [dispatch, socketIoEvent])

  return <Fragment>{children}</Fragment>
}

const VideoSocketManager = PageSocketManager(VideoSocket, [
  'do_play_video',
  'do_pause_video',
  'do_ended_video',
])

export { VideoSocketManager }
export default VideoSocketManager
