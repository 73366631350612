import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { AutoSizer, Grid } from 'react-virtualized'
import styled from 'styled-components'

import { arrayChunks } from '../../libs'
import { addCheckForLongPress } from '../../libs/couvettes'
import { isTouchDevice } from '../../libs/url'
import { Moco, NullableProductAttribute, Product } from '../../model/product'
import { couvettesItemsSelector, isCouvettesLoadingSelector } from '../../store/couvettes/selectors'
import { palette } from '../../style/theme'
import { LeftArrow, RightArrow } from '../Arrow/Arrow'
import GridItem from './GridItem/GridItem'

const Wrapper = styled.div`
  padding: 3.8vh 0 3.8vh 1.6vw;
  background-color: ${palette.wildSand};
  flex: 1;

  .ReactVirtualized__Grid:focus {
    outline: none;
  }
`

export const calculateRowHeight = (height: number, numberOfRows: number) => {
  return height / numberOfRows
}

export const calculateColumnWidth = (width: number) => () => {
  return Math.min(Math.round(width / 3.65), 700)
}

const scrollCouvettesListener = (
  shouldFetchPage: React.MutableRefObject<boolean>,
  scrollLeftRef: React.MutableRefObject<number>,
  getNextPage: () => void,
) => ({
  scrollLeft,
  clientWidth,
  scrollWidth,
}: {
  scrollLeft: number
  clientWidth: number
  scrollWidth: number
}) => {
  scrollLeftRef.current = scrollLeft
  if (shouldFetchPage.current && scrollLeft && scrollWidth - scrollLeft < clientWidth * 2) {
    getNextPage()
    shouldFetchPage.current = false
  }
}

const extractFirstMocoFromModels = (models: Product[]) => {
  const mocos = models.reduce((gridMocos, model) => {
    const mocosOrdered = Object.values(model.mocos).sort((m1, m2) => m1.plpOrder - m2.plpOrder)

    gridMocos.push({ modelTag: model.modelTag, ...mocosOrdered[0] })
    return gridMocos
  }, [] as (Moco & { modelTag?: NullableProductAttribute })[])

  return mocos
}

type Props = {
  getNextPage: () => void
}

const GridView: React.FC<Props> = ({ getNextPage }) => {
  const couvettesItems = useSelector(couvettesItemsSelector)
  const couvettesLoading = useSelector(isCouvettesLoadingSelector)

  const [currentSection, setCurrentSection] = useState<any>(null)
  const [currentScrollLeft, setCurrentScrollLeft] = useState(0)
  const [clientWidth, setClientWidth] = useState(0)
  const [scrollWidth, setScrollWidth] = useState(0)
  const [currentColumnWidth, setCurrentColumnWidth] = useState(0)

  const numberOfRows = 3
  const overscanColumnCount = 10

  const scrollLeft = useRef(0)
  const shouldFetchPage = useRef(true)
  const intervalReference = useRef(null)
  const gridRef = useRef<Grid>(null)
  const leftArrowRef = useRef(null)
  const rightArrowRef = useRef(null)

  const mocos = extractFirstMocoFromModels(couvettesItems)
  const products = arrayChunks(mocos, numberOfRows)

  useEffect(() => {
    if (couvettesLoading === false) {
      gridRef.current?.scrollToPosition({
        scrollLeft: scrollLeft.current,
      } as any)
      shouldFetchPage.current = true
    }
  }, [couvettesLoading])

  const isScrolledLeft = currentScrollLeft

  const isScrolledRight =
    scrollWidth - clientWidth === currentScrollLeft ||
    scrollWidth - clientWidth - currentScrollLeft < 0

  const isTouch = isTouchDevice()

  const moveRight = () => {
    addCheckForLongPress(
      'right',
      rightArrowRef.current,
      gridRef,
      intervalReference,
      clientWidth,
      () => {
        gridRef.current?.scrollToCell({ columnIndex: currentSection?.columnStopIndex, rowIndex: 0 })
      },
    )
  }

  const moveLeft = () => {
    addCheckForLongPress(
      'left',
      leftArrowRef.current,
      gridRef,
      intervalReference,
      clientWidth,
      () => {
        const computedColumnIndex =
          currentSection.columnStartIndex - Math.floor(clientWidth / currentColumnWidth)
        gridRef.current?.scrollToCell({
          columnIndex: computedColumnIndex >= 0 ? computedColumnIndex : 0,
          rowIndex: 0,
        })
      },
    )
  }

  const hasMoreItems =
    currentSection &&
    currentSection.columnStopIndex + 1 < Math.ceil(couvettesItems.length / numberOfRows)
  return (
    <Wrapper>
      {!isScrolledLeft || isTouch ? null : <LeftArrow ref={leftArrowRef} onMouseDown={moveLeft} />}
      {(isScrolledRight && !hasMoreItems && !(couvettesItems.length && couvettesLoading)) ||
      isTouch ? null : (
        <RightArrow ref={rightArrowRef} onMouseDown={moveRight} />
      )}
      <AutoSizer>
        {({ height, width }) => (
          <Grid
            cellRenderer={({ columnIndex, key, rowIndex, style }) => {
              if (products[columnIndex].length <= rowIndex) {
                return null
              }

              const { modelCode, mocoCode, brandCode, modelTag, catalogImages } = products[
                columnIndex
              ][rowIndex]

              const modelTagLabel = modelTag ? modelTag.label : ''

              return (
                <div key={key} style={{ ...style, display: 'flex' }}>
                  <GridItem
                    modelTagLabel={modelTagLabel}
                    modelCode={modelCode}
                    mocoCode={mocoCode}
                    brandCode={brandCode}
                    catalogImgPath={catalogImages.base}
                  />
                </div>
              )
            }}
            columnCount={products && products.length}
            columnWidth={() => {
              const columnWidth = calculateColumnWidth(width)
              setCurrentColumnWidth(columnWidth())
              return columnWidth()
            }}
            height={height}
            overscanColumnCount={overscanColumnCount}
            ref={gridRef}
            onSectionRendered={setCurrentSection}
            rowCount={numberOfRows}
            rowHeight={calculateRowHeight(height, numberOfRows)}
            scrollToAlignment={'start'}
            width={width}
            onScroll={e => {
              setScrollWidth(e.scrollWidth)
              setClientWidth(e.clientWidth)
              setCurrentScrollLeft(e.scrollLeft)
              scrollCouvettesListener(shouldFetchPage, scrollLeft, getNextPage)(e)
            }}
          />
        )}
      </AutoSizer>
    </Wrapper>
  )
}

export default GridView
