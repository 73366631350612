import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { getBrandByBrandCode } from '../../libs/brand'
import { Moco, Product } from '../../model/product'
import { brandsSelector } from '../../store/brands/selectors'
import { glassCSS, scrollbarCssDe } from '../../style/DigitalEventsCommonComponents'
import { getFluidFontSize, palette, pxToRem, spacing } from '../../style/theme'
import Modal from '../../styleguide/Modal'
import { MocoImage } from './MocoCard'

const MoreFeaturesModal = styled(Modal)`
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-content {
    background-color: transparent;
    ${glassCSS}
  }
  .ant-modal-body {
    font-size: ${getFluidFontSize('14px')} !important;
    display: flex;
    flex-direction: column;
  }
  .ant-modal-close {
    position: absolute;
    color: ${palette.white};
    font-size: ${getFluidFontSize('25px')};
  }
`

const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  flex: wrap;
  flex: 0;
  align-items: center;
  border-bottom: 0.5px solid ${palette.white};
  padding-bottom: ${pxToRem(spacing(4))}rem;
  margin-bottom: ${pxToRem(spacing(4))}rem;
  .modal-title {
    font-weight: bold;
    font-size: ${getFluidFontSize('35px')};
    flex: 1;
    text-transform: capitalize;
  }
`

const ModelData = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  img {
    max-width: 10em;
    height: auto;
  }
`

const ModelDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  .title {
    font-size: ${getFluidFontSize('10px')};
    text-transform: uppercase;
  }
  .model {
    font-size: ${getFluidFontSize('10px')};
    opacity: 60%;
  }
`

const FeatureList = styled.ul`
  overflow: auto;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  ${scrollbarCssDe}
  li {
    flex: 50%;
    padding-right: 1.5rem;
    font-size: 1rem;
    line-height: 2.5rem;
  }
`

const MoreFeatures: React.FC<{
  visible: boolean
  moco?: Moco
  model?: Product
  onClose?: () => void
}> = ({ visible, moco, model, onClose }) => {
  const { t } = useTranslation()
  const brands = useSelector(brandsSelector)

  if (!moco || !model) return null
  const {
    category,
    frameShape,
    frontMaterial,
    gender,
    geofit,
    lensBase,
    marketingTheme,
    templeMaterial,
    type,
    rxAble,
  } = model

  const {
    bridgeDesign,
    flex,
    progressiveFriendly,
    frontColor,
    weight,
    templeColor,
    lensColor,
    lensColorType,
    lensMaterial,
    lensProperties,
    contrastEnhancement,
    lensBaseColor,
    lightTransmission,
    lensProtection,
  } = moco
  const brand = getBrandByBrandCode(brands, moco?.brandCode)
  return (
    <MoreFeaturesModal visible={visible} onCancel={onClose} style={{ minWidth: '50vw' }}>
      <ModalHead>
        <span className="modal-title">{t('Highlight.more_features')}</span>
        <ModelData>
          <MocoImage key={moco.mocoCode} moco={moco} />
          <ModelDetails>
            <span className="title">
              {brand?.brand} {model.styleName?.label}
            </span>
            <span className="model">{moco.mocoCode.replaceAll('__', '-')}</span>
          </ModelDetails>
        </ModelData>
      </ModalHead>
      <FeatureList>
        {category && (
          <li>
            {t('ProductPage.item_category')} <span>{category.label}</span>
          </li>
        )}
        {gender && (
          <li>
            {t('ProductPage.item_gender')} <span>{gender.label}</span>
          </li>
        )}
        {marketingTheme && (
          <li>
            {t('ProductPage.item_theme')} <span>{marketingTheme.label}</span>
          </li>
        )}
        {frameShape && (
          <li>
            {t('ProductPage.item_shape')} <span>{frameShape.label}</span>
          </li>
        )}
        {frontMaterial && (
          <li>
            {t('ProductPage.item_frontmaterial')} <span>{frontMaterial.label}</span>
          </li>
        )}
        {templeMaterial && (
          <li>
            {t('ProductPage.item_templematerial')} <span>{templeMaterial.label}</span>
          </li>
        )}
        {type && (
          <li>
            {t('ProductPage.item_type')} <span>{type.label}</span>
          </li>
        )}
        {bridgeDesign && (
          <li>
            {t('ProductPage.item_bridgedesign')} <span>{bridgeDesign}</span>
          </li>
        )}
        {geofit && (
          <li>
            {t('ProductPage.item_geofit')} <span>{geofit.label}</span>
          </li>
        )}
        {lensBase && (
          <li>
            {t('ProductPage.item_base')} <span>{lensBase.label}</span>
          </li>
        )}
        {flex && (
          <li>
            {t('ProductPage.item_flex')} <span>{flex}</span>
          </li>
        )}
        {rxAble && (
          <li>
            {t('ProductPage.item_rxable')} <span>{rxAble.label}</span>
          </li>
        )}
        {progressiveFriendly && (
          <li>
            {t('ProductPage.item_progressivefriendly')} <span>{progressiveFriendly.label}</span>
          </li>
        )}
        {weight > 0 && (
          <li>
            {t('ProductPage.item_weight')} <span>{weight}</span>
          </li>
        )}
        {frontColor && (
          <li>
            {t('ProductPage.item_frontcolor')} <span>{frontColor.label}</span>
          </li>
        )}
        {templeColor && (
          <li>
            {t('ProductPage.item_templecolor')} <span>{templeColor.label}</span>
          </li>
        )}
        {lensColor && (
          <li>
            {t('ProductPage.item_lenscolor')} <span>{lensColor.label}</span>
          </li>
        )}
        {lensColorType && (
          <li>
            {t('ProductPage.item_lenscolortype')} <span>{lensColorType.label}</span>
          </li>
        )}
        {lensMaterial && (
          <li>
            {t('ProductPage.item_lensmaterial')} <span>{lensMaterial.label}</span>
          </li>
        )}
        {lensProperties && (
          <li>
            {t('ProductPage.item_lensproperty')} <span>{lensProperties.label}</span>
          </li>
        )}
        {contrastEnhancement && (
          <li>
            {t('ProductPage.item_contrastenhancement')} <span>{contrastEnhancement.label}</span>
          </li>
        )}
        {lensBaseColor && (
          <li>
            {t('ProductPage.item_lensbasecolor')} <span>{lensBaseColor.label}</span>
          </li>
        )}
        {lightTransmission && (
          <li>
            {t('ProductPage.item_lighttransmission')} <span>{lightTransmission.label}</span>
          </li>
        )}
        {lensProtection && (
          <li>
            {t('ProductPage.item_lensprotection')} <span>{lensProtection.label}</span>
          </li>
        )}
      </FeatureList>
    </MoreFeaturesModal>
  )
}

export default MoreFeatures
