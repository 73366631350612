import React from 'react'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'

// This is a copy and paste form React Router V6, remove it when React Router will be updated
// The only modification is at line 91

/**
 * Creates a URLSearchParams object using the given initializer.
 *
 * This is identical to `new URLSearchParams(init)` except it also
 * supports arrays as values in the object form of the initializer
 * instead of just strings. This is convenient when you need multiple
 * values for a given key, but don't want to use an array initializer.
 *
 * For example, instead of:
 *
 *   let searchParams = new URLSearchParams([
 *     ['sort', 'name'],
 *     ['sort', 'price']
 *   ]);
 *
 * you can do:
 *
 *   let searchParams = createSearchParams({
 *     sort: ['name', 'price']
 *   });
 *
 * @see https://reactrouter.com/docs/en/v6/utils/create-search-params
 */
export function createSearchParams(init: URLSearchParamsInit = ''): URLSearchParams {
  return new URLSearchParams(
    typeof init === 'string' || Array.isArray(init) || init instanceof URLSearchParams
      ? init
      : Object.keys(init).reduce((memo, key) => {
          const value = init[key]
          return memo.concat(Array.isArray(value) ? value.map(v => [key, v]) : [[key, value]])
        }, [] as ParamKeyValuePair[]),
  )
}

/**
 * A convenient wrapper for reading and writing search parameters via the
 * URLSearchParams interface.
 *
 * @see https://reactrouter.com/docs/en/v6/hooks/use-search-params
 */

export type ParamKeyValuePair = [string, string]

export type URLSearchParamsInit =
  | string
  | ParamKeyValuePair[]
  | Record<string, string | string[]>
  | URLSearchParams

export function useSearchParams(defaultInit?: URLSearchParamsInit) {
  if (typeof URLSearchParams === 'undefined') {
    throw new Error(
      'You cannot use the `useSearchParams` hook in a browser that does not ' +
        'support the URLSearchParams API. If you need to support Internet ' +
        'Explorer 11, we recommend you load a polyfill such as ' +
        'https://github.com/ungap/url-search-params\n\n' +
        "If you're unsure how to load polyfills, we recommend you check out " +
        'https://polyfill.io/v3/ which provides some recommendations about how ' +
        'to load polyfills only for users that need them, instead of for every ' +
        'user.',
    )
  }

  const defaultSearchParamsRef = React.useRef(createSearchParams(defaultInit))

  const location = useLocation()
  const searchParams = React.useMemo(() => {
    const searchParams = createSearchParams(location.search)

    for (const key of defaultSearchParamsRef.current.keys()) {
      if (!searchParams.has(key)) {
        defaultSearchParamsRef.current.getAll(key).forEach(value => {
          searchParams.append(key, value)
        })
      }
    }

    return searchParams
  }, [location.search])

  const history = useHistory()
  const setSearchParams = React.useCallback(
    (nextInit: URLSearchParamsInit, navigateOptions?: { replace?: boolean; state?: any }) => {
      // MOD:
      navigateOptions?.replace
        ? history.replace('?' + createSearchParams(nextInit), navigateOptions?.state)
        : history.push('?' + createSearchParams(nextInit), navigateOptions?.state)
    },
    [history],
  )

  return [searchParams, setSearchParams] as const
}
