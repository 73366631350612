import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { resetForNewClient } from '../extraActions'

const DEFAULT_STATE = {
  isOpen: false,
}

export const slice = createSlice({
  name: 'afaCartOutOfAssortment',
  initialState: DEFAULT_STATE,
  reducers: {
    setIsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpen = payload
    },
    reset: () => DEFAULT_STATE,
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, () => DEFAULT_STATE)
  },
})

export default slice.reducer
