import { toInteger } from 'lodash'
import memoize from 'memoize-one'

import { DoorShippingAddress } from '../model/cart'
import { Door, DoorsSortingBy, DoorWithUnits } from '../model/customer'

export const getDefaultDoorId = (doors?: Door[]) => {
  const mainDoor = doors && doors.find(el => el.mainDoor)
  return mainDoor ? mainDoor.id : null
}

export const hasDisabledDoors = (doors: Door[] = []) => !!doors.find(el => !el.enabled)

const sortById = (a: DoorWithUnits, b: DoorWithUnits) => {
  return toInteger(a.id) > toInteger(b.id) ? 1 : -1
}

const sortByUnits = (a: DoorWithUnits, b: DoorWithUnits) => {
  const thereAreUnits = a.units || b.units
  const result = thereAreUnits ? b.units - a.units : sortById(a, b)
  return result
}

const sortByKey = (sortingBy: DoorsSortingBy) => (a: DoorWithUnits, b: DoorWithUnits) => {
  const key = sortingBy.key as keyof DoorWithUnits
  const result = a[key] > b[key] ? -1 : 1
  return result
}

const sortByMainDoor = (a: DoorWithUnits, b: DoorWithUnits) => {
  const result = a.mainDoor > b.mainDoor ? -1 : 1
  return result
}

const sortByEnabledDoor = (a: DoorWithUnits, b: DoorWithUnits) => {
  const result = a.enabled > b.enabled ? -1 : 1
  return result
}

const mainDoorFirst = (sorter: (a: DoorWithUnits, b: DoorWithUnits) => number) => (
  a: DoorWithUnits,
  b: DoorWithUnits,
) => {
  const considerMainDoor = a.mainDoor !== b.mainDoor
  const result = considerMainDoor ? sortByMainDoor(a, b) : sorter(a, b)
  return result
}

const enabledDoorsFirst = (sorter: (a: DoorWithUnits, b: DoorWithUnits) => number) => (
  a: DoorWithUnits,
  b: DoorWithUnits,
) => {
  const considerEnabled = a.enabled !== b.enabled
  const result = considerEnabled ? sortByEnabledDoor(a, b) : sorter(a, b)
  return result
}

const applyOrder = (
  ascending: boolean,
  sorterFn: (a: DoorWithUnits, b: DoorWithUnits) => number,
) => (a: DoorWithUnits, b: DoorWithUnits) => {
  const result = sorterFn(a, b) * (ascending ? -1 : 1)
  return result
}

export const sortDoorsList = (doors: DoorWithUnits[], sortingBy: DoorsSortingBy) => {
  const sorterFn = sortingBy.key === 'units' ? sortByUnits : sortByKey(sortingBy)
  const orderedSorterFn = applyOrder(sortingBy.ascending, sorterFn)
  const sortedDoors = doors.sort(mainDoorFirst(enabledDoorsFirst(orderedSorterFn)))
  return sortedDoors
}

export const doDoorMatchSearch = (door: Door, searchQuery: string = '') =>
  !searchQuery ||
  door.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
  door.id.includes(searchQuery) ||
  door.addresses
    .map(address => Object.values(address).join(' '))
    .join(' ')
    .toLowerCase()
    .includes(searchQuery.toLowerCase())

export const getDefaultChecked = memoize((doors: Door[]) => {
  return doors.filter(item => item.enabled).map(item => item.id)
})

export const formatDoorAddress = (door?: Door): string => {
  const address = door?.addresses[0]

  if (!address) {
    return ''
  }

  return [address.name, address.zipCode, address.city].filter(text => text !== null).join(' ')
}

export const formatDoorShippingAddress = (address: DoorShippingAddress): string => {
  return [address.name, address.zipcode, address.city].filter(text => text !== null).join(' ')
}
