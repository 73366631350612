import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components'

import AfaFilters from '../../components/AfaFilters/AfaPlpFilters'
import AfaFooter from '../../components/AfaFooter'
import AfaSearch from '../../components/AfaSearch/AfaSearch'
import AfaSort from '../../components/AfaSort/AfaSort'
import AfaHeader from '../../components/Header/AfaHeader'
import useFetchInitialData from '../../hooks/useFetchInitialData'
import { useHandleOpenAfaPlpPage } from '../../hooks/useHandleOpenPages'
import { useSearchParams } from '../../hooks/useSearchParams'
import { breakpoints, pxToRem } from '../../style/theme'
import AfaPlpFooter from './AfaPlpFooter'
import AfaPlpProducts from './AfaPlpProducts'
import AfaPlpTopbar from './AfaPlpTopbar'
import { getDeviceFromUrl } from '../../libs/url'
import AfaMoodboard from '../AfaMoodboard/AfaMoodboard'
import { useDispatch, useSelector } from 'react-redux'
import { afaProductsSelector } from '../../store/afa/selectors'
import { CustomAfaModal } from '../AfaKeylooks/AfaKeylooksMain'
import { openPinModalSelector } from '../../store/whiteboard/selectors'
import whiteboardActions from '../../store/whiteboard/actions'
import WhiteboardModalPin from '../Whiteboard/WhiteboardModalPin'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  --header-height: ${pxToRem(100)}rem;
`

const Main = styled.main`
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: row;

  @media screen and (max-width: ${breakpoints.M}) {
    flex-direction: column;
  }
`

const FiltersAndProducts = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const AfaPlp: React.FC = () => {
  const location = useLocation()

  useFetchInitialData({ loadCustomer: true })
  const dispatch = useDispatch()

  const openPinModal = useSelector(openPinModalSelector)

  const { sport } = useHandleOpenAfaPlpPage()

  const device = getDeviceFromUrl()

  const [, setSearchParams] = useSearchParams()

  const products = useSelector(afaProductsSelector)
  const thereAreCachedProducts = products.length
  useEffect(() => {
    // we cannot use the searchParams from the useSearchParams hook since we'll need to include it in
    // the dependencies array and this leads to a loop
    const searchParams = new URLSearchParams(window.location.search)
    const start = searchParams.get('start')

    const isPageReload = !!start && !thereAreCachedProducts
    if (isPageReload) {
      searchParams.delete('start')
      searchParams.delete('scrollTop')
      setSearchParams(searchParams, { replace: true, state: location.state })
    }
  }, [setSearchParams, location.state, thereAreCachedProducts])

  return device === 'wall' ? (
    <AfaMoodboard sport={sport} />
  ) : (
    <Wrapper>
      <AfaSearch />

      <AfaHeader />

      <Main>
        <AfaPlpTopbar />
        <FiltersAndProducts>
          <AfaFilters />
          <AfaSort />
          <AfaPlpProducts />
        </FiltersAndProducts>
      </Main>
      <AfaPlpFooter />
      <AfaFooter />

      <CustomAfaModal
        isOpen={openPinModal}
        closeOnBackdropClick
        onRequestClose={() => {
          dispatch(whiteboardActions.setOpenPinModal(false))
          dispatch(whiteboardActions.resetItemsPinModal())
        }}
        fullscreen={false}
      >
        <WhiteboardModalPin />
      </CustomAfaModal>
    </Wrapper>
  )
}

export default AfaPlp
