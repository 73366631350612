import React, { SVGProps } from 'react'

const BackIcon: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="14"
      height="12.000496"
      viewBox="0 0 14 12.000496"
      version="1.1"
      id="svg828"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
    >
      <path
        transform="translate(-10,-11.000881)"
        d="m 22.822,22.81 a 0.668,0.668 0 0 1 -0.187,-0.488 c 0,-0.133 0.003,-0.267 0.008,-0.405 0.004,-0.137 0.007,-0.294 0.007,-0.472 0,-0.946 -0.118,-1.71 -0.354,-2.295 -0.236,-0.585 -0.624,-1.011 -1.164,-1.28 -0.54,-0.27 -1.267,-0.405 -2.182,-0.405 h -5.146 l -1.835,-0.114 2.521,2.348 1.656,1.723 c 0.06,0.06 0.104,0.135 0.134,0.221 0.03,0.086 0.045,0.175 0.045,0.267 a 0.636,0.636 0 0 1 -0.649,0.655 0.7,0.7 0 0 1 -0.492,-0.213 L 10.231,17.297 A 0.711,0.711 0 0 1 10,16.78 c 0,-0.193 0.077,-0.363 0.231,-0.51 l 4.938,-5.04 c 0.159,-0.153 0.328,-0.229 0.507,-0.229 a 0.637,0.637 0 0 1 0.649,0.656 c 0,0.091 -0.015,0.179 -0.045,0.263 a 0.588,0.588 0 0 1 -0.134,0.217 l -1.656,1.723 -2.513,2.348 1.827,-0.107 h 5.214 c 1.77,0 3.043,0.418 3.818,1.255 0.776,0.836 1.164,2.176 1.164,4.021 0,0.224 -0.004,0.426 -0.011,0.606 a 2.75,2.75 0 0 1 -0.049,0.446 1.083,1.083 0 0 1 -0.111,0.282 0.588,0.588 0 0 1 -0.206,0.21 0.611,0.611 0 0 1 -0.324,0.08 0.639,0.639 0 0 1 -0.477,-0.19"
      />
    </svg>
  )
}

export default BackIcon
