import RCIcon from '../../../components/UI/RCIcon'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { filtersOpenSelector, getSelectedSwitch } from '../../../store/whiteboard/selectors'
import classnames from 'classnames'
import whiteboardActions from '../../../store/whiteboard/actions'
import { Filter, Wrapper, Sort, Arrow, Selection } from './style'
import { SORTING_CRITERIA } from '../../../model/whiteboard'
import WhiteboardSort from '../components/WhiteboardSort'
import { useSearchParams } from '../../../hooks/useSearchParams'

type Props = {
  filteredBoards: number
  totalBoards: number
}

const SORTING_VALUES: { label: string; value: SORTING_CRITERIA }[] = [
  { label: 'Whiteboard.mostRecent', value: 'date' },
  { label: 'Whiteboard.alphabeticalOrder', value: 'name' },
]

const WhiteboardToolbar = ({ totalBoards, filteredBoards }: Props) => {
  const { t } = useTranslation()
  const whiteboardSwitchSelection = useSelector(getSelectedSwitch)
  const dispatch = useDispatch()
  const [openSort, setOpenSort] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const ref = useRef<HTMLDivElement>(null)

  const sortByQuery = searchParams.get('sort')

  useEffect(() => {
    if (!sortByQuery) {
      searchParams.set('sort', SORTING_VALUES[0].value)
    }

    searchParams.set('start', '0')
    setSearchParams(searchParams, { replace: true })
  }, [searchParams, setSearchParams, sortByQuery])

  const filtersOpen = useSelector(filtersOpenSelector)

  return (
    <Wrapper ref={ref} id="whiteboard-toolbar" centerElements={!totalBoards}>
      {!!totalBoards && (
        <Filter
          id="whiteboard-toolbar-filter"
          onClick={() => dispatch(whiteboardActions.toggleFiltersOpen())}
        >
          <RCIcon type="filter-dark"></RCIcon>
          {t('GenericWords.filter')}
          <Arrow type={filtersOpen ? 'up' : 'down'} theme="outlined" />
        </Filter>
      )}
      <Selection>
        <span
          onClick={() => dispatch(whiteboardActions.changeSelectedSwitch('whiteboard'))}
          className={classnames({
            selected: whiteboardSwitchSelection === 'whiteboard',
          })}
        >
          {t('Whiteboard.whiteboard')}
        </span>
        <span
          onClick={() => dispatch(whiteboardActions.changeSelectedSwitch('template'))}
          className={classnames({
            selected: whiteboardSwitchSelection === 'template',
          })}
        >
          {t('Whiteboard.templates')}
        </span>
      </Selection>
      {!!totalBoards && (
        <Sort>
          <div>
            <span>
              {filteredBoards} {t('Whiteboard.boards')}
            </span>
            <span onClick={() => setOpenSort(open => !open)}>
              {t('FooterNav.orderBy_button')}
              <Arrow type={!openSort ? 'down' : 'up'} theme="outlined" />
            </span>
          </div>
        </Sort>
      )}
      <WhiteboardSort
        sortOpen={openSort}
        toggleSortOpen={() => setOpenSort(open => !open)}
        sortingValues={SORTING_VALUES}
      />
    </Wrapper>
  )
}

export default WhiteboardToolbar
