import React from 'react'
import styled from 'styled-components'
import { getFluidSizeWithFullFormula, palette, pxToRem } from '../../../style/theme'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  font-family: 'GilmerRegular', sans-serif;
  color: ${palette.tangaroa};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.div`
  font-size: ${getFluidSizeWithFullFormula('px', 26, 48, 1366, 3840)};
  margin-bottom: ${getFluidSizeWithFullFormula('px', 32, 40, 1366, 3840)};
  font-weight: bold;
`

const Subtitle = styled.span`
  font-size: ${getFluidSizeWithFullFormula('px', 20, 40, 1366, 3840)};
  margin-bottom: ${getFluidSizeWithFullFormula('px', 72, 87, 1366, 3840)};
`

const ConfirmButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: ${pxToRem(4)}rem;
  font-size: ${getFluidSizeWithFullFormula('px', 13, 24, 1366, 3840)};
  padding: ${getFluidSizeWithFullFormula('px', 20, 27, 1366, 3840)}
    ${getFluidSizeWithFullFormula('px', 20, 24, 1366, 3840)};
  height: ${getFluidSizeWithFullFormula('px', 56, 72, 1366, 3840)};
  min-width: ${getFluidSizeWithFullFormula('px', 120, 150, 1366, 3840)};
  font-weight: bold;
  color: ${palette.white};
  background-color: ${palette.tangaroa};
`

type Props = {
  onClose: () => void
}

const AfaCartAdjustAllNotNeeded: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Title>{t('Afa.Cart.AdjustAll.Title')}</Title>
      <Subtitle>{t('Afa.Cart.AdjustAll.NotNeededText')}</Subtitle>
      <ConfirmButton onClick={onClose}>{t('Afa.Cart.AdjustAll.NotNeededConfirm')}</ConfirmButton>
    </Wrapper>
  )
}

export default AfaCartAdjustAllNotNeeded
