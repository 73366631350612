import { createSelector } from 'reselect'
import { RootState } from '../../model/model'
import { ItemType, WhiteboardItemType } from '../../model/whiteboard'
import { WhiteboardProductType } from '../../model/whiteboard'

export const getSelectedSwitch = (state: RootState) => state.whiteboard.selectedSwitch
export const getSelectedCategory = (state: RootState) => state.whiteboard.selectedCategory
export const getSelectedBoardId = (state: RootState) => state.whiteboard.selectedBoardId
export const getSelectedBoard = (state: RootState) =>
  state.whiteboard.selectedWhiteboard?.boards.find(
    board => board.key === state.whiteboard.selectedBoardId,
  )
export const getSelectedWhiteboard = (state: RootState) => state.whiteboard.selectedWhiteboard
export const getSelectedItems = (state: RootState): Record<ItemType, WhiteboardItemType[]> => {
  const selectedBoard = state.whiteboard.selectedWhiteboard?.boards.find(
    board => board.key === state.whiteboard.selectedBoardId,
  )

  return {
    items: selectedBoard?.items.filter(item => item.selected) || [],
    texts: selectedBoard?.texts.filter(item => item.selected) || [],
    images: selectedBoard?.images.filter(item => item.selected) || [],
  }
}

export const getSelectedProducts = (state: RootState): WhiteboardProductType[] => {
  const selectedBoard = state.whiteboard.selectedWhiteboard?.boards.find(
    board => board.key === state.whiteboard.selectedBoardId,
  )

  return [...(selectedBoard?.items?.filter(item => item.selected) || [])]
}

export const getItemsToUnpin = (state: RootState) => state.whiteboard.itemsToUnpin
export const getUndoActions = (state: RootState) => state.whiteboard.whiteboardHistory
export const getRedoActions = (state: RootState) => state.whiteboard.redoActionsWhiteboard
export const getSelectedBoardItems = (state: RootState) => {
  const selectedBoard = state.whiteboard.selectedWhiteboard?.boards.find(
    board => board.key === state.whiteboard.selectedBoardId,
  )

  return Object.entries(selectedBoard?.items || {}).map(([, value]) => value)
}

export const getAddedTextId = (state: RootState) => state.whiteboard.addedTextId

export const getSetSelectedBoardMenu = (state: RootState) => state.whiteboard.selectedBoardMenu

export const openCreateNewWhiteboardSelector = (state: RootState) =>
  state.whiteboard.openCreateNewWhiteboard

export const openWhiteboardSettingsSelector = (state: RootState) =>
  state.whiteboard.openWhiteboardSettings

export const whiteboardSettingsSelector = (state: RootState) => state.whiteboard.isSettingsModalOpen

export const whiteboardDeleteBoardSelector = (state: RootState) =>
  state.whiteboard.isDeleteBoardModalOpen
export const getMultiselectEnabled = (state: RootState) => state.whiteboard.multiselectEnabled
export const getMultiselectActionsEnabled = (state: RootState) =>
  state.whiteboard.multiselectActionsEnabled

export const openPinModalSelector = (state: RootState) => state.whiteboard.openPinModal

export const itemsPinModalSelector = (state: RootState) => state.whiteboard.itemsPinModal

export const lastPinnedWhiteboardSelector = (state: RootState) =>
  state.whiteboard.lastPinnedWhiteboard

export const openAddItemsInCartSelector = (state: RootState) => state.whiteboard.openAddItemsInCart

export const getSelectedSkuCode = (state: RootState) => state.whiteboard.selectedSkuCode
export const getWhiteboardSortingCriteria = (state: RootState) =>
  state.whiteboard.whiteboardSortCriteria

export const previewIsUpdatingSelector = (state: RootState) => state.whiteboard.previewIsUpdating

export const whiteboardSnapshotSelector = createSelector(
  (state: RootState) => state.whiteboard.whiteboardSnapshot,
  snapshot => snapshot,
)

export const filtersOpenSelector = (state: RootState) => state.whiteboard.filtersOpen

export const whiteboardsGridFiltersSelector = (state: RootState) =>
  state.whiteboard.whiteboardsGridFilters

export const filteredWhiteboardsLengthSelector = (state: RootState) =>
  state.whiteboard.filteredWhiteboardsLength

export const templatesSelector = createSelector(
  (state: RootState) => state.whiteboard.templates,
  templates => templates,
)
export const whiteboardsSelector = createSelector(
  (state: RootState) => state.whiteboard.whiteboards,
  whiteboards => whiteboards,
)

export const openTemplateModalSelector = createSelector(
  (state: RootState) => state.whiteboard.openTemplateModal,
  templateModal => templateModal,
)

export const whiteboardFromTemplateSelector = createSelector(
  (state: RootState) => state.whiteboard.whiteboardFromTemplate,
  template => template,
)

export const getShowImagePlaceholder = createSelector(
  (state: RootState) => state.whiteboard.imagePlaceholder,
  imagePlaceholder => imagePlaceholder,
)

export const whiteboardFiltersSelector = createSelector(
  (state: RootState) => state.whiteboard.whiteboardFilters,
  filters => new Set(filters),
)

export const latestSaveWhiteboardSelector = createSelector(
  (state: RootState) => state.whiteboard.latestSaveWhiteboard,
  result => result,
)

export const repeatActionSelector = createSelector(
  (state: RootState) => state.whiteboard.repeatAction,
  action => action,
)

export const whiteboardLinksSelector = createSelector(
  (state: RootState) => state.whiteboard.whiteboardLinks,
  links => links,
)

export const sportFiltersAreLoadingSelector = createSelector(
  (state: RootState) => state.whiteboard.sportFiltersAreLoading,
  sportFiltersAreLoading => sportFiltersAreLoading,
)

export const categoriesSportsSelector = createSelector(
  (state: RootState) => state.whiteboard.categoriesSports,
  categoriesSports => categoriesSports,
)

export const sportPromisesAreFulfilledSelector = createSelector(
  (state: RootState) => state.whiteboard.sportPromisesAreFulfilled,
  sportPromisesAreFulfilled => sportPromisesAreFulfilled,
)

export const getShowSelectedAction = createSelector(
  (state: RootState) => state.whiteboard.showSelectedAction,
  showSelectedAction => showSelectedAction,
)

export const whiteboardToUpdateIdSelector = createSelector(
  (state: RootState) => state.whiteboard.whiteboardToUpdateId,
  whiteboardToUpdateId => whiteboardToUpdateId,
)

export const whiteboardToUpdatePreviewProductsSelector = createSelector(
  (state: RootState) => state.whiteboard.whiteboardToUpdatePreviewProducts,
  whiteboardToUpdatePreviewProducts => whiteboardToUpdatePreviewProducts,
)

export const itemIsBeingAddedToBoardSelector = createSelector(
  (state: RootState) => state.whiteboard.itemIsBeingAddedToBoard,
  itemIsBeingAddedToBoard => itemIsBeingAddedToBoard,
)

export const initialWhiteboardStateSelector = createSelector(
  (state: RootState) => state.whiteboard.initialWhiteboardState,
  initialWhiteboardState => initialWhiteboardState,
)

export const currentWhiteboardStateSelector = createSelector(
  (state: RootState) => state.whiteboard.currentWhiteboardState,
  currentWhiteboardState => currentWhiteboardState,
)

export const initialFirstBoardStateSelector = createSelector(
  (state: RootState) => state.whiteboard.initialFirstBoardState,
  initialFirstBoardState => initialFirstBoardState,
)

export const currentFirstBoardStateSelector = createSelector(
  (state: RootState) => state.whiteboard.currentFirstBoardState,
  currentFirstBoardState => currentFirstBoardState,
)

export const whiteboardIsWaitingForScreenshotSelector = createSelector(
  (state: RootState) => state.whiteboard.whiteboardIsWaitingForScreenshot,
  whiteboardIsWaitingForScreenshot => whiteboardIsWaitingForScreenshot,
)
