const range: { [key: string]: { [key: string]: number } } = {
  income: {
    'under $25k': 1,
    '$25k - $50k': 2,
    '$50k - $75k': 3,
    '$75k - $100k': 4,
    '$100k - $200k': 5,
    '$200k or more': 6,
  },
  age: {
    'under 20': 1,
    '20 - 24': 2,
    '25 - 34': 3,
    '35 - 44': 4,
    '45 - 54': 5,
    'over 55': 6,
  },
}

export const sortDemographicsByDescription = (demographicsToggle: string) => (
  a: { description: string },
  b: { description: string },
) => {
  const descriptionA = a?.description.toLowerCase()
  const descriptionB = b?.description.toLowerCase()

  const orderA = range[demographicsToggle][descriptionA]
  const orderB = range[demographicsToggle][descriptionB]

  if (orderA < orderB) {
    return -1
  } else if (orderA > orderB) {
    return 1
  } else {
    return 0
  }
}
