import { Button, Col, Icon } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Row from '../../../styleguide/Row'

import { useHandleTableWallPageSwitch } from '../../../hooks/useHandleTableWallPageSwitch'
import {
  breakpointsCross,
  breakpointsNoUnit,
  getFluidSizeWithFullFormula,
  minSupportedWidth,
  palette,
} from '../../../style/theme'

export const MoodboardHeaderRow = styled(Row)`
  flex: 1;
  display: flex;
  align-items: center;
`

const ButtonCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`

const SwitchButton = styled(Button)`
  font-size: ${getFluidSizeWithFullFormula('px', 8, 20, minSupportedWidth, breakpointsNoUnit.L)};
  color: ${palette.congressBlue};
  text-transform: uppercase;
  word-break: break-word;
  white-space: normal;
  min-width: 0;
  display: none;
  @media (max-width: ${breakpointsCross.L.max}) {
    display: block;
  }
`

export const MooodboardTile = styled(Col)`
  color: ${palette.mineShaft};
  font-weight: bold;
  font-size: ${getFluidSizeWithFullFormula('px', 16, 40, 1024, breakpointsNoUnit.XL)};
  line-height: ${getFluidSizeWithFullFormula('px', 28, 70, 1024, breakpointsNoUnit.XL)};
  padding-left: ${getFluidSizeWithFullFormula(
    'px',
    2,
    40,
    minSupportedWidth,
    breakpointsNoUnit.XL,
  )};
`

export const MoodboardBrandLogo = styled(Col)`
  height: 11.39vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 0.03vw solid ${palette.dustyGray};

  img {
    padding: ${getFluidSizeWithFullFormula('px', 2, 20, minSupportedWidth, breakpointsNoUnit.XL)};
    padding-right: ${getFluidSizeWithFullFormula(
      'px',
      2,
      40,
      minSupportedWidth,
      breakpointsNoUnit.XL,
    )};
    width: 100%;
    max-width: 9.79vw;
  }

  @media screen and (max-width: 1366px) {
    height: 13.18vh;
    border-right: 0.08vw solid ${palette.dustyGray};
    img {
      width: 100%;
      max-width: 15.23vw;
    }
  }
`

type Props = {
  logo: string
  title: string
  alt: string
}

const MoodboardHeader: React.FC<Props> = ({ logo, title, alt }) => {
  const { t } = useTranslation()
  const handleSwitch = useHandleTableWallPageSwitch()

  return (
    <MoodboardHeaderRow align="middle">
      <MoodboardBrandLogo span={2}>
        <img src={logo} data-testid="moodboard-logo" alt={alt} />
      </MoodboardBrandLogo>
      <MooodboardTile span={16} data-testid="moodboard-title">
        {title}
      </MooodboardTile>
      <ButtonCol span={6}>
        <SwitchButton className="btn-link hidden-wall" onClick={handleSwitch}>
          <Icon type="switcher" /> {t('PdpPage.product_page')}
        </SwitchButton>
      </ButtonCol>
    </MoodboardHeaderRow>
  )
}

export default MoodboardHeader
