import React from 'react'
import styled from 'styled-components/macro'

import { duration, zIndex } from '../style/theme'

const Wrapper = styled.div`
  position: absolute;
  top: -99999px;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity ${duration.short}ms;
  pointer-events: none;
  overflow: hidden;
  z-index: ${zIndex.backdrop};

  &.is-open {
    top: 0;
    opacity: 1;
    pointer-events: initial;
  }
`

const Layer = styled.div`
  position: absolute;
  top: auto;
  bottom: calc(max(5vh, 3.5em) + 1em);
  left: 0;
  background-color: white;
  transform: translateY(-100%);
  transition: transform ${duration.short}ms;
  max-height: 100%;
  overflow: hidden;
  display: flex;

  .is-open & {
    transform: translateY(0);
  }
`

type Props = {
  onClick: () => void
  open: boolean
  className?: string
}

const Backdrop: React.FC<Props> = ({ onClick, open, className, children }) => {
  return (
    <Wrapper
      onClick={onClick}
      className={[className, open ? 'is-open' : undefined].join(' ')}
      aria-hidden={!open}
      data-testid="backdrop"
    >
      <Layer onClick={e => e.stopPropagation()}>{children}</Layer>
    </Wrapper>
  )
}

export default Backdrop
