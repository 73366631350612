import React, { useEffect, useRef } from 'react'
import Helmet from 'react-helmet'
import Modal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import Loading from '../../components/Loading'
import PageSocketManager, { PageSocketManagerProps } from '../../components/PageSocketManager'
import TablePdpContent from '../../components/PDP/TablePdpContent'
import WallPdpContent from '../../components/PDP/WallPdpContent'
import app_config from '../../config/app/config'
import { getBrandByBrandCode } from '../../libs/brand'
import { extractColorCodeFromMocoCode, getCLensToMocoCode } from '../../libs/productsV2'
import { isTableDevice } from '../../libs/url'
import { getReverseViewType } from '../../libs/view'
import { CouvetteVideoState } from '../../model/couvettes'
import { EmitEvent } from '../../model/model'
import { PdpState } from '../../model/pdp'
import { clearPDPModel, closePDP, loadPdpContent } from '../../store/actions'
import { brandsSelector } from '../../store/brands/selectors'
import { couvetteVideoSelector } from '../../store/couvetteVideo/selectors'
import {
  isPdpVisibleSelector,
  isSearchingSelector,
  isSeethroughModalVisibleSelector,
  loadedSelector,
  loadErrorSelector,
  mocoCodeSelector,
  pdpCouvetteSelector,
  pdpLoadingSelector,
  pdpSelector,
  pdpViewTypeSelector,
  seeThroughSelectedMocoSelector,
} from '../../store/pdp/selectors'

const StyledModal = styled(Modal)`
  height: 100%;
  width: 100%;

  .ant-modal-content {
    background: #333;
  }

  .ant-modal-close {
    background: #fff;
    top: 84.5vh;
    right: 1vw;
    width: 3vw;
    height: 3vw;
    border-radius: 6px;

    &.left {
      left: 0.5vw;

      @media screen and (max-width: 1921px) {
        display: none;
      }
    }

    &::before {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      padding: 0;
      content: 'X';
      font-size: 2vw;
      padding-top: 0.1em; // optical align
      font-weight: 100;
      color: #666;
      font-family: 'Avenir-Roman', 'Avenir-Roman-Custom', sans-serif;
    }

    @media screen and (max-width: 1921px) {
      top: 70.5vh;
      right: 3vw;
    }

    &-x {
      width: 2.5vw;
      height: 1.14vw;
      text-align: left;
      line-height: 2.8;
      padding-left: 0.26vw;
      display: flex;
    }
  }

  .ant-modal-body {
    padding: 0;
  }

  .ReactVirtualized__List {
    will-change: initial !important;
  }
`

const emitWallPdpAction = (
  pdp: PdpState,
  couvetteVideo: CouvetteVideoState,
  emitEvent: EmitEvent,
) => {
  if (!isTableDevice()) {
    return
  }

  const { content, couvette, modelCode, code: mocoCode, skuChangedByScrolling } = pdp

  const colorCode = extractColorCodeFromMocoCode(mocoCode)
  const moco = couvette?.mocos[colorCode]

  if (moco) {
    /**
     * NOTE: before opening the pdp we want to stop any video playing. This is to avoid
     * an inconsistent UI status that may occur because PDP model video button triggers
     * the same video of the pdp model video button.
     */
    const pdpInfo = {
      content: content && mocoCode && content[mocoCode],
      modelCode,
      viewType: getReverseViewType(pdp.viewType),
      code: mocoCode,
      skuChangedByScrolling,
      couvetteVideo,
    }
    if (skuChangedByScrolling) {
      emitEvent('scroll_pdp_page', { ...pdpInfo })
    } else {
      emitEvent('open_pdp_page', { ...pdpInfo, couvette })
    }
  }
}

export const Pdp: React.FC<PageSocketManagerProps> = ({ socketIoEvent, emitEvent }) => {
  const couvetteVideo = useSelector(couvetteVideoSelector)
  const pdp = useSelector(pdpSelector)
  const brands = useSelector(brandsSelector)
  const loading = useSelector(pdpLoadingSelector)
  const loaded = useSelector(loadedSelector)
  const loadError = useSelector(loadErrorSelector)
  const isPdpVisible = useSelector(isPdpVisibleSelector)
  const isSearching = useSelector(isSearchingSelector)
  const couvette = useSelector(pdpCouvetteSelector)
  const viewType = useSelector(pdpViewTypeSelector)
  const mocoCode = useSelector(mocoCodeSelector)
  const isSeethroughModalVisible = useSelector(isSeethroughModalVisibleSelector)
  const seeThroughSelectedMoco = useSelector(seeThroughSelectedMocoSelector)

  const colorCode = extractColorCodeFromMocoCode(mocoCode)
  const moco = couvette?.mocos[colorCode]
  const brand = couvette && getBrandByBrandCode(brands, couvette.brandCode)
  const content =
    moco &&
    pdp.content[(isSeethroughModalVisible ? seeThroughSelectedMoco.mocoCode : mocoCode) || '']

  const dispatch = useDispatch()

  const prevSocketEventRef = useRef(socketIoEvent)
  const prevCouvetteRef = useRef(couvette)
  const prevLoadingRef = useRef(loading)
  const prevLoadedRef = useRef(loaded)
  const prevIsSearchingRef = useRef(isSearching)

  const isReady = isPdpVisible && mocoCode && moco && !isSearching

  const mocoHasChanged = couvette !== prevCouvetteRef.current
  const pdpStoppedLoading = prevLoadingRef.current === true && !loading
  const pdpGotLoaded = !prevLoadedRef.current && loaded

  const wallPdpActionNeeded =
    isReady &&
    (mocoHasChanged ||
      (prevIsSearchingRef.current === true && !isSearching) ||
      pdpStoppedLoading ||
      pdpGotLoaded ||
      // NOTE: when couvette video ends, table should broadcast again the navigation event
      // NOTE: we want the signal to be sent only for pdp video, not the couvette video
      (prevSocketEventRef.current != socketIoEvent && socketIoEvent === 'do_ended_video'))

  useEffect(() => {
    if (wallPdpActionNeeded) {
      emitWallPdpAction(pdp, couvetteVideo, emitEvent)
    }
  }, [wallPdpActionNeeded, pdp, couvetteVideo, emitEvent])

  const brandCode = couvette?.brandCode
  const mocos = Object.values(couvette?.mocos || {})
  const allMocoCodes = mocos.map(moco => moco.mocoCode)
  const shouldLoadContent = isReady && !loading && !content && !loadError
  const cLensAndMocos = getCLensToMocoCode(mocos)

  useEffect(() => {
    if (shouldLoadContent) {
      dispatch(
        loadPdpContent({
          mocos: allMocoCodes,
          cLensAndMocos,
          brand: brandCode,
        }),
      )
    }
  }, [dispatch, shouldLoadContent, allMocoCodes, brandCode, cLensAndMocos])

  useEffect(() => {
    prevSocketEventRef.current = socketIoEvent
  }, [socketIoEvent])

  useEffect(() => {
    prevCouvetteRef.current = couvette
  }, [couvette])

  useEffect(() => {
    prevLoadingRef.current = loading
  }, [loading])

  useEffect(() => {
    prevLoadedRef.current = loaded
  }, [loaded])

  useEffect(() => {
    prevIsSearchingRef.current = isSearching
  }, [isSearching])

  const closeModal = () => dispatch(closePDP())

  return !isSearching && couvette && couvette.mocos && moco && mocoCode && brand ? (
    viewType === app_config.viewType.table ? (
      <>
        <TablePdpContent
          closeModal={closeModal}
          content={content}
          model={couvette}
          couvetteVideo={couvetteVideo}
          code={mocoCode}
        />
        <button onClick={closeModal} className="ant-modal-close" />
      </>
    ) : (
      <WallPdpContent content={content} brand={brand} />
    )
  ) : (
    <Loading />
  )
}

const PdpWithSocket = PageSocketManager(Pdp, ['do_play_video', 'do_ended_video'])

export const ModalPDP: React.FC = () => {
  const isPdpVisible = useSelector(isPdpVisibleSelector)

  const viewType = useSelector(pdpViewTypeSelector)
  const mocoCode = useSelector(mocoCodeSelector)

  const dispatch = useDispatch()

  return !mocoCode ? null : (
    <StyledModal
      isOpen={isPdpVisible}
      className="modal-pdp"
      data-testid="modal-pdp"
      overlayClassName="ant-modal-mask pdp-modal-mask"
      appElement={document.getElementById('root') || undefined}
      onRequestClose={() => dispatch(closePDP())}
      onAfterClose={() => dispatch(clearPDPModel())}
      shouldCloseOnOverlayClick={true}
    >
      <Helmet>
        <body
          data-pdp-wall-view={viewType === app_config.viewType.wall}
          data-pdp-table-view={viewType === app_config.viewType.table}
        />
      </Helmet>
      <PdpWithSocket />
    </StyledModal>
  )
}

export default ModalPDP
