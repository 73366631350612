import { Icon } from 'antd'
import classnames from 'classnames'
import React, { Component, MouseEventHandler } from 'react'
import styled from 'styled-components'

import { getFluidFontSize } from '../../../../style/theme'

const StyledIcon = styled(Icon)`
  font-size: ${getFluidFontSize('1rem')} !important;
`

type Props = {
  className?: string
  renderMenu: (goBack: () => void) => void
}

type State = {
  collapsed: boolean
}
class SubMenu extends Component<Props, State> {
  state = {
    collapsed: false,
  }
  toggleMenu: MouseEventHandler<HTMLDivElement> = e => {
    if (!e || e.currentTarget.classList.contains('sub-menu')) {
      this.setState({
        collapsed: !this.state.collapsed,
      })
    }
  }

  goBack = () => this.setState({ collapsed: false })
  render = () => {
    const { renderMenu, className } = this.props

    return (
      <div
        className={classnames({
          'sub-menu': true,
          hidden: !this.state.collapsed,
          visible: this.state.collapsed,
          [className || '']: !!className,
        })}
        onClick={this.toggleMenu}
      >
        {this.props.children}
        <StyledIcon type="right" />
        {renderMenu(this.goBack)}
      </div>
    )
  }
}

export default SubMenu
