import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { cmsTagSelector, languageSelector } from '../store/app/selectors'
import { customerNationalitySelector } from '../store/customer/selectors'
import { getAppointments } from '../store/digitalEvents/actions'

const useFetchAppointments = () => {
  const dispatch = useDispatch()
  const language = useSelector(languageSelector)
  const cmsTag = useSelector(cmsTagSelector)
  const country = useSelector(customerNationalitySelector)

  useEffect(() => {
    if (cmsTag && country) {
      dispatch(getAppointments({ language, cmsTag, country }))
    }
  }, [dispatch, language, cmsTag, country])
}

export default useFetchAppointments
