import { Modal } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { useUploadCartMutation } from '../../../../services/cart'
import { getCart } from '../../../../store/actions'
import checkoutActions from '../../../../store/checkout/actions'
import { selectFileData } from '../../../../store/checkout/selectors'
import { getFluidFontSize, palette, pxToRem } from '../../../../style/theme'
import Loading from '../../../../components/Loading'
import { NextButton } from '../Common'
import { errorNotification } from '../../../../components/Notification/notifications'
import { ANT_MODAL_Z_INDEX } from '../../../../style/GlobalStyle'

const ModalLabel = styled.div`
  text-align: center;
  margin: ${pxToRem(30)}rem 0 ${pxToRem(50)}rem 0;
  font-size: ${getFluidFontSize('14px')};
  font-weight: bold;
  color: ${palette.tangaroa};
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: ${pxToRem(30)}rem;
`

const ModalButton = styled.button`
  text-transform: uppercase;
  border: 1px solid ${palette.tangaroa};
  border-radius: ${pxToRem(5)}rem;
  padding: ${pxToRem(10)}rem ${pxToRem(30)}rem;
  color: ${palette.white};
  background-color: ${palette.congressBlue};

  :last-of-type {
    color: ${palette.white};
    background-color: ${palette.congressBlue};
  }
`

type Props = {
  onClick?: () => void
}

const ConfirmCartUploadButton: React.FC<Props> = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const fileData = useSelector(selectFileData)

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [uploadCart, uploadCartResult] = useUploadCartMutation()

  const [isUploading, setIsUploading] = useState(false)

  const closeModal = useCallback(() => {
    setIsModalVisible(false)
    dispatch(checkoutActions.setFileData(undefined))
    uploadCartResult.reset()
    dispatch(checkoutActions.setImportExportStep(0))
  }, [dispatch, uploadCartResult])

  useEffect(() => {
    if (isUploading && uploadCartResult.isSuccess) {
      setIsUploading(false)
      closeModal()
    }
  }, [uploadCartResult, closeModal, dispatch, isUploading])

  function makeRequest() {
    return new Promise((resolve, reject) => {
      if (!fileData) {
        reject(errorNotification({ message: 'missing file data in redux store' }))
      } else {
        resolve((setIsUploading(true), uploadCart(false, fileData)))
      }
    })
  }

  return (
    <>
      <NextButton onClick={() => setIsModalVisible(true)}>
        {t('Checkout.ImportExport.Confirm')}
      </NextButton>
      <Modal
        className="modal-checkout-apply"
        wrapClassName="checkout-modal-wrapper-apply"
        title={t('Checkout.Process.ApplyDateWarning')}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => setIsModalVisible(false)}
        centered
        footer={null}
        width="40vw"
        zIndex={ANT_MODAL_Z_INDEX + 1}
      >
        <ModalLabel>
          <p>{t('Checkout.ImportExport.ModalContent')}</p>
          <p>{t('Checkout.ImportExport.ModalContentDown')}</p>
          {uploadCartResult.isLoading && <Loading isFullPage={false} />}
        </ModalLabel>
        <ButtonWrapper>
          <ModalButton onClick={closeModal}>{t('Checkout.ImportExport.ModalCancel')}</ModalButton>
          <ModalButton
            onClick={() => {
              makeRequest()
                .then(() => {
                  return dispatch(getCart())
                })
                .catch(() => {
                  errorNotification({ message: 'missing file data in redux store' })
                })
            }}
          >
            {t('Checkout.ImportExport.ModalConfirm')}
          </ModalButton>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default ConfirmCartUploadButton
