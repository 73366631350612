import styled from 'styled-components'
import CheckIcon from '../../../../components/icons/CheckIcon'
import PlusIcon from '../../../../components/icons/PlusIcon'
import { getFluidSizeWithFullFormula, palette, pxToRem } from '../../../../style/theme'

export const Wrapper = styled.div`
  padding: 0 ${getFluidSizeWithFullFormula('px', 56, 112, 1366, 3840)};
`

export const Subtitle = styled.p`
  font-family: GilmerMedium, sans-serif;
  color: ${palette.tangaroa};
  font-size: ${getFluidSizeWithFullFormula('px', 20, 40, 1366, 3840)};
`

export const Body = styled.div`
  display: flex;
  letter-spacing: 1.1px;
  max-width: ${getFluidSizeWithFullFormula('px', 569, 1100, 1366, 3840)};
  padding-top: ${getFluidSizeWithFullFormula('px', 56, 112, 1366, 3840)};
  margin: 0 auto;
  flex-flow: column;
  flex: 1 0 0;
  font-family: GilmerBold, sans-serif;
  color: #1e2a3f;
  font-size: ${getFluidSizeWithFullFormula('px', 22, 44, 1366, 3840)};
  min-height: ${getFluidSizeWithFullFormula('px', 307, 615, 1366, 3840)};
  > p {
    text-transform: uppercase;
    padding-bottom: ${getFluidSizeWithFullFormula('px', 24, 48, 1366, 3840)};
  }
`

export const Input = styled.input`
  padding: ${getFluidSizeWithFullFormula('px', 20, 40, 1366, 3840)}
    ${getFluidSizeWithFullFormula('px', 10, 20, 1366, 3840)};
  color: ${palette.cloudBurst};
  border: solid 1px ${palette.shadowBlue};
  border-radius: 4px;
  font-size: ${getFluidSizeWithFullFormula('px', 14, 24, 1366, 3840)};
  margin-bottom: ${getFluidSizeWithFullFormula('px', 16, 32, 1366, 3840)};
  &:focus {
    outline: none;
  }
`

export const Button = styled.button`
  margin: ${pxToRem(50)}rem auto 0;
  padding: ${getFluidSizeWithFullFormula('px', 20, 40, 1366, 3840)};
  box-sizing: border-box;
  height: ${pxToRem(56)}rem;
  display: flex;
  min-width: ${pxToRem(219)}rem;
  align-items: center;
  justify-content: center;
  font-size: ${getFluidSizeWithFullFormula('px', 13, 26, 1366, 3840)};
  font-family: GilmerBold;
  text-transform: uppercase;
  border: 1px solid ${palette.tangaroa};
  border-radius: ${getFluidSizeWithFullFormula('px', 4, 8, 1366, 3840)};
  cursor: pointer;

  &.confirm {
    background-color: ${palette.tangaroa};
    color: ${palette.white};
  }

  img {
    display: inline-block;
    margin-right: 10px;
    margin-top: 2px;
  }
`

export const UserRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${getFluidSizeWithFullFormula('px', 12, 24, 1366, 3840)}
    ${getFluidSizeWithFullFormula('px', 16, 32, 1366, 3840)};
  align-items: center;
`
export const UserList = styled.div`
  border-radius: 6px;
  border: solid 1px ${palette.bermudaGray};
  max-height: ${getFluidSizeWithFullFormula('px', 192, 384, 1366, 3840)};
  overflow-y: auto;
`

export const UserData = styled.div`
  text-align: left;
`

export const UserEmail = styled.div`
  font-family: GilmerMedium, sans-serif;
  color: ${palette.santasGray};
  font-size: ${getFluidSizeWithFullFormula('px', 11, 22, 1366, 3840)};
`

export const UserName = styled.div`
  font-family: GilmerMedium, sans-serif;
  color: ${palette.tangaroa};
  font-size: ${getFluidSizeWithFullFormula('px', 14, 28, 1366, 3840)};
`

export const CheckIconRound = styled(CheckIcon)`
  background-color: #e0f4ca;
  border-radius: 50%;
  width: ${getFluidSizeWithFullFormula('px', 24, 48, 1366, 3840)};
  height: ${getFluidSizeWithFullFormula('px', 24, 48, 1366, 3840)};
  padding: ${getFluidSizeWithFullFormula('px', 5, 10, 1366, 3840)};
  fill: #75a53e;
`

export const PlusIconRound = styled(PlusIcon)`
  border-radius: 50%;
  background-color: ${palette.tangaroa};
  width: ${getFluidSizeWithFullFormula('px', 24, 48, 1366, 3840)};
  height: ${getFluidSizeWithFullFormula('px', 24, 48, 1366, 3840)};
  padding: ${getFluidSizeWithFullFormula('px', 5, 10, 1366, 3840)};
  fill: white;
`

export const SuccessUserList = styled.div`
  max-height: ${getFluidSizeWithFullFormula('px', 440, 880, 1366, 3840)};
  overflow-y: auto;
  margin-top: ${getFluidSizeWithFullFormula('px', 40, 80, 1366, 3840)};
  margin-bottom: ${getFluidSizeWithFullFormula('px', 40, 80, 1366, 3840)};

  > div {
    max-width: ${getFluidSizeWithFullFormula('px', 300, 600, 1366, 3840)};
    margin: 0 auto;
  }
`
