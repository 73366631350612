import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { formatDoorShippingAddress } from '../../../../libs/doors'
import { DoorShippingAddress } from '../../../../model/cart'
import { useConfirmCartMutation, useGetDoorShippingAddressQuery } from '../../../../services/cart'
import checkoutActions from '../../../../store/checkout/actions'
import {
  doorsShippingAddressesInCartSelector,
  orderNotesSelector,
  selectDoorsInCheckout,
  selectedAddressesSelector,
} from '../../../../store/checkout/selectors'
import { getFluidFontSize, palette, pxToRem, spacing } from '../../../../style/theme'
import Loading from '../../../../components/Loading'
import { cartBackOfficeNoteSelector } from '../../../../store/cart/selectors'

const TableWrapper = styled.div`
  background-color: ${palette.white};
  padding: ${pxToRem(spacing(3))}rem ${pxToRem(spacing(4))}rem ${pxToRem(spacing(4.5))}rem
    ${pxToRem(spacing(4))}rem;
  border-radius: ${pxToRem(spacing(1))}rem;
  margin-bottom: ${pxToRem(spacing(5))}rem;
  color: ${palette.tangaroa};

  &.error {
    background-color: ${palette.pomegranate};
    color: ${palette.white};
    text-align: center;
  }
`

const Table = styled.table`
  width: 100%;
  padding: 0 ${pxToRem(spacing(2))}rem;
  margin: 0 auto;

  th {
    text-transform: uppercase;
    font-weight: 700;
    padding-bottom: ${pxToRem(spacing(3))}rem;
    font-size: ${getFluidFontSize(`${pxToRem(11.5)}`, 15)};
    color: ${palette.biscay};
    font-family: 'Avenir-Heavy', sans-serif;

    span:last-of-type {
      margin-left: ${pxToRem(5)}rem;
    }
  }
`

const InputAddress = styled.textarea`
  width: 100%;
  height: 20vw;
  border-color: ${palette.viking};
  border-radius: ${pxToRem(5)}rem;
  padding: ${pxToRem(spacing(2))}rem;
  font-size: ${getFluidFontSize('14px', 15)};
  color: ${palette.doveGray};

  ::placeholder {
    color: ${palette.doveGray};
    font-family: 'Avenir-Roman', sans-serif;
  }
`

const TBodyBorder = styled.tbody`
  position: relative;
  display: flex;
  padding: ${pxToRem(20)}rem ${pxToRem(24)}rem;
  font-weight: 700;
  font-size: ${getFluidFontSize('14px', 15)};
  border: ${pxToRem(1)}rem solid ${palette.softGrey};
  border-radius: ${pxToRem(5)}rem;
  margin: ${pxToRem(5)}rem 0;

  &.border {
    border: ${pxToRem(1)}rem solid ${palette.viking};
    background-color: ${palette.selago};
  }
`

const AddressLabel = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .radioInput {
    display: none;
  }

  .realRadio {
    width: 1.25em;
    height: 1.25em;
    border: ${pxToRem(1)}rem solid ${palette.cornflowerBlue};
    border-radius: 50%;
    background-color: ${palette.selago};
    margin-right: ${pxToRem(15)}rem;
    box-sizing: border-box;
    padding: ${pxToRem(3)}rem;
  }

  .realRadio::after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background-color: ${palette.cornflowerBlue};
    border-radius: 50%;
    transform: scale(0);
  }

  .radioInput:checked + .realRadio::after {
    transform: scale(1);
  }
`

const LoadingWrapper = styled.div`
  top: 0;
  left: 0;
  background: none;
  position: absolute;
  height: 100vh;
  width: 100vw;
  pointer-events: visible;
  display: block;
  z-index: 9999;
`

type DoorRowProps = {
  address: DoorShippingAddress
}

const AddressRow: React.FC<DoorRowProps> = ({ address }) => {
  return (
    <span>
      {address.addressid} - {formatDoorShippingAddress(address)}
    </span>
  )
}

const sortAddress = (a: DoorShippingAddress, b: DoorShippingAddress) => {
  if (a.addresstype === 'WE' && b.addresstype !== 'WE') {
    return -1
  } else if (a.addresstype !== 'WE' && b.addresstype === 'WE') {
    return 1
  } else {
    return a.addressid.localeCompare(b.addressid)
  }
}

const Address: React.FC = () => {
  const { t } = useTranslation()
  const { isLoading, isError, data: doors } = useGetDoorShippingAddressQuery()
  const orderNotes = useSelector(orderNotesSelector)
  const selectedAddresses = useSelector(selectedAddressesSelector)
  const doorsInCart = useSelector(selectDoorsInCheckout)
  const doorsShippingAddressesInCart = useSelector(doorsShippingAddressesInCartSelector)
  const cartBackOfficeNote = useSelector(cartBackOfficeNoteSelector)

  const [, confirmCartResult] = useConfirmCartMutation()

  const dispatch = useDispatch()

  return (
    <>
      {isLoading && <Loading />}

      {/* different loading to disable the footer buttons on submit to back office */}
      {confirmCartResult.isLoading && (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )}

      {((!isLoading && doors?.length === 0) || isError) && (
        <TableWrapper className="error">
          <Table>
            <thead>
              <tr>
                <th>
                  <span>{t('Checkout.Address.Error')}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <span>{t('Checkout.Address.ErrorMessage')}</span>
            </tbody>
          </Table>
        </TableWrapper>
      )}

      {doors
        ?.filter(({ id }) => doorsInCart.includes(id))
        .sort((a, b) => a.id.localeCompare(b.id))
        .map(door => (
          <TableWrapper key={door.id}>
            <Table>
              <thead>
                <tr>
                  <th>
                    <span>{door.id}</span>
                    <span>{door.name}</span>
                  </th>
                </tr>
              </thead>
              {[...door.addresses]
                .sort((a, b) => sortAddress(a, b))
                .map((address, i) => {
                  const defaultAddresses = door.addresses.filter(
                    ({ addresstype }) => addresstype === 'WE',
                  )
                  const selectedByDefault =
                    (defaultAddresses.length === 1 && address.addresstype === 'WE') ||
                    (doorsShippingAddressesInCart[door.id] &&
                      doorsShippingAddressesInCart[door.id].length === 1 &&
                      doorsShippingAddressesInCart[door.id].includes(address.addressid))
                  const selectedAddress = selectedAddresses.find(({ doorId }) => doorId === door.id)
                  if (selectedByDefault && !selectedAddress) {
                    dispatch(
                      checkoutActions.setSelectedAddress({
                        doorId: door.id,
                        selectedAddressId: address.addressid,
                      }),
                    )
                  }
                  const checked = selectedAddress?.selectedAddressId === address.addressid
                  return (
                    <tr key={address.addressid}>
                      <TBodyBorder className={checked ? 'border' : ''} key={i}>
                        <AddressLabel>
                          <input
                            className="radioInput"
                            type="radio"
                            name={door.id}
                            value={address.addressid}
                            checked={checked}
                            onChange={() => {
                              dispatch(
                                checkoutActions.setSelectedAddress({
                                  doorId: door.id,
                                  selectedAddressId: address.addressid,
                                }),
                              )
                            }}
                          />
                          <div className="realRadio"></div>
                          <AddressRow address={address} />
                        </AddressLabel>
                      </TBodyBorder>
                    </tr>
                  )
                })}
            </Table>
          </TableWrapper>
        ))}

      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <th>{t('Checkout.Address.NotesTitle')}</th>
            </tr>
          </thead>
          <tbody>
            {cartBackOfficeNote && (
              <span>
                <b>{t('Checkout.Address.PreviousNotes')}</b> {cartBackOfficeNote}
              </span>
            )}
            <InputAddress
              placeholder={t('Checkout.Address.NotesPlaceholder')}
              value={orderNotes}
              onChange={event => dispatch(checkoutActions.setOrderNotes(event.target.value))}
            />
          </tbody>
        </Table>
      </TableWrapper>
    </>
  )
}

export default Address
