import styled from 'styled-components/macro'
import { getFluidSizeWithFullFormula as gF, palette } from '../../../style/theme'
import { useSelector } from 'react-redux'
import assortmentAdvisorSelectors from '../../../store/assortmentAdvisor/selectors'
import { useGetDemographicsQuery } from '../services'
import { useEffect, useState } from 'react'
import { MapDemographicsByDescription, MapView } from '../Model/aaModel'
import { MarkerCircles, MarkerMap } from '../Pages/Map/HeatMap'
import { viewportSelector } from '../../../store/viewport/selectors'
import { calculateDistanceBetweenTwoCoordinates } from '../../../libs/assortmentAdvisor'
import { useTranslation } from 'react-i18next'
import { sortDemographicsByDescription } from '../../../helpers/sortDemographicsByDescription'

const Wrapper = styled.div`
  position: absolute;
  bottom: 1vw;
  right: 1vw;
  z-index: 999;
  background-color: ${palette.blueZodiacAA};
  padding: ${gF('px', 34, 68, 1366, 3840)} ${gF('px', 25, 50, 1366, 3840)};
  color: ${palette.white};
  border-radius: 22px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const Categories = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: ${gF('px', 11, 22, 1366, 3840)};
  margin-top: ${gF('px', 27, 54, 1366, 3840)};
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  font-family: 'EuclidCircularB-Regular', sans-serif;
  font-size: ${gF('px', 24, 48, 1366, 3840)};
  letter-spacing: -0.6px;
  padding-right: 0.5em;
`

const ZipCode = styled.div`
  color: ${palette.cornflowerBlueAA};
  padding: ${gF('px', 1, 2, 1366, 3840)} ${gF('px', 9.5, 19, 1366, 3840)};
  border-radius: 8px;
  background-color: rgba(80, 147, 249, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${gF('px', 16, 32, 1366, 3840)};
  height: max-content;
`

const Category = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: ${gF('px', 11, 22, 1366, 3840)};
`

const CategoryTitle = styled.span`
  font-family: 'EuclidCircularB-Regular', sans-serif;
  font-size: ${gF('px', 15, 30, 1366, 3840)};
  text-transform: capitalize;
  width: ${gF('px', 100, 200, 1366, 3840)};
  white-space: nowrap;
`

const NoData = styled.div`
  font-size: ${gF('px', 15, 30, 1366, 3840)};
`

const CategoryPercentage = styled(CategoryTitle)`
  width: ${gF('px', 35, 70, 1366, 3840)};
  text-align: end;
`

const CategoryBar = styled.div`
  width: ${gF('px', 170, 340, 1366, 3840)};
  height: ${gF('px', 6, 12, 1366, 3840)};
  border-radius: 3px;
  position: relative;
  background-color: rgba(68, 138, 244, 0.1);
  width: ${gF('px', 170, 340, 1366, 3840)};
`

const CategoryBarPercentage = styled.div<{ percentage: number; barColor: string }>`
  position: absolute;
  border-radius: 3px;
  top: 0;
  left: 0;
  height: 100%;
  width: ${({ percentage }) => percentage}%;
  background-color: ${({ barColor }) => barColor};
`

const BAR_COLORS = ['#c4dbfd', '#b0cffc', '#629ef9', '#136ef6', '#064bb1', '#043a8b']

type Props = {
  demographicsToggle: 'age' | 'income'
  map: google.maps.Map | undefined
  mapView: MapView | null
  setDemographicsCircles: React.Dispatch<React.SetStateAction<MarkerCircles>>
  setZipCodeMarkers: React.Dispatch<React.SetStateAction<MarkerMap>>
}

const DemographicsStatistics: React.FC<Props> = ({
  demographicsToggle,
  map,
  mapView,
  setDemographicsCircles,
  setZipCodeMarkers,
}) => {
  const { t } = useTranslation()

  const searchedAddress = useSelector(assortmentAdvisorSelectors.searchedAddressSelector)
  const { width } = useSelector(viewportSelector)
  const { currentData } = useGetDemographicsQuery(
    searchedAddress?.country.short || '',
    searchedAddress?.zipCode || '',
  )

  const [demographics, setDemographics] = useState<MapDemographicsByDescription>()

  useEffect(() => {
    setDemographics(demographicsToggle === 'age' ? currentData?.['Age'] : currentData?.['Income'])
  }, [currentData, demographicsToggle])

  useEffect(() => {
    if (mapView === 'demographics' && searchedAddress && searchedAddress.bounds && map) {
      const bounds = new google.maps.LatLngBounds(
        searchedAddress.bounds.southwest,
        searchedAddress.bounds.northeast,
      )
      const center = bounds.getCenter()
      const circleId = `${center.lat()}-${center.lng()}`
      const cityCircle = new google.maps.Circle({
        strokeColor: '#5093f9',
        strokeOpacity: 1,
        strokeWeight: 1,
        fillColor: 'rgba(80, 147, 249, 0.6)',
        map,
        center: center,
        radius: calculateDistanceBetweenTwoCoordinates(
          bounds.getNorthEast(),
          bounds.getSouthWest(),
        ),
      })
      setDemographicsCircles(prevCircles => {
        if (Object.keys(prevCircles).length > 0) {
          Object.keys(prevCircles).forEach(key => {
            prevCircles[key].setMap(null)
          })
        }
        return { [circleId]: cityCircle }
      })

      if (searchedAddress.zipCode) {
        const markerWidth = width * 0.0446
        const markerHeight = width * 0.019
        const markerFontSize = width * 0.0117
        const zipCodeMarkerId = `${center.lat()}-${center.lng()}`
        const zipCodeMarker = new google.maps.Marker({
          position: center,
          map,
          icon: {
            url:
              'data:image/svg+xml;charset=UTF-8,' +
              encodeURIComponent(
                `<svg xmlns="http://www.w3.org/2000/svg" width="${markerWidth}" height="${markerHeight}" viewBox="0 0 ${markerWidth} ${markerHeight}"><rect width="${markerWidth}" height="${markerHeight}" rx="8" fill="#242e41" /><text x="50%" y="50%" text-anchor="middle" dominant-baseline="central" font-size="${markerFontSize}" font-family="sans-serif" fill="#5093f9">${searchedAddress.zipCode}</text></svg>`,
              ),
          },
          zIndex: 4,
        })
        setZipCodeMarkers(prevZipCodeMarkers => {
          if (Object.keys(prevZipCodeMarkers).length > 0) {
            Object.keys(prevZipCodeMarkers).forEach(key => {
              prevZipCodeMarkers[key].setMap(null)
            })
          }
          return { [zipCodeMarkerId]: zipCodeMarker }
        })
      }
    }
  }, [mapView, setDemographicsCircles, map, searchedAddress, width, setZipCodeMarkers])

  return (
    <Wrapper>
      {searchedAddress && (
        <Header>
          <Title>
            <span>{searchedAddress.name.long}</span>
            <span>
              {searchedAddress.name.short} {`(${searchedAddress.country.short.toUpperCase()})`}
            </span>
          </Title>
          <ZipCode>{searchedAddress.zipCode}</ZipCode>
        </Header>
      )}
      <Categories>
        {demographics ? (
          Object.values(demographics)
            .sort(sortDemographicsByDescription(demographicsToggle))
            .map((category, index) => {
              const barColor = BAR_COLORS[index % BAR_COLORS.length]
              return (
                <Category key={category.description}>
                  <CategoryTitle>{category.description}</CategoryTitle>
                  <CategoryBar>
                    <CategoryBarPercentage percentage={category.percentage} barColor={barColor} />
                  </CategoryBar>
                  <CategoryPercentage>{category.percentage}%</CategoryPercentage>
                </Category>
              )
            })
        ) : (
          <NoData>{t('AA.noData')}</NoData>
        )}
      </Categories>
    </Wrapper>
  )
}

export default DemographicsStatistics
