import { useDispatch } from 'react-redux'

import devices from '../config/devices'
import socketManager from '../libs/socketManager'
import { getUrlParts } from '../libs/url'
import { CategoryId } from '../model/model'
import { changeAssortmentViewType } from '../store/actions'

export const useChangeShopCategory = () => {
  const dispatch = useDispatch()

  const changeShopCategory = (category?: CategoryId) => {
    socketManager.emitSignal(
      'open_tableCart_page',
      {
        viewType: category,
        roomType: getUrlParts().roomType,
      },
      devices.not([devices.extraipad]),
    )
    dispatch(changeAssortmentViewType(category))
  }

  return changeShopCategory
}
