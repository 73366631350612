import { createSelector } from '@reduxjs/toolkit'

import app_config from '../config/app/config'
import {
  isCategoriesPage,
  isExtraIpadDevice,
  isFullRoom,
  isIPadView,
  isPageMatches,
  isPLPPage,
  isSearchPLP,
} from '../libs/url'
import { appLoadedSelector } from './app/selectors'
import { brandsLoadedSelector, brandsSelector } from './brands/selectors'
import {
  cartDataSelector,
  cartViewTypeSelector,
  loadedCartProductDetailsSelector,
  loadingCartProductDetailsSelector,
  pendingCartUpdateSelector,
} from './cart/selectors'
import { doorLoadingStatusSelector } from './customer/selectors'
import { isPdpVisibleSelector } from './pdp/selectors'
import { isPlpStarsModeSelector } from './stars/selectors'

export const isPLPPageSelector = () => isPLPPage()

export const isCategoriesPageSelector = () => isCategoriesPage()

export const isSearchPLPSelector = () => isSearchPLP()

export const isWishlistPageSelector = () => isPageMatches('wishlist')

export const isCartPageSelector = () => isPageMatches('cart')

export const isPDPPageSelector = () => isPageMatches('pdp')

export const isChooseBrandSelector = () => isPageMatches('choose-brand')

export const isIPadViewSelector = () => isIPadView()

export const showGridButtonSelector = createSelector(
  isPLPPageSelector,
  isCategoriesPageSelector,
  isPdpVisibleSelector,
  isPlpStarsModeSelector,
  (isPLPPage, isCategoriesPage, isPdpVisible, isPlpStarsMode) =>
    isPLPPage && !isCategoriesPage && !isPdpVisible && !isPlpStarsMode,
)

export const showSortByButtonSelector = createSelector(
  isPdpVisibleSelector,
  () => isSearchPLP(),
  isCategoriesPageSelector,
  (isPdpVisible, isSearchPage, isCategoriesPage) =>
    !isCategoriesPage && !isPdpVisible && !isSearchPage,
)

export const showSearchButtonSelector = createSelector(
  isPdpVisibleSelector,
  isCategoriesPageSelector,
  isChooseBrandSelector,
  (isPdpVisible, isCategoriesPage, isChooseBrand) =>
    Boolean(isChooseBrand || (!isPdpVisible && !isCategoriesPage)),
)

export const showFilterButtonSelector = createSelector(
  isPdpVisibleSelector,
  cartViewTypeSelector,
  isCategoriesPageSelector,
  brandsLoadedSelector,
  brandsSelector,
  isChooseBrandSelector,
  (isPdpVisible, viewType, isCategoriesPage, brandsLoaded, brands, isChooseBrand) =>
    Boolean(
      !(brandsLoaded && brands.length === 0) &&
        viewType === app_config.viewType.table &&
        (isChooseBrand || (!isCategoriesPage && !isPdpVisible)),
    ),
)

export const showShopCategorySwitcherSelector = () => {
  const isCartPage = isCartPageSelector()
  return isCartPage && !isExtraIpadDevice() && !isFullRoom()
}

export const disableCartButtonSelector = createSelector(
  appLoadedSelector,
  pendingCartUpdateSelector,
  cartDataSelector,
  loadingCartProductDetailsSelector,
  loadedCartProductDetailsSelector,
  brandsLoadedSelector,
  doorLoadingStatusSelector,
  (
    appLoaded,
    pendigCartUpdate,
    cartData,
    loadingCartProductDetails,
    loadedCartProductDetails,
    brandsLoaded,
    doorLoadingStatus,
  ) => {
    return (
      !appLoaded ||
      pendigCartUpdate ||
      cartData?.loading ||
      !cartData?.loaded ||
      loadingCartProductDetails ||
      !loadedCartProductDetails ||
      !brandsLoaded ||
      doorLoadingStatus !== 'ok'
    )
  },
)
