import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import app_config from '../../config/app/config'
import { getReverseViewType } from '../../libs/view'
import { PdpContent } from '../../model/content'
import {
  PdpState,
  SeeThemOnViewType,
  VirtualMirrorAvailability,
  VirtualMirrorConfig,
} from '../../model/pdp'
import { CatalogImages, Moco, Product } from '../../model/product'
import { resetForNewClient } from '../extraActions'

export const seeThemOnViewTypes = Object.freeze({
  single: 'single',
  four: 'four',
})

const DEFAULT_STATE: PdpState = {
  skuChangedByScrolling: false,
  content: {},
  couvette: null,
  loading: false,
  loaded: false,
  loadError: false,
  isPdpVisible: false,
  isSearching: false,
  modelCode: null,
  viewType: app_config.viewType.table as 'tableView',
  code: null,
  vtoModalVisible: false,
  vtoSelectedUpc: null,
  fullscreen360Visible: false,
  fullscreen360CatalogImages: null,
  fullscreen360Code: null,
  tre60Info: {},
  virtualMirrorAvailability: {},
  isSeethroughModalVisible: false,
  advancedDoorSelectionOpen: false,
  virtualMirrorUrl: '',
  seeThemOnViewType: 'single',
  seeThemOnCurrentSlide: 0,
  seeThemOnCurrentFacesBLockId: null,
  seeThroughSelectedMoco: {} as Moco,
  virtualMirrorConfig: {} as VirtualMirrorConfig,
  virtualMirrorUnmountStatus: false,
}

export const slice = createSlice({
  name: 'pdp',
  initialState: DEFAULT_STATE,
  reducers: {
    showPDP: (
      state,
      {
        payload: { code, modelCode, skuChangedByScrolling = false },
      }: PayloadAction<{
        code: string
        modelCode: string | null
        skuChangedByScrolling?: boolean
      }>,
    ) => {
      state.isPdpVisible = true
      state.code = code
      state.modelCode = modelCode
      state.skuChangedByScrolling = skuChangedByScrolling
    },
    setPDPCode: (state, { payload }: PayloadAction<string>) => {
      state.code = payload
    },
    openVTOView: (state, { payload }: PayloadAction<string | undefined>) => {
      if (payload) {
        state.vtoModalVisible = true
        state.vtoSelectedUpc = payload
      }
    },
    closeVTOView: (state, { payload }: PayloadAction<{ waitUnmount: boolean }>) => {
      if (payload.waitUnmount) {
        state.virtualMirrorUnmountStatus = 'requested'
      }
      state.vtoModalVisible = false
      state.vtoSelectedUpc = null
      state.virtualMirrorUrl = ''
    },
    showFullscreen360: (
      state,
      { payload }: PayloadAction<{ catalogImages: CatalogImages; code: string }>,
    ) => {
      state.fullscreen360Visible = true
      state.fullscreen360CatalogImages = payload.catalogImages
      state.fullscreen360Code = payload.code
    },
    closeFullscreen360: state => {
      state.fullscreen360Visible = false
      state.fullscreen360CatalogImages = null
      state.fullscreen360Code = null
    },
    searchPdpCouvetteFail: state => {
      state.isSearching = false
      state.isPdpVisible = false
      state.couvette = null
      state.tre60Info = {}
    },
    savePdpInfo: (state, { payload }: PayloadAction<{ couvette: Product }>) => {
      state.couvette = payload.couvette
      state.modelCode = payload.couvette.modelCode
    },
    searchingPdpCouvette: state => {
      state.isSearching = true
    },
    loadingContent: state => {
      state.loading = true
      state.loaded = false
    },
    loadingContentSuccess: (state, { payload }: PayloadAction<PdpContent>) => {
      state.loading = false
      state.loaded = true
      state.loadError = false
      Object.assign(state.content, payload)
    },
    setSeethroughModalVisibility: (state, { payload }: PayloadAction<boolean>) => {
      state.isSeethroughModalVisible = payload
    },
    enable360: (state, { payload }: PayloadAction<string>) => {
      const code = payload
      state.tre60Info[code] = { loaded: true, enabled: true }
    },
    disable360: (state, { payload }: PayloadAction<string>) => {
      const code = payload
      state.tre60Info[code] = { loaded: true, enabled: false }
    },
    setSeethroughWithoutLoading: (state, { payload }: PayloadAction<string>) => {
      const mocoCode = payload
      if (state.content[mocoCode]) {
        state.content[mocoCode].seethroughWithoutLoaded = false
        state.content[mocoCode].seethroughWithoutLoading = true
        state.content[mocoCode].image = undefined
      }
    },
    setSeethroughWithoutLoaded: (state, { payload }: PayloadAction<string>) => {
      const mocoCode = payload
      if (state.content[mocoCode]) {
        state.content[mocoCode].seethroughWithoutLoaded = true
        state.content[mocoCode].seethroughWithoutLoading = false
      }
    },
    updateSeethroughWithoutContent: (
      state,
      { payload }: PayloadAction<{ mocoCode: string; content: PdpContent }>,
    ) => {
      state.content[payload.mocoCode].image = payload.content.image
    },
    toggleAdvancedDoorSelectionAction: state => {
      state.advancedDoorSelectionOpen = !state.advancedDoorSelectionOpen
    },
    setSeeThemOnViewType: (state, { payload }: PayloadAction<SeeThemOnViewType>) => {
      state.seeThemOnViewType = payload
    },
    setSeeThemOnCurrentSlide: (state, { payload }: PayloadAction<number>) => {
      state.seeThemOnCurrentSlide = payload
    },
    setSeeThemOnCurrentFacesBlockId: (state, { payload }: PayloadAction<string>) => {
      state.seeThemOnCurrentFacesBLockId = payload
    },
    loadingContentFail: state => {
      state.loading = false
      state.loaded = false
      state.loadError = true
    },
    closePdpView: state => {
      state.isPdpVisible = false
      state.loadError = false
      state.content = {}
      state.code = null
      state.modelCode = null
      state.viewType = app_config.viewType.table as 'tableView' // Note: we need it in case of switch, to restore the viewType
      state.virtualMirrorAvailability = {}
      state.virtualMirrorUrl = ''
    },
    clearPdpModel: state => {
      state.couvette = null
    },
    searchPdpCouvetteSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        couvette: Product
        virtualMirrorAvailability: VirtualMirrorAvailability
      }>,
    ) => {
      const { couvette, virtualMirrorAvailability } = payload
      const tre60Info = Object.values(couvette.mocos).reduce((result, { mocoCode }) => {
        result[mocoCode] = { loaded: false, enabled: false }
        return result
      }, {} as Record<string, { loaded: boolean; enabled: boolean }>)
      state.isSearching = false
      state.tre60Info = tre60Info
      state.virtualMirrorAvailability = virtualMirrorAvailability
    },
    switchPdpType: state => {
      const { viewType } = state
      state.viewType = getReverseViewType(viewType)
    },
    setVmUrl: (state, { payload }: PayloadAction<string>) => {
      state.virtualMirrorUrl = payload
    },
    setSeeThroughSelectedMoco: (state, { payload }: PayloadAction<Moco>) => {
      state.seeThroughSelectedMoco = payload
    },
    setVirtualMirrorConfig: (state, { payload }: PayloadAction<VirtualMirrorConfig>) => {
      state.virtualMirrorConfig = payload
    },
    setVirtualMirrorUnmountStatus: (
      state,
      { payload }: PayloadAction<false | 'requested' | 'done'>,
    ) => {
      state.virtualMirrorUnmountStatus = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, () => DEFAULT_STATE)
  },
})

export default slice.reducer
