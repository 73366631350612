import { Spin } from 'antd'
import classnames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { getDeviceAbsolutePath } from '../../../libs/url'
import { pxToRem } from '../../../style/theme'
import RCIcon from '../../UI/RCIcon'
import FooterBadge from './Badge'

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:active {
    text-decoration: none;
  }
`

const Label = styled.div`
  position: relative;
`

const Badges = styled.div`
  position: absolute;
  top: ${pxToRem(-10)}rem;
  right: 0;
  transform: translate(50%);
`

const StyledSpin = styled(Spin)`
  position: absolute;
  top: ${pxToRem(4)}rem;
  z-index: 1;
`

type Props = {
  type: string
  className: string
  disabled: boolean
  count: number
  processed?: number
}

const LinkTo: React.FC<Props> = ({ type, className, disabled, count, processed }) => {
  const { t } = useTranslation()

  return (
    <StyledLink to={disabled ? '#' : `${getDeviceAbsolutePath()}/${type}`} className={className}>
      <RCIcon type={type === 'wishlist' ? 'heart' : type} />
      <Label
        className={classnames({
          label: true,
          'with-spin': disabled,
        })}
      >
        {t(`FooterNav.item_${type}`)}

        {!disabled && (
          <Badges>
            <FooterBadge count={count} processed={processed} />
          </Badges>
        )}
      </Label>

      {disabled && <StyledSpin size="small" />}
    </StyledLink>
  )
}

export default LinkTo
