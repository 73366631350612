import React, { Component } from 'react'
import PropTypes from 'prop-types'
import KeyboardInput from '../KeyboardInput'
import { isIPadView } from '../../../libs/url'

class AlphaNumericKeyboardInput extends Component {
  render = () => (
    <KeyboardInput
      enabled
      containerClassName={this.props.containerClassName}
      type={this.props.type}
      onChange={this.props.onChange}
      value={this.props.value}
      min={this.props.min}
      max={this.props.max}
      step={this.props.step}
      name={this.props.name}
      inputClassName={this.props.inputClassName}
      placeholder={this.props.placeholder}
      showKeyboardOnInit={!isIPadView() && this.props.showKeyboardOnInit}
      hidingKeyboardDisabled={this.props.hidingKeyboardDisabled}
      onHideKeyboard={this.props.onHideKeyboard}
      onSubmit={this.props.onSubmit}
      forceShow={this.props.forceShow}
      validateInput={this.props.validateInput}
      textarea={this.props.textarea}
      onFocus={this.props.onFocus}
      onBlur={this.props.onBlur}
      autoFocus={this.props.autoFocus}
    />
  )
}

AlphaNumericKeyboardInput.propTypes = {
  containerClassName: PropTypes.string,
  forceShow: PropTypes.bool,
  hidingKeyboardDisabled: PropTypes.bool,
  inputClassName: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onHideKeyboard: PropTypes.func,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  showKeyboardOnInit: PropTypes.bool,
  step: PropTypes.number,
  type: PropTypes.string,
  validateInput: PropTypes.func,
  value: PropTypes.string,
  textarea: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}

export default AlphaNumericKeyboardInput
