import React, { SVGProps } from 'react'
import { palette } from '../../style/theme'

const SuccessIcon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle
      cx={44}
      cy={44}
      r={43}
      fill={palette.paleGreen}
      stroke={palette.darkGreen}
      strokeWidth={2}
    />
    <path
      d="M66.657 30.655c.002.474-.192.928-.538 1.262L37.474 59.694a2.888 2.888 0 0 1-2.009.806 2.89 2.89 0 0 1-2.009-.806L19.788 46.44a1.756 1.756 0 0 1-.538-1.26c0-.474.193-.927.538-1.261l1.416-1.373c.345-.333.813-.52 1.3-.52.486 0 .954.187 1.3.52l11.01 10.677a.935.935 0 0 0 1.294 0L62.103 28.02a1.868 1.868 0 0 1 1.3-.521c.487 0 .954.187 1.3.52l1.416 1.375c.346.333.54.787.54 1.26z"
      fill={palette.darkGreen}
    />
  </svg>
)

export default SuccessIcon
