import { useEffect, useRef, useState } from 'react'
import Icon from '../../../../../styleguide/Icon'
import { Search } from './style'
import { SelectedSwitchType } from '../../../../../model/whiteboard'
import { useTranslation } from 'react-i18next'

type Props = {
  setSearchTextValue: React.Dispatch<React.SetStateAction<string>>
  whiteboardSwitchSelection: SelectedSwitchType
}

const SearchWB: React.FC<Props> = ({ setSearchTextValue, whiteboardSwitchSelection }) => {
  const { t } = useTranslation()

  const inputRef = useRef<HTMLInputElement>(null)

  const [digit, setDigit] = useState('')

  useEffect(() => {
    setDigit('')
    if (inputRef?.current) {
      inputRef.current.value = ''
    }
  }, [whiteboardSwitchSelection])

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (digit.length) {
      timer = setTimeout(async () => {
        setSearchTextValue(digit)
      }, 500)
      return () => clearTimeout(timer)
    } else {
      setSearchTextValue('')
    }
  }, [digit, setSearchTextValue])

  return (
    <Search>
      <Icon type="search" onClick={() => inputRef.current?.focus()} />
      <input
        ref={inputRef}
        type="text"
        onChange={e => setDigit(e.target.value)}
        placeholder={
          whiteboardSwitchSelection === 'whiteboard'
            ? t('Whiteboard.search_whiteboard')
            : t('Whiteboard.search_template')
        }
      />
    </Search>
  )
}

export default SearchWB
