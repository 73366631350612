import { isIpadOnlyRoom } from '../../libs/url'
import { MODAL_TRANSITION_DURATION_MS } from '../../libs/utils'
import { CmsContent } from '../../model/content'
import { AppThunk } from '../../model/model'
import { showFooterModal } from '../app/actions'
import { slice } from './slice'

// METHODS

export const play_video = (
  media: CmsContent,
  mediaUniqueId: string,
  dates: string[] | undefined = undefined,
): AppThunk => {
  return dispatch => {
    if (isIpadOnlyRoom()) {
      dispatch(showFooterModal('advAndVideoContent', false))
    }
    return dispatch(
      slice.actions._play_video({ media: media, mediaUniqueId: mediaUniqueId, dates: dates }),
    )
  }
}

export const pause_video = (): AppThunk => {
  return dispatch => {
    return dispatch(slice.actions._pause_video())
  }
}

export const end_video = (): AppThunk => {
  return dispatch => {
    if (isIpadOnlyRoom()) {
      dispatch(showFooterModal(null, false))
      setTimeout(() => {
        dispatch(slice.actions._end_video())
      }, MODAL_TRANSITION_DURATION_MS)
    } else {
      return dispatch(slice.actions._end_video())
    }
  }
}

export default {
  ...slice.actions,
  play_video,
  pause_video,
  end_video,
}
