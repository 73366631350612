import styled from 'styled-components/macro'
import { getFluidSizeWithFullFormula as gF, palette } from '../../style/theme'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import whiteboardActions from '../../store/whiteboard/actions'
import { ButtonsWrapper, Title, Wrapper } from './components/WhiteboardCommonComponents'
import classnames from 'classnames'
import {
  getSelectedSwitch,
  templatesSelector,
  whiteboardsSelector,
} from '../../store/whiteboard/selectors'

const Question = styled.h2`
  font-family: GilmerRegular, sans-serif;
  font-size: ${gF('px', 20, 40, 1366, 3840)};
  color: ${palette.tangaroa};
  margin-top: ${gF('px', 32, 60, 1366, 3840)};
  margin-bottom: ${gF('px', 56, 204, 1366, 3840)};
  text-transform: none;
`

const Selection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  width: ${gF('px', 313, 670, 1366, 3840)};
  height: ${gF('px', 160, 342, 1366, 3840)};
  border: solid 1px ${palette.platinum};
  background-color: ${palette.white};
  color: ${palette.cloudBurst};
  font-family: GilmerBold, sans-serif;
  font-size: ${gF('px', 18, 36, 1366, 3840)};
  line-height: 1.44;
  letter-spacing: 1px;
  text-transform: uppercase;

  &:hover {
    border: solid 1px ${palette.tangaroa};
  }

  &.disabled {
    pointer-events: none;
    background-color: ${palette.lightGray};
    color: ${palette.santasGray};
    border: solid 1px ${palette.santasGray};

    &:hover {
      border: solid 1px ${palette.santasGray};
    }
  }
`

const WhiteboardModalCreation: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { whiteboards: templates } = useSelector(templatesSelector)
  const { whiteboards } = useSelector(whiteboardsSelector)

  const selectedType = useSelector(getSelectedSwitch)

  return (
    <Wrapper>
      <Title>{t(`Whiteboard.newCreation.title_${selectedType}`)}</Title>
      <Question>{t('Whiteboard.newCreation.question')}</Question>
      <ButtonsWrapper>
        <Selection
          onClick={() => {
            dispatch(whiteboardActions.deselectWhiteboard())
            dispatch(whiteboardActions.changeSelectedSwitch(selectedType))
            dispatch(whiteboardActions.setOpenCreateNewWhiteboard(false))
            dispatch(whiteboardActions.setOpenWhiteboardSettings(true))
          }}
        >
          {t(`Whiteboard.newCreation.new_${selectedType}`)}
        </Selection>
        {
          <Selection
            className={classnames({
              disabled: selectedType === 'whiteboard' ? !templates?.length : !whiteboards?.length,
            })}
            onClick={() => {
              dispatch(whiteboardActions.setOpenCreateNewWhiteboard(false))
              dispatch(whiteboardActions.setOpenTemplateModal(true))
            }}
          >
            {t(`Whiteboard.newCreation.button_${selectedType}`)}
          </Selection>
        }
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default WhiteboardModalCreation
