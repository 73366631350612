import socketManager from '../../libs/socketManager'
import devices from '../../config/devices'
import { getWishlist } from './actions'
import { AppThunk } from '../../model/model'
import { DeviceName } from '../../model/device'

export const subscribeToWishlistMessagesAction = (): AppThunk => dispatch => {
  socketManager.bindListener('wishlist_updated', ({ device }: { device: DeviceName }) => {
    if (devices.isCurrentDeviceToSync([devices.wall, devices.table, devices.extraipad], device)) {
      dispatch(getWishlist())
    }
  })
}
