import { createSelector } from '@reduxjs/toolkit'
import { ASSORTMENT_ADVISOR_SELECTED_DOOR_ID_PARAM_NAME } from '../../flows/AssortmentAdvisor/consts'

import { Customer, RootState } from '../../model/model'

export const customerDoorsSelector = (state: RootState) => {
  let doors = state.customer.doors
  const storedDoorsString = localStorage.getItem('customer-doors')
  if (!doors.length && storedDoorsString) {
    doors = JSON.parse(storedDoorsString)
  }
  return doors
}

export const activeDoorsSelector = createSelector(
  customerDoorsSelector,
  () => {
    const searchParams = new URLSearchParams(window.location.search)
    return searchParams.get(ASSORTMENT_ADVISOR_SELECTED_DOOR_ID_PARAM_NAME)
  },
  (doors, aaSelectedDoorId) =>
    doors.filter(({ id, enabled }) => (aaSelectedDoorId ? id === aaSelectedDoorId : enabled)),
)

export const activeDoorsIdsSelector = createSelector(activeDoorsSelector, activeDoors =>
  activeDoors.map(({ id }) => id),
)

export const customerSelector = (state: RootState) => state.customer

export const checkIsMainDoorEnabled = (state: RootState) => {
  const enabledDoors = activeDoorsSelector(state)
  return Boolean(enabledDoors.find(door => door.mainDoor))
}

export const showCartTotalSelector = (state: RootState) => state.customer.showCartTotal

export const showSrPriceSelector = (state: RootState) => state.customer.showSrPrice || false

export const showWhsPriceSelector = (state: RootState) => state.customer.showWhsPrice || false

export const customerVisitedSelector = (state: RootState) => state.customer.visited

export const showNotesSelector = (state: RootState) => state.customer.showNotes

export const customerNameSelector = (state: RootState) => {
  const customerData = localStorage.getItem('customerData')
  let customerName = ''
  if (state.customer.name) {
    customerName = state.customer.name
  } else if (customerData) {
    const customerDataParsed: Customer = JSON.parse(customerData)
    customerName = customerDataParsed.name
  }
  return customerName
}

export const customerNationalitySelector = (state: RootState) => state.customer.nationality

export const customerStarsSelector = (state: RootState) => state.customer.stars

export const customerLoadedSelector = (state: RootState) => state.customer.loaded

export const welcomeVisitedSelector = (state: RootState) => state.customer.welcome_visited

export const digitalPreviewVisitedSelector = (state: RootState) =>
  state.customer.digitalPreviewVisited

export const doorLoadingStatusSelector = (state: RootState) => state.customer.doorsLoadingStatus

export const pricePreferencesLoadingStatusSelector = (state: RootState) =>
  state.customer.pricePreferencesLoadingStatus

export const customerTypeSelector = createSelector(customerStarsSelector, isCustomerStars =>
  isCustomerStars ? 'STARS' : 'STD',
)
