import { apiCustomer, CustomerPricePrefsAPI, DoorAPI } from '../../api/restApi'
import { errorNotification } from '../../components/Notification/notifications'
import { handleErrors, handleNonBlockingErrors } from '../../libs/handleError'
import log from '../../libs/log'
import { Door, PricePrefs } from '../../model/customer'
import { AppThunk, AppThunkPromise } from '../../model/model'
import { dataLoadedFail } from '../app/actions'
import { updateAnalytics } from '../cart/actions'
import { initPlpFiltersCounters } from '../filters/actions'
import { slice } from './slice'

export const getCustomerDoors = (customerId: string, token: string): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()
    const { eventId, lang } = state.app

    if (!customerId || !eventId) {
      return
    }

    const doorAPI = new DoorAPI(token, lang)

    dispatch(slice.actions.loadingCustomerDoors())

    return doorAPI
      .getDoors(token, customerId, eventId)
      .then(handleErrors)
      .then(doors => {
        localStorage.setItem('customer-doors', JSON.stringify(doors))
        return dispatch(slice.actions.setCustomerDoors(doors))
      })
      .catch(err => {
        log.error(err, 'getCustomerDoors')
        dispatch(slice.actions.loadCustomerDoorsError())
        return dispatch(dataLoadedFail(err))
      })
  }
}

const getCustomerPricePreferences = (
  customerId: string,
  eventId: string,
  token: string,
): AppThunk => {
  return (dispatch, getState) => {
    dispatch(slice.actions.loadingCustomerPricePreferences())
    const state = getState()
    const api = new CustomerPricePrefsAPI(token, state.app.lang, customerId, eventId)
    return api
      .get()
      .then(handleNonBlockingErrors)
      .then(data => {
        if (data) {
          dispatch(slice.actions.setCustomerPricePreferences(data))
          dispatch(slice.actions.loadedCustomerPricePreferences())
        } else {
          dispatch(slice.actions.loadCustomerPricePreferencesError())
        }
      })
      .catch(err => {
        log.error(err, 'getCustomerPricePreferences')
        return dispatch(dataLoadedFail(err))
      })
  }
}

export const getCustomerData = (
  customerId: string,
  assortment: string,
  token: string,
): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()
    const { eventId } = state.app
    dispatch(slice.actions.resetCustomerState())

    return apiCustomer(token, customerId, eventId, assortment)
      .then(handleErrors)
      .then(data => {
        if (!data || !data.customerId) {
          dispatch(slice.actions.loadCustomerFail())
        } else {
          const dataStringified = JSON.stringify(data)
          localStorage.setItem('customerData', dataStringified)
        }
        dispatch(slice.actions.loadCustomerAction(data))
        dispatch(getCustomerDoors(customerId, token))
        dispatch(getCustomerPricePreferences(customerId, eventId, token))
        dispatch(initPlpFiltersCounters())
      })
      .catch(err => {
        log.error(err, 'getCustomerData')
        return dispatch(dataLoadedFail(err))
      })
  }
}

type ErrorCheckPayload = { id: string; enabled: boolean }
const thereAreErrors = (response: ErrorCheckPayload[], request: ErrorCheckPayload[]) => {
  const sortById = (a: ErrorCheckPayload, b: ErrorCheckPayload) => (a.id > b.id ? -1 : 1)
  const getHash = (doors: ErrorCheckPayload[]) =>
    doors
      .sort(sortById)
      .map(door => `${door.id}${door.enabled}`)
      .join('')
  const responseHash = getHash(response)
  const requestHash = getHash(request)

  return responseHash !== requestHash
}

export const updateDoors = (newDoorsList: string[]): AppThunkPromise => (dispatch, getState) => {
  const state = getState()
  const { customerId, eventId, lang } = state.app
  const doorsMap = state.customer.doors.map(door => ({
    id: door.id + '',
    enabled: newDoorsList.indexOf(door.id) > -1,
  }))

  const token = state.auth.token || ''
  const doorAPI = new DoorAPI(token, lang)

  return doorAPI
    .setDoors(token, customerId, eventId, doorsMap)
    .then(handleErrors)
    .then((doors: Door[]) => {
      if (thereAreErrors(doors, doorsMap)) {
        errorNotification({
          message: 'This doors settings could not be saved due to a backend error',
        })
      }

      dispatch(slice.actions.setCustomerDoors(doors))
      dispatch(updateAnalytics())
    })
    .catch(err => {
      log.error(err, 'updateDoors')
      dispatch(dataLoadedFail(err))
    })
}

export const updatePricePrefs = (pricePrefs: PricePrefs): AppThunk => {
  return (dispatch, getState) => {
    dispatch(slice.actions.setCustomerPricePreferences(pricePrefs))
    const state = getState()
    const { customerId, eventId, lang } = state.app

    const customerPricePrefsApi = new CustomerPricePrefsAPI(
      state.auth.token || '',
      lang,
      customerId,
      eventId,
    )

    return customerPricePrefsApi.set(pricePrefs)
  }
}

export const loadCustomerAction = slice.actions.loadCustomerAction
export const loadCustomerFail = slice.actions.loadCustomerFail
export const setBrandVisited = slice.actions.setBrandVisited
export const setWelcomeVisited = slice.actions.setWelcomeVisited
export const setDigitalPreviewVisited = slice.actions.setDigitalPreviewVisited
export const setCustomerDoors = slice.actions.setCustomerDoors
export const loadingCustomerDoors = slice.actions.loadingCustomerDoors
export const loadCustomerDoorsError = slice.actions.loadCustomerDoorsError
export const loadingCustomerPricePreferences = slice.actions.loadingCustomerPricePreferences
export const loadedCustomerPricePreferences = slice.actions.loadedCustomerPricePreferences
export const loadCustomerPricePreferencesError = slice.actions.loadCustomerPricePreferencesError
export const setCustomerPricePreferences = slice.actions.setCustomerPricePreferences
export const setShowNotes = slice.actions.setShowNotes
export const resetCustomerState = slice.actions.resetCustomerState

const doorActions = {
  ...slice.actions,
  getCustomerDoors,
  getCustomerData,
  updateDoors,
  updatePricePrefs,
}

export default doorActions
