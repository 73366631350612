export const isObjectEmptyOrWithUndefined = (
  object: Record<string, any> | Record<string, any>[],
): boolean => {
  if (Array.isArray(object)) {
    return object.every(o => isObjectEmptyOrWithUndefined(o))
  }
  if (Object.keys(object).length === 0) {
    return true
  }
  return Object.values(object).every(value => {
    if (typeof value === 'object') {
      return isObjectEmptyOrWithUndefined(value)
    } else {
      return value === undefined
    }
  })
}
