import { NextButton } from '../AfaCheckout/components/AfaCheckoutCommonComponents'
import React from 'react'
import { getFluidSizeWithFullFormula } from '../../style/theme'
import Spinner from '../Whiteboard/components/Spinner'
import styled from 'styled-components'
import { useAfaCheckCartNotifications } from '../../hooks/useAfaCheckCartNotifications'
import { errorNotification } from '../../components/Notification/notifications'
import afaCartActions from '../../store/afaCart/actions'
import afaCartSummaryActions from '../../store/afaCartSummary/actions'
import { getDeviceAbsolutePath } from '../../libs/url'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AfaCartProduct } from '../../model/afa'
import { getAfaCartQuantity } from '../../libs/afa'
import { useHistory, useParams } from 'react-router'
import { isKamUserSelector } from '../../store/app/selectors'

const StyledSpinner = styled(Spinner)`
  padding: 0;
  font-size: ${getFluidSizeWithFullFormula('px', 13, 24, 1366, 3840)};
`

interface Props {
  cartProducts: AfaCartProduct[]
}

export const AfaCartSubmitButton: React.FC<Props> = ({ cartProducts }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { assortment } = useParams<{ assortment: string }>()

  const showCheckoutButton = useSelector(isKamUserSelector)

  const { checkCartNotifications, isChecking } = useAfaCheckCartNotifications()
  const cartQuantity = getAfaCartQuantity(cartProducts)

  const handleSubmit = async () => {
    if (!cartQuantity) {
      errorNotification({
        message: t('Afa.Cart.addItems'),
      })
    }

    if (cartQuantity) {
      const cartCheckResult = await checkCartNotifications(cartProducts)
      if (!cartCheckResult) {
        return
      }
    }

    if (cartQuantity) {
      dispatch(afaCartActions.setCancellationDateDialogOpen(true))
    } else {
      dispatch(afaCartSummaryActions.hideMultidoorNotification())
      history.push(`${getDeviceAbsolutePath()}/${assortment}/checkout`)
    }
  }

  return showCheckoutButton ? (
    <NextButton onClick={handleSubmit}>
      <span>{t('Checkout.Footer.SubmitOrder')}</span>
      {isChecking && <StyledSpinner />}
    </NextButton>
  ) : (
    <></>
  )
}
