import styled from 'styled-components'
import { getFluidSizeWithFullFormula, palette, pxToRem } from '../../../style/theme'

const Wrapper = styled.div`
  margin-top: ${pxToRem(100)}rem;
  align-self: end;
  display: grid;
  justify-content: center;
  /*grid-auto-columns: minmax(0, ${pxToRem(110)}rem);*/
  grid-auto-flow: column;
  gap: ${pxToRem(54)}rem;
`

const Button = styled.button`
  padding: ${getFluidSizeWithFullFormula('px', 20, 40, 1366, 3840)};
  box-sizing: border-box;
  height: ${pxToRem(56)}rem;
  display: flex;
  min-width: ${pxToRem(219)}rem;
  align-items: center;
  justify-content: center;
  font-size: ${getFluidSizeWithFullFormula('px', 20, 40, 1366, 3840)};
  font-family: GilmerBold;
  text-transform: uppercase;
  border: 1px solid ${palette.tangaroa};
  border-radius: 4px;
  cursor: pointer;
  color: ${palette.tangaroa};

  &.confirm {
    background-color: ${palette.tangaroa};
    color: ${palette.white};
  }

  &.delete-board {
    font-size: ${getFluidSizeWithFullFormula('px', 13, 26, 1366, 3840)};
  }
`

type Props = {
  onCancel: () => void
  onConfirm: () => void
  cancelText: string
  confirmText: string
  classNameProp?: string
}

const ButtonsContainer = ({
  confirmText,
  onConfirm,
  cancelText,
  onCancel,
  classNameProp,
}: Props) => {
  return (
    <Wrapper>
      <Button className={classNameProp} onClick={onCancel}>
        {cancelText}
      </Button>
      <Button onClick={onConfirm} className={`confirm ${classNameProp}`.trim()}>
        {confirmText}
      </Button>
    </Wrapper>
  )
}

export default ButtonsContainer
