import { useSelector } from 'react-redux'
import { afaCartSelectors } from '../store/afaCart/selectors'
import { useGetMocoDropsQuery } from '../services/afaProduct'
import { AfaCartAdjustType } from '../store/afaCartAdjust/slice'
import { afaCartAdjustSelectors } from '../store/afaCartAdjust/selectors'
import { useMemo } from 'react'
import { AfaMoco } from '../model/afa'

const useDropsOrRealtimeDropsByUpc = (moco: AfaMoco | undefined) => {
  const isCartAdjustOpen = useSelector(afaCartAdjustSelectors.isOpen)
  const dropsRealtime = useSelector(afaCartSelectors.dropsRealtime)
  const dropsByUpcQuery = useGetMocoDropsQuery(moco)

  const dropsByUpc = useMemo(() => {
    return isCartAdjustOpen === AfaCartAdjustType.OUT_OF_STOCK && dropsRealtime
      ? dropsRealtime
      : dropsByUpcQuery.currentData
  }, [dropsRealtime, dropsByUpcQuery.currentData, isCartAdjustOpen])

  return { dropsByUpc, dropsByUpcQuery }
}

const useDropsHavePriceVariation = (moco: AfaMoco | undefined) => {
  const { dropsByUpc } = useDropsOrRealtimeDropsByUpc(moco)

  const dropsHavePriceVariation = useMemo(() => {
    return Object.values(dropsByUpc || {}).some(drops => {
      return drops.some(({ masterPrice }) => !!masterPrice.unitPriceRt || !!masterPrice.unitPriceWs)
    })
  }, [dropsByUpc])

  return dropsHavePriceVariation
}

export const useAfaDropsHooks = {
  dropsOrRealtimeDropsByUpc: useDropsOrRealtimeDropsByUpc,
  dropsHavePriceVariation: useDropsHavePriceVariation,
}
