import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import Loading from '../../components/Loading'
import { disable360, enable360, showFullscreen360 } from '../../store/pdp/actions'
import {
  has360Selector,
  hasPi20Selector,
  hasPi21Selector,
  image360Selector,
} from '../../store/pdp/selectors'
import { breakpoints, palette, zIndex } from '../../style/theme'
import ProductImageFull from '../Product/ProductImageFull'
import Tre60 from '../Tre60'
import RCIcon from '../UI/RCIcon'
import { CatalogImages } from '../../model/product'
import { getDegrees, getPi, getProductImageUrl } from '../../libs/productImages'

const Wrapper = styled.div`
  width: 100%;

  .loading-wrapper {
    z-index: ${zIndex.inPageloading};
    background-color: ${palette.white};
  }
`
Wrapper.displayName = 'ImageFullOr360'

const Button360 = styled.button`
  width: 2.8vw;
  height: 2.8vw;
  padding: 4px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${breakpoints.XL}) {
    pointer-events: none;
    border: none;
    box-shadow: none;
  }

  img {
    width: 100%;
    height: 100%;
  }
`

const Icon360Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

type Props = {
  mocoCode: string
  fullscreen: boolean
  className: string
  button360disabled?: boolean
  has360?: boolean
  hasPi20?: boolean
  hasPi21?: boolean
  tre60InfoMoco?: Record<string, { loaded: boolean; enabled: boolean }>
  catalogImages: CatalogImages
}

export const ImageFullOr360: React.FC<Props> = ({
  tre60InfoMoco,
  mocoCode,
  fullscreen,
  className,
  has360,
  hasPi20,
  hasPi21,
  button360disabled = false,
  catalogImages,
}) => {
  const [loaded, setLoaded] = useState(false)
  const dispatch = useDispatch()
  const colorCode = mocoCode.split('__')[1]
  const imagesSelected = useSelector(image360Selector)
  const has360Selected = useSelector(has360Selector(colorCode))
  const hasPi20Selected = useSelector(hasPi20Selector(colorCode))
  const hasPi21Selected = useSelector(hasPi21Selector(colorCode))
  const multipleImagesOfMocos = tre60InfoMoco || imagesSelected
  const multipleImages = multipleImagesOfMocos && multipleImagesOfMocos[mocoCode]

  const has360PropOrSelected = has360 || has360Selected
  const hasPi20PropOrSelected = hasPi20 || hasPi20Selected
  const hasPi21PropOrSelected = hasPi21 || hasPi21Selected

  const image360Paths = getDegrees(catalogImages.degrees, fullscreen)
  const imagePi20Paths = getPi(catalogImages.pi20, fullscreen)
  const imagePi21Paths = getPi(catalogImages.pi21, fullscreen)

  const hasPi21Or20 = hasPi21PropOrSelected || hasPi20PropOrSelected
  const hasMultipleImages = hasPi21Or20 || has360PropOrSelected
  const multipleImagesLoadingError =
    hasMultipleImages && multipleImages?.loaded && !multipleImages?.enabled

  const images = hasPi21PropOrSelected
    ? imagePi21Paths
    : hasPi20PropOrSelected
    ? imagePi20Paths
    : image360Paths

  const shouldRenderSingleImage = !hasMultipleImages || multipleImagesLoadingError

  return (
    <Wrapper className={['image-full-or-360', className].join(' ')}>
      {!loaded && <Loading isFullPage={false} />}
      {shouldRenderSingleImage ? (
        <ProductImageFull
          src={getProductImageUrl({ path: catalogImages.base || '' })}
          onLoad={() => setLoaded(true)}
        />
      ) : (
        <Tre60
          fullscreen={fullscreen}
          images={images}
          index={hasPi21Or20 ? 0 : 1}
          onImagesLoadSuccess={() => {
            setLoaded(true)
            dispatch(enable360(mocoCode))
          }}
          onImagesLoadError={() => dispatch(disable360(mocoCode))}
        />
      )}
      {multipleImages?.loaded && multipleImages?.enabled && (
        <Icon360Wrapper>
          <Button360
            className="icon-360"
            onClick={ev => {
              if (button360disabled) return
              if (fullscreen) {
                ev.preventDefault()
                ev.stopPropagation()
                return
              }
              dispatch(showFullscreen360({ catalogImages: catalogImages, code: mocoCode }))
            }}
          >
            <RCIcon type="360" isGray={false} />
          </Button360>
        </Icon360Wrapper>
      )}
    </Wrapper>
  )
}

export default ImageFullOr360
