import { useGetProductQuery } from '../services/afaProduct'
import { useSearchParams } from './useSearchParams'
import { useCallback, useMemo } from 'react'

export const useAfaSelectedColor = (modelCode: string) => {
  const productQuery = useGetProductQuery(modelCode)
  const product = productQuery.data

  const colors = useMemo(() => {
    return Object.values(product?.mocos || {})
  }, [product])

  const [searchParams, setSearchParams] = useSearchParams()
  const selectedColorCode = searchParams.get('color')

  const selectedColor = useMemo(() => {
    return colors.find(({ colorCode }) => colorCode === selectedColorCode)
  }, [selectedColorCode, colors])

  const setSelectedColor = useCallback(
    (colorCode: string) => {
      searchParams.set('color', colorCode)
      setSearchParams(searchParams, { replace: true })
    },
    [searchParams, setSearchParams],
  )

  return { selectedColor, setSelectedColor }
}
