import { isAfter } from 'date-fns'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import DigitalEventsFooter from '../../components/DigitalEventsFooter/DigitalEventsFooter'
import DigitalEventsHeader from '../../components/DigitalEventsHeader/DigitalEventsHeader'
import ScrollablePageWrapper from '../../components/ScrollablePageWrapper'
import useFetchAppointments from '../../hooks/useFetchAppointments'
import useFetchBackgrounds from '../../hooks/useFetchBackgrounds'
import useFetchInitiatives from '../../hooks/useFetchInitiatives'
import { isNotUndefined } from '../../libs/utils'
import { Appointment } from '../../model/appointment'
import {
  appointmentsSelector,
  dashboardBackgroundSelector,
} from '../../store/digitalEvents/selectors'
import {
  mainContentLateralPadding,
  MainFullBleed,
  ScrollContainer,
} from '../../style/DigitalEventsCommonComponents'
import { breakpoints, pxToRem, spacing } from '../../style/theme'
import Clock from './Clock'
import HightlightAppointment from './HightlightAppointment'
import Initiatives from './Initiatives'
import NextAppointments from './NextAppointments'
import BackgroundVideo from '../../components/BackgroundVideo'
import { isBefore } from 'date-fns/esm'

const MainWrapper = styled(MainFullBleed)`
  color: white;
  display: grid;
  grid-template-rows: auto auto min-content;
  gap: ${pxToRem(spacing(1))}rem;
`

const AppointmentsAndTimeAndHighlight = styled.div`
  ${mainContentLateralPadding};
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${pxToRem(spacing(5))}rem;
  align-items: center;

  @media (max-width: ${breakpoints.M}) {
    grid-template-columns: 2fr 1fr 2fr;
    gap: 0;
  }

  @media (max-width: ${breakpoints.S}) {
    grid-template-columns: 3fr 1fr 3fr;
    gap: ${pxToRem(spacing(2))}rem;
  }
`

const getAppointmentsPresentOrFutureDate = (appointments: Appointment[] | null) =>
  appointments?.filter(a => {
    const start = new Date(a.startDate)
    const end = new Date(a.endDate)
    const now = new Date()
    return isAfter(start, now) || (isBefore(start, now) && isAfter(end, now))
  })

const Dashboard: React.FC = () => {
  const appointments = useSelector(appointmentsSelector)
  const filterAppointments = getAppointmentsPresentOrFutureDate(appointments || [])?.sort(
    (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
  )
  const highlightAppointment = filterAppointments && filterAppointments[0]
  const nextAppointments = filterAppointments?.slice(1, 3).filter(isNotUndefined) // we skip the first appointment, since it is shown in the highlight box

  const background = useSelector(dashboardBackgroundSelector)

  useFetchBackgrounds()

  useFetchAppointments()

  useFetchInitiatives()

  return (
    <ScrollablePageWrapper background={background.image}>
      <BackgroundVideo background={background.video} />
      <ScrollContainer>
        <DigitalEventsHeader currentPage="dashboard" />
        <MainWrapper>
          <AppointmentsAndTimeAndHighlight>
            <NextAppointments nextAppointments={nextAppointments} />
            <Clock />
            <HightlightAppointment highlightAppointment={highlightAppointment} />
          </AppointmentsAndTimeAndHighlight>
          <Initiatives />
        </MainWrapper>
        <DigitalEventsFooter />
      </ScrollContainer>
    </ScrollablePageWrapper>
  )
}

export default Dashboard
