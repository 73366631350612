import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import app_config from '../../config/app/config'
import LedList from '../../containers/LedList'
import { roomTypeSelector } from '../../store/app/selectors'
import { setActiveBrandAction, setMyShopLastAddedProductUpcs } from '../../store/cart/actions'
import { assortmentsViewTypeSelector, cartViewTypeSelector } from '../../store/cart/selectors'
import { cartModeSelector } from '../../store/stars/selectors'
import MyShop from '../Cart/MyShop'
import TableCartContent from '../Cart/TableCartContent'
import StarsLogoWall from '../UI/StarsLogo/StarsLogo'

export const WallCart = () => {
  const dispatch = useDispatch()

  const viewType = useSelector(cartViewTypeSelector)
  const assortmentsViewType = useSelector(assortmentsViewTypeSelector)
  const roomType = useSelector(roomTypeSelector)
  const viewMode = useSelector(cartModeSelector)

  useEffect(() => {
    return () => {
      dispatch(setMyShopLastAddedProductUpcs([]))
    }
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(setActiveBrandAction(null))
    }
  }, [dispatch])

  const isSmallOrMediumRoom = [app_config.roomType.SMALL, app_config.roomType.MEDIUM].includes(
    roomType as 'standard' | 'medium',
  )
  const isSunOrOpticalView = [app_config.sunCategory, app_config.opticalCategory].includes(
    assortmentsViewType?.category as '1' | '2',
  )

  if (isSmallOrMediumRoom && isSunOrOpticalView) {
    return <LedList category={assortmentsViewType?.category} />
  }

  if (viewMode === 'stars') {
    return <StarsLogoWall />
  }

  return viewType === app_config.viewType.wall ? <MyShop /> : <TableCartContent />
}

export default WallCart
