import 'antd/dist/antd.css'

import { createGlobalStyle, css } from 'styled-components'

import app_config from '../config/app/config'
import {
  breakpoints,
  getFluidSizeWithFullFormula as gF,
  palette,
  pxToRem,
  spacing,
  breakpointsCross,
} from './theme'

export const ANT_MODAL_Z_INDEX = 100003

const commonToastsStyle = css`
  margin-left: auto;
  border-radius: 8px;
  padding: ${gF('px', 12, 24, 1366, 3840)};

  .Toastify__toast-body {
    padding: 0 ${gF('px', 4, 8, 1366, 3840)};

    & > div:last-child {
      //notification text
      width: ${gF('px', 194, 388, 1366, 3840)};
      overflow-x: hidden;
      text-overflow: ellipsis;
      color: ${palette.cloudBurst};
      font-family: 'GilmerMedium', sans-serif;
      font-size: ${gF('px', 14, 28, 1366, 3840)};
    }

    .Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter {
      padding-left: ${gF('px', 5, 10, 1366, 3840)};
      padding-right: ${gF('px', 10, 20, 1366, 3840)};
      width: fit-content;
    }
  }

  button {
    width: ${gF('px', 14, 28, 1366, 3840)};
    height: ${gF('px', 14, 28, 1366, 3840)};

    svg {
      width: 100%;
      height: 100%;
    }
  }
`

const notificationStyles = css`
  .Toastify__toast-container.Toastify__toast-container--bottom-right {
    width: fit-content;
    bottom: ${gF('px', 62, 124, 1366, 3840)};
    right: ${gF('px', 32, 64, 1366, 3840)};
    padding: 0;

    .afa-toast-success {
      border: ${gF('px', 1, 2, 1366, 3840)} solid ${palette.darkGreen};
      background-color: ${palette.paleGreen};
      ${commonToastsStyle};
    }

    .afa-toast-error {
      border: ${gF('px', 1, 2, 1366, 3840)} solid ${palette.darkYellow};
      background-color: ${palette.paleYellow};
      ${commonToastsStyle};
    }

    .afa-toast-with-links {
      width: fit-content;

      .Toastify__toast-body {
        padding-top: ${gF('px', 10, 20, 1366, 3840)};
        padding-bottom: ${gF('px', 10, 20, 1366, 3840)};

        & > div:last-child {
          width: ${gF('px', 339, 678, 1366, 3840)};
        }

        .Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter {
          padding-left: ${gF('px', 10, 20, 1366, 3840)};
          padding-right: 0;
        }
      }
    }

    .frames-toast {
      background-color: ${palette.turquoise};
      ${commonToastsStyle};

      .Toastify__toast-body {
        & > div:last-child {
          color: ${palette.white};
        }

        .Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter {
          width: ${gF('px', 24, 48, 1366, 3840)};
          height: ${gF('px', 24, 48, 1366, 3840)};
          padding-left: ${gF('px', 0, 10, 1366, 3840)};
          color: ${palette.tulipTree};
          margin-right: ${gF('px', 20, 40, 1366, 3840)};

          i {
            font-size: ${gF('px', 24, 48, 1366, 3840)};
            line-height: ${gF('px', 24, 48, 1366, 3840)};
          }
        }
      }
    }
  }
`

const url = app_config.publicUrl

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Avenir-Roman-Custom';
    src: url('${url}/assets/fonts/avenir/roman/Avenir-Roman.eot');
    src: url('${url}/assets/fonts/avenir/roman/Avenir-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'GilmerBold';
    src: url('${url}/assets/fonts/gilmer/gilmer-bold.woff2') format('woff2'),
    url('${url}/assets/fonts/gilmer/gilmer-bold.woff') format('woff'),
    url('${url}/assets/fonts/gilmer/gilmer-bold.svg#GilmerBold') format('svg');
    font-display: swap;
  }

  @font-face {
    font-family: 'GilmerMedium';
    src: url('${url}/assets/fonts/gilmer/gilmer-medium.woff2') format('woff2'),
    url('${url}/assets/fonts/gilmer/gilmer-medium.woff') format('woff'),
    url('${url}/assets/fonts/gilmer/gilmer-medium.svg#GilmerBold') format('svg');
    font-display: swap;
  }

  @font-face {
    font-family: 'GilmerRegular';
    src: url('${url}/assets/fonts/gilmer/gilmer-regular.woff2') format('woff2'),
    url('${url}/assets/fonts/gilmer/gilmer-regular.woff') format('woff'),
    url('${url}/assets/fonts/gilmer/gilmer-regular.svg#GilmerBold') format('svg');
    font-display: swap;
  }

  @font-face {
    font-family: 'GilmerLight';
    src: url('${url}/assets/fonts/gilmer/gilmer-light.woff2') format('woff2'),
    url('${url}/assets/fonts/gilmer/gilmer-light.woff') format('woff'),
    url('${url}/assets/fonts/gilmer/gilmer-light.svg#GilmerBold') format('svg');
    font-display: swap;
  }

  @font-face {
    font-family: 'EuclidCircularB-Regular';
    src: url('${url}/assets/fonts/euclid-circular-b/EuclidCircularBRegular.woff2') format('woff2'),
    url('${url}/assets/fonts/euclid-circular-b/EuclidCircularBRegular.woff') format('woff');
  }

  @font-face {
    font-family: 'EuclidCircularB-Bold';
    src: url('${url}/assets/fonts/euclid-circular-b/EuclidCircularBBold.woff2') format('woff2'),
    url('${url}/assets/fonts/euclid-circular-b/EuclidCircularBBold.woff') format('woff');
    font-weight: bold;
  }

  @font-face {
    font-family: 'EuclidCircularB-Italic';
    src: url('${url}/assets/fonts/euclid-circular-b/EuclidCircularBItalic.woff2') format('woff2'),
    url('${url}/assets/fonts/euclid-circular-b/EuclidCircularBItalic.woff') format('woff');
    font-weight: normal;
  }

  @font-face {
    font-family: 'EuclidCircularB-Light';
    src: url('${url}/assets/fonts/euclid-circular-b/EuclidCircularBLight.woff2') format('woff2'),
    url('${url}/assets/fonts/euclid-circular-b/EuclidCircularBLight.woff') format('woff');
    font-weight: 300;
  }

  @font-face {
    font-family: 'EuclidCircularB-Medium';
    src: url('${url}/assets/fonts/euclid-circular-b/EuclidCircularBMedium.woff2') format('woff2'),
    url('${url}/assets/fonts/euclid-circular-b/EuclidCircularBMedium.woff') format('woff');
    font-weight: 500;
  }

  div.Toastify {
    ${notificationStyles}
  }

  .ant-notification-notice-description {
    display: none;
  }

  input {
    border: ${pxToRem(spacing(0.5 / 10))}rem solid;
    border-radius: ${pxToRem(spacing(0.5))}rem;
    border-color: ${palette.geyser};
    height: ${pxToRem(spacing(4))}rem;
    opacity: 1;
    color: black;
    font-family: inherit;


    /* Chrome, Safari, Edge, Opera */

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  * {
    margin: 0;
    padding: 0;
    touch-action: manipulation;
  }

  ul,
  ol {
    list-style: none;
  }

  button {
    cursor: pointer;
    border: none;
    background: none;
    font: inherit;
    appearance: none;
    text-transform: inherit;
    outline: none;
    margin: 0;
    padding: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    color: inherit;
    font-weight: inherit;
  }

  h1, h2 {
    font-family: GilmerMedium, sans-serif;
  }

  h3, h4, h5, h6 {
    font-family: GilmerRegular, sans-serif;
  }

  :root {
    --header-height: 15vh;

    @media (max-width: ${breakpoints.M}) {
      --header-height: 5.5vh;
    }

    @media (max-width: ${breakpoints.S}) {
      --header-height: 8vh;
    }

    .visually-hidden { /* Contain text within 1px box */
      height: 1px;
      overflow: hidden;
      width: 1px; /* Keep the layout */
      position: absolute; /* Remove any visible trace (e.g. background color) */
      clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
      clip: rect(1px, 1px, 1px, 1px);
      clip-path: inset(50%); /* browsers in the future */ /* Prevent the screen reader to skip spaces between words */
      white-space: nowrap;
    }
  }

  body {
    * {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin: 0;
      padding: 0;
    }

    &:not(.page-cart) *:not(.allow-scrollbar),
    .cart-brands {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  #root,
  .layout,
  .layout-content,
  body {
    font-family: "Avenir-Roman", "Avenir-Roman-Custom", sans-serif;
    height: 100%;
    margin: 0;
    padding: 0;
      //background: ${palette.white};
    flex: 1;
    min-height: 0;
  }

  body, html {
    overscroll-behavior: none;
    overflow: hidden;
    @media screen and (max-width: ${breakpoints.L}) {
      position: fixed;
      overflow: hidden;
      -webkit-overflow-scrolling: auto !important;
    }
  }

  #root {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .ant-list,
  .ant-checkbox-wrapper {
    font-family: 'Avenir-Roman-Custom', sans-serif;
  }

  .wall-view-container {
    height: 100%;
    background: ${palette.black};

    .hidden-wall {
      display: none;
    }
  }

  .layout {
    display: flex;
    flex-direction: column;
  }

  .btn,
  .ant-btn {
    outline: none;
    border-radius: 2em;
    height: auto;
    padding: .5em 1em;
    min-width: 7em;

    &:hover,
    &:focus {
      color: inherit;
      border-color: ${palette.codGray2};
    }

    &-primary {
      background-color: ${palette.blueCheckout};
      border-color: ${palette.blueCheckout};
    }

    &-magenta {
      background-color: ${palette.tamarillo};
      border-color: ${palette.tamarillo};
    }

    &-danger {
      background-color: ${palette.tamarillo};
      border-color: ${palette.tamarillo};
      color: ${palette.white};
    }

    &.btn-link {
      border: transparent;
    }
  }

  .ant-dropdown-trigger.ant-dropdown-trigger .anticon-down,
  .ant-dropdown-link.ant-dropdown-link .anticon-down {
    font-size: inherit;
  }

  .ant-modal {
    height: calc(100% - 150px);
  }

  .ant-modal-content,
  .ant-modal-body {
    height: 100%;
  }

  .ant-modal-wrap {
    z-index: ${ANT_MODAL_Z_INDEX};
  }

  .ant-modal-mask {
    z-index: ${ANT_MODAL_Z_INDEX - 1};

    &.pdp-modal-mask {
      background-color: ${palette.mineShaftDark};
      @media (max-width: ${breakpoints.M}) {
        z-index: 1;
      }
    }
  }

  a {
    color: ${palette.mineShaft};
  }

  .icon-3D {
    display: inline-block;
    font-size: 1.3rem;
    font-weight: bolder;
    line-height: 1;
    padding: .5em .5em 0;
    margin-left: .5vw;
  }

  @media screen and (min-height: ${breakpoints.L}) {
    .device-table {
      font-size: 1.8rem;

      .ant-checkbox-wrapper, .ant-radio-wrapper {
        zoom: 1;

        .ant-radio {
          zoom: 2;
          margin-right: .5rem;
        }

        .ant-checkbox {
          zoom: 2;
          margin-right: .5rem;
        }
      }

      .ant-modal-body {
        font-size: inherit;
      }
    }

    .ant-modal-content {
      border-radius: 1vh;
    }
  }

  @media screen and (min-width: ${breakpoints.L}) {
    .zoomFix {
      zoom: 2;

      &.login-page {
        .ant-input.input {
          font-size: 1rem;
        }
      }
    }
  }

  .bold {
    font-weight: bold;
  }

  .rh5v-Overlay_inner,
  .rh5v-DefaultPlayer_controls {
    display: none !important;
  }

  .half-opacity {
    opacity: .25;

    &.led-cart-glass {
      .optical & {
        opacity: .20;
      }
    }

    &.led-cart-glass {
      .sun & {
        opacity: .15;
      }
    }
  }

  .full-opacity {
    opacity: 1;
  }

  .rc-hidden-global {
    display: none !important;
  }

  .ant-scroll-number-only > p {
    font-size: 0;
  }

  .ant-scroll-number-only > p.current {
    font-size: 0.7rem;
  }

  .apply-wishlist-btn {
    .ant-popover-content {
      .ant-popover-arrow {
        display: none;
      }
    }

    .ant-popover-inner {
      background: #36d6bb;
      border-radius: 2rem;
      text-align: center;
      box-shadow: none;
      transform: translateY(-1rem);
      padding: 10px;

      .ant-popover-message-title {
        color: ${palette.white};
        font-size: 1.25rem;
        padding: 1rem;
      }

      .ant-popover-buttons {
        text-align: center;

        .ant-btn {
          font-size: 1rem;
          font-weight: bold;
          text-transform: uppercase;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
          margin-right: 1vw;

          &:not(.ant-btn-primary) {
            color: ${palette.blueCheckout};
          }
        }
      }
    }

    @media screen and (max-width: ${breakpoints.M}) {
      .ant-popover-inner {
        transform: translateY(1vh) translateX(-2.5vw);

        .ant-popover-message-title {
          font-size: 1rem;
        }

        .ant-popover-buttons {
          .ant-btn {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .ant-notification-notice-with-icon {
    display: flex;
    align-items: center;
  }

  .success-notification {
    ${notificationStyles};
    outline-color: ${palette.darkGreen} !important;
    background-color: ${palette.paleGreen} !important;
  }

  .error-notification {
    ${notificationStyles};
    outline-color: ${palette.darkYellow} !important;
    background-color: ${palette.paleYellow} !important;
  }

  .afa-checkout-error-email-notification {
    @media (max-width: ${breakpoints.M}) {
      padding-bottom: 12px;
      padding-right: 18px;
    }
  }

  .afa-checkout-success-email-notification {
    @media (max-width: ${breakpoints.M}) {
      padding-bottom: 12px;
    }
  }

  .ant-notification-notice {
    font-family: 'GilmerMedium', sans-serif;
    background: ${palette.turquoise};

    &-message {
      color: ${palette.white};
    }
  }

  .show-on-desktop {
    @media screen and (min-width: 3000px) {
      display: block !important;
    }
  }

  .hide-on-desktop {
    @media screen and (min-width: 3000px) {
      display: none !important;
    }
  }

  .modal-highest {
    z-index: 1005;
  }

  .modal-planogram .ant-modal, .modal-planogram-stars .ant-modal {
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    .ant-modal-content {
      .ant-modal-body {
        overflow: hidden;
      }

      .ant-modal-close {
        .ant-modal-close-x {
          line-height: 5.5rem;
        }

        .ant-modal-close-icon {
          color: ${palette.white};
        }
      }
    }
  }

  .modal-planogram .ant-modal {
    .ant-modal-content {
      background: #000;
    }
  }

  .modal-planogram-stars .ant-modal {
    .ant-modal-content {
      background: #061532;
    }
  }

  .ant-notification {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-content: flex-end;
    z-index: 1000000;
    bottom: 6vh !important;

    span {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      align-content: flex-end;
    }
  }

  .modal-icon-prop {
    .ant-modal {
      height: 100%;
      top: 0;
      padding-bottom: 0;
      margin: 0;
      max-width: 100%;
      overflow: hidden;
    }

    .ant-modal-content {
      background: #000;

      .ant-modal-body {
        padding: 61px 30px 30px 30px !important;

        &:before {
          content: "";
          width: 1px;
          margin-left: -1px;
          float: left;
          height: 0;
          padding-top: 9 / 16 * 100%;
        }

        &:after {
          content: "";
          display: table;
          clear: both;
        }
      }

      .ant-modal-close {
        .ant-modal-close-x {
          line-height: 5.5rem;

          .ant-modal-close-icon {
            color: ${palette.white};
          }
        }
      }
    }
  }

  .code-scanner-modal {
    .ant-modal-header {
      background-color: ${palette.concrete};
    }

    .ant-modal-title {
      color: ${palette.blueCheckout};
      font-size: pxToRem(22);
      text-align: center;
    }

    .ant-modal-close {
      color: ${palette.blueCheckout};
    }
  }

  .scannerControls {
    text-align: center;
  }

  .switch-camera-btn {
    border-radius: 4px;
    color: ${palette.blueCheckout};
    min-width: 0;

    svg {
      vertical-align: middle;
    }
  }

  .page-cart .brand-logo > img {
    max-width: 155px;
    max-height: 3vh;
  }

  .filter-by-modal {
    padding-bottom: 0;

    .ant-modal-content {
      border-radius: 20px 20px 0 0;
      overflow: auto;
      margin-bottom: 30px;
      padding-bottom: 20px;
    }

    .ant-checkbox-group {
      > .ant-row {
        + .ant-row {
          border-top: 1px solid #666;
        }
      }
    }

    .ant-modal-body {
      padding: 2vw 2vw;
      height: auto;
    }

    .ant-modal-close {
      background: ${palette.white};
      position: fixed;
      margin-right: 0.7vw;
      top: 50vh;
      border: 1px solid #dadada;
      border-radius: 8px;
      @media (max-width: ${breakpoints.M}) {
        right: 1vw;
      }

      &-x {
        width: auto;
        height: auto;
        line-height: 1;
        padding: 1rem;

        .anticon {
          font-size: 1.5rem;
        }

        @media (max-width: ${breakpoints.M}) {
          padding: 0.6vw;
          .anticon {
            font-size: 1rem;
          }
        }

        &:before {
          display: inline;
        }
      }
    }

    .ant-radio-wrapper {
      font-size: inherit;
    }
  }

  .modal-checkout {
    .ant-modal {
      height: auto;
    }

    .ant-modal-content,
    .ant-modal-body {
      height: auto;
    }

    .anticon.anticon-close {
      font-size: 22px;
      vertical-align: -0.250em;
      color: ${palette.biscay};
    }

    .ant-modal-header {
      text-align: center;
      border-radius: 8px 8px 0 0;
      background-color: ${palette.concrete};

      .ant-modal-title {
        font-size: 22px;
        color: ${palette.biscay};
      }
    }

    .ant-modal-footer {
      text-align: center;
      border-radius: 0 0 8px 8px;
      border-top: none;
    }

    &-finalize {
      height: 100%;
      padding: 2rem;
      top: 0;

      .ant-modal-content {
        height: 100%;
        padding: 3.3rem 0 3px;

        .ant-modal-body {
          height: 100%;
          padding: 0;

          .checkout-iframe-container {
            height: 100%;
            position: relative;

            &.loading iframe {
              opacity: 0;
            }
          }
        }
      }
    }
  }

  #checkout-iframe {
    border: none;
  }

  .ant-modal-wrap.checkout-modal-wrapper {
    z-index: 1012;
  }

  .modal-afa-checkout-apply {
    font-family: 'GilmerMedium', sans-serif;

    .ant-modal {
      height: auto;
    }

    .ant-modal-content,
    .ant-modal-body {
      height: auto;
    }

    .ant-modal-body {
      padding-bottom: ${gF('px', 40, 66, 1366, 3840)};
      padding-top: 0;
    }

    .ant-modal-content {
      border-radius: 10px;

      @media (min-width: ${breakpointsCross.L.max}) {
        margin-top: 16vh;
      }

      @media (min-width: ${breakpoints.L}) {
        margin-top: ${gF('px', 370, 1010, 1920, 3840)};
      }
    }

    .anticon.anticon-close {
      font-size: 22px;
      vertical-align: -0.250em;
      color: ${palette.tangaroa};
    }

    .ant-modal-header {
      text-align: center;
      border-bottom: 0;
      padding-top: ${gF('px', 40, 110, 1366, 3840)};
      padding-bottom: 0;

      .ant-modal-title {
        color: ${palette.tangaroa};
        font-weight: bold;
        font-size: ${gF('px', 26, 48, 1366, 3840)};

        @media (min-width: ${breakpoints.L}) {
          font-weight: 500;
        }
      }
    }

    .ant-modal-footer {
      text-align: center;
      border-top: none;
    }

    &-finalize {
      height: 100%;
      padding: 2rem;
      top: 0;

      .ant-modal-content {
        height: 100%;
        padding: 3.3rem 0 3px;

        .ant-modal-body {
          height: 100%;
          padding: 0;

          .checkout-iframe-container {
            height: 100%;
            position: relative;

            &.loading iframe {
              opacity: 0;
            }
          }
        }
      }
    }
  }

  .modal-checkout-apply {
    .ant-modal {
      height: auto;
    }

    .ant-modal-content,
    .ant-modal-body {
      height: auto;
    }

    .anticon.anticon-close {
      font-size: 22px;
      vertical-align: -0.250em;
      color: ${palette.tangaroa};
    }

    .ant-modal-header {
      background-color: #E5AD50;
      text-align: center;

      .ant-modal-title {
        color: ${palette.tangaroa};
        font-family: Avenir, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
      }
    }

    .ant-modal-footer {
      text-align: center;
      border-top: none;
    }

    &-finalize {
      height: 100%;
      padding: 2rem;
      top: 0;

      .ant-modal-content {
        height: 100%;
        padding: 3.3rem 0 3px;

        .ant-modal-body {
          height: 100%;
          padding: 0;

          .checkout-iframe-container {
            height: 100%;
            position: relative;

            &.loading iframe {
              opacity: 0;
            }
          }
        }
      }
    }
  }

  .ant-modal-wrap.checkout-modal-wrapper-apply {
    z-index: ${ANT_MODAL_Z_INDEX};
  }

  .login-menu-modal {
    padding: 0;
    position: fixed;
    top: unset;
    bottom: 5vh;
    right: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 0;
    margin: 0 !important;

    .ant-modal-header {
      border-bottom: none;
      border-top-left-radius: 20px;
      border-top-right-radius: 0;
      padding-left: 30px;
    }

    .ant-modal-body {
      padding: 0 24px 0 30px;
    }

    .ant-modal-content {
      box-shadow: none;
      border-radius: 0;
      border-top-left-radius: 1vh;
    }

    .ant-menu {
      border-right: none;
    }

    .ant-modal-close {
      display: none;
    }

    @media screen and (max-width: ${breakpoints.L}) {
      bottom: 5vh;
      max-height: calc(100% - 15vh);
      display: flex;
      .ant-modal-content {
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        .ant-modal-header {
          flex: 0;
        }

        .ant-modal-body {
          flex: 1;
          min-height: 0;
          height: auto;
          overflow-y: auto;
          @media screen and ( max-width: ${breakpoints.S}) {
            padding-bottom: 1rem;
          }
        }
      }
    }
  }

  .desktop .checkout-menu-item {
    display: none;
  }

  .login-menu-modal {
    height: auto;
  }

  .menu-container {
    position: absolute;
    overflow-y: hidden;
    padding: 30px;
    //width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${palette.white};
    border: 1px solid ${palette.silver};
    z-index: 1;
    border-top-left-radius: 1vh;
    right: 0;

    [ant-click-animating-without-extra-node]:after, .ant-click-animating-node {
      left: 94%;
      opacity: 1;
      animation: none;
    }

    .menu-header {
      display: flex;
      padding-bottom: 11px;
      border-bottom: 1px solid ${palette.alto};
      justify-content: space-between;
      color: ${palette.biscay};
      font-size: 1.2rem;
      font-weight: bold;

      span {
        font-size: 0.9rem;
        color: ${palette.curiousBlue};
      }

      .menu-title {
        margin: 0 auto;
        text-transform: uppercase;
        color: ${palette.biscay};
      }

      .ant-btn {
        min-width: unset;
        padding: 14px;
        margin-left: -14px;
        margin-top: -13px;
        line-height: 1;
        border: none;
      }
    }

    .menu-list {
      height: 90%;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .ant-radio-group {
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      .ant-radio-button-wrapper {
        display: block;
        padding: 15px 0;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid ${palette.alto};
        height: auto;
        line-height: 1;
        color: ${palette.blueCheckout};
        font-size: 1rem;
        box-shadow: none !important;
        transition: none;
        outline: none;

        &::before {
          content: none;
        }

        &:last-child {
          border-bottom: none;
        }
      }

      .ant-radio-button-wrapper-checked {
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 30%;
          right: 0;
          width: 6%;
          height: 20%;
          border-left: 1px solid ${palette.blueCheckout};
          border-bottom: 1px solid ${palette.blueCheckout};
          transform: rotate(-45deg);
          transition: none;
        }
      }
    }
  }

  @media screen and (max-width: ${breakpoints.L}) {
    .language-menu-container {
      max-width: 25%;
    }

    .login-menu-modal {
      bottom: 5vh;
      max-height: calc(100% - 15vh);
      display: flex;

      .ant-modal-content {
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        .ant-modal-header {
          flex: 0;
        }

        .ant-modal-body {
          flex: 1;
          min-height: 0;
          height: auto;
          overflow-y: auto;
          @media screen and ( max-width: ${breakpoints.S}) {
            padding-bottom: 1rem;
          }
        }
      }
    }
  }

  .sub-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 0;

    &.hidden {
      opacity: 1;

      .menu-container, .doors-menu-container {
        visibility: hidden;
        transition: .3s;
        transform: translateX(100%);
      }
    }

    &.visible {
      .menu-container, .doors-menu-container {
        transition: .3s;
        transform: translateX(0%);
      }
    }

    .doors-menu-container {
      .ant-btn {
        display: block;
        padding: 15px 40px;
        border-radius: 3rem;
        background-color: ${palette.blueCheckout};
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        color: ${palette.white};
        font-size: 0.8rem;
        margin-left: 1vw;

        &[disabled] {
          opacity: 0.5;
        }
      }
    }
  }

  .order-by-modal {
    font-size: calc(12px + 13 * ((100vw - 1024px) / 2816));
    padding: 0;
    top: auto;
    height: auto;
    bottom: 5%;
    max-width: 80vw;
    left: 29%;
    position: absolute;

    .ant-modal-close {
      display: none;
    }

    .ant-modal-content {
      padding: 0 0.8vw;
      border-radius: 0.5vw 0.5vw 0 0;
    }

    .ant-modal-header {
      padding: 1.3vh 0;
      margin-bottom: 1vh;
    }

    .ant-modal-title {
      font-size: calc(12px + 13 * (100vw / 3840));
      color: ${palette.biscay};
    }

    .ant-modal-body {
      padding: 0;
      font-size: inherit;
      padding-bottom: 1em;
    }

    .ant-radio-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 0.5vh;
      font-size: calc(10px + 13 * (100vw / 3840));
      color: ${palette.biscay};
      text-transform: uppercase;
      vertical-align: middle;
    }
  }

  .footer-order-by, .footer-doors-button {
    .ant-btn {
      z-index: 1002;
    }
  }

  .footer-doors-button, .footer-order-by {
    position: relative;
  }

  @media screen and (max-width: ${breakpoints.L}) {
    .order-by-modal {
      left: 28%;
    }
  }

  @media screen and (max-width: ${breakpoints.M}) {
    .order-by-modal {
      left: 22.5%;
    }
  }

  .filter-by-month-cart .ant-modal-content {
    padding-top: 1.3vh;
  }

  [data-pdp-wall-view="true"] {
    header {
      display: none;
    }
  }

  .rc-notification {
    display: inline-block;
    width: auto;
    color: ${palette.white};
    font-size: 14px;
    background: ${palette.turquoise};
    border-radius: 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

    .undo-notification__btn {
      background: transparent;
      border-color: transparent;
    }

    .undo-notification__close {
      color: ${palette.black};
    }

    .undo-notification__grid {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .undo-notification__padding {
      padding: 10px 20px;
    }

    .undo-notification__column1 {
      text-transform: uppercase;
      font-weight: bold;
      padding-right: 20px;
      border-right: 1px solid ${palette.elfGreen};
    }

    .undo-notification__column2 {
      text-transform: lowercase;
      padding-left: 20px;
    }

    .undo-notification__model-code {
      text-transform: uppercase;
    }

    &.ant-notification-notice {
      background: ${palette.turquoise};
      border-radius: 15px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    }

    .ant-notification-notice-message {
      color: ${palette.white};
    }
  }

  .wall-pdp {
    .pdp-container {
      background-color: rgba(0, 0, 0, 0.35);
    }

    .thumbs-navigation-wrapper {
      width: 16vw;
    }

    &-content {
      background-color: ${palette.wildSand};
      position: relative;
      overflow-y: auto;
      padding: 3.7vmin;
      @media screen and (max-width: ${breakpoints.M}) {
        padding: 1.76vw;
      }

      .pdp_big_logo {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 10vw;
        }
      }

      .blank-view {
        background-color: #dfdfdf;
        width: 54.9vw;
        height: 40vh;
        margin: 0 auto;
        @media screen and (max-width: ${breakpoints.M}) {
          width: 47vw;
        }
      }

      &__sidebar {
        z-index: 9;
        height: 100vh;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;

        .pdp {
          &__color-slider {
            width: 100%;
            height: 90%;

            .thumbs-navigation-wrapper {
              position: static;
              width: 100%;
              height: 100%;
              padding: 0 1.5vh;

              .thumbs-list {
                width: 100%;
                padding-bottom: 0;
              }
            }
          }

          &__switch {
            width: 100%;
            padding: 0 1.5vh;

            button {
              height: 5vh;
              border-radius: 0.7vh;
              border-width: 0;
              font-size: 1.5vh;
              font-weight: 500;
              padding: 1.5vh 3vh;
              color: ${palette.blueCheckout};
            }
          }
        }
      }

      &__mood-board {
        z-index: 8;

        &.is_table {
          .moodboard {
            height: 92vh;
          }
        }
      }

      &__right {
        height: 100%;

        &.is_table {
          .pdp__vto, .blank-view {
            height: 36vh;
          }

          .wall-pdp-content__vto {
            .blank-view {
              height: 100%;
              z-index: 1;
            }
          }
        }
      }

      &__see-through {
        padding: 2.1vh 3vh 4vh;
        z-index: 7;

        .blank-view {
          height: 30vh;
        }
      }

      &__vto {
        z-index: 6;
        position: relative;

        .blank-view {
          position: absolute;
          z-index: 1;
        }
      }
    }
  }

  .filter-checkout-modal {
    padding-bottom: 0;

    .ant-modal-content {
      border-radius: 2px;
      overflow: auto;
      margin-bottom: 30px;
      padding-bottom: 20px;
    }

    .ant-checkbox-group {
      > .ant-row {
        + .ant-row {
          border-top: 1px solid #666;
        }
      }
    }

    .ant-modal-body {
      padding: 2vw 2vw;
      height: auto;
    }

    .ant-modal-close {
      background: ${palette.white};
      position: fixed;
      margin-right: 0.7vw;
      top: 85vh;
      border: 1px solid #dadada;
      border-radius: 2px;
      @media (max-width: ${breakpoints.M}) {
        right: 1vw;
      }

      &-x {
        width: auto;
        height: auto;
        line-height: 1;
        padding: 1rem;

        .anticon {
          font-size: 1.5rem;
        }

        @media (max-width: ${breakpoints.M}) {
          padding: 0.6vw;
          .anticon {
            font-size: 1rem;
          }
        }

        &:before {
          display: inline;
        }
      }
    }

    .ant-radio-wrapper {
      font-size: inherit;
    }
  }

  .keyboard-numeric {
    width: 300px;
    height: auto;
    background: ${palette.strangeWhite};
    border-radius: 4px;
    flex-direction: column;
    flex-wrap: wrap;
    display: flex;
    bottom: 150px;

    .keyboard-row {
      display: flex;
      width: 300px;
      justify-content: space-between;

      &:first-child {
        order: 7;
        margin-top: -20%;
        background-color: ${palette.silverSand};
        z-index: 1;
        width: 100px;

        .keyboard-button {
          background-color: ${palette.silverSand};
          border-color: ${palette.silverSand};
          width: 100px;
        }

        .keyboard-numberButton {
          display: none;
        }
      }

      &:nth-child(5) {
        .shift-symbols {
          display: none;
        }
      }

      &:last-child {
        justify-content: flex-end;
      }

      .keyboard-submit-button {
        flex-basis: 200px;
        background-color: ${palette.blueCheckout};
        border-color: ${palette.blueCheckout};
        color: ${palette.blueCheckout};
        position: relative;
        width: 200px;

        &::after {
          content: 'Close';
          display: block;
          color: ${palette.white};
          position: absolute;
        }
      }

      .keyboard-space {
        display: none;
      }
    }
  }

  .keyboard-wrapper {
    bottom: 5vh;
    border-radius: 4px;
    width: 525px;
    left: calc(50% - 260px);
    margin-top: -1vh;

    .keyboard-submit-button {
      background-color: ${palette.blueCheckout};
      border-color: ${palette.blueCheckout};
      color: ${palette.blueCheckout};
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &::after {
        content: 'Close';
        display: block;
        color: ${palette.white};
      }
    }
  }

  //https://abstractsrl.atlassian.net/browse/RED-5320
  .page-afa {
    #root,
    .ant-notification,
    .ReactModalPortal {
      font-family: 'GilmerMedium', sans-serif;

      .ant-notification-notice {
        background-color: ${palette.noticeNotificationBackground};
        outline: 1px solid ${palette.cloudBurst};

        &.success {
          background-color: ${palette.chromeWhite};
          outline: 1px solid ${palette.leaf};
        }

        &.error {
          background-color: ${palette.papayaWhip};
          outline: 1px solid ${palette.goldDrop};

          .anticon {
            width: ${pxToRem(32)}rem;
            height: ${pxToRem(32)}rem;
          }
        }

        .ant-notification-notice-with-icon {
          display: flex;
          align-items: center;

          i.anticon {
            display: none;
          }

          .ant-notification-notice-message {
            color: ${palette.cloudBurst};
          }
        }

        &:not(.error, .success) .ant-notification-notice-with-icon .ant-notification-notice-message {
          margin-left: 0;
        }

      }
    }
  }

  .react-calendar__month-view__days .react-calendar__tile, .react-calendar__month-view__weekdays__weekday {
    text-decoration: none!important;
  }

  .react-calendar__month-view__weekdays__weekday abbr[title] {
    text-decoration: none!important;
  }

  .react-calendar__month-view__days .react-calendar__tile:disabled:hover abbr {
    background-color: transparent !important;
  }


  :root {
    --rtr-overlay-z-index: 10000000 !important;
  }
`

export default GlobalStyle
