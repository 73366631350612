import classnames from 'classnames'
import React, { MouseEventHandler, TouchEventHandler, useRef, useState } from 'react'
import styled from 'styled-components'

import { isIPadView } from '../../libs/url'
import zoomable from '../UI/Zoomable'

const Wrapper = styled.div`
  position: relative;
  /* max images width is 1800px */
  max-width: 1800px;
  margin: 0 auto;
  height: 100%;
`

const Ul = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  list-style: none;
`

const Li = styled.li`
  display: none;
  overflow: hidden;
  height: 100%;
  &.active {
    display: block;
  }
`

const Img = styled.img`
  margin: 0 auto;
  display: block;
  pointer-events: none;
  user-select: none;
  height: 100%;
  cursor: move;
  width: 100%;
  object-fit: contain;
`

type Props = {
  images: { file: string }[]
  index?: number
  tollerance?: number
  zoomStyle?: Record<string, unknown>
  zoomActive?: boolean
  fullscreen: boolean
  onImagesLoadSuccess: () => void
  onImagesLoadError: () => void
}

const Tre60: React.FC<Props> = ({
  index = 0,
  tollerance = 50,
  zoomActive = true,
  images,
  onImagesLoadSuccess,
  onImagesLoadError,
  zoomStyle = {},
  fullscreen,
}) => {
  const [updatedIndex, setUpdatedIndex] = useState(index)
  const [xCord, setXCoord] = useState(0)
  const imagesLoaded = useRef([] as string[])
  const imagesFailed = useRef([] as string[])

  const onTouchStart: TouchEventHandler<HTMLUListElement> = e => {
    setXCoord(Number(e.touches[0].clientX))
  }

  const onMouseDown: MouseEventHandler<HTMLUListElement> = e => {
    setXCoord(Number(e.clientX))
  }

  const turnRight = () => {
    setUpdatedIndex(updatedIndex - 1 < 0 ? imagesLoaded.current.length - 1 : updatedIndex - 1)
  }

  const turnLeft = () => {
    setUpdatedIndex(updatedIndex + 1 === imagesLoaded.current.length ? 0 : updatedIndex + 1)
  }

  const onTouchMove: TouchEventHandler<HTMLUListElement> = e => {
    const clientX = e.touches[0].clientX

    if (zoomActive) {
      return
    }

    if (clientX > xCord + tollerance) {
      setXCoord(Number(clientX))
      turnRight()
    }
    if (xCord - clientX > tollerance) {
      setXCoord(Number(clientX))
      turnLeft()
    }
  }

  const onMouseMove: MouseEventHandler<HTMLUListElement> = e => {
    const clientX = e.clientX

    if (zoomActive || !e.buttons) {
      return
    }

    if (clientX > xCord + tollerance) {
      setXCoord(Number(clientX))
      turnRight()
    }
    if (xCord - clientX > tollerance) {
      setXCoord(Number(clientX))
      turnLeft()
    }
  }

  const onImageLoadSuccess = (url: string) => {
    imagesLoaded.current.push(url)
    if (imagesLoaded.current.length > 1 && !!onImagesLoadSuccess) {
      onImagesLoadSuccess()
    }
  }

  const onImageLoadError = (url: string) => {
    imagesFailed.current.push(url)
    if (imagesFailed.current.length === images.length - 1 && !!onImagesLoadError) {
      onImagesLoadError()
    }
  }

  const onDoubleClick: MouseEventHandler<HTMLUListElement> = e => {
    if (isIPadView()) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  return (
    <Wrapper className={fullscreen ? 'tre-60 fullscreen' : 'tre-60'}>
      <Ul
        onTouchStart={onTouchStart}
        onMouseDown={onMouseDown}
        onTouchMove={onTouchMove}
        onMouseMove={onMouseMove}
        onDoubleClick={onDoubleClick}
      >
        {images.map((image, i) => {
          const active = updatedIndex === i

          return (
            <Li
              key={i}
              className={classnames({
                active,
              })}
            >
              <Img
                onLoad={() => onImageLoadSuccess(image.file)}
                onError={() => onImageLoadError(image.file)}
                style={active ? zoomStyle : undefined}
                src={image.file}
              />
            </Li>
          )
        })}
      </Ul>
    </Wrapper>
  )
}

export { Tre60 }
export default zoomable(Tre60)
