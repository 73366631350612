import { useAssortmentType } from './useAssortmentType'
import { useSelector } from 'react-redux'
import { cartUnitsByDoor } from '../store/cart/selectors'
import { useGetDoorsWithProducts } from '../services/afaCart'
import { useMemo } from 'react'

export const useCartUnitsByDoor = () => {
  const assortment = useAssortmentType()
  const unitsByDoorFrames = useSelector(cartUnitsByDoor)
  const { cartProductsCountByDoor: unitsByDoorAfa } = useGetDoorsWithProducts(assortment !== 'afa')

  const unitsByDoor = useMemo(() => {
    switch (assortment) {
      case 'afa':
        return unitsByDoorAfa
      case 'frames':
        return unitsByDoorFrames
      default:
        return {}
    }
  }, [assortment, unitsByDoorAfa, unitsByDoorFrames])

  return unitsByDoor
}
