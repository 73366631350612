import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Initiative } from '../../../model/initiative'
import { BoxTitle, Spin } from '../../../style/DigitalEventsCommonComponents'
import { DigitalEventsBoxBorderRadius, palette, pxToRem, spacing } from '../../../style/theme'

const Container = styled.div`
  width: 100%;
`

const Title = styled(BoxTitle)`
  margin-bottom: ${pxToRem(spacing(2))}rem;
`

const Card = styled.div`
  border-radius: ${DigitalEventsBoxBorderRadius};
  color: ${palette.white};
  overflow: hidden;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
`

const PosterImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`

const Video = styled.video`
  width: 100%;
  height: auto;
  position: relative;
`

const InitiativeVideo: React.FC<{ initiative?: Initiative }> = ({ initiative }) => {
  const { t } = useTranslation()
  return (
    <Container>
      <Title>{t('Appointment.Cockpit.AppointmentType.InitiativePresentation')}</Title>
      <Card>
        {initiative ? (
          <>
            <PosterImage src={initiative.posterImage} alt="" />
            <Video
              src={initiative.url}
              poster="/assetes/images/transparent_placeholder.png"
              controls
            />
          </>
        ) : (
          <Spin />
        )}
      </Card>
    </Container>
  )
}

export default InitiativeVideo
