import styled from 'styled-components/macro'
import { getFluidSizeWithFullFormula as gF, palette } from '../../../../style/theme'
import { DEFAULT_INPUTS } from './HeatMap'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DebuggerFeatures } from '../../../../Debug'
import appActions from '../../../../store/app/actions'
import { appSelectors } from '../../../../store/app/selectors'

const Wrapper = styled.div`
  background-color: ${palette.rhino}14;
  backdrop-filter: blur(18px);
  position: absolute;
  top: 50%;
  left: 1vw;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  row-gap: 1vh;
  padding: 1vh;
  border-radius: 15px;
  border: ${gF('px', 1, 2, 1366, 3840)} solid white;
  font-size: ${gF('px', 16, 32, 1366, 3840)};
`

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1vw;
`

const Label = styled.label`
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0.5vh 1vh;
  border-radius: 5px;
  color: white;
`

const Input = styled.input`
  width: 6vw;
`

const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 1vw;
  * {
    flex: 1;
  }
`

const Button = styled.button`
  border-radius: 5px;
  background-color: ${palette.mango};
  color: black;

  &.close {
    background-color: ${palette.milanoRed};
    color: white;
  }

  &.copy {
    background-color: ${palette.tree};
    color: white;
  }

  &:active {
    transform: translateY(${gF('px', 2, 4, 1366, 3840)});
  }
`

export type DefaultInputs = {
  logarithmicBase: number
  radius: number
  maxIntensity: number
  mapOpacity: number
  colorStartingOpacity: number
  colorEndingOpacity: number
  colorSteps: number
}

type Props = {
  defaultInputs: DefaultInputs
  setDefaultInputs: React.Dispatch<React.SetStateAction<DefaultInputs>>
}

const DebugOverlay: React.FC<Props> = ({ defaultInputs, setDefaultInputs }) => {
  const [copyLabel, setCopyLabel] = useState('COPY')
  const dispatch = useDispatch()
  const showDebugOverlay = useSelector(
    appSelectors.isActiveDebugger(DebuggerFeatures.FRAMES_ASSORTMENT_ADVISOR_OVERLAY),
  )

  return (
    <Wrapper>
      {Object.entries(defaultInputs).map(([inputKey, inputValue]) => {
        const inputName = inputKey as keyof DefaultInputs

        return (
          <InputWrapper key={inputKey}>
            <Label htmlFor={`input-${inputKey}`}>{inputKey}</Label>
            <Input
              onChange={event =>
                setDefaultInputs({ ...defaultInputs, [inputName]: parseFloat(event.target.value) })
              }
              onBlur={() =>
                isNaN(inputValue) && setDefaultInputs({ ...defaultInputs, [inputName]: 0 })
              }
              id={`input-${inputKey}`}
              type="number"
              value={inputValue.toString()}
            />
          </InputWrapper>
        )
      })}
      <ButtonsWrapper>
        <Button
          className="close"
          onClick={() => {
            if (showDebugOverlay) {
              dispatch(
                appActions.toggleActiveDebug(DebuggerFeatures.FRAMES_ASSORTMENT_ADVISOR_OVERLAY),
              )
            }
          }}
        >
          CLOSE
        </Button>
        <Button onClick={() => setDefaultInputs(DEFAULT_INPUTS)}>RESET</Button>
        <Button
          className="copy"
          onClick={() => {
            if (copyLabel === 'COPY') {
              navigator.clipboard.writeText(JSON.stringify(defaultInputs))
              setCopyLabel('COPIED!')
              setTimeout(() => {
                setCopyLabel('COPY')
              }, 1000)
            }
          }}
        >
          {copyLabel}
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default DebugOverlay
