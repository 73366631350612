import { Modal } from 'antd'
import classnames from 'classnames'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import app_config from '../../../config/app/config'
import { isTableDevice } from '../../../libs/url'
import { Brand } from '../../../model/brand'
import { CartProduct } from '../../../model/cart'
import { CmsContent, PdpContent } from '../../../model/content'
import { CouvetteVideoState } from '../../../model/couvettes'
import { VirtualMirrorAvailability } from '../../../model/pdp'
import { Moco, Product } from '../../../model/product'
import { WishlistProduct } from '../../../model/wishlist'
import { breakpoints, getFluidFontSize, getFluidSize, palette, zIndex } from '../../../style/theme'
import CloseIcon from '../../icons/CloseIcon'
import ImageFullOr360 from '../../ImageFullOr360'
import MediaPlayer from '../../MediaPlayer'
import PlayPauseVideoBtn from '../../PlayPauseVideoBtn'
import ProductItemTable from '../../Product/ProductItemTable'
import RtlLoader from '../../RtlLoader'
import RCIcon from '../../UI/RCIcon'
import ProductSizes from '../ProductSizes'
import ShowVtoButton from '../TablePdpContent/ShowVtoButton'
import { getProductImageUrl } from '../../../libs/productImages'
import { useSelector } from 'react-redux'
import { languageSelector } from '../../../store/app/selectors'

const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto min-content;
  grid-column-gap: 0px;
  grid-row-gap: 1em;
  border-right: 1px solid ${palette.silver};
  flex: 1;

  .icon-toggler {
    align-items: center;
    justify-content: center;
    max-width: 6vw;
  }

  .full-product-image {
    height: 100%;
    width: 100%;
    overflow: hidden;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
`

const Header = styled.div`
  z-index: 2;
  grid-area: 1 / 1 / 2 / 2;
  padding: 2em 2em 0;
`

const VideoModal = styled(Modal as any)`
  top: 0;
  height: 100%;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  .ant-modal-body {
    padding: 4vh 4vw;
    background-color: ${palette.white};
  }
  .ant-modal-close {
    margin-top: 0;
    background-color: ${palette.white};
    border-radius: 6.4px;
    border: 0.8px solid ${palette.alto};
    right: 1rem;
    top: 1rem;
  }
  .ant-modal-close-x {
    width: 36px;
    height: 36px;
    line-height: 36px;
  }
`
const Image = styled(ImageFullOr360)`
  position: relative;
  height: 100%;
  z-index: 1;
  grid-area: 2 / 1 / 3 / 2;
  display: flex;
  flex-direction: column;
`

const ProductGridControls = styled.div`
  grid-area: 3 / 1 / 4 / 2;
  padding: 0 2em 2em;
`

const ControlsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  > * {
    margin-left: 2em;
  }

  @media screen and (max-width: ${breakpoints.L}) {
    flex-wrap: wrap;
    > * {
      margin-left: 0;
    }
  }
`

const buttonStyle = css`
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid ${palette.gallery};
  border-radius: 10px;
  font-size: ${getFluidFontSize('11px')};
  color: ${palette.biscay};
  height: 4vh;
  display: flex;
  align-items: center;
  padding: 0 0.75em;

  img {
    height: 1.5em;
    width: 1.5em;
    margin-right: 0.5em;
  }
`
const Button = styled.button`
  ${buttonStyle}
`

const SeeThemOnControl = styled(Button)`
  @media screen and (max-width: ${breakpoints.L}) {
    margin-left: 0;
  }
`

const PlayControl = styled(PlayPauseVideoBtn)`
  i {
    height: ${getFluidSize('11px')};
    width: ${getFluidSize('11px')};
  }
  ${buttonStyle}
  &.header-controls__play {
    width: auto;
    background-color: transparent;
  }
`

const VtoController = styled.div`
  @media screen and (max-width: ${breakpoints.L}) {
    flex: 1 0 100%;
    margin: 0 0 1em 0;
  }
`

const VtoButton = styled(ShowVtoButton)`
  ${buttonStyle}
`

const DimensionsControl = styled(Button)`
  .dimensions-button.active {
    background-color: ${palette.congressBlue};
    color: ${palette.white};
  }
`

const OpenRtrButton = styled(Button)`
  min-width: 1.1vw;
  padding: 0;

  .rc-icon {
    width: 2.67em;
    height: 2.67em;
  }

  img {
    height: 2.67em;
    width: 2.67em;
  }
`

const Iframe = styled.iframe`
  border: none;
`

const RtrModalInner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const CloseButton = styled.button`
  display: block;
  font-size: ${getFluidFontSize('20px', 20)};
  width: 2em;
  height: 2em;
  margin: 1em auto 0;

  svg {
    width: 100%;
    height: 100%;
  }
`

const LoadingContainer = styled.div`
  position: relative;
  flex: 1;
`

type Props = {
  model: Product
  moco: Moco
  brand: Brand
  couvetteVideo?: CouvetteVideoState
  wishlistProducts: WishlistProduct[]
  modelVideo?: CmsContent
  cartProducts: CartProduct[]
  content?: PdpContent
  virtualMirrorAvailability: VirtualMirrorAvailability
  showModalProductView: (moco: Moco) => void
  isSeeThemOnAvailable: boolean
}

export const PDPLeftCol: React.FC<Props> = ({
  couvetteVideo,
  modelVideo,
  moco,
  model,
  wishlistProducts,
  cartProducts,
  content,
  virtualMirrorAvailability,
  brand,
  showModalProductView,
  isSeeThemOnAvailable,
}) => {
  const language = useSelector(languageSelector)
  const { t } = useTranslation()
  const [isLoadingIframe, setIframeLoading] = useState(false)

  const [upcsRTR, setUpcsRTR] = useState<string[]>([])

  useEffect(() => {
    Object.values(model.mocos).forEach(moco => {
      Object.keys(moco.sizes).forEach(upc => {
        window.rtrViewerMV?.isIdAvailable({ type: 'upc', value: upc }).then((hasRTR: boolean) => {
          if (hasRTR) {
            setUpcsRTR(current => current.concat([upc]))
          }
        })
      })
    })
  }, [model.mocos])

  const showRTRButton = upcsRTR.length > 0

  const [dimensionsVisible, setDimensionsVisible] = useState(false)
  const [videoVisible, setVideoVisible] = useState(false)
  const [openRtrModal, setOpenRtrModal] = useState(false)

  const { mocoCode, sizes } = moco
  const materialVideo = content && content.video && content.video.url
  const shouldRenderPlayButton = modelVideo !== undefined && isTableDevice()
  const shouldRenderMaterialButton = content && content.video && content.video.url
  const shouldRenderTryThemOnButton =
    moco && get(virtualMirrorAvailability, `${moco.mocoCode}.isAvailable`, false)

  const dimensionsImage = getProductImageUrl({ path: moco.catalogImages.base, imwidth: 600 })

  return (
    <>
      <Wrapper className={classnames({ 'product-grid': true })}>
        <Header className="product-grid-details">
          <ProductItemTable
            cartProducts={cartProducts}
            moco={moco}
            wishlistProducts={wishlistProducts}
            brand={brand}
            model={model}
          />

          {videoVisible && (
            <VideoModal
              className="video-modal"
              title=""
              visible={videoVisible}
              onCancel={() => setVideoVisible(!videoVisible)}
              width="100%"
              footer={false}
              destroyOnClose={true}
              wrapClassName="modal-highest"
              maskStyle={{ zIndex: 1004 }}
            >
              <MediaPlayer videoUrl={materialVideo} loop={false} />
            </VideoModal>
          )}
        </Header>
        <Image
          className="product-grid-image"
          fullscreen={false}
          mocoCode={mocoCode}
          catalogImages={moco.catalogImages}
        />

        <ProductGridControls className="product-grid-controls">
          <ControlsContainer className="controls-container">
            {shouldRenderTryThemOnButton && (
              <VtoController>
                <VtoButton upc={virtualMirrorAvailability[moco.mocoCode].upc} />
              </VtoController>
            )}
            {isSeeThemOnAvailable && (
              <SeeThemOnControl
                onClick={() => {
                  showModalProductView(moco)
                }}
              >
                <RCIcon type="see-them" />
                {t('ProductPage.seeThemOn')}
              </SeeThemOnControl>
            )}
            {shouldRenderPlayButton && (
              <PlayControl
                classSmartLook="video_prodotto"
                couvetteVideo={couvetteVideo}
                media={modelVideo}
                uniqueId={mocoCode}
              />
            )}
            {shouldRenderMaterialButton && (
              <Button
                onClick={() => setVideoVisible(!videoVisible)}
                className="material-video-button"
              >
                <RCIcon type="material" />
                {t('ProductPage.material')}
              </Button>
            )}
            <DimensionsControl
              onClick={() => setDimensionsVisible(!dimensionsVisible)}
              className={classnames({ 'dimensions-button': true, active: dimensionsVisible })}
            >
              <RCIcon type="dimensions" />
              {t('ProductPage.dimensions')}
            </DimensionsControl>

            {showRTRButton && (
              <OpenRtrButton
                className="open-rtr three-d"
                data-testid="rtr-button"
                onClick={() => {
                  const initInput = {
                    data: {
                      id: {
                        type: 'upc',
                        value: upcsRTR[0],
                      },
                      locale: language,
                    },
                  }
                  window.rtrViewerMV?.init(initInput)
                }}
              >
                <RCIcon type="3D" />
              </OpenRtrButton>
            )}
          </ControlsContainer>
        </ProductGridControls>

        {dimensionsVisible && (
          <ProductSizes
            close={() => setDimensionsVisible(!dimensionsVisible)}
            image={dimensionsImage}
            sizes={sizes}
          />
        )}
      </Wrapper>
      {showRTRButton && (
        <Modal
          className="rtr-modal"
          title=""
          visible={openRtrModal}
          width="100%"
          footer={false}
          zIndex={zIndex.rtrModal}
          closable={false}
          maskClosable={true}
        >
          <RtrModalInner>
            <LoadingContainer>
              <Iframe
                width="100%"
                height="100%"
                onLoadStart={() => setIframeLoading(true)}
                onLoad={() => setIframeLoading(false)}
                src={`${app_config.RTRBasePath}/rc/index.html?id=${model.modelCode}${
                  window.device.desktop() ? '&desktop=true' : ''
                }`}
              />
              {isLoadingIframe && <RtlLoader isFullPage={false} />}
            </LoadingContainer>
            <CloseButton onClick={() => setOpenRtrModal(false)}>
              <CloseIcon />
            </CloseButton>
          </RtrModalInner>
        </Modal>
      )}
    </>
  )
}

export default PDPLeftCol
