import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import afaActions from '../../store/afa/actions'
import { cartFiltersOpenSelector } from '../../store/afa/selectors'
import Backdrop from '../../styleguide/Backdrop'
import styled from 'styled-components'
import { breakpoints, getFluidSizeWithFullFormula } from '../../style/theme'
import AfaFiltersLayer from '../../components/AfaFilters/AfaFiltersLayer'
import { useGetAfaCartFiltersData } from '../../hooks/useGetAfaCartFiltersData'
import { useGetFilteredAfaCartQuery } from '../../services/afaCart'

const FiltersBackdrop = styled(Backdrop)`
  & > div {
    width: min(${getFluidSizeWithFullFormula('px', 697, 1223, 1366, 3840)}, 100%);
    height: min(${getFluidSizeWithFullFormula('px', 623, 1280, 1366, 3840)}, 100%);
  }

  & > div {
    @media (max-width: ${breakpoints.M}) {
      transform: translateY(100%);

      &.is-open {
        transform: translateX(0);
      }

      top: unset !important;
    }

    top: unset;
    bottom: 0;
    transform: translateX(-100%);

    .is-open & {
      transform: translateX(0);
    }
  }
`

const AfaCartFilters: React.FC = () => {
  const filtersOpen = useSelector(cartFiltersOpenSelector)

  const dispatch = useDispatch()

  const categories = useGetAfaCartFiltersData()

  const filteredCartQuery = useGetFilteredAfaCartQuery()
  const numberOrResults = filteredCartQuery.data?.items.reduce(
    (sum, { unconfirmedQuantity }) => sum + unconfirmedQuantity,
    0,
  )

  const showResults = () => {
    dispatch(afaActions.closeCartFilter())
  }

  return (
    <FiltersBackdrop onClick={() => showResults()} open={filtersOpen}>
      <AfaFiltersLayer
        categories={categories}
        showLoader={false}
        showResults={showResults}
        defaultSelectedCategoryName={''}
        numberOfResults={numberOrResults}
      />
    </FiltersBackdrop>
  )
}

export default AfaCartFilters
