import { isEqual } from 'lodash'

import { VirtualMirror } from '@luxottica/virtual-mirror'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import app_config from '../../config/app/config'
import { deleteIntersessionCustomerId, storeIntersessionCustomerId } from '../../libs/customer'
import { deleteIntersessionEventId, storeIntersessionEventId } from '../../libs/event'
import { getDeviceFromUrl, getRoomFromUrl, getRoomTypeFromUrl } from '../../libs/url'
import { Content } from '../../model/content'
import { RcEvent } from '../../model/events'
import {
  AppContent,
  AppLangCode,
  AppState,
  Customer,
  DeviceName,
  ModalType,
  RoomType,
  ScanCamerMode,
} from '../../model/model'
import { resetForNewClient } from '../extraActions'
import { AfaBrand } from '../../model/afa'
import { Environments } from '../../model/api'
import { DebuggerFeatures } from '../../Debug'

const emptyContent: Content = {
  id: '',
  type: 'image',
  title: '',
  url: '',
  mediaType: '',
}

const DEFAULT_STATE: AppState = {
  content: {
    intro: emptyContent,
    welcomeLeddx: emptyContent,
    nav_led_dx: emptyContent,
    act_led_dx: emptyContent,
    multibrandLeddx: emptyContent,
    digitalPreviewLeddx: emptyContent,
    welcomeLedsx: emptyContent,
    nav_led_sx: emptyContent,
    act_led_sx: emptyContent,
    multibrandLedsx: emptyContent,
    digitalPreviewLedsx: emptyContent,
    springsummerImage: emptyContent,
    digitalPreviewWall: emptyContent,
    welcome: emptyContent,
    multibrandWall: emptyContent,
  },
  customerId: '',
  customers: null,
  errors: [],
  eventId: '',
  events: null,
  footerModalVisible: '',
  loadingLanguage: false,
  lang: app_config.langDefault as AppLangCode,
  languages: (app_config.languages as any) as { name: string; code: AppLangCode }[],
  loaded: false,
  loading: false,
  notifications: [],
  isBrandActivationPlaying: false,
  isBrandActivationPlayed: false,
  massiveOrder: false,
  isKidCategoryModeEnabled: false,
  scanCameraMode: 'environment',
  isStickyHeader: false,
  roomName: getRoomFromUrl(),
  roomType: getRoomTypeFromUrl() as RoomType | undefined,
  deviceName: getDeviceFromUrl() as DeviceName | undefined,
  afaBrandSelected: {} as AfaBrand,
  showSpinner: false,
  environment: app_config.env as Environments,
  debug: {
    active: [] as number[],
  },
}

export const slice = createSlice({
  name: 'app',
  initialState: DEFAULT_STATE,
  reducers: {
    initSession: state => {
      state.customerId = ''
      state.eventId = ''
      state.roomName = getRoomFromUrl()
      state.roomType = (getRoomTypeFromUrl() as RoomType) || 'standard'
      state.deviceName = getDeviceFromUrl() as DeviceName | undefined
    },
    setDeviceName: (state, { payload }: PayloadAction<DeviceName>) => {
      state.deviceName = payload
    },
    _setRoomType: (state, { payload }: PayloadAction<RoomType>) => {
      state.roomType = payload
    },
    setRoomName: (state, { payload = '' }: PayloadAction<string>) => {
      state.roomName = payload.toLowerCase()
    },
    setGeneralContentLoading: state => {
      state.loading = true
    },
    setGeneralContent: (state, { payload }: PayloadAction<AppContent>) => {
      state.content = payload
      state.loading = false
      state.loaded = true
    },
    enableKidCategoryMode: state => {
      state.isKidCategoryModeEnabled = true
    },
    disableKidCategoryMode: state => {
      state.isKidCategoryModeEnabled = false
    },
    setScanCameraMode: (state, { payload }: PayloadAction<ScanCamerMode>) => {
      state.scanCameraMode = payload
    },
    setStickyHeaderAction: (state, { payload }: PayloadAction<boolean>) => {
      state.isStickyHeader = payload
    },
    resetErrors: state => {
      state.errors = []
    },
    toggleMassiveOrder: state => {
      state.massiveOrder = !state.massiveOrder
    },
    setEventReleases: (
      state,
      { payload }: PayloadAction<{ eventId: string; releases: Partial<RcEvent> }>,
    ) => {
      if (state.events) {
        state.events = state.events.map(event =>
          event.id === payload.eventId
            ? Object.assign(event, { releases: payload.releases })
            : event,
        )
      }
    },
    changeLanguageAction: (
      state,
      { payload }: PayloadAction<{ language: AppLangCode; loading: boolean }>,
    ) => {
      state.loadingLanguage = payload.loading
      state.lang = payload.language
    },
    removeError: (state, { payload }: PayloadAction<any>) => {
      state.errors = state.errors.filter(error => !isEqual(error, payload))
    },
    setCustomers: (
      state,
      { payload }: PayloadAction<{ customers: Customer[] | null; customerId?: string }>,
    ) => {
      state.customers = payload.customers
      if (payload.customerId) {
        storeIntersessionCustomerId(payload.customerId)
        state.customerId = payload.customerId
      }
    },
    showFooterModal: (state, { payload }: PayloadAction<ModalType | null>) => {
      state.footerModalVisible = payload || ''
    },
    setCustomerId: (state, { payload }: PayloadAction<string>) => {
      if (payload === '') {
        deleteIntersessionCustomerId()
      }
      VirtualMirror.resetBipaState()
      storeIntersessionCustomerId(payload)
      state.customerId = payload
    },
    setCustomerIdWithoutSync: (state, { payload }: PayloadAction<string>) => {
      if (payload === '') {
        deleteIntersessionCustomerId()
      }
      VirtualMirror.resetBipaState()
      storeIntersessionCustomerId(payload)
      state.customerId = payload
    },
    delCustomerId: state => {
      VirtualMirror.resetBipaState()
      deleteIntersessionCustomerId()
      state.customerId = ''
    },
    setEvents: (state, { payload }: PayloadAction<{ events: RcEvent[]; eventId?: string }>) => {
      state.events = payload.events
      if (payload.eventId) {
        storeIntersessionEventId(payload.eventId)
        state.eventId = payload.eventId
      }
    },
    setEventListWithoutSync: (state, { payload }: PayloadAction<RcEvent[]>) => {
      state.events = payload
    },
    setEventId: (state, { payload }: PayloadAction<string>) => {
      if (payload === '') {
        deleteIntersessionEventId()
      }
      storeIntersessionEventId(payload)
      state.eventId = payload
    },
    setEventIdWithoutSync: (state, { payload }: PayloadAction<string>) => {
      if (payload === '') {
        deleteIntersessionEventId()
      }
      storeIntersessionEventId(payload)
      state.eventId = payload
    },
    delEventId: state => {
      deleteIntersessionEventId()
      state.eventId = ''
    },
    storeError: (state, { payload }: PayloadAction<any>) => {
      state.errors = state.errors.concat(payload)
    },
    storeErrorWithoutSync: (state, { payload }: PayloadAction<any>) => {
      state.errors = state.errors.concat(payload)
    },
    setAfaBrand: (state, { payload }: PayloadAction<AfaBrand>) => {
      state.afaBrandSelected = payload
    },
    setShowSpinner: (state, { payload }: PayloadAction<boolean>) => {
      state.showSpinner = payload
    },
    setEnvironment: state => {
      state.environment = app_config.env as Environments
    },
    setDebug: (state, { payload }: PayloadAction<number[]>) => {
      state.debug.active = payload
    },
    toggleActiveDebug: (state, { payload }: PayloadAction<DebuggerFeatures>) => {
      if (state.debug.active.includes(payload)) {
        state.debug.active = state.debug.active.filter(feature => feature !== payload)
      } else {
        state.debug.active = state.debug.active.concat([payload])
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, state => {
      state.customerId = ''
      state.afaBrandSelected = {} as AfaBrand
      deleteIntersessionCustomerId()
    })
  },
})

export default slice.reducer
