import React from 'react'
import Iframe from 'react-iframe'
import { useSelector } from 'react-redux'
import { RouteChildrenProps } from 'react-router'
import styled from 'styled-components'

import CloseIcon from '../components/icons/CloseIcon'
import app_config from '../config/app/config'
import { getWCSToken } from '../libs/auth'
import { eventIdSelector, languageSelector } from '../store/app/selectors'
import { tokenSelector } from '../store/auth/selectors'
import { DigitalEventsExternalPagesCloseButton } from '../style/DigitalEventsCommonComponents'
import { Environments } from '../model/api'

const StyledIFrame = styled(Iframe)`
  border: none;
`

const mapEnvToPageAddress: Record<Environments, string> = {
  lcl: 'https://rccontactmanagementdev.azurewebsites.net',
  dev: 'https://rccontactmanagementdev.azurewebsites.net',
  stage: 'https://rccontactmanagementdev.azurewebsites.net',
  qua: 'https://rccontactmanagementdev.azurewebsites.net',
  uat: 'https://rccontactmanagementqual.azurewebsites.net/',
  prod: 'https://rccontactmanagementprod.azurewebsites.net',
}

const ContactManagementPage: React.FC<RouteChildrenProps> = ({ history }) => {
  const token = useSelector(tokenSelector)
  const selectedLanguage = useSelector(languageSelector)
  const eventId = useSelector(eventIdSelector)
  const pageAddress = mapEnvToPageAddress[app_config.env as Environments] || mapEnvToPageAddress.dev

  const wcsToken = getWCSToken(token)
  return (
    <>
      <DigitalEventsExternalPagesCloseButton onClick={() => history.goBack()}>
        <CloseIcon />
      </DigitalEventsExternalPagesCloseButton>
      <StyledIFrame
        url={`${pageAddress}?eventCode=${eventId}&token=${wcsToken}&locale=${selectedLanguage.replace(
          '-',
          '_',
        )}`}
        width="100%"
        height="100%"
        id="contacts-iframe"
        display="block"
        position="relative"
      />
    </>
  )
}

export default ContactManagementPage
