import { createSelector } from 'reselect'
import { RootState } from '../../model/model'

export const getInitiatives = createSelector(
  (state: RootState) => state.initiatives.initiatives,
  initiatives => initiatives,
)

export const selectedInitiativeSelector = createSelector(
  (state: RootState) => state.initiatives.selectedInitiative,
  selectedInitiative => selectedInitiative,
)

export const categoryIsLoadingSelector = createSelector(
  (state: RootState) => state.initiatives.categoryIsLoading,
  categoryIsLoading => categoryIsLoading,
)

export const launchInitiativeSelector = createSelector(
  (state: RootState) => state.initiatives.launchInitiative,
  launchInitiative => launchInitiative,
)
