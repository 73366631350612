import React from 'react'
import styled from 'styled-components/macro'
import Loading from '../../../../components/Loading'
import ProductCard from './ProductCard'
import useGetFilteredMocos from '../../Hooks/useGetFilteredMocos'
import { useTranslation } from 'react-i18next'
import { Choice } from '../../Model/aaModel'
import { getFluidSizeWithFullFormula as gF } from '../../../../style/theme'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16vw, 1fr));
  gap: 0.5vw;
  grid-auto-rows: min-content;
  min-height: calc(100% - 5vw);
  position: relative;
`

const Alert = styled.div`
  width: 100%;
  height: calc(100% - 5vw);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${gF('px', 25, 50, 1366, 3840)};
  text-align: center;
`

const Products: React.FC<{
  settings: { showMatchToggleActive: boolean; showAllTagsToggleActive: boolean }
  sortCriteria?: Choice
}> = ({ settings, sortCriteria }) => {
  const { t } = useTranslation()

  const { mocos, isFetching, isLoading } = useGetFilteredMocos(sortCriteria)

  return !isFetching && !isLoading && !mocos.length ? (
    <Alert>{t('AA.Plp.emptyCombination')}</Alert>
  ) : (
    <Wrapper>
      {isFetching || isLoading ? (
        <Loading isFullPage={false} />
      ) : (
        mocos.map(moco => (
          <ProductCard
            key={`${moco.modelCode}_${moco.colorCode}`}
            moco={moco}
            settings={settings}
          />
        ))
      )}
    </Wrapper>
  )
}

export default Products
