import { useParams } from 'react-router'
import { getDefaultColor } from '../libs/afa'
import { useGetProductQuery } from '../services/afaProduct'
import { useSearchParams } from './useSearchParams'

export const useGetAfaProductSport = () => {
  const [searchParams] = useSearchParams()
  const selectedColorCode = searchParams.get('color')

  const { modelCode } = useParams<{ modelCode?: string }>()

  const productQuery = useGetProductQuery(modelCode)
  const product = productQuery.data || undefined

  const defaultColor = getDefaultColor(product)

  const selectedColor = selectedColorCode ? product?.mocos[selectedColorCode] : defaultColor

  const sport = selectedColor?.sports && selectedColor?.sports[0]

  return sport
}
