import { RootState } from '../../model/model'

const isMapBrandsFilterOpen = (state: RootState) => state.assortmentAdvisor.isMapBrandsFilterOpen
// const searchedAddressDetails = (state: RootState) => state.assortmentAdvisor.searchedAddressDetails
// const searchedCoordinates = (state: RootState) => state.assortmentAdvisor.searchedCoordinates
// const getSelectedAddress = (state: RootState) => state.assortmentAdvisor.selectedAddress
const searchedAddressSelector = (state: RootState) => state.assortmentAdvisor.searchedAddress
const getSelectedDoorId = (state: RootState) => state.assortmentAdvisor.selectedDoorId
const getDefaultAddressSelector = (state: RootState) => state.assortmentAdvisor.getDefaultAddress
const mapDraggedSelector = (state: RootState) => state.assortmentAdvisor.mapDragged

const assortmentAdvisorSelectors = {
  isMapBrandsFilterOpen,
  // searchedAddressDetails,
  // searchedCoordinates,
  // getSelectedAddress,
  searchedAddressSelector,
  getSelectedDoorId,
  getDefaultAddressSelector,
  mapDraggedSelector,
}

export default assortmentAdvisorSelectors
