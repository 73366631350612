import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import AfaFilters from '../../components/AfaFilters/AfaPlpFilters'
import AfaFooter from '../../components/AfaFooter'
import AfaSearch from '../../components/AfaSearch/AfaSearch'
import AfaHeader from '../../components/Header/AfaHeader'
import { useSearchParams } from '../../hooks/useSearchParams'
import { useGetPlpProductsQuery } from '../../services/afaProducts'
import { getFluidSizeWithFullFormula, palette } from '../../style/theme'
import AfaPlpProducts from './AfaPlpProducts'
import AfaSearchResultFooter from './AfaSearchResultFooter'
import AfaSearchResultTopbar from './AfaSearchResultTopbar'
import WhiteboardModalPin from '../Whiteboard/WhiteboardModalPin'
import { openPinModalSelector } from '../../store/whiteboard/selectors'
import { useDispatch, useSelector } from 'react-redux'
import whiteboardActions from '../../store/whiteboard/actions'
import { CustomAfaModal } from '../AfaKeylooks/AfaKeylooksMain'
import AfaSort from '../../components/AfaSort/AfaSort'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`

const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Title = styled.div`
  background-color: ${palette.alabaster};
  text-align: center;
  font-size: ${getFluidSizeWithFullFormula('px', 28, 64, 1366, 3840)};
  padding: 1.2em 0;
`

const FiltersAndProducts = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const AfaSearchResultPage: React.FC = () => {
  const { t } = useTranslation()
  const openPinModal = useSelector(openPinModalSelector)
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  const searchTerm = searchParams.get('searchTerm')

  const productsQuery = useGetPlpProductsQuery()

  const [firstProductResult] = productsQuery.currentData?.products || []

  return (
    <Wrapper>
      <AfaSearch />

      <AfaHeader />

      <Main>
        <AfaSearchResultTopbar />

        <FiltersAndProducts>
          <AfaSort />

          <AfaFilters />

          {productsQuery.isSuccess && (
            <Title>
              {t(
                productsQuery.data && productsQuery.data.count > 0
                  ? 'Afa.resultsFor'
                  : 'Afa.noResultsFor',
              )}{' '}
              <strong>{searchTerm}</strong>
            </Title>
          )}

          <AfaPlpProducts hideNumberOfProducts />
        </FiltersAndProducts>
      </Main>

      {firstProductResult?.brandCodeParent && (
        <AfaSearchResultFooter brandCode={firstProductResult.brandCodeParent} />
      )}

      <AfaFooter productBrand={firstProductResult?.brandCodeParent} />

      <CustomAfaModal
        isOpen={openPinModal}
        closeOnBackdropClick
        onRequestClose={() => {
          dispatch(whiteboardActions.setOpenPinModal(false))
          dispatch(whiteboardActions.resetItemsPinModal())
        }}
        fullscreen={false}
      >
        <WhiteboardModalPin />
      </CustomAfaModal>
    </Wrapper>
  )
}

export default AfaSearchResultPage
