import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'

import useFetchInitialData from '../../hooks/useFetchInitialData'
import { AfaMoco } from '../../model/afa'
import { activeDoorsSelector } from '../../store/customer/selectors'
import {
  breakpoints,
  breakpointsCross,
  getFluidSizeWithFullFormula as gF,
  palette,
  pxToRem,
} from '../../style/theme'
import AfaTabSwitcher from '../../styleguide/AfaTabSwitcher'
import DuplicateOrderModalContent from './DuplicateOrderModalContent'
import SizeRows from './SizeRows'
import { useSearchParams } from '../../hooks/useSearchParams'
import AfaModal from '../../components/AfaModal'
import DuplicateButton from './DuplicateButton'
import { sortDoorsList } from '../../libs/doors'
import { useDuplicateCartOnDoorsMutation } from '../../services/afaCart'

const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: max-content 1fr;
  overflow: hidden;

  @media (min-width: ${breakpoints.L}) {
    & > div:first-child {
      order: 2;
    }
    grid-auto-rows: 1fr max-content;
  }
`

const Drops = styled.div`
  padding: 2.5rem clamp(20px, ${gF('px', 20, 64, 1366, 3840)}, 64px);
  background-color: ${palette.white};
  border: 1px solid ${palette.mischka};
  border-radius: ${pxToRem(4)}rem ${pxToRem(4)}rem 0 0;
  overflow-y: auto;

  @media (max-width: ${breakpointsCross.L.max}) {
    flex-direction: column;
    border-radius: 0 0 ${pxToRem(4)}rem ${pxToRem(4)}rem;
  }
`

const DuplicateInfoWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

type Props = {
  className?: string
  color: AfaMoco
  cartIsLoadingUpdates?: boolean
}

const ColorDoorsAndDrops: React.FC<Props> = ({ className, color, cartIsLoadingUpdates }) => {
  useFetchInitialData({ loadCustomer: true })
  const activeDoors = useSelector(activeDoorsSelector)
  const [selectedDoorPosition, setSelectedDoorPosition] = useState(-1)

  const [searchParams, setSearchParams] = useSearchParams()
  const selectedDoorId = searchParams.get('doorId') || ''
  const isCartPage = window.location.pathname.includes('/cart')

  const [destinationDoorIds, setDestinationDoorIds] = useState<string[]>([])

  const [, duplicateProductOnDoorsResult] = useDuplicateCartOnDoorsMutation()

  const setSelectedDoorId = useCallback(
    (doorId: string) => {
      searchParams.set('doorId', doorId)
      setSearchParams(searchParams, { replace: true })
    },
    [searchParams, setSearchParams],
  )

  const [duplicateInfoModalOpen, setDuplicateInfoModalOpen] = useState(false)

  const sortedTabs = sortDoorsList(
    activeDoors.map(door => ({ ...door, units: 0 })),
    { key: 'id', ascending: true },
  )

  useEffect(() => {
    if (!selectedDoorId && activeDoors.length > 0) {
      setSelectedDoorId(activeDoors.find(({ mainDoor }) => mainDoor)?.id || activeDoors[0].id)
    }

    if (selectedDoorId && sortedTabs.find(door => door.id === selectedDoorId)) {
      const oldSelectedIndex = sortedTabs.findIndex(door => door.id === selectedDoorId)
      setSelectedDoorPosition(oldSelectedIndex)
    }

    if (
      selectedDoorId &&
      !sortedTabs.find(door => door.id === selectedDoorId) &&
      sortedTabs[selectedDoorPosition]
    ) {
      setSelectedDoorId(sortedTabs[selectedDoorPosition].id)
    }
  }, [activeDoors, selectedDoorId, selectedDoorPosition, setSelectedDoorId, sortedTabs])

  const onClose = () => {
    setDuplicateInfoModalOpen(false)
    setTimeout(() => {
      duplicateProductOnDoorsResult.reset()
      setDestinationDoorIds([])
    }, 250)
  }

  return (
    <Wrapper className={className}>
      {selectedDoorId && (
        <AfaTabSwitcher
          selectedTabId={selectedDoorId}
          setSelectedTabId={setSelectedDoorId}
          tabs={sortedTabs.map(door => ({ id: door.id, label: door.name }))}
          cartIsLoadingUpdates={cartIsLoadingUpdates}
        />
      )}

      <Drops>
        <DuplicateInfoWrapper>
          {!isCartPage && (
            <DuplicateButton
              activeDoors={activeDoors}
              selectedDoorId={selectedDoorId}
              modelCode={color.modelCode}
              colorCode={color.colorCode}
              variant="top"
              setDuplicateInfoModalOpen={setDuplicateInfoModalOpen}
            />
          )}

          <AfaModal isOpen={duplicateInfoModalOpen} onRequestClose={() => onClose()}>
            {
              <DuplicateOrderModalContent
                sourceDoorId={selectedDoorId}
                upcs={Object.keys(color.sizes)}
                close={() => onClose()}
                destinationDoorIds={destinationDoorIds}
                setDestinationDoorIds={setDestinationDoorIds}
                key={duplicateInfoModalOpen ? 1 : 0}
              />
            }
          </AfaModal>
        </DuplicateInfoWrapper>

        <SizeRows cartIsLoadingUpdates={cartIsLoadingUpdates} selectedDoorId={selectedDoorId} />

        <DuplicateButton
          activeDoors={activeDoors}
          selectedDoorId={selectedDoorId}
          modelCode={color.modelCode}
          colorCode={color.colorCode}
          variant="bottom"
          setDuplicateInfoModalOpen={setDuplicateInfoModalOpen}
        />
      </Drops>
    </Wrapper>
  )
}

export default ColorDoorsAndDrops
