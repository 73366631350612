import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RouteChildrenProps, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import SwiperCore from 'swiper/core'

import { Button as MButton } from '@material-ui/core'

import DigitalEventsFooter from '../../components/DigitalEventsFooter/DigitalEventsFooter'
import DigitalEventsHeader from '../../components/DigitalEventsHeader/DigitalEventsHeader'
import ProductModalView from '../../components/Product/ProductModalView'
import useFetchBackgrounds from '../../hooks/useFetchBackgrounds'
import { checkVirtualMirrorAvailability } from '../../libs/couvettes'
import { getCLensToMocoCode } from '../../libs/productsV2'
import { HTMLVideoElementWithDisablePip, TDispatch } from '../../model/model'
import { Moco, Product } from '../../model/product'
import { brandsSelector } from '../../store/brands/selectors'
import { getDetailFromModelCodes } from '../../store/couvettes/actions'
import { loadedProductsSelector } from '../../store/couvettes/selectors'
import { getSingleBrandPageContents } from '../../store/digitalEvents/actions'
import {
  categoriesPageDEContentsSelector,
  selectCollectionHighlightsBg,
} from '../../store/digitalEvents/selectors'
import { getVmUrl, loadPdpContent, setSeethroughModalVisibility } from '../../store/pdp/actions'
import {
  isSeethroughModalVisibleSelector,
  pdpContentSelector,
  pdpHighlightSeeThemOnAvailableForMocosSelector,
} from '../../store/pdp/selectors'
import {
  scrollbarCssDe,
  scrollMaxHeight,
  XSHeight,
} from '../../style/DigitalEventsCommonComponents'
import { breakpointsCross, getFluidFontSize, palette, pxToRem, spacing } from '../../style/theme'
import Icon from '../../styleguide/Icon'
import { extractModelFromModelCode } from './helper'
import HightlightBox from './HighlightBox'
import SeenProductsSlider from './SeenProductsSlider'
import VideoBox from './VideoBox'

const StyledProductModalView = styled(ProductModalView)`
  &.product-modal-view {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const StyledMButton = styled(MButton)`
  background-color: ${palette.codGray} !important;
  color: ${palette.white};
`

const BottomButtonsWrapper = styled.div`
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  z-index: 2;
  bottom: ${pxToRem(spacing(4))}rem;

  @media (max-height: ${XSHeight}) {
    position: relative;
    height: 0;
    bottom: 0;

    button {
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      margin-top: ${pxToRem(spacing(2))}rem;
    }
  }
`

const FeaturedLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: ${pxToRem(spacing(2))}rem;
`

const HighlightPageWrapper = styled.div<{ bg?: string }>`
  min-height: 100%;
  overflow: hidden;
  ${props =>
    props.bg
      ? `
      background: url(${props.bg});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      `
      : `background-color: ${palette.bigStone};`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  .left-arrow {
    &-hidden {
      opacity: 0;
    }
  }

  .right-arrow {
    &-hidden {
      opacity: 0;
    }
  }

  .swiper-slide {
    height: auto;
  }
`

const VideoAndCurrentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 60%;

  @media (max-height: ${breakpointsCross.M.max}) {
    height: 47%;
  }

  @media (max-height: 599px) {
    height: auto;
  }
`

const FeaturedCarouselControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 25vw;
  font-size: ${getFluidFontSize('16px')};
  color: ${palette.white};
  text-transform: uppercase;
  margin-left: ${pxToRem(spacing(8))}rem;

  @media (max-width: ${breakpointsCross.L.max}) {
    max-width: 35vw;
  }

  @media (max-height: ${XSHeight}) {
    margin-left: ${pxToRem(spacing(4))}rem;
  }
`

const MainWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100px;
  justify-content: space-between;

  @media (max-height: ${scrollMaxHeight}) {
    ${scrollbarCssDe}
    overflow-y: auto;
    overflow-x: hidden;
  }
`

type Props = RouteChildrenProps<{ brand: string; highlightId: string }>

const CollectionHighlights: React.FC<Props> = ({ match }) => {
  const history = useHistory()

  const { t } = useTranslation()

  const brand = match?.params?.brand
  const brands = useSelector(brandsSelector)
  const selectedBrand = (brands || []).find(brandItem => brandItem.slug === brand)

  const background = useSelector(selectCollectionHighlightsBg(selectedBrand?.code))
  const pdpContents = useSelector(pdpContentSelector)
  const loadedProducts = useSelector(loadedProductsSelector)
  const isSeethroughModalVisible: boolean = useSelector(isSeethroughModalVisibleSelector)
  const seeThemOnAvailableForMocos: Record<string, boolean> = useSelector(
    pdpHighlightSeeThemOnAvailableForMocosSelector,
  )

  const contents = useSelector(categoriesPageDEContentsSelector(selectedBrand?.code))
  const videos = selectedBrand ? contents?.videoShoppable : null
  const currentVideoIndexValue =
    match?.params?.highlightId && videos && videos.length > 1
      ? videos.findIndex(video => video.id === match.params.highlightId)
      : 0
  const [currentVideoIndex, setCurrentVideoIndex] = useState(currentVideoIndexValue)
  const videoData = videos ? videos[currentVideoIndex] : null
  const currentInDisplayIndex = useRef<number>(videoData?.initialItem ? 0 : -1)

  const [vtoAvaiabilities, setVtoAvaiabilities] = useState(
    {} as Record<
      string,
      {
        [modelCode: string]: {
          isAvailable: boolean
          upc: string
        }
      }
    >,
  )
  const [swiperRef, setSwiperRef] = useState<SwiperCore>()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [vtoUrl, setVtoUrl] = useState('')
  const [currentProductVariant, setCurrentProductVariant] = useState<string | null>(null)
  const [currentProduct, setCurrentProduct] = useState(videoData ? videoData.initialItem : null)
  const [videoEnded, setVideoEnded] = useState(false)

  const maximumTimeReached = useRef(0)
  const videoRef = useRef<HTMLVideoElementWithDisablePip>(null)

  const dispatch: TDispatch = useDispatch()

  const extractModelCodes = useCallback(() => {
    if (videoData) {
      const modelCodes = [...videoData.timeline.map(i => i.product.product)]
      if (videoData.initialItem && videoData.initialItem.product)
        modelCodes.push(videoData.initialItem.product)
      return modelCodes
    } else {
      return []
    }
  }, [videoData])

  useFetchBackgrounds()

  useEffect(() => {
    if (selectedBrand?.code && !contents) {
      dispatch(getSingleBrandPageContents(selectedBrand?.code))
    }
  }, [dispatch, selectedBrand?.code, t, contents])

  useEffect(() => {
    if (videoData) {
      const modelCodesExtracted = extractModelCodes().map(mc => {
        return extractModelFromModelCode(mc)[0]
      })
      dispatch(getDetailFromModelCodes([...new Set(modelCodesExtracted)], true))
    }
  }, [videoData, dispatch, brand, extractModelCodes])

  useEffect(() => {
    if (!loadedProducts) {
      return
    }

    loadedProducts.forEach(product => {
      checkVirtualMirrorAvailability(product.mocos).then(result => {
        setVtoAvaiabilities(vtoAvaiabilities => ({
          ...vtoAvaiabilities,
          [product.modelCode]: result,
        }))
      })
    })

    const mocos = [
      ...new Set(
        extractModelCodes()
          .map(mc => {
            return extractModelFromModelCode(mc).join('__')
          })
          .concat(
            loadedProducts.reduce((allProducts, currentProduct) => {
              allProducts = allProducts.concat(
                Object.values(currentProduct.mocos).map(p => p.mocoCode),
              )
              return allProducts
            }, [] as string[]),
          ),
      ),
    ]
    // TODO: is correct to pick cLens only from loadedProducts?
    const cLensAndMocos = loadedProducts.flatMap(({ mocos }) =>
      getCLensToMocoCode(Object.values(mocos)),
    )

    selectedBrand &&
      dispatch(
        loadPdpContent({
          mocos,
          cLensAndMocos,
          brand: selectedBrand.code,
        }),
      )
  }, [loadedProducts, dispatch, brand, extractModelCodes, selectedBrand])

  const getModelByModelCode = useCallback(
    modelCode => {
      if (!loadedProducts.length || !modelCode) return
      const model = extractModelFromModelCode(modelCode)[0]
      return loadedProducts.find(item => item.modelCode === model)
    },
    [loadedProducts],
  )

  const getMocoByModelCode = (modelCode?: string) => {
    if (!loadedProducts.length || !modelCode) return
    const code = extractModelFromModelCode(modelCode)[1]
    return getModelByModelCode(modelCode)?.mocos[code]
  }

  const actualInShowProduct = (currentProduct ? currentProduct : videoData?.initialItem || {})
    .product
  const currentProductModelCode = currentProductVariant || actualInShowProduct
  const model = getMocoByModelCode(currentProductModelCode)
  const currentMocoCode = model?.mocoCode || ''
  const hasVto =
    vtoAvaiabilities &&
    currentProductModelCode &&
    vtoAvaiabilities[extractModelFromModelCode(currentProductModelCode)[0]] &&
    vtoAvaiabilities[extractModelFromModelCode(currentProductModelCode)[0]][currentMocoCode] &&
    vtoAvaiabilities[extractModelFromModelCode(currentProductModelCode)[0]][currentMocoCode]
      .isAvailable

  useEffect(() => {
    if (!hasVto || !currentProductModelCode || !loadedProducts) {
      return
    }

    setVtoUrl('')

    dispatch(
      getVmUrl(
        vtoAvaiabilities[extractModelFromModelCode(currentProductModelCode)[0]][currentMocoCode]
          .upc,
        Object.values(vtoAvaiabilities[extractModelFromModelCode(currentProductModelCode)[0]]).map(
          v => v.upc,
        ),
        getModelByModelCode(currentProductModelCode),
      ),
    ).then(result => {
      setVtoUrl(result.url)
    })
  }, [
    dispatch,
    currentProductModelCode,
    getModelByModelCode,
    currentMocoCode,
    hasVto,
    vtoAvaiabilities,
    loadedProducts,
  ])

  const setVideoTime = (milliseconds: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = milliseconds / 1000
    }
  }

  let currentMoco: Moco | undefined
  let currentModel: Product | undefined
  let currentUpc: string | undefined
  const inShowMoco = getModelByModelCode(actualInShowProduct)

  if (
    actualInShowProduct &&
    currentProductVariant &&
    inShowMoco &&
    inShowMoco.mocos &&
    !Object.values(inShowMoco.mocos).find(
      moco => moco.mocoCode.replaceAll('__', ' ') === currentProductVariant,
    )
  ) {
    setCurrentProductVariant(null)
  }

  if (currentProductModelCode) {
    currentMoco = model
    currentModel = getModelByModelCode(currentProductModelCode)
    if (currentMoco) {
      currentUpc = loadedProducts.length
        ? currentMoco?.sizes[Object.keys(currentMoco?.sizes)[0]].upc
        : undefined
    }
  }

  if (!videos || !brands || !brands.length) {
    return <div>Loading....</div>
  }

  if (!videos || !videos.length) {
    return <div>No video found</div>
  }

  if (!videoData) {
    return null
  }

  return (
    <>
      <HighlightPageWrapper bg={background}>
        <MainWrapper>
          <DigitalEventsHeader />
          <VideoAndCurrentWrapper>
            <VideoBox
              videoRef={videoRef}
              videoEnded={videoEnded}
              setVideoEnded={setVideoEnded}
              maximumTimeReached={maximumTimeReached}
              videoData={videoData}
              currentInDisplayIndex={currentInDisplayIndex}
              swiperRef={swiperRef}
              setCurrentProduct={setCurrentProduct}
              currentVideoIndex={currentVideoIndex}
              videos={videos}
              setCurrentVideoIndex={setCurrentVideoIndex}
              setCurrentProductVariant={setCurrentProductVariant}
              setVideoTime={setVideoTime}
            />
            <HightlightBox
              loadedProducts={loadedProducts}
              currentUpc={currentUpc}
              currentMoco={currentMoco}
              getMocoByModelCode={getMocoByModelCode}
              currentProductModelCode={currentProductModelCode}
              videoRef={videoRef}
              getModelByModelCode={getModelByModelCode}
              currentModel={currentModel}
              currentProduct={currentProduct}
              setCurrentProductVariant={setCurrentProductVariant}
              hasVto={hasVto}
              vtoUrl={vtoUrl}
            />
          </VideoAndCurrentWrapper>
          <FeaturedCarouselControl>
            <FeaturedLabel>{t('Highlight.carousel_title')}</FeaturedLabel>
            <div>
              <Icon
                type="left"
                className={`left-arrow ${currentIndex === 0 ? 'left-arrow-hidden' : ''}`}
                onClick={() => {
                  swiperRef?.slidePrev()
                }}
              />
              <Icon
                type="right"
                className={`right-arrow ${swiperRef?.isEnd ? 'right-arrow-hidden' : ''}`}
                onClick={() => {
                  swiperRef?.slideNext()
                }}
              />
            </div>
          </FeaturedCarouselControl>
          <SeenProductsSlider
            setSwiperRef={setSwiperRef}
            setCurrentIndex={setCurrentIndex}
            loadedProducts={loadedProducts}
            videoData={videoData}
            maximumTimeReached={maximumTimeReached}
            currentInDisplayIndex={currentInDisplayIndex}
            setVideoTime={setVideoTime}
            getModelByModelCode={getModelByModelCode}
            getMocoByModelCode={getMocoByModelCode}
          />
          {!!videos && !!videos.length && videos.length > 1 && (
            <BottomButtonsWrapper>
              <StyledMButton
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  const nextIndex =
                    currentVideoIndex === videos.length - 1 ? 0 : currentVideoIndex + 1
                  history.push(
                    `/digital-events/collection-highlight/${match?.params.brand}/${videos[nextIndex].id}`,
                  )
                  setCurrentProduct(videos[nextIndex] ? videos[nextIndex].initialItem : null)
                  setVideoEnded(false)
                  setCurrentVideoIndex(nextIndex)
                  setCurrentProductVariant(null)
                  setVideoTime(0)
                  maximumTimeReached.current = 0
                }}
              >
                {t('Highlight.next')}
              </StyledMButton>
            </BottomButtonsWrapper>
          )}
          <DigitalEventsFooter />
        </MainWrapper>
      </HighlightPageWrapper>
      {isSeethroughModalVisible && (
        <StyledProductModalView
          key={currentProductModelCode}
          content={pdpContents[extractModelFromModelCode(currentProductModelCode || '').join('__')]}
          brandLogo={selectedBrand?.logo}
          hideModalProductView={() => dispatch(setSeethroughModalVisibility(false))}
          model={getModelByModelCode(currentProductModelCode)}
          moco={getMocoByModelCode(currentProductModelCode)}
          isSeeThemOnAvailable={
            seeThemOnAvailableForMocos[getMocoByModelCode(currentProductModelCode)?.mocoCode || '']
          }
        />
      )}
    </>
  )
}

export default CollectionHighlights
