import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { checkoutStepSelector } from '../../../../store/checkout/selectors'
import { getFluidFontSize, palette, pxToRem } from '../../../../style/theme'
import RCIcon from '../../../../components/UI/RCIcon'
import { cartEditableSelector, cartIsLoadingSelector } from '../../../../store/cart/selectors'

const StepperWrapper = styled.div`
  width: 45%;
  align-self: center;
`

const StepperList = styled.ol`
  list-style-type: none;
  display: grid;
  grid-auto-flow: column;
  position: relative;
  color: ${palette.blueCheckout};
  font-size: ${getFluidFontSize(`${pxToRem(12.5)}rem`, 15)};
  font-family: 'Avenir-Heavy', sans-serif;
  gap: ${pxToRem(8)}rem;
`

const Step = styled.li<{
  clickable?: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ clickable }) => (clickable ? 'cursor: pointer;' : '')} :not(:last-child)::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    position: absolute;
    left: 50%;
    top: 1rem;
    z-index: 1;
  }

  & > span {
    content: counter(step-counter);
    background-color: ${palette.blueCheckout};
    border: 1px solid ${palette.blueCheckout};
    border-radius: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${palette.white};
    z-index: 2;
    padding: ${pxToRem(16)}rem;
  }

  &.active ~ li > span,
  li.active::after,
  li.active ~ li::after {
    background-color: ${palette.white};
    border: 1px solid ${palette.blueCheckout};
    color: ${palette.blueCheckout};
  }

  &.active ~ li {
    color: ${palette.dustyGray};
  }
`

const StepLabel = styled.span`
  display: flex;
  gap: 0.5rem;
  flex-direction: row;

  .anticon {
    height: 1.7em;
    width: 1.7em;
  }
`

type Props = {
  steps: string[]
  active: number
  onClick: (index: number) => void
}

const StepperBuilder: React.FC<Props> = ({ steps, active, onClick }) => {
  const currentPage = useSelector(checkoutStepSelector)
  const cartEditable = useSelector(cartEditableSelector)
  const cartIsLoading = useSelector(cartIsLoadingSelector)

  return (
    <StepperWrapper>
      <StepperList>
        {steps.map((step, index) => (
          <Step
            className={index === active ? 'active' : ''}
            key={index}
            onClick={() => onClick(index)}
            clickable={(index < currentPage && cartEditable) || !cartIsLoading}
          >
            <StepLabel>
              {index < currentPage ? (
                <>
                  <RCIcon type="icons-check" />
                  <span>{step}</span>
                </>
              ) : (
                <>
                  <span>{(index + 1).toString().padStart(2, '0')}</span>
                  <span>{step}</span>
                </>
              )}
            </StepLabel>
          </Step>
        ))}
      </StepperList>
    </StepperWrapper>
  )
}

export default StepperBuilder
