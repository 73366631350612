import styled, { css } from 'styled-components/macro'
import { getFluidSizeWithFullFormula as gF, palette } from '../../style/theme'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import whiteboardActions from '../../store/whiteboard/actions'
import { Title, Wrapper } from './components/WhiteboardCommonComponents'
import {
  itemsPinModalSelector,
  lastPinnedWhiteboardSelector,
} from '../../store/whiteboard/selectors'
import {
  useGetVisibleWhiteboardsQuery,
  usePinItemToWhiteBoardMutation,
} from '../../services/whiteboard'
import { NewWhiteboardButton } from './WhiteboardsListPage'
import Icon from '../../styleguide/Icon'
import { afaVerticalScrollbarCss } from '../../components/CommonComponents'
import { ReactNode, useCallback, useEffect, useMemo } from 'react'
import {
  errorNotification,
  notificationWithComponent,
} from '../../components/Notification/notifications'
import Loading from '../../components/Loading'
import { useParams } from 'react-router-dom'
import { getDeviceAbsolutePath } from '../../libs/url'
import { useGetBrandsQuery } from '../../services/afa'
import { useGetPlpProductsQuery } from '../../services/afaProducts'
import { useGetProductQuery } from '../../services/afaProduct'
import { useHistory } from 'react-router'
import { generateSkuCode } from '../../libs/whiteboard'
import { getProductImageUrl } from '../../libs/productImages'
import { WhiteboardCardType } from '../../model/whiteboard'
import { convertTimestampYyyyMmDdHhMmSsToDate } from '../../libs/time'

const modalWidth = css`
  width: ${gF('px', 460, 660, 1366, 3840)};
`

const lookProduct = css`
  ${modalWidth};
  height: ${gF('px', 78, 110, 1366, 3840)};
  border-radius: 4px;
  border: solid 1px ${palette.shadowBlue};
`

const Look = styled.div`
  ${lookProduct};
  margin: ${gF('px', 80, 120, 1366, 3840)} 0 ${gF('px', 52, 72, 1366, 3840)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: ${gF('px', 8, 16, 1366, 3840)};
`

const LookTitle = styled.h1`
  text-transform: uppercase;
  font-family: GilmerBold, sans-serif;
  font-size: ${gF('px', 16, 32, 1366, 3840)};
  line-height: 1.13;
  letter-spacing: 0.19px;
  color: ${palette.cloudBurst};
`

const LookCounter = styled.h2`
  text-transform: capitalize;
  font-family: GilmerMedium, sans-serif;
  font-size: ${gF('px', 12, 24, 1366, 3840)};
  line-height: 1.5;
  letter-spacing: 0.19px;
  color: ${palette.cloudBurst};
`

const Product = styled.div<{ itemAlreadyPinnedAndRecentBoardPinned: boolean }>`
  width: ${gF('px', 460, 660, 1366, 3840)};
  border-radius: 4px;
  border: solid 1px ${palette.shadowBlue};
  display: grid;
  grid-template-columns: ${gF('px', 100, 175, 1366, 3840)} 1fr;
  grid-template-rows: 1fr;
  margin: ${props =>
    props.itemAlreadyPinnedAndRecentBoardPinned
      ? `${gF('px', 50, 80, 1366, 3840)} 0 ${gF('px', 32, 48, 1366, 3840)}`
      : `${gF('px', 80, 110, 1366, 3840)} 0 ${gF('px', 52, 72, 1366, 3840)}`};
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
  max-height: ${gF('px', 100, 175, 1366, 3840)};
  align-self: center;
`

const Text = styled.div`
  padding: ${gF('px', 16, 24, 1366, 3840)};
  display: flex;
  flex-direction: column;
  letter-spacing: 0.19px;
  color: ${palette.cloudBurst};
  font-size: ${gF('px', 12, 24, 1366, 3840)};
  line-height: 1.5;
`

const Name = styled.h3`
  font-family: GilmerBold, sans-serif;
  font-size: ${gF('px', 16, 32, 1366, 3840)};
  line-height: 1.13;
`
const ProductCode = styled.h4`
  font-family: GilmerMedium, sans-serif;
  margin: ${gF('px', 4, 8, 1366, 3840)} 0 ${gF('px', 8, 16, 1366, 3840)};
`
const Color = styled.div`
  font-family: GilmerRegular, sans-serif;

  & span {
    font-family: GilmerMedium, sans-serif;
  }
`

const BoardsWrapper = styled.div`
  ${modalWidth};
  display: flex;
  flex-direction: column;
  padding-bottom: ${gF('px', 40, 60, 1366, 3840)};
  border-bottom: 2px solid ${palette.platinum};
`

const RecentBoardWrapper = styled.div`
  margin-bottom: ${gF('px', 24, 48, 1366, 3840)};
  padding-bottom: ${gF('px', 24, 48, 1366, 3840)};
  border-bottom: 2px solid ${palette.mischka};
`

const BoardsTitle = styled.h2`
  text-transform: uppercase;
  font-family: GilmerBold, sans-serif;
  font-size: ${gF('px', 18, 36, 1366, 3840)};
  letter-spacing: 0.9px;
  color: ${palette.cloudBurst};
  margin-bottom: ${gF('px', 16, 32, 1366, 3840)};
`

const BoardListWrapper = styled.div`
  ${afaVerticalScrollbarCss};
  max-height: calc((${gF('px', 16, 32, 1366, 3840)} * 3) + (${gF('px', 70, 100, 1366, 3840)} * 3));
  overflow-y: auto;
`

const BoardsList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: ${gF('px', 16, 24, 1366, 3840)};
`

const SingleBoard = styled.li`
  height: ${gF('px', 70, 110, 1366, 3840)};
  display: flex;
  align-items: center;
  padding-left: ${gF('px', 32, 48, 1366, 3840)};
  border-radius: 4px;
  border: solid 1px ${palette.mischka};
  cursor: pointer;
  font-family: GilmerMedium, sans-serif;
  font-size: ${gF('px', 18, 36, 1366, 3840)};
  color: ${palette.cloudBurst};

  &:hover {
    background-color: ${palette.extraLight};
  }
`

const NoBoardsTitle = styled.h3`
  font-family: GilmerBold, sans-serif;
  font-size: ${gF('px', 12, 24, 1366, 3840)};
  letter-spacing: -0.55px;
  color: ${palette.cloudBurst};
`

const ItemAlreadyPinnedWrapper = styled.div`
  margin-top: ${gF('px', 32, 48, 1366, 3840)};
`
const ItemAlreadyPinnedTitle = styled.h4`
  text-transform: uppercase;
  font-family: GilmerMedium, sans-serif;
  font-size: ${gF('px', 12, 24, 1366, 3840)};
  letter-spacing: -0.55px;
  color: ${palette.cloudBurst};
`
const ItemAlreadyPinned = styled.span`
  margin-top: ${gF('px', 12, 24, 1366, 3840)};
  font-family: GilmerRegular, sans-serif;
  font-size: ${gF('px', 18, 36, 1366, 3840)};
  letter-spacing: -0.55px;
  color: ${palette.cloudBurst};
`

const NewWhiteboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: ${gF('px', 23, 46, 1366, 3840)};
  margin-top: ${gF('px', 28, 46, 1366, 3840)};
`

const NewBoardMessage = styled.p`
  font-family: GilmerRegular, sans-serif;
  color: ${palette.cloudBurst};
  font-size: ${gF('px', 18, 36, 1366, 3840)};
`

const NotificationWrapper = styled.div`
  color: ${palette.tangaroa};
  padding-left: ${gF('px', 12, 24, 1366, 3840)};
`
const NotificationTitle = styled.div`
  font-family: GilmerMedium, sans-serif;
  font-size: ${gF('px', 14, 28, 1366, 3840)};
  margin-bottom: ${gF('px', 0, 20, 1366, 3840)};
`

const NotificationBody = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: ${gF('px', 18, 36, 1366, 3840)};
`

const NotificationMessage = styled.span`
  font-family: GilmerBold, sans-serif;
  font-size: ${gF('px', 18, 36, 1366, 3840)};
  line-height: ${gF('px', 26, 48, 1366, 3840)};
  white-space: nowrap;
  width: auto;
  overflow-x: hidden;
  text-overflow: ellipsis;
`

const CustomLink = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1ch;
  cursor: pointer;
  &:hover {
    color: ${palette.cloudBurst};
  }
`

const LinkText = styled.span`
  font-family: GilmerMedium, sans-serif;
  font-size: ${gF('px', 12, 24, 1366, 3840)};
  letter-spacing: 0.3px;
  white-space: nowrap;
`

const Arrow = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23s75v02rn1a)'%3E%3Cpath d='M15.997 8a.817.817 0 0 0-.202-.524L8.938.24A.766.766 0 0 0 7.86.209a.792.792 0 0 0-.03 1.077l5.631 5.952H.76a.762.762 0 0 0 0 1.524H13.46l-5.63 5.952c-.275.29-.251.793.03 1.077.29.294.802.26 1.077-.03l6.857-7.237c.18-.185.19-.343.202-.524z' fill='%23031434'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='s75v02rn1a'%3E%3Cpath fill='%23fff' d='M0 0h16v16H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  width: ${gF('px', 18, 32, 1366, 3840)};
  height: ${gF('px', 18, 32, 1366, 3840)};
  background-repeat: no-repeat;
  background-size: contain;
`

type Props = {
  keylookName?: string
}

const WhiteboardModalPin: React.FC<Props> = ({ keylookName }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const products = useSelector(itemsPinModalSelector)
  const whiteboardsAndTemplatesQuery = useGetVisibleWhiteboardsQuery() //get query without type for having a list of whiteboards AND templates
  const [pinItemsToWhiteboard, pinItemsToWhiteboardResult] = usePinItemToWhiteBoardMutation()
  const lastPinnedWhiteboard = useSelector(lastPinnedWhiteboardSelector)
  const { assortment, brandCode, modelCode } = useParams<{
    assortment: string
    brandCode: string
    modelCode: string
  }>()
  const productQuery = useGetProductQuery(modelCode)
  const product = productQuery.data
  const productsQuery = useGetPlpProductsQuery()
  const [firstProductResult] = productsQuery.currentData?.products || []
  const brandsQuery = useGetBrandsQuery()
  const brands = brandsQuery?.data || []
  const brand = brands.find(item => item.code === brandCode || firstProductResult?.brandCodeParent)

  const itemAlreadyPinned = whiteboardsAndTemplatesQuery?.data?.lastPinnedWhiteboard
  const whiteboards = (whiteboardsAndTemplatesQuery?.data?.whiteboards || [])
    .slice()
    .sort((a, b) => (b.type ?? '').localeCompare(a.type ?? '')) //show whiteboards first, then templates

  const totalBoards = whiteboardsAndTemplatesQuery?.data?.total || 0

  const findElementWithLatestDate = (whiteboards: WhiteboardCardType[]) => {
    const dateTimestamps = whiteboards
      .filter(({ timestampLastPinned }) => timestampLastPinned > 0)
      .map(({ timestampLastPinned }) =>
        convertTimestampYyyyMmDdHhMmSsToDate(timestampLastPinned).getTime(),
      )
      .filter(timestamp => !isNaN(timestamp))
    const maxDate = new Date(Math.max(...dateTimestamps))
    const elementWithLatestDate = whiteboards.find(
      ({ timestampLastPinned }) =>
        convertTimestampYyyyMmDdHhMmSsToDate(timestampLastPinned).getTime() === maxDate.getTime(),
    )

    return elementWithLatestDate
  }

  const recentBoardPinned = findElementWithLatestDate(whiteboards)

  const whiteboardsFiltered = recentBoardPinned?.dateLastPinned
    ? whiteboards.filter(whiteboard => recentBoardPinned.key !== whiteboard.key)
    : whiteboards
  const itemAlreadyPinnedAndRecentBoardPinned =
    !!itemAlreadyPinned?.key && !!recentBoardPinned?.dateLastPinned

  const imageUrl =
    products[0]?.mocos && Object.values(products[0]?.mocos)?.[0]?.catalogImgPath
      ? getProductImageUrl({ path: Object.values(products[0]?.mocos)?.[0].catalogImgPath || '' })
      : ''
  const productColorCode = products[0]?.mocos && Object.values(products[0]?.mocos)?.[0].colorCode
  const productColorDescription =
    products[0]?.mocos && Object.values(products[0]?.mocos)?.[0].colorDescription

  const history = useHistory()

  const goToWhiteboard = useCallback(() => {
    const linkToWhiteboard = `${getDeviceAbsolutePath()}/${assortment}/${brand?.code ||
      product?.brandCodeParent}/whiteboard/${lastPinnedWhiteboard.id}`
    dispatch(whiteboardActions.setSelectedCategory({ category: '' }))
    history.push(linkToWhiteboard)
  }, [assortment, brand, product, lastPinnedWhiteboard, history, dispatch])

  const LinkToWhiteboard: ReactNode = useMemo(
    () => (
      <NotificationWrapper>
        <NotificationTitle>{t('Whiteboard.savedIn')}</NotificationTitle>
        <NotificationBody>
          <NotificationMessage>{lastPinnedWhiteboard.name}</NotificationMessage>
          <CustomLink onClick={() => goToWhiteboard()}>
            <LinkText>{t('Whiteboard.goToBoard')}</LinkText>
            <Arrow />
          </CustomLink>
        </NotificationBody>
      </NotificationWrapper>
    ),
    [lastPinnedWhiteboard, goToWhiteboard, t],
  )

  useEffect(() => {
    if (pinItemsToWhiteboardResult.status === 'fulfilled') {
      dispatch(whiteboardActions.setOpenPinModal(false))

      if (pinItemsToWhiteboardResult.data.warnings.length) {
        dispatch(whiteboardActions.resetItemsPinModal())
        pinItemsToWhiteboardResult.data.warnings.forEach(warning => {
          errorNotification({
            message: warning.message,
          })
        })
      } else if (pinItemsToWhiteboardResult.error || pinItemsToWhiteboardResult.isError) {
        dispatch(whiteboardActions.resetItemsPinModal())
        errorNotification({
          message: t('Whiteboard.errorUpdateWhiteboard'),
        })
      } else {
        setTimeout(() => {
          dispatch(whiteboardActions.resetItemsPinModal())
        }, 2500)
        notificationWithComponent({
          component: LinkToWhiteboard,
          notificationType: 'success',
        })
      }
      dispatch(whiteboardActions.setSelectedSkuCode(''))
      pinItemsToWhiteboardResult.reset()
    }
  }, [pinItemsToWhiteboardResult, dispatch, t, LinkToWhiteboard])

  return (
    <Wrapper>
      <Title>{t('Whiteboard.saveInWhiteboard')}</Title>
      {products.length > 1 ? (
        <Look>
          <LookTitle>{keylookName}</LookTitle>
          <LookCounter>
            {products.length} {t('Afa.Cart.Items')}
          </LookCounter>
        </Look>
      ) : (
        <Product itemAlreadyPinnedAndRecentBoardPinned={itemAlreadyPinnedAndRecentBoardPinned}>
          <Img src={imageUrl} alt="product" />
          <Text>
            <Name>{products[0]?.name}</Name>
            <ProductCode>
              {products[0]?.modelCode}-{productColorCode}
            </ProductCode>
            <Color>
              {t('Afa.fC.colorFacet')}:<span> {productColorDescription}</span>
            </Color>
          </Text>
        </Product>
      )}
      {totalBoards > 0 ? (
        <BoardsWrapper>
          {recentBoardPinned?.dateLastPinned && recentBoardPinned.key !== itemAlreadyPinned?.key ? (
            <RecentBoardWrapper>
              <BoardsTitle>{t('Whiteboard.recentBoardsTitle')}</BoardsTitle>
              <SingleBoard
                onClick={() => {
                  pinItemsToWhiteboard({ whiteboardId: recentBoardPinned.key, products: products })
                  dispatch(
                    whiteboardActions.setLastPinnedWhiteboard({
                      id: recentBoardPinned.key,
                      name: recentBoardPinned.name,
                      skuItemPinned: generateSkuCode(products),
                    }),
                  )
                }}
              >
                {recentBoardPinned.name}
              </SingleBoard>
            </RecentBoardWrapper>
          ) : (
            <BoardsTitle>
              {whiteboardsFiltered.length > 1
                ? t('Whiteboard.boardsTitle')
                : t('Whiteboard.boardTitle')}
            </BoardsTitle>
          )}
          <BoardListWrapper>
            <BoardsList>
              {whiteboardsFiltered.map(whiteboard => (
                <SingleBoard
                  onClick={() => {
                    pinItemsToWhiteboard({ whiteboardId: whiteboard.key, products: products })
                    dispatch(
                      whiteboardActions.setLastPinnedWhiteboard({
                        id: whiteboard.key,
                        name: whiteboard.name,
                        skuItemPinned: generateSkuCode(products),
                      }),
                    )
                  }}
                  key={whiteboard.key}
                >
                  {whiteboard.name}
                </SingleBoard>
              ))}
            </BoardsList>
          </BoardListWrapper>

          {itemAlreadyPinned?.name && (
            <ItemAlreadyPinnedWrapper>
              <ItemAlreadyPinnedTitle>{t('Whiteboard.alreadySavedIn')}:</ItemAlreadyPinnedTitle>
              <ItemAlreadyPinned>{itemAlreadyPinned.name}</ItemAlreadyPinned>
            </ItemAlreadyPinnedWrapper>
          )}
        </BoardsWrapper>
      ) : totalBoards === 0 && itemAlreadyPinned?.key ? (
        <ItemAlreadyPinnedWrapper>
          <ItemAlreadyPinnedTitle>{t('Whiteboard.alreadySavedIn')}:</ItemAlreadyPinnedTitle>
          <ItemAlreadyPinned>{itemAlreadyPinned.name}</ItemAlreadyPinned>
        </ItemAlreadyPinnedWrapper>
      ) : (
        <NoBoardsTitle>{t('Whiteboard.noBoardsTitle')}</NoBoardsTitle>
      )}

      {totalBoards === 0 && !itemAlreadyPinned?.key && (
        <NewWhiteboardWrapper>
          <NewBoardMessage>
            {totalBoards > 0 ? t('Whiteboard.orNewBoardMessage') : t('Whiteboard.newBoardMessage')}
          </NewBoardMessage>
          <NewWhiteboardButton
            onClick={() => {
              dispatch(whiteboardActions.deselectWhiteboard())
              dispatch(whiteboardActions.changeSelectedSwitch('whiteboard'))
              dispatch(whiteboardActions.setOpenWhiteboardSettings(true))
              dispatch(whiteboardActions.setOpenPinModal(false))
            }}
          >
            <Icon type="plus" />
            {t('Whiteboard.new')} {t('Whiteboard.whiteboard')}
          </NewWhiteboardButton>
        </NewWhiteboardWrapper>
      )}

      {(pinItemsToWhiteboardResult.isLoading ||
        whiteboardsAndTemplatesQuery.isFetching ||
        whiteboardsAndTemplatesQuery.isLoading) && <Loading isFullPage={false} />}
    </Wrapper>
  )
}

export default WhiteboardModalPin
