import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ContentInEvidenceBackgrounds = {
  layoutVariant: []
  items: []
  callToActionCustomText: string
  callToActionEnabled: true
  teaserTitle: string
  teaserText: string
  detailText: string
  pictures: {
    mimeType: string
    copyright: string
    caption: string
    alt: string
    disableCropping: false
    data: string
    callToActionEnabled: false
    teaserTitle: string
    related: []
    locale: string
    language: string
    creationDate: string
    title: string
    segment: string
    url: string
    path: string
    folderId: number
    lastModified: string
    keywords: string
    htmlDescription: string
    htmlTitle: string
    extDisplayedDate: string
    subjectTaxonomy: {
      value: string
      externalReference: string
      'lookup-value': string
      taxonomyVector: string
      'lookup-externalReference': string
      callToActionCustomText: string
      callToActionEnabled: boolean
      teaserTitle: string
      teaserText: string
      detailText: string
      pictures: []
      related: []
      locale: string
      language: string
      creationDate: string
      title: string
      segment: string
      path: string
      folderId: number
      lastModified: string
      keywords: string
      htmlDescription: string
      htmlTitle: string
      extDisplayedDate: string
      subjectTaxonomy: []
      locationTaxonomy: []
      contentId: number
      contentType: string
      restType: string
    }[]
    locationTaxonomy: []
    contentId: number
    contentType: string
    restType: string
  }[]
  related: []
  locale: string
  language: string
  creationDate: string
  title: string
  segment: string
  url: string
  path: string
  folderId: number
  lastModified: string
  keywords: string
  htmlDescription: string
  htmlTitle: string
  extDisplayedDate: string
  subjectTaxonomy: {
    value: string
    externalReference: string
    'lookup-value': string
    taxonomyVector: string
    'lookup-externalReference': string
    callToActionCustomText: string
    callToActionEnabled: true
    teaserTitle: string
    teaserText: string
    detailText: string
    pictures: []
    related: []
    locale: string
    language: string
    creationDate: string
    title: string
    segment: string
    path: string
    folderId: 605
    lastModified: string
    keywords: string
    htmlDescription: string
    htmlTitle: string
    extDisplayedDate: string
    subjectTaxonomy: []
    locationTaxonomy: []
    contentId: number
    contentType: string
    restType: string
  }[]
  locationTaxonomy: []
  contentId: number
  contentType: string
  restType: string
}

export type EvidenceInitiative = {
  id: string
  description: string
  startdate: string
  enddate: string
  brands: string[]
  cmskey: string
  salesorgs: string[]
  products: { upc: string; model: string }[]
  enabled: boolean
  image?: string
  initiativecode: string
}

type InitiativesState = {
  initiatives: EvidenceInitiative[]
  launchInitiative: boolean
  selectedInitiative: string
  categoryIsLoading: boolean
}

const DEFAULT_STATE: InitiativesState = {
  initiatives: [],
  launchInitiative: false,
  selectedInitiative: '',
  categoryIsLoading: false,
}

export const slice = createSlice({
  name: 'initiatives',
  initialState: DEFAULT_STATE,
  reducers: {
    setInitiatives: (state, { payload }: PayloadAction<{ initiatives: EvidenceInitiative[] }>) => {
      state.initiatives = payload.initiatives
    },
    setSelectedInitiative: (state, { payload }: PayloadAction<string>) => {
      state.selectedInitiative = payload
    },
    setCategoryIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.categoryIsLoading = payload
    },
    setLaunchInitiative: (state, { payload }: PayloadAction<boolean>) => {
      state.launchInitiative = payload
    },
  },
})

export default slice.reducer
