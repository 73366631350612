import React from 'react'
import { useSelector } from 'react-redux'

import DisplayMedia from '../../components/DisplayMedia'
import { contentIntroSelector } from '../../store/app/selectors'

const IntroVideo = () => {
  const introVideo = useSelector(contentIntroSelector)
  return <DisplayMedia media={introVideo} loop={true} />
}

export default IntroVideo
