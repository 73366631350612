import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import whiteboardActions from '../../../../../store/whiteboard/actions'
import {
  currentFirstBoardStateSelector,
  currentWhiteboardStateSelector,
  getMultiselectActionsEnabled,
  getMultiselectEnabled,
  getRedoActions,
  getSelectedBoard,
  getSelectedBoardId,
  getSelectedCategory,
  getSelectedItems,
  getSelectedWhiteboard,
  getShowSelectedAction,
  getUndoActions,
  initialFirstBoardStateSelector,
  initialWhiteboardStateSelector,
} from '../../../../../store/whiteboard/selectors'
import {
  align,
  alignValues,
  distributeValues,
  WhiteboardType,
} from '../../../../../model/whiteboard'
import RCIcon from '../../../../../components/UI/RCIcon'
import classnames from 'classnames'
import {
  useGetImageURLMutation,
  useSaveWhiteboardMutation,
} from '../../../../../services/whiteboard'
import {
  errorNotification,
  successNotification,
} from '../../../../../components/Notification/notifications'
import { useTranslation } from 'react-i18next'
import {
  Actions,
  BoardsBarWrapper,
  ButtonContainer,
  Done,
  InputFileButton,
  Menu,
  MultiselectMenu,
  Wrapper,
} from './style'
import useViewportUpdate from '../useViewportUpdate'
import { convertToBase64 } from '../../../../../libs/files'
import BoardsBar from '../BoardsBar'
import { useGet4KDevice } from '../../../../../hooks/useGet4KDevice'
import alignHorizontalCenter from '../../../../../assets/images/horizontal-center.png'
import alignVerticalCenter from '../../../../../assets/images/vertical-center.png'
import alignRight from '../../../../../assets/images/right.png'
import alignLeft from '../../../../../assets/images/left.png'
import alignTop from '../../../../../assets/images/top.png'
import alignBottom from '../../../../../assets/images/bottom.png'

const imageMap: Record<align, string> = {
  top: alignTop,
  bottom: alignBottom,
  left: alignLeft,
  right: alignRight,
  'horizontal center': alignHorizontalCenter,
  'vertical center': alignVerticalCenter,
}

type Props = {
  currentSnapshot: string
  whiteboardIsLocked: boolean
  isOwner: boolean
  getSnapshot: () => Promise<string>
}

async function getImageDimensions(file: File) {
  const img = new Image()
  img.src = URL.createObjectURL(file)
  await img.decode()
  return {
    width: img.width,
    height: img.height,
  }
}

const Toolbar: React.FC<Props> = ({
  currentSnapshot,
  whiteboardIsLocked,
  isOwner,
  getSnapshot,
}) => {
  const selectedAction = useSelector(getShowSelectedAction)
  const is4kDevice = useGet4KDevice()
  const dispatch = useDispatch()
  const selectedItems = useSelector(getSelectedItems)
  const selectedItemsArray = Object.entries(selectedItems).flatMap(([, values]) => values)
  const undoActions = useSelector(getUndoActions)
  const redoActions = useSelector(getRedoActions)
  const selectedWhiteboard = useSelector(getSelectedWhiteboard) as WhiteboardType
  const multiselectEnabled = useSelector(getMultiselectEnabled)
  const multiselectActionsEnabled = useSelector(getMultiselectActionsEnabled)
  const [saveWhiteboard, saveWhiteboardResult] = useSaveWhiteboardMutation()
  const selectedBoard = useSelector(getSelectedBoard)
  const itemsInBoard = selectedBoard?.items || []
  const allItemsAreOOA = itemsInBoard.every(item => item.product.invalid)
  const allItemsSelectedAreOOA = selectedItemsArray.every(item => item.product?.invalid)
  const { t } = useTranslation()
  const toolbarRef = useRef(null)
  const { viewportUpdated } = useViewportUpdate()
  const sidebarOpen = useSelector(getSelectedCategory)
  const [getImageURL] = useGetImageURLMutation()
  const selectedBoardId = useSelector(getSelectedBoardId)
  const firstBoardId = selectedWhiteboard?.boards?.[0]?.key ?? ''
  const isFirstBoardSelected =
    !!selectedBoardId && !!firstBoardId && firstBoardId === selectedBoardId
  const initialFirstBoardState = useSelector(initialFirstBoardStateSelector)
  const currentFirstBoardState = useSelector(currentFirstBoardStateSelector)
  const firstBoardHasChangesToSave = initialFirstBoardState !== currentFirstBoardState
  const initialWhiteboardState = useSelector(initialWhiteboardStateSelector)
  const currentWhiteboardState = useSelector(currentWhiteboardStateSelector)
  const whiteboardHasChangesToSave = initialWhiteboardState !== currentWhiteboardState

  useEffect(() => {
    if (multiselectActionsEnabled) {
      dispatch(whiteboardActions.enableMultiselectActions())
    }
  }, [dispatch, multiselectActionsEnabled])

  const menuItems = [
    {
      id: 'multiselect',
      label: 'multiselect',
      actions: [
        {
          label: 'enableMultiselect',
          action: () => dispatch(whiteboardActions.enableMultiselect()),
          icon: '',
        },
        {
          label: 'enableMultiselectActions',
          action: () => dispatch(whiteboardActions.enableMultiselectActions()),
          icon: '',
        },
      ],
      show: !whiteboardIsLocked,
    },
    {
      id: 'align',
      label: 'align',
      variant: 'vertical',
      actions: alignValues.map(position => ({
        label: `align ${position}`,
        action: () => dispatch(whiteboardActions.alignItems(position)),
        icon: imageMap[position],
      })),
      show: !whiteboardIsLocked,
    },
    {
      id: 'distribute',
      label: 'distribute',
      variant: 'horizontal',
      actions: distributeValues.map(direction => ({
        label: direction,
        action: () => dispatch(whiteboardActions.distribute(direction)),
        icon: direction === 'horizontally' ? alignHorizontalCenter : alignVerticalCenter,
      })),
      show: !whiteboardIsLocked,
    },
    {
      id: 'layer',
      label: 'layer',
      variant: 'horizontal',
      actions: [
        {
          label: 'bring to front',
          action: () => dispatch(whiteboardActions.setLayer(1)),
          icon: '',
        },
        { label: 'send to back', action: () => dispatch(whiteboardActions.setLayer(0)), icon: '' },
      ],
      show: !whiteboardIsLocked,
    },
    {
      id: 'delete',
      label: 'delete',
      actions: [
        {
          label: 'remove',
          action: () => {
            dispatch(whiteboardActions.removeItemsFromBoard())
          },
          icon: '',
        },
      ],
      show: !whiteboardIsLocked,
    },
    {
      id: 'addToCart',
      label: t('Whiteboard.toolbar.addToCart'),
      actions: [
        {
          label: t('Whiteboard.toolbar.addToCart'),
          action: () => dispatch(whiteboardActions.setOpenAddItemsInCart(true)),
          icon: '',
        },
      ],
      show: true,
    },
  ]

  useEffect(() => {
    if (saveWhiteboardResult.status === 'fulfilled') {
      successNotification({
        message: t('Whiteboard.whiteboardSaved'),
      })
    }
  }, [saveWhiteboardResult.status, t])

  const handleSelectedAction = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number,
    actions: { label: string; action: () => void }[],
  ) => {
    event.stopPropagation()
    dispatch(whiteboardActions.setShowSelectedAction(index))

    if (index === 0 || actions.length === 1) {
      actions[0].action()
    }
  }

  const addText = () => {
    dispatch(whiteboardActions.addTextToBoard())
  }

  const enableMultiselect = (e: React.MouseEvent) => {
    e.stopPropagation()

    dispatch(whiteboardActions.enableMultiselectActions())
    dispatch(whiteboardActions.enableMultiselect())
  }

  const addToCart = () => {
    dispatch(whiteboardActions.setOpenAddItemsInCart(true))
  }

  useEffect(() => {
    const wrapper = (toolbarRef.current as unknown) as HTMLElement
    const height = wrapper.clientHeight
    wrapper.style.bottom = `-${height / 2}px`
  }, [viewportUpdated])

  const addImageToBoard = async (file: File) => {
    if (file.size > 2000000) {
      return errorNotification({ message: t('Whiteboard.fileTooLarge') })
    }

    const imageToImport = await convertToBase64(file)
    const res = await getImageURL({
      base64: imageToImport.replace(/data:image\/(.*);base64,/, ''),
      filename: file.name,
    })
    dispatch(whiteboardActions.showImagePlaceholder())
    const {
      data: { URL },
    } = res as { data: { fileName: string; URL: string } }

    const { width, height } = await getImageDimensions(file)
    dispatch(whiteboardActions.addImageToBoard({ URL, width, height }))
  }

  const handleSaveWhiteboard = async () => {
    let preview = currentSnapshot

    if (isFirstBoardSelected) {
      preview = await getSnapshot()
    } else if (firstBoardHasChangesToSave) {
      dispatch(whiteboardActions.setWhiteboardIsWaitingForScreenshot(true))
    }
    if (selectedWhiteboard) {
      saveWhiteboard({
        selectedWhiteboard: {
          ...selectedWhiteboard,
          previewProducts: preview,
        },
        buttonSaveClicked: true,
      })
    }
  }

  return (
    <Wrapper ref={toolbarRef}>
      {!selectedItemsArray.length ? (
        <Actions sidebarOpen={!!sidebarOpen}>
          {!whiteboardIsLocked && (
            <>
              <ButtonContainer>
                <span className={classnames(!undoActions.length && 'disabled')}>
                  <RCIcon
                    type="icon-back"
                    onClick={() => dispatch(whiteboardActions.undoAction())}
                  />
                </span>
                <span className={classnames(!redoActions.length && 'disabled')}>
                  <RCIcon
                    type="icon-forward"
                    onClick={() => dispatch(whiteboardActions.redoAction())}
                  />
                </span>
              </ButtonContainer>
              <div onClick={addText}>
                <span>{t('Whiteboard.toolbar.addText')}</span>
              </div>
              <InputFileButton>
                <label htmlFor="upload-image">{t('Whiteboard.toolbar.importImage')}</label>
                <input
                  onChange={e => e.target.files && addImageToBoard(e.target.files[0])}
                  id="upload-image"
                  name="upload-image"
                  type="file"
                  accept="image/*"
                />
              </InputFileButton>
              <div
                className={
                  (whiteboardIsLocked && !isOwner) || !whiteboardHasChangesToSave ? 'disabled' : ''
                }
                onClick={handleSaveWhiteboard}
              >
                <span>{t('GenericWords.save')}</span>
              </div>
              <div
                id="whiteboard-icon-settings"
                onClick={() => dispatch(whiteboardActions.setIsSettingsModalOpen(true))}
              >
                <span>
                  <RCIcon type="icon-settings" />
                </span>
              </div>
            </>
          )}
          <div
            className={classnames({
              disabled: !itemsInBoard.length || allItemsAreOOA,
            })}
            onClick={addToCart}
          >
            <span>{t('Whiteboard.toolbar.addToCart')}</span>
          </div>
        </Actions>
      ) : (
        <Actions sidebarOpen={!!sidebarOpen}>
          {menuItems
            .filter(item => item.show)
            .map(({ label, actions, variant, id }, index) => (
              <div
                key={id}
                className={classnames({
                  selected: selectedAction === index,
                  multiselectEnabled: multiselectEnabled && label !== 'multiselect',
                  disabled: allItemsSelectedAreOOA && id === 'addToCart',
                })}
                onClick={event =>
                  index !== selectedAction && handleSelectedAction(event, index, actions)
                }
              >
                <span>{label}</span>
                {label === 'multiselect' && selectedAction === 0 && selectedItemsArray.length && (
                  <MultiselectMenu>
                    <div>
                      <span>
                        {selectedItemsArray.length}{' '}
                        {selectedItemsArray.length > 1
                          ? t('Whiteboard.toolbar.itemsSelected')
                          : t('Whiteboard.toolbar.itemSelected')}
                      </span>
                      <Done onClick={enableMultiselect}>
                        {t('Whiteboard.addItemsToCartModal.done')}
                      </Done>
                    </div>
                  </MultiselectMenu>
                )}
                {selectedAction === index && actions.length > 1 && label !== 'multiselect' && (
                  <Menu variant={variant}>
                    {actions.map(({ label, action, icon }) => (
                      <div key={label} onClick={action}>
                        {!!icon && <img src={icon} alt={icon} />}
                        <span>{label}</span>
                      </div>
                    ))}
                  </Menu>
                )}
              </div>
            ))}
        </Actions>
      )}
      {is4kDevice && (
        <BoardsBarWrapper sidebarOpen={!!sidebarOpen}>
          <BoardsBar variant="compact" isLocked={!isOwner && whiteboardIsLocked} />
        </BoardsBarWrapper>
      )}
    </Wrapper>
  )
}

export default Toolbar
