import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { getDeviceAbsolutePath } from '../../libs/url'
import { isDigitalEventSelector } from '../../store/app/selectors'
import { pxToRem, spacing } from '../../style/theme'
import BrandLogo from '../Couvettes/Couvette/BrandLogo'

const StyledBrandLogo = styled(BrandLogo)`
  .no-elements {
    opacity: 0.2;

    + .count-products {
      display: none;
    }
  }
  .brand-logo-img {
    height: ${pxToRem(spacing(8))}rem;
  }
`

const WrapperBrandLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
`

type Props = {
  selectBrand?: (slug: string) => void
  active?: boolean
  slug: string
  className?: string
  hideCount?: boolean
  logo: string
  notClickable?: boolean
  selected?: boolean
  countProducts?: number
  setOpenedBrand?: (brandCode: string) => void
  brandCode?: string
}

export const Brand: React.FC<Props> = ({
  active = true,
  slug,
  countProducts,
  hideCount,
  notClickable,
  selectBrand = () => undefined,
  brandCode,
  selected,
  logo,
  setOpenedBrand,
  className,
}) => {
  const { t } = useTranslation()
  const isDe = useSelector(isDigitalEventSelector)

  const brandLogo = logo && (
    <WrapperBrandLogo>
      <StyledBrandLogo
        active={active}
        brandCode={brandCode || ''}
        onClick={() => {
          setOpenedBrand && brandCode && setOpenedBrand(brandCode)
          selectBrand(slug)
        }}
        selected={selected}
      />
    </WrapperBrandLogo>
  )

  return (
    <div className={className}>
      {active && !notClickable ? (
        <Link
          to={
            isDe
              ? `/digital-events/single-brand/${slug}`
              : `${getDeviceAbsolutePath()}/single-brand/${slug}`
          }
        >
          {brandLogo}
        </Link>
      ) : (
        brandLogo
      )}
      {!hideCount && (
        <p className="count-products">
          {countProducts} {t('GenericWords.items')}
        </p>
      )}
    </div>
  )
}

export default Brand
