import '@fortawesome/fontawesome-free/css/all.css'

import { format } from 'date-fns'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import CalendarIcon from '../../../../components/icons/CalendarIcon'
import WarningIcon from '../../../../components/icons/WarningIcon'
import ProductIcons from '../../../../components/ProductIcons'
import RCIcon from '../../../../components/UI/RCIcon'
import { useUpdateCart } from '../../../../hooks/useUpdateCart'
import { deleteFromCart, getDeliveryDate } from '../../../../libs/cart'
import { CartProduct, CheckoutProduct } from '../../../../model/cart'
import { Moco } from '../../../../model/product'
import { updateCartAction } from '../../../../store/cart/actions'
import { selectFilteredCheckoutProducts } from '../../../../store/checkout/selectors'
import { customerDoorsSelector } from '../../../../store/customer/selectors'
import { disableCartButtonSelector } from '../../../../store/showButtonsSelectors'
import { duration, getFluidFontSize, palette, pxToRem, spacing } from '../../../../style/theme'
import { CartProductsListRow, CustomDatePicker, CustomInput, LabelInput } from '../Common'

const Wrapper = styled(CartProductsListRow)<{ disabled?: boolean }>`
  border-bottom: ${pxToRem(spacing(0.2))}rem solid;
  border-color: ${palette.geyser};

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
`

const ProcessTableCell = styled.div`
  color: ${palette.biscay};
  font-size: ${getFluidFontSize(`${pxToRem(12.5)}rem`, 15)};
  font-family: 'Avenir-Roman', sans-serif;

  .anticon {
    height: 1.5em;
    width: 1.5em;
  }
`

const ImageIconsAndCode = styled(ProcessTableCell)`
  font-weight: bold;
  align-items: center;
  display: grid;
  grid-template-rows: 1.5em 3fr 1fr;
  padding: 0.25rem 20% 0.25rem 1rem;
`

const ImageIconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledWarningIcons = styled(WarningIcon)`
  width: 1.5em;
  height: 1.5em;
  color: ${palette.pomegranate};
`

const IconsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const Icons = styled(ProductIcons)`
  font-size: ${getFluidFontSize('10px', 20)};
`

const ImageWrapper = styled.div`
  overflow: hidden;
  height: 100%;
`

const Image = styled.img`
  width: 115%;
  height: 105%;
  object-fit: contain;
`

const Code = styled.div`
  flex-wrap: nowrap;
  font-weight: 500;
  font-size: ${getFluidFontSize('8px', 15)};
  color: ${palette.gray};
`

const ItemSent = styled.span`
  color: ${palette.mountainMeadow};
`

const ItemDoorName = styled.span`
  display: inline-block;
  text-transform: lowercase;

  &:first-letter {
    text-transform: capitalize;
  }
`

const InputWrapperBodyReference = styled.div`
  border: solid 1px ${palette.bondiBlueDark};
  border-radius: ${pxToRem(spacing(0.5))}rem;
  background: ${palette.white};
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: ${pxToRem(8)}rem ${pxToRem(12)}rem;
`

const Tooltip = styled.div`
  position: relative;
  display: inline-block;

  .tooltip-text {
    opacity: 0;
    transition: opacity ${duration.standard}ms;
    width: max-content;
    background-color: ${palette.pomegranate};
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    top: -5px;
    left: 130%;
    z-index: 1;
  }

  &:hover .tooltip-text {
    opacity: 1;
  }

  .tooltip-text::after {
    content: ' ';
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent ${palette.pomegranate} transparent transparent;
  }
`

const ProcessTableRow: React.FC<{
  checkoutProduct: CheckoutProduct
  cartProducts: CartProduct[]
  moco?: Moco
  style: Record<string, any>
}> = ({ checkoutProduct, cartProducts, moco, style }) => {
  const {
    doorId,
    quantity,
    confirmedQuantity,
    modelCode,
    colorCode,
    imageUrl,
    brandCode,
    sizeMasterData,
    deliveryDate,
    minDeliveryDate,
    reference,
    outOfAssortment,
    status,
    size,
  } = checkoutProduct

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const updateCart = useUpdateCart()

  const checkoutProductsFiltered = useSelector(selectFilteredCheckoutProducts)
  const doors = useSelector(customerDoorsSelector)
  const door = doors.find(door => door.id === doorId)
  const disableRow = useSelector(disableCartButtonSelector)

  const productConfirmed = checkoutProductsFiltered.reduce((result, product) => {
    return result + (product.confirmedQuantity || 0)
  }, 0)

  const disableDates = () => {
    const minDeliveryDateYYYYMMDD = minDeliveryDate
      ?.split('-')
      .reverse()
      .join('-')
    const minDate = new Date(minDeliveryDateYYYYMMDD || 0)
    const today = new Date()
    if (!isNaN(minDate.getTime()) && minDate > today) {
      return minDeliveryDateYYYYMMDD
    } else {
      const dd = String(today.getDate() + 1).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0')
      const yyyy = today.getFullYear()
      return yyyy + '-' + mm + '-' + dd
    }
  }

  const min = disableDates()

  const [newReference, setNewReference] = useState(reference)
  useEffect(() => {
    setNewReference(reference)
  }, [reference])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateCart = useCallback(debounce(updateCart, 2000), [])

  const updateReference = (e: React.FormEvent<HTMLInputElement>) => {
    const newReference = e.currentTarget.value
    setNewReference(newReference)

    if (newReference !== reference) {
      debouncedUpdateCart({
        doors: [doorId],
        productSizes: sizeMasterData ? [sizeMasterData] : [],
        qntToAdd: null,
        cartProducts,
        finalQuantity: null,
        reference: newReference,
      })
    }
  }

  return (
    <Wrapper
      disabled={disableRow}
      className={productConfirmed === 0 ? 'notProcessed' : ''}
      style={style}
    >
      <ImageIconsWrapper>
        {outOfAssortment && status === '1' && (
          <Tooltip>
            <StyledWarningIcons />
            <span className="tooltip-text">{t('Checkout.Process.OutOfAssortmentWarning')}</span>
          </Tooltip>
        )}
        <ImageIconsAndCode style={{ height: style.height }}>
          <IconsWrapper>
            {moco && <Icons moco={moco} hasRTR={false} hiddenIcons={['adv', 'vto', 'ideal']} />}
          </IconsWrapper>
          <ImageWrapper>
            <Image src={imageUrl} alt="" />
          </ImageWrapper>
          <Code>
            {modelCode} {colorCode} {size}
          </Code>
        </ImageIconsAndCode>
      </ImageIconsWrapper>

      {productConfirmed > 0 && (
        <ProcessTableCell>
          <ItemSent>{confirmedQuantity}</ItemSent>
        </ProcessTableCell>
      )}
      <ProcessTableCell>
        <span>{quantity}</span>
      </ProcessTableCell>
      <ProcessTableCell>
        <span>{doorId}</span> - <ItemDoorName>{door?.name}</ItemDoorName>
      </ProcessTableCell>
      <ProcessTableCell>
        <span>{door?.gtmRating}</span>
      </ProcessTableCell>
      <ProcessTableCell>
        <CustomDatePicker
          value={new Date(getDeliveryDate(deliveryDate, minDeliveryDate, true))}
          disabled={quantity === 0 || outOfAssortment}
          minDate={min ? new Date(min) : undefined}
          calendarIcon={<CalendarIcon />}
          clearIcon={null}
          onChange={(date: Date | null) => {
            const deliveryDate = date ? format(date, 'dd-MM-yyyy') : ''

            updateCart({
              doors: [doorId],
              productSizes: sizeMasterData ? [sizeMasterData] : [],
              qntToAdd: null,
              cartProducts,
              finalQuantity: null,
              deliveryDate,
            })
          }}
        />
      </ProcessTableCell>
      <ProcessTableCell>
        <InputWrapperBodyReference>
          <CustomInput
            type="input"
            value={newReference}
            disabled={quantity === 0 || outOfAssortment}
            onChange={updateReference}
            maxLength={15}
          />
          <LabelInput className={reference === '' ? '' : 'none'}>
            {t('Checkout.Process.ReferencePlaceholder')}
          </LabelInput>
        </InputWrapperBodyReference>
      </ProcessTableCell>
      <ProcessTableCell>
        {quantity > 0 && (
          <RCIcon
            type="icons-trash"
            onClick={() => {
              if (outOfAssortment) {
                dispatch(
                  updateCartAction([
                    {
                      ...checkoutProduct,
                      confirmedQuantity: confirmedQuantity || 0,
                      oldQnt: quantity,
                      quantity: 0,
                      requestStatus: 'waiting',
                    },
                  ]),
                )
              } else {
                deleteFromCart({
                  productSizes: sizeMasterData ? [sizeMasterData] : [],
                  cartProducts: cartProducts.filter(
                    product => product.status === '1' && product.doorId === doorId,
                  ),
                  updateCart,
                  brandCode,
                })
              }
            }}
          />
        )}
      </ProcessTableCell>
    </Wrapper>
  )
}

export default ProcessTableRow
