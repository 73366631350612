import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DisplayCartSummaryAs } from '../../model/afa'

import { resetForNewClient } from '../extraActions'

type CategoryKey = string

const DEFAULT_STATE = {
  openSummary: false,
  cartSummarySelectedPage: 'graphs' as 'graphs' | 'table',
  displayAsLayerOpen: false,
  displayAs: 'quantity' as DisplayCartSummaryAs,
  showFilters: false,
  filtersApplied: {} as Record<CategoryKey, string[]>,
  showMultidoorNotification: true,
  filterIDs: [] as string[],
}

export const slice = createSlice({
  name: 'afaCartSummary',
  initialState: DEFAULT_STATE,
  reducers: {
    toggleSummary: state => {
      state.openSummary = !state.openSummary
    },

    setCartSummarySelectedPage: (state, { payload }: PayloadAction<'graphs' | 'table'>) => {
      state.cartSummarySelectedPage = payload
    },

    setDisplayAsLayerOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.displayAsLayerOpen = payload
    },

    setDisplayAs: (state, { payload }: PayloadAction<DisplayCartSummaryAs>) => {
      state.displayAs = payload
    },

    setShowFilters: (state, { payload }: PayloadAction<boolean>) => {
      state.showFilters = payload
    },

    setFilters: (
      state,
      { payload }: PayloadAction<{ categoryKey: string; filtersApplied: string[] }>,
    ) => {
      state.filtersApplied[payload.categoryKey] = payload.filtersApplied
    },

    resetFilters: state => {
      state.filtersApplied = {}
    },

    hideMultidoorNotification: state => {
      state.showMultidoorNotification = false
    },

    setFilterID: (state, { payload }: PayloadAction<string>) => {
      const filterIDSet = new Set(state.filterIDs)
      if (filterIDSet.has(payload)) {
        filterIDSet.delete(payload)
      } else {
        filterIDSet.add(payload)
      }
      state.filterIDs = Array.from(filterIDSet)
    },
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, () => DEFAULT_STATE)
  },
})

export default slice.reducer
