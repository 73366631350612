import { useTranslation } from 'react-i18next'
import { NullableProductAttribute } from '../../model/product'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { setSeeThemOnCurrentFacesBlockId, setSeeThemOnCurrentSlide } from '../../store/actions'
import app_config from '../../config/app/config'
import { arrayChunks } from '../../libs'
import {
  seeThemOnCurrentFacesBlockId,
  seeThemOnCurrentSlideSelector,
  seeThemOnViewTypeSelector,
} from '../../store/pdp/selectors'
import VtoRender from './VtoRender/VtoRender'
import styled, { css } from 'styled-components/macro'
import { getFluidSizeWithFullFormula as gF } from '../../style/theme'
import { VtoImage } from '@luxottica/vto-image'
import { apiLog } from '../../api/restApi'
import classNames from 'classnames'

const Vto_Style = css`
  .vto__container {
    height: 100%;
    display: flex;
    flex-direction: column;

    .render.video {
      height: 100%;
    }

    .render.image {
      flex: 1;

      .vto-image-swipeable-container {
        height: 100%;

        .vto-image-rotate-bar {
          display: none;
        }
      }
    }

    .vto__shape {
      height: 10%;
      text-align: center;
      font-size: ${gF('px', 14, 28, 1366, 3840)};
    }
  }
`

const Container = styled.div`
  position: relative;
  width: ${gF('px', 447, 1017, 1366, 3840)};
`

const VtoRotate = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  top: 2.5vh;
  left: 0;
  z-index: 99;
  padding: 0 2.5vw;

  img {
    width: ${gF('px', 32, 64, 1366, 3840)};
    height: ${gF('px', 7, 14, 1366, 3840)};
    align-self: flex-end;
  }

  span {
    font-family: Avenir-Roman, Avenir-Roman-Custom, sans-serif;
    font-size: ${gF('px', 14, 28, 1366, 3840)};
    font-style: italic;
    color: black;
    text-transform: lowercase;
    margin-left: 25%;
  }
`

const Wrapper = styled.div`
  width: 100%;
  height: 59vh;
  overflow: hidden;
  border: 0.1vh solid rgb(203, 203, 203);
  border-radius: 8px;
  padding: 6.5vh 2.5vh 4.5vh;

  ${Vto_Style};
`

const Carousel = styled.ul`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  column-gap: 2.6vw;
`

const Card = styled.li`
  height: 100%;
  width: 100%;
  min-width: 100%;

  &.showMultipleView {
    height: 80%;
    display: grid;
    grid-template-rows: repeat(2, minmax(auto, 1fr));
    grid-template-columns: repeat(2, minmax(auto, 1fr));

    .render.image {
      min-height: 20vh;
      max-height: 20vh;
    }
  }
`

const FakeCard = styled(Card)`` //it's necessary for holding in place the last real card when scrolling

const Controls = styled.div`
  position: absolute;
  top: calc(100% - 4.5vh);
  left: 0;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1vw;
  column-gap: ${gF('px', 10, 20, 1366, 3840)};
`

const Common_Arrows_Style = css`
  width: ${gF('px', 32, 64, 1366, 3840)};
  height: ${gF('px', 32, 64, 1366, 3840)};
  cursor: pointer;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`

const ArrowLeft = styled.div`
  background-image: url('${app_config.publicUrl}/assets/images/carousel_arrow_SX.svg');
  ${Common_Arrows_Style};
`

const ArrowRight = styled.div`
  background-image: url('${app_config.publicUrl}/assets/images/carousel_arrow_RX.svg');
  ${Common_Arrows_Style};
`

const SlicksContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  column-gap: ${gF('px', 3, 6, 1366, 3840)};
`

const Slick = styled.li`
  flex: 1 1 0%;
  height: 0.5vh;
  background-color: rgb(154, 154, 154, 0.3);
  border-radius: 15px;
  cursor: pointer;

  &.active {
    flex: 0.5 1 0%;
    background-color: rgb(0, 81, 146);
  }
`

const { vtoVideosId } = app_config

const getVideoFacesIds = (brandCode: string) => {
  switch (brandCode) {
    case 'RX':
    case 'RW':
    case 'RB':
      return [
        'RB-female-caucasian-1',
        'RB-male-caucasian-1',
        'RB-female-afro-1',
        'RB-male-afro-1',
        'RB-female-asian-1',
        'RB-male-asian-1',
      ]
    default:
      return [
        'XBRAND-female-caucasian-1-outfit-1',
        'XBRAND-female-asian-1-outfit-1',
        'XBRAND-female-afro-1-outfit-1',
        'XBRAND-male-caucasian-1-outfit-1',
        'XBRAND-male-asian-1-outfit-1',
        'XBRAND-male-afro-1-outfit-1',
      ]
  }
}

const selectVideoIdBlock = ({
  geofit,
  gender,
  isJunior,
}: {
  geofit: NullableProductAttribute
  gender: NullableProductAttribute
  isJunior?: boolean
}) => {
  if (isJunior) {
    return 'junior'
  } else if (geofit && geofit.id && geofit.id.toLowerCase() === 'asian') {
    return `asian${gender?.label.toLowerCase()}`
  } else {
    return gender?.id.toLowerCase()
  }
}

const getShapeFromVideoId = (videoId: string) => {
  const videoIdParts = videoId.split('-')
  const genderPartIndex = videoIdParts && videoIdParts.findIndex(el => el.includes('male'))
  if (genderPartIndex !== -1 && genderPartIndex > 0) {
    return videoIdParts[genderPartIndex - 1]
  }
}

interface VideoIdData {
  videoId: string
  loading: boolean
  loaded: boolean
  renderedAt: Date | null
}

type Props = {
  brandCode: string
  upc: string
  imgbrand?: string
  gender: NullableProductAttribute
  geofit: NullableProductAttribute
  hasImage: boolean
  hasVideo: boolean
  visible: boolean
  isJunior: boolean
  pageSize: number
}

const Vto: React.FC<Props> = ({
  upc,
  visible,
  pageSize,
  hasVideo,
  brandCode,
  gender,
  geofit,
  isJunior,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const activeSlide = useSelector(seeThemOnCurrentSlideSelector)
  const seeThemOnViewType = useSelector(seeThemOnViewTypeSelector)
  const currentFacesBlockId = useSelector(seeThemOnCurrentFacesBlockId)

  const [videoIds, setVideoIds] = useState<VideoIdData[]>([])
  const [slides, setSlides] = useState<string[][]>([])
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [localUpc, setLocalUpc] = useState('')
  const [localPageSize, setLocalPageSize] = useState<number | undefined>(undefined)

  const isSingleProductView = seeThemOnViewType === 'single'

  const updateVideoState = (
    videoId: string,
    isLoaded: boolean,
    isLoading: boolean,
    callback?: () => void,
  ) => {
    const updatedVideoInfo = {
      videoId,
      renderedAt: isLoaded ? new Date() : null,
      loaded: isLoaded,
      loading: isLoading,
    }

    setVideoIds(prevVideoIds => {
      const updatedVideoState = [...prevVideoIds]
      const videoIndex = updatedVideoState.findIndex(v => v.videoId === videoId)
      updatedVideoState[videoIndex] = updatedVideoInfo
      callback && callback()
      return updatedVideoState
    })
  }

  const handleCardSwipe = (value: string | number) => {
    if (typeof value === 'string') {
      switch (value) {
        case 'left':
          if (activeSlide > 0) {
            dispatch(setSeeThemOnCurrentSlide(activeSlide - 1))
          }
          break
        case 'right':
          if (activeSlide < slides.length - 1) {
            dispatch(setSeeThemOnCurrentSlide(activeSlide + 1))
          }
          break
      }
    } else {
      dispatch(setSeeThemOnCurrentSlide(value))
    }
  }

  useEffect(() => {
    if (isSingleProductView) {
      const singleViewFormula = activeSlide * 4
      dispatch(setSeeThemOnCurrentSlide(singleViewFormula))
    } else {
      const multipleViewFormula = Math.floor(activeSlide / 4)
      dispatch(setSeeThemOnCurrentSlide(multipleViewFormula))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSingleProductView])

  useEffect(() => {
    let retryCount = 0
    let scrollBehavior = 'smooth'
    const retryTimes = 3

    const scrollToActiveSlide = () => {
      const targetCard = document.getElementById(`vto-card-${activeSlide}`)
      const options = {
        behavior: scrollBehavior,
        block: 'center',
        inline: 'center',
      } as ScrollIntoViewOptions

      if (targetCard) {
        targetCard.scrollIntoView(options)
      } else {
        // Retry if targetCard is null, it can happen when changing product and DOM is not ready yet
        if (retryCount < retryTimes) {
          retryCount++
          scrollBehavior = 'instant'
          setTimeout(scrollToActiveSlide, 100)
        }
      }
    }

    scrollToActiveSlide()
  }, [activeSlide, upc])

  useEffect(() => {
    if (visible) {
      const pageItems = videoIds.slice(activeSlide * pageSize, (activeSlide + 1) * pageSize)
      pageItems.forEach(({ videoId }: VideoIdData) => {
        const index = videoIds.findIndex(v => v.videoId === videoId)
        const divId = `model-${index}`

        updateVideoState(videoId, false, true, () => {
          setTimeout(() => {
            VtoImage.renderImage({
              divId: divId,
              videoId: videoId,
              upc: upc,
            })
              .then(() => updateVideoState(videoId, true, false))
              .catch(error => apiLog(error))
          })
        })
      })
    }
  }, [activeSlide, pageSize, upc, videoIds, visible])

  useEffect(() => {
    const facesBlockId = selectVideoIdBlock({ geofit, gender, isJunior })
    dispatch(setSeeThemOnCurrentFacesBlockId(facesBlockId || ''))

    const videoFacesId = getVideoFacesIds(brandCode)
    const imageFacesId = facesBlockId
      ? ([] as string[]).concat(vtoVideosId[facesBlockId as '1' | '2' | '5'])
      : []
    const facesId = hasVideo ? videoFacesId : imageFacesId
    const updatedSlides = arrayChunks(facesId, pageSize)

    let updatedInitialSlideIndex = currentSlideIndex
    let updatedVideoIds = videoIds

    if (localPageSize && localPageSize !== pageSize) {
      updatedInitialSlideIndex = pageSize === 1 ? activeSlide * 4 : Math.floor(activeSlide / 4)
    }

    if (upc !== localUpc) {
      updatedInitialSlideIndex = activeSlide && activeSlide < updatedSlides.length ? activeSlide : 0
      updatedInitialSlideIndex = currentFacesBlockId === facesBlockId ? updatedInitialSlideIndex : 0

      updatedVideoIds = facesId.map((videoId, index) => ({
        videoId,
        renderedAt: updatedVideoIds[index] ? updatedVideoIds[index].renderedAt : null,
        loaded: false,
        loading: false,
      }))
    }

    setSlides(updatedSlides)
    setCurrentSlideIndex(updatedInitialSlideIndex)
    setVideoIds(updatedVideoIds)
    setLocalUpc(upc)
    setLocalPageSize(pageSize)
  }, [
    upc,
    visible,
    pageSize,
    hasVideo,
    geofit,
    gender,
    isJunior,
    brandCode,
    dispatch,
    currentSlideIndex,
    videoIds,
    localPageSize,
    localUpc,
    activeSlide,
    currentFacesBlockId,
  ])

  return (
    <Container>
      {!hasVideo && (
        <VtoRotate>
          <img src={`${app_config.publicUrl}/assets/images/rotate.svg`} />
          <span>{t('Vto.clickAndDragToRotate')}</span>
        </VtoRotate>
      )}
      <Wrapper>
        <Carousel>
          {slides.map((slide, slideIndex) => (
            <Card
              className={classNames({ showMultipleView: !isSingleProductView })}
              id={`vto-card-${slideIndex}`}
              key={`${slideIndex}_${slide.length}`}
            >
              {slide.map((videoId, videoIdIndex) => (
                <div key={`${videoIdIndex}_${videoId}`} className="vto__container">
                  <VtoRender
                    divId={`model-${videoIdIndex + slideIndex * pageSize}`}
                    hasVideo={hasVideo}
                    videoId={videoId}
                    upc={upc}
                    slideIndex={slideIndex}
                  />
                  {!hasVideo && (
                    <div className="vto__shape">
                      <span>{t(`Vto.txt${getShapeFromVideoId(videoId)}`)}</span>
                    </div>
                  )}
                </div>
              ))}
            </Card>
          ))}
          <FakeCard />
        </Carousel>
      </Wrapper>
      <Controls>
        <ArrowLeft onClick={() => handleCardSwipe('left')} />
        <SlicksContainer>
          {slides.map((slide, slideIndex) => (
            <Slick
              className={classNames({ active: slideIndex === activeSlide })}
              key={`slick-${slideIndex}_${slide.length}`}
              onClick={() => handleCardSwipe(slideIndex)}
            />
          ))}
        </SlicksContainer>
        <ArrowRight onClick={() => handleCardSwipe('right')} />
      </Controls>
    </Container>
  )
}

export default Vto
