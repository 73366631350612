import { FetchArgs, fetchBaseQuery, retry } from '@reduxjs/toolkit/dist/query'

import { RootState } from '../model/model'
import { tokenSelector } from '../store/auth/selectors'
import app_config from '../config/app/config'
import { deleteIntersessionAuthToken, getIntersessionAuthToken } from './auth'

export const staggeredBaseQueryWithBailOut = (baseUrl: string) =>
  retry(
    async (args: string | FetchArgs, api, extraOptions) => {
      const state = api.getState() as RootState
      const authToken = tokenSelector(state)

      const result = await fetchBaseQuery({
        baseUrl,
        prepareHeaders: headers => {
          headers.append('Content-Type', 'application/json')
          headers.append('Authorization', `Bearer ${authToken}`)
          return headers
        },
      })(args, api, {
        ...extraOptions,
        skip: !authToken,
      })

      if (result.error && result.error.status === 401) {
        if (!getIntersessionAuthToken()) {
          deleteIntersessionAuthToken()
          window.location.href = app_config.loginUrl as string
        }
      }

      // retry only if the error is on the server
      // 401 errors are handled above
      const isNotServerError =
        result.error &&
        result.error.status !== 401 &&
        result.error.status >= 300 &&
        result.error.status < 500

      const isResultFalse =
        result.error &&
        result.error.data &&
        (result.error.data as { result?: boolean })?.result === false

      if (isNotServerError || isResultFalse) {
        // for testing
        // if (result.error?.status === 500) {
        retry.fail(result.error)
      }

      return result
    },
    {
      maxRetries: 3,
    },
  )
