import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getFluidSize, getFluidFontSize, breakpointsCross } from '../../style/theme'
const CharacteristicLabelTitle = styled.span`
  min-width: ${getFluidSize('100px')};
  max-width: 20vw;
`

const CharacteristicLabelDescription = styled.span`
  width: 100%;
  opacity: 60%;
  text-align: left;
`

const CharacteristicLabel = styled.span`
  font-size: ${getFluidFontSize('10px')};
  width: 100%;
  @media (min-width: ${breakpointsCross.L.min}) {
    width: 75%;
    min-width: 16em;
  }

  display: flex;
  justify-content: space-between;
`
const Characteristics: React.FC<{ model?: any; moco?: any }> = ({ model, moco }) => {
  const { t } = useTranslation()

  return (
    <p>
      {model?.geofit && (
        <CharacteristicLabel>
          <CharacteristicLabelTitle>{t('ProductPage.item_geofit')}</CharacteristicLabelTitle>
          <CharacteristicLabelDescription>{model?.geofit.label}</CharacteristicLabelDescription>
        </CharacteristicLabel>
      )}
      {moco?.frontColor && (
        <CharacteristicLabel>
          <CharacteristicLabelTitle>{t('ProductPage.item_frontcolor')}</CharacteristicLabelTitle>
          <CharacteristicLabelDescription>{moco?.frontColor.label}</CharacteristicLabelDescription>
        </CharacteristicLabel>
      )}
      {moco?.templeColor && (
        <CharacteristicLabel>
          <CharacteristicLabelTitle>{t('ProductPage.item_templecolor')}</CharacteristicLabelTitle>
          <CharacteristicLabelDescription>{moco?.templeColor.label}</CharacteristicLabelDescription>
        </CharacteristicLabel>
      )}
      {moco?.lensColor && (
        <CharacteristicLabel>
          <CharacteristicLabelTitle>{t('ProductPage.item_lenscolor')}</CharacteristicLabelTitle>
          <CharacteristicLabelDescription>{moco?.lensColor.label}</CharacteristicLabelDescription>
        </CharacteristicLabel>
      )}
    </p>
  )
}

export default withTranslation('common')(Characteristics)
