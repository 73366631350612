import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useGetPlpProductsQuery } from '../../services/afaProducts'
import { breakpoints, getFluidSizeWithFullFormula, palette, pxToRem } from '../../style/theme'
import AfaFilterButton from './AfaFilterButton'
import AfaPlpFooterSportSwitcher from './AfaPlpFooterSportSwitcher'

const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  padding: 1em 2em;
  background-color: ${palette.tangaroa};
  font-size: ${getFluidSizeWithFullFormula('px', 12, 24, 1366, 3840)};
  color: white;

  @media screen and (max-width: ${breakpoints.M}) {
    grid-template-columns: 1fr auto;
  }
`

const AfaSearchResultFilterButton = styled(AfaFilterButton)`
  @media screen and (max-width: ${breakpoints.L}) {
    visibility: hidden;
  }
`

const NumberOfProducts = styled.div`
  color: white;
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: ${pxToRem(24)}rem;

  @media screen and (max-width: ${breakpoints.L}) {
    display: none;
  }
`

const AfaSearchResultFooter: React.FC<{ brandCode?: string }> = ({ brandCode }) => {
  const { t } = useTranslation()

  const productsQuery = useGetPlpProductsQuery()

  return (
    <Wrapper>
      <AfaSearchResultFilterButton />

      <AfaPlpFooterSportSwitcher brandCode={brandCode} />

      <NumberOfProducts>
        {productsQuery.data && productsQuery.data.count > 0
          ? `${productsQuery.data?.count} ${t('Afa.products')}`
          : ''}
      </NumberOfProducts>
    </Wrapper>
  )
}

export default AfaSearchResultFooter
