import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import DownIcon from '../../../components/icons/DownIcon'
import { useSelector } from 'react-redux'
import { customerDoorsSelector } from '../../../store/customer/selectors'
import { useGetSelectedDoorId, useSelectDoorId } from '../Hooks/usePreselectDefaultDoor'
import DoorsLayer from './DoorsLayer'
import { palette } from '../../../style/theme'

const Button = styled.button`
  color: white;
  display: flex;
  font-size: 1vw;
  align-items: center;
  gap: 0.75vw;
`

const Text = styled.div`
  display: flex;
  align-items: center;
`

const Label = styled.div`
  padding: 1vw;
  text-transform: capitalize;
`

const DoorId = styled.div`
  color: ${palette.cornflowerBlueAA};
`

const IconWrapper = styled.div`
  background-color: ${palette.blueZodiac};
  border-radius: 0.5vw;
  padding: 0.75vw;
  display: flex;
  font-size: min(1vw, 35px);
  align-items: center;
  justify-content: center;

  svg {
    width: 1.25em;
    height: 1.25em;
  }
`

const ArrowIcon = styled(DownIcon)`
  transition: transform 0.25s;

  &.up {
    transform: rotate(180deg);
  }
`

const DoorsButton: React.FC = () => {
  const { t } = useTranslation()

  const [layerOpen, setLayerOpen] = useState(false)

  const doors = useSelector(customerDoorsSelector)

  const selectedDoorId = useGetSelectedDoorId()
  const selectDoorId = useSelectDoorId()

  useEffect(() => {
    if (doors.length === 1 && !selectedDoorId) {
      selectDoorId(doors[0].id)
    }
  }, [doors, selectedDoorId, selectDoorId])

  return (
    <>
      <Button onClick={() => setLayerOpen(!layerOpen)}>
        <Text>
          <Label>{t('AA.doors')}</Label>
          <DoorId>{selectedDoorId}</DoorId>
        </Text>
        <IconWrapper>
          <ArrowIcon className={layerOpen ? 'up' : 'down'} />
        </IconWrapper>
      </Button>

      <DoorsLayer isOpen={layerOpen} setLayerOpen={setLayerOpen} />
    </>
  )
}

export default DoorsButton
