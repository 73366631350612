import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Appointment } from '../../model/appointment'
import { Brand } from '../../model/brand'
import { brandSelectorByCode, brandSelectorByCodes } from '../../store/brands/selectors'
import { breakpoints, breakpointsNoUnit, palette, pxToRem, spacing } from '../../style/theme'
import ChevronDownIcon from '../icons/ChevronDownIcon'
import ChevronUpIcon from '../icons/ChevronUpIcon'
import { BrandLogoImage } from '../../style/CommonComponents'
import { useGetBrandLogo } from '../../services/afaContents'

const CardContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  margin-top: ${pxToRem(spacing(5))}rem;
  color: ${palette.white};
  overflow: hidden;

  .swiper-container {
    height: 40vh;

    @media (max-width: ${breakpoints.M}) {
      height: 35vh;
    }
  }

  .swiper-slide {
    height: 100%;
  }

  .swiper-slide img {
    display: block;
    height: 100%;
    width: 100%;
    padding: 0 20%;
  }
}
`

const IconContent = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  padding: ${pxToRem(spacing(2))}rem 0;
  color: ${palette.white};
`

const SwiperStyled = styled(Swiper)<{ arrows: boolean }>`
  ${props =>
    !props.arrows
      ? `.swiper-wrapper {
    justify-content: center;
  }`
      : ''}
`

type Props = {
  appointmentBrands: Appointment['brands']
}

type SwiperProps = {
  brandCode: string
}

const SwiperSlideWithLogo: React.FC<SwiperProps> = ({ brandCode }) => {
  const { brandLogo, isSuccess } = useGetBrandLogo(brandCode)
  const brand = useSelector(brandSelectorByCode(brandCode))
  return (
    <SwiperSlide>
      {brandLogo || !isSuccess ? (
        <BrandLogoImage src={brandLogo} alt="" />
      ) : (
        <span>{brand?.brand || brandCode}</span>
      )}
    </SwiperSlide>
  )
}

const AppointmentBrand: React.FC<Props> = ({ appointmentBrands }) => {
  const filterCodesBrands = appointmentBrands.map(brand => brand.code)
  const brands = useSelector(brandSelectorByCodes(filterCodesBrands))
  const [initiativesSwiper, setInitiativeSwiper] = useState<SwiperCore>()
  const [, setCurrentInitiativeIndex] = useState(0) //only to allow the component to evaluate the arrows after a change

  return (
    <CardContainer>
      <IconContent>
        {!initiativesSwiper?.isBeginning ? (
          <ChevronUpIcon
            onClick={() => {
              initiativesSwiper?.slidePrev()
            }}
          />
        ) : null}
      </IconContent>
      <SwiperStyled
        arrows={brands.length >= 3}
        direction={'vertical'}
        spaceBetween={10}
        slidesPerView={4}
        loop={false}
        onSwiper={swiper => setInitiativeSwiper(swiper)}
        onSlideChange={swiper => {
          setCurrentInitiativeIndex(swiper.activeIndex)
        }}
        breakpoints={{
          [breakpointsNoUnit.XS]: { slidesPerView: 3 },
          [breakpointsNoUnit.L]: { slidesPerView: brands.length >= 4 ? 4 : brands.length },
        }}
      >
        {brands.map((brand: Brand) => (
          <SwiperSlideWithLogo key={brand.code} brandCode={brand.code} />
        ))}
      </SwiperStyled>
      <IconContent>
        {!initiativesSwiper?.isEnd ? (
          <ChevronDownIcon
            onClick={() => {
              initiativesSwiper?.slideNext()
            }}
          />
        ) : null}
      </IconContent>
    </CardContainer>
  )
}

export default AppointmentBrand
