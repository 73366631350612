import { Badge, Icon } from 'antd'
import classnames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import {
  UpdateCartPayload,
  useRevertableUpdateCart,
  useUpdateCart,
  useUpdateCartOnlyIfMainDoorIsEnabled,
} from '../../../../hooks/useUpdateCart'
import { deleteFromCart } from '../../../../libs/cart'
import { isPageMatches } from '../../../../libs/url'
import { CartProduct, SizeInCart } from '../../../../model/cart'
import { Door } from '../../../../model/customer'
import { Size } from '../../../../model/product'
import { WishlistProduct } from '../../../../model/wishlist'
import { toggleWishlistAction } from '../../../../store/actions'
import { getIsMassiveOrderEnabled } from '../../../../store/app/selectors'
import {
  cartViewTypeSelector,
  disablePurchaseControls,
  getPendingNotificationSelector,
  isCartViewSelector,
} from '../../../../store/cart/selectors'
import { activeDoorsSelector } from '../../../../store/customer/selectors'
import { dislikeItemsSelector } from '../../../../store/dislike/selectors'
import { isPdpVisibleSelector } from '../../../../store/pdp/selectors'
import { disableCartButtonSelector } from '../../../../store/showButtonsSelectors'
import { cartModeSelector } from '../../../../store/stars/selectors'
import { isWishlistLoadingSelector } from '../../../../store/wishlist/selectors'
import {
  breakpointsNoUnit,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
} from '../../../../style/theme'
import { getAntBadgeCountCss } from '../../../CommonComponents'
import RCIcon from '../../../UI/RCIcon'
import { errorNotification } from '../../../Notification/notifications'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid ${palette.gallery};
  border-radius: 6rem;
  background-color: ${palette.white};
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  line-height: normal;
  font-size: ${getFluidSizeWithFullFormula(
    'px',
    8,
    24,
    breakpointsNoUnit.XS,
    breakpointsNoUnit.XL,
  )};
  padding: 0.5em 0;
`

const CouvetteDislikeBtn = styled.button`
  display: none;

  &[disabled] {
    opacity: 0.5;
  }
`

const SizeLabel = styled.div`
  color: ${palette.kobi};
  padding: 0 0.75em;
  white-space: nowrap;
  border-right: 1px solid ${palette.gallery};
`

const WishlistBtn = styled.button`
  border-right: 1px solid ${palette.gallery};
  min-width: 2em;
  color: ${palette.congressBlue};

  &.disabled {
    opacity: 0.3;
  }
`

const CartBtn = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 0 0.75em;

  &[disabled] {
    opacity: 0.5;
  }

  .ant-badge {
    font-size: inherit;
  }

  ${getAntBadgeCountCss(palette.hibiscus)}
`

const Processed = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0 0.75em;
  color: ${palette.dustyGray};

  .ant-badge {
    font-size: inherit;
  }

  ${getAntBadgeCountCss(palette.dustyGray)}
`

const MyshopHeaderBadge = styled(Badge as any)<{ onClick: () => void; disabled?: boolean }>`
  z-index: 1;
  width: 2.3rem;
  height: 1.3rem;

  img {
    width: 1.3rem;
    height: 1.3rem;
  }

  sup {
    background: ${palette.hibiscus};
    min-width: ${pxToRem(18)}rem;
    height: ${pxToRem(18)}rem;
  }

  &[disabled] {
    opacity: 0.5;
  }
`

const CartDeleteBtn: React.FC<{ handleClick: () => void; disabled?: boolean }> = ({
  handleClick,
  disabled,
}) => (
  <div className="couvette-header__dislike">
    <CouvetteDislikeBtn className="couvette-dislike-btn" onClick={handleClick} disabled={disabled}>
      <Icon type="delete" theme="outlined" />
    </CouvetteDislikeBtn>
  </div>
)

const checkIsInWishlist = (
  productSizes: (Size | SizeInCart)[],
  itemsInWishlist: WishlistProduct[],
) => {
  const upcsNotInWishlist = productSizes.map(({ upc, mocoCode }) => ({
    upc,
    mocoCode,
  }))

  const upcsInWishlist =
    itemsInWishlist &&
    itemsInWishlist.map(({ upc, mocoCode }) => ({
      upc,
      mocoCode,
    }))

  const isInWislist = (upcsInWishlist && upcsInWishlist.length) === upcsNotInWishlist.length
  return isInWislist
}

const onAddToCartClick: (a: {
  enabledDoors: Door[]
  cartProducts: CartProduct[]
  headerControlCode?: string
  isMassiveOrderEnabled: boolean
  productSizes: Size[]
  updateCart: (p: UpdateCartPayload) => void
  revertableUpdateCart: (p: UpdateCartPayload, b: string[]) => void
  myShopHeader?: boolean
  disabled?: boolean
  updateCartOnlyIfMainDoorIsEnabled: (f: () => void) => void
  dislikeItems?: string[]
  brandCode: string
}) => void = ({
  enabledDoors,
  cartProducts,
  headerControlCode,
  isMassiveOrderEnabled,
  productSizes,
  updateCart,
  revertableUpdateCart,
  myShopHeader,
  disabled,
  updateCartOnlyIfMainDoorIsEnabled,
  dislikeItems,
  brandCode,
}) => {
  if (disabled) return

  const doorIds = enabledDoors.map(({ id }) => id)

  const updateCartPayload = {
    doors: doorIds,
    productSizes: productSizes
      .filter(p => !dislikeItems?.includes(p.mocoCode))
      .map(productSize => ({ ...productSize, brandCode })),
    qntToAdd: 1,
    cartProducts,
  }

  const sizeCode = productSizes.reduce((_, { size, sizeLabel }) => `${size} ${sizeLabel}`, {})

  const codes = headerControlCode ? [`${headerControlCode}-${sizeCode}`] : []

  const updateCartCallback =
    myShopHeader || isMassiveOrderEnabled
      ? () => revertableUpdateCart(updateCartPayload, codes)
      : () => updateCart(updateCartPayload)

  updateCartOnlyIfMainDoorIsEnabled(updateCartCallback)
}

const onToggleWishlist: (args: {
  productSizes: Size[]
  toggleWishlist: (payloadItems: any[]) => void
  headerControlCode?: string
  cartProducts: CartProduct[]
  itemsInWishlist: WishlistProduct[]
  brandCode: string
  defaultDoor: string
}) => void = ({
  productSizes,
  toggleWishlist,
  headerControlCode,
  cartProducts,
  itemsInWishlist,
  brandCode,
  defaultDoor,
}) => {
  const filterdSizes = productSizes.filter(
    ({ doors, enabledDoors }) =>
      doors?.includes(defaultDoor) || enabledDoors?.includes(defaultDoor),
  )
  const itemsNotInCart = filterdSizes.filter(
    productSize => !cartProducts.find(cartProduct => cartProduct.upc === productSize.upc),
  )
  const itemsNotInWishlist = itemsNotInCart.filter(
    item => !itemsInWishlist.some(itemInWishlist => itemInWishlist.upc == item.upc),
  )
  const notAllItemsAreInWishlist = itemsNotInWishlist.length !== 0
  const itemsForPayload =
    headerControlCode && notAllItemsAreInWishlist ? itemsNotInWishlist : itemsNotInCart
  const payloadItems = itemsForPayload.map(
    ({ upc, mocoCode, size, skuCode, enabledDoors, categoryId }) => ({
      enabledDoors,
      upc,
      mocoCode,
      brandCode,
      size,
      skuCode,
      categoryId,
      toDelete: !notAllItemsAreInWishlist,
    }),
  )

  toggleWishlist(payloadItems)
}

export type Props = {
  productSizes: Size[]
  cartProducts: CartProduct[]
  cartEditable?: boolean
  doors?: string[] // the doors in cart
  showWishlist?: boolean
  deleteIcon?: boolean
  itemsInWishlist?: any
  headerControlCode?: string
  disableWishlist?: boolean
  myShopHeader?: boolean
  brandCode: string
  onCartPage?: boolean
}

const PurchaseControls: React.FC<Props> = ({
  productSizes,
  cartEditable,
  showWishlist,
  deleteIcon,
  headerControlCode,
  cartProducts = [],
  disableWishlist,
  myShopHeader,
  itemsInWishlist,
  doors,
  brandCode,
  onCartPage,
}) => {
  const dislikeItems = useSelector(dislikeItemsSelector)
  const [disabled, disabledMessageKey] = useSelector(
    disablePurchaseControls(productSizes, doors, dislikeItems, brandCode, headerControlCode),
  )
  const isMassiveOrderEnabled = useSelector(getIsMassiveOrderEnabled)
  const activeDoors = useSelector(activeDoorsSelector)
  const pendingNotification = useSelector(
    getPendingNotificationSelector({ productSizes, productSize: undefined }),
  )
  const isCartView = useSelector(isCartViewSelector)
  const isPdpVisible = useSelector(isPdpVisibleSelector)
  const mode = useSelector(cartModeSelector)

  const updateCart = useUpdateCart()
  const revertableUpdateCart = useRevertableUpdateCart()
  const updateCartOnlyIfMainDoorIsEnabled = useUpdateCartOnlyIfMainDoorIsEnabled()
  const isWishlistLoading = useSelector(isWishlistLoadingSelector)
  const disableDeleteButton = useSelector(disableCartButtonSelector)
  const cartViewType = useSelector(cartViewTypeSelector)

  const { t } = useTranslation()

  const dispatch = useDispatch()

  // Needed by purchase controls of every plp model's header
  const purchaseControlSize = productSizes && productSizes.length ? productSizes[0].size : ''

  const filterProductSizesByDoor = (door: string) => ({
    enabledDoors,
  }: {
    enabledDoors: string[]
    size: string
  }) => enabledDoors.includes(door)

  const filterProductSizesByDoorAndSize = (door: string) => ({
    enabledDoors,
    size,
  }: {
    enabledDoors: string[]
    size: string
  }) => size === purchaseControlSize && enabledDoors.includes(door)

  const sizesDoorsCombinationsFastAddToCart = pendingNotification?.doors?.reduce(
    (total: number, door: string) => {
      const productSizesFiltered = pendingNotification?.productSizes?.filter(
        myShopHeader ? filterProductSizesByDoor(door) : filterProductSizesByDoorAndSize(door),
      )

      return total + productSizesFiltered.length
    },
    0,
  )

  const sizesDoorsCombinationsSingleMoco = pendingNotification?.doors?.filter(
    (door: string) => !productSizes[0].enabledDoors || productSizes[0].enabledDoors.includes(door),
  ).length

  const sizesDoorsCombinations =
    myShopHeader || Boolean(headerControlCode)
      ? sizesDoorsCombinationsFastAddToCart
      : sizesDoorsCombinationsSingleMoco

  const cartProductsQuantity = cartProducts.reduce(
    (tot, cartProduct) => tot + cartProduct.quantity,
    0,
  )

  const pendingNotificationQuantity = (pendingNotification?.qntToAdd || 0) * sizesDoorsCombinations

  const cartQuantity = cartProductsQuantity + pendingNotificationQuantity

  const confirmedQty = cartProducts.reduce(
    (tot, cartProduct) => tot + (cartProduct.confirmedQuantity || 0),
    0,
  )

  const isCartPage = isPageMatches('cart')

  if (
    isCartPage &&
    isCartView &&
    !cartQuantity &&
    !confirmedQty &&
    !isPdpVisible &&
    !(mode === 'stars' && onCartPage)
  ) {
    return null
  }

  const cartQuantityForSizeOnSomeDoor = cartProducts.some(cartProduct =>
    productSizes.find(({ upc }) => upc === cartProduct.upc),
  )

  const everyMocoIsInCart = productSizes.every(productSize =>
    cartProducts.find(({ upc }) => upc === productSize.upc),
  )

  if (!productSizes) return null

  // All sizes are equal in terms of labels, but differs for skuCode and upc
  const sizeAttrs = productSizes[0]

  let cartIcon,
    wishlistIcon,
    processedIcon,
    deleteButton = null

  const isInWislist = checkIsInWishlist(productSizes, itemsInWishlist)

  const unconfirmedCartProducts = cartProducts.filter(({ status }) => status === '1')

  const targetDoors = isMassiveOrderEnabled
    ? activeDoors
    : activeDoors.filter(door => door.mainDoor)

  const enabledDoors = targetDoors.filter(door =>
    productSizes.some(
      size => size.enabledDoors?.includes(door.id) || size.doors?.includes(door.id),
    ),
  )

  const disabledCartBtn =
    !!disabled ||
    (!(onCartPage && cartViewType === 'tableView') && enabledDoors.length === 0) ||
    !cartEditable

  const disabledCartBtnMyShop = !!disabled || !cartEditable || unconfirmedCartProducts.length === 0

  if (cartEditable && myShopHeader)
    return (
      <MyshopHeaderBadge
        overflowCount={999}
        onClick={() =>
          onAddToCartClick({
            enabledDoors,
            cartProducts: unconfirmedCartProducts.length ? unconfirmedCartProducts : cartProducts,
            headerControlCode,
            isMassiveOrderEnabled,
            productSizes,
            updateCart,
            revertableUpdateCart,
            myShopHeader,
            disabled: disabledCartBtnMyShop,
            updateCartOnlyIfMainDoorIsEnabled,
            dislikeItems,
            brandCode,
          })
        }
        disabled={disabledCartBtnMyShop}
        count={cartQuantity || null}
      >
        <RCIcon type="cart" />
      </MyshopHeaderBadge>
    )

  if (!isPdpVisible) {
    const CartIcon = (
      <Badge count={cartQuantity || null} overflowCount={999} className="in-cart">
        <Icon type="shopping-cart" />
      </Badge>
    )
    cartIcon = (
      <CartBtn
        className="purchase-controls__item purchase-controls--cart"
        onClick={() =>
          onAddToCartClick({
            enabledDoors,
            cartProducts: unconfirmedCartProducts.length ? unconfirmedCartProducts : cartProducts,
            headerControlCode,
            isMassiveOrderEnabled,
            productSizes,
            updateCart,
            revertableUpdateCart,
            myShopHeader,
            disabled: disabledCartBtn,
            updateCartOnlyIfMainDoorIsEnabled,
            dislikeItems,
            brandCode,
          })
        }
        disabled={disabledCartBtn}
      >
        {CartIcon}
      </CartBtn>
    )
    if (cartEditable && deleteIcon && cartProductsQuantity > 0) {
      deleteButton = (
        <CartDeleteBtn
          disabled={disableDeleteButton}
          handleClick={() => {
            deleteFromCart({
              productSizes,
              cartProducts: unconfirmedCartProducts,
              updateCart,
              brandCode,
            })
          }}
        />
      )
    }
  }

  if (showWishlist) {
    const isDisabled =
      disableWishlist ||
      disabled ||
      (headerControlCode ? everyMocoIsInCart : cartQuantityForSizeOnSomeDoor) ||
      isWishlistLoading ||
      enabledDoors.length === 0
    wishlistIcon = (
      <WishlistBtn
        className={classnames({
          'purchase-controls__item': true,
          'purchase-controls--wish': true,
          disabled: !!isDisabled,
        })}
        disabled={!!isDisabled}
        onClick={() =>
          onToggleWishlist({
            productSizes,
            toggleWishlist: items => dispatch(toggleWishlistAction(items)),
            headerControlCode,
            cartProducts,
            itemsInWishlist,
            brandCode,
            defaultDoor: activeDoors.find(({ mainDoor }) => mainDoor)?.id || '',
          })
        }
      >
        <RCIcon
          type={isInWislist ? 'heart-filled' : 'heart'}
          iconClass={`heart-icon ${isInWislist ? 'heart-icon--active' : ''} ${
            isDisabled ? 'disabled' : ''
          }`}
        />
      </WishlistBtn>
    )
  }

  if (confirmedQty > 0 && !isPdpVisible) {
    processedIcon = (
      <Processed>
        <Badge count={confirmedQty} overflowCount={999}>
          <RCIcon type="processed-icon" />
        </Badge>
      </Processed>
    )
  }

  return (
    <Wrapper
      className="purchase-controls"
      onClick={e => {
        e.stopPropagation()
        if (disabled && disabledMessageKey) {
          errorNotification({
            message: t(disabledMessageKey as string),
            className: 'rc-notification',
          })
        }
      }}
    >
      {(sizeAttrs.sizeLabel || sizeAttrs.size) && (
        <SizeLabel className="purchase-controls__item purchase-controls--size">
          {sizeAttrs.sizeLabel} {sizeAttrs.size}
        </SizeLabel>
      )}
      {mode === 'stars' && onCartPage ? null : deleteButton}
      {wishlistIcon}
      {mode === 'stars' && onCartPage ? null : cartIcon}
      {mode === 'stars' && onCartPage ? null : processedIcon}
    </Wrapper>
  )
}

export default PurchaseControls
