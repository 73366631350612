import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DislikeState } from '../../model/dislike'
import { resetForNewClient } from '../extraActions'

const DEFAULT_STATE: DislikeState = {
  loading: false,
  loaded: false,
  listId: null,
  items: [],
}

export const slice = createSlice({
  name: 'dislike',
  initialState: DEFAULT_STATE,
  reducers: {
    loadDislikeSuccess: (state, { payload }: PayloadAction<{ id: string; items: string[] }>) => {
      state.loaded = true
      state.loading = false
      state.listId = payload.id
      state.items = [...payload.items]
    },
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, () => DEFAULT_STATE)
  },
})

export default slice.reducer
