import { Carousel } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { getBrandByBrandCode } from '../../../libs/brand'
import { requestCalculateAnalytics } from '../../../libs/calculate-analytics'
import { isNotUndefined, rgba } from '../../../libs/utils'
import { CartAnalytics } from '../../../model/cart'
import { BrandCode } from '../../../model/model'
import { brandsSelector } from '../../../store/brands/selectors'
import {
  analyticsSelector,
  cartProductsDetailsSelector,
  filteredCartProductsSelector,
} from '../../../store/cart/selectors'
import { pxToRem } from '../../../style/theme'
import { CartContent, scrollbarCss } from '../../CommonComponents'
import BarGraph from '../../Graphs/BarGraph'
import { barChartDefaultConfig } from '../../Graphs/config'
import DoughnutGraph from '../../Graphs/DoughnutGraph'
import TableAnalytics from './TableAnalytics'
import TableCartAnalyticsBrands from './TableCartAnalyticsBrands'

const Wrapper = styled(CartContent)`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1.5vw 0;
  ${scrollbarCss}

  .ant-carousel {
    flex: 1;
  }
`

const GraphWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: min-content ${pxToRem(600)}rem;
  gap: 0.5em;
`

const GraphTitle = styled.h2``

const GraphTitleItem = styled.h2``

const StyledCarousel = styled(Carousel as any)`
  padding-top: 5vh;
  height: 100%;

  .slick-list,
  .slick-track {
    height: 100%;
  }

  .slick-slide > div {
    height: 100%;
  }

  .slick-dots {
    top: 12px;
    li button {
      border-radius: 1vh;
      width: 2vw !important;
      height: 0.5vh !important;
      background: #005192 !important;
    }
  }
`

const Slide = styled.div`
  height: 100%;

  .chartjs-render-monitor {
    height: 350px !important;
  }
`

const SlideOneInner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1.5vmin;
  overflow: hidden;
`

const Row = styled.div`
  padding: 0 1vw;

  &:nth-child(2) {
    flex: 1;
  }

  &:nth-child(3) {
    flex: 1.5;
  }
`

const RowTwoGraph = styled(Row)`
  display: flex;

  > * {
    width: 50%;
    height: 100%;
  }
`

const TableCartAnalytics: React.FC = () => {
  const { t } = useTranslation()

  const brands = useSelector(brandsSelector)
  const analyticsRedux = useSelector(analyticsSelector)
  const cartProductsDetails = useSelector(cartProductsDetailsSelector)
  const cartProductsFiltered = useSelector(filteredCartProductsSelector)

  const [analyticsState, setAnalyticsState] = useState<CartAnalytics | undefined>(analyticsRedux)
  const [selectedBrands, setSelectedBrands] = useState<BrandCode[] | null>(null)

  const analyticsBrands =
    analyticsRedux?.brands
      .map(analyticsBrand => brands.find(brand => brand.code === analyticsBrand.key))
      .filter(isNotUndefined) || []

  const selectedBrandsWithFallback = selectedBrands || analyticsBrands.map(({ code }) => code)

  useEffect(() => {
    let unmounted = false

    const updateAnalytics = async () => {
      const cartProductsRefilteredByAnalyticsBrands = cartProductsFiltered.filter(cartProduct => {
        const brand = getBrandByBrandCode(brands, cartProduct.brandCode)
        return selectedBrandsWithFallback.includes(brand?.code || '')
      })

      const analytics = (await requestCalculateAnalytics(
        cartProductsRefilteredByAnalyticsBrands,
        brands,
        cartProductsDetails,
      )) as CartAnalytics

      if (!unmounted) {
        setAnalyticsState(analytics)
      }
    }

    updateAnalytics()

    return () => {
      unmounted = true
    }
  }, [selectedBrandsWithFallback, brands, cartProductsDetails, cartProductsFiltered])

  const brandLabels = analyticsState?.brands.map(brandAnalytics => brandAnalytics.key) || []

  const {
    sunPercentage,
    opticalPercentage,
    newModelPercentage,
    colorRefreshPercentage,
    carryOverPercentage,
  } = (analyticsState?.brands || []).reduce(
    (result, brandAnalytics, i) => {
      result.sunPercentage.push(Math.floor((brandAnalytics.sun * 100) / brandAnalytics.total_items))
      result.opticalPercentage.push(100 - result.sunPercentage[i])
      result.newModelPercentage.push(
        Math.floor((brandAnalytics.new_model * 100) / brandAnalytics.total_items),
      )
      result.colorRefreshPercentage.push(
        Math.floor((brandAnalytics.color_refresh * 100) / brandAnalytics.total_items),
      )
      result.carryOverPercentage.push(
        100 - result.newModelPercentage[i] - result.colorRefreshPercentage[i],
      )

      return result
    },
    {
      sunPercentage: [] as number[],
      opticalPercentage: [] as number[],
      newModelPercentage: [] as number[],
      colorRefreshPercentage: [] as number[],
      carryOverPercentage: [] as number[],
    },
  )

  const datasetStyle1 = {
    ...barChartDefaultConfig,
    backgroundColor: rgba(70, 120, 170, 1),
    hoverBackgroundColor: rgba(70, 120, 170, 1),
  }

  const datasetStyle2 = {
    ...barChartDefaultConfig,
    backgroundColor: rgba(151, 205, 241, 1),
    hoverBackgroundColor: rgba(151, 205, 241, 1),
  }

  const datasetStyle3 = {
    ...barChartDefaultConfig,
    backgroundColor: rgba(233, 243, 252, 1),
    hoverBackgroundColor: rgba(233, 243, 252, 1),
  }

  const backgroundColors = [rgba(151, 205, 241, 1), rgba(70, 120, 170, 1)]

  return (
    <Wrapper>
      <TableCartAnalyticsBrands
        brands={analyticsBrands}
        onChangeSelectedBrands={setSelectedBrands}
        selectedBrands={selectedBrandsWithFallback}
      />
      {!!analyticsState && (
        <StyledCarousel className="analytics-carousel" adaptiveHeight={true}>
          <Slide>
            <SlideOneInner>
              <Row>
                <GraphTitle>{t('Chart.order_summary')}</GraphTitle>
                <TableAnalytics dataSource={[analyticsState.total]} className="totals-table" />
              </Row>

              <Row>
                <GraphTitle>{t('Chart.item_unitsbrands')}</GraphTitle>
                <BarGraph
                  datasets={[
                    {
                      data: analyticsState.brands.map(brandAnalytics => brandAnalytics.total_items),
                      label: t('Chart.order_summary'),
                      ...barChartDefaultConfig,
                    },
                  ]}
                  labels={brandLabels}
                />
              </Row>

              <RowTwoGraph>
                <GraphWrapper>
                  <GraphTitleItem>{t('Chart.item_unitscategory')}</GraphTitleItem>
                  <DoughnutGraph
                    data={[analyticsState.total.sun, analyticsState.total.optical]}
                    labels={[t('Chart.label_sun'), t('Chart.label_optical')]}
                    config={{
                      backgroundColor: backgroundColors,
                    }}
                  />
                </GraphWrapper>

                <GraphWrapper>
                  <GraphTitleItem>{t('Chart.item_newvscolorvscarry')}</GraphTitleItem>
                  <DoughnutGraph
                    data={[
                      analyticsState.total.carry_over,
                      analyticsState.total.new_model,
                      analyticsState.total.color_refresh,
                    ]}
                    labels={[
                      t('Chart.label_carryover'),
                      t('Chart.label_newmodel'),
                      t('Chart.label_colorrefresh'),
                    ]}
                    config={{
                      backgroundColor: [rgba(233, 243, 252, 1), ...backgroundColors],
                    }}
                  />
                </GraphWrapper>
              </RowTwoGraph>
            </SlideOneInner>
          </Slide>

          <Slide>
            <Row>
              <TableAnalytics
                dataSource={[analyticsState.total, ...analyticsState.brands]}
                className="headless-table"
              />
            </Row>
          </Slide>

          <Slide>
            <Row>
              <GraphTitle>{t('Chart.item_unitscategory_by_brands')}</GraphTitle>
              <BarGraph
                max={100}
                datasets={[
                  {
                    data: opticalPercentage,
                    label: t('Chart.label_optical'),
                    ...datasetStyle1,
                  },
                  {
                    data: sunPercentage,
                    label: t('Chart.label_sun'),
                    ...datasetStyle2,
                  },
                ]}
                labels={brandLabels}
                showLegend={true}
              />
            </Row>

            <Row>
              <GraphTitle>{t('Chart.item_newvscolorvscarry_by_brands')}</GraphTitle>
              <BarGraph
                max={100}
                datasets={[
                  {
                    data: carryOverPercentage,
                    label: t('Chart.label_carryover'),
                    ...datasetStyle1,
                  },
                  {
                    data: colorRefreshPercentage,
                    label: t('Chart.label_colorrefresh'),
                    ...datasetStyle2,
                  },
                  {
                    data: newModelPercentage,
                    label: t('Chart.label_newmodel'),
                    ...datasetStyle3,
                  },
                ]}
                labels={brandLabels}
                showLegend={true}
              />
            </Row>
          </Slide>
        </StyledCarousel>
      )}
    </Wrapper>
  )
}

export default TableCartAnalytics
