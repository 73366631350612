import { createSlice, original, PayloadAction } from '@reduxjs/toolkit'

import app_config from '../../config/app/config'
import { getCouvettesBrands } from '../../libs/couvettes'
import { CouvettesState } from '../../model/couvettes'
import { Product } from '../../model/product'
import { resetForNewClient } from '../extraActions'

const DEFAULT_STATE: CouvettesState = {
  brands: [],
  counters: {},
  isGridView: false,
  items: [],
  loading: false,
  loaded: true,
  noMorePages: false,
  loadedProducts: [],
  pagination: {
    isFirstRequest: true,
    sort: 'release',
    start: undefined,
    count: 0,
  },
  total: {},
  isComingFromFilters: false,
}

const calculateMorePages = (
  state: CouvettesState | undefined,
  count: number | undefined,
  paylaodStart: string | undefined,
  payloadItems: Product[],
) => {
  if (!state) {
    return false
  }

  const isOldStart = isNaN(Number(paylaodStart)) // in this case start is actually the old nextModel
  const resultIsNotEmpty = payloadItems.length > 0
  const startIsPopulated = !!paylaodStart
  const morePages =
    resultIsNotEmpty &&
    startIsPopulated &&
    (isOldStart
      ? state.pagination.start !== paylaodStart
      : Number(paylaodStart) * app_config.plpPageSize < (count || 0))

  return morePages
}

export const slice = createSlice({
  name: 'couvettes',
  initialState: DEFAULT_STATE,
  reducers: {
    loadCouvettesSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        couvettes: { items: Product[] }
        pagination: { start?: string; isFirstRequest: boolean; sort: string; count: number }
      }>,
    ) => {
      if (state.pagination.start !== payload.pagination.start) {
        // if sorting doesn't change we append items
        const sortedItems = payload.couvettes.items.sort((a, b) => a.plpOrder - b.plpOrder)
        const items =
          payload.pagination.sort === state.pagination.sort
            ? state.items.concat(sortedItems)
            : sortedItems

        state.brands = getCouvettesBrands(items)
        state.items = items
        state.loading = false
        state.loaded = true

        const morePages = calculateMorePages(
          original(state),
          payload.pagination.count,
          payload.pagination.start,
          payload.couvettes.items,
        )
        state.noMorePages = !morePages
        state.pagination = {
          ...payload.pagination,
          isFirstRequest: false,
        }
      }
    },
    loadDetailsSuccess: (state, { payload }: PayloadAction<Product[]>) => {
      state.loadedProducts = payload
    },
    loadingCouvettes: state => {
      state.loading = true
      state.loaded = false
    },
    setIsGridView: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridView = payload
    },
    setCouvettes: (state, { payload }: PayloadAction<{ items: Product[]; start?: string }>) => {
      state.brands = getCouvettesBrands(payload.items)
      state.items = payload.items

      const morePages = calculateMorePages(
        state,
        state.pagination.count,
        payload.start,
        payload.items,
      )
      state.noMorePages = !morePages
      state.pagination.start = payload.start
    },
    removeCouvettes: state => ({
      ...DEFAULT_STATE,
      pagination: {
        ...DEFAULT_STATE.pagination,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        sort: state.pagination.sort,
      },
      isComingFromFilters: state.isComingFromFilters,
    }),
    setSorting: (state, { payload }: PayloadAction<string>) => {
      state.pagination.sort = payload
    },
    resetSortBy: state => {
      state.pagination.sort = DEFAULT_STATE.pagination.sort
    },
    setIsComingFromFilters: (state, { payload }: PayloadAction<boolean>) => {
      state.isComingFromFilters = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, () => DEFAULT_STATE)
  },
})

export default slice.reducer
