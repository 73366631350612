import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components/macro'

import CatalogImage from '../../assets/images/keylook/catalog_2023FW.jpg'

import { grayScrollbar } from '../../components/CommonComponents'
import { useGetBrandCategoriesQuery } from '../../services/afa'
import {
  breakpoints,
  breakpointsCross,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
} from '../../style/theme'
import AfaKeylooksSidebarSport from './AfaKeylooksSidebarSport'
import { BrandCategorySport, ContentKeylook4K } from '../../model/afa'
import { useSearchParams } from '../../hooks/useSearchParams'
import { useSelector } from 'react-redux'
import { eventIdSelector, languageSelector } from '../../store/app/selectors'
import app_config from '../../config/app/config'

const WrapperParent = styled.div`
  position: relative;
  height: 100%;
  width: ${getFluidSizeWithFullFormula('px', 236, 500, 1366, 3840)};
  background-color: ${palette.white};
`

const Wrapper = styled.div`
  ${grayScrollbar};
  position: relative;
  height: 100%;
  width: 100%;
`

const fluidPaddingLeft = getFluidSizeWithFullFormula('px', 20, 64, 1366, 3840)

const fluidPaddingRight = getFluidSizeWithFullFormula('px', 10, 27, 1366, 3840)

const SportsWrapper = styled.div`
  padding-block: ${pxToRem(32)}rem ${pxToRem(19)}rem;
  padding-inline: ${pxToRem(20)}rem ${pxToRem(10)}rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.L}) {
    padding-block: ${getFluidSizeWithFullFormula('px', 32, 93, 1366, 3840)} ${pxToRem(19)}rem;
    padding-inline: ${fluidPaddingLeft} ${fluidPaddingRight};
  }

  @media (min-width: ${breakpoints.XL}) {
    padding-right: ${pxToRem(8)}rem;
  }
`

const Content = styled.div`
  padding-right: calc(${pxToRem(10)}rem - ${pxToRem(6)}rem);

  @media (min-width: ${breakpoints.L}) {
    padding-right: ${getFluidSizeWithFullFormula('px', 6, 38, 1366, 3840)};
  }

  @media (min-width: ${breakpoints.XL}) {
    padding-right: ${pxToRem(46)}rem;
  }
`

const CatalogImg = styled.img`
  margin-bottom: ${pxToRem(64)}rem;

  @media (max-width: ${breakpointsCross.XL.max}) {
    margin-bottom: ${getFluidSizeWithFullFormula('px', 32, 64, 1920, 3840)};
  }

  @media (max-width: ${breakpointsCross.L.max}) {
    display: none;
  }
`

const isSelectedKeyLookInSportsShown = (sports: BrandCategorySport[], keylookname: string) => {
  const keylookCodes = sports.flatMap(each => each.keylooks.map(({ code }) => code.toUpperCase()))
  return keylookCodes.includes(keylookname.toUpperCase())
}

const AfaKeylooksSidebar: React.FC = () => {
  const { brandCode } = useParams<{ sportCode: string; brandCode: string }>()

  const categoriesQuery = useGetBrandCategoriesQuery(brandCode)

  const [searchParams, setSearchParams] = useSearchParams()
  const sportsNamesString = searchParams.get('sport')
  const sportsNames = useMemo(() => sportsNamesString?.split(',') || [], [sportsNamesString])

  const sportsToRender = useMemo(() => {
    const sports = categoriesQuery.data?.sports || []
    return sports
      .slice()
      .sort((a, b) => (a.name === 'Theme' ? -1 : a.name.localeCompare(b.name)))
      .filter(({ name, keylooks }) => {
        const sportIsSelected = sportsNames.includes(name) || !sportsNames.length
        const sportHasKeyLooks = keylooks.length > 0

        return sportIsSelected && sportHasKeyLooks
      })
  }, [categoriesQuery.data?.sports, sportsNames])

  const selectedKeyLookName = searchParams.get('keylook')

  const eventId = useSelector(eventIdSelector)
  const language = useSelector(languageSelector)
  const [banner, setBanner] = useState(CatalogImage)

  useEffect(() => {
    const selectFirstKeyLookOfFirstSportShown = () => {
      const selectedKeyLookCode = sportsToRender[0]?.keylooks[0]?.code

      const searchParams = new URLSearchParams(window.location.search)

      if (selectedKeyLookCode) {
        searchParams.set('keylook', selectedKeyLookCode)
        setSearchParams(searchParams, { replace: true })
      } else {
        searchParams.delete('keylook')
        setSearchParams(searchParams, { replace: true })
      }
    }

    if (
      !selectedKeyLookName ||
      !isSelectedKeyLookInSportsShown(sportsToRender, selectedKeyLookName)
    ) {
      selectFirstKeyLookOfFirstSportShown()
    }
  }, [sportsToRender, selectedKeyLookName, setSearchParams])

  useEffect(() => {
    const getInitiativesContent = async () => {
      const content = await fetch(
        `${app_config.apiUrl}/content/keylook4K?eventId=${eventId}&language=${language}`,
      )
      const response = (await content.json()) as ContentKeylook4K[]

      if (response[0]?.items[0]) {
        const banner = response[0]?.items[0].data
        /*      const pictures = response[0].pictures.reduce((acc, { subjectTaxonomy, data }) => {
          return subjectTaxonomy[0].externalReference
            ? {
              ...acc,
              [subjectTaxonomy[0].externalReference]: data,
            }
            : acc
        }, {})*/

        setBanner(banner)
      }
    }

    getInitiativesContent()
  }, [eventId, language])

  return (
    <WrapperParent>
      <SportsWrapper>
        <Wrapper>
          <Content>
            <div>
              <CatalogImg src={banner} alt="catalog" />
              {sportsToRender.map(sport => (
                <AfaKeylooksSidebarSport key={sport.name} sport={sport} />
              ))}
            </div>
          </Content>
        </Wrapper>
      </SportsWrapper>
    </WrapperParent>
  )
}

export default AfaKeylooksSidebar
