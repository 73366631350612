import {
  breakpoints,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
} from '../../../../../style/theme'
import styled from 'styled-components/macro'
import Modal from '../../../components/Modal'

export const Section = styled.div`
  text-align: left;
  font-family: GilmerMedium;
  border-right: 2px solid ${palette.mischka};
  margin-right: 108px;
  padding-right: 108px;
  padding-bottom: 20px;
  min-height: 482px;

  &:last-child {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  @media screen and (max-width: ${breakpoints.L}) {
    border-bottom: 2px solid ${palette.mischka};
    margin-bottom: 16px;
    border-right: none;
    margin-right: 0;
    padding-right: 0;

    min-height: auto;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
  }
`

export const SectionTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${getFluidSizeWithFullFormula('px', 10, 50, 1024, 3840)};

  @media screen and (max-width: ${breakpoints.L}) {
    &:last-child {
      padding-bottom: 0;
    }
  }
`

export const SectionTitle = styled.div`
  text-transform: uppercase;
  font-size: ${getFluidSizeWithFullFormula('px', 18, 24, 1366, 3840)};
  font-family: GilmerBold;
  letter-spacing: 0.9px;
  padding-right: ${getFluidSizeWithFullFormula('px', 63, 144, 1366, 3840)};
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  color: ${palette.tangaroa};
  padding-top: 204px;
  justify-content: center;

  @media screen and (max-width: ${breakpoints.L}) {
    flex-direction: column;
    padding-top: 0;
  }
`

export const Info = styled.div`
  font-size: ${getFluidSizeWithFullFormula('px', 12, 24, 1024, 3840)};
  padding-bottom: ${getFluidSizeWithFullFormula('px', 14, 38, 1024, 3840)};
`

export const Options = styled.div<{ type?: string }>`
  font-size: ${getFluidSizeWithFullFormula('px', 16, 24, 1366, 3840)};
  display: ${({ type }) => (type === 'radio' ? 'flex' : 'block')};
  justify-content: space-between;
`

export const Option = styled.label<{ type?: string }>`
  flex-direction: ${({ type }) => (type === 'radio' ? 'column' : 'row')};
  display: flex;
  align-items: center;
  //padding-bottom: ${getFluidSizeWithFullFormula('px', 10, 38, 1366, 3840)};
  cursor: pointer;

  &:last-child {
    padding-bottom: 0;
  }

  @media screen and (max-width: ${breakpoints.L}) {
    font-family: GilmerRegular;
  }
`

export const RadioIcon = styled.div<{ checked: boolean }>`
  width: ${getFluidSizeWithFullFormula('px', 48, 56, 1366, 3840)};
  height: ${getFluidSizeWithFullFormula('px', 48, 56, 1366, 3840)};
  border: ${getFluidSizeWithFullFormula('px', 1, 2, 1366, 3840)} solid
    ${({ checked }) => (checked ? palette.extraLight : palette.mischka)};
  border-radius: 8px;
  background-color: ${({ checked }) => (checked ? palette.extraLight : palette.white)};
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: inline-block;
  }
`

export const RadioInput = styled.input`
  opacity: 0;
  display: none;
`

export const CheckboxLabel = styled.div<{ type?: string }>`
  cursor: pointer;
  display: inline-block;
  padding-left: ${({ type }) => (type === 'radio' ? '0' : '16px')};
  font-family: GilmerMedium, sans-serif;
`

export const InputCheckbox = styled.input`
  width: ${getFluidSizeWithFullFormula('px', 18, 24, 1366, 3840)};
  accent-color: ${palette.tangaroa};
`

export const ModalWithPosition = styled(Modal)`
  h2 {
    margin-bottom: ${pxToRem(18)}rem;
  }
  > div {
    bottom: 0;
    position: relative;

    @media screen and (max-width: ${breakpoints.L}) {
      max-height: 90vh;
      overflow-y: auto;
    }
  }
`
