import map from 'mout/object/map'

import { createSlice, original, PayloadAction } from '@reduxjs/toolkit'

import {
  CartFilters,
  FilterCounters,
  FiltersPageType,
  PlpFilters,
  WishlistFilters,
} from '../../model/filters'
import { resetForNewClient } from '../extraActions'
import { DEFAULT_STATE } from './defaultState'

export const slice = createSlice({
  name: 'filters',
  initialState: DEFAULT_STATE,
  reducers: {
    applySegmentFilter: state => {
      state.plp.segment.active = state.plp.segment.selected
    },
    resetFilters: (state, { payload }: PayloadAction<FiltersPageType | undefined>) => {
      const pageType = payload || state.pageType

      const actualRelease = pageType === 'plp' && state[pageType].release
      const releaseFilter = actualRelease
        ? { release: { ...actualRelease, selected: [], active: [] } }
        : {}

      Object.assign(state[pageType], {
        ...releaseFilter, // preserve release filter values if there are some or remove the key entirely (avoid `release: undefined`)
        ...DEFAULT_STATE[pageType],
      })
    },
    updateFilters: (
      state,
      {
        payload,
      }: PayloadAction<{
        filters: CartFilters | PlpFilters | WishlistFilters
        pageType?: FiltersPageType
      }>,
    ) => {
      const pageType = payload.pageType || state.pageType
      const releaseFilter =
        pageType === 'plp' && state[pageType].release
          ? { release: original(state[pageType].release) }
          : {}

      Object.assign(state[pageType], {
        ...releaseFilter, // preserve release filter values if there are some or remove the key entirely (avoid `release: undefined`)
        ...payload.filters,
      })
    },
    updateFiltersCounters: (
      state,
      {
        payload: { facets, pageType },
      }: PayloadAction<{
        facets: {
          name: keyof (PlpFilters | CartFilters | WishlistFilters)
          options: FilterCounters
        }[]
        pageType: FiltersPageType
      }>,
    ) => {
      facets
        .filter(
          facet =>
            !['priceRange', 'brand_slug'].includes(facet.name) && state[pageType][facet.name],
        )
        .forEach(facet => {
          Object.keys((state[pageType] && state[pageType][facet.name]?.counter) || {}).forEach(
            key => {
              const facetInState = state[pageType] && state[pageType][facet.name]
              if (facetInState) {
                facetInState.counter[key] = 0
              }
            },
          )

          Object.assign(
            (state[pageType] && state[pageType][facet.name]?.counter) || {},
            facet.options,
          )
        })
    },
    preselectBrandsAction: (state, { payload }: PayloadAction<string[]>) => {
      state.plp.brand_slug.active = payload
      state.plp.brand_slug.selected = payload
    },
    // Set category filter or reset it if empty
    setCategoryFilter: (state, { payload }: PayloadAction<string>) => {
      if (state.pageType === 'plp') {
        const categoryId = payload
        const newValue = categoryId ? [categoryId] : []

        state[state.pageType].category.active = newValue
        state[state.pageType].category.selected = newValue
      }
    },
    setFiltersActivePageName: (state, { payload }: PayloadAction<FiltersPageType>) => {
      state.pageType = payload
    },
    toggleBrandsBarAction: (state, { payload }: PayloadAction<boolean | undefined>) => {
      state.brandsBarOpened = payload !== undefined ? payload : !original(state)?.brandsBarOpened
    },
    _applyFilters: state => {
      const oldFilters = state[state.pageType]
      const newFilters = map(
        oldFilters,
        (filterItem: { selected: string[]; active: string[] }, key: string) =>
          key !== 'segment' && filterItem.selected && filterItem.active
            ? {
                ...filterItem,
                active: filterItem.selected,
              }
            : { ...filterItem },
      )
      state[state.pageType] = newFilters
    },
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, () => DEFAULT_STATE)
  },
})

export default slice.reducer
