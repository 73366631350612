import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import {
  seeThemOnCurrentSlideSelector,
  seeThemOnViewTypeSelector,
} from '../../../store/pdp/selectors'
import { duration, palette } from '../../../style/theme'
import PlayIcon from '../../icons/PlayIcon'

const Wrapper = styled.div`
  position: relative;
`

const Video = styled.video`
  width: 100%;
  height: 100%;
`

const PlayIconWrapper = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3rem;
  height: 3rem;
  border-radius: 2rem;
  background-color: ${palette.codGray};
  opacity: 0.8;
  color: ${palette.white};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity ${duration.shorter}ms;

  &.hidden {
    opacity: 0;
  }
`

const VtoVideo: React.FC<{ src: string; slideIndex: number }> = ({ src, slideIndex }) => {
  const viewType = useSelector(seeThemOnViewTypeSelector)
  const ref = useRef<HTMLVideoElement>(null)
  const currentSlide = useSelector(seeThemOnCurrentSlideSelector)
  const playedRef = useRef(false)
  const autoPlay = viewType === 'single' && currentSlide === slideIndex
  const [isPlaying, setIsPlaying] = useState(autoPlay)

  useEffect(() => {
    if (autoPlay) {
      !playedRef.current && ref.current?.play()
      playedRef.current = true
    } else {
      ref.current?.pause()
    }
  }, [autoPlay])

  return (
    <Wrapper
      className="render video"
      onClick={() => (isPlaying ? ref.current?.pause() : ref.current?.play())}
    >
      <Video
        data-testid="vto-video"
        muted
        preload="metadata"
        ref={ref}
        src={`${src}#t=0.001`}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
      />
      <PlayIconWrapper className={isPlaying ? 'hidden' : ''}>
        <PlayIcon />
      </PlayIconWrapper>
    </Wrapper>
  )
}

export default VtoVideo
