import { Modal } from 'antd'
import React, { useEffect, useRef } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { RouteChildrenProps, withRouter } from 'react-router'
import styled from 'styled-components'

import ActivationVideo from '../../components/ActivationVideo'
import CodeScannerModal from '../../components/CodeScanner/CodeScannerModal'
import { scrollbarCss } from '../../components/CommonComponents'
import CouvetteVideoPlayer from '../../components/CouvetteVideoPlayer'
import DiscoveryVideo from '../../components/DiscoveryVideo'
import FooterLogin from '../../components/FooterNav/FooterLogin/FooterLogin'
import FooterOrderBy from '../../components/FooterNav/FooterOrderBy/FooterOrderBy'
import ModalFiltersCheckout from '../CheckoutPage/components/ModalCheckout'
import DoorsList from '../../components/ModalDoorsList'
import ModalFilterBy from '../../components/ModalFilterBy'
import ModalProductSearch from '../../components/ModalProductSearch/ModalProductSearch'
import MonthMenu from '../../components/PlpSidebar/MonthMenu'
import { mapDispatchToProps } from '../../libs'
import { isIPadView, isPageMatches } from '../../libs/url'
import { MODAL_TRANSITION_DURATION_MS } from '../../libs/utils'
import { Brand } from '../../model/brand'
import { AssortmentViewType } from '../../model/cart'
import { AppThunk, ModalType, ModalTypePopulated, RootState } from '../../model/model'
import { closeAllModals, closeFooterModal, end_video } from '../../store/actions'
import { customerIdSelector, footerModalVisibleSelector } from '../../store/app/selectors'
import { usernameSelector } from '../../store/auth/selectors'
import { openedBrandsSelector } from '../../store/brands/selectors'
import { assortmentsViewTypeSelector } from '../../store/cart/selectors'
import { cartModeSelector } from '../../store/stars/selectors'
import {
  breakpoints,
  getFluidFontSize,
  getFluidSize,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
  spacing,
} from '../../style/theme'
import LedList from '../LedList'
import ModalPDP from '../ModalPDP'
import { useAssortmentType } from '../../hooks/useAssortmentType'
import { AssortmentType } from '../../model/customer'

const StyledModal = styled(Modal as any)`
  &.afa-login-menu-modal {
    font-size: ${getFluidFontSize('10px')};

    @media (min-width: ${breakpoints.XL}) {
      bottom: 7.5rem;
    }

    .ant-modal-title {
      * {
        font-family: GilmerBold, sans-serif;
        color: ${palette.tangaroa};
      }
    }
  }

  &.login-menu-modal {
    .ant-modal-body,
    .ant-radio-group,
    .menu-list {
      ${scrollbarCss}
    }
  }

  &.activation-video-container,
  &.discrovery-video-container {
    padding: 0;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    .ant-modal-content {
      background-color: ${palette.black};
    }

    .ant-modal-close-x {
      color: ${palette.white};
    }
  }

  &.doors-menu-container {
    &.afa {
      border-top-left-radius: ${getFluidSizeWithFullFormula('px', 10, 25, 1366, 3840)};
    }

    position: fixed;
    top: calc(100% - 78% - 5vh);
    right: 0;
    margin: 0;
    max-width: 87vw;
    max-height: 78%;
    padding: ${pxToRem(39)}rem 0 0 0;
    width: 100%;
    height: 100%;
    background-color: ${palette.white};
    border: ${pxToRem(1)}rem solid ${palette.silver};
    z-index: 1;
    border-top-left-radius: 1vw;

    @media screen and (max-width: ${breakpoints.L}) {
      top: calc(100% - 78% - 5vh);
      max-width: 87vw;
      max-height: 78%;
    }
    @media screen and (max-width: ${breakpoints.M}) {
      top: calc(100% - 80% - 5vh);
      max-height: 80%;
      max-width: 100%;
      padding: ${pxToRem(31)}rem 0;
    }

    .ant-modal-body {
      padding: 0;
    }

    .ant-btn {
      height: ${pxToRem(45)}rem;
      font-weight: bold;
      box-shadow: 0 ${pxToRem(2)}rem ${pxToRem(4)}rem 0 rgba(0, 0, 0, 0.1);
    }

    .ant-btn-primary {
      width: ${pxToRem(172)}rem;
      height: ${pxToRem(45)}rem;
    }

    .ant-modal-content {
      box-shadow: none;
      height: 100%;
      display: flex;
      flex-wrap: wrap;

      .ant-modal-body {
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
      }
    }

    .ant-modal-close {
      display: none;
    }

    .ant-checkbox-group {
      display: block;
    }
  }

  &.doors-menu-checkboxes {
    display: flex;
    flex-direction: column;
    padding: 0.5vh;

    .ant-checkbox-wrapper {
      padding: 0.5vh;
      margin: 0;
    }
  }

  .ant-modal-close {
    z-index: 1000;
  }
`

const LoginMenuModalHeadStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  padding-top: ${getFluidSize('0.8rem')};
`
const ModalTitleStyled = styled.span`
  color: ${palette.biscay};
  font-size: ${getFluidFontSize('1.2rem')};
  font-weight: bold;
  padding-right: ${getFluidSize('1.8rem')};
`

const ModalInfoStyled = styled.div`
  max-width: 15rem;
  max-width: max(20vw, 15rem);
  font-size: ${getFluidFontSize('0.8rem')};
  color: ${palette.biscay};
  font-weight: bold;
  text-align: right;
`

const ModalInfoCustomerNameStyled = styled.div`
  min-width: 150px;
  word-wrap: break-word;

  @media (min-width: ${breakpoints.XL}) {
    min-width: 300px;
  }
`

const loginModalType = ({
  userName,
  customerId,
  className,
  t,
}: {
  userName: string
  customerId: string
  className: string
  t: (field: string) => string
}) => ({
  item: FooterLogin,
  props: {
    title: (
      <LoginMenuModalHeadStyled>
        <ModalTitleStyled>{t('FooterNav.menu_title')}</ModalTitleStyled>
        <ModalInfoStyled>
          <ModalInfoCustomerNameStyled>{userName}</ModalInfoCustomerNameStyled>
          <div>ID{customerId}</div>
        </ModalInfoStyled>
      </LoginMenuModalHeadStyled>
    ),
    closable: true,
    destroyOnClose: true,
    className,
    width: 'auto',
    footer: null,
  },
})

const getModalTitle = ({
  modalVisible,
  userName,
  customerId,
  selectedBrands,
  end_video,
  closeFooterModal,
  t,
  assortment,
  category,
  isStars,
}: {
  modalVisible: ModalTypePopulated
  userName: string
  customerId: string
  selectedBrands: Brand[]
  end_video: () => AppThunk
  closeFooterModal: (flag: boolean) => void
  t: TFunction
  assortment: AssortmentType
  category?: AssortmentViewType
  isStars?: boolean
}) => {
  const ipadOnlyContentModalProps = {
    width: '100%',
    footer: null,
    destroyOnClose: true,
    bodyStyle: { paddingTop: '6vh' },
    maskStyle: { zIndex: 1015 },
    zIndex: 1020,
  }

  const modalTypes = {
    sort: {
      item: FooterOrderBy,
      props: {
        className: 'order-by-modal device-table',
        closable: true,
        footer: null,
        title: isPageMatches('cart')
          ? t('FooterNav.orderCart_button')
          : isPageMatches('wishlist')
          ? t('FooterNav.orderWishlist_button')
          : t('FooterNav.orderBy_button'),
        width: 'auto',
      },
    },

    filters: {
      item: ModalFilterBy,
      props: {
        className: 'filter-by-modal device-table',
        closable: true,
        footer: null,
        style: {
          top: 'auto',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        },
        destroyOnClose: true,
        title: null,
        width: '90%',
      },
    },

    filterByMonth: {
      item: MonthMenu,
      props: {
        className: 'order-by-modal filter-by-month-cart device-table',
        title: null,
        closable: false,
        footer: null,
        width: '16em',
        style: {
          left: '22vw',
        },
      },
    },

    filtersCheckout: {
      item: ModalFiltersCheckout,
      props: {
        className: 'filter-checkout-modal device-table',
        closable: true,
        footer: null,
        style: {
          top: '48vh',
          left: '5vw',
          height: '50%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        },
        destroyOnClose: true,
        title: null,
        width: '80%',
      },
    },

    afaLogin: loginModalType({
      userName,
      customerId,
      className: 'login-menu-modal afa-login-menu-modal',
      t,
    }),

    login: loginModalType({ userName, customerId, className: 'login-menu-modal', t }),

    doors: {
      item: DoorsList,
      props: {
        className: `doors-menu-container ${assortment}`,
        closable: true,
        footer: null,
        destroyOnClose: true,
        width: isIPadView() ? '93vw' : '86.6%',
      },
    },

    activationVideo: {
      item: ActivationVideo,
      props: {
        ...ipadOnlyContentModalProps,
        className: 'activation-video-container',
      },
      modalContentProps: selectedBrands[0]
        ? {
            brandCode: selectedBrands[0].code,
            media: selectedBrands[0].act_wall,
            videoPlayedCallback: closeFooterModal,
          }
        : {},
    },

    discoveryVideo: {
      item: DiscoveryVideo,
      props: {
        ...ipadOnlyContentModalProps,
        className: 'discrovery-video-container',
      },
      modalContentProps: selectedBrands[0]
        ? {
            brandCode: selectedBrands[0].code,
            media: selectedBrands[0].discovery_wall,
            videoPlayedCallback: closeFooterModal,
          }
        : {},
    },

    advAndVideoContent: {
      item: CouvetteVideoPlayer,
      props: {
        ...ipadOnlyContentModalProps,
        onCancel: () => {
          closeFooterModal(false)
          setTimeout(end_video, MODAL_TRANSITION_DURATION_MS)
        },
        wrapClassName: 'modal-icon-prop',
      },
    },

    planogram: {
      item: LedList,
      modalContentProps: category,
      props: {
        ...ipadOnlyContentModalProps,
        wrapClassName: isStars ? 'modal-planogram-stars' : 'modal-planogram',
        bodyStyle: { padding: 0 },
      },
    },

    codeScanner: {
      item: CodeScannerModal,
      props: {
        footer: null,
        title: t('Header.frame_the_barcode'),
        destroyOnClose: true,
        width: `${pxToRem(spacing(56))}rem`,
        className: 'code-scanner-modal',
        bodyStyle: { paddingTop: `${pxToRem(spacing(5.625))}rem` },
        centered: true,
        zIndex: 1010,
      },
    },
  }

  return modalTypes[modalVisible]
}

type OwnProps = Record<string, unknown>

type ConnectedProps = {
  actions: {
    closeAllModals: typeof closeAllModals
    closeFooterModal: typeof closeFooterModal
    end_video: typeof end_video
  }
  customerId: string
  modalVisible: ModalType
  userName: string
  selectedBrands: Brand[]
  category?: AssortmentViewType
}

type Props = OwnProps & ConnectedProps & RouteChildrenProps

const ModalContainer: React.FC<Props> = ({
  selectedBrands,
  customerId,
  history,
  actions: { closeAllModals, closeFooterModal, end_video },
  modalVisible,
  userName,
  category,
}) => {
  const { t } = useTranslation()

  const isStars = useSelector(cartModeSelector) === 'stars'

  useEffect(() => {
    // Close all modals on route change
    const unlisten = history.listen(() => closeAllModals())
    return unlisten
  }, [closeAllModals, history])

  const assortment = useAssortmentType()

  const modalType = useRef<{
    item: React.ComponentType<any>
    props: Record<string, any>
    modalContentProps?: Record<string, any>
  }>()

  if (modalVisible) {
    modalType.current = getModalTitle({
      modalVisible,
      userName,
      customerId,
      selectedBrands,
      t,
      end_video,
      closeFooterModal,
      assortment,
      category,
      isStars,
    })
  }

  const [ModalTypeItem, modalProps, modalContentProps] = modalType.current
    ? [modalType.current.item, modalType.current.props, modalType.current.modalContentProps]
    : [null, {}, {}]

  return (
    <React.Fragment>
      <ModalProductSearch />
      <ModalPDP />
      <StyledModal visible={!!modalVisible} onCancel={() => closeFooterModal()} {...modalProps}>
        {ModalTypeItem !== null && <ModalTypeItem {...modalContentProps} />}
      </StyledModal>
    </React.Fragment>
  )
}

const mapStateToProps = (state: RootState): Omit<ConnectedProps, 'actions'> => ({
  customerId: customerIdSelector(state),
  modalVisible: footerModalVisibleSelector(state),
  userName: usernameSelector(state),
  selectedBrands: openedBrandsSelector(state),
  category: assortmentsViewTypeSelector(state),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps())(ModalContainer),
) as React.ComponentType<OwnProps>
