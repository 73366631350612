import styled from 'styled-components'
import { getFluidFontSize, getFluidSizeWithFullFormula as gF, palette } from '../../style/theme'
import { transparentHorizontalScrollbarCss } from '../CommonComponents'

export const CarouselContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const Carousel = styled.ul`
  width: 100%;
  height: 100%;
  position: relative;
`

export const CardsWrapper = styled.div`
  height: 100%;
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  gap: 0;
  background-color: transparent;
  position: relative;
  z-index: 1;

  ${transparentHorizontalScrollbarCss};
  &::-webkit-scrollbar {
    height: ${gF('px', 6, 12, 1366, 3840)};
  }
`

export const Card = styled.li`
  width: 18vmin;
  height: 100%;
  flex-shrink: 0;
  user-select: none;
  cursor: pointer;
  background-color: white;
  border: ${gF('px', 6, 12, 1366, 3840)} solid white;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.5s;

  &.selected {
    border-color: ${palette.anakiwa};
  }
`

export const VmImage = styled.img`
  height: 65%;
  width: auto;
`

export const VmProductCode = styled.h4`
  font-size: ${getFluidFontSize('0.7rem')};
  color: ${palette.congressBlue};
`

export const VmProductName = styled.h5`
  font-size: ${getFluidFontSize('0.5rem')};
  color: ${palette.congressBlue};
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`

export const CarouselBottom = styled.div`
  position: absolute;
  bottom: ${gF('px', 3, 6, 1366, 3840)};
  left: 0;
  pointer-events: none;
  width: 100%;
  height: ${gF('px', 3, 6, 1366, 3840)};
  background-color: ${palette.dodgerBlue};
  z-index: 0;
`
