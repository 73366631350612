import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import whiteboardActions from '../../../../../store/whiteboard/actions'
import {
  convertPercentageToPx,
  convertPxToNumber,
  convertPxToPercentage,
} from '../../../../../libs/whiteboard'
import { DraggableData, DraggableEvent } from 'react-draggable'
import {
  ItemType,
  WhiteboardItemType,
  WhiteboardProductType,
} from '../../../../../model/whiteboard'
import { getSelectedItems } from '../../../../../store/whiteboard/selectors'
import { Rnd } from 'react-rnd'
import { ResizeDirection } from 're-resizable'

const pxRegex = /[0-9]+(\.[0-9]+)?(px)/g
const useDraggable = (
  item: WhiteboardItemType | WhiteboardProductType,
  type: ItemType,
  x: number,
  y: number,
  ref: React.MutableRefObject<Rnd | null>,
) => {
  const [drag, setDrag] = useState(false)
  const [mouseMove, setMouseMove] = useState(false)
  const dispatch = useDispatch()
  const selectedItems = useSelector(getSelectedItems)

  const board = document.getElementById('board') as HTMLElement
  const { width, height, left, top, right, bottom } = board?.getBoundingClientRect() || {}

  const selectedItemsArray = Object.entries(selectedItems).flatMap(([itemType, values]) =>
    values.map(value => ({ itemType, selectedElement: document.getElementById(value.id) })),
  ) as { itemType: ItemType; selectedElement: HTMLElement }[]

  const handleResizeStart = () => {
    const draggableItem = ref.current?.resizableElement.current as HTMLElement

    const itemRect = draggableItem.getBoundingClientRect()
    draggableItem.setAttribute('starting-width', `${itemRect.width}`)
    draggableItem.setAttribute('starting-height', `${itemRect.height}`)

    if (item.type === 'texts') {
      const textArea = draggableItem.getElementsByTagName('textarea')[0] as HTMLElement
      const fontSize = Number(getComputedStyle(textArea).fontSize.replace('px', ''))
      draggableItem.setAttribute('starting-font-size', `${fontSize}`)
    }
  }

  const handleClick = (forceToggle?: boolean): void => {
    setMouseMove(false)

    if (drag) {
      setDrag(false)
    }
    if (!drag || forceToggle) {
      dispatch(whiteboardActions.toggleSelectedItem({ type, item }))
    }
  }

  const handleResize = (dir: ResizeDirection) => {
    const draggableItem = ref.current?.resizableElement.current as HTMLElement
    const startingWidth = Number(draggableItem.getAttribute('starting-width'))
    const itemWidth = draggableItem.getBoundingClientRect().width
    const ratio = itemWidth / startingWidth

    selectedItemsArray
      //.filter(item => item.itemType !== 'texts')
      .forEach(({ selectedElement, itemType }) => {
        const el = document.getElementById(selectedElement.id) as HTMLElement
        if (selectedElement.id !== item.id) {
          const elRect = el.getBoundingClientRect()
          const startingWidth = Number(el.getAttribute('starting-width'))
          const startingHeight = Number(el.getAttribute('starting-height'))
          const startingY = Number(el.getAttribute('starting-y'))
          const startingX = Number(el.getAttribute('starting-x'))
          const newWidth = startingWidth * ratio
          const newHeight = startingHeight * ratio
          const deltaX = newWidth - startingWidth
          const deltaY = newHeight - startingHeight
          const [transformX, transformY] = el.style.transform.match(pxRegex) || []

          if (
            newWidth + elRect.left <= right &&
            elRect.left >= left &&
            newHeight + elRect.top <= bottom &&
            elRect.top >= top
          ) {
            if (itemType === 'texts') {
              const textArea = el.getElementsByTagName('textarea')[0] as HTMLElement
              const startingFont = Number(el.getAttribute('starting-font-size'))

              const fontSize = startingFont * ratio
              const padding = fontSize / 1.5
              textArea.style.fontSize = `${fontSize}px`
              textArea.style.height = '100%'
              textArea.style.padding = `${padding}px`
            }

            el.style.width = `${newWidth}px`
            el.style.height = `${newHeight}px`

            switch (dir) {
              case 'topRight': {
                el.style.transform = `translate(${transformX}, ${startingY - deltaY}px)`
                break
              }
              case 'topLeft': {
                el.style.transform = `translate(${startingX - deltaX}px, ${startingY - deltaY}px)`
                break
              }
              case 'bottomLeft': {
                el.style.transform = `translate(${startingX - deltaX}px, ${transformY})`
                break
              }
            }
          }
        } else if (itemType === 'texts') {
          const textArea = el.getElementsByTagName('textarea')[0] as HTMLElement
          const startingFont = Number(el.getAttribute('starting-font-size'))

          const fontSize = startingFont * ratio
          const padding = fontSize / 1.5
          textArea.style.fontSize = `${fontSize}px`
          textArea.style.height = '100%'
          textArea.style.padding = `${padding}px`
        }
      })
  }

  const handleResizeStop = () => {
    const itemsToUpdate = selectedItemsArray
      //.filter(item => item.itemType !== 'texts')
      .map(({ selectedElement, itemType }) => {
        const el = document.getElementById(selectedElement.id) as HTMLElement
        const elRect = el.getBoundingClientRect()
        const [transformX, transformY] = el.style.transform.match(pxRegex) || []

        el.setAttribute('starting-width', `${elRect.width}`)
        el.setAttribute('starting-height', `${elRect.height}`)
        el.setAttribute('starting-x', `${convertPxToNumber(transformX)}`)
        el.setAttribute('starting-y', `${convertPxToNumber(transformY)}`)

        const item: {
          itemId: string
          width: number
          height: number
          x: number
          y: number
          type: ItemType
          fontSize?: number
        } = {
          itemId: selectedElement.id,
          width: convertPxToPercentage(elRect.width),
          height: convertPxToPercentage(elRect.height),
          x: convertPxToPercentage(transformX),
          y: convertPxToPercentage(transformY),
          type: itemType,
        }

        if (item.type === 'texts') {
          const textArea = el.getElementsByTagName('textarea')[0]
          const fontSize = Number(textArea.style.fontSize.replace('px', ''))
          item.fontSize = convertPxToPercentage(fontSize)
        }

        return item
      })

    dispatch(whiteboardActions.setSize(itemsToUpdate))
  }

  const handleDragStop = () => {
    const itemToUpdate = selectedItemsArray.map(({ selectedElement, itemType }) => {
      const el = document.getElementById(selectedElement?.id) as HTMLElement
      const [transformX, transformY] = el?.style.transform.match(pxRegex) || []

      el.setAttribute('starting-x', `${convertPxToNumber(transformX)}`)
      el.setAttribute('starting-y', `${convertPxToNumber(transformY)}`)

      return {
        itemId: selectedElement.id,
        x: convertPxToPercentage(transformX),
        y: convertPxToPercentage(transformY),
        type: itemType,
      }
    })

    dispatch(whiteboardActions.setPosition(itemToUpdate))
  }

  const handleMouseMove = () => {
    if (!mouseMove && drag) {
      setMouseMove(true)
    }
  }

  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    if (!drag) {
      setDrag(true)
    }

    const deltaX = data.x - convertPercentageToPx(x)
    const deltaY = data.y - convertPercentageToPx(y)

    selectedItemsArray.forEach(({ selectedElement }) => {
      if (selectedElement.id !== ref?.current?.resizableElement.current?.id) {
        const startingX = Number(selectedElement.getAttribute('starting-x'))
        const startingY = Number(selectedElement.getAttribute('starting-y'))
        const el = document.getElementById(selectedElement.id) as HTMLElement
        const elRect = el.getBoundingClientRect()
        const newX = startingX + deltaX
        const newY = startingY + deltaY

        const translateX = newX < 0 ? 0 : newX > width - elRect.width ? width - elRect.width : newX
        const translateY =
          newY < 0 ? 0 : newY > height - elRect.height ? height - elRect.height : newY

        selectedElement.style.transform = `translate(${translateX}px, ${translateY}px)`
      }
    })
  }

  return {
    handleClick,
    handleMouseMove,
    handleDragStop,
    handleResizeStop,
    handleDrag,
    handleResizeStart,
    handleResize,
  }
}

export default useDraggable
