import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RouteChildrenProps, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'

import DigitalEventsFooter from '../../components/DigitalEventsFooter/DigitalEventsFooter'
import DigitalEventsHeader from '../../components/DigitalEventsHeader/DigitalEventsHeader'
import { brandSelector } from '../../store/brands/selectors'
import { getSingleBrandPageContents } from '../../store/digitalEvents/actions'
import { categoriesPageDEContentsSelector } from '../../store/digitalEvents/selectors'
import {
  BoxTitle,
  mainContentPadding,
  PageWrapper,
  ScrollContainer,
} from '../../style/DigitalEventsCommonComponents'
import { breakpointsCross, DigitalEventsBoxBorderRadius, pxToRem, spacing } from '../../style/theme'
import CategoryLink from './CategoryLink'
import JoinAppointmentBox from './JoinAppointmentBox'
import NewCollectionBox from './NewCollectionBox'

const Content = styled.main`
  min-height: 400px;
  display: grid;
  gap: ${pxToRem(spacing(2))}rem;
  grid-template-columns: 3fr 2fr;
  @media (min-width: ${breakpointsCross.M.min}) {
    gap: ${pxToRem(spacing(4))}rem;
  }
  @media (min-width: ${breakpointsCross.L.min}) {
    grid-template-columns: 4fr 2fr;
    gap: ${pxToRem(spacing(12))}rem;
  }
  flex: 1;
  ${mainContentPadding}
  overflow: hidden;
`

const LeftCol = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Title = styled(BoxTitle)`
  margin-bottom: ${pxToRem(spacing(2))}rem;
`

const RightCol = styled.div`
  display: grid;
  gap: ${pxToRem(spacing(5.5))}rem;
  grid-template-rows: min-content auto;
  overflow: hidden;
`

const JoinAppointment = styled.div`
  display: flex;
  flex-direction: column;
`

const Catalogue = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const CatalogueContent = styled(Swiper)<{
  isTransition: boolean
}>`
  flex: 1;
  width: 100%;

  ${({ isTransition }) =>
    !isTransition &&
    `
    .swiper-slide {
      border-radius: ${DigitalEventsBoxBorderRadius};
      backdrop-filter: blur(30px);
      box-shadow: 20px 21px 30px 0 rgba(107, 89, 114, 0.05);
      background-origin: border-box;
      background-clip: content-box, border-box;
      height: min-content;
    }
  `}

  ${({ isTransition }) =>
    isTransition &&
    `
    .swiper-wrapper {
      border-radius: ${DigitalEventsBoxBorderRadius};
      backdrop-filter: blur(30px) !important;
      box-shadow: 20px 21px 30px 0 rgba(107, 89, 114, 0.05) !important;
      background-origin: border-box !important;
      background-clip: content-box, border-box !important;
      height: min-content !important;
    }
  `}
`

const DigitalEventsCategoriesPage: React.FC<RouteChildrenProps> = ({ history }) => {
  const { brandSlug } = useParams<{ brandSlug: string }>()
  const brand = useSelector(brandSelector(brandSlug))
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const brandCode = brand?.code
  const contents = useSelector(categoriesPageDEContentsSelector(brandCode))
  const background = contents?.background
  const [transition, setTransition] = useState(false)

  useEffect(() => {
    if (!background && brandCode) {
      dispatch(getSingleBrandPageContents(brandCode))
    }
  }, [dispatch, t, background, brandCode])

  return (
    <PageWrapper background={background}>
      <ScrollContainer>
        <DigitalEventsHeader />
        <Content>
          <LeftCol>
            <Title>{t('CollectionsPage.newCollection')}</Title>
            <NewCollectionBox brandSlug={brandSlug} history={history} />
          </LeftCol>
          <RightCol>
            <JoinAppointment>
              <Title>{t('CollectionsPage.joinAppointment')}</Title>
              <JoinAppointmentBox brandCode={brandCode || ''} />
            </JoinAppointment>
            <Catalogue>
              <Title>{t('CollectionsPage.catalogue')}</Title>
              <CatalogueContent
                autoHeight={true}
                spaceBetween={5}
                slidesPerView="auto"
                freeMode={true}
                direction="vertical"
                onTransitionStart={() => {
                  setTransition(true)
                }}
                onTransitionEnd={() => {
                  setTransition(false)
                }}
                isTransition={transition}
              >
                {brand?.categoriesAvailable.sun && (
                  <SwiperSlide>
                    <CategoryLink
                      label={t('CollectionsPage.item_sun')}
                      slug={brand.slug}
                      category="sun"
                    />
                  </SwiperSlide>
                )}
                {brand?.categoriesAvailable.optical && (
                  <SwiperSlide>
                    <CategoryLink
                      label={t('CollectionsPage.item_optical')}
                      slug={brand.slug}
                      category="optical"
                    />
                  </SwiperSlide>
                )}
                {brand?.isJunior &&
                  brand?.categoriesAvailable.sun &&
                  brand?.categoriesAvailable.sunJunior && (
                    <SwiperSlide>
                      <CategoryLink
                        label={`${brand.juniorBrandDefinition.toLowerCase()} ${t(
                          'CollectionsPage.item_sun',
                        )}`}
                        slug={brand.slug}
                        category="sun"
                        isJunior={true}
                      />
                    </SwiperSlide>
                  )}
                {brand?.isJunior &&
                  brand?.categoriesAvailable.optical &&
                  brand?.categoriesAvailable.opticalJunior && (
                    <SwiperSlide>
                      <CategoryLink
                        label={`${brand.juniorBrandDefinition.toLowerCase()} ${t(
                          'CollectionsPage.item_optical',
                        )}`}
                        slug={brand.slug}
                        category="optical"
                        isJunior={true}
                      />
                    </SwiperSlide>
                  )}
                {brand?.categoriesAvailable.electronic && (
                  <SwiperSlide>
                    <CategoryLink
                      label={t('CollectionsPage.item_electronic')}
                      slug={brand.slug}
                      category="electronic"
                    />
                  </SwiperSlide>
                )}
              </CatalogueContent>
            </Catalogue>
          </RightCol>
        </Content>
        <DigitalEventsFooter />
      </ScrollContainer>
    </PageWrapper>
  )
}

export default DigitalEventsCategoriesPage
