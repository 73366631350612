import { CartProduct } from '../model/cart'
import {
  CartFilters,
  FilterCounters,
  FilterData,
  PlpFilters,
  WishlistFilters,
} from '../model/filters'
import { Product } from '../model/product'
import { CheckoutFilters } from '../model/checkout'

const facetsWorker = new Worker('./calculate-cart-facets.worker', { type: 'module' })

export function requestCalculateFacetsFromCart(
  cartProducts: CartProduct[],
  cartProductsDetails: Record<string, Product>,
  filters: FilterData | CheckoutFilters,
  releases?: string[],
) {
  return new Promise<
    {
      name: keyof (PlpFilters | CartFilters | WishlistFilters | CheckoutFilters)
      options: FilterCounters
    }[]
  >(res => {
    let requestId: string | undefined = undefined
    const cb = (e: {
      data: {
        requestId: string
        response: {
          name: keyof (PlpFilters | CartFilters | WishlistFilters | CheckoutFilters)
          options: FilterCounters
        }[]
      }
    }) => {
      if (e.data.requestId === requestId) {
        facetsWorker.removeEventListener('message', cb)
        res(e.data.response)
      }
    }
    facetsWorker.addEventListener('message', cb)
    requestId = (Date.now() + Math.random()).toString()
    facetsWorker.postMessage({ cartProducts, cartProductsDetails, filters, releases, requestId })
  })
}
