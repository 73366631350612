import styled from 'styled-components'
import { getFluidSizeWithFullFormula as gF, palette } from '../../../../../style/theme'

export const Search = styled.div`
  display: flex;
  align-items: center;
  color: ${palette.tangaroa};
  cursor: pointer;
  position: relative;

  i {
    font-size: ${gF('px', 14, 28, 1366, 3840)};
    margin-right: ${gF('px', 2.5, 10, 1366, 3840)};
  }

  input {
    font-family: GilmerMedium, sans-serif;
    font-size: ${gF('px', 12, 28, 1366, 3840)};
    background-color: transparent;
    border: none;
    outline: none;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${palette.tangaroa};
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${palette.tangaroa};
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${palette.tangaroa};
    }
  }
`
