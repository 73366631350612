import { add, differenceInDays, format, isEqual } from 'date-fns'
import { debounce, times } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { GlassBox } from '../../../style/DigitalEventsCommonComponents'
import ChevronDownIcon from '../../../components/icons/ChevronDownIcon'
import ChevronUpIcon from '../../../components/icons/ChevronUpIcon'

const ContainerSlider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  color: whitesmoke;
  overflow: hidden;
`
const Month = styled.div`
  font-size: 18px;
`

const GlassButton = styled(GlassBox)`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  border: 0;
  &&::before {
    border-radius: 20px;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.1)
    );
  }
`

const StyledDay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: lighter;
`

type DateProps = {
  onDayChange: (delta: number) => void
  date: Date
}

type Props = {
  onDayChange: (selectedDate: Date) => any
  selectDate: Date
}

type DateOfMonthProps = {
  distanceFromToday: number
  date: Date
}

const DayOfMonth = ({ date, distanceFromToday }: DateOfMonthProps) => {
  const opacity = 1 - distanceFromToday * 0.3
  const fontSize = 72 - distanceFromToday * 10
  return <StyledDay style={{ opacity, fontSize }}>{format(date, 'dd')}</StyledDay>
}

const SelectedDay = ({ date, onDayChange }: DateProps) => {
  const padding = '1.5rem 0'
  return (
    <StyledDay style={{ padding }}>
      <GlassButton onClick={() => onDayChange(1)}>
        <ChevronUpIcon color={'white'} />
      </GlassButton>
      <DayOfMonth date={date} distanceFromToday={0} />
      <Month>{format(date, 'MMM')}</Month>
      <GlassButton onClick={() => onDayChange(-1)}>
        <ChevronDownIcon color={'white'} />
      </GlassButton>
    </StyledDay>
  )
}

const CalendarSlider = ({ onDayChange, selectDate }: Props) => {
  const [selectedDate, setDate] = useState<Date>(new Date())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cb = useCallback(debounce(onDayChange, 1e3), [])

  useEffect(() => {
    setDate(selectDate)
  }, [selectDate])

  const changeDay = (delta: number) => {
    const newDay = add(selectedDate, { days: delta })
    cb(newDay)
    setDate(newDay)
  }

  //getting 7 days buffer according to current date in calendar
  const days = times(7, (index: number) => {
    const currentDay = 3 - index
    return add(selectedDate, { days: currentDay })
  })

  return (
    <ContainerSlider>
      {days.map(d => {
        // the greater the distance the more is opaque
        const distanceFromToday = Math.abs(differenceInDays(selectedDate, d))
        const isSelectedDate = isEqual(d, selectedDate)
        const key = d.toDateString()
        return isSelectedDate ? (
          <SelectedDay key={key} onDayChange={changeDay} date={d} />
        ) : (
          <DayOfMonth key={key} date={d} distanceFromToday={distanceFromToday} />
        )
      })}
    </ContainerSlider>
  )
}

export default CalendarSlider
