import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { breakpoints, palette, pxToRem } from '../../style/theme'
import RCIcon from '../../components/UI/RCIcon'
import { getFluidSizeWithFullFormula as gF } from '../../style/theme'
import { useSelector } from 'react-redux'
import { initialNotificationCountSelector } from '../../store/afaCartNotifications/selectors'

const Wrapper = styled.div`
  background-color: ${palette.extraLight};
  border-radius: 8px;
  color: ${palette.tangaroa};
  font-size: ${pxToRem(14)}rem;
  margin: 0 ${pxToRem(20)}rem;
  padding: ${pxToRem(16)}rem ${pxToRem(24)}rem;
  position: relative;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${pxToRem(4)}rem;
`

const Title = styled.div`
  margin-left: ${pxToRem(8)}rem;
  font-size: ${gF('px', 16, 32, 1366, 3840)};
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: ${palette.santasGray};
    font-family: GilmerMedium, sans-serif;
    font-size: ${gF('px', 14, 24, 1366, 3840)};
    line-height: ${gF('px', 14, 24, 1366, 3840)};
    font-weight: 500;
  }
`

const Body = styled.div`
  font-size: ${gF('px', 14, 24, 1366, 3840)};
  white-space: pre-line;
`

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
  padding-left: ${pxToRem(120)}rem;

  @media (max-width: ${breakpoints.XS}) {
    position: static;
  }
`

const ActionButton = styled.div`
  cursor: pointer;
  text-transform: capitalize;
  font-weight: bold;
  font-size: ${gF('px', 12, 24, 1366, 3840)};
`

const IconStyled = styled(RCIcon)`
  width: ${gF('px', 16, 32, 1366, 3840)};
  height: ${gF('px', 16, 32, 1366, 3840)};
`

const Content = styled.div<{ bodyAndBtnGap?: number }>`
  display: grid;
  grid-template-columns: auto max-content;
  gap: ${props => props.bodyAndBtnGap ?? 120}px;
`

type Action = {
  label: string
  onClick: () => void
}

type Props = {
  actions: Action[]
  title: string
  body: string
  notificationCounter: number
  bodyAndBtnGap?: number
  children?: ReactElement<any, any>
}

const AfaCartNotification: React.FC<Props> = ({
  actions,
  title,
  body,
  notificationCounter,
  bodyAndBtnGap,
  children,
}) => {
  const initialNotificationCount = useSelector(initialNotificationCountSelector)

  return (
    <Wrapper>
      <Header>
        <IconStyled type="chat_bubble_outline" />
        <Title>
          {title}
          {initialNotificationCount > 0 && (
            <span>
              {notificationCounter}/{initialNotificationCount}
            </span>
          )}
        </Title>
      </Header>
      <Content bodyAndBtnGap={bodyAndBtnGap}>
        <Body>{body}</Body>
        {children}
        <ActionsWrapper>
          {actions.map(action => {
            return (
              <ActionButton onClick={action.onClick} key={action.label}>
                {action.label}
              </ActionButton>
            )
          })}
        </ActionsWrapper>
      </Content>
    </Wrapper>
  )
}

export default AfaCartNotification
