import React from 'react'
import styled from 'styled-components/macro'

import app_config from '../../../config/app/config'

const Arrow = styled.svg`
  content: '';
  display: inline-block;
  height: 15px;
  width: 15px;
  transition: transform 0.2s;
  &.up {
    transform: rotate(-90deg);
  }
  &.down {
    transform: rotate(90deg);
  }
  &.left {
    transform: rotate(-180deg);
  }
  svg {
    width: 100%;
    height: 100%;
  }

  &.disabled {
    pointer-events: none;
    filter: opacity(0.3);
    cursor: initial;
  }
`

const Icon = styled.img<{ size?: string }>`
  width: ${props => props.size ?? '1em'};
  height: ${props => props.size ?? '1em'};

  &.disabled {
    pointer-events: none;
    filter: opacity(0.3);
    cursor: initial;
  }
`

type Props = {
  type: string
  arrow?: boolean
  iconClass?: string
  isGray?: boolean
  replaceDefaultClass?: boolean
  onClick?: (event: React.MouseEvent) => void
  size?: string
}

const RCIcon: React.FC<Props> = ({
  type,
  arrow,
  iconClass = '',
  isGray,
  replaceDefaultClass,
  onClick,
  size,
}) =>
  arrow ? (
    <Arrow
      viewBox="0 0 20 30"
      xmlns="http://www.w3.org/2000/svg"
      className={`arrow-icon ${type} ${iconClass}`}
    >
      <path d="M17.638 16.43L3.383 30.443a1.148 1.148 0 0 1-.967.483c-.403 0-.765-.16-1.087-.483l-.846-.846A1.148 1.148 0 0 1 0 28.631c0-.403.161-.765.483-1.087l12.322-12.08L.483 3.382a1.767 1.767 0 0 1-.362-1.088c0-.402.12-.725.362-.966L1.33.362A1.767 1.767 0 0 1 2.416 0c.403 0 .725.12.967.362l14.255 14.014c.241.322.362.684.362 1.087 0 .403-.12.765-.362 1.087v-.12z" />
    </Arrow>
  ) : (
    <Icon
      alt="icon"
      className={`${replaceDefaultClass ? '' : 'anticon rc-icon'} ${iconClass}`}
      src={`${app_config.publicUrl}/assets/images/${type}${isGray ? '-gray' : ''}.svg`}
      onClick={onClick}
      size={size}
    />
  )

export default RCIcon
