import React, { SVGProps } from 'react'

const CheckIcon: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      height="44"
      width="44"
      fill="currentColor"
      {...props}
    >
      <path d="M20.3898874,0.199901786 L6.51527027,14.3673274 L1.61011261,9.35865179 C1.37792342,9.1215625 1.00144595,9.1215625 0.769207207,9.35865179 L0.208653153,9.93103571 C-0.023536036,10.168125 -0.023536036,10.5525476 0.208653153,10.7896875 L6.09484234,16.8000982 C6.32703153,17.0371875 6.70350901,17.0371875 6.93574775,16.8000982 L21.7913468,1.6309881 C22.023536,1.39389881 22.023536,1.00947619 21.7913468,0.77233631 L21.2307432,0.199901786 C20.9985541,-0.0371875 20.6220766,-0.0371875 20.3898874,0.199901786 Z"></path>
    </svg>
  )
}

export default CheckIcon
