import React from 'react'

import { UpdateCartPayload, useUpdateCart } from '../../../hooks/useUpdateCart'
import UndoNotification from './'

type Props = {
  codes: string[]
  message?: string
  payload: UpdateCartPayload
}
const UndoNotificationFastAddsCart: React.FC<Props> = ({ codes, message, payload }) => {
  const updateCart = useUpdateCart()

  return (
    <UndoNotification
      onCountdownExpired={() => {
        updateCart(payload)
      }}
      codes={codes}
      message={message || ''}
      notificationType="fastAddsToCart"
      showCountdown
    />
  )
}

export default UndoNotificationFastAddsCart
