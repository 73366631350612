import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Product } from '../../../model/product'
import { fluidFontSize } from '../../../style/theme'
import CouvetteBodyV2 from './CouvetteBody'
import CouvetteHeader from './CouvetteHeader/CouvetteHeader'

const CouvetteWrapper = styled.div`
  height: 100%;
  position: relative;
  padding: 1vh 3.5vh 0 0;
`

const CouvetteInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  font-size: ${fluidFontSize};
`

type Props = {
  couvette: Product
  showWishlist?: boolean
  hideHeader: boolean
  showBrandLogo: boolean
}

const Couvette: React.FC<Props> = ({
  hideHeader,
  couvette,
  showBrandLogo,
  showWishlist = true,
}) => {
  const [upcsRTR, setUpcsRTR] = useState<string[]>([])

  useEffect(() => {
    Object.values(couvette.mocos).forEach(moco => {
      Object.keys(moco.sizes).forEach(upc => {
        window.rtrViewerMV?.isIdAvailable({ type: 'upc', value: upc }).then((hasRTR: boolean) => {
          if (hasRTR) {
            setUpcsRTR(current => current.concat([upc]))
          }
        })
      })
    })
  }, [couvette.mocos])

  return (
    <CouvetteWrapper className="couvette-full">
      <CouvetteInner
        className={classnames({
          couvette: true,
        })}
      >
        <CouvetteHeader
          showWishlist={showWishlist}
          hideHeader={hideHeader}
          couvette={couvette}
          mocos={couvette.mocos}
          showBrandLogo={showBrandLogo}
          hasRTR={upcsRTR.length > 0}
        />
        <CouvetteBodyV2
          mocos={couvette.mocos}
          showWishlist={showWishlist}
          model={couvette}
          upcsRTR={upcsRTR}
        />
      </CouvetteInner>
    </CouvetteWrapper>
  )
}

export default Couvette
