import React, { SVGProps } from 'react'

const BrandsIcon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 14" {...props}>
    <g fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-1821.000000, -71.000000) translate(0.000000, 48.000000) translate(1812.000000, 0.000000)">
            <g fillRule="nonzero">
              <path
                d="M41.92 1.234l-.459 2.311s-.845.242-1.054.953c0 0-.265.687-.676 2.17 0 0-.917 3.748-2.445 5.17 0 0-1.681 1.803-5.189 2.037 0 0-3.153.344-5.293-.82 0 0-2.236-.89-3.194-3.607 0 0-.667-1.804-1.158-3.506 0 0-.354-1.226-1.472-1.156-1.118-.07-1.472 1.156-1.472 1.156-.491 1.702-1.159 3.506-1.159 3.506-.957 2.717-3.193 3.607-3.193 3.607-2.14 1.156-5.294.82-5.294.82-3.507-.242-5.188-2.038-5.188-2.038-1.529-1.42-2.446-5.169-2.446-5.169-.41-1.491-.675-2.17-.675-2.17-.21-.719-1.054-.953-1.054-.953L.08 1.234C.394.726 1.094.617 1.094.617 4.038.07 8.905.07 8.905.07c4.135-.015 9.412 1.172 9.412 1.172 1.56.5 2.679.46 2.679.46s1.118.032 2.679-.46c0 0 5.277-1.187 9.412-1.172 0 0 4.875 0 7.81.547.009 0 .709.101 1.023.617zm-4.167 7.48s.804-2.655.627-4.966c0 0-.072-1.663-1.681-2.147 0 0-2.036-.508-4.135-.437 0 0-3.644.132-5.044.476 0 0-2.1.406-2.944.984 0 0-1.368.632-.982 2.857 0 0 .226 2.437.91 3.943 0 0 1.085 2.686 3.571 3.303 0 0 3.081 1.125 6.17-.132 0 0 2.381-.68 3.508-3.881zM18.406 5.48c.394-2.225-.982-2.857-.982-2.857-.844-.578-2.944-.984-2.944-.984-1.4-.344-5.044-.476-5.044-.476-2.1-.07-4.135.437-4.135.437-1.609.476-1.681 2.147-1.681 2.147-.177 2.311.627 4.966.627 4.966 1.119 3.193 3.508 3.88 3.508 3.88 3.08 1.258 6.17.133 6.17.133 2.486-.609 3.572-3.303 3.572-3.303.683-1.506.909-3.943.909-3.943z"
                transform="translate(9.000000, 23.000000)"
              />
              <path
                d="M10.965 14c-.644 0-1.07-.047-1.078-.047-1.818-.125-3.146-.68-3.934-1.116-.86-.484-1.28-.93-1.295-.945-1.52-1.421-2.422-5.052-2.462-5.208-.402-1.468-.668-2.155-.676-2.163-.2-.671-.99-.906-.997-.906L.475 3.6 0 1.218l.016-.023C.346.656 1.054.547 1.086.547 3.998.008 8.865 0 8.913 0c4.095-.015 9.372 1.156 9.428 1.171 1.529.492 2.647.461 2.663.461l.008.148c-.008 0-1.15.032-2.703-.468-.048-.008-5.237-1.163-9.307-1.163h-.08c-.049 0-4.9.007-7.804.538-.008 0-.651.102-.95.555l.443 2.24c.185.063.87.336 1.062.992 0 0 .266.695.676 2.179.008.039.933 3.747 2.421 5.137.024.024 1.706 1.788 5.14 2.023.033 0 3.162.328 5.246-.805.032-.015 2.22-.913 3.153-3.568.008-.016.668-1.82 1.15-3.498 0-.016.379-1.28 1.553-1.21l-.008.148c-1.046-.062-1.384 1.054-1.392 1.101-.482 1.687-1.15 3.49-1.158 3.506-.957 2.717-3.21 3.638-3.234 3.646-1.36.734-3.137.867-4.255.867z"
                transform="translate(9.000000, 23.000000)"
              />
              <path
                d="M10.932 13.266c-1.15 0-2.228-.203-3.21-.601-.015-.008-.61-.18-1.35-.75-.677-.523-1.61-1.507-2.197-3.17-.008-.031-.812-2.694-.635-4.997 0-.016.088-1.726 1.737-2.21.024-.007 2.068-.515 4.16-.445.04 0 3.667.14 5.06.477.08.015 2.123.413 2.967.999 0 0 1.408.68 1.014 2.928 0 .016-.233 2.46-.917 3.959-.008.023-1.126 2.725-3.628 3.342 0 .007-1.287.468-3 .468zM9.042 1.234c-1.923 0-3.7.437-3.717.445C3.781 2.132 3.7 3.732 3.7 3.748c-.177 2.272.62 4.92.628 4.943.571 1.624 1.48 2.584 2.14 3.092.716.554 1.303.726 1.311.733.965.399 2.027.594 3.161.594 1.69 0 2.953-.453 2.96-.453 2.43-.601 3.516-3.233 3.532-3.256.676-1.483.901-3.896.91-3.927.377-2.148-.886-2.757-.942-2.788-.828-.57-2.904-.976-2.928-.984-1.384-.335-4.996-.476-5.036-.476-.137.008-.265.008-.394.008zM31.043 14c-1.118 0-2.904-.133-4.263-.875-.016-.007-2.269-.929-3.226-3.646-.008-.015-.676-1.82-1.158-3.506-.016-.047-.346-1.163-1.392-1.1l-.008-.149c1.166-.078 1.545 1.195 1.553 1.21.482 1.679 1.15 3.483 1.15 3.498.933 2.655 3.121 3.553 3.145 3.56 2.1 1.133 5.221.813 5.253.813 3.435-.234 5.125-2 5.14-2.015 1.497-1.397 2.422-5.106 2.43-5.137.41-1.476.676-2.179.676-2.179.193-.648.869-.921 1.054-.984l.442-2.24c-.298-.453-.941-.555-.95-.555C37.987.156 33.136.15 33.088.15h-.08c-4.071 0-9.268 1.155-9.316 1.17-1.545.493-2.687.47-2.703.47l.008-.15c.008 0 1.126.024 2.655-.46.056-.015 5.333-1.187 9.436-1.171.048 0 4.915.008 7.827.547.024.007.74.117 1.07.648l.016.023-.467 2.381-.048.016c-.008 0-.804.234-.997.906-.008.015-.274.703-.676 2.17-.04.149-.941 3.787-2.462 5.208-.016.016-1.737 1.828-5.237 2.062-.008-.008-.434.031-1.07.031z"
                transform="translate(9.000000, 23.000000)"
              />
              <path
                d="M31.068 13.266c-1.714 0-3.001-.46-3.017-.468-2.494-.61-3.612-3.319-3.62-3.342-.684-1.5-.917-3.943-.917-3.967-.394-2.249 1.013-2.928 1.022-2.936.836-.57 2.871-.976 2.96-.991 1.392-.336 5.02-.477 5.06-.477.137-.007.265-.007.402-.007 1.939 0 3.74.445 3.757.453 1.649.484 1.737 2.194 1.737 2.21.177 2.295-.627 4.965-.635 4.989-.587 1.67-1.52 2.646-2.196 3.177-.74.57-1.336.75-1.36.75-.965.406-2.043.609-3.193.609zm1.89-12.032c-.129 0-.257 0-.394.008-.032 0-3.652.14-5.028.476-.024.008-2.1.414-2.92.976-.064.031-1.327.648-.95 2.787 0 .032.234 2.444.91 3.928.008.031 1.102 2.662 3.523 3.256.024.008 1.287.46 2.969.46 1.126 0 2.188-.195 3.145-.593.016-.008.603-.18 1.32-.734.659-.515 1.568-1.468 2.14-3.1.007-.023.795-2.662.627-4.934 0-.016-.089-1.624-1.625-2.077-.008-.016-1.794-.453-3.717-.453zM1.569 2.21s.386-.008.724.062c0 0 .225.055.378.149 0 0 .233.14-.057.25 0 0-.313.14-.868.093 0 0-.483-.054-.692-.187 0 0-.314-.149-.056-.281.008.008.273-.086.57-.086zM40.262 2.21s-.386-.008-.724.062c0 0-.225.055-.378.149 0 0-.233.14.057.25 0 0 .313.14.876.093 0 0 .483-.054.692-.187 0 0 .314-.149.057-.281-.016.008-.282-.086-.58-.086zM20.851 1.632H21.851V4.896H20.851z"
                transform="translate(9.000000, 23.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default BrandsIcon
