import styled, { css } from 'styled-components/macro'
import { palette, getFluidSizeWithFullFormula as gF } from '../../../../../style/theme'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 90vh;
`

export const Title = styled.h2`
  font-family: 'Avenir', sans-serif;
  font-weight: 500;
  font-size: ${gF('px', 26, 52, 1366, 3840)};
  letter-spacing: -0.55px;
  color: ${palette.blackPearlWB};
`

export const SubTitle = styled.h3`
  font-family: GilmerRegular, sans-serif;
  font-size: ${gF('px', 20, 40, 1366, 3840)};
  color: ${palette.tangaroa};
  margin: ${gF('px', 27, 54, 1366, 3840)} 0 ${gF('px', 40, 80, 1366, 3840)};
`

export const TemplatesGrid = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-gap: ${gF('px', 40, 80, 1366, 3840)} ${gF('px', 17, 34, 1366, 3840)};
  overflow-y: auto;
  max-height: ${gF('px', 512, 1024, 1366, 3840)};
  margin-bottom: ${gF('px', 64, 128, 1366, 3840)};
`

export const SingleTemplate = styled.li``

export const TemplateImageWrapper = styled.div`
  width: ${gF('px', 203, 406, 1366, 3840)};
  height: ${gF('px', 203, 406, 1366, 3840)};
  border-radius: 10px;
  border: solid ${gF('px', 1, 2, 1366, 3840)} ${palette.platinum};
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &.selected {
    border-color: ${palette.bermudaGray};
  }

  &:hover {
    border-color: ${palette.bermudaGray};
  }
`

export const TemplateImage = styled.img`
  width: 90%;
  height: auto;
`

export const TemplateTitle = styled.h4`
  font-family: GilmerMedium, sans-serif;
  font-weight: 500;
  font-size: ${gF('px', 14, 28, 1366, 3840)};
  color: ${palette.tangaroa};
  margin-top: ${gF('px', 12, 24, 1366, 3840)};
  word-break: break-all;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: ${gF('px', 40, 80, 1366, 3840)};
`

const ButtonsCommonCss = css`
  height: ${gF('px', 56, 112, 1366, 3840)};
  border-radius: 4px;
  border: solid ${gF('px', 1, 2, 1366, 3840)} ${palette.tangaroa};
  font-size: ${gF('px', 13, 26, 1366, 3840)};
  font-family: GilmerBold, sans-serif;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  text-align: center;
  line-height: ${gF('px', 56, 112, 1366, 3840)};
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
`

export const CancelButton = styled.div`
  width: ${gF('px', 169, 338, 1366, 3840)};
  ${ButtonsCommonCss}
`

export const ConfirmButton = styled.div`
  width: ${gF('px', 205, 410, 1366, 3840)};
  color: ${palette.white};
  background-color: ${palette.tangaroa};
  ${ButtonsCommonCss}

  &.disabled {
    pointer-events: none;
    background-color: ${palette.lightGray};
    color: ${palette.santasGray};
    border-color: ${palette.santasGray};
  }
`
