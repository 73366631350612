import React, { useEffect } from 'react'
import Header from '../../../components/Header'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  breakpoints,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
  spacing,
} from '../../../style/theme'
import AfaStepper from '../components/AfaStepper'
import AfaFooter from '../../../components/AfaFooter'
import AfaSubHeader from '../components/AfaSubHeader'
import SuccessIcon from '../../../components/icons/SuccessIcon'
import { useConfirmAfaCartMutation } from '../../../services/afaCart'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Content = styled.div`
  background-color: ${palette.lightGray};
  border-radius: ${pxToRem(spacing(1))}rem;
  height: 100%;
  display: flex;
  justify-content: center;
`

const ThankYouWrapper = styled.div`
  background: ${palette.white};
  width: ${getFluidSizeWithFullFormula('px', 1085, 2776, 1366, 3840)};
  border-radius: ${getFluidSizeWithFullFormula('px', 8, 20, 1366, 3840)};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${getFluidSizeWithFullFormula('px', 295, 1045, 1366, 3840)};
  margin-top: ${getFluidSizeWithFullFormula('px', 67, 175, 1366, 3840)};
`

const ThankYouText = styled.p`
  color: ${palette.cloudBurst};
  font-size: ${getFluidSizeWithFullFormula('px', 26, 64, 1366, 3840)};
  font-weight: bold;

  @media (min-width: ${breakpoints.L}) {
    letter-spacing: 0.28px;
  }
`

const ThankYouContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: ${getFluidSizeWithFullFormula('px', 32, 160, 1366, 3840)};
`

const IconWrapper = styled.div`
  width: ${getFluidSizeWithFullFormula('px', 88, 288, 1366, 3840)};
  height: ${getFluidSizeWithFullFormula('px', 88, 288, 1366, 3840)};
`

const AfaCheckoutThankYouPage: React.FC = () => {
  const { t } = useTranslation()
  const [, confirmCartResult] = useConfirmAfaCartMutation()

  useEffect(() => {
    return () => {
      confirmCartResult.reset()
    }
  }, [confirmCartResult])

  return (
    <>
      <Wrapper>
        <Header hideButtons />
        <AfaSubHeader hideImportSwitch />
        <AfaStepper activeStep={3} completedSuccessfully />
        <Content>
          <ThankYouWrapper>
            <ThankYouContent>
              <IconWrapper>
                <SuccessIcon />
              </IconWrapper>

              <ThankYouText>{t('Checkout.Confirm.ThankYou')}</ThankYouText>
            </ThankYouContent>
          </ThankYouWrapper>
        </Content>
      </Wrapper>
      <AfaFooter centralColContent={null} />
    </>
  )
}

export default AfaCheckoutThankYouPage
