import React from 'react'
import styled from 'styled-components/macro'
import { format } from 'date-fns'
import BoldCalendarIcon from '../../components/icons/BoldCalendarIcon'

import { getFluidSizeWithFullFormula, palette } from '../../style/theme'
import { DdMmYyyyDateString } from '../../model/model'
import { convertDdMmYyyyToDate } from '../../libs/time'
import RCIcon from '../../components/UI/RCIcon'

const SizeDateWrapper = styled.div<{ $backgroundColor?: string; $border?: boolean }>`
  width: ${getFluidSizeWithFullFormula('px', 60, 150, 1366, 3840)};
  min-height: ${getFluidSizeWithFullFormula('px', 115, 364, 1366, 3840)};

/*  @media() {
    min-width: ${getFluidSizeWithFullFormula('px', 138, 138, 1366, 3840)};
  }*/

  &.dropDate {
    background-color: ${palette.extraLight};
    font-family: GilmerRegular;
  }

  &.seasonDate {
    border: 1px solid ${palette.tangaroa};
  }

  &.expiredDate {
    border: 1px solid ${palette.darkYellow};
    background-color: ${palette.paleYellow};
    color: ${palette.darkYellow};
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  border-radius: clamp(20px, ${getFluidSizeWithFullFormula('px', 20, 40, 1366, 3840)}, 40px);
`

const SizeDateText = styled.p`
  font-family: GilmerBold;
  font-size: ${getFluidSizeWithFullFormula('px', 14, 34, 1366, 3840)};
  line-height: normal;
  margin-bottom: clamp(5px, ${getFluidSizeWithFullFormula('px', 5, 12.4, 1366, 3840)}, 12.4px);
  letter-spacing: clamp(
    0.28px,
    ${getFluidSizeWithFullFormula('px', 0.28, 0.68, 1366, 3840)},
    0.68px
  );
`

const SizeYearText = styled.p`
  font-size: clamp(12px, ${getFluidSizeWithFullFormula('px', 12, 29.1, 1366, 3840)}, 29.1px);
  line-height: normal;

  letter-spacing: clamp(0.6px, ${getFluidSizeWithFullFormula('px', 0.6, 1.46, 1366, 3840)}, 1.46px);
`

const SizeDateContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-inline: 6px;
`

const BoldCalendarIconContainer = styled.div`
  width: clamp(15px, ${getFluidSizeWithFullFormula('px', 15, 58.4, 1366, 3840)}, 58.4px);
  height: clamp(16.4px, ${getFluidSizeWithFullFormula('px', 16.4, 64, 1366, 3840)}, 64px);
  margin-bottom: clamp(8.6px, ${getFluidSizeWithFullFormula('px', 8.6, 48, 1366, 3840)}, 48px);
`

const PencilIconContainer = styled.div`
  margin-bottom: clamp(8.6px, ${getFluidSizeWithFullFormula('px', 8.6, 48, 1366, 3840)}, 48px);
  img {
    font-size: clamp(17px, ${getFluidSizeWithFullFormula('px', 17, 64, 1366, 3840)}, 64px);
  }
`

const CalendarIcon = () => (
  <BoldCalendarIconContainer>
    <BoldCalendarIcon />
  </BoldCalendarIconContainer>
)

const PencilIcon = ({ className }: { className?: string }) => (
  <PencilIconContainer>
    <RCIcon type={className === 'expiredDate' ? 'icon-pencil-orange' : 'icon-pencil'} />
  </PencilIconContainer>
)

type SizeDateProps = {
  date: DdMmYyyyDateString
  className?: 'dropDate' | 'seasonDate' | 'expiredDate'
  type?: string
}

const SizeDate: React.FC<SizeDateProps> = ({ date: dateString, className, children, type }) => {
  const date = dateString && new Date(convertDdMmYyyyToDate(dateString))
  return (
    <SizeDateWrapper className={className}>
      <SizeDateContent>
        {type === 'customDelivery' ? <PencilIcon className={className} /> : <CalendarIcon />}
        {children}
        {date && (
          <>
            <SizeDateText>{format(date, 'dd/MM')}</SizeDateText>
            <SizeYearText>{format(date, 'yyyy')}</SizeYearText>
          </>
        )}
      </SizeDateContent>
    </SizeDateWrapper>
  )
}

export default SizeDate
