import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { formatMoney } from '../../../../libs'
import { AAMoco } from '../../Model/aaModel'
import { useDispatch, useSelector } from 'react-redux'
import { buildMocoCode } from '../../../../libs/productsV2'
import { useTranslation } from 'react-i18next'
import AddToCartButton from './AddToCartButton'
import { showSrPriceSelector, showWhsPriceSelector } from '../../../../store/customer/selectors'
import { brandSelectorByCode } from '../../../../store/brands/selectors'
import { getProductImageUrl } from '../../../../libs/productImages'
import { openPDP } from '../../../../store/actions'
import RCIcon from '../../../../components/UI/RCIcon'
import { palette } from '../../../../style/theme'
import { currencySelector } from '../../../../store/cart/selectors'
import { useSendAnalytics } from '../../../../services/analytics'

const Wrapper = styled.div`
  padding: 1vw;
  border-radius: 0.5vw;
  background-color: white;
  font-family: 'EuclidCircularB-Regular', sans-serif;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  & * {
    color: ${palette.swamp};
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1vw;
  line-height: 1.2;
`

const NameAndCode = styled.div`
  color: ${palette.midnight};
`

const BrandMatch = styled.div`
  display: flex;
  justify-content: space-between;
`
const Brand = styled.div`
  font-size: clamp(12px, 0.6vw, 26px);
  font-weight: 500;
  letter-spacing: -0.35px;
  margin-bottom: 0.1vw;
  padding-bottom: 0.5vw;
  text-transform: uppercase;
`

const Match = styled.div`
  font-size: clamp(12px, 0.6vw, 26px);
  font-weight: 600;
  margin-bottom: 0.1vw;
  padding-bottom: 0.5vw;
  color: ${palette.cornflowerBlueAA};
`

const Code = styled.div`
  font-size: clamp(14px, 0.8vw, 30px);
  font-weight: 500;
`

const Name = styled.div`
  font-size: clamp(12px, 0.6vw, 26px);
`

const Prices = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  white-space: nowrap;
  color: ${palette.midnight};
`

const WhsPrice = styled.div`
  font-size: max(0.7vw, 12px);
  font-weight: 600;
`

const SrpPrice = styled.div`
  font-size: max(0.6vw, 11px);
`

const Chips = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2vw;
  overflow-x: auto;
  gap: 0.25vw;
  // in Chrome version 114 scroll-x works poorly without z-index
  z-index: 0;
`

const Tags = styled.div`
  display: flex;
  gap: 0.25vw;
  margin-bottom: 1vw;
`

const Tag = styled.div`
  border: 1px solid ${palette.iron};
  border-radius: 0.5vw;
  padding: 0.4vw;
  font-size: max(0.7vw, 12px);
  line-height: 1;
  height: 1.9vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;

  &.colorRefresh {
    width: 1.5vw;
    padding: 0;
  }
`

const ColorRefreshIcon = styled.div`
  position: relative;
  width: 1em;
  height: 1em;
  background-image: conic-gradient(
    from 0.25turn,
    #e9573f,
    #f6bb42 0.14turn,
    #8cc152 0.25turn,
    #37bc9b 0.41turn,
    #4a89dc 0.56turn,
    #967adc 0.7turn,
    #d770ad 0.84turn,
    #e9573f
  );
  border-radius: 3rem;

  &::after {
    content: '';
    background-color: white;
    width: 0.5em;
    height: 0.5em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 3rem;
  }
`

const PositioningTag = styled.div`
  border-radius: 0.5vw;
  padding: 0.4vw 0.75vw;
  line-height: 1;
  color: var(--color);
  background-color: var(--background-color);
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  gap: 0.25vw;
  border: 1px solid transparent;
  font-size: max(0.75vw, 14px);
  font-weight: 500;

  &.young {
    --background-color: hsl(216deg 93% 92%);
    --color: ${palette.blue};
  }

  &.innovative {
    --background-color: hsl(299deg 79% 92%);
    --color: ${palette.barney};
  }

  &.sophisticated {
    --background-color: ${palette.softPeach};
    --color: ${palette.brownishGrey};
  }

  &.sport {
    --background-color: rgba(73, 197, 188, 0.18);
    --color: ${palette.waterGreen};
  }

  &.everyday {
    --background-color: rgba(165, 185, 65, 0.18);
    --color: #a5b941;
  }

  &.expand {
    padding: 0.25vw 0.35vw;
    --color: ${palette.brownishGrey};
    border-color: rgba(2, 31, 56, 0.1);
  }

  span {
    font-weight: 600;
    color: var(--color);
  }
`

const ImageWrapper = styled.div`
  margin-bottom: 2vw;
  aspect-ratio: 2;
`

const Img = styled.img`
  display: block;
`

const CartButtons = styled.div`
  gap: 0.5vw;
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: flex-start;
  height: 6vw;
  margin-top: auto;

  > * {
    flex-basis: calc(50% - 0.25vw);
  }
`

type Props = {
  moco: AAMoco
  settings: {
    showAllTagsToggleActive: boolean
    showMatchToggleActive: boolean
  }
}

const ProductCard: React.FC<Props> = ({ moco, settings }) => {
  const [sendAnalytics] = useSendAnalytics()

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currency = useSelector(currencySelector)
  const showSrpPrice = useSelector(showSrPriceSelector)
  const showWhsPrice = useSelector(showWhsPriceSelector)

  const [tagsExpanded, setTagsExpanded] = useState(false)

  const {
    brand: brandCode,
    modelCode,
    modelName,
    whsPrice,
    srPrice,
    positioningMatch,
    isNew,
    isAdv,
    isColorRefresh,
    colorCode,
    match,
    catalogImages,
  } = moco

  const brand = useSelector(brandSelectorByCode(brandCode))

  const sizes = Object.values(moco.sizes)
  const mocoCode = buildMocoCode({ modelCode, colorCode })

  const { showAllTagsToggleActive, showMatchToggleActive } = settings

  useEffect(() => {
    setTagsExpanded(showAllTagsToggleActive)
  }, [showAllTagsToggleActive])

  const tags = Object.entries(positioningMatch)
    .sort(([, percentA], [, percentB]) => Number(percentB) - Number(percentA))
    .filter((_, index) => tagsExpanded || index === 0)

  const showMatch = match && match > 60 && showMatchToggleActive

  return (
    <Wrapper>
      <BrandMatch>
        <Brand>{brand?.brand}</Brand>
        {showMatch && (
          <Match>
            {t('AA.match')}: {match}%
          </Match>
        )}
      </BrandMatch>
      <Header>
        <NameAndCode>
          <Code>
            {modelCode} {colorCode}
          </Code>
          <Name>{modelName}</Name>
        </NameAndCode>
        <Prices>
          <WhsPrice>{showWhsPrice ? formatMoney(currency, whsPrice) : ''}</WhsPrice>
          <SrpPrice>
            {showSrpPrice ? `${t('AA.SRP')} ${formatMoney(currency, srPrice)}` : ''}
          </SrpPrice>
        </Prices>
      </Header>
      <Chips>
        {tags.map(([name, percent]) => (
          <PositioningTag key={name} className={name}>
            {t(`AA.${name}`)} {tagsExpanded && <span>{percent}%</span>}
          </PositioningTag>
        ))}
        <PositioningTag className="expand" onClick={() => setTagsExpanded(!tagsExpanded)}>
          <RCIcon type={tagsExpanded ? 'minus-black' : 'plus-black'} />
        </PositioningTag>
      </Chips>

      <ImageWrapper
        onClick={() => {
          dispatch(openPDP(mocoCode, modelCode))
          sendAnalytics('GO_TO_PDP_ACTION')
        }}
      >
        <Img
          src={getProductImageUrl({ path: catalogImages?.base || '' })}
          onError={e => (e.currentTarget.style.display = 'none')}
        />
      </ImageWrapper>

      <Tags>
        {isAdv && <Tag>{t('AA.Adv')}</Tag>}
        {isNew && <Tag>{t('AA.New')}</Tag>}
        {isColorRefresh && (
          <Tag className="colorRefresh">
            <ColorRefreshIcon />
          </Tag>
        )}
      </Tags>

      <CartButtons>
        {sizes.map(size => (
          <AddToCartButton key={size.upc} size={size} />
        ))}
      </CartButtons>
    </Wrapper>
  )
}

export default ProductCard
