import { debounce } from 'lodash'

import { CartAPI } from '../../../api/restApi'
import { showCartWarnings } from '../../../libs/cart'
import { getUpdatedCart } from '../../../libs/updateCartLib'
import { getDeviceFromUrl, isPageMatches } from '../../../libs/url'
import { CartProduct, UpdateCartPayloadItem } from '../../../model/cart'
import { AppThunk, RootState } from '../../../model/model'
import { dataLoadedFail } from '../../app/actions'
import { setDislikeList } from '../../dislike/actions'
import { fullResetFiltersAction } from '../../filters/actions'
import { addOrUpdateNotificationAction, showNotificationsAction } from '../../notifications/actions'
import { loadWishlistSuccess } from '../../wishlist/actions'
import { wishlistProductsSelector } from '../../wishlist/selectors'
import { slice } from '../slice'
import { saveUpdatedCart, setCart, setCartUpdating } from './'
import { UpdateCartPayload } from '../../../hooks/useUpdateCart'

function addToApiRequestsBuffer(updateCartItems: UpdateCartPayloadItem[], state: RootState) {
  const notInNewRequests = (apiRequest: any) => {
    const isNotInNewRequests = !updateCartItems.find(
      newRequest => apiRequest.upc === newRequest.upc && apiRequest.doorId === newRequest.doorId,
    )
    const alreadySent = apiRequest.requestStatus !== 'waiting'

    return isNotInNewRequests || alreadySent
  }

  const apiRequestsBuffer = state.cart.apiRequestsBuffer
    .filter(notInNewRequests)
    .concat(updateCartItems)

  return slice.actions.addToApiRequestBuffer(apiRequestsBuffer)
}

function responseIsDifferentFromRequest(requestItems: CartProduct[], responseItems: CartProduct[]) {
  return requestItems.some(
    ({ upc, doorId, quantity, status, minDeliveryDate, deliveryDate }) =>
      !responseItems.find(
        pu =>
          pu.upc === upc &&
          pu.doorId === doorId &&
          pu.quantity === quantity &&
          pu.status === status &&
          pu.minDeliveryDate === minDeliveryDate &&
          pu.deliveryDate === deliveryDate,
      ),
  )
}

export const sendUpdateCartRequest = (): AppThunk => (dispatch, getState) => {
  const state = getState()

  const { eventId, customerId, lang } = state.app

  const items = state.cart.apiRequestsBuffer.filter(
    apiRequest => apiRequest.requestStatus === 'waiting',
  )

  if (!items.length) {
    return
  }

  dispatch(slice.actions.setWaitingApiRequestAsPending())
  dispatch(setCartUpdating(true))
  const { token } = state.auth

  if (!token) {
    return
  }

  const cartApi = new CartAPI(token, lang)
  const request = cartApi.update(customerId, eventId, items)
  return request
    .then(response => {
      const { cart, wishlist, dislikeList } = response

      showCartWarnings(cart.warnings)

      if (cart.warnings.length || responseIsDifferentFromRequest(items, cart.productsUpdated)) {
        dispatch(setCart(getUpdatedCart(cart.productsUpdated, getState(), cart.warnings)))
        dispatch(loadWishlistSuccess(wishlist))
        dispatch(setDislikeList(dislikeList))
      } else {
        dispatch(slice.actions.setPendingApiRequestAsFullfilled())
      }
      dispatch(fullResetFiltersAction([], true))
    })
    .catch(err => {
      dispatch(slice.actions.resetPendingApiRequestAsWaiting())
      dispatch(slice.actions.setCartUpdating(false))
      dispatch(dataLoadedFail(err))
    })
}

const DEBOUNCE_TIMEOUT = 1500
const debounceOptions = { maxWait: 15000 }
const sendUpdateCartRequestBatch = debounce(
  dispatch => {
    dispatch(sendUpdateCartRequest())
  },
  DEBOUNCE_TIMEOUT,
  debounceOptions,
)

function optimisticOperations(updateCartItems: UpdateCartPayloadItem[]): AppThunk {
  return (dispatch, getState) => {
    const state = getState()
    const wishlistProducts = wishlistProductsSelector(state)

    dispatch(saveUpdatedCart(getUpdatedCart(updateCartItems, state)))

    const updatedWishlistProducts = wishlistProducts.filter(
      product => !updateCartItems.find(item => item.upc === product.upc),
    )
    dispatch(
      loadWishlistSuccess({ ...state.wishlist.wishlistData, products: updatedWishlistProducts }),
    )

    const updatedDislikeList = state.dislike.items.filter(
      item => !updateCartItems.find(itemWithoutOldQnt => itemWithoutOldQnt.mocoCode === item),
    )
    if (state.dislike.listId) {
      dispatch(setDislikeList({ items: updatedDislikeList, id: state.dislike.listId }))
    }
  }
}

/**
 * @description update cart
 * @param {array} items
 * @param {boolean} reorderItems
 * @param {boolean} isOwnRequest
 * @returns {Promise} the promise is resolved when the aouRequestsBuffer is sent to the api,
 *   the return value is the updated cart (or the rolled back one in case of erro)
 */
export function updateCartAction(updateCartItems: UpdateCartPayloadItem[]): AppThunk {
  return (dispatch, getState) => {
    const state = getState()
    dispatch(addToApiRequestsBuffer(updateCartItems, state))
    dispatch(optimisticOperations(updateCartItems))
    sendUpdateCartRequestBatch(dispatch)
  }
}

const batchRevertableUpdateCartAction = debounce(
  dispatch => {
    dispatch(showNotificationsAction('fastAddsToCart'))
  },
  DEBOUNCE_TIMEOUT,
  debounceOptions,
)

export const revertableUpdateCartAction = (
  updateCartPayload: UpdateCartPayload,
  codes: string[],
): AppThunk => {
  return dispatch => {
    const notificationMessage = `${codes.map(code => code.replace(/__/g, ' ')).join('')}`
    dispatch(
      addOrUpdateNotificationAction({
        codes: codes,
        message: notificationMessage,
        notificationPayload: updateCartPayload,
        notificationType: 'fastAddsToCart',
      }),
    )
    batchRevertableUpdateCartAction(dispatch)
  }
}

export const getCartCached = (): AppThunk => (dispatch, getState) => {
  const state = getState()
  const {
    app: { customerId, eventId, lang },
    auth: { token },
  } = state

  if (!token) {
    return Promise.reject('missing token')
  }

  const cartApi = new CartAPI(token, lang)

  dispatch(setCartUpdating(true))
  return cartApi
    .getFromCache(customerId, eventId)
    .then(response => {
      const { cart, wishlist, dislikeList } = response

      dispatch(setCart(cart))
      dispatch(loadWishlistSuccess(wishlist))
      dispatch(setDislikeList(dislikeList))
    })
    .catch(err => {
      dispatch(setCartUpdating(false))
      dispatch(dataLoadedFail(err))
    })
}

export const checkIfCartUpdateIsNeeded = (initiatorDevice: string): AppThunk => dispatch => {
  const device = getDeviceFromUrl()

  if (!device || device === initiatorDevice) {
    return
  }

  const isCartPage = isPageMatches('cart')

  if (['led-left', 'led-right'].includes(device) && !isCartPage) {
    return
  }

  if (['extra-ipad', 'vto-ipad', 'table'].includes(device)) {
    return dispatch(getCartCached())
  }

  if (isCartPage) {
    return dispatch(getCartCached())
  }
}

export const resetPendingApiRequestAsWaiting = slice.actions.resetPendingApiRequestAsWaiting
