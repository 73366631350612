import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDuplicateCartOnDoorsMutation } from '../../../services/afaCart'
import { ButtonConfirm } from '../../../components/AfaCommonComponents'
import styled from 'styled-components'
import {
  breakpoints,
  getFluidSizeWithFullFormula as gF,
  palette,
  pxToRem,
} from '../../../style/theme'
import FirstPageAfaDuplicate from './pages/FirstPageAfaDuplicate'
import SecondPageAfaDuplicate from './pages/SecondPageAfaDuplicate'
import ThirdPageAfaDuplicate from './pages/ThirdPageAfaDuplicate'
import { viewportSelector } from '../../../store/viewport/selectors'
import { scrollbarCss } from '../../../components/CommonComponents'
import { FixedSizeList as List } from 'react-window'

export const Wrapper = styled.div`
  background-color: ${palette.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 100%;
  align-items: center;
  font-size: ${gF('px', 15, 32.5, 1366, 3840)};
  padding-top: ${gF('px', 36, 110, 1366, 3840)};
  padding-bottom: ${gF('px', 40, 66, 1366, 3840)};
`
export const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ListWrapper = styled.div`
  position: relative;
  left: ${pxToRem(3)}rem;
  width: ${gF('px', 612, 1300, 1366, 3840)};
`
export const StyledList = styled(List)`
  margin: 2em 0;
  ${scrollbarCss}
  & {
    color: ${props => props.scrollbarColor};
  }
`
export const ListShadow = styled.div`
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 25%;
  z-index: 999;
  width: calc(100% - ${pxToRem(6)}rem);
  background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
`
export const Option = styled.div`
  width: 35em;
  height: ${gF('px', 60, 92, 1366, 3840)};
  margin-bottom: ${gF('px', 30, 40, 1366, 3840)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  border: ${gF('px', 1, 3, 1366, 3840)} solid ${palette.mischka};
  padding: 0 ${gF('px', 25, 32, 1366, 3840)};

  input {
    min-width: ${gF('rem', 1.25, 13, 1366, 3840)};
    min-height: ${gF('rem', 1.25, 13, 1366, 3840)};
    accent-color: ${palette.black};
  }

  label {
    font-size: ${gF('px', 18, 24, 1366, 3840)};
    font-weight: bold;
    color: ${palette.cloudBurst};
    margin-left: 1em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${palette.cloudBurst};
    @media (max-width: ${breakpoints.L}) {
      color: ${palette.blackPearl};
    }
  }
`
export const WrapperRow = styled.div`
  display: flex;
  justify-content: center;
`
export const DuplicateInfo = styled.h3`
  font-size: ${gF('px', 20, 40, 1366, 3840)};
  margin-top: ${gF('px', 32, 40, 1366, 3840)};
  margin-bottom: ${gF('px', 32, 72, 1366, 3840)};
  color: ${palette.tangaroa};
  @media (max-width: ${breakpoints.L}) {
    color: ${palette.cloudBurst};
  }
`
export const FromDoorInfo = styled(DuplicateInfo)`
  font-size: ${gF('px', 18, 40, 1366, 3840)};
  font-family: GilmerLight, sans-serif;
  font-weight: normal;
  margin-top: ${gF('px', 32, 40, 1366, 3840)};
  margin-bottom: ${gF('px', -20, -30, 1366, 3840)};
  span {
    font-family: GilmerBold, sans-serif;
  }
  @media (max-width: ${breakpoints.L}) {
    font-family: GilmerRegular, sans-serif;
  }
`
export const DoorDuplicate = styled.div`
  margin-bottom: ${gF('px', 30, 40, 1366, 3840)};
  font-size: ${gF('px', 18, 32, 1366, 3840)};
  color: ${palette.tangaroa};
  width: 27.5em;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &::before {
      content: '';
      display: inline-block;
      height: 0.8em;
      width: 0.8em;
      background-size: 0.8em 0.8em;
      background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='rgb(117, 165, 62)' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.802 3.052a.58.58 0 0 1-.18.42l-9.547 9.26a.963.963 0 0 1-.67.268.963.963 0 0 1-.67-.269L.18 8.313a.585.585 0 0 1 0-.84l.472-.458a.625.625 0 0 1 .867 0l3.67 3.56a.312.312 0 0 0 .431 0l8.665-8.401a.624.624 0 0 1 .866 0l.473.458c.115.11.18.262.18.42z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      margin-right: 0.5em;
    }
  }
`
export const DuplicateDataOnDoorErrorImage = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16' cy='16' r='15.5' fill='%23FFEDD8' stroke='%23F18200'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17 12a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0v-4zm-1 7a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H16z' fill='%23F18200'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  height: 2.1em;
  width: 2.1em;
  background-size: 2.1em 2.1em;
  margin-top: 2em;
`
export const DuplicateDataOnDoorErrorText = styled.div`
  white-space: pre-line;
  font-size: ${gF('px', 14, 20, 1366, 3840)};
  font-family: GilmerRegular, sans-serif;
  text-align: center;
  font-weight: bold;
  color: ${palette.tangaroa};
  margin: 1.5em 0 2.1em;
`
export const ButtonConfirmMod = styled(ButtonConfirm)`
  margin: 0 0 0 0;
  font-family: GilmerBold, sans-serif;
`

export const ErrorMessage = styled.h1`
  font-size: ${gF('px', 20, 40, 1366, 3840)};
  margin-top: ${gF('px', 32, 40, 1366, 3840)};
  color: ${palette.tangaroa};
  @media (max-width: ${breakpoints.L}) {
    color: ${palette.cloudBurst};
  }
`

type Props = {
  onClose: () => void
}
const AfaDuplicateCartOnDoorsModal: React.FC<Props> = ({ onClose }) => {
  const [sourceDoorId, setSourceDoorId] = useState<string>('')
  const [destinationDoorIds, setDestinationDoorIds] = useState<string[]>([])
  const [showRecap, setShowRecap] = useState<boolean>(false)
  const [sourceDoorIdData, setSourceDoorIdData] = useState<string>('')

  const { height } = useSelector(viewportSelector)
  const itemSize = Math.round(height / (height > parseInt(breakpoints.L) ? 17.25 : 11.25))
  const listHeight = height / 2.5
  const listCSS = {
    itemSize: itemSize,
    listHeight: listHeight,
    scrollbarColor: palette.bermudaGray,
    scrollbarBackgroundColor: palette.mischka,
  }

  const [duplicateCartOnDoors, duplicateCartOnDoorsResult] = useDuplicateCartOnDoorsMutation()

  const handleClose = () => {
    onClose()
    setTimeout(() => {
      setSourceDoorId('')
      setSourceDoorIdData('')
      setShowRecap(false)
      setDestinationDoorIds([])
    }, 250)
  }

  useEffect(() => {
    if (duplicateCartOnDoorsResult.isSuccess) {
      setShowRecap(true)
    }
  }, [duplicateCartOnDoorsResult.isSuccess])

  if (!sourceDoorId) {
    return (
      <FirstPageAfaDuplicate
        sourceDoorIdData={sourceDoorIdData}
        setSourceDoorIdData={setSourceDoorIdData}
        setSourceDoorId={setSourceDoorId}
        setDestinationDoorIds={setDestinationDoorIds}
        onClose={onClose}
        listCSS={listCSS}
      />
    )
  } else if (!showRecap) {
    return (
      <SecondPageAfaDuplicate
        sourceDoorId={sourceDoorId}
        destinationDoorIds={destinationDoorIds}
        setDestinationDoorIds={setDestinationDoorIds}
        duplicateCartOnDoors={duplicateCartOnDoors}
        setSourceDoorId={setSourceDoorId}
        isLoading={duplicateCartOnDoorsResult.isLoading}
        listCSS={listCSS}
        //setShowRecap={setShowRecap} //FOR TESTING ONLY
      />
    )
  } else {
    return (
      <ThirdPageAfaDuplicate
        duplicateCartOnDoorsResult={duplicateCartOnDoorsResult}
        destinationDoorIds={destinationDoorIds}
        onClose={handleClose}
        listCSS={listCSS}
      />
    )
  }
}

export default AfaDuplicateCartOnDoorsModal
