import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import SwiperCore, { Navigation, Pagination } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'

import { initiativesSelector } from '../../store/digitalEvents/selectors'
import { BoxTitle, mainContentLateralPadding } from '../../style/DigitalEventsCommonComponents'
import { breakpoints, breakpointsNoUnit, palette, pxToRem, spacing } from '../../style/theme'
import Icon from '../../styleguide/Icon'
import InitiativeTile from './InitiativeTile'

SwiperCore.use([Pagination, Navigation])

const Wrapper = styled.div`
  ${mainContentLateralPadding}
  grid-row-start: 3;
  width: 100vw;
  padding-right: 0;
  flex: 0;

  @media (max-width: ${breakpoints.M}) {
    padding-right: 0;
  }

  @media (max-width: ${breakpoints.S}) {
    padding-top: ${pxToRem(spacing(2))}rem;
    padding-right: 0;
  }

  .left-arrow {
    margin-right: ${pxToRem(spacing(2))}rem;
    &-hidden {
      opacity: 0;
    }
  }

  .right-arrow {
    &-hidden {
      opacity: 0;
    }
  }
`

const InitiativeCardTitle = styled(BoxTitle)`
  width: 31%;
  justify-content: space-between;
  display: flex;
  color: ${palette.white};
  margin-bottom: ${pxToRem(spacing(2))}rem;
  gap: ${pxToRem(spacing(1))}rem;

  .initiatives-header__arrows {
    margin-right: ${pxToRem(spacing(4))}rem;
  }

  @media (max-width: ${breakpoints.M}) {
    width: 37%;
  }

  @media (max-width: ${breakpoints.S}) {
    margin-bottom: ${pxToRem(spacing(1))}rem;
  }
`

const Initiatives: React.FC = () => {
  const [initiativesSwiper, setInitiativeSwiper] = useState<SwiperCore>()
  const [currentInitiativeIndex, setCurrentInitiativeIndex] = useState(0)
  const { t } = useTranslation()
  const initiatives = useSelector(initiativesSelector)

  return initiatives?.length ? (
    <Wrapper>
      <InitiativeCardTitle>
        <div>{t('Dashboard.inititative_title')}</div>
        <div>
          <Icon
            type="left"
            className={`left-arrow ${currentInitiativeIndex === 0 ? 'left-arrow-hidden' : ''}`}
            onClick={() => {
              initiativesSwiper?.slidePrev()
            }}
          />
          <Icon
            type="right"
            onClick={() => {
              initiativesSwiper?.slideNext()
            }}
            className={`right-arrow ${initiativesSwiper?.isEnd ? 'right-arrow-hidden' : ''}`}
          />
        </div>
      </InitiativeCardTitle>
      <div>
        <Swiper
          slidesPerView={2.4}
          setWrapperSize
          loop={false}
          scrollbar={{ draggable: true }}
          onSwiper={swiper => setInitiativeSwiper(swiper)}
          onSlideChange={swiper => {
            setCurrentInitiativeIndex(swiper.activeIndex)
          }}
          breakpoints={{
            [breakpointsNoUnit.M]: {
              slidesPerView: 2.45,
            },
            [breakpointsNoUnit.L]: {
              slidesPerView: 3.07,
            },
          }}
        >
          {initiatives?.map(initiative => (
            <SwiperSlide key={initiative.id}>
              <InitiativeTile initiative={initiative} />
            </SwiperSlide>
          ))}
          <SwiperSlide>{/* to allow reaching the last slide*/}</SwiperSlide>
        </Swiper>
      </div>
    </Wrapper>
  ) : null
}

export default Initiatives
