import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { getDeviceAbsolutePath } from '../../../libs/url'
import { breakpoints, getFluidSizeWithFullFormula, palette, pxToRem } from '../../../style/theme'
import AfaModal from '../../../components/AfaModal'
import { useDispatch, useSelector } from 'react-redux'
import { showMultidoorNotificationSelector } from '../../../store/afaCartSummary/selectors'
import afaCartSummaryActions from '../../../store/afaCartSummary/actions'
import { customerDoorsSelector } from '../../../store/customer/selectors'

const Wrapper = styled.div`
  padding-top: clamp(20px, ${getFluidSizeWithFullFormula('px', 20, 80, 1366, 3840)}, 80px);
  padding-bottom: clamp(24px, ${getFluidSizeWithFullFormula('px', 24, 55, 1366, 3840)}, 55px);
  padding-inline: 2vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${palette.lightGray};
  color: ${palette.tangaroa};
`

const PageTitle = styled.div`
  display: flex;
  align-items: center;

  h1 {
    font-size: clamp(24px, ${getFluidSizeWithFullFormula('px', 24, 64, 1366, 3840)}, 64px);
    font-weight: bold;
    letter-spacing: 1px;

    @media (min-width: ${breakpoints.L}) {
      letter-spacing: 0.28px;
    }
  }
`

const StepperList = styled.div`
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: relative;
  color: ${palette.tangaroa};
  font-size: clamp(13px, ${getFluidSizeWithFullFormula('px', 13, 20, 1366, 3840)}, 20px);
  gap: ${pxToRem(8)}rem;
`

const Step = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &:not(:last-child)::after {
    content: '';
    display: block;
    width: calc(100% + 0.5rem);
    height: 2px;
    background-color: ${palette.platinum};
    position: absolute;
    left: 50%;
    top: 1rem;
    z-index: 1;
  }
`

const StepTitle = styled.p`
  font-weight: bold;
  color: ${palette.tangaroa};
`

const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: clamp(9px, ${getFluidSizeWithFullFormula('px', 9, 16, 1366, 3840)}, 16px);
`

const StepLabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  padding: ${pxToRem(16)}rem;
  width: 32px;
  height: 32px;
  border: 2px solid;
  background-color: ${palette.white};
  color: ${palette.tangaroa};
  border-radius: 50%;
  font-weight: bold;

  .active & {
    background-color: ${palette.white};
    color: ${palette.tangaroa};
    border-color: ${palette.tangaroa};
  }

  .processed & {
    background-color: ${palette.tangaroa};
    color: ${palette.white};
    border-color: ${palette.tangaroa};
  }

  .not-processed & {
    background-color: ${palette.white};
    color: ${palette.tangaroa};
    border-color: ${palette.platinum};
  }
`

const AfaModalAvailability = styled(AfaModal)`
  > div {
    overflow: visible;
  }
`
const ModalTitle = styled.h2`
  font-family: GilmerBold, sans-serif;
  text-align: center;
  font-size: ${getFluidSizeWithFullFormula('px', 26, 48, 1366, 3840)};
`

const ModalText = styled.p`
  margin: ${pxToRem(72)}rem 0;
  font-family: GilmerMedium, sans-serif;
  text-align: center;
  font-size: ${getFluidSizeWithFullFormula('px', 20, 40, 1366, 3840)};
`

const ModalButtonContainer = styled.div`
  text-align: center;
`

const ModalCloseButton = styled.button`
  background-color: ${palette.tangaroa};
  font-family: GimerBold, sans-serif;
  color: ${palette.white};
  width: ${pxToRem(206)}rem;
  height: ${pxToRem(56)}rem;
  border-radius: ${pxToRem(4)}rem;
  font-size: ${getFluidSizeWithFullFormula('px', 13, 24, 1366, 3840)};
  text-transform: uppercase;
`

const getClassName = ({
  currentStep,
  activeStep,
  completedSuccessfully,
}: {
  currentStep: number
  activeStep: number
  completedSuccessfully: boolean
}) => {
  if (completedSuccessfully) return 'processed'
  return currentStep === activeStep
    ? 'active'
    : activeStep > currentStep
    ? 'processed'
    : 'not-processed'
}

type Props = {
  activeStep: number
  completedSuccessfully?: boolean
}

const AfaStepper: React.FC<Props> = ({ activeStep, completedSuccessfully = false }) => {
  const { assortment } = useParams<{
    assortment: string
  }>()

  const dispatch = useDispatch() //hideMultidoorNotification
  const showNotificationModal = useSelector(showMultidoorNotificationSelector)
  const customerDoors = useSelector(customerDoorsSelector)

  useEffect(() => {
    if (customerDoors.length < 2) {
      dispatch(afaCartSummaryActions.hideMultidoorNotification())
    }
  }, [customerDoors.length, dispatch])

  const { t } = useTranslation()

  const steps = [
    {
      stepTitle: t('Checkout.Stepper.DeliveryReferences'),
      pageTitle: t('Checkout.Process.PageTitle'),
      link: '',
    },
    {
      stepTitle: t('Checkout.Stepper.DeliveryAddress'),
      pageTitle: t('Checkout.Address.PageTitle'),
      link: '/addresses',
    },
    {
      stepTitle: t('Checkout.Stepper.Confirmation'),
      pageTitle: t('Checkout.Confirm.PageTitle'),
      link: '/thank-you',
    },
  ]

  return (
    <Wrapper>
      <PageTitle>
        <h1>{steps[activeStep - 1]?.pageTitle}</h1>
      </PageTitle>

      <StepperList>
        {steps.map((step, index) => (
          <Step
            className={getClassName({
              currentStep: index,
              activeStep: activeStep - 1,
              completedSuccessfully,
            })}
            key={index}
            to={
              step.link === undefined
                ? '#'
                : `${getDeviceAbsolutePath()}/${assortment}/checkout${step.link}`
            }
          >
            <StepContent>
              <StepLabelContainer>{index + 1}</StepLabelContainer>

              <StepTitle>{step.stepTitle}</StepTitle>
            </StepContent>
          </Step>
        ))}
      </StepperList>

      <AfaModalAvailability
        fullscreen={false}
        isOpen={showNotificationModal}
        onRequestClose={() => dispatch(afaCartSummaryActions.hideMultidoorNotification())}
      >
        <ModalTitle>{t('Afa.Checkout.productAvailability.title')}</ModalTitle>
        <ModalText>{t('Afa.Checkout.productAvailability.text')}</ModalText>
        <ModalButtonContainer>
          <ModalCloseButton
            onClick={() => dispatch(afaCartSummaryActions.hideMultidoorNotification())}
          >
            {t('GenericWords.ok')}
          </ModalCloseButton>
        </ModalButtonContainer>
      </AfaModalAvailability>
    </Wrapper>
  )
}

export default AfaStepper
