import { Button, Col, Icon } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useHandleTableWallPageSwitch } from '../../../hooks/useHandleTableWallPageSwitch'
import {
  breakpointsCross,
  breakpointsNoUnit,
  getFluidSizeWithFullFormula,
  minSupportedWidth,
  palette,
} from '../../../style/theme'

import Row from '../../../styleguide/Row'

const SwitchButton = styled(Button)`
  color: ${palette.congressBlue};
  text-transform: uppercase;
  position: fixed;
  left: 1vmin;
  bottom: 1vmin;
  font-size: 1.5vmin;
  display: block;
  @media (max-width: ${breakpointsCross.L.max}) {
    display: none;
  }
`

const MoodboardSmallTiles = styled(Row)`
  padding-left: 5.625vw;
  display: flex;
  width: 100%;
`

const MoodboardSmallTilesTile = styled(Col)`
  display: flex;
  align-items: center;
  margin-left: 1vw;
  width: 30vw;

  @media screen and (max-width: ${breakpointsCross.M.min}) {
    flex-direction: column;
    width: 23vw;
  }
`

const TilePicture = styled.div`
  width: 15.4vw;
  @media screen and (max-width: ${breakpointsCross.M.min}) {
    width: 100%;
  }
  height: 25vh;
  background: ${palette.wildSand};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }
`

const TileText = styled.div`
  width: 13vw;
  margin-left: 2rem;
  color: ${palette.mineShaft};
  font-size: ${getFluidSizeWithFullFormula('px', 8, 30, minSupportedWidth, breakpointsNoUnit.XL)};
  line-height: ${getFluidSizeWithFullFormula(
    'px',
    14,
    70,
    minSupportedWidth,
    breakpointsNoUnit.XL,
  )};
  @media screen and (max-width: ${breakpointsCross.M.min}) {
    margin-left: 0;
    text-align: center;
    width: 21.6vw;
    margin-top: 1.4vh;
  }
`

type Tile = {
  url?: string
  paragraph?: string
}

type Props = {
  tile1?: Tile
  tile2?: Tile
  tile3?: Tile
}

const SmallTiles: React.FC<Props> = ({ tile1, tile2, tile3, children }) => {
  const { t } = useTranslation()

  const handleSwitch = useHandleTableWallPageSwitch()

  return (
    <MoodboardSmallTiles>
      <SwitchButton className="btn-link hidden-wall" onClick={handleSwitch}>
        <Icon type="switcher" /> {t('PdpPage.product_page')}
      </SwitchButton>
      <MoodboardSmallTilesTile>
        <TilePicture>
          {tile1 ? (
            <img src={tile1.url} data-testid={tile1.url} />
          ) : (
            tile3 && <img src={tile3.url} data-testid={tile3.url} />
          )}
        </TilePicture>
        <TileText>{tile1 ? tile1.paragraph : tile3 && tile3.paragraph}</TileText>
      </MoodboardSmallTilesTile>
      <MoodboardSmallTilesTile>
        <TilePicture>
          {tile2 ? (
            <img src={tile2.url} data-testid={tile2.url} />
          ) : (
            tile3 && <img src={tile3.url} data-testid={tile3.url} />
          )}
        </TilePicture>
        <TileText>{tile2 ? tile2.paragraph : tile3 && tile3.paragraph}</TileText>
      </MoodboardSmallTilesTile>
      {children}
    </MoodboardSmallTiles>
  )
}

export default SmallTiles
