import { History } from 'history'

import devices, { sessionSynchedDevices } from '../../config/devices'
import socketManager from '../../libs/socketManager'
import { getDeviceAbsolutePath, isExtraIpadDevice, isTableDevice } from '../../libs/url'
import { AppThunk, DeviceName, RootState } from '../../model/model'
import { destroySession, loggedIn } from '../auth/actions'
import { tokenSelector } from '../auth/selectors'
import { resetRecommended } from '../cart/actions'
import { getCustomerData } from '../customer/actions'
import {
  changeLanguage,
  resetErrors,
  setCustomerIdWithoutSync,
  setEventIdWithoutSync,
  setEventListWithoutSync,
} from './actions'
import {
  customerIdSelector,
  deviceNameSelector,
  eventIdSelector,
  eventsSelector,
} from './selectors'

const INIT_SESSION_MSG = 'init_session'

export const reinitSessionData = (state: RootState, deviceToInit?: DeviceName) => {
  if (devices.isCurrentDeviceAuthorized(devices.table)) {
    const token = tokenSelector(state)
    const eventId = eventIdSelector(state)
    const customerId = customerIdSelector(state)
    const events = eventsSelector(state)
    const sessionData = {
      token,
      eventId,
      customerId,
      events,
      deviceToInit,
    }
    socketManager.emitSignal(INIT_SESSION_MSG, sessionData)
  }
}

export const subscribeToAppMessagesAction = (history: History): AppThunk => (
  dispatch,
  getState,
) => {
  socketManager.bindListener('device_join_room', (data: { device: DeviceName }) => {
    const aSecondaryDeviceHasJoined = data.device && data.device !== devices.table.routes.root_url
    const thePrimaryDeviceHasJoined = data.device && data.device === devices.table.routes.root_url
    if (aSecondaryDeviceHasJoined) {
      reinitSessionData(getState(), data.device)
    } else if (thePrimaryDeviceHasJoined && !isTableDevice()) {
      // this allows the secondary device to sync with the primary even if the primary
      // is switched on after the secondary, in this case
      // the secondary device receives a join signal from the primary device,
      // it emits its own join signal again,
      // the primary device will listen to this signal
      // and will emit a reinit session data message to reinit session on the secondary device
      socketManager.emitJoinSignal()
    }
  })

  socketManager.bindListener(INIT_SESSION_MSG, ({ response, requestData }: any) => {
    const state = getState()
    const currentDevice = deviceNameSelector(state)
    if (
      response.deviceToInit === currentDevice &&
      devices.isCurrentDeviceToSync(sessionSynchedDevices, requestData.device)
    ) {
      const { token, eventId, customerId, events, language } = response

      dispatch(resetRecommended())
      dispatch(resetErrors())
      token && dispatch(loggedIn(token))
      eventId && dispatch(setEventIdWithoutSync(eventId))
      customerId && dispatch(setCustomerIdWithoutSync(customerId))
      customerId && dispatch(setEventListWithoutSync(events))
      language && dispatch(changeLanguage(language))
      if (token && customerId) {
        dispatch(getCustomerData(customerId, 'frames', token))
        if (isExtraIpadDevice()) {
          history.push(`${getDeviceAbsolutePath()}/choose-brand`)
        }
      }
    }
  })

  socketManager.bindListener('do_logout', () => {
    dispatch(destroySession())
  })
}
