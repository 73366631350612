import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { getDeviceAbsolutePath } from '../../../libs/url'
import {
  breakpointsNoUnit,
  DigitalEventsBoxBorderRadius,
  getFluidSizeWithFullFormula,
  palette,
} from '../../../style/theme'
import { useGetBrandLogo } from '../../../services/afaContents'
import { useSelector } from 'react-redux'
import { brandSelectorByCode } from '../../../store/brands/selectors'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
`

const StyledLink = styled(Link)<{ background?: string }>`
  background-color: ${palette.bigStone};
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
    url(${({ background }) => background});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: ${DigitalEventsBoxBorderRadius};
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 30vw;

  @supports (aspect-ratio: 4/5.7) {
    height: auto;
    max-height: 100%;
    aspect-ratio: 4/5.7;
  }

  &.inactive {
    opacity: 0.5;
  }
`

const WrapperLogo = styled.div`
  display: flex;
  height: 40%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  color: ${palette.white};
`

const Logo = styled.img`
  display: block;
  max-width: 55%;
  max-height: 75px;
  filter: invert(1);
`

const LockedText = styled.div<{ visible: boolean }>`
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${palette.white};
  font-size: ${getFluidSizeWithFullFormula('px', 12, 36, 0, breakpointsNoUnit.L)};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 1s;
  cursor: pointer;
`

interface Props {
  brandCode: string
  slug: string
  active: boolean
  background?: string
}

const BrandsCard: React.FC<Props> = ({ brandCode, slug, active, background }) => {
  const history = useHistory()
  const [isMessageVisible, setMessageVisible] = useState(false)
  const { t } = useTranslation()

  const onClick = () => {
    if (!active) {
      setMessageVisible(true)
      setTimeout(() => setMessageVisible(false), 3000)
    }
  }

  const brand = useSelector(brandSelectorByCode(brandCode))
  const { brandLogo, isSuccess } = useGetBrandLogo(brandCode)

  return (
    <Wrapper>
      <StyledLink
        to={active ? `${getDeviceAbsolutePath()}/single-brand/${slug}` : '#'}
        background={background}
        onClick={onClick}
        className={active ? 'active' : 'inactive'}
      >
        {brandCode && (
          <WrapperLogo>
            {brandLogo || !isSuccess ? (
              <Logo src={brandLogo} alt={brand?.brand || brandCode} />
            ) : (
              <span>{brand?.brand || brandCode}</span>
            )}
          </WrapperLogo>
        )}
      </StyledLink>
      <LockedText
        onClick={() => {
          onClick()
          if (active) history.push(`${getDeviceAbsolutePath()}/single-brand/${slug}`)
        }}
        visible={isMessageVisible}
      >
        {t('DigitalPreviewBrandsPage.locked')}
      </LockedText>
    </Wrapper>
  )
}

export default BrandsCard
