export const calculateDistanceBetweenTwoCoordinates = (
  firstCoordinate: google.maps.LatLng,
  secondCoordinate: google.maps.LatLng,
  unit: 'm' | 'km' = 'm',
) => {
  // Calculate the distance between the two coordinates using the Haversine formula
  const R = unit === 'm' ? 6371e3 : 6.371 // Earth's radius in meters or kilometers
  const lat1 = (firstCoordinate.lat() * Math.PI) / 180
  const lat2 = (secondCoordinate.lat() * Math.PI) / 180
  const deltaLat = ((secondCoordinate.lat() - firstCoordinate.lat()) * Math.PI) / 180
  const deltaLng = ((secondCoordinate.lng() - firstCoordinate.lng()) * Math.PI) / 180
  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c
  return distance
}
