import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getLabel } from '../../../libs/filters'
import { isElectronicPage, isPageMatches } from '../../../libs/url'
import { brandsSelector } from '../../../store/brands/selectors'
import Checkbox from '../../../styleguide/Checkbox'
import Radio from '../../../styleguide/Radio'

type Props = {
  value: boolean | string
  controlClass?: string
  quantity?: number
  toggle: () => void
  optionLabel: string
  optionName: string
  type: 'checkbox' | 'radio'
  checked: boolean
  filterName: string
  isCheckoutRecap?: boolean
  disabled?: boolean
  withoutInput?: boolean
}

export const BaseFilter: React.FC<Props> = ({
  value,
  checked,
  controlClass,
  quantity,
  toggle,
  type,
  optionName,
  optionLabel,
  filterName,
  isCheckoutRecap,
  disabled,
  withoutInput,
}) => {
  const { t } = useTranslation()
  const brands = useSelector(brandsSelector)

  const label = getLabel({
    filterName,
    optionName,
    brands,
    optionLabel,
    t,
  })

  const isFilterEmpty = quantity !== null && quantity === 0
  const isFilterReleaseEmptyInCart = isPageMatches('cart') && filterName === 'release' && !quantity
  const isFilterReleaseOther =
    !isPageMatches('cart') && filterName === 'release' && optionLabel === 'other'
  const isCheckout = isPageMatches('checkout')

  const isFilterElectronicCategories = filterName === 'category' && isElectronicPage()

  const isDisabled = (!isFilterReleaseOther && isFilterEmpty) || isFilterReleaseEmptyInCart

  const qty = quantity || 0
  const renderLabel = `${label} ${qty && qty > 0 ? `(${qty})` : ''}`

  return type === 'checkbox' ? (
    <Checkbox
      disabled={(isDisabled && !isCheckoutRecap) || !!disabled}
      checked={checked}
      value={value}
      className={[controlClass || '', withoutInput ? 'without-input' : ''].join(' ')}
      onChange={toggle}
    >
      {renderLabel}
    </Checkbox>
  ) : (
    <Radio
      className={[controlClass || '', withoutInput ? 'without-input' : ''].join(' ')}
      value={value}
      checked={checked}
      onChange={toggle}
      disabled={isFilterElectronicCategories || (isCheckout && isFilterEmpty) || !!disabled}
    >
      {renderLabel}
    </Radio>
  )
}

export default BaseFilter
