export const analyticsPadding = '1.5vw 0'

export const minSupportedWidth = 0

export const breakpointsNoUnit = {
  XS: 900,
  S: 1024,
  M: 1366,
  L: 1920,
  XL: 3840,
}

export const breakpoints = {
  XS: `${breakpointsNoUnit.XS}px`,
  S: `${breakpointsNoUnit.S}px`,
  M: `${breakpointsNoUnit.M}px`,
  L: `${breakpointsNoUnit.L}px`,
  XL: `${breakpointsNoUnit.XL}px`,
}

export const breakpointsCross = {
  XS: {
    max: `${breakpointsNoUnit.XS - 1}px`,
    min: `${breakpointsNoUnit.XS}px`,
    over: `${breakpointsNoUnit.XS + 1}px`,
  },
  S: {
    max: `${breakpointsNoUnit.S - 1}px`,
    min: `${breakpointsNoUnit.S}px`,
    over: `${breakpointsNoUnit.S + 1}px`,
  },
  M: {
    max: `${breakpointsNoUnit.M - 1}px`,
    min: `${breakpointsNoUnit.M}px`,
    over: `${breakpointsNoUnit.M + 1}px`,
  },
  L: {
    max: `${breakpointsNoUnit.L - 1}px`,
    min: `${breakpointsNoUnit.L}px`,
    over: `${breakpointsNoUnit.L + 1}px`,
  },
  XL: {
    max: `${breakpointsNoUnit.XL - 1}px`,
    min: `${breakpointsNoUnit.XL}px`,
    over: `${breakpointsNoUnit.XL + 1}px`,
  },
}

// names from https://chir.ag/projects/name-that-color
export const palette = {
  butterScotch: '#fbbf3c',
  wildSand: '#f6f6f6',
  white: '#fff',
  black: '#000',
  codGray2: '#111',
  tropicalBlue: '#D3E8F8',
  selago: '#e9f3fc',
  biscay: '#173e5c',
  turquoise: '#36d6bb',
  elfGreen: '#0f986d',
  alto: '#dadada',
  congressBlue: '#005192',
  curiousBlue: '#338acc',
  mineShaft: '#222222',
  codGray: '#181818',
  hibiscus: '#C4316E',
  tamarillo: '#9a142d',
  tundora: '#404040',
  mariner: '#2c7ec4',
  mischka: '#DEE0E5',
  santasGray: '#9a9fad',
  gallery: '#efefef',
  kobi: '#e699b9',
  chalky: '#edd78f',
  diSerria: '#d7ae56',
  riptide: '#95e9db',
  mercury: '#e1e1e1',
  silver: '#cccccc',
  athensGray: '#e8ebee',
  doveGray: '#666666',
  cornFlower: '#8DC6EB',
  cornflowerBlue: '#63b1e5',
  cornflowerBlueAA: '#5093F9',
  cornflowerBlueDarker: '#6e8dfd',
  gray: '#888888',
  concrete: '#f3f3f3',
  alabaster: '#fafafa',
  red: 'red',
  denim: '#1452CC',
  dustyGray: '#999999',
  bondiBlue: '#0095B6',
  geyser: '#D9DEE3',
  bigStone: '#171C3C',
  bigStoneAA: '#182c4a',
  blueStars: '#061532',
  greenStarsIn: '#75AE8F',
  defaultDeBg: '#828db5',
  tangaroa: '#031434',
  viking: '#67B2E3',
  blueCheckout: '#005192',
  linkWater: '#F4F7FC',
  linkWaterDark: '#D5E4F5',
  linkWaterLight: '#DCE9F9',
  zircon: '#F8FBFF',
  tree: '#0f8a0d',
  greyBlue: '#606f89',
  cucumber: '#ebfad7',
  zuchini: '#034e06',
  raspberry: '#ffebec',
  pomegranate: '#c4453e',
  lemon: '#fff6e7',
  mango: '#e5ad50',
  bondiBlueDark: '#00a3b0',
  mountainMeadow: '#11b683',
  softGrey: '#ededed',
  softWhite: '#f1f1f1',
  blueGradient: '#d3e8f8',
  whiteGradient: '#e9f3fc',
  mineShaftDark: '#333333',
  niceBlue: '#005997',
  strangeWhite: '#f5f5f5',
  littleGrey: '#d9d9d9',
  philippineSilver: '#b3b3b3',
  silverSand: '#BABDC1',
  lavender: '#e2f0ff',
  mediumElectricBlue: '#035093',
  water: '#cbe3ff',
  milanoRed: '#b90909',
  shadowBlue: '#7888A5',
  platinum: '#DEE0E5',
  manatee: '#9A9FAD',
  bermudaGray: '#7888a5',
  lightGray: '#f7f7f7',
  extraLight: '#f0f1f5',
  noticeNotificationBackground: '#F0F1F5',
  darkYellow: '#f18200',
  paleYellow: '#ffedd8',
  cloudBurst: '#1E2A3F',
  bilobaFlower: '#b8aaf0',
  wewak: '#f3a294',
  calypso: '#366690',
  monteCarlo: '#83cec0',
  zombie: '#dde38b',
  carissma: '#ed8ec7',
  blackPearl: '#081f32',
  blackPearlWB: '#071432',
  paleGreen: '#E0F4CA',
  darkGreen: '#75A53E',
  midnight: '#021F38',
  chromeWhite: '#E0F4CA',
  leaf: '#75A53E',
  papayaWhip: '#FFEDD8',
  goldDrop: '#F18200',
  checkGreen: 'rgb(117, 165, 62)',
  sanMarino: '#3C6AB3',
  rhino: '#324666',
  hawkesBlue: '#DAE9FD',
  rockBlue: '#a2b2d0',
  shipCove: '#8398C3',
  steelBlue: '#607FBE',
  blue: '#448af4',
  seance: '#7c0f7d',
  nileBlue: '#193156',
  blueZodiac: '#0b1c35',
  blueZodiacAA: '#0e213d',
  romanCoffee: '#826353',
  frenchLilac: '#e4a6ec',
  clairvoyant: '#5e0667',
  iron: '#dee2e5',
  pattensBlue: '#d5e8fe',
  brownishGrey: '#A96F60',
  softPeach: '#F1EEEB',
  night: '#080c22',
  swamp: '#001625',
  acidGreen: '#A5B840',
  waterGreen: '#49c5bc',
  firefly: '#0e213d',
  barney: '#A62EA8',
  lynch: '#79859F',
  tulipTree: '#EFB041',
  anakiwa: '#89E4FE',
  dodgerBlue: '#2DCAFF',
  AAinnovative: '#d383dc',
  AAinnovativeDarker: '#622366',
  AAsophisticated: '#D89B8F',
  AAsophisticatedDarker: '#5B322A',
  AAyoung: '#7FAFF4',
  AAyoungDarker: '#1E3C68',
  AAeveryday: '#D9E585',
  AAeverydayDarker: '#636B25',
  AAsport: '#8CEDE4',
  AAsportDarker: '#18514C',
  AAbrands: '#D8D8D8',
  AAbrandsDarker: '#333333',
}

export const zIndex = {
  base: 1,
  searchModal: 4,
  inPageloading: 5,
  imagesAboveLoading: 6,
  plpArrows: 10,
  plpMenus: 15,
  checkoutMenus: 15,
  header: 20,
  afaCartDropSwitcher: 21,
  afaCartSummary: 22,
  backdrop: 23,
  subMenu: 100,
  shareAfaCartModal: 1010,
  rtrModal: 1011,
  afaPdpModal: 20000,
}

export const buttonBorderRadius = '8px'
export const borderRadiusPreHome = '16px'
export const DigitalEventsBoxBorderRadius = '10px'

export const spacing = n => n * 8

const BASE_FONT_SIZE = 16
export const pxToRem = px => px / BASE_FONT_SIZE

export const discontinuedOpacity = 0.4

export const fluidFontSize = `calc(12px + 12 * ((100vw - ${breakpoints.S}) / 2816))`

export const getFluidFontSize = (baseSize, deltaSize) => getFluidSize(baseSize, deltaSize)

export const getFluidFontSizeDe = (minSize, maxSize) =>
  getFluidSizeWithFullFormula('px', minSize, maxSize, 960, 1920)

export const getFluidSize = (baseSize, deltaSize = 12) =>
  `calc(${baseSize} + ${deltaSize} * ((100vw - ${breakpoints.S}) / 2816))`

export const getNegativeFluidSize = baseSize =>
  `calc( 0px - (${baseSize} + 12 * ((100vw - ${breakpoints.S}) / 2816)))`

export const footerHeight = '5vh'
export const footerMinHeight = '3.5em'

export const getFluidSizeWithFullFormula = (unit, minSize, maxSize, minVW, maxVW) =>
  `calc(${minSize}${unit} + (${maxSize} - ${minSize})*((100vw - ${minVW}px)/(${maxVW} - ${minVW})))`

export const getFluidSizeWithFullFormulaNegative = (unit, minSize, maxSize, minVW, maxVW) =>
  `calc(-${minSize}${unit} - (${maxSize} - ${minSize})*((100vw - ${minVW}px)/(${maxVW} - ${minVW})))`

export const getFluidSizeWithFullFormulaHeight = (unit, minSize, maxSize, minVH, maxVH) =>
  `calc(${minSize}${unit} + (${maxSize} - ${minSize})*((100vh - ${minVH}px)/(${maxVH} - ${minVH})))`

export const getFlexRowFluidMaxWidthPerItems = (
  unit,
  minSize,
  maxSize,
  minVW,
  maxVW,
  itemsPerRow,
) =>
  `calc((${minSize}${unit} + (${maxSize} - ${minSize})*((100vw - ${minVW}px)/(${maxVW} - ${minVW}))) * ${itemsPerRow +
    1});`

export const duration = {
  shortest: 150,
  shorter: 200,
  short: 250,
  standard: 300,
  complex: 375,
  enteringScreen: 225,
  leavingScreen: 195,
}

export const plpSidebarTabletLateralPadding = `${pxToRem(spacing(3))}rem`
