import { WhiteboardItemType } from '../../../../../../model/whiteboard'
import { DraggableComponent, Wrapper } from '../style'
import {
  convertPercentageToPx,
  convertPxToPercentage,
  roundNumber,
} from '../../../../../../libs/whiteboard'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef } from 'react'
import { Rnd } from 'react-rnd'
import useDraggable from '../useDraggable'
import whiteboardActions from '../../../../../../store/whiteboard/actions'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import useSingleAndDoubleClick from '../useSingleAndDoubleClick'
import {
  getAddedTextId,
  getMultiselectActionsEnabled,
  getMultiselectEnabled,
} from '../../../../../../store/whiteboard/selectors'
import { ResizeDirection } from 're-resizable'
import { useSearchParams } from '../../../../../../hooks/useSearchParams'
import { DraggableData, DraggableEvent } from 'react-draggable'
import { useGet4KDevice } from '../../../../../../hooks/useGet4KDevice'

type Props = {
  text: WhiteboardItemType
  updatePosition: () => void
  reference: any
  whiteboardIsLocked: boolean
}

const DraggableText: React.FC<Props> = ({
  text,
  updatePosition,
  reference,
  whiteboardIsLocked,
}) => {
  const { t } = useTranslation()
  const {
    id,
    selected,
    settings: {
      position: { x, y },
      style: { zIndex, width, height, fontSize: fontSizePercentage },
    },
    isNew,
  } = text

  const parentElement = document.getElementById('board-content')
  const rndElement = document.getElementById(id)

  const fontSize = convertPercentageToPx(fontSizePercentage || 0)

  const isTouchDevice = 'ontouchstart' in window
  const dispatch = useDispatch()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const textAreaRef = useRef(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const multiselectEnabled = useSelector(getMultiselectEnabled)
  const multiselectActionsEnabled = useSelector(getMultiselectActionsEnabled)

  const addedTextId = useSelector(getAddedTextId)

  const {
    handleMouseMove,
    handleDragStop,
    handleClick,
    handleDrag,
    handleResizeStop,
    handleResizeStart,
    handleResize,
  } = useDraggable(text, 'texts', x, y, reference)

  const debouncedChangeText = debounce(
    ({ newValue, height }: { height: number; newValue: string }) => {
      const item = {
        ...text,
        settings: {
          ...text.settings,
          style: {
            ...text.settings.style,
            height,
          },
        },
        value: newValue,
      } as WhiteboardItemType
      dispatch(whiteboardActions.changeTextItemValue({ item }))
    },
    250,
  )

  const OnInput = (e: any) => {
    const item = e.target

    item.style.height = '0'
    item.style.height = item.scrollHeight + 'px'
    debouncedChangeText({
      height: convertPxToPercentage(item.scrollHeight),
      newValue: item.value,
    })
  }

  const getFontSize = () => {
    if (textAreaRef?.current) {
      const css = getComputedStyle(textAreaRef.current)
      return Number(css.fontSize.replace('px', ''))
    }
  }

  const handleOnChange = (e: any) => {
    if (parentElement && rndElement) {
      const parentRect = parentElement.getBoundingClientRect()
      const elementRect = rndElement.getBoundingClientRect()

      const distanceBottom = parentRect.bottom - elementRect.bottom

      if (distanceBottom > (getFontSize() || fontSize) + 5) {
        const text = e.target
        const wrapper = reference.current?.resizableElement.current as HTMLElement

        if (wrapper.style.height !== text.scrollHeight + 'px') {
          wrapper.style.height = text.scrollHeight + 'px'
        }

        //text.setAttribute('style', 'height:' + text.scrollHeight + 'px;overflow-y:hidden')
        OnInput(e)
      }
    }
  }

  useEffect(() => {
    if (reference) {
      reference.current?.resizableElement.current?.setAttribute(
        'starting-x',
        `${convertPercentageToPx(x)}`,
      )
      reference.current?.resizableElement.current?.setAttribute(
        'starting-y',
        `${convertPercentageToPx(y)}`,
      )
      if (fontSize) {
        reference.current?.resizableElement.current?.setAttribute('starting-font-size', fontSize)
      }
      reference.current?.resizableElement.current?.setAttribute('id', `${id}`)
    }
  }, [id, reference, x, y, fontSize])

  useEffect(() => {
    if (reference) {
      const element = reference.current?.resizableElement.current as HTMLElement
      const { width, height } = element.getBoundingClientRect()

      element.setAttribute('starting-x', `${convertPercentageToPx(x)}`)
      element.setAttribute('starting-y', `${convertPercentageToPx(y)}`)
      element.setAttribute('starting-width', `${roundNumber(width)}`)
      element.setAttribute('starting-height', `${roundNumber(height)}`)
      element.setAttribute('id', `${id}`)

      reference.current?.updatePosition({
        x: convertPercentageToPx(x),
        y: convertPercentageToPx(y),
      })
    }
  }, [reference, x, y, id])

  const moveCaret = () => {
    const textArea = document.getElementById(`text-area-${id}`) as HTMLInputElement
    if (textArea) {
      textArea.setSelectionRange(textArea.value.length, textArea.value.length)
      textArea.focus()
    }
  }

  const onSingleClick = () => {
    if (isTouchDevice) return

    handleClick()
  }

  const onSingleTap = () => {
    const fakeTextArea = document.getElementById(`text-area-${id}`)
    if (fakeTextArea) {
      const isDragging = fakeTextArea.getAttribute('dragging')

      if (!isDragging) {
        handleClick(true)

        if (selected) {
          fakeTextArea.blur()
        }
      }
    }
  }
  const onDoubleTap = () => {
    if (!selected) {
      dispatch(whiteboardActions.toggleSelectedItem({ type: 'texts', item: text }))
    }

    const fakeTextArea = document.getElementById(`text-area-${id}`) as HTMLElement
    if (fakeTextArea) {
      fakeTextArea.removeAttribute('dragging')
    }

    setTimeout(() => {
      moveCaret()
    }, 250)
  }

  const onDoubleClick = () => {
    if (isTouchDevice) return
    if (!selected) {
      dispatch(whiteboardActions.toggleSelectedItem({ type: 'texts', item: text }))
    }

    setTimeout(() => {
      moveCaret()
    }, 150)
  }

  const handleClickAndTouch = useSingleAndDoubleClick(onDoubleTap, onSingleTap)
  const handleDoubleClick = useSingleAndDoubleClick(onDoubleClick, onSingleClick)

  useEffect(() => {
    if (addedTextId === text.id) {
      isTouchDevice ? onDoubleTap() : onDoubleClick()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedTextId, text.id])

  const onDragStart = () => {
    const fakeTextArea = document.getElementById(`text-area-${id}`)
    if (fakeTextArea) {
      fakeTextArea.setAttribute('dragging', 'true')
    }
  }

  const onDragStop = () => {
    handleDragStop()
    updatePosition()

    const fakeTextArea = document.getElementById(`text-area-${id}`)
    if (fakeTextArea) {
      fakeTextArea.removeAttribute('dragging')
    }
  }

  const props = { enableResizing: !whiteboardIsLocked ? undefined : !whiteboardIsLocked }
  const is4kDevice = useGet4KDevice()
  const readOnly = whiteboardIsLocked || multiselectActionsEnabled || multiselectEnabled
  const autoFocus = isNew || (selected && !multiselectActionsEnabled && !multiselectEnabled)

  return reference ? (
    <DraggableComponent
      {...props}
      ref={(c: Rnd | null) => (reference.current = c)}
      style={{
        zIndex,
      }}
      minHeight={is4kDevice ? 94 : 44}
      disableDragging={!selected}
      className={classnames({
        isTouchDevice,
        multiselectEnabled,
        selected,
        text: true,
        whiteboardIsLocked,
      })}
      bounds="parent"
      lockAspectRatio
      cancel={!selected ? '.wrapper' : ''}
      resizeHandleWrapperClass="cursor-pointer"
      onMouseMove={handleMouseMove}
      onDragStart={onDragStart}
      onDrag={(e: DraggableEvent, data: DraggableData) =>
        !whiteboardIsLocked && !multiselectEnabled && handleDrag(e, data)
      }
      onDragStop={onDragStop}
      onResizeStart={() => {
        const resizing = searchParams.get('resizing')
        if (!resizing) {
          searchParams.set('resizing', 'true')
          setSearchParams(searchParams, { replace: true })
        }
        handleResizeStart()
      }}
      onResizeStop={() => {
        handleResizeStop()
        setTimeout(() => {
          searchParams.delete('resizing')
          setSearchParams(searchParams, { replace: true })
        }, 200)
      }}
      onResize={(e: MouseEvent | TouchEvent, dir: ResizeDirection) => {
        handleResize(dir)
      }}
      default={{
        x: convertPercentageToPx(x),
        y: convertPercentageToPx(y),
        width: convertPercentageToPx(width),
        height: convertPercentageToPx(height),
      }}
    >
      <Wrapper ref={wrapperRef} onClick={handleDoubleClick} onTouchStart={handleClickAndTouch}>
        <textarea
          draggable={false}
          ref={textAreaRef}
          style={{
            fontSize: fontSize || getFontSize(),
          }}
          readOnly={readOnly}
          autoFocus={autoFocus}
          id={`text-area-${id}`}
          placeholder={t('Whiteboard.addText')}
          onChange={handleOnChange}
          defaultValue={text.value}
        />
      </Wrapper>
    </DraggableComponent>
  ) : null
}

export default DraggableText
