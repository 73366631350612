import classnames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { chooseIcon } from '../../../../libs'
import { getBrandByBrandCode } from '../../../../libs/brand'
import { getDefaultDoorId } from '../../../../libs/doors'
import { isPageMatches, isTableDevice } from '../../../../libs/url'
import { Mocos, Product } from '../../../../model/product'
import { toggleDislikeListAction } from '../../../../store/actions'
import { showRtrSelector } from '../../../../store/app/selectors'
import { brandsSelector } from '../../../../store/brands/selectors'
import {
  cartEditableSelector,
  cartProductsFilteredByActiveDoorsSelector,
  insertToCartRequestSelector,
} from '../../../../store/cart/selectors'
import { couvetteVideoSelector } from '../../../../store/couvetteVideo/selectors'
import { customerSelector } from '../../../../store/customer/selectors'
import { dislikeSelector } from '../../../../store/dislike/selectors'
import { cartModeSelector } from '../../../../store/stars/selectors'
import { wishlistProductsSelector } from '../../../../store/wishlist/selectors'
import { breakpoints, getFluidFontSize, palette, pxToRem, spacing } from '../../../../style/theme'
import DislikeBtn from '../../../DislikeBtn'
import PlayPauseVideoBtn from '../../../PlayPauseVideoBtn'
import RCIcon from '../../../UI/RCIcon'
import BestsellerIcon from '../BestsellerIcon'
import BrandLogo from '../BrandLogo'
import PurchaseControls from '../PurchaseControls/PurchaseControls'
import { defineIconsDisplayed, getUniqueSizes, toggleDislikeAll } from './helpers'

const StyledPlayPauseVideoBtn = styled(PlayPauseVideoBtn)`
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  i,
  img {
    height: 0.75rem;
    width: 0.75rem;
  }
`

const cornerRadius = '0.74vh'

const Header = styled.div`
  width: 100%;
  min-height: ${pxToRem(230)}rem;
  position: relative;
  display: flex;
  margin-top: 3vh;
  background-color: ${palette.gallery};
  border-radius: ${cornerRadius} ${cornerRadius} 0 0;
  color: rgba(0, 0, 0, 0.65);
  display: flex;

  .bestseller span {
    color: ${palette.white};
  }

  @media screen and (max-width: ${breakpoints.L}) {
    min-height: ${pxToRem(142.3)}rem;
  }

  @media screen and (max-width: ${breakpoints.M}) {
    min-height: ${pxToRem(118.3)}rem;
  }
`

const Tag = styled.div`
  width: 4.16%;
  border-radius: 0 0 ${cornerRadius} 0;
  display: flex;
  font-size: 0.7em;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  transform: rotate(180deg);
  writing-mode: vertical-rl;

  &.new {
    background: linear-gradient(0deg, ${palette.chalky} 0%, ${palette.diSerria} 100%);
  }

  &.carry-over {
    background: linear-gradient(0deg, ${palette.riptide} 0%, ${palette.turquoise} 100%);
  }

  &.digital-preview {
    background: linear-gradient(0deg, ${palette.curiousBlue} 0%, ${palette.biscay} 100%);
    color: ${palette.white};
  }
`
Tag.displayName = 'Tag'

const HeaderMain = styled.div`
  width: 100%;
  margin: 0 0 1em 1em;
  column-gap: 0.2em;
  row-gap: 0.5em;
`

const TopRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  grid-column-end: span 3;
`

const Title = styled.div`
  display: flex;
  text-transform: uppercase;
  flex: 1;
  margin-top: 1em;
  align-items: start;

  &.break-title-words {
    @media screen and (max-width: ${breakpoints.L}) {
      max-width: ${pxToRem(140)}rem;
    }

    @media screen and (max-width: ${breakpoints.M}) {
      max-width: ${pxToRem(500)}rem;
    }
  }

  .top-header__prod-campaign {
    font-weight: bold;
    margin-right: ${pxToRem(10)}rem;
  }
`

const Icons = styled.span`
  display: flex;
  align-items: center;
  font-size: ${getFluidFontSize('1rem')};

  > * {
    margin-right: 0.75em;
  }
`

const LogoAndDislike = styled.div`
  position: absolute;
  right: ${pxToRem(spacing(11))}rem;
  top: ${pxToRem(spacing(-1.5))}rem;

  @media screen and (max-width: ${breakpoints.L}) {
    right: ${pxToRem(spacing(7))}rem;
    top: ${pxToRem(spacing(-0.5))}rem;
  }

  @media screen and (max-width: ${breakpoints.M}) {
    top: ${pxToRem(spacing(-0.7))}rem;
  }
`

const Dislike = styled(DislikeBtn)<{ dislikeVisible: boolean }>`
  visibility: ${props => (props.dislikeVisible ? 'visible' : 'hidden')};
`

const CouvetteBrandLogo = styled(BrandLogo)`
  width: 4vw;
  height: 2.5vw;
  background: ${palette.white};
  border-radius: ${pxToRem(5)}rem;
  box-shadow: 0 ${pxToRem(1)}rem ${pxToRem(6)}rem 0 rgba(0, 0, 0, 0.08);
  z-index: 1;
  padding: ${pxToRem(5)}rem;
  transform: translate(1em, -70%);
  display: flex;
  align-items: center;

  .brand-logo {
    padding: 0.23vw 0.18vw;
  }

  @media screen and (max-width: ${breakpoints.M}) {
    width: 6vw;
    height: 3.5vw;
  }
`

const Details = styled.div``

const ModelCodeAndPlayIcon = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  align-items: center;
  column-gap: 0.5em;
`

const ModelCode = styled.div`
  text-transform: uppercase;
  white-space: nowrap;
  color: ${palette.curiousBlue};
`
ModelCode.displayName = 'ModelCode'

const StyleName = styled.div`
  text-transform: capitalize;
  color: ${palette.kobi};
  font-size: 0.83em;

  &.break-words {
    @media screen and (max-width: ${breakpoints.L}) {
      max-width: ${pxToRem(100)}rem;
    }
  }
`

const Controls = styled.div`
  position: absolute;
  right: 1em;
  bottom: 1em;
  padding-top: 1.5em;
  display: grid;
  grid-template-columns: min-content min-content;
  grid-template-rows: 1fr 1fr;
  grid-column-start: 3;
  justify-content: flex-end;
  row-gap: 0.5em;
  column-gap: 0.5em;
  align-items: flex-start;
  > *:last-child {
    grid-column-start: 2;
  }
`
Controls.displayName = 'Controls'

interface Props {
  showWishlist: boolean
  hideHeader: boolean
  mocos: Mocos
  showBrandLogo: boolean
  couvette: Product
  hasRTR: boolean
}

const CouvetteHeader: React.FC<Props> = ({
  hideHeader,
  showBrandLogo,
  couvette,
  mocos,
  showWishlist,
  hasRTR,
}) => {
  const { t } = useTranslation()

  const couvetteVideo = useSelector(couvetteVideoSelector)
  const insertToCartRequest = useSelector(insertToCartRequestSelector)
  const cartEditable = useSelector(cartEditableSelector)
  const customer = useSelector(customerSelector)
  const dislike = useSelector(dislikeSelector)
  const wishlistItems = useSelector(wishlistProductsSelector)
  const cartProducts = useSelector(cartProductsFilteredByActiveDoorsSelector)
  const brands = useSelector(brandsSelector)
  const mode = useSelector(cartModeSelector)
  const showRTRIcon = useSelector(showRtrSelector(hasRTR))

  const onCartPage = isPageMatches('cart')

  const dispatch = useDispatch()

  const {
    labelVideo,
    modelVideo,
    modelCode,
    styleName,
    marketingTheme,
    materialBestSellerRanking,
    modelTag,
    newOld,
  } = couvette

  const { iconVto } = defineIconsDisplayed(mocos)

  const labelCollectionCouvette = marketingTheme ? marketingTheme.label : null

  const mocoValues = Object.values(couvette.mocos)
  const isBestseller = mocoValues.some(moco => moco.isBestseller)
  const isAdvModel = mocoValues.some(moco => moco.isAdv)

  const isBestsellerIcon = isBestseller && (
    <BestsellerIcon count={Number(materialBestSellerRanking?.label)} type="bestseller-filled" />
  )

  const mocoCodesNotInCart = mocoValues.filter(
    moco => !cartProducts.find(product => product.mocoCode === moco.mocoCode),
  )
  const thereAreItemsNotInCart = mocoCodesNotInCart.length > 0
  const thereArePendingAddToCartRequestForThisModel = insertToCartRequest.find(
    request => request.mocoCode.split('__')[0] === couvette.modelCode,
  )

  const brand = getBrandByBrandCode(brands, couvette.brandCode)

  const modelTagLabel = modelTag ? modelTag.label : ''

  const uniqueSizes = getUniqueSizes(mocos)

  const mocosArray = Object.values(mocos)
  const mocoCodes = mocosArray.map(el => el.mocoCode)
  const defaultDoorId = getDefaultDoorId(customer.doors)
  const dislikeVisible =
    cartEditable && thereAreItemsNotInCart && !thereArePendingAddToCartRequestForThisModel

  const iconsLength = [newOld, showRTRIcon, iconVto, isAdvModel, isBestseller, labelVideo].filter(
    p => !!p,
  ).length

  return (
    <Header>
      <Tag
        className={classnames({
          new: modelTagLabel === 'New',
          'digital-preview': modelTagLabel === 'Digital Preview',
          'carry-over': modelTagLabel !== 'New' && modelTagLabel !== 'Digital Preview', // Carry Over and old releases will have the same style
        })}
      >
        {modelTagLabel === 'New' && t('Plp.newLabel')}
        {modelTagLabel === 'Digital Preview' && t('Plp.digitalPreviewLabel')}
        {modelTagLabel === 'Carry Over' && t('Plp.carryOverLabel')}
        {modelTagLabel !== 'New' &&
          modelTagLabel !== 'Digital Preview' &&
          modelTagLabel !== 'Carry Over' &&
          modelTagLabel}
      </Tag>
      <HeaderMain>
        <TopRow>
          <Title className={iconsLength > 3 ? 'break-title-words' : ''}>
            {!hideHeader && labelCollectionCouvette && (
              <span className="top-header__prod-campaign">{labelCollectionCouvette}</span>
            )}
            <Icons className="top-header__icons header-icons">
              {newOld && chooseIcon(newOld.label, 'header-icons__single')}
              {showRTRIcon && <RCIcon type="3D-plp" iconClass="header-icons__single" />}
              {iconVto && <RCIcon type="vto-pink" iconClass="header-icons__single" />}
              {isAdvModel && <RCIcon type="adv" iconClass="header-icons__single" />}
              {isBestsellerIcon}
              {labelVideo && isTableDevice() && (
                <StyledPlayPauseVideoBtn
                  classSmartLook={'video_campagna'}
                  couvetteVideo={couvetteVideo}
                  media={labelVideo}
                  uniqueId={modelCode}
                  headerIcon
                />
              )}
            </Icons>
          </Title>
          <LogoAndDislike>
            {showBrandLogo && brand && <CouvetteBrandLogo brandCode={brand.code} />}
          </LogoAndDislike>
          <Dislike
            dislikeVisible={!!dislikeVisible}
            handleClick={() =>
              dislikeVisible
                ? toggleDislikeAll({
                    mocos,
                    dislike,
                    cartProducts,
                    toggleDislike: (
                      items: {
                        mocoCode: string
                        upcs: string[]
                        modelCode: string | undefined
                      }[],
                    ) => dispatch(toggleDislikeListAction(items)),
                  })
                : undefined
            }
          />
        </TopRow>
        <Details>
          <ModelCodeAndPlayIcon>
            <ModelCode>{couvette.modelCode.replace('_', ' ')}</ModelCode>
            {modelVideo && isTableDevice() && (
              <StyledPlayPauseVideoBtn
                classSmartLook={'video_prodotto'}
                couvetteVideo={couvetteVideo}
                media={modelVideo}
                uniqueId={modelCode}
                headerIcon
              />
            )}
          </ModelCodeAndPlayIcon>
          <StyleName className={uniqueSizes.length > 1 ? 'break-words' : ''}>
            {styleName && styleName.label}
          </StyleName>
        </Details>
        <Controls>
          {uniqueSizes.map((sizeKey, index) => {
            const productsSizes = mocosArray
              .map(m => {
                const sizes = Object.values(m.sizes).filter(s => s.size === sizeKey)
                const sizesWithEnabledDoors = sizes.map(s => ({
                  ...s,
                  enabledDoors: m.enabledDoors,
                }))
                return sizesWithEnabledDoors
              })
              .reduce((tot, cur) => [...tot, ...cur], [])

            const filteredCartProducts = cartProducts.filter(
              cartProduct =>
                mocoCodes.includes(cartProduct.mocoCode) && cartProduct.size === sizeKey,
            )

            const itemsInWishlist = productsSizes.filter(elem =>
              wishlistItems.find(el => el.upc === elem.upc),
            )

            const disableWishlist = Boolean(
              !cartEditable ||
                (thereArePendingAddToCartRequestForThisModel &&
                  thereArePendingAddToCartRequestForThisModel.size === sizeKey),
            )
            if (mode === 'stars' && onCartPage) {
              return null
            }
            return (
              mocosArray.filter(moco =>
                Object.values(moco.sizes).find(s => s.size === sizeKey),
              ) && (
                <PurchaseControls
                  key={index}
                  productSizes={productsSizes}
                  cartProducts={filteredCartProducts}
                  cartEditable={cartEditable}
                  doors={defaultDoorId ? [defaultDoorId] : []}
                  showWishlist={showWishlist}
                  itemsInWishlist={itemsInWishlist}
                  disableWishlist={disableWishlist}
                  headerControlCode={mocosArray.length ? mocosArray[0].modelCode : ''}
                  brandCode={mocosArray[0].brandCode}
                />
              )
            )
          })}
        </Controls>
      </HeaderMain>
    </Header>
  )
}

export default CouvetteHeader
