import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import styled from 'styled-components'

import { breakpoints, breakpointsCross, palette, pxToRem, spacing } from '../../style/theme'

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title)

const GraphAndLegend = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  gap: 1rem;

  @media (max-width: ${breakpoints.L}) {
    flex-direction: column;
  }
`

const LabelsWrapper = styled.div`
  flex: 1;
  padding-top: ${pxToRem(spacing(2))}rem;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
`

const Label = styled.span`
  color: ${palette.black};
  margin-right: 1em;
  white-space: nowrap;
`

const Dot = styled.span<{ color?: string }>`
  height: ${pxToRem(spacing(1))}rem;
  width: ${pxToRem(spacing(1))}rem;
  background-color: ${props => props.color};
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5em;
`

// https://www.chartjs.org/docs/latest/configuration/responsive.html#important-note
const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  flex: 3.5;

  @media (max-width: ${breakpointsCross.L.max}) {
    flex: 2;
  }
`

const DoughnutGraph: React.FC<{
  data: number[]
  labels: string[]
  config?: {
    backgroundColor?: string[]
    borderColor?: string
    hoverBackgroundColor?: string
  }
}> = ({ data, labels, config }) => {
  return (
    <GraphAndLegend>
      <Wrapper>
        <Doughnut
          data={{
            labels,
            datasets: [
              {
                data,
                borderWidth: 1,
                ...config,
              },
            ],
          }}
          height={100}
          width={100}
          options={{
            radius: '80%',
            cutout: '80%',
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
          }}
        />
      </Wrapper>
      <LabelsWrapper>
        {labels.map((label, index) => (
          <Label key={label}>
            <Dot
              color={
                config?.backgroundColor &&
                config?.backgroundColor[index % config?.backgroundColor.length]
              }
            />
            {label}
          </Label>
        ))}
      </LabelsWrapper>
    </GraphAndLegend>
  )
}

export default DoughnutGraph
