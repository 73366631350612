import React from 'react'
import styled from 'styled-components'

import CheckIcon from '../icons/CheckIcon'
import { getFluidSizeWithFullFormula, palette } from '../../style/theme'

const Wrapper = styled.div`
  padding-top: ${getFluidSizeWithFullFormula('px', 8, 12, 1366, 3840)};
  padding-bottom: ${getFluidSizeWithFullFormula('px', 8, 12, 1366, 3840)};
  padding-left: ${getFluidSizeWithFullFormula('px', 18, 24, 1366, 3840)};
  padding-right: ${getFluidSizeWithFullFormula('px', 18, 24, 1366, 3840)};
  border: solid 1px ${palette.shadowBlue};
  display: flex;
  align-items: center;
  border-radius: 2rem;
  user-select: none;
  font-size: ${getFluidSizeWithFullFormula('px', 12, 24, 1366, 3840)};

  &.active {
    background-color: ${palette.white};
    color: ${palette.black};
  }

  &.disabled {
    opacity: 0.5;
  }

  svg {
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
  }
`

const Label = styled.label`
  display: flex;
  align-items: center;
  text-transform: capitalize;
`

type Props = {
  disabled: boolean
  className?: string
  name?: string
  onChange: () => void
  active?: boolean
}

const AfaFilterChip: React.FC<Props> = ({
  disabled,
  className,
  name,
  onChange,
  active,
  children,
}) => {
  const id = `afa-chip-${name}`
  return (
    <Wrapper className={[className, active ? 'active' : '', disabled ? 'disabled' : ''].join(' ')}>
      <input
        onChange={() => !disabled && onChange()}
        className="visually-hidden"
        id={id}
        type="checkbox"
        name={name}
      />
      <Label htmlFor={id}>
        {active && <CheckIcon />} {children}
      </Label>
    </Wrapper>
  )
}

export default AfaFilterChip
