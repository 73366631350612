import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Spinner from '../../../components/Spinner'
import Toggle from '../../../components/Toggle'
import RCIcon from '../../../../../components/UI/RCIcon'
import { getSelectedBoard } from '../../../../../store/whiteboard/selectors'
import whiteboardActions from '../../../../../store/whiteboard/actions'
import {
  Section,
  ModalWithPosition,
  Content,
  SectionTop,
  SectionTitle,
  Options,
  Option,
  Info,
  RadioIcon,
  InputCheckbox,
  CheckboxLabel,
  RadioInput,
} from './style'

const SettingsModal: React.FC<{ onCancel: () => void }> = ({ onCancel }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const selectedBoard = useSelector(getSelectedBoard)

  const {
    showProductName = false,
    showProductNumber = false,
    showColorName = false,
    showColorCode = false,
    showWholesale = false,
    showSuggested = false,
    labelPosition = 'bottom',
    showItemInCart = true,
  } = selectedBoard || {}

  const boardSettings = {
    showProductName,
    showProductNumber,
    showColorName,
    showColorCode,
    showWholesale,
    showSuggested,
    labelPosition,
    showItemInCart,
  }

  const isProductInfoToggleActive =
    showProductName || showProductNumber || showColorName || showColorCode || false
  const isPriceInfoToggleActive = showWholesale || showSuggested || false

  const settings = [
    {
      title: t('Whiteboard.boardSettings.productInfo.title'),
      label: t('Whiteboard.boardSettings.label'),
      showSection: true,
      showOptions: isProductInfoToggleActive,
      onToggleChange: () => {
        dispatch(
          whiteboardActions.setSettings({
            ...boardSettings,
            showProductName: !isProductInfoToggleActive,
            showProductNumber: !isProductInfoToggleActive,
            showColorName: !isProductInfoToggleActive,
            showColorCode: !isProductInfoToggleActive,
          }),
        )
      },
      options: [
        {
          iconType: '',
          title: t('Whiteboard.boardSettings.productInfo.productName'),
          id: 'product-name',
          state: showProductName,
          onChange: () => {
            dispatch(
              whiteboardActions.setSettings({
                ...boardSettings,
                showProductName: !showProductName,
              }),
            )
          },
        },
        {
          title: t('Whiteboard.boardSettings.productInfo.productNumber'),
          id: 'product-number',
          state: showProductNumber,
          onChange: () => {
            dispatch(
              whiteboardActions.setSettings({
                ...boardSettings,
                showProductNumber: !showProductNumber,
              }),
            )
          },
        },
        {
          title: t('Whiteboard.boardSettings.productInfo.colorName'),
          id: 'color-name',
          state: showColorName,
          onChange: () => {
            dispatch(
              whiteboardActions.setSettings({ ...boardSettings, showColorName: !showColorName }),
            )
          },
        },
        {
          title: t('Whiteboard.boardSettings.productInfo.colorCode'),
          id: 'color-code',
          state: showColorCode,
          onChange: () => {
            dispatch(
              whiteboardActions.setSettings({ ...boardSettings, showColorCode: !showColorCode }),
            )
          },
        },
      ],
    },
    {
      title: t('Whiteboard.boardSettings.price.title'),
      label: t('Whiteboard.boardSettings.label'),
      showSection: true,
      showOptions: isPriceInfoToggleActive,
      onToggleChange: () => {
        dispatch(
          whiteboardActions.setSettings({
            ...boardSettings,
            showSuggested: !isPriceInfoToggleActive,
            showWholesale: !isPriceInfoToggleActive,
          }),
        )
      },
      options: [
        {
          title: t('Whiteboard.boardSettings.price.wholesalePrice'),
          id: 'wholesale-price',
          state: showWholesale,
          onChange: () => {
            dispatch(
              whiteboardActions.setSettings({ ...boardSettings, showWholesale: !showWholesale }),
            )
          },
        },
        {
          title: t('Whiteboard.boardSettings.price.suggestedPrice'),
          id: 'suggested-price',
          state: showSuggested,
          onChange: () => {
            dispatch(
              whiteboardActions.setSettings({ ...boardSettings, showSuggested: !showSuggested }),
            )
          },
        },
      ],
    },
    {
      title: t('Whiteboard.boardSettings.labelPosition.title'),
      type: 'radio',
      showSection: isProductInfoToggleActive || isPriceInfoToggleActive,
      showOptions: true,
      options: [
        {
          title: t('Whiteboard.boardSettings.labelPosition.top'),
          id: 'top',
          iconType: 'icon-position-top',
          state: labelPosition === 'top',
          onChange: () => {
            dispatch(whiteboardActions.setSettings({ ...boardSettings, labelPosition: 'top' }))
          },
        },
        {
          title: t('Whiteboard.boardSettings.labelPosition.bottom'),
          id: 'bottom',
          state: labelPosition === 'bottom',
          iconType: 'icon-position-bottom',
          onChange: () => {
            dispatch(whiteboardActions.setSettings({ ...boardSettings, labelPosition: 'bottom' }))
          },
        },
        {
          title: t('Whiteboard.boardSettings.labelPosition.right'),
          id: 'right',
          state: labelPosition === 'right',
          iconType: 'icon-position-right',
          onChange: () => {
            dispatch(whiteboardActions.setSettings({ ...boardSettings, labelPosition: 'right' }))
          },
        },
        {
          title: t('Whiteboard.boardSettings.labelPosition.left'),
          id: 'left',
          state: labelPosition === 'left',
          iconType: 'icon-position-left',
          onChange: () => {
            dispatch(whiteboardActions.setSettings({ ...boardSettings, labelPosition: 'left' }))
          },
        },
      ],
    },
    {
      title: t('Whiteboard.boardSettings.itemsInCart'),
      options: [],
      showSection: true,
      showOptions: showItemInCart,
      onToggleChange: () => {
        dispatch(
          whiteboardActions.setSettings({ ...boardSettings, showItemInCart: !showItemInCart }),
        )
      },
    },
  ]

  const modalRef = useRef(null)

  useEffect(() => {
    if (modalRef.current) {
      //Da scommentare se Lux vuole la modale allineata con l'icona
      // const modal = modalRef.current as HTMLElement
      // const cogsIcon = document.getElementById('whiteboard-icon-settings') as HTMLElement
      // const { top, left } = cogsIcon.getBoundingClientRect()
      // const modalContent = modal.childNodes.item(0) as HTMLElement
      // const icon = modalContent.childNodes.item(0) as HTMLElement
      // const iconCoordinates = icon.getBoundingClientRect()
      //modalContent.style.right = `${iconCoordinates.right - left}px`
    }
  }, [])

  return (
    <ModalWithPosition
      ref={modalRef}
      title={t('Whiteboard.boardSettings.title')}
      onCancel={onCancel}
    >
      <Content>
        {!selectedBoard ? (
          <Spinner />
        ) : (
          settings.map(
            ({ options, title, type, label, showOptions, showSection, onToggleChange }, index) => {
              if (!showSection) return

              return (
                <Section key={index}>
                  <SectionTop>
                    <SectionTitle>{title}</SectionTitle>
                    {onToggleChange && <Toggle locked={showOptions} action={onToggleChange} />}
                  </SectionTop>

                  {showOptions && (
                    <>
                      {label && <Info>Label Option</Info>}

                      {options?.length > 0 && (
                        <Options type={type}>
                          {options.map(({ iconType, id, onChange, title, state }) => (
                            <Option key={id} htmlFor={id} type={type}>
                              {type === 'radio' ? (
                                <>
                                  {iconType && (
                                    <RadioIcon checked={state}>
                                      <RCIcon type={iconType} size="20px" />
                                    </RadioIcon>
                                  )}
                                  <RadioInput
                                    id={id}
                                    checked={state}
                                    type="radio"
                                    onChange={onChange}
                                  />
                                </>
                              ) : (
                                <InputCheckbox
                                  type="checkbox"
                                  onChange={onChange}
                                  checked={state}
                                  id={id}
                                />
                              )}
                              <CheckboxLabel type={type}>{title}</CheckboxLabel>
                            </Option>
                          ))}
                        </Options>
                      )}
                    </>
                  )}
                </Section>
              )
            },
          )
        )}
      </Content>
    </ModalWithPosition>
  )
}

export default SettingsModal
