import React, { SVGProps } from 'react'

const CalendarIconAfa: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.544 0c.442 0 .8.327.8.73v.324h3.023V.73c0-.403.358-.73.8-.73.442 0 .8.327.8.73v.324h1.077c1.08 0 1.956.799 1.956 1.784v7.378c0 .985-.876 1.784-1.956 1.784H1.956C.876 12 0 11.201 0 10.216V2.838c0-.985.876-1.784 1.956-1.784h.788V.73c0-.403.358-.73.8-.73zM1.6 6.73v3.486c0 .18.16.325.356.325h8.088c.197 0 .356-.146.356-.325V6.73H1.6zm8.8-1.46H1.6V2.838c0-.18.16-.324.356-.324h.788v.324c0 .403.358.73.8.73.442 0 .8-.327.8-.73v-.324h3.023v.324c0 .403.358.73.8.73.442 0 .8-.327.8-.73v-.324h1.077c.197 0 .356.145.356.324V5.27z"
      />
    </svg>
  )
}

export default CalendarIconAfa
