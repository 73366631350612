import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { CartProduct, UpdateCartPayloadItem } from '../../../../model/cart'
import { updateCartAction } from '../../../../store/actions'
import {
  breakpoints,
  breakpointsNoUnit,
  getFluidFontSize,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
} from '../../../../style/theme'
import Icon from '../../../../styleguide/Icon'
import ProductImage from '../../../Product/ProductImage/ProductImage'
import { Badge } from 'antd'
import RCIcon from '../../../UI/RCIcon'
import { getAntBadgeCountCss } from '../../../CommonComponents'
import { getProductImageUrl } from '../../../../libs/productImages'

const SizeLabel = styled.div`
  color: ${palette.kobi};
  padding: 0 0.75em;
  white-space: nowrap;
  border-right: 1px solid ${palette.gallery};
`

const DeleteButton = styled.button`
  padding: 0 0.75em;
  border-right: 1px solid ${palette.gallery};
`

const Quantity = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0 0.75em;
  border-right: 1px solid ${palette.gallery};
  opacity: 0.5;

  .ant-badge {
    font-size: inherit;
  }

  ${getAntBadgeCountCss(palette.hibiscus)}
`

const ConfirmedQuantity = styled(Quantity)`
  opacity: 1;
  ${getAntBadgeCountCss(palette.dustyGray)}
`

const Item = styled.div`
  position: relative;
  min-height: 21vmin;
  border-bottom: ${pxToRem(1)}rem solid ${palette.mercury};
  background: ${palette.white};
  z-index: 0;
  overflow: hidden;

  @media screen and (max-width: ${breakpoints.M}) and (orientation: landscape) {
    min-height: 21vmin;
  }
`

const Grid = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
`

const Image = styled(ProductImage)`
  &.product-imag {
    width: 70%;
  }
`

const RightCol = styled.div`
  text-align: right;
  padding: 4%;
  padding-left: 0;
`

const ProdInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-transform: uppercase;
  white-space: nowrap;
  color: ${palette.biscay};
  height: 100%;
`

const BriefInfo = styled.div`
  padding-bottom: 1em;
`

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const PurchaseControl = styled.div`
  margin-top: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid ${palette.gallery};
  border-radius: 6rem;
  background-color: ${palette.white};
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  line-height: normal;
  font-size: ${getFluidSizeWithFullFormula(
    'px',
    8,
    24,
    breakpointsNoUnit.XS,
    breakpointsNoUnit.XL,
  )};
  padding: 0.5em 0;
`

const FrontColor = styled.div`
  color: ${palette.curiousBlue};
  font-size: ${getFluidFontSize('10px')};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: break-spaces;
`

type CartProductsWithQuantity = {
  products: CartProduct[]
  totalQuantity: number
  totalConfirmedQuantity: number
}

type CartProductsBySize = Record<string, CartProductsWithQuantity>

type Props = {
  cartProduct: CartProduct
  cartProducts?: CartProduct[]
  style?: any
}

export const CouvetteItemNonInAssortment: React.FC<Props> = ({
  cartProduct,
  cartProducts,
  style,
}) => {
  const dispatch = useDispatch()

  const { colorCode, modelCode } = cartProduct

  const products =
    cartProducts &&
    cartProducts
      .filter(c => c.modelCode === modelCode && c.colorCode === colorCode)
      .reduce((result, product) => {
        if (!result[product.size]) {
          result[product.size] = { totalQuantity: 0, totalConfirmedQuantity: 0, products: [] }
        }
        result[product.size].totalQuantity += product.quantity
        result[product.size].totalConfirmedQuantity += product.confirmedQuantity || 0
        result[product.size].products.push(product)
        return result
      }, {} as CartProductsBySize)

  const imgPath = getProductImageUrl({ path: cartProduct.urlImage || '' })

  return (
    <Item style={style}>
      <Grid>
        <Image clickable={false} imgPath={imgPath} />
        <RightCol>
          <ProdInfo className="couvette-item__prod-info prod-info">
            <BriefInfo>
              <FrontColor>
                <span>{modelCode}</span>
                <span>{colorCode}</span>
              </FrontColor>
              <FrontColor>
                <span>{cartProduct.modelDescription || ''}</span>
              </FrontColor>
            </BriefInfo>
            <Controls>
              {products &&
                Object.entries(products).map(([size, cartProductsWithQuantity]) => {
                  const { totalQuantity, totalConfirmedQuantity } = cartProductsWithQuantity
                  const updateCartPayload = cartProductsWithQuantity.products.map(product => {
                    return {
                      ...product,
                      confirmedQuantity: product.confirmedQuantity || 0,
                      oldQnt: product.quantity,
                      quantity: 0,
                      requestStatus: 'waiting',
                    } as UpdateCartPayloadItem
                  })

                  return (
                    <PurchaseControl key={size}>
                      <SizeLabel>{size}</SizeLabel>
                      {!!totalQuantity && totalQuantity > 0 && (
                        <>
                          <DeleteButton
                            onClick={() => {
                              dispatch(updateCartAction(updateCartPayload))
                            }}
                          >
                            <Icon type="delete" theme="outlined" />
                          </DeleteButton>
                          <Quantity>
                            <Badge count={totalQuantity} overflowCount={999} className="in-cart">
                              <Icon type="shopping-cart" />
                            </Badge>
                          </Quantity>
                        </>
                      )}
                      {!!totalConfirmedQuantity && totalConfirmedQuantity > 0 && (
                        <ConfirmedQuantity>
                          <Badge count={totalConfirmedQuantity} overflowCount={999}>
                            <RCIcon type="processed-icon" />
                          </Badge>
                        </ConfirmedQuantity>
                      )}
                    </PurchaseControl>
                  )
                })}
            </Controls>
          </ProdInfo>
        </RightCol>
      </Grid>
    </Item>
  )
}

export default CouvetteItemNonInAssortment
