import { Dispatch, SetStateAction, useState } from 'react'
import styled from 'styled-components'
import { getFluidSizeWithFullFormula as gF, palette, pxToRem } from '../../../../style/theme'
import useFocus from '../helpers/setFocus'

const Wrapper = styled.div`
  width: ${gF('px', 525, 1137.5, 1366, 3840)};
  min-height: ${gF('px', 60, 92, 1366, 3840)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid 1px ${palette.bermudaGray};
  border-radius: ${pxToRem(4)}rem;
  padding: 0 ${gF('px', 25, 50, 1366, 3840)};
`
const MagnifyingGlassImg = styled.div`
  height: ${gF('px', 22.5, 32, 1366, 3840)};
  width: ${gF('px', 22.5, 32, 1366, 3840)};
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='m22.65 20.903.597.597a1.1 1.1 0 0 1 0 1.556l-.311.31a1.1 1.1 0 0 1-1.556 0l-4.858-4.857A9.558 9.558 0 0 1 10.8 20.4a9.6 9.6 0 0 1-9.6-9.6 9.6 9.6 0 0 1 9.6-9.6 9.6 9.6 0 0 1 9.6 9.6 9.56 9.56 0 0 1-1.994 5.859l1.637 1.637c.15.115.294.241.43.377l2 2c.07.07.128.147.177.23zM10.8 18a7.2 7.2 0 1 0 0-14.4 7.2 7.2 0 0 0 0 14.4z' fill='%237888A5'/%3E%3C/svg%3E%0A");
  margin-right: ${gF('px', 15, 25, 1366, 3840)};
  &.d-none {
    display: none;
  }
`
const Cancel = styled.div`
  height: ${gF('px', 22.5, 32, 1366, 3840)};
  width: ${gF('px', 22.5, 32, 1366, 3840)};
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m9.246 8.006 6.5-6.503a.89.89 0 0 0 0-1.243c-.337-.355-.887-.338-1.243 0l-6.5 6.502L1.503.26A.89.89 0 0 0 .26.26c-.355.337-.338.888 0 1.243l6.5 6.503-6.5 6.502a.89.89 0 0 0 0 1.243c.177.178.39.249.621.249a.92.92 0 0 0 .622-.249l6.5-6.502 6.5 6.502c.178.178.391.249.622.249a.92.92 0 0 0 .622-.249.89.89 0 0 0 0-1.243l-6.5-6.502z' fill='%23031434'/%3E%3C/svg%3E%0A");
  cursor: pointer;
  &.d-none {
    display: none;
  }
`
const SearchBar = styled.input.attrs({ type: 'text' })`
  width: 100%;
  height: 100%;
  font-size: ${gF('px', 18, 36, 1366, 3840)};
  font-family: 'GilmerRegular', sans-serif;
  font-weight: bold;
  letter-spacing: 0.26px;
  color: ${palette.bermudaGray};
  border: none;
  &:focus {
    outline: none;
  }

  ::placeholder {
    color: ${palette.bermudaGray};
  }
`

type Props = {
  doorSearch: string
  setDoorSearch: Dispatch<SetStateAction<string>>
}

const AfaDuplicateCartSearchBar: React.FC<Props> = ({ doorSearch, setDoorSearch }) => {
  const [onFocus, setOnFocus] = useState(false)
  const [inputRef, setInputFocus] = useFocus()

  return (
    <Wrapper>
      <MagnifyingGlassImg onClick={() => setInputFocus()} className={onFocus ? 'd-none' : ''} />
      <SearchBar
        ref={inputRef}
        onFocus={() => setOnFocus(!onFocus)}
        onBlur={() => setOnFocus(!onFocus)}
        value={doorSearch}
        onChange={e => setDoorSearch(e.currentTarget.value)}
        placeholder={onFocus ? '' : 'Search doors'}
      />
      <Cancel onClick={() => setDoorSearch('')} className={doorSearch === '' ? 'd-none' : ''} />
    </Wrapper>
  )
}

export default AfaDuplicateCartSearchBar
