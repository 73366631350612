import styled from 'styled-components/macro'
import { getFluidSizeWithFullFormula as gF, palette } from '../../../style/theme'
import React, { FC } from 'react'
import RCIcon from '../../../components/UI/RCIcon'
import { useSearchParams } from '../../../hooks/useSearchParams'
import { useTranslation } from 'react-i18next'
import { useToggleAaMapTrends } from '../../../hooks/useHandleOpenPages'
import { firstLetterCapital } from '../../../helpers/genericHelper'

const Wrapper = styled.div`
  cursor: pointer;
  height: ${gF('px', 62, 124, 1366, 3840)};
  width: ${gF('px', 188, 386, 1366, 3840)};
  border-radius: ${gF('px', 20, 40, 1366, 3840)};
  font-family: 'EuclidCircularB-Regular', sans-serif;
  font-size: ${gF('px', 16, 32, 1366, 3840)};
  padding: ${gF('px', 18, 36, 1366, 3840)} ${gF('px', 6, 12, 1366, 3840)};
  display: flex;
  align-items: center;

  span {
    margin: 0 ${gF('px', 20, 40, 1366, 3840)};
  }

  &.map {
    color: ${palette.white};
    background-color: ${palette.blueZodiac};
  }

  &.plp {
    color: ${palette.black};
    background-color: ${palette.geyser};
  }
`

const IconContainer = styled.div`
  height: ${gF('px', 48, 96, 1366, 3840)};
  width: ${gF('px', 48, 96, 1366, 3840)};
  border-radius: ${gF('px', 14, 28, 1366, 3840)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${palette.white};
  font-size: ${gF('px', 24, 48, 1366, 3840)};
  border: 2px solid ${palette.cornflowerBlueAA};

  &.filled {
    border-color: transparent;
    background-color: ${palette.white};
  }

  img {
    height: ${gF('px', 36, 72, 1366, 3840)};
    width: ${gF('px', 36, 72, 1366, 3840)};
  }
`

interface Props {
  mode: 'plp' | 'map'
  className?: string
}

const AreaTrendsButton: FC<Props> = ({ mode, className }) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const handleToggleTrends = useToggleAaMapTrends()
  const trendsEnabled = searchParams.get('trends') === 'true' && mode === 'map'

  return (
    <Wrapper className={`${mode} ${className ?? ''}`} onClick={() => handleToggleTrends(mode)}>
      {!trendsEnabled && (
        <IconContainer className="filled">
          <RCIcon type="icon-trends" />
        </IconContainer>
      )}
      <span>{firstLetterCapital(t('AA.areaTrends'))}</span>
      {trendsEnabled && <IconContainer>&#x2715;</IconContainer>}
    </Wrapper>
  )
}

export default AreaTrendsButton
