import { VirtualMirror } from '@luxottica/virtual-mirror'

import { apiCustomerList, apiLogin } from '../../api/restApi'
import app_config from '../../config/app/config'
import { deleteIntersessionInfo } from '../../libs/auth'
import log from '../../libs/log'
import socketManager from '../../libs/socketManager'
import { AppThunk, AppThunkPromise } from '../../model/model'
import appActions, { resetErrors, storeError } from '../app/actions'
import { logoutPolicySelector } from './selectors'
import { slice } from './slice'
import { handleErrors } from '../../libs/handleError'

export function resetAppErrors(): AppThunk {
  return dispatch => {
    dispatch(resetAppErrors())
  }
}

export const login = (
  loginFormDataOrToken: { username: string; password: string } | string,
): AppThunkPromise => dispatch => {
  dispatch(resetErrors())

  return apiLogin(loginFormDataOrToken)
    .then(res => res.json())
    .then(data => {
      if (
        !data.authToken &&
        data.message &&
        (!data.message.includes('Invalid Credentials') ||
          !data.message.includes('Username not found'))
      ) {
        log.error(data, 'login')
        const errorParsed = JSON.parse(data.message)
        dispatch(
          slice.actions.loginFailed(
            errorParsed.error_description || errorParsed.error.message || errorParsed,
          ),
        )
      } else {
        const { authToken, events, customers } = data
        if (authToken) {
          const eventId = dispatch(appActions.setEvents(events))
          if (events && events.length === 1 && events[0].id === eventId) {
            dispatch(appActions.setCustomers(customers))
          } else {
            if (eventId) {
              apiCustomerList(authToken, eventId)
                .then(handleErrors)
                .then(customers => {
                  dispatch(appActions.setCustomers(customers))
                })
            }
          }
          dispatch(slice.actions.loggedIn(authToken))
        } else {
          dispatch(slice.actions.loginFailed('There is not a user with these credentials'))
        }
      }
    })
    .catch(err => {
      log.error(err, 'login')
      dispatch(storeError(err))
    })
}

export const destroySession = (): AppThunk => {
  return dispatch => {
    deleteIntersessionInfo()
    return dispatch({ type: 'DESTROY_SESSION' })
  }
}

export const logout = (): AppThunk => {
  return (dispatch, getState) => {
    // logout slave devices
    socketManager.emitSignal('logout')

    // logout master device
    deleteIntersessionInfo()
    VirtualMirror.resetBipaState()
    const logoutPolicy = logoutPolicySelector(getState())
    dispatch({ type: 'DESTROY_SESSION' })
    setTimeout(() => {
      if (logoutPolicy) {
        window.location.href = `${app_config.logoutUrl}/${logoutPolicy}/oauth2/v2.0/logout?post_logout_redirect_uri=${app_config.loginUrl}`
      } else {
        window.location.href = '/'
      }
    }, 100)
  }
}

export const loginFailed = slice.actions.loginFailed
export const loggedIn = slice.actions.loggedIn

const authActions = {
  ...slice.actions,
  resetAppErrors,
  login,
  destroySession,
  logout,
}

export default authActions
