import { CartFilters, FiltersState, PlpFilters } from '../../model/filters'

const cart: CartFilters = {
  geoFit: {
    active: [],
    selected: [],
    counter: {
      GLOBAL: undefined,
      ASIAN: undefined,
    },
    options: [
      { id: '-', name: 'GLOBAL', label: 'global' },
      { id: 'A', name: 'ASIAN', label: 'asian' },
    ],
  },
  asianFit: {
    active: [],
    selected: [],
    counter: {
      TRUE: undefined,
      FALSE: undefined,
    },
    options: [
      { id: 'TRUE', name: 'ASIANFIT', label: 'true' },
      { id: 'FALSE', name: 'ASIANFIT', label: 'false' },
    ],
  },
  assortmentIntent: {
    active: [],
    selected: [],
    counter: {
      PPE: undefined,
      HEADSET: undefined,
      OTHER: undefined,
    },
    options: [
      { id: 'PPE', name: 'PPE', label: 'ppe' },
      { id: 'HEADSET COMPATIBLE', name: 'HEADSET COMPATIBLE', label: 'headset' },
      { id: 'SHIELD YOUR EYES', name: 'SHIELD YOUR EYES', label: 'shield' },
      { id: 'OTHER LENS FEATURES', name: 'OTHER LENS FEATURES', label: 'other' },
    ],
  },
  ageGroup: {
    active: [],
    selected: [],
    counter: {
      ADULT: undefined,
      CHILD: undefined,
    },
    options: [
      { id: 'ADULT', name: 'ADULT', label: 'adult' },
      { id: 'CHILD', name: 'CHILD', label: 'child' },
    ],
  },
  lensProperties: {
    active: [],
    selected: [],
    counter: {
      PL: undefined,
    },
    options: [{ id: 'PL', name: 'POLARIZED', label: 'polarized' }],
  },
  tags: {
    active: [],
    selected: [],
    counter: {
      ADV: undefined,
      BESTSELLER: undefined,
    },
    options: [
      { id: 'ADV', name: 'ADV', label: 'adv' },
      { id: 'BESTSELLER', name: 'BESTSELLER', label: 'bestseller' },
    ],
  },
  brand_slug: {
    active: [],
    selected: [],
    counter: {
      oakley: undefined,
      'ray-ban': undefined,
      prada: undefined,
    },
    options: [
      { id: 'ray-ban', name: 'Ray Ban', label: 'Ray Ban' },
      { id: 'oakley', name: 'OALKEY', label: 'OALKEY' },
      { id: 'prada', name: 'Prada', label: 'Prada' },
    ],
  },
  gender: {
    active: [],
    selected: [],
    counter: {
      '1': undefined,
      '2': undefined,
      '5': undefined,
    },
    options: [
      { id: '1', name: 'man', label: 'man' },
      { id: '2', name: 'woman', label: 'woman' },
      { id: '5', name: 'unisex', label: 'unisex' },
    ],
  },
  size: {
    active: [],
    selected: [],
    counter: {
      XXS: undefined,
      XS: undefined,
      S: undefined,
      M: undefined,
      L: undefined,
      XL: undefined,
      XXL: undefined,
    },
    options: [
      { id: 'XXS', name: 'XXS', label: 'xxs' },
      { id: 'XS', name: 'XS', label: 'xs' },
      { id: 'S', name: 'S', label: 's' },
      { id: 'M', name: 'M', label: 'm' },
      { id: 'L', name: 'L', label: 'l' },
      { id: 'XL', name: 'XL', label: 'xl' },
      { id: 'XXL', name: 'XXL', label: 'xxl' },
    ],
  },
  frontMaterial: {
    active: [],
    selected: [],
    counter: {
      '1': undefined,
      '3': undefined,
      Plastic: undefined,
      Wood: undefined,
      '                    C': undefined,
    },
    options: [
      { id: '1', name: 'METAL', label: 'metal' },
      { id: '3', name: 'TITANIUM', label: 'titanium' },
      { id: 'Plastic', name: 'PLASTIC', label: 'plastic' },
      { id: 'Wood', name: 'WOOD', label: 'wood' },
      { id: '                    C', name: 'CARBON', label: 'carbon' },
    ],
  },
  frontFamilyColor: {
    active: [],
    selected: [],
    counter: {
      BLACK: undefined,
      BLUE: undefined,
      BORDEAUX: undefined,
      'BRONZE/COPPER': undefined,
      BROWN: undefined,
      CLEAR: undefined,
      GOLD: undefined,
      GREEN: undefined,
      GREY: undefined,
      GUNMETAL: undefined,
      HAVANA: undefined,
      HONEY: undefined,
      IVORY: undefined,
      'LIGHT BLUE': undefined,
      'LIGHT BROWN': undefined,
      MULTI: undefined,
      // 'NOT APPLICABLE': undefined,
      ORANGE: undefined,
      PINK: undefined,
      'PURPLE/REDDISH': undefined,
      RED: undefined,
      SILVER: undefined,
      VIOLET: undefined,
      WHITE: undefined,
      YELLOW: undefined,
    },
    options: [
      { id: 'BLACK', name: 'BLACK', label: 'black' },
      { id: 'BLUE', name: 'BLUE', label: 'blue' },
      { id: 'BORDEAUX', name: 'BORDEAUX', label: 'bordeaux' },
      { id: 'BRONZE/COPPER', name: 'BRONZE/COPPER', label: 'bronzecopper' },
      { id: 'BROWN', name: 'BROWN', label: 'brown' },
      { id: 'CLEAR', name: 'CLEAR', label: 'clear' },
      { id: 'GOLD', name: 'GOLD', label: 'gold' },
      { id: 'GREEN', name: 'GREEN', label: 'green' },
      { id: 'GREY', name: 'GREY', label: 'grey' },
      { id: 'GUNMETAL', name: 'GUNMETAL', label: 'gunmetal' },
      { id: 'HAVANA', name: 'HAVANA', label: 'havana' },
      { id: 'HONEY', name: 'HONEY', label: 'honey' },
      { id: 'IVORY', name: 'IVORY', label: 'ivory' },
      { id: 'LIGHT BLUE', name: 'LIGHT BLUE', label: 'lightblue' },
      { id: 'LIGHT BROWN', name: 'LIGHT BROWN', label: 'lightbrown' },
      { id: 'MULTI', name: 'MULTI', label: 'multi' },
      // { id: 'NOT APPLICABLE', name: 'NOT APPLICABLE', label: 'notapplicable' },
      { id: 'ORANGE', name: 'ORANGE', label: 'orange' },
      { id: 'PINK', name: 'PINK', label: 'pink' },
      { id: 'PURPLE/REDDISH', name: 'PURPLE/REDDISH', label: 'purplereddish' },
      { id: 'RED', name: 'RED', label: 'red' },
      { id: 'SILVER', name: 'SILVER', label: 'silver' },
      { id: 'VIOLET', name: 'VIOLET', label: 'violet' },
      { id: 'WHITE', name: 'WHITE', label: 'white' },
      { id: 'YELLOW', name: 'YELLOW', label: 'yellow' },
    ],
  },
  frameShape: {
    active: [],
    selected: [],
    counter: {
      '01': undefined,
      '02': undefined,
      '03': undefined,
      '04': undefined,
      '05': undefined,
      '06': undefined,
      '15': undefined,
      '20': undefined,
      '21': undefined,
      '31': undefined,
    },
    options: [
      { id: '01', name: 'OVAL', label: 'oval' },
      { id: '02', name: 'PHANTOS', label: 'phantos' },
      { id: '03', name: 'PILOT', label: 'pilot' },
      { id: '04', name: 'RECTANGLE', label: 'rectangle' },
      { id: '05', name: 'ROUND', label: 'round' },
      { id: '06', name: 'SQUARE', label: 'square' },
      { id: '15', name: 'IRREGULAR', label: 'irregular' },
      { id: '20', name: 'BUTTERFLY', label: 'butterfly' },
      { id: '21', name: 'CAT EYE', label: 'cateye' },
      { id: '31', name: 'PILLOW', label: 'pillow' },
    ],
  },
  type: {
    active: [],
    selected: [],
    counter: {
      A: undefined,
      B: undefined,
      C: undefined,
    },
    options: [
      { id: 'A', name: 'FULL RIM', label: 'fullrim' },
      { id: 'B', name: 'RIMLESS', label: 'rimless' },
      { id: 'C', name: 'SEMI RIM', label: 'semirimless' },
    ],
  },
  segment: {
    active: [],
    selected: [],
    counter: {
      '1': undefined,
      '2': undefined,
      '3': undefined,
      '4': undefined,
      '5': undefined,
    },
    options: [
      { id: '1', name: 'lifestyle', label: 'lifestyle' },
      { id: '2', name: 'luxury', label: 'luxury' },
      { id: '3', name: 'premium fashion', label: 'premiumfashion' },
      { id: '4', name: 'fashion', label: 'fashion' },
      { id: '5', name: 'sport performance', label: 'sportperformance' },
    ],
  },
}

const plp: PlpFilters = {
  ...cart,
  category: {
    active: [],
    selected: ['2'],
    counter: {
      '1': undefined,
      '2': undefined,
      '3': undefined,
    },
    options: [
      { id: '2', name: 'sun', label: 'sun' },
      { id: '1', name: 'optical', label: 'optical' },
      { id: '3', name: 'clipon', label: 'clipon' },
    ],
  },
}

export const DEFAULT_STATE: FiltersState = {
  plp,
  cart,
  pageType: 'plp',
  brandsBarOpened: false,
}
