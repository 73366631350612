import React from 'react'
import styled from 'styled-components'
import { getFluidSizeWithFullFormula, palette } from '../../../style/theme'
import classnames from 'classnames'

const Wrapper = styled.div`
  height: ${getFluidSizeWithFullFormula('px', 32, 48, 1366, 3840)};
  width: ${getFluidSizeWithFullFormula('px', 68, 102, 1366, 3840)};
`

const ToggleWrapper = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: ${palette.gallery};
  border-radius: ${getFluidSizeWithFullFormula('px', 32, 48, 1366, 3840)};
  box-sizing: border-box;
  position: relative;
  border: 1px solid ${palette.gallery};
  cursor: pointer;
  transition: 1s transform linear;

  &.locked {
    background-color: ${palette.white};
    border: 1px solid ${palette.tangaroa};
  }

  span {
    display: inline-block;
    left: ${getFluidSizeWithFullFormula('px', 2, 4, 1366, 3840)};
    top: ${getFluidSizeWithFullFormula('px', 2, 4, 1366, 3840)};
    position: absolute;
    height: ${getFluidSizeWithFullFormula('px', 26, 38, 1366, 3840)};
    width: ${getFluidSizeWithFullFormula('px', 26, 38, 1366, 3840)};
    border-radius: 50%;
    background-color: ${palette.tangaroa};
    transition: 0.2s transform linear;

    &.locked {
      transform: translateX(${getFluidSizeWithFullFormula('px', 35, 54, 1366, 3840)});
    }
  }
`

const Toggle: React.FC<{ className?: string; locked: boolean; action: () => void }> = ({
  className,
  locked,
  action,
}) => {
  return (
    <Wrapper className={className}>
      <ToggleWrapper onClick={action} className={classnames({ locked })}>
        <span className={classnames({ locked })} />
      </ToggleWrapper>
    </Wrapper>
  )
}

export default Toggle
