import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { isAuthenticated } from '../libs/auth'
import { tokenSelector } from '../store/auth/selectors'

const PrivateRoute: React.FC<RouteProps> = ({ children, ...props }) => {
  const token = useSelector(tokenSelector)

  return isAuthenticated(token) ? <Route {...props}>{children}</Route> : <Redirect to="/" />
}

export default PrivateRoute
