import { useEffect, useState } from 'react'

const useSingleAndDoubleClick = (actionDoubleClick: any, actionSimpleClick: any, delay = 150) => {
  const [click, setClick] = useState(0)
  const [event, setEvent] = useState<any>(null)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (click === 1) actionSimpleClick(event)
      setClick(0)
    }, delay)

    if (click === 2) actionDoubleClick(event)

    return () => {
      setEvent(null)
      clearTimeout(timer)
    }
  }, [actionDoubleClick, actionSimpleClick, click, delay, event])

  return (e: any) => {
    setClick(prev => prev + 1)
    setEvent(e)
  }
}

export default useSingleAndDoubleClick
