import React from 'react'
import styled from 'styled-components'

import { breakpoints, pxToRem, spacing } from '../../style/theme'

export const Wrapper = styled.div`
  padding: ${pxToRem(spacing(0.5))}rem ${pxToRem(spacing(2))}rem;
  border-radius: 2rem;
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.1);
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;

  @media (max-width: ${breakpoints.M}) {
    padding: ${pxToRem(spacing(0.5))}rem ${pxToRem(spacing(1))}rem;
  }
`

const Label = styled.div`
  opacity: 0.5;
  margin-right: 1em;
`

const Text = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

const Chip: React.FC<{ className?: string; label?: string; text: string }> = ({
  className,
  label,
  text,
}) => (
  <Wrapper className={className}>
    {label && <Label>{label}:</Label>}
    <Text>{text}</Text>
  </Wrapper>
)

export default Chip
