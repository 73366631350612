import React from 'react'
import styled from 'styled-components'
import CategoryImage, { PlaceholderCol } from './CategoryImage'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Row from '../../styleguide/Row'

import { palette } from '../../style/theme'
import { useParams } from 'react-router-dom'
import { brandSelector, isOpenedBrandStarsSelector } from '../../store/brands/selectors'
import { disableKidCategoryMode, enableKidCategoryMode } from '../../store/app/actions'
import starsActions from '../../store/stars/actions'
import { SubBrand } from '../../model/brand'
import { activeDoorsIdsSelector } from '../../store/customer/selectors'
import couvettesActions from '../../store/couvettes/actions'

export const SingleBrandGrid = styled.div`
  background-color: ${palette.codGray};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4vh;
`
export const StyledRow = styled(Row)`
  background-color: ${palette.codGray};
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 43%;
`

const isSubBrandStars = (subBrand: SubBrand, activeDoorsIds: string[]) => {
  return subBrand.starsDoorsIds.some(doorId => activeDoorsIds.includes(doorId))
}

const Categories: React.FC = () => {
  const { brandSlug } = useParams<{ brandSlug: string }>()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const brand = useSelector(brandSelector(brandSlug))
  const isStarsBrand = useSelector(isOpenedBrandStarsSelector)
  const activeDoorsIds = useSelector(activeDoorsIdsSelector)

  const smallGrid =
    brand?.categoriesAvailable.sun &&
    brand?.categoriesAvailable.optical &&
    brand?.categoriesAvailable.electronic

  const subBrands = brand?.subBrands || []
  const sunSubBrand = subBrands.find(({ sun, junior }) => sun && !junior)
  const opticalSubBrand = subBrands.find(({ optical, junior }) => optical && !junior)
  const sunJuniorSubBrand = subBrands.find(({ sun, junior }) => sun && junior)
  const opticalJuniorSubBrand = subBrands.find(({ optical, junior }) => optical && junior)
  const electronicSubBrand = subBrands.find(({ electronic }) => electronic)

  return (
    <div className="layout">
      <SingleBrandGrid className="layout-content">
        {(brand?.categoriesAvailable.sun ||
          brand?.categoriesAvailable.optical ||
          brand?.categoriesAvailable.electronic) && (
          <StyledRow>
            {brand.categoriesAvailable.sun && (
              <CategoryImage
                title={t('CollectionsPage.item_sun')}
                brand={brand}
                type="sun"
                pageSlug="sun"
                handleClick={() => {
                  dispatch(couvettesActions.setIsComingFromFilters(false))
                  dispatch(disableKidCategoryMode())
                  if (
                    (sunSubBrand && isSubBrandStars(sunSubBrand, activeDoorsIds)) ||
                    (!sunSubBrand && isStarsBrand)
                  ) {
                    dispatch(starsActions.setMode('stars'))
                  } else {
                    dispatch(starsActions.setMode('units'))
                  }
                }}
                small={smallGrid}
              />
            )}
            {brand.categoriesAvailable.optical && (
              <CategoryImage
                title={t('CollectionsPage.item_optical')}
                brand={brand}
                type="optical"
                pageSlug="optical"
                handleClick={() => {
                  dispatch(couvettesActions.setIsComingFromFilters(false))
                  dispatch(disableKidCategoryMode())
                  if (
                    (opticalSubBrand && isSubBrandStars(opticalSubBrand, activeDoorsIds)) ||
                    (!opticalSubBrand && isStarsBrand)
                  ) {
                    dispatch(starsActions.setMode('stars'))
                  } else {
                    dispatch(starsActions.setMode('units'))
                  }
                }}
                small={smallGrid}
              />
            )}
            {brand?.categoriesAvailable.electronic && (
              <CategoryImage
                brand={brand}
                type="electronics"
                pageSlug="electronics-sun"
                handleClick={() => {
                  dispatch(couvettesActions.setIsComingFromFilters(false))
                  dispatch(disableKidCategoryMode())
                  if (
                    (electronicSubBrand && isSubBrandStars(electronicSubBrand, activeDoorsIds)) ||
                    (!electronicSubBrand && isStarsBrand)
                  ) {
                    dispatch(starsActions.setMode('stars'))
                  } else {
                    dispatch(starsActions.setMode('units'))
                  }
                }}
                title={t('CollectionsPage.item_electronic')}
                small={smallGrid}
              />
            )}
          </StyledRow>
        )}
        {brand?.isJunior && (
          <StyledRow>
            {brand.categoriesAvailable.sunJunior && (
              <CategoryImage
                brand={brand}
                type="sun_kids"
                pageSlug="sun"
                handleClick={() => {
                  dispatch(couvettesActions.setIsComingFromFilters(false))
                  dispatch(enableKidCategoryMode())
                  if (
                    (sunJuniorSubBrand && isSubBrandStars(sunJuniorSubBrand, activeDoorsIds)) ||
                    (!sunJuniorSubBrand && isStarsBrand)
                  ) {
                    dispatch(starsActions.setMode('stars'))
                  } else {
                    dispatch(starsActions.setMode('units'))
                  }
                }}
                title={`${brand.juniorBrandDefinition} ${t('CollectionsPage.item_sun')}`}
                small={smallGrid}
              />
            )}
            {brand.categoriesAvailable.opticalJunior && (
              <CategoryImage
                brand={brand}
                type="optical_kids"
                pageSlug="optical"
                handleClick={() => {
                  dispatch(couvettesActions.setIsComingFromFilters(false))
                  dispatch(enableKidCategoryMode())
                  if (
                    (opticalJuniorSubBrand &&
                      isSubBrandStars(opticalJuniorSubBrand, activeDoorsIds)) ||
                    (!opticalJuniorSubBrand && isStarsBrand)
                  ) {
                    dispatch(starsActions.setMode('stars'))
                  } else {
                    dispatch(starsActions.setMode('units'))
                  }
                }}
                title={`${brand.juniorBrandDefinition} ${t('CollectionsPage.item_optical')}`}
                small={smallGrid}
              />
            )}
            {smallGrid && <PlaceholderCol />}
          </StyledRow>
        )}
      </SingleBrandGrid>
    </div>
  )
}

export default Categories
