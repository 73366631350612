import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { TDispatch } from '../../model/model'
import { closeFooterModal, searchCouvettes } from '../../store/actions'
import { scanCameraModeSelector } from '../../store/app/selectors'
import { modelsFoundSelector } from '../../store/search/selectors'
import CodeScanner from './CodeScanner'
import { getDeviceAbsolutePath } from '../../libs/url'
import { useHistory } from 'react-router'
import { errorNotification } from '../Notification/notifications'

const CodeScannerStyled = styled(CodeScanner)`
  margin-bottom: 1em;
`

const CodeScannerModal: React.FC = () => {
  const scanCameraMode = useSelector(scanCameraModeSelector)
  const modelsFound = useSelector(modelsFoundSelector)
  const history = useHistory()
  const isAfa = window.location.href.includes('afa')

  const dispatch: TDispatch = useDispatch()

  const { t } = useTranslation()

  const [detectedCode, setDetectedCode] = useState('')
  const [isScanned, setIsScanned] = useState(false)

  useEffect(() => {
    if (detectedCode !== '' && modelsFound) {
      setDetectedCode('')
    } else if (detectedCode && !modelsFound) {
      errorNotification({ message: t('ModalProductSearch.item_no_products') })
    }
  }, [modelsFound, detectedCode, t, isAfa])

  return (
    <div>
      <CodeScannerStyled
        width={400}
        height={200}
        onResult={(res: { value: string }[]) => {
          const code = res[0].value
          setIsScanned(true)
          if (isAfa) {
            history.push(`${getDeviceAbsolutePath()}/afa/search-results?searchTerm=${code}`)
          } else {
            dispatch(searchCouvettes(code)).then(() => {
              setDetectedCode(code)
              dispatch(closeFooterModal(false))
            })
          }
          // eslint-disable-next-line no-console
          console.log('CODE: ', code)
        }}
        onError={(message: string) => errorNotification({ message })}
        facingMode={scanCameraMode}
        isScanned={isScanned}
      />
    </div>
  )
}

export default CodeScannerModal
