import React from 'react'
import styled from 'styled-components'
import { palette } from '../../../style/theme'
import StarsLogo from '../../icons/StarsLogo'

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: ${palette.blueStars};
  background-color: ${palette.white};
`

const StarsLogoContent = styled.div`
  svg {
    width: 26vw;
    height: 26vw;
  }
`

type Props = {
  className?: string
}

const StarsLogoWall: React.FC<Props> = ({ className = '' }) => {
  return (
    <Wrapper className={className}>
      <StarsLogoContent>
        <StarsLogo />
      </StarsLogoContent>
    </Wrapper>
  )
}

export default StarsLogoWall
